import {
  Grid,
  Typography,
  Popover,
  IconButton,
  Divider,
  Button,
  Box,
} from "@material-ui/core";
import colors from "constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import parse from "html-react-parser";
import { municipalLandingCodes as formCodes } from "constants/formContentManagement";
import { formatToCurrency } from "utils/number";

const useStyles = makeStyles((theme) => ({
  paper: {
    color: colors.black,
    backgroundColor: colors.white,
    borderRadius: "0.625rem",
    width: "60rem",
    minHeight: "27rem",
    padding: theme.spacing(2),
  },
  closeButtonCell: {
    marginLeft: "auto",
  },
  statusTitle: {
    height: "2rem",
  },
  centralZone: {
    width: "100%",
    paddingRight: "4rem",
  },
  iconButton: {
    padding: theme.spacing(0.8),
    color: colors.grey.main,
  },
  superDenseList: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  actionArea: {
    padding: theme.spacing(2),
    width: "240px",
  },
}));

/**
 * A modal to display 5-year banking limit information and compliance status.
 *
 * @param {object} props
 * @param {boolean} props.isFiveYearOpen  The modal's open/closed state.
 * @param {Function} props.setIsFiveYearOpen  Sets the modal's open/closed state.
 * @param {object} props.data  object with data from the five year banking limit
 * @param {number} props.data.remainingFunds  five year banking limit remaining funds
 * @param {string} props.data.status  five year banking limit status
 * @param {string} props.data.exemption  five year banking limit exemption status
 * @param {object[]} props.formFields - array of object containing props for the modal form fields
 * @param {string} props.formFields.code - sets the code of the form field
 * @param {string} props.formFields.text - sets the text of the form field
 * @returns {React.Component} The 5-Year Banking Limit modal component.
 */
const FiveYearBankingLimitModal = (props) => {
  const { isFiveYearOpen, setFiveYearOpen, data, formFields } = props;
  const classes = useStyles();

  const formatWithBracketsIfNegative = (value) =>
    value < 0
      ? `(${formatToCurrency(Math.abs(value))})`
      : formatToCurrency(value);

  const formatToCurrencyOrBlank = (value) =>
    !isNaN(parseFloat(value)) ? formatWithBracketsIfNegative(value) : "";

  return (
    <>
      <Popover
        open={isFiveYearOpen}
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        anchorEl={document.body}
        anchorOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
        PaperProps={{
          "data-testid": "fiveYearPaper",
          square: false,
          variant: "elevation",
          elevation: 20,
        }}
        data-testid="fiveYearModal"
      >
        <Grid container direction="column">
          <Grid item container direction="row">
            <Grid item>
              <Typography variant="h3" data-testid="headerText">
                5 Year Banking Limit Status
              </Typography>
            </Grid>
            <Grid item classes={{ root: classes.closeButtonCell }}>
              <IconButton
                data-testid="popoverCloseButton"
                classes={{
                  root: classes.iconButton,
                }}
                onClick={setFiveYearOpen}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            classes={{ root: classes.centralZone }}
          >
            <Grid item>
              <Typography variant="body1" data-testid="instructionText">
                {parse(
                  formFields[formCodes.bankLimitDescriptionFormStart]?.text ??
                    ""
                )}
              </Typography>
            </Grid>
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.statusTitle }} xs={6}>
                <Box color={colors.green.dark}>
                  <Typography variant="body2" data-testid="remainingFundsText">
                    {formFields[formCodes.bankLimitTitleFunds]?.text}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">
                  {formatToCurrencyOrBlank(data?.remainingFunds)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.statusTitle }} xs={6}>
                <Box color={colors.green.dark}>
                  <Typography variant="body2" data-testid="statusText">
                    {formFields[formCodes.bankLimitTitleStatus]?.text}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{data?.status}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.statusTitle }} xs={6}>
                <Box color={colors.green.dark}>
                  <Typography variant="body2" data-testid="exemptionText">
                    {formFields[formCodes.bankLimitTitleExemption]?.text}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{data?.exemption}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {parse(
                formFields[formCodes.bankLimitDescriptionFormEnd]?.text ?? ""
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid container direction="row-reverse">
          <Grid item classes={{ root: classes.actionArea }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={setFiveYearOpen}
              fullWidth
              size="large"
              data-testid="closeButton"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

FiveYearBankingLimitModal.propTypes = {
  isFiveYearOpen: PropTypes.bool.isRequired,
  setFiveYearOpen: PropTypes.func.isRequired,
  data: PropTypes.shape({
    remainingFunds: PropTypes.number,
    status: PropTypes.string,
    exemption: PropTypes.string,
  }),
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

FiveYearBankingLimitModal.defaultProps = {
  data: null,
  formFields: [],
};

export default FiveYearBankingLimitModal;
