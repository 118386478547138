import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Divider, Typography } from "@material-ui/core";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  submitReportCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    // 32px
    paddingLeft: "2rem",
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 20px 0px
    margin: "1.25rem 0rem",
  },
  submittedBox: {
    marginTop: "1rem",
    padding: "1rem 2rem",
    width: "21.875rem",
    alignSelf: "center",
    "& :nth-child(2)": {
      marginTop: "1rem",
    },
  },
}));

/**
 * A page component for editing "Submit Report" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const SubmitReportEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(formIds.submitReport);

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Submit Report"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyPadding
            )}
          >
            {/* Fields Group: Outstanding Prerequisite Instructions */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    data-testid="OutstandingPrereqInstrsTitle"
                    variant="h4"
                    gutterBottom
                  >
                    Outstanding Prerequisite Instructions
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Outstanding Prerequisite Instructions */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[
                    formCodes.outstandingPrerequisiteInstructions
                  ] && (
                    <FormFieldWrapper
                      id="sr-out-prereq-instrs"
                      testId="srOutPrereqInstrs"
                      type={
                        formFields[
                          formCodes.outstandingPrerequisiteInstructions
                        ].type
                      }
                      value={
                        formFields[
                          formCodes.outstandingPrerequisiteInstructions
                        ].text
                      }
                      isFullWidth
                      textProps={{ variant: "body1" }}
                      align="flex-start"
                      onEditClick={() =>
                        handleEditClick(
                          formFields[
                            formCodes.outstandingPrerequisiteInstructions
                          ]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Divider */}
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Fields Group: Complete Prerequisite Instructions */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    data-testid="CompletePrereqInstrsTitle"
                    variant="h4"
                    gutterBottom
                  >
                    Complete Prerequisite Instructions
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Complete Prerequisite Instructions */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.completePrerequisiteInstructions] && (
                    <FormFieldWrapper
                      id="sr-complete-prereq-instrs"
                      testId="srCompletePrereqInstrs"
                      type={
                        formFields[formCodes.completePrerequisiteInstructions]
                          .type
                      }
                      value={
                        formFields[formCodes.completePrerequisiteInstructions]
                          .text
                      }
                      textProps={{ variant: "body1" }}
                      align="flex-start"
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.completePrerequisiteInstructions]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Divider */}
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Fields Group: Success Modal Text */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    data-testid="SuccessModalTextTitle"
                    variant="h4"
                    gutterBottom
                  >
                    Success Modal Text
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Submitted box */}
            <Box
              border={1}
              borderColor={colors.main}
              className={classes.submittedBox}
            >
              {/* Submitted */}
              <Grid item xs>
                <Grid container direction="column" wrap="nowrap">
                  <Grid item>
                    {formFields[formCodes.submitted] && (
                      <FormFieldWrapper
                        id="sr-submitted"
                        testId="srSubmitted"
                        type={formFields[formCodes.submitted].type}
                        value={formFields[formCodes.submitted].text}
                        textProps={{ variant: "h2", align: "center" }}
                        isFullWidth
                        onEditClick={() =>
                          handleEditClick(formFields[formCodes.submitted])
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* Thanks */}
              <Grid item xs>
                <Grid container direction="column" wrap="nowrap">
                  <Grid item>
                    {formFields[formCodes.thanks] && (
                      <FormFieldWrapper
                        id="sr-thanks"
                        testId="srThanks"
                        type={formFields[formCodes.thanks].type}
                        value={formFields[formCodes.thanks].text}
                        textProps={{ variant: "body1", align: "center" }}
                        onEditClick={() =>
                          handleEditClick(formFields[formCodes.thanks])
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="sr-edit-popover"
        testId="srEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default SubmitReportEditPage;
