import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider } from "@material-ui/core";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  projectReportResultsCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
  },
  formTitle: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      "&:last-child": {
        // 20px
        paddingBottom: "1.25rem",
      },
    },
  },
  formField: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        paddingBottom: "0rem",
      },
    },
  },
  formFieldGrid: {
    "& > .MuiGrid-item > .MuiGrid-container > .MuiGrid-item": {
      // 24px
      paddingRight: "1.5rem",
      // 24px
      paddingLeft: "1.5rem",
      "&:first-child": {
        paddingLeft: "0rem",
      },
      "&:last-child": {
        paddingRight: "0rem",
      },
    },
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 24px 0px
    margin: "1.5rem 0rem",
  },
  fullWidth: {
    width: "100%",
  },
  fundsAppliedContainer: {
    // 32px 120px 0px 32px
    margin: "2rem 7.5rem 0rem 2rem",
    border: `1px solid ${colors.grey.main}`,
    borderRadius: "10px",
    padding: "1rem",
  },
}));

/**
 * A page component for editing "Project Reporting: Results" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const ReportResultsEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.projectReportResults
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Project Reporting: Results"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyPadding
            )}
          >
            {/* Form Description */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.descriptionForm] && (
                    <FormFieldWrapper
                      id="prr-form-description"
                      testId="prrFormDescription"
                      type={formFields[formCodes.descriptionForm].type}
                      value={formFields[formCodes.descriptionForm].text}
                      isFullWidth
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionForm])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionCategory] && (
                    <FormFieldWrapper
                      id="prr-category-description"
                      testId="prrCategoryDescription"
                      type={formFields[formCodes.descriptionCategory].type}
                      value={formFields[formCodes.descriptionCategory].text}
                      isFullWidth
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.descriptionCategory]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Outputs Title */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitle}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.titleOutputs] && (
                    <FormFieldWrapper
                      id="prr-outputs-title"
                      testId="prrOutputsTitle"
                      type={formFields[formCodes.titleOutputs].type}
                      value={formFields[formCodes.titleOutputs].text}
                      textProps={{ variant: "h4" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.titleOutputs])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionOutputs] && (
                    <FormFieldWrapper
                      id="prr-outputs-description"
                      testId="prrOutputsDescription"
                      type={formFields[formCodes.descriptionOutputs].type}
                      value={formFields[formCodes.descriptionOutputs].text}
                      isFullWidth
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.descriptionOutputs]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Outcomes Title */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitle}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.titleOutcomes] && (
                    <FormFieldWrapper
                      id="prr-outcomes-title"
                      testId="prrOutcomesTitle"
                      type={formFields[formCodes.titleOutcomes].type}
                      value={formFields[formCodes.titleOutcomes].text}
                      textProps={{ variant: "h4" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.titleOutcomes])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionOutcomes] && (
                    <FormFieldWrapper
                      id="prr-outcomes-description"
                      testId="prrOutcomesDescription"
                      type={formFields[formCodes.descriptionOutcomes].type}
                      value={formFields[formCodes.descriptionOutcomes].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.descriptionOutcomes]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Other Results */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.fieldOtherResults] && (
                    <FormFieldWrapper
                      id="prr-other-results-field"
                      testId="prrOtherResultsField"
                      type={formFields[formCodes.fieldOtherResults].type}
                      value={formFields[formCodes.fieldOtherResults].text}
                      className={classes.fullWidth}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.fieldOtherResults])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="prr-edit-popover"
        testId="prrEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default ReportResultsEditPage;
