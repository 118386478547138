import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import MunicpalLandingPage from "./municipal/LandingPage";
import AmoLandingPage from "./amo/LandingPage";
import CommunicationsLandingPage from "./communications/LandingPage";
import { useUserContext } from "../contexts/UserContext";

/**
 * A user-context-sensitive landing page.
 *
 * @returns {React.Component} Landing component.
 */
const Landing = () => {
  const { user, silentSignIn } = useUserContext();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  // if there is a user in memory, redirect the user to the correct home page based on the role they have
  if (user.isAuthenticated) {
    let component = null;
    if (user.roles.includes("AmoStaff")) {
      component = <AmoLandingPage />;
    } else if (user.roles.includes("CommunicationsUser")) {
      component = <CommunicationsLandingPage />;
    } else if (user.roles.includes("MunicipalUser")) {
      component = <MunicpalLandingPage />;
    }
    return component;
  }

  // If the landing page is called from local login, try signing in silently (it probably
  // means the login form was called from an email and the user already logged in)
  if (urlParams.get("fromLogin") === "true") {
    silentSignIn({ state: { userUrl: "/landing" } });
    return <Redirect to="/landing" />;
  }

  // if there is no user, redirect to login
  return <Redirect to="/login" />;
};

export default Landing;
