import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Icon } from "@material-ui/core";

import { useHistory, useLocation, Link } from "react-router-dom";
import clsx from "clsx";

import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import ListPageWrapper from "components/ListPageWrapper";

import colors from "constants/colors";
import { routes } from "constants/routes";
import { snackbarTypes } from "constants/snackbar";
import {
  displaySubTypes,
  displayTypes,
  tableDefaultActions,
} from "constants/amoTableConstants";
import { useSnackbar } from "contexts/SnackbarContext";
import { useYearContext } from "contexts/YearContext";
import { exportToExcel } from "utils/export";
import {
  projectCategoriesAbbreviations,
  indicatorTypes,
  indicatorValueTypes,
} from "constants/amoTableDropdownOptions";

import ManageGroupingsModal from "./ManageGroupingsModal";
import { useIndicatorsSummary } from "./indicatorManagementHooks";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.black,
  },
  rowFont: {
    fontSize: "0.875rem",
  },
  textLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  editIcon: {
    fontSize: "1.5rem",
  },
  table: {
    height: "10%",
  },
}));

/**
 * A page component for listing all outputs and outcomes on Content Management
 *
 * @returns {React.Component} The page component
 */
const OutputsOutcomesListPage = () => {
  const classes = useStyles();
  const { contextYear } = useYearContext();
  const location = useLocation();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const [showModal, setShowModal] = useState(false);
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const { data: indicators, isFetching } = useIndicatorsSummary(contextYear);

  const tableColumns = [
    {
      fieldKey: "category",
      label: "Category",
      minWidth: "8.5rem",
      dropdownOptions: projectCategoriesAbbreviations,
      displayType: displayTypes.dropdown,
      displaySubType: displaySubTypes.differentFilterAndItems,
    },
    {
      fieldKey: "outputOutcome",
      label: "Output/Outcome",
      minWidth: "12.5rem",
      dropdownOptions: indicatorTypes,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "description",
      label: "Description",
      minWidth: "22rem",
    },
    {
      fieldKey: "sort",
      label: "Sort",
      minWidth: "6rem",
    },
    {
      fieldKey: "type",
      label: "Type",
      minWidth: "6rem",
      dropdownOptions: indicatorValueTypes,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "status",
      label: "Status",
      minWidth: "7rem",
      dropdownOptions: {
        Active: "Active",
        Inactive: "Inactive",
      },
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "edit",
      minWidth: "3rem",
      hideLabel: true,
      hideSearch: true,
      customRender: ({ id }) => (
        <Link
          to={`${routes.contentManagement.outputsOutcomes.edit.replace(
            ":outputOutcomeId",
            id
          )}${location.search}`}
          ref={(element) => {
            rowRefs.current[id] = element;
          }}
          onFocus={() => setFocusedItem({ id })}
        >
          <Icon
            color="primary"
            onMouseOver={(event) => event.stopPropagation()}
            className={clsx("material-icons-outlined", classes.editIcon)}
          >
            edit
          </Icon>
        </Link>
      ),
      isCustom: true,
    },
  ];

  const handleItemClick = (item) => {
    history.push(
      `${routes.contentManagement.outputsOutcomes.list}/${item.id}${location.search}`
    );
  };

  const tableActions = {
    actions: [
      tableDefaultActions.new,
      tableDefaultActions.search,
      tableDefaultActions.export,
    ],
    actionsCustomConfigs: {
      [tableDefaultActions.new]: {
        onClick: () => {
          history.push(
            `${routes.contentManagement.outputsOutcomes.new}${location.search}`
          );
        },
      },
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            exportToExcel(
              filteredData.current,
              tableColumns,
              "Outputs & Outcomes"
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
      },
    },
  };

  return (
    <ListPageWrapper>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.table}
        wrap="nowrap"
      >
        <Grid item>
          <Typography gutterBottom className={classes.title} variant="h1">
            Edit Outputs & Outcomes
          </Typography>
        </Grid>
        <Grid item>
          <AmoTableWithFilters
            id="indicators-table"
            items={indicators}
            columns={tableColumns}
            defaultOrderBy="name"
            enableItemClick
            onItemClick={handleItemClick}
            onFilteredDataChanged={(value) => {
              filteredData.current = value;
            }}
            onScroll={(item) => {
              rowRefs.current?.[item.id]?.focus();
            }}
            focusedItem={focusedItem}
            actionsProps={tableActions}
            isLoading={isFetching}
            mappedFilterMatchs={{
              status: (itemValue, filterValue) => itemValue === filterValue,
            }}
          />
        </Grid>
      </Grid>
      {showModal && (
        <ManageGroupingsModal
          variant="default"
          showModal={showModal}
          closeModalFunction={() => setShowModal(false)}
          title="Groupings"
          text="Texty text"
          showButton
          buttonText="Save and Close"
        />
      )}
    </ListPageWrapper>
  );
};

export default OutputsOutcomesListPage;
