import React from "react";
import { Icon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = (color, size, customWidth, noMargin, borderRadius) =>
  makeStyles((theme) => ({
    icon: {
      height: size,
      width: customWidth ?? size,
      marginRight: noMargin ? "0" : "0.5rem",
      backgroundColor: color,
      borderRadius: borderRadius ?? "0",
    },
  }))();

/**
 * Displays a colored square.
 *
 * @param {object} props
 * @param {string} props.color The color of the square
 * @param {string} props.size The size of the square
 * @param {string} props.customWidth The height of the square
 * @param {string} props.noMargin The margin right of the square
 * @param {string} props.borderRadius The border radius of the square
 * @returns {React.Component} A colored square component.
 */
const ColoredSquare = ({
  color,
  size,
  customWidth,
  noMargin,
  borderRadius,
}) => {
  const classes = useStyles(color, size, customWidth, noMargin, borderRadius);
  return <Icon className={classes.icon} />;
};

ColoredSquare.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.string,
  customWidth: PropTypes.string,
  borderRadius: PropTypes.string,
  noMargin: PropTypes.bool,
};

ColoredSquare.defaultProps = {
  size: "0.875rem",
  customWidth: undefined,
  noMargin: false,
  borderRadius: undefined,
};

export default ColoredSquare;
