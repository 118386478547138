import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import colors from "constants/colors";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import AmoTooltip from "components/AmoTooltip";
import AmoTextField from "components/inputs/AmoTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { outputOptions, unitDataOptions } from "constants/propTypes";
import {
  getDecimals,
  unitCategoryTypes,
  lengthUnitTypes,
  sortComparator,
} from "constants/indicatorConstants";
import {
  localRoadsCategoryId,
  localRoadsSubcategoryId,
} from "constants/projectReport";
import { stableSort } from "utils/data";

const useStyles = makeStyles((theme) => ({
  accordion: {
    position: "relative",
    width: "90%",
    backgroundColor: "transparent",
    border: `1px solid ${colors.grey.main}`,
    "&.MuiAccordion-rounded": {
      borderRadius: "10px",
    },

    "& > .MuiAccordionSummary-root > .MuiAccordionSummary-content": {
      // 16px 0px
      margin: "1rem 0",
      "&.Mui-expanded": {
        // 16px 0px 0px 0px
        margin: "1rem 0 0 0",
      },
    },
    "& > .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon": {
      "&.Mui-expanded": {
        transform: "rotate(90deg)",
      },
      "& .MuiIcon-root": {
        color: colors.black,
      },
    },
  },
  contentCol: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    marginTop: "-1rem",
  },
  textRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  tooltipContainer: {
    marginLeft: "0.5rem",
    marginTop: "1.25rem",
  },
  inputContainer: {
    position: "relative",
    width: "20rem",
  },
  numericRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    flexDirection: "row",
  },
  unitContainer: {
    width: "12.5rem",
    marginLeft: "2rem",
  },
  selectBox: {
    minWidth: "100px",
  },
}));

/**
 * A form component for editing Project Report Financials
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.disabled - allows inputs to be disabled when reporting year doesn't match end date
 * @param {Array} props.outputData - object containing output data
 * @param {object} props.outputSchema - yup schema for the output form
 * @param {object} props.defaultValues - default values for the output form
 * @param {Function} props.validateOutput - function that determines if the output form is valid or not
 * @param {Array} props.unitData - array that holds indicator unit type data
 * @param {object} props.prevFormValues - object that holds the previous form value before a save event
 * @param {Function} props.dirtyFieldHook - hook that returns the forms dirtyFields to the parent component
 * @param {boolean} props.secondaryInputs - object that holds the noOutputsApply and otherResults values
 * @param {Function} props.onFormRender - function called when the project outputs form is rendered (params: RHF utils)
 *
 * @returns - Project Outputs form component
 */
const ProjectOutputs = (props) => {
  const {
    disabled,
    outputData,
    outputSchema,
    defaultValues,
    validateOutput,
    unitData,
    prevFormValues,
    dirtyFieldHook,
    secondaryInputs,
    onFormRender,
  } = props;
  const classes = useStyles();

  const [unitGroups, setUnitGroups] = useState(null);
  const [formWasReseted, setFormWasReseted] = useState(false);
  const previousFormFields = useRef(null);

  const {
    control: outputControl,
    getValues: getOutputFormValues,
    formState: outputFormState,
    setValue: setOutputValues,
    watch: outputWatch,
    reset: resetOutputs,
    trigger: triggerOutputValidation,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(outputSchema),
  });
  const { isValid, isDirty, dirtyFields } = outputFormState;

  // function that renders the different group accordions
  const renderData = () =>
    outputData.map((val, idx) => {
      const groupingName = val.groupName || "Unknown Group";
      const currentGroupContent = stableSort(val.indicators, sortComparator);
      return (
        <Accordion
          className={classes.accordion}
          elevation={0}
          key={val.groupId}
          style={{ marginTop: idx !== 0 && "1rem" }}
        >
          <AccordionSummary
            id="proj-report-ccbf-funds"
            aria-controls="proj-report-ccbf-funds-content"
            expandIcon={<Icon className="material-icons">chevron_right</Icon>}
          >
            <Typography variant="h4">{groupingName}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.contentCol}>
            {currentGroupContent.map((content) => {
              const savedData =
                content.savedData.id !== null ? content.savedData : null;
              const saveId = savedData !== null ? savedData.id : "new";
              const currentUnitId = outputWatch(
                `indicator-${content.id}-${saveId}-unit`
              );
              return (
                <Grid key={content.id}>
                  <Box className={classes.textRow}>
                    <Typography variant="h7" style={{ marginTop: "1rem" }}>
                      {content.description}
                    </Typography>
                    {content.tooltipText !== null && (
                      <Box className={classes.tooltipContainer}>
                        <AmoTooltip
                          tooltipTitle={null}
                          tooltipText={content.tooltipText}
                        />
                      </Box>
                    )}
                  </Box>
                  {formType(content, saveId, currentUnitId)}
                </Grid>
              );
            })}
          </AccordionDetails>
        </Accordion>
      );
    });

  const handleClearUnitDropdown = (fieldName, value = "") => {
    setOutputValues(`${fieldName}-unit`, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    triggerOutputValidation([fieldName, `${fieldName}-multiplier`]);
  };

  // renders the different form types depending on the the input type provided
  const formType = (indicatorData, saveId, currentUnitId) => {
    const indicatorName = indicatorData.indicatorType;
    const indicatorFormName = `indicator-${indicatorData.id}-${saveId}`;
    const currentUnitGroup =
      indicatorData.indicatorUnitTypeId !== null
        ? unitGroups[`${indicatorFormName}-unit`]
        : null;

    const isInvalidUnit =
      currentUnitGroup &&
      !currentUnitGroup.map((item) => item.id).includes(currentUnitId);

    const isLocalRoadsLength =
      indicatorData.categoryId === localRoadsCategoryId &&
      indicatorData.subcategoryId === localRoadsSubcategoryId &&
      indicatorData.indicatorUnitTypeId === unitCategoryTypes.length;

    const showLanesField =
      !isInvalidUnit &&
      isLocalRoadsLength &&
      currentUnitId !== lengthUnitTypes.laneKilometres;

    switch (indicatorName) {
      case "Checkbox":
        return (
          <AmoCheckbox
            disabled={disabled || getOutputFormValues("noOutputsApply")}
            control={outputControl}
            id={`${indicatorFormName}-output`}
            name={indicatorFormName}
            color="primary"
          />
        );

      case "Numeric":
        return (
          <Box className={classes.numericRow}>
            <AmoTextField
              disabled={disabled || getOutputFormValues("noOutputsApply")}
              control={outputControl}
              id={`${indicatorFormName}-output`}
              name={indicatorFormName}
              onChangeTrigger={() =>
                triggerOutputValidation(`${indicatorFormName}-unit`)
              }
              className={classes.inputContainer}
              numberFormatProps={{
                allowNegative: false,
                decimalScale: getDecimals(indicatorData.validationTypeName),
              }}
              characterLimit="18"
              hideCharacterCount
            />
            {showLanesField && (
              <AmoTextField
                disabled={disabled || getOutputFormValues("noOutputsApply")}
                control={outputControl}
                id={`${indicatorFormName}-multiplier`}
                name={`${indicatorFormName}-multiplier`}
                className={classes.unitContainer}
                numberFormatProps={{
                  allowNegative: false,
                  decimalScale: 0,
                }}
                label="Number of Lanes"
              />
            )}
            {currentUnitGroup !== null &&
              indicatorData.indicatorUnitTypeId !==
                unitCategoryTypes.numeric && (
                <AmoTextField
                  control={outputControl}
                  disabled={disabled || getOutputFormValues("noOutputsApply")}
                  select
                  id={`${indicatorFormName}-unit-output`}
                  name={`${indicatorFormName}-unit`}
                  className={classes.unitContainer}
                  SelectProps={{ classes: { select: classes.selectBox } }}
                  label="Unit"
                  onChangeTrigger={() =>
                    triggerOutputValidation([
                      indicatorFormName,
                      `${indicatorFormName}-multiplier`,
                    ])
                  }
                  InputProps={{
                    endAdornment:
                      currentUnitId && !disabled ? (
                        <InputAdornment position="start">
                          <IconButton
                            id={`${indicatorFormName}-unit-clear`}
                            data-testid={`${indicatorFormName}-unitClearTest`}
                            size="small"
                            onClick={() =>
                              handleClearUnitDropdown(indicatorFormName)
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                >
                  {currentUnitGroup
                    .filter(
                      (unit) =>
                        isLocalRoadsLength ||
                        unit.id !== lengthUnitTypes.laneKilometres
                    )
                    .map((value) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                </AmoTextField>
              )}
          </Box>
        );

      case "List":
        return (
          <AmoTextField
            control={outputControl}
            disabled={disabled || getOutputFormValues("noOutputsApply")}
            select
            id={`${indicatorFormName}-output`}
            name={indicatorFormName}
            className={classes.inputContainer}
          >
            {indicatorData.indicatorValues.map((value) => (
              <MenuItem key={`${value}-${indicatorData.id}`} value={value}>
                {value}
              </MenuItem>
            ))}
          </AmoTextField>
        );

      default:
        return null;
    }
  };

  // creates the proper unit dropdown array for each input associated with a unit type
  const setUnitData = () => {
    const unitArrayGroups = {};

    for (const data of outputData) {
      const indicatorData = data.indicators;

      for (const indicator of indicatorData) {
        const savedData =
          indicator.savedData.id !== null ? indicator.savedData : null;
        const unitTypeId = indicator.indicatorUnitTypeId;
        const saveId = savedData !== null ? savedData.id : "new";

        if (unitTypeId !== null) {
          const indicatorName = `indicator-${indicator.id}-${saveId}-unit`;
          const unitArray = [];
          let referenceCategoryId = null;

          const customUnit = unitData.find(
            (unit) => unit.indicatorId === indicator.id
          );
          if (customUnit && indicator.indicatorUnitTypeId === 5) {
            unitArray.push(customUnit);
          } else {
            for (const unitType of unitData) {
              if (
                unitType.indicatorUnitCategoryTypeId === unitTypeId &&
                referenceCategoryId === null
              ) {
                referenceCategoryId = unitType.indicatorUnitCategoryTypeId;
                break;
              }
            }

            for (const unitType of unitData) {
              if (
                unitType.indicatorUnitCategoryTypeId === referenceCategoryId &&
                !unitType.indicatorId
              ) {
                unitArray.push(unitType);
              }
            }
          }
          unitArrayGroups[indicatorName] = stableSort(
            unitArray,
            sortComparator
          );
        }
      }
    }

    setUnitGroups(unitArrayGroups);
    return unitArrayGroups;
  };

  // if the form has been edited already, this function will repopulate the rest form with the pre-existing values
  // this occurs after the form has been reset after an Additional Category has been added
  const populateRetainedForm = (prevFormValuesRef) => {
    const hasPreviousValues =
      prevFormValuesRef && Object.keys(prevFormValuesRef).length > 0;
    if (!hasPreviousValues) return;

    const formValues = getOutputFormValues();
    const valueKeys = Object.keys(formValues);
    Object.entries(prevFormValuesRef).forEach((values) => {
      if (
        valueKeys.includes(values[0]) &&
        formValues[values[0]] !== values[1]
      ) {
        setOutputValues(values[0], values[1], {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });
  };

  // sets dirty fields on previously saved inputs when they are loaded
  const loadSavedData = (unitGroupData) => {
    const outputValues = {
      ...defaultValues,
      noOutputsApply: secondaryInputs,
    };

    for (const data of outputData) {
      const indicatorData = data.indicators;
      for (const indicator of indicatorData) {
        if (!indicator.savedData?.id) {
          continue;
        }

        const inputName = `indicator-${indicator.id}-${
          indicator.savedData?.id ?? "new"
        }`;

        let savedValue = indicator.savedData.outcomeValue ?? "";
        if (savedValue === "true") {
          savedValue = true;
        }
        if (savedValue === "false") {
          savedValue = false;
        }

        outputValues[inputName] = savedValue;
        outputValues[`${inputName}-multiplier`] =
          indicator.savedData.multiplier;

        if (indicator.indicatorUnitTypeId !== null) {
          const unitGroup = unitGroupData[`${inputName}-unit`];

          const isValidUnit = unitGroup
            .map((item) => item.id)
            .includes(indicator.savedData.unitTypeId);

          outputValues[`${inputName}-unit`] = isValidUnit
            ? indicator.savedData.unitTypeId
            : "";
        }
      }
    }

    if (Object.keys(prevFormValues ?? {}).length > 0) {
      previousFormFields.current = prevFormValues;
    }
    resetOutputs(outputValues);
    setFormWasReseted(true);
  };

  useEffect(() => {
    if (formWasReseted) {
      setFormWasReseted(false);
      triggerOutputValidation();
      if (previousFormFields.current) {
        populateRetainedForm(previousFormFields.current);
        previousFormFields.current = null;
      }
    }
  }, [formWasReseted]);

  useEffect(() => {
    onFormRender(getOutputFormValues);
    const unitGroupData = setUnitData();
    loadSavedData(unitGroupData);
  }, []);

  useEffect(() => {
    validateOutput({ isDirty, isValid });
  }, [isDirty, isValid]);

  useEffect(() => {
    dirtyFieldHook(dirtyFields);
  }, [Object.keys(dirtyFields ?? {}).length]);

  return (
    <>
      <Grid style={{ paddingBottom: "0.5rem" }}>
        {unitGroups !== null && renderData()}
      </Grid>
      <AmoCheckbox
        control={outputControl}
        disabled={disabled}
        label="None of the above apply"
        testId="noOutputCheckbox"
        id="noOutputCheckbox"
        name="noOutputsApply"
        color="primary"
      />
    </>
  );
};

ProjectOutputs.propTypes = {
  disabled: PropTypes.bool.isRequired,
  outputData: PropTypes.arrayOf(outputOptions).isRequired,
  outputSchema: PropTypes.shape().isRequired,
  defaultValues: PropTypes.shape().isRequired,
  validateOutput: PropTypes.func.isRequired,
  unitData: PropTypes.arrayOf(unitDataOptions).isRequired,
  prevFormValues: PropTypes.shape().isRequired,
  dirtyFieldHook: PropTypes.func.isRequired,
  secondaryInputs: PropTypes.bool.isRequired,
  onFormRender: PropTypes.func,
};

ProjectOutputs.defaultProps = {
  onFormRender: () => {},
};

export default ProjectOutputs;
