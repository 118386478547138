import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";

import clsx from "clsx";
import parse from "html-react-parser";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider, Icon, MenuItem } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";

import { projectService } from "api/services/projectService";

import FormInputWrapper from "components/municipal/FormInputWrapper";
import AmoFormActions from "components/AmoFormActions";
import AmoTextField from "components/inputs/AmoTextField";
import AmoDatePicker from "components/inputs/AmoDatePicker";

import colors from "constants/colors";
import {
  formIds,
  projectReportFinancialsCodes as formCodes,
} from "constants/formContentManagement";
import { steps, formStatuses } from "constants/projectReport";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { roleGroups, roles } from "constants/user";

import { useYearContext } from "contexts/YearContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";
import { useMunicipalContext } from "contexts/MunicipalContext";

import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import { Dollars, range } from "utils/number";

import { dateToPayload } from "utils/date";
import { projectKeys } from "./projectQueryKeys";
import ProjectReportFinancialsFundsWidget from "./ProjectReportFinancialsFundsWidget";
import {
  useProjectFinancialsData,
  useProjectFunds,
} from "./projectReportFinancialsHooks";
import { useNavigationHelper } from "hooks/navigationHook";
import DeletionModal from "components/DeletionModal";
import {
  deleteButtonTooltipText,
  deleteType,
} from "functions/projectDeleteMunicipalUtils";
import DeletionType from "enums/deletionType";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  body: {
    flexGrow: 1,
    flexBasis: 0,
    // 0px 80px 0px 80px
    padding: "0rem 5rem 0rem 5rem",
  },
  scrollableContent: {
    overflow: "hidden auto",
  },
  contentPadding: {
    // 0px 24px 0px 24px
    padding: "0rem 1.5rem 0rem 2.5rem",
  },
  fundsAppliedPadding: {
    // 0px 64px 0px 24px
    padding: "0rem 4rem 0rem 1.5rem",
  },
  topMargin: {
    // 24px
    marginTop: "1.5rem",
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    // 8px
    paddingBottom: "0.5rem",
  },
  outlinedButton: {
    backgroundColor: "transparent",
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
  formActions: {
    // 16px
    marginTop: "1rem",
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 32px 0px
    margin: "2rem 0rem",
  },
  warning: {
    "& label.Mui-focused": {
      color: colors.yellow,
    },
    "& legend.Mui-focused": {
      color: colors.yellow,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root:not(.Mui-disabled)": {
      "& fieldset": {
        borderColor: colors.yellow,
      },
      "&:hover fieldset": {
        borderColor: colors.yellow,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.yellow,
      },
      "& .MuiInputAdornment-root button": {
        color: colors.yellow,
      },
    },
  },
  // Remove spinner from number input
  numberTextField: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
}));

/* eslint-disable react/prop-types -- Simple utility component */
const FinancialsFormInputWrapper = ({
  children,
  fieldCode,
  flags,
  formFields,
  fullWidth,
  id,
  showFlag,
  testId,
}) => {
  const { text, tooltipText } = formFields[fieldCode] || {};
  const flag = flags[fieldCode];

  const hasTooltip = !!tooltipText;
  const hasFlag = !!flag && showFlag;

  return (
    <FormInputWrapper
      id={id}
      testId={testId}
      tooltip={hasTooltip}
      tooltipProps={hasTooltip ? { title: text, text: tooltipText } : {}}
      flag={hasFlag}
      flagProps={hasFlag ? { ...flag, title: text } : {}}
      childrenFullWidth={fullWidth}
      justifyContent="center"
    >
      {children}
    </FormInputWrapper>
  );
};
/* eslint-enable */

FinancialsFormInputWrapper.defaultProps = {
  fullWidth: false,
  showFlag: true,
};

/**
 * A form component for editing Project Report Financials
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.projectId - sets the project id used to load the project data, pass 'new' for new project [required]
 * @param {number} props.activeStep - sets the active step of the project report [required]
 * @param {number} props.categoryFormStatus - sets the Category form status (values: @see formStatuses )
 * @param {number} props.resultsFormStatus - sets the Results form status (values: @see formStatuses )
 * @param {object} props.informationData - sets the information data of the project report
 * @param {Function} props.onFormStatusChange - function called when the form status changes (params: { @see formStatuses })
 * @param {Function} props.onNextStepRequest - function called when move to the next step is requested (params: none)
 * @param {Function} props.onUnsavedChanges - function called when project unsaved data changes (params: boolean)
 * @param {Function} props.onProjectDataChange - function called when project data changes (params: financialsData)
 * @param {Function} props.onProjectFundsChange - function called when project funds changes (params: { ccbfFundsTotal, ccbfFunds })
 * @param {boolean} props.isPastFinancingEndDate - boolean to handle control disabling when past Financing End Date.
 * @param {number} props.municipalityClosingBalance - sets the current value of the municipalities annual reporting closing balance.
 * @param {number} props.endOfFinancingDateYear - int to use for individual field display and validation
 * @param {boolean} props.projectDeletionRequested - boolean to handle disabling deletion button if the request was already sent.
 * @param {Function} props.refetchValidationInfo - refetch Validation Info to update projectDeletionRequested value.
 * @returns - The form component
 */
const ProjectReportFinancialsForm = (props) => {
  const {
    projectId,
    activeStep,
    categoryFormStatus,
    resultsFormStatus,
    informationData,
    onFormStatusChange,
    onNextStepRequest,
    onUnsavedChanges,
    onProjectDataChange,
    onProjectFundsChange,
    isPastFinancingEndDate,
    municipalityClosingBalance,
    endOfFinancingDateYear,
    projectDeletionRequested,
    refetchValidationInfo,
  } = props;

  const classes = useStyles();
  const { formFields } = useFormManagement(
    formIds.projectReportFinancials,
    true
  );
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { user: currentUser, hasRoles } = useUserContext();
  const isTreasurerOrDelegate = hasRoles(
    roleGroups.municipal.treasurerOrDelegate
  );
  const { navigateBack } = useNavigationHelper();
  const { contextYear } = useYearContext();
  const hasFinancialsPermission = hasRoles(roleGroups.municipal.financials);
  const { getMunicipality } = useMunicipalContext();

  const mounted = useIsMounted();

  const [flags, setFlags] = useState({});
  const [formStatus, setFormStatus] = useState(formStatuses.untouched);
  const [projectWasSaved, setProjectWasSaved] = useState(false);
  const [ccbfFundsTotal, setCcbfFundsTotal] = useState();
  const [ccbfFunds, setCcbfFunds] = useState([]);
  const [formWasReseted, setFormWasReseted] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const hasMunicipalRole = currentUser.roles.includes(roles.municipal);
  const showCcbfFunding = informationData?.startDate
    ? informationData?.startDate?.year <= contextYear
    : false;

  const getFieldLabel = (code, defaultLabel = "This field") =>
    formFields[code]?.text || defaultLabel;

  // The schema for form validation
  // Query project financials data
  const {
    data: projectResult,
    refetch: refetchProjectData,
    isFetched,
  } = useProjectFinancialsData(projectId);

  // Query project funds for deleteion
  const { data: fundsResult } = useProjectFunds(projectId, false);
  const reportingYear = getMunicipality()?.reportingYear;
  const hasPriorExpenditures = fundsResult?.data?.funds.some(
    ({ year }) => year < reportingYear
  );

  // The form validation schema
  const ProjectFinancialsValidationSchema = useMemo(() => {
    const transformEmptyNumber = (value, originalValue) => {
      if (new Dollars(value).isNaN() && originalValue === "") {
        return null;
      }

      return value;
    };

    // Validation helper for use within yup schema test
    const whenTotalProjectCostSetMaxCcbfBudgeted = (
      totalProjectCost,
      schema
    ) => {
      if (new Dollars(totalProjectCost || 0).eq(0)) {
        return schema;
      }

      return schema.test(
        "total-budgeted-max",
        `${getFieldLabel(
          formCodes.fieldTotalBudgeted
        )} must be less than or equal to the total project cost`,
        (value) => new Dollars(value || 0).le(new Dollars(totalProjectCost))
      );
    };

    const whenCcbfBudgetedSetMinProjectCost = (
      totalCcbfBudgeted,
      totalCcbfFunds,
      schema
    ) => {
      if (
        new Dollars(totalCcbfBudgeted || 0).eq(0) &&
        new Dollars(totalCcbfFunds || 0).eq(0)
      ) {
        return schema;
      }

      if (new Dollars(totalCcbfFunds || 0).gt(totalCcbfBudgeted)) {
        return schema.test(
          "total-cost-min-ccbf-funds",
          `${getFieldLabel(
            formCodes.fieldTotalCost
          )} must be greater than or equal to the total amount of CCBF funds spent`,
          (value) =>
            new Dollars(value || 0).ge(new Dollars(totalCcbfFunds || 0))
        );
      }

      return schema.test(
        "total-cost-min-ccbf-budgeted",
        `${getFieldLabel(
          formCodes.fieldTotalCost
        )} must be greater than or equal to the CCBF budget`,
        (value) =>
          new Dollars(value || 0).ge(new Dollars(totalCcbfBudgeted || 0))
      );
    };

    const whenConstructionEndDateSetMinEndFinancingDate = (
      constructionEndDate,
      isMunicipalUser,
      ccbfFundsLastYear,
      schema
    ) => {
      // Set date to {ccbfFundsFirstYear}-01-01 00:00:00 not included
      const ccbfFundsMinDate = DateTime.local(ccbfFundsLastYear ?? 1);
      const municipalMinDate = DateTime.local(contextYear);

      // if we're already past the financing end date, just return
      if (isPastFinancingEndDate && !flags[formCodes.fieldEndOfFinancing]) {
        return schema;
      }

      if (
        constructionEndDate &&
        constructionEndDate > ccbfFundsMinDate &&
        (constructionEndDate > municipalMinDate || isPastFinancingEndDate)
      ) {
        return schema.min(
          constructionEndDate.set({
            hour: 0,
            minute: 0,
            second: 0,
          }),
          `${getFieldLabel(
            formCodes.fieldEndOfFinancing
          )} must be on or after the end of construction date`
        );
      }

      if (
        isMunicipalUser &&
        municipalMinDate > ccbfFundsMinDate &&
        !isPastFinancingEndDate
      ) {
        return schema.min(
          municipalMinDate,
          `${getFieldLabel(
            formCodes.fieldEndOfFinancing
          )} must be in or after the current year`
        );
      }

      if (!ccbfFundsLastYear) {
        return schema;
      }

      return schema.min(
        ccbfFundsMinDate,
        `${getFieldLabel(
          formCodes.fieldEndOfFinancing
        )} must be in or after the last year in which CCBF funds were applied`
      );
    };

    const checkIfCcbfFundingCausesNegativeBalance = (
      fundsInYear,
      amountForReportingYear
    ) =>
      new Dollars(municipalityClosingBalance || 0)
        .plus(amountForReportingYear)
        .minus(fundsInYear)
        .valueOf();

    const checkIfValueIsZeroForLaterThanCurrentReportingYear = (fundsInYear) =>
      fundsInYear == null ||
      !(
        informationData?.startDate?.year > contextYear &&
        !new Dollars(fundsInYear || 0).eq(0)
      );

    const whenCcbfFundingIsVisibleSetOtherValidations = (
      showCcbfFundingRef,
      schema
    ) => {
      if (!showCcbfFundingRef) {
        return schema;
      }

      return schema
        .typeError(
          `${getFieldLabel(formCodes.fieldFunding)} is not a valid number`
        )
        .test(
          "ccbf-funding-validation-min",
          `${getFieldLabel(
            formCodes.fieldFunding
          )} must be 0 or a positive number`,
          (value) => new Dollars(value || 0).ge(0)
        )
        .test(
          "ccbf-funding-validation",
          function ccbfFundingValidationMaxFunding(value) {
            // in the context of Yup schema validation, the use of this is valid and necessary to access the validation context.
            /* eslint-disable react/no-this-in-sfc */
            const amountForReportingYear =
              fundsResult?.data?.funds.find(
                (item) => item.year === reportingYear
              )?.rawAmount || 0;
            const formatToCurrency = new Dollars(
              municipalityClosingBalance || 0
            )
              .plus(amountForReportingYear)
              .valueOf()
              .toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
            if (
              new Dollars(
                checkIfCcbfFundingCausesNegativeBalance(
                  value || 0,
                  amountForReportingYear
                )
              ).lt(0)
            ) {
              return this.createError({
                message: `You cannot spend more than you have available (${formatToCurrency}).`,
                path: this.path,
              });
            }

            return true;
            /* eslint-enable react/no-this-in-sfc */
          }
        )
        .test(
          "ccbf-funding-validation-later-than-reporting-year",
          "Cannot be greater than 0 for projects starting later than current reporting year",
          checkIfValueIsZeroForLaterThanCurrentReportingYear
        );
    };

    const whenTotalProjectCostSetMaxCcbfFunding = (
      totalProjectCost,
      totalCcbfFunds,
      ccbfFundsCurrentYearAmount,
      schema
    ) => {
      if (new Dollars(totalProjectCost).isNaN()) {
        return schema;
      }

      // Calculate max CCBF Funding for the year
      const maxFunding = new Dollars(totalProjectCost || 0)
        .minus(totalCcbfFunds || 0)
        .plus(ccbfFundsCurrentYearAmount || 0)
        .valueOf();
      return schema.max(
        maxFunding,
        "Total CCBF funding cannot be greater than total project cost"
      );
    };

    // Define the schema
    return yup
      .object()
      .shape(
        {
          ccbfFunding: yup
            .number()
            .nullable()
            .transform(transformEmptyNumber)
            .when(
              "$showCcbfFunding",
              whenCcbfFundingIsVisibleSetOtherValidations
            )
            .when(
              [
                "totalProjectCost",
                "$ccbfFundsTotal",
                "$ccbfFundsCurrentYearAmount",
              ],
              whenTotalProjectCostSetMaxCcbfFunding
            ),
          totalCcbfBudgeted: yup
            .number()
            .nullable()
            .transform(transformEmptyNumber)
            .typeError(
              `${getFieldLabel(
                formCodes.fieldTotalBudgeted
              )} is not a valid number`
            )
            .positive(
              `${getFieldLabel(
                formCodes.fieldTotalBudgeted
              )} must be a positive number`
            )
            .test(
              "totalCcbfBudgeted-max",
              `${getFieldLabel(
                formCodes.fieldTotalBudgeted
              )} must be less than 999,999,999,999,999,999`,
              (value) =>
                new Dollars(value || 0).lt(new Dollars("999999999999999999"))
            )
            .when("totalProjectCost", whenTotalProjectCostSetMaxCcbfBudgeted),
          totalProjectCost: yup
            .number()
            .nullable()
            .transform(transformEmptyNumber)
            .typeError(
              `${getFieldLabel(formCodes.fieldTotalCost)} is not a valid number`
            )
            .positive(
              `${getFieldLabel(
                formCodes.fieldTotalCost
              )} must be a positive number`
            )
            .test(
              "totalProjectCost-max",
              `${getFieldLabel(
                formCodes.fieldTotalCost
              )} must be less than 999,999,999,999,999,999`,
              (value) =>
                new Dollars(value || 0).lt(new Dollars("999999999999999999"))
            )
            .when(
              ["totalCcbfBudgeted", "$ccbfFundsTotal"],
              whenCcbfBudgetedSetMinProjectCost
            ),
          endOfFinancingDate: yup
            .date()
            .nullable()
            .typeError(
              `${getFieldLabel(
                formCodes.fieldEndOfFinancing
              )} is not a valid date (yyyy-mm-dd)`
            )
            .max(
              DateTime.local(2029).minus({ second: 1 }),
              `${getFieldLabel(
                formCodes.fieldEndOfFinancing
              )} must be in or before 2028`
            )
            .when(
              [
                "$constructionEndDate",
                "$isMunicipalUser",
                "$ccbfFundsLastYear",
              ],
              whenConstructionEndDateSetMinEndFinancingDate
            ),
          otherFederalFunds: yup.mixed().oneOf(["", true, false]),
          provincialFunds: yup.mixed().oneOf(["", true, false]),
          stackingLimits: yup.mixed().oneOf(["", true, false]),
          additionalComments: yup
            .string()
            .nullable()
            .test(
              "additional-comments-validation-len",
              "Must be 4500 characters or less",
              (val) => val.length <= 4500
            ),
        },
        [["totalCcbfBudgeted", "totalProjectCost"]]
      )
      .required();
  }, [
    contextYear,
    isPastFinancingEndDate,
    flags,
    municipalityClosingBalance,
    projectResult,
    informationData,
  ]);

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  // NOTE: you should be able to fetch and only render a component with the form hook once fetch is complete.
  const defaultValues = {
    ccbfFunding: "",
    totalCcbfBudgeted: "",
    totalProjectCost: "",
    endOfFinancingDate: null,
    otherFederalFunds: "",
    provincialFunds: "",
    stackingLimits: "",
    additionalComments: "",
  };

  // returns projectDeletionRequested in Deletion Requested or empty string to be in line with the same value from ProjectListPage.jsx
  const projectDeletionRequestedInString = projectDeletionRequested
    ? "Deletion Requested"
    : "";

  const getDeleteType = () => {
    const isRequestDeletion =
      deleteType(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ) === DeletionType.REQUEST;

    return {
      text:
        isRequestDeletion && isTreasurerOrDelegate
          ? "Request Deletion"
          : "Delete Project",
      action: isRequestDeletion ? refetchValidationInfo : navigateBack,
    };
  };

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState,
    trigger,
    watch,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(ProjectFinancialsValidationSchema),
    context: {
      ccbfFundsTotal: ccbfFundsTotal ?? 0,
      ccbfFundsLastYear: ccbfFunds?.length > 0 ? ccbfFunds[0].year : null,
      ccbfFundsCurrentYearAmount:
        ccbfFunds?.length > 0
          ? ccbfFunds?.find((obj) => obj.year === contextYear)?.rawAmount ?? 0
          : 0,
      constructionEndDate: informationData?.constructionEndDate,
      isMunicipalUser: hasMunicipalRole,
      showCcbfFunding,
    },
  });

  useEffect(() => {
    trigger("totalCcbfBudgeted");
    trigger("ccbfFunding");
  }, [watch("totalProjectCost")]);

  useEffect(() => {
    trigger("totalProjectCost");
  }, [watch("totalCcbfBudgeted")]);

  // Must watch otherFederalFunds to show/hide stacking limits based on its value
  const otherFederalFundsValue = watch("otherFederalFunds");

  const { isValid, isSubmitting, isDirty, dirtyFields } = formState;

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    if (projectResult?.data) {
      const projectData = {
        ...projectResult.data,
        endOfFinancingDate: projectResult.data.endOfFinancingDate
          ? DateTime.fromISO(projectResult.data.endOfFinancingDate)
          : null,
        otherFederalFunds: projectResult.data.otherFederalFunds ?? "",
        provincialFunds: projectResult.data.provincialFunds ?? "",
        stackingLimits: projectResult.data.stackingLimits ?? "",
      };
      reset(projectData);
      setFormWasReseted(true);
      setFlags(projectData.flags || {});

      const touched = projectWasTouched(projectData);
      setProjectWasSaved(touched);

      handleFormStatusUpdate(
        getFormStatus(projectData, projectData.flags, touched)
      );

      onProjectDataChange(projectData);
    }
  }, [projectResult?.data]);

  useEffect(() => {
    if (formWasReseted && informationData) {
      setFormWasReseted(false);
      trigger();
    }
  }, [formWasReseted, informationData]);

  useLayoutEffect(() => {
    handleFormStatusUpdate(getFormStatus(getValues(), flags, projectWasSaved));
  }, [categoryFormStatus, isPastFinancingEndDate, endOfFinancingDateYear]);

  const handlePromptConfirmation = async (save, event) => {
    if (save) {
      const isFormValid = await trigger();

      if (!mounted.current) {
        return false;
      }

      if (isFormValid) {
        // Need to call handleSubmit passing the event or null when calling outside a UI interaction
        handleSubmit(saveProjectData)(event);
      } else {
        showSnackbar("Unable to save due to invalid data", snackbarTypes.error);
        return false;
      }
    } else {
      reset();
    }

    return true;
  };

  useEffect(() => {
    if (activeStep === steps.financials) {
      onUnsavedChanges(
        isDirty,
        !isValid,
        isDirty ? handlePromptConfirmation : null
      );
    }
  }, [isDirty, isValid]);

  const booleanIsEmpty = (value) =>
    value === "" || value === null || value === undefined;

  const booleanWasTouched = (value) => value || value === false;

  const projectDataIsIncomplete = (projectRef, isRecentProject = true) =>
    (showCcbfFunding && !isPastFinancingEndDate && !projectRef.ccbfFunding) ||
    !projectRef.totalCcbfBudgeted ||
    !projectRef.totalProjectCost ||
    !projectRef.endOfFinancingDate ||
    (isRecentProject && booleanIsEmpty(projectRef.otherFederalFunds)) ||
    (isRecentProject && booleanIsEmpty(projectRef.provincialFunds)) ||
    (isRecentProject &&
      projectRef.otherFederalFunds &&
      (contextYear >= 2023 || endOfFinancingDateYear >= 2023) &&
      booleanIsEmpty(projectRef.stackingLimits));

  const getFormStatus = (projectRef, flagsRef, projectWasSavedRef) => {
    if (categoryFormStatus === formStatuses.untouched) {
      return formStatuses.disabled;
    }

    if (flagsRef && Object.entries(flagsRef).length > 0) {
      return formStatuses.flagged;
    }

    if (isPastFinancingEndDate) {
      return formStatuses.completed;
    }

    if (projectWasSavedRef) {
      const isRecentProject =
        !endOfFinancingDateYear || endOfFinancingDateYear >= 2023;
      if (projectDataIsIncomplete(projectRef, isRecentProject)) {
        return formStatuses.incomplete;
      }
      return formStatuses.completed;
    }

    return formStatuses.untouched;
  };

  const projectWasTouched = (projectRef) =>
    projectRef?.ccbfFunding ||
    projectRef?.totalCcbfBudgeted ||
    projectRef?.totalProjectCost ||
    projectRef?.endOfFinancingDate ||
    booleanWasTouched(projectRef?.otherFederalFunds) ||
    booleanWasTouched(projectRef?.provincialFunds) ||
    booleanWasTouched(projectRef?.stackingLimits) ||
    projectRef?.additionalComments;

  const handleFormStatusUpdate = (newFormStatus) => {
    if (newFormStatus !== formStatus) {
      setFormStatus(newFormStatus);
      onFormStatusChange(newFormStatus);
    }
  };

  const saveProjectData = async ({
    ccbfFunding,
    totalCcbfBudgeted,
    totalProjectCost,
    endOfFinancingDate,
    otherFederalFunds,
    provincialFunds,
    stackingLimits,
    additionalComments,
  }) => {
    try {
      const payload = { id: projectId };
      const commentsChanged = dirtyFields.additionalComments;
      const ccbfFundingChanged = dirtyFields.ccbfFunding;
      const financialEndDateChanged = dirtyFields.endOfFinancingDate;

      if (!isNaN(ccbfFunding) && ccbfFunding >= 0 && showCcbfFunding) {
        payload.ccbfFunding = ccbfFunding;
      }
      if (totalCcbfBudgeted) {
        payload.totalCcbfBudgeted = totalCcbfBudgeted;
      }
      if (totalProjectCost) {
        payload.totalProjectCost = totalProjectCost;
      }
      if (endOfFinancingDate) {
        // Passing only the iso date (without time)
        payload.endOfFinancingDate = dateToPayload(endOfFinancingDate);
      }
      if (!booleanIsEmpty(otherFederalFunds)) {
        payload.otherFederalFunds = otherFederalFunds;
      }
      if (!booleanIsEmpty(provincialFunds)) {
        payload.provincialFunds = provincialFunds;
      }
      if (!booleanIsEmpty(stackingLimits)) {
        payload.stackingLimits = stackingLimits;
      }
      if (additionalComments) {
        payload.additionalComments = additionalComments;
      }

      await projectService.updateFinancials(payload.id, payload);

      if (!mounted.current) {
        return;
      }

      refetchProjectData();

      showSnackbar("Project updated successfully.");

      // If ccbf funding changed invalidate funds query
      if (ccbfFundingChanged) {
        queryClient.invalidateQueries(projectKeys.projectFunds(projectId));
        queryClient.invalidateQueries(projectKeys.projectValidation(projectId));
      }
      // If additional comments changed invalidate information query
      if (commentsChanged) {
        queryClient.invalidateQueries(
          projectKeys.projectInformation(projectId)
        );
      }
      // if the financing end date has changed, invalidate the validation query
      if (financialEndDateChanged) {
        queryClient.invalidateQueries(projectKeys.projectValidation(projectId));
      }
    } catch {
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const renderFormActions = (resultsFormStatusRef) => [
    {
      testId: "projReportSaveButton",
      label: "Save",
      disabled:
        !isDirty ||
        isSubmitting ||
        !isValid ||
        !hasFinancialsPermission ||
        (!Object.entries(flags).length &&
          isPastFinancingEndDate &&
          !dirtyFields.additionalComments),
      onClick: handleSubmit(saveProjectData),
    },
    {
      testId: "projReportNextButton",
      label: "Next",
      variant: "outlined",
      className: classes.outlinedButton,
      disabled: resultsFormStatusRef === formStatuses.disabled,
      onClick: onNextStepRequest,
    },
    {
      testId: "projReportDeleteButton",
      label: getDeleteType().text,
      variant: "outlined",
      color: "secondary",
      disabled: !isTreasurerOrDelegate || projectDeletionRequested,
      onClick: () => setShowDeletionModal(true),
      tooltipText: deleteButtonTooltipText(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ),
    },
  ];

  const handleProjectFundsChange = (funds) => {
    setCcbfFundsTotal(funds?.ccbfFundsTotal);
    setCcbfFunds(funds?.ccbfFunds ?? []);
    onProjectFundsChange(funds);
  };

  const deleteModalClose = () => {
    setShowDeletionModal(false);
  };

  return (
    <>
      <DeletionModal
        open={showDeletionModal}
        id={parseInt(projectId, 10)}
        closeFunction={deleteModalClose}
        updateFunction={getDeleteType().action}
        deletionType={deleteType(
          isTreasurerOrDelegate,
          hasPriorExpenditures,
          projectDeletionRequestedInString
        )}
      />
      {!isFetched &&
        projectId !== "new" &&
        range(0, 5).map((index) => (
          <Skeleton key={index} width="100%" height="8rem" />
        ))}
      {(isFetched || projectId === "new") && (
        <Grid
          container
          className={classes.root}
          direction="column"
          spacing={0}
          wrap="nowrap"
        >
          <Grid item xs className={classes.scrollableContent}>
            <Grid
              container
              className={classes.body}
              direction="column"
              spacing={0}
              alignItems="stretch"
              wrap="nowrap"
            >
              <Grid item className={classes.contentPadding}>
                <Typography
                  className={classes.fullWidth}
                  variant="body1"
                  component="span"
                >
                  {parse(formFields[formCodes.descriptionForm]?.text || "")}
                </Typography>
              </Grid>
              <Grid item className={classes.contentPadding}>
                <Typography variant="h4" noWrap className={classes.title}>
                  {formFields[formCodes.titleInfo]?.text}
                </Typography>
              </Grid>
              <Grid item className={classes.contentPadding}>
                <Typography
                  className={classes.fullWidth}
                  variant="body1"
                  component="span"
                >
                  {parse(formFields[formCodes.descriptionInfo]?.text || "")}
                </Typography>
              </Grid>
              {showCcbfFunding && (
                <Grid item className={classes.topMargin}>
                  <Grid container spacing={10}>
                    {isPastFinancingEndDate ? (
                      <Grid item xs />
                    ) : (
                      <Grid item xs>
                        <FinancialsFormInputWrapper
                          id="proj-report-ccbf-funding-field-wrapper"
                          testId="projReportCcbfFundingFieldWrapper"
                          fieldCode={formCodes.fieldFunding}
                          formFields={formFields}
                          flags={flags}
                          fullWidth
                          showFlag={false}
                        >
                          <AmoTextField
                            disabled={
                              !hasFinancialsPermission || isPastFinancingEndDate
                            }
                            control={control}
                            id="proj-report-ccbf-funding-field"
                            name="ccbfFunding"
                            testId="projReportCcbfFundingField"
                            className={clsx(
                              classes.fullWidth,
                              classes.numberTextField
                            )}
                            label={getFieldLabel(formCodes.fieldFunding, "")}
                            required
                            pattern={/[^0-9.]+/g}
                            helperText={
                              formFields[formCodes.fieldFunding]?.helperText
                            }
                            numberFormatProps={{ decimalScale: 2 }}
                            isCurrencyField
                          />
                        </FinancialsFormInputWrapper>
                      </Grid>
                    )}
                    <Grid item xs />
                  </Grid>
                </Grid>
              )}
              <Grid item className={classes.topMargin}>
                <FormInputWrapper
                  id="proj-report-ccbf-funding-widget-wrapper"
                  testId="projReportCcbfFundingWidgetWrapper"
                  tooltip={false}
                  flag={!!flags[formCodes.fieldFunding]}
                  flagProps={
                    flags[formCodes.fieldFunding]
                      ? {
                          ...flags[formCodes.fieldFunding],
                          title:
                            formFields[flags[formCodes.fieldFunding]]?.text,
                        }
                      : {}
                  }
                  childrenFullWidth
                >
                  <ProjectReportFinancialsFundsWidget
                    projectId={projectId}
                    title={formFields[formCodes.titleFundsApplied]?.text || ""}
                    onProjectFundsChange={handleProjectFundsChange}
                    warning={!!flags[formCodes.fieldFunding]}
                  />
                </FormInputWrapper>
              </Grid>
              <Grid item>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item>
                <Grid container spacing={10}>
                  <Grid item xs>
                    <FinancialsFormInputWrapper
                      id="proj-report-ccbf-budgeted-field-wrapper"
                      testId="projReportCcbfBudgetedFieldWrapper"
                      fieldCode={formCodes.fieldTotalBudgeted}
                      formFields={formFields}
                      flags={flags}
                      fullWidth
                    >
                      <AmoTextField
                        disabled={
                          !hasFinancialsPermission ||
                          (!flags[formCodes.fieldTotalBudgeted] &&
                            isPastFinancingEndDate)
                        }
                        control={control}
                        id="proj-report-ccbf-budgeted-field"
                        name="totalCcbfBudgeted"
                        testId="projReportCcbfBudgetedField"
                        className={clsx(
                          classes.fullWidth,
                          classes.numberTextField,
                          flags[formCodes.fieldTotalBudgeted]
                            ? classes.warning
                            : null
                        )}
                        label={getFieldLabel(formCodes.fieldTotalBudgeted, "")}
                        required
                        pattern={/[^0-9.]+/g}
                        helperText={
                          formFields[formCodes.fieldTotalBudgeted]?.helperText
                        }
                        numberFormatProps={{ decimalScale: 2 }}
                        isCurrencyField
                      />
                    </FinancialsFormInputWrapper>
                  </Grid>
                  <Grid item xs>
                    <FinancialsFormInputWrapper
                      id="proj-report-total-cost-field-wrapper"
                      testId="projReportTotalCostFieldWrapper"
                      fieldCode={formCodes.fieldTotalCost}
                      formFields={formFields}
                      flags={flags}
                      fullWidth
                    >
                      <AmoTextField
                        disabled={
                          !hasFinancialsPermission ||
                          (!flags[formCodes.fieldTotalCost] &&
                            isPastFinancingEndDate)
                        }
                        control={control}
                        id="proj-report-total-cost-field"
                        name="totalProjectCost"
                        testId="projReportTotalCostField"
                        className={clsx(
                          classes.fullWidth,
                          classes.numberTextField,
                          flags[formCodes.fieldTotalCost]
                            ? classes.warning
                            : null
                        )}
                        label={getFieldLabel(formCodes.fieldTotalCost, "")}
                        required
                        pattern={/[^0-9.]+/g}
                        helperText={
                          formFields[formCodes.fieldTotalCost]?.helperText
                        }
                        numberFormatProps={{ decimalScale: 2 }}
                        isCurrencyField
                      />
                    </FinancialsFormInputWrapper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.topMargin}>
                <Grid container spacing={10}>
                  <Grid item xs>
                    <FinancialsFormInputWrapper
                      id="proj-report-end-financing-field-wrapper"
                      testId="projReportEndFinancingFieldWrapper"
                      fieldCode={formCodes.fieldEndOfFinancing}
                      formFields={formFields}
                      flags={flags}
                      fullWidth
                    >
                      <AmoDatePicker
                        disabled={
                          !hasFinancialsPermission ||
                          (!flags[formCodes.fieldEndOfFinancing] &&
                            isPastFinancingEndDate)
                        }
                        control={control}
                        id="proj-report-end-financing-field"
                        name="endOfFinancingDate"
                        testId="projReportEndFinancingField"
                        className={clsx(
                          classes.fullWidth,
                          flags[formCodes.fieldEndOfFinancing]
                            ? classes.warning
                            : null
                        )}
                        label={getFieldLabel(formCodes.fieldEndOfFinancing, "")}
                        required
                        variant="inline"
                        pickerIcon={
                          <Icon className="material-icons-outlined">
                            calendar_today
                          </Icon>
                        }
                        helperText={
                          formFields[formCodes.fieldEndOfFinancing]?.helperText
                        }
                      />
                    </FinancialsFormInputWrapper>
                  </Grid>
                  <Grid item xs>
                    {(!endOfFinancingDateYear ||
                      endOfFinancingDateYear >= 2023) && (
                      <FinancialsFormInputWrapper
                        id="proj-report-provincial-funds-field-wrapper"
                        testId="projReportProvincialFundsFieldWrapper"
                        fieldCode={formCodes.fieldProvincialFunds}
                        formFields={formFields}
                        flags={flags}
                        fullWidth
                      >
                        <AmoTextField
                          disabled={
                            !hasFinancialsPermission ||
                            (!flags[formCodes.fieldProvincialFunds] &&
                              isPastFinancingEndDate)
                          }
                          control={control}
                          id="proj-report-provincial-funds-field"
                          name="provincialFunds"
                          testId="projReportProvincialFundsField"
                          className={clsx(
                            classes.fullWidth,
                            flags[formCodes.fieldProvincialFunds]
                              ? classes.warning
                              : null
                          )}
                          select
                          label={getFieldLabel(
                            formCodes.fieldProvincialFunds,
                            ""
                          )}
                          variant="outlined"
                          required
                          helperText={
                            formFields[formCodes.fieldProvincialFunds]
                              ?.helperText
                          }
                        >
                          <MenuItem
                            key="projReportProvincialFundsField-true"
                            value
                          >
                            Yes
                          </MenuItem>
                          <MenuItem
                            key="projReportProvincialFundsField-false"
                            value={false}
                          >
                            No
                          </MenuItem>
                        </AmoTextField>
                      </FinancialsFormInputWrapper>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {(!endOfFinancingDateYear || endOfFinancingDateYear >= 2023) && (
                <>
                  <Grid item className={classes.topMargin}>
                    <FinancialsFormInputWrapper
                      id="proj-report-other-federal-funds-field-wrapper"
                      testId="projReportOtherFederalFundsFieldWrapper"
                      fieldCode={formCodes.fieldOtherFunds}
                      formFields={formFields}
                      flags={flags}
                      fullWidth
                    >
                      <AmoTextField
                        disabled={
                          !hasFinancialsPermission ||
                          (!flags[formCodes.fieldOtherFunds] &&
                            isPastFinancingEndDate)
                        }
                        control={control}
                        id="proj-report-other-federal-funds-field"
                        name="otherFederalFunds"
                        testId="projReportOtherFederalFundsField"
                        className={clsx(
                          classes.fullWidth,
                          flags[formCodes.fieldOtherFunds]
                            ? classes.warning
                            : null
                        )}
                        select
                        label={getFieldLabel(formCodes.fieldOtherFunds, "")}
                        variant="outlined"
                        required
                        helperText={
                          formFields[formCodes.fieldOtherFunds]?.helperText
                        }
                      >
                        <MenuItem
                          key="projReportOtherFederalFundsField-true"
                          value
                        >
                          Yes
                        </MenuItem>
                        <MenuItem
                          key="projReportOtherFederalFundsField-false"
                          value={false}
                        >
                          No
                        </MenuItem>
                      </AmoTextField>
                    </FinancialsFormInputWrapper>
                  </Grid>
                  {otherFederalFundsValue &&
                    (contextYear >= 2023 || endOfFinancingDateYear >= 2023) && (
                      <Grid item className={classes.topMargin}>
                        <FinancialsFormInputWrapper
                          id="proj-report-stacking-limits-field-wrapper"
                          testId="projReportStackingLimitsFieldWrapper"
                          fieldCode={formCodes.fieldStackingLimits}
                          formFields={formFields}
                          flags={flags}
                          fullWidth
                        >
                          <AmoTextField
                            disabled={
                              !hasFinancialsPermission ||
                              (!flags[formCodes.fieldStackingLimits] &&
                                isPastFinancingEndDate)
                            }
                            control={control}
                            id="proj-report-stacking-limits-field"
                            name="stackingLimits"
                            testId="projReportStackingLimitsField"
                            className={clsx(
                              classes.fullWidth,
                              flags[formCodes.fieldStackingLimits]
                                ? classes.warning
                                : null
                            )}
                            select
                            label={getFieldLabel(
                              formCodes.fieldStackingLimits,
                              ""
                            )}
                            variant="outlined"
                            required
                            helperText={
                              formFields[formCodes.fieldStackingLimits]
                                ?.helperText
                            }
                          >
                            <MenuItem
                              key="projReportStackingLimitsField-true"
                              value
                            >
                              Yes
                            </MenuItem>
                            <MenuItem
                              key="projReportStackingLimitsField-false"
                              value={false}
                            >
                              No
                            </MenuItem>
                          </AmoTextField>
                        </FinancialsFormInputWrapper>
                      </Grid>
                    )}
                </>
              )}
              <Grid item>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item>
                <FinancialsFormInputWrapper
                  id="proj-report-comments-field-wrapper"
                  testId="projReportCommentsFieldWrapper"
                  fieldCode={formCodes.fieldComments}
                  formFields={formFields}
                  flags={flags}
                  fullWidth
                >
                  <AmoTextField
                    multiline
                    rows={1}
                    rowsMax={10}
                    disabled={
                      !hasFinancialsPermission ||
                      (!flags[formCodes.fieldComments] &&
                        isPastFinancingEndDate)
                    }
                    control={control}
                    id="proj-report-comments-field"
                    name="additionalComments"
                    testId="projReportCommentsField"
                    className={clsx(
                      classes.fullWidth,
                      flags[formCodes.fieldComments] ? classes.warning : null
                    )}
                    label={getFieldLabel(formCodes.fieldComments, "")}
                    helperText={formFields[formCodes.fieldComments]?.helperText}
                    characterLimit={4500}
                  />
                </FinancialsFormInputWrapper>
              </Grid>
            </Grid>
          </Grid>
          {activeStep === steps.financials && (
            <Grid item>
              <AmoFormActions
                className={classes.formActions}
                actions={renderFormActions(resultsFormStatus)}
                spacing="1.25rem"
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

// set the prop-types for this component
ProjectReportFinancialsForm.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  activeStep: PropTypes.oneOf([
    steps.category,
    steps.generalInformation,
    steps.financials,
    steps.communications,
    steps.results,
  ]).isRequired,
  categoryFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]).isRequired,
  resultsFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  informationData: PropTypes.any,
  onFormStatusChange: PropTypes.func,
  onNextStepRequest: PropTypes.func,
  onUnsavedChanges: PropTypes.func,
  onProjectDataChange: PropTypes.func,
  onProjectFundsChange: PropTypes.func,
  isPastFinancingEndDate: PropTypes.bool,
  municipalityClosingBalance: PropTypes.number,
  endOfFinancingDateYear: PropTypes.number,
  projectDeletionRequested: PropTypes.bool,
  refetchValidationInfo: PropTypes.func,
};

ProjectReportFinancialsForm.defaultProps = {
  informationData: {},
  onFormStatusChange: () => {},
  onNextStepRequest: () => {},
  onUnsavedChanges: () => {},
  onProjectDataChange: () => {},
  onProjectFundsChange: () => {},
  isPastFinancingEndDate: false,
  municipalityClosingBalance: 0,
  endOfFinancingDateYear: 0,
  projectDeletionRequested: true,
  refetchValidationInfo: () => {},
};

export default ProjectReportFinancialsForm;
