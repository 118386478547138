import parse from "html-react-parser";

export const getTextWidth = (text, font) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context == null) return 0;
  context.font = font || getComputedStyle(document.body).font;
  return context.measureText(text).width;
};

export const fonts = {
  // Got from running the following in dev tools' console:
  // getComputedStyle(document.getElementsByClassName("MuiTypography-body1")[0]).font
  body1: "16px / 24px Montserrat",
  body1Bold: "700 16px / 24px Montserrat",
};

export const parseBreakLines = (text) =>
  (typeof text === "string" || text instanceof String) && text
    ? parse(`${text}`.split("\n").join("<br/>"))
    : text;
