const validationTypes = {
  decimals0: "0 Decimals",
  decimals1: "1 Decimal",
  decimals2: "2 Decimals",
  decimals3: "3 Decimals",
};

export const getDecimals = (validationType) => {
  switch (validationType) {
    case validationTypes.decimals0:
      return 0;
    case validationTypes.decimals1:
      return 1;
    case validationTypes.decimals2:
      return 2;
    case validationTypes.decimals3:
      return 3;
    default:
      return undefined; // undefined so as to not set the property
  }
};

export const calculationTypes = {
  change: "CHANGE",
  increase: "CHANGEUP",
  decrease: "CHANGEDOWN",
  percentageChange: "PRCNTCHG",
  percentageIncrease: "PRCNTUP",
  percentageDecrease: "PRCNTDOWN",
};

export const percentageCalculationTypes = [
  calculationTypes.percentageChange,
  calculationTypes.percentageIncrease,
  calculationTypes.percentageDecrease,
];

export const changeCalculationTypes = [
  calculationTypes.change,
  calculationTypes.percentageChange,
];
export const increaseCalculationTypes = [
  calculationTypes.increase,
  calculationTypes.percentageIncrease,
];
export const decreaseCalculationTypes = [
  calculationTypes.decrease,
  calculationTypes.percentageDecrease,
];

export const getValidation = (code) => {
  if (changeCalculationTypes.includes(code)) {
    return {
      validation: (before, after) =>
        before && after ? before !== after : true,
      errorMessage: "Cannot be same as before value",
    };
  }
  if (increaseCalculationTypes.includes(code)) {
    return {
      validation: (before, after) =>
        (before || before === 0) && (after || after === 0)
          ? before < after
          : true,
      errorMessage: "Must be greater than before value",
    };
  }
  if (decreaseCalculationTypes.includes(code)) {
    return {
      validation: (before, after) =>
        (before || before === 0) && (after || after === 0)
          ? before > after
          : true,
      errorMessage: "Must be less than before value",
    };
  }
  return {
    validation: () => true,
    errorMessage: "",
  };
};

export const sortComparator = (item1, item2) => {
  const value1 = item1.sortOrder ?? 0;
  const value2 = item2.sortOrder ?? 0;
  if (value1 === value2) return 0;
  return value1 < value2 ? -1 : 1;
};

export const unitCategoryTypes = {
  length: 1,
  area: 2,
  energy: 3,
  volume: 4,
  numeric: 5,
  speed: 6,
  time: 7,
  mass: 8,
};

export const lengthUnitTypes = {
  feet: 1,
  metres: 2,
  kilometres: 3,
  laneKilometres: 4,
  miles: 5,
  millimetres: 23,
  centimetres: 24,
  yards: 31,
  inches: 32,
};

export const areaUnitTypes = {
  squareFeet: 6,
  squareMetres: 7,
  squareKilometres: 8,
  acres: 9,
  hectares: 10,
};

export const energyUnitTypes = {
  megawattHours: 11,
  kilowattHours: 12,
  gigawattHours: 13,
  joules: 14,
  kilojoules: 15,
  megajoules: 25,
  gigajoules: 33,
};

export const volumeUnitTypes = {
  cubicMetres: 16,
  litres: 17,
  megalitres: 18,
  gallons: 19,
};

export const numericUnitType = {
  custom: 22,
};

export const speedUnitType = {
  kmPerHour: 26,
};

export const timeUnitTypes = {
  hours: 27,
  days: 28,
  seconds: 29,
  years: 30,
  months: 34,
  weeks: 35,
  minutes: 36,
};

export const massUnitTypes = {
  tonnes: 20,
  kilograms: 21,
  grams: 37,
  milligrams: 38,
};

export const standardIndicatorUnitTypeId = {
  custom: 22,
};

export const unitTypesByCategory = {
  [unitCategoryTypes.length]: lengthUnitTypes,
  [unitCategoryTypes.area]: areaUnitTypes,
  [unitCategoryTypes.energy]: energyUnitTypes,
  [unitCategoryTypes.volume]: volumeUnitTypes,
  [unitCategoryTypes.numeric]: numericUnitType,
  [unitCategoryTypes.speed]: speedUnitType,
  [unitCategoryTypes.time]: timeUnitTypes,
  [unitCategoryTypes.mass]: massUnitTypes,
};
