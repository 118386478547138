import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    marginBottom: "1.5rem",
  },
  label: {
    fontSize: "0.875rem",
  },
  skeletonWidth: {
    maxWidth: "35rem",
  },
}));

/**
 * This overview component to be used on the Communications - Project Details Page.
 *
 * @param {object} props - object containing props for this component
 * @param {object} props.projectData - project information used by this component
 * @param {boolean} props.isFetching - controls whether the component project data is being fetched
 *
 * @returns {Function} The overview component
 */
const ProjectDetailsOverview = (props) => {
  const { projectData, isFetching } = props;

  const classes = useStyles();

  const loadingTextValue = (isFetchingRef, objectives, scopeOfWork) => {
    if (isFetchingRef) {
      return (
        <Skeleton
          width="100%"
          height="1.75rem"
          variant="rect"
          className={classes.skeletonWidth}
        />
      );
    }
    let descriptionTextValue = "";
    if (scopeOfWork && objectives) {
      descriptionTextValue = `${scopeOfWork}\n\n${objectives}`;
    } else if (scopeOfWork) {
      descriptionTextValue = scopeOfWork;
    } else if (objectives) {
      descriptionTextValue = objectives;
    } else {
      descriptionTextValue = "--";
    }

    return <>{descriptionTextValue}</>;
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        <Grid item>
          <Typography variant="h4" className={classes.title}>
            Overview
          </Typography>
        </Grid>

        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Communications Status
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(
                    isFetching,
                    projectData?.comunnicationStatusName
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Project Review Status
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(isFetching, projectData?.reviewStatusName)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Project Title
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(isFetching, projectData?.title)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Category
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(
                    isFetching,
                    projectData?.categoryName +
                      (projectData?.subCategoryName
                        ? ` - ${projectData.subCategoryName}`
                        : "")
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Municipality
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(isFetching, projectData?.municipalityName)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Project Description
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ whiteSpace: "pre-wrap" }} variant="body1">
                  {loadingTextValue(
                    isFetching,
                    projectData?.objectives,
                    projectData?.scopeOfWork
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// set the prop-types for this component
ProjectDetailsOverview.propTypes = {
  projectData: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool,
};

ProjectDetailsOverview.defaultProps = {
  isFetching: false,
};

export default ProjectDetailsOverview;
