import DeletionType from "enums/deletionType";
import { projectReportStatuses } from "constants/amoTableDropdownOptions";

export const deleteType = (
  isTreasurerOrDelegate,
  isPriorExpenditures,
  reportStatus
) => {
  if (isPriorExpenditures) {
    return DeletionType.REQUEST;
  }
  if (reportStatus === projectReportStatuses["Deletion Requested"]) {
    return DeletionType.REQUEST;
  }
  return DeletionType.DELETION;
};

export const deleteButtonTooltipText = (
  isTreasurerOrDelegate,
  isPriorExpenditures,
  reportStatus
) => {
  if (reportStatus === projectReportStatuses["Deletion Requested"]) {
    return "A deletion request is pending review";
  }
  if (!isTreasurerOrDelegate && isPriorExpenditures) {
    return "Only treasurers and their delegates can request project deletion";
  }
  if (!isTreasurerOrDelegate && !isPriorExpenditures) {
    return "Only treasurers and their delegates can delete projects";
  }
  return "";
};
