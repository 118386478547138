import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import { routes } from "constants/routes";
import AmoTable from "components/table/AmoTable";
import { getSortingComparator, stableSort } from "utils/data";
import { DateTime } from "luxon";
import { announcementsService } from "api/services/announcementService";
import { dateFormats } from "constants/dateFormats";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { tableDefaultActions } from "constants/amoTableConstants";
import ListPageWrapper from "components/ListPageWrapper";
import AmoConfirmPopover from "components/AmoConfirmPopover";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.black,
  },
  actionIcon: {
    fontSize: "1.5rem",
  },
}));

/**
 * Announcement List Page.
 *
 * @returns {React.Component} Announcement List Page component.
 */
const AnnouncementsListPage = () => {
  const classes = useStyles();
  const mounted = useIsMounted();
  const { showSnackbar } = useSnackbar();
  const { search } = useLocation();
  const history = useHistory();

  const [announcements, setAnnouncements] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [idToDelete, setIdToDelete] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const tableColumns = [
    {
      fieldKey: "creationDate",
      label: "Date",
      widthPerc: 15,
    },
    {
      fieldKey: "title",
      label: "Title",
      widthPerc: 25,
    },
    {
      fieldKey: "lastEdited",
      label: "Last Edited",
      widthPerc: 25,
    },
    {
      fieldKey: "published",
      label: "Published?",
      widthPerc: 20,
    },
    {
      fieldKey: "actions",
      hideHeader: true,
      widthPerc: 15,
      customRender: ({ id }) => (
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <IconButton
              data-testid={`announcementsTableEditButton${id}`}
              component={Link}
              to={`${routes.contentManagement.announcements.edit.replace(
                ":announcementId",
                id
              )}${search}`}
            >
              <EditOutlined color="primary" className={classes.actionIcon} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              data-testid={`announcementsTableRemoveButton${id}`}
              onClick={() => setIdToDelete(id)}
            >
              <DeleteOutline color="secondary" className={classes.actionIcon} />
            </IconButton>
          </Grid>
        </Grid>
      ),
      isCustom: true,
    },
  ];

  const getData = async () => {
    try {
      const { data } = await announcementsService.getAll();

      if (!mounted.current) {
        return;
      }

      const mappedResult = data.map((item) => ({
        ...item,
        creationDate: DateTime.fromISO(item.creationDate).toFormat(
          dateFormats.isoDate
        ),
        lastEdited: `${DateTime.fromISO(item.updateDate).toFormat(
          dateFormats.isoDateAndTime
        )} by ${item.updatedByFullName}`,
        published: item.publish ? "Yes" : "No",
      }));
      setAnnouncements(mappedResult);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }

    setIsLoaded(true);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      // If the data is loaded, sort the forms
      const sortedResult = stableSort(
        announcements,
        getSortingComparator(
          order,
          orderBy === "nameComponent" ? "name" : orderBy
        )
      );

      setAnnouncements(sortedResult);
    }
  }, [isLoaded, order, orderBy]);

  const removeAnnouncement = async () => {
    try {
      await announcementsService.remove(idToDelete);

      if (!mounted.current) {
        return;
      }

      setIdToDelete(null);
      showSnackbar("Successfully deleted");
      getData();
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const closeRemovePopover = () => {
    setIdToDelete(null);
  };

  const handleRequestSort = (fieldKey, newOrder) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);
  };

  const tableActions = {
    actions: [tableDefaultActions.new],
    actionsCustomConfigs: {
      [tableDefaultActions.new]: {
        label: "New Announcement",
        onClick: () => {
          history.push(
            `${routes.contentManagement.announcements.new}${search}`
          );
        },
        style: { minWidth: "15.813rem" },
      },
    },
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography gutterBottom className={classes.title} variant="h1">
            Announcements
          </Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <AmoTable
            id="announcements-table"
            testId="announcementsTable"
            items={announcements}
            columns={tableColumns}
            defaultOrderBy="lastEdited"
            defaultOrder="desc"
            onRequestSort={handleRequestSort}
            shouldUseQueryString
            disableFilters
            actionsProps={tableActions}
          />
        </Grid>
      </Grid>
      <AmoConfirmPopover
        open={idToDelete}
        variant="default"
        yesButtonVariant="contained"
        yesButtonText="Yes"
        yesButtonFunction={removeAnnouncement}
        closePopover={closeRemovePopover}
        noButtonVariant="outlined"
        noButtonText="Cancel"
        noButtonFunction={closeRemovePopover}
        title="Remove Announcement"
        text="Are you sure you want to delete the selected announcement?"
      />
    </ListPageWrapper>
  );
};

export default AnnouncementsListPage;
