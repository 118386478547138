import { useQuery } from "react-query";
import { projectService } from "api/services/projectService";
import { fixedRound } from "utils/number";
import { getDecimals } from "constants/indicatorConstants";

// Query Keys
import { resultsLogQueryKeys } from "./resultsLogQueryKeys";

/**
 * Store server's state of results reporting log
 *
 * @param {number} year The year of the results being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useResultsReportingLog = (year) =>
  useQuery(
    resultsLogQueryKeys.resultsLog(year),
    () => projectService.getResultsReportingLog(year),
    {
      select: ({ data }) =>
        data.map((result) => ({
          ...result,
          numericValue: fixedRound(
            result.numericValue,
            getDecimals(result.validationTypeName)
          ),
          beforeValue: fixedRound(
            result.beforeValue,
            getDecimals(result.validationTypeName)
          ),
          afterValue: fixedRound(
            result.afterValue,
            getDecimals(result.validationTypeName)
          ),
          description: [result.scopeOfWork, result.objectives]
            .filter(Boolean)
            .join(" "),
        })),
      enabled: !!year,
    }
  );
