export const displayTypes = {
  text: "text",
  date: "date",
  boolean: "boolean",
  number: "number",
  currency: "currency",
  dropdown: "dropdown",
  component: "component",
};

export const displaySubTypes = {
  differentFilterAndItems: "differentFilterAndItems",
};

export const displayTypesArray = Object.values(displayTypes);

export const tableDefaultActions = {
  new: "new",
  search: "search",
  clearSearch: "clearSearch",
  export: "export",
};

export const tableDefaultActionsConfigs = {
  [tableDefaultActions.new]: {
    label: "New",
    onClick: () => {},
  },
  [tableDefaultActions.search]: {
    onClick: () => {},
  },
  [tableDefaultActions.clearSearch]: {
    onClick: () => {},
  },
  [tableDefaultActions.export]: {
    onClick: () => {},
  },
};
