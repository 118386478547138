import { Api } from "../api";
import { AxiosResponse } from "axios";

const api = new Api("api/Projects");

/**
 * @typedef {{
 *  description: string,
 *  groupName: string,
 *  id: number,
 *  indicatorType: string,
 *  indicatorUnitTypeId: number,
 *  indicatorValues: Array,
 *  isBeforeAfter: boolean,
 *  tooltipText: string }} Indicators
 */

/**
 * @typedef {{
 *  categoryId: number,
 *  categoryNameText: string,
 *  indicators: Indicators[] }} Outputs
 */

/**
 * @typedef {{
 *  categoryId: number,
 *  categoryNameText: string,
 *  indicators: Indicators[] }} Outcomes
 */

/**
 * @typedef {{
 *  outputs: Outputs[],
 *  outcomes: Outcomes[] }} ProjectResultIndicators
 */

export const projectReportingResultsService = {
  /**
   * @param projectId
   * @returns {Promise<AxiosResponse<ProjectResultIndicators[]>>}
   */
  getProjectResultIndicators: (projectId) =>
    api.axios.get(`/${projectId}/results/indicators`),

  saveBrandNewProjectResults: (projectId, payload) =>
    api.axios.post(`/${projectId}/results`, payload),

  updateProjectResults: (projectId, payload) =>
    api.axios.put(`/${projectId}/results`, payload),
};
