import { useQuery } from "react-query";
import reviewTypes from "constants/reviewTypes";
import { historyLogKeys } from "./historyLogKeys";
import { reviewService } from "api/services/reviewsService";
import { projectService } from "api/services/projectService";

/**
 * Store server's state of history logs
 *
 * @param {string} type The type of the history being fetched
 * @param {string} objectId The id of the history object being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useHistoryLogs = (type, objectId) => {
  const key = historyLogKeys.historyLogs(type, objectId);
  let asyncFunc;

  if (type === reviewTypes.communications) {
    asyncFunc = () => projectService.getCommunicationsHistory(objectId);
  } else {
    asyncFunc = () => reviewService.getHistoryLogs(objectId);
  }
  return useQuery(key, asyncFunc, {
    select: ({ data }) => data,
  });
};
