import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  MenuItem,
  Divider,
  TextField,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import colors from "constants/colors";
import { useSnackbar } from "contexts/SnackbarContext";
import { errorMessages } from "constants/errorMessages";
import { snackbarTypes } from "constants/snackbar";
import { communicationStatusIds } from "constants/communicationStatusIds";
import ConciseViewTable from "./ConciseViewTable";
import DetailedViewTable from "./DetailedViewTable";
import { useProjectList } from "./ProjectListHooks";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";
import { useLocation, useHistory } from "react-router-dom";
import { tableDefaultActions } from "constants/amoTableConstants";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontSize: "2.25rem",
    fontWeight: 700,
  },
  tabText: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: colors.green.main,
    cursor: "pointer",
  },
  selectedText: {
    color: "black",
    borderBottom: `2px solid ${colors.green.main}`,
  },
  selectBox: {
    minWidth: "100px",
  },
  dropdownContainer: {
    width: "16rem",
  },
}));

/**
 * The Communications Project List Page.
 *
 * @returns {React.Component} Communications Project List page
 */

const ProjectListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const location = useLocation();
  const history = useHistory();
  const [selectedData, setSelectedData] = useState([]);
  const [dropdownData, setDropDownData] = useState([]);
  const [enableClick, setEnableClick] = useState(true);
  const [status, setStatus] = useConditionalQueryParams(
    "status",
    "number",
    0,
    true
  );

  const ConciseTab = 0;
  const DetailedTab = 1;

  const [selectedTab, setSelectedTab] = useConditionalQueryParams(
    "selectedTab",
    "number",
    ConciseTab,
    true
  );

  const { data: projectList, isFetching } = useProjectList();

  // function that get the mock data for now
  const getData = async () => {
    try {
      if (projectList) {
        createDropdownValues(projectList);
      }
    } catch {
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  // filters all the data based on the communication id that is currently selected - this filtered data will be shown in the table
  const filterCommunicationIds = (dataArray) => {
    // check if status is All
    if (status === 0) {
      setSelectedData(dataArray);
    } else {
      const filteredArray = dataArray.filter(
        (project) => project.communicationStatusId === status
      );
      setSelectedData(filteredArray);
    }
  };

  // creates an array of dropdown values that are used in the dropdown component
  const createDropdownValues = (dataArray) => {
    const idKeys = Object.keys(communicationStatusIds);
    const dropdownArray = [];

    for (const key of idKeys) {
      const currentKey = Number(key);
      // check if status is All and add all value to dropdown values
      if (currentKey === 0) {
        dropdownArray.push({
          id: 0,
          name: `${communicationStatusIds[0]} (${dataArray.length})`,
        });
      } else {
        const filteredArray = dataArray.filter(
          (project) => project.communicationStatusId === currentKey
        );

        const communicationStatusName = `${communicationStatusIds[currentKey]} (${filteredArray.length})`;

        const dropdownObject = {
          id: currentKey,
          name: communicationStatusName,
        };

        dropdownArray.push(dropdownObject);
      }
    }

    setDropDownData(dropdownArray);
  };

  // gets data on page load
  useEffect(() => {
    getData();
  }, [projectList]);

  // calls the filtering function every time a new status value is selected from the dropdown
  useEffect(() => {
    if (projectList?.length > 0) {
      filterCommunicationIds(projectList);
    }
  }, [status, projectList]);

  const handleItemClick = (item) => {
    if (enableClick) {
      history.push(
        `${`/communications/projects/${item.id}`}${location.search}`
      );
    }
  };

  const statusDropdown = (
    <TextField
      select
      variant="outlined"
      id="communicationStatus"
      name="status"
      label="Status"
      className={classes.dropdownContainer}
      SelectProps={{ classes: { select: classes.selectBox } }}
      onChange={(event) => setStatus(event.target.value)}
      value={!dropdownData.length ? "" : status}
      onFocus={() => setEnableClick(false)}
      onBlur={() => setEnableClick(true)}
      disabled={!dropdownData?.length || isFetching}
    >
      {dropdownData?.map((value) => (
        <MenuItem
          key={value.id}
          value={value.id}
          onKeyDown={(event) => {
            if (!["ArrowUp", "ArrowDown"].includes(event.key)) {
              setEnableClick(true);
            }
          }}
          onFocus={() => setEnableClick(false)}
        >
          {value.name}
        </MenuItem>
      ))}
    </TextField>
  );

  const tableActions = {
    actions: ["statusDropdown", tableDefaultActions.search],
    customActionsComponents: {
      statusDropdown,
    },
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        alignContent="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography className={classes.titleText}>Project List</Typography>
        </Grid>
        <Divider />
        <Grid
          item
          xs={12}
          container
          direction="row"
          style={{ paddingLeft: "1rem", marginTop: "1rem" }}
          justifyContent="flex"
        >
          <Grid item>
            <Button variant="text" onClick={() => setSelectedTab(ConciseTab)}>
              <Typography
                className={clsx(
                  classes.tabText,
                  selectedTab === ConciseTab && classes.selectedText
                )}
              >
                Concise
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={() => setSelectedTab(DetailedTab)}>
              <Typography
                className={clsx(
                  classes.tabText,
                  selectedTab === DetailedTab && classes.selectedText
                )}
              >
                Detailed
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {selectedTab === ConciseTab && (
            <ConciseViewTable
              tableData={selectedData}
              enableClick={enableClick}
              handleItemClick={handleItemClick}
              tableActions={tableActions}
              isLoading={isFetching}
            />
          )}
          {selectedTab === DetailedTab && (
            <DetailedViewTable
              tableData={selectedData}
              enableClick={enableClick}
              handleItemClick={handleItemClick}
              tableActions={tableActions}
              isLoading={isFetching}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default ProjectListPage;
