/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, createContext } from "react";
import PropTypes from "prop-types";
import { AuthProvider } from "oidc-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { UserManager, WebStorageStateStore } from "oidc-client";
import { useHistory } from "react-router-dom";
import { oidcOptions } from "../constants/oidcOptions";

/**
 * The base ID server context.
 */
const IdSrvContext = createContext(null);

/**
 * React's context hook.
 *
 * @returns {any} The current value for the ID server context.
 */
const useIdSrvContext = () => useContext(IdSrvContext);

/**
 * An ID server context wrapper, provides context to children.
 *
 * @param {Node} {children}
 * @returns {Element} Wrapped children, that will now have access to the ID server context.
 */
const IdSrvProvider = ({ children }) => {
  const history = useHistory();

  const afterSignIn = (returnedUser) => {
    // redirect the user to the page they requested
    if (returnedUser) {
      history.replace(returnedUser?.state?.userUrl);
    }
  };

  const oidcConfig = {
    userManager: new UserManager({
      authority: oidcOptions.authority,
      client_id: "gastax.app",
      redirect_uri: oidcOptions.redirectUri,
      post_logout_redirect_uri: oidcOptions.postLogoutRedirectUri,
      response_type: "code",
      loadUserInfo: true,
      automaticSilentRenew: true,
      scope: "openid profile roles gastax.api",
      stateStore: new WebStorageStateStore({ store: window.localStorage }),
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    }),
    autoSignIn: false,
    onSignIn: afterSignIn,
  };

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};

IdSrvProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useIdSrvContext, IdSrvProvider };
