export const projectReviewStatusIds = {
  incomplete: 1,
  pending: 2,
  updated: 3,
  approved: 4,
  contacted: 5,
  toBeContacted: 6,
  toBeRevised: 7,
  toBeDiscussed: 8,
};
