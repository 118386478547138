import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  permalink: string,
 *  title: string }} HelpTitle
 */

/**
 * @typedef {{
 *  id: number,
 *  permalink: string,
 *  question: string }} FaqQuestion
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string }} HelpFile
 */

/**
 * @typedef {{
 *  id: number,
 *  title: string,
 *  content: string,
 *  isPublished: boolean,
 *  files: HelpFile[] }} HelpContent
 */

/**
 * @typedef {{
 *  id: number,
 *  permalink: string,
 *  question: string,
 *  answer: string,
 *  isPublished: boolean }} FaqContent
 */

// Get title lists
const helpTitlesApi = new Api("api/HelpPages/titles");
const faqTitlesApi = new Api("api/Faq/titles");

// Get content
const helpContentApi = new Api("api/HelpPages/");
const faqContentApi = new Api("api/FAQ/");

export const municipalHelpService = {
  /**
   * @returns {Promise<AxiosResponse<HelpTitle[]>>}
   */
  getAllHelpTitles: () => helpTitlesApi.get(),

  /**
   * @param {string} filter
   * @returns {Promise<AxiosResponse<FaqQuestion[]>>}
   */
  getAllFaqTitles: (filter) => faqTitlesApi.get({ filter }),

  /**
   * @param {string} id
   *
   * @returns {Promise<AxiosResponse<HelpContent[]>>}
   */
  getHelpById: (id) => helpContentApi.getById(id),

  /**
   * @param {string} id
   *
   * @returns {Promise<AxiosResponse<FaqContent[]>>}
   */
  getFaqById: (id) => faqContentApi.getById(id),
};
