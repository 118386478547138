import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { calculateRawChange } from "utils/changeCalculations";
import { getReportedValueSummary } from "utils/indicator";

/**
 * Component to display project outcome specific data
 *
 * @param {object} props
 * @returns {Function} The ProjectOutcome component
 */
const ProjectOutcome = (props) => {
  const { outcome } = props;
  const {
    value,
    before,
    after,
    description,
    standardUnitAbbreviation,
    calculationCode,
    indicatorUnitTypeId,
    reportedValue,
    reportedBefore,
    reportedAfter,
    reportedMultiplier,
    reportedUnitAbbreviation,
    categoryId,
    subcategoryId,
  } = outcome;

  const isBeforeAfter = before && after;
  const unitAbbreviation = standardUnitAbbreviation
    ? ` ${standardUnitAbbreviation}`
    : "";

  const getReportedValueText = (valueRef) => {
    if (standardUnitAbbreviation !== reportedUnitAbbreviation) {
      return getReportedValueSummary(
        categoryId,
        subcategoryId,
        indicatorUnitTypeId,
        valueRef,
        reportedUnitAbbreviation,
        reportedMultiplier
      );
    }

    return "";
  };

  const change = calculateRawChange(
    calculationCode,
    +parseFloat(before?.replace(/,/g, "")),
    +parseFloat(after?.replace(/,/g, "")),
    true,
    unitAbbreviation
  );
  const reportedChange = calculateRawChange(
    calculationCode,
    +parseFloat(reportedBefore?.replace(/,/g, "")),
    +parseFloat(reportedAfter?.replace(/,/g, "")),
    true
  );

  return (
    <Grid container direction="column" spacing={3} wrap="nowrap">
      <Grid item>
        <Typography variant="body2">{description}</Typography>
        {isBeforeAfter ? (
          <>
            <Typography>
              Before: {before}
              {unitAbbreviation}
              {getReportedValueText(reportedBefore)}
            </Typography>
            <Typography>
              After: {after}
              {unitAbbreviation}
              {getReportedValueText(reportedAfter)}
            </Typography>
            <Typography>
              Change: {change ?? "NA"}
              {standardUnitAbbreviation !== reportedUnitAbbreviation &&
                (reportedChange
                  ? getReportedValueText(reportedChange)
                  : " (NA)")}
            </Typography>
          </>
        ) : (
          <Typography>
            {value === "true" ? "Yes" : value}
            {unitAbbreviation}
            {getReportedValueText(reportedValue)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

ProjectOutcome.propTypes = {
  outcome: PropTypes.shape().isRequired,
};

export default ProjectOutcome;
