import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, Link } from "react-router-dom";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import { useCommunicationsUserHooks } from "./hooks/userDataHooks";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useSnackbar } from "contexts/SnackbarContext";
import { tableDefaultActions } from "constants/amoTableConstants";
import { snackbarTypes } from "constants/snackbar";
import { exportToExcel } from "utils/export";
import { errorMessages } from "constants/errorMessages";

/**
 * A table component for displaying Communication users
 *
 * @param {object} props
 * @returns {React.Component} The table component
 */
const CommunicationUserTable = (props) => {
  const { id, tableTitleComponent } = props;

  const history = useHistory();
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const { showSnackbar } = useSnackbar();

  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const {
    data: userData,
    isFetching: dataFetching,
  } = useCommunicationsUserHooks();

  const tableColumns = [
    {
      fieldKey: "name",
      label: "Name",
      widthPerc: 20,
      rowColor: "primary",
      rowVariant: "body2",
      customRender: (user) => (
        <Link
          to={`/users/amo/${user.id}${location.search}`}
          ref={(element) => {
            rowRefs.current[user.id] = element;
          }}
          onFocus={() => setFocusedItem({ id: user.id })}
          className={globalClasses.tableActionCell}
        >
          {user.name}
        </Link>
      ),
      isCustom: true,
    },
    { fieldKey: "organization", label: "Organization", widthPerc: 20 },
    { fieldKey: "email", label: "Email", widthPerc: 20 },
    { fieldKey: "phone", label: "Phone #" },
  ];

  const handleItemClick = (user) => {
    history.push(`/users/communication/${user.id}${location.search}`);
  };

  const tableActions = {
    titleComponent: tableTitleComponent,
    actions: [
      tableDefaultActions.new,
      tableDefaultActions.search,
      tableDefaultActions.export,
    ],
    actionsCustomConfigs: {
      [tableDefaultActions.new]: {
        onClick: () => {
          history.push(`/users/communication/new${location.search}`);
        },
      },
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            exportToExcel(
              filteredData.current,
              tableColumns,
              "Communications Staff"
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
      },
    },
  };

  return (
    <AmoTableWithFilters
      id={id}
      testId="communicationUserTable"
      items={dataFetching ? [] : userData}
      columns={tableColumns}
      defaultOrderBy="name"
      enableItemClick
      onItemClick={handleItemClick}
      onFilteredDataChanged={(value) => {
        filteredData.current = value;
      }}
      onScroll={(item) => {
        rowRefs.current?.[item.id]?.focus();
      }}
      focusedItem={focusedItem}
      actionsProps={tableActions}
      isLoading={dataFetching}
    />
  );
};

// set the prop-types for this component
CommunicationUserTable.propTypes = {
  id: PropTypes.string,
  tableTitleComponent: PropTypes.node,
};

CommunicationUserTable.defaultProps = {
  id: "communication-user-table",
  tableTitleComponent: PropTypes.node,
};

export default CommunicationUserTable;
