import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider, Typography, Button } from "@material-ui/core";
import AmoModal from "components/AmoModal";
import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import { useMunicipalityContacts } from "../../pages/amo/project-reviews/projectReviewsHooks";
import { isNullOrWhitespace } from "utils/string";
import { formatToPhoneWithExtension } from "utils/number";
import ContactType from "enums/contactType";

const useStyles = makeStyles((theme) => ({
  email: {
    color: colors.green.main,
    textDecoration: "none",
    justifyContent: "flex-start",
    fontWeight: "bold",
    marginLeft: "-0.375rem",
    paddingTop: "0",
    paddingBottom: "0",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  },
}));

/**
 * @param {object} props - object containing props for this component
 * @param {boolean} props.showModal - controls visibility of the modal
 * @param {boolean} props.closeModalFunction - function to handle the close modal functionality
 * @param {boolean} props.contactInfo - object containing the contact info to display
 * @returns - the manage contact info modal, styled for the AMO project
 */
const ContactInfoModal = (props) => {
  const { showModal, closeModalFunction, municipalityId } = props;

  const classes = useStyles();

  const { data: municipalityContacts } = useMunicipalityContacts(
    municipalityId
  );

  // See more about zoomphonecall on https://developers.zoom.us/docs/zoom-phone/outbound-call/
  const zoomString = (userType) => {
    const contacts = {
      [ContactType.PRIMARY]: municipalityContacts?.primary,
      [ContactType.DELEGATE]: municipalityContacts?.delegate,
      [ContactType.TREASURER]: municipalityContacts?.treasurer,
    };

    const { phone, phoneExtension } = contacts[userType] || {};

    const cleanPhone = phone ? phone.replace(/[^0-9.]+/g, "") : "";
    const cleanExtension = phoneExtension
      ? `?callerid={${phoneExtension}}`
      : "";

    return `zoomphonecall://+1${cleanPhone}${cleanExtension}`;
  };
  return (
    <AmoModal
      data-testid="amoModal"
      open={showModal}
      variant="default"
      title="Contact Information"
      width="35rem"
      showButton
      closePopover={closeModalFunction}
      buttonsLeftSpacer={6}
      buttons={[
        {
          key: "contact-info-close-button",
          label: "Close",
          color: "primary",
          variant: "outlined",
          onClick: closeModalFunction,
        },
      ]}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} />
        <Grid item xs={6}>
          <Typography>
            <b>Municipality:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isNullOrWhitespace(municipalityContacts?.municipalityName)
              ? "--"
              : municipalityContacts?.municipalityName}
          </Typography>
        </Grid>

        <Divider style={{ marginBottom: "1rem", marginTop: "1rem" }} />

        <Grid item xs={6}>
          <Typography>
            <b>Treasurer:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isNullOrWhitespace(municipalityContacts?.treasurer?.name)
              ? "--"
              : municipalityContacts?.treasurer?.name}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            <b>Treasurer Email:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isNullOrWhitespace(municipalityContacts?.treasurer.email) ? (
            <Typography>--</Typography>
          ) : (
            <Button
              className={classes.email}
              align="left"
              href={`mailto:${municipalityContacts?.treasurer.email}`}
              disableRipple
              style={{ backgroundColor: "transparent" }}
            >
              {municipalityContacts?.treasurer.email}
            </Button>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography>
            <b>Treasurer Phone:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isNullOrWhitespace(municipalityContacts?.treasurer?.phone) ? (
            <Typography>--</Typography>
          ) : (
            <Button
              className={classes.email}
              align="left"
              href={zoomString(ContactType.TREASURER)}
              disableRipple
              style={{ backgroundColor: "transparent" }}
            >
              {formatToPhoneWithExtension(
                municipalityContacts?.treasurer?.phone,
                municipalityContacts?.treasurer.phoneExtension
              )}
            </Button>
          )}
        </Grid>

        <Divider style={{ marginBottom: "1rem", marginTop: "1rem" }} />

        <Grid item xs={6}>
          <Typography>
            <b>Treasurer Delegate:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isNullOrWhitespace(municipalityContacts?.delegate.name)
              ? "--"
              : municipalityContacts?.delegate.name}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            <b>Delegate Email:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isNullOrWhitespace(municipalityContacts?.delegate.email) ? (
            <Typography>--</Typography>
          ) : (
            <Button
              className={classes.email}
              align="left"
              href={`mailto:${municipalityContacts?.delegate.email}`}
              disableRipple
              style={{ backgroundColor: "transparent" }}
            >
              {municipalityContacts?.delegate.email}
            </Button>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography>
            <b>Delegate Phone:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isNullOrWhitespace(municipalityContacts?.delegate?.phone) ? (
            <Typography>--</Typography>
          ) : (
            <Button
              className={classes.email}
              align="left"
              href={zoomString(ContactType.DELEGATE)}
              disableRipple
              style={{ backgroundColor: "transparent" }}
            >
              {formatToPhoneWithExtension(
                municipalityContacts?.delegate?.phone,
                municipalityContacts?.delegate?.phoneExtension
              )}
            </Button>
          )}
        </Grid>

        <Divider style={{ marginBottom: "1rem", marginTop: "1rem" }} />

        <Grid item xs={6}>
          <Typography>
            <b>Primary Contact:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isNullOrWhitespace(municipalityContacts?.primary.name)
              ? "--"
              : municipalityContacts?.primary.name}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            <b>Primary Contact Email:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isNullOrWhitespace(municipalityContacts?.primary.email) ? (
            <Typography>--</Typography>
          ) : (
            <Button
              className={classes.email}
              align="left"
              href={`mailto:${municipalityContacts?.primary.email}`}
              disableRipple
              style={{ backgroundColor: "transparent" }}
            >
              {municipalityContacts?.primary.email}
            </Button>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography>
            <b>Primary Contact Phone:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isNullOrWhitespace(municipalityContacts?.primary?.phone) ? (
            <Typography>--</Typography>
          ) : (
            <Button
              className={classes.email}
              align="left"
              href={zoomString(ContactType.PRIMARY)}
              disableRipple
              style={{ backgroundColor: "transparent" }}
            >
              {formatToPhoneWithExtension(
                municipalityContacts?.primary?.phone,
                municipalityContacts?.primary?.phoneExtension
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </AmoModal>
  );
};

ContactInfoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModalFunction: PropTypes.func.isRequired,
  contactInfo: PropTypes.shape({
    municipalityName: PropTypes.string,
    treasurer: {
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    },
    delegate: {
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    },
    primary: {
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    },
  }).isRequired,
  municipalityId: PropTypes.number.isRequired,
};

export default ContactInfoModal;
