import React, { createContext, useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import UnsavedChangesPopover from "components/UnsavedChangesPopover";

/**
 * The base user prompt context.
 */
const UserPromptContext = createContext();

/**
 * React's context hook.
 *
 * @returns {any} The current value for the user prompt context.
 */
const useUserPrompt = () => useContext(UserPromptContext);

/**
 * A user prompt context wrapper, provides context to children.
 *
 * @param {Node} {children}
 * @returns {Element} Wrapped children, that will now have access to the user prompt context.
 */
const UserPromptProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [saveCallback, setSaveCallback] = useState();
  const [closeCallback, setCloseCallback] = useState();
  const [isAsyncClosing, setIsAsyncClosing] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const userPromptCallback = useRef();

  const getUserConfirmation = (message, callback) => {
    /* Shows custom prompt for unsaved changes on form, default is windows.confirm */
    if (message === "unsaved") {
      setOpen(true);
      userPromptCallback.current = callback;
    }
  };

  const closeModal = (result) => {
    setOpen(false);
    if (userPromptCallback.current) {
      userPromptCallback.current(result);
    }
  };

  const handleUnsavedPromptSave = (save, event) => {
    let saveSuccess = true;
    if (saveCallback) {
      saveSuccess = saveCallback(save, event);
    }

    if (saveSuccess) {
      if (!isAsyncClosing) {
        closeModal(true);
      }
    }
  };
  const handleClose = () => {
    if (!isAsyncClosing) {
      closeModal(false);
    }
    if (closeCallback) {
      closeCallback();
    }
  };

  const closeUserPrompt = (result = false) => {
    if (!isAsyncClosing) return;
    closeModal(result);
  };

  const value = {
    getUserConfirmation,
    setSaveCallback,
    setCloseCallback,
    setIsAsyncClosing,
    closeUserPrompt,
    setIsInvalid,
  };

  return (
    <UserPromptContext.Provider value={value}>
      {children}
      <UnsavedChangesPopover
        id="navigation-confirm-unsaved-prompt"
        testId="navigationConfirmUnsavedPrompt"
        open={open}
        onSave={(event) => handleUnsavedPromptSave(true, event)}
        onContinue={(event) => handleUnsavedPromptSave(false, event)}
        onClose={handleClose}
        isInvalid={isInvalid}
      />
    </UserPromptContext.Provider>
  );
};

UserPromptProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useUserPrompt, UserPromptContext, UserPromptProvider };
