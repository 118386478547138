import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid } from "@material-ui/core";
import BankingInformationField from "./BankingInformationField";
import PropTypes from "prop-types";
import { bankingInformationCodes } from "constants/formContentManagement";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  divider: {
    background: "#F1F1F1",
  },
  leftOverviewItem: {
    marginLeft: "0rem",
  },
}));

export const NumberFormatCustom = React.forwardRef((props, ref) => {
  // TODO: Should we pass the inputRef from MUI through to the input component? If not, comment why.
  const { onChange, isNegative, format, inputRef, ...other } = props;
  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
      format={format}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isNegative: PropTypes.bool.isRequired,
  format: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
};

NumberFormatCustom.defaultProps = {
  inputRef: null,
};

/**
 * A form component to use on the banking information page and modal
 *
 * @param {object} props - object containing props for this component
 * @param {object} props.control - react hook form control
 * @param {object} props.getValues - react hook form getValues
 * @param {object} props.bankingInformation - banking information data
 * @param {boolean} props.isEditMode - flag to indicate wheter fields should be read only or editable
 * @param {boolean} props.isModal - flag to adjust style depending on whether this is a modal or not
 * @param {object} props.formFields - object containing the information of the fields in this form
 * @returns {React.Component} The banking information form component
 */
const BankingInformationForm = (props) => {
  const {
    control,
    getValues,
    bankingInformation,
    isEditMode,
    isModal,
    formFields,
  } = props;
  const classes = useStyles();

  return (
    <Grid item container spacing={4}>
      <Grid item container xs={12}>
        <Grid item xs={isModal ? 6 : 5}>
          <BankingInformationField
            name="municipalityName"
            label="Municipality"
            value={bankingInformation.municipalityName}
            control={control}
            overrideClassName={isModal ? classes.leftOverviewItem : ""}
          />
        </Grid>

        <Grid item xs={6}>
          <BankingInformationField
            name="treasurerName"
            label="Treasurer"
            value={bankingInformation.treasurerName}
            control={control}
          />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid item container xs={12}>
        <Grid item xs={isModal ? 6 : 5}>
          <BankingInformationField
            name="contactName"
            label={formFields[bankingInformationCodes.fieldContactName].text}
            value={bankingInformation.contactName}
            editMode={isEditMode}
            control={control}
            overrideClassName={isModal ? classes.leftOverviewItem : ""}
            required
            helperText={
              formFields[bankingInformationCodes.fieldContactName].helperText
            }
            tooltipText={
              formFields[bankingInformationCodes.fieldContactName].tooltipText
            }
          />
        </Grid>

        <Grid item xs={6}>
          <BankingInformationField
            name="contactEmail"
            label={formFields[bankingInformationCodes.fieldContactEmail].text}
            value={bankingInformation.contactEmail}
            editMode={isEditMode}
            control={control}
            required
            helperText={
              formFields[bankingInformationCodes.fieldContactEmail].helperText
            }
            tooltipText={
              formFields[bankingInformationCodes.fieldContactEmail].tooltipText
            }
          />
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={isModal ? 6 : 5}>
          <BankingInformationField
            name="contactPhone"
            label={formFields[bankingInformationCodes.fieldContactPhone].text}
            value={bankingInformation.contactPhone}
            editMode={isEditMode}
            control={control}
            overrideClassName={isModal ? classes.leftOverviewItem : ""}
            required
            helperText={
              formFields[bankingInformationCodes.fieldContactPhone].helperText
            }
            tooltipText={
              formFields[bankingInformationCodes.fieldContactPhone].tooltipText
            }
            phoneFormat
            inputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                format: "###-###-####",
                isNegative: false,
              },
              onBlur: () => {},
            }}
            inputLabelProps={{
              shrink: !["", undefined, null].includes(
                getValues("contactPhone")
              ),
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <BankingInformationField
            name="phoneExtension"
            label={
              formFields[bankingInformationCodes.fieldContactPhoneExtension]
                ?.text
            }
            value={bankingInformation.phoneExtension}
            editMode={isEditMode}
            control={control}
            helperText={
              formFields[bankingInformationCodes.fieldContactPhoneExtension]
                .helperText
            }
            tooltipText={
              formFields[bankingInformationCodes.fieldContactPhoneExtension]
                .tooltipText
            }
            inputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                format: "##########",
                isNegative: false,
              },
              onBlur: () => {},
            }}
            inputLabelProps={{
              shrink: !["", undefined, null].includes(
                getValues("phoneExtension")
              ),
            }}
          />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid item container xs={12}>
        <Grid item xs={isModal ? 6 : 5}>
          <BankingInformationField
            name="institutionName"
            label={
              formFields[bankingInformationCodes.fieldFinancialInstitution].text
            }
            value={bankingInformation.institutionName}
            previousValue={bankingInformation.institutionNameOld}
            editMode={isEditMode}
            control={control}
            overrideClassName={isModal ? classes.leftOverviewItem : ""}
            required
            helperText={
              formFields[bankingInformationCodes.fieldFinancialInstitution]
                .helperText
            }
            tooltipText={
              formFields[bankingInformationCodes.fieldFinancialInstitution]
                .tooltipText
            }
          />
        </Grid>

        <Grid item xs={6}>
          <BankingInformationField
            name="institutionNumber"
            label={
              formFields[bankingInformationCodes.fieldInstitutionNumber].text
            }
            value={bankingInformation.institutionNumber}
            previousValue={bankingInformation.institutionNumberOld}
            editMode={isEditMode}
            control={control}
            required
            helperText={
              formFields[bankingInformationCodes.fieldInstitutionNumber]
                .helperText
            }
            tooltipText={
              formFields[bankingInformationCodes.fieldInstitutionNumber]
                .tooltipText
            }
          />
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={isModal ? 6 : 5}>
          <BankingInformationField
            name="transitNumber"
            label={formFields[bankingInformationCodes.fieldTransitNumber].text}
            value={bankingInformation.transitNumber}
            previousValue={bankingInformation.transitNumberOld}
            editMode={isEditMode}
            control={control}
            overrideClassName={isModal ? classes.leftOverviewItem : ""}
            required
            helperText={
              formFields[bankingInformationCodes.fieldTransitNumber].helperText
            }
            tooltipText={
              formFields[bankingInformationCodes.fieldTransitNumber].tooltipText
            }
          />
        </Grid>

        <Grid item xs={6}>
          <BankingInformationField
            name="accountNumber"
            label={formFields[bankingInformationCodes.fieldAccountNumber].text}
            value={bankingInformation.accountNumber}
            previousValue={bankingInformation.accountNumberOld}
            editMode={isEditMode}
            control={control}
            required
            helperText={
              formFields[bankingInformationCodes.fieldAccountNumber].helperText
            }
            tooltipText={
              formFields[bankingInformationCodes.fieldAccountNumber].tooltipText
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

BankingInformationForm.propTypes = {
  control: PropTypes.shape(),
  getValues: PropTypes.func,
  bankingInformation: PropTypes.shape().isRequired,
  isEditMode: PropTypes.bool,
  isModal: PropTypes.bool,
  formFields: PropTypes.shape().isRequired,
};

BankingInformationForm.defaultProps = {
  isEditMode: false,
  control: {},
  getValues: () => {},
  isModal: false,
};

export default BankingInformationForm;
