import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { useFieldArray, useWatch, useFormContext } from "react-hook-form";
import AmoRichTextEditor from "components/inputs/AmoRichTextEditor";
import PropTypes from "prop-types";
import { RemoveCircleOutline } from "@material-ui/icons";

const defaultQuestionOption = () => ({ value: "" });
export const defaultQuestionOptions = () =>
  [...Array(2)].map(() => defaultQuestionOption());

/**
 * Component to handle Questionnaire's questions' options list behavior
 *
 * @param {object} props
 * @param {number} props.nestIndex The nested index belonging to a question, needed for nested arrays with useFieldArray usage
 * @param {boolean} props.hasLimitedEdition If true, the questionnaire form has limited edition
 *
 * @returns {Function} The questionnaire's questions' options list
 */
const QuestionnaireQuestionOptions = ({ nestIndex, hasLimitedEdition }) => {
  const { clearErrors, control, setError, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `questions[${nestIndex}].options`,
    keyName: "arrayFieldId",
  });

  const questions = useWatch({
    control,
    name: "questions",
    defaultValue: [],
  });

  const removeOption = (index) => {
    cleanLinkedConditionAnswer(index);
    remove(index);
  };

  const onOptionChange = (value, index) => {
    // If has limited edition, can't remove/add an option
    if (hasLimitedEdition) return;

    if (!value) {
      cleanLinkedConditionAnswer(index);
    }
    if (value && index === fields?.length - 1) {
      append(defaultQuestionOption());
    }
  };

  const cleanLinkedConditionAnswer = (optionIndex) => {
    for (let i = 0; i < questions?.length; i += 1) {
      const question = questions?.[i];
      if (
        question.visibilityConditions.some(
          (condition) => condition.questionConditionAnswerIndex === optionIndex
        )
      ) {
        setValue(
          `questions[${i}].visibilityConditions`,
          question.visibilityConditions.map((condition) =>
            condition.questionConditionAnswerIndex === optionIndex
              ? { ...condition, questionConditionAnswerIndex: "" }
              : condition
          )
        );
      }
    }
  };

  return fields?.map((item, index) => (
    <Grid
      key={item.arrayFieldId}
      item
      container
      direction="row"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs>
        <AmoRichTextEditor
          control={control}
          label={`Option ${index + 1}`}
          id={`questionnaire-question-select-option${index}`}
          name={`questions[${nestIndex}].options[${index}].value`}
          testId={`questionnaireQuestionSelectOption${index}`}
          setError={setError}
          clearErrors={clearErrors}
          isSingleLineInput
          onChangeTrigger={(value) => onOptionChange(value, index)}
        />
      </Grid>
      <Grid item xs>
        {!hasLimitedEdition && (
          <IconButton
            onClick={() => removeOption(index)}
            disabled={
              (index === fields.length - 1 &&
                !fields?.[index]?.value &&
                !!fields?.[index - 1]?.value) ||
              fields.length <= 2
            }
            color="secondary"
          >
            <RemoveCircleOutline />
          </IconButton>
        )}
      </Grid>
    </Grid>
  ));
};

QuestionnaireQuestionOptions.propTypes = {
  nestIndex: PropTypes.number.isRequired,
  hasLimitedEdition: PropTypes.bool.isRequired,
};

export default QuestionnaireQuestionOptions;
