import axios from "axios";
import { routes } from "constants/routes";

let signOutFunc = null;
let history = null;
let removeUserFunc = null;
export const setupApiInterceptorConfig = (
  signOutFuncRef,
  historyRef,
  removeUserFuncRef = null
) => {
  signOutFunc = signOutFuncRef;
  history = historyRef;
  removeUserFunc = removeUserFuncRef;
};

export class Api {
  constructor(endpoint = "", redirectOnUnauthorized = true) {
    this.endpoint = endpoint ? `/${endpoint}` : "";
    this.axios = axios.create({
      baseURL: `${process.env.REACT_APP_AUTHORITY}${this.endpoint}`,
      withCredentials: true,
    });

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // check if the response is 401 Unauthorized
        if (error?.response?.status === 401 && signOutFunc) {
          if (signOutFunc && redirectOnUnauthorized) {
            // Signout the user out and redirect to login
            signOutFunc();
          } else if (removeUserFunc && !redirectOnUnauthorized) {
            // Just remove the stored user
            removeUserFunc();
          }
        }

        // check if the response is 403 Forbidden
        if (error?.response?.status === 403 && history) {
          // Redirect to access denied page
          history.replace(routes.accessDenied);
        }
        return Promise.reject(error);
      }
    );
  }

  get(params = undefined) {
    return this.axios.get("", { params });
  }

  getById(id, params = undefined, otherConfigs = undefined) {
    return this.axios.get(`/${id}`, { params, ...(otherConfigs ?? {}) });
  }

  getFile(subroute, params = undefined) {
    return this.axios.get(`/${subroute}`, { responseType: "blob", params });
  }

  post(payload, subroute = "") {
    return this.axios.post(subroute, payload);
  }

  postToReceiveInsuranceFiles(payload, subroute = "") {
    return this.axios.post(subroute, payload, { responseType: "blob" });
  }

  put(id, payload) {
    if (!("id" in payload)) return this.axios.put(`/${id}`, { id, ...payload });
    return this.axios.put(`/${id}`, payload);
  }

  putWithoutId(payload, subroute = "") {
    return this.axios.put(subroute, payload);
  }

  del(id) {
    return this.axios.delete(`/${id}`);
  }

  upload(payload, subroute = "", onUploadProgress) {
    return this.axios.post(subroute, payload, {
      onUploadProgress,
    });
  }
}
