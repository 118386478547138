import {
  makeStyles,
  MenuItem,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { AmoNumberFormat, CurrencyNumberFormat } from "./AmoNumberFormat";

const useStyles = makeStyles((theme) => ({
  selectPlaceholder: {
    color: "#9e9e9e",
    fontFamily: "Roboto",
    lineHeight: "inherit",
  },
}));

/**
 * This component is a customized MUI TextField, built to work with a react-hook-form (RHF) controlled form.
 *
 * To use this and other RHF input components, make sure that the RHF controller object is being passed in from the parent component.
 *
 * The controller object comes from the RHF useForm hook, and it allows the input to be registered, controlled, and validated.
 *
 * @param {object} props
 * @param {any} props.children
 * @param {object} props.control    The react-hook-form control, generated by useForm()
 * @param {any} props.InputProps    MUI props that get passed to the Input component
 * @param {any} props.InputLabelProps    MUI props that get passed to the Input Label component
 * @param {any} props.SelectProps    MUI props that get passed to the Select component
 * @param {object} props.classes    Used for class overrides of the root components
 * @param {string} props.className  Passes in a css class for the TextField
 * @param {boolean} props.disabled  Disables the component when true
 * @param {string} props.label      Label text for the component
 * @param {string} props.id         Field ID
 * @param {string} props.name       The name of the input; react-hook-form uses this to register the input
 * @param {string} props.testId     Maps to data-test-id; renamed on AMO component to avoid issues with targeting tests (maybe unnecessary)
 * @param {boolean} props.required  Sets the required flag for this input (prefer handling this via a yup schema in most cases)
 * @param {boolean} props.select    Textfield becomes a text-select input; expects menu items as children
 * @param {string} props.variant    Sets the MUI variant for this input
 * @param {string} props.fullWidth  Sets the MUI fullWidth property for this input
 * @param {boolean} props.multiline Textfield becomes a TextArea
 * @param {number} props.rows       Sets the initial number of rows for the TextArea
 * @param {number} props.rowsMax    Sets the max number of rows for the TextArea
 * @param {string} props.placeholder Sets placeholder text for this input
 * @param {string} props.focused    Sets focused state for this input
 * @param {string} props.onChangeTrigger   Event that triggers on input change
 * @param {string} props.pattern    Sets format regex pattern to test this input value
 * @param {boolean} props.emptyAsNull    Transforms empty string values to null
 * @param {string} props.selectPlaceholderValue Sets custom select's placeholder value for this input, it can't be null or undefined
 * @param {string} props.helperText - green helper text of the input shown when the input is touched
 * @param {Function} props.clickFunction - any sort of function that will be called by this component onClick
 * @param {Function} props.keyDownFunction - any sort of function that will be called by this component onKeyDown
 * @param {object} props.numberFormatProps - Props to pass to NumberFormat from react-number-format
 * @param {Function} props.onFocus - any sort of function that will be called by this input focused
 * @param {number} props.characterLimit - sets the max length of the text
 * @param {boolean} props.isCurrencyField    Formats input to currency
 * @param {boolean} props.hideCharacterCount If the character countdown should be hidden
 *
 * @returns {Function} A MUI TextField component wrapped with the react-hook-form controller
 */
const AmoTextField = ({
  // children are a well-known prop type in React, which has built-in warnings for type mismatches here
  // eslint-disable-next-line react/prop-types
  children,
  classes,
  control,
  disabled,
  InputProps,
  InputLabelProps,
  SelectProps,
  label,
  id,
  name,
  testId,
  className,
  required,
  select,
  variant,
  fullWidth,
  multiline,
  rows,
  rowsMax,
  placeholder,
  focused,
  pattern,
  emptyAsNull,
  selectPlaceholderValue,
  onChangeTrigger,
  helperText,
  clickFunction,
  keyDownFunction,
  numberFormatProps,
  onFocus,
  characterLimit,
  isCurrencyField,
  hideCharacterCount,
}) => {
  const internalClasses = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const { onBlur: customOnBlur, ...modifiedInputProps } = InputProps || {
    onBlur: () => {},
  };
  const defaultInputProps = { maxLength: characterLimit };
  if (numberFormatProps) {
    modifiedInputProps.inputComponent = AmoNumberFormat;
    modifiedInputProps.inputProps = {
      ...defaultInputProps,
      ...numberFormatProps,
    };
  }
  if (isCurrencyField) {
    modifiedInputProps.inputComponent = CurrencyNumberFormat;
  }
  const modifiedInputLabelProps = (value) =>
    isCurrencyField || numberFormatProps
      ? {
          ...InputLabelProps,
          shrink: value || isFocused,
        }
      : InputLabelProps;

  const showHelperText =
    (characterLimit !== undefined && !hideCharacterCount) || helperText;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { onBlur }, fieldState: { error } }) => (
        <>
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            onFocus={() => {
              setIsFocused(true);
              onFocus();
            }}
            onBlur={() => {
              setIsFocused(false);
              if (customOnBlur) {
                customOnBlur();
              }
              onBlur();
            }}
            onChange={({ target: { value } }) => {
              let newValue =
                ["Before", "After"].includes(placeholder) &&
                value.length > characterLimit
                  ? value.substring(0, characterLimit)
                  : value;
              if (pattern) newValue = newValue.replace(pattern, "");
              if (emptyAsNull && newValue === "") newValue = null;
              field.onChange(newValue);
              onChangeTrigger(newValue);
            }}
            onClick={clickFunction}
            onKeyDown={keyDownFunction}
            id={id}
            data-testid={testId ?? `${id}-test`}
            classes={classes}
            className={className}
            disabled={disabled}
            InputProps={modifiedInputProps}
            InputLabelProps={modifiedInputLabelProps(field.value)}
            SelectProps={SelectProps}
            label={label}
            variant={variant}
            required={required}
            select={select}
            error={!!error}
            FormHelperTextProps={{ component: "div" }}
            helperText={
              error?.message ??
              (isFocused && showHelperText ? (
                <>
                  <Grid container spacing={1}>
                    <Grid item>{helperText}</Grid>
                    {characterLimit !== undefined &&
                      !hideCharacterCount &&
                      (field?.value?.length ?? 0) <= characterLimit && (
                        <Grid item>
                          <Typography variant="caption">
                            {helperText
                              ? `(${
                                  field?.value?.length ?? 0
                                }/${characterLimit})`
                              : `${
                                  field?.value?.length ?? 0
                                }/${characterLimit}`}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                </>
              ) : undefined)
            }
            fullWidth={fullWidth}
            multiline={multiline}
            minRows={rows}
            maxRows={rowsMax}
            placeholder={placeholder}
            focused={focused}
          >
            {select && placeholder && (
              <MenuItem value={selectPlaceholderValue ?? "none"} disabled>
                <Typography className={internalClasses.selectPlaceholder}>
                  {placeholder}
                </Typography>
              </MenuItem>
            )}
            {children}
          </TextField>
        </>
      )}
    />
  );
};

AmoTextField.propTypes = {
  classes: PropTypes.shape(),
  className: PropTypes.string,
  control: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  InputProps: PropTypes.shape(),
  InputLabelProps: PropTypes.shape(),
  SelectProps: PropTypes.shape(),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  select: PropTypes.bool,
  testId: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  placeholder: PropTypes.string,
  focused: PropTypes.bool,
  onChangeTrigger: PropTypes.func,
  pattern: PropTypes.instanceOf(RegExp),
  emptyAsNull: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectPlaceholderValue: PropTypes.any,
  helperText: PropTypes.string,
  clickFunction: PropTypes.func,
  keyDownFunction: PropTypes.func,
  numberFormatProps: PropTypes.shape(),
  onFocus: PropTypes.func,
  characterLimit: PropTypes.number,
  isCurrencyField: PropTypes.bool,
  hideCharacterCount: PropTypes.bool,
};

AmoTextField.defaultProps = {
  classes: undefined,
  className: undefined,
  disabled: false,
  InputProps: undefined,
  InputLabelProps: undefined,
  SelectProps: undefined,
  select: undefined,
  required: false,
  testId: undefined,
  variant: "outlined",
  fullWidth: false,
  multiline: false,
  rows: 3,
  rowsMax: 4,
  placeholder: "",
  focused: undefined,
  onChangeTrigger: () => {},
  pattern: undefined,
  emptyAsNull: false,
  selectPlaceholderValue: null,
  label: null,
  helperText: null,
  clickFunction: () => {},
  keyDownFunction: () => {},
  numberFormatProps: null,
  onFocus: () => {},
  characterLimit: undefined,
  isCurrencyField: false,
  hideCharacterCount: false,
};

export default AmoTextField;
