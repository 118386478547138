// Set up a key factory per feature for a flexible query key reference
export const financialReviewsQueryKeys = {
  financialReviews: (year) => ["financialReviews", year],
  financialReviewYears: ["financialReviewYears"],
  financialReviewData: (municipalityId, year) => [
    ...financialReviewsQueryKeys.financialReviews(year),
    municipalityId,
  ],
  aerChart: (data) => ["aerChart", ...Object.values(data)],
  financialReviewAllocations: ["review", "financial", "allocations"],
};
