import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  title: string,
 *  issue: string }} IssueDetails
 */

/**
 * @typedef {{
 *  requirements: IssueDetails[],
 *  projects: IssueDetails[] }} SubmitValidationResponse
 */

const api = new Api("api/municipalities");
export const submitReportsService = {
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @returns {Promise<AxiosResponse<SubmitValidationResponse>>}
   */
  getAnnualReportStatus: (municipalityId, year) =>
    api.axios.get(`${municipalityId}/report/${year}`),
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @returns {void}
   */
  submitAnnualReport: (municipalityId, year) =>
    api.axios.post(`${municipalityId}/report/${year}`),
};
