import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step, StepButton, Typography, Icon } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import colors from "constants/colors";
import { steps, formStatuses } from "constants/projectReport";

const useStyles = makeStyles((theme) => ({
  yellow: {
    color: colors.yellow,
  },
  grey: {
    color: colors.grey.main,
  },
  stepper: {
    // 70px
    height: "4.375rem",
    backgroundColor: "transparent",
    borderTop: `1px solid ${colors.grey.light}`,
    borderBottom: `1px solid ${colors.grey.light}`,
    padding: 0,
  },
  step: {
    flex: "1 1 auto",
    height: "100%",
  },
  stepButton: {
    height: "3.75rem",
    // 0px 0px 8px 0px
    padding: "0 0 0.5rem 0",
    // 0px 0px -8px 0px
    margin: "0 0 -0.5rem 0",
    "& .MuiStepLabel-label": {
      ...theme.typography.subtitle2,
      color: colors.black,
      lineHeight: 1.375,
    },
    "& .MuiStepLabel-active": {
      ...theme.typography.subtitle1,
      lineHeight: 1.375,
    },
    "& .Mui-disabled .MuiTypography-root:first-child": {
      color: colors.grey.main,
      lineHeight: 1.375,
    },
    "& .MuiStepLabel-labelContainer": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  stepButtonCaption: {
    // 14px
    fontSize: "0.875rem",
    fontStyle: "italic",
    lineHeight: 1,
  },
  underline: {
    height: "0.5rem",
    backgroundColor: colors.green.dark,
    left: 0,
    width: "100%",
    transition: "transform 0.3s ease-in-out",
  },
}));

const StepUnderline = ({ active }) => {
  const classes = useStyles();
  return active ? <div className={classes.underline} /> : null;
};

StepUnderline.propTypes = {
  active: PropTypes.bool.isRequired,
};

/**
 * A stepper component for project report forms
 *
 * @param {object} props - object containing props for this component
 * @param {number} props.activeStep - sets the active step of the component (values: @see steps )
 * @param {number} props.categoryFormStatus - sets the Category form status (values: @see formStatuses )
 * @param {number} props.generalInformationFormStatus - sets the General Information form status (values: @see formStatuses )
 * @param {number} props.financialsFormStatus - sets the Financials form status (values: @see formStatuses )
 * @param {number} props.communicationsFormStatus - sets the Financials form status (values: @see formStatuses )
 * @param {number} props.resultsFormStatus - sets the Results form status (values: @see formStatuses )
 * @param {Function} props.onStepChange - function called when user clicks on an step button (params: { new step })
 * @returns - The stepper component
 */
const ProjectReportStepper = (props) => {
  const {
    categoryFormStatus,
    generalInformationFormStatus,
    financialsFormStatus,
    communicationsFormStatus,
    resultsFormStatus,
    activeStep,
    onStepChange,
  } = props;

  const classes = useStyles();

  const handleStepChange = (step) => {
    if (step !== activeStep) {
      onStepChange(step);
    }
  };

  const renderStepIcon = (status) => {
    if (status === formStatuses.flagged) {
      return (
        <Icon className={clsx("material-icons", classes.yellow)}>flag</Icon>
      );
    }

    if (status === formStatuses.completed || status === formStatuses.changed) {
      return (
        <Icon className="material-icons-outlined" color="primary">
          check_circle_outline
        </Icon>
      );
    }

    return (
      <Icon className={clsx("material-icons-outlined", classes.grey)}>
        circle
      </Icon>
    );
  };

  const renderStep = (id, name, step, formStatus) => (
    <Step
      key={`proj-report-step-${id}`}
      completed={
        formStatus === formStatuses.completed ||
        formStatus === formStatuses.changed
      }
      className={classes.step}
      disabled={formStatus === formStatuses.disabled}
    >
      <StepButton
        id={`proj-report-stepbutton-${id}`}
        data-testid={`projReportStepButton${id}`}
        className={classes.stepButton}
        icon={renderStepIcon(formStatus)}
        optional={
          formStatus === formStatuses.incomplete ? (
            <Typography
              variant="caption"
              color="secondary"
              className={classes.stepButtonCaption}
            >
              Incomplete
            </Typography>
          ) : null
        }
        onClick={() => handleStepChange(step)}
      >
        {name}
      </StepButton>
      <StepUnderline active={activeStep === step} />
    </Step>
  );

  return (
    <>
      <Stepper
        nonLinear
        activeStep={activeStep}
        connector={null}
        className={classes.stepper}
      >
        {renderStep("Category", "Category", steps.category, categoryFormStatus)}
        {renderStep(
          "GeneralInfo",
          "General Information",
          steps.generalInformation,
          generalInformationFormStatus
        )}
        {renderStep(
          "Financials",
          "Financials",
          steps.financials,
          financialsFormStatus
        )}
        {renderStep(
          "Communications",
          "Communications",
          steps.communications,
          communicationsFormStatus
        )}
        {renderStep("Results", "Results", steps.results, resultsFormStatus)}
      </Stepper>
    </>
  );
};

// set the prop-types for this component
ProjectReportStepper.propTypes = {
  activeStep: PropTypes.oneOf([
    steps.category,
    steps.generalInformation,
    steps.financials,
    steps.communications,
    steps.results,
  ]),
  categoryFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]),
  generalInformationFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]),
  financialsFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]),
  communicationsFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]),
  resultsFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]),
  onStepChange: PropTypes.func,
};

ProjectReportStepper.defaultProps = {
  activeStep: steps.category,
  categoryFormStatus: formStatuses.untouched,
  generalInformationFormStatus: formStatuses.disabled,
  financialsFormStatus: formStatuses.disabled,
  resultsFormStatus: formStatuses.disabled,
  communicationsFormStatus: formStatuses.disabled,
  onStepChange: () => {},
};

export default ProjectReportStepper;
