import * as React from "react";
import { Grid, ListItemText, Typography } from "@material-ui/core";
import FileUploadList from "components/FileUploadList";
import ListPageWrapper from "components/ListPageWrapper";
import { snackbarTypes } from "constants/snackbar";
import { useSnackbar } from "contexts/SnackbarContext";
import { errorMessages } from "constants/errorMessages";
import { Fragment, useState } from "react";
import FilterActionsBar from "../FilterActionsBar";
// Used for JSDocs
// eslint-disable-next-line import/named, no-unused-vars
import { SummaryPayload } from "api/services/summaryService";
import { useSummaryStyles } from "../hooks/summaryStylesHook";
import { useUserContext } from "contexts/UserContext";
import { useYearsRange } from "../hooks/yearsRangeHooks";
import { useProjectQuestionnairesData } from "./projectQuestionnairesHooks";
import parse from "html-react-parser";
import { exportQuestionnairesSummary } from "utils/export";
import { useIsMounted } from "hooks/useIsMounted";

import { pdfReportsService } from "api/services/pdfReportService";
import { printFile } from "utils/pdf";
import { questionnaireTypes } from "constants/questionnaireTypes";

const QuestionnairesSummaryPage = () => {
  const { user } = useUserContext();
  const summaryClasses = useSummaryStyles();
  const { showSnackbar } = useSnackbar();

  const mounted = useIsMounted();

  const { data: years } = useYearsRange("questionnaire");

  const [payload, setPayload] = useState(/** @type {SummaryPayload} */ ({}));
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [currentQuestionnaireType, setCurrentQuestionnaireType] = useState(
    questionnaireTypes.assetMgmt
  );
  const { data: questionnaires, isFetched } = useProjectQuestionnairesData(
    payload
  );

  const getPdfFile = async () => {
    try {
      const { data } = await pdfReportsService.getSummaryQuestionnaires(
        user.municipalityId,
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
    return null;
  };

  const handlePrint = async () => {
    setIsLoadingFile(true);

    const pdfFile = await getPdfFile();

    if (!mounted.current) {
      return;
    }

    if (pdfFile) {
      printFile(pdfFile);
    }

    setIsLoadingFile(false);
  };

  const parseBreakLines = (text) =>
    text != null ? parse(`${text}`.split("\n").join("<br/>")) : null;

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography className={summaryClasses.title} variant="h2">
            Questionnaires Summary
          </Typography>
        </Grid>
        <Grid item>
          <FilterActionsBar
            years={years}
            onFilter={({ from, to, questionnaireType }) => {
              setCurrentQuestionnaireType(questionnaireType);
              setPayload({
                municipalityId: user.municipalityId,
                yearStart: from,
                yearEnd: to,
                questionnaireType,
              });
            }}
            onDownload={() => {
              try {
                exportQuestionnairesSummary(
                  questionnaires,
                  currentQuestionnaireType
                );
              } catch (error) {
                showSnackbar(
                  errorMessages.creatingSpreadsheet,
                  snackbarTypes.error
                );
              }
            }}
            onPrint={handlePrint}
            showQuestionnaireType
            disableOptionalButtons={
              !isFetched || !questionnaires?.length || isLoadingFile
            }
            showPrintLoading={isLoadingFile}
          />
        </Grid>
        {isFetched && !questionnaires?.length && (
          <Grid item>
            <Typography>
              No results found for the selected reporting period
            </Typography>
          </Grid>
        )}
        <Grid item className={summaryClasses.list}>
          {questionnaires &&
            questionnaires?.map(({ year, details, attachments }) => (
              <Fragment key={year}>
                <Grid item className={summaryClasses.yearTitle}>
                  <Typography variant="h3" className={summaryClasses.darkGreen}>
                    {year}
                  </Typography>
                </Grid>
                <Grid item className={summaryClasses.divider} />
                {details?.map(({ id, question, answer }) => (
                  <Fragment key={id}>
                    <Grid item className={summaryClasses.listItemWrapper}>
                      <Typography variant="h5">{parse(question)}</Typography>
                      <ListItemText className={summaryClasses.listItemText}>
                        {parseBreakLines(answer)}
                      </ListItemText>
                    </Grid>
                    <Grid item className={summaryClasses.spacer} />
                  </Fragment>
                ))}
                {currentQuestionnaireType === questionnaireTypes.assetMgmt && (
                  <>
                    <Grid item className={summaryClasses.listItemWrapper}>
                      <Typography variant="h5">Attachment(s):</Typography>
                    </Grid>
                    <Grid item className={summaryClasses.smallSpacer} />
                    <Grid item className={summaryClasses.listItemWrapper}>
                      <FileUploadList
                        initialValue={attachments.map(({ id, fileName }) => ({
                          id,
                          name: fileName,
                        }))}
                        readonly
                      />
                    </Grid>
                  </>
                )}
              </Fragment>
            ))}
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default QuestionnairesSummaryPage;
