// Set up a key factory per feature for a flexible query key reference
export const landingPageKeys = {
  dashboard: ["comm", "dashboard"],
  recentComms: () => [...landingPageKeys.dashboard, "recent", "comms"],
  recentUpdates: () => [...landingPageKeys.dashboard, "recent", "updates"],
  projectLocations: (statusId, earliestDate) => [
    ...landingPageKeys.dashboard,
    "projectLocations",
    statusId,
    earliestDate,
  ],
};
