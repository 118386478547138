import * as React from "react";
import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import AmoTable from "./AmoTable";
import { filterData, getSortingComparator, stableSort } from "utils/data";
import { useDebounce } from "@focus21/hooks";
import {
  AmoTableWithFiltersProps,
  AmoTableWithFiltersDefaultProps,
} from "constants/amoTablePropTypes";
import { useTableSearchQuery } from "hooks/tableSearchQueryHook";
import { tableDefaultActions, displayTypes } from "constants/amoTableConstants";

/**
 * A table component that uses AmoTable but handles filters internally, uses almost the same props as AmoTable
 *
 * @param {AmoTableWithFiltersProps} props - object containing props for this component
 *
 * @returns {React.Component} - The table component
 */
const AmoTableWithFilters = (props) => {
  const {
    id,
    items,
    mappedFieldKeys,
    mappedFilterMatchs,
    columns,
    defaultOrder,
    defaultOrderBy,
    enableItemClick,
    uniqueKeyGenerationFields,
    onItemClick,
    onFilteredDataChanged,
    debouncingTime,
    onScroll,
    focusedItem,
    actionsProps,
    isLoading,
    hideTableContent,
    showFilters,
  } = props;

  const cellKeys = columns.map((cell) => cell.fieldKey);
  const { hasFilters, clearFilters } = useTableSearchQuery(id, cellKeys);

  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [internalData, setInternalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  // This state is needed for the useDebounce hook
  const [filtersForDebounce, setFiltersForDebounce] = useState({});

  const filters = useRef({});

  const [internalShowFilters, setInternalShowFilters] = useState(false);
  const onSearchButtonClick = useCallback(() => {
    setInternalShowFilters((prevShowFilters) => !prevShowFilters);

    const { actionsCustomConfigs } = actionsProps ?? {};
    const customSearchClick =
      actionsCustomConfigs?.[tableDefaultActions.search]?.onClick;
    if (customSearchClick) {
      customSearchClick();
    }
  }, [internalShowFilters, actionsProps]);

  const debouncedFilters = useDebounce(
    () => filtersForDebounce,
    [filtersForDebounce],
    debouncingTime
  );

  const tableActionsProps = useMemo(() => {
    const customConfigs = actionsProps?.actionsCustomConfigs ?? {};
    const defaultProps = {
      ...(actionsProps ?? {}),
      actionsCustomConfigs: {
        ...customConfigs,
        [tableDefaultActions.search]: {
          ...customConfigs?.[tableDefaultActions.search],
          onClick: onSearchButtonClick,
        },
        [tableDefaultActions.clearSearch]: {
          onClick: clearFilters,
        },
      },
    };
    const { actions } = defaultProps;

    const newActions = [...actions];
    const searchIndex = actions.indexOf(tableDefaultActions.search);

    if (searchIndex >= 0) {
      if (hasFilters) {
        // Add clear search action before the search action if it has filters
        newActions.splice(searchIndex, 0, tableDefaultActions.clearSearch);
      } else {
        // Remove clear search action if it has filters
        const clearSearchIndex = actions.indexOf(
          tableDefaultActions.clearSearch
        );
        if (clearSearchIndex >= 0) {
          newActions.splice(clearSearchIndex, 1);
        }
      }
    }

    return {
      ...defaultProps,
      actions: newActions,
    };
  }, [hasFilters, actionsProps]);

  useEffect(() => {
    if (items) {
      const sortedResult = stableSort(
        items,
        getSortingComparator(order, getOrderBy(orderBy))
      );
      setInternalData(sortedResult);
    }
  }, [items]);

  const numericFilterFunction = (value, filter, displayType) => {
    let numberValue = Number(value);
    if (isNaN(numberValue) && displayType === displayTypes.currency) {
      numberValue = 0;
    } else if (isNaN(numberValue)) {
      return Object.entries(filter).length === 0;
    }

    const minFilter =
      filter?.min !== undefined ? filter.min : Number.MIN_SAFE_INTEGER;
    const maxFilter =
      filter?.max !== undefined ? filter.max : Number.MAX_SAFE_INTEGER;

    return numberValue >= minFilter && numberValue <= maxFilter;
  };

  const dropdownFilterFunction = (value, filter) => {
    const filterKeys = Object.keys(filter ?? {}).map((key) =>
      key.toLowerCase()
    );
    if (!filterKeys?.length) return true;
    return filterKeys?.includes((value ?? "").toLowerCase()) ?? false;
  };

  const filterProjects = (callOnChange = false) => {
    // Numeric filter match function
    const numericFilterMatchs = columns
      .filter(
        (cell) =>
          cell.displayType === displayTypes.number ||
          cell.displayType === displayTypes.currency
      )
      .reduce(
        (obj, cell) => ({
          ...obj,
          [cell.fieldKey]: (value, filter) =>
            numericFilterFunction(value, filter, cell.displayType),
        }),
        mappedFilterMatchs ?? {}
      );
    // Dropdown filter match function
    const dropdownFilterMatchs = columns
      .filter((cell) => cell.displayType === displayTypes.dropdown)
      .reduce(
        (obj, cell) => ({ ...obj, [cell.fieldKey]: dropdownFilterFunction }),
        numericFilterMatchs ?? {}
      );
    const filtered = [
      ...stableSort(
        filterData(internalData, filters.current, dropdownFilterMatchs),
        getSortingComparator(order, orderBy)
      ),
    ];
    setFilteredData(filtered);
    if (callOnChange) {
      onFilteredDataChanged(filtered);
    }
  };

  useEffect(() => {
    if (!filtersInitialized && filteredData?.length) {
      onFilteredDataChanged(filteredData);
      setFiltersInitialized(true);
      // Sort after the filter is initialized
      handleRequestSort(orderBy, order, true);
    }
  }, [filteredData]);

  useEffect(() => {
    filterProjects(true);
  }, [debouncedFilters]);

  useEffect(() => {
    if (hasFilters) {
      filterProjects(true);
    } else {
      setFilteredData(internalData);
    }
  }, [internalData]);

  const getOrderBy = (newOrderBy) => mappedFieldKeys[newOrderBy] ?? newOrderBy;

  const handleRequestSort = (fieldKey, newOrder, isFilterInitialized) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);

    // Doesn't need to sort the the filtered data is not initialized yet
    if (!isFilterInitialized) return;

    const sortedCell = columns.find((cell) => cell.fieldKey === fieldKey);
    const sorted = stableSort(
      filteredData,
      getSortingComparator(
        newOrder,
        getOrderBy(fieldKey),
        false,
        sortedCell?.displayType === displayTypes.currency
      )
    );
    setFilteredData(sorted);
    onFilteredDataChanged(sorted);
  };

  const handleFilterChange = (fieldKey, value, filterList = null) => {
    const tempFilters = { ...filters.current };

    if (
      filterList &&
      !Object.keys(filterList).length &&
      Object.keys(tempFilters)
    ) {
      filters.current = {};
      setFiltersForDebounce({});
      return;
    }

    const changedFilters = filterList
      ? Object.entries(filterList)
      : [[fieldKey, value]];
    for (const [fkey, fval] of changedFilters) {
      const key = mappedFieldKeys[fkey] ?? fkey;
      if (fval) tempFilters[key] = fval;
      else delete tempFilters[key];
    }
    filters.current = { ...tempFilters };
    setFiltersForDebounce({ ...tempFilters });
  };

  return (
    <AmoTable
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(id ? { id } : {})}
      columns={columns}
      items={filteredData}
      rawItems={items}
      defaultOrderBy={orderBy}
      defaultOrder={order}
      onRequestSort={(fieldKey, newOrder) =>
        handleRequestSort(fieldKey, newOrder, filtersInitialized)
      }
      onFilterChange={handleFilterChange}
      showFilters={showFilters || internalShowFilters}
      uniqueKeyGenerationFields={uniqueKeyGenerationFields}
      enableItemClick={enableItemClick}
      onItemClick={onItemClick}
      shouldUseQueryString
      onScroll={onScroll}
      focusedItem={focusedItem}
      actionsProps={tableActionsProps}
      isLoading={isLoading}
      hideTableContent={hideTableContent}
    />
  );
};

// set the prop-types for this component
AmoTableWithFilters.propTypes = AmoTableWithFiltersProps;
AmoTableWithFilters.defaultProps = AmoTableWithFiltersDefaultProps;

export default AmoTableWithFilters;
