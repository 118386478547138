import React from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { mobileBreakpoint } from "constants/viewport";

import AmoPageHeader from "components/AmoPageHeader";
import AnnualReportNavBar from "components/annualReport/AnnualReportNavBar";
import ListPageWrapper from "components/ListPageWrapper";

import { useViewport } from "hooks/viewportHook";

const useStyles = makeStyles((theme) => ({
  navBar: {
    zIndex: 99,
    position: ({ breaking }) => (breaking ? "absolute" : "relative"),
    top: 0,
    left: 0,
    height: "375px",
  },
  mainContent: {
    zIndex: 0,
    overflow: "hidden",
    position: "relative",
    // 96px : 24px
    marginLeft: ({ breaking }) => (breaking ? "6rem" : "1.5rem"),
    flexGrow: 1,
    flexBasis: 0,
  },
  contentContainer: {
    position: "relative",
    height: "100%",
  },
  rowSpacer: {
    width: "1rem",
  },
  fullHeight: {
    height: "100%",
  },
}));

/**
 * A styled municipal report page.
 *
 * @param {object} props
 * @param {object} [props.backLink] - The page backlink.
 * @param {React.Element} props.children - The page content.
 * @param {object[]} [props.headerActions] - The page header actions.
 * @param {string} props.title - The page title.
 * @returns {React.Component} The page component.
 */
const ReportPageWrapper = ({ backLink, children, headerActions, title }) => {
  // variables for handling page sizing
  const { width } = useViewport();
  const breakpointToPixel =
    Number(mobileBreakpoint.reportingPageWidth.split("rem")[0]) * 16;
  const breaking = width < breakpointToPixel;

  // set styles based on breakpoint value
  const classes = useStyles({ breaking });

  return (
    <ListPageWrapper>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classes.fullHeight}
      >
        <Grid item>
          <AmoPageHeader
            title="Annual Report"
            subtitle={title}
            backLinkText={backLink.text}
            backLinkTo={backLink.to}
            hideDivider
            actions={headerActions}
          />
        </Grid>
        <Grid item>
          &nbsp;
          {/* creates a blank row, but could be changed by setting the height for the above Grid */}
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            classes={{ root: classes.contentContainer }}
            wrap="nowrap"
          >
            <Grid item classes={{ root: classes.navBar }}>
              <AnnualReportNavBar
                variant="drawer"
                respondToMouseEvents={breaking}
              />
            </Grid>
            <Grid item classes={{ root: classes.mainContent }}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

ReportPageWrapper.propTypes = {
  backLink: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  children: PropTypes.node,
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      testId: PropTypes.string.isRequired,
      label: PropTypes.string,
      color: PropTypes.oneOf(["primary", "secondary"]),
      variant: PropTypes.oneOf(["outlined", "contained"]),
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  title: PropTypes.string.isRequired,
};

ReportPageWrapper.defaultProps = {
  backLink: {},
  children: null,
  headerActions: null,
};

export default ReportPageWrapper;
