import React, { useState, useEffect, useCallback } from "react";
import { Button, Grid, Box, Typography, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import AmoDatePicker from "components/inputs/AmoDatePicker";
import AmoTextField from "components/inputs/AmoTextField";
import AmoFormActions from "components/AmoFormActions";
import FileUploadList from "components/FileUploadList";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { fileTypes, fileAssociations } from "constants/fileTypes";
import { parseDateString } from "utils/yupUtils";
import { dateToPayload } from "utils/date";
import { useNavigationBlocker } from "hooks/navigationHook";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    color: colors.black,
    backgroundColor: colors.white,
    width: "34rem",
    maxHeight: "80vh",
    padding: theme.spacing(0),
    borderRadius: "5px",
    boxShadow: "15px -10px 40px rgba(0, 0, 0, 0.25)",
    overflowY: "auto",
  },
  modalHeader: {
    position: "relative",
    width: "100%",
    marginTop: "0.5rem",
  },
  modalContent: {
    position: "relative",
    width: "100%",
    paddingBottom: theme.spacing(2),
  },
  headerFont: {
    fontSize: "1.25rem",
    marginLeft: "2rem",
  },
  closeIcon: {
    cursor: "pointer",
    fontSize: "1.5rem",
    marginRight: "1.25rem",
  },
  gridContent: {
    position: "relative",
    width: "100%",
    marginTop: "2rem",
  },
  fullWidth: {
    position: "relative",
    width: "30rem",
  },
  datePicker: {
    width: "14rem",
  },
  fileErrorBox: {
    position: "relative",
    width: "100%",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0.5rem",
  },
  errorText: {
    fontSize: "0.75rem",
    color: colors.red.main,
  },
  button: {
    width: "15rem",
    height: "3.125rem",
    marginRight: "2rem",
  },
  buttonGrid: {
    position: "relative",
    width: "100%",
    marginTop: "1rem",
    height: "5rem",
    borderTop: `1px solid ${colors.grey.light}`,
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  staticInputGrid: {
    position: "relative",
    width: "50%",
    marginTop: "2rem",
    marginLeft: "2rem",
  },
  caption: {
    fontSize: "0.875rem",
    fontWeight: "normal",
  },
  notesContainer: {
    width: "18rem",
    textAlign: "left",
    marginLeft: "8.75rem",
  },
}));

/**
 * creates a modal dialog for the Insurance Reporting page using Material-UI's Popover component
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.open - controls visibility of the popover
 * @param {Array} props.insuranceData - array containing the data that will be visualized in the Modal
 * @param {number} props.id - id of the selected insuranceData
 * @param {number} props.municipalityId - id of the municipality
 * @param {number} props.contextYear - contextYear of the municipality
 * @param {boolean} props.readonly - sets the modal access to readonly
 * @param {Function} props.closeFunction - function that closes the modal
 * @param {Function} props.saveFunction - function that saves the form
 *
 * @returns - The modal component
 */
const InsuranceModal = (props) => {
  const {
    open,
    insuranceData,
    id,
    municipalityId,
    contextYear,
    closeFunction,
    saveFunction,
    readonly,
  } = props;
  const [insuranceSelected, setInsuranceSelected] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [fileIsDirty, setFileIsDirty] = useState(false);
  const [fileUploadAction, setFileUploadAction] = useState("Idle");
  const [formWasReseted, setFormWasReseted] = useState(false);

  const classes = useStyles(props);

  const insuranceSchema = yup
    .object({
      coverageStart: yup
        .date()
        .transform(parseDateString)
        .typeError("Invalid Date Format")
        .min(
          new Date("2004-12-31"),
          "Coverage Starts must be on or after January 1, 2005"
        )
        .required(),
      coverageEnd: yup
        .date()
        .transform(parseDateString)
        .typeError("Invalid Date Format")
        .when("coverageStart", (coverageStart, schema) =>
          schema
            .test({
              name: "coverageStartMin",
              exclusive: true,
              message: "Coverage End must be on or after January 1, 2005",
              test: (value) => !value || value > new Date("2004-12-31"),
            })
            .min(
              yup.ref("coverageStart"),
              "End date can't be before start date"
            )
        )
        .min(
          new Date("2004-12-31"),
          "Coverage Starts must be on or after January 1, 2005"
        )
        .required(),
      notes: yup.string().max(500).nullable(),
      file: yup.object().nullable(),
    })
    .required();

  const defaultValues = {
    coverageStart: null,
    coverageEnd: null,
    notes: "",
    file: null,
  };

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid, isSubmitting, isDirty },
    trigger,
  } = useForm({
    defaultValues,
    initialValue: insuranceSelected,
    mode: "onBlur",
    resolver: yupResolver(insuranceSchema),
  });

  const handleModalClose = () => {
    reset(defaultValues);
    setFileData(null);
    setFileIsDirty(false);
    closeFunction();
  };

  const closeModal = (checkForUnsavedChanges = true) => {
    if (checkForUnsavedChanges && (isDirty || fileIsDirty)) {
      openPromptModal();
    } else {
      handleModalClose();
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const formValuesToPayload = (formValues) => ({
    id: fileIsDirty ? -1 : id,
    ...formValues,
    coverageStart: dateToPayload(formValues.coverageStart),
    coverageEnd: dateToPayload(formValues.coverageEnd),
    fileId:
      statusType === "Not submitted" || fileIsDirty
        ? formValues?.file?.id ?? fileData?.id ?? null
        : null,
  });

  const postUploadFile = (_, savedFilesIds) => {
    setValue("file", savedFilesIds[0]);
  };

  const handleUserPromptClose = () => {
    closePromptModal(false);
  };

  const handleUserPromptSave = useCallback(
    (save, event) => {
      if (save) {
        handleSubmit(async (values) => {
          saveFunction(formValuesToPayload(values));
          closeFunction();
        })(event);
      } else if (fileIsDirty) {
        setFileUploadAction("Rollback");
      } else {
        handleModalClose();
      }

      unblockNavigationAndClosePrompt(true);
      return true;
    },
    [fileData, statusType]
  );

  // Hook to block user navigation
  const {
    openPromptModal,
    closePromptModal,
    unblockNavigationAndClosePrompt,
  } = useNavigationBlocker((isDirty || fileIsDirty) && !readonly, {
    onPromptSaveUseCallback: handleUserPromptSave,
    onPromptCloseCallback: handleUserPromptClose,
    hasInvalidData: !isValid || (insuranceSelected.year > 2020 && !fileData),
    isAsyncClosing: true,
  });

  // keeps the Save button disabled if readonly, currently submitting or not valid, or
  // if the year is greater than 2020 and there is no file, since the new system REQUIRES a file each year
  // as of reporting year 2021.
  const addOrEditFormActions = [
    {
      disabled:
        readonly ||
        isSubmitting ||
        !isValid ||
        !fileData ||
        (!isDirty && !fileIsDirty),
      testId: "insuranceReportingSaveButton",
      label: "Save",
      onClick: handleSubmit((values) => {
        saveFunction(formValuesToPayload(values));
        closeModal(false);
      }),
    },
    {
      testId: "insuranceReportingCloseButton",
      label: "Close",
      color: "primary",
      variant: "outlined",
      onClick: () => onCancel(),
    },
  ];

  const modalHeader = (title) => (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.modalHeader}
    >
      <Typography variant="body2" type="h3" className={classes.headerFont}>
        {title}
      </Typography>
      <CloseIcon
        className={classes.closeIcon}
        onClick={() => {
          onCancel();
        }}
      />
    </Grid>
  );

  // this method determines if the FileUploadList component should show on screen
  // based on the data in the current record.
  const shouldShowFileUpload = (displayType) => {
    switch (displayType) {
      case "view": {
        // only display if the file data exists
        if (fileData) {
          return true;
        }
        return false;
      }
      case "edit": {
        // only display if current record's year is greater than or equal to 2017
        if (insuranceSelected.year >= 2017) {
          if (
            statusType === "Not submitted" ||
            statusType === "Submitted" ||
            statusType === "Not Approved"
          ) {
            // allow the user to view the file (if it exists)
            // and/or replace the file
            return true;
          }
          // for "approved" status
          if (statusType === "Approved" && fileData) {
            // only display the file if it exists.
            return true;
          }
        }
        // any other condition return false
        return false;
      }
      default: {
        return true;
      }
    }
  };

  const onFileRollback = () => {
    setFileUploadAction("Idle");
    handleModalClose();
  };

  const addOrEditInsurance = () => {
    let modalTitle = null;

    if (readonly) {
      modalTitle = "View";
    } else if (statusType === "Not submitted") {
      modalTitle = "Add";
    } else if (statusType === "Submitted" || statusType === "Not Approved") {
      modalTitle = "Edit";
    }

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        className={classes.modalContent}
      >
        <Grid item>
          {modalHeader(`${modalTitle} Insurance: ${insuranceSelected.year}`)}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          className={classes.gridContent}
        >
          <Grid item>
            <AmoDatePicker
              disabled={readonly}
              control={control}
              id="insurance-reporting-add-coverage-start"
              name="coverageStart"
              testId="insuranceReportingAddCoverageStart"
              label="Coverage Starts"
              variant="outlined"
              required
              hideCalendar
              format="yyyy-MM-dd"
              className={classes.datePicker}
              helperText="Use format YYYY-MM-DD"
              keepInvisibleHelperText
            />
          </Grid>
          <Grid item>
            <AmoDatePicker
              disabled={readonly}
              control={control}
              id="insurance-reporting-add-coverage-end"
              name="coverageEnd"
              testId="insuranceReportingAddCoverageEnd"
              label="Coverage Ends"
              variant="outlined"
              required
              hideCalendar
              format="yyyy-MM-dd"
              className={classes.datePicker}
              helperText="Use format YYYY-MM-DD"
              keepInvisibleHelperText
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          className={classes.gridContent}
          style={{ marginTop: "0.5rem" }}
        >
          <AmoTextField
            multiline
            rows={1}
            rowsMax={10}
            disabled={readonly}
            control={control}
            label="Notes"
            id="insurance-reporting-notes"
            name="notes"
            testId="insuranceReportingNotes"
            className={classes.fullWidth}
            variant="outlined"
            emptyAsNull
            characterLimit={500}
          />
        </Grid>
        <Grid
          container
          className={classes.gridContent}
          justifyContent="space-evenly"
        >
          <Grid item className={classes.fullWidth}>
            {shouldShowFileUpload("edit") && (
              <FileUploadList
                fileYear={insuranceSelected.year}
                fileAssociationId={fileAssociations.Insurance}
                fileAssociationObjectId={id}
                municipalityId={municipalityId}
                multiple={false}
                showAllowedTypesHint
                allowedTypes={[
                  fileTypes.pdf,
                  fileTypes.jpg,
                  fileTypes.jpeg,
                  fileTypes.png,
                ]}
                onChange={(data) => {
                  if (data?.[0]?.id !== fileData?.id) {
                    setFileData(data?.[0]);
                    setFileIsDirty(true);
                  }
                }}
                initialValue={fileData ? [fileData] : []}
                readonly={insuranceSelected.year < contextYear}
                onUpload={postUploadFile}
                uploadAction={fileUploadAction}
                onRollback={onFileRollback}
              />
            )}
          </Grid>
          {insuranceSelected.year > 2020 && !fileData && (
            <Box className={classes.fileErrorBox}>
              <Typography className={classes.errorText}>
                A relevant insurance file must be uploaded
              </Typography>
            </Box>
          )}
        </Grid>
        {statusType === "Submitted" && (
          <Grid
            container
            className={classes.staticInputGrid}
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2">Date Received:</Typography>
            <Typography>{insuranceSelected.received}</Typography>
          </Grid>
        )}
        <Grid container style={{ marginTop: "1rem" }}>
          <AmoFormActions actions={addOrEditFormActions} hideLateralBorders />
        </Grid>
      </Grid>
    );
  };

  const viewInsurance = () => (
    <Grid container direction="column" className={classes.modalContent}>
      <Grid item>
        {modalHeader(`View Insurance: ${insuranceSelected.year}`)}
      </Grid>
      <Grid
        container
        className={classes.staticInputGrid}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body2">Coverage Start:</Typography>
        <Typography>{insuranceSelected.coverageStart}</Typography>
      </Grid>
      <Grid
        container
        className={classes.staticInputGrid}
        direction="row"
        justifyContent="space-between"
        style={{ marginTop: "1rem" }}
      >
        <Typography variant="body2">Coverage End:</Typography>
        <Typography>{insuranceSelected.coverageEnd}</Typography>
      </Grid>
      <Grid
        container
        className={classes.staticInputGrid}
        direction="row"
        justifyContent="space-between"
        style={{ marginTop: "1rem" }}
      >
        <Typography variant="body2">Date Received:</Typography>
        <Typography>{insuranceSelected.received}</Typography>
      </Grid>
      <Grid
        container
        className={classes.staticInputGrid}
        direction="row"
        justifyContent="space-between"
        style={{ marginTop: "1rem", width: "fit-content" }}
      >
        <Typography variant="body2">Notes:</Typography>
        <Typography className={classes.notesContainer}>
          {insuranceSelected.notes}
        </Typography>
      </Grid>
      <Grid container className={classes.gridContent} justifyContent="center">
        {shouldShowFileUpload("view") && (
          <Grid item className={classes.fullWidth}>
            <FileUploadList
              readonly
              initialValue={fileData ? [fileData] : []}
            />
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.buttonGrid} justifyContent="flex-end">
        <Button
          color="primary"
          variant="outlined"
          className={classes.button}
          onClick={() => closeModal(false)}
        >
          <Typography align="center" variant="body2">
            Close
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );

  const renderForm = () => {
    if (insuranceData?.length && id !== null) {
      if (
        statusType === "Not submitted" ||
        statusType === "Submitted" ||
        statusType === "Not Approved"
      ) {
        return addOrEditInsurance();
      }
      if (statusType === "Approved") {
        return viewInsurance();
      }
      return null;
    }
    return null;
  };

  useEffect(() => {
    insuranceData?.forEach((val) => {
      if (val.id === id) {
        setInsuranceSelected(val);
        setStatusType(val.status);
        // setting the relevant react hook form values
        if (val.status === "Submitted" || val.status === "Not Approved") {
          // using reset here helps to set other form values like isValid, isDirty properly.
          reset({
            coverageStart: DateTime.fromFormat(val.coverageStart, "yyyy-MM-dd"),
            coverageEnd: DateTime.fromFormat(val.coverageEnd, "yyyy-MM-dd"),
            notes: val.notes,
          });
          setFormWasReseted(true);
        }

        if (val.file != null) {
          setFileData(val.file);
          clearErrors();
        } else {
          setFileData(null);
        }
      }
    });
  }, [id !== null]);

  useEffect(() => {
    if (formWasReseted) {
      setFormWasReseted(false);
      trigger();
    }
  }, [formWasReseted]);

  return (
    <Modal
      open={open}
      className={classes.modal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          closeFunction(event, reason);
        }
      }}
      disableEscapeKeyDown
    >
      <Box className={classes.box}>{renderForm()}</Box>
    </Modal>
  );
};

InsuranceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  insuranceData: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      actions: PropTypes.any,
      coverageEnd: PropTypes.string,
      coverageStart: PropTypes.string,
      file: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      id: PropTypes.number,
      recieved: PropTypes.string,
      status: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      statusComponent: PropTypes.any,
      year: PropTypes.number,
    })
  ).isRequired,
  id: PropTypes.number,
  municipalityId: PropTypes.number.isRequired,
  contextYear: PropTypes.number.isRequired,
  closeFunction: PropTypes.func.isRequired,
  saveFunction: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

InsuranceModal.defaultProps = {
  id: null,
  readonly: false,
};

export default InsuranceModal;
