import { useQuery } from "react-query";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { booleanToTextOrUndefined } from "utils/string";

import { commProjectDetailsQueryKeys } from "./commProjectDetailsQueryKeys";
import { projectDetailsService } from "api/services/projectDetailsService";
import { formatISODate } from "utils/date";

/**
 * Store server's state of communications project details results
 *
 * @param {number} projectId
 * @returns The query object returned by the useQuery hook
 */
export const useProjectDetailsHook = (projectId) => {
  const { municipalities } = useMunicipalContext();

  return useQuery(
    commProjectDetailsQueryKeys.projectDetails(projectId),
    () => projectDetailsService.getProjectDetails(projectId),
    {
      select: ({ data }) => ({
        ...(data ?? {}),
        municipalityName: municipalities[data?.municipalityId]?.name,
        startDate: formatISODate(data?.startDate),
        constructionEndDate: formatISODate(data?.constructionEndDate),
        locations:
          data?.locations?.map((location) => ({
            lat: location.latitude,
            lng: location.longitude,
            color: location.isPrimary ? "green" : "blue",
            label: `${location.isPrimary ? "Primary" : "Secondary"} Location`,
          })) ?? [],
        otherFederalFunds: booleanToTextOrUndefined(data.otherFederalFunds),
        provincialFunds: booleanToTextOrUndefined(data.provincialFunds),
        stackingLimits: booleanToTextOrUndefined(data.stackingLimits),
      }),
      enabled: !!projectId,
    }
  );
};
