import { useState } from "react";

const initialFormValue = {
  subject: "",
  body: "",
  enabled: false,
  to: [],
  cc: [],
};

export const useNotificationForm = () => {
  const [form, setForm] = useState(initialFormValue);
  const [touched, setTouched] = useState(
    Object.keys(initialFormValue).reduce(
      (acc, key) => ({
        ...acc,
        [key]: false,
      }),
      {}
    )
  );

  const getErrors = (fieldValues = form, errors = {}) => {
    const newErrors = { ...errors };
    if ("subject" in fieldValues)
      newErrors.subject = fieldValues.subject ? "" : "This field is required.";
    if ("body" in fieldValues)
      newErrors.body = fieldValues.body ? "" : "This field is required.";
    return newErrors;
  };

  const [errors, setErrors] = useState(() => getErrors(initialFormValue));

  const validate = (fieldValues = form) => {
    setErrors({
      ...getErrors(fieldValues, errors),
    });
  };

  const markAsTouched = (fieldName) => {
    setTouched({
      ...touched,
      [fieldName]: true,
    });
  };

  const markAllAsTouched = () => {
    setTouched(
      Object.keys(initialFormValue).reduce(
        (acc, key) => ({
          ...acc,
          [key]: true,
        }),
        {}
      )
    );
  };

  /**
   * Handles fields manipulation, setting new field values, errors, and marks as touched
   *
   * @param {string} fieldName
   * @param {string} fieldValue
   * @param {boolean} forceInvalidation When set to true it will forcefully invalidate the form field
   */
  const handleInputValue = (
    fieldName,
    fieldValue,
    forceInvalidation = false
  ) => {
    setForm({
      ...form,
      [fieldName]: fieldValue,
    });
    if (!forceInvalidation) validate({ [fieldName]: fieldValue });
    else forceInvalidate(fieldName);
    markAsTouched(fieldName);
  };

  const prefillForm = (newFormValue) => {
    const newValue = {
      subject: newFormValue?.subject ?? form.subject,
      body: newFormValue?.body ?? form.body,
      enabled: newFormValue?.enabled ?? form.enabled,
      to: newFormValue?.to ?? form.to,
      cc: newFormValue?.cc ?? form.cc,
    };
    setForm(newValue);
    validate(newValue);
    markAllAsTouched();
  };

  const forceInvalidate = (fieldName) => {
    setErrors({
      ...errors,
      // Forced invalidation
      [fieldName]: "Invalid content",
    });
  };

  const formIsValid = () => Object.values(errors).every((err) => !err);

  return {
    form,
    errors,
    touched,
    prefillForm,
    handleInputValue,
    markAsTouched,
    markAllAsTouched,
    formIsValid,
  };
};
