import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  title: string,
 *  content: string,
 *  isPublished: number,
 *  lastEdited: string,
 *  pemalink: string,
 *  sortOrder: number
 *  }} HelpPages
 */

const api = new Api("api/HelpPages");
export const helpListService = {
  /**
   * @returns {Promise<AxiosResponse<HelpPages[]>>}
   */
  getAll: () => api.get(),
  remove: (permalink) => api.del(permalink),
  create: (payload) => api.post(payload),
  /**
   * @param {string} permalink
   * @returns {Promise<AxiosResponse<HelpPages>>}
   */
  getByPermalink: (permalink) => api.getById(permalink),
  update: (permalink, payload) => api.put(permalink, payload),
};
