// Set up a key factory per feature for a flexible query key reference
export const projectReviewsQueryKeys = {
  projectReviews: (year) => ["projectReviews", year],
  projectReviewById: (projectId) => [
    "projectReviews",
    "projectReview",
    projectId,
  ],
  municipalityContacts: (municipalityId) => [
    "projectReviews",
    "municipalityContacts",
    municipalityId,
  ],
};
