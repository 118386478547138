import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: colors.grey.light,
    margin: "0.2rem 0",
    padding: "1rem",
  },
  timestamp: {
    fontSize: "0.88rem",
  },
  secondLine: {
    marginTop: "0.5rem",
  },
}));

/**
 * A component to display a history log comment
 *
 * @param {object} props - object containing props for this component
 * @param {number} props.id - id of log item
 * @param {string} props.timestamp - log timestamp
 * @param {string} props.user - user that caused the log
 * @param {string} props.value - comment made
 *
 * @returns - The log item component
 */
const HistoryLogCommentItem = (props) => {
  const { id, timestamp, user, value } = props;
  const classes = useStyles();
  return (
    <Grid
      key={id}
      container
      direction="column"
      className={classes.mainContainer}
    >
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerWrapper}
        >
          <Grid item>
            <Typography variant="body2">{user}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.timestamp}>
              {DateTime.fromISO(timestamp).toFormat("yyyy-MM-dd hh:mm a")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.secondLine}>
        <Typography style={{ whiteSpace: "pre-wrap" }} variant="body1">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

// set the prop-types for this component
HistoryLogCommentItem.propTypes = {
  id: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

HistoryLogCommentItem.defaultProps = {};

export default HistoryLogCommentItem;
