/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { MenuItem, Typography, ListItemIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import colors from "../constants/colors";
import { useNavigationHelper } from "../hooks/navigationHook";

const useStyles = makeStyles(() => ({
  menuLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: colors.green.main,
    },
    display: "flex",
    width: "100%",
  },
  menuActiveLink: {
    color: colors.black,
    display: "flex",
  },
  menuItemIcon: {
    minWidth: 40,
  },
}));

/**
 * A popup menu item component
 *
 * @returns {React.Component} The popup menu item component
 */
const AmoPopupMenuItem = React.forwardRef((props, ref) => {
  const { text, iconName, to, testId, className } = props;

  const classes = useStyles();
  const { handleSamePathNavigation } = useNavigationHelper();

  return (
    <>
      <MenuItem
        className={className}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            event.target.querySelector("a").click();
          }
        }}
      >
        <NavLink
          ref={ref}
          exact
          to={to}
          onClick={(event) => handleSamePathNavigation(event, to)}
          className={classes.menuLink}
          activeClassName={classes.menuActiveLink}
          data-testid={testId}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <img src={iconName} height={20} width={20} alt={text} />
          </ListItemIcon>
          <Typography color="primary" variant="subtitle2">
            {text}
          </Typography>
        </NavLink>
      </MenuItem>
    </>
  );
});

// set the prop-types for this component
AmoPopupMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  to: PropTypes.string,
  testId: PropTypes.string,
  className: PropTypes.string,
};

AmoPopupMenuItem.defaultProps = {
  to: "/",
  testId: null,
  className: null,
};

export default AmoPopupMenuItem;
