import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import colors from "../../constants/colors";
import { useNavigationHelper } from "../../hooks/navigationHook";
import { routes } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  menuContainer: {
    "& > .MuiGrid-item:last-child": { paddingRight: "0" },
  },
  menuItemSpacing: {
    padding: "0rem 1.75rem",
  },
  menuNavLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  menuActiveLink: {
    color: colors.black,
    backgroundColor: colors.grey.light,
  },
}));

/**
 * A menu component with items for communications users
 *
 * @returns {React.Component} The menu component
 */
const CommMenu = () => {
  const classes = useStyles();
  const { handleSamePathNavigation } = useNavigationHelper();

  return (
    <>
      <Grid
        data-testid="headerCommMenu"
        container
        className={clsx(classes.menuContainer, classes.fullHeight)}
        justifyContent="flex-end"
        alignItems="center"
        spacing={0}
      >
        <Grid item className={classes.menuItemSpacing}>
          <NavLink
            data-testid="headerDashboardLink"
            exact
            to={routes.dashboard}
            onClick={(event) =>
              handleSamePathNavigation(event, routes.dashboard)
            }
            className={classes.menuNavLink}
            activeClassName={classes.menuActiveLink}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <Typography align="center" variant="subtitle1">
              Dashboard
            </Typography>
          </NavLink>
        </Grid>
        <Grid item className={classes.menuItemSpacing}>
          <NavLink
            data-testid="headerProjectsLink"
            exact
            to={routes.communications.projects.list}
            onClick={(event) =>
              handleSamePathNavigation(
                event,
                routes.communications.projects.list
              )
            }
            className={classes.menuNavLink}
            activeClassName={classes.menuActiveLink}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <Typography align="center" variant="subtitle1">
              Project List
            </Typography>
          </NavLink>
        </Grid>
      </Grid>
    </>
  );
};

export default CommMenu;
