import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";
import { routes } from "constants/routes";
import { roles } from "constants/user";
/**
 * A router component
 *
 * @param {object} props
 * @returns {React.Component} The amo router
 */
const PrivateRoute = (props) => {
  const { children, path, redirectPath, exact, allowedRoles } = props;
  const { user, hasRoles } = useUserContext();

  return (
    <>
      {user.loaded && (
        <Route
          exact={exact}
          path={path}
          render={({ location }) => {
            if (!user.isAuthenticated) {
              return (
                <Redirect
                  to={{
                    pathname: redirectPath,
                    state: { from: location },
                  }}
                />
              );
            }
            if (
              allowedRoles?.length &&
              !hasRoles([...allowedRoles, roles.amoSuperAdmin])
            ) {
              return (
                <Redirect
                  to={{
                    pathname: routes.accessDenied,
                    state: { from: location },
                  }}
                />
              );
            }
            return children;
          }}
        />
      )}
    </>
  );
};

// set the prop-types for the private route component
PrivateRoute.propTypes = {
  ...Route.propTypes,
  redirectPath: PropTypes.string,
  exact: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
  ...Route.defaultProps,
  allowedRoles: undefined,
  redirectPath: "/login",
};

export default PrivateRoute;
