import React, { useState } from "react";
import PropTypes from "prop-types";
import FileUpload from "./FileUpload";
import {
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Icon,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { fileTypes } from "constants/fileTypes";
import clsx from "clsx";
import { v4 as uuidV4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  fileRow: {
    backgroundColor: "#F1F1F1",
    height: "4rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
  },
  fileIcon: {
    verticalAlign: "bottom",
    marginRight: "0.625rem",
  },
}));

/**
 * Component to upload allocations file.
 *
 * @param {object} props - object containing props for this component
 * @param {Function} props.onChange - Function called when there is a file change (selected or removed) [params: selected file]
 *
 * @returns {React.Component} The Allocations File Upload component.
 */
const AllocationsFileUpload = (props) => {
  const classes = useStyles();
  const { onChange } = props;
  const [file, setFile] = useState(null);

  // There is no reference to the current state values on FileUpload's handleDrop callback
  const onFileSelected = (newFiles) => {
    const selectedFile = newFiles.length > 0 ? newFiles[0] : null;
    setFile(selectedFile);
    onChange(selectedFile);
  };

  const onRemove = () => {
    setFile(null);
    onChange(null);
  };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {file && (
          <Grid item key={file?.id || uuidV4()} style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.fileRow}
              wrap="nowrap"
            >
              <Grid item>
                <Icon
                  color="primary"
                  className={clsx("material-icons-outlined", classes.fileIcon)}
                >
                  file_download
                </Icon>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  color="primary"
                  variant="body2"
                  noWrap
                  title={file?.name}
                >
                  {file?.name}
                </Typography>
              </Grid>
              <Grid item data-testid="allocationsFileDeleteButton">
                <IconButton
                  color="secondary"
                  component="span"
                  onClick={() => onRemove(file.id)}
                >
                  <DeleteOutline />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!file && (
          <>
            <Grid item data-testid="fileUploadContainer">
              <FileUpload
                multiple={false}
                allowedTypes={[fileTypes.xlsx]}
                onChange={onFileSelected}
              />
            </Grid>
            <Grid item align="center" data-testid="allowedFileTypesHint">
              <Typography>Valid file types: .XLSX</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

AllocationsFileUpload.propTypes = {
  onChange: PropTypes.func,
};

AllocationsFileUpload.defaultProps = {
  onChange: () => {},
};

export default AllocationsFileUpload;
