import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import colors from "constants/colors";
import { routes, replaceParams } from "constants/routes";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";

import ListPageWrapper from "components/ListPageWrapper";
import HistoryLog from "components/amo/HistoryLog";
import ReviewDataInput from "components/amo/reviewLog/ReviewDataInput";
import AmoPageHeader from "components/AmoPageHeader";
import ContactInfoModal from "components/amo/ContactInfoModal";

import FinancialReviewForm from "./FinancialReviewForm";
import AerChart from "components/amo/AerChart";
import { useHistoryLogs } from "components/amo/historyLogHooks";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  useFinancialReviewData,
  useAerChartData,
} from "./financialReviewsHooks";
import { useProjectFinancialsData } from "pages/municipal/summaries/financial/projectFinancialsHooks";
import { exportFinancialSummary } from "utils/export";
import { copyPageUrl } from "utils/url";
import reviewTypes from "constants/reviewTypes";
import { useYearContext } from "contexts/YearContext";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: colors.black,
  },
  pageContent: {
    // 24px 16px 0px 16px
    padding: "1.5rem 1rem 0rem 1rem",
  },
  button: {
    minHeight: "3.125rem",
    fontSize: "1rem",
    borderWidth: "0.125rem",
    "&:hover": {
      borderWidth: "0.125rem",
    },
  },
  iconButton: {
    border: `0.125rem solid ${theme.palette.primary.main}`,
  },
  ellipsisIcon: {
    cursor: "pointer",
    fontSize: "3.2rem",
  },
  menuItemIcon: {
    color: "black",
    minWidth: "initial",
    marginRight: "1.125rem",
  },
  categoryIcon: {
    width: "100%",
    textAlign: "center",
  },
  dividerContainer: {
    // 24px
    paddingBottom: "1.5rem",
  },
  buttonsBar: {
    paddingBottom: "1.438rem !important",
  },
  historyPadding: {
    // 16px
    paddingBottom: "1rem",
  },
  formContent: {
    // 36px
    paddingRight: "2.25rem",
  },
}));

/**
 * The Financial Review Page.
 *
 * @returns {React.Component} Financial Review Page component.
 */
const FinancialReviewPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { contextYear } = useYearContext();
  const history = useHistory();
  const { search } = useLocation();

  const [showContactInformation, setShowContactInformation] = useState(false);
  const [financialYears, setFinancialYears] = useState();
  const { municipalityId, year } = useParams();
  const { data: aerChartData } = useAerChartData(
    municipalityId,
    2005, // Earliest year for records
    year
  );

  const {
    data: financialReview,
    refetch: refetchFinancialData,
    isLoading: isLoadingReview,
    isFetching: isFetchingReview,
  } = useFinancialReviewData(municipalityId, year);

  const reviewId = financialReview ? financialReview.reviewId : null;
  const statusId = financialReview ? financialReview.statusId : null;

  const { refetch: refetchHistoryData } = useHistoryLogs(
    reviewTypes.review,
    reviewId
  );

  const {
    data: financialsExportData,
    isLoading: isLoadingExportData,
  } = useProjectFinancialsData({
    municipalityId,
    yearStart: 2005, // Earliest year for records
    yearEnd: year,
  });

  useEffect(() => {
    if (financialsExportData) {
      setFinancialYears(financialsExportData?.years);
    }
  }, [financialsExportData]);

  useEffect(() => {
    history.push(
      `${`/reviews/financial/${municipalityId}/${contextYear}`}${search}`
    );
  }, [contextYear]);

  const handleFormDataUpdate = (updatedData) => {
    refetchFinancialData();
  };

  const handleDataInputSave = () => {
    refetchHistoryData();
    refetchFinancialData();
  };

  const copyURL = () => {
    copyPageUrl(
      replaceParams(routes.dual.financials, {
        municipalityId,
        year,
      })
    );
    showSnackbar("Successfully copied URL", snackbarTypes.success);
  };

  const download = async () => {
    try {
      const filename = `AMO - ${financialReview?.municipalityName} - Financial Summary - 2005 to ${year}`;
      exportFinancialSummary(financialsExportData?.years ?? [], filename);
    } catch (error) {
      showSnackbar(errorMessages.creatingSpreadsheet, snackbarTypes.error);
    }
  };

  const headerActions = [
    {
      testId: "financialReviewContactInfo",
      label: "Contact Info",
      variant: "outlined",
      onClick: () => setShowContactInformation(true),
    },
    {
      testId: "financialReviewCopyUrl",
      label: "Copy URL",
      variant: "outlined",
      onClick: copyURL,
    },
    {
      testId: "financialReviewDownload",
      iconName: "file_download",
      variant: "outlined",
      isIconButton: true,
      onClick: download,
      disabled: isLoadingExportData,
    },
  ];

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <AmoPageHeader
            title="Financial Information Review"
            backLinkText="Back to Financial Information"
            backLinkTo={routes.reviews.financial.list}
            actions={headerActions}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={0}
            className={classes.pageContent}
            justifyContent="space-between"
          >
            <Grid item className={classes.formContent} xs={6} md={7} lg={9}>
              {isLoadingReview ? (
                <Skeleton width="100%" height="50rem" variant="rect" />
              ) : (
                <FinancialReviewForm
                  key={`financial-form-${year}`}
                  financialData={financialReview ?? {}}
                  onDataUpdate={handleFormDataUpdate}
                  yearsData={financialYears}
                  isFetching={isFetchingReview || isLoadingExportData}
                />
              )}
              <Grid container direction="column" spacing={5}>
                <Grid item xs>
                  {aerChartData && (
                    <AerChart width={900} height={400} data={aerChartData} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={5} lg={3}>
              <Grid container direction="column" spacing={0}>
                <Grid item className={classes.historyPadding}>
                  <HistoryLog objectId={reviewId} />
                </Grid>
                <Grid item>
                  <ReviewDataInput
                    id="financial-review-log-data-input"
                    testId="financialReviewLogDataInput"
                    projectStatusId={statusId}
                    objectId={reviewId}
                    onSave={handleDataInputSave}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showContactInformation && (
        <ContactInfoModal
          variant="default"
          showModal={showContactInformation}
          closeModalFunction={() => setShowContactInformation(false)}
          title="Contact Information"
          showButton
          municipalityId={municipalityId}
        />
      )}
    </ListPageWrapper>
  );
};

export default FinancialReviewPage;
