import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Warning } from "@material-ui/icons";

import colors from "constants/colors";
import AmoTable from "components/table/AmoTable";
import { getSortingComparator, stableSort } from "utils/data";
import { useLocation } from "react-router-dom";
import { AmoTableColumnProps } from "constants/amoTablePropTypes";

const useStyles = makeStyles({
  box: {
    border: `1px solid ${colors.grey.main}`,
    "&.MuiAccordion-rounded": {
      borderRadius: "10px",
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      "&.Mui-expanded": {
        marginBottom: 0,
      },
    },
  },
  warning: {
    color: colors.red.main,
    // 24px
    marginRight: "1.5rem",
  },
  title: {
    fontFamily: "Roboto",
    // 24px
    fontSize: "1.5rem",
    color: colors.black,
  },
});

const SubmitReportAccordion = ({ title, data, columns }) => {
  const classes = useStyles();
  const location = useLocation();

  const [expanded, setExpanded] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0]?.fieldKey);
  const [sortedData, setSortedData] = useState(
    stableSort(data, getSortingComparator(order, orderBy))
  );

  const sortData = (newOrder, newOrderBy) => {
    const sortedItems = stableSort(
      data,
      getSortingComparator(newOrder, newOrderBy)
    );
    setSortedData(sortedItems);
  };

  const handleRequestSort = (fieldKey, newOrder) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);

    sortData(newOrder, fieldKey);
  };

  useEffect(() => {
    sortData(order, orderBy);
  }, [location, data]);

  return (
    <Accordion
      className={classes.box}
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={
          <span className="material-icons-outlined">expand_more</span>
        }
      >
        <Warning className={classes.warning} />
        <Typography className={classes.title} align="center">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AmoTable
          id={`annual-report-submit-issues-table-${title}`}
          testId="annualReportSubmitIssuesTable"
          items={sortedData}
          columns={columns}
          defaultOrderBy={columns[0]?.fieldKey}
          onRequestSort={handleRequestSort}
          shouldUseQueryString
          disableFilters
          uniqueKeyGenerationFields={["title", "id", "issue"]}
        />
      </AccordionDetails>
    </Accordion>
  );
};

SubmitReportAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      issue: PropTypes.string.isRequired,
    })
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(AmoTableColumnProps)),
};

SubmitReportAccordion.defaultProps = {
  columns: [],
};

export default SubmitReportAccordion;
