import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";
import colors from "../constants/colors";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.black,
    textWrap: "nowrap",
  },
  subtitle: {
    color: colors.black,
    marginLeft: "1rem",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  navLinkGridItem: {
    marginLeft: "2rem",
  },
  navLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  divider: {
    backgroundColor: colors.grey.light,
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  button: {
    width: "15rem",
    height: "3.125rem",
  },
  buttonMargin: {
    marginRight: "1rem",
  },
  iconButton: {
    border: `0.125rem solid`,
  },
  buttonIcon: {
    marginLeft: "0.4rem",
  },
}));

/**
 * A page header component
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.title - sets the text displayed as the page title with bold effect [required]
 * @param {string} props.subtitle - sets the text displayed on the right side of the page title without effect
 * @param {string} props.backLinkText - sets the text displayed by the back navigation link, hides the link if not set
 * @param {string} props.backLinkTo - sets the path to which the app will navigate when clicking the back link
 * @param {boolean} props.replaceOnBack - controls whether the page header back link will erase the current path from history on click
 * @param {boolean} props.hideDivider - controls whether the page header bottom divider is hidden (default: false)
 * @param {object[]} props.actions - object array containing actions to be displayed as buttons on the left side of the back link
 * @param {string} props.actions.testId - data-testid of the action button [required]
 * @param {string} props.actions.label - sets this action button text [required]
 * @param {string} props.actions.color - sets this action button color (valid values: "primary", "secondary")
 * @param {string} props.actions.variant - sets this action button variant (valid values: "outlined", "contained")
 * @param {boolean} props.actions.disabled - controls whether this action button is disabled
 * @param {any} props.navLinkProp - any prop that the user wishes to pass to NavLink
 * @param {any} props.backLinkSearch - sets the search query of the path to which the app will navigate when clicking the back link, otherwise will use location.search
 * @param {Function} props.actions.onClick - function called when user clicks on the action button (params: event)
 * @returns {React.Component} The page header component
 */
const AmoPageHeader = (props) => {
  const {
    title,
    subtitle,
    backLinkText,
    backLinkTo,
    replaceOnBack,
    hideDivider,
    actions,
    navLinkProp,
    backLinkSearch,
  } = props;

  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>
        <Grid
          container
          spacing={0}
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Grid container spacing={1} alignItems="baseline" wrap="nowrap">
              <Grid item>
                <Typography className={classes.title} variant="h1">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.subtitle} variant="h2">
                  {subtitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={0} alignItems="baseline">
              {actions?.map((action, index) => (
                <Grid item key={action.testId}>
                  {action?.isIconButton ? (
                    <IconButton
                      data-testid={action.testId}
                      onClick={action.onClick}
                      className={classes.iconButton}
                      color={action.color || "primary"}
                      variant={action.variant || "contained"}
                      disabled={action.disabled || false}
                    >
                      <Icon
                        className={clsx(
                          "material-icons-outlined",
                          classes.menuIcon
                        )}
                      >
                        {action.iconName}
                      </Icon>
                    </IconButton>
                  ) : (
                    <Button
                      data-testid={action.testId}
                      className={clsx(
                        classes.button,
                        index < actions.length - 1 ? classes.buttonMargin : null
                      )}
                      color={action.color || "primary"}
                      variant={action.variant || "contained"}
                      size="medium"
                      style={action.style}
                      disableElevation
                      disabled={action.disabled || false}
                      onClick={action.onClick}
                    >
                      <Typography align="center" variant="body2">
                        {action.label}
                      </Typography>
                      {action.iconName && (
                        <Icon
                          className={clsx(
                            "material-icons-outlined",
                            classes.buttonIcon
                          )}
                        >
                          {action.iconName}
                        </Icon>
                      )}
                    </Button>
                  )}
                </Grid>
              ))}
              {backLinkText && (
                <Grid item className={classes.navLinkGridItem}>
                  <NavLink
                    data-testid="pageBackLink"
                    to={{
                      pathname: backLinkTo,
                      state: navLinkProp,
                      search: backLinkSearch ?? location.search,
                    }}
                    replace={replaceOnBack}
                    className={classes.navLink}
                  >
                    <Typography align="center" variant="body1">
                      {backLinkText}
                    </Typography>
                  </NavLink>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!hideDivider && (
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
      )}
    </Grid>
  );
};

// set the prop-types for this component
AmoPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backLinkText: PropTypes.string,
  backLinkTo: PropTypes.string,
  replaceOnBack: PropTypes.bool,
  hideDivider: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      testId: PropTypes.string.isRequired,
      label: PropTypes.string,
      color: PropTypes.oneOf(["primary", "secondary"]),
      variant: PropTypes.oneOf(["outlined", "contained"]),
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  navLinkProp: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.object,
  ]),
  backLinkSearch: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.object,
  ]),
};

AmoPageHeader.defaultProps = {
  subtitle: null,
  backLinkText: null,
  backLinkTo: null,
  replaceOnBack: true,
  hideDivider: false,
  actions: null,
  navLinkProp: null,
  backLinkSearch: null,
};

export default AmoPageHeader;
