import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link, useHistory } from "react-router-dom";
import { useFinancialReports } from "./landingPageHooks";
import { BarChart, Cell, Bar, XAxis, Text } from "recharts";
import PropTypes from "prop-types";
import { routes } from "constants/routes";
import { useYearContext } from "contexts/YearContext";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "45rem",
    minHeight: "18rem",
    borderRadius: "10px",
    border: "1px solid #939393",
    padding: "1rem",
    paddingBottom: "0rem",
    display: "flex",
    flexDirection: "column",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    position: "relative",
    width: "100%",
  },
  button: {
    width: "15rem",
    height: "3.125rem",
  },
  barChartContainer: {
    marginTop: "1rem",
    flexGrow: 1,
    display: "flex",
  },
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    marginBottom: "2rem",
  },
}));

/**
 * Component that holds financial reporting data for Amo users
 *
 * @param {object} props - object containing props for this component
 * @param {string} id - component id
 * @param {string} testId - testing id
 *
 * @returns - Widget component
 */

const FinancialReportingWidget = (props) => {
  const { contextYear } = useYearContext();
  const { data, isLoading, isError, error } = useFinancialReports(contextYear);
  const history = useHistory();
  const classes = useStyles();
  const { id, testId } = props;

  const FILL_OBJECT = {
    Incomplete: "#E0E0E0",
    "Review Required": "#5F8CFE",
    "Follow-up Required": "#FA0000",
    Contacted: "#FFB600",
    Approved: "#23AE49",
  };

  const REVIEW_PATH_FILTERS = {
    Incomplete: "Incomplete-true",
    "Review Required": "Pending-true_Updated-true",
    "Follow-up Required":
      "To be contacted-true_To be revised-true_To be discussed-true",
    Contacted: "Contacted-true",
    Approved: "Approved-true",
  };

  const CustomizedAxisTick = (classProps) => {
    // x, y are the prop names from recharts
    // eslint-disable-next-line id-length
    const { x, y, payload } = classProps;

    return (
      <Text
        x={x}
        y={y}
        width={75}
        fontSize={13}
        textAnchor="middle"
        verticalAnchor="start"
      >
        {payload.value}
      </Text>
    );
  };

  return (
    <>
      {isLoading && (
        <Skeleton
          style={{
            width: "45rem",
            height: "18rem",
          }}
          variant="rect"
        />
      )}
      {!isLoading && (
        <Box className={classes.container} id={id} data-testid={testId}>
          <Box className={classes.column}>
            <Box className={classes.titleRow}>
              <Typography variant="h1">Financial Reporting</Typography>
              <Button
                color="primary"
                variant="outlined"
                size="medium"
                disableElevation
                className={classes.button}
                component={Link}
                to={routes.reviews.financial.list}
              >
                <Typography align="center" variant="body2">
                  Review Financials
                </Typography>
              </Button>
            </Box>
            <Box
              className={classes.barChartContainer}
              id="financialReportingBarChart"
              data-testid="testingFinancialReportingBarChart"
            >
              {isError && (
                <Box className={classes.errorContainer}>
                  <Typography variant="h5">
                    {error?.response?.status === 404
                      ? "Data not found"
                      : "Unable to get data"}
                  </Typography>
                </Box>
              )}
              {!isError && (
                <BarChart
                  data={data}
                  width={43 * 16} // 1rem = 16px
                  height={12 * 16}
                  barCategoryGap="5%"
                  margin={{
                    top: 20,
                    bottom: 2,
                  }}
                  onClick={(graphData) => {
                    if (graphData) {
                      history.push(
                        `${routes.reviews.financial.list}?filter_statusName=${
                          REVIEW_PATH_FILTERS[graphData?.activeLabel]
                        }`
                      );
                    }
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tick={<CustomizedAxisTick />}
                    interval={0}
                  />
                  <Bar
                    minPointSize={0}
                    dataKey="value"
                    isAnimationActive={false}
                    label={{
                      fontSize: 15,
                      position: "top",
                      fontWeight: 200,
                      fill: "black",
                    }}
                  >
                    {data?.map((entry) => (
                      <Cell
                        cursor="pointer"
                        key={entry.name}
                        fill={FILL_OBJECT[entry.name]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

FinancialReportingWidget.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
};

export default FinancialReportingWidget;
