/* eslint-disable id-length */
import XLSX from "xlsx";
import { DateTime } from "luxon";
import { customRound } from "utils/number";
import { booleanToTextOrUndefined } from "utils/string";
import XlsxPopulate from "xlsx-populate";
import { downloadWorkbook } from "./xlsxPopulateUtils";
import { Document, HeadingLevel, Packer, Paragraph, TextRun } from "docx";
import { downloadFile } from "./pdf";
import { questionnaireAnswerTypes } from "constants/questionnaireAnswerTypes";
import { displayTypes } from "constants/amoTableConstants";
import { questionnaireTypes } from "constants/questionnaireTypes";

const exportFinancialSummary = (unsortedData, filename = undefined) => {
  const hideFireStationRow =
    unsortedData.length === 1 && unsortedData[0].year < 2021;
  const rowsFormat = [
    { field: "reportTitle", label: "Annual Reports" },
    { field: "spacer1" },
    { field: "year" },
    { field: "openBalance", label: "Opening Balance", currency: true },
    { field: "revenuesTitle", label: "Revenues", colSpacer: 1 },
    {
      field: "allocation",
      label: "Received from AMO (Including Receivables)",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "interestEarned",
      label: "Interest earned",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "proceedsFromDisposal",
      label: "Proceeds from the Disposal of Assets",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "netRevenues",
      label: "Net Revenues",
      currency: true,
      colSpacer: 2,
    },
    { field: "transfersTitle", label: "Transfers", colSpacer: 1 },
    {
      field: "transferredIn",
      label: "Transfers In",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "transferredOut",
      label: "Transfers Out",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "netTransfers",
      label: "Net Transfers",
      currency: true,
      colSpacer: 2,
    },
    { field: "expendituresTitle", label: "Expenditures", colSpacer: 1 },
    {
      field: "broadband",
      label: "Broadband Connectivity",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "brownfield",
      label: "Brownfield Redevelopment",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "capacityBldg",
      label: "Capacity Building",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "commEnergy",
      label: "Community Energy Systems",
      currency: true,
      colSpacer: 2,
    },
    { field: "culture", label: "Culture", currency: true, colSpacer: 2 },
    {
      field: "disaster",
      label: "Disaster Mitigation",
      currency: true,
      colSpacer: 2,
    },
    { field: "water", label: "Drinking Water", currency: true, colSpacer: 2 },
    hideFireStationRow
      ? null
      : {
          field: "fireStation",
          label: "Fire Stations",
          currency: true,
          colSpacer: 2,
        },
    {
      field: "roadsBridges",
      label: "Local Roads and Bridges",
      currency: true,
      colSpacer: 2,
    },
    { field: "transit", label: "Public Transit", currency: true, colSpacer: 2 },
    { field: "recreation", label: "Recreation", currency: true, colSpacer: 2 },
    {
      field: "airport",
      label: "Regional and Local Airports",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "shortline",
      label: "Short-line Rail",
      currency: true,
      colSpacer: 2,
    },
    {
      field: "shortsea",
      label: "Short-sea Shipping",
      currency: true,
      colSpacer: 2,
    },
    { field: "solidWaste", label: "Solid Waste", currency: true, colSpacer: 2 },
    { field: "sport", label: "Sports", currency: true, colSpacer: 2 },
    { field: "tourism", label: "Tourism", currency: true, colSpacer: 2 },
    { field: "wastewater", label: "Wastewater", currency: true, colSpacer: 2 },
    {
      field: "netExpenditures",
      label: "Net Expenditures",
      currency: true,
      colSpacer: 2,
    },
    { field: "closeBalance", label: "Closing Balance", currency: true },
  ].filter(Boolean);

  // Sort data by year, descending
  const data = unsortedData.sort((a, b) => b.year - a.year);

  // Set negative fields to be negative (as they are stored as positive in the DB)
  const negativeFields = [
    "transferredOut",
    "airport",
    "broadband",
    "brownfield",
    "capacityBldg",
    "commEnergy",
    "culture",
    "disaster",
    "fireStation",
    "recreation",
    "roadsBridges",
    "shortline",
    "shortsea",
    "solidWaste",
    "sport",
    "transit",
    "tourism",
    "wastewater",
    "water",
  ];
  for (let i = 0; i < data.length; i += 1) {
    if (hideFireStationRow) {
      data[i].fireStation = "";
    }
    for (let j = 0; j < negativeFields.length; j += 1) {
      if (data[i][negativeFields[j]] && data[i][negativeFields[j]] > 0)
        data[i][negativeFields[j]] *= -1;
    }
  }

  // Create array to turn into sheet
  const sheetArray = rowsFormat.map((rowFormat) => [
    ...Array(rowFormat.colSpacer ?? 0),
    rowFormat.label,
    ...Array(2 - (rowFormat.colSpacer ?? 0)),
    ...data.map((record) =>
      rowFormat.currency && record[rowFormat.field] == null
        ? "-"
        : record[rowFormat.field]
    ),
  ]);

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(sheetArray, { skipHeader: true });

  // Set formulas
  for (let colIndex = 3; colIndex < data.length + 3; colIndex += 1) {
    const openBalanceIndex = XLSX.utils.encode_cell({ r: 3, c: colIndex });
    const previousCloseBalanceIndex = XLSX.utils.encode_cell({
      r: 33,
      c: colIndex + 1,
    });
    if (colIndex < data.length + 2)
      worksheet[openBalanceIndex] = { f: previousCloseBalanceIndex };

    const netRevenuesIndex = XLSX.utils.encode_cell({ r: 8, c: colIndex });
    const revenuesRange = XLSX.utils.encode_range({
      s: { r: 5, c: colIndex },
      e: { r: 7, c: colIndex },
    });
    worksheet[netRevenuesIndex] = { f: `SUM(${revenuesRange})` };

    const netTransfersIndex = XLSX.utils.encode_cell({ r: 12, c: colIndex });
    const transfersRange = XLSX.utils.encode_range({
      s: { r: 10, c: colIndex },
      e: { r: 11, c: colIndex },
    });
    worksheet[netTransfersIndex] = { f: `SUM(${transfersRange})` };

    const netExpendituresIndex = XLSX.utils.encode_cell({
      r: hideFireStationRow ? 31 : 32,
      c: colIndex,
    });
    const expendituresRange = XLSX.utils.encode_range({
      s: { r: 14, c: colIndex },
      e: { r: hideFireStationRow ? 30 : 31, c: colIndex },
    });
    worksheet[netExpendituresIndex] = { f: `SUM(${expendituresRange})` };

    const closingBalanceIndex = XLSX.utils.encode_cell({
      r: hideFireStationRow ? 32 : 33,
      c: colIndex,
    });
    worksheet[closingBalanceIndex] = {
      f: `SUM(${openBalanceIndex}, ${netRevenuesIndex}, ${netTransfersIndex}, ${netExpendituresIndex})`,
    };
  }
  // Set Row Heights
  const bigRowIndices = [3, 4, 9, 13, 33];
  worksheet["!rows"] = [...Array(34).keys()].map((index) =>
    bigRowIndices.includes(index) ? { hpt: 30 } : {}
  );

  // Set Column Widths
  worksheet["!cols"] = [
    { wch: 2 },
    { wch: 2 },
    { wch: 30 },
    ...data.map(() => ({ wch: 20 })),
  ];

  // Set Currency Format
  for (let rowIndex = 0; rowIndex < rowsFormat.length; rowIndex += 1) {
    for (let colIndex = 3; colIndex < data.length + 3; colIndex += 1) {
      if (rowsFormat[rowIndex].currency) {
        const cellIndex = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
        worksheet[cellIndex].z = "$#,##0.00 ;($#,##0.00)";
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, "Annual Reports");
  const dateNow = DateTime.now().toFormat("yyyyMMddHHmmss");
  const defaultFilename = `Financial Summary_${dateNow}`;
  XLSX.writeFile(workbook, `${filename ?? defaultFilename}.xlsx`);
};

const replaceHtmlTags = (text) => {
  const regex = /<a href="(.*?)"(?:\s+target="_blank")?>(.*?)<\/a>/g;
  return text.replace(regex, (match, url, linkText) => `${linkText} (${url})`);
};

const exportQuestionnairesSummary = (data, currentQuestionnaireType) => {
  const headers = ["Question", "Answer", "Year"];
  const sheetArray = [
    headers,
    ...data.reduce((acc, { details, year, attachments }) => {
      const questionAnswers = details.map(({ question, answer }) => [
        question,
        replaceHtmlTags(answer),
        year,
      ]);

      let attachmentInfo = [];

      if (currentQuestionnaireType === questionnaireTypes.assetMgmt) {
        if (attachments.length === 0) {
          attachmentInfo = [
            ["Attached files", "There are no files available yet.", year],
          ];
        } else {
          attachmentInfo = [
            [`Attached files`, `${attachments.length}`, year],
            ...attachments.map(({ fileName }) => [fileName, "", year]),
          ];
        }
      }
      return [...acc, ...questionAnswers, ...attachmentInfo];
    }, []),
  ];

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(sheetArray, { skipHeader: true });

  const specialColumnSizes = {
    question: 80,
    year: 10,
  };
  // Set Column Widths
  worksheet["!cols"] = [
    ...headers.map((header) => ({
      wch: specialColumnSizes[header.toLowerCase()] ?? 20,
    })),
  ];
  XLSX.utils.book_append_sheet(workbook, worksheet, "Questionnaires");
  const dateNow = DateTime.now().toFormat("yyyyMMddHHmmss");
  XLSX.writeFile(workbook, `Questionnaires Summary_${dateNow}.xlsx`);
};

const formatDate = (unformattedDate, addTime = false) => {
  const date = new Date(unformattedDate);
  const year = date.getFullYear();
  const month = date.toLocaleString("en-CA", { month: "2-digit" });
  const day = date.toLocaleString("en-CA", { day: "2-digit" });
  if (addTime) {
    const time = date.toLocaleString("en-CA", {
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    return `${year}-${month}-${day}-${time}`;
  }
  return `${year}-${month}-${day}`;
};

const generateWorkbookAndWorksheet = (
  data,
  columns,
  specialColumnSizes = {},
  mappedFieldKeys = {}
) => {
  const sheetArray = [
    columns.map(({ label }) => label),
    ...data.map((row) =>
      columns.map(({ fieldKey, customExport, displayType, isRounded }) => {
        if (customExport) {
          return customExport(row);
        }
        const value = row[mappedFieldKeys?.[fieldKey] ?? fieldKey];

        if (displayType === displayTypes.date) {
          return value && formatDate(value);
        }

        const isCurrency = displayType === displayTypes.currency;
        if (isCurrency && isRounded) {
          return customRound(value, 0) ?? 0;
        }
        return value ?? (isCurrency ? 0 : "");
      })
    ),
  ];

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(sheetArray, { skipHeader: true });

  // Set Column Widths
  worksheet["!cols"] = [
    ...columns.map(({ fieldKey }) => ({
      wch: specialColumnSizes[fieldKey] ?? 20,
    })),
  ];

  // Set Currency Format
  for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
    for (let rowIndex = 1; rowIndex < data.length + 1; rowIndex += 1) {
      const value = columns[colIndex];
      const cellIndex = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
      if (!worksheet[cellIndex] || value?.displayType !== displayTypes.currency)
        continue;

      if (value.isRounded) {
        worksheet[
          cellIndex
        ].z = `_("$"* #,##0_);_("$"* (#,##0);_("$"* "-"??_);_(@_)`;
      } else {
        worksheet[
          cellIndex
        ].z = `_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)`;
      }
    }
  }

  return { workbook, worksheet };
};

const exportToExcel = (data, columns, title = null, exportConfig = {}) => {
  const {
    specialColumnSizes,
    mappedFieldKeys,
    sheetName,
    removeTimestampFromName,
  } = exportConfig;

  const { workbook, worksheet } = generateWorkbookAndWorksheet(
    data,
    columns,
    specialColumnSizes ?? {},
    mappedFieldKeys ?? {}
  );

  let fileName = `AMO - ${title ?? "Export"}`;
  if (!removeTimestampFromName || !title) {
    const dateNow = DateTime.now().toFormat("yyyyMMddHHmmss");
    fileName = `${fileName} - ${dateNow}`;
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName ?? "AMO");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

const exportRecentComms = (data, tableCells, specialColumnSizes = {}) => {
  const { workbook, worksheet } = generateWorkbookAndWorksheet(
    data,
    tableCells,
    specialColumnSizes
  );

  XLSX.utils.book_append_sheet(workbook, worksheet, "Recent Comms");
  const dateNow = DateTime.now().toFormat("yyyyMMddHHmmss");
  XLSX.writeFile(workbook, `AMO - Recent Communications - ${dateNow}.xlsx`);
};

const exportProjectHistory = async (data, projectId) => {
  const wb = await XlsxPopulate.fromBlankAsync();
  const sheet = wb.sheet(0);
  sheet.name("Project History");

  const headers = [
    "Project ID (AMO)",
    "Project ID (Municipal)",
    "Municipal ID",
    "Municipality",
    "Category",
    "Sub Category",
    "Title",
    "Scope Of Work",
    "Objectives",
    "Investment Type",
    "Location",
    "Start Date",
    "Construction End Date",
    "Financing End Date",
    "Total Cost",
    "Total Budgeted",
    "Funding Applied Sum",
    "Other federal funds?",
    "Provincial funds?",
    "Stacking limits on federal funding exceeded?",
    "Communications Activities",
    "Additional Comments",
    "Other Results",
    "Signage Posted",
    "Media Uploaded",
    "No Outputs Apply",
    "Is Published",
    "Creation Date",
    "Revision date",
    "User",
  ];
  headers.forEach((header, column) => {
    sheet.cell(1, column + 1).value(header);
    const headerWidth = header.toString().length * 1.2;
    sheet.column(column + 1).width(headerWidth);
  });
  data.forEach((projectHistory, row) =>
    [
      projectHistory.projectId,
      projectHistory.municipalityInternalId,
      projectHistory.municipalityId,
      projectHistory.municipalityName,
      projectHistory.category,
      projectHistory.subCategory,
      projectHistory.title,
      projectHistory.scopeOfWork,
      projectHistory.objectives,
      projectHistory.investmentType,
      projectHistory.location,
      projectHistory.startDate,
      projectHistory.constructionEndDate,
      projectHistory.financingEndDate,
      projectHistory.totalCost,
      projectHistory.totalBudgeted,
      projectHistory.fundingAppliedSum,
      booleanToTextOrUndefined(projectHistory.otherFederalFunds) ?? "",
      booleanToTextOrUndefined(projectHistory.provincialFunds) ?? "",
      booleanToTextOrUndefined(projectHistory.stackingLimits) ?? "",
      projectHistory.communicationsActivities,
      projectHistory.additionalComments,
      projectHistory.otherResults,
      projectHistory.signagePosted ? "Yes" : "No",
      projectHistory.hasFiles,
      projectHistory.noOutputsApply ? "Yes" : "No",
      projectHistory.isPublished ? "Yes" : "No",
      projectHistory.creationDate,
      projectHistory.updatedDate,
      projectHistory.userName,
    ].forEach((value, column) => {
      const cell = sheet.cell(row + 2, column + 1);
      if (
        typeof value === "string" &&
        value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:.\d+)?$/)
      ) {
        // add time to date for columns Creation Date and Revision date
        const addTime = [27, 28].includes(column);
        cell.value(formatDate(value, addTime));
      } else {
        cell.value(value);
      }
      if (row > 0 && sheet.cell(row + 1, column + 1).value() !== cell.value())
        cell.style("fill", "ffff00");
    })
  );
  downloadWorkbook(
    wb,
    `Project_${projectId}_${DateTime.now().toFormat("yyyyMMddHHmmss")}.xlsx`
  );
};

const exportQuestionnaireToWord = async (
  questions,
  fileName = "questionnaire"
) => {
  const mappedQuestions = questions.map(
    ({ answerType, text, answers, selectedAnswer }) => ({
      type: answerType,
      question: text,
      answers: answers?.length
        ? answers.map(({ value, id }) => ({
            value,
            selected: selectedAnswer.some(({ answerId }) => id === answerId),
          }))
        : selectedAnswer.map(({ value, file }) => ({
            value: file?.name ?? value,
            selected: null,
          })),
    })
  );

  const questionTitle = (text) =>
    new Paragraph({
      children: [
        new TextRun({
          text,
          color: "000000",
          bold: true,
          size: 20,
        }),
      ],
      heading: HeadingLevel.TITLE,
    });

  const questionAnswer = (text, monospaceTextPrefix = null) =>
    new Paragraph({
      children: [
        ...(monospaceTextPrefix
          ? [
              new TextRun({
                text: `${monospaceTextPrefix} `,
                color: "000000",
                font: "monospace",
              }),
            ]
          : []),
        new TextRun({ text }),
      ],
    });

  const questionHint = (text, boldItalicText) =>
    new Paragraph({
      children: [
        new TextRun({ text }),
        new TextRun({
          text: boldItalicText,
          bold: true,
          italics: true,
        }),
      ],
    });

  const emptyParagraph = () => new Paragraph({ text: "" }); // Extra line for better visibility

  const generateDoc = (lines) =>
    new Document({ sections: [{ children: lines }] });

  const cleanHtml = (htmlString) =>
    htmlString?.replace(/<[^>]*>/g, "").replaceAll("&nbsp;", "");

  const lines = mappedQuestions.flatMap(
    ({ type, question, answers }, index) => {
      const isDropdown = [
        questionnaireAnswerTypes.multiSelectMultipleChoice.text,
        questionnaireAnswerTypes.singleSelectMultipleChoice.text,
        questionnaireAnswerTypes.yesNo.text,
      ].includes(type);
      const isMultipleChoice =
        type === questionnaireAnswerTypes.multiSelectMultipleChoice.text;
      return [
        ...(index > 0 ? [emptyParagraph()] : []),
        questionTitle(cleanHtml(question)),
        ...(!answers?.length
          ? [emptyParagraph()]
          : [
              ...(isDropdown
                ? [
                    questionHint(
                      isMultipleChoice
                        ? "Multiple choice: "
                        : "Single choice: ",
                      isMultipleChoice
                        ? "Select any answer that apply"
                        : "Select only one answer that apply"
                    ),
                    emptyParagraph(),
                  ]
                : []),
              ...answers.map(({ value, selected }) => {
                if (!isDropdown) {
                  return questionAnswer(cleanHtml(value));
                }
                const filled = isMultipleChoice ? "[x]" : "(x)";
                const empty = isMultipleChoice ? "[ ]" : "( )";
                return questionAnswer(
                  cleanHtml(value),
                  selected ? filled : empty
                );
              }),
            ]),
        emptyParagraph(),
      ];
    }
  );
  const docx = generateDoc(lines);

  const blob = await Packer.toBlob(docx);
  downloadFile(blob, `${fileName}.docx`);
};

const exportToExcelWithWorker = (
  data,
  columns,
  title = null,
  exportConfig = {},
  callback = null
) => {
  const { removeTimestampFromName } = exportConfig;

  let fileName = `AMO - ${title ?? "Export"}`;
  if (!removeTimestampFromName || !title) {
    const dateNow = DateTime.now().toFormat("yyyyMMddHHmmss");
    fileName = `${fileName} - ${dateNow}`;
  }

  // Worker code based on the documentation: https://docs.sheetjs.com/docs/demos/bigdata/worker/
  const workerCode = `
  /* load standalone script from CDN */
  importScripts("https://cdn.sheetjs.com/xlsx-0.17.0/package/dist/xlsx.full.min.js");

  const formatDate = ${formatDate.toString()};
  const customRound = ${customRound.toString()};
  
  /* this callback will run once the main context sends a message */
  self.addEventListener('message', async (e) => {
    try {
      const {
        data,
        columns,
        title,
        exportConfig,
      } = e.data;

      const {
        specialColumnSizes,
        mappedFieldKeys,
        sheetName,
      } = exportConfig;

      
      const sheetArray = [
        columns.map(({ label }) => label),
        ...data.map((row) =>
          columns.map(({ fieldKey, customExport, displayType, isRounded }) => {
            if (customExport) {
              return customExport(row);
            }
            const value = row[mappedFieldKeys?.[fieldKey] ?? fieldKey];
    
            if (displayType === "${displayTypes.date}") {
              return value && formatDate(value);
            }
    
            const isCurrency = displayType === "${displayTypes.currency}";
            if (isCurrency && isRounded) {
              return customRound(value, 0) ?? 0;
            }
            return value ?? (isCurrency ? 0 : "");
          })
        ),
      ];
      

      /* Create a new workbook from the data */      
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(sheetArray, { skipHeader: true });

      worksheet["!cols"] = [
        ...columns.map(({ fieldKey }) => ({
          wch: specialColumnSizes?.[fieldKey] ?? 20,
        })),
      ];

      // Set Currency Format
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        for (let rowIndex = 1; rowIndex < data.length + 1; rowIndex += 1) {
          const value = columns[colIndex];
          const cellIndex = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
          if (!worksheet[cellIndex] || value?.displayType !== "${
            displayTypes.currency
          }")
            continue;

          if (value.isRounded) {
            worksheet[
              cellIndex
            ].z = \`_("$"* #,##0_);_("$"* (#,##0);_("$"* "-"??_);_(@_)\`;
          } else {
            worksheet[
              cellIndex
            ].z = \`_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)\`;
          }
        }
      }
      
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName ?? "AMO");
  
      /* Reply with workbook */
      postMessage({ workbook });
    } catch(e) {
      /* Pass the error message back */
      postMessage({error: String(e.message || e).bold() });
    }
  }, false);
  `;

  /* this mantra embeds the worker source in the function */
  const worker = new Worker(URL.createObjectURL(new Blob([workerCode])));

  /* when the worker sends back the data, create a download */
  worker.onmessage = (event) => {
    const { error, workbook } = event.data;
    if (error) {
      if (callback) callback({ error });
      return;
    }

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
    if (callback) callback({ success: true });
  };

  /* post a message to the worker */
  worker.postMessage({
    data,
    columns,
    title,
    exportConfig,
  });

  return { terminate: () => worker.terminate() };
};

export {
  exportFinancialSummary,
  exportQuestionnairesSummary,
  exportRecentComms,
  exportProjectHistory,
  exportQuestionnaireToWord,
  exportToExcel,
  exportToExcelWithWorker,
};
