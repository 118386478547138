import React from "react";
import {
  Popover,
  Button,
  Grid,
  IconButton,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../constants/colors";
import CloseIcon from "@material-ui/icons/Close";

// sets the Material-UI styles for this component
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    color: colors.black,
    backgroundColor: colors.white,
    marginRight: "10%",
    padding: theme.spacing(0),
  },
  button: {
    height: "3.125rem",
    borderWidth: "0.125rem",
  },
  iconButton: {
    padding: theme.spacing(0.25),
    color: ({ variant }) => (variant === "error" ? colors.white : null),
  },
  title: {
    fontWeight: 480,
    fontSize: "1.5rem",
  },
  outerGridContainer: {
    padding: theme.spacing(2),
  },
  topGridContainer: {
    padding: theme.spacing(0),
    flexWrap: "nowrap",
  },
  topGridItem: {
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
  bottomGridContainer: {
    borderTop: "0.0625rem solid grey",
    padding: theme.spacing(1.8),
    paddingRight: theme.spacing(1),
  },
  headerContainer: {
    paddingBottom: theme.spacing(0),
    flexGrow: 1,
  },
  loadingProgress: {
    color: colors.grey.light,
  },
}));

/**
 * creates a modal dialog using Material-UI's Popover component
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.variant - controls the color scheme of the popover valid values are {"success", "error", "default"}
 * @param {boolean} props.open - controls visibility of the popover
 * @param {string} props.title - text to display in the title
 * @param {string} props.text - text to display as the message
 * @param {boolean} props.showButton - controls visibility of the function button
 * @param {Function} props.closePopover - function that hides the popover
 * @param {boolean} props.showSecondButton - controls visibility of the function button
 * @param {string} props.width - override the width of the modal, restricted by the minWidth and maxWidth set in the paper class
 * @param {number} props.buttonsLeftSpacer - number from 1-12, of how many columns of empty space to put to left of the action buttons
 * @param {string} props.buttons - buttons to put at bottom
 * @param {string} props.buttons.key - unique key to refer to button
 * @param {string} props.buttons.label - button text
 * @param {string} props.buttons.color - button color (primary or secondary)
 * @param {string} props.buttons.variant - MUI variant (outlined or contained)
 * @param {Function} props.buttons.onClick - function to run on button click
 * @param {Function} props.buttons.disabled - sets the disabled state of the button
 * @returns - the popover component, styled for the AMO project
 */
const AmoModal = (props) => {
  const {
    title,
    showButton,
    open,
    closePopover,
    children,
    width,
    buttonsLeftSpacer,
    buttons,
  } = props;
  // set initial values
  const styleSettings = {
    textColor: colors.black,
    backColor: colors.white,
    themeColor: "primary",
    icon: null,
  };

  const classes = useStyles(props);

  // assign the button section
  let buttonRow = null;
  if (showButton) {
    buttonRow = (
      <Grid container className={classes.bottomGridContainer}>
        {buttonsLeftSpacer ? <Grid item xs={buttonsLeftSpacer} /> : ""}
        <Grid item container xs={12 - buttonsLeftSpacer} spacing={1}>
          {buttons.map((button, index) => (
            <Grid item xs={12 / buttons.length} key={button.key}>
              <Button
                data-testid={`amoModalFunctionButton-${button.key}`}
                classes={{
                  root: classes.button,
                }}
                style={{
                  width: button?.width,
                  marginLeft: button?.marginLeft,
                }}
                color={button.color || styleSettings.themeColor}
                variant={button.variant || "contained"}
                onClick={button.onClick}
                disabled={button.disabled}
              >
                {button.loading ? (
                  <CircularProgress
                    className={classes.loadingProgress}
                    size={28}
                  />
                ) : (
                  button.label
                )}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <Popover
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      anchorEl={document.body}
      anchorOrigin={{
        horizontal: "center",
        vertical: "center",
      }}
      transformOrigin={{
        horizontal: "center",
        vertical: "center",
      }}
      PaperProps={{
        "data-testid": "amoModalPaper",
        square: false,
        variant: "elevation",
        elevation: 20,
        style: { maxWidth: width ?? undefined },
      }}
      onClose={closePopover}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Grid
            container
            classes={{
              root: classes.outerGridContainer,
            }}
            direction="column"
            wrap="nowrap"
          >
            <Grid item>
              <Grid
                container
                classes={{
                  root: classes.topGridContainer,
                }}
                direction="row"
              >
                <Grid item className={classes.headerContainer}>
                  <Typography
                    data-testid="amoModalTitle"
                    component="div"
                    className={classes.title}
                  >
                    <Box color={styleSettings.textColor}>{title}</Box>
                  </Typography>
                </Grid>
                <Grid
                  item
                  classes={{
                    root: classes.topGridItem,
                  }}
                >
                  <IconButton
                    data-testid="amoModalCloseButton"
                    classes={{
                      root: classes.iconButton,
                    }}
                    onClick={closePopover}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>&nbsp;</Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box>{children}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{buttonRow}</Grid>
      </Grid>
    </Popover>
  );
};

// set the prop-types for this component
AmoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closePopover: PropTypes.func.isRequired,
  title: PropTypes.string,
  showButton: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.string,
  buttonsLeftSpacer: PropTypes.number,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.oneOf(["primary", "secondary"]),
      variant: PropTypes.oneOf(["outlined", "contained"]),
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      className: PropTypes.string,
      width: PropTypes.string,
      loading: PropTypes.bool,
    })
  ).isRequired,
};

// set prop-types defaults for this component
AmoModal.defaultProps = {
  title: "Message",
  showButton: false,
  children: null,
  width: null,
  buttonsLeftSpacer: 0,
};

export default AmoModal;
