/**
 * The colors used to style the theme and the application
 *
 * @returns {object} The colors.
 */
const colors = {
  green: {
    light: "#8dba69",
    main: "#4d7f2a",
    dark: "#0a3500",
    contrastText: "#fff",
    border: "#23AE49",
  },
  grey: {
    light: "#F1F1F1",
    mediumLight: "#F5F5F5",
    medium: "#D4D4D4",
    main: "#939393",
    dark: "#666666",
    contrastText: "#000",
    icon: "#2C2C2C",
  },
  red: {
    main: "#E05343",
    dark: "#a2342b",
    warning: "#FA0000",
    contrastText: "#fff",
  },
  slider: {
    backgroundBar: "#DADADA",
    activeBar: "#151515",
    thumbDetail: "#545454",
  },
  black: "#000000",
  white: "#ffffff",
  yellow: "#FFB600",
  background: "#FAFAFA",
};

export default colors;
