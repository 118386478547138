import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./constants/theme";
import AmoRouter from "components/AmoRouter";
import AmoProviderLayer from "components/AmoProviderLayer";
import { UserPromptProvider } from "./contexts/UserPromptContext";
import { oneDayInMs } from "constants/caching";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      cacheTime: oneDayInMs,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

/**
 * The app component.
 *
 * @returns {React.Element} The app.
 */
const App = () => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <UserPromptProvider>
        <AmoProviderLayer>
          <AmoRouter />
        </AmoProviderLayer>
      </UserPromptProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>
);

export default App;
