import { useQuery } from "react-query";
import { projectService } from "api/services/projectService";

// Query Keys
import { projectKeys } from "./projectQueryKeys";

/**
 * Store server's state of current project's information to use for UI validation
 *
 * @param {string} projectId The id of the project being fetched
 *
 * @returns The query object return by the useQuery hook
 */
export const useProjectDateValidationInfo = (projectId) =>
  useQuery(
    projectKeys.projectValidation(projectId),
    () => projectService.getValidationInfo(projectId),
    {
      select: ({ data }) => data,
      enabled: !!projectId && projectId !== "new",
    }
  );
