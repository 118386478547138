import { createTheme } from "@material-ui/core/styles";
import colors from "./colors";

/*
 * The Material UI theme setup and global component styling overrides.
 *
 * @returns {object} The theme object.
 */
const theme = createTheme({
  palette: {
    primary: {
      light: colors.green.light,
      main: colors.green.main,
      dark: colors.green.dark,
      contrastText: colors.green.contrastText,
    },
    secondary: {
      main: colors.red.main,
      dark: colors.red.dark,
      contrastText: colors.red.contrastText,
    },
    error: {
      main: colors.red.main,
      dark: colors.red.dark,
      contrastText: colors.red.contrastText,
    },
    success: {
      light: colors.green.light,
      main: colors.green.main,
      dark: colors.green.dark,
      contrastText: colors.green.contrastText,
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Roboto"].join(","),
    body2: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    body1: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "normal",
    },
    caption: {
      fontFamily: "Montserrat",
      fontSize: "0.75rem",
      fontWeight: "lighter",
    },
    h1: {
      fontFamily: "Montserrat",
      fontSize: "2.25rem",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Montserrat",
      fontSize: "2.25rem",
      fontWeight: "normal",
    },
    h3: {
      fontFamily: "Montserrat",
      fontSize: "1.75rem",
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Montserrat",
      fontSize: "1.75rem",
      fontWeight: "normal",
    },
    h5: {
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: "normal",
    },
    subtitle1: {
      fontFamily: "Montserrat",
      fontSize: "1.125rem",
      fontWeight: "bold",
    },
    subtitle2: {
      fontFamily: "Montserrat",
      fontSize: "1.125rem",
      fontWeight: "normal",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        // Full screen style
        "html, body": {
          height: "100%",
          margin: 0,
          overflow: "hidden",
          // Firefox scrollbar style
          scrollbarColor: `${colors.grey.main} ${colors.background}`,
        },
        "#root": {
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
        },
        // Scrollbar style
        "*::-webkit-scrollbar": {
          width: "5px",
          height: "8px",
          marginRight: 1,
        },
        "*::-webkit-scrollbar-track": {
          background: "none",
        },
        "*::-webkit-scrollbar-thumb": {
          background: colors.grey.main,
          borderRadius: "2px",
        },
        "*::-webkit-scrollbar-thumb:hover": {
          background: colors.grey.dark,
        },
      },
    },
    MuiButton: {
      // all buttons will take these styles
      root: {
        textTransform: "none",
        borderRadius: "25px",
        width: "100%",
      },
      label: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        width: "100%",
        alignItems: "align-items-center",
      },
      outlinedPrimary: {
        color: colors.green.main,
        backgroundColor: colors.green.contrastText,
        border: `0.2em solid ${colors.green.main}`,
        "&:hover": {
          color: colors.green.main,
          backgroundColor: colors.grey.light,
          border: `0.2em solid ${colors.green.main}`,
        },
        "&$disabled": {
          color: colors.grey.main,
          backgroundColor: colors.white,
        },
      },
      outlinedSecondary: {
        color: colors.red.main,
        backgroundColor: colors.red.contrastText,
        border: `0.2em solid ${colors.red.main}`,
        "&:hover": {
          color: colors.red.main,
          backgroundColor: colors.grey.light,
          border: `0.2em solid ${colors.red.main}`,
        },
        "&$disabled": {
          color: colors.grey.main,
          backgroundColor: colors.white,
        },
      },
      containedPrimary: {
        color: colors.green.contrastText,
        backgroundColor: colors.green.main,
        "&:hover": {
          backgroundColor: colors.green.dark,
        },
        "&$disabled": {
          color: colors.white,
          backgroundColor: colors.grey.main,
        },
      },
      containedSecondary: {
        color: colors.red.contrastText,
        backgroundColor: colors.red.main,
        "&:hover": {
          backgroundColor: colors.red.dark,
        },
        "&$disabled": {
          color: colors.white,
          backgroundColor: colors.grey.main,
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        // handles the default "unchecked" state
        color: colors.grey.dark,
        backgroundColor: "transparent",
        "&$checked": {
          opacity: 1.2,
          backgroundColor: "transparent",
        },
        "&$disabled": {
          backgroundColor: "transparent",
        },
        "&$checked + $track": {
          opacity: 0.5,
        },
        "&$disabled + $track": {
          opacity: 0.1,
        },
      },
      colorPrimary: {
        "&$checked": {
          color: colors.green.main,
        },
        "&$disabled": {
          color: colors.grey.main,
        },
        "&$checked + $track": {
          backgroundColor: colors.green.light,
        },
        "&$disabled + $track": {
          backgroundColor: colors.grey.light,
        },
      },
      colorSecondary: {
        "&$checked": {
          color: colors.red.dark,
        },
        "&$disabled": {
          color: colors.grey.main,
        },
        "&$checked + $track": {
          backgroundColor: colors.red.main,
        },
        "&$disabled + $track": {
          backgroundColor: colors.grey.light,
        },
      },
    },
    MuiRadio: {
      root: {
        fontFamily: "Roboto",
        color: colors.green.main,
      },
      colorPrimary: {
        "&$checked": {
          color: colors.green.main,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        "&$disabled": {
          color: colors.grey.main,
        },
      },
      colorSecondary: {
        "&$checked": {
          color: colors.red.main,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        "&$disabled": {
          color: colors.grey.main,
        },
      },
    },
    MuiLink: {
      root: {
        TypographyClasses: {
          fontFamily: "Montserrat",
          fontSize: "1em",
          color: colors.green.main,
        },
      },
    },
    MuiSelect: {
      root: {
        fontFamily: "Roboto",
      },
      select: {
        minWidth: "240px",
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "1.5625rem",
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Roboto",
        "&:hover": {
          color: colors.green.main,
          backgroundColor: colors.white,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: "Roboto",
        color: colors.green.main,
        "&$error": {
          "&:after": {
            fontFamily: "Material Icons",
            content: "'warning'",
            color: colors.red.main,
            position: "absolute",
            right: "0px",
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Roboto",
      },
      input: {
        "&::placeholder": {
          textOverflow: "ellipsis !important",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.grey.dark,
        fontFamily: "Roboto",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "85%",
      },
      shrink: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: "Roboto",
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Roboto",
      },
    },
    MuiDivider: {
      root: {
        width: "100%",
      },
    },
  }, // overrides
});

export default theme;
