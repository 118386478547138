import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Divider, Typography, Box } from "@material-ui/core";
import colors from "constants/colors";

// Query Hooks
import { useHelpTitles } from "./helpPageHooks";

// the sub-components
import HelpPageNavBarItem from "./HelpPageNavBarItem";

const menuWidth = "23rem";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: "0.625rem",
  },
  paper: {
    color: colors.black,
  },
  menu: {
    width: menuWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100%",
    overflow: "hidden",
  },
  outlined: {
    border: true,
    borderColor: colors.grey.main,
  },
  rounded: {
    borderRadius: "0.625rem",
  },
}));

/**
 * This component helps display the different sections of the Help Page as well
 * as allow navigation to those sections
 *
 * @param {object} props - the properties of this component
 * @param {string} props.variant - helps the component decide styles
 * @param {boolean} props.respondToMouseEvents - when true allows mouse events to change component UI
 * @returns - the Project NavBar component, styled for the AMO Project
 */
const HelpPageNavBar = (props) => {
  // call hooks to create the styles needed for this control
  const classes = useStyles();

  // Set up help titles query
  const { data: helpData } = useHelpTitles();

  const Title = () => (
    <>
      <Grid
        item
        container
        classes={{
          root: classes.header,
        }}
      >
        <Grid
          item
          classes={{
            root: classes.title,
          }}
        >
          <Typography variant="h1">
            <Box color={colors.green.dark}>Help</Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid item />
    </>
  );

  // this sets up the menu items, passing in the status text retrieved from the API
  const menuContent = (
    <>
      <HelpPageNavBarItem
        linkText="FAQs"
        navLink="/help/faqs"
        menuItemContent="FAQs"
      />
      <Divider orientation="horizontal" variant="fullWidth" light />

      {helpData?.data?.map((content) => (
        <>
          <HelpPageNavBarItem
            key={content.id}
            linkText={content.title}
            navLink={`/help/${content.permalink}`}
            menuItemContent={content.title}
          />
          <Divider orientation="horizontal" variant="fullWidth" light />
        </>
      ))}
    </>
  );

  // finally return the created component
  return (
    <Grid container direction="column">
      <Title />
      <Grid item>
        <Paper
          square={false}
          variant="outlined"
          className={classes.menu}
          classes={{
            root: classes.menu,
            rounded: classes.rounded,
            outlined: classes.outlined,
          }}
        >
          <Grid
            container
            direction="column"
            spacing={0}
            alignItems="flex-start"
          >
            {menuContent}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HelpPageNavBar;
