import React, { useState, useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { useParams, useHistory, useLocation } from "react-router-dom";

import colors from "constants/colors";
import { routes, replaceParams } from "constants/routes";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";

import ProjectReviewForm from "pages/amo/project-reviews/ProjectReviewForm";
import ListPageWrapper from "components/ListPageWrapper";
import HistoryLog from "components/amo/HistoryLog";
import ReviewDataInput from "components/amo/reviewLog/ReviewDataInput";
import AmoPageHeader from "components/AmoPageHeader";
import ContactInfoModal from "components/amo/ContactInfoModal";
import AmoConfirmPopover from "components/AmoConfirmPopover";
import { useProjectReviewDataById } from "./projectReviewsHooks";
import { projectService } from "api/services/projectService";
import { errorMessages } from "constants/errorMessages";

import { useWrapApi } from "hooks/wrapApiHook";
import { useHistoryLogs } from "components/amo/historyLogHooks";
import { useIsMounted } from "hooks/useIsMounted";
import { copyPageUrl } from "utils/url";
import { exportProjectHistory } from "utils/export";
import NotFoundPage from "pages/NotFoundPage";
import reviewTypes from "constants/reviewTypes";
import { projectReportStatuses } from "constants/amoTableDropdownOptions";
import { useMunicipalContext } from "contexts/MunicipalContext";

import AmoDeletionModal from "components/amo/AmoDeletionModal";

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: colors.black,
  },
  pageContent: {
    // 24px 16px 0px 16px
    padding: "1.5rem 1rem 0rem 1rem",
  },
  button: {
    minHeight: "3.125rem",
    fontSize: "1rem",
    borderWidth: "0.125rem",
    "&:hover": {
      borderWidth: "0.125rem",
    },
  },
  iconButton: {
    border: `0.125rem solid ${theme.palette.primary.main}`,
  },
  ellipsisIcon: {
    cursor: "pointer",
    fontSize: "3.2rem",
  },
  menuItemIcon: {
    color: "black",
    minWidth: "initial",
    marginRight: "1.125rem",
  },
  categoryIcon: {
    width: "100%",
    textAlign: "center",
  },
  dividerContainer: {
    // 24px
    paddingBottom: "1.5rem",
  },
  buttonsBar: {
    paddingBottom: "1.438rem !important",
  },
  historyPadding: {
    // 16px
    paddingBottom: "1rem",
  },
  formContent: {
    // 36px
    paddingRight: "2.25rem",
  },
}));

/**
 * The Project Review Page.
 *
 * @returns {React.Component} Project Review Page component.
 */
const ProjectReviewPage = () => {
  const classes = useStyles();
  const { id: projectId } = useParams();
  const { showSnackbar } = useSnackbar();
  const { getMunicipalityName } = useMunicipalContext();

  const mounted = useIsMounted();

  const [showContactInformation, setShowContactInformation] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAmoDeletionModal, setShowAmoDeletionModal] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const {
    data: projectReview,
    refetch: refetchProjectReviewData,
    isFetching: isFetchingProjectReview,
    error,
  } = useProjectReviewDataById(projectId);

  const reviewId = projectReview ? projectReview.reviewId : null;
  const statusId = projectReview ? projectReview.statusId : null;

  useEffect(() => {
    history.block(() => {
      refetchProjectReviewData();
    });
  }, []);

  const { refetch: refetchHistoryData } = useHistoryLogs(
    reviewTypes.review,
    reviewId
  );

  const handleFieldSave = () => {
    refetchProjectReviewData();
  };

  const handleDataInputSave = () => {
    refetchHistoryData();
    refetchProjectReviewData();
  };

  const copyURL = () => {
    copyPageUrl(replaceParams(routes.dual.projects, { projectId }));
    showSnackbar("URL Copied", snackbarTypes.success);
  };

  const deleteProject = async () => {
    try {
      await projectService.deleteProject(projectReview.projectId);

      if (!mounted.current) {
        return;
      }

      showSnackbar(
        "The project was successfully deleted.",
        snackbarTypes.success
      );
      setShowDeleteModal(false);
      history.push(`${routes.reviews.projects.list}${location.search}`);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const deleteAmoModalClose = () => setShowAmoDeletionModal(false);

  const deleteUpdateFunction = () => {
    setShowAmoDeletionModal(false);
    history.push(`${routes.reviews.projects.list}${location.search}`);
  };

  const denyDeleteUpdateFunction = () => {
    setShowAmoDeletionModal(false);
    refetchProjectReviewData();
  };

  const isDeletionRequested =
    projectReview?.reportStatus === projectReportStatuses["Deletion Requested"];

  const headerActions = [
    {
      testId: "versionHistory",
      label: "Version History",
      variant: "outlined",
      iconName: "file_download",
      onClick: () => downloadHistory(),
      disabled: isFetchingProjectReview,
    },
    {
      testId: "projectReviewContactInfo",
      label: "Contact Info",
      variant: "outlined",
      onClick: () => setShowContactInformation(true),
    },
    {
      testId: "projectReviewCopyUrl",
      label: "Copy URL",
      variant: "outlined",
      onClick: copyURL,
    },
    {
      testId: "projectReviewDelete",
      iconName: "delete",
      color: "secondary",
      variant: "outlined",
      isIconButton: true,
      onClick: () => {
        isDeletionRequested
          ? setShowAmoDeletionModal(true)
          : setShowDeleteModal(true);
      }, // deleteProject,
    },
  ];

  const getProjectChanges = useWrapApi(projectService.getProjectChanges);
  const downloadHistory = async () => {
    const {
      data: { data },
    } = await getProjectChanges.call(projectId);

    if (!mounted.current) {
      return;
    }

    const projectHistory = data.map((item) => ({
      ...item,
      municipalityName: getMunicipalityName(item.municipalityId),
      location:
        !item.latitude ||
        !item.longitude ||
        isNaN(item.latitude) ||
        isNaN(item.longitude)
          ? null
          : `${item.latitude?.toFixed(5)}, ${item.longitude?.toFixed(5)}`,
    }));
    exportProjectHistory(projectHistory, projectId);
  };

  return (
    <>
      {error?.response?.status === 404 && (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <NotFoundPage />
        </Grid>
      )}
      {!(error?.response?.status === 404) && (
        <ListPageWrapper>
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <AmoPageHeader
                title={`Project Review: ${projectId}`}
                backLinkText="Back to Project List"
                backLinkTo={routes.reviews.projects.list}
                actions={headerActions}
              />
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={0}
                className={classes.pageContent}
                justifyContent="space-between"
              >
                <Grid item className={classes.formContent} xs={6} md={7} lg={9}>
                  <ProjectReviewForm
                    projectData={projectReview}
                    onFieldSave={handleFieldSave}
                    isFetching={isFetchingProjectReview}
                    setShowAmoDeletionModal={() =>
                      setShowAmoDeletionModal(true)
                    }
                  />
                </Grid>
                <Grid item xs={6} md={5} lg={3}>
                  <Grid container direction="column" spacing={0}>
                    <Grid item className={classes.historyPadding}>
                      <HistoryLog objectId={reviewId} />
                    </Grid>
                    <Grid item>
                      <ReviewDataInput
                        id="project-review-log-data-input"
                        testId="projectReviewLogDataInput"
                        projectStatusId={statusId}
                        objectId={reviewId}
                        showPublished
                        onSave={handleDataInputSave}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {projectReview && (
            <AmoDeletionModal
              open={showAmoDeletionModal}
              id={projectId}
              closeFunction={deleteAmoModalClose}
              updateFunction={deleteUpdateFunction}
              denyDeleteUpdateFunction={denyDeleteUpdateFunction}
              name={projectReview.deletionRequestedName}
              dateRequested={projectReview.requestDate}
              reason={projectReview.deletionRequestReason}
            />
          )}

          {showContactInformation && (
            <ContactInfoModal
              variant="default"
              showModal={showContactInformation}
              closeModalFunction={() => setShowContactInformation(false)}
              title="Contact Information"
              showButton
              municipalityId={projectReview.municipalityId}
            />
          )}

          {showDeleteModal && (
            <AmoConfirmPopover
              data-testid="questionnaire-delete-popup"
              variant="default"
              open
              title="Delete?"
              text="Are you sure you want to delete this project?"
              yesButtonFunction={() => deleteProject()}
              yesButtonVariant="contained"
              noButtonFunction={() => setShowDeleteModal(false)}
              closePopover={() => setShowDeleteModal(false)}
            />
          )}
        </ListPageWrapper>
      )}
    </>
  );
};

export default ProjectReviewPage;
