import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  considering: number,
 *  planning: number,
 *  onHold: number,
 *  ready: number }} projectStatus
 */

const api = new Api("api/communications");

export const communicationDashboardService = {
  /**
   * @returns {Promise<AxiosResponse<projectStatus>>}
   */
  getProjectStatus: () => api.getById("projects/status-graph"),
};
