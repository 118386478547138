import React from "react";
import PropTypes from "prop-types";
import { questionnaireAnswerTypes } from "constants/questionnaireAnswerTypes";
import { Grid, MenuItem, Icon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import AmoTextField from "components/inputs/AmoTextField";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import AmoDatePicker from "components/inputs/AmoDatePicker";
import FileUploadList from "components/FileUploadList";
import { fileAssociations, fileTypes } from "constants/fileTypes";
import { useIsMounted } from "hooks/useIsMounted";
import parse from "html-react-parser";

const QuestionnaireNumberFormat = React.forwardRef((props, ref) => {
  const { onChange, decimalScale, ...other } = props;
  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={decimalScale}
    />
  );
});

QuestionnaireNumberFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalScale: PropTypes.number,
};

QuestionnaireNumberFormat.defaultProps = {
  decimalScale: undefined,
};

const useStyles = makeStyles((theme) => ({
  listItemText: {
    "& > span > p": {
      "&:first-child": {
        marginTop: "0rem",
      },
      "&:last-child": {
        marginBottom: "0rem",
      },
      "& > a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontWeight: "bolder",
      },
    },
    "& > span > p > a:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * This component is a customized MUI TextField, built to work with a react-hook-form (RHF) controlled form.
 *
 * To use this and other RHF input components, make sure that the RHF controller object is being passed in from the parent component.
 *
 * The controller object comes from the RHF useForm hook, and it allows the input to be registered, controlled, and validated.
 *
 * @param {object} props
 * @param {object} props.control                  The react-hook-form control, generated by useForm()
 * @param {string} props.id                       Field ID
 * @param {string} props.name                     The name of the input; react-hook-form uses this to register the input
 * @param {string} props.testId                   Maps to data-test-id; renamed on AMO component to avoid issues with targeting tests (maybe unnecessary)
 * @param {string} props.title                    Title for the question
 * @param {string} props.description              Description to show alongside the title for the question
 * @param {string} props.type                     Type of question to render
 * @param {object} props.answerValues             Possible values for a multiple choice question
 * @param {string} props.fileAssociationObjectId  Corresponding association object id for the files
 * @param {string} props.files                    Initial files for the file upload component
 * @param {string} props.allowedFileFormats       Allowed File Formats for the file upload component
 * @param {number} props.municipalityId           Municipality Id for the file upload component
 * @param {Function} props.saveUploadedFilesIds   Function to save the returning ids for the uploaded files.
 * @param {boolean} props.readOnly                Flag that disables the fields.
 * @param {boolean} props.deleteFileAnswers       Function to handle the deletion of the associated submitted answer of a deleted file.
 * @param {number} props.fileYear                 Year of the file to upload
 * @param {Function} props.onUpload - Function triggered after upload
 * @param {number} props.characterLimit           The question character limit for text and number types
 * @param {string} props.uploadAction - indicates the upload action to execute (Idle, Rollback)
 * @param {Function} props.onRollback - function triggered after upload is reverted
 * @returns {Function} A MUI TextField component wrapped with the react-hook-form controller
 */
const ProjectReportQuestionnaireQuestion = ({
  control,
  title,
  description,
  id,
  name,
  testId,
  type,
  answerValues,
  fileAssociationObjectId,
  files,
  allowedFileFormats,
  municipalityId,
  fileYear,
  saveUploadedFilesIds,
  readOnly,
  deleteFileAnswers,
  characterLimit,
  onUpload,
  uploadAction,
  onRollback,
}) => {
  const mounted = useIsMounted();

  const questionsMap = {
    [questionnaireAnswerTypes.shortAnswer.text]: (
      <AmoTextField
        multiline
        rows={1}
        rowsMax={10}
        control={control}
        placeholder="Type your response here"
        id={id}
        name={name}
        testId={testId}
        variant="outlined"
        fullWidth
        disabled={readOnly}
        characterLimit={characterLimit}
      />
    ),
    [questionnaireAnswerTypes.number.text]: (
      <AmoTextField
        control={control}
        placeholder="Type your response here"
        id={id}
        name={name}
        testId={testId}
        variant="outlined"
        fullWidth
        disabled={readOnly}
        InputProps={{
          inputComponent: QuestionnaireNumberFormat,
          inputProps: {
            decimalScale: isNaN(characterLimit) ? 0 : characterLimit,
          },
        }}
      />
    ),
    [questionnaireAnswerTypes.date.text]: (
      <AmoDatePicker
        control={control}
        id={id}
        name={name}
        testId={testId}
        variant="inline"
        fullWidth
        disabled={readOnly}
        pickerIcon={
          <Icon className="material-icons-outlined">calendar_today</Icon>
        }
      />
    ),
    [questionnaireAnswerTypes.yesNo.text]: (
      <AmoTextField
        control={control}
        id={id}
        name={name}
        testId={testId}
        variant="outlined"
        select
        placeholder="Select one"
        fullWidth
        disabled={readOnly}
      >
        {answerValues?.map((item) => (
          <MenuItem key={item.value} value={item.id.toString()}>
            {item.value}
          </MenuItem>
        ))}
      </AmoTextField>
    ),
    [questionnaireAnswerTypes.singleSelectMultipleChoice.text]: (
      <AmoTextField
        control={control}
        id={id}
        name={name}
        testId={testId}
        variant="outlined"
        select
        placeholder="Select one"
        fullWidth
        disabled={readOnly}
      >
        {answerValues?.map((item) => (
          <MenuItem key={item.value} value={item.id.toString()}>
            {parse(item.value)}
          </MenuItem>
        ))}
      </AmoTextField>
    ),
    [questionnaireAnswerTypes.multiSelectMultipleChoice
      .text]: answerValues?.map((item) => (
      <Grid key={item.id} container direction="column" wrap="nowrap">
        <AmoCheckbox
          control={control}
          label={parse(item.value)}
          id={item.id.toString()}
          name={`checkboxAnswers.${item.id}`}
          testId={testId}
          color="primary"
          disabled={readOnly}
        />
      </Grid>
    )),
    [questionnaireAnswerTypes.assetManagementPlanUpload.text]: (
      <FileUploadList
        fileAssociationId={fileAssociations.Questionnaires}
        fileAssociationObjectId={fileAssociationObjectId}
        initialValue={files ?? []}
        allowedTypes={allowedFileFormats}
        municipalityId={municipalityId}
        fileYear={Number(fileYear)}
        onUpload={async (objectId, savedFileIds) => {
          await saveUploadedFilesIds(name, savedFileIds);

          if (!mounted.current) {
            return;
          }

          onUpload();
        }}
        onDelete={() => deleteFileAnswers(name)}
        readonly={readOnly}
        uploadAction={uploadAction}
        onRollback={onRollback}
      />
    ),
  };

  const classes = useStyles();

  return (
    <>
      <Grid item>
        <ListItemText className={classes.listItemText} variant="body2">
          {parse(title)}
        </ListItemText>
        {description && (
          <ListItemText className={classes.listItemText} variant="body1">
            {parse(description)}
          </ListItemText>
        )}
      </Grid>
      <Grid item>{questionsMap[type]}</Grid>
    </>
  );
};

ProjectReportQuestionnaireQuestion.propTypes = {
  control: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  answerValues: PropTypes.arrayOf(PropTypes.shape()),
  fileAssociationObjectId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any),
  allowedFileFormats: PropTypes.arrayOf(PropTypes.string),
  municipalityId: PropTypes.number,
  fileYear: PropTypes.string,
  saveUploadedFilesIds: PropTypes.func,
  readOnly: PropTypes.bool,
  deleteFileAnswers: PropTypes.func,
  characterLimit: PropTypes.number,
  onUpload: PropTypes.func,
  uploadAction: PropTypes.oneOf(["Idle", "Rollback"]),
  onRollback: PropTypes.func,
};

ProjectReportQuestionnaireQuestion.defaultProps = {
  answerValues: undefined,
  description: undefined,
  fileAssociationObjectId: undefined,
  files: undefined,
  allowedFileFormats: [
    fileTypes.jpg,
    fileTypes.png,
    fileTypes.mov,
    fileTypes.avi,
    fileTypes.pdf,
  ],
  municipalityId: -1,
  fileYear: undefined,
  saveUploadedFilesIds: () => {},
  readOnly: false,
  deleteFileAnswers: () => {},
  characterLimit: undefined,
  onUpload: () => {},
  uploadAction: "Idle",
  onRollback: () => {},
};

export default ProjectReportQuestionnaireQuestion;
