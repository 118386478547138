import { Api } from "../api";
import { AxiosResponse } from "axios";

const api = new Api("api/amo/banking-information");

/**
 * @typedef {{
 *  projectId: number,
 *  objectId: number,
 *  fieldCode: string,
 *  value: string }} verificationRequest
 */

/**
 * @typedef {{
 *  action: string,
 *  actionDate: string,
 *  actionBy: number,
 *  value: string }} verificationRequestResponse
 */

/**
 * @typedef {{
 *  id: number,
 *  municipalityId: number,
 *  municipalityName: string,
 *  treasurerName: string,
 *  isVerified: boolean,
 *  isEditRequested: boolean,
 *  contactName: string,
 *  contactEmail: string,
 *  contactPhone: string,
 *  institutionName: string,
 *  institutionNumber: string,
 *  transitNumber: string,
 *  accountNumber: string }} BankingInformationView
 * */

const makeAPI = (municipalityId) =>
  new Api(`api/municipality/${municipalityId}`);

export const bankingInformationService = {
  /**
   * @param {verificationRequest} payload
   * @returns {Promise<AxiosResponse>} Empty response
   */
  postRequestVerification: (payload) => {
    api.axios.post("request-verification", payload);
  },

  /**
   * @returns {Promise<AxiosResponse<verificationRequestResponse>>} Empty response
   */
  getRequestVerification: () => api.axios.get("request-verification"),

  /**
   * @param {number} municipalityId
   * @returns {Promise<AxiosResponse<BankingInformationView>>}
   */
  getBankingInformation: (municipalityId) =>
    makeAPI(municipalityId).getById("banking-information"),

  /**
   * @param {number} municipalityId
   * @param {number} payload
   * @returns {Promise<AxiosResponse>}
   */
  postBankingInformation: (municipalityId, payload) =>
    makeAPI(municipalityId).axios.post("banking-information", payload),

  /**
   * @param {number} municipalityId
   * @param {number} payload
   * @returns {Promise<AxiosResponse>}
   */
  postRequestToEdit: (municipalityId, payload) =>
    makeAPI(municipalityId).axios.post(
      "banking-information/request-edit",
      payload
    ),

  /**
   * @param {number} municipalityId
   * @returns {Promise<AxiosResponse>}
   */
  putVerifyBankingInformation: (municipalityId) =>
    makeAPI(municipalityId).put("banking-information/verify", {}),
  /**
   * @returns {Promise<AxiosResponse>}
   */
  putExportedBankingInformation: () => api.axios.put("exported"),
};
