import React from "react";
import ListPageWrapper from "components/ListPageWrapper";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FinancialReportingWidget from "./FinancialReportingWidget";
import FinancialsOverviewWidget from "./FinancialsOverviewWidget";
import ReportingStatus from "components/amo/ReportingStatus";
import { useReportingStatusData } from "./landingPageHooks";
import Skeleton from "@material-ui/lab/Skeleton";
import ProjectReportingWidget from "./ProjectReportingWidget";
import { useYearContext } from "contexts/YearContext";

const useStyles = makeStyles({
  gridPadding: {
    paddingLeft: "6.3rem",
  },
  bottomPadding: {
    paddingBottom: "1rem",
  },
  colPadding: {
    marginTop: "1rem",
  },
  rowPadding: {
    marginTop: "1rem",
    marginLeft: "1rem",
  },
});

/**
 * AMO Landing Page.
 *
 * @returns {React.Component} AMO Landing Page component.
 */
const LandingPage = () => {
  const classes = useStyles();
  const { contextYear } = useYearContext();
  const {
    data: reportingStatuses,
    isLoading: reportingStatusesLoading,
  } = useReportingStatusData(contextYear);

  return (
    <ListPageWrapper>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.bottomPadding}
      >
        <Grid item>
          {reportingStatusesLoading && (
            <Skeleton
              style={{
                width: "80rem",
                height: "16.859rem",
                transform: "initial",
              }}
            />
          )}
          {!reportingStatusesLoading && (
            <ReportingStatus statuses={reportingStatuses} />
          )}
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ width: "80rem" }}
        >
          <Grid item>
            <Grid item className={classes.colPadding}>
              <ProjectReportingWidget
                id="projectReportingWidget"
                testId="testProjectReportingWidget"
              />
            </Grid>

            <Grid item className={classes.colPadding}>
              <FinancialReportingWidget
                id="financialReportingWidget"
                testId="testFinancialReportingWidget"
              />
            </Grid>
          </Grid>
          <Grid item className={classes.rowPadding}>
            <FinancialsOverviewWidget
              id="financialOverviewWidget"
              testId="testFinancialOverviewWidget"
            />
          </Grid>
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default LandingPage;
