import { useQuery } from "react-query";
import { userKeys } from "./userQueryKeys";
import { userService } from "api/services/userService";

/**
 * Store server's state of the account details
 *
 * @param {string} accountId The id of the executing user
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useAccountDetails = (accountId) =>
  useQuery(userKeys.account(accountId), () =>
    userService.getAccountDetails(accountId)
  );
