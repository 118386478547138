import React from "react";
import {
  Icon,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  DialogContent,
  Popover,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    // 516px
    width: "32.25rem",
    borderRadius: "5px",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    borderTop: "none",
    borderBottomColor: colors.grey.main,
    // 24px
    padding: "0rem 1rem 1.5rem 1rem",
  },
  dialogActions: {
    // 16px
    padding: "1rem",
    borderTop: `1px solid ${colors.grey.main}`,
  },
  dialogActionsSpacing: {
    "& :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  title: {
    // 24px
    fontSize: "1.5rem",
    fontWeight: "500",
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(1),
    color: colors.grey.icon,
  },
  button: {
    // 50px
    height: "3.125rem",
  },
  saveButton: {
    // 200px
    flex: "2",
  },
  continueButton: {
    // 260px
    flex: "3",
  },
  caption: {
    // 14px
    fontSize: "0.875rem",
    fontWeight: "normal",
  },
}));

/**
 * A popup component to consult user about unsaved changes
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the component [required]
 * @param {string} props.testId - data-testid of the component
 * @param {boolean} props.open - controls whether the popover is open or not
 * @param {Function} props.onClose - function called when user clicks on the Close icon (params: none)
 * @param {Function} props.onSave - function called when user clicks on the 'Save Changes' button (params: none)
 * @param {Function} props.onContinue - function called when user clicks on the 'Continue without saving' button (params: none)
 * @param {boolean} props.isInvalid - controls whether the propmt should be for invalid data
 * @returns {React.Component} The popup component
 */
const UnsavedChangesPopover = (props) => {
  const { id, testId, open, onSave, onContinue, onClose, isInvalid } = props;

  const classes = useStyles();

  const handleSave = () => {
    onSave();
  };

  const handleContinue = () => {
    onContinue();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Popover
        id={id}
        data-testid={testId ?? `${id}-test`}
        anchorEl={document.body}
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.popoverPaper,
        }}
        getContentAnchorEl={null}
        elevation={4}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
      >
        <DialogTitle
          disableTypography
          className={classes.dialogTitle}
          onClose={handleClose}
        >
          <Typography variant="h6" className={classes.title}>
            Unsaved Changes
          </Typography>
          <IconButton
            data-testid={`${testId ?? `${id}-test`}CloseIcon`}
            aria-label="closeIcon"
            className={classes.closeIcon}
            onClick={handleClose}
          >
            <Icon className="material-icons">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {isInvalid ? (
            <>
              <Typography variant="body1" className={classes.caption}>
                Some of the changes that you made are invalid and cannot be
                saved.
              </Typography>
              <Box height="1rem" />
              <Typography variant="body1" className={classes.caption}>
                Would you like to revise your changes or continue without
                saving?
              </Typography>
            </>
          ) : (
            <Typography variant="body1" className={classes.caption}>
              Would you like to save your changes or continue without saving?
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          className={classes.dialogActions}
          // classes={{ spacing: classes.dialogActionsSpacing }}
        >
          {isInvalid ? (
            <Button
              data-testid={`${testId ?? `${id}-test`}ReviseButton`}
              className={clsx(classes.button, classes.saveButton)}
              onClick={handleClose}
              color="primary"
              variant="outlined"
              size="large"
            >
              Revise changes
            </Button>
          ) : (
            <Button
              data-testid={`${testId ?? `${id}-test`}SaveButton`}
              className={clsx(classes.button, classes.saveButton)}
              onClick={handleSave}
              color="primary"
              variant="contained"
              size="large"
            >
              Save changes
            </Button>
          )}
          <Button
            data-testid={`${testId ?? `${id}-test`}ContinueButton`}
            className={clsx(classes.button, classes.continueButton)}
            onClick={handleContinue}
            color="secondary"
            variant="outlined"
            size="large"
          >
            Continue without saving
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};

// set the prop-types for this component
UnsavedChangesPopover.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
  onClose: PropTypes.func,
  isInvalid: PropTypes.bool,
};

UnsavedChangesPopover.defaultProps = {
  testId: null,
  onSave: () => {},
  onContinue: () => {},
  onClose: () => {},
  isInvalid: false,
};

export default UnsavedChangesPopover;
