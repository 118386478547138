import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import colors from "constants/colors";
import { routes } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: colors.grey.light,
    marginBottom: "1rem",
    padding: "0.8rem",
    "& p": {
      display: "inline",
    },
  },
  secondLine: {
    marginTop: "0.5rem",
    "& p": {
      fontSize: "0.88rem",
    },
  },
  fontSize: {
    fontSize: "0.875rem",
  },
  dateText: {
    color: colors.grey.dark,
  },
  routerTextLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * A component to a single recent list update item
 *
 * @param {object} props - object containing props for this component
 * @param {number} props.id - Project id
 * @param {string} props.name - Project name
 * @param {string} props.municipality - Municipality name related to the project
 * @param {string} props.date - When the project data was updated
 * @param {string} props.updatedBy - Name of the user responsible for the update
 *
 * @returns - The recent list item component
 */
const RecentListUpdateItem = (props) => {
  const { id, name, municipality, date, updatedBy } = props;
  const classes = useStyles();
  return (
    <Typography variant="body1" className={classes.fontSize} component="div">
      <Grid
        key={id}
        container
        direction="column"
        className={classes.mainContainer}
      >
        <Grid item>
          <Typography
            variant="body1"
            className={clsx(classes.fontSize, classes.dateText)}
          >
            {date}
          </Typography>
        </Grid>
        <Grid item>
          <RouterLink
            to={routes.communications.projects.details.replace(
              ":projectId",
              id
            )}
            className={classes.routerTextLink}
          >
            <Typography variant="body2" color="primary">
              {name}
            </Typography>
          </RouterLink>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.fontSize}>
            {municipality}
          </Typography>
        </Grid>
        <Grid item className={classes.secondLine}>
          Updated by{" "}
          <Typography variant="body2" className={classes.fontSize}>
            {updatedBy}
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

// set the prop-types for this component
RecentListUpdateItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  municipality: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  updatedBy: PropTypes.string.isRequired,
};

RecentListUpdateItem.defaultProps = {};

export default RecentListUpdateItem;
