import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AmoMap from "components/AmoMap";
import {
  mapStatuses,
  defaultMapStatus,
  mapTimeRanges,
  defaultMapTimeRange,
  mapStatusColors,
} from "constants/communicationConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: "18rem",
    borderRadius: "10px",
    border: "1px solid #939393",
    padding: "2rem",
    paddingBottom: "0rem",
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "1.5rem",
  },
  dropdownWrapper: {
    width: "12rem",
    "& .MuiSelect-selectMenu": {
      minWidth: "0",
    },
  },
  mapContainer: {
    margin: "2rem 0",
  },
}));

/**
 * A component to display the communications map widget
 *
 * @param {object} props - object containing props for this component
 * @param {object[]} props.locations - project locations
 * @param {Function} props.onMapChange - function to call when map params change
 *
 * @returns - map widget component
 */
const MapWidget = (props) => {
  const classes = useStyles();
  const { locations, onMapChange } = props;

  const [selectedStatus, setSelectedStatus] = useState(defaultMapStatus);
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    defaultMapTimeRange
  );

  useEffect(
    () =>
      onMapChange([
        selectedStatus.id,
        selectedStatus.id === mapStatuses.published.id
          ? selectedTimeRange.getDate()
          : null,
      ]),
    [selectedStatus, selectedTimeRange]
  );

  return (
    <Grid container className={classes.container} direction="column">
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Project Map
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex" }}>
            <div className={classes.dropdownWrapper}>
              <TextField
                label="Status"
                variant="outlined"
                value={selectedStatus}
                onChange={(event) => setSelectedStatus(event.target.value)}
                select
                fullWidth
              >
                {Object.values(mapStatuses).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {selectedStatus.id === mapStatuses.published.id && (
              <div
                className={classes.dropdownWrapper}
                style={{ marginLeft: "2rem" }}
              >
                <TextField
                  label="Time Range"
                  variant="outlined"
                  value={selectedTimeRange}
                  onChange={(event) => setSelectedTimeRange(event.target.value)}
                  select
                  fullWidth
                >
                  {Object.values(mapTimeRanges).map((value) => (
                    <MenuItem key={value} value={value}>
                      {value.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.mapContainer}>
        <AmoMap
          locations={locations.map((location) => ({
            lat: location.latitude,
            lng: location.longitude,
            color: mapStatusColors[location.statusId] || undefined,
          }))}
          width="100%"
          height="29rem"
        />
      </Grid>
    </Grid>
  );
};

// set the prop-types for this component
MapWidget.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      statusId: PropTypes.number,
    })
  ).isRequired,
  onMapChange: PropTypes.func.isRequired,
};

MapWidget.defaultProps = {};

export default MapWidget;
