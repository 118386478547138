import React, { useState, useEffect } from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import colors from "../constants/colors";
import { mobileBreakpoint } from "../constants/viewport";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { useViewport } from "../hooks/viewportHook";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.white,
    borderBottom: `0.125rem solid ${colors.green.border}`,
    height: "4.375rem",
    flexGrow: 1,
    padding: "0rem 0.625rem 0rem 1.875rem",
  },
  paddingMobile: {
    padding: "0rem 0.313rem 0rem 1rem",
  },
}));

/**
 * A header component wrapper
 *
 * @returns {React.Component} The header component wrapper
 */
const Header = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const breakpointToPixel =
    Number(mobileBreakpoint.headerWidth.split("rem")[0]) * 16;

  const [isMobileView, setIsMobileView] = useState(
    width < breakpointToPixel // rem to px
  );
  useEffect(() => {
    setIsMobileView(width < breakpointToPixel);
  }, [width]);

  return (
    <>
      <AppBar position="static" elevation={0} data-testid="headerAppBar">
        <Toolbar
          className={clsx(
            classes.root,
            isMobileView ? classes.paddingMobile : null
          )}
        >
          {isMobileView ? <MobileHeader /> : <DesktopHeader />}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
