import { Snackbar, Typography } from "@material-ui/core";
import React, { createContext, useContext, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import { snackbarTypes } from "constants/snackbar";

/**
 * The base snackbar context.
 */
const SnackBarContext = createContext();

/**
 * React's context hook.
 *
 * @returns {any} The current value for the snackbar context.
 */
const useSnackbar = () => useContext(SnackBarContext);

/**
 * A snackbar context wrapper, provides context to children.
 *
 * @param {Node} {children}
 * @returns {Element} Wrapped children, that will now have access to the snackbar context.
 */
const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState(null);

  const showSnackbar = (message, type = snackbarTypes.success) => {
    setConfig({ message, type });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const value = { showSnackbar };

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
        <Alert
          elevation={6}
          variant="filled"
          icon={false}
          severity={config?.type}
        >
          <Typography>{config?.message}</Typography>
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useSnackbar, SnackBarContext, SnackbarProvider };
