export const amoPlaceholders = {
  treasurerFirstName: "[TreasurerFirstName]",
  treasurerLastName: "[TreasurerLastName]",
  treasurerFullName: "[TreasurerFullName]",
  treasurerEmail: "[TreasurerEmail]",
  treasurerPhone: "[TreasurerPhone]",
  delegateFirstName: "[DelegateFirstName]",
  delegateLastName: "[DelegateLastName]",
  delegateFullName: "[DelegateFullName]",
  delegateEmail: "[DelegateEmail]",
  delegatePhone: "[DelegatePhone]",
  contactFirstName: "[ContactFirstName]",
  contactLastName: "[ContactLastName]",
  contactFullName: "[ContactFullName]",
  contactEmail: "[ContactEmail]",
  contactPhone: "[ContactPhone]",
  userFirstName: "[UserFirstName]",
  userLastName: "[UserLastName]",
  userFullName: "[UserFullName]",
  userEmail: "[UserEmail]",
  userPhone: "[UserPhone]",
  affectedUserFirstName: "[AffectedUserFirstName]",
  affectedUserLastName: "[AffectedUserLastName]",
  affectedUserFullName: "[AffectedUserFullName]",
  affectedUserEmail: "[AffectedUserEmail]",
  affectedUserPhone: "[AffectedUserPhone]",
  reportingYear: "[ReportingYear]",
  eventDate: "[EventDate]",
  eventTime: "[EventTime]",
  expirationTimeAndDate: "[ExpirationTimeAndDate]",
  financialYearLink: "[FinancialYearLink]",
  passwordCreationLink: "[PasswordCreationLink]",
  resetPasswordLink: "[ResetPasswordLink]",
  categories: "[Categories]",
  projectIDWithLink: "[ProjectIDWithLink]",
  projectTitle: "[ProjectTitle]",
  previousAmount: "[PreviousAmount]",
  currentAmount: "[CurrentAmount]",
  field: "[Field]",
  earnedOrTransferred: "[EarnedOrTransferred]",
  projectId: "[ProjectId]",
};
