import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { fileTypes } from "constants/fileTypes";

export const areAllFilesValid = (files, allowedTypes) =>
  files.every(
    (file) =>
      allowedTypes.some((allowedType) => {
        const fileType = file.type.split("/").reverse()[0];
        const alternativeTypes = {
          [fileTypes.jpg]: ["jpeg"],
          [fileTypes.xlsx]: [
            "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "vnd.ms-excel",
          ],
          [fileTypes.mov]: ["quicktime"],
          [fileTypes.wmv]: ["x-ms-wmv"],
          [fileTypes.docx]: [
            "vnd.openxmlformats-officedocument.wordprocessingml.document",
            "msword",
          ],
          [fileTypes.pptx]: [
            "vnd.openxmlformats-officedocument.presentationml.presentation",
            "vnd.ms-powerpoint",
          ],
        };
        return (
          allowedType === fileType ||
          (alternativeTypes[allowedType] ?? []).includes(fileType)
        );
      }) && file.size / (1024 * 1024) <= 25
  );

const useStyles = makeStyles((theme) => ({
  dragAndDropButton: {
    height: "3.5rem",
    borderStyle: "dashed !important",
    borderWidth: "0.125rem",
    borderRadius: "0.25rem",
    boxSizing: "border-box",
    color: "#939393 !important",
    backgroundColor: "transparent",
  },
  buttonIcon: {
    marginLeft: "0.625rem",
    marginRight: "0.625rem",
  },
  draggingArea: {
    backgroundColor: "rgba(255, 255, 255, .8)",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
  draggingAreaText: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
}));

/**
 * The interface for uploading files.
 *
 * @param {object} props
 * @param {boolean} props.multiple Allows multiple files when true.
 * @param {Array} props.allowedTypes Specifies which filetypes are allowed.
 * @param {Function} props.onChange Change handler for the parent list component.
 * @returns {React.Component} The File Upload component.
 */
const FileUpload = (props) => {
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();
  const { multiple, allowedTypes, onChange } = props;
  const hiddenFileInput = React.createRef(null);
  const dropRef = React.createRef();
  const [dragging, setDragging] = useState(false);
  let dragCount = 0;

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragIn = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCount += 1;
    setDragging(true);
  };

  const handleDragOut = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCount -= 1;
    if (dragCount === 0) setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCount = 0;
    setDragging(false);
    processFiles(event.dataTransfer.files);
  };

  const handleFileInputChange = (event) => {
    processFiles(event.target.files);
    hiddenFileInput.current.value = "";
  };

  const processFiles = (fileList) => {
    const files = Array.from(fileList);
    if (!multiple && files.length > 1)
      return showSnackbar(`Only one file is allowed here`, snackbarTypes.error);
    if (!areAllFilesValid(files, allowedTypes))
      return showSnackbar(
        `Invalid file type or exceeded 25 MB size limit`,
        snackbarTypes.error
      );
    return onChange(files);
  };

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);
  }, []);

  return (
    <>
      <input
        ref={hiddenFileInput}
        type="file"
        onChange={handleFileInputChange}
        multiple={multiple}
        accept={allowedTypes.map((allowedType) => `.${allowedType}`).join(", ")}
        style={{ display: "none" }}
      />
      <Button
        ref={dropRef}
        onClick={() => hiddenFileInput.current.click()}
        className={classes.dragAndDropButton}
        color="primary"
        variant="outlined"
      >
        <CloudUploadIcon color="primary" className={classes.buttonIcon} />
        <Typography>
          Drag {multiple ? "files" : "a file"} here or click to open file
          browser
        </Typography>
        {dragging && (
          <div className={classes.draggingArea}>
            <Typography
              variant="h3"
              color="primary"
              className={classes.draggingAreaText}
            >
              Drop here
            </Typography>
          </div>
        )}
      </Button>
    </>
  );
};

FileUpload.propTypes = {
  multiple: PropTypes.bool,
  allowedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
};

FileUpload.defaultProps = {
  multiple: false,
  onChange: () => {},
};

export default FileUpload;
