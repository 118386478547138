import React from "react";
import { Grid, Typography, IconButton, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { recentListTypes } from "constants/recentList";
import RecentListCommItem from "./RecentListCommItem";
import RecentListUpdateItem from "./RecentListUpdateItem";
import colors from "constants/colors";
import { ReactComponent as DownloadIcon } from "img/icons/download_circle_icon.svg";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    border: "1px solid black",
    borderRadius: "0.5rem",
    padding: "0.8rem 0.5rem 0.2rem 1.25rem",
    height: "100%",
  },
  title: {
    fontSize: "1.5rem",
  },
  headerWrapper: {
    marginBottom: "1rem",
  },
  items: {
    overflowX: "hidden",
    overflowY: "auto",
    paddingRight: "0.5rem",
    "&::-webkit-scrollbar": {
      width: "3px",
    },
  },
  emptyList: {
    background: colors.grey.light,
    margin: "0.2rem 0",
    padding: "1rem",
    textAlign: "Center",
  },
  iconButton: {
    padding: "0 0.2rem 0 0",
  },
  icon: {
    width: "2.125rem",
    height: "2.125rem",
    marginRight: "0.2rem",
    marginTop: "0",
    marginBottom: "0",
    padding: "0 0",
  },
}));

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  municipality: number,
 *  date: string,
 *  twitterUrl: string,
 *  instagramUrl: string,
 *  linkedInUrl: string,
 *  buildingCommunitiesUrl: string }} CommItem
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  municipality: number,
 *  date: string,
 *  updatedBy: string }} UpdateItem
 */

/**
 * A component to display a recent communication events
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - the component id
 * @param {string} props.testId - the component test id
 * @param {CommItem[] | UpdateItem[]} props.items - array of items to display
 * @param {string} props.type - the recent list type
 * @param {string} props.title - the title displayed on the component
 * @param {string} props.width - width of component
 * @param {string} props.maxHeight - max height of list component
 * @param {boolean} props.showDownloadIcon - controls whether component shows the download icon or not
 * @param {Function} props.onDownloadClick - function called when user clicks on the download icon button (params: { items })
 *
 * @returns - The recent list component
 */
const RecentList = (props) => {
  const {
    id,
    testId,
    items,
    type,
    title,
    width,
    maxHeight,
    showDownloadIcon,
    onDownloadClick,
  } = props;
  const classes = useStyles();

  return (
    <Grid
      id={id}
      data-testid={testId}
      container
      direction="column"
      className={classes.mainContainer}
      style={{ width }}
    >
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerWrapper}
        >
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          {showDownloadIcon && (
            <Grid item>
              <IconButton
                onClick={() => onDownloadClick(items)}
                className={classes.iconButton}
                color="primary"
                variant="contained"
                disabled={!items.length}
              >
                <DownloadIcon
                  className={classes.icon}
                  style={{
                    stroke: items.length ? colors.green.main : colors.grey.main,
                  }}
                  fill={items.length ? colors.green.main : colors.grey.main}
                />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          className={classes.items}
          style={{ maxHeight }}
          wrap="nowrap"
        >
          {items.map((item) =>
            type === recentListTypes.comms ? (
              <RecentListCommItem
                key={`recentComms${item.id}`}
                id={item.id}
                name={item.name}
                municipality={item.municipality}
                date={item.date}
                twitter={item.twitterUrl}
                instagram={item.instagramUrl}
                linkedIn={item.linkedInUrl}
                buildingCommunities={item.buildingCommunitiesUrl}
              />
            ) : (
              <RecentListUpdateItem
                key={`recentUpdates${item.id}`}
                id={item.id}
                name={item.name}
                municipality={item.municipality}
                date={item.date}
                updatedBy={item.updatedBy}
              />
            )
          )}
          {!items.length && (
            <Box className={classes.emptyList}>
              <Typography variant="body1">
                No{" "}
                {type === recentListTypes.comms ? "communications" : "updates"}{" "}
                found
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

// set the prop-types for this component
RecentList.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([recentListTypes.comms, recentListTypes.updates]),
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  showDownloadIcon: PropTypes.bool,
  onDownloadClick: PropTypes.func,
};

RecentList.defaultProps = {
  width: "100%",
  maxHeight: "27rem",
  showDownloadIcon: false,
  onDownloadClick: () => {},
  type: recentListTypes.updates,
};

export default RecentList;
