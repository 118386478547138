import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Paper,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../constants/colors";

const useStyles = makeStyles({
  gridRoot: {
    justifyContent: "center",
  },
  gridItem: {
    paddingTop: "10rem",
  },
  paperRoot: {
    width: "25rem",
    height: "31.25rem",
  },
  paperRounded: {
    borderRadius: "0.625rem",
  },
  paperOutlined: {
    border: `1px solid ${colors.grey.main}`,
  },
  gridContainer: {
    spacing: "8rem",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80%",
  },
  inputRoot: {
    width: "15rem",
  },
  buttonRoot: {
    width: "15rem",
  },
});

/**
 * A small password reset form, sends a password reset email to the user.
 *
 * @param {object} props
 * @param {boolean} props.handleClick The click handler, fired when the send button is clicked.
 *
 * @returns {React.Component} The Password Reset form.
 */
const ResetPasswordInput = (props) => {
  const { handleClick } = props;
  const [userIdentifier, setUserIdentifier] = useState("");

  const classes = useStyles();

  return (
    <Grid container direction="row" classes={{ root: classes.gridRoot }}>
      <Grid item classes={{ root: classes.gridItem }}>
        <Paper
          variant="outlined"
          classes={{
            root: classes.paperRoot,
            rounded: classes.paperRounded,
            outlined: classes.paperOutlined,
          }}
        >
          <Grid
            container
            direction="column"
            classes={{ root: classes.gridContainer }}
          >
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <Box color={colors.green.dark}>
                <Typography variant="h5" data-testid="resetPasswordTitle">
                  Recover Password
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography data-testid="resetPasswordPrompt">
                Please enter your email or username.
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="email"
                label="Email or Username"
                classes={{ root: classes.inputRoot }}
                value={userIdentifier}
                onChange={(event) => setUserIdentifier(event.target.value)}
              />
            </Grid>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <Button
                data-testid="resetPasswordButton"
                classes={{ root: classes.buttonRoot }}
                variant="contained"
                color="primary"
                onClick={() => handleClick(userIdentifier)}
                size="large"
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

ResetPasswordInput.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default ResetPasswordInput;
