import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  approved: number,
 *  submitted: number,
 *  unsubmitted: number }} ReportingStatusData
 */

const api = new Api("api/amo/reporting");
export const reportingService = {
  /**
   * @param {number} year Reporting year
   * @returns {Promise<AxiosResponse<ReportingStatusData>>}
   */
  getReportingStatusData: (year) => api.getById(`year/${year}/status/widget`),
  /**
   * @returns {Promise<AxiosResponse<number[]>>}
   */
  getAllReportingYears: () => api.getById(`years`),
  /**
   * @param {number} municipalityId Municipality Id
   * @param {number} year Reporting year
   * @returns {Promise<AxiosResponse>}
   */
  reopenReportingYear: (municipalityId, year) =>
    api.put(`${municipalityId}/${year}/reopen`, {}),
};
