import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import RichTextEditor from "components/RichTextEditor";

/**
 * This component is a customized RichTextEditor, built to work with a react-hook-form controlled form
 *
 * @param props
 * @param {string} props.id                   Field ID
 * @param {string} props.testId               Maps to data-test-id; renamed on AMO component to avoid issues with targeting tests
 * @param {object} props.control              The react-hook-form control, generated by useForm()
 * @param {string} props.label                Label text for the component
 * @param {string} props.name                 The name of the input; react-hook-form uses this to register the input
 * @param {Function} props.setError           The setError function of react-hook-form to manually set errors
 * @param {Function} props.clearErrors        The clearErrors function of react-hook-form to manually clear errors
 * @param {boolean} props.disabled            Props that disables RichTextEditor components if needed
 * @param {boolean} props.isSingleLineInput   Controls whether the RichTextEditor is single line only
 * @param {boolean} props.simpleToolbar       Controls whether the RichTextEditor has simple toolbar
 * @param {boolean} props.adaptableMultiline  Controls whether the RichTextEditor has adaptable height input
 * @param {boolean} props.hideBold            Controls whether the RichTextEditor hides bold format
 * @param {boolean} props.required            Controls whether the input is required
 * @param {string} props.onChangeTrigger   Event that triggers on input change
 *
 * @returns {Function} A RichTextEditor component wrapped with the react-hook-form controller
 */
const AmoRichTextEditor = ({
  id,
  testId,
  control,
  label,
  name,
  setError,
  clearErrors,
  disabled,
  isSingleLineInput,
  simpleToolbar,
  adaptableMultiline,
  hideBold,
  required,
  onChangeTrigger,
}) => (
  <Controller
    name={name}
    control={control}
    render={({
      field: { value, onChange, onBlur },
      fieldState: { isTouched, error },
    }) => (
      <RichTextEditor
        id={id}
        testId={testId}
        onChange={(newValue, isValid) => {
          if (!isValid) {
            // Workaround since it doesn't set the error correctly otherwise
            setTimeout(() =>
              setError(name, { type: "manual", message: "Invalid content" })
            );
          } else {
            clearErrors(name);
          }
          if (value !== newValue) {
            onChange(newValue);
            onChangeTrigger(newValue);
          }
        }}
        onBlur={onBlur}
        value={value}
        error={isTouched && !!error}
        helperText={isTouched && error ? error?.message : undefined}
        label={label}
        placeholder={label}
        disabled={disabled}
        isSingleLineInput={isSingleLineInput}
        simpleToolbar={simpleToolbar}
        adaptableMultiline={adaptableMultiline}
        hideBold={hideBold}
        required={required}
      />
    )}
  />
);

AmoRichTextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  control: PropTypes.shape().isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  // Rich text editor has internal validation
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSingleLineInput: PropTypes.bool,
  simpleToolbar: PropTypes.bool,
  adaptableMultiline: PropTypes.bool,
  hideBold: PropTypes.bool,
  required: PropTypes.bool,
  onChangeTrigger: PropTypes.func,
};

AmoRichTextEditor.defaultProps = {
  testId: undefined,
  label: undefined,
  disabled: false,
  isSingleLineInput: false,
  simpleToolbar: false,
  adaptableMultiline: false,
  hideBold: false,
  required: false,
  onChangeTrigger: () => {},
};

export default AmoRichTextEditor;
