import React, { useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";

// Query hooks and management
import { useUserDetails } from "./treasurerUserHooks";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useUpdateUserDetails } from "./usersHook";

import { useMunicipalContext } from "contexts/MunicipalContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";

import NotFoundPage from "pages/NotFoundPage";
import AmoTextField from "components/inputs/AmoTextField";
import AmoSwitch from "components/inputs/AmoSwitch";
import AmoPageHeader from "components/AmoPageHeader";
import AmoFormActions from "components/AmoFormActions";
import colors from "constants/colors";
import { patterns } from "constants/regex";
import { routes } from "constants/routes";
import { roles } from "constants/user";

const useStyles = makeStyles((theme) => ({
  readOnly: {
    // -9px
    marginTop: "-0.563rem",
  },
  readOnlyLabel: {
    color: colors.grey.dark,
    fontFamily: "Roboto",
    fontSize: "0.75rem",
    marginBottom: "0.5rem",
  },
  readOnlyValue: {
    fontFamily: "Roboto",
  },
  inputWidth: {
    width: "90%",
  },
}));

/**
 * Treasurer User Edit Page.
 *
 * @returns {React.Component} Treasurer User Edit Page component.
 */
const TreasurerUserEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { userId } = useParams();
  const history = useHistory();
  const { municipalities } = useMunicipalContext();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const { hasRoles } = useUserContext();

  // define schema for form validation
  const TreasurerUserEditValidationSchema = yup
    .object({
      firstName: yup
        .string()
        .trim()
        .required("First name is required")
        .max(50, "Too long, maximum 50 characters"),
      lastName: yup
        .string()
        .trim()
        .required("Last name is required")
        .max(50, "Too long, maximum 50 characters"),
      email: yup
        .string()
        .email("Email must be a valid email")
        .required("Email is required"),
      phone: yup
        .string()
        .required("Phone number is required")
        .matches(patterns.phone, { message: "Invalid phone format" }),
      phoneExtension: yup
        .string()
        .nullable()
        .matches(patterns.number, { message: "Invalid phone extension" }),
      title: yup
        .string()
        .trim()
        .nullable()
        .max(100, "Too long, maximum 100 characters"),
      isEnabled: yup.boolean(),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneExtension: "",
    title: "",
    isEnabled: true,
  };

  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(TreasurerUserEditValidationSchema),
  });

  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    error,
  } = useUserDetails(userId);

  const navigateBack = () => {
    history.push(`${routes.users.base}${location.search}`);
  };

  const { mutateAsync: updateUser } = useUpdateUserDetails(
    showSnackbar,
    navigateBack
  );

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    reset(userDetails ?? defaultValues);
  }, [userDetails, reset]);

  const handleSaveClick = async ({
    email,
    firstName,
    lastName,
    isEnabled: enabled,
    phone: phoneNumber,
    phoneExtension,
    title,
  }) => {
    const userData = {
      userType: "treasurer",
      userId,
      firstName,
      lastName,
      title,
      email,
      phoneNumber,
      phoneExtension: phoneExtension !== undefined ? phoneExtension : "",
      municipalityId: userDetails.municipalityId,
      enabled,
    };
    await updateUser(userData);
  };

  const { isValid, isSubmitting, isDirty } = formState;

  const formActions = [
    {
      testId: "treasurerUserEditSaveButton",
      label: "Save",
      disabled: !isDirty || isSubmitting || !isValid,
      onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
    },
    {
      testId: "treasurerUserEditCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: navigateBack,
    },
  ];

  return isUserDetailsLoading ? (
    <>
      <Skeleton width="50%" />
      <Skeleton width="50%" />
    </>
  ) : (
    <>
      {error?.response?.status === 404 && (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <NotFoundPage />
        </Grid>
      )}
      {!(error?.response?.status === 404) && (
        <Grid container direction="column" spacing={0} wrap="nowrap">
          {/* Header */}
          <Grid item>
            <AmoPageHeader
              title="User Management:"
              subtitle="Edit User"
              backLinkText="Back to user list"
              backLinkTo={routes.users.base}
            />
          </Grid>

          {/* Body */}
          <Grid item xs className={globalClasses.editPageBodyContainer}>
            <Grid
              container
              direction="column"
              spacing={0}
              className={clsx(
                globalClasses.editPageBody22,
                globalClasses.editPageBodyTextFirstChildPadding
              )}
              wrap="nowrap"
            >
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      label="First Name"
                      id="treasurer-user-edit-firstName"
                      name="firstName"
                      required
                      testId="treasurerUserEditFirstName"
                      variant="outlined"
                      className={classes.inputWidth}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      label="Last Name"
                      id="treasurer-user-edit-lastName"
                      name="lastName"
                      testId="treasurerUserEditLastName"
                      className={classes.inputWidth}
                      variant="outlined"
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      id="treasurer-user-edit-title"
                      name="title"
                      testId="treasurerUserEditTitle"
                      className={classes.inputWidth}
                      label="Title"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs className={classes.readOnly}>
                    <Typography
                      variant="subtitle2"
                      className={classes.readOnlyLabel}
                    >
                      Municipality
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.readOnlyValue}
                    >
                      {userDetails
                        ? municipalities?.[userDetails.municipalityId]?.fullName
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      id="treasurer-user-edit-email"
                      name="email"
                      testId="treasurerUserEditEmail"
                      className={classes.inputWidth}
                      label="Email"
                      required
                    />
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item xs={8}>
                      <AmoTextField
                        control={control}
                        id="treasurer-user-edit-phone"
                        name="phone"
                        testId="treasurerUserEditPhone"
                        className={classes.inputWidth}
                        label="Phone"
                        required
                        pattern={/[^0-9.]+/g}
                        numberFormatProps={{
                          format: "###-###-####",
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <AmoTextField
                        control={control}
                        id="treasurer-user-edit-phone-extension"
                        name="phoneExtension"
                        testId="treasurerUserEditPhoneExtension"
                        className={classes.inputWidth}
                        label="Ext."
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {hasRoles([roles.amo, roles.amoSuperAdmin]) && (
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      <AmoSwitch
                        color="primary"
                        control={control}
                        id="treasurer-user-edit-enabled"
                        name="isEnabled"
                        testId="userEditEnabled"
                        label="User Enabled"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Actions */}
          <AmoFormActions actions={formActions} hideLateralBorders />
        </Grid>
      )}
    </>
  );
};

export default TreasurerUserEditPage;
