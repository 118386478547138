import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider, Icon } from "@material-ui/core";
import AmoTable from "components/table/AmoTable";
import colors from "constants/colors";
import { routes } from "constants/routes";
import { getSortingComparator, stableSort } from "../../utils/data";
import { DateTime } from "luxon";
import { dateFormats } from "constants/dateFormats";
import { formsService } from "api/services/formService";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import ListPageWrapper from "components/ListPageWrapper";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useAsyncEffect } from "hooks/useAsyncEffect";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.black,
  },
  rowFont: {
    fontSize: "0.875rem",
  },
  editIcon: {
    fontSize: "1.5rem",
  },
}));

/**
 * A page component for listing all editable forms on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const FormContentListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { search } = useLocation();
  const globalClasses = useGlobalStyles();

  const [forms, setForms] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("lastEdited");
  const [isLoaded, setIsLoaded] = useState(false);

  const tableColumns = [
    {
      fieldKey: "nameComponent",
      label: "Form",
      widthPerc: 45,
      customRender: ({ id, name }) => (
        <Link
          data-testid="formContentTableNameLink"
          to={`${routes.contentManagement.forms.edit.base}${id}${search}`}
          className={globalClasses.tableActionCell}
        >
          <Typography
            className={classes.rowFont}
            color="primary"
            variant="body2"
          >
            {name}
          </Typography>
        </Link>
      ),
      isCustom: true,
    },
    {
      fieldKey: "lastEdited",
      label: "Last Edited",
      widthPerc: 50,
    },
    {
      fieldKey: "edit",
      widthPerc: 5,
      hideHeader: true,
      customRender: ({ id }) => (
        <Link
          data-testid={`formContentTableEditLink${id}`}
          to={`${routes.contentManagement.forms.edit.base}${id}${search}`}
          className={classes.navLink}
        >
          <Icon
            color="primary"
            onMouseOver={(event) => event.stopPropagation()}
            className={clsx("material-icons-outlined", classes.editIcon)}
          >
            edit
          </Icon>
        </Link>
      ),
      isCustom: true,
    },
  ];

  // this effect will make a call to the API to get the forms list
  useAsyncEffect(async (status) => {
    try {
      const { data } = await formsService.getAll();

      if (status.aborted) {
        return;
      }

      const mappedResult = data.map((item) => ({
        id: item.id,
        name: item.name,
        lastEdited:
          item.updateDate && item.updatedBy
            ? `${DateTime.fromISO(item.updateDate).toFormat(
                dateFormats.isoDateAndTime
              )} by ${item.updatedBy}`
            : null,
      }));

      setForms(mappedResult);
    } catch {
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      // If the data is loaded, sort the forms
      const sortedResult = stableSort(
        forms,
        getSortingComparator(
          order,
          orderBy === "nameComponent" ? "name" : orderBy
        )
      );

      setForms(sortedResult);
    }
  }, [isLoaded, order, orderBy]);

  const handleRequestSort = (fieldKey, newOrder) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography gutterBottom className={classes.title} variant="h1">
            Forms
          </Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <AmoTable
            id="form-mgmt-table"
            testId="formContentManagementTable"
            items={forms}
            columns={tableColumns}
            defaultOrderBy="lastEdited"
            defaultOrder="desc"
            onRequestSort={handleRequestSort}
            shouldUseQueryString
            disableFilters
          />
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default FormContentListPage;
