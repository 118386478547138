import React, { useContext, createContext } from "react";
import PropTypes from "prop-types";
import { useUserContext } from "./UserContext";
import { roles } from "../constants/user";
import { useQuery } from "react-query";
import { municipalityService } from "api/services/municipalityService";
import { oneDayInMs } from "constants/caching";
import { stableSort } from "utils/data";

/**
 * Store server's state of all municipalities
 *
 * @param {boolean} isAuthenticated
 * @param {boolean} isMunicipalUser
 * @returns The query object returned by the useQuery hook
 */
const useAllMunicipalities = (isAuthenticated, isMunicipalUser) =>
  useQuery(["municipalities"], () => municipalityService.getAll(), {
    select: ({ data }) =>
      data?.reduce((map, obj) => ({ ...map, [obj.id]: obj }), {}) ?? {},
    enabled: isAuthenticated && !isMunicipalUser,
    staleTime: oneDayInMs,
    refetchOnMount: true,
  });

/**
 * Store server's state of user municipality
 *
 * @param {boolean} isAuthenticated
 * @param {boolean} isMunicipalUser
 * @returns The query object returned by the useQuery hook
 */
const useUserMunicipality = (isAuthenticated, isMunicipalUser) =>
  useQuery(
    ["municipalities", "current"],
    () => municipalityService.getUserMunicipality(),
    {
      select: ({ data }) => data,
      enabled: isAuthenticated && isMunicipalUser,
      staleTime: oneDayInMs,
      refetchOnMount: true,
    }
  );

/**
 * The base municipal context.
 */
const MunicipalContext = createContext(null);

/**
 * React's context hook.
 *
 * @returns {any} The current value for the user context.
 */
const useMunicipalContext = () => useContext(MunicipalContext);

/**
 * A municipal context wrapper, provides context to children.
 *
 * @param {Node} {children}
 * @returns {Element} Wrapped children, that will now have access to the municipal context.
 */
const MunicipalProvider = ({ children }) => {
  const { user } = useUserContext();

  const isMunicipalUser =
    (!user?.roles?.includes(roles.amo) &&
      user?.roles?.includes(roles.municipal)) ??
    false;

  const {
    data: municipalities,
    isFetching: isFetchingMunicipalities,
    refetch: refetchAllMunicipalities,
  } = useAllMunicipalities(
    user?.loaded && user?.isAuthenticated,
    isMunicipalUser
  );

  const {
    data: userMunicipality,
    isFetching: isFetchingUserMunicipality,
    refetch: refetchUserMunicipality,
  } = useUserMunicipality(
    user?.loaded && user?.isAuthenticated,
    isMunicipalUser
  );

  const getMunicipality = (municipalityIdParam) => {
    // If no municipality id is passed, use the current logged user municipality
    const municipalityId = municipalityIdParam ?? user?.municipalityId;

    if (isMunicipalUser) {
      return userMunicipality;
    }

    if (!municipalityId) return null;
    return municipalities[municipalityId];
  };

  const getMunicipalityName = (municipalityIdParam, isFullName = false) => {
    const municipality = getMunicipality(municipalityIdParam);
    return (isFullName ? municipality?.fullName : municipality?.name) ?? "";
  };

  const getMunicipalitiesNames = (asObject = true, isFullName = false) => {
    const namesArray = Object.entries(municipalities ?? {}).map(([_, obj]) =>
      isFullName ? obj?.fullName : obj?.name
    );

    const sortedNames = stableSort(namesArray, (item1, item2) =>
      item1.toLowerCase() < item2.toLowerCase() ? -1 : 1
    );

    if (asObject) {
      return sortedNames.reduce((obj, name) => ({ ...obj, [name]: name }), {});
    }

    return sortedNames;
  };

  const getMunicipalitiesRegions = (asObject = true) => {
    const regionsArray = Object.entries(municipalities ?? {})
      .map(([_, obj]) => obj?.region)
      .filter((region) => !!region);

    const sortedRegions = stableSort(regionsArray, (item1, item2) =>
      item1.toLowerCase() < item2.toLowerCase() ? -1 : 1
    );

    if (asObject) {
      return sortedRegions.reduce(
        (obj, region) => ({ ...obj, [region]: region }),
        {}
      );
    }

    return sortedRegions;
  };

  return (
    <MunicipalContext.Provider
      value={{
        municipalities: municipalities ?? {},
        userMunicipality: userMunicipality ?? {},
        isFetching: isMunicipalUser
          ? isFetchingUserMunicipality
          : isFetchingMunicipalities,
        selectedMunicipalityId: user?.municipalityId ?? 0,
        getMunicipality,
        getMunicipalityName,
        getMunicipalitiesNames,
        getMunicipalitiesRegions,
        refetch: isMunicipalUser
          ? refetchUserMunicipality
          : refetchAllMunicipalities,
      }}
    >
      {children}
    </MunicipalContext.Provider>
  );
};

MunicipalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useMunicipalContext, MunicipalProvider, MunicipalContext };
