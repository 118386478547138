import React, { useState, useEffect, useMemo } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { filesService } from "api/services/fileService";
import { indicatorService } from "api/services/indicatorService";

import AmoDatePicker from "components/inputs/AmoDatePicker";
import AmoLocationPickerControl from "components/inputs/AmoLocationPickerControl";
import AmoMap from "components/AmoMap";
import AmoTextField from "components/inputs/AmoTextField";
import FileUploadList from "components/FileUploadList";
import ProjectReviewFieldWrapper from "components/amo/ProjectReviewFieldWrapper";

import { errorMessages } from "constants/errorMessages";
import {
  projectReportGeneralInfoCodes,
  projectReportCategoryCodes,
  projectReportFinancialsCodes,
  projectReportCommunicationsCodes,
} from "constants/formContentManagement";
import { snackbarTypes } from "constants/snackbar";
import reviewFieldWrapperTypes from "constants/reviewFieldWrapperTypes";
import {
  signageRationaleTexts,
  signageRationaleValues,
} from "constants/signageRationaleValues";
import {
  signageIntentionsTexts,
  signageIntentionsValues,
} from "constants/signageIntentionsValues";
import {
  signageTypeTexts,
  signageTypeValues,
} from "constants/signageTypeValues";
import {
  signagePostedTexts,
  signagePostedValues,
} from "constants/signagePostedValues";

import { useMunicipalContext } from "contexts/MunicipalContext";
import { useSnackbar } from "contexts/SnackbarContext";

import { useIsMounted } from "hooks/useIsMounted";

import ProjectReportFinancialsFundsWidget from "pages/municipal/report/ProjectReportFinancialsFundsWidget";

import { formatToCurrency } from "utils/number";
import { getLowerCaseStringValue } from "utils/string";
import { locationIsInOntario } from "../../../utils/location";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    marginBottom: "1.5rem",
  },
  titleSpacing: {
    marginTop: "2.5rem",
  },
  inputWidth: {
    width: "100%",
  },
  inputPlaceholder: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
    },
  },
}));

const yupLocationObject = () =>
  yup
    .object({
      id: yup.number(),
      position: yup.object({ lat: yup.number(), lng: yup.number() }),
      address: yup.string().nullable(),
      valid: yup.boolean().nullable(),
    })
    .nullable();

const transformEmptyNumber = (value, originalValue) => {
  if (isNaN(value) && originalValue === "") {
    return null;
  }

  return value;
};

const ProjectValidationSchema = yup
  .object({
    category: yup.string(),
    subcategory: yup.string(),
    projectTitle: yup.string().nullable(),
    objectivesOfProject: yup.string().nullable(),
    projectScopeOfWork: yup.string().nullable(),
    natureOfInvestment: yup.string().nullable(),
    additionalComments: yup.string().nullable(),
    primaryLocation: yupLocationObject(),
    secondaryLocations: yup.array().of(yupLocationObject()).nullable(),
    projectStartDate: yup
      .date()
      .nullable()
      .typeError("This is not a valid date (yyyy-mm-dd)"),
    endOfConstructionDate: yup
      .date()
      .nullable()
      .typeError("This is not a valid date (yyyy-mm-dd)"),
    endOfFinancingDate: yup
      .date()
      .nullable()
      .typeError("This is not a valid date (yyyy-mm-dd)"),
    totalCcbfFundsBudgeted: yup
      .number()
      .nullable()
      .transform(transformEmptyNumber)
      .typeError("This is not a valid number")
      .positive("This must be a positive number"),
    totalProjectCost: yup
      .number()
      .nullable()
      .transform(transformEmptyNumber)
      .typeError("This is not a valid number")
      .positive("This must be a positive number"),
    otherFederalFunds: yup.mixed().oneOf(["", true, false]),
    provincialFunds: yup.mixed().oneOf(["", true, false]),
    stackingLimits: yup.mixed().oneOf(["", true, false]),
    signagePosted: yup
      .mixed()
      .oneOf(["", ...Object.values(signagePostedValues)]),
    signageType: yup.mixed().oneOf(["", ...Object.values(signageTypeValues)]),
    signageIntentions: yup
      .mixed()
      .oneOf(["", ...Object.values(signageIntentionsValues)]),
    signageRationale: yup
      .mixed()
      .oneOf(["", ...Object.values(signageRationaleValues)]),
  })
  .required();

/**
 * This form component to be used on the Project Details Tab.
 *
 * @param {object} props - object containing props for this component
 * @param {object} props.projectData - project information used by this component
 * @param {Function} props.onFieldSave - function called when user clicks on the field popup save button (params: none)
 * @param {boolean} props.isFetching - whether the is fetching the project data
 *
 * @returns {Function} The form component
 */
const ProjectDetailsTab = (props) => {
  const { projectData, onFieldSave, isFetching } = props;

  const { getMunicipality } = useMunicipalContext();

  const classes = useStyles();
  const { id: projectId } = useParams();

  const { showSnackbar } = useSnackbar();

  const mounted = useIsMounted();

  const natureOfInvestmentValues = [
    {
      id: "1",
      name: "New",
    },
    {
      id: "2",
      name: "Enhancement",
    },
    {
      id: "3",
      name: "Renewal",
    },
  ];

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [yesNoValues] = useState([
    {
      id: true,
      name: "Yes",
    },
    {
      id: false,
      name: "No",
    },
  ]);
  const [subcategoryOverwrite, setSubcategoryOverwrite] = useState();
  const [categoryOverwrite, setCategoryOverwrite] = useState();
  const [eocDateWarning, setEocDateWarning] = useState({});
  const [eofDateWarning, setEofDateWarning] = useState({});
  const [signageWarning, setSignageWarning] = useState({});
  const [totalCostWarning, setTotalCostWarning] = useState({});
  const [eocFieldValue, setEocFieldValue] = useState(true);
  const [eofFieldValue, setEofFieldValue] = useState(true);
  const [psdFieldValue, setPsdFieldValue] = useState(true);

  const isProjectStartDateValid = async (date) => {
    try {
      await ProjectValidationSchema.validate({ projectStartDate: date });
      return true;
    } catch (error) {
      return false;
    }
  };

  const isEndOfConstructionDateValid = async (date) => {
    try {
      await ProjectValidationSchema.validate({ endOfConstructionDate: date });
      return true;
    } catch (error) {
      return false;
    }
  };

  const isEndOfFinancingDateValid = async (date) => {
    try {
      await ProjectValidationSchema.validate({ endOfFinancingDate: date });
      return true;
    } catch (error) {
      return false;
    }
  };

  const defaultValues = {
    category: "",
    subcategory: "",
    projectTitle: "",
    objectivesOfProject: "",
    projectScopeOfWork: "",
    natureOfInvestment: "",
    additionalComments: "",
    primaryLocation: "",
    secondaryLocations: [],
    projectStartDate: null,
    endOfConstructionDate: null,
    endOfFinancingDate: null,
    totalCcbfFundsBudgeted: "",
    totalProjectCost: "",
    otherFederalFunds: "",
    provincialFunds: "",
    stackingLimits: "",
    signagePosted: false,
    providedLinks: "",
  };

  const { control, reset, setValue, resetField, watch } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possible onBlur) if very important for validation
    mode: "onChange",
    resolver: yupResolver(ProjectValidationSchema),
  });

  // New signage fields were included to be validated on 2023 and onwards
  const includeNewSignageFields =
    projectData.dates?.constructionEndDate?.value === null ||
    (projectData.dates?.constructionEndDate?.value &&
      DateTime.fromISO(projectData.dates?.constructionEndDate?.value).year >=
        2023);

  const signagePosted = watch("signagePosted");
  const signageIntentions = watch("signageIntentions");

  const otherFederalFunds = projectData.financials?.otherFederalFunds?.value;

  const handleCloseEditPopover = (fieldName, value) => {
    setValue(fieldName, value);
  };

  const handleCloseEditPopoverDate = (fieldName) => {
    resetField(fieldName);
  };

  const handleCloseEditPopoverBoolean = (fieldName) => {
    resetField(fieldName);
  };

  const [categoryWatch, subcategoryWatch] = useWatch({
    control,
    name: ["category", "subcategory"],
  });

  // this effect will make a call to the API to get the data
  useEffect(() => {
    if (projectData) {
      const getCategoriesData = async () => {
        try {
          const { data } = await indicatorService.getDropdownData();

          if (!mounted.current) {
            return;
          }

          setCategories(data.categories);
          setSubcategories(
            categories.filter((category) => category.parentCategoryId)
          );
        } catch {
          if (!mounted.current) {
            return;
          }

          showSnackbar(errorMessages.generic, snackbarTypes.error);
        }
      };

      getCategoriesData();
      setFormData();
    }
  }, [projectData]);

  // Memoize project funds
  const preloadedFunds = useMemo(() => {
    if (isFetching) {
      return {};
    }

    const projectStartDate = DateTime.fromISO(
      projectData.dates?.startDate?.value
    );
    const municipality = getMunicipality(projectData.municipalityId);

    // Collect existing project funds
    let total = 0;
    const hasYear = {};
    const funds = projectData.financials?.ccbfFundsApplied.map((fund) => {
      total += Number(fund.amount?.value);
      hasYear[fund.year] = true;

      return {
        ...fund.amount,
        ...fund,
        amount: fund.amount?.value,
      };
    });

    // Add missing years so they can be edited
    // All years since the project start year up to the municipal reporting year should have editable funds
    if (projectStartDate.isValid && municipality) {
      for (
        let { year } = projectStartDate;
        year < municipality.reportingYear;
        year += 1
      ) {
        if (!hasYear[year]) {
          funds.push({ year, amount: 0 });
        }
      }
    }

    return { total, funds };
  }, [isFetching, projectData.municipalityId]);

  const setFormData = () => {
    if (projectData) {
      const formValues = {
        category:
          subcategories.filter(
            (item) =>
              item.id === Number(projectData.category?.selectedCategory?.value)
          )?.length > 0
            ? 9
            : Number(projectData.category?.selectedCategory?.value),
        subcategory: projectData.category?.selectedSubcategory?.value
          ? Number(projectData.category?.selectedSubcategory?.value)
          : "",
        projectTitle: projectData.projectDetails?.title?.value,
        objectivesOfProject: projectData.projectDetails?.objectives?.value,
        projectScopeOfWork: projectData.projectDetails?.scopeOfWork?.value,
        natureOfInvestment:
          projectData.projectDetails?.natureOfInvestment?.value,
        additionalComments:
          projectData.projectDetails?.additionalComments?.value,
        primaryLocation: projectData.location?.primaryLocation?.value
          ? stringToLocation(projectData.location?.primaryLocation?.value)
          : "",
        secondaryLocations:
          projectData.location?.secondaryLocations?.map((location, index) =>
            stringToLocation(location?.details?.value)
          ) ?? [],
        projectStartDate: DateTime.fromISO(projectData.dates?.startDate?.value)
          .isValid
          ? DateTime.fromISO(projectData.dates?.startDate?.value)
          : null,
        endOfConstructionDate: DateTime.fromISO(
          projectData.dates?.constructionEndDate?.value
        ).isValid
          ? DateTime.fromISO(projectData.dates?.constructionEndDate?.value)
          : null,
        endOfFinancingDate: DateTime.fromISO(
          projectData.dates?.financingEndDate?.value
        ).isValid
          ? DateTime.fromISO(projectData.dates?.financingEndDate?.value)
          : null,
        totalCcbfFundsBudgeted: Number(
          projectData.financials?.totalFundsBudgeted?.value
        ),
        totalProjectCost: Number(projectData.financials?.totalCost?.value),
        otherFederalFunds: projectData.financials?.otherFederalFunds?.value
          ? projectData.financials?.otherFederalFunds?.value === "True"
          : "",
        provincialFunds: projectData.financials?.provincialFunds?.value
          ? projectData.financials?.provincialFunds?.value === "True"
          : "",
        stackingLimits: projectData.financials?.stackingLimits?.value
          ? projectData.financials?.stackingLimits?.value === "True"
          : "",
        signagePosted: projectData.communications?.signagePosted?.value
          ? projectData.communications?.signagePosted?.value === "True"
          : "",
        signageType: projectData.communications?.signageType?.value
          ? Number(projectData.communications?.signageType?.value)
          : "",
        signageIntentions: projectData.communications?.signageIntentions?.value
          ? projectData.communications?.signageIntentions?.value === "True"
          : "",
        signageRationale: projectData.communications?.signageRationale?.value
          ? Number(projectData.communications?.signageRationale?.value)
          : "",
        providedLinks: projectData.communications?.providedLinks?.value,
      };
      reset(formValues);

      if (projectData.dates?.constructionEndDate?.approvedValue) {
        const approvedEOC = DateTime.fromISO(
          projectData.dates?.constructionEndDate?.approvedValue
        );
        const currentEOC = DateTime.fromISO(
          projectData.dates?.constructionEndDate?.value
        );
        const eocExtension = currentEOC.diff(approvedEOC, "years").toObject()
          .years;

        if (eocExtension >= 1) {
          setEocDateWarning({
            type: "warning",
            tooltipText: `The EOC was pushed back ${Math.round(
              eocExtension
            )} year${Math.round(eocExtension) > 1 ? "s" : ""}`,
          });
        } else {
          setEocDateWarning({});
        }
      } else {
        setEocDateWarning({});
      }

      if (projectData.dates?.financingEndDate?.approvedValue) {
        const approvedEOF = DateTime.fromISO(
          projectData.dates?.financingEndDate?.approvedValue
        );
        const currentEOF = DateTime.fromISO(
          projectData.dates?.financingEndDate?.value
        );
        const eofExtension = currentEOF.diff(approvedEOF, "years").toObject()
          .years;

        if (eofExtension >= 1) {
          setEofDateWarning({
            type: "warning",
            tooltipText: `The EOF was pushed back ${Math.round(
              eofExtension
            )} year${Math.round(eofExtension) > 1 ? "s" : ""}`,
          });
        } else {
          setEofDateWarning({});
        }
      } else {
        setEofDateWarning({});
      }

      if (
        projectData.financials?.totalCost?.approvedValue &&
        projectData.financials?.totalCost?.approvedValue !== ""
      ) {
        const costDifference = Math.round(
          projectData.financials?.totalCost?.value -
            projectData.financials?.totalCost?.approvedValue
        );
        const absoluteDifference = Math.abs(costDifference);
        const percentageDifference = Math.round(
          (costDifference / projectData.financials?.totalCost?.approvedValue) *
            100
        );
        if (percentageDifference <= -20 || percentageDifference >= 20) {
          setTotalCostWarning({
            type: "warning",
            tooltipText: `The total cost ${
              costDifference > 0 ? "grew" : "fell"
            } by ${percentageDifference}%`,
          });
        } else if (absoluteDifference >= 1000000) {
          setTotalCostWarning({
            type: "warning",
            tooltipText: `The total cost ${
              costDifference > 0 ? "grew" : "fell"
            } by ${formatToCurrency(absoluteDifference)}`,
          });
        } else {
          setTotalCostWarning({});
        }
      } else {
        setTotalCostWarning({});
      }

      if (
        projectData.communications?.signagePosted?.value !== "True" &&
        projectData.financials?.totalCost?.value >= 100000
      ) {
        setSignageWarning({
          type: "warning",
          tooltipText: "This project should probably have a sign",
        });
      } else {
        setSignageWarning({});
      }
    }
  };

  const stringToLocation = (locationString) => {
    try {
      const lat = Number(locationString.split(",")[0]);
      const lng = Number(locationString.split(",")[1].trim());
      const address =
        !isNaN(lat) && !isNaN(lng)
          ? `${lat?.toFixed(5)}, ${lng?.toFixed(5)}`
          : locationString;
      return {
        address,
        location: {
          lat,
          lng,
        },
        position: {
          lat,
          lng,
        },
      };
    } catch {
      return "";
    }
  };

  const downloadAllFiles = async () => {
    try {
      const { data } = await filesService.getByProjectId(projectId);

      if (!mounted.current) {
        return;
      }

      // Download file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Project ${projectId} files.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const getBooleanFormat = (value) =>
    ({ true: "Yes", false: "No" }[getLowerCaseStringValue(value)] ?? null);

  const getSignageFormat = (value, valuesObject) =>
    valuesObject[getLowerCaseStringValue(value)] ?? null;

  const endOfFinancingDateYear =
    projectData.dates?.financingEndDate?.value &&
    DateTime.fromISO(projectData.dates?.financingEndDate?.value).year;

  const isLocationInOntario = async (value) => {
    const locationStr = value.position
      ? `${value.position.lat},${value.position.lng}`
      : value.address;

    const isValid = await locationIsInOntario(locationStr, !value.position);

    return isValid;
  };

  return (
    <>
      <Grid item>
        <Typography variant="h4" className={classes.title}>
          Category
        </Typography>
      </Grid>

      <Grid item container spacing={6}>
        <Grid item xs={6}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportCategoryCodes.category}
            id="categoryWrapper"
            label="Category"
            fieldName="category"
            onClose={handleCloseEditPopover}
            value={Number(projectData.category?.selectedCategory?.value)}
            previousValue={
              projectData.category?.selectedCategory?.previousValue
                ? Number(projectData.category?.selectedCategory?.previousValue)
                : ""
            }
            flagProps={projectData.category?.selectedCategory?.flagProps}
            formatValue={(value) =>
              categories?.find((obj) => obj.id === value)?.name || ""
            }
            valueOverwrite={categoryOverwrite}
            onSave={() => {
              setSubcategoryOverwrite(
                categoryWatch === 9 ? subcategoryWatch : "--"
              );
              onFieldSave();
            }}
            renderInput={(categoryValue, setCategoryValue) => (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AmoTextField
                    control={control}
                    label="Category"
                    id="category"
                    name="category"
                    select
                    required
                    testId="category"
                    variant="outlined"
                    className={classes.inputWidth}
                    onChangeTrigger={(val) => {
                      if (val !== 9) {
                        setValue("subcategory", "");
                      }
                      setCategoryValue(val);
                    }}
                  >
                    <MenuItem key="categoryNone" value="" disabled>
                      Category
                    </MenuItem>
                    {categories &&
                      Object.entries(
                        categories.filter(
                          (category) => !category.parentCategoryId
                        )
                      )
                        .sort(([aKey, aVal], [bKey, bVal]) =>
                          aVal.name.localeCompare(bVal.name)
                        )
                        .map(([key, value]) => (
                          <MenuItem key={`category-${key}`} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                  </AmoTextField>
                </Grid>
                <Grid item xs={12}>
                  <AmoTextField
                    control={control}
                    label="Sub-category"
                    id="subcategory"
                    name="subcategory"
                    select
                    required
                    disabled={categoryWatch !== 9}
                    testId="subcategory"
                    variant="outlined"
                    className={classes.inputWidth}
                  >
                    <MenuItem key="subcategoryNone" value="" disabled>
                      Category
                    </MenuItem>
                    {categories &&
                      Object.entries(
                        categories.filter(
                          (category) => category.parentCategoryId
                        )
                      )
                        .sort(([aKey, aVal], [bKey, bVal]) =>
                          aVal.name.localeCompare(bVal.name)
                        )
                        .map(([key, value]) => (
                          <MenuItem key={`subcategory-${key}`} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                  </AmoTextField>
                </Grid>
              </Grid>
            )}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={6}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportCategoryCodes.subcategory}
            id="subcategoryWrapper"
            label="Sub-category"
            fieldName="subcategory"
            onClose={handleCloseEditPopover}
            value={
              projectData.category?.selectedSubcategory?.value
                ? Number(projectData.category?.selectedSubcategory?.value)
                : ""
            }
            previousValue={
              projectData.category?.selectedSubcategory?.previousValue
                ? Number(
                    projectData.category?.selectedSubcategory?.previousValue
                  )
                : ""
            }
            flagProps={projectData.category?.selectedSubcategory?.flagProps}
            formatValue={(value) =>
              categories?.find((obj) => obj.id === value)?.name || ""
            }
            valueOverwrite={subcategoryOverwrite}
            onSave={() => {
              setCategoryOverwrite(categoryWatch);
              onFieldSave();
            }}
            renderInput={(subcategoryValue, setSubcategoryValue) => (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AmoTextField
                    control={control}
                    label="Category"
                    id="category"
                    name="category"
                    select
                    required
                    testId="category"
                    variant="outlined"
                    className={classes.inputWidth}
                    onChangeTrigger={(val) => {
                      if (val !== 9) {
                        setValue("subcategory", "");
                        setSubcategoryValue("--");
                      }
                    }}
                  >
                    <MenuItem key="categoryNone" value="" disabled>
                      Category
                    </MenuItem>
                    {categories &&
                      Object.entries(
                        categories.filter(
                          (category) => !category.parentCategoryId
                        )
                      )
                        .sort(([aKey, aVal], [bKey, bVal]) =>
                          aVal.name.localeCompare(bVal.name)
                        )
                        .map(([key, value]) => (
                          <MenuItem key={`category-${key}`} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                  </AmoTextField>
                </Grid>
                <Grid item xs={12}>
                  <AmoTextField
                    control={control}
                    label="Sub-category"
                    id="subcategory"
                    name="subcategory"
                    select
                    required
                    disabled={categoryWatch !== 9}
                    testId="subcategory"
                    variant="outlined"
                    className={classes.inputWidth}
                    onChangeTrigger={(val) => setSubcategoryValue(val)}
                  >
                    <MenuItem key="subcategoryNone" value="" disabled>
                      Category
                    </MenuItem>
                    {categories &&
                      Object.entries(
                        categories.filter(
                          (category) => category.parentCategoryId
                        )
                      )
                        .sort(([aKey, aVal], [bKey, bVal]) =>
                          aVal.name.localeCompare(bVal.name)
                        )
                        .map(([key, value]) => (
                          <MenuItem key={`subcategory-${key}`} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                  </AmoTextField>
                </Grid>
              </Grid>
            )}
            isFetching={isFetching}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Typography
          variant="h4"
          className={clsx(classes.title, classes.titleSpacing)}
        >
          Project Details
        </Typography>
      </Grid>

      <Grid item container spacing={4}>
        <Grid item xs={12}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldTitle}
            id="projectTitleWrapper"
            label="Project Title"
            fieldName="projectTitle"
            onClose={handleCloseEditPopover}
            value={projectData.projectDetails?.title?.value}
            previousValue={projectData.projectDetails?.title?.previousValue}
            flagProps={projectData.projectDetails?.title?.flagProps}
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                control={control}
                id="project-title"
                name="projectTitle"
                testId="projectTitle"
                className={classes.inputWidth}
                label="Project Title"
                required
                onChangeTrigger={(val) => setFieldValue(val)}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={12}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldInternalTitle}
            id="internalProjectTitleWrapper"
            label="Internal Project Title"
            fieldName="internalProjectTitle"
            onClose={handleCloseEditPopover}
            value={projectData.projectDetails?.internalTitle?.value}
            previousValue={
              projectData.projectDetails?.internalTitle?.previousValue
            }
            flagProps={projectData.projectDetails?.internalTitle?.flagProps}
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                control={control}
                id="internal-project-title"
                name="internalProjectTitle"
                testId="internalProjectTitle"
                className={classes.inputWidth}
                label="Internal Project Title"
                required
                onChangeTrigger={(val) => setFieldValue(val)}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={12}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldObjectives}
            id="objectivesOfProjectWrapper"
            label="Objectives of Project"
            fieldName="objectivesOfProject"
            onClose={handleCloseEditPopover}
            value={projectData.projectDetails?.objectives?.value?.trim()}
            previousValue={projectData.projectDetails?.objectives?.previousValue?.trim()}
            flagProps={projectData.projectDetails?.objectives?.flagProps}
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                control={control}
                id="objectivesOfProject"
                name="objectivesOfProject"
                testId="objectivesOfProject"
                className={classes.inputWidth}
                label="Objectives of Project"
                required
                multiline
                onChangeTrigger={(val) => setFieldValue(val)}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={12}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldScope}
            id="projectScopeOfWorkWrapper"
            label="Project Scope of Work"
            fieldName="projectScopeOfWork"
            onClose={handleCloseEditPopover}
            value={projectData.projectDetails?.scopeOfWork?.value?.trim()}
            previousValue={projectData.projectDetails?.scopeOfWork?.previousValue?.trim()}
            flagProps={projectData.projectDetails?.scopeOfWork?.flagProps}
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                control={control}
                id="projectScopeOfWork"
                name="projectScopeOfWork"
                testId="projectScopeOfWork"
                className={classes.inputWidth}
                label="Project Scope of Work"
                required
                multiline
                onChangeTrigger={(val) => setFieldValue(val)}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={12}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldNatureInvestment}
            id="natureOfInvestmentWrapper"
            label="Nature of Investment"
            fieldName="natureOfInvestment"
            onClose={handleCloseEditPopover}
            value={projectData.projectDetails?.natureOfInvestment?.value}
            previousValue={
              projectData.projectDetails?.natureOfInvestment?.previousValue
            }
            flagProps={
              projectData.projectDetails?.natureOfInvestment?.flagProps
            }
            formatValue={(value) =>
              natureOfInvestmentValues?.find((obj) => obj.id === value)?.name ||
              ""
            }
            renderInput={(
              natureOfInvestmentValue,
              setNatureOfInvestmentValue
            ) => (
              <AmoTextField
                control={control}
                label="Nature Of Investment"
                id="natureOfInvestmentDropdown"
                name="natureOfInvestment"
                select
                required
                testId="natureOfInvestmentDropdown"
                variant="outlined"
                className={classes.inputWidth}
                placeholder="Nature Of Investment"
                onChangeTrigger={(val) => setNatureOfInvestmentValue(val)}
              >
                {natureOfInvestmentValues.map((value) => (
                  <MenuItem
                    key={`natureOfInvestmentField-${value.id}`}
                    value={value.id}
                  >
                    {value.name}
                  </MenuItem>
                ))}
              </AmoTextField>
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={10}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldComments}
            id="additionalCommentsWrapper"
            label="Additional Comments"
            fieldName="additionalComments"
            onClose={handleCloseEditPopover}
            value={projectData.projectDetails?.additionalComments?.value?.trim()}
            hideFlag
            hideFlagSpace
            previousValue={projectData.projectDetails?.additionalComments?.previousValue?.trim()}
            flagProps={
              projectData.projectDetails?.additionalComments?.flagProps
            }
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                control={control}
                id="additionalComments"
                name="additionalComments"
                testId="additionalComments"
                className={classes.inputWidth}
                label=" Additional Comments"
                required
                multiline
                onChangeTrigger={(val) => setFieldValue(val)}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Typography
          variant="h4"
          className={clsx(classes.title, classes.titleSpacing)}
        >
          Location
        </Typography>
      </Grid>

      <Grid item container spacing={4}>
        <Grid item xs={12}>
          {isFetching ? (
            <Skeleton width="100%" height="25rem" variant="rect" />
          ) : (
            <AmoMap
              locations={projectData.mapLocations}
              height="25rem"
              width="100%"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldPrimaryLocation}
            objectId={projectData.location?.primaryLocation?.id}
            id="primaryLocationWrapper"
            label="Primary Location"
            fieldName="primaryLocation"
            onClose={handleCloseEditPopover}
            value={stringToLocation(
              projectData.location?.primaryLocation?.value
            )}
            previousValue={stringToLocation(
              projectData.location?.primaryLocation?.previousValue
            )}
            flagProps={projectData.location?.primaryLocation?.flagProps}
            formatValue={(value) =>
              value?.address ? `${value.address}` : value
            }
            wrapperType={reviewFieldWrapperTypes.locationPicker}
            renderInput={(_, setFieldValue) => (
              <AmoLocationPickerControl
                control={control}
                id="primaryLocation"
                name="primaryLocation"
                testId="primaryLocation"
                label="Primary Location"
                required
                onChangeTrigger={async (val) => {
                  const isValid = await isLocationInOntario(val);

                  if (isValid) {
                    setFieldValue(val);
                  } else {
                    showSnackbar(
                      "Location should be in Ontario",
                      snackbarTypes.error
                    );
                  }
                }}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>

        {projectData.location?.secondaryLocations.map(
          (secondaryLocation, index) => {
            const displayIndex = index + 1;
            return (
              <Grid item xs={6}>
                <ProjectReviewFieldWrapper
                  key={displayIndex}
                  projectId={projectId}
                  fieldCode={
                    projectReportGeneralInfoCodes.fieldSecondaryLocation
                  }
                  objectId={secondaryLocation.id}
                  id={`additionalLocation${displayIndex}Wrapper`}
                  label={`Additional Location ${displayIndex}`}
                  fieldName={`additionalLocation${displayIndex}`}
                  onClose={handleCloseEditPopover}
                  value={stringToLocation(secondaryLocation?.details?.value)}
                  previousValue={stringToLocation(
                    secondaryLocation?.details?.previousValue
                  )}
                  flagProps={secondaryLocation.details.flagProps}
                  hideFlag
                  formatValue={(value) =>
                    value?.address ? `${value.address}` : value
                  }
                  wrapperType={reviewFieldWrapperTypes.locationPicker}
                  renderInput={(_, setFieldValue) => (
                    <AmoLocationPickerControl
                      control={control}
                      id={`additionalLocation${displayIndex}`}
                      name={`secondaryLocations.${index}`}
                      testId={`additionalLocation${displayIndex}`}
                      className={classes.inputWidth}
                      label={`Additional Location ${displayIndex}`}
                      required
                      onChangeTrigger={(val) => setFieldValue(val)}
                    />
                  )}
                  onSave={onFieldSave}
                  isFetching={isFetching}
                />
              </Grid>
            );
          }
        )}
      </Grid>

      <Grid item>
        <Typography
          variant="h4"
          className={clsx(classes.title, classes.titleSpacing)}
        >
          Dates
        </Typography>
      </Grid>

      <Grid item container spacing={4}>
        <Grid item xs={6}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldStartDate}
            id="projectStartDateWrapper"
            label="Project Start Date"
            fieldName="projectStartDate"
            onClose={handleCloseEditPopoverDate}
            value={DateTime.fromISO(projectData.dates?.startDate?.value)}
            previousValue={DateTime.fromISO(
              projectData.dates?.startDate?.previousValue
            )}
            flagProps={projectData.dates?.startDate?.flagProps}
            formatValue={(value) =>
              !value.invalid ? value?.toFormat("yyyy-MM-dd") : ""
            }
            renderInput={(_, setFieldValue) => (
              <AmoDatePicker
                control={control}
                label="Project Start Date"
                id="projectStartDate"
                name="projectStartDate"
                required
                testId="projectStartDate"
                variant="inline"
                className={classes.inputWidth}
                format="yyyy-MM-dd"
                onChangeTrigger={async (val) => {
                  setFieldValue(val);
                  const isValid = await isProjectStartDateValid(val);

                  if (!mounted.current) {
                    return;
                  }

                  setPsdFieldValue(isValid);
                }}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
            isSaveEnable={psdFieldValue}
          />
        </Grid>

        <Grid item xs={6}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportGeneralInfoCodes.fieldEndConstruction}
            id="endOfConstructionDateWrapper"
            label="End of Construction Date"
            fieldName="endOfConstructionDate"
            onClose={handleCloseEditPopoverDate}
            value={DateTime.fromISO(
              projectData.dates?.constructionEndDate?.value
            )}
            warning={eocDateWarning}
            previousValue={DateTime.fromISO(
              projectData.dates?.constructionEndDate?.previousValue
            )}
            flagProps={projectData.dates?.constructionEndDate?.flagProps}
            formatValue={(value) =>
              !value.invalid ? value?.toFormat("yyyy-MM-dd") : ""
            }
            renderInput={(_, setFieldValue) => (
              <AmoDatePicker
                control={control}
                label="End of Construction Date"
                id="endOfConstructionDate"
                name="endOfConstructionDate"
                required
                testId="endOfConstructionDate"
                variant="inline"
                className={classes.inputWidth}
                format="yyyy-MM-dd"
                onChangeTrigger={async (val) => {
                  setFieldValue(val);
                  const isValid = await isEndOfConstructionDateValid(val);

                  if (!mounted.current) {
                    return;
                  }

                  setEocFieldValue(isValid);
                }}
              />
            )}
            onSave={handleCloseEditPopoverDate}
            isFetching={isFetching}
            isSaveEnable={eocFieldValue}
          />
        </Grid>

        <Grid item xs={6}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportFinancialsCodes.fieldEndOfFinancing}
            id="endOfFinancingDateWrapper"
            label="End of Financing Date"
            fieldName="endOfFinancingDate"
            onClose={handleCloseEditPopoverDate}
            value={DateTime.fromISO(projectData.dates?.financingEndDate?.value)}
            previousValue={DateTime.fromISO(
              projectData.dates?.financingEndDate?.previousValue
            )}
            flagProps={projectData.dates?.financingEndDate?.flagProps}
            warning={eofDateWarning}
            formatValue={(value) =>
              !value.invalid ? value?.toFormat("yyyy-MM-dd") : ""
            }
            renderInput={(_, setFieldValue) => (
              <AmoDatePicker
                control={control}
                label="End of Financing Date"
                id="endOfFinancingDate"
                name="endOfFinancingDate"
                required
                testId="endOfFinancingDate"
                variant="inline"
                className={classes.inputWidth}
                format="yyyy-MM-dd"
                onChangeTrigger={async (val) => {
                  setFieldValue(val);
                  const isValid = await isEndOfFinancingDateValid(val);

                  if (!mounted.current) {
                    return;
                  }

                  setEofFieldValue(isValid);
                }}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
            isSaveEnable={eofFieldValue}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Typography
          variant="h4"
          className={clsx(classes.title, classes.titleSpacing)}
        >
          Financials
        </Typography>
      </Grid>

      <Grid item container spacing={4}>
        <Grid item xs={10}>
          {isFetching ? (
            <Skeleton width="100%" height="4rem" variant="rect" />
          ) : (
            <ProjectReportFinancialsFundsWidget
              projectId={projectId}
              municipalityId={projectData.municipalityId}
              title=" CCBF Funds Applied to Date"
              isProjectReview
              preloadedFunds={preloadedFunds}
              onSave={onFieldSave}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportFinancialsCodes.fieldTotalBudgeted}
            id="totalCcbfFundsBudgetedWrapper"
            label="Total CCBF Funds Budgeted"
            fieldName="totalCcbfFundsBudgeted"
            onClose={handleCloseEditPopover}
            formatValue={(value) => (value ? formatToCurrency(value) : "")}
            value={Number(projectData.financials?.totalFundsBudgeted?.value)}
            previousValue={Number(
              projectData.financials?.totalFundsBudgeted?.previousValue
            )}
            flagProps={projectData.financials?.totalFundsBudgeted?.flagProps}
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                control={control}
                id="totalCcbfFundsBudgeted"
                name="totalCcbfFundsBudgeted"
                testId="totalCcbfFundsBudgeted"
                className={classes.inputWidth}
                label="Total CCBF Funds Budgeted"
                required
                pattern={/[^0-9.]+/g}
                onChangeTrigger={(val) => setFieldValue(val)}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={6}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportFinancialsCodes.fieldTotalCost}
            id="totalProjectCostWrapper"
            label="Total Project Cost"
            fieldName="totalProjectCost"
            onClose={handleCloseEditPopover}
            value={Number(projectData.financials?.totalCost?.value)}
            warning={totalCostWarning}
            previousValue={Number(
              projectData.financials?.totalCost?.previousValue
            )}
            flagProps={projectData.financials?.totalCost?.flagProps}
            formatValue={(value) => (value ? formatToCurrency(value) : "")}
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                control={control}
                id="totalProjectCost"
                name="totalProjectCost"
                testId="totalProjectCost"
                className={classes.inputWidth}
                label="Total Project Cost"
                required
                pattern={/[^0-9.]+/g}
                onChangeTrigger={(val) => setFieldValue(val)}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>
        {(!endOfFinancingDateYear || endOfFinancingDateYear >= 2023) && (
          <>
            <Grid item xs={6}>
              <ProjectReviewFieldWrapper
                projectId={projectId}
                fieldCode={projectReportFinancialsCodes.fieldOtherFunds}
                id="otherFederalFundsWrapper"
                label="Other federal funds?"
                value={projectData.financials?.otherFederalFunds?.value}
                fieldName="otherFederalFunds"
                onClose={handleCloseEditPopoverBoolean}
                previousValue={
                  projectData.financials?.otherFederalFunds?.previousValue
                }
                flagProps={projectData.financials?.otherFederalFunds?.flagProps}
                formatValue={getBooleanFormat}
                renderInput={(_, setFieldValue) => (
                  <AmoTextField
                    color="primary"
                    control={control}
                    id="otherFederalFunds"
                    name="otherFederalFunds"
                    testId="otherFederalFunds"
                    label="Other federal funds?"
                    className={classes.inputWidth}
                    select
                    placeholder="Other federal funds?"
                    onChangeTrigger={(val) => setFieldValue(val)}
                  >
                    {yesNoValues.map((option) => (
                      <MenuItem
                        key={`otherFederalFundsField-${option.id}`}
                        value={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </AmoTextField>
                )}
                onSave={onFieldSave}
                isFetching={isFetching}
              />
            </Grid>

            <Grid item xs={6}>
              <ProjectReviewFieldWrapper
                projectId={projectId}
                fieldCode={projectReportFinancialsCodes.fieldProvincialFunds}
                id="provincialFundsWrapper"
                label="Provincial funds?"
                value={projectData.financials?.provincialFunds?.value}
                fieldName="provincialFunds"
                onClose={handleCloseEditPopoverBoolean}
                previousValue={
                  projectData.financials?.provincialFunds?.previousValue
                }
                flagProps={projectData.financials?.provincialFunds?.flagProps}
                formatValue={getBooleanFormat}
                renderInput={(_, setFieldValue) => (
                  <AmoTextField
                    color="primary"
                    control={control}
                    id="provincialFunds"
                    name="provincialFunds"
                    testId="provincialFunds"
                    label="Provincial funds?"
                    className={classes.inputWidth}
                    select
                    placeholder="Provincial funds?"
                    onChangeTrigger={(val) => setFieldValue(val)}
                  >
                    {yesNoValues.map((option) => (
                      <MenuItem
                        key={`provincialFundsField-${option.id}`}
                        value={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </AmoTextField>
                )}
                onSave={onFieldSave}
                isFetching={isFetching}
              />
            </Grid>
            {otherFederalFunds === "True" && (
              <>
                <Grid item xs={6}>
                  <ProjectReviewFieldWrapper
                    projectId={projectId}
                    fieldCode={projectReportFinancialsCodes.fieldStackingLimits}
                    id="stackingLimitsWrapper"
                    label="Stacking limits on federal funding exceeded?"
                    value={projectData.financials?.stackingLimits?.value}
                    fieldName="stackingLimits"
                    onClose={handleCloseEditPopoverBoolean}
                    previousValue={
                      projectData.financials?.stackingLimits?.previousValue
                    }
                    flagProps={
                      projectData.financials?.stackingLimits?.flagProps
                    }
                    formatValue={getBooleanFormat}
                    renderInput={(_, setFieldValue) => (
                      <AmoTextField
                        color="primary"
                        control={control}
                        id="stackingLimits"
                        name="stackingLimits"
                        testId="stackingLimits"
                        label="Stacking limits on federal funding exceeded?"
                        className={classes.inputWidth}
                        select
                        placeholder="Stacking limits on federal funding exceeded?"
                        onChangeTrigger={(val) => setFieldValue(val)}
                      >
                        {yesNoValues.map((option) => (
                          <MenuItem
                            key={`stackingLimitsField-${option.id}`}
                            value={option.id}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </AmoTextField>
                    )}
                    onSave={onFieldSave}
                    isFetching={isFetching}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>

      <Grid item>
        <Typography
          variant="h4"
          className={clsx(classes.title, classes.titleSpacing)}
        >
          Communications
        </Typography>
      </Grid>

      <Grid item container spacing={4}>
        <Grid item xs={6}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportCommunicationsCodes.fieldSignagePosted}
            id="signagePostedWrapper"
            label="Federal infrastructure signage"
            value={projectData.communications?.signagePosted?.value}
            fieldName="signagePosted"
            onClose={handleCloseEditPopoverBoolean}
            previousValue={
              projectData.communications?.signagePosted?.previousValue
            }
            flagProps={projectData.communications?.signagePosted?.flagProps}
            warning={signageWarning}
            formatValue={(val) => getSignageFormat(val, signagePostedTexts)}
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                color="primary"
                control={control}
                id="signagePosted"
                name="signagePosted"
                testId="signagePosted"
                label="Federal infrastructure signage"
                className={classes.inputWidth}
                select
                placeholder="Federal infrastructure signage"
                onChangeTrigger={(val) => setFieldValue(val)}
              >
                {Object.values(signagePostedValues).map((value) => (
                  <MenuItem
                    key={`constructionSignagePostedField-${value}`}
                    value={value}
                  >
                    {signagePostedTexts[value]}
                  </MenuItem>
                ))}
              </AmoTextField>
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>
        {includeNewSignageFields &&
          signagePosted === signagePostedValues.Posted && (
            <Grid item xs={6}>
              <ProjectReviewFieldWrapper
                projectId={projectId}
                fieldCode={projectReportCommunicationsCodes.fieldSignageType}
                id="signageTypeWrapper"
                label="Type of signage posted"
                value={projectData.communications?.signageType?.value}
                fieldName="signageType"
                onClose={handleCloseEditPopoverBoolean}
                previousValue={
                  projectData.communications?.signageType?.previousValue
                }
                flagProps={projectData.communications?.signageType?.flagProps}
                warning={signageWarning}
                formatValue={(val) => getSignageFormat(val, signageTypeTexts)}
                renderInput={(_, setFieldValue) => (
                  <AmoTextField
                    color="primary"
                    control={control}
                    id="signageType"
                    name="signageType"
                    testId="signageType"
                    label="Type of signage posted"
                    className={classes.inputWidth}
                    select
                    placeholder="Type of signage posted"
                    onChangeTrigger={(val) => setFieldValue(val)}
                  >
                    {Object.values(signageTypeValues).map((value) => (
                      <MenuItem key={`signageTypeField-${value}`} value={value}>
                        {signageTypeTexts[value]}
                      </MenuItem>
                    ))}
                  </AmoTextField>
                )}
                onSave={onFieldSave}
                isFetching={isFetching}
              />
            </Grid>
          )}
        {includeNewSignageFields &&
          signagePosted === signagePostedValues["Not posted"] && (
            <>
              <Grid item xs={6}>
                <ProjectReviewFieldWrapper
                  projectId={projectId}
                  fieldCode={
                    projectReportCommunicationsCodes.fieldSignageIntentions
                  }
                  id="signageIntentionsWrapper"
                  label="Signage intentions"
                  value={projectData.communications?.signageIntentions?.value}
                  fieldName="signageIntentions"
                  onClose={handleCloseEditPopoverBoolean}
                  previousValue={
                    projectData.communications?.signageIntentions?.previousValue
                  }
                  flagProps={
                    projectData.communications?.signageIntentions?.flagProps
                  }
                  warning={signageWarning}
                  formatValue={(val) =>
                    getSignageFormat(val, signageIntentionsTexts)
                  }
                  renderInput={(_, setFieldValue) => (
                    <AmoTextField
                      color="primary"
                      control={control}
                      id="signageIntentions"
                      name="signageIntentions"
                      testId="signageIntentions"
                      label="Signage intentions"
                      className={classes.inputWidth}
                      select
                      placeholder="Signage intentions"
                      onChangeTrigger={(val) => setFieldValue(val)}
                    >
                      {Object.values(signageIntentionsValues).map((value) => (
                        <MenuItem
                          key={`signageIntentionsField-${value}`}
                          value={value}
                        >
                          {signageIntentionsTexts[value]}
                        </MenuItem>
                      ))}
                    </AmoTextField>
                  )}
                  onSave={onFieldSave}
                  isFetching={isFetching}
                />
              </Grid>
              {signageIntentions ===
                signageIntentionsValues["Signage will not be posted"] && (
                <Grid item xs={6}>
                  <ProjectReviewFieldWrapper
                    projectId={projectId}
                    fieldCode={
                      projectReportCommunicationsCodes.fieldSignageRationale
                    }
                    id="signageRationaleWrapper"
                    label="Rationale"
                    value={projectData.communications?.signageRationale?.value}
                    fieldName="signageRationale"
                    onClose={handleCloseEditPopoverBoolean}
                    previousValue={
                      projectData.communications?.signageRationale
                        ?.previousValue
                    }
                    flagProps={
                      projectData.communications?.signageRationale?.flagProps
                    }
                    warning={signageWarning}
                    formatValue={(val) =>
                      getSignageFormat(val, signageRationaleTexts)
                    }
                    renderInput={(_, setFieldValue) => (
                      <AmoTextField
                        color="primary"
                        control={control}
                        id="signageRationale"
                        name="signageRationale"
                        testId="signageRationale"
                        label="Rationale"
                        className={classes.inputWidth}
                        select
                        placeholder="Rationale"
                        onChangeTrigger={(val) => setFieldValue(val)}
                      >
                        {Object.values(signageRationaleValues).map((value) => (
                          <MenuItem
                            key={`signageRationaleField-${value}`}
                            value={value}
                          >
                            {signageRationaleTexts[value]}
                          </MenuItem>
                        ))}
                      </AmoTextField>
                    )}
                    onSave={onFieldSave}
                    isFetching={isFetching}
                  />
                </Grid>
              )}
            </>
          )}

        <Grid item xs={9}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportCommunicationsCodes.titleUpload}
            id="fileUploadWrapper"
            label="File Upload"
            value="file upload"
            hideEdit
            wrapperType={
              projectData.communications?.fileUpload?.value?.length > 0
                ? reviewFieldWrapperTypes.fileUpload
                : reviewFieldWrapperTypes.text
            }
            downloadAllFunction={downloadAllFiles}
            flagProps={projectData.communications?.fileUpload?.flagProps}
            renderComponent={
              <FileUploadList
                fileAssociationId={1}
                fileAssociationObjectId={projectId}
                initialValue={
                  projectData.communications?.fileUpload?.value ?? []
                }
                onChange={onFieldSave}
                municipalityId={projectData.municipalityId}
                fileYear={2022}
                multiple
                synchronized
              />
            }
            onSave={onFieldSave}
          />
        </Grid>

        <Grid item xs={10}>
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportCommunicationsCodes.fieldLinks}
            id="providedLinksWrapper"
            label="Provided Links"
            value={projectData.communications?.providedLinks?.value}
            previousValue={
              projectData.communications?.providedLinks?.previousValue
            }
            flagProps={projectData.communications?.providedLinks?.flagProps}
            renderInput={(_, setFieldValue) => (
              <AmoTextField
                control={control}
                id="providedLinks"
                name="providedLinks"
                testId="providedLinks"
                className={classes.inputWidth}
                label="Provided Links"
                required
                onChangeTrigger={(val) => setFieldValue(val)}
                multiline
                rowsMax={5}
                rows={1}
              />
            )}
            onSave={onFieldSave}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={12} />
      </Grid>
    </>
  );
};

// set the prop-types for this component
ProjectDetailsTab.propTypes = {
  projectData: PropTypes.shape().isRequired,
  onFieldSave: PropTypes.func,
  isFetching: PropTypes.bool,
};

ProjectDetailsTab.defaultProps = {
  onFieldSave: () => {},
  isFetching: false,
};

export default ProjectDetailsTab;
