import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

import { useMunicipalContext } from "contexts/MunicipalContext";
import ProjectReviewStatus from "components/amo/ReviewStatus";

import colors from "constants/colors";
import ProjectDetailsTab from "./ProjectDetailsTab";
import ProjectReportResultsForm from "pages/municipal/report/ProjectReportResultsForm";
import { formStatuses, steps } from "constants/projectReport";
import { useYearContext } from "contexts/YearContext";
import { projectReportStatuses } from "constants/amoTableDropdownOptions";

const useStyles = makeStyles((theme) => ({
  body: {
    // 0px 352px 0px 352px
    padding: "0rem 22rem 0rem 22rem",
  },
  title: {
    fontSize: "1.5rem",
    marginBottom: "1.5rem",
  },
  titleSpacing: {
    marginTop: "2.5rem",
  },
  overviewItem: {
    marginLeft: "3.75rem",
  },
  label: {
    fontSize: "0.875rem",
  },
  rightOverviewItem: {
    marginLeft: "4.5rem",
  },
  projectStatus: {
    marginLeft: "-1.25rem",
  },
  selectedText: {
    color: "black",
    textDecoration: "underline",
    textDecorationColor: colors.green.main,
    fontWeight: 700,
  },
  tabText: {
    fontSize: "1.5rem",
    cursor: "pointer",
  },
  fullHeight: {
    height: "100REM",
  },
  stepContent: {
    flexDirection: "column",
  },
}));

/**
 * This form component to be used on the Project Review Page.
 *
 * @param {object} props - object containing props for this component
 * @param {object} props.projectData - project information used by this component
 * @param {Function} props.onFieldSave - function called when user clicks on the field popup save button (params: none)
 * @param {boolean} props.isFetching - whether the is fetching the project data
 * @param {Function} props.setShowAmoDeletionModal - set if AmoDeletionModal is shown
 *
 * @returns {Function} The form component
 */
const ProjectReviewForm = (props) => {
  const {
    projectData,
    onFieldSave,
    isFetching,
    setShowAmoDeletionModal,
  } = props;
  const {
    isReportingPastDate,
    isInReportingYear,
    isInPreviousReportingYear,
    isInFutureReportingYear,
  } = useYearContext();

  const classes = useStyles();

  const { municipalities } = useMunicipalContext();
  const [selectedTab, setSelectedTab] = useState("Project Details");

  const reportingYear = projectData
    ? municipalities[projectData.municipalityId]?.reportingYear
    : null;
  const constructionEndDate = projectData
    ? projectData.dates.constructionEndDate.value
    : null;

  const isConstructionEndInReportingYear =
    projectData &&
    (isInReportingYear(constructionEndDate, reportingYear) ||
      isInPreviousReportingYear(constructionEndDate, reportingYear) ||
      isInFutureReportingYear(constructionEndDate, reportingYear));
  const isConstructionEndBeforeReportingYear =
    projectData && !isReportingPastDate(constructionEndDate, reportingYear);

  const textItem = (text) => {
    const isDeletionRequested =
      text === projectReportStatuses["Deletion Requested"];
    return (
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={
            isDeletionRequested
              ? {
                  color: colors.red.warning,
                  cursor: "pointer",
                  fontWeight: 600,
                }
              : null
          }
          onClick={isDeletionRequested ? setShowAmoDeletionModal : null}
        >
          {text}
        </Typography>
      </Grid>
    );
  };

  const reviewFormItem = (title, internalComponent) => (
    <Grid item xs={6}>
      <Grid container alignItems="flex-end" className={classes.overviewItem}>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.label}>
            {title}
          </Typography>
        </Grid>
        {internalComponent}
      </Grid>
    </Grid>
  );

  return (
    <>
      {projectData && (
        <form>
          <Grid container direction="column" spacing={0} wrap="nowrap">
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                Overview
              </Typography>
            </Grid>

            <Grid item container spacing={4}>
              {reviewFormItem(
                "Review Status",
                <Grid item xs={12} className={classes.projectStatus}>
                  <ProjectReviewStatus
                    id={projectData.statusId}
                    name={projectData.statusName}
                  />
                </Grid>
              )}
              {reviewFormItem(
                "Assigned To",
                textItem(projectData.assigneeName)
              )}
              {reviewFormItem(
                "Project Status",
                textItem(projectData.projectStatus)
              )}
              {reviewFormItem(
                "Report Status",
                textItem(projectData.reportStatus)
              )}
              {reviewFormItem(
                "Municipality",
                textItem(
                  municipalities[projectData.municipalityId]?.fullName ?? "--"
                )
              )}
              {reviewFormItem(
                "Communication Status",
                textItem(projectData.communicationStatus)
              )}
            </Grid>

            <Divider style={{ marginTop: "1.5rem", marginBottom: "1rem" }} />

            <Grid
              item
              xs={12}
              container
              direction="row"
              style={{ paddingLeft: "1rem", marginTop: "1rem" }}
            >
              <Typography
                className={clsx(
                  classes.tabText,
                  selectedTab === "Project Details" && classes.selectedText
                )}
                onClick={() => setSelectedTab("Project Details")}
              >
                Project Details
              </Typography>
              <Typography
                className={clsx(
                  classes.tabText,
                  selectedTab === "Results" && classes.selectedText
                )}
                style={{ marginLeft: "3rem" }}
                onClick={() => {
                  setSelectedTab("Results");
                }}
              >
                Results
              </Typography>
            </Grid>
            <Divider style={{ marginTop: "1.5rem", marginBottom: "1rem" }} />

            {selectedTab === "Project Details" && (
              <ProjectDetailsTab
                projectData={projectData}
                onFieldSave={onFieldSave}
                isFetching={isFetching}
              />
            )}

            {selectedTab === "Results" && (
              <Grid item xs>
                <Box className={clsx(classes.fullHeight, classes.stepContent)}>
                  <ProjectReportResultsForm
                    projectId={projectData.projectId}
                    activeStep={steps.results}
                    categoryData={{
                      categoryId: Number(
                        projectData.category?.selectedCategory?.value
                      ),
                      subcategoryId: Number(
                        projectData.category?.selectedSubcategory?.value
                      ),
                    }}
                    categoryFormStatus={formStatuses.completed}
                    isConstructionEndInReportingYear={
                      isConstructionEndInReportingYear
                    }
                    isConstructionEndBeforeReportingYear={
                      isConstructionEndBeforeReportingYear
                    }
                    isAnnualReportTab={false}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </>
  );
};

// set the prop-types for this component
ProjectReviewForm.propTypes = {
  projectData: PropTypes.shape().isRequired,
  onFieldSave: PropTypes.func,
  isFetching: PropTypes.bool,
  setShowAmoDeletionModal: PropTypes.func,
};

ProjectReviewForm.defaultProps = {
  onFieldSave: () => {},
  isFetching: false,
  setShowAmoDeletionModal: () => {},
};

export default ProjectReviewForm;
