// Set up a key factory per feature for a flexible query key reference
export const projectKeys = {
  projects: ["projects"],
  projectCategories: () => [...projectKeys.projects, "categories"],
  project: (projectId) => [...projectKeys.projects, projectId],
  projectCategory: (projectId) => [
    ...projectKeys.projects,
    "category",
    projectId,
  ],
  projectInformation: (projectId) => [
    ...projectKeys.projects,
    "information",
    projectId,
  ],
  projectFinancials: (projectId) => [
    ...projectKeys.projects,
    "financials",
    projectId,
  ],
  projectCommunications: (projectId) => [
    ...projectKeys.projects,
    "communications",
    projectId,
  ],
  projectFunds: (projectId) => [...projectKeys.projects, "funds", projectId],
  projectValidation: (projectId) => [
    ...projectKeys.projects,
    "validation",
    projectId,
  ],
};
