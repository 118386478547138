import * as React from "react";
import { formatToCurrency } from "../../../utils/number";
import { Divider, Grid, Typography } from "@material-ui/core";

const financialRowElement = (
  displayName,
  financialDataFieldName,
  financialData,
  classes,
  insertDivider = false,
  bold = false,
  underlined = false,
  isNegative = false,
  hasLeftMargin = true,
  hasVerticalSpacing = false,
  dontShowBeforeYear = undefined
) => {
  if (
    dontShowBeforeYear !== undefined &&
    financialData !== undefined &&
    financialData?.years !== undefined &&
    financialData?.years.length === 1 &&
    financialData.years[0].year < dontShowBeforeYear
  ) {
    return <></>;
  }
  return (
    <>
      {insertDivider ? (
        <Divider className={classes.rowDivider} />
      ) : (
        <div style={{ marginTop: 8, marginBottom: 8 }} />
      )}
      <Grid
        container
        direction="row"
        wrap="nowrap"
        className={hasVerticalSpacing ? classes.verticalMargins : undefined}
      >
        {financialDataFieldName !== undefined ? (
          <>
            <Grid item className={classes.firstColumnCell}>
              <Typography
                variant={bold ? "body2" : "body1"}
                className={underlined ? classes.underlined : undefined}
                style={{ marginLeft: hasLeftMargin ? 24 : 0 }}
              >
                {displayName}
              </Typography>
            </Grid>
            {financialData?.years &&
              financialData?.years?.map(({ year, ...financial }) => (
                <Grid item className={classes.cell} key={year} align="right">
                  <Typography
                    variant={bold ? "body2" : "body1"}
                    className={underlined ? classes.underlined : undefined}
                    style={{
                      marginRight:
                        !isNegative || financial[financialDataFieldName] === 0
                          ? 4
                          : 0,
                    }}
                  >
                    {getFieldValue(
                      year,
                      financial,
                      financialDataFieldName,
                      dontShowBeforeYear,
                      isNegative
                    )}
                  </Typography>
                </Grid>
              ))}
            <Grid className={classes.middleColumnSpacing} />
            <Grid item className={classes.lastColumnCell} align="right">
              <Typography
                variant={bold ? "body2" : "body1"}
                className={underlined ? classes.underlined : undefined}
                style={{
                  marginRight:
                    !isNegative ||
                    financialData?.cumulative[financialDataFieldName] === 0
                      ? 4
                      : 0,
                }}
              >
                {financialData?.cumulative[financialDataFieldName] === undefined
                  ? ""
                  : formatToCurrency(
                      (isNegative ? -1 : 1) *
                        financialData?.cumulative[financialDataFieldName]
                    )}
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item className={classes.firstColumnCell}>
            <Typography
              variant={bold ? "h5" : "body1"}
              className={underlined ? classes.underlined : undefined}
            >
              {displayName}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const getFieldValue = (
  year,
  financial,
  financialDataFieldName,
  dontShowBeforeYear,
  isNegative
) => {
  let fieldValue = "";
  if (dontShowBeforeYear !== undefined && year < dontShowBeforeYear) {
    return fieldValue;
  }
  if (financial[financialDataFieldName] !== undefined) {
    fieldValue = formatToCurrency(
      (isNegative ? -1 : 1) * financial[financialDataFieldName]
    );
  }
  return fieldValue;
};

export default financialRowElement;
