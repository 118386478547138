import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { projectNavOptions } from "../../constants/projectNavOptions";
import { Paper, Grid, Divider, Typography, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import colors from "../../constants/colors";

// Hooks
import { useAnnualReportNavBar } from "./annualReportNavBarHooks";

// Contexts
import { useMunicipalContext } from "contexts/MunicipalContext";
import { useYearContext } from "contexts/YearContext";

// Sub-components
import AnnualReportNavBarItem from "./AnnualReportNavBarItem";

const drawerOpenWidth = 368;
const drawerClosedWidth = 51;
const displayMenuWidth = "100%";

// this hook creates styles for when this component is used as a navigation drawer
const useDrawerStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerClosedWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100%",
    zIndex: 100,
  },
  drawerOpen: {
    width: drawerOpenWidth,
    overflowX: "visible",
    zIndex: 100,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  paper: {
    color: colors.black,
  },
  outlined: {
    border: true,
    borderColor: colors.grey.main,
  },
  rounded: {
    borderRadius: "0.625rem",
    padding: "0.625rem",
  },
}));

// this hook creates styles for when this component is used on the landing page
const useWidgetStyles = makeStyles((theme) => ({
  header: {
    padding: "0.625rem",
  },
  title: {
    padding: "0 0 0 0.5rem",
  },
  year: {
    marginLeft: "auto",
  },
  drawer: {
    width: displayMenuWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100%",
  },
  paper: {
    color: colors.black,
  },
  outlined: {
    border: true,
    borderColor: colors.grey.main,
  },
  rounded: {
    borderRadius: "0.625rem",
    padding: "0.625rem",
  },
}));

/**
 * This component helps display the different sections of the annual report as well
 * as allow navigation to those sections
 *
 * @param {object} props - the properties of this component
 * @param {string} props.variant - helps the component decide styles
 * @param {boolean} props.respondToMouseEvents - when true allows mouse events to change component UI
 * @returns - the Project NavBar component, styled for the AMO Project
 */
const AnnualReportNavBar = (props) => {
  // destructure the props
  const { variant, respondToMouseEvents } = props;
  // this controls whether or not the drawer is open or closed when in "drawer" mode
  const [isOpen, setIsOpen] = useState(!respondToMouseEvents);

  // call hooks to create the styles needed for this control
  const drawerClasses = useDrawerStyles();
  const widgetClasses = useWidgetStyles();
  const { contextYear } = useYearContext();

  // consume context
  const municipalContext = useMunicipalContext();
  // converted to string because if not, it will start as a number and later on converted to string, creating 2 different react queries and refetch issues
  const municipalityId = municipalContext?.selectedMunicipalityId?.toString();

  // this method creates the URL paths for the links
  const getNavLink = (linkFor) => {
    let navLink = "";
    switch (linkFor) {
      case projectNavOptions.Projects: {
        navLink = "projects";
        break;
      }
      case projectNavOptions.Insurance: {
        navLink = "insurance";
        break;
      }
      case projectNavOptions.Financials: {
        navLink = "financials";
        break;
      }
      case projectNavOptions.RiskManagement: {
        navLink = "questionnaires/risk";
        break;
      }
      case projectNavOptions.AssetManagement: {
        navLink = "questionnaires/assets";
        break;
      }
      case projectNavOptions.ReportingYear: {
        navLink = "close";
        break;
      }
      default: {
        navLink = "";
        break;
      }
    }

    // create link urls
    return `/reporting/${contextYear}/${navLink}`;
  };

  // this helps to force the open/closed state to change
  // as the user is resizing their browser window
  useEffect(() => {
    if (respondToMouseEvents && isOpen) {
      setIsOpen(!isOpen);
    } else if (!respondToMouseEvents && !isOpen) {
      setIsOpen(true);
    }
  }, [respondToMouseEvents]);

  const { data: reportSummary, isLoading } = useAnnualReportNavBar(
    municipalityId
  );

  // setup the title section, if not in "drawer" mode
  const title =
    variant === "drawer" ? null : (
      <>
        <Grid
          container
          classes={{
            root: widgetClasses.header,
          }}
        >
          <Grid
            item
            classes={{
              root: widgetClasses.title,
            }}
          >
            <Typography variant="h3">
              <Box color={colors.green.dark}>Reporting Checklist</Box>
            </Typography>
          </Grid>
          <Grid
            item
            classes={{
              root: widgetClasses.year,
            }}
          >
            <Box color={colors.green.dark}>
              <Typography variant="h4">{contextYear}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item />
      </>
    );

  const MenuLoadingSkeleton = () => (
    <>
      <Skeleton width="100%" height="50px" />
      <Skeleton width="100%" height="50px" />
      <Skeleton width="100%" height="50px" />
    </>
  );

  // Define some of the longer strings for display below
  const projectsProgress = `${reportSummary?.data.completedProjects}/${reportSummary?.data.totalProjects}`;
  const financialStatus = reportSummary?.data.financialStatus;

  // this sets up the menu items, passing in the status text retrieved from the API
  const menuContent = isLoading ? (
    <MenuLoadingSkeleton />
  ) : (
    reportSummary && (
      <>
        {title}
        <AnnualReportNavBarItem
          variant={variant}
          open={variant === "drawer" ? isOpen : true}
          navLink={getNavLink(projectNavOptions.Projects)}
          menuItemContent={projectNavOptions.Projects}
          statusText={`${projectsProgress} reports complete`}
        />
        <Divider orientation="horizontal" variant="fullWidth" light />
        <AnnualReportNavBarItem
          variant={variant}
          open={variant === "drawer" ? isOpen : true}
          navLink={getNavLink(projectNavOptions.Insurance)}
          menuItemContent={projectNavOptions.Insurance}
          statusText={reportSummary.data.insuranceStatus}
        />
        <Divider orientation="horizontal" variant="fullWidth" light />
        <AnnualReportNavBarItem
          variant={variant}
          open={variant === "drawer" ? isOpen : true}
          navLink={getNavLink(projectNavOptions.Financials)}
          menuItemContent={projectNavOptions.Financials}
          statusText={financialStatus}
        />
        {reportSummary.data.riskManagementQuestionnaireStatus !==
          "Not yet available" && (
          <>
            <Divider orientation="horizontal" variant="fullWidth" light />
            <AnnualReportNavBarItem
              variant={variant}
              open={variant === "drawer" ? isOpen : true}
              navLink={getNavLink(projectNavOptions.RiskManagement)}
              menuItemContent={projectNavOptions.RiskManagement}
              statusText={reportSummary.data.riskManagementQuestionnaireStatus}
            />
          </>
        )}
        {reportSummary.data.assetManagementQuestionnaireStatus !==
          "Not yet available" && (
          <>
            <Divider orientation="horizontal" variant="fullWidth" light />
            <AnnualReportNavBarItem
              variant={variant}
              open={variant === "drawer" ? isOpen : true}
              navLink={getNavLink(projectNavOptions.AssetManagement)}
              menuItemContent={projectNavOptions.AssetManagement}
              statusText={reportSummary.data.assetManagementQuestionnaireStatus}
            />
          </>
        )}
        <Divider orientation="horizontal" variant="fullWidth" light />
        <AnnualReportNavBarItem
          variant={variant}
          open={variant === "drawer" ? isOpen : true}
          navLink={getNavLink(projectNavOptions.ReportingYear)}
          menuItemContent={projectNavOptions.ReportingYear}
          statusText={reportSummary.data.closeYearStatus}
        />
      </>
    )
  );

  // finally return the created component
  return (
    <>
      <Paper
        square={false}
        variant="outlined"
        className={clsx(
          variant === "drawer" ? drawerClasses.drawer : widgetClasses.drawer,
          {
            [drawerClasses.drawerOpen]: variant === "drawer" ? isOpen : null,
            [drawerClasses.drawerClose]: variant === "drawer" ? !isOpen : null,
          }
        )}
        classes={{
          root: clsx(
            variant === "drawer" ? drawerClasses.paper : widgetClasses.paper,
            {
              [drawerClasses.drawerOpen]: variant === "drawer" ? isOpen : null,
              [drawerClasses.drawerClose]:
                variant === "drawer" ? !isOpen : null,
            }
          ),
          rounded:
            variant === "drawer"
              ? drawerClasses.rounded
              : widgetClasses.rounded,
          outlined:
            variant === "drawer"
              ? drawerClasses.outlined
              : widgetClasses.outlined,
        }}
        onMouseOver={() => {
          if (!isOpen) {
            if (respondToMouseEvents) {
              setIsOpen(true);
            }
          }
        }}
        onMouseLeave={() => {
          if (isOpen) {
            if (respondToMouseEvents) {
              setIsOpen(false);
            }
          }
        }}
      >
        <Grid
          container
          direction="column"
          spacing={0}
          alignItems={isOpen ? "flex-start" : "center"}
        >
          {menuContent}
        </Grid>
      </Paper>
    </>
  );
};

AnnualReportNavBar.defaultProps = {
  respondToMouseEvents: true,
};

AnnualReportNavBar.propTypes = {
  variant: PropTypes.oneOf(["drawer", "widget"]).isRequired,
  respondToMouseEvents: PropTypes.bool,
};

export default AnnualReportNavBar;
