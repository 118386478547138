import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { TextField, InputAdornment, IconButton, Icon } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import colors from "constants/colors";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  inputIcon: {
    color: colors.grey.dark,
  },
  warning: {
    "& label.Mui-focused": {
      color: colors.yellow,
    },
    "& legend.Mui-focused": {
      color: colors.yellow,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root:not(.Mui-disabled)": {
      "& fieldset": {
        borderColor: colors.yellow,
      },
      "&:hover fieldset": {
        borderColor: colors.yellow,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.yellow,
      },
      "& .MuiInputAdornment-root button": {
        color: colors.yellow,
      },
    },
  },
}));

/**
 * A autocomplete component discover a position by address
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the component [required]
 * @param {string} props.testId - data-testid of the component
 * @param {string} props.label - label of the textfield input
 * @param {string} props.inputValue - value of the textfield input
 * @param {boolean} props.required - controls whether the textfield input is required or not
 * @param {boolean} props.warning - controls whether the textfield input display warning style or not
 * @param {string} props.helperText - helperText of the textfield input
 * @param {boolean} props.error - error state of the textfield input
 * @param {Function} props.onSelect - function called when user selects an address on the autocomplete list (params: { address, position })
 * @param {Function} props.onInputChange - function called when user textfield input changes (params: { value })
 * @param {boolean} props.hideAdornment - controls whether textfield end adornment button will appear
 * @param {Function} props.onAdornmentClick - function called when user clicks on the textfield end adornment button (params: none)
 * @param {boolean} props.disabled - controls whether textfield and button will be disabled
 * @param {Function} props.onFocus - function called when user focus on the textfield input (params: none)
 * @param {Function} props.onBlur - function called when user blur on the textfield input (params: none)
 *
 * @returns {React.Component} The autocomplete component
 */
const AmoLocationAutocomplete = (props) => {
  const {
    id,
    testId,
    label,
    inputValue,
    required,
    warning,
    helperText,
    error,
    onSelect,
    onInputChange,
    hideAdornment,
    onAdornmentClick,
    disabled,
    onFocus,
    onBlur,
  } = props;

  const classes = useStyles();

  const mounted = useIsMounted();

  const [selectedValue, setSelectedValue] = useState(null);

  const handleAutocompleteChange = async (_, value) => {
    if (!value || !value?.description) {
      return;
    }

    try {
      const results = await geocodeByAddress(value.description);

      if (!mounted.current) {
        return;
      }

      const latLng = await getLatLng(results[0]);

      if (!mounted.current) {
        return;
      }

      setSelectedValue(value);
      onSelect(value.description, latLng);
    } catch (err) {
      console.error("Error", err);
    }
  };

  const renderMuiAutocomplete = (
    suggestions,
    getInputProps,
    loading,
    onChange = undefined
  ) => (
    <Autocomplete
      id={`${id}-mui`}
      onChange={handleAutocompleteChange}
      options={suggestions}
      getOptionLabel={(option) => option.description || ""}
      getOptionSelected={(option, value) => option.placeId === value.placeId}
      filterOptions={(options) => options}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          onChange={(event) => onChange(event.target.value)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getInputProps()}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid={testId ?? id}
          required={required}
          label={label}
          helperText={helperText}
          error={error}
          variant="outlined"
          className={warning ? classes.warning : null}
          InputProps={{
            ...params.InputProps,
            endAdornment: !hideAdornment && (
              <InputAdornment position="end">
                <IconButton
                  id={`${id}-modal-button`}
                  data-testid={`${testId ?? `${id}-test`}ModalButton`}
                  aria-label="openLocationModalIcon"
                  onClick={() => onAdornmentClick()}
                  disabled={disabled}
                >
                  <Icon
                    className={clsx(
                      "material-icons-outlined",
                      disabled ? "Mui-disabled" : classes.inputIcon
                    )}
                  >
                    location_on
                  </Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      value={selectedValue}
      inputValue={inputValue}
      loading={loading}
      forcePopupIcon={false}
      disableClearable
      popupIcon={null}
    />
  );

  const renderGoogleAutocomplete = () => (
    <PlacesAutocomplete id={id} value={inputValue} onChange={onInputChange}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
        renderMuiAutocomplete(suggestions, getInputProps, loading)
      }
    </PlacesAutocomplete>
  );

  const render = (status) => {
    if (status === Status.SUCCESS) {
      return renderGoogleAutocomplete();
    }
    return renderMuiAutocomplete(
      [],
      () => {},
      status === Status.LOADING,
      onInputChange
    );
  };

  return (
    <>
      <Wrapper
        apiKey={process.env.REACT_APP_PLACES_API_KEY}
        libraries={["places"]}
        render={render}
        region="CA"
      />
    </>
  );
};

// set the prop-types for this component
AmoLocationAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  label: PropTypes.string,
  inputValue: PropTypes.string,
  required: PropTypes.bool,
  warning: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  hideAdornment: PropTypes.bool,
  onAdornmentClick: PropTypes.func,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

AmoLocationAutocomplete.defaultProps = {
  testId: undefined,
  label: "",
  inputValue: "",
  required: false,
  warning: false,
  helperText: "",
  error: false,
  onSelect: () => {},
  onInputChange: () => {},
  hideAdornment: false,
  onAdornmentClick: () => {},
  disabled: false,
  onFocus: () => {},
  onBlur: () => {},
};

export default AmoLocationAutocomplete;
