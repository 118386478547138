import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography, FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import { actionTypes } from "constants/historyLog";
import HistoryLogChangeItem from "./HistoryLogChangeItem";
import HistoryLogCommentItem from "./HistoryLogCommentItem";
import colors from "constants/colors";
import reviewTypes from "constants/reviewTypes";
import { useHistoryLogs } from "./historyLogHooks";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    border: "1px solid black",
    borderRadius: "0.5rem",
    padding: "0.8rem 1.5rem",
    width: "100%",
    maxWidth: "30rem",
  },
  headerWrapper: {
    marginBottom: "0.5rem",
  },
  switchWrapper: {
    marginRight: "0",
  },
  switchLabel: {
    fontSize: "0.9rem",
    paddingLeft: "0.5rem",
  },
  logsItem: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  noHistoryItem: {
    background: colors.grey.light,
    margin: "0.2rem 0",
    padding: "1rem",
    textAlign: "Center",
  },
}));

/**
 * @typedef {{
 *  id: number,
 *  actionTypeDescription: string,
 *  actionDate: string,
 *  actionBy: string,
 *  actionValue: string,
 *  isAmoUser: boolean
 * }} Log
 */

/**
 * A component to display a history of event logs
 *
 * @param {object} props - object containing props for this component
 * @param {number} props.objectId - id of the object related to this component (Review or Communication)
 * @param {string} props.type - type of the history log information (values: @see reviewTypes) [defaults to: "review"]
 * @param {string} props.width - width of component
 * @param {string} props.maxHeight - max height of component
 * @param {boolean} props.hideShowChanges - hides "Show Changes" switch
 * @param {boolean} props.isCommunicationUser - true if the user is a communication user
 *
 * @returns - The entire history log component
 */
const HistoryLog = (props) => {
  const {
    objectId,
    type,
    maxHeight,
    hideShowChanges,
    isCommunicationUser,
  } = props;
  const classes = useStyles();
  const [showChanges, setShowChanges] = useState(true);
  const bottomRef = useRef(null);

  useEffect(() => {
    // Added this condition because the unit tests doesn't seem to support the scrollIntoView function
    if (bottomRef.current?.scrollIntoView) {
      bottomRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, []);

  const { data: historyLogs, isLoading: isLoadingHistory } = useHistoryLogs(
    type,
    objectId
  );

  const filteredLogs =
    historyLogs?.filter(
      ({ actionDescription: actionType }) =>
        (showChanges || actionType === actionTypes.comment) &&
        // Hide these specific types for now (maybe we'll use flagField some other time)
        actionType !== actionTypes.flagField &&
        actionType !== actionTypes.editField
    ) ?? [];

  return isLoadingHistory ? (
    <Skeleton width="30rem" height="13rem" variant="rect" />
  ) : (
    <Grid
      container
      direction="column"
      className={classes.mainContainer}
      spacing={0}
      wrap="nowrap"
    >
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerWrapper}
        >
          <Grid item>
            <Typography variant="h5">History</Typography>
          </Grid>
          {!hideShowChanges && (
            <Grid item>
              <FormControlLabel
                className={classes.switchWrapper}
                control={<Switch defaultChecked color="primary" />}
                label={
                  <Typography variant="caption" className={classes.switchLabel}>
                    Show changes
                  </Typography>
                }
                onChange={(event) => setShowChanges(event.target.checked)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item className={classes.logsItem} style={{ maxHeight }}>
        <Grid container direction="column">
          {filteredLogs.map((log) =>
            log.actionDescription === actionTypes.comment ? (
              <HistoryLogCommentItem
                key={log.id}
                id={log.id}
                timestamp={log.actionDate}
                user={log.actionBy}
                value={log.actionValue}
              />
            ) : (
              <HistoryLogChangeItem
                key={log.id}
                id={log.id}
                type={log.actionDescription}
                timestamp={log.actionDate}
                user={log.actionBy}
                value={log.actionValue}
                isAmoUser={log.isAmoUser}
                isCommunicationUser={isCommunicationUser}
              />
            )
          )}
          {!filteredLogs.length && (
            <div className={classes.noHistoryItem}>
              <Typography variant="body1">No history found</Typography>
            </div>
          )}
          <div ref={bottomRef} />
        </Grid>
      </Grid>
    </Grid>
  );
};

// set the prop-types for this component
HistoryLog.propTypes = {
  objectId: PropTypes.number.isRequired,
  type: PropTypes.oneOf([reviewTypes.review, reviewTypes.communications]),
  maxHeight: PropTypes.string,
  hideShowChanges: PropTypes.bool,
  isCommunicationUser: PropTypes.bool,
};

HistoryLog.defaultProps = {
  type: reviewTypes.review,
  maxHeight: "23em",
  hideShowChanges: false,
  isCommunicationUser: false,
};

export default HistoryLog;
