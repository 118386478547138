export const fileTypes = {
  jpeg: "jpeg",
  jpg: "jpg",
  png: "png",
  pdf: "pdf",
  mov: "mov",
  avi: "avi",
  xlsx: "xlsx",
  gif: "gif",
  mp4: "mp4",
  wmv: "wmv",
  docx: "docx",
  pptx: "pptx",
};

export const fileAssociations = {
  Projects: 1,
  Financials: 2,
  Insurance: 3,
  Questionnaires: 4,
  Help: 5,
};
