import React, { useState, useLayoutEffect } from "react";
import {
  Grid,
  Icon,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import PropTypes from "prop-types";

import RichTextEditor from "components/RichTextEditor";

import colors from "constants/colors";
import { formFieldTypes } from "constants/formContentManagement";

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    width: "30%",
    borderRadius: "5px",
  },
  dialogTitle: {
    margin: 0,
    // 16px 16px 0px 16px
    padding: "1rem 1rem 0rem 1rem",
  },
  dialogContent: {
    borderTop: "none",
    borderBottomColor: colors.grey.main,
    // 28px 40px
    padding: `1.75rem 2.5rem`,
  },
  dialogActions: {
    // 16px
    padding: `1rem`,
    borderTop: `1px solid ${colors.grey.main}`,
  },
  dialogActionsSpacing: {
    "& :not(:first-child)": {
      // 16px
      marginLeft: "1rem",
    },
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "500",
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    // 8px
    top: "0.5rem",
    color: colors.grey.icon,
  },
  button: {
    // 50px
    minHeight: "3.125rem",
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    // 16px
    marginLeft: "1rem",
  },
  form: {
    "& > :not(:last-child)": {
      // 20px
      paddingBottom: "1.25rem",
    },
  },
}));

/**
 * A dialog component to edit a form content management field
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the component [required]
 * @param {string} props.testId - data-testid of the component
 * @param {object} props.field - object containing the props of the form field being edited
 * @param {number} props.field.id - form field id
 * @param {string} props.field.code - form field code
 * @param {string} props.field.type - form field type (valid values: @see formFieldTypes)
 * @param {string} props.field.text - form field text value
 * @param {string} props.field.helperText - form field helper text value (valid for types: @see formFieldTypes.field @see formFieldTypes.link )
 * @param {string} props.field.tooltipText - form field tooltip text value (valid for types: @see formFieldTypes.titleWithTooltip @see formFieldTypes.field )
 * @param {string} props.field.url - form field url value (valid for type: @see formFieldTypes.link )
 * @param {boolean} props.open - whether the dialog is open or not
 * @param {Function} props.onSave - function called when user clicks on the Save button (params: @see props.field)
 * @param {Function} props.onClose - function called when user clicks on the Close button (params: none)
 * @param {boolean} props.showHelpText - whether the dialog need to show Help Text or not
 * @returns - The dialog component
 */
const FormFieldEditDialog = (props) => {
  const {
    id,
    testId,
    field,
    open,
    onSave,
    onClose,
    showHelpText = true,
  } = props;

  const classes = useStyles();

  const [fieldState, setFieldState] = useState({});
  const [formIsValid, setFormIsValid] = useState(true);

  useLayoutEffect(() => {
    setFieldState({ ...field });
  }, [field]);

  const handleChange = (updatedField, newValue) => {
    setFieldState({ ...fieldState, [updatedField]: newValue });
    // TODO: validate form
    setFormIsValid(true);
  };

  const handleSave = (event) => {
    onSave(fieldState);
  };

  const handleClose = (event) => {
    onClose();
  };

  const renderModalTitle = () => {
    if (fieldState.type === formFieldTypes.description) {
      return "Edit Description";
    }
    if (
      fieldState.type === formFieldTypes.title ||
      fieldState.type === formFieldTypes.titleWithTooltip
    ) {
      return "Edit Title";
    }
    if (fieldState.type === formFieldTypes.field) {
      return "Edit Field";
    }
    if (fieldState.type === formFieldTypes.link) {
      return "Edit Link";
    }

    return null;
  };

  const renderModalBody = () => {
    if (fieldState.type === formFieldTypes.description) {
      return renderDescriptionBody();
    }
    if (fieldState.type === formFieldTypes.title) {
      return renderTitleBody();
    }
    if (fieldState.type === formFieldTypes.titleWithTooltip) {
      return renderTitleWithTooltipBody();
    }
    if (fieldState.type === formFieldTypes.field) {
      return renderFieldBody();
    }
    if (fieldState.type === formFieldTypes.link) {
      return renderLinkBody();
    }

    return null;
  };

  const renderDescriptionBody = () => (
    <RichTextEditor
      id={`${id}-description`}
      data-testid={`${testId ?? `${id}-test`}Description`}
      placeholder="Description (Tags like <a>, <b>, <i> accepted)"
      value={fieldState.text}
      onChange={(value) => handleChange("text", value)}
      decreaseToolbarSpacing
    />
  );

  const renderTitleBody = () => (
    <TextField
      id={`${id}-title`}
      data-testid={`${testId ?? `${id}-test`}Title`}
      className={classes.fullWidth}
      label="Title"
      value={fieldState.text}
      onChange={(event) => handleChange("text", event.target.value, true)}
      variant="outlined"
    />
  );

  const renderTitleWithTooltipBody = () => (
    <Box className={classes.form}>
      <TextField
        id={`${id}-title`}
        data-testid={`${testId ?? `${id}-test`}Title`}
        className={classes.fullWidth}
        label="Title"
        value={fieldState.text}
        onChange={(event) => handleChange("text", event.target.value, true)}
        variant="outlined"
        required
      />
      <RichTextEditor
        id={`${id}-tooltip`}
        data-testid={`${testId ?? `${id}-test`}Tooltip`}
        placeholder="Tooltip Text (Tags like <a>, <b>, <i> accepted)"
        value={fieldState.tooltipText}
        onChange={(value) => handleChange("tooltipText", value)}
        decreaseToolbarSpacing
      />
    </Box>
  );

  const renderFieldBody = () => (
    <Box className={classes.form}>
      <TextField
        id={`${id}-field`}
        data-testid={`${testId ?? `${id}-test`}Field`}
        className={classes.fullWidth}
        label="Label Text"
        value={fieldState.text}
        onChange={(event) => handleChange("text", event.target.value, true)}
        variant="outlined"
        required
      />
      {showHelpText && (
        <TextField
          multiline
          id={`${id}-help`}
          data-testid={`${testId ?? `${id}-test`}Help`}
          className={classes.fullWidth}
          label="Help Text"
          value={fieldState.helperText}
          onChange={(event) => handleChange("helperText", event.target.value)}
          variant="outlined"
        />
      )}
      <RichTextEditor
        id={`${id}-tooltip`}
        data-testid={`${testId ?? `${id}-test`}Tooltip`}
        placeholder="Tooltip Text (Tags like <a>, <b>, <i> accepted)"
        value={fieldState.tooltipText}
        onChange={(value) => handleChange("tooltipText", value)}
        decreaseToolbarSpacing
      />
    </Box>
  );

  const renderLinkBody = () => (
    <Box className={classes.form}>
      <TextField
        id={`${id}-link`}
        data-testid={`${testId ?? `${id}-test`}Link`}
        className={classes.fullWidth}
        label="Link Text"
        value={fieldState.text}
        onChange={(event) => handleChange("text", event.target.value, true)}
        variant="outlined"
        required
      />
      <TextField
        id={`${id}-url`}
        data-testid={`${testId ?? `${id}-test`}Url`}
        className={classes.fullWidth}
        label="URL"
        value={fieldState.url}
        onChange={(event) => handleChange("url", event.target.value, true)}
        variant="outlined"
        required
      />
      <TextField
        id={`${id}-aria-label`}
        data-testid={`${testId ?? `${id}-test`}AriaLabel`}
        className={classes.fullWidth}
        label="ARIA Label"
        value={fieldState.helperText}
        onChange={(event) => handleChange("helperText", event.target.value)}
        variant="outlined"
      />
    </Box>
  );

  return (
    <>
      <Dialog
        id={id}
        data-testid={testId ?? `${id}-test`}
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.modalPaper,
        }}
        elevation={4}
        // hideBackdrop
      >
        <DialogTitle
          disableTypography
          className={classes.dialogTitle}
          onClose={handleClose}
        >
          <Typography variant="h6" className={classes.title}>
            {renderModalTitle()}
          </Typography>
          <IconButton
            data-testid={`${testId ?? `${id}-test`}CloseIcon`}
            aria-label="closeIcon"
            className={classes.closeIcon}
            onClick={handleClose}
          >
            <Icon className="material-icons">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item className={classes.fullWidth}>
              {renderModalBody()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          className={classes.dialogActions}
          classes={{ spacing: classes.dialogActionsSpacing }}
        >
          <Button
            data-testid={`${testId ?? `${id}-test`}SaveButton`}
            className={classes.button}
            onClick={handleSave}
            color="primary"
            variant="contained"
            size="large"
            disabled={!formIsValid}
          >
            Save
          </Button>
          <Button
            data-testid={`${testId ?? `${id}-test`}CloseButton`}
            className={clsx(classes.button, classes.closeButton)}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="large"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// set the prop-types for this component
FormFieldEditDialog.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  field: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    type: PropTypes.oneOf([
      formFieldTypes.description,
      formFieldTypes.title,
      formFieldTypes.titleWithTooltip,
      formFieldTypes.field,
      formFieldTypes.link,
    ]),
    text: PropTypes.string,
    helperText: PropTypes.string,
    tooltipText: PropTypes.string,
    url: PropTypes.string,
  }),
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  showHelpText: PropTypes.bool,
};

FormFieldEditDialog.defaultProps = {
  testId: null,
  open: false,
  field: null,
  onSave: () => {},
  onClose: () => {},
  showHelpText: true,
};

export default FormFieldEditDialog;
