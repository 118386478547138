import React, { useState, useEffect } from "react";
import { makeStyles, Divider, Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import AmoModal from "components/AmoModal";
import AmoTextField from "components/inputs/AmoTextField";
import AmoSwitch from "components/inputs/AmoSwitch";
import { NumberFormatCustom } from "pages/municipal/report/FinancialsForm";

import colors from "constants/colors";
import { formatToCurrency } from "utils/number";

import { useIncrementalityMonitoringModal } from "./incrementalityMonitoringReviewsHooks";
import { useWrapApi } from "hooks/wrapApiHook";
import { useIsMounted } from "hooks/useIsMounted";
import { incrementalityMonitoringService } from "api/services/incrementalityMonitoringService";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "1.5rem",
  },
  dividerContainer: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  rowContainer: {
    marginBottom: "0.75rem",
  },
  rowLabel: {
    color: colors.green.dark,
  },
  inputField: {
    "& > .MuiInput-underline:after": {
      transform: "none",
    },
  },
}));

/**
 * The Incrementality Monitoring Modal.
 *
 * @param {object} props - object containing props for this component
 * @param {object} props.municipalityId - sets the municipality used to load the modal data [required]
 * @param {boolean} props.open - controls visibility of the AmoModal component
 * @param {Function} props.onClose - function called when the modal should be closed
 *
 * @returns {React.Component} Incrementality Monitoring Modal component.
 */
const IncrementalityMonitoringModal = (props) => {
  const { municipalityId, open, onClose } = props;
  const classes = useStyles();

  const mounted = useIsMounted();

  const [annualTarget, setAnnualTarget] = useState(null);

  const transformEmptyNumber = (value, originalValue) => {
    if (isNaN(value) && originalValue === "") {
      return 0;
    }

    return value;
  };

  // define schema for form validation
  const IncrementalityMonitoringValidationSchema = yup
    .object({
      baseAmount: yup
        .number()
        .nullable()
        .transform(transformEmptyNumber)
        .typeError("Base amount is not a valid number"),
      amendmentMade: yup.boolean(),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    baseAmount: "",
    amendmentMade: false,
  };

  const { control, handleSubmit, reset, formState, getValues } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(IncrementalityMonitoringValidationSchema),
  });

  const { isValid, isSubmitting, isDirty } = formState;

  const { data, isFetching } = useIncrementalityMonitoringModal(municipalityId);

  const putData = useWrapApi(
    incrementalityMonitoringService.putMunicipalityData,
    `Saved information for ${data?.municipalityName}`
  );

  // Reset the form with fetched incrementality monitoring details (as default values)
  useEffect(() => {
    reset({ ...data, baseAmount: data?.baseAmount?.toString() ?? "" });
  }, [data]);

  const saveData = async ({ baseAmount, amendmentMade }) => {
    const payload = { municipalityId, baseAmount, amendmentMade };
    const response = await putData.call(municipalityId, payload);

    if (!mounted.current) {
      return;
    }

    if (!response.error) {
      onClose(true);
    }
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  const modalButtons = [
    {
      key: "incrementalityModalSave",
      label: "Save",
      color: "primary",
      variant: "contained",
      disabled: !isDirty || isSubmitting || !isValid || isFetching,
      onClick: handleSubmit(saveData),
    },
    {
      key: "incrementalityModalClose",
      label: "Close",
      color: "primary",
      variant: "outlined",
      onClick: closeModal,
    },
  ];

  const calculateAnnualTarget = () => {
    const baseAmount = getValues("baseAmount");
    if (baseAmount && !isNaN(baseAmount)) {
      setAnnualTarget(baseAmount / 5);
    } else {
      setAnnualTarget(0);
    }
  };

  const renderModalRow = (
    label,
    value,
    isMoney = true,
    isComponent = false
  ) => {
    const transformedValue = isMoney ? formatToCurrency(value, true) : value;

    const rowValueComponent = isComponent ? (
      value
    ) : (
      <Typography variant="body1">{transformedValue}</Typography>
    );

    return (
      <Grid
        container
        direction="row"
        spacing={0}
        wrap="nowrap"
        alignItems="center"
        className={classes.rowContainer}
      >
        <Grid item xs={5}>
          <Typography variant="body2" className={classes.rowLabel}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs>
          {isFetching ? (
            <Skeleton width="8rem" height="1rem" variant="rect" />
          ) : (
            rowValueComponent
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <AmoModal
      open={open}
      title="Municipal Incrementality Info"
      showButton
      width="55rem"
      buttonsLeftSpacer={5}
      buttons={modalButtons}
      closePopover={closeModal}
    >
      <Grid
        container
        direction="column"
        spacing={0}
        wrap="nowrap"
        className={classes.container}
      >
        <Grid item>
          <Typography variant="body2">Municipality</Typography>
        </Grid>
        <Grid item>
          {isFetching ? (
            <Skeleton width="20rem" height="1rem" variant="rect" />
          ) : (
            <Typography variant="body1">{data?.municipalityName}</Typography>
          )}
        </Grid>

        <Grid item className={classes.dividerContainer}>
          <Divider />
        </Grid>

        <Grid item>
          {renderModalRow(
            "Base Amount",
            <AmoTextField
              control={control}
              id="incrementality-monitoring-base-amount"
              testId="incrementalityMonitoringBaseAmount"
              className={classes.inputField}
              name="baseAmount"
              variant="standard"
              pattern={/[^0-9.]+/g}
              InputProps={{
                inputComponent: NumberFormatCustom,
                onBlur: () => calculateAnnualTarget(),
              }}
            />,
            false,
            true
          )}
        </Grid>
        <Grid item>
          {renderModalRow(
            "Annual Target",
            annualTarget ?? data?.annualTarget ?? 0
          )}
        </Grid>
        <Grid item>
          {renderModalRow(
            "Average Annual Investment",
            data?.averageAnnualInvestment ?? 0
          )}
        </Grid>
        <Grid item>
          {renderModalRow(
            "Period of Average Annual Investment",
            `${data?.periodStart ?? ""}-${data?.periodEnd ?? ""}`,
            false
          )}
        </Grid>
        <Grid item>{renderModalRow("Status", data?.status, false)}</Grid>
        <Grid item>
          {renderModalRow(
            "Amendment Made",
            <AmoSwitch
              control={control}
              id="incrementality-monitoring-amendment-made"
              testId="incrementalityMonitoringAmendmentMade"
              name="amendmentMade"
              color="primary"
            />,
            false,
            true
          )}
        </Grid>
      </Grid>
    </AmoModal>
  );
};

// set the prop-types for this component
IncrementalityMonitoringModal.propTypes = {
  municipalityId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

// set prop-types defaults for this component
IncrementalityMonitoringModal.defaultProps = {
  open: false,
};

export default IncrementalityMonitoringModal;
