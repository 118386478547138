import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { getSortingComparator, stableSort } from "utils/data";
import colors from "constants/colors";
import clsx from "clsx";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import AmoTable from "components/table/AmoTable";
import { insuranceService } from "api/services/insuranceService";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { useYearContext } from "contexts/YearContext";
import InsuranceModal from "./InsuranceModal";
import { useUserContext } from "contexts/UserContext";
import { roleGroups } from "constants/user";
import { useInsuranceList } from "./insuranceReportHooks";
import { useAnnualReportNavBar } from "components/annualReport/annualReportNavBarHooks";
import { useIsMounted } from "hooks/useIsMounted";
import { useGlobalStyles } from "hooks/globalStylesHook";

const useStyles = makeStyles((theme) => ({
  textLink: {
    cursor: "pointer",
    color: colors.green.main,
    textDecoration: "none",
    fontSize: "0.875rem",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  validStatus: {
    color: "#00B628",
  },
  incompleteStatus: {
    color: "black",
  },
}));

const InsuranceList = () => {
  const classes = useStyles();
  const { hasRoles } = useUserContext();
  const { contextYear } = useYearContext();
  const { selectedMunicipalityId } = useMunicipalContext();
  const { showSnackbar } = useSnackbar();
  const globalClasses = useGlobalStyles();

  const mounted = useIsMounted();

  const [modalState, handleModalState] = useState(false);
  const [insuranceData, setInsuranceData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("year");
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});

  const hasFinancialsPermission = hasRoles(roleGroups.municipal.financials);

  const { refetch } = useAnnualReportNavBar(selectedMunicipalityId);

  const {
    data: insuranceList,
    refetch: refetchInsurance,
    isFetching,
  } = useInsuranceList(selectedMunicipalityId);

  const tableColumns = [
    {
      fieldKey: "year",
      label: "Year",
      minWidth: "5.8rem",
    },
    {
      fieldKey: "received",
      label: "Received",
      minWidth: "8.2rem",
    },
    { fieldKey: "coverageStart", label: "Coverage Start", minWidth: "11.1rem" },
    { fieldKey: "coverageEnd", label: "Coverage End", minWidth: "11.1rem" },
    {
      fieldKey: "status",
      label: "Status",
      minWidth: "8.7rem",
      customRender: ({ status }) => (
        <Typography
          variant="body3"
          className={
            status !== "Not submitted"
              ? classes.validStatus
              : classes.incompleteStatus
          }
        >
          {status}
        </Typography>
      ),
      isCustom: true,
    },
    { fieldKey: "notes", label: "Notes", minWidth: "15rem" },
    {
      fieldKey: "actions",
      minWidth: "5rem",
      maxWidth: "8rem",
      customRender: ({ id, status }) => (
        <Button
          onClick={() => openModal(id)}
          ref={(element) => {
            rowRefs.current[id] = element;
          }}
          onFocus={() => setFocusedItem({ id })}
          className={clsx(classes.textLink, globalClasses.tableActionCell)}
          disableRipple
        >
          {getActionType(status)}
        </Button>
      ),
      isCustom: true,
    },
  ];

  const openModal = (id) => {
    setSelectedId(id);
    handleModalState(true);
    setFocusedItem(null);
  };

  const closeModal = () => {
    setSelectedId(null);
    // refresh the data here, just in case the user has deleted a file and used the close button
    refetchInsurance();
    refetch();
    handleModalState(false);
    setFocusedItem({});
  };

  const handleSaveForm = async (payload) => {
    try {
      const { id, ...postPayload } = payload;
      if (id < 0) {
        await insuranceService.post(selectedMunicipalityId, {
          year: contextYear,
          ...postPayload,
        });
      } else {
        await insuranceService.put(selectedMunicipalityId, payload);
      }

      if (!mounted.current) {
        return;
      }

      // refetch the changed data
      refetchInsurance();
      refetch();
      showSnackbar("Insurance saved", snackbarTypes.success);
    } catch (err) {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const handleRequestSort = (fieldKey, newOrder) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);
  };

  const getActionType = (status) => {
    if (status === "Approved" || !hasFinancialsPermission) {
      return "View";
    }
    if (status === "Not submitted") {
      return "Upload";
    }
    if (status === "Submitted") {
      return "Edit";
    }
    if (status === "Approved") {
      return "View";
    }
    if (status === "Not Approved") {
      return "Edit";
    }
    return null;
  };

  useEffect(() => {
    if (insuranceList?.length) {
      // If the data is loaded, sort the forms
      const sortedResult = stableSort(
        insuranceList,
        getSortingComparator(
          order,
          orderBy === "statusComponent" ? "status" : orderBy
        )
      );

      setInsuranceData(sortedResult);
    }
  }, [insuranceList, order, orderBy]);

  return (
    <>
      <InsuranceModal
        open={modalState}
        insuranceData={insuranceList}
        id={selectedId}
        municipalityId={selectedMunicipalityId}
        closeFunction={closeModal}
        saveFunction={handleSaveForm}
        contextYear={contextYear}
        readonly={!hasFinancialsPermission}
      />
      <AmoTable
        id="insurance-table"
        testId="insuranceTable"
        items={insuranceData}
        columns={tableColumns}
        defaultOrderBy="year"
        defaultOrder="desc"
        onRequestSort={handleRequestSort}
        shouldUseQueryString
        disableFilters
        onScroll={(item) => {
          rowRefs.current?.[item.id]?.focus();
        }}
        focusedItem={focusedItem}
        isLoading={isFetching}
      />
    </>
  );
};

export default InsuranceList;
