/**
 * Function to extract the value from an object to use for comparison
 *
 * @param {object} item - object that is being compared
 * @param {string} field - field in object that should be used for comparing
 * @param {boolean} isCaseSensitive  - true if strings should take into account case, ex: "A" < "Z" < "a" < "z"
 * @param {boolean} isMoney - true if the null value should be considered 0
 * @returns {*} value to compare, null if no value
 */
const getValueToCompare = (item, field, isCaseSensitive, isMoney) => {
  let value = null;
  if (
    (item && item[field]) ||
    typeof item[field] === "boolean" ||
    typeof item[field] === "number"
  ) {
    value = item[field];
  }
  if (!isCaseSensitive && typeof value === "string") {
    value = value.toLowerCase();
  }
  return isMoney ? value ?? 0 : value;
};

/**
 * Function to get a sorting comparator for Array.protoype.sort()
 *
 * @param {string} order - order to sort, "desc" if descending, ascending otherwise
 * @param {string} field - field in objects to sort by
 * @param {boolean} isCaseSensitive - true if strings should take into account case, ex: "A" < "Z" < "a" < "z"
 * @param {boolean} isMoney - true if the null value should be considered 0
 * @returns {Function} a comparator function
 */
export const getSortingComparator = (
  order,
  field,
  isCaseSensitive = false,
  isMoney = false
) => (item1, item2) => {
  const value1 = getValueToCompare(item1, field, isCaseSensitive, isMoney);
  const value2 = getValueToCompare(item2, field, isCaseSensitive, isMoney);
  // If values are the same, keep same order
  if (value1 === value2) return 0;
  // If item1 has no value, make it last
  if (value1 === null) return 1;
  // If item2 has no value, make it last
  if (value2 === null) return -1;
  // If descending order, make the higher value first
  if (order === "desc") return value1 > value2 ? -1 : 1;
  // Otherwise (ascending), make the lower value first
  return value1 < value2 ? -1 : 1;
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((item1, item2) => {
    const order = comparator(item1[0], item2[0]);
    if (order !== 0) return order;
    return item1[1] - item2[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};

// Filtering
export const filterData = (data, filters, customFilterMatch = {}) =>
  data?.filter((item) => {
    for (const keys of Object.entries(filters)) {
      const key = keys[0];
      const itemValue = item[key]?.toString()?.toLowerCase();
      let filterValue = filters[key];
      if (typeof filterValue === "string") {
        filterValue = filterValue?.toLowerCase();
      }
      if (!filterValue) return true;
      const defaultFilterMatch = (obj, filter) => obj && obj.includes(filter);
      const filterMatch = customFilterMatch[key] || defaultFilterMatch;
      if (!filterMatch(itemValue, filterValue)) {
        return false;
      }
    }

    return true;
  });
