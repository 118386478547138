import { useQuery } from "react-query";
import { reviewService } from "api/services/reviewsService";
import reviewTypes from "constants/reviewTypes";
import { reviewLogDataInputKeys } from "./reviewLogDataInputKeys";
import { communicationsService } from "api/services/communicationService";

/**
 * Store server's state of review log lookups
 *
 * @param {string} type The type of the lookup being fetched
 * @param {string} objectId The id of the lookup object being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useReviewLogLookups = (type, objectId) => {
  const key = reviewLogDataInputKeys.lookups(type, objectId);
  let asyncFunc;

  if (type === reviewTypes.communications) {
    asyncFunc = () => communicationsService.getStatuses(objectId);
  } else {
    asyncFunc = () => reviewService.getDataInputLookups(objectId);
  }
  return useQuery(key, asyncFunc);
};
