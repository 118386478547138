import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  assetManagementQuestionnaireStatus: string,
 *  closeYearStatus: string,
 *  completedProjects: number,
 *  financialsLastUpdated: string,
 *  insuranceStatus: string,
 *  riskManagementQuestionnaireStatus: string,
 *  totalProjects: number }} reportSummary
 */

const api = new Api("api/municipalities");
const reportSummaryPath = "report/summary";
export const annualReportNavBarService = {
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<reportSummary>>}
   */
  getById: (id) => api.getById(`${id}/${reportSummaryPath}`),
};
