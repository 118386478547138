import * as React from "react";
import {
  MenuItem,
  Button,
  Grid,
  IconButton,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useSnackbar } from "contexts/SnackbarContext";
import * as yup from "yup";
import AmoTextField from "components/inputs/AmoTextField";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import { questionnaireTypes } from "constants/questionnaireTypes";
import { snackbarTypes } from "constants/snackbar";
import { useEffect } from "react";
import colors from "constants/colors";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";
import { ReactComponent as DownloadIcon } from "img/icons/download_circle_thin_icon.svg";

const useStyles = makeStyles((theme) => ({
  lightDivider: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
  },
  outlinedIconButtonDisabled: {
    border: `0.063rem solid rgba(0, 0, 0, 0.12)`,
    color: "#939393",
    backgroundColor: "white !important",
  },
  button: {
    minHeight: "3.125rem",
    minWidth: "5.313rem",
    fontSize: "1rem",
    borderWidth: "0.125rem",
    "&:hover": {
      borderWidth: "0.125rem",
    },
  },
  loadingProgress: {
    color: colors.grey.light,
  },
  dropdown: {
    // 208px
    maxWidth: "13rem",
    "& .MuiSelect-root": {
      // 160px
      minWidth: "10rem",
    },
  },
  iconButton: {
    width: "3rem",
    height: "3rem",
    margin: "0 0",
    padding: "0 0",
  },
  icon: {
    width: "3rem",
    height: "3rem",
  },
}));

/**
 * A bar component with filters and actions for municipal summary pages
 *
 * @param {object} props
 * @param {number[]} props.years Array of years to display in dropdowns
 * @param {boolean} props.showQuestionnaireType Sets visibility of the Questionnaire Type dropdown
 * @param {Function} props.onFilter Function that fires on Filter button click
 * @param {Function} props.onPrint Function that fires on Print button click
 * @param {boolean} props.showPrint Sets visibility of the Print button
 * @param {Function} props.onDownload Function that fires on Download button click
 * @param {boolean} props.showDownload Sets visibility of the Download button
 * @param {Function} props.searchButton Search and clear button component
 * @param {Function} props.showSearch Sets visibility of the Search button
 * @param {boolean} props.disableOptionalButtons Sets visibility of the Search button
 * @param {number} props.noDatesProjectCount Number of projects with no dates
 * @param {boolean} props.hideDividers If true, will hide the bar dividers
 * @returns {React.Component} The filters and actions bar component
 */
const FilterActionsBar = ({
  years,
  showQuestionnaireType,
  onFilter,
  onPrint,
  showPrint,
  showPrintLoading,
  onDownload,
  showDownload,
  showDownloadLoading,
  searchButton,
  showSearch,
  disableOptionalButtons,
  noDatesProjectCount,
  hideDividers,
}) => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [dropdownQuery, setDropdownQuery] = useConditionalQueryParams(
    "dropdown",
    "object",
    {
      period: undefined,
      from: undefined,
      to: undefined,
      questionnaireType: showQuestionnaireType
        ? questionnaireTypes.assetMgmt
        : null,
      includeProjectsWithNoDates: null,
    },
    true
  );

  const FiltersSchema = yup.object({
    period: yup.mixed().nullable(),
    from: yup.number().nullable(),
    to: yup.number().nullable(),
    questionnaireType: yup.string(),
    includeProjectsWithNoDates: yup.boolean().nullable(),
  });

  // Static reporting periods
  const periods = {
    custom: "Custom",
  };

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: dropdownQuery,
    mode: "onChange",
    resolver: yupResolver(FiltersSchema),
  });

  useEffect(() => {
    if (years?.length) {
      reset({
        period:
          parseInt(dropdownQuery.period, 10) ||
          dropdownQuery.period || // if not a number, checking if it's a string
          years[years.length - 1],
        from: parseInt(dropdownQuery.from, 10) || years[0],
        to: parseInt(dropdownQuery.to, 10) || years[0],
        questionnaireType:
          dropdownQuery.questionnaireType || questionnaireTypes.assetMgmt,
        includeProjectsWithNoDates: null,
      });
    }
  }, [years]);

  const reportingPeriod = watch("period");

  const handleFiltersChange = ({
    period,
    from,
    to,
    questionnaireType,
    includeProjectsWithNoDates,
  }) => {
    if (period === periods.custom && ![from, to].includes(0) && from > to) {
      showSnackbar(
        "Start year can't be higher than the end year",
        snackbarTypes.error
      );
    } else {
      onFilter({
        from: period !== periods.custom ? period : from,
        to: period !== periods.custom ? period : to,
        ...(showQuestionnaireType ? { questionnaireType } : {}),
        ...(noDatesProjectCount ? { includeProjectsWithNoDates } : {}),
      });
    }
  };

  return (
    <Grid item container direction="column" spacing={2} wrap="nowrap">
      {!hideDividers && <Divider className={classes.lightDivider} />}
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              {years && [...years, periods.custom].includes(reportingPeriod) && (
                <AmoTextField
                  control={control}
                  id="period"
                  name="period"
                  testId="period"
                  fullWidth
                  select
                  label="Reporting Period"
                  variant="outlined"
                  onChangeTrigger={(value) =>
                    setDropdownQuery({ ...dropdownQuery, period: value })
                  }
                  className={classes.dropdown}
                >
                  {years.map((year) => (
                    <MenuItem key={`period-${year}`} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                  <MenuItem value={periods.custom}>{periods.custom}</MenuItem>
                </AmoTextField>
              )}
            </Grid>
            {reportingPeriod === periods.custom && (
              <>
                <Grid item>
                  <AmoTextField
                    control={control}
                    id="from"
                    name="from"
                    testId="from"
                    fullWidth
                    select
                    label="Start Year"
                    placeholder="Select a year"
                    selectPlaceholderValue={0}
                    variant="outlined"
                    onChangeTrigger={(value) =>
                      setDropdownQuery({ ...dropdownQuery, from: value })
                    }
                    className={classes.dropdown}
                  >
                    {years &&
                      years.map((year) => (
                        <MenuItem key={`from-${year}`} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                  </AmoTextField>
                </Grid>
                <Grid item>
                  <AmoTextField
                    control={control}
                    id="to"
                    name="to"
                    testId="to"
                    fullWidth
                    select
                    label="End Year"
                    placeholder="Select a year"
                    selectPlaceholderValue={0}
                    variant="outlined"
                    onChangeTrigger={(value) =>
                      setDropdownQuery({ ...dropdownQuery, to: value })
                    }
                    className={classes.dropdown}
                  >
                    {years &&
                      years.map((year) => (
                        <MenuItem key={`to-${year}`} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                  </AmoTextField>
                </Grid>
              </>
            )}
            {!!noDatesProjectCount && (
              <>
                <Grid item>
                  <AmoCheckbox
                    control={control}
                    id="filter-project-dates-checkbox"
                    name="includeProjectsWithNoDates"
                    testId="filterProjectDatesCheckbox"
                    color="primary"
                    label={`Include ${noDatesProjectCount} projects without a defined start or end of financing date`}
                  />
                </Grid>
              </>
            )}
            {showQuestionnaireType && (
              <Grid item>
                <AmoTextField
                  control={control}
                  id="questionnaireType"
                  name="questionnaireType"
                  testId="questionnaireType"
                  fullWidth
                  select
                  label="Questionnaire"
                  variant="outlined"
                  onChangeTrigger={(value) =>
                    setDropdownQuery({
                      ...dropdownQuery,
                      questionnaireType: value,
                    })
                  }
                >
                  {Object.values(questionnaireTypes).map((value) => (
                    <MenuItem key={`questionnaire-type-${value}`} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </AmoTextField>
              </Grid>
            )}
            <Grid item>
              <Button
                className={classes.button}
                data-testid="filterButton"
                color="primary"
                variant="contained"
                onClick={handleSubmit((formValues) =>
                  handleFiltersChange(formValues)
                )}
              >
                Go
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            {showSearch && <Grid item>{searchButton}</Grid>}
            {showPrint && (
              <Grid item>
                <Button
                  className={classes.button}
                  data-testid="printButton"
                  color="primary"
                  variant="outlined"
                  onClick={onPrint}
                  disabled={disableOptionalButtons}
                >
                  {showPrintLoading ? (
                    <CircularProgress
                      className={classes.loadingProgress}
                      size={28}
                    />
                  ) : (
                    "Print"
                  )}
                </Button>
              </Grid>
            )}
            {showDownload && (
              <Grid item>
                <IconButton
                  color="primary"
                  data-testid="downloadButton"
                  className={classes.iconButton}
                  onClick={onDownload}
                  disabled={disableOptionalButtons}
                >
                  {showDownloadLoading ? (
                    <CircularProgress
                      className={classes.loadingProgress}
                      size={28}
                    />
                  ) : (
                    <DownloadIcon
                      className={classes.icon}
                      style={{
                        stroke: !disableOptionalButtons
                          ? colors.green.main
                          : colors.grey.main,
                      }}
                      fill={
                        !disableOptionalButtons
                          ? colors.green.main
                          : colors.grey.main
                      }
                    />
                  )}
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!hideDividers && <Divider className={classes.lightDivider} />}
    </Grid>
  );
};

FilterActionsBar.propTypes = {
  years: PropTypes.arrayOf(PropTypes.number),
  showQuestionnaireType: PropTypes.bool,
  onFilter: PropTypes.func,
  onPrint: PropTypes.func,
  showPrint: PropTypes.bool,
  showPrintLoading: PropTypes.bool,
  onDownload: PropTypes.func,
  showDownload: PropTypes.bool,
  showDownloadLoading: PropTypes.bool,
  searchButton: PropTypes.node,
  showSearch: PropTypes.bool,
  disableOptionalButtons: PropTypes.bool,
  noDatesProjectCount: PropTypes.number,
  hideDividers: PropTypes.bool,
};

FilterActionsBar.defaultProps = {
  years: undefined,
  showQuestionnaireType: false,
  onFilter: () => {},
  onPrint: () => {},
  showPrint: true,
  showPrintLoading: false,
  onDownload: () => {},
  showDownload: true,
  showDownloadLoading: false,
  searchButton: <></>,
  showSearch: false,
  disableOptionalButtons: false,
  noDatesProjectCount: null,
  hideDividers: false,
};

export default FilterActionsBar;
