import React, { useRef, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography, IconButton, Grid } from "@material-ui/core";
import colors from "constants/colors";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paginationButton: {
    color: colors.black,
    fontSize: "1rem",
    width: "2.5rem",
    height: "2.5rem",
  },
  activePageButton: {
    backgroundColor: "#EBEBEB",
  },
  activePageNumber: {
    fontWeight: "bold",
  },
  arrowButton: {
    color: colors.green.main,
    width: "1rem",
    height: "1rem",
  },
  disabledButton: {
    color: colors.grey.medium,
  },
}));

/**
 * A table pagination component to navigate between the table pages
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.tableId - id of the table component
 * @param {number} props.itemsLength - length of the items array
 * @param {number} props.itemsPerPage - how many items to display per page
 * @param {boolean} props.shouldUseQueryString - if true, AmoTable will set set and use the query string param for pagination and filtering
 * @param {Function} props.onPageChange - function called when user changes the page (params: new page)
 *
 * @returns {React.Component} - The table pagination component
 */
const AmoTablePagination = (props) => {
  const {
    itemsLength,
    tableId,
    itemsPerPage,
    shouldUseQueryString,
    onPageChange,
  } = props;

  const classes = useStyles();
  const pageRef = useRef(null);
  const { search } = useLocation();

  const scrollToPaginationTool = useRef(false);

  const queryParamName = tableId ? `page_${tableId}` : "page";
  const [page, setPage] = useConditionalQueryParams(
    queryParamName,
    "number",
    1,
    shouldUseQueryString,
    (newPage, callbackData) => {
      const { isActiveChange } = callbackData ?? {};
      if (isActiveChange) {
        scrollToPaginationTool.current = true;
      }
      onPageChange(newPage);
    }
  );
  const startingIndex = Math.max(0, (page - 1) * itemsPerPage);

  useEffect(() => {
    // If current page is out of bounds, reset to 1
    if (
      (itemsLength > 0 && startingIndex > itemsLength - 1) ||
      (!search && !search.includes(`${queryParamName}=1`))
    ) {
      setPage(1);
    }
  }, [page, itemsLength]);

  useLayoutEffect(() => {
    // Added this condition because the unit tests doesn't seem to support the scrollIntoView function
    if (pageRef.current?.scrollIntoView && scrollToPaginationTool.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      scrollToPaginationTool.current = false;
    }
  }, [scrollToPaginationTool.current]);

  const changePage = (newPage, maxPage) => {
    let targetPage = Math.max(newPage, 1);
    targetPage = Math.min(newPage, maxPage);
    setPage(targetPage, { isActiveChange: true });
  };

  // TODO: maybe use memo for these variables
  const maxPage = Math.ceil(itemsLength / itemsPerPage);

  const firstPage = Math.max(page - 2, 1);
  const lastPage = Math.min(page + 2, maxPage);
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  let pageNumbersArray = [];

  if (page < 5) {
    pageNumbersArray = range(1, Math.min(5, maxPage), 1);
  } else if (page < maxPage - 3) {
    pageNumbersArray = range(firstPage, lastPage, 1);
  } else {
    pageNumbersArray = range(Math.max(maxPage - 4, 1), maxPage, 1);
  }

  return (
    maxPage > 1 && (
      <Grid
        id={`${tableId ?? "amo-table"}-pagination`}
        item
        container
        alignItems="center"
        justifyContent="center"
        ref={pageRef}
      >
        <Grid item key="paginationButton-back">
          <IconButton
            className={classes.arrowButton}
            onClick={() => changePage(page - 1, maxPage)}
            disabled={page <= 1}
          >
            <ArrowBackIos
              className={clsx(
                classes.arrowButton,
                page <= 1 ? classes.disabledButton : null
              )}
              data-testid="arrow-back-ios"
            />
          </IconButton>
        </Grid>
        {!pageNumbersArray.includes(1) && (
          <Grid item key="paginationButton-1">
            <IconButton
              className={clsx(
                classes.paginationButton,
                page === 1 ? classes.activePageButton : null
              )}
              onClick={() => changePage(1, maxPage)}
            >
              <Typography
                data-testid="first-page-button"
                className={page === 1 ? classes.activePageNumber : null}
              >
                1
              </Typography>
            </IconButton>
            ...
          </Grid>
        )}
        {pageNumbersArray.map((item) => (
          <Grid item key={`paginationButton-${item}`}>
            <IconButton
              className={clsx(
                classes.paginationButton,
                item === page ? classes.activePageButton : null
              )}
              onClick={() => changePage(item, maxPage)}
            >
              <Typography
                data-testid={`${item}-page-button`}
                className={page === item ? classes.activePageNumber : null}
              >
                {item}
              </Typography>
            </IconButton>
          </Grid>
        ))}
        {!pageNumbersArray.includes(maxPage) && (
          <Grid item key={`paginationButton-${maxPage}`}>
            <>...</>
            <IconButton
              className={clsx(
                classes.paginationButton,
                page === maxPage ? classes.activePageButton : null
              )}
              onClick={() => changePage(maxPage, maxPage)}
            >
              <Typography
                data-testid="max-page-button"
                className={page === maxPage ? classes.activePageNumber : null}
              >
                {maxPage}
              </Typography>
            </IconButton>
          </Grid>
        )}
        <Grid item key="paginationButton-forward">
          <IconButton
            className={classes.arrowButton}
            onClick={() => changePage(page + 1, maxPage)}
            disabled={page >= maxPage}
          >
            <ArrowForwardIos
              className={clsx(
                classes.arrowButton,
                page >= maxPage ? classes.disabledButton : null
              )}
              data-testid="arrow-forward-ios"
            />
          </IconButton>
        </Grid>
      </Grid>
    )
  );
};

// set the prop-types for this component
AmoTablePagination.propTypes = {
  tableId: PropTypes.string,
  itemsLength: PropTypes.number,
  itemsPerPage: PropTypes.number,
  shouldUseQueryString: PropTypes.bool,
  onPageChange: PropTypes.func,
};

AmoTablePagination.defaultProps = {
  tableId: undefined,
  itemsLength: 0,
  itemsPerPage: 10,
  shouldUseQueryString: false,
  onPageChange: () => {},
};

export default AmoTablePagination;
