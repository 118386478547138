import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Button, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  steps,
  formStatuses,
  localRoadsCategoryId,
  localRoadsSubcategoryId,
} from "constants/projectReport";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import clsx from "clsx";
import {
  formIds,
  projectReportResultsCodes as formCodes,
} from "constants/formContentManagement";
import { useSnackbar } from "contexts/SnackbarContext";
import colors from "constants/colors";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import placeholders from "functions/placeholders";
import { projectReportingResultsService } from "api/services/projectReportingResultsService";
import { indicatorService } from "api/services/indicatorService";
import ProjectOutputs from "components/municipal/ProjectOutputs";
import ProjectOutcomes from "components/municipal/ProjectOutcomes";
import AmoFormActions from "components/AmoFormActions";
import AdditionalCategoriesModal from "components/municipal/AdditionalCategoriesModal";
import * as yup from "yup";
import Skeleton from "@material-ui/lab/Skeleton";
import { useUserContext } from "contexts/UserContext";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { roles, roleGroups } from "constants/user";
import { useWrapApi } from "hooks/wrapApiHook";
import { templateService } from "api/services/templateService";
import XlsxPopulate from "xlsx-populate";
import { downloadWorkbook } from "utils/xlsxPopulateUtils";
import {
  percentageCalculationTypes,
  getValidation,
  unitCategoryTypes,
  lengthUnitTypes,
} from "constants/indicatorConstants";
import { fileTypes } from "constants/fileTypes";
import { calculateRawChange } from "utils/changeCalculations";
import { useProjectFunds } from "./projectReportFinancialsHooks";
import { useNavigationHelper } from "../../../hooks/navigationHook";
import {
  deleteButtonTooltipText,
  deleteType,
} from "../../../functions/projectDeleteMunicipalUtils";
import DeletionType from "../../../enums/deletionType";
import DeletionModal from "../../../components/DeletionModal";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  scrollableContent: {
    overflow: "hidden auto",
  },
  body: {
    flexGrow: 1,
    flexBasis: 0,
    padding: "0rem 5rem 0rem 5rem",
  },
  contentPadding: {
    padding: "0rem 2.5rem 0rem 2.5rem",
  },
  headerStyles: {
    marginTop: "1.5rem",
  },
  bodyStyles: {
    marginTop: "1rem",
  },
  fullWidth: {
    width: "100%",
  },
  addAdditionalCategoryButton: {
    width: "auto",
    marginTop: "1rem",
  },
  formActions: {
    // 16px
    marginTop: "1rem",
  },
  saveButton: {
    position: "relative",
    height: "3.5rem",
    width: "25%",
  },
  warningText: {
    // 16px
    fontSize: "1rem",
    color: colors.red.warning,
  },
  indicatorHeader: {
    fontSize: "1.5rem",
  },
}));

/**
 * A form component for editing Project Report Results
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.projectId - sets the project id used to load the project data, pass 'new' for new project [required]
 * @param {number} props.activeStep - sets the active step of the project report [required]
 * @param {number} props.categoryFormStatus - sets the Category form status (values: @see formStatuses )
 * @param {object} props.categoryData - sets the category data of the project report
 * @param {boolean} props.isConstructionEndInReportingYear - set a boolean to disable controls if the end of construction is not in the current reporting year.
 * @param {boolean} props.isConstructionEndBeforeReportingYear - set a boolean to disable controls if the end of construction is not in the current reporting year.
 * @param {Function} props.onFormStatusChange - function called when the form status changes (params: { @see formStatuses })
 * @param {boolean} props.isAnnualReportTab - Adjusts the styling of the form slightly depending on whether it's being rendered as an annual report tab
 * @param {object} props.projectInfo - information of the project
 * @param {Function} props.onUnsavedChanges - function called when project unsaved data changes (params: boolean, function)
 * @param {boolean} props.projectDeletionRequested - boolean to handle disabling deletion button if the request was already sent.
 * @param {Function} props.refetchValidationInfo - refetch Validation Info to update projectDeletionRequested value.
 *
 * @returns - The form component
 */
const ProjectReportResultsForm = (props) => {
  const {
    projectId,
    activeStep,
    categoryFormStatus,
    categoryData,
    isConstructionEndInReportingYear,
    isConstructionEndBeforeReportingYear,
    onFormStatusChange,
    projectInfo,
    isAnnualReportTab,
    onUnsavedChanges,
    projectDeletionRequested,
    refetchValidationInfo,
  } = props;
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { formFields, addPlaceholderValue } = useFormManagement(
    formIds.projectReportResults,
    true
  );
  const { hasRoles } = useUserContext();
  const isTreasurerOrDelegate = hasRoles(
    roleGroups.municipal.treasurerOrDelegate
  );
  const { getMunicipality } = useMunicipalContext();
  const { navigateBack } = useNavigationHelper();

  const hasProjectsPermission =
    hasRoles(roleGroups.municipal.projects) || hasRoles(roles.amo);

  const mounted = useIsMounted();

  const [outputData, setOutputData] = useState([]);
  const [outcomeData, setOutcomeData] = useState([]);
  const [outputYupSchema, setOutputYupSchema] = useState(null);
  const [outputDefaultValues, setOutputDefaultValues] = useState({});
  const [outcomeYupSchema, setOutcomeYupSchema] = useState(null);
  const [outcomeDefaultValues, setOutcomeDefaultValues] = useState({});
  const [isOutputValid, setIsOutputValid] = useState(true);
  const [isOutcomeValid, setIsOutcomeValid] = useState(true);
  const [isOutputDirty, setIsOutputDirty] = useState(false);
  const [isOutcomeDirty, setIsOutcomeDirty] = useState(false);
  const [outputPreviousForm, setOutputPreviousForm] = useState({});
  const [outcomePreviousForm, setOutcomePreviousForm] = useState({});
  const [unitData, setUnitData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);
  const [hasSavedData, setHasSavedData] = useState(false);
  const [dirtyOutputs, setDirtyOutputs] = useState({});
  const [dirtyOutcomes, setDirtyOutcomes] = useState({});
  const [secondaryInputs, setSecondaryInputs] = useState({});
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const [formStatus, setFormStatus] = useState(formStatuses.untouched);

  const inputFile = useRef(null);
  const getOutputFormValues = useRef(null);
  const getOutcomeFormValues = useRef(null);

  const getResultsTemplateFile = useWrapApi(
    templateService.getResultsTemplateFile
  );
  const insertResults = useWrapApi(
    projectReportingResultsService.saveBrandNewProjectResults,
    "Successfully saved the results"
  );
  const updateResults = useWrapApi(
    projectReportingResultsService.updateProjectResults,
    "Successfully saved the results"
  );

  const getResultValue = (type, value) => {
    switch (type) {
      case "Checkbox":
        if (value === "true") {
          return "Yes";
        }
        if (value === "false") {
          return "No";
        }
        return null;
      default:
        return value;
    }
  };

  const onDownloadResultsTemplate = async () => {
    const {
      data: { data: fileData },
    } = await getResultsTemplateFile.call();

    if (!mounted.current) {
      return;
    }

    const workbook = await XlsxPopulate.fromDataAsync(fileData);

    if (!mounted.current) {
      return;
    }

    const category = categories?.find(
      ({ id }) =>
        id === (categoryData?.subcategoryId || categoryData?.categoryId)
    );
    const categoryAbbreviation =
      category?.abbreviation?.replace(" ", "")?.replace("-", "") || "";

    const noOutputsLocator = `${categoryAbbreviation}_Output_None`;
    const commentsLocator = `${categoryAbbreviation}_Comments`;

    if (secondaryInputs.noOutputsApply) {
      workbook.definedName(noOutputsLocator)?.value("X");
    }
    workbook.definedName(commentsLocator)?.value(secondaryInputs.otherResults);

    const projectData = {
      ...projectInfo,
      category: category?.parentName
        ? `${category?.parentName} - ${category?.name}`
        : category?.name,
    };

    const projectDataDefinedNames = {
      id: "AMO_ID",
      internalId: "Municipal_ID",
      title: "Project_Title",
      category: "Project_Category",
    };

    Object.entries(projectDataDefinedNames).forEach(([key, name]) => {
      workbook.definedName(name).value(projectData[key]);
    });

    const outputs = outputData.reduce(
      (acc, output) => [
        ...acc,
        ...output?.indicators?.map((indicator) => ({
          value: indicator?.savedData?.outcomeValue,
          type: indicator?.indicatorType,
          beforeValue: indicator?.savedData?.beforeValue,
          afterValue: indicator?.savedData?.afterValue,
          cellLocator: indicator?.indicatorCellLocator,
          isBeforeAfter: indicator?.isBeforeAfter,
          standardValue: indicator?.savedData?.standardOutcomeValue,
          standardBeforeValue: indicator?.savedData?.standardBeforeValue,
          standardAfterValue: indicator?.savedData?.standardAfterValue,
        })),
      ],
      []
    );

    const indicators = [
      ...outcomeData.map((outcome) => ({
        value: outcome?.savedData?.outcomeValue,
        type: outcome?.indicatorType,
        beforeValue: outcome?.savedData?.beforeValue,
        afterValue: outcome?.savedData?.afterValue,
        cellLocator: outcome?.indicatorCellLocator,
        isBeforeAfter: outcome?.isBeforeAfter,
        standardValue: outcome?.savedData?.standardOutcomeValue,
        standardBeforeValue: outcome?.savedData?.standardBeforeValue,
        standardAfterValue: outcome?.savedData?.standardAfterValue,
      })),
      ...(secondaryInputs.noOutputsApply ? [] : outputs),
    ];

    indicators?.forEach(
      ({
        cellLocator,
        isBeforeAfter,
        type,
        standardValue,
        standardBeforeValue,
        standardAfterValue,
      }) => {
        try {
          if (isBeforeAfter) {
            workbook.definedName(`${cellLocator}_b`).value(standardBeforeValue);
            workbook.definedName(`${cellLocator}_a`).value(standardAfterValue);
          } else {
            workbook
              .definedName(cellLocator)
              .value(getResultValue(type, standardValue));
          }
        } catch {
          // console.warn(`Named cell not found: ${cellLocator}`);
        }
      }
    );

    const sheetsMap = {
      "Broadband Connectivity": "BC",
      "Brownfield Redevelopment": "BR",
      "Capacity-Building": "CB",
      "Community Energy Systems": "CE",
      Culture: "CU",
      "Disaster Mitigation": "DM",
      "Drinking Water": "DW",
      "Fire Stations": "FI",
      "Local Roads and Bridges - Active Transportation": "LR - A",
      "Local Roads and Bridges - Bridges": "LR - B",
      "Local Roads and Bridges - Culverts": "LR - C",
      "Local Roads and Bridges - Local Roads": "LR - R",
      "Local Roads and Bridges - Other": "LR - O",
      "Public Transit": "PT",
      "Regional and Local Airports": "RA",
      Recreation: "RE",
      "Short-line Rail": "SR",
      "Short-sea Shipping": "SS",
      Sports: "SP",
      "Solid Waste": "SW",
      Tourism: "TO",
      Wastewater: "WW",
    };

    const usedCategories = selectedCategoriesIds.map((categoryId) => {
      const cat = categories.find(({ id }) => id === categoryId);
      return cat?.parentName ? `${cat?.parentName} - ${cat?.name}` : cat?.name;
    });

    const categoriesToDelete = Object.keys(sheetsMap).filter(
      (cat) => !usedCategories.includes(cat)
    );

    categoriesToDelete?.forEach((cat) => {
      const sheetName = sheetsMap[cat];
      try {
        workbook.deleteSheet(sheetName);
      } catch {
        // console.warn(`Sheet not found: ${sheetName}`);
      }
    });

    await downloadWorkbook(workbook, "Project Results Template.xlsx");
  };

  const populateUploadPayload = (indicators, workbook, hasSavedDataRef) => {
    const result = [];

    for (let i = 0; i <= (indicators?.length ?? 0) - 1; i += 1) {
      const indicator = indicators[i];
      const savedId = indicator.savedData?.id;
      const indicatorLocator = indicator.indicatorCellLocator;
      const payload = {};

      if (!indicatorLocator) {
        continue;
      }

      payload.indicatorId = indicator.id;

      if (savedId) {
        payload.id = Number(savedId);
      }

      if (indicator.isBeforeAfter) {
        payload.beforeValue = workbook
          .definedName(`${indicatorLocator}_b`)
          ?.value()
          ?.toString();
        payload.afterValue = workbook
          .definedName(`${indicatorLocator}_a`)
          ?.value()
          ?.toString();
        payload.outcomeValue = workbook
          .definedName(indicatorLocator)
          ?.value()
          ?.toString();

        const { beforeValue, afterValue, outcomeValue } = payload;

        // Because the outcome value is filled with Excel formula
        // it might not be filled if the template was not opened
        // or used Office Online to fill. If that happens, calculate
        // the change here.
        if (!outcomeValue && beforeValue && afterValue) {
          payload.outcomeValue = calculateRawChange(
            indicator.calculationTypeCode,
            beforeValue,
            afterValue
          )?.toString();
        }
      } else {
        payload.outcomeValue = workbook
          .definedName(indicatorLocator)
          ?.value()
          ?.toString();

        if (indicator.indicatorType === "Checkbox") {
          if (payload.outcomeValue === "Yes") {
            payload.outcomeValue = "true";
          } else if (payload.outcomeValue === "No") {
            payload.outcomeValue = "false";
          } else {
            payload.outcomeValue = null;
          }
        }
      }

      payload.unitTypeId = indicator.standardIndicatorUnitTypeId;

      if ((hasSavedDataRef && savedId) || payload.outcomeValue) {
        result.push(payload);
      }
    }

    return result;
  };

  const onUploadResultsTemplate = async (event) => {
    event.preventDefault();
    const { files } = event.target;
    const file = files[0];
    const workbook = await XlsxPopulate.fromDataAsync(file);

    if (!mounted.current) {
      return;
    }

    const category = categories?.find(
      ({ id }) =>
        id === (categoryData?.subcategoryId || categoryData?.categoryId)
    );

    const categoryAbbreviation =
      category?.abbreviation?.replace(" ", "")?.replace("-", "") || "";

    const outputs = outputData.map((group) => group.indicators)?.flat(1);
    const noOutputsApply =
      workbook.definedName(`${categoryAbbreviation}_Output_None`)?.value() ===
      "X";

    const outputPayload = noOutputsApply
      ? []
      : populateUploadPayload(outputs, workbook, hasSavedData);
    const outcomePayload = populateUploadPayload(
      outcomeData,
      workbook,
      hasSavedData
    );

    const payload = {
      noOutputsApply,
      otherResults: workbook
        .definedName(`${categoryAbbreviation}_Comments`)
        ?.value(),
      outputs: outputPayload,
      outcomes: outcomePayload,
    };

    let response;
    if (hasSavedData) {
      response = await updateResults.call(projectId, payload);
    } else {
      response = await insertResults.call(projectId, payload);
    }

    if (!mounted.current) {
      return;
    }

    // clear file input value
    inputFile.current.value = "";

    if (response && !response.error) {
      // Update the project results data
      resetAndGetData(true);
    }
  };

  const transformEmptyNumber = (value, originalValue) => {
    if (isNaN(value) && originalValue === "") {
      return null;
    }

    return value;
  };

  // Query project funds for deleteion
  const { data: fundsResult } = useProjectFunds(projectId, false);
  const reportingYear = getMunicipality()?.reportingYear;
  const hasPriorExpenditures = fundsResult?.data?.funds.some(
    ({ year }) => year < reportingYear
  );

  // returns projectDeletionRequested in Deletion Requested or empty string to be in line with the same value from ProjectListPage.jsx
  const projectDeletionRequestedInString = projectDeletionRequested
    ? "Deletion Requested"
    : "";

  const getDeleteType = () => {
    const isRequestDeletion =
      deleteType(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ) === DeletionType.REQUEST;

    return {
      text: isRequestDeletion ? "Request Deletion" : "Delete Project",
      action: isRequestDeletion ? refetchValidationInfo : navigateBack,
    };
  };

  // creates the form schemas based on the different values given to it
  const createSchemas = (dataArray, schemaObject, defaultValueObject) => {
    const tempSchema = schemaObject;
    const tempSchemaExcludes = [];
    const tempDefaultValueObject = defaultValueObject;
    for (const val of dataArray) {
      const currentSavedData = val.savedData;

      if (!hasSavedData && currentSavedData.id !== null) {
        setHasSavedData(true);
      }

      const saveId = currentSavedData.id !== null ? currentSavedData.id : "new";
      const inputName = `indicator-${val.id}-${saveId}`;

      const cannotBeZero =
        val.isBeforeAfter &&
        percentageCalculationTypes.includes(val.calculationTypeCode);

      if (val.indicatorType === "Numeric") {
        const afterName = `${inputName}-after`;
        const unitName = `${inputName}-unit`;

        const beforeWhenOptions = {
          is: (value) => !!value,
          then: (schema) => schema.required("Before is required"),
        };

        tempSchema[inputName] = yup
          .number()
          .label("Value")
          .nullable(true)
          .when(afterName, beforeWhenOptions)
          .when(unitName, beforeWhenOptions)
          .transform((value) => (isNaN(value) ? null : value))
          .moreThan(cannotBeZero ? 0 : -1);

        tempDefaultValueObject[inputName] = "";

        if (
          val.indicatorUnitTypeId !== null &&
          val.indicatorUnitTypeId !== unitCategoryTypes.numeric
        ) {
          const unitWhenOptions = {
            is: (value) => !!value,
            then: (schema) => schema.required("Please select a unit"),
          };
          tempSchema[unitName] = yup
            .number()
            .label("Value")
            .nullable(true)
            .when(inputName, unitWhenOptions)
            .when(afterName, unitWhenOptions)
            .transform(transformEmptyNumber);
          tempDefaultValueObject[unitName] = "";
          tempSchemaExcludes.push([unitName, inputName]);
          if (
            val.categoryId === localRoadsCategoryId &&
            val.subcategoryId === localRoadsSubcategoryId &&
            val.indicatorUnitTypeId === unitCategoryTypes.length &&
            currentSavedData.unitTypeId !== lengthUnitTypes.laneKilometres
          ) {
            const multiplierName = `${inputName}-multiplier`;
            tempSchema[multiplierName] = yup
              .number()
              .label("Value")
              .nullable(true)
              .when(unitName, {
                is: (value) =>
                  !!value && value !== lengthUnitTypes.laneKilometres,
                then: (schema) =>
                  schema.required("Number of Lanes is required"),
              })
              .integer()
              .positive()
              .transform((value) => (isNaN(value) ? null : value));
            tempDefaultValueObject[multiplierName] = null;
          }
        }

        if (val.isBeforeAfter) {
          const calculationValidation = getValidation(val.calculationTypeCode);
          const afterWhenOptions = {
            is: (value) => !!value,
            then: (schema) => schema.required("After is required"),
          };

          tempSchema[afterName] = yup
            .number()
            .label("Value")
            .nullable(true)
            .when(inputName, afterWhenOptions)
            .when(unitName, afterWhenOptions)
            .transform((value) => (isNaN(value) ? null : value))
            .test(
              "calculation-validation",
              calculationValidation.errorMessage,
              (value, testContext) =>
                calculationValidation.validation(
                  testContext.parent[inputName], // is the before value
                  value // is the after value
                )
            );
          tempDefaultValueObject[afterName] = "";
          tempSchemaExcludes.push([afterName, inputName]);
          if (val.indicatorUnitTypeId !== null) {
            tempSchemaExcludes.push([afterName, unitName]);
          }

          const changeName = `${inputName}-change`;
          tempSchema[changeName] = yup.string().label("Value");
          tempDefaultValueObject[changeName] = "";
        }
      }
      if (val.indicatorType === "Checkbox") {
        tempSchema[inputName] = yup.boolean().label("Value");
        tempDefaultValueObject[inputName] = false;
      }
      if (val.indicatorType === "List") {
        tempSchema[inputName] = yup.string().label("Value");
        tempDefaultValueObject[inputName] = "";
      }
    }

    return {
      schema: tempSchema,
      schemaExcludes: tempSchemaExcludes,
      defaultValues: tempDefaultValueObject,
    };
  };

  // function that dynamically creates yup schemas and default values based on the indicator data being returned
  const setSchemaTypes = (dataArray, type) => {
    let schema = {};
    let schemaExcludes = [];
    let defaultValues = {};

    if (type === "outputs") {
      schema.noOutputsApply = yup.boolean();
      defaultValues.noOutputsApply = false;
      for (const data of dataArray) {
        const outputIndicatorData = data.indicators;
        const outputOrganizedData = createSchemas(
          outputIndicatorData,
          schema,
          defaultValues
        );

        schema = outputOrganizedData.schema;
        schemaExcludes = [
          ...schemaExcludes,
          ...outputOrganizedData.schemaExcludes,
        ];
        defaultValues = outputOrganizedData.defaultValues;
      }
    }

    if (type === "outcomes") {
      schema.otherResults = yup
        .string()
        .max(2000, "Must be 2,000 characters or less");
      defaultValues.otherResults = "";
      const outcomeIndicatorData = dataArray;
      const outcomeOrganizedData = createSchemas(
        outcomeIndicatorData,
        schema,
        defaultValues
      );

      schema = outcomeOrganizedData.schema;
      schemaExcludes = outcomeOrganizedData.schemaExcludes;
      defaultValues = outcomeOrganizedData.defaultValues;
    }

    const finalSchema = yup.object().shape(schema, schemaExcludes);

    return {
      schema: finalSchema,
      defaultValues,
    };
  };

  // parent function that calls the additional functions that generate the yup schema and default values
  const createYupSchemas = (dataObject) => {
    const outputIndicators = dataObject.outputs;
    const outcomeIndicators = dataObject.outcomes;

    const outputFormData = setSchemaTypes(outputIndicators, "outputs");
    const outcomeFormData = setSchemaTypes(outcomeIndicators, "outcomes");

    setOutputYupSchema(outputFormData.schema);
    setOutputDefaultValues(outputFormData.defaultValues);

    setOutcomeYupSchema(outcomeFormData.schema);
    setOutcomeDefaultValues(outcomeFormData.defaultValues);
  };

  // api call that gets all the indicator data for a selected project
  const getData = async () => {
    try {
      const {
        data,
      } = await projectReportingResultsService.getProjectResultIndicators(
        projectId
      );

      if (!mounted.current) {
        return;
      }

      createYupSchemas(data);
      setSecondaryInputs({
        noOutputsApply: data?.noOutputsApply ?? false,
        otherResults: data?.otherResults ?? "",
      });
      setOutputData(data?.outputs ?? []);
      setOutcomeData(data?.outcomes ?? []);

      handleFormStatusUpdate(
        getFormStatus(data?.outputs, data?.outcomes, data?.noOutputsApply)
      );
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  // function that resets and retrieves new data after an additional category has been added or after a save has occured
  const resetAndGetData = async (isSaving) => {
    setIsLoading(true);

    if (isSaving) {
      setOutputPreviousForm({});
      setOutcomePreviousForm({});
    } else {
      const outputForm = getOutputFormValues.current
        ? getOutputFormValues.current()
        : {};
      const outcomeForm = getOutcomeFormValues.current
        ? getOutcomeFormValues.current()
        : {};

      setOutputPreviousForm({ ...outputForm });
      setOutcomePreviousForm({ ...outcomeForm });
    }

    setOutputData({});
    setOutcomeData({});
    setOutputDefaultValues({});
    setOutcomeDefaultValues({});
    setOutputYupSchema({});
    setOutcomeYupSchema({});

    await getData();

    if (!mounted.current) {
      return;
    }

    setIsLoading(false);
  };

  const updateFormFieldPlaceholders = (
    categoriesRef,
    selectedCategoriesIdsRef
  ) => {
    if (
      !categoriesRef ||
      categoriesRef.length === 0 ||
      !selectedCategoriesIdsRef ||
      selectedCategoriesIdsRef.length === 0
    ) {
      return;
    }

    const selectedCategories = categoriesRef.filter((category) =>
      selectedCategoriesIdsRef.includes(category.id)
    );
    const placeholderValue = selectedCategories
      .map((category) =>
        category.parentName
          ? `${category.parentName} - ${category.name}`
          : category.name
      )
      .join(", ");
    addPlaceholderValue(placeholders.categories, placeholderValue);
  };

  const updateCategories = (newCategories) => {
    setCategories(newCategories);
    updateFormFieldPlaceholders(newCategories, selectedCategoriesIds);
  };

  const updateSelectedCategories = (newSelectedCategoriesIds) => {
    const mainCategoryId =
      categoryData.subcategoryId || categoryData.categoryId;
    let sortedSelectedCategoriesIds;
    if (mainCategoryId) {
      sortedSelectedCategoriesIds = newSelectedCategoriesIds.filter(
        (id) => id !== mainCategoryId
      );
      sortedSelectedCategoriesIds.unshift(mainCategoryId);
    }
    setSelectedCategoriesIds(
      sortedSelectedCategoriesIds ?? newSelectedCategoriesIds
    );
    updateFormFieldPlaceholders(
      categories,
      sortedSelectedCategoriesIds ?? newSelectedCategoriesIds
    );
  };

  // api call that gets unit type data needed for dropdowns
  const getIndicatorUnitTypeData = async () => {
    const { data } = await indicatorService.getDropdownDataByProjectId(
      projectId
    );

    if (!mounted.current) {
      return;
    }

    setUnitData(data.indicatorUnitTypes);

    const subcategoriesParentIds = data.categories
      ?.filter((category) => category.parentCategoryId)
      ?.map((category) => category.parentCategoryId);
    const newCategories = data.categories
      ?.filter(
        (category) =>
          !subcategoriesParentIds.some((parentId) => parentId === category.id)
      )
      ?.map((category) => ({
        ...category,
        parentName: data.categories.find(
          (parent) => parent.id === category.parentCategoryId
        )?.name,
      }));
    updateCategories(newCategories);
  };

  const refetchData = async () => {
    setIsLoading(true);

    await Promise.all([getIndicatorUnitTypeData(), getData()]);

    if (!mounted.current) {
      return;
    }

    setIsLoading(false);
  };

  // function that populates the post payload - this function is used only in POST calls and only saves dirty form fields
  const populatePostPayload = (formObject, resetOutcomeForm, dirtyFields) => {
    const objectArray = [];
    const dirtyFieldKeys = Object.keys(dirtyFields);
    const formKeys = Object.keys(formObject);

    for (const keys of dirtyFieldKeys) {
      const formName = keys;
      const formValue = formObject[keys];

      if (
        formName !== "noOutputsApply" &&
        formName !== "otherResults" &&
        !formName.includes("-unit") &&
        !formName.includes("-after") &&
        !formName.includes("-change") &&
        !formName.includes("-multiplier")
      ) {
        const entryObject = {};
        const formId = Number(formName.split("-")[1]);

        entryObject.indicatorId = formId;
        entryObject.outcomeValue = resetOutcomeForm ? "" : String(formValue);

        if (formKeys.includes(`${formName}-multiplier`)) {
          entryObject.multiplier = formObject[`${formName}-multiplier`] || 1;
        }

        if (formKeys.includes(`${formName}-unit`)) {
          entryObject.unitTypeId = formObject[`${formName}-unit`] || null;
        }

        if (formKeys.includes(`${formName}-after`)) {
          entryObject.beforeValue = resetOutcomeForm ? "" : String(formValue);
          entryObject.afterValue = resetOutcomeForm
            ? ""
            : String(formObject[`${formName}-after`]);
          entryObject.outcomeValue = resetOutcomeForm
            ? ""
            : String(formObject[`${formName}-change`].replaceAll("%", ""));
        }

        objectArray.push(entryObject);
      }
    }

    return objectArray;
  };

  // function that populates put payloads - this function saves the overall changes in the form (dirty fields cannot be used in this case - eg: user deselects a checkbox, dirtyFields will not track that change but this function will)
  const populatePutPayload = (formObject, resetOutcomeForm, dirtyFields) => {
    const objectArray = [];
    const formKeys = Object.keys(formObject);
    const dirtyFieldKeys = Object.keys(dirtyFields);

    if (resetOutcomeForm) {
      return objectArray;
    }

    for (const key of dirtyFieldKeys) {
      const formName = key;
      const formValue = formObject[key];

      if (formName === "noOutputsApply" || formName === "otherResults") {
        continue;
      }

      const formId = Number(formName.split("-")[1]);
      const saveId = formName.split("-")[2];

      let indicatorName = formName;
      const isNotOutcomeValue =
        formName.includes("-unit") ||
        formName.includes("-after") ||
        formName.includes("-change") ||
        formName.includes("-multiplier");

      if (isNotOutcomeValue) {
        indicatorName = formName.substr(0, formName.lastIndexOf("-"));
        if (
          dirtyFieldKeys.includes(indicatorName) ||
          objectArray.some((obj) => obj.indicatorId === formId)
        ) {
          continue;
        }
      }

      const entryObject = {};

      entryObject.indicatorId = formId;
      if (saveId !== "new") {
        entryObject.id = Number(saveId);
      }

      if (isNotOutcomeValue) {
        entryObject.outcomeValue = String(formObject[indicatorName]);
      } else {
        entryObject.outcomeValue = String(formValue);
      }

      if (formName.includes("-multiplier")) {
        entryObject.multiplier = formValue || 1;
      } else if (formKeys.includes(`${indicatorName}-multiplier`)) {
        entryObject.multiplier = formObject[`${indicatorName}-multiplier`] || 1;
      }

      if (formName.includes("-unit")) {
        entryObject.unitTypeId = formValue || null;
      } else if (formKeys.includes(`${indicatorName}-unit`)) {
        entryObject.unitTypeId = formObject[`${indicatorName}-unit`] || null;
      }

      if (formName.includes("-after")) {
        entryObject.beforeValue = String(formObject[indicatorName]);
        entryObject.afterValue = String(formValue);
        entryObject.outcomeValue = String(
          formObject[`${indicatorName}-change`].replaceAll("%", "")
        );
      } else if (formKeys.includes(`${indicatorName}-after`)) {
        entryObject.beforeValue = String(
          isNotOutcomeValue ? formObject[indicatorName] : formValue
        );
        entryObject.afterValue = String(formObject[`${indicatorName}-after`]);
        entryObject.outcomeValue = String(
          formObject[`${indicatorName}-change`].replaceAll("%", "")
        );
      }

      objectArray.push(entryObject);
    }

    return objectArray;
  };

  const handlePromptConfirmation = (save) => {
    if (save) {
      if (isOutcomeValid && isOutputValid) {
        saveProjectData(false);
      } else {
        showSnackbar("Unable to save due to invalid data", snackbarTypes.error);
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    const newFormStatus = getFormStatus(
      outputData,
      outcomeData,
      secondaryInputs?.noOutputsApply
    );
    const oldFormStatus = formStatus;
    // Loads results data if form is not disabled anymore
    if (
      oldFormStatus === formStatuses.disabled &&
      newFormStatus !== formStatuses.disabled
    ) {
      refetchData();
    } else {
      handleFormStatusUpdate(
        getFormStatus(outputData, outcomeData, secondaryInputs?.noOutputsApply)
      );
    }
  }, [categoryFormStatus]);

  useEffect(() => {
    updateSelectedCategories(selectedCategoriesIds);
  }, [categoryData]);

  useEffect(() => {
    if (activeStep === steps.results) {
      // results tab is selected
      refetchData();
    }
  }, [activeStep]);

  useEffect(() => {
    if (!isAnnualReportTab) return;
    if (activeStep === steps.results) {
      const isDirty = isOutcomeDirty || isOutputDirty;
      const isInvalid = !(isOutcomeValid && isOutputValid);
      onUnsavedChanges(
        isDirty,
        isInvalid,
        isDirty ? handlePromptConfirmation : null
      );
    }
  }, [isOutcomeDirty, isOutputDirty, isOutcomeValid, isOutputValid]);

  const saveProjectData = async (refetchResults = true) => {
    try {
      setIsLoading(true);

      const outputForm = getOutputFormValues.current
        ? getOutputFormValues.current()
        : {};
      const outcomeForm = getOutcomeFormValues.current
        ? getOutcomeFormValues.current()
        : {};

      const payload = {};

      payload.noOutputsApply = outputForm.noOutputsApply;
      payload.otherResults = outcomeForm.otherResults;

      const outputPayload = !hasSavedData
        ? populatePostPayload(
            outputForm,
            outputForm.noOutputsApply,
            dirtyOutputs
          )
        : populatePutPayload(
            outputForm,
            outputForm.noOutputsApply,
            dirtyOutputs
          );
      const outcomePayload = !hasSavedData
        ? populatePostPayload(outcomeForm, false, dirtyOutcomes)
        : populatePutPayload(outcomeForm, false, dirtyOutcomes);

      payload.outputs = outputPayload;
      payload.outcomes = outcomePayload;

      let response;

      if (hasSavedData) {
        response = await updateResults.call(projectId, payload);
      } else {
        response = await insertResults.call(projectId, payload);
      }

      if (!mounted.current) {
        return;
      }

      if (response && !response.error && refetchResults) {
        resetAndGetData(true);
      }
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const getFormStatus = (outputsRef, outcomesRef, noOutputsApplyRef) => {
    if (categoryFormStatus === formStatuses.untouched) {
      return formStatuses.disabled;
    }
    if (
      !isConstructionEndInReportingYear &&
      !isConstructionEndBeforeReportingYear
    ) {
      return formStatuses.completed;
    }

    const outputHasData =
      outputsRef?.some(
        (output) =>
          !!output.indicators?.some((indicator) => indicator.savedData?.id)
      ) ?? false;

    const outcomeHasData =
      outcomesRef?.some((outcome) => outcome.savedData?.id) ?? false;

    const outputIsComplete =
      outputsRef?.some(
        (output) =>
          !!output.indicators?.some(
            (indicator) =>
              indicator.savedData?.id !== null &&
              indicator.savedData?.outcomeValue &&
              (!indicator.indicatorUnitTypeId ||
                indicator.indicatorUnitTypeId === unitCategoryTypes.numeric ||
                indicator.savedData?.unitTypeId)
          )
      ) || noOutputsApplyRef;

    const outcomeIsComplete = outcomesRef?.some(
      (outcome) =>
        outcome.savedData?.id !== null &&
        outcome.savedData?.outcomeValue &&
        (!outcome.indicatorUnitTypeId ||
          outcome.indicatorUnitTypeId === unitCategoryTypes.numeric ||
          outcome.savedData?.unitTypeId) &&
        (!outcome.isBeforeAfter ||
          (outcome.savedData?.beforeValue && outcome.savedData?.afterValue))
    );

    // If both output and outcome has at least one field filled the form is complete
    if (
      (outputIsComplete || (outputsRef?.length ?? 0) <= 0) &&
      (outcomeIsComplete || (outcomesRef?.length ?? 0) <= 0)
    ) {
      return formStatuses.completed;
    }
    // If only one of output or outcome has at least one field filled the form is incomplete
    if (outputHasData || noOutputsApplyRef || outcomeHasData) {
      return formStatuses.incomplete;
    }

    return formStatuses.untouched;
  };

  const handleFormStatusUpdate = (newFormStatus) => {
    if (newFormStatus !== formStatus) {
      setFormStatus(newFormStatus);
      onFormStatusChange(newFormStatus);
    }
  };

  const renderFormActions = () => [
    {
      testId: "projReportSaveButton",
      label: "Save",
      disabled:
        // Outcome and output should be valid to enable save
        !(isOutcomeValid && isOutputValid) ||
        // Outcome or output should be dirty to enable save
        !(isOutcomeDirty || isOutputDirty) ||
        !hasProjectsPermission ||
        isLoading,
      onClick: saveProjectData,
    },
    {
      testId: "projReportDeleteButton",
      label: getDeleteType().text,
      variant: "outlined",
      color: "secondary",
      disabled: !isTreasurerOrDelegate || projectDeletionRequested,
      onClick: () => setShowDeletionModal(true),
      tooltipText: deleteButtonTooltipText(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ),
    },
  ];

  const renderResultButtonActions = () => [
    {
      testId: "projReportDownloadResultsButton",
      label: "Download Results File",
      variant: "outlined",
      onClick: onDownloadResultsTemplate,
    },
    {
      testId: "projReportUploadResultsButton",
      label: "Upload Results File",
      variant: "outlined",
      onClick: () => inputFile.current?.click(),
      disabled: !isConstructionEndInReportingYear || !hasProjectsPermission,
    },
  ];

  const LoadingSkeleton = () => (
    <>
      <Skeleton width="100%" height="50px" />
      <Skeleton width="100%" height="50px" />
      <Skeleton width="100%" height="50px" />
    </>
  );

  const deleteModalClose = () => {
    setShowDeletionModal(false);
  };

  const resultsForm = () => (
    <>
      <DeletionModal
        open={showDeletionModal}
        id={parseInt(projectId, 10)}
        closeFunction={deleteModalClose}
        updateFunction={getDeleteType().action}
        deletionType={deleteType(
          isTreasurerOrDelegate,
          hasPriorExpenditures,
          projectDeletionRequestedInString
        )}
      />
      <input
        type="file"
        id="project-results-template-upload-input"
        ref={inputFile}
        style={{ display: "none" }}
        onInput={onUploadResultsTemplate}
        accept={`.${fileTypes.xlsx}`}
      />
      <Grid
        container
        className={classes.root}
        direction="column"
        spacing={0}
        wrap="nowrap"
      >
        <Grid
          item
          xs
          className={isAnnualReportTab ? classes.scrollableContent : ""}
        >
          <Grid
            container
            className={isAnnualReportTab ? classes.body : ""}
            direction="column"
            spacing={0}
            alignItems="stretch"
            wrap="nowrap"
          >
            {!isConstructionEndInReportingYear && (
              <Grid item className={classes.contentPadding}>
                <Typography
                  className={clsx(classes.fullWidth, classes.warningText)}
                  variant="body2"
                >
                  Results cannot be submitted unless the construction end date
                  is within the current reporting year.
                </Typography>
              </Grid>
            )}
            <Grid item className={classes.contentPadding}>
              <Typography
                className={classes.fullWidth}
                variant="body1"
                component="span"
              >
                {parse(formFields[formCodes.descriptionForm]?.text || "")}
              </Typography>
            </Grid>
            <Grid>
              <AmoFormActions
                hideLateralBorders
                actions={renderResultButtonActions()}
                spacing="1.25rem"
              />
            </Grid>
            <Grid item className={classes.contentPadding}>
              <Typography
                className={classes.fullWidth}
                variant="body1"
                component="span"
              >
                {parse(formFields[formCodes.descriptionCategory]?.text || "")}
              </Typography>
            </Grid>
            <Grid item className={classes.contentPadding}>
              <Button
                data-testid="proj-report-results-add-additional-categories-button"
                className={classes.addAdditionalCategoryButton}
                color="primary"
                disableElevation
                onClick={() => setModalIsOpen(true)}
                startIcon={
                  <Icon className="material-icons-outlined">
                    add_circle_outline
                  </Icon>
                }
                disabled={!hasProjectsPermission}
              >
                <Typography variant="body1">
                  Add additional relevant categories
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
              className={clsx(classes.contentPadding, classes.headerStyles)}
            >
              <Typography
                className={clsx(classes.fullWidth, classes.indicatorHeader)}
                variant="h5"
                component="span"
              >
                {parse(formFields[formCodes.titleOutputs]?.text || "")}
              </Typography>
            </Grid>
            <Grid
              item
              className={clsx(classes.contentPadding, classes.bodyStyles)}
            >
              <Typography
                className={classes.fullWidth}
                variant="body1"
                component="span"
              >
                {parse(formFields[formCodes.descriptionOutputs]?.text || "")}
              </Typography>
            </Grid>
            <Grid
              item
              className={clsx(classes.contentPadding, classes.bodyStyles)}
            >
              {outputData.length > 0 && (
                <ProjectOutputs
                  outputData={outputData}
                  outputSchema={outputYupSchema}
                  defaultValues={outputDefaultValues}
                  validateOutput={(state) => {
                    setIsOutputValid(state.isValid);
                    setIsOutputDirty(state.isDirty);
                  }}
                  unitData={unitData}
                  disabled={
                    !isConstructionEndInReportingYear || !hasProjectsPermission
                  }
                  prevFormValues={outputPreviousForm}
                  dirtyFieldHook={setDirtyOutputs}
                  secondaryInputs={secondaryInputs.noOutputsApply}
                  onFormRender={(getOutputValues) => {
                    getOutputFormValues.current = getOutputValues;
                  }}
                />
              )}
            </Grid>
            <Grid
              item
              className={clsx(classes.contentPadding, classes.headerStyles)}
            >
              <Typography
                className={clsx(classes.fullWidth, classes.indicatorHeader)}
                variant="h5"
                component="span"
              >
                {parse(formFields[formCodes.titleOutcomes]?.text || "")}
              </Typography>
            </Grid>
            <Grid
              item
              className={clsx(classes.contentPadding, classes.bodyStyles)}
            >
              <Typography
                className={classes.fullWidth}
                variant="body1"
                component="span"
              >
                {parse(formFields[formCodes.descriptionOutcomes]?.text || "")}
              </Typography>
            </Grid>
            <Grid
              item
              className={clsx(classes.contentPadding, classes.bodyStyles)}
            >
              {outcomeData.length > 0 && (
                <ProjectOutcomes
                  outcomeData={outcomeData}
                  outcomeSchema={outcomeYupSchema}
                  defaultValues={outcomeDefaultValues}
                  validateOutcome={(state) => {
                    setIsOutcomeValid(state.isValid);
                    setIsOutcomeDirty(state.isDirty);
                  }}
                  unitData={unitData}
                  disabled={
                    !isConstructionEndInReportingYear || !hasProjectsPermission
                  }
                  prevFormValues={outcomePreviousForm}
                  dirtyFieldHook={setDirtyOutcomes}
                  secondaryInputs={secondaryInputs.otherResults}
                  onFormRender={(getOutcomeValues) => {
                    getOutcomeFormValues.current = getOutcomeValues;
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {activeStep === steps.results && (
          <Grid>
            <AmoFormActions
              className={classes.formActions}
              actions={renderFormActions()}
              spacing="1.25rem"
            />
          </Grid>
        )}
      </Grid>
      <AdditionalCategoriesModal
        projectId={projectId}
        categoryId={categoryData.subcategoryId || categoryData.categoryId}
        modalIsOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        updateForm={resetAndGetData}
        onSelectedCategoriesChange={updateSelectedCategories}
        categories={categories}
      />
    </>
  );

  if (isLoading) {
    return <LoadingSkeleton />;
  }
  return resultsForm();
};

ProjectReportResultsForm.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  activeStep: PropTypes.oneOf([
    steps.category,
    steps.generalInformation,
    steps.financials,
    steps.communications,
    steps.results,
  ]).isRequired,
  categoryFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]).isRequired,
  categoryData: PropTypes.shape(),
  isConstructionEndInReportingYear: PropTypes.bool,
  isConstructionEndBeforeReportingYear: PropTypes.bool,
  onFormStatusChange: PropTypes.func,
  projectInfo: PropTypes.shape(),
  isAnnualReportTab: PropTypes.bool,
  onUnsavedChanges: PropTypes.func,
  projectDeletionRequested: PropTypes.bool,
  refetchValidationInfo: PropTypes.func,
};

ProjectReportResultsForm.defaultProps = {
  categoryData: {},
  isConstructionEndInReportingYear: false,
  isConstructionEndBeforeReportingYear: false,
  onFormStatusChange: () => {},
  projectInfo: {},
  isAnnualReportTab: true,
  onUnsavedChanges: () => {},
  projectDeletionRequested: true,
  refetchValidationInfo: () => {},
};

export default ProjectReportResultsForm;
