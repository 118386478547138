import { DateTime } from "luxon";

export const mapStatuses = {
  unpublished: { id: -1, label: "Unpublished" },
  considering: { id: 2, label: "Considering", color: "purple" },
  planning: { id: 3, label: "Planning", color: "blue" },
  onHold: { id: 4, label: "On Hold", color: "yellow" },
  ready: { id: 5, label: "Ready", color: "green" },
  published: { id: 7, label: "Published", color: "amoGreen" },
};

export const mapStatusColors = Object.fromEntries(
  Object.values(mapStatuses).map((status) => [status.id, status.color])
);

export const defaultMapStatus = mapStatuses.published;

const getDate = (days) => DateTime.now().minus({ days }).toFormat("yyyy-MM-dd");

export const mapTimeRanges = {
  lastWeek: { label: "Last Week", getDate: () => getDate(7) },
  lastMonth: { label: "Last Month", getDate: () => getDate(30) },
  lastYear: { label: "Last Year", getDate: () => getDate(365) },
  lastTwoYears: { label: "Last Two Years", getDate: () => getDate(730) },
};

export const defaultMapTimeRange = mapTimeRanges.lastYear;
