import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Divider,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Icon,
} from "@material-ui/core";
import clsx from "clsx";
import { DateTime, Info } from "luxon";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  municipalLandingCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { keyDatesService } from "api/services/keyDateService";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    "& a": {
      color: colors.green.main,
      textDecoration: "none",
      pointerEvents: "none",
      fontWeight: "bold",
    },
  },
  formTitle: {
    // 32px
    paddingLeft: "2rem",
    "& > .MuiGrid-item": {
      "&:last-child": {
        // 20px
        paddingBottom: "1.25rem",
      },
    },
  },
  formField: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        paddingBottom: "0rem",
      },
    },
  },
  formFieldGrid: {
    "& > .MuiGrid-item > .MuiGrid-container > .MuiGrid-item": {
      // 24px
      paddingRight: "1.5rem",
      // 24px
      paddingLeft: "1.5rem",
      "&:first-child": {
        paddingLeft: "0rem",
      },
      "&:last-child": {
        paddingRight: "0rem",
      },
    },
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 20px 0px
    margin: "1.25rem 0rem",
  },
  inputWidth: {
    width: "100%",
  },
  paddingTop: {
    // 32px
    paddingTop: "2rem",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  formTitleWrapper: {
    color: colors.green.dark,
  },
  fieldContainer: {
    // 16px 96px
    padding: "1rem 6rem",
  },
  cardContainer: {
    // 0px 16px 0px 16px
    margin: "0rem 1rem 0rem 1rem",
    border: `1px solid ${colors.grey.main}`,
    padding: "1rem",
    marginBottom: "1rem",
  },
  saveIconButton: {
    // marginLeft: "0.5rem",
    padding: "0.5rem 0.5rem 0.5rem 0rem",
  },
  saveIcon: {
    fontSize: "1.5rem",
  },
  deadlineLabel: {
    color: colors.green.dark,
  },
  textField: {
    // 240px
    width: "15rem",
    paddingRight: "0.75rem",
    "& .MuiSelect-root": {
      // 176px
      minWidth: "11rem",
    },
  },
}));

/**
 * A page component for editing "Municipal Landing Page" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const MunicipalLandingEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.municipalLandingPage
  );
  const { showSnackbar } = useSnackbar();

  const [reportingDeadlineId, setReportingDeadlineId] = useState();
  const [reportingDeadline, setReportingDeadline] = useState(DateTime.now());
  const [
    reportingDeadlineSaveDisabled,
    setReportingDeadlineSaveDisabled,
  ] = useState(true);
  const previousReportingDeadline = useRef(reportingDeadline);

  const [deadlineWarningThreshold, setDeadlineWarningThreshold] = useState();
  const [
    deadlineWarningThresholdSaveDisabled,
    setDeadlineWarningThresholdSaveDisabled,
  ] = useState(true);
  const previousDeadlineWarningThreshold = useRef(deadlineWarningThreshold);

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // this effect will make a call to the API to get the reporting deadline data
  useEffect(async () => {
    try {
      const { data } = await keyDatesService.getByName("reportingDueDate");

      if (!mounted.current) {
        return;
      }

      const deadline = DateTime.local(
        DateTime.now().year,
        data.month,
        data.day
      );

      setReportingDeadlineId(data.id);
      setReportingDeadline(deadline);
      setDeadlineWarningThreshold(data.thresholdDays);

      previousReportingDeadline.current = deadline;
      previousDeadlineWarningThreshold.current = data.thresholdDays;
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  }, []);

  const renderDaysOptions = () => {
    const options = [];
    for (let i = 1; i <= reportingDeadline.daysInMonth; i += 1) {
      options.push(
        <MenuItem key={`mlpReportDeadlineDayOption-${i}`} value={i}>
          {i}
        </MenuItem>
      );
    }
    return options;
  };

  const handleReportDeadlineMonthChange = (value) => {
    // Setting the day like this to avoid invalid date error
    let newReportingDeadline = DateTime.fromFormat(
      `${value} 01 ${reportingDeadline.year}`,
      "LLLL dd yyyy"
    ).set({ day: reportingDeadline.day });
    // In case it automatically changes month when setting the day
    while (newReportingDeadline.monthLong !== value) {
      newReportingDeadline = newReportingDeadline.minus({ days: 1 });
    }
    setReportingDeadline(newReportingDeadline);
    setReportingDeadlineSaveDisabled(
      newReportingDeadline.equals(previousReportingDeadline.current)
    );
  };

  const handleReportDeadlineDayChange = (value) => {
    const newReportingDeadline = DateTime.local(
      reportingDeadline.year,
      reportingDeadline.month,
      value
    );
    setReportingDeadline(newReportingDeadline);
    setReportingDeadlineSaveDisabled(
      newReportingDeadline.equals(previousReportingDeadline.current)
    );
  };

  const buildKeyDatePayload = () => ({
    id: reportingDeadlineId,
    month: reportingDeadline.month,
    day: reportingDeadline.day,
    thresholdDays: deadlineWarningThreshold,
  });

  const onReportDeadlineSaveClick = async () => {
    try {
      await keyDatesService.update(reportingDeadlineId, buildKeyDatePayload());

      if (!mounted.current) {
        return;
      }

      previousReportingDeadline.current = reportingDeadline;
      setReportingDeadlineSaveDisabled(true);

      showSnackbar("Reporting deadline updated successfully.");
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const handleDeadlineWarningThresholdChange = (value) => {
    const newThreshold = parseInt(value.replace(/[^0-9]+/g, ""), 10);
    setDeadlineWarningThreshold(newThreshold);
    setDeadlineWarningThresholdSaveDisabled(
      newThreshold === previousDeadlineWarningThreshold.current
    );
  };

  const onDeadlineWarningThresholdSaveClick = async () => {
    try {
      await keyDatesService.update(reportingDeadlineId, buildKeyDatePayload());

      if (!mounted.current) {
        return;
      }

      previousDeadlineWarningThreshold.current = deadlineWarningThreshold;
      setDeadlineWarningThresholdSaveDisabled(true);

      showSnackbar("Deadline warning threshold updated successfully.");
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);
    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Municipal Landing Page"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyPadding
            )}
          >
            {/* Reporting Deadline */}
            <Grid item className={classes.fieldContainer}>
              <Grid container spacing={0} wrap="nowrap" alignItems="center">
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    noWrap
                    className={classes.deadlineLabel}
                  >
                    Reporting Deadline
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="mlp-report-deadline-month"
                    data-testid="mlpReportDeadlineMonth"
                    className={classes.textField}
                    select
                    label="Month"
                    variant="outlined"
                    value={reportingDeadline.monthLong || ""}
                    onChange={(event) =>
                      handleReportDeadlineMonthChange(event.target.value)
                    }
                  >
                    {Info.months().map((option) => (
                      <MenuItem
                        key={`mlpReportDeadlineMonthOption-${option}`}
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    id="mlp-report-deadline-day"
                    data-testid="mlpReportDeadlineDay"
                    className={classes.textField}
                    select
                    label="Day"
                    variant="outlined"
                    value={reportingDeadline.day || ""}
                    onChange={(event) =>
                      handleReportDeadlineDayChange(event.target.value)
                    }
                  >
                    {renderDaysOptions()}
                  </TextField>
                </Grid>
                <Grid item>
                  <IconButton
                    data-testid="mlpReportDeadlineSaveIcon"
                    aria-label="reportDeadlineSaveIcon"
                    className={classes.saveIconButton}
                    onClick={() => onReportDeadlineSaveClick()}
                    color="primary"
                    disabled={reportingDeadlineSaveDisabled}
                  >
                    <Icon
                      className={clsx(
                        "material-icons-outlined",
                        classes.saveIcon
                      )}
                    >
                      save
                    </Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {/* Deadline Warning Threshold */}
            <Grid item className={classes.fieldContainer}>
              <Grid container spacing={0} wrap="nowrap" alignItems="center">
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    noWrap
                    className={classes.deadlineLabel}
                  >
                    Deadline Warning Threshold
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="mlp-deadline-warning-threshold"
                    data-testid="mlpDeadlineWarningThreshold"
                    className={classes.textField}
                    label="Days"
                    variant="outlined"
                    value={deadlineWarningThreshold || ""}
                    onChange={(event) =>
                      handleDeadlineWarningThresholdChange(event.target.value)
                    }
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    data-testid="mlpDeadlineWarningThresholdSaveIcon"
                    aria-label="deadlineWarningThresholdSaveIcon"
                    className={classes.saveIconButton}
                    onClick={() => onDeadlineWarningThresholdSaveClick()}
                    color="primary"
                    disabled={deadlineWarningThresholdSaveDisabled}
                  >
                    <Icon
                      className={clsx(
                        "material-icons-outlined",
                        classes.saveIcon
                      )}
                    >
                      save
                    </Icon>
                  </IconButton>
                </Grid>
                {/* <Grid item /> */}
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* 5 Year Banking Limit Status */}
            <Grid item className={classes.cardContainer}>
              <Grid container direction="column" spacing={0}>
                <Grid item xs>
                  <Typography variant="h4" noWrap className={classes.title}>
                    5 Year Banking Limit Status
                  </Typography>
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.bankLimitDescriptionFormStart] && (
                    <FormFieldWrapper
                      id="mlp-bank-limit-form-description-start"
                      testId="mlpBankLimitFormDescriptionStart"
                      type={
                        formFields[formCodes.bankLimitDescriptionFormStart].type
                      }
                      value={
                        formFields[formCodes.bankLimitDescriptionFormStart].text
                      }
                      textProps={{ variant: "body1" }}
                      align="flex-start"
                      isFullWidth
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.bankLimitDescriptionFormStart]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.bankLimitTitleFunds] && (
                    <FormFieldWrapper
                      id="mlp-bank-limit-funds-title"
                      testId="mlpBankLimitFundsTitle"
                      type={formFields[formCodes.bankLimitTitleFunds].type}
                      value={formFields[formCodes.bankLimitTitleFunds].text}
                      textProps={{ variant: "body2" }}
                      className={classes.formTitleWrapper}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.bankLimitTitleFunds]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.bankLimitTitleStatus] && (
                    <FormFieldWrapper
                      id="mlp-bank-limit-status-title"
                      testId="mlpBankLimitStatusTitle"
                      type={formFields[formCodes.bankLimitTitleStatus].type}
                      value={formFields[formCodes.bankLimitTitleStatus].text}
                      textProps={{ variant: "body2" }}
                      className={classes.formTitleWrapper}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.bankLimitTitleStatus]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.bankLimitTitleExemption] && (
                    <FormFieldWrapper
                      id="mlp-bank-limit-exemption-title"
                      testId="mlpBankLimitExemptionTitle"
                      type={formFields[formCodes.bankLimitTitleExemption].type}
                      value={formFields[formCodes.bankLimitTitleExemption].text}
                      textProps={{ variant: "body2" }}
                      className={classes.formTitleWrapper}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.bankLimitTitleExemption]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.bankLimitDescriptionFormEnd] && (
                    <FormFieldWrapper
                      id="mlp-bank-limit-form-description-end"
                      testId="mlpBankLimitFormDescriptionEnd"
                      type={
                        formFields[formCodes.bankLimitDescriptionFormEnd].type
                      }
                      value={
                        formFields[formCodes.bankLimitDescriptionFormEnd].text
                      }
                      textProps={{ variant: "body1" }}
                      className={classes.formDescription}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.bankLimitDescriptionFormEnd]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Incrementality Compliance */}
            <Grid item className={classes.cardContainer}>
              <Grid container direction="column" spacing={0}>
                <Grid item xs>
                  <Typography variant="h4" noWrap className={classes.title}>
                    Incrementality Compliance
                  </Typography>
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.complianceDescriptionFormStart] && (
                    <FormFieldWrapper
                      id="mlp-compliance-form-description-start"
                      testId="mlpComplianceFormDescriptionStart"
                      type={
                        formFields[formCodes.complianceDescriptionFormStart]
                          .type
                      }
                      value={
                        formFields[formCodes.complianceDescriptionFormStart]
                          .text
                      }
                      textProps={{ variant: "body1" }}
                      align="flex-start"
                      isFullWidth
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.complianceDescriptionFormStart]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.complianceTitleTargetAvgInvestment] && (
                    <FormFieldWrapper
                      id="mlp-compliance-target-avg-investment-title"
                      testId="mlpComplianceTargetAvgInvestmentTitle"
                      type={
                        formFields[formCodes.complianceTitleTargetAvgInvestment]
                          .type
                      }
                      value={
                        formFields[formCodes.complianceTitleTargetAvgInvestment]
                          .text
                      }
                      textProps={{ variant: "body2" }}
                      className={classes.formTitleWrapper}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[
                            formCodes.complianceTitleTargetAvgInvestment
                          ]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.complianceTitleAvgInvestment] && (
                    <FormFieldWrapper
                      id="mlp-compliance-avg-investment-title"
                      testId="mlpComplianceAvgInvestmentTitle"
                      type={
                        formFields[formCodes.complianceTitleAvgInvestment].type
                      }
                      value={
                        formFields[formCodes.complianceTitleAvgInvestment].text
                      }
                      textProps={{ variant: "body2" }}
                      className={classes.formTitleWrapper}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.complianceTitleAvgInvestment]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.complianceTitleStatus] && (
                    <FormFieldWrapper
                      id="mlp-compliance-status-title"
                      testId="mlpComplianceStatusTitle"
                      type={formFields[formCodes.complianceTitleStatus].type}
                      value={formFields[formCodes.complianceTitleStatus].text}
                      textProps={{ variant: "body2" }}
                      className={classes.formTitleWrapper}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.complianceTitleStatus]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {formFields[formCodes.complianceDescriptionFormEnd] && (
                    <FormFieldWrapper
                      id="mlp-compliance-form-description-end"
                      testId="mlpComplianceFormDescriptionEnd"
                      type={
                        formFields[formCodes.complianceDescriptionFormEnd].type
                      }
                      value={
                        formFields[formCodes.complianceDescriptionFormEnd].text
                      }
                      textProps={{ variant: "body1" }}
                      className={classes.formDescription}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.complianceDescriptionFormEnd]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="mlp-edit-popover"
        testId="mlpEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default MunicipalLandingEditPage;
