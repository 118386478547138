import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import AmoTooltip from "../AmoTooltip";
import AmoFlagViewPopover from "./AmoFlagViewPopover";
import { gridOptions } from "constants/propTypes";

/**
 * A wrapper component for inputs that can be flagged for municipal users
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - component id used to compose flag id ({id}Popover) [required]
 * @param {string} props.testId - component data-testid for the wrapper grid and flag testId ({testId}Popover)
 * @param {Node|Node[]} props.children - input children put between flag and tooltip [required]
 * @param {boolean} props.flag - controls visibility of the flag component, but it still takes up space if false
 * @param {boolean} props.tooltip - controls visibility of the tooltip component, but it still takes up space if false
 * @param {boolean} props.hideFlagSpacing - controls visibility of the flag component, without taking up space if true
 * @param {boolean} props.hideTooltipSpacing - controls visibility of the tooltip component, without taking up space if true
 * @param {object} props.flagProps - object containing props for the flag component
 * @param {string} props.flagProps.title - text to display in the flag title
 * @param {string} props.flagProps.ownerName - text to display in the flag owner name
 * @param {string} props.flagProps.ownerEmail - text to display in the flag owner email
 * @param {string} props.flagProps.comment - text to display in the flag comment
 * @param {object} props.tooltipProps - object containing props for the tooltip component
 * @param {string} props.tooltipProps.title - text to display in the tooltip title
 * @param {string} props.tooltipProps.text - text to display in the tooltip description
 * @param {boolean} props.childrenFullWidth - controls whether the children is full width or not
 * @param {string} props.justifyContent - sets this form input wrapper Grid container justifyContent prop (valid values: @see gridOptions )
 * @returns - The wrapper component
 */
const FormInputWrapper = (props) => {
  const {
    id,
    testId,
    children,
    flag,
    tooltip,
    flagProps,
    tooltipProps,
    childrenFullWidth,
    hideFlagSpacing,
    hideTooltipSpacing,
    justifyContent,
    alignItems,
  } = props;

  const [isInteractiveTooltip, setIsInteractiveTooltip] = useState(false);

  useEffect(() => {
    if (tooltipProps.text?.includes("<a")) {
      setIsInteractiveTooltip(true);
    }
  }, [tooltipProps.text]);

  return (
    <>
      <Grid
        data-testid={testId}
        container
        justifyContent={justifyContent}
        alignItems={alignItems}
        spacing={1}
        wrap="nowrap"
      >
        {(!hideFlagSpacing || flag) && (
          <Grid item>
            <Box visibility={flag ? "visible" : "hidden"}>
              <AmoFlagViewPopover
                id={`${id}Popover`}
                testId={testId ? `${testId}Popover` : undefined}
                title={flagProps.title || ""}
                ownerName={flagProps.ownerName || ""}
                ownerEmail={flagProps.ownerEmail || ""}
                comment={flagProps.comment || ""}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={childrenFullWidth}>
          {children}
        </Grid>
        {(!hideTooltipSpacing || tooltip) && (
          <Grid item>
            <Box visibility={tooltip ? "visible" : "hidden"}>
              <AmoTooltip
                tooltipTitle={tooltipProps.title || ""}
                tooltipText={tooltipProps.text}
                isInteractive={isInteractiveTooltip}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

// set the prop-types for this component
FormInputWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  flag: PropTypes.bool,
  tooltip: PropTypes.bool,
  flagProps: PropTypes.shape({
    title: PropTypes.string,
    ownerName: PropTypes.string,
    ownerEmail: PropTypes.string,
    comment: PropTypes.string,
  }),
  tooltipProps: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    // placement: PropTypes.string,
  }),
  childrenFullWidth: PropTypes.bool,
  hideFlagSpacing: PropTypes.bool,
  hideTooltipSpacing: PropTypes.bool,
  justifyContent: PropTypes.oneOf(gridOptions.justifyContent),
  alignItems: PropTypes.oneOf(gridOptions.alignItems),
};

FormInputWrapper.defaultProps = {
  testId: undefined,
  flag: false,
  tooltip: true,
  flagProps: {},
  tooltipProps: {},
  childrenFullWidth: true,
  hideFlagSpacing: false,
  hideTooltipSpacing: false,
  justifyContent: "flex-start",
  alignItems: "center",
};

export default FormInputWrapper;
