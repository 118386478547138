import { DateTime } from "luxon";

/**
 *  Opens the browser print modal with the blob passed
 *
 * @param {Blob} file - the file that will be printed.
 */

const printFile = (file) => {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: "application/pdf" })
  );

  // Create a link and simulate a click to open the PDF in a new tab
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank"; // Open in a new tab
  link.innerText = "Open and Print PDF";

  // automate click for direct opening
  link.click();

  // Append the link to the body or any suitable container
  document.body.appendChild(link);
};

/**
 *  Downloads the passed blob with the name passed
 *
 * @param {Blob} file - the file that will be downloaded.
 * @param {string} filename - generated file name.
 */
const downloadFile = (file, filename) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const dateNow = DateTime.now().toFormat("yyyyMMddHHmmss");

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename ?? dateNow);

  document.body.appendChild(link);

  link.click();
  link.parentNode.removeChild(link);

  if (url) {
    window.URL.revokeObjectURL(url);
  }
};

export { printFile, downloadFile };
