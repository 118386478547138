import React, { useState, useRef } from "react";
import { makeStyles, Divider, Grid, Typography } from "@material-ui/core";
import { useHistory, useLocation, Link } from "react-router-dom";

import ListPageWrapper from "components/ListPageWrapper";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import ProjectCategoryIcon from "components/ProjectCategoryIcon";
import ReviewStatus from "components/amo/ReviewStatus";

import { useGlobalStyles } from "hooks/globalStylesHook";
import { snackbarTypes } from "constants/snackbar";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { useSnackbar } from "contexts/SnackbarContext";
import { exportToExcel } from "utils/export";
import { useYearContext } from "contexts/YearContext";
import { useMunicipalContext } from "contexts/MunicipalContext";
import {
  projectCategoriesShort,
  reviewStatuses,
} from "constants/amoTableDropdownOptions";

import { useProjectReviewsData } from "./projectReviewsHooks";
import { useReviewAssignees } from "../commonReviewHooks";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  idField: {
    width: "5rem",
    textAlign: "right",
  },
}));

/**
 * The Project List Page.
 *
 * @returns {React.Component} Project List Page component.
 */
const ProjectReviewListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const { contextYear } = useYearContext();
  const { getMunicipalitiesNames } = useMunicipalContext();

  const [focusedItem, setFocusedItem] = useState({});
  const [isExportingToExcel, setIsExportingToExcel] = useState(false);
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const handleItemClick = (item) => {
    history.push(`${`/reviews/projects/${item.id}`}${location.search}`);
  };

  const { data: projects, isFetching } = useProjectReviewsData(contextYear);
  const { data: assignees } = useReviewAssignees();

  const tableColumns = [
    {
      fieldKey: "id",
      label: "Project ID",
      minWidth: "9.375rem",
      className: classes.idField,
    },
    {
      fieldKey: "municipality",
      label: "Municipality",
      minWidth: "10rem",
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "title",
      label: "Project Title",
      minWidth: "15.625rem",
      customRender: ({ title, id }) => (
        <Link
          to={`${`/reviews/projects/${id}`}${location.search}`}
          ref={(element) => {
            rowRefs.current[id] = element;
          }}
          onFocus={() => setFocusedItem({ id })}
          className={globalClasses.tableActionCell}
        >
          {title}
        </Link>
      ),
      isCustom: true,
    },
    {
      fieldKey: "categoryName",
      label: "Category",
      isComponent: true,
      horizontalAlign: "center",
      minWidth: "9rem",
      customRender: ({ categoryName, categoryCode }) => (
        <ProjectCategoryIcon name={categoryName} code={categoryCode} />
      ),
      isCustom: true,
      dropdownOptions: projectCategoriesShort,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "projectCost",
      label: "Total Cost",
      minWidth: "10rem",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "ccbfFunding",
      label: "CCBF Funding",
      minWidth: "11rem",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "assigneeName",
      label: "Assigned To",
      minWidth: "9.8rem",
      dropdownOptions: {
        " ": " ",
        ...(assignees?.reduce(
          (obj, { name }) => ({ ...obj, [name]: name }),
          {}
        ) ?? {}),
      },
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "statusName",
      label: "Review status",
      minWidth: "9.375rem",
      customRender: ({ statusId, statusName }) => (
        <ReviewStatus id={statusId} name={statusName} />
      ),
      isCustom: true,
      dropdownOptions: reviewStatuses,
      displayType: displayTypes.dropdown,
    },
  ];

  const tableActions = {
    actions: [tableDefaultActions.search, tableDefaultActions.export],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick: () => {
          setIsExportingToExcel(true);
          try {
            exportToExcel(
              filteredData.current,
              tableColumns,
              "Project Reviews",
              {
                sheetName: "Reviews",
                specialColumnSizes: { title: 40 },
              }
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          } finally {
            setIsExportingToExcel(false);
          }
        },
        disabled: isExportingToExcel,
      },
    },
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="h1">Projects</Typography>
        </Grid>
        <Grid item className={classes.dividerContainer}>
          <Divider />
        </Grid>
        <Grid item>
          <AmoTableWithFilters
            items={projects}
            columns={tableColumns}
            defaultOrderBy="id"
            defaultOrder="desc"
            enableItemClick
            onItemClick={handleItemClick}
            onFilteredDataChanged={(value) => {
              filteredData.current = value;
            }}
            debouncingTime={450}
            onScroll={(item) => {
              rowRefs.current?.[item.id]?.focus();
            }}
            focusedItem={focusedItem}
            actionsProps={tableActions}
            isLoading={isFetching}
          />
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default ProjectReviewListPage;
