import {
  localRoadsCategoryId,
  localRoadsSubcategoryId,
} from "constants/projectReport";
import { lengthUnitTypes } from "constants/indicatorConstants";

export const isLocalRoadsLength = (
  categoryId,
  subcategoryId,
  indicatorUnitTypeId
) =>
  categoryId === localRoadsCategoryId &&
  subcategoryId === localRoadsSubcategoryId &&
  (indicatorUnitTypeId === lengthUnitTypes.feet ||
    indicatorUnitTypeId === lengthUnitTypes.metres ||
    indicatorUnitTypeId === lengthUnitTypes.kilometres ||
    indicatorUnitTypeId === lengthUnitTypes.miles ||
    indicatorUnitTypeId === lengthUnitTypes.millimetres ||
    indicatorUnitTypeId === lengthUnitTypes.centimetres ||
    indicatorUnitTypeId === lengthUnitTypes.yards ||
    indicatorUnitTypeId === lengthUnitTypes.inches);

export const getReportedValueSummary = (
  categoryId,
  subcategoryId,
  indicatorUnitTypeId,
  reportedValue,
  reportedUnitAbbreviation,
  reportedMultiplier
) =>
  ` (${reportedValue} ${reportedUnitAbbreviation}${
    isLocalRoadsLength(categoryId, subcategoryId, indicatorUnitTypeId)
      ? ` over ${reportedMultiplier} lanes`
      : ""
  })`;
