import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AmoModal from "components/AmoModal";
import AmoTable from "components/table/AmoTable";
import NameLink from "components/municipal/NameLink";
import { getSortingComparator, stableSort } from "utils/data";
import { useYearContext } from "contexts/YearContext";
import { displayTypes } from "constants/amoTableConstants";

/**
 * A modal component to display the project upload results
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.open - controls visibility of the modal
 * @param {object[]} props.uploadResultData - object containing information of the project upload result
 * @param {number} props.uploadResultData.amoId - project amo id
 * @param {string} props.uploadResultData.internalId - project internal id
 * @param {string} props.uploadResultData.title - project title
 * @param {string} props.uploadResultData.status - project upload result status
 * @param {boolean} props.isLoading - whether the modal upload data is loading or not
 * @param {Function} props.onClose - function called when user clicks on the 'Continue' button or close icon (params: none)
 *
 * @returns {React.Component} The modal component
 */
const ProjectUploadModal = (props) => {
  const { open, onClose, uploadResultData, isLoading } = props;
  const { contextYear } = useYearContext();

  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("projectNameComponent");

  const tableColumns = [
    {
      fieldKey: "amoId",
      label: "AMO ID",
      widthPerc: 15,
    },
    {
      fieldKey: "internalId",
      label: "Internal ID",
      widthPerc: 20,
    },
    {
      fieldKey: "projectNameComponent",
      label: "Project Name",
      widthPerc: 28,
      displayType: displayTypes.component,
    },
    {
      fieldKey: "status",
      label: "Update Result",
      color: ({ status }) =>
        status?.startsWith("Successfully") ? "initial" : "secondary",
      variant: ({ status }) =>
        status?.startsWith("Successfully") ? "body1" : "body2",
      widthPerc: 37,
    },
  ];

  const getOrderBy = (newOrderBy) => {
    if (newOrderBy === "projectNameComponent") {
      return "title";
    }

    return newOrderBy;
  };

  // this effect sort the data when changed
  useEffect(() => {
    const mappedData =
      uploadResultData?.map((item) => {
        const title = item.title ?? "Untitled Project";
        return {
          ...item,
          title,
          projectNameComponent: (
            <NameLink
              name={title}
              link={`/reporting/${contextYear}/projects/${item.amoId}`}
            />
          ),
        };
      }) ?? [];

    const sortedResult = stableSort(
      mappedData,
      getSortingComparator(order, getOrderBy(orderBy))
    );

    setTableData(sortedResult);
  }, [uploadResultData]);

  const handleRequestSort = (fieldKey, newOrder) => {
    const newOrderBy = getOrderBy(fieldKey);
    setOrder(newOrder);
    setOrderBy(newOrderBy);

    const sorted = stableSort(
      tableData,
      getSortingComparator(newOrder, newOrderBy)
    );
    setTableData(sorted);
  };

  const closeModal = async () => {
    onClose();
  };

  return (
    <AmoModal
      data-testid="projectUploadResultModal"
      open={open}
      variant="default"
      title="File Upload Results"
      width="55rem"
      showButton
      closePopover={closeModal}
      buttonsLeftSpacer={8}
      buttons={[
        {
          key: "continue",
          label: "Continue",
          color: "primary",
          variant: "contained",
          onClick: closeModal,
        },
      ]}
    >
      <AmoTable
        id="projects-upload-table"
        testId="projectsUploadTable"
        items={tableData}
        columns={tableColumns}
        defaultOrderBy="projectNameComponent"
        onRequestSort={handleRequestSort}
        shouldUseQueryString
        disableFilters
        isLoading={isLoading}
      />
    </AmoModal>
  );
};

ProjectUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadResultData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool,
};

ProjectUploadModal.defaultProps = {
  isLoading: false,
};

export default ProjectUploadModal;
