import {
  Grid,
  Typography,
  Popover,
  IconButton,
  Divider,
  Button,
  Box,
} from "@material-ui/core";
import colors from "constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import parse from "html-react-parser";
import { municipalLandingCodes as formCodes } from "constants/formContentManagement";
import { formatToCurrency } from "utils/number";

const useStyles = makeStyles((theme) => ({
  paper: {
    color: colors.black,
    backgroundColor: colors.white,
    borderRadius: "0.625rem",
    width: "60rem",
    minHeight: "27rem",
    padding: theme.spacing(2),
  },
  closeButtonCell: {
    marginLeft: "auto",
  },
  statusTitle: {
    height: "2rem",
  },
  centralZone: {
    width: "100%",
    paddingRight: "4rem",
  },
  iconButton: {
    padding: theme.spacing(0.8),
    color: colors.grey.main,
  },
  superDenseList: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  actionArea: {
    padding: theme.spacing(2),
    width: "240px",
  },
}));

/**
 * A modal to display incrementality compliance status.
 *
 * @param {object} props
 * @param {boolean} props.isIncrementalityOpen  The modal's open/closed state.
 * @param {Function} props.setIncrementalityOpen  Sets the modal's open/closed state.
 * @param {object} props.data  object with data from the incrementality compliance
 * @param {number} props.data.targetAnnualInvestment  incrementality compliance target annual investment
 * @param {number} props.data.averageAnnualInvestment  incrementality compliance average annual investment
 * @param {string} props.data.status  incrementality compliance status
 * @param {object[]} props.formFields - array of object containing props for the modal form fields
 * @param {string} props.formFields.code - sets the code of the form field
 * @param {string} props.formFields.text - sets the text of the form field
 * @returns {React.Component} The Incrementality Compliance modal component.
 */
const IncrementalityComplianceModal = (props) => {
  const {
    isIncrementalityOpen,
    setIncrementalityOpen,
    data,
    formFields,
  } = props;
  const classes = useStyles();

  const formatWithBracketsIfNegative = (value) =>
    value < 0
      ? `(${formatToCurrency(Math.abs(value))})`
      : formatToCurrency(value);

  const formatToCurrencyOrBlank = (value) =>
    !isNaN(parseFloat(value)) ? formatWithBracketsIfNegative(value) : "";

  return (
    <>
      <Popover
        open={isIncrementalityOpen}
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        anchorEl={document.body}
        anchorOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
        PaperProps={{
          "data-testid": "incrementalityPaper",
          square: false,
          variant: "elevation",
          elevation: 20,
        }}
        data-testid="incrementalityModal"
      >
        <Grid container direction="column">
          <Grid item container direction="row">
            <Grid item>
              <Typography variant="h3">Incrementality Compliance</Typography>
            </Grid>
            <Grid item classes={{ root: classes.closeButtonCell }}>
              <IconButton
                data-testid="popoverCloseButton"
                classes={{
                  root: classes.iconButton,
                }}
                onClick={setIncrementalityOpen}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            classes={{ root: classes.centralZone }}
          >
            <Grid item>
              <Typography variant="body1">
                {parse(
                  formFields[formCodes.complianceDescriptionFormStart]?.text ??
                    ""
                )}
              </Typography>
            </Grid>
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.statusTitle }} xs={6}>
                <Box color={colors.green.dark}>
                  <Typography variant="body2">
                    {
                      formFields[formCodes.complianceTitleTargetAvgInvestment]
                        ?.text
                    }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">
                  {formatToCurrencyOrBlank(data?.targetAnnualInvestment)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.statusTitle }} xs={6}>
                <Box color={colors.green.dark}>
                  <Typography variant="body2">
                    {`${
                      formFields[formCodes.complianceTitleAvgInvestment]?.text
                    } (${data?.periodStart ?? ""}-${data?.periodEnd ?? ""})`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">
                  {formatToCurrencyOrBlank(data?.averageAnnualInvestment)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.statusTitle }} xs={6}>
                <Box color={colors.green.dark}>
                  <Typography variant="body2">
                    {formFields[formCodes.complianceTitleStatus]?.text}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{data?.status}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {parse(
                formFields[formCodes.complianceDescriptionFormEnd]?.text ?? ""
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid container direction="row-reverse">
          <Grid item classes={{ root: classes.actionArea }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={setIncrementalityOpen}
              fullWidth
              size="large"
              data-testid="closeButton"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

IncrementalityComplianceModal.propTypes = {
  isIncrementalityOpen: PropTypes.bool.isRequired,
  setIncrementalityOpen: PropTypes.func.isRequired,
  data: PropTypes.shape({
    targetAnnualInvestment: PropTypes.number,
    averageAnnualInvestment: PropTypes.number,
    status: PropTypes.string,
    periodStart: PropTypes.number,
    periodEnd: PropTypes.number,
  }),
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

IncrementalityComplianceModal.defaultProps = {
  data: null,
  formFields: [],
};

export default IncrementalityComplianceModal;
