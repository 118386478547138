import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider } from "@material-ui/core";
import clsx from "clsx";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  projectReportGeneralInfoCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    // 32px
    paddingLeft: "2rem",
    "& > .MuiGrid-item": {
      // 20px
      paddingBottom: "1.25rem",
    },
  },
  formTitle: {
    // 32px
    paddingLeft: "2rem",
    "& > .MuiGrid-item": {
      "&:last-child": {
        // 20px
        paddingBottom: "1.25rem",
      },
    },
  },
  formField: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        paddingBottom: "0rem",
      },
    },
  },
  formFieldGrid: {
    "& > .MuiGrid-item > .MuiGrid-container > .MuiGrid-item": {
      // 24px
      paddingRight: "1.5rem",
      // 24px
      paddingLeft: "1.5rem",
      "&:first-child": {
        paddingLeft: "0rem",
      },
      "&:last-child": {
        paddingRight: "0rem",
      },
    },
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 20px 0px
    margin: "1.25rem 0rem",
  },
  inputWidth: {
    width: "100%",
  },
  paddingTop: {
    // 32px
    paddingTop: "2rem",
  },
}));

/**
 * A page component for editing "Project Reporting: General Information" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const ReportGeneralInfoEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.projectReportGeneralInfo
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Project Reporting: General Information"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyPadding
            )}
          >
            {/* Form Description */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.descriptionForm] && (
                    <FormFieldWrapper
                      id="prgi-form-description"
                      testId="prgiFormDescription"
                      type={formFields[formCodes.descriptionForm].type}
                      value={formFields[formCodes.descriptionForm].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionForm])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Project Details Title */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitle}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.titleDetails] && (
                    <FormFieldWrapper
                      id="prgi-proj-details-title"
                      testId="prgiProjDetailsTitle"
                      type={formFields[formCodes.titleDetails].type}
                      value={formFields[formCodes.titleDetails].text}
                      textProps={{ variant: "h4" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.titleDetails])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionDetails] && (
                    <FormFieldWrapper
                      id="prgi-proj-details-description"
                      testId="prgiProjDetailsDescription"
                      type={formFields[formCodes.descriptionDetails].type}
                      value={formFields[formCodes.descriptionDetails].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.descriptionDetails]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Project Details Fields */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.fieldTitle] && (
                    <FormFieldWrapper
                      id="prgi-proj-title-field"
                      testId="prgiProjTitleField"
                      type={formFields[formCodes.fieldTitle].type}
                      value={formFields[formCodes.fieldTitle].text}
                      className={classes.inputWidth}
                      inputProps={{ required: true }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.fieldTitle])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.fieldInternalTitle] && (
                    <FormFieldWrapper
                      id="prgi-proj-internal-title-field"
                      testId="prgiProjInternalTitleField"
                      type={formFields[formCodes.fieldInternalTitle].type}
                      value={formFields[formCodes.fieldInternalTitle].text}
                      className={classes.inputWidth}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.fieldInternalTitle]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.fieldObjectives] && (
                    <FormFieldWrapper
                      id="prgi-proj-objectives-field"
                      testId="prgiProjObjectivesField"
                      type={formFields[formCodes.fieldObjectives].type}
                      value={formFields[formCodes.fieldObjectives].text}
                      className={classes.inputWidth}
                      inputProps={{ required: true }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.fieldObjectives])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.fieldScope] && (
                    <FormFieldWrapper
                      id="prgi-proj-scope-field"
                      testId="prgiProjScopeField"
                      type={formFields[formCodes.fieldScope].type}
                      value={formFields[formCodes.fieldScope].text}
                      className={classes.inputWidth}
                      inputProps={{ required: true }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.fieldScope])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(classes.formField, classes.formFieldGrid)}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldPrimaryLocation] && (
                        <FormFieldWrapper
                          id="prgi-proj-primary-location-field"
                          testId="prgiProjPrimaryLocationField"
                          type={formFields[formCodes.fieldPrimaryLocation].type}
                          value={
                            formFields[formCodes.fieldPrimaryLocation].text
                          }
                          className={classes.inputWidth}
                          inputProps={{
                            required: true,
                            endIconName: "location_on",
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldPrimaryLocation]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldSecondaryLocation] && (
                        <FormFieldWrapper
                          id="prgi-proj-secondary-location-field"
                          testId="prgiProjSecondaryLocationField"
                          type={
                            formFields[formCodes.fieldSecondaryLocation].type
                          }
                          value={
                            formFields[formCodes.fieldSecondaryLocation].text
                          }
                          className={classes.inputWidth}
                          inputProps={{ endIconName: "location_on" }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldSecondaryLocation]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(classes.formField, classes.formFieldGrid)}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldStartDate] && (
                        <FormFieldWrapper
                          id="prgi-proj-start-date-field"
                          testId="prgiProjStartDateField"
                          type={formFields[formCodes.fieldStartDate].type}
                          value={formFields[formCodes.fieldStartDate].text}
                          className={classes.inputWidth}
                          inputProps={{
                            required: true,
                            endIconName: "calendar_today",
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldStartDate]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldEndConstruction] && (
                        <FormFieldWrapper
                          id="prgi-proj-end-construction-field"
                          testId="prgiProjEndConstructionField"
                          type={formFields[formCodes.fieldEndConstruction].type}
                          value={
                            formFields[formCodes.fieldEndConstruction].text
                          }
                          className={classes.inputWidth}
                          inputProps={{
                            required: true,
                            endIconName: "calendar_today",
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldEndConstruction]
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldNatureInvestment] && (
                        <FormFieldWrapper
                          id="prgi-proj-nature-investment-field"
                          testId="prgiProjNatureInvestmentField"
                          type={
                            formFields[formCodes.fieldNatureInvestment].type
                          }
                          value={
                            formFields[formCodes.fieldNatureInvestment].text
                          }
                          className={classes.inputWidth}
                          inputProps={{ endIconName: "arrow_drop_down" }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldNatureInvestment]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* File Upload Fields */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.fieldComments] && (
                    <FormFieldWrapper
                      id="prgi-proj-comments-field"
                      testId="prgiProjCommentsField"
                      type={formFields[formCodes.fieldComments].type}
                      value={formFields[formCodes.fieldComments].text}
                      className={classes.inputWidth}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.fieldComments])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="prgi-edit-popover"
        testId="prgiEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default ReportGeneralInfoEditPage;
