import { useEffect, useState, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useDebounce } from "@focus21/hooks";
import { useIsMounted } from "hooks/useIsMounted";

/**
 * Hook to handle permalink checking and automatic adjusting
 *
 * @param {string} targetField name of the field that the permalink is based off
 * @param {string} permalinkField name of the permalink field
 * @param {string} savedPermalink permalink currently saved in the DB
 * @param {Function} checkPermalink api call to check if the permalink is taken
 * @param {object} control useForm control, to set useWatch on the fields
 * @param {Function} getValues useForm getValues, to fetch form values
 * @param {Function} setValue useForm setValue, to set form values
 * @param {Function} setError useForm setError, to set form errors
 * @param {Function} clearErrors useForm clearErrors, to clear form errors
 *
 * @returns {boolean[]} [disablePermalink, disableSave], to let form know to disable permalink field or save button
 */
export const usePermalink = (
  targetField,
  permalinkField,
  savedPermalink,
  checkPermalink,
  control,
  getValues,
  setValue,
  setError,
  clearErrors
) => {
  const mounted = useIsMounted();

  const [disablePermalink, setDisablePermalink] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const lastPermalink = useRef("");

  const createPermalink = (value) =>
    value
      .toLowerCase()
      .replace(/[^0-9a-z -]/g, "")
      .replace(/ /g, "-");

  const isPermalinkSaved = () =>
    getValues(permalinkField) === lastPermalink.current;

  const adjustPermalink = async () => {
    setDisablePermalink(true);
    clearErrors(permalinkField);

    const latestTarget = getValues(targetField);
    const latestPermalink = getValues(permalinkField);

    const newPermalink = createPermalink(
      latestPermalink !== lastPermalink.current ? latestPermalink : latestTarget
    );
    lastPermalink.current = newPermalink;
    setValue(permalinkField, newPermalink, {
      shouldValidate: !!newPermalink || !!latestPermalink,
    });

    if (newPermalink && newPermalink !== savedPermalink) {
      try {
        await checkPermalink(newPermalink);

        if (!mounted.current) {
          return;
        }

        setError(permalinkField, { message: "Permalink is taken" });
      } catch (error) {
        if (error.response?.data?.status !== 404) {
          throw error;
        }

        if (!mounted.current) {
          return;
        }
      }
    }
    setDisableSave(false);
    setDisablePermalink(false);
  };

  const targetWatch = useWatch({
    control,
    name: targetField,
    defaultValue: "",
  });
  const permalinkWatch = useWatch({
    control,
    name: permalinkField,
    defaultValue: "",
  });

  useDebounce(adjustPermalink, [targetWatch], 2000);

  useDebounce(
    () => {
      if (!isPermalinkSaved()) adjustPermalink();
    },
    [permalinkWatch],
    2000
  );

  useEffect(() => {
    setDisableSave(true);
    if (isPermalinkSaved()) setDisablePermalink(true);
  }, [targetWatch, permalinkWatch]);

  return [disablePermalink, disableSave];
};
