import { useQuery } from "react-query";
import { submitReportsService } from "api/services/submitReportService";

// Query Keys
import { annualReportKeys } from "./annualReportQueryKeys";

/**
 * Store server's state of annual report status
 *
 * @param {number} municipalityId The id of the municipality responsible for the annual report
 * @param {number} year The year of the annual report being fetched
 * @param {boolean} enabled Whether the query is enabled or not
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useAnnualReportStatus = (municipalityId, year, enabled = true) =>
  useQuery(
    annualReportKeys.status(municipalityId, year),
    () => submitReportsService.getAnnualReportStatus(municipalityId, year),
    {
      enabled,
    }
  );

export const submitAnnualReport = (municipalityId, year) =>
  submitReportsService.submitAnnualReport(municipalityId, year);
