import LuxonUtils from "@date-io/luxon";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import "./datepicker.css";
import { DateTime } from "luxon";

/**
 * This component is a customized MUI KeyboardDatePicker, built to work with a react-hook-form (RHF) controlled form.
 *
 * To use this and other RHF input components, make sure that the RHF controller object is being passed in from the parent component.
 *
 * The controller object comes from the RHF useForm hook, and it allows the input to be registered, controlled, and validated.
 *
 * @param {object} props
 * @param {object} props.control    The react-hook-form control, generated by useForm()
 * @param {boolean} props.disabled  Disables the component when true
 * @param {string} props.label      Label text for the component
 * @param {string} props.id         Field ID
 * @param {string} props.name       The name of the input; react-hook-form uses this to register the input
 * @param {string} props.testId     Maps to data-test-id; renamed on AMO component to avoid issues with targeting tests (maybe unnecessary)
 * @param {string} props.className  Passes in a css class
 * @param {boolean} props.required  Sets the required flag for this input (prefer handling this via a yup schema in most cases)
 * @param {string} props.variant    Sets the MUI variant for this input
 * @param {string} props.fullWidth  Sets the MUI fullWidth property for this input
 * @param {string} props.pickerIcon  An override icon for the default icon for the picker
 * @param {string} props.minDate   The earliest date available to select
 * @param {string} props.maxDate   The earliest date available to select
 * @param {boolean} props.hideCalendar Hides the calendar icon and component if needed
 * @param {string} props.format format that the date should be in
 * @param {string} props.helperText - green helper text of the input shown when the input is touched
 * @param {boolean} props.keepInvisibleHelperText  prevents the screen from shifting up/down whenever the input is focused
 * @param {Function} props.onChangeTrigger  Event that triggers on input change
 * @param {Function} props.onBlurCustom  Event that triggers on input change
 *
 * @returns {Function} A MUI KeyboardDatePicker component wrapped with the react-hook-form controller
 */
const AmoDatePicker = ({
  control,
  disabled,
  label,
  id,
  name,
  testId,
  className,
  required,
  variant,
  pickerIcon,
  minDate,
  maxDate,
  hideCalendar,
  format,
  helperText,
  keepInvisibleHelperText,
  onChangeTrigger,
  fullWidth,
  onBlurCustom,
}) => {
  const [isInputActive, setIsInputActive] = useState(false);

  const showHelperText = (errorObject) => {
    if (errorObject?.message) {
      return errorObject.message;
    }

    if (isInputActive) {
      return helperText;
    }

    if (keepInvisibleHelperText) {
      // invisible text that is the same same size as the other helper text - prevents the modal from shifting up/down whenever the input is selected
      return (
        <div style={{ fontSize: "0.75rem", visibility: "hidden" }}>hidden</div>
      );
    }

    return errorObject?.message;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        valueAsDate: true,
      }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <KeyboardDatePicker
            id={id}
            onChange={(data) => {
              onChange(data);
              onChangeTrigger(data);
            }}
            value={value}
            label={label}
            variant={variant}
            data-testid={testId ?? `${id}-test`}
            color="primary"
            disabled={disabled}
            required={required}
            error={!!error}
            FormHelperTextProps={{ component: "div" }}
            helperText={showHelperText(error)}
            inputVariant="outlined"
            disableToolbar
            format={format}
            autoOk
            keyboardIcon={pickerIcon}
            className={clsx(
              className,
              format !== "yyyy-MM-dd" && "customDatePicker"
            )}
            style={fullWidth ? { width: "100%" } : null}
            minDate={minDate}
            maxDate={maxDate}
            onFocus={() => {
              setIsInputActive(true);
              if (!DateTime.fromISO(value).isValid) {
                onChange(null);
              }
            }}
            onBlur={() => {
              setIsInputActive(false);
              onBlur();
              onBlurCustom();
            }}
            KeyboardButtonProps={
              hideCalendar && { disabled: true, style: { display: "none" } }
            }
          />
        </MuiPickersUtilsProvider>
      )}
    />
  );
};

AmoDatePicker.propTypes = {
  className: PropTypes.string,
  control: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  testId: PropTypes.string,
  variant: PropTypes.string,
  pickerIcon: PropTypes.shape(),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  hideCalendar: PropTypes.bool,
  format: PropTypes.string,
  helperText: PropTypes.string,
  keepInvisibleHelperText: PropTypes.bool,
  onChangeTrigger: PropTypes.func,
  onBlurCustom: PropTypes.func,
  fullWidth: PropTypes.bool,
};

AmoDatePicker.defaultProps = {
  className: undefined,
  disabled: false,
  required: false,
  testId: undefined,
  variant: "outlined",
  pickerIcon: <CalendarTodayIcon />,
  minDate: "0001/01/01",
  maxDate: undefined,
  hideCalendar: false,
  format: "yyyy-MM-dd",
  helperText: null,
  keepInvisibleHelperText: false,
  onChangeTrigger: () => {},
  onBlurCustom: () => {},
  fullWidth: false,
};

export default AmoDatePicker;
