import React from "react";
import clsx from "clsx";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import {
  FourRotateSvg,
  FourZeroGreenSvg,
  FourZeroDarkGreenSvg,
  FourZeroMirrorSvg,
  BackArrowSvg,
} from "img/notFoundImages";
import colors from "constants/colors";
import { routes } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    width: "auto",
  },
  shadowContainer: {
    position: "relative",
  },
  messageContainer: {
    padding: "1rem 1rem 1.5rem 1rem",
  },
  bottomContainer: {
    marginTop: "-1.188rem",
  },
  linkContainer: {
    marginTop: "7.75rem",
  },
  shadow: {
    position: "absolute",
    top: "-8px",
    left: "5px",
    zIndex: -1,
  },
  h1: {
    fontSize: "2.5rem",
  },
  darkGreen: {
    color: colors.green.dark,
  },
  leftPadding: {
    paddingLeft: "1rem",
  },
  textLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * A page component to display not found error message when route doesn't exist
 *
 * @returns {React.Component} The page component
 */
const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Grid
          container
          className={classes.gridContainer}
          direction="column"
          wrap="nowrap"
        >
          <Grid item>
            <Grid container wrap="nowrap" alignItems="flex-end">
              <Grid item>
                <Box className={classes.shadowContainer}>
                  <FourZeroDarkGreenSvg />
                  <FourZeroGreenSvg className={classes.shadow} />
                </Box>
              </Grid>
              <Grid item>
                <Grid
                  container
                  wrap="nowrap"
                  direction="column"
                  className={classes.messageContainer}
                >
                  <Grid item>
                    <Typography variant="h5" className={classes.darkGreen}>
                      Something’s wrong...
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      className={clsx(classes.h1, classes.darkGreen)}
                    >
                      OOPSY!
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.darkGreen}>
                      We can’t find the page that you’re looking for :(
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              className={classes.bottomContainer}
              wrap="nowrap"
              alignItems="baseline"
            >
              <Grid item>
                <FourZeroMirrorSvg />
              </Grid>
              <Grid item className={classes.leftPadding}>
                <FourRotateSvg />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              className={classes.linkContainer}
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item>
                <BackArrowSvg />
              </Grid>
              <Grid item className={classes.leftPadding}>
                <Link
                  data-testid="notFoundHomeLink"
                  to={routes.dashboard}
                  className={classes.textLink}
                >
                  <Typography color="primary" variant="h6">
                    Back to home
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NotFoundPage;
