import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Typography } from "@material-ui/core";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  projectReportCategoryCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    // 32px
    paddingLeft: "2rem",
    "& > .MuiGrid-item": {
      // 20px
      paddingBottom: "1.25rem",
    },
  },
  formField: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        paddingBottom: "0rem",
      },
    },
  },
  formFieldInput: {
    width: "20.5rem",
  },
  alignCenter: {
    width: "unset",
    alignSelf: "center",
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 20px 0px
    margin: "1.25rem 0rem",
  },
  inputWidth: {
    width: "100%",
  },
  linkReadonly: {
    width: "100%",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
  },
  paddingTop: {
    // 40px
    paddingTop: "2.5rem",
  },
}));

/**
 * A page component for editing "Project Reporting: Category" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const ReportCategoryEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.projectReportCategory
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Project Reporting: Category"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyMargin
            )}
          >
            {/* Fields Group: Unsaved Category View */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    data-testid="unsavedCategoryViewTitle"
                    variant="h4"
                    gutterBottom
                  >
                    Unsaved Category View
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Form Description */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.descriptionForm] && (
                    <FormFieldWrapper
                      id="prc-form-description"
                      testId="prcFormDescription"
                      type={formFields[formCodes.descriptionForm].type}
                      value={formFields[formCodes.descriptionForm].text}
                      textProps={{ variant: "body1" }}
                      align="flex-start"
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionForm])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Project Category */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item className={classes.alignCenter}>
                  {formFields[formCodes.category] && (
                    <FormFieldWrapper
                      id="prc-proj-category"
                      testId="prcProjCategory"
                      type={formFields[formCodes.category].type}
                      value={formFields[formCodes.category].text}
                      inputProps={{
                        required: true,
                        endIconName: "arrow_drop_down",
                      }}
                      textProps={{ variant: "h4" }}
                      className={classes.formFieldInput}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.category])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Is my project eligible? */}
            <Grid item xs className={classes.alignCenter}>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.isProjectEligible] && (
                    <FormFieldWrapper
                      id="prc-is-proj-eligible"
                      testId="prcIsProjEligibleLink"
                      type={formFields[formCodes.isProjectEligible].type}
                      value={formFields[formCodes.isProjectEligible].text}
                      textProps={{ color: "primary" }}
                      className={classes.inputWidth}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.isProjectEligible])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Subcategory */}
            <Grid item xs className={classes.alignCenter}>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.subcategory] && (
                    <FormFieldWrapper
                      id="prc-proj-subcategory"
                      testId="prcProjSubcategory"
                      type={formFields[formCodes.subcategory].type}
                      value={formFields[formCodes.subcategory].text}
                      inputProps={{
                        required: true,
                        endIconName: "arrow_drop_down",
                      }}
                      textProps={{ variant: "h4" }}
                      className={classes.formFieldInput}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.subcategory])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Divider */}
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Fields Group: Saved Category View */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    data-testid="SavedCategoryViewTitle"
                    variant="h4"
                    gutterBottom
                  >
                    Saved Category View
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Form Description Saved */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.descriptionFormSaved] && (
                    <FormFieldWrapper
                      id="prc-form-description-saved"
                      testId="prcFormDescriptionSaved"
                      type={formFields[formCodes.descriptionFormSaved].type}
                      value={formFields[formCodes.descriptionFormSaved].text}
                      textProps={{ variant: "body1" }}
                      align="flex-start"
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.descriptionFormSaved]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Project Category (readonly) */}
            <Grid item xs className={classes.alignCenter}>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.category] && (
                    <FormFieldWrapper
                      id="prc-proj-category-readonly"
                      testId="prcProjCategoryReadonly"
                      type={formFields[formCodes.category].type}
                      value={formFields[formCodes.category].text}
                      inputProps={{
                        required: true,
                        endIconName: "arrow_drop_down",
                      }}
                      textProps={{ variant: "h4" }}
                      className={classes.formFieldInput}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Is my project eligible? (readonly) */}
            <Grid item xs className={classes.alignCenter}>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.isProjectEligible] && (
                    <FormFieldWrapper
                      id="prc-is-proj-eligible-readonly"
                      testId="prcIsProjEligibleLinkReadonly"
                      type={formFields[formCodes.isProjectEligible].type}
                      value={formFields[formCodes.isProjectEligible].text}
                      textProps={{ color: "primary" }}
                      className={classes.linkReadonly}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Subcategory (readonly) */}
            <Grid item xs className={classes.alignCenter}>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.subcategory] && (
                    <FormFieldWrapper
                      id="prc-proj-subcategory-readonly"
                      testId="prcProjSubcategoryReadonly"
                      type={formFields[formCodes.subcategory].type}
                      value={formFields[formCodes.subcategory].text}
                      inputProps={{
                        required: true,
                        endIconName: "arrow_drop_down",
                      }}
                      textProps={{ variant: "h4" }}
                      className={classes.formFieldInput}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Reason for change request */}
            <Grid item xs className={classes.paddingTop}>
              <Grid
                container
                direction="column"
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.changeRequestReason] && (
                    <FormFieldWrapper
                      id="prc-proj-change-request-reason"
                      testId="prcProjChangeRequestReason"
                      type={formFields[formCodes.changeRequestReason].type}
                      value={formFields[formCodes.changeRequestReason].text}
                      inputProps={{
                        required: true,
                        endIconName: "arrow_drop_down",
                      }}
                      textProps={{ variant: "h4" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.changeRequestReason]
                        )
                      }
                      className={classes.inputWidth}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Divider */}
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="prc-edit-popover"
        testId="prcEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default ReportCategoryEditPage;
