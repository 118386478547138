import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AmoModal from "components/AmoModal";
import AmoTextField from "components/inputs/AmoTextField";
import { bankingInformationService } from "api/services/bankingInformationService";
import { useWrapApi } from "hooks/wrapApiHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useVerificationRequestHooks } from "pages/amo/banking-information-reviews/bankingInformationReviewsHooks";
import { DateTime } from "luxon";
import AmoRichTextEditor from "components/inputs/AmoRichTextEditor";
import { routes } from "constants/routes";
import NotificationPlaceholders from "../../pages/amo/notifications/NotificationPlaceholders";
import { amoPlaceholders } from "constants/amoPlaceholders";

const useStyles = makeStyles((theme) => ({
  textbox: {
    width: "100%",
    marginTop: "1rem",
  },
  radio: {
    marginTop: "1rem",
    marginLeft: "2rem",
  },
}));

/**
 * Modal to request banking information verification
 *
 * @param props
 * @param props.showModal
 * @param props.closeModal
 * @param props.bankingInformationReviews
 * @param props.bankingInformationReviewsRefetch
 * @param props.bankingInformationPlaceholders
 * @returns {React.Component} Modal component.
 */
const BankingRequestVerificationModal = (props) => {
  const classes = useStyles();

  const mounted = useIsMounted();

  const {
    showModal,
    closeModal,
    bankingInformationReviews,
    bankingInformationReviewsRefetch,
    bankingInformationPlaceholders,
  } = props;

  const [sendOption, setSendOption] = useState("sendToAll");
  const [generateWarning, setGenerateWarning] = useState(false);
  const [disableAllUnverified, setDisableAllUnverified] = useState(false);
  const [isLastRequestOld, setIsLastRequestOld] = useState(false);

  const {
    data: verificationRequest,
    refetch: refetchVerificationRequest,
  } = useVerificationRequestHooks();

  const postRequestVerification = useWrapApi(
    bankingInformationService.postRequestVerification,
    "Verification request emails queued"
  );

  const requestSchema = yup
    .object({
      emailSubject: yup.string().required(),
      emailBody: yup.string().required(),
    })
    .required();

  const defaultBody = `Hi ${amoPlaceholders.treasurerFirstName},
    <p>We expect to transfer funds from the Canada Community-Building Fund (CCBF) to your community in the coming weeks. Funds will be transferred electronically.</p>
    <p>Please <a href="${
      window.location.origin + routes.administration.bankingInformation
    }">log in</a> to the CCBF reporting site to confirm that we have the correct account number and contact information on file. We will not transfer funds to your community until account numbers and contact information have been verified.</p>
    <p>Feel free to contact us if you have any questions or concerns.</p>
    <p>AMO's CCBF Team <a href="mailto:ccbf@amo.on.ca">ccbf@amo.on.ca</a></p>`;

  const defaultValues = {
    subject: "Banking Information requested",
    body: defaultBody,
    toOption: false,
  };

  // unused variables will most likely need to be used in future tickets
  const {
    control,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(requestSchema),
  });

  useEffect(() => {
    const unverifiedMunicipalities = bankingInformationReviews?.filter(
      (review) => review.status === "Unverified"
    );
    setDisableAllUnverified(!unverifiedMunicipalities?.length > 0);
  }, [bankingInformationReviews]);

  const handleRadioChange = (event) => {
    setSendOption(event.target.value);
    setValue("toOption", event.target.value !== "sendToAll");
  };

  useEffect(() => {
    const cutoffDate = DateTime.now().minus({ months: 2 });

    if (verificationRequest) {
      const verificationRequestDate = DateTime.fromISO(
        verificationRequest.actionDate
      );
      setIsLastRequestOld(cutoffDate > verificationRequestDate);
      if (cutoffDate < verificationRequestDate) {
        setSendOption("sendToAllUnverified");
        setValue("toOption", true);
      }
    }
  }, [verificationRequest]);

  const handleRequest = async (isWarningModal) => {
    if (sendOption === "sendToAll") {
      setGenerateWarning(true);
      if (isWarningModal) {
        await postRequestVerification.call(getValues());

        if (!mounted.current) {
          return;
        }

        reset(defaultValues);
        bankingInformationReviewsRefetch();
      }
      closeModal();
    } else {
      const response = await postRequestVerification.call(getValues());

      if (!mounted.current) {
        return;
      }

      if (!response.error) {
        reset(defaultValues);
        bankingInformationReviewsRefetch();
        closeModal();
      }
    }
    refetchVerificationRequest();
  };

  const closeWarningModal = () => {
    setGenerateWarning(false);
  };

  const standardModal = () => (
    <AmoModal
      open={showModal}
      width="40rem"
      title="Request Verification for All"
      showButton
      closePopover={closeModal}
      buttons={[
        {
          key: "sendRequest",
          label: "Send Request",
          color: "primary",
          variant: "contained",
          onClick: () => handleRequest(false),
        },
        {
          key: "close",
          label: "Close",
          color: "primary",
          variant: "outlined",
          onClick: closeModal,
        },
      ]}
    >
      <Grid>
        <AmoTextField
          control={control}
          label="Email Subject"
          name="subject"
          variant="outlined"
          className={classes.textbox}
        />
        <div className={classes.textbox}>
          <NotificationPlaceholders
            title="The following keys can be used in the body of this email:"
            placeholders={bankingInformationPlaceholders}
          />
        </div>
        <div className={classes.textbox}>
          <AmoRichTextEditor
            control={control}
            name="body"
            label="Email body"
            setError={setError}
            clearErrors={clearErrors}
          />
        </div>
        <FormControl>
          <RadioGroup
            aria-labelledby="send-options-radio-group"
            name="send-options-radio-group"
            value={sendOption}
            onChange={handleRadioChange}
            className={classes.radio}
          >
            <FormControlLabel
              value="sendToAll"
              control={<Radio color="primary" />}
              label="Send to all"
            />
            <FormControlLabel
              value="sendToAllUnverified"
              control={<Radio color="primary" />}
              label="Send to all unverified"
              disabled={disableAllUnverified}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </AmoModal>
  );

  const warningModal = () => (
    <AmoModal
      open={generateWarning}
      width="32rem"
      title="Warning"
      showButton
      closePopover={closeWarningModal}
      buttons={[
        {
          key: "yesButton",
          label: "Yes",
          color: "primary",
          variant: "contained",
          onClick: () => {
            handleRequest(true);
            closeWarningModal();
            closeModal();
          },
        },
        {
          key: "noButton",
          label: "No",
          color: "secondary",
          variant: "contained",
          onClick: closeWarningModal,
        },
      ]}
    >
      <Typography>
        {`${
          !isLastRequestOld
            ? "You have recently requested verification for all municipalities."
            : ""
        } Are you
        sure you want to request verification for all municipalities ${
          !isLastRequestOld ? "again" : ""
        }?`}
      </Typography>
    </AmoModal>
  );

  if (!generateWarning) {
    return standardModal();
  }
  return warningModal();
};

BankingRequestVerificationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default BankingRequestVerificationModal;
