import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import { routes } from "constants/routes";
import {
  formIds,
  insuranceReportingCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
  },
  formDescriptionContent: {
    "& > p:first-child": {
      // 8px
      marginTop: "0.5rem",
    },
  },
}));

/**
 * A page component for editing "Insurance Reporting" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const InsuranceReportingEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.insuranceReporting
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Insurance Reporting"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyPadding
            )}
          >
            {/* Form Description */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.descriptionForm] && (
                    <FormFieldWrapper
                      id="ir-form-description"
                      testId="irFormDescription"
                      type={formFields[formCodes.descriptionForm].type}
                      value={formFields[formCodes.descriptionForm].text}
                      textProps={{ variant: "body1" }}
                      align="flex-start"
                      className={classes.formDescriptionContent}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionForm])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="ir-edit-popover"
        testId="irEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default InsuranceReportingEditPage;
