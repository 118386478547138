import React, { useEffect, useState } from "react";
import { Grid, Icon, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { filesService } from "api/services/fileService";
import FileUploadList from "components/FileUploadList";
import ProjectReviewFieldWrapper from "components/amo/ProjectReviewFieldWrapper";
import AmoTextField from "components/inputs/AmoTextField";
import { fileTypes, fileAssociations } from "constants/fileTypes";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { projectReportCommunicationsCodes } from "constants/formContentManagement";
import colors from "constants/colors";
import { useIsMounted } from "hooks/useIsMounted";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const useStyles = makeStyles(() => ({
  titleText: {
    fontSize: "1.5rem",
    fontWeight: 400,
  },
  containerGrid: {
    marginTop: "1.5rem",
    width: "100%",
  },
  blankImage: {
    borderRadius: 0,
    height: "9rem",
    backgroundColor: "#C4C4C4",
  },
  subtitleText: {
    fontSize: "0.875rem",
    fontWeight: 700,
  },
  linkText: {
    fontSize: "1rem",
  },
  image: {
    height: "9rem",
    cursor: "pointer",
  },
  imageMovie: {
    height: "9rem",
    width: "9rem",
    cursor: "pointer",
    fontSize: "9rem",
  },
  downloadAll: {
    color: colors.green.main,
    textDecoration: "none",
    justifyContent: "flex-end",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  },
  downloadAllIcon: {
    marginRight: "0.5rem",
  },
}));

/**
 * Component that returns media components for a project
 *
 * @param {object} props - object containing props for this component
 * @param {Array} files - array containing the media files linked to a project
 * @param {number} projectId - project ID
 * @param {string} links - provided links to the project
 * @param {boolean} isLoaded - indicates if the project has been loaded or not
 * @param {Function} refetchData - function that refetches project data after an upload/delete event
 * @param {Function} onSave - function that triggers after saving
 *
 * @returns - The Media Gallery Component
 */

const MediaGallery = (props) => {
  const { files, projectId, links, isLoaded, refetchData, onSave } = props;
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const mounted = useIsMounted();

  const [fileBlobs, setFileBlobs] = useState([]);

  const getFileData = async (id) => {
    try {
      const { data } = await filesService.getById(id);
      return data;
    } catch {
      if (mounted.current) {
        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
      return null;
    }
  };

  // gets file data from backend
  const prepareFiles = async () => {
    const dataArray = [];
    let results = [];
    const filesWithImages = files.filter(
      ({ name }) => name.split(".").reverse()[0] !== fileTypes.pdf
    );
    for (const fileData of filesWithImages) {
      const fileBlob = getFileData(fileData.id);
      results.push(fileBlob);
    }

    results = await Promise.all(results); // there was an linting rule that didn't allow await/async calls in a for loop so this step was done to avoid that error

    if (!mounted.current) {
      return;
    }

    filesWithImages.forEach((value, index) => {
      const fileObject = value;
      fileObject.blob = results[index];
      dataArray.push(fileObject);
    });

    setFileBlobs(dataArray);
  };

  const ProjectValidationSchema = yup
    .object({
      providedLinks: yup.string().nullable(),
    })
    .required();

  const defaultValues = {
    providedLinks: "",
  };

  const { control, reset } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possible onBlur) if very important for validation
    mode: "onChange",
    resolver: yupResolver(ProjectValidationSchema),
  });

  useEffect(() => {
    if (isLoaded && files.length > 0) {
      prepareFiles();
    }
  }, [isLoaded]);

  useEffect(() => {
    reset({ providedLinks: links });
  }, [links]);

  // function that allows users to download files on click
  const downloadFile = async (url, name) => {
    try {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch {
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const downloadAllFiles = async () => {
    try {
      const { data } = await filesService.getByProjectId(projectId);

      if (!mounted.current) {
        return;
      }

      // Download file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Project ${projectId} files.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  // resets file data and triggers refetch
  const resetFileData = (value) => {
    setFileBlobs([]);
    refetchData(value, true);
  };

  const loadingGallerySkeleton = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "0.5rem" }}>
        <Grid item xs={3}>
          <Skeleton width="9rem" height="9rem" variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton width="9rem" height="9rem" variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton width="9rem" height="9rem" variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton width="9rem" height="9rem" variant="rect" />
        </Grid>
      </Grid>
    </>
  );

  const loadingFilesSkeleton = () => (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "0.5rem" }}
        direction="column"
      >
        <Grid item>
          <Skeleton width="100%" height="4rem" variant="rect" />
        </Grid>
        <Grid item>
          <Skeleton width="100%" height="4rem" variant="rect" />
        </Grid>
        <Grid item>
          <Skeleton width="100%" height="4rem" variant="rect" />
        </Grid>
      </Grid>
    </>
  );

  const renderImages = () => {
    if (fileBlobs.length > 0) {
      return (
        <Grid
          container
          spacing={2}
          columns={12}
          style={{ marginTop: "0.5rem" }}
        >
          {fileBlobs.map((blobData) => {
            const isMovie = ["wmv", "avi", "mp4"].includes(
              blobData.name.split(".").pop()
            );
            const blobToURL = URL.createObjectURL(blobData.blob);

            return (
              <Grid item key={blobData.id}>
                {isMovie ? (
                  <Icon
                    color="primary"
                    className={classes.imageMovie}
                    onClick={() => downloadFile(blobToURL, blobData.name)}
                  >
                    movie
                  </Icon>
                ) : (
                  <input
                    type="image"
                    alt="media gallery image"
                    src={blobToURL}
                    className={classes.image}
                    onClick={() => downloadFile(blobToURL, blobData.name)}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      );
    }
    return null;
  };

  return (
    <Grid className={classes.containerGrid}>
      <Typography className={classes.titleText}>Media Gallery</Typography>
      {isLoaded ? renderImages() : loadingGallerySkeleton()}
      <Grid style={{ marginTop: "1rem" }}>
        <Grid container justifyContent="space-between" spacing={0}>
          <Grid item>
            <Typography className={classes.titleText}>File Upload</Typography>
          </Grid>
          {!!files?.length && (
            <Grid item>
              <Button
                className={classes.downloadAll}
                align="right"
                onClick={() => {
                  downloadAllFiles();
                }}
              >
                <Icon
                  className={clsx(
                    "material-icons-outlined",
                    classes.downloadAllIcon
                  )}
                  color="primary"
                >
                  file_download
                </Icon>
                <Typography align="right" variant="body1">
                  Download all
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        {isLoaded ? (
          <FileUploadList
            fileAssociationId={fileAssociations.Projects}
            fileAssociationObjectId={parseInt(projectId, 10)}
            initialValue={files}
            multiple
            allowedTypes={[
              fileTypes.pdf,
              fileTypes.jpg,
              fileTypes.jpeg,
              fileTypes.png,
              fileTypes.mov,
              fileTypes.avi,
              fileTypes.gif,
              fileTypes.mp4,
              fileTypes.wmv,
            ]}
            instructions="Upload images or videos of the project, including before and after photos."
            onChange={resetFileData}
            onUpload={() => {
              onSave();
            }}
            synchronized
          />
        ) : (
          loadingFilesSkeleton()
        )}
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        {isLoaded ? (
          <ProjectReviewFieldWrapper
            projectId={projectId}
            fieldCode={projectReportCommunicationsCodes.fieldLinks}
            id="commProvidedLinksWrapper"
            label="Provided Links"
            value={links}
            hideFlag
            hidePrevious
            renderInput={(value, setFieldValue) => (
              <AmoTextField
                multiline
                rows={1}
                rowsMax={5}
                control={control}
                id="providedLinks"
                name="providedLinks"
                testId="commProvidedLinksInput"
                label="Provided Links"
                required
                onChangeTrigger={(val) => setFieldValue(val)}
                fullWidth
              />
            )}
            onSave={() => {
              onSave();
              refetchData();
            }}
            onClose={() => reset()}
          />
        ) : (
          <>
            <Skeleton width="15%" height="1.75rem" />
            <Skeleton width="100%" height="1.75rem" variant="rect" />
          </>
        )}
      </Grid>
    </Grid>
  );
};

MediaGallery.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
  ),
  projectId: PropTypes.number,
  links: PropTypes.string,
  isLoaded: PropTypes.bool.isRequired,
  refetchData: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

MediaGallery.defaultProps = {
  files: [],
  projectId: null,
  links: null,
  onSave: () => {},
};

export default MediaGallery;
