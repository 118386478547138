import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Tabs, Tab, Box, Grid } from "@material-ui/core";
import colors from "constants/colors";
import ListPageWrapper from "components/ListPageWrapper";

import FAQs from "./FAQs";
import Pages from "./Pages";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";

const useStyles = makeStyles((theme) => ({
  titleText: {
    position: "relative",
    width: "100%",
    color: colors.black,
    paddingBottom: "1rem",
    borderBottom: "0.125rem #F1F1F1 solid",
  },
  tab: {
    marginLeft: "1rem",
    "& .MuiTab-root": {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textTransform: "none",
      padding: "0rem",
      marginRight: "3rem",
      maxWidth: "none",
      minWidth: "0rem",
      "&.Mui-selected": {
        color: colors.black,
      },
    },
  },
}));

/**
 * AMO Help Page.
 *
 * @returns {React.Component} AMO Help Page component.
 */
const AmoHelp = () => {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useConditionalQueryParams(
    "selectedTab",
    "string",
    "FAQs",
    true
  );

  const handleTabChange = (_event, newValue) => {
    setCurrentTab(newValue);
  };

  const tabsComponent = (
    <Tabs
      className={classes.tab}
      value={currentTab}
      onChange={handleTabChange}
      aria-label="pages-faqs-tabs"
      textColor="primary"
      indicatorColor="primary"
    >
      <Tab
        id="faqs-tab"
        label="FAQs"
        aria-controls="faqs-tab-tabpanel"
        tabIndex={0}
        value="FAQs"
      />
      <Tab
        id="pages-tab"
        label="Pages"
        aria-controls="pages-tab-tabpanel"
        tabIndex={0}
        value="Pages"
      />
    </Tabs>
  );

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="h1" className={classes.titleText}>
            Help Content Management
          </Typography>
        </Grid>
        <Grid item>
          <Box display={currentTab === "FAQs" ? "block" : "none"}>
            <FAQs tableTitleComponent={tabsComponent} />
          </Box>
          <Box display={currentTab === "Pages" ? "block" : "none"}>
            <Pages tableTitleComponent={tabsComponent} />
          </Box>
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default AmoHelp;
