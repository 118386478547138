import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import parse from "html-react-parser";

import AmoModal from "components/AmoModal";
import NameLink from "components/municipal/NameLink";
import SubmitReportAccordion from "pages/municipal/report/SubmitReportAccordion";

import { useMunicipalContext } from "contexts/MunicipalContext";
import { routes } from "constants/routes";
import { questionnaireTypes } from "constants/questionnaireTypes";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  titleText: {
    // 14px
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  issueText: {
    // 14px
    fontSize: "0.875rem",
    fontWeight: "bold",
    fontStyle: "italic",
  },
  issueContent: {
    // 8px
    paddingRight: "0.5rem",
    overflowY: "auto",
    overflowX: "hidden",
    // 560px
    maxHeight: "35rem",
  },
}));

/**
 * The reporting status modal to display report submission issues
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.open - controls visibility of the modal
 * @param {Function} props.onClose - function to handle the close modal functionality
 * @param {Function} props.onSubmit - function to handle the report submission functionality
 * @param {number} props.municipalityId - the id of the municipality related to the report submission
 * @param {number} props.year - the report submission year
 * @param {object[]} props.requirements - array of other requirements missing on the report submission
 * @param {object[]} props.projects - array of project requirements missing on the report submission
 *
 * @returns - the manage contact info modal, styled for the AMO project
 */

const ReportingStatusSubmissionModal = (props) => {
  const classes = useStyles();
  const { municipalities } = useMunicipalContext();

  const {
    open,
    onClose,
    onSubmit,
    municipalityId,
    year,
    requirements,
    projects,
  } = props;

  const municipalityFormalname =
    municipalities?.[municipalityId]?.formalName ?? "";
  const municipalityName = municipalities?.[municipalityId]?.name ?? "";
  const issuesDescription = `Please address the items below before submitting ${year}'s report for ${municipalityFormalname}.`;
  const submissionDescription = `<p>You're about to submit the ${year} report for ${municipalityFormalname}. This should only be done if:</p>
  <ul>
    <li>Municipal staff cannot submit the report themselves;</li>
    <li>Submission is required to proceed with the preparation of AMO's annual report;</li>
    <li>The report is – to the best of AMO's knowledge – accurate as it currently stands; and</li>
    <li>The Program Manager has approved the forced submission of this report.</li>
  </ul>
  <p><br></p>
  <p>Are you sure that you want to continue?</p>`;

  const requirementsColumns = [
    {
      fieldKey: "title",
      label: "Requirement",
      color: "primary",
      className: classes.titleText,
    },
    {
      fieldKey: "issue",
      label: "Issue",
      color: "secondary",
      className: classes.issueText,
    },
  ];
  const projectsColumns = [
    {
      fieldKey: "title",
      label: "Project",
      color: "primary",
      className: classes.titleText,
    },
    {
      fieldKey: "issue",
      label: "Issue",
      color: "secondary",
      className: classes.issueText,
    },
  ];

  const mappedRequirements =
    requirements?.map((issue) => {
      let link = "";
      if (issue.type === "InsuranceReporting") {
        link = `${routes.reviews.insurance.list}?filter_municipality=${municipalityName}-true`;
      } else if (issue.type === "FinancialReporting") {
        link = routes.reviews.financial.form
          .replace(":municipalityId", municipalityId)
          .replace(":year", year);
      } else if (issue.type === "AssetQuestionnaire") {
        link = routes.reviews.questionnaires.form
          .replace(":municipalityId", municipalityId)
          .replace(":year", year)
          .replace(":type", questionnaireTypes.assetMgmt);
      } else if (issue.type === "RiskQuestionnaire") {
        link = routes.reviews.questionnaires.form
          .replace(":municipalityId", municipalityId)
          .replace(":year", year)
          .replace(":type", questionnaireTypes.riskMgmt);
      }

      return {
        ...issue,
        title: <NameLink name={issue.title} link={link} removeSearch />,
      };
    }) ?? [];

  const mappedProjects =
    projects?.map((project) => {
      const title = project.title ?? "Untitled Project";
      return {
        ...project,
        title: project.id ? (
          <NameLink
            name={title}
            link={routes.reviews.projects.form.replace(":id", project.id)}
          />
        ) : (
          title
        ),
      };
    }) ?? [];

  const hasIssues = requirements?.length > 0 || projects?.length > 0;

  const submissionButtons = [
    {
      key: "yes",
      label: "Yes",
      color: "primary",
      variant: "contained",
      width: "15rem",
      marginLeft: "7rem",
      onClick: onSubmit,
    },
    {
      key: "no",
      label: "No",
      onClick: onClose,
      color: "secondary",
      variant: "outlined",
      marginLeft: "3rem",
      width: "15rem",
    },
  ];

  const issuesButtons = [
    {
      key: "close",
      label: "Close",
      color: "primary",
      variant: "contained",
      width: "20rem",
      marginLeft: "15rem",
      onClick: onClose,
    },
  ];

  return (
    <>
      <AmoModal
        data-testid="reportingStatusSubmissionIssuesModal"
        open={open}
        variant="default"
        title="Force Submission"
        width="50rem"
        showButton
        closePopover={onClose}
        buttonsLeftSpacer={0}
        buttons={hasIssues ? issuesButtons : submissionButtons}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          wrap="nowrap"
          className={classes.issueContent}
        >
          <Grid item>
            <Typography
              className={classes.fullWidth}
              variant="body1"
              component="span"
            >
              {parse(hasIssues ? issuesDescription : submissionDescription)}
            </Typography>
          </Grid>
          {hasIssues && requirements?.length > 0 && (
            <Grid item>
              <SubmitReportAccordion
                title="Annual Reporting Requirements"
                data={mappedRequirements}
                columns={requirementsColumns}
              />
            </Grid>
          )}
          {hasIssues && projects?.length > 0 && (
            <Grid item>
              <SubmitReportAccordion
                title="Project Reporting Requirements"
                data={mappedProjects}
                columns={projectsColumns}
              />
            </Grid>
          )}
        </Grid>
      </AmoModal>
    </>
  );
};

ReportingStatusSubmissionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  municipalityId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape()),
  projects: PropTypes.arrayOf(PropTypes.shape()),
};

ReportingStatusSubmissionModal.defaultProps = {
  requirements: [],
  projects: [],
};

export default ReportingStatusSubmissionModal;
