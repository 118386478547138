import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import AmoLocationPicker from "components/AmoLocationPicker";
import { patterns } from "constants/regex";

/**
 * This component is a customized AmoLocationPicker, built to work with a react-hook-form (RHF) controlled form.
 *
 * To use this and other RHF input components, make sure that the RHF controller object is being passed in from the parent component.
 *
 * The controller object comes from the RHF useForm hook, and it allows the input to be registered, controlled, and validated.
 *
 * @param {object} props
 * @param {object} props.control    The react-hook-form control, generated by useForm()
 * @param {string} props.label      Label text for the component
 * @param {string} props.id         Field ID
 * @param {string} props.name       The name of the input; react-hook-form uses this to register the input
 * @param {string} props.testId     Maps to data-test-id; renamed on AMO component to avoid issues with targeting tests (maybe unnecessary)
 * @param {boolean} props.required  Sets the required flag for this input (prefer handling this via a yup schema in most cases)
 * @param {boolean} props.warning  Controls whether the textfield input display warning style or not
 * @param {boolean} props.disabled - controls whether textfield and button will be disabled
 * @param {string} props.helperText - green helper text of the input shown when the input is touched
 * @param {Function} props.onChangeTrigger   Event that triggers on input change
 *
 * @returns {Function} A MUI KeyboardDatePicker component wrapped with the react-hook-form controller
 */
const AmoLocationPickerControl = ({
  control,
  name,
  id,
  testId,
  label,
  required,
  warning,
  disabled,
  helperText,
  onChangeTrigger,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [valueHasChanged, setValueHasChanged] = useState(false);

  const isDifferentLocation = (newLocation, previousLocation) =>
    previousLocation?.address !== newLocation?.address ||
    previousLocation?.position?.lat !== newLocation?.position?.lat ||
    previousLocation?.position?.lng !== newLocation?.position?.lng;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <AmoLocationPicker
          id={id}
          testId={testId ?? `${id}-test`}
          label={label}
          required={required}
          address={value?.address ?? ""}
          position={value?.position ?? null}
          warning={warning}
          helperText={error?.message ?? (isFocused ? helperText : undefined)}
          error={!!error}
          disabled={disabled}
          onSelect={(newAddress, newPosition) => {
            const changedValue = {
              ...(value ?? {}),
              address: newAddress,
              position: newPosition,
            };
            const locationHasChanged = isDifferentLocation(value, changedValue);
            onChange(changedValue);
            if (locationHasChanged) onChangeTrigger(changedValue);
            setValueHasChanged(false);
          }}
          onInputChange={(newValue) => {
            const { position: oldPosition, ...valueWithoutPosition } =
              value ?? {};
            let position = null;
            if (new RegExp(patterns.coordinate).test(newValue)) {
              const coordSplit = newValue.replaceAll(" ", "").split(",");
              position = {
                lat: +coordSplit[0],
                lng: +coordSplit[1],
              };
            }
            const changedValue = {
              ...valueWithoutPosition,
              address: newValue,
              position,
            };
            setValueHasChanged(isDifferentLocation(value, changedValue));
            onChange(changedValue);
          }}
          onAutocompleteFocus={() => setIsFocused(true)}
          onAutocompleteBlur={(event) => {
            setIsFocused(false);
            onBlur();
            if (valueHasChanged) {
              onChangeTrigger(value);
            }
            setValueHasChanged(false);
          }}
        />
      )}
    />
  );
};

AmoLocationPickerControl.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  warning: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  onChangeTrigger: PropTypes.func,
};

AmoLocationPickerControl.defaultProps = {
  testId: undefined,
  label: "",
  required: false,
  warning: false,
  disabled: false,
  helperText: null,
  onChangeTrigger: () => {},
};

export default AmoLocationPickerControl;
