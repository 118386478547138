import { Api } from "../api";
import { AxiosResponse } from "axios";

const api = new Api("api/amo/landing");

/**
 * @typedef {{
 *  openingBalance: number,
 *  closingBalance: number,
 *  expenditures: number,
 *  fiveYearBankingLimitCompliance: number,
 *  incrementalityCompliance: number,
 *  nextScheduledPayment: string,
 *  revenues: number,
 *  totalMunicipalities: number,
 *  transfers: number }} FinancialsOverview
 */

export const amoFinancialOverviewService = {
  /**
   * @param {number} year
   * @returns {Promise<AxiosResponse<FinancialsOverview[]>>}
   */
  getFinancialOverview: (year) => api.getById(`financials/${year}`),
};
