import React, { useState, useEffect } from "react";
import colors from "../../constants/colors";
import { DateTime } from "luxon";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Divider,
  ButtonBase,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import FiveYearBankingLimitModal from "./financialStatus/FiveYearBankingLimitModal";
import IncrementalityComplianceModal from "./financialStatus/IncrementalityComplianceModal";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { formIds } from "constants/formContentManagement";
import { financialStatusService } from "api/services/financialStatusService";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { formatToCurrency } from "utils/number";
import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useYearContext } from "contexts/YearContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "0.625rem 0 0 0",
  },
  title: {
    padding: "0 0 0 0.5rem",
  },
  year: {
    marginLeft: "auto",
    padding: "0 1rem 0 0",
  },
  content: {
    width: "100%",
    marginTop: "0.84rem",
  },
  paper: {
    color: colors.black,
    width: "100%",
    height: "100%",
  },
  outlined: {
    border: true,
    borderColor: colors.grey.main,
  },
  rounded: {
    borderRadius: "0.625rem",
    padding: "0.625rem",
  },
  itemTextDisplay: {
    color: colors.green.dark,
    padding: "0 0 0 0",
  },
  itemTextDisplayCenter: {
    display: "flex",
    color: colors.green.main,
    padding: "0 0 0 0",
    justifyContent: "center",
    alignItems: "center",
  },
  itemValueDisplay: {
    marginLeft: "auto",
    padding: "0 0 0 0",
  },
  outerGrid: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    padding: "0.5rem 0 0 1rem",
  },
  financialsButton: {
    "&:focus": {
      outline: "2px solid black",
      borderRadius: "2px",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  shimmer: {
    width: "10rem",
  },
  iconShimmer: {
    width: "3rem",
    height: "3rem",
  },
}));

/**
 * Displays financial status information.
 *
 * @returns {React.Component} The Financial Status widget.
 */
const FinancialStatusWidget = () => {
  const classes = useStyles();

  const mounted = useIsMounted();

  const [isFiveYearModalOpen, setIsFiveYearModelOpen] = useState(false);
  const [isIncrementalityModalOpen, setIsIncrementalityModalOpen] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(true);
  const [
    {
      openingBalance,
      revenues,
      expenditures,
      transfers,
      closingBalance,
      nextPayment: { date: nextPaymentDate, amount: nextPaymentAmount },
      incrementality,
      fiveYearBankLimit,
    },
    setFinancials,
  ] = useState({ nextPayment: {} });
  const { selectedMunicipalityId } = useMunicipalContext();
  const { contextYear } = useYearContext();
  const { showSnackbar } = useSnackbar();
  const { formFields } = useFormManagement(formIds.municipalLandingPage, true);

  useEffect(() => {
    const fetchFinancials = async () => {
      setIsLoading(true);
      try {
        const response = await financialStatusService.getById(
          selectedMunicipalityId
        );

        if (!mounted.current) {
          return;
        }

        setFinancials(response.data);
      } catch (error) {
        if (!mounted.current) {
          return;
        }

        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
      setIsLoading(false);
    };

    if (selectedMunicipalityId) fetchFinancials();
  }, [selectedMunicipalityId]);

  const showHideFiveYearPopup = () => {
    setIsFiveYearModelOpen(!isFiveYearModalOpen);
  };

  const showHideIncrementalityPopup = () => {
    setIsIncrementalityModalOpen(!isIncrementalityModalOpen);
  };

  const formatToCurrencyOrBlank = (value) =>
    !isNaN(parseFloat(value)) ? formatToCurrency(value) : "TBD";

  const formatDateOrBlank = (value) =>
    value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL) : "TBD";

  const renderFinancialStatusIcon = (statusRef, testId) => {
    if (isLoading) {
      return <Skeleton className={classes.iconShimmer} />;
    }

    if (statusRef[0] === "In Compliance" || statusRef[1] === "Yes") {
      return (
        <CheckCircleOutlineOutlinedIcon
          data-testid={testId}
          color="primary"
          style={{ fontSize: "3rem" }}
        />
      );
    }

    return (
      <ReportProblemOutlinedIcon
        data-testid={testId}
        color="secondary"
        style={{ fontSize: "3rem" }}
      />
    );
  };

  return (
    <Paper
      square={false}
      variant="outlined"
      classes={{
        root: classes.paper,
        rounded: classes.rounded,
        outlined: classes.outlined,
      }}
    >
      <Box>
        <Grid
          container
          direction="column"
          spacing={2}
          classes={{ root: classes.outerGrid }}
        >
          <Grid
            container
            direction="row"
            classes={{
              root: classes.header,
            }}
          >
            <Grid
              item
              classes={{
                root: classes.title,
              }}
            >
              <Typography variant="h3">
                <Box color={colors.green.dark} data-testid="headerText">
                  Financial Information
                </Box>
              </Typography>
            </Grid>
            <Grid
              item
              classes={{
                root: classes.year,
              }}
            >
              <Box color={colors.green.dark}>
                <Typography variant="h4" data-testid="headerYear">
                  {contextYear}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            spacing={1}
            classes={{ root: classes.content }}
          >
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.itemTextDisplay }}>
                <Typography
                  variant="body1"
                  component="span"
                  data-testid="openingText"
                >
                  Opening Balance
                </Typography>
              </Grid>
              <Grid
                item
                classes={{ root: classes.itemValueDisplay }}
                data-testid="openingAmount"
              >
                {isLoading ? (
                  <Skeleton className={classes.shimmer} />
                ) : (
                  formatToCurrencyOrBlank(openingBalance)
                )}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid
                item
                classes={{ root: classes.itemTextDisplay }}
                data-testid="revenueText"
              >
                <Typography variant="body1" component="span">
                  Revenues (Including Receivables)
                </Typography>
              </Grid>
              <Grid
                item
                classes={{ root: classes.itemValueDisplay }}
                data-testid="revenueAmount"
              >
                {isLoading ? (
                  <Skeleton className={classes.shimmer} />
                ) : (
                  formatToCurrencyOrBlank(revenues)
                )}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid
                item
                classes={{ root: classes.itemTextDisplay }}
                data-testid="expenditureText"
              >
                <Typography variant="body1" component="span">
                  Expenditures
                </Typography>
              </Grid>
              <Grid
                item
                classes={{ root: classes.itemValueDisplay }}
                data-testid="expenditureAmount"
              >
                {isLoading ? (
                  <Skeleton className={classes.shimmer} />
                ) : (
                  formatToCurrencyOrBlank(expenditures)
                )}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid
                item
                classes={{ root: classes.itemTextDisplay }}
                data-testid="transferText"
              >
                <Typography variant="body1" component="span">
                  Transfers
                </Typography>
              </Grid>
              <Grid
                item
                classes={{ root: classes.itemValueDisplay }}
                data-testid="transferAmount"
              >
                {isLoading ? (
                  <Skeleton className={classes.shimmer} />
                ) : (
                  formatToCurrencyOrBlank(transfers)
                )}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid
                item
                classes={{ root: classes.itemTextDisplay }}
                data-testid="closingBalance"
              >
                <Typography variant="body1" component="span">
                  Closing Balance
                </Typography>
              </Grid>
              <Grid
                item
                classes={{ root: classes.itemValueDisplay }}
                data-testid="closingAmount"
              >
                <Typography variant="h3" component="span">
                  {isLoading ? (
                    <Skeleton className={classes.shimmer} />
                  ) : (
                    formatToCurrencyOrBlank(closingBalance)
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Divider />
          <Grid
            item
            container
            direction="column"
            spacing={1}
            classes={{ root: classes.content }}
          >
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.itemTextDisplay }}>
                <Typography
                  variant="body1"
                  component="span"
                  data-testid="nextPaymentText"
                >
                  Next Scheduled Transfer
                </Typography>
              </Grid>
              <Grid
                item
                classes={{ root: classes.itemValueDisplay }}
                data-testid="nextPaymentDate"
              >
                {isLoading ? (
                  <Skeleton className={classes.shimmer} />
                ) : (
                  formatDateOrBlank(nextPaymentDate)
                )}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid
                item
                classes={{ root: classes.itemTextDisplay }}
                data-testid="paymentAmountText"
              >
                <Typography variant="body1" component="span">
                  Amount
                </Typography>
              </Grid>
              <Grid
                item
                classes={{ root: classes.itemValueDisplay }}
                data-testid="paymentAmount"
              >
                {isLoading ? (
                  <Skeleton className={classes.shimmer} />
                ) : (
                  formatToCurrencyOrBlank(
                    nextPaymentDate ? nextPaymentAmount : null
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            item
            container
            direction="column"
            classes={{ root: classes.content }}
          >
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.itemTextDisplayCenter }}>
                <ButtonBase
                  onClick={showHideFiveYearPopup}
                  data-testid="fiveYearButton"
                  className={classes.financialsButton}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    data-testid="fiveYearText"
                  >
                    5-year Banking Limit Status
                  </Typography>
                </ButtonBase>
              </Grid>
              <Grid item classes={{ root: classes.itemValueDisplay }}>
                {renderFinancialStatusIcon(
                  [fiveYearBankLimit?.status, fiveYearBankLimit?.exemption],
                  "fiveYearIcon"
                )}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid item classes={{ root: classes.itemTextDisplayCenter }}>
                <ButtonBase
                  onClick={showHideIncrementalityPopup}
                  data-testid="incrementalityButton"
                  className={classes.financialsButton}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    data-testid="incrementalityText"
                  >
                    Incrementality Compliance
                  </Typography>
                </ButtonBase>
              </Grid>
              <Grid item classes={{ root: classes.itemValueDisplay }}>
                {renderFinancialStatusIcon(
                  [incrementality?.status],
                  "incrementalityIcon"
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <FiveYearBankingLimitModal
        isFiveYearOpen={isFiveYearModalOpen}
        setFiveYearOpen={showHideFiveYearPopup}
        data={fiveYearBankLimit}
        formFields={formFields}
      />
      <IncrementalityComplianceModal
        isIncrementalityOpen={isIncrementalityModalOpen}
        setIncrementalityOpen={showHideIncrementalityPopup}
        data={incrementality}
        formFields={formFields}
      />
    </Paper>
  );
};

export default FinancialStatusWidget;
