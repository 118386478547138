import React, { useState } from "react";
import PropTypes from "prop-types";
import AmoLocationAutocomplete from "./AmoLocationAutocomplete";
import AmoLocationPickerModal from "./AmoLocationPickerModal";

/**
 * A location picker component to fill a position by address or map click
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the component [required]
 * @param {string} props.testId - data-testid of the component
 * @param {string} props.label - label of the textfield input
 * @param {string} props.address - address of the autocomplete input
 * @param {object} props.position - object containing position data
 * @param {number} props.position.lat - latitude of the position
 * @param {number} props.position.lng - longitude of the position
 * @param {boolean} props.required - controls whether the textfield input is required or not
 * @param {boolean} props.warning - controls whether the textfield input display warning style or not
 * @param {string} props.helperText - helperText of the textfield input
 * @param {boolean} props.error - error state of the textfield input
 * @param {Function} props.onSelect - function called when user selects an address on the autocomplete list (params: { address, position })
 * @param {Function} props.onInputChange - function called when user textfield input changes (params: { value })
 * @param {boolean} props.disabled - controls whether textfield and button will be disabled
 * @param {Function} props.onAutocompleteFocus - function called when user focus on autocomplete input (params: none)
 * @param {Function} props.onAutocompleteBlur - function called when user blur autocomplete input (params: none)
 *
 * @returns {React.Component} The location picker component
 */
const AmoLocationPicker = (props) => {
  const {
    id,
    testId,
    label,
    address,
    position,
    required,
    warning,
    helperText,
    error,
    onSelect,
    onInputChange,
    disabled,
    onAutocompleteFocus,
    onAutocompleteBlur,
  } = props;

  const [openModal, setModalOpen] = useState(false);

  const handleModalSelect = (newAddress, latLng) => {
    setModalOpen(false);
    onSelect(newAddress, latLng);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <AmoLocationAutocomplete
        id={`${id}-picker-autocomplete`}
        testId={`${testId || id}PickerAutocomplete`}
        label={label}
        inputValue={address}
        required={required}
        warning={warning}
        helperText={helperText}
        error={error}
        onInputChange={onInputChange}
        onSelect={onSelect}
        disabled={disabled}
        onAdornmentClick={() => setModalOpen(true)}
        onFocus={onAutocompleteFocus}
        onBlur={onAutocompleteBlur}
      />
      {openModal && (
        <AmoLocationPickerModal
          id={`${id}-modal`}
          testId={`${testId || id}Modal`}
          open={openModal}
          initialAddress={address}
          initialPosition={position}
          onSelect={handleModalSelect}
          onCancel={handleModalClose}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

// set the prop-types for this component
AmoLocationPicker.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  label: PropTypes.string,
  address: PropTypes.string,
  position: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  required: PropTypes.bool,
  warning: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  onAutocompleteFocus: PropTypes.func,
  onAutocompleteBlur: PropTypes.func,
};

AmoLocationPicker.defaultProps = {
  testId: undefined,
  label: "",
  address: "",
  position: undefined,
  required: false,
  warning: false,
  helperText: "",
  error: false,
  onSelect: () => {},
  onInputChange: () => {},
  disabled: false,
  onAutocompleteFocus: () => {},
  onAutocompleteBlur: () => {},
};

export default AmoLocationPicker;
