import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import clsx from "clsx";
import parse from "html-react-parser";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import AmoTooltip from "components/AmoTooltip";
import AmoTextField from "components/inputs/AmoTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  formIds,
  projectReportResultsCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { outcomeOptions, unitDataOptions } from "constants/propTypes";
import {
  calculationTypes,
  getDecimals,
  unitCategoryTypes,
  lengthUnitTypes,
  sortComparator,
  standardIndicatorUnitTypeId,
} from "constants/indicatorConstants";
import {
  changeCalculations,
  clearCalculations,
  calculateRawChange,
} from "utils/changeCalculations";
import {
  localRoadsCategoryId,
  localRoadsSubcategoryId,
} from "constants/projectReport";
import { stableSort } from "utils/data";

const useStyles = makeStyles((theme) => ({
  contentCol: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    marginTop: "0rem",
  },
  textRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  tooltipContainer: {
    marginLeft: "0.5rem",
    marginTop: "0.25rem",
  },
  inputContainer: {
    width: "20rem",
  },
  changeContainer: {
    marginTop: "0.1rem",
    marginLeft: "0.25rem",
    "& .MuiInputBase-input": {
      padding: 0,
      height: "0.8rem",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  afterContainer: {
    marginTop: "1rem",
  },
  unitContainer: {
    width: "12.5rem",
    marginLeft: "2rem",
  },
  menuContainer: {},
  otherResults: {
    marginTop: "4rem",
  },
  numericRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    flexDirection: "row",
  },
  selectBox: {
    minWidth: "100px",
  },
  changeText: {
    marginTop: "1rem",
    fontWeight: "bold",
    fontSize: "0.875rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

/**
 * A form component for editing Project Report Financials
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.disabled - allows inputs to be disabled when reporting year doesn't match end date
 * @param {object} props.outcomeData - object containing outcome data
 * @param {object} props.outcomeSchema - yup schema for the outcome form
 * @param {object} props.defaultValues - default values for the outcome form
 * @param {Function} props.validateOutcome - function that determines if the outcome form is valid or not
 * @param {object} props.unitData - array that holds indicator unit type data
 * @param {object} props.prevFormValues - object that holds the previous form value before a save event
 * @param {Function} props.dirtyFieldHook - hook that returns the forms dirtyFields to the parent component
 * @param {string} props.secondaryInputs - object that holds the noOutputsApply and otherResults values
 * @param {Function} props.onFormRender - function called when the project outputs form is rendered (params: RHF utils)
 *
 * @returns - Project Outputs form component
 */
const ProjectOutcomes = (props) => {
  const {
    disabled,
    outcomeData,
    outcomeSchema,
    defaultValues,
    validateOutcome,
    unitData,
    prevFormValues,
    dirtyFieldHook,
    secondaryInputs,
    onFormRender,
  } = props;
  const { formFields } = useFormManagement(formIds.projectReportResults);
  const classes = useStyles();

  const [unitGroups, setUnitGroups] = useState(null);
  const [currentCalcType, setCurrentCalcType] = useState(null);
  const [loadedDefaultValues, setLoadedDefaultValues] = useState(null);
  const [formWasReseted, setFormWasReseted] = useState(false);
  const previousFormFields = useRef(null);

  const {
    control: outcomeControl,
    getValues: getOutcomeFormValues,
    formState: outcomeFormState,
    setValue: setOutcomeValues,
    watch: outcomeWatch,
    reset: resetOutcomeValues,
    resetField: resetOutcomeField,
    trigger: triggerOutcomeValidation,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(outcomeSchema),
  });

  const { isValid, isDirty, dirtyFields } = outcomeFormState;

  const setcalcWatchList = () => {
    const defaultKeys = Object.keys(defaultValues);
    const watchList = [];
    for (const keys in defaultKeys) {
      if (defaultKeys[keys].includes("-after")) {
        watchList.push(defaultKeys[keys].split("-after")[0], defaultKeys[keys]);
      }
    }

    return watchList;
  };

  const watchFields = outcomeWatch(setcalcWatchList());

  const renderData = () =>
    stableSort(outcomeData, sortComparator).map((returnData) => {
      const savedData =
        returnData.savedData.id !== null ? returnData.savedData : null;
      const saveId = savedData !== null ? savedData.id : "new";
      const currentUnitId = outcomeWatch(
        `indicator-${returnData.id}-${saveId}-unit`
      );
      return (
        <Grid
          key={savedData !== null ? savedData.id : returnData.id}
          style={{ marginTop: "2rem" }}
        >
          <Grid style={{ marginBottom: "1rem" }}>
            <Box className={classes.textRow}>
              <Typography variant="h7">{returnData.description}</Typography>
              {returnData.tooltipText !== null && (
                <Box className={classes.tooltipContainer}>
                  <AmoTooltip
                    tooltipTitle={null}
                    tooltipText={returnData.tooltipText}
                  />
                </Box>
              )}
            </Box>
            {formType(returnData, saveId, currentUnitId)}
          </Grid>
        </Grid>
      );
    });

  const handleClearUnitDropdown = (fieldName, value = "") => {
    setOutcomeValues(`${fieldName}-unit`, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    triggerOutcomeValidation([
      fieldName,
      `${fieldName}-after`,
      `${fieldName}-multiplier`,
    ]);
  };

  // renders the different form types depending on the the input type provided
  const formType = (indicatorData, saveId, currentUnitId) => {
    const indicatorName = indicatorData.indicatorType;
    const indicatorFormName = `indicator-${indicatorData.id}-${saveId}`;

    const currentUnitGroup = indicatorData.indicatorUnitTypeId
      ? unitGroups[`${indicatorFormName}-unit`]
      : null;

    const isInvalidUnit =
      currentUnitGroup &&
      !currentUnitGroup.map((item) => item.id).includes(currentUnitId);

    const isLocalRoadsLength =
      indicatorData.categoryId === localRoadsCategoryId &&
      indicatorData.subcategoryId === localRoadsSubcategoryId &&
      indicatorData.indicatorUnitTypeId === unitCategoryTypes.length;

    const showLanesField =
      !isInvalidUnit &&
      isLocalRoadsLength &&
      currentUnitId !== lengthUnitTypes.laneKilometres;

    switch (indicatorName) {
      case "Checkbox":
        return (
          <AmoCheckbox
            control={outcomeControl}
            disabled={disabled}
            id={`${indicatorFormName}-outcome`}
            name={indicatorFormName}
            color="primary"
          />
        );

      case "Numeric":
        return (
          <>
            <Box className={classes.numericRow}>
              <AmoTextField
                control={outcomeControl}
                disabled={disabled}
                id={`${indicatorFormName}-outcome`}
                name={indicatorFormName}
                className={classes.inputContainer}
                placeholder={indicatorData.isBeforeAfter ? "Before" : "Value"}
                onChangeTrigger={() =>
                  triggerOutcomeValidation([
                    `${indicatorFormName}-after`,
                    `${indicatorFormName}-unit`,
                  ])
                }
                onFocus={() =>
                  setCurrentCalcType({
                    fieldName: indicatorFormName,
                    calcType: indicatorData.calculationTypeCode,
                  })
                }
                numberFormatProps={{
                  allowNegative: false,
                  decimalScale: getDecimals(indicatorData.validationTypeName),
                }}
                characterLimit="18"
                hideCharacterCount
              />
              {showLanesField && (
                <AmoTextField
                  disabled={disabled}
                  control={outcomeControl}
                  id={`${indicatorFormName}-multiplier`}
                  name={`${indicatorFormName}-multiplier`}
                  className={classes.unitContainer}
                  numberFormatProps={{
                    allowNegative: false,
                    decimalScale: 0,
                  }}
                  label="Number of Lanes"
                />
              )}
              {currentUnitGroup !== null &&
                indicatorData.indicatorUnitTypeId !==
                  unitCategoryTypes.numeric && (
                  <AmoTextField
                    control={outcomeControl}
                    disabled={disabled}
                    select
                    id={`${indicatorFormName}-unit-outcome`}
                    className={classes.unitContainer}
                    name={`${indicatorFormName}-unit`}
                    SelectProps={{ classes: { select: classes.selectBox } }}
                    label="Unit"
                    onChangeTrigger={() =>
                      triggerOutcomeValidation([
                        indicatorFormName,
                        `${indicatorFormName}-after`,
                        `${indicatorFormName}-multiplier`,
                      ])
                    }
                    InputProps={{
                      endAdornment:
                        currentUnitId && !disabled ? (
                          <InputAdornment position="start">
                            <IconButton
                              id={`${indicatorFormName}-unit-clear`}
                              data-testid={`${indicatorFormName}-unitClearTest`}
                              size="small"
                              onClick={() =>
                                handleClearUnitDropdown(indicatorFormName)
                              }
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                  >
                    {currentUnitGroup
                      .filter(
                        (unit) =>
                          isLocalRoadsLength ||
                          unit.id !== lengthUnitTypes.laneKilometres
                      )
                      .map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}
                  </AmoTextField>
                )}
            </Box>
            {indicatorData.isBeforeAfter && (
              <>
                <AmoTextField
                  disabled={disabled}
                  control={outcomeControl}
                  id={`${indicatorFormName}-outcome-after`}
                  name={`${indicatorFormName}-after`}
                  className={clsx(
                    classes.inputContainer,
                    classes.afterContainer
                  )}
                  onFocus={() =>
                    setCurrentCalcType({
                      fieldName: indicatorFormName,
                      calcType: indicatorData.calculationTypeCode,
                    })
                  }
                  placeholder="After"
                  onChangeTrigger={() =>
                    triggerOutcomeValidation([
                      indicatorFormName,
                      `${indicatorFormName}-unit`,
                    ])
                  }
                  numberFormatProps={{
                    allowNegative: false,
                    decimalScale: getDecimals(indicatorData.validationTypeName),
                  }}
                  characterLimit="18"
                  hideCharacterCount
                />

                <Typography className={classes.changeText} component="div">
                  Change:
                  <AmoTextField
                    control={outcomeControl}
                    id={`${indicatorFormName}-change-outcome`}
                    name={`${indicatorFormName}-change`}
                    className={classes.changeContainer}
                    disabled
                  />
                </Typography>
              </>
            )}
          </>
        );

      case "List":
        return (
          <AmoTextField
            control={outcomeControl}
            disabled={disabled}
            select
            id={`${indicatorFormName}-outcome`}
            name={indicatorFormName}
            className={classes.inputContainer}
          >
            {indicatorData.indicatorValues.map((value) => (
              <MenuItem
                key={`${value}-${indicatorData.id}`}
                value={value}
                className={classes.menuContainer}
              >
                {value}
              </MenuItem>
            ))}
          </AmoTextField>
        );

      default:
        return null;
    }
  };

  // creates the proper unit dropdown array for each input associated with a unit type
  const setUnitData = () => {
    const unitArrayGroups = {};

    for (const indicator of outcomeData) {
      const savedData =
        indicator.savedData.id !== null ? indicator.savedData : null;
      const unitTypeId = indicator.indicatorUnitTypeId;
      const saveId = savedData !== null ? savedData.id : "new";

      if (unitTypeId !== null) {
        const indicatorName = `indicator-${indicator.id}-${saveId}-unit`;
        const unitArray = [];
        let referenceCategoryId = null;

        const customUnit = unitData.find(
          (unit) => unit.indicatorId === indicator.id
        );
        if (customUnit && indicator.indicatorUnitTypeId === 5) {
          unitArray.push(customUnit);
        } else {
          for (const unitType of unitData) {
            if (
              unitType.indicatorUnitCategoryTypeId === unitTypeId &&
              referenceCategoryId === null
            ) {
              referenceCategoryId = unitType.indicatorUnitCategoryTypeId;
              break;
            }
          }

          for (const unitType of unitData) {
            if (
              unitType.indicatorUnitCategoryTypeId === referenceCategoryId &&
              !unitType.indicatorId
            ) {
              unitArray.push(unitType);
            }
          }
        }
        unitArrayGroups[indicatorName] = stableSort(unitArray, sortComparator);
      }
    }

    setUnitGroups(unitArrayGroups);
  };

  // if the form has been edited already, this function will repopulate the rest form with the pre-existing values
  // this occurs after the form has been reset after an Additional Category has been added
  const populateRetainedForm = (prevFormValuesRef) => {
    const hasPreviousValues =
      prevFormValuesRef && Object.keys(prevFormValuesRef).length > 0;
    if (!hasPreviousValues) return;

    const valueKeys = Object.keys(getOutcomeFormValues());
    Object.entries(prevFormValuesRef).forEach((values) => {
      if (valueKeys.includes(values[0])) {
        setOutcomeValues(values[0], values[1], {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });
  };

  // sets dirty fields on previously saved inputs when they are loaded
  const loadSavedData = () => {
    const outcomeValues = {
      ...defaultValues,
      otherResults: secondaryInputs,
    };

    for (const data of outcomeData) {
      if (!data.savedData?.id) {
        continue;
      }

      const inputName = `indicator-${data.id}-${data.savedData.id}`;

      if (data.isBeforeAfter) {
        outcomeValues[inputName] = data.savedData?.beforeValue ?? "";
        outcomeValues[`${inputName}-after`] = data.savedData?.afterValue ?? "";
        outcomeValues[`${inputName}-change`] =
          calculateRawChange(
            data.calculationTypeCode,
            data.savedData?.beforeValue,
            data.savedData?.afterValue,
            true
          )?.toString() ?? "";
      } else {
        outcomeValues[inputName] =
          data.indicatorType === "Checkbox"
            ? { false: false, true: true }[data.savedData?.outcomeValue] ?? ""
            : data.savedData?.outcomeValue ?? "";
      }
      outcomeValues[`${inputName}-multiplier`] = data.savedData.multiplier;

      if (data.indicatorUnitTypeId !== null) {
        const unitTypeIds = unitData
          .filter(
            (unit) =>
              unit.indicatorUnitCategoryTypeId === data.indicatorUnitTypeId
          )
          .map((obj) => obj.id);
        outcomeValues[`${inputName}-unit`] =
          unitTypeIds.includes(data.savedData?.unitTypeId) &&
          data.standardIndicatorUnitTypeId !==
            standardIndicatorUnitTypeId.custom
            ? data.savedData?.unitTypeId
            : "";
      }
    }

    if (Object.keys(prevFormValues ?? {}).length > 0) {
      previousFormFields.current = prevFormValues;
    }
    setLoadedDefaultValues(outcomeValues);
    resetOutcomeValues(outcomeValues);
    setFormWasReseted(true);
  };

  useEffect(() => {
    if (formWasReseted) {
      setFormWasReseted(false);
      triggerOutcomeValidation();
      if (previousFormFields.current) {
        populateRetainedForm(previousFormFields.current);
        previousFormFields.current = null;
      }
    }
  }, [formWasReseted]);

  useEffect(() => {
    onFormRender(getOutcomeFormValues);
    setUnitData();
    loadSavedData();
  }, []);

  // checks for changes in the beforeAfter fields and calculates the changes between then
  useEffect(() => {
    const dirtyFieldKeys = Object.keys(dirtyFields);
    const cleanFieldKeys = setcalcWatchList().filter(
      (key) => !dirtyFieldKeys.includes(key)
    );

    // Clear calculations when the fields are empty
    for (const key of cleanFieldKeys) {
      clearCalculations(
        key,
        getOutcomeFormValues,
        resetOutcomeField,
        loadedDefaultValues
      );
    }

    // Fill calculations according to field values
    for (const key of dirtyFieldKeys) {
      if (key.includes(currentCalcType?.fieldName)) {
        changeCalculations(
          currentCalcType.calcType ?? calculationTypes.change,
          key,
          getOutcomeFormValues,
          setOutcomeValues
        );
      }
    }
  }, [watchFields]);

  useEffect(() => {
    validateOutcome({ isDirty, isValid });
  }, [isDirty, isValid]);

  useEffect(() => {
    dirtyFieldHook(dirtyFields);
  }, [Object.keys(dirtyFields ?? {}).length]);

  return (
    <Grid style={{ paddingBottom: "0.5rem" }}>
      {unitGroups !== null && renderData()}
      <Box className={clsx(classes.textRow, classes.otherResults)}>
        <AmoTextField
          multiline
          rows={1}
          rowsMax={10}
          control={outcomeControl}
          disabled={disabled}
          id={String(formFields[formCodes.fieldOtherResults]?.id || "")}
          helperText={parse(
            formFields[formCodes.fieldOtherResults]?.helperText || ""
          )}
          name="otherResults"
          data-testid="otherResultsTextbox"
          fullWidth
          label={formFields[formCodes.fieldOtherResults]?.text || ""}
          characterLimit={2000}
        />
        {formFields[formCodes.fieldOtherResults]?.tooltipText !== null && (
          <Box className={classes.tooltipContainer}>
            <AmoTooltip
              tooltipTitle={null}
              tooltipText={formFields[formCodes.fieldOtherResults]?.tooltipText}
            />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

ProjectOutcomes.propTypes = {
  disabled: PropTypes.bool.isRequired,
  outcomeData: PropTypes.arrayOf(outcomeOptions).isRequired,
  outcomeSchema: PropTypes.shape().isRequired,
  defaultValues: PropTypes.shape().isRequired,
  validateOutcome: PropTypes.func.isRequired,
  unitData: PropTypes.arrayOf(unitDataOptions).isRequired,
  prevFormValues: PropTypes.shape().isRequired,
  dirtyFieldHook: PropTypes.func.isRequired,
  secondaryInputs: PropTypes.string.isRequired,
  onFormRender: PropTypes.func,
};

ProjectOutcomes.defaultProps = {
  onFormRender: () => {},
};

export default ProjectOutcomes;
