import React, { useState, useRef } from "react";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import PropTypes from "prop-types";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { routes } from "constants/routes";
import { NavLink, useLocation } from "react-router-dom";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { AmoTableActionsProps } from "constants/amoTablePropTypes";
import { displayTypes } from "constants/amoTableConstants";
import { useMunicipalContext } from "contexts/MunicipalContext";
import {
  projectCategories,
  reviewStatuses,
  communicationStatuses,
} from "constants/amoTableDropdownOptions";
import { formatISODate } from "utils/date";

const useStyles = makeStyles((theme) => ({
  cellText: {
    fontSize: "0.875rem",
  },
}));

/**
 * The Communications Project List Detailed Table.
 *
 * @param {object} props - object containing props for this component
 * @param {Array} props.tableData - object containing the table data
 * @param {boolean} props.enableClick - enable/disable table item click
 * @param {Function} props.handleItemClick - function called when user clicks on an item row (params: { item clicked })
 * @param {object} props.tableActions - the table actions for the AmoTableWithFilters component @see AmoTableActionsProps
 * @param {boolean} props.isLoading - sets whether the table data is loading
 *
 * @returns {React.Component} Communications Project List Detailed Table.
 */
const DetailedViewTable = (props) => {
  const {
    tableData,
    enableClick,
    handleItemClick,
    tableActions,
    isLoading,
  } = props;

  const classes = useStyles();
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const {
    getMunicipalitiesRegions,
    getMunicipalitiesNames,
  } = useMunicipalContext();

  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});

  const tableColumns = [
    {
      fieldKey: "id",
      label: "Project #",
      minWidth: "9rem",
      verticalAlign: "top",
    },
    {
      fieldKey: "municipalityName",
      label: "Municipality",
      minWidth: "10rem",
      verticalAlign: "top",
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "region",
      label: "Region",
      minWidth: "10rem",
      verticalAlign: "top",
      dropdownOptions: getMunicipalitiesRegions(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "name",
      label: "Title",
      minWidth: "30rem",
      verticalAlign: "top",
      customRender: (item) => (
        <Grid container direction="row" spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
            <NavLink
              to={{
                pathname: routes.communications.projects.details.replace(
                  ":projectId",
                  item.id
                ),
                state: "Detailed",
                search: location.search,
              }}
              ref={(element) => {
                rowRefs.current[item.id] = element;
              }}
              onFocus={() => setFocusedItem({ id: item.id })}
              className={globalClasses.tableActionCell}
            >
              {item.name}
            </NavLink>
          </Grid>
        </Grid>
      ),
      isCustom: true,
    },
    {
      fieldKey: "description",
      label: "Description",
      minWidth: "25rem",
      verticalAlign: "top",
      customRender: ({ objectives, scopeOfWork }) => (
        <Typography className={classes.cellText}>
          <Grid direction="column">
            {!!scopeOfWork && <Grid item>{scopeOfWork}</Grid>}
            {!!scopeOfWork && !!objectives && <br />}
            {!!objectives && <Grid item>{objectives}</Grid>}
          </Grid>
        </Typography>
      ),
      isCustom: true,
    },
    {
      fieldKey: "category",
      label: "Category",
      minWidth: "15rem",
      verticalAlign: "top",
      dropdownOptions: projectCategories,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "registrationDate",
      label: "Registration Date",
      minWidth: "13.375rem",
      verticalAlign: "top",
      customRender: (item) =>
        item.registrationDate !== "0001-01-01T00:00:00" ? (
          <Typography className={classes.cellText}>
            <Grid item xs={12}>
              {formatISODate(item.registrationDate)}{" "}
            </Grid>
          </Typography>
        ) : (
          <></>
        ),
      isCustom: true,
    },
    {
      fieldKey: "startDate",
      label: "Start Date",
      minWidth: "9.375rem",
      verticalAlign: "top",
      customRender: (item) =>
        item.startDate !== "0001-01-01T00:00:00" ? (
          <Typography className={classes.cellText}>
            <Grid item xs={12}>
              {formatISODate(item.startDate)}{" "}
            </Grid>
          </Typography>
        ) : (
          <></>
        ),
      isCustom: true,
    },
    {
      fieldKey: "constructionEndDate",
      label: "End of Construction",
      minWidth: "16.375rem",
      verticalAlign: "top",
      customRender: (item) => (
        <Typography className={classes.cellText}>
          <Grid item xs={12}>
            {formatISODate(item.constructionEndDate)}
          </Grid>
        </Typography>
      ),
      isCustom: true,
    },
    {
      fieldKey: "financingEndDate",
      label: "End of Financing",
      minWidth: "13rem",
      verticalAlign: "top",
      customRender: (item) => (
        <Typography className={classes.cellText}>
          <Grid item xs={12}>
            {formatISODate(item.financingEndDate)}
          </Grid>
        </Typography>
      ),
      isCustom: true,
    },
    {
      fieldKey: "totalCost",
      label: "Total Cost",
      minWidth: "9.375rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "ccbfBudgeted",
      label: "Budgeted CCBF Expenditures",
      minWidth: "20.375rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "ccbfCurrentYear",
      label: "Current Year's CCBF Expenditures",
      minWidth: "22rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "ccbfPreviousYear",
      label: "Previous Year's CCBF Expenditures",
      minWidth: "22rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "ccbfSpent",
      label: "Cumulative CCBF Expenditures",
      minWidth: "20rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "hasMedia",
      label: "Has Media?",
      minWidth: "9.375rem",
      verticalAlign: "top",
      customRender: ({ hasMedia }) => (
        <Typography className={classes.cellText}>
          {hasMedia ? "Yes" : "No"}
        </Typography>
      ),
      isCustom: true,
      displayType: displayTypes.boolean,
    },
    {
      fieldKey: "signagePosted",
      label: "Signage Installed?",
      minWidth: "13.375rem",
      verticalAlign: "top",
      customRender: ({ signagePosted }) => (
        <Typography className={classes.cellText}>
          {signagePosted ? "Yes" : "No"}
        </Typography>
      ),
      isCustom: true,
      displayType: displayTypes.boolean,
    },
    {
      fieldKey: "links",
      label: "Links",
      minWidth: "20rem",
      verticalAlign: "top",
    },
    {
      fieldKey: "reviewStatus",
      label: "Review Status",
      minWidth: "11rem",
      verticalAlign: "top",
      dropdownOptions: reviewStatuses,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "communicationStatus",
      label: "Communications Status",
      minWidth: "17rem",
      verticalAlign: "top",
      dropdownOptions: communicationStatuses,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "updatedDate",
      label: "Last Updated",
      minWidth: "10.5rem",
      verticalAlign: "top",
      customRender: (item) =>
        item.updatedDate !== "0001-01-01T00:00:00" ? (
          <Typography className={classes.cellText}>
            <Grid item xs>
              {formatISODate(item.updatedDate)}{" "}
              {item.updatedBy ? `by ${item.updatedBy}` : ""}
            </Grid>
          </Typography>
        ) : (
          <></>
        ),
      isCustom: true,
    },
  ];

  return (
    <AmoTableWithFilters
      items={tableData}
      columns={tableColumns}
      defaultOrderBy="id"
      defaultOrder="desc"
      enableItemClick={enableClick}
      onItemClick={handleItemClick}
      debouncingTime={450}
      onScroll={(item) => {
        rowRefs.current?.[item.id]?.focus();
      }}
      focusedItem={focusedItem}
      actionsProps={tableActions}
      isLoading={isLoading}
    />
  );
};

DetailedViewTable.propTypes = {
  tableData: PropTypes.shape().isRequired,
  enableClick: PropTypes.bool,
  handleItemClick: PropTypes.func,
  tableActions: PropTypes.instanceOf(AmoTableActionsProps),
  isLoading: PropTypes.bool,
};

DetailedViewTable.defaultProps = {
  enableClick: true,
  handleItemClick: () => {},
  tableActions: [],
  isLoading: false,
};

export default DetailedViewTable;
