import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { routes } from "constants/routes";
import RichTextEditor from "components/RichTextEditor";
import { useAnnouncementForm } from "./AnnouncementForm";
import { useSnackbar } from "contexts/SnackbarContext";
import { announcementsService } from "api/services/announcementService";
import { errorMessages } from "constants/errorMessages";
import { snackbarTypes } from "constants/snackbar";
import AmoPageHeader from "components/AmoPageHeader";
import clsx from "clsx";
import AmoFormActions from "components/AmoFormActions";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useIsMounted } from "hooks/useIsMounted";

/**
 * Announcement Edit Page.
 *
 * @returns {React.Component} Announcement Edit Page component.
 */
const AnnouncementEditPage = () => {
  const globalClasses = useGlobalStyles();

  const mounted = useIsMounted();

  const { announcementId } = useParams();
  const isCreate = announcementId === "new";
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const { search } = useLocation();
  const [isSaving, setIsSaving] = useState(false);

  const {
    handleInputValue,
    markAsTouched,
    markAllAsTouched,
    formIsValid,
    prefillForm,
    form,
    errors,
    touched,
  } = useAnnouncementForm();

  useAsyncEffect((status) => {
    if (!isCreate) {
      (async () => {
        try {
          const { data } = await announcementsService.getById(announcementId);
          if (status.aborted) {
            return;
          }
          prefillForm(data);
        } catch {
          if (status.aborted) {
            return;
          }
          showSnackbar(errorMessages.generic, snackbarTypes.error);
        }
      })();
    }
  }, []);

  const submit = async (event) => {
    event.preventDefault();
    if (!formIsValid()) {
      markAllAsTouched();
    } else {
      setIsSaving(true);
      try {
        await (isCreate
          ? announcementsService.create(form)
          : announcementsService.update(announcementId, form));

        if (!mounted.current) {
          return;
        }

        showSnackbar(
          `Announcement ${isCreate ? "created" : "updated"} successfully.`
        );
        goBackToList();
      } catch {
        if (mounted.current) {
          showSnackbar(errorMessages.generic, snackbarTypes.error);
        }
      } finally {
        if (mounted.current) {
          setIsSaving(false);
        }
      }
    }
  };

  const goBackToList = () =>
    history.push(`${routes.contentManagement.announcements.list}${search}`);

  const formActions = [
    {
      testId: "announcementSubmitButton",
      label: "Save",
      disabled: !formIsValid() || isSaving,
      onClick: submit,
    },
    {
      testId: "announcementCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: goBackToList,
    },
  ];

  return (
    <Grid container direction="column" spacing={0} wrap="nowrap">
      {/* Header */}
      <Grid item>
        <AmoPageHeader
          title={`${isCreate ? "New" : "Edit"} Announcement`}
          backLinkText="Back to Announcement List"
          backLinkTo={routes.contentManagement.announcements.list}
        />
      </Grid>

      {/* Body */}
      <Grid item xs className={globalClasses.editPageBodyContainer}>
        <Grid
          container
          direction="column"
          spacing={0}
          className={clsx(
            globalClasses.editPageBody22,
            globalClasses.editPageBodyText
          )}
          wrap="nowrap"
        >
          <Grid item>
            <Typography>
              Please enter the details of your announcement.
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              placeholder="Announcement Title"
              focused={false}
              value={form.title}
              onBlur={() => markAsTouched("title")}
              onChange={(event) =>
                handleInputValue("title", event.target.value)
              }
              error={touched.title && !!errors.title}
              helperText={touched.title && errors.title}
            />
          </Grid>

          <Grid item>
            <RichTextEditor
              placeholder="Write your announcement here"
              value={form.body}
              onBlur={() => markAsTouched("body")}
              onChange={(value, isValid) =>
                handleInputValue("body", value, !isValid)
              }
              error={touched.body && !!errors.body}
              helperText={touched.body && errors.body ? errors.body : undefined}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              control={<Switch color="primary" checked={form.publish} />}
              label="Publish"
              onChange={() => handleInputValue("publish", !form.publish)}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Actions */}
      <AmoFormActions actions={formActions} hideLateralBorders />
    </Grid>
  );
};

export default AnnouncementEditPage;
