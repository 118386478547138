import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  Icon,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import parse from "html-react-parser";
import colors from "../../constants/colors";
import { typographyOptions } from "../../constants/propTypes";
import { formFieldTypes } from "../../constants/formContentManagement";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: `${colors.black}80`,
      backgroundColor: `${colors.black}1A`,
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: `${colors.black}80`,
    },
  },
  editIconButton: {
    marginLeft: "0.5rem",
    padding: "0.5rem",
  },
  editIcon: {
    fontSize: "1.5rem",
  },
  controlLabel: {
    marginRight: "0rem",
    "&:hover": {
      cursor: "default",
    },
  },
  switchControlLabel: {
    marginLeft: "0rem",
    "&:hover": {
      cursor: "default",
    },
  },
  controlField: {
    "&:hover": {
      cursor: "default",
      backgroundColor: "transparent",
    },
    "& .MuiIconButton-root": {
      cursor: "default",
    },
    "& .Mui-checked:hover": {
      backgroundColor: "transparent",
    },
  },
}));

/**
 * A wrapper component to display form content management field
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the component [required]
 * @param {string} props.testId - data-testid of the component
 * @param {string} props.type - sets the displayed form field type (valid values: @see formFieldTypes) [required]
 * @param {string} props.value - sets the displayed value of the form field (each type is displayed differently)
 * @param {string} props.className - sets the className of the component displaying the form field value
 * @param {string} props.align - sets the alignItems prop of the root Grid component (valid values: "center", "flex-start", "flex-end")
 * @param {string} props.isFullWidth - controls whether the grid item containing the field will fill the remaining width or not (default for @see formFieldTypes.field is true)
 * @param {object} props.textProps - object containing props this form field Typography configuration (valid for all types except: @see formFieldTypes.field )
 * @param {string} props.textProps.variant - sets this form field Typography variant (valid values: @see typographyOptions.variant )
 * @param {string} props.textProps.color - sets this form field Typography color (valid values: @see typographyOptions.color )
 * @param {string} props.textProps.align - sets this form field Typography align (valid values: @see typographyOptions.align )
 * @param {object} props.inputProps - object containing props this form field TextField configuration (valid only for: @see formFieldTypes.field )
 * @param {boolean} props.inputProps.required - controls whether this form field is required
 * @param {string} props.inputProps.endIconName - defines this form field TextField end position icon
 * @param {boolean} props.inputProps.checkbox - controls whether this form field is a checkbox (priority over switch if both are true)
 * @param {boolean} props.inputProps.switch - controls whether this form field is a switch
 * @param {boolean} props.inputProps.labelOnly - controls whether this form field display as Typography not TextField
 * @param {Function} props.onEditClick - function called when user clicks on the edit icon button (params: none)
 * @returns - The wrapper component
 */
const FormFieldWrapper = (props) => {
  const {
    id,
    testId,
    type,
    value,
    className,
    align,
    isFullWidth,
    textProps,
    inputProps,
    onEditClick,
  } = props;

  const classes = useStyles();

  const renderFormField = () => {
    if (type === formFieldTypes.description) {
      return renderDescriptionType();
    }
    if (
      type === formFieldTypes.title ||
      type === formFieldTypes.titleWithTooltip
    ) {
      return renderTitleType();
    }
    if (type === formFieldTypes.field) {
      return renderFieldType();
    }
    if (type === formFieldTypes.link) {
      return renderLinkType();
    }

    return null;
  };

  const renderDescriptionType = () => (
    <Typography
      id={`${id}-description`}
      data-testid={testId ? `${testId}Description` : null}
      className={className}
      variant={textProps.variant}
      color={textProps.color}
      align={textProps.align}
      component="span"
    >
      {parse(value)}
    </Typography>
  );

  const renderTitleType = () => (
    <Typography
      id={`${id}-title`}
      data-testid={testId ? `${testId}Title` : null}
      className={className}
      variant={textProps.variant}
      color={textProps.color}
      align={textProps.align}
      noWrap
    >
      {value}
    </Typography>
  );

  const renderFieldType = () => {
    if (inputProps.labelOnly) {
      return (
        <Typography
          id={`${id}-field`}
          data-testid={testId ? `${testId}Field` : null}
          className={className}
          variant={textProps.variant}
          color={textProps.color}
          align={textProps.align}
          noWrap
        >
          {value}
        </Typography>
      );
    }
    if (inputProps.checkbox) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              id={`${id}-field`}
              data-testid={testId ? `${testId}Field` : null}
              checked={false}
              color="primary"
              disableRipple
              className={classes.controlField}
            />
          }
          className={clsx(classes.controlLabel, className)}
          label={value}
        />
      );
    }
    if (inputProps.switch) {
      return (
        <FormControlLabel
          control={
            <Switch
              id={`${id}-field`}
              data-testid={testId ? `${testId}Field` : null}
              checked
              color="primary"
              disableRipple
              className={classes.controlField}
            />
          }
          className={clsx(classes.switchControlLabel, className)}
          label={value}
          labelPlacement="start"
        />
      );
    }

    return (
      <TextField
        id={`${id}-field`}
        data-testid={testId ? `${testId}Field` : null}
        className={clsx(classes.textField, className)}
        label={value}
        variant="outlined"
        required={inputProps.required}
        disabled
        InputProps={{
          endAdornment: inputProps.endIconName && (
            <InputAdornment position="end">
              <Icon className="material-icons-outlined">
                {inputProps.endIconName}
              </Icon>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const renderLinkType = () => (
    <Typography
      id={`${id}-link`}
      data-testid={testId ? `${testId}Link` : null}
      variant={textProps.variant}
      color={textProps.color}
      align={textProps.align}
      className={className}
    >
      {value}
    </Typography>
  );

  return (
    <>
      <Grid
        id={id}
        data-testid={testId}
        container
        spacing={0}
        wrap="nowrap"
        alignItems={align}
      >
        <Grid
          item
          xs={
            isFullWidth === undefined
              ? type === formFieldTypes.field
              : isFullWidth
          }
        >
          {renderFormField()}
        </Grid>
        {onEditClick && (
          <Grid item>
            <IconButton
              data-testid={`${testId ?? `${id}-test`}EditIcon`}
              aria-label="editIcon"
              className={classes.editIconButton}
              onClick={() => onEditClick()}
            >
              <Icon
                className={clsx("material-icons-outlined", classes.editIcon)}
                color="primary"
              >
                edit
              </Icon>
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

// set the prop-types for this component
FormFieldWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  type: PropTypes.oneOf([
    formFieldTypes.description,
    formFieldTypes.title,
    formFieldTypes.titleWithTooltip,
    formFieldTypes.field,
    formFieldTypes.link,
  ]).isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  align: PropTypes.oneOf(["center", "flex-start", "flex-end"]),
  isFullWidth: PropTypes.bool,
  textProps: PropTypes.shape({
    variant: PropTypes.oneOf(typographyOptions.variant),
    color: PropTypes.oneOf(typographyOptions.color),
    align: PropTypes.oneOf(typographyOptions.align),
  }),
  inputProps: PropTypes.shape({
    required: PropTypes.bool,
    endIconName: PropTypes.string,
    checkbox: PropTypes.bool,
    switch: PropTypes.bool,
    labelOnly: PropTypes.bool,
  }),
  onEditClick: PropTypes.func,
};

FormFieldWrapper.defaultProps = {
  testId: null,
  value: "",
  className: undefined,
  align: "center",
  isFullWidth: undefined,
  textProps: {},
  inputProps: {},
  onEditClick: undefined,
};

export default FormFieldWrapper;
