import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { getReportedValueSummary } from "utils/indicator";

/**
 * Component to display project output specific data
 *
 * @param {object} props
 * @returns {Function} The ProjectOutput component
 */
const ProjectOutput = (props) => {
  const { output } = props;
  const {
    value,
    description,
    standardUnitAbbreviation,
    indicatorUnitTypeId,
    reportedValue,
    reportedMultiplier,
    reportedUnitAbbreviation,
    categoryId,
    subcategoryId,
  } = output;

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap">
      <Grid item>
        <Typography variant="body2">{description}</Typography>
        <Typography>
          {value === "true" ? "Yes" : value}
          {standardUnitAbbreviation && ` ${standardUnitAbbreviation}`}
          {standardUnitAbbreviation !== reportedUnitAbbreviation &&
            getReportedValueSummary(
              categoryId,
              subcategoryId,
              indicatorUnitTypeId,
              reportedValue,
              reportedUnitAbbreviation,
              reportedMultiplier
            )}
        </Typography>
      </Grid>
    </Grid>
  );
};

ProjectOutput.propTypes = {
  output: PropTypes.shape().isRequired,
};

export default ProjectOutput;
