import React from "react";
import {
  Popover,
  Button,
  Grid,
  IconButton,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../constants/colors";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

// set variables regarding the styling of the component
const getStyleSettings = (variant) => {
  const returnValue = {
    textColor: null,
    backColor: null,
    themeColor: null,
    icon: null,
  };

  // assign the colors of the popover based on the variant prop value
  switch (variant) {
    // each case handles the design of the modal dialog for a different variant,
    // add a variant here (and in the props definition) rather than
    // creating a new modal, or changing the values below
    case "success": {
      returnValue.textColor = colors.black;
      returnValue.backColor = colors.white;
      returnValue.themeColor = "primary";
      returnValue.icon = (
        <CheckCircleOutlineOutlinedIcon
          data-testid="popoverIcon"
          color="primary"
          style={{ fontSize: "5rem" }}
        />
      );
      break;
    }
    case "error": {
      returnValue.textColor = colors.white;
      returnValue.backColor = colors.red.main;
      returnValue.themeColor = "secondary";
      returnValue.icon = (
        <CancelOutlinedIcon
          data-testid="popoverIcon"
          style={{ fontSize: "5rem", color: "white" }}
        />
      );
      break;
    }
    default: {
      returnValue.textColor = colors.black;
      returnValue.backColor = colors.white;
      returnValue.themeColor = "primary";
      returnValue.icon = null;
    }
  }
  // send back the configuration
  return returnValue;
};

// sets the Material-UI styles for this component
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    color: ({ variant }) => getStyleSettings(variant).textColor,
    backgroundColor: ({ variant }) => getStyleSettings(variant).backColor,
    borderRadius: "0.625rem",
    maxHeight: "465px",
    padding: theme.spacing(0),
  },
  button: {
    minWidth: "60px",
    // 50px
    height: "3.125rem",
  },
  iconButton: {
    padding: theme.spacing(0.8),
    color: ({ variant }) => (variant === "error" ? colors.white : null),
  },
  outerGridContainer: {
    padding: theme.spacing(0),
  },
  topGridContainer: {
    padding: theme.spacing(0),
  },
  topGridItem: {
    padding: theme.spacing(0),
  },
  bottomGridContainer: {
    padding: theme.spacing(2),
  },
  headerContainer: {
    paddingBottom: theme.spacing(2),
  },
}));

/**
 * creates a modal dialog using Material-UI's Popover component
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.variant - controls the color scheme of the popover valid values are {"success", "error", "default"}
 * @param {boolean} props.open - controls visibility of the popover
 * @param {string} props.title - text to display in the title
 * @param {string} props.text - text to display as the message
 * @param {string} props.width - width of the popover
 * @param {boolean} props.showButton - controls visibility of the function button
 * @param {string} props.buttonText - the label displayed on the button
 * @param {Function} props.buttonFunction - function that executes when user clicks the button  ** MUST BE PARAMETERLESS **
 * @param {string} props.buttonVariant - controls the variant of the button, valid values are {"outlined", "contained"}
 * @param {string} props.buttonColor - controls the color of the button, default value is "primary"
 * @param {Function} props.closePopover - function that hides the popover
 * @param {string} props.secondButtonText - the label displayed on second button
 * @param {Function} props.secondButtonFunction - function that executes when user clicks the 2nd button ** MUST BE PARAMETERLESS **
 * @param {string} props.secondButtonVariant - controls the variant of the 2nd button, valid values are {"outlined", "contained"}
 * @param {string} props.secondButtonColor - controls the color of the 2nd button, default value is "primary"
 * @returns - the popover component, styled for the AMO project
 */
const AmoPopover = (props) => {
  const {
    variant,
    title,
    text,
    width,
    showButton,
    buttonText,
    buttonFunction,
    buttonVariant,
    buttonColor,
    open,
    closePopover,
    secondButtonText,
    secondButtonFunction,
    secondButtonVariant,
    secondButtonColor,
  } = props;
  // set initial values
  const styleSettings = getStyleSettings(variant);

  // gather the styles for this popover
  const classes = useStyles(props);

  // assign the button section, if there should be a button on the popover
  let buttonRow = null;
  if (showButton) {
    buttonRow = (
      <Grid container spacing={secondButtonFunction ? 2 : 0}>
        <Grid item xs={secondButtonFunction ? 6 : 12}>
          <Button
            data-testid="popoverFunctionButton"
            classes={{
              root: classes.button,
            }}
            color={buttonColor || styleSettings.themeColor}
            variant={buttonVariant}
            onClick={buttonFunction}
          >
            {buttonText}
          </Button>
        </Grid>
        {secondButtonFunction && (
          <Grid item xs={6}>
            <Button
              data-testid="popoverFunctionSecondButton"
              classes={{
                root: classes.button,
              }}
              color={secondButtonColor || styleSettings.themeColor}
              variant={secondButtonVariant}
              onClick={secondButtonFunction}
            >
              {secondButtonText}
            </Button>
          </Grid>
        )}
        <Grid item>&nbsp;</Grid>
      </Grid>
    );
  }

  // send the prepared component back to the browser
  return (
    <Popover
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      anchorEl={document.body}
      anchorOrigin={{
        horizontal: "center",
        vertical: "center",
      }}
      transformOrigin={{
        horizontal: "center",
        vertical: "center",
      }}
      PaperProps={{
        "data-testid": "popoverPaper",
        square: false,
        variant: "elevation",
        elevation: 20,
        style: { width },
      }}
    >
      <Grid
        container
        classes={{
          root: classes.outerGridContainer,
        }}
        direction="column"
        alignItems="center"
      >
        <Grid
          container
          classes={{
            root: classes.topGridContainer,
          }}
          direction="row-reverse"
        >
          <Grid
            item
            classes={{
              root: classes.topGridItem,
            }}
          >
            <IconButton
              data-testid="popoverCloseButton"
              classes={{
                root: classes.iconButton,
              }}
              onClick={closePopover}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          classes={{
            root: classes.bottomGridContainer,
          }}
          direction="column"
          alignItems="center"
          wrap="wrap"
        >
          <Grid item className={classes.headerContainer}>
            <Typography data-testid="popoverTitle" variant="h2" component="div">
              <Box color={styleSettings.textColor}>{title}</Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              data-testid="popoverMessage"
              variant="body1"
              component="div"
            >
              <Box color={styleSettings.textColor}>{text}</Box>
            </Typography>
          </Grid>
          <Grid item>&nbsp;</Grid>
          <Grid item>{styleSettings.icon ? styleSettings.icon : null}</Grid>
          <Grid item>&nbsp;</Grid>
          {buttonRow}
        </Grid>
      </Grid>
    </Popover>
  );
};

// set the prop-types for this component
AmoPopover.propTypes = {
  variant: PropTypes.oneOf(["success", "error", "default"]).isRequired,
  open: PropTypes.bool.isRequired,
  closePopover: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonFunction: PropTypes.func,
  buttonVariant: PropTypes.oneOf(["outlined", "contained"]),
  buttonColor: PropTypes.string,
  secondButtonText: PropTypes.string,
  secondButtonFunction: PropTypes.func,
  secondButtonVariant: PropTypes.oneOf(["outlined", "contained"]),
  secondButtonColor: PropTypes.string,
};

// set prop-types defaults for this component
AmoPopover.defaultProps = {
  title: "Message",
  text: "Message Text",
  width: "500px",
  showButton: false,
  buttonText: null,
  buttonFunction: null,
  buttonVariant: "outlined",
  buttonColor: "primary",
  secondButtonText: "Cancel",
  secondButtonFunction: null,
  secondButtonVariant: "outlined",
  secondButtonColor: "primary",
};

export default AmoPopover;
