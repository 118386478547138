import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { formatToCurrency } from "utils/number";
import { DateTime } from "luxon";
import Skeleton from "@material-ui/lab/Skeleton";
import { useFinancialOverview } from "./landingPageHooks";
import { useYearContext } from "contexts/YearContext";
import { routes } from "constants/routes";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "34rem",
    height: "37rem",
    borderRadius: "10px",
    border: "1px solid #939393",
    padding: "1rem",
    paddingBottom: "0rem",
  },
  column: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    height: "100%",
  },
  titleRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    paddingBottom: "4rem",
  },
  titleText: {
    fontSize: "1.875rem",
  },
  financialsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    marginTop: "0.3rem",
  },
  divider: {
    position: "relative",
    width: "100%",
    borderBottom: "0.063rem solid #F1F1F1",
    paddingBottom: "1rem",
  },
  financialTitle: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  financialText: {
    color: "#4D7F2A",
    fontSize: "1rem",
  },
  largeFinancialText: {
    color: "#4D7F2A",
    fontSize: "1.875rem",
  },
  paymentText: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  skeleton: {
    width: "7rem",
  },
  link: {
    textDecoration: "none",
  },
}));

const FinancialsOverviewWidget = (props) => {
  const { id, testId } = props;
  const classes = useStyles();
  const { contextYear } = useYearContext();
  const { data, isLoading } = useFinancialOverview(contextYear);

  const formatFinancialValues = (value) => {
    const formattedValue = !value ? "$0.00" : formatToCurrency(value);

    return formattedValue;
  };

  // backend returns 0001 year if there is no nextScheduledPayment
  const checkIfNoNextScheduledPayment = (value) =>
    value === undefined || value.substring(0, 4) === "0001";

  return (
    <Box className={classes.container} id={id} data-testid={testId}>
      <Box className={classes.column}>
        <Box className={classes.titleRow}>
          <Typography variant="h1" className={classes.titleText}>
            Overview
          </Typography>
        </Box>

        <Box className={classes.financialsRow}>
          <Typography variant="h6" className={classes.financialTitle}>
            Opening Balance
          </Typography>
          {isLoading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Link to={routes.reviews.financial.list} className={classes.link}>
              <Typography variant="h5" className={classes.financialText}>
                {formatFinancialValues(data?.openingBalance)}
              </Typography>
            </Link>
          )}
        </Box>
        <Box className={classes.financialsRow}>
          <Typography variant="h6" className={classes.financialTitle}>
            Revenues (Including Receivables)
          </Typography>
          {isLoading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Link to={routes.reviews.financial.list} className={classes.link}>
              <Typography variant="h5" className={classes.financialText}>
                {formatFinancialValues(data?.revenues)}
              </Typography>
            </Link>
          )}
        </Box>
        <Box className={classes.financialsRow}>
          <Typography variant="h6" className={classes.financialTitle}>
            Expenditures
          </Typography>
          {isLoading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Link to={routes.reviews.financial.list} className={classes.link}>
              <Typography variant="h5" className={classes.financialText}>
                {formatFinancialValues(data?.expenditures)}
              </Typography>
            </Link>
          )}
        </Box>
        <Box className={classes.financialsRow}>
          <Typography variant="h6" className={classes.financialTitle}>
            Transfers
          </Typography>
          {isLoading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Link to={routes.reviews.financial.list} className={classes.link}>
              <Typography variant="h5" className={classes.financialText}>
                {formatFinancialValues(data?.transfers)}
              </Typography>
            </Link>
          )}
        </Box>
        <Box
          className={clsx(classes.financialsRow, classes.divider)}
          style={{ alignItems: "flex-start", marginTop: "0.5rem" }}
        >
          <Typography variant="h6" className={classes.financialTitle}>
            Closing Balance
          </Typography>
          {isLoading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Link to={routes.reviews.financial.list} className={classes.link}>
              <Typography variant="h1" className={classes.largeFinancialText}>
                {formatFinancialValues(data?.closingBalance)}
              </Typography>
            </Link>
          )}
        </Box>
        <Box
          className={classes.financialsRow}
          style={{ alignItems: "flex-start", marginTop: "1.5rem" }}
        >
          <Typography variant="h6" className={classes.financialTitle}>
            5-year Banking Limit Compliance:
          </Typography>
          {isLoading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Link to={routes.reviews.bankLimit} className={classes.link}>
              <Typography variant="h1" className={classes.largeFinancialText}>
                {contextYear < 2018
                  ? "N/A"
                  : `${data?.fiveYearBankingLimitCompliance}/${data?.totalMunicipalities}`}
              </Typography>
            </Link>
          )}
        </Box>
        <Box
          className={clsx(classes.financialsRow, classes.divider)}
          style={{ alignItems: "flex-start", marginTop: "2rem" }}
        >
          <Typography variant="h6" className={classes.financialTitle}>
            Incrementality Compliance:
          </Typography>
          {isLoading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Link
              to={routes.reviews.incrementalityMonitoring.list}
              className={classes.link}
            >
              <Typography variant="h1" className={classes.largeFinancialText}>
                {`${data?.incrementalityCompliance}/${data?.totalMunicipalities}`}
              </Typography>
            </Link>
          )}
        </Box>
        <Box
          className={classes.financialsRow}
          style={{ alignItems: "flex-start", marginTop: "1.5rem" }}
        >
          <Typography variant="h6" className={classes.financialTitle}>
            Next Scheduled Payment:
          </Typography>
          {isLoading ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Typography variant="h5" className={classes.paymentText}>
              {checkIfNoNextScheduledPayment(data?.nextScheduledPayment)
                ? "None"
                : DateTime.fromISO(data?.nextScheduledPayment).toLocaleString(
                    DateTime.DATE_FULL
                  )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

FinancialsOverviewWidget.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
};

export default FinancialsOverviewWidget;
