import { useEffect, useState } from "react";
import { useQueryParams, StringParam } from "use-query-params";

/**
 * A hook to display the search button and clear filters button for tables
 *
 * @param {string} tableId pass the table id to query the filters correctly
 * @param {string[]} keys pass the table cell keys to query the filters correctly
 *
 * @returns an array with the search button component, the show filters boolean, and the set show filters function
 */
export const useTableSearchQuery = (tableId = undefined, keys = []) => {
  const [hasFilters, setHasFilters] = useState(false);
  const paramsPrefix = `filter${tableId ? `_${tableId}` : ""}`;
  const queryParamsConfig = keys.reduce(
    (object, key) => ({
      ...object,
      [`${paramsPrefix}_${key}`]: StringParam,
    }),
    {}
  );
  const [filtersQuery, setFiltersQuery] = useQueryParams(queryParamsConfig);

  const clearFilters = () => {
    const clearedFilterParams = keys.reduce(
      (object, key) => ({
        ...object,
        [`${paramsPrefix}_${key}`]: undefined,
      }),
      {}
    );
    setFiltersQuery(clearedFilterParams);
  };

  useEffect(() => {
    const hasFilter = Object.values(filtersQuery).some((value) => !!value);
    setHasFilters(hasFilter);
  }, [filtersQuery]);

  return { hasFilters, clearFilters };
};
