/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { MenuItem, Typography, Grid } from "@material-ui/core";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import PropTypes from "prop-types";
import { useYearContext } from "contexts/YearContext";
import AmoPopupMenu from "components/AmoPopupMenu";
import { useQuery } from "react-query";
import { reportingService } from "api/services/reportingService";
import { reporting } from "constants/reporting";
import { oneDayInMs } from "constants/caching";

/**
 * Store server's state of all reporting years
 *
 * @returns The query object returned by the useQuery hook
 */
const useAllReportingYears = () =>
  useQuery(
    ["reporting", "years"],
    () => reportingService.getAllReportingYears(),
    {
      select: ({ data }) => data,
      staleTime: oneDayInMs,
    }
  );

/**
 * A popup menu component for context years
 *
 * @param {object} props
 * @param {string} props.id The AmoPopupMenu component id
 * @param {string} props.testId The AmoPopupMenu component testId
 * @param {object} props.classes The AmoPopupMenu component classes
 * @returns {React.Component} The popup menu component
 */
const AmoPopupYearMenu = (props) => {
  const { id, testId, classes } = props;

  const { contextYear, setSelectedYear } = useYearContext();
  const contextYearParentRef = useRef(null);

  const { data: reportingYears } = useAllReportingYears();

  const renderContextYearTitle = (contextYearRef) => (
    <Grid container style={{ height: "4.25rem" }} alignItems="center">
      <Grid item>
        <Typography color="primary" variant="h3">
          {contextYearRef}
        </Typography>
      </Grid>
      <Grid item style={{ paddingTop: "0.5rem" }}>
        <ExpandMoreOutlinedIcon color="primary" fontSize="large" />
      </Grid>
    </Grid>
  );

  return (
    <Grid item ref={contextYearParentRef}>
      <AmoPopupMenu
        id={id}
        testId={testId}
        anchor="parent"
        title={renderContextYearTitle(contextYear)}
        classes={classes}
        anchorRef={contextYearParentRef}
      >
        {(reportingYears ?? reporting.availableYears)
          .slice()
          .reverse()
          .map((value) => (
            <MenuItem
              key={`headerContextYearMenu${value}`}
              onClick={() => setSelectedYear(value)}
            >
              <Typography color="primary" variant="subtitle2">
                {value}
              </Typography>
            </MenuItem>
          ))}
      </AmoPopupMenu>
    </Grid>
  );
};

// set the prop-types for this component
AmoPopupYearMenu.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  classes: PropTypes.shape({
    button: PropTypes.string,
    buttonActive: PropTypes.string,
    menu: PropTypes.string,
  }),
};

AmoPopupYearMenu.defaultProps = {
  testId: null,
  classes: {
    link: null,
    linkActive: null,
    menu: null,
  },
};

export default AmoPopupYearMenu;
