import {
  makeStyles,
  Divider,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";

import React, { useRef, useState } from "react";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import ListPageWrapper from "components/ListPageWrapper";
import { exportToExcel } from "utils/export";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import { useIncrementalityMonitoringReviewsHooks } from "./incrementalityMonitoringReviewsHooks";
import { range } from "utils/number";
import { booleanToTextOrUndefined } from "utils/string";
import { incrementalityMonitoringStatuses } from "constants/amoTableDropdownOptions";

import IncrementalityMonitoringModal from "./IncrementalityMonitoringModal";
import { incrementalityMonitoringService } from "api/services/incrementalityMonitoringService";
import { useWrapApi } from "hooks/wrapApiHook";
import { useIsMounted } from "hooks/useIsMounted";
import { fileTypes } from "constants/fileTypes";
import XlsxPopulate from "xlsx-populate";
import { DateTime } from "luxon";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { reporting } from "constants/reporting";
import IncrementalityMonitoringUploadModal from "./IncrementalityMonitoringUploadModal";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "20.563rem",
    height: "3.125rem",
  },
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
}));

/**
 * The Financial Review List Page.
 *
 * @returns {React.Component} Financial Review List Page component.
 */
const IncrementalityMonitoringListPage = () => {
  const classes = useStyles();

  const mounted = useIsMounted();

  const { showSnackbar } = useSnackbar();
  const { municipalities, getMunicipalitiesNames } = useMunicipalContext();
  const globalClasses = useGlobalStyles();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [fileUploadResult, setFileUploadResult] = useState([]);
  const [focusedItem, setFocusedItem] = useState({});
  const [isLoadingUploadData, setIsLoadingUploadData] = useState(false);
  const inputFile = useRef(null);
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const tableColumns = [
    {
      fieldKey: "municipalityName",
      label: "Municipality",
      minWidth: "15.625rem",
      customRender: ({ municipalityId, municipalityName }) => (
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              color="primary"
              onClick={() => openModal(municipalityId)}
              disableRipple
              className={globalClasses.tableActionCell}
              ref={(element) => {
                rowRefs.current[municipalityId] = element;
              }}
              onFocus={() => setFocusedItem({ municipalityId })}
            >
              {municipalityName}
            </Button>
          </Grid>
        </Grid>
      ),
      isCustom: true,
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "baseAmount",
      label: "Base Amount",
      minWidth: "10.5rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "annualTarget",
      label: "Annual Target",
      minWidth: "10.9rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "averageAnnualInvestment",
      label: "Avg. Annual Investment",
      minWidth: "16.5rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "status",
      label: "Status",
      minWidth: "11rem",
      dropdownOptions: incrementalityMonitoringStatuses,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "ammendmentMade",
      label: "Amendment Made",
      minWidth: "14rem",
      displayType: displayTypes.boolean,
      customExport: ({ ammendmentMade }) =>
        booleanToTextOrUndefined(ammendmentMade) ?? "",
    },
  ];

  const {
    data: incrementalityMonitoringReviews,
    isFetching: incrementalityMonitoringReviewsFetching,
    refetch: incrementalityRefetch,
  } = useIncrementalityMonitoringReviewsHooks();

  const openModal = (municipalityId) => {
    setModalData({ municipalityId });
    setShowModal(true);
    setFocusedItem(null);
  };

  const closeModal = (shouldRefetch) => {
    setShowModal(false);
    setFocusedItem({ municipalityId: modalData.municipalityId });
    setModalData({});
    if (shouldRefetch) {
      incrementalityRefetch();
    }
  };

  const updateIncrementalityMonitorings = useWrapApi(
    incrementalityMonitoringService.updateIncrementalityMonitoringData,
    "Successfully updated review log information"
  );

  const handleFileUpload = async (event) => {
    event.preventDefault();
    setIsLoadingUploadData(true);

    const { files } = event.target;
    const file = files[0];
    const workbook = await XlsxPopulate.fromDataAsync(file);
    const worksheet = workbook.sheet(0);

    if (!mounted.current) {
      return;
    }

    const usedRange = worksheet.usedRange();
    const highestRowNum = usedRange.endCell().rowNumber();

    // Starts with 2 to skip headers row
    const incrementalityMonitoringData = range(2, highestRowNum).map(
      (index) => ({
        municipalityId: worksheet.row(index).cell(1).value(),
        amount: worksheet.row(index).cell(2).value(),
        periodEnd: worksheet.row(index).cell(3).value(),
      })
    );

    if (
      !incrementalityMonitoringData?.length ||
      incrementalityMonitoringData.every(
        ({ municipalityId }) => municipalityId == null
      )
    ) {
      showSnackbar("Uploaded file is empty", snackbarTypes.warning);
      return;
    }

    setShowFileUploadModal(true);
    const uploadResult = incrementalityMonitoringData.map(
      ({ municipalityId, amount, periodEnd }, i) => {
        const status = [
          municipalities[municipalityId] ? "" : "Municipal ID: Not found;",
          !incrementalityMonitoringData.some(
            ({ municipalityId: municipalityId2 }, i2) =>
              municipalityId === municipalityId2 && i !== i2
          )
            ? ""
            : "Municipal ID: Repeated;",
          !isNaN(amount) ? "" : "Investment: Not a number;",
          isNaN(amount) || amount > 0
            ? ""
            : "Investment: Should be higher than 0;",
          periodEnd > reporting.firstYearOfCalculatedAveragesPeriod
            ? ""
            : `End Year: Should be higher than ${reporting.firstYearOfCalculatedAveragesPeriod};`,
          periodEnd < DateTime.now().year
            ? ""
            : "End Year: Should be less than current year;",
        ];
        return {
          municipalityId,
          status: `${
            status.every((error) => error === "")
              ? "Successfully updated"
              : `Failed to process: ${status.join("")}`
          }`,
        };
      }
    );

    const payload = incrementalityMonitoringData.filter(({ municipalityId }) =>
      uploadResult
        .flatMap(({ status, municipalityId: municipalityId2 }) =>
          status.startsWith("Successfully") ? [municipalityId2] : []
        )
        .includes(municipalityId)
    );

    await updateIncrementalityMonitorings.call(payload);

    if (!mounted.current) {
      return;
    }

    setFileUploadResult(uploadResult);
    setIsLoadingUploadData(false);

    // clear file input value
    inputFile.current.value = "";
  };

  const updateAnnualInvestmentButton = (
    <>
      <input
        type="file"
        id="incrementality-monitoring-upload-input"
        ref={inputFile}
        style={{ display: "none" }}
        onInput={(event) => handleFileUpload(event)}
        accept={`.${fileTypes.xlsx}`}
      />
      <Button
        aria-label="tableActionSearch"
        onClick={() => inputFile.current?.click()}
        color="primary"
        size="medium"
        variant="outlined"
        className={classes.button}
        disabled={incrementalityMonitoringReviewsFetching}
      >
        Update Avg. Annual Investment
      </Button>
    </>
  );

  const tableActions = {
    actions: [
      "updateAnnualInvestment",
      tableDefaultActions.search,
      tableDefaultActions.export,
    ],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            exportToExcel(
              filteredData.current,
              [
                {
                  fieldKey: "municipalityId",
                  label: "Municipality Id",
                },
                ...tableColumns,
              ],
              "Incrementality Monitoring Reviews",
              {
                sheetName: "Reviews",
                specialColumnSizes: { municipalityName: 30 },
              }
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
      },
    },
    customActionsComponents: {
      updateAnnualInvestment: updateAnnualInvestmentButton,
    },
  };

  return (
    <>
      <IncrementalityMonitoringUploadModal
        open={showFileUploadModal}
        onClose={() => {
          setShowFileUploadModal(false);
          incrementalityRefetch();
        }}
        uploadResultData={fileUploadResult}
        isLoading={isLoadingUploadData}
      />
      <ListPageWrapper>
        <Grid container direction="column" spacing={2} wrap="nowrap">
          <Grid item>
            <Typography variant="h1">Incrementality Monitoring</Typography>
          </Grid>
          <Grid item className={classes.dividerContainer}>
            <Divider />
          </Grid>
          <Grid item>
            <Grid item>
              <AmoTableWithFilters
                items={incrementalityMonitoringReviews}
                columns={tableColumns}
                defaultOrderBy="municipalityName"
                onFilteredDataChanged={(value) => {
                  filteredData.current = value;
                }}
                onScroll={(item) => {
                  rowRefs.current?.[item.municipalityId]?.focus();
                }}
                focusedItem={focusedItem}
                actionsProps={tableActions}
                isLoading={incrementalityMonitoringReviewsFetching}
                uniqueKeyGenerationFields={["municipalityId"]}
              />
            </Grid>
          </Grid>
        </Grid>
      </ListPageWrapper>

      {showModal && (
        <IncrementalityMonitoringModal
          open={showModal}
          municipalityId={modalData.municipalityId}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default IncrementalityMonitoringListPage;
