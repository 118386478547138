import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

/**
 * Input component that uses "react-number-format" package
 */
const AmoNumberFormat = React.forwardRef((props, ref) => {
  // TODO: Should we pass the inputRef from MUI through to the input component? If not, comment why.
  const { name, onChange, inputRef, ...other } = props;

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      isNumericString
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
});

AmoNumberFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
};

AmoNumberFormat.defaultProps = {
  inputRef: null,
};

const CurrencyNumberFormat = React.forwardRef((props, ref) => {
  // TODO: Should we pass the inputRef from MUI through to the input component? If not, comment why.
  const {
    onChange,
    isNegative,
    allowNegative,
    decimalScale,
    inputRef,
    ...other
  } = props;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={decimalScale}
      fixedDecimalScale
      allowNegative={allowNegative}
      prefix={isNegative ? "($" : "$"}
      suffix={isNegative ? ")" : ""}
    />
  );
});

CurrencyNumberFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isNegative: PropTypes.bool.isRequired,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
};

CurrencyNumberFormat.defaultProps = {
  allowNegative: false,
  decimalScale: 2,
  inputRef: null,
};

export { AmoNumberFormat, CurrencyNumberFormat };
