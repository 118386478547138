import React, { useState, useRef } from "react";
import {
  Grid,
  Icon,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  DialogContent,
  Popover,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../../constants/colors";
import clsx from "clsx";
import { usePopupState } from "material-ui-popup-state/hooks";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    width: "21.5rem",
    borderRadius: "5px",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    borderTop: "none",
    borderBottomColor: colors.grey.main,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingTop: "0",
  },
  dialogActions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  dialogActionsSpacing: {
    "& :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  fullWidth: {
    width: "100%",
  },
  flag: {
    padding: theme.spacing(0.2),
  },
  primary: {
    color: theme.palette.primary.main,
  },
  yellow: {
    color: colors.yellow,
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "500",
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(1),
    color: colors.grey.icon,
  },
  button: {
    minHeight: 50,
    marginBottom: theme.spacing(1),
  },
  saveButton: {
    maxWidth: "40%",
    marginLeft: theme.spacing(2),
  },
  caption: {
    fontSize: "0.875rem",
    fontWeight: "normal",
  },
  fieldSection: {
    marginBottom: "1rem",
  },
}));

/**
 * A flag popup component for AMO users
 *
 * @param {object} props - props for this component
 * @param {object} props.id - id of the component
 * @param {object} props.testId - data-testid of the component
 * @param {object} props.flagId - id of the flag record
 * @param {object} props.ownerName - last edited by
 * @param {object} props.lastUpdate - timestamp of last edit
 * @param {object} props.comment - comment value of flag
 * @param {object} props.onSave - function to save flag
 * @param {object} props.onRemove - function to remove flag
 * @param {object} props.fieldLabel - label of the field
 * @param {object} props.fieldValue - value of the field
 *
 * @returns {React.Component} The flag popup component
 */
const AmoFlagInputPopover = (props) => {
  const {
    id,
    testId,
    flagId,
    ownerName,
    lastUpdate,
    comment,
    onSave,
    onRemove,
    fieldLabel,
    fieldValue,
  } = props;

  const classes = useStyles();

  const isEdit = flagId > 0;

  const popupState = usePopupState({ variant: "popover", popupId: id });

  const previousValue = useRef(isEdit ? comment : "");
  const [value, setValue] = useState(previousValue.current);

  const title = isEdit ? "Edit Flag" : "Add Flag";

  const handleSave = (event) => {
    popupState.close(event);
    previousValue.current = value;
    onSave(value);
  };

  const handleRemove = (event) => {
    previousValue.current = "";
    handleClose(event);
    onRemove();
  };

  const handleClose = (event) => {
    popupState.close(event);
    setValue(previousValue.current);
  };

  return (
    <>
      <IconButton
        data-testid={`${testId ?? `${id}-test`}Button`}
        aria-controls={popupState.isOpen ? id : undefined}
        aria-haspopup="true"
        aria-label="flagIcon"
        className={clsx(
          classes.flag,
          isEdit ? classes.yellow : classes.primary
        )}
        onClick={popupState.open}
        onTouchStart={popupState.open}
      >
        <Icon className={isEdit ? "material-icons" : "material-icons-outlined"}>
          flag
        </Icon>
      </IconButton>
      <Popover
        id={popupState.popupId}
        anchorEl={popupState.anchorEl}
        open={popupState.isOpen}
        onClose={popupState.close}
        data-testid={testId ?? `${id}-test`}
        classes={{
          paper: classes.popoverPaper,
        }}
        getContentAnchorEl={null}
        elevation={4}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        keepMounted
        hideBackdrop
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DialogTitle
          disableTypography
          className={classes.dialogTitle}
          onClose={handleClose}
        >
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            data-testid={`${testId ?? `${id}-test`}CloseIcon`}
            aria-label="closeIcon"
            className={classes.closeIcon}
            onClick={handleClose}
          >
            <Icon className="material-icons">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item className={classes.fieldSection}>
              <div>
                <Typography variant="body2">{fieldLabel}</Typography>
              </div>
              <div>
                <Typography variant="body1">{fieldValue}</Typography>
              </div>
            </Grid>
            <Grid item className={classes.fullWidth}>
              <TextField
                data-testid={`${testId ?? `${id}-test`}Textbox`}
                className={classes.fullWidth}
                label="Flag comment"
                value={value}
                onChange={(event) => setValue(event.target.value)}
                variant="outlined"
                multiline
                minRows={4}
                maxRows={10}
                required
              />
            </Grid>
            {isEdit && (
              <Grid item>
                <Typography variant="caption" className={classes.caption}>
                  Last edited{" "}
                  {DateTime.fromISO(lastUpdate, { locale: "en-GB" }).toFormat(
                    "yyyy-MM-dd HH:mm a"
                  )}{" "}
                  by {ownerName}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          className={classes.dialogActions}
          classes={{ spacing: classes.dialogActionsSpacing }}
        >
          <Button
            className={clsx(classes.button, classes.saveButton)}
            onClick={handleSave}
            color="primary"
            variant="contained"
            size="large"
            disabled={!value}
          >
            Save
          </Button>
          {isEdit && (
            <Button
              data-testid={`${testId ?? `${id}-test`}CloseButton`}
              className={classes.button}
              onClick={handleRemove}
              color="secondary"
              variant="outlined"
              size="large"
            >
              Remove Flag
            </Button>
          )}
        </DialogActions>
      </Popover>
    </>
  );
};

// set the prop-types for this component
AmoFlagInputPopover.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  flagId: PropTypes.number,
  ownerName: PropTypes.string,
  lastUpdate: PropTypes.instanceOf(DateTime),
  comment: PropTypes.string,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  fieldLabel: PropTypes.string,
  fieldValue: PropTypes.string,
};

AmoFlagInputPopover.defaultProps = {
  testId: null,
  flagId: 0,
  ownerName: null,
  lastUpdate: null,
  comment: null,
  onSave: () => {},
  onRemove: () => {},
  fieldLabel: "",
  fieldValue: "",
};

export default AmoFlagInputPopover;
