/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  Grid,
  Icon,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { FaqDetails } from "./FaqDetails";
import { useDebounce } from "@focus21/hooks";

import { useForm, useWatch } from "react-hook-form";

import SearchBox from "components/SearchBox";

import colors from "constants/colors";
import { useFaqQuestions } from "./helpPageHooks";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  accordionItem: {
    marginBottom: "1rem",
    width: "95%",
  },
  faqAccordion: {
    backgroundColor: "white",
    border: `1px solid ${colors.grey.main}`,
    "&.MuiAccordion-rounded:last-child": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    "&.MuiAccordion-rounded:first-child": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "& > .MuiAccordionSummary-root > .MuiAccordionSummary-content": {
      // 16px 0px
      margin: "1rem 0",
      "&.Mui-expanded": {
        // 16px 0px 0px 0px
        margin: "1rem 0 0 0",
      },
    },
    "& > .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon": {
      "&.Mui-expanded": {
        transform: "rotate(90deg)",
      },
      "& .MuiIcon-root": {
        color: colors.black,
      },
    },
  },
  primaryTextDark: {
    color: theme.palette.primary.dark,
    marginTop: "1rem",
    width: "100%",
    "& > span > p": {
      "&:first-child": {
        marginTop: "0rem",
      },
      "&:last-child": {
        marginBottom: "0rem",
      },
      "& > a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontWeight: "bolder",
      },
    },
    "& > span > p > a:hover": {
      textDecoration: "underline",
    },
    "& > span > iframe": {
      width: "100%",
      height: "56vw",
      // 560px
      maxWidth: "35rem",
      // 315px
      maxHeight: "19.75rem",
    },
  },
  contentContainer: {
    marginLeft: "1rem",
  },
}));

/**
 * Renders the FAQ content for all FAQ items.
 *
 * @returns {React.Component} The FAQ content section.
 */
const FaqsSection = () => {
  const scrollRefs = useRef({});
  const { permalink: permalinkFromQuery } = useParams();
  const classes = useStyles();

  const [filteredFaqs, setFilteredFaqs] = useState(null);

  // Hooks
  const { control } = useForm({
    mode: "onChange",
  });
  const filter = useWatch({ control, name: "filter", defaultValue: "" });
  const { data: faqQuestions, isFetching } = useFaqQuestions("");

  const faqFilter = () => {
    if (filter.length === 0) {
      setFilteredFaqs(faqQuestions?.data);
    } else {
      const faqObject = faqQuestions.data;
      const filteredArray = faqObject.filter((value) =>
        value.question.includes(filter)
      );
      setFilteredFaqs(filteredArray);
    }
  };

  useDebounce(() => faqFilter, [filter, faqQuestions], 500);

  const submitHandler = (event) => {
    event.preventDefault();
  };

  useDebounce(
    () => {
      if (permalinkFromQuery && scrollRefs)
        document.getElementById(permalinkFromQuery)?.scrollIntoView();
    },
    [scrollRefs],
    1000
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          gutterBottom
          className={classes.primaryTextDark}
          variant="h2"
        >
          FAQs
        </Typography>
        <Divider orientation="horizontal" variant="fullWidth" light />
      </Grid>
      <Grid item container className={classes.contentContainer}>
        <Grid item>
          <form onSubmit={submitHandler}>
            <SearchBox
              prompt="Filter"
              control={control}
              id="faq-filter-box"
              name="filter"
              testId="faqFilterBox"
              on
            />
          </form>
        </Grid>
        {isFetching ? (
          <>
            <Skeleton width="100%" height="50px" />
            <Skeleton width="100%" height="50px" />
            <Skeleton width="100%" height="50px" />
          </>
        ) : (
          filteredFaqs?.map((faq) => {
            const expanded =
              permalinkFromQuery && faq.permalink === permalinkFromQuery;
            return (
              <Grid container item key={faq?.question} id={faq.permalink}>
                <Grid item className={classes.accordionItem}>
                  <Accordion
                    ref={(el) => {
                      scrollRefs.current[permalinkFromQuery] = el;
                    }}
                    className={classes.faqAccordion}
                    elevation={0}
                    defaultExpanded={expanded}
                    TransitionProps={{ unmountOnExit: true }} // Stops FAQ content from being queried when collapsed
                  >
                    <AccordionSummary
                      id="faq-summary"
                      aria-controls="faq-accordion-summary"
                      expandIcon={
                        <Icon className="material-icons">chevron_right</Icon>
                      }
                    >
                      <Typography
                        variant="h4"
                        className={classes.primaryTextDark}
                      >
                        {faq?.question}
                      </Typography>
                      &nbsp;&nbsp;
                    </AccordionSummary>
                    <FaqDetails
                      classes={classes}
                      faqId={faq?.permalink || "unknown"}
                    />
                  </Accordion>
                </Grid>
              </Grid>
            );
          }) ?? (
            <Grid container item>
              <Typography variant="h4">No FAQs found</Typography>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default FaqsSection;
