import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    // 8px
    marginRight: "0rem",
  },
}));

/**
 * This component is a customized MUI Checkbox, built to work with a react-hook-form controlled form
 *
 * @param props
 * @param {string} props.color      Theme color option
 * @param {object} props.control    The react-hook-form control, generated by useForm()
 * @param {boolean} props.disabled  Disables the component when true
 * @param {string} props.label      Label text for the component
 * @param {string} props.id         Field ID
 * @param {string} props.name       The name of the input; react-hook-form uses this to register the input
 * @param {string} props.testId     Maps to data-test-id; renamed on AMO component to avoid issues with targeting tests (maybe unnecessary)
 * @param {string} props.className  Passes in a css class
 * @param {boolean} props.required  Sets the required flag for this input (prefer handling this via a yup schema in most cases)
 * @param {string} props.variant    Sets the MUI variant for this input
 * @param {Function} props.onChangeTrigger  Event that triggers on input change
 * @param {string} props.size        Sets the MUI size for this input
 * @param {object} props.formControlClasses Passes in the MUI classes prop for the FormControlLabel component
 *
 * @returns {Function} A MUI Checkbox component wrapped with the react-hook-form controller
 */
const AmoCheckbox = ({
  color,
  control,
  disabled,
  label,
  id,
  name,
  testId,
  className,
  formControlClasses,
  required,
  variant,
  onChangeTrigger,
  size,
}) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          className={classes.checkboxLabel}
          classes={formControlClasses}
          label={label}
          control={
            <Checkbox
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              checked={field.value}
              color={color}
              disabled={disabled}
              id={id}
              data-testid={testId ?? `${id}-test`}
              className={className}
              name={name}
              variant={variant}
              required={required}
              onChange={({ target: { checked } }) => {
                field.onChange(checked);
                onChangeTrigger(checked);
              }}
              size={size}
            />
          }
        />
      )}
    />
  );
};

AmoCheckbox.propTypes = {
  className: PropTypes.string,
  formControlClasses: PropTypes.shape(),
  color: PropTypes.string,
  control: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  testId: PropTypes.string,
  variant: PropTypes.string,
  onChangeTrigger: PropTypes.func,
  size: PropTypes.string,
};

AmoCheckbox.defaultProps = {
  className: undefined,
  formControlClasses: undefined,
  color: "",
  disabled: false,
  label: undefined,
  required: false,
  testId: undefined,
  variant: "outlined",
  onChangeTrigger: () => {},
  size: "medium",
};

export default AmoCheckbox;
