import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  MenuItem,
  Grid,
  Button,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import colors from "constants/colors";
import reviewTypes from "constants/reviewTypes";
import CloseIcon from "@material-ui/icons/Close";

import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import AmoTextField from "components/inputs/AmoTextField";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import AmoSwitch from "components/inputs/AmoSwitch";

import { useIsMounted } from "hooks/useIsMounted";
import { useWrapApi } from "hooks/wrapApiHook";
import { reviewService } from "api/services/reviewsService";
import { communicationsService } from "api/services/communicationService";

import { useReviewLogLookups } from "./reviewLogDataInputHooks";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.grey.light,
    // 24px
    padding: "1.25rem",
    marginTop: 0,
    width: "100%",
    maxWidth: "30rem",
  },
  textfield: {
    background: colors.white,
    marginBottom: "1.25rem",
  },
  checkboxLabel: {
    // 14px
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  button: {
    width: "11rem",
    height: "3.125rem",
  },
  switch: {
    // 7px
    marginLeft: "-0.438rem",
    marginRight: "0.313rem",
  },
}));

/**
 * A data input component for updating review information
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the data input component [required]
 * @param {string} props.testId - data-testid of the table component
 * @param {string} props.projectStatusId - current status of the project
 * @param {number} props.objectId - id of the object related to this component (Review or Communication)
 * @param {boolean} props.showPublished - controls if the published switch should be visible or not
 * @param {string} props.type - type of the history log information (values: @see reviewTypes) [defaults to: "review"]
 * @param {Function} props.onSave - function called when user clicks on the save button (params: none)
 * @param {boolean} props.isBusy - indicates if the status is loading or not
 *
 * @returns - The data input component
 */
const ReviewDataInput = (props) => {
  const {
    id,
    testId,
    projectStatusId,
    objectId,
    showPublished,
    type,
    onSave,
    isBusy,
  } = props;

  const classes = useStyles();

  const mounted = useIsMounted();

  const [assignees, setAssignees] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const updateReviewDataInput = useWrapApi(
    reviewService.putDataInput,
    "Successfully updated review log information"
  );
  const updateCommDataInput = useWrapApi(
    communicationsService.saveHistory,
    "Successfully updated"
  );

  // define schema for form validation
  const ReviewDataInputValidationSchema = yup
    .object({
      assigneeId: yup
        .number()
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      statusId: yup
        .number()
        .transform((value) => (isNaN(value) ? projectStatusId : value))
        .nullable(),
      comment: yup.string().nullable(),
      trackChange: yup.boolean(),
      published: yup.boolean(),
    })
    .required();

  const defaultValues = {
    assigneeId: "",
    statusId: "",
    comment: "",
    trackChange: true,
    published: false,
  };

  const { control, handleSubmit, reset, formState, setValue } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(ReviewDataInputValidationSchema),
  });

  const [assigneeWatch] = useWatch({
    control,
    name: ["assigneeId"],
  });

  const [statusWatch] = useWatch({
    control,
    name: ["statusId"],
  });

  const {
    data: lookups,
    isLoading,
    isFetching,
    refetch: refetchLookupsData,
  } = useReviewLogLookups(type, objectId);

  useEffect(() => {
    if (lookups?.data) {
      if (type === reviewTypes.review) {
        const allAssignees = lookups?.data?.assignees ?? [];
        const allStatuses = lookups?.data?.statuses ?? [];

        const selectedAssignee = allAssignees.find(
          (assignee) => assignee.selected
        );
        const selectedStatus = allStatuses.find((status) => status.selected);

        reset({
          ...defaultValues,
          published: !!lookups?.data?.published,
          assigneeId: selectedAssignee?.id ?? "",
          statusId: selectedStatus?.id ?? "",
        });
        setAssignees(lookups?.data?.assignees ?? []);
        setStatuses(lookups?.data?.statuses ?? []);
      } else {
        const allStatuses = (lookups?.data ?? []).map((status) =>
          status.name === "Published" ? { ...status, disabled: true } : status
        );
        setStatuses(allStatuses);
        reset({
          ...defaultValues,
          comment: "",
          statusId: projectStatusId ?? "",
        });
      }
    }
  }, [lookups?.data, isFetching]);

  const handleClearDropdown = (fieldName, value = null) => {
    setValue(fieldName, value, { shouldDirty: true });
  };

  const handleSave = async ({
    assigneeId,
    statusId,
    comment,
    trackChange,
    published,
  }) => {
    let result;
    if (type === reviewTypes.review) {
      result = await updateReviewDataInput.call(objectId, {
        reviewId: objectId,
        assigneeId,
        statusId: statusId || projectStatusId,
        comment,
        trackChange,
        isPublished: published,
      });

      if (!mounted.current) {
        return;
      }
    } else if (type === reviewTypes.communications) {
      result = await updateCommDataInput.call({
        projectId: +objectId,
        statusId: statusId || projectStatusId,
        comment,
      });

      if (!mounted.current) {
        return;
      }

      reset({
        ...defaultValues,
        comment: "",
      });
    }

    if (result && !result?.error) {
      onSave();
      refetchLookupsData();
    }
  };

  const disableSaveButton = () => {
    // Exclude trackChange (Clear Changes checkbox) from dirty logic
    const { trackChange, ...dirtyFields } = formState?.dirtyFields;
    return !Object.keys(dirtyFields)?.length;
  };

  const isPreviousActionable = () =>
    statuses.some((status) => status.id === projectStatusId);

  return (
    <>
      <Grid
        id={id}
        container
        direction="column"
        className={classes.container}
        spacing={0}
      >
        {type === reviewTypes.review && (
          <Grid item>
            {isLoading ? (
              <Skeleton />
            ) : (
              <>
                <AmoTextField
                  control={control}
                  id={`${id}-assignee`}
                  name="assigneeId"
                  testId={testId ?? `${id}AssigneeTest`}
                  className={classes.textfield}
                  select
                  fullWidth
                  variant="outlined"
                  label="Set Assignee"
                  placeholder="Set Assignee"
                  InputProps={{
                    endAdornment: assigneeWatch ? (
                      <InputAdornment position="start">
                        <IconButton
                          id={`${id}-clear-assignee`}
                          data-testid={testId ?? `${id}ClearAssigneeTest`}
                          size="small"
                          onClick={() => handleClearDropdown("assigneeId")}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                >
                  {assignees.map((value) => (
                    <MenuItem
                      key={`review-data-input-assignee-${value.id}`}
                      value={value.id}
                    >
                      {value.name}
                    </MenuItem>
                  ))}
                </AmoTextField>
              </>
            )}
          </Grid>
        )}
        <Grid item>
          {isLoading || isBusy ? (
            <Skeleton />
          ) : (
            <AmoTextField
              control={control}
              id={`${id}-status`}
              name="statusId"
              testId={testId ?? `${id}StatusTest`}
              className={classes.textfield}
              select
              fullWidth
              variant="outlined"
              label={
                type === reviewTypes.review
                  ? "Set Status"
                  : "Set Communication Status"
              }
              placeholder={
                type === reviewTypes.review
                  ? "Set Status"
                  : "Set Communication Status"
              }
              InputProps={{
                endAdornment:
                  !isPreviousActionable() && statusWatch ? (
                    <InputAdornment position="start">
                      <IconButton
                        id={`${id}-clear-status-id`}
                        data-testid={testId ?? `${id}ClearStatusIdTest`}
                        size="small"
                        onClick={() => handleClearDropdown("statusId")}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
            >
              {statuses.map((value) => (
                <MenuItem
                  key={`review-data-input-status-${value.id}`}
                  value={value.id}
                  disabled={value.disabled}
                >
                  {value.name}
                </MenuItem>
              ))}
            </AmoTextField>
          )}
        </Grid>
        <Grid item>
          {isLoading ? (
            <Skeleton />
          ) : (
            <AmoTextField
              multiline
              rows={1}
              rowsMax={10}
              control={control}
              label="Enter comment"
              id={`${id}-comment`}
              name="comment"
              testId={testId ?? `${id}CommentTest`}
              className={classes.textfield}
              variant="outlined"
              fullWidth
            />
          )}
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems={type === reviewTypes.review ? "center" : "right"}
          >
            {type === reviewTypes.review && (
              <Grid item xs>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <Grid container direction="column">
                    <Grid item>
                      <AmoCheckbox
                        control={control}
                        id={`${id}-clear-changes`}
                        name="trackChange"
                        testId={testId ?? `${id}ClearChangesTest`}
                        color="primary"
                        label="Clear Changes"
                        formControlClasses={{
                          label: classes.checkboxLabel,
                        }}
                        size="small"
                      />
                    </Grid>
                    {showPublished && (
                      <Grid item>
                        <AmoSwitch
                          color="primary"
                          control={control}
                          id={`${id}-published`}
                          name="published"
                          testId={testId ?? `${id}PublishedTest`}
                          label="Published"
                          labelPlacement="end"
                          className={classes.switch}
                          formControlClasses={{
                            label: classes.checkboxLabel,
                          }}
                          size="small"
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item>
              {isLoading ? (
                <Skeleton />
              ) : (
                <Button
                  id={`${id}-save-button`}
                  data-testid={testId ?? `${id}SaveButtonTest`}
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  size="medium"
                  disableElevation
                  onClick={handleSubmit((formValues) => handleSave(formValues))}
                  disabled={
                    disableSaveButton() ||
                    formState.isSubmitted ||
                    formState.isSubmitting
                  }
                >
                  <Typography align="center" variant="body2">
                    Save
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// set the prop-types for this component
ReviewDataInput.propTypes = {
  id: PropTypes.string,
  testId: PropTypes.string,
  projectStatusId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  showPublished: PropTypes.bool,
  type: PropTypes.oneOf([reviewTypes.review, reviewTypes.communications]),
  onSave: PropTypes.func,
  isBusy: PropTypes.bool,
};

ReviewDataInput.defaultProps = {
  id: "",
  testId: "",
  showPublished: false,
  type: reviewTypes.review,
  onSave: () => {},
  isBusy: false,
};

export default ReviewDataInput;
