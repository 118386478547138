import React from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Button,
  makeStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useWatch, useFormContext } from "react-hook-form";
import { RemoveCircleOutline } from "@material-ui/icons";
import AmoTextField from "components/inputs/AmoTextField";
import AmoSwitch from "components/inputs/AmoSwitch";
import AmoRichTextEditor from "components/inputs/AmoRichTextEditor";
import { useGlobalStyles } from "hooks/globalStylesHook";
import QuestionnaireQuestionConditions, {
  defaultQuestionCondition,
} from "./QuestionnaireQuestionConditions";
import QuestionnaireQuestionOptions, {
  defaultQuestionOptions,
} from "./QuestionnaireQuestionOptions";
import PropTypes from "prop-types";
import { questionnaireAnswerTypes } from "constants/questionnaireAnswerTypes";
import { questionnaireTypes } from "constants/questionnaireTypes";

const useStyles = makeStyles((theme) => ({
  questionAnswerDeleteButton: {
    color: "#666666",
  },
  questionAnswerDeleteButtonIcon: {
    marginRight: "0.5rem",
  },
  questionAnswerDeleteButtonText: {
    lineHeight: "1.2",
  },
  questionAnswerArea: {
    border: "0.063rem solid #666666",
    marginTop: "1rem",
  },
  addQuestionRow: {
    paddingRight: "0 !important",
  },
}));

export const defaultQuestion = () => ({
  text: "",
  descriptionText: "",
  answerTypeId: questionnaireAnswerTypes.shortAnswer.value,
  options: defaultQuestionOptions(),
  required: true,
  questionCharacterLimit: "",
  visibilityConditionsSwitch: false,
  visibilityConditions: [defaultQuestionCondition()],
});

/**
 * Component to handle Questionnaire's dynamic questions list behavior
 *
 * @param {object} props
 * @param {boolean} props.hasLimitedEdition If true, the questionnaire form has limited edition
 * @param {boolean} props.isLoading Controls whether the questionnaire data is loading
 * @param {object[]} props.questions The react-hook-form field array
 * @param {Function} props.appendQuestion The react-hook-form field array function to append new question
 * @param {Function} props.removeQuestion The react-hook-form field array function to remove one question
 *
 * @returns {Function} The questionnaire's questions list
 */
const QuestionnaireQuestions = ({
  hasLimitedEdition,
  isLoading,
  questions,
  appendQuestion: append,
  removeQuestion: remove,
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const { clearErrors, control, setError, setValue, watch } = useFormContext();

  const questionnaireType = useWatch({
    control,
    name: "type",
    defaultValue: "",
  });

  const watchedQuestions = useWatch({
    control,
    name: "questions",
    defaultValue: [defaultQuestion()],
  });

  const questionsAvailableForConditionsExist = (currentQuestionIndex) =>
    watchedQuestions?.some(
      (question, questionIndex) =>
        questionIndex < currentQuestionIndex &&
        [
          questionnaireAnswerTypes.yesNo.value,
          questionnaireAnswerTypes.singleSelectMultipleChoice.value,
          questionnaireAnswerTypes.multiSelectMultipleChoice.value,
        ].includes(question.answerTypeId)
    );

  const answerTypes =
    questionnaireType === questionnaireTypes.assetMgmt
      ? Object.values(questionnaireAnswerTypes)
      : Object.values(questionnaireAnswerTypes).filter(
          ({ value }) =>
            value !== questionnaireAnswerTypes.assetManagementPlanUpload.value
        );

  const onAnswerTypeChange = (value, index) => {
    const isSelect = [
      questionnaireAnswerTypes.multiSelectMultipleChoice.value,
      questionnaireAnswerTypes.singleSelectMultipleChoice.value,
    ].includes(value);

    if (!isSelect) {
      cleanAffectedConditions(index);
    }
  };

  const removeQuestion = (index) => {
    cleanAffectedConditions(index);
    remove(index);
  };

  const cleanAffectedConditions = (index) => {
    watchedQuestions?.forEach((question, i) =>
      setValue(
        `questions[${i}].visibilityConditions`,
        question.visibilityConditions?.filter(
          (condition) => condition.questionConditionQuestionNumber - 1 !== index
        ) ?? []
      )
    );
  };

  return (
    <>
      {isLoading && <Skeleton width="100%" height="18.75rem" variant="rect" />}
      {!isLoading && !questions?.length && (
        <Grid item>
          <Typography>No questions found</Typography>
        </Grid>
      )}
      {!isLoading &&
        questions?.map((item, index) => {
          const disableDeleteQuestion = index === 0 && questions?.length === 1;
          const isAvailableForConditions = questionsAvailableForConditionsExist(
            index
          );

          const watchedAnswerTypeId = watch(`questions[${index}].answerTypeId`);

          const answerTypeFields = {
            [questionnaireAnswerTypes.shortAnswer.value]: (
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs>
                  <AmoTextField
                    control={control}
                    label="Character limit (optional)"
                    id={`questionnaire-question-character-limit-${index}`}
                    name={`questions[${index}].characterLimit`}
                    testId={`questionnaireQuestionCharacterLimit${index}`}
                    variant="outlined"
                    fullWidth
                    disabled={hasLimitedEdition}
                    pattern={/[^0-9]+/g}
                  />
                </Grid>
                <Grid item xs />
              </Grid>
            ),
            [questionnaireAnswerTypes.number.value]: (
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs>
                  <AmoTextField
                    control={control}
                    label="Max Decimal Places (optional)"
                    id={`questionnaire-question-character-limit-${index}`}
                    name={`questions[${index}].characterLimit`}
                    testId={`questionnaireQuestionCharacterLimit${index}`}
                    variant="outlined"
                    fullWidth
                    disabled={hasLimitedEdition}
                    pattern={/[^0-9]+/g}
                  />
                </Grid>
                <Grid item xs />
              </Grid>
            ),
            [questionnaireAnswerTypes.singleSelectMultipleChoice.value]: (
              <QuestionnaireQuestionOptions
                nestIndex={index}
                hasLimitedEdition={hasLimitedEdition}
              />
            ),
            [questionnaireAnswerTypes.multiSelectMultipleChoice.value]: (
              <QuestionnaireQuestionOptions
                nestIndex={index}
                hasLimitedEdition={hasLimitedEdition}
              />
            ),
          };

          return (
            <Grid
              key={item.arrayFieldId}
              item
              className={classes.questionAnswerArea}
            >
              <Grid container direction="column" spacing={3} wrap="nowrap">
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h6">Question {index + 1}</Typography>
                  </Grid>
                  <Grid item>
                    {!hasLimitedEdition && (
                      <Button
                        className={classes.questionAnswerDeleteButton}
                        onClick={() => removeQuestion(index)}
                        disabled={disableDeleteQuestion}
                      >
                        <RemoveCircleOutline
                          className={classes.questionAnswerDeleteButtonIcon}
                          color={
                            disableDeleteQuestion ? undefined : "secondary"
                          }
                        />
                        <Typography
                          className={classes.questionAnswerDeleteButtonText}
                          variant="h5"
                          color={
                            disableDeleteQuestion ? undefined : "secondary"
                          }
                        >
                          Delete
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  spacing={4}
                  alignItems="center"
                >
                  <Grid item xs>
                    <AmoTextField
                      control={control}
                      id={`questionnaire-question-answer-type-${index}`}
                      name={`questions[${index}].answerTypeId`}
                      testId={`questionnaireQuestionAnswerType${index}`}
                      fullWidth
                      select
                      label="Answer type"
                      variant="outlined"
                      onChangeTrigger={(value) =>
                        onAnswerTypeChange(value, index)
                      }
                      required
                      disabled={hasLimitedEdition}
                    >
                      <MenuItem
                        key={`questionnaireQuestionAnswerTypeNone${item.arrayFieldId}`}
                        value=""
                        disabled
                      >
                        Answer type
                      </MenuItem>
                      {answerTypes?.map(({ text, value }) => (
                        <MenuItem
                          key={`questionnaireQuestionAnswerType-${value}-${item.arrayFieldId}`}
                          value={value}
                        >
                          {text}
                        </MenuItem>
                      ))}
                    </AmoTextField>
                  </Grid>
                  <Grid item xs>
                    <AmoSwitch
                      color="primary"
                      control={control}
                      id={`questionnaire-question-required-${index}`}
                      name={`questions[${index}].required`}
                      testId={`questionnaireQuestionRequired${index}`}
                      label="Required Question"
                      labelPlacement="end"
                      isChangesAllowed={!hasLimitedEdition}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <AmoRichTextEditor
                    control={control}
                    label="Question Text"
                    required
                    id={`questionnaire-question-text-${index}`}
                    name={`questions[${index}].text`}
                    testId={`questionnaireQuestionText${index}`}
                    setError={setError}
                    clearErrors={clearErrors}
                    isSingleLineInput
                    hideBold
                  />
                </Grid>
                <Grid item>
                  <AmoRichTextEditor
                    control={control}
                    label="Description Text (optional)"
                    id={`questionnaire-question-description-text-${index}`}
                    name={`questions[${index}].descriptionText`}
                    testId={`questionnaireQuestionDescriptionText${index}`}
                    setError={setError}
                    clearErrors={clearErrors}
                    simpleToolbar
                    adaptableMultiline
                  />
                </Grid>
                {answerTypeFields[watchedAnswerTypeId]}
                {isAvailableForConditions && !hasLimitedEdition && (
                  <Grid item>
                    <AmoSwitch
                      color="primary"
                      control={control}
                      id={`questionnaire-question-visibility-conditions-${index}`}
                      name={`questions[${index}].visibilityConditionsSwitch`}
                      testId={`questionnaireQuestionVisibilityConditions${index}`}
                      label="Visibility conditions"
                      labelPlacement="end"
                    />
                  </Grid>
                )}
              </Grid>
              {isAvailableForConditions && (
                <QuestionnaireQuestionConditions
                  nestIndex={index}
                  hasLimitedEdition={hasLimitedEdition}
                />
              )}
            </Grid>
          );
        })}
      {!hasLimitedEdition && (
        <Grid
          item
          container
          direction="row-reverse"
          className={classes.addQuestionRow}
        >
          <Grid item>
            <Button
              className={globalClasses.button}
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => append(defaultQuestion())}
            >
              <Typography variant="body2">Add Question</Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

QuestionnaireQuestions.propTypes = {
  hasLimitedEdition: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  appendQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
};

export default QuestionnaireQuestions;
