import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography } from "@material-ui/core";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import { formatToCurrency } from "utils/number";

const useStyles = makeStyles((theme) => ({
  chart: {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    padding: 0,
  },
  legendItem: {
    width: "17rem",
  },
  legendLine: {
    height: "0.5rem",
    width: "3rem",
  },
  legendRect: {
    height: "1rem",
    width: "3rem",
  },
  legendContainer: {
    marginTop: "2rem",
  },
}));

const AerChart = (props) => {
  const { width, height, data } = props;
  const classes = useStyles();

  const processedData = data.map((item) => ({
    ...item,
    expenditures: -1 * item.expenditures, // expected to be passed as positive value from API
  }));
  const sectionOrder = [
    "openBalance",
    "netTransfers",
    "revenues",
    "expenditures",
    "closeBalance",
  ];
  const renderLegend = (renderLegendProps) => {
    const { payload } = renderLegendProps;

    // sort sections by sectionOrder
    const sections = sectionOrder.map(
      (dataKey) =>
        payload.find(
          ({ payload: legendItem }) => legendItem.dataKey === dataKey
        ).payload
    );
    return (
      <Grid container spacing={2} className={classes.legendContainer}>
        {sections.map((legendItem) => (
          <Grid item className={classes.legendItem} key={legendItem.dataKey}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Box
                  className={
                    legendItem.stroke ? classes.legendLine : classes.legendRect
                  }
                  sx={{ bgcolor: legendItem.stroke || legendItem.fill }}
                />
              </Grid>
              <Grid item>
                <Typography>{legendItem.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <ComposedChart
      width={width}
      height={height}
      data={processedData}
      stackOffset="sign" // Used to correctly show negative bars
      barCategoryGap="25%"
      className={classes.chart}
    >
      <XAxis dataKey="year" hide />
      <ReferenceLine y={0} stroke="black" />
      <Tooltip
        labelStyle={{ textAlign: "center" }}
        formatter={(value) => (
          <span style={{ color: "black", fontWeight: 500 }}>
            {formatToCurrency(value)}
          </span>
        )}
        itemSorter={(item) => sectionOrder.indexOf(item.dataKey)}
        itemStyle={{ textAlign: "right" }}
      />
      <Legend align="left" content={renderLegend} />
      <Bar dataKey="revenues" name="Revenues" stackId="aer" fill="#3422FF" />
      <Bar
        dataKey="netTransfers"
        name="Net Transfers"
        stackId="aer"
        fill="#A3C2FF"
      />
      <Bar
        dataKey="openBalance"
        name="Opening Balance"
        stackId="aer"
        fill="#2A7F60"
      />
      <Bar
        dataKey="expenditures"
        name="Expenditures"
        stackId="aer"
        fill="#FFC942"
      />
      <Line
        type="linear"
        dataKey="closeBalance"
        name="Closing Balance"
        stroke="#00E632"
        legendType="rect"
        dot={false}
        strokeWidth={6}
      />
    </ComposedChart>
  );
};

AerChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      openBalance: PropTypes.number,
      closeBalance: PropTypes.number,
      revenues: PropTypes.number,
      netTransfers: PropTypes.number,
      expenditures: PropTypes.number,
    })
  ).isRequired,
};

AerChart.defaultProps = {};

export default AerChart;
