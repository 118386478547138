import React from "react";
import { Icon, makeStyles, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { projectCategoryIcons } from "constants/projectCategoryIcons";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "100%",
    textAlign: "center",
  },
}));

/**
 * Displays icon of a project category by code as well as a tooltip with the category name.
 *
 * @param {object} props
 * @param {string} props.code The code of the category
 * @param {string} props.name The name of the category
 * @returns {React.Component} A project category icon component.
 */
const ProjectCategoryIcon = ({ code, name }) => {
  const classes = useStyles();
  const svgIcon = projectCategoryIcons[code];
  return (
    <Tooltip title={name}>
      <Icon
        color="primary"
        className={clsx("material-icons-outlined", classes.icon)}
      >
        <img src={svgIcon} height={25} width={25} alt="Category Icon" />
      </Icon>
    </Tooltip>
  );
};

ProjectCategoryIcon.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ProjectCategoryIcon;
