import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import { formatToCurrency } from "utils/number";

const useStyles = makeStyles((theme) => ({
  container: {
    // fontSize: "1.5rem",
    marginTop: "1.5rem",
  },
  label: {
    fontSize: "0.875rem",
  },
  skeletonWidth: {
    maxWidth: "20rem",
  },
}));

/**
 * This financials component to be used on the Communications - Project Details Page.
 *
 * @param {object} props - object containing props for this component
 * @param {object} props.projectData - project information used by this component
 * @param {boolean} props.isFetching - controls whether the component project data is being fetched
 *
 * @returns {Function} The financials component
 */
const ProjectDetailsFinancials = (props) => {
  const { projectData, isFetching } = props;

  const classes = useStyles();

  const loadingTextValue = (isFetchingRef, value, isCurrency = false) => (
    <>
      {isFetchingRef ? (
        <Skeleton
          width="100%"
          height="1.75rem"
          variant="rect"
          className={classes.skeletonWidth}
        />
      ) : (
        (isCurrency ? formatToCurrency(value) : value) ?? "--"
      )}
    </>
  );

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={0}
        wrap="nowrap"
        className={classes.container}
      >
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Total Cost
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(isFetching, projectData?.totalCost, true)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Start Date
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(isFetching, projectData?.startDate)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  CCBF Budgeted
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(
                    isFetching,
                    projectData?.ccbfBudgeted,
                    true
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  End of Construction
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(
                    isFetching,
                    projectData?.constructionEndDate
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  CCBF Spent To-date
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(
                    isFetching,
                    projectData?.ccbfSpentToDate,
                    true
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Other federal funds?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(isFetching, projectData?.otherFederalFunds)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Provincial funds?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(isFetching, projectData?.provincialFunds)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container alignItems="flex-end" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Stacking limits on federal funding exceeded?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {loadingTextValue(isFetching, projectData?.stackingLimits)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// set the prop-types for this component
ProjectDetailsFinancials.propTypes = {
  projectData: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool,
};

ProjectDetailsFinancials.defaultProps = {
  isFetching: false,
};

export default ProjectDetailsFinancials;
