import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import colors from "constants/colors";
import { Grid, LinearProgress, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMunicipalContext } from "contexts/MunicipalContext";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { DateTime } from "luxon";
import { reportStatusWidgetService } from "api/services/reportStatusWidgetService";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { reporting } from "constants/reporting";
import { useIsMounted } from "hooks/useIsMounted";
import { useWindowDimensions } from "hooks/windowDimensionsHook";

const ReportingStatusIcon = ({ iconType, classes }) => {
  switch (iconType) {
    case "alert": {
      return (
        <Box classes={{ root: classes.icon }}>
          <WarningIcon />
        </Box>
      );
    }
    case "checkmark": {
      return (
        <Box classes={{ root: classes.icon }}>
          <CheckCircleOutlinedIcon />
        </Box>
      );
    }
    default: {
      return null;
    }
  }
};

// create the CSS needed for this component
const useStyles = makeStyles((theme) => ({
  box: {
    height: "100%",
    backgroundColor: colors.grey.light,
    alignItems: "flex-start",
    display: "flex",
    padding: "1rem",
  },
  gridContainer: {
    alignItems: "center",
    width: "100%",
  },
  barItem: {
    width: "80%",
  },
  line: {
    width: "100%",
    height: "3rem",
    borderRadius: "1.5rem",
    backgroundColor: colors.grey.medium,
  },
  bar: {
    width: "100%",
    borderRadius: "1.5rem",
  },
  barColorPrimary: {
    // handles the "done" portion of the bar
    backgroundColor: ({ barColor }) => barColor ?? colors.grey.medium,
  },
  icon: {
    color: ({ barColor }) => barColor ?? colors.grey.medium,
  },
  statusText: {
    fontSize: "1.25rem",
  },
  statusTextGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  calendarGridItem: {
    display: "flex",
    position: "relative",
    width: "80%",
  },
  datesGrid: {
    display: "flex",
    height: "2rem",
    width: "100%",
  },
  datesGridItem: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    overflow: "visible",
    flexWrap: "wrap",
    whiteSpace: "nowrap",
    width: ({ numberOfDays }) => `${100 / (numberOfDays ?? 366)}%`,
    fontSize: ".5rem",
    zIndex: 10,
  },
  datesGridEmptyItem: {
    width: ({ numberOfDays }) => `${100 / (numberOfDays ?? 366)}%`,
  },
  dateItemTickMark: {
    width: ({ numberOfDays }) => `${100 / (numberOfDays ?? 366)}%`,
    backgroundColor: colors.grey.medium,
    zIndex: 1,
  },
  dateItemRow: {
    display: "flex",
    width: "100%",
    height: "1rem",
  },
  dateText: {
    fontSize: "0.8rem",
  },
}));

// if this object changes, then the tests for this component may also need to change
const defaultState = {
  yearStart: DateTime.local(reporting.calendarYear),
  yearEnd: DateTime.local(reporting.calendarYear + 1),
  reportingDueDate: DateTime.local(reporting.calendarYear, 3, 31),
  thresholdDays: 5,
  paymentDates: [],
  annualStatus: false,
};

/**
 * This component gives the user a feel of where they are in the timeline of the reporting lifecycle
 *
 * @param {object} props
 * @param {DateTime} props.displayDate - a configurable "today" mainly for testing
 * @returns - the Reporting Status Widget that a Municipal User would see on their landing page.
 */
const ReportingStatusWidget = (props) => {
  const { displayDate } = props;

  const mounted = useIsMounted();

  const [displayedDate] = useState(displayDate);
  const { selectedMunicipalityId } = useMunicipalContext();
  const { showSnackbar } = useSnackbar();

  const [componentData, setComponentData] = useState({});

  // create two arrays of grid items
  const [dateGrid, setDateGrid] = useState({
    // one for the hash marks
    markItems: [],
    // and one for the dates
    items: [],
    markItemsExtra: [],
    itemsExtra: [],
  });

  const stylesProps = {};
  const classes = useStyles(stylesProps);

  const dateGridRef = useRef(null);
  const { screenWidth } = useWindowDimensions();

  const getData = async () => {
    try {
      const { data } = await reportStatusWidgetService.getById(
        selectedMunicipalityId
      );

      if (!mounted.current) {
        return;
      }

      const yearStart = DateTime.fromISO(data.yearStart);
      const yearEnd = DateTime.fromISO(data.yearEnd);

      setComponentData({
        ...data,
        yearStart,
        yearEnd,
        reportingDueDate: DateTime.fromISO(data.reportingDueDate),
        paymentDates: data.paymentDates?.map((value) =>
          DateTime.fromISO(value)
        ),
        // number of days in bar
        numberOfDays: yearEnd.diff(yearStart, "days").toObject().days + 1,
      });
    } catch (error) {
      if (!mounted.current) {
        return;
      }

      if (error && error.response.status !== 404) {
        setComponentData(defaultState);
      } else {
        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
    }
  };

  const emptyGridItem = (rowNum, dayNum) => (
    <Grid
      item
      key={`${rowNum}-${dayNum}`}
      classes={{ root: classes.datesGridEmptyItem }}
    />
  );
  const markGridItem = (rowNum, dayNum) => (
    <Grid
      key={`${rowNum}-${dayNum}`}
      item
      classes={{ root: classes.dateItemTickMark }}
    />
  );
  const dateGridItem = (rowNum, dayNum, cellData) => (
    <Grid
      key={`${rowNum}-${dayNum}`}
      item
      classes={{ root: classes.datesGridItem }}
    >
      <Typography variant="body2" component="span">
        {cellData.date.toLocaleString(DateTime.DATE_FULL)}
      </Typography>
      <Typography
        variant="body1"
        component="span"
        classes={{ root: classes.dateText }}
      >
        {cellData.progressText}
      </Typography>
    </Grid>
  );

  // this useEffect grabs data from the database
  useLayoutEffect(() => {
    if (selectedMunicipalityId && selectedMunicipalityId > 0) {
      getData();
    }
  }, [selectedMunicipalityId]);

  useEffect(() => {
    const dateGridWidth = dateGridRef?.current?.offsetWidth;
    if (Object.keys(componentData).length > 0) {
      let secondRowOffset = 40;
      if (dateGridWidth) {
        const tickExpectedWidthPerc = 100 / (componentData.numberOfDays ?? 366);
        const tickExpectedWidth = (dateGridWidth * tickExpectedWidthPerc) / 100;
        secondRowOffset = Math.ceil(145 / tickExpectedWidth);
      }

      // now we have all the data we need, set the styles
      // const classes = useStyles({ barColor, numberOfDays });
      // set the three important dates
      const progressData = [];
      progressData.push({
        dayNumber: 0,
        date: componentData.yearStart,
        progressText: `${reporting.currentReportingYear} reporting open`,
        isSecondRow: false,
      });
      progressData.push({
        dayNumber: componentData.numberOfDays - 1,
        date: componentData.yearEnd,
        progressText: `${reporting.calendarYear} reporting open`,
        isSecondRow: false,
      });
      const reportingDayNumber = componentData.reportingDueDate
        .diff(componentData.yearStart, "days")
        .toObject().days;
      progressData.push({
        dayNumber: reportingDayNumber,
        date: componentData.reportingDueDate,
        progressText: "Reporting due",
        isSecondRow:
          reportingDayNumber <= secondRowOffset ||
          Math.abs(componentData.numberOfDays - 1 - reportingDayNumber) <=
            secondRowOffset,
      });

      // find and set the next payment date
      // create a copy of the array and sort it
      const sortedDates = componentData.paymentDates
        .slice()
        .sort((date1, date2) => date1.startOf("day") - date2.startOf("day"));
      const nextPaymentDate = sortedDates.find(
        (value) => value.startOf("day") >= displayedDate.startOf("day")
      );
      if (nextPaymentDate) {
        const paymentDayNumber = nextPaymentDate
          .diff(componentData.yearStart, "days")
          .toObject().days;
        progressData.push({
          dayNumber: paymentDayNumber,
          date: nextPaymentDate,
          progressText: "Estimated next payment",
          isSecondRow: progressData.some(
            (data) =>
              Math.abs(paymentDayNumber - data.dayNumber) <= secondRowOffset &&
              !data.isSecondRow
          ),
        });
      }

      const daysWithDates = progressData.map((item) => item.dayNumber);

      const dateGridMarkItems = [];
      const dateGridItems = [];
      const dateGridMarkItemsExtra = [];
      const dateGridItemsExtra = [];

      // set the displayed values based on where they are in the "calendar"
      for (const dayNum of Array(componentData.numberOfDays).keys()) {
        if (daysWithDates.includes(dayNum)) {
          // create a cell for this day with date
          const cellData = progressData.find(
            (item) => item.dayNumber === dayNum
          );

          if (cellData.isSecondRow) {
            dateGridMarkItems.push(markGridItem(1, dayNum));
            dateGridItems.push(markGridItem(2, dayNum));
            dateGridMarkItemsExtra.push(markGridItem(3, dayNum));
            dateGridItemsExtra.push(dateGridItem(4, dayNum, cellData));
          } else {
            dateGridMarkItems.push(markGridItem(1, dayNum));
            dateGridItems.push(dateGridItem(2, dayNum, cellData));
            dateGridMarkItemsExtra.push(emptyGridItem(3, dayNum));
            dateGridItemsExtra.push(emptyGridItem(4, dayNum));
          }
        } else {
          // return an empty grid cell
          dateGridMarkItems.push(emptyGridItem(1, dayNum));
          dateGridItems.push(emptyGridItem(2, dayNum));
          dateGridMarkItemsExtra.push(emptyGridItem(3, dayNum));
          dateGridItemsExtra.push(emptyGridItem(4, dayNum));
        }
      }

      setDateGrid({
        markItems: dateGridMarkItems,
        items: dateGridItems,
        markItemsExtra: dateGridMarkItemsExtra,
        itemsExtra: dateGridItemsExtra,
      });
    }
  }, [
    Object.keys(componentData).length,
    dateGridRef?.current?.offsetWidth,
    screenWidth,
  ]);

  if (Object.keys(componentData).length > 0) {
    let barColor = null;
    let statusText = null;
    let boldStatusText = null;
    let iconType = null;
    const progressValue =
      ((displayedDate - componentData.yearStart) * 100) /
      (componentData.yearEnd - componentData.yearStart);

    const reportingComplete = componentData.annualStatus;

    const thresholdDate = componentData.reportingDueDate.minus({
      days: componentData.thresholdDays,
    });

    // calculates the number of days until the reporting due date as a whole number
    const getDaysLeft = () => {
      if (componentData) {
        // converts to a new DateTime object without the Time portion
        const reportingDueDate = DateTime.fromFormat(
          componentData.reportingDueDate.toFormat("D"),
          "M/d/yyyy"
        );
        // converts to a new DateTime object without the Time portion
        const dateOfDisplay = DateTime.fromFormat(
          displayedDate.toFormat("D"),
          "M/d/yyyy"
        );
        return reportingDueDate.diff(dateOfDisplay, "days").toObject().days;
      }
      return 0;
    };

    // now calculate the variable parts of the component
    if (reportingComplete) {
      barColor = colors.green.main;
      statusText = `${reporting.currentReportingYear}'s report is complete `;
      iconType = "checkmark";
    } else if (
      displayedDate >= thresholdDate &&
      displayedDate <= componentData.reportingDueDate
    ) {
      // if within threshold days from due date, return yellow
      barColor = colors.yellow;
      iconType = "alert";
      const daysRemaining = getDaysLeft();
      statusText = `${reporting.currentReportingYear}'s report is due `;
      if (daysRemaining === 0) {
        boldStatusText = `today`;
      } else {
        statusText += "in ";
        if (daysRemaining === 1) {
          boldStatusText = `${daysRemaining} day`;
        } else {
          boldStatusText = `${daysRemaining} days`;
        }
      }
    } else if (displayedDate > componentData.reportingDueDate) {
      // if we're after the reporting due date, display yellow bar with message
      barColor = colors.yellow;
      iconType = "alert";
      statusText = `Please submit ${reporting.currentReportingYear}'s report as soon as possible`;
      boldStatusText = "";
    } else {
      // if before the threshold date, return green.dark
      const daysRemaining = getDaysLeft();
      barColor = colors.green.dark;
      statusText = `${reporting.currentReportingYear}'s report is due in `;
      boldStatusText = `${daysRemaining} days`;
      iconType = "none";
    }

    stylesProps.barColor = barColor;
    stylesProps.numberOfDays = componentData.numberOfDays;

    // this sets the icon for the text status at the top of the widget
    const icon = ReportingStatusIcon({ iconType, classes });

    // finally prepare the component
    return (
      <>
        <Box
          classes={{
            root: classes.box,
          }}
        >
          <Grid
            container
            classes={{
              root: classes.gridContainer,
            }}
            direction="column"
            spacing={0}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                spacing={1}
                classes={{
                  root: classes.statusTextGrid,
                }}
              >
                <Grid item>{icon}</Grid>
                <Grid item>
                  <Typography
                    classes={{ root: classes.statusText }}
                    variant="body1"
                  >
                    {statusText}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    classes={{ root: classes.statusText }}
                    variant="body2"
                  >
                    {boldStatusText}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ height: "1rem" }} />
            <Grid
              item
              classes={{
                root: classes.barItem,
              }}
            >
              <LinearProgress
                data-testid="progressBar"
                id="progressBar"
                classes={{
                  root: classes.line,
                  bar: classes.bar,
                  colorPrimary: classes.colorPrimary,
                  barColorPrimary: classes.barColorPrimary,
                }}
                variant="determinate"
                value={progressValue}
                color="primary"
              />
            </Grid>
            <Grid item classes={{ root: classes.calendarGridItem }}>
              <Grid
                container
                direction="row"
                classes={{ root: classes.datesGrid }}
                spacing={0}
                ref={dateGridRef}
              >
                <Grid item classes={{ root: classes.dateItemRow }}>
                  {dateGrid.markItems}
                </Grid>
                <Grid item classes={{ root: classes.dateItemRow }}>
                  {dateGrid.items}
                </Grid>
                <Grid item classes={{ root: classes.dateItemRow }}>
                  {dateGrid.markItemsExtra}
                </Grid>
                <Grid item classes={{ root: classes.dateItemRow }}>
                  {dateGrid.markItemsExtra}
                </Grid>
                <Grid item classes={{ root: classes.dateItemRow }}>
                  {dateGrid.itemsExtra}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
  return null;
};

// default the display date to today's date
ReportingStatusWidget.defaultProps = {
  displayDate: DateTime.now(),
};

// allow devs to pass in a date for testing
ReportingStatusWidget.propTypes = {
  displayDate: PropTypes.instanceOf(DateTime),
};

// default the display date to today's date
ReportingStatusIcon.defaultProps = {
  classes: {},
  iconType: undefined,
};

// allow devs to pass in a date for testing
ReportingStatusIcon.propTypes = {
  classes: PropTypes.shape(),
  iconType: PropTypes.string,
};

export default ReportingStatusWidget;
