import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  TextField,
  Button,
  Popper,
  Grid,
  Typography,
  Icon,
} from "@material-ui/core";
import colors from "constants/colors";
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/hooks";
import {
  AmoNumberFormat,
  CurrencyNumberFormat,
} from "components/inputs/AmoNumberFormat";
import { formatToCurrency, customRound } from "utils/number";
import AmoTableFilterNumericSlider from "./AmoTableFilterNumericSlider";
import AmoTableFilterNumericSliderThumb from "./AmoTableFilterNumericSliderThumb";

const useStyles = makeStyles((theme) => ({
  filterButton: {
    height: "3.5rem",
    maxWidth: "12.5rem",
    "& .MuiButton-label": {
      fontWeight: "normal",
      color: "rgba(0, 0, 0, 0.5)",
      justifyContent: "space-between",
    },
  },
  filterButtonText: {
    fontSize: "0.875rem",
  },
  filterButtonActive: {
    border: `1px solid ${colors.green.main}`,
  },
  rootContainer: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.green.main}`,
    borderRadius: "5px",
    zIndex: 99,
    marginTop: "0.75rem",
  },
  labelTitle: {
    fontWeight: 500,
  },
  contentContainer: {
    padding: "1rem",
  },
  actionContainer: {
    borderTop: `1px solid ${colors.grey.medium}`,
    padding: "0.5rem 0rem 0.75rem 0.75rem",
  },
  actionButton: {
    height: "3.125rem",
    width: "6.25rem",
    marginRight: "0.5rem",
  },
}));

/**
 * A table filter component for filtering the value of a column
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.fieldKey - key of the item property that contain this filter value [required]
 * @param {string} props.tableId - id of the table component
 * @param {string} props.label - text to display in the filter input label
 * @param {object} props.range - numeric filter range object with the min and max range values (example: { min: 0, max: 50 })
 * @param {object} props.value - numeric filter object with the min and max values (example: { min: 0, max: 50 })
 * @param {Function} props.onFilterChange - function called when user types on a filter input (params: { @see props.fieldKey }, { filter value })
 * @param {boolean} props.isCurrency - controls if this numeric filter should be currency, default false
 * @param {boolean} props.isRounded - controls if this numeric filter should be rounded, default false
 * @param {string} props.buttonWidth - text or number to configure the size of the numeric filter button width
 *
 * @returns {React.Component} - The table filter component
 */
const AmoTableFilterNumeric = (props) => {
  const {
    fieldKey,
    tableId,
    label,
    range,
    value,
    onFilterChange,
    isCurrency,
    isRounded,
    buttonWidth,
  } = props;

  const baseId = `${tableId ?? "amo-table"}-filter-numeric-${fieldKey}`;

  const classes = useStyles();
  const [internalValue, setInternalValue] = useState(value);
  const popupState = usePopupState({
    variant: "popper",
    popupId: `${baseId}-popper`,
  });

  const handleFilterChange = (newValue) => {
    onFilterChange(newValue);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const buttonLabel = useMemo(() => {
    const hasMinValue = value?.min !== undefined && value?.min !== null;
    const hasMaxValue = value?.max !== undefined && value?.max !== null;

    if (!hasMinValue && !hasMaxValue) {
      return label;
    }

    const minValue = hasMinValue ? value.min : range.min;
    const maxValue = hasMaxValue ? value.max : range.max;

    return isCurrency
      ? `${formatToCurrency(minValue, isRounded)} - ${formatToCurrency(
          maxValue,
          isRounded
        )}`
      : `${minValue} - ${maxValue}`;
  }, [value, range]);

  return (
    <>
      <Button
        variant="outlined"
        className={clsx(
          "MuiOutlinedInput-root",
          classes.filterButton,
          popupState.isOpen ? classes.filterButtonActive : ""
        )}
        endIcon={
          <Icon className="material-icons-outlined">arrow_drop_down</Icon>
        }
        style={{ width: buttonWidth ?? "10rem", borderRadius: "3px" }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bindToggle(popupState)}
      >
        <Typography variant="body1" noWrap className={classes.filterButtonText}>
          {buttonLabel}
        </Typography>
      </Button>
      <Popper
        placement="bottom-start"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bindPopper(popupState)}
      >
        <Grid
          container
          direction="column"
          spacing={0}
          className={classes.rootContainer}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              spacing={2}
              className={classes.contentContainer}
            >
              <Grid item>
                <Typography variant="body2" className={classes.labelTitle}>
                  {label}
                </Typography>
              </Grid>
              <Grid item>
                <AmoTableFilterNumericSlider
                  ThumbComponent={AmoTableFilterNumericSliderThumb}
                  value={[
                    Number(internalValue?.min ?? range.min),
                    Number(internalValue?.max ?? range.max),
                  ]}
                  min={isRounded ? customRound(range.min, 0) : range.min}
                  max={isRounded ? customRound(range.max, 0) : range.max}
                  onChange={(_, newValue) => {
                    setInternalValue((prevInternalValue) => ({
                      ...prevInternalValue,
                      min: newValue[0],
                      max: newValue[1],
                    }));
                  }}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs>
                    <TextField
                      id={`${baseId}-min-value-input`}
                      onChange={(event) => {
                        setInternalValue((prevInternalValue) => ({
                          ...prevInternalValue,
                          min: event.target.value
                            ? Number(event.target.value)
                            : null,
                        }));
                      }}
                      onBlur={() => {
                        setInternalValue((prevInternalValue) => {
                          let newValue = prevInternalValue?.min;
                          if (newValue === null) {
                            return prevInternalValue;
                          }
                          const maxValue = prevInternalValue?.max ?? range.max;
                          if (newValue < range.min) {
                            newValue = range.min;
                          }
                          if (newValue > maxValue) {
                            newValue = maxValue;
                          }
                          return {
                            ...prevInternalValue,
                            min: newValue,
                          };
                        });
                      }}
                      label="Min Value"
                      value={
                        internalValue?.min !== undefined &&
                        internalValue?.min !== null
                          ? internalValue.min.toString()
                          : range?.min?.toString() ?? ""
                      }
                      variant="outlined"
                      InputProps={{
                        inputComponent: isCurrency
                          ? CurrencyNumberFormat
                          : AmoNumberFormat,
                        inputProps: {
                          decimalScale: isRounded ? 0 : 2,
                          allowNegative: range.min < 0,
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={`${baseId}-max-value-input`}
                      onChange={(event) => {
                        setInternalValue((prevInternalValue) => ({
                          ...prevInternalValue,
                          max: event.target.value
                            ? Number(event.target.value)
                            : null,
                        }));
                      }}
                      onBlur={() => {
                        setInternalValue((prevInternalValue) => {
                          let newValue = prevInternalValue?.max;
                          if (newValue === null) {
                            return prevInternalValue;
                          }
                          const minValue = prevInternalValue?.min ?? range.min;
                          if (newValue > range.max) {
                            newValue = range.max;
                          }
                          if (newValue < minValue) {
                            newValue = minValue;
                          }
                          return {
                            ...prevInternalValue,
                            max: newValue,
                          };
                        });
                      }}
                      label="Max Value"
                      value={
                        internalValue?.max !== undefined &&
                        internalValue?.max !== null
                          ? internalValue.max.toString()
                          : range?.max?.toString() ?? ""
                      }
                      variant="outlined"
                      InputProps={{
                        inputComponent: isCurrency
                          ? CurrencyNumberFormat
                          : AmoNumberFormat,
                        inputProps: {
                          decimalScale: isRounded ? 0 : 2,
                          allowNegative: range.min < 0,
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={0}
              justifyContent="flex-end"
              className={classes.actionContainer}
            >
              <Grid item>
                <Button
                  color="primary"
                  size="medium"
                  variant="outlined"
                  className={classes.actionButton}
                  onClick={() => {
                    setInternalValue(value);
                    popupState.close();
                  }}
                >
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={classes.actionButton}
                  onClick={() => {
                    handleFilterChange(internalValue);
                    popupState.close();
                  }}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popper>
    </>
  );
};

// set the prop-types for this component
AmoTableFilterNumeric.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  tableId: PropTypes.string,
  label: PropTypes.string,
  range: PropTypes.exact({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  value: PropTypes.exact({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  onFilterChange: PropTypes.func,
  isCurrency: PropTypes.bool,
  isRounded: PropTypes.bool,
  buttonWidth: PropTypes.string,
};

AmoTableFilterNumeric.defaultProps = {
  tableId: undefined,
  label: undefined,
  range: {
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
  },
  value: {},
  onFilterChange: () => {},
  isCurrency: false,
  isRounded: false,
  buttonWidth: undefined,
};

export default AmoTableFilterNumeric;
