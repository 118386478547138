// Set up a key factory per feature for a flexible query key reference
export const annualReportKeys = {
  reports: ["annual", "reports"],
  status: (municipalityId, year) => [
    ...annualReportKeys.reports,
    "submit",
    municipalityId,
    year,
  ],
};
