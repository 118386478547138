import { useState, useEffect } from "react";

export const useWindowDimensions = () => {
  const getWindowDimensions = () => ({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  });

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
