import React, { useState, useEffect } from "react";

import { DateTime } from "luxon";
import Skeleton from "@material-ui/lab/Skeleton";

import PropTypes from "prop-types";

import { reviewService } from "api/services/reviewsService";
import reviewFieldWrapperTypes from "constants/reviewFieldWrapperTypes";

import { useUserContext } from "contexts/UserContext";

import { useIsMounted } from "hooks/useIsMounted";
import { useWrapApi } from "hooks/wrapApiHook";

import FormViewWrapper from "./FormViewWrapper";
import AmoFlagInputPopover from "./AmoFlagInputPopover";

/**
 * A component that composes FormViewWrapper for Project Review Log
 * Contains logic for saving flags and fields
 *
 * @param {object} props
 * Object containing props for this component and by extension, FormViewWrapper.
 * @param {number} props.projectId
 * Id of the project.
 * @param {string} props.id
 * Id of the component.
 * @param {string} [props.testId]
 * Data-testid of the component.
 * @param {string} [props.fieldCode]
 * Code of the field.
 * @param {number} [props.objectId]
 * Id of the object of the project (like funds or locations).
 * @param {string} [props.label]
 * Sets the displayed label of the field.
 * @param {string} [props.value]
 * Sets the displayed value of the field.
 * @param {Function} [props.formatValue]
 * Function to format the value for display.
 * @param {string} [props.previousValue]
 * Sets the previously displayed value of the field.
 * @param {boolean} [props.iconsToRight]
 * Sets if save and flag icons should to right as opposed to left (default).
 * @param {boolean} [props.renderInput]
 * Function to create children components, takes in a value and setValue function to use in the components on onChange().
 * @param {object} [props.flagProps]
 * Object containing props of this flag popover configuration.
 * @param {number} [props.flagProps.flagId]
 * Sets the flag id of the flag popover.
 * @param {string} [props.flagProps.ownerName]
 * Sets the owner name of the flag popover.
 * @param {Date} [props.flagProps.lastUpdate]
 * Sets the last update date of the flag popover.
 * @param {string} [props.flagProps.comment]
 * Sets the comment of the flag popover.
 * @param {boolean} [props.hideEdit]
 * Sets if edit button should appear.
 * @param {boolean} [props.hideFlag]
 * Sets if flag button should appear.
 * @param {boolean} [props.hideLabel]
 * Sets if the label should appear.
 * @param {boolean} [props.hidePrevious]
 * Sets if the previous value should appear.
 * @param {object} [props.renderComponent]
 * Component to render instead of just text.
 * @param {string} [props.wrapperType]
 * Sets the wrapper type.
 * @param {Function} [props.downloadAllFunction]
 * Function called when user clicks on the download all button.
 * @param {Function} [props.onSave]
 * Function to call when clicking the save function.
 * @param {Function} [props.onClose]
 * Function to call when closing the edit popup.
 * @param {Function} [props.fieldName]
 * Name identifier for the value of renderComponent.
 * @param {object} [props.warning]
 * Object containing props of this warning configuration.
 * @param {object} [props.warning.type]
 * Type of warning to display.
 * @param {object} [props.warning.tooltipText]
 * Text to show on hover.
 * @param {boolean} [props.isFetching]
 * Whether the is fetching the project data.
 * @param {boolean} [props.isSaveEnable]
 * Disable or enable Save button of AmoEditFieldPopover.
 * @param {number} [props.year]
 * The year that this field relates to.
 * @returns {PropTypes.node} The wrapper component.
 */
const ProjectReviewFieldWrapper = (props) => {
  const {
    id,
    projectId,
    testId,
    fieldCode,
    objectId,
    label,
    value,
    formatValue,
    previousValue,
    iconsToRight,
    renderInput,
    flagProps,
    hideEdit,
    hideFlag,
    hideLabel,
    hidePrevious,
    renderComponent,
    wrapperType,
    downloadAllFunction,
    valueOverwrite,
    onSave,
    onClose,
    fieldName,
    warning,
    isFetching,
    isSaveEnable,
    year,
  } = props;

  const mounted = useIsMounted();

  const [currentFlagProps, setCurrentFlagProps] = useState(flagProps);
  const [currentValue, setCurrentValue] = useState(value);
  const [internalPreviousValue, setInternalPreviousValue] = useState(value);
  const [displayValue, setDisplayValue] = useState(value);
  const { user } = useUserContext();

  const postFlag = useWrapApi(
    reviewService.postFlag,
    "Successfully added flag"
  );
  const putFlag = useWrapApi(
    reviewService.putFlag,
    "Successfully updated flag"
  );
  const deleteFlag = useWrapApi(
    reviewService.deleteFlag,
    "Successfully cleared flag"
  );
  const putField = useWrapApi(
    reviewService.putField,
    "Successfully updated field"
  );

  const handleFlagSave = async (flagText) => {
    const payload = { reviewObjectId: +projectId, fieldCode, flagText };
    if (currentFlagProps?.flagId) payload.flagId = currentFlagProps.flagId;

    const response = currentFlagProps?.flagId
      ? await putFlag.call(
          payload.reviewObjectId,
          currentFlagProps.flagId,
          payload
        )
      : await postFlag.call(payload.reviewObjectId, payload);

    if (!mounted.current) {
      return;
    }

    if (!response.error) {
      const name = `${user.firstName} ${user.lastName}`;
      setCurrentFlagProps({
        ...currentFlagProps,
        flagId: currentFlagProps?.flagId ?? response?.data?.data,
        lastUpdate: DateTime.now().toISO(),
        ownerName: name,
        comment: flagText,
      });
      onSave();
    }
  };

  const handleDeleteFlag = async () => {
    const response = await deleteFlag.call(projectId, currentFlagProps.flagId);

    if (mounted.current && !response.error) {
      setCurrentFlagProps({});
      onSave();
    }
  };

  const handleFieldSave = async (newValue, skipOnSave = false) => {
    if (displayValue !== newValue) {
      let payloadValue;
      if (wrapperType.toLowerCase().includes("location")) {
        if (!mounted.current) {
          return;
        }

        payloadValue = `${newValue?.position.lat}, ${newValue?.position.lng}`;
      } else {
        payloadValue = newValue;
      }

      const payload = { projectId, objectId, fieldCode, value: payloadValue };
      if (year) {
        payload.year = year;
      }

      const response = await putField.call(projectId, fieldCode, payload);

      if (!mounted.current) {
        return;
      }

      if (!response.error) {
        setDisplayValue(newValue);
      }
    }
    if (onSave && !skipOnSave) {
      onSave();
    }
  };

  const handleClose = () => {
    onClose(fieldName, displayValue);
    setCurrentValue(value);
  };

  useEffect(async () => {
    setDisplayValue(value);
  }, [value]);

  useEffect(async () => {
    setInternalPreviousValue(previousValue);
  }, [previousValue]);

  useEffect(async () => {
    if (valueOverwrite) {
      setCurrentValue(valueOverwrite);
      handleFieldSave(valueOverwrite, true);
    }
  }, [valueOverwrite]);

  return isFetching ? (
    <Skeleton width="25rem" height="3rem" variant="rect" />
  ) : (
    <FormViewWrapper
      id={id}
      testId={testId}
      fieldCode={fieldCode}
      label={label}
      value={formatValue(displayValue)}
      previousValue={hidePrevious ? null : formatValue(internalPreviousValue)}
      iconsToRight={iconsToRight}
      hideEdit={hideEdit}
      hideFlag={hideFlag}
      hideLabel={hideLabel}
      renderComponent={renderComponent}
      wrapperType={wrapperType}
      editProps={
        renderInput
          ? {
              input: renderInput(currentValue, setCurrentValue),
              onSave: () => handleFieldSave(currentValue),
              onClose: () => handleClose(),
              isSaveEnable,
            }
          : null
      }
      flagProps={{
        ...currentFlagProps,
        onSave: handleFlagSave,
        onRemove: handleDeleteFlag,
      }}
      downloadAllFunction={downloadAllFunction}
      warning={warning}
    />
  );
};

// set the prop-types for this component
ProjectReviewFieldWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  testId: PropTypes.string,
  fieldCode: PropTypes.string,
  objectId: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
  formatValue: PropTypes.func,
  previousValue: PropTypes.string,
  iconsToRight: PropTypes.bool,
  renderInput: PropTypes.func,
  flagProps: PropTypes.shape({
    flagId: AmoFlagInputPopover.propTypes.flagId,
    ownerName: AmoFlagInputPopover.propTypes.ownerName,
    lastUpdate: AmoFlagInputPopover.propTypes.lastUpdate,
    comment: AmoFlagInputPopover.propTypes.comment,
  }),
  hideEdit: PropTypes.bool,
  hideFlag: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hidePrevious: PropTypes.bool,
  renderComponent: PropTypes.shape(),
  wrapperType: PropTypes.string,
  downloadAllFunction: PropTypes.func,
  valueOverwrite: PropTypes.shape(),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  fieldName: PropTypes.node,
  warning: PropTypes.shape({
    type: PropTypes.string,
    tooltipText: PropTypes.string,
  }),
  isFetching: PropTypes.bool,
  isSaveEnable: PropTypes.bool,
  year: PropTypes.number,
};

ProjectReviewFieldWrapper.defaultProps = {
  testId: null,
  fieldCode: "",
  objectId: undefined,
  label: undefined,
  value: undefined,
  formatValue: (value) => value,
  previousValue: undefined,
  iconsToRight: false,
  renderInput: null,
  flagProps: {},
  hideEdit: false,
  hideFlag: false,
  hideLabel: false,
  hidePrevious: false,
  renderComponent: null,
  wrapperType: reviewFieldWrapperTypes.text,
  downloadAllFunction: () => {},
  valueOverwrite: null,
  onSave: () => {},
  onClose: () => {},
  fieldName: "",
  warning: {},
  isFetching: false,
  isSaveEnable: true,
  year: undefined,
};

export default ProjectReviewFieldWrapper;
