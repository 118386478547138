import React, { useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, Typography, FormControlLabel, Radio } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Query hooks and management
import { useAmoUserDetails } from "./amoUserHooks";
import { useUpdateUserDetails } from "./usersHook";

import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";

import AmoSwitch from "components/inputs/AmoSwitch";
import AmoRadioGroup from "components/inputs/AmoRadioGroup";
import AmoPageHeader from "components/AmoPageHeader";
import AmoFormActions from "components/AmoFormActions";
import AmoTooltip from "components/AmoTooltip";

import colors from "constants/colors";
import { roles } from "constants/user";
import { useGlobalStyles } from "hooks/globalStylesHook";
import NotFoundPage from "pages/NotFoundPage";

const useStyles = makeStyles((theme) => ({
  label: {
    color: colors.black,
    fontFamily: "Roboto",
    marginBottom: "0.75rem",
  },
}));

/**
 * AMO User Edit Page.
 *
 * @returns {React.Component} AMO User Edit Page component.
 */
const AmoUserEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { userId } = useParams();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();

  const { hasRoles } = useUserContext();

  const specialRoleOptions = [
    {
      key: "amoUserEditSpecialRoleNone",
      value: "",
      label: "None",
      tooltipText: "This user does not have any special role or privileges.",
    },
    {
      key: "amoUserEditSpecialRoleLead",
      value: "Lead Reviewer",
      label: "Lead Reviewer",
      tooltipText:
        "Projects and financial reports will be assigned to this user when created or updated.",
    },
    {
      key: "amoUserEditSpecialRoleManager",
      value: "Manager",
      label: "Manager",
      tooltipText:
        "This user is the Program Manager. No special privileges are currently assigned.",
    },
  ];

  // define schema for form validation
  const AmoUserEditValidationSchema = yup
    .object({
      specialRole: yup.string().nullable(),
      isEnabled: yup.boolean(),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    specialRole: "",
    isEnabled: true,
  };

  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(AmoUserEditValidationSchema),
  });

  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    error,
  } = useAmoUserDetails(userId);

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    reset(userDetails ?? defaultValues);
  }, [userDetails]);

  const navigateBack = () => {
    history.push(`/users${location.search}`);
  };

  const { mutateAsync: updateUser } = useUpdateUserDetails(
    showSnackbar,
    navigateBack
  );

  const handleSaveClick = async ({ specialRole, isEnabled: enabled }) => {
    const userData = {
      userType: "amo",
      userId,
      amoLead: specialRole === "Lead Reviewer",
      amoManager: specialRole === "Manager",
      enabled,
    };
    updateUser(userData);
  };

  const { isValid, isSubmitting, isDirty } = formState;

  const formActions = [
    {
      testId: "amoUserEditSaveButton",
      label: "Save",
      disabled: !isDirty || isSubmitting || !isValid,
      onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
    },
    {
      testId: "amoUserEditCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: navigateBack,
    },
  ];

  return isUserDetailsLoading ? (
    <>
      <Skeleton width="50%" />
      <Skeleton width="50%" />
    </>
  ) : (
    <>
      {error?.response?.status === 404 && (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <NotFoundPage />
        </Grid>
      )}
      {!(error?.response?.status === 404) && (
        <Grid container direction="column" spacing={0} wrap="nowrap">
          {/* Header */}
          <Grid item>
            <AmoPageHeader
              title="User Management:"
              subtitle="Edit User"
              backLinkText="Back to user list"
              backLinkTo="/users"
            />
          </Grid>

          {/* Body */}
          <Grid item xs className={globalClasses.editPageBodyContainer}>
            <Grid
              container
              direction="column"
              spacing={3}
              className={globalClasses.editPageBody22}
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="body1">
                  To revise contact information or change credentials, please
                  contact Information Services.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" className={classes.label}>
                  User
                </Typography>
                <Typography variant="body1">{userDetails?.name}</Typography>
              </Grid>
              <Grid item>
                <AmoRadioGroup
                  control={control}
                  id="amo-user-edit-special-role"
                  name="specialRole"
                  testId="amoUserEditSpecialRole"
                  label="Special Role:"
                  labelVariant="subtitle2"
                  ariaLabel="amoUserEditSpecialRole"
                >
                  {specialRoleOptions.map((option) => (
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      key={`${option.key}Grid`}
                    >
                      <Grid item>
                        <FormControlLabel
                          key={option.key}
                          value={option.value}
                          control={<Radio color="primary" />}
                          label={option.label}
                        />
                      </Grid>
                      <Grid item>
                        <AmoTooltip
                          tooltipTitle={option.label}
                          tooltipText={option.tooltipText}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </AmoRadioGroup>
              </Grid>
              {hasRoles([roles.amoSuperAdmin]) && (
                <Grid item xs>
                  <AmoSwitch
                    color="primary"
                    control={control}
                    id="amo-user-edit-enabled"
                    name="isEnabled"
                    testId="userEditEnabled"
                    label="User Enabled"
                    labelPlacement="start"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Actions */}
          <AmoFormActions actions={formActions} hideLateralBorders />
        </Grid>
      )}
    </>
  );
};

export default AmoUserEditPage;
