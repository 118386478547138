import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider } from "@material-ui/core";
import AmoTable from "components/table/AmoTable";
import colors from "constants/colors";
import { routes } from "constants/routes";
import { getSortingComparator, stableSort } from "utils/data";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import ListPageWrapper from "components/ListPageWrapper";
import { notificationsService } from "api/services/notificationService";
import NameLink from "components/municipal/NameLink";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.black,
  },
}));

/**
 * A page component for listing all editable notifications on Content Management
 *
 * @returns {React.Component} The page component
 */
const NotificationsListPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { search } = useLocation();
  const mounted = useIsMounted();

  const [notifications, setNotifications] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const tableColumns = [
    {
      fieldKey: "name",
      label: "Notification",
      widthPerc: 45,
      customRender: ({ name, id }) => (
        <NameLink
          name={name}
          link={routes.contentManagement.notifications.edit.replace(
            ":notificationId",
            id
          )}
        />
      ),
      isCustom: true,
    },
    {
      fieldKey: "enabled",
      label: "Enabled",
      widthPerc: 50,
    },
  ];

  // this effect will make a call to the API to get the notifications list
  useEffect(async () => {
    try {
      const { data } = await notificationsService.getAll();

      if (!mounted.current) {
        return;
      }

      const mappedResult = data.map((item) => ({
        ...item,
        enabled: item.enabled ? "Yes" : "No",
      }));

      setNotifications(mappedResult);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      // If the data is loaded, sort the forms
      const sortedResult = stableSort(
        notifications,
        getSortingComparator(
          order,
          orderBy === "nameComponent" ? "name" : orderBy
        )
      );

      setNotifications(sortedResult);
    }
  }, [isLoaded, order, orderBy]);

  const handleRequestSort = (fieldKey, newOrder) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);
  };

  const handleItemClick = ({ id }) =>
    history.push(
      `${routes.contentManagement.notifications.edit.replace(
        ":notificationId",
        id
      )}${search}`
    );

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography gutterBottom className={classes.title} variant="h1">
            Notifications
          </Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <AmoTable
            id="notifications-table"
            testId="notificationContentManagementTable"
            items={notifications}
            columns={tableColumns}
            defaultOrderBy="enabled"
            defaultOrder="desc"
            enableItemClick
            onItemClick={handleItemClick}
            onRequestSort={handleRequestSort}
            shouldUseQueryString
            disableFilters
          />
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default NotificationsListPage;
