import React, { useState, useRef } from "react";

import { DateTime } from "luxon";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useLocation, useParams } from "react-router-dom";

import { steps, formStatuses } from "constants/projectReport";
import { range } from "utils/number";
import { useYearContext } from "contexts/YearContext";
import { useUserContext } from "contexts/UserContext";
import { useNavigationBlocker } from "hooks/navigationHook";
import { useAnnualReportNavBar } from "components/annualReport/annualReportNavBarHooks";
import ReportPageWrapper from "components/ReportPageWrapper";
import NotFoundPage from "pages/NotFoundPage";

import ProjectReportStepper from "./ProjectReportStepper";
import ProjectReportCategoryForm from "./ProjectReportCategoryForm";
import ProjectReportGeneralInfoForm from "./ProjectReportGeneralInfoForm";
import ProjectReportFinancialsForm from "./ProjectReportFinancialsForm";
import ProjectReportResultsForm from "./ProjectReportResultsForm";
import ProjectReportCommunicationsForm from "./ProjectReportCommunicationsForm";
import { useProjectDateValidationInfo } from "./projectReportValidationHooks";
import { useProjectInformationData } from "./projectReportGeneralInfoHooks";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  /*
    This is needed because of an issue with MUI that causes a TextField with `multiline` property
    to render a `textarea` with height 0 on first mount when the parent has the `display: none` set:
    - https://github.com/mui/material-ui/issues/1870
    - https://github.com/mui/material-ui/issues/6841
    - https://github.com/mui/material-ui/issues/25167
  */
  hiddenHeight: {
    height: 0,
  },
}));

/**
 * Project Reporting Page.
 *
 * @returns {React.Component} Project Reporting Page component.
 */
const ProjectReportPage = () => {
  const classes = useStyles();
  const { user } = useUserContext();
  const { refetch } = useAnnualReportNavBar(user?.municipalityId);
  const { projectId: projectIdFromParam } = useParams();
  const {
    contextYear,
    isReportingPastDate,
    isInReportingYear,
  } = useYearContext();
  const location = useLocation();

  const [projectId, setProjectId] = useState(projectIdFromParam);
  const [activeStep, setActiveStep] = useState(steps.category);

  const [categoryFormStatus, setCategoryFormStatus] = useState(
    formStatuses.untouched
  );
  const [generalInformationFormStatus, setGeneralInformationStatus] = useState(
    formStatuses.disabled
  );
  const [financialsFormStatus, setFinancialsFormStatus] = useState(
    formStatuses.disabled
  );
  const [communicationsFormStatus, setCommunicationsFormStatus] = useState(
    formStatuses.disabled
  );
  const [resultsFormStatus, setResultsFormStatus] = useState(
    formStatuses.disabled
  );

  const defaultUnsavedChangesState = {
    hasChanges: false,
    hasInvalidData: false,
    promptCallback: () => {},
  };

  const [categoryData, setCategoryData] = useState({});
  const [informationData, setInformationData] = useState({});
  const [financialsData, setFinancialsData] = useState({});
  const [
    activeStepUnsavedChangesState,
    setActiveStepUnsavedChangesState,
  ] = useState(defaultUnsavedChangesState);

  const {
    hasChanges,
    hasInvalidData,
    promptCallback,
  } = activeStepUnsavedChangesState;

  const intendedStep = useRef();

  const changeActiveStep = (newStep) => {
    setActiveStep(newStep);
    setActiveStepUnsavedChangesState(defaultUnsavedChangesState);
  };

  const handleStepChange = (newStep) => {
    if (activeStepUnsavedChangesState.hasChanges) {
      intendedStep.current = newStep;
      openPromptModal();
    } else {
      changeActiveStep(newStep);
    }
  };

  // Get project data
  const {
    data: projectResult,
    refetch: refetchProjectData,
  } = useProjectInformationData(projectId);

  // Query project validation data
  const {
    data: projectValidationInfo,
    isSuccess,
    isError,
    error,
    isLoading,
    refetch: refetchValidationInfo,
  } = useProjectDateValidationInfo(projectId);
  const isPastConstructionEndDate =
    projectValidationInfo &&
    isReportingPastDate(projectValidationInfo.endOfConstructionDate);
  const isPastFinancingEndDate =
    projectValidationInfo &&
    isReportingPastDate(projectValidationInfo.endOfFinancingDate);
  const isConstructionEndInReportingYear =
    projectValidationInfo &&
    isInReportingYear(projectValidationInfo.endOfConstructionDate);
  const closingBalance =
    projectValidationInfo && projectValidationInfo.closingBalance;
  const constructionEndDateYear =
    projectValidationInfo &&
    DateTime.fromISO(projectValidationInfo.endOfConstructionDate).year;
  const isConstructionEndBeforeReportingYear =
    projectValidationInfo &&
    !isReportingPastDate(projectValidationInfo.endOfConstructionDate);
  const endOfFinancingDateYear =
    projectValidationInfo &&
    DateTime.fromISO(projectValidationInfo.endOfFinancingDate).year;
  const projectDeletionRequested =
    projectValidationInfo && projectValidationInfo.projectDeletionRequested;

  const goToNextStep = () => {
    if (activeStep === steps.results) {
      return;
    }
    handleStepChange(activeStep + 1);
  };

  const handleUserPromptSave = (saveData, event) => {
    let saveSuccess = true;

    if (promptCallback) {
      saveSuccess = promptCallback(saveData, event);
    }

    if (saveSuccess) {
      changeActiveStep(intendedStep.current);
      intendedStep.current = null;
      unblockNavigationAndClosePrompt(true);
    }

    return saveSuccess;
  };

  const handleUserPromptClose = () => {
    closePromptModal(false);
  };

  const handleUnsavedChanges = (
    formHasChanges,
    formHasInvalidData,
    callback
  ) => {
    setActiveStepUnsavedChangesState({
      ...activeStepUnsavedChangesState,
      hasChanges: formHasChanges,
      hasInvalidData: formHasInvalidData,
      promptCallback: callback,
    });
  };

  // Hook to block user navigation
  const {
    openPromptModal,
    closePromptModal,
    unblockNavigationAndClosePrompt,
  } = useNavigationBlocker(hasChanges, {
    onPromptSaveCallback: handleUserPromptSave,
    onPromptCloseCallback: handleUserPromptClose,
    hasInvalidData,
    isAsyncClosing: true,
  });

  let pageTitle;

  if (projectId === "new") {
    pageTitle = "Add New Project";
  } else if (projectResult?.data?.title) {
    pageTitle = `Edit Project ${projectId}: ${projectResult.data.title}`;
  } else {
    pageTitle = `Edit Project ${projectId}: Untitled Project`;
  }

  return (
    <ReportPageWrapper
      backLink={{
        text: "Back to Project List",
        to: `/reporting/${contextYear}/projects`,
        query: location.search,
      }}
      title={pageTitle}
    >
      {isLoading &&
        range(0, 5).map((index) => (
          <Skeleton
            key={index}
            width="100%"
            height={["0.126rem", "4.3rem", "0.126rem"][index] ?? "8rem"}
          />
        ))}
      {(isSuccess || projectId === "new") && (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <Grid item>
            <ProjectReportStepper
              activeStep={activeStep}
              categoryFormStatus={categoryFormStatus}
              generalInformationFormStatus={generalInformationFormStatus}
              financialsFormStatus={financialsFormStatus}
              communicationsFormStatus={communicationsFormStatus}
              resultsFormStatus={resultsFormStatus}
              onStepChange={handleStepChange}
            />
          </Grid>
          <Grid item xs>
            <Box
              className={
                activeStep === steps.category
                  ? classes.fullHeight
                  : classes.hiddenHeight
              }
            >
              <ProjectReportCategoryForm
                projectId={projectId}
                activeStep={activeStep}
                onProjectCreate={(id) => setProjectId(id)}
                onFormStatusChange={(newStatus) => {
                  setCategoryFormStatus(newStatus);
                  refetch();
                }}
                onNextStepRequest={goToNextStep}
                onProjectDataChange={setCategoryData}
                isPastConstructionEndDate={isPastConstructionEndDate}
                projectDeletionRequested={projectDeletionRequested}
                refetchValidationInfo={refetchValidationInfo}
              />
            </Box>
            <Box
              className={
                activeStep === steps.generalInformation
                  ? classes.fullHeight
                  : classes.hiddenHeight
              }
            >
              <ProjectReportGeneralInfoForm
                projectId={projectId}
                projectResult={projectResult}
                refetchProjectData={refetchProjectData}
                activeStep={activeStep}
                categoryFormStatus={categoryFormStatus}
                financialsData={financialsData}
                onFormStatusChange={(newStatus) => {
                  setGeneralInformationStatus(newStatus);
                  refetch();
                }}
                onNextStepRequest={goToNextStep}
                onUnsavedChanges={handleUnsavedChanges}
                onProjectDataChange={(data) => {
                  refetch();
                  setInformationData(data);
                }}
                isPastConstructionEndDate={isPastConstructionEndDate}
                constructionEndDateYear={constructionEndDateYear}
                projectDeletionRequested={projectDeletionRequested}
                refetchValidationInfo={refetchValidationInfo}
              />
            </Box>
            <Box
              className={
                activeStep === steps.financials
                  ? classes.fullHeight
                  : classes.hiddenHeight
              }
            >
              <ProjectReportFinancialsForm
                projectId={projectId}
                activeStep={activeStep}
                categoryFormStatus={categoryFormStatus}
                resultsFormStatus={resultsFormStatus}
                informationData={informationData}
                onFormStatusChange={(newStatus) => {
                  setFinancialsFormStatus(newStatus);
                  refetch();
                }}
                onNextStepRequest={goToNextStep}
                onUnsavedChanges={handleUnsavedChanges}
                onProjectDataChange={(data) =>
                  setFinancialsData({ ...financialsData, ...data })
                }
                onProjectFundsChange={(data) =>
                  setFinancialsData({ ...financialsData, ...data })
                }
                isPastFinancingEndDate={isPastFinancingEndDate}
                municipalityClosingBalance={closingBalance}
                projectDeletionRequested={projectDeletionRequested}
                endOfFinancingDateYear={endOfFinancingDateYear}
                refetchValidationInfo={refetchValidationInfo}
              />
            </Box>
            <Box
              className={
                activeStep === steps.communications
                  ? classes.fullHeight
                  : classes.hiddenHeight
              }
            >
              <ProjectReportCommunicationsForm
                projectId={projectId}
                activeStep={activeStep}
                onProjectCreate={(id) => setProjectId(id)}
                onFormStatusChange={(newStatus) => {
                  setCommunicationsFormStatus(newStatus);
                  refetch();
                }}
                onNextStepRequest={goToNextStep}
                onUnsavedChanges={handleUnsavedChanges}
                isPastConstructionEndDate={isPastConstructionEndDate}
                endOfConstructionDate={
                  projectValidationInfo?.endOfConstructionDate
                }
                projectDeletionRequested={projectDeletionRequested}
                refetchValidationInfo={refetchValidationInfo}
              />
            </Box>
            <Box
              className={
                activeStep === steps.results
                  ? classes.fullHeight
                  : classes.hiddenHeight
              }
            >
              <ProjectReportResultsForm
                projectId={projectId}
                activeStep={activeStep}
                categoryFormStatus={categoryFormStatus}
                categoryData={categoryData}
                projectInfo={informationData}
                onFormStatusChange={(newStatus) => {
                  setResultsFormStatus(newStatus);
                  refetch();
                }}
                onNextStepRequest={goToNextStep}
                onUnsavedChanges={handleUnsavedChanges}
                isConstructionEndInReportingYear={
                  isConstructionEndInReportingYear
                }
                isConstructionEndBeforeReportingYear={
                  isConstructionEndBeforeReportingYear
                }
                projectDeletionRequested={projectDeletionRequested}
                refetchValidationInfo={refetchValidationInfo}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {isError && error?.response?.status === 404 && (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <NotFoundPage />
        </Grid>
      )}
    </ReportPageWrapper>
  );
};

export default ProjectReportPage;
