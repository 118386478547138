import React from "react";
import { Grid, Typography, Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../constants/colors";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";

const useStyles = makeStyles({
  gridRoot: {
    justifyContent: "center",
  },
  gridItem: {
    paddingTop: "10rem",
  },
  paperRoot: {
    width: "25rem",
    height: "31.25rem",
  },
  paperRounded: {
    borderRadius: "0.625rem",
  },
  paperOutlined: {
    border: `1px solid ${colors.grey.main}`,
  },
  gridContainer: {
    spacing: "8rem",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80%",
  },
  gridItemText: {
    textAlign: "center",
    width: "65%",
  },
});

/**
 * Displays a success message to the user upon successful reset request.
 *
 * @returns {React.Component} The Password Reset Success message.
 */
const ResetPasswordSuccess = () => {
  const classes = useStyles();

  return (
    <Grid container direction="row" classes={{ root: classes.gridRoot }}>
      <Grid item classes={{ root: classes.gridItem }}>
        <Paper
          variant="outlined"
          classes={{
            root: classes.paperRoot,
            rounded: classes.paperRounded,
            outlined: classes.paperOutlined,
          }}
        >
          <Grid
            container
            direction="column"
            classes={{ root: classes.gridContainer }}
          >
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <CheckCircleOutlined
                color="primary"
                style={{ fontSize: "5rem" }}
              />
            </Grid>
            <Grid item>
              <Box color={colors.green.dark}>
                <Typography variant="h5">Recover Password</Typography>
              </Box>
            </Grid>
            <Grid item classes={{ root: classes.gridItemText }}>
              <Typography variant="body1">
                An email has been sent.
                <br />
                Please follow the recovery link when you recieve it.
              </Typography>
            </Grid>
            <Grid item>&nbsp;</Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordSuccess;
