import React from "react";
import { Grid, Icon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  checked: { color: "#4D7F2A" },
  unchecked: { color: "#939393" },
}));

/**
 * Displays a checked or unchecked icon.
 *
 * @param {object} props
 * @param {boolean} props.checked The state of the icon
 * @returns {React.Component} A checked or unchecked icon component.
 */
const CheckedIcon = ({ checked }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" alignContent="center">
      <Grid item>
        <Icon
          className={clsx(
            "material-icons-outlined",
            checked ? classes.checked : classes.unchecked
          )}
        >
          {checked ? "check_circle" : "circle"}
        </Icon>
      </Grid>
    </Grid>
  );
};

CheckedIcon.propTypes = {
  checked: PropTypes.bool,
};

CheckedIcon.defaultProps = {
  checked: false,
};

export default CheckedIcon;
