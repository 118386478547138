import * as React from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import ListPageWrapper from "components/ListPageWrapper";
import { snackbarTypes } from "constants/snackbar";
import { useSnackbar } from "contexts/SnackbarContext";
import { errorMessages } from "constants/errorMessages";
import { useState } from "react";
import { useSummaryStyles } from "../hooks/summaryStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import FilterActionsBar from "../FilterActionsBar";
import { exportFinancialSummary } from "utils/export";
import { useYearsRange } from "../hooks/yearsRangeHooks";
// Used for JSDocs
// eslint-disable-next-line import/named, no-unused-vars
import { SummaryPayload } from "api/services/summaryService";
import { useProjectFinancialsData } from "./projectFinancialsHooks";
import { useUserContext } from "contexts/UserContext";

import { pdfReportsService } from "api/services/pdfReportService";
import { printFile } from "utils/pdf";
import totalRow from "components/municipal/financialSummary/totalRow";
import useStyles from "components/municipal/financialSummary/financialTableStyles";
import titleRow from "components/municipal/financialSummary/titleRow";
import financialEntry from "components/municipal/financialSummary/financialEntry";

/**
 * Financial summary page for municipal users.
 *
 * @returns {React.Component} Financial Summary Page component.
 */
const FinancialSummaryPage = () => {
  const { user } = useUserContext();
  const classes = useStyles();
  const summaryClasses = useSummaryStyles();
  const { showSnackbar } = useSnackbar();

  const mounted = useIsMounted();

  const { data: years } = useYearsRange("financial");

  const [payload, setPayload] = useState(/** @type {SummaryPayload} */ ({}));
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const { data: financials, isFetched } = useProjectFinancialsData(payload);

  const getPdfFile = async () => {
    try {
      const { data } = await pdfReportsService.getSummaryFinancials(
        user.municipalityId,
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
    return null;
  };

  const handlePrint = async () => {
    setIsLoadingFile(true);

    const pdfFile = await getPdfFile();

    if (!mounted.current) {
      return;
    }

    if (pdfFile) {
      printFile(pdfFile);
    }

    setIsLoadingFile(false);
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography className={summaryClasses.title} variant="h2">
            Financial Summary
          </Typography>
        </Grid>
        <Grid item>
          <FilterActionsBar
            years={years}
            onFilter={({ from, to }) =>
              setPayload({
                municipalityId: user.municipalityId,
                yearStart: from,
                yearEnd: to,
              })
            }
            onDownload={() => {
              try {
                exportFinancialSummary(financials.years);
              } catch (error) {
                showSnackbar(
                  errorMessages.creatingSpreadsheet,
                  snackbarTypes.error
                );
              }
            }}
            onPrint={handlePrint}
            disableOptionalButtons={
              !isFetched || !financials?.years?.length || isLoadingFile
            }
            showPrintLoading={isLoadingFile}
          />
        </Grid>
        {isFetched && !financials?.years?.length && (
          <Grid item>
            <Typography>
              No results found for the selected reporting period
            </Typography>
          </Grid>
        )}
        {!!financials?.years?.length && (
          <Grid
            container
            direction="column"
            className={classes.margins}
            // Dynamic width set as style
            style={{
              minWidth: `${
                25 + 12.5 * (financials?.years?.length || 0) + 16
              }rem`,
            }}
            wrap="nowrap"
          >
            <Grid
              container
              direction="row"
              wrap="nowrap"
              className={classes.firstRow}
              align="right"
            >
              <Grid item className={classes.firstColumnCell} />
              {financials?.years &&
                financials?.years?.map(({ year }) => (
                  <Grid item className={classes.cell} key={year}>
                    <Typography variant="h5" className={classes.header}>
                      {year}
                    </Typography>
                  </Grid>
                ))}
              <Grid
                className={classes.middleColumnSpacing}
                style={{ marginRight: 4 }}
              />
              <Grid item className={classes.lastColumnCell} align="right">
                <Typography variant="h5" className={classes.header}>
                  Cumulative (2005 - {financials && payload.yearEnd})
                </Typography>
              </Grid>
            </Grid>
            <Divider className={summaryClasses.divider} />
            {titleRow(
              "Opening balance",
              classes,
              "openBalance",
              financials,
              false
            )}

            {titleRow("Revenues", classes)}
            {financialEntry(
              "Received from AMO (Including Receivables)",
              "allocation",
              financials,
              classes,
              false
            )}
            {financialEntry(
              "Interest earned",
              "interestEarned",
              financials,
              classes,
              false
            )}
            {financialEntry(
              "Proceeds from the Disposal of Assets",
              "proceedsFromDisposal",
              financials,
              classes,
              false
            )}

            {totalRow("Net Revenues", "netRevenues", financials, classes)}

            {titleRow("Transfers", classes)}
            {financialEntry(
              "Received from another municipality",
              "transferredIn",
              financials,
              classes,
              false
            )}
            {financialEntry(
              "Transferred to another municipality",
              "transferredOut",
              financials,
              classes
            )}

            {totalRow("Net Transfers", "netTransfers", financials, classes)}

            {titleRow("Expenditures", classes)}
            {financialEntry(
              "Broadband Connectivity",
              "broadband",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Brownfield Redevelopment",
              "brownfield",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Capacity Building",
              "capacityBldg",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Community Energy Systems",
              "commEnergy",
              financials,
              classes,
              true
            )}
            {financialEntry("Culture", "culture", financials, classes, true)}
            {financialEntry(
              "Disaster Mitigation",
              "disaster",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Drinking Water",
              "water",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Fire Stations",
              "fireStation",
              financials,
              classes,
              true,
              2021
            )}
            {financialEntry(
              "Local Roads and Bridges",
              "roadsBridges",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Public Transit",
              "transit",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Recreation",
              "recreation",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Regional and Local Airports",
              "airport",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Short-line Rail",
              "shortline",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Short-sea Shipping",
              "shortsea",
              financials,
              classes,
              true
            )}
            {financialEntry(
              "Solid Waste",
              "solidWaste",
              financials,
              classes,
              true
            )}
            {financialEntry("Sports", "sport", financials, classes, true)}
            {financialEntry("Tourism", "tourism", financials, classes, true)}
            {financialEntry(
              "Wastewater",
              "wastewater",
              financials,
              classes,
              true
            )}

            {totalRow(
              "Net Expenditures",
              "netExpenditures",
              financials,
              classes,
              true
            )}

            {titleRow(
              "Closing balance",
              classes,
              "closeBalance",
              financials,
              false
            )}
          </Grid>
        )}
      </Grid>
    </ListPageWrapper>
  );
};

export default FinancialSummaryPage;
