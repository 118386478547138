import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import colors from "constants/colors";
import AmoModal from "components/AmoModal";
import AmoTextField from "components/inputs/AmoTextField";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import { additionalCategoriesService } from "api/services/additionalCategoriesService";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  categoryFilter: {
    width: "100%",
    marginTop: "1rem",
  },
  checkboxContainer: {
    height: "16rem",
    overflow: "auto",
    flexWrap: "nowrap",
    border: `1px solid ${colors.grey.main}`,
    borderRadius: "4px",
    marginTop: "1rem",
    padding: "0.5rem 1rem",
  },
  warningText: {
    fontSize: "1rem",
  },
}));

/**
 * Modal to add additional categories in project results
 *
 * @param props
 * @param props.projectId
 * @param props.categoryId
 * @param props.modalIsOpen
 * @param props.closeModal
 * @param props.updateForm
 * @param props.onCategoriesChange
 * @param props.onSelectedCategoriesChange
 * @returns {React.Component} Modal component.
 */
const AdditionalCategoriesModal = (props) => {
  const classes = useStyles();

  const mounted = useIsMounted();

  const {
    projectId,
    categoryId: mainCategoryId,
    modalIsOpen,
    closeModal,
    updateForm,
    onSelectedCategoriesChange,
    categories,
  } = props;
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [initialSelectedIds, setInitialSelectedIds] = useState([]);

  const validationSchema = yup
    .object({
      selectedCategoryIds: yup.array().of(yup.boolean()),
    })
    .required();

  // Default form values for react-hook-form
  const defaultValues = {
    selectedCategoryIds: [],
    categoryFilter: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useWatch({
    control,
    name: ["categoryFilter", "selectedCategoryIds"],
  });

  const [categoryFilter] = getValues(["categoryFilter"]);

  const fetchSavedCategories = async () => {
    const response = await additionalCategoriesService.getById(projectId);

    if (!mounted.current) {
      return;
    }

    response.data.selectedCategoryIds.map((categoryId) =>
      setValue(`selectedCategoryIds[${categoryId}]`, true)
    );
    setInitialSelectedIds(response.data.selectedCategoryIds);
    onSelectedCategoriesChange(response.data.selectedCategoryIds);
  };

  useEffect(() => {
    if (projectId) {
      fetchSavedCategories();
    }
  }, [projectId]);

  // function that checks if previously selected categories have been removed from the list
  const checkForRemovals = (results) => {
    const removedIds = initialSelectedIds.filter((id) => !results.includes(id));
    return removedIds.length > 0 && !warningModalOpen;
  };

  const handleSaveClick = async ({ selectedCategoryIds }) => {
    const results = selectedCategoryIds
      .map((val, index) => (val ? index : null))
      .filter(Boolean);
    const removalOccured = checkForRemovals(results);

    // if a removal has occured, the original modal is closed and the warning modal is then displayed
    if (removalOccured) {
      setWarningModalOpen(true);
      closeModal();
    }
    // if no removals are detected, the save procedure continues
    else {
      try {
        await additionalCategoriesService.postById(projectId, {
          selectedCategoryIds: results,
        });

        if (!mounted.current) {
          return;
        }

        updateForm(false);
        onSelectedCategoriesChange(results);
        showSnackbar("Categories saved!", snackbarTypes.success);
      } catch (error) {
        if (!mounted.current) {
          return;
        }

        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    reset();
    fetchSavedCategories();
    if (warningModalOpen) {
      setWarningModalOpen(false);
    } else {
      closeModal();
    }
  };

  const displayCategory = (category) => {
    if (
      categoryFilter.length > 0 &&
      category.name.toLowerCase().indexOf(categoryFilter.toLowerCase()) < 0
    )
      return "";
    const label = category.parentName
      ? `${category.parentName}: ${category.name}`
      : category.name;
    return (
      <Grid item key={category.id}>
        <AmoCheckbox
          id={`category-checkbox-${category.id}`}
          className={classes.categoryCheckbox}
          color="primary"
          control={control}
          name={`selectedCategoryIds[${category.id}]`}
          label={label}
          value={false}
          disabled={category.id === mainCategoryId}
        />
      </Grid>
    );
  };

  const warningModal = () => (
    <AmoModal
      open={warningModalOpen}
      width="33rem"
      title="Save Changes?"
      showButton
      closePopover={handleCloseModal}
      buttons={[
        {
          key: "saveChanges",
          label: "Save Changes",
          color: "primary",
          variant: "contained",
          disabled: isSubmitting,
          onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
        },
        {
          key: "discardChanges",
          label: "Discard Changes",
          color: "secondary",
          variant: "outlined",
          onClick: handleCloseModal,
        },
      ]}
    >
      <Grid>
        <Typography variant="h6" className={classes.warningText}>
          You are removing one or more additional categories. This may remove
          some output and outcome indicators and their data entries.
        </Typography>
      </Grid>
    </AmoModal>
  );

  const standardModal = () => (
    <AmoModal
      open={modalIsOpen}
      width="33rem"
      title="Add additional relevant categories"
      showButton
      closePopover={handleCloseModal}
      buttons={[
        {
          key: "save",
          label: "Save",
          color: "primary",
          variant: "contained",
          disabled: isSubmitting,
          onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
        },
        {
          key: "close",
          label: "Close",
          color: "primary",
          variant: "outlined",
          onClick: handleCloseModal,
        },
      ]}
    >
      <Grid container direction="column" spacing={0} wrap="nowrap">
        <Grid item>
          <AmoTextField
            control={control}
            label="Find a category"
            name="categoryFilter"
            variant="outlined"
            className={classes.categoryFilter}
          />
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.checkboxContainer}
        >
          {categories.map((category) => displayCategory(category))}
        </Grid>
      </Grid>
    </AmoModal>
  );

  if (modalIsOpen) {
    return standardModal();
  }
  if (warningModalOpen) {
    return warningModal();
  }
  return null;
};

AdditionalCategoriesModal.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  onSelectedCategoriesChange: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape()),
};

AdditionalCategoriesModal.defaultProps = {
  onSelectedCategoriesChange: () => {},
  categories: [],
};

export default AdditionalCategoriesModal;
