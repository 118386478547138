// Set up a key factory per feature for a flexible query key reference
export const userKeys = {
  users: ["users"],
  user: (userId) => [...userKeys.users, userId],
  delegateCheck: (userId, municipalityId) => [
    ...userKeys.users,
    "delegate",
    municipalityId,
    { userId }, // Optional params will typically appear in an object at the end of the key array
  ],
  primaryContactCheck: (userId, municipalityId) => [
    ...userKeys.users,
    "primaryContact",
    municipalityId,
    { userId },
  ],
  account: (userId) => [...userKeys.users, "account", { userId }],
};
