import { useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useUserPrompt } from "contexts/UserPromptContext";

/**
 * @typedef {object} NavigationHelper
 * @property {object} location - The browser location object.
 * @property {Function} isCurrentPath - Takes a path and returns true if it matches the current path.
 * @property {Function} handleSamePathNavigation - Wrap an event if you want to prevent default behavior when on the same path.
 * @property {Function} currentPathStartsWith - Takes a path prefix and returns true if it matches the current path prefix.
 * @property {Function} navigateBack - Uses react-router to navigate back to the previous page.
 */

/**
 * Navigation helper hook.
 *
 * @returns {NavigationHelper} The navigation helper object.
 */
const useNavigationHelper = () => {
  const location = useLocation();
  const history = useHistory();

  const isCurrentPath = (path) => location.pathname === path;

  const currentPathStartsWith = (prefix) =>
    location.pathname.startsWith(prefix);

  const currentPathMatches = (regexString) => {
    const regex = new RegExp(regexString);
    return regex.test(location.pathname);
  };

  const handleSamePathNavigation = (event, path) => {
    if (isCurrentPath(path)) {
      event.preventDefault();
    }
  };

  const handleSamePathSubnavigation = (event, path) => {
    if (currentPathStartsWith(path)) {
      event.preventDefault();
    }
  };

  const navigateBack = () => {
    history.goBack();
  };

  return {
    location,
    isCurrentPath,
    handleSamePathNavigation,
    handleSamePathSubnavigation,
    currentPathStartsWith,
    currentPathMatches,
    navigateBack,
  };
};

/**
 * @typedef {object} NavigationBlocker
 * @property {boolean} isBlocked - Whether the navigation is blocked or not
 * @property {Function} unblockNavigation Function used to unblock the user navigation
 * @property {Function} unblockNavigationAndClosePrompt Function used to unblock the user navigation and close the user prompt modal if async
 * @property {Function} closePromptModal Function used to close the user prompt modal if async
 */

/**
 * @typedef {object} NavigationBlockerOptions
 * @property {boolean} hasInvalidData Whether the navigation should be blocked with invalid data or not
 * @property {Function} onPromptSaveCallback Function called when the user interacts with the block prompt buttons
 * @property {Function} onPromptCloseCallback Function called when the user closes the block prompt
 * @property {Function} onPromptSaveUseCallback Function from useCallback hook called when the user interacts with the block prompt buttons (takes priority over onPromptSaveCallback)
 * @property {Function} onPromptCloseUseCallback Function from useCallback hook called when the user closes the block prompt (takes priority over onPromptSaveCallback)
 * @property {boolean} isAsyncClosing Whether the navigation block modal should close manually
 */

/**
 * Navigation blocker hook.
 *
 * @param {boolean} shouldBlock Whether the navigation should be blocked or not
 * @param {NavigationBlockerOptions} options The navigation blocker options object
 *
 * @returns {NavigationBlocker} The navigation blocker object.
 */
const useNavigationBlocker = (
  shouldBlock,
  {
    hasInvalidData = false,
    onPromptSaveCallback = () => {},
    onPromptCloseCallback = () => {},
    onPromptSaveUseCallback = null,
    onPromptCloseUseCallback = null,
    isAsyncClosing = false,
  }
) => {
  const history = useHistory();
  const {
    getUserConfirmation,
    setSaveCallback,
    setCloseCallback,
    setIsAsyncClosing,
    closeUserPrompt,
    setIsInvalid,
  } = useUserPrompt();

  const unblockNavigationFunc = useRef();

  const unblockNavigation = () => {
    if (unblockNavigationFunc.current) {
      unblockNavigationFunc.current();
      unblockNavigationFunc.current = null;
    }
  };

  const unblockNavigationAndClosePrompt = (result = null) => {
    unblockNavigation();
    if (isAsyncClosing) {
      closeUserPrompt(result);
    }
  };

  const openPromptModal = () => {
    getUserConfirmation("unsaved");
  };

  useEffect(() => {
    setIsAsyncClosing(isAsyncClosing);
  }, [isAsyncClosing]);

  // Effect to prevent navigation to other pages
  useEffect(() => {
    if (shouldBlock) {
      if (!unblockNavigationFunc.current) {
        unblockNavigationFunc.current = history.block(() => "unsaved");

        const saveCallback = onPromptSaveUseCallback ?? onPromptSaveCallback;
        const closeCallback = onPromptCloseUseCallback ?? onPromptCloseCallback;

        setSaveCallback(() => saveCallback);
        setCloseCallback(() => closeCallback);
      }
    } else {
      unblockNavigation();
    }
  }, [history, shouldBlock]);

  useEffect(() => {
    setSaveCallback(() => onPromptSaveUseCallback);
  }, [onPromptSaveUseCallback]);

  useEffect(() => {
    setCloseCallback(() => onPromptCloseUseCallback);
  }, [onPromptCloseUseCallback]);

  // Effect to indicate if the propmt should be for invalid data
  useEffect(() => {
    setIsInvalid(hasInvalidData);
  }, [hasInvalidData]);

  return {
    isBlocked: !!unblockNavigationFunc.current,
    unblockNavigation,
    unblockNavigationAndClosePrompt,
    openPromptModal,
    closePromptModal: closeUserPrompt,
  };
};

export { useNavigationHelper, useNavigationBlocker };
