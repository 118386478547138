import React from "react";
import PropTypes from "prop-types";
import AmoModal from "components/AmoModal";
import BankingInformationForm from "components/BankingInformationForm";

/**
 * @param {object} props - object containing props for this component
 * @param {boolean} props.showModal - controls visibility of the modal
 * @param {Function} props.closeModal - function triggered when closing the modal
 * @param {object} props.formFields - object containing the information of the fields in this form
 * @param {object} props.bankingInformation - object containing the banking information data
 * @returns - the Review Banking Information modal, styled for the AMO project
 */
const BankingInformationDetailsModal = (props) => {
  const { showModal, closeModal, formFields, bankingInformation } = props;

  return (
    <AmoModal
      data-testid="bankingInformationDetailsModal"
      open={showModal}
      variant="default"
      title="Review Banking Information"
      width="50rem"
      showButton
      closePopover={closeModal}
      buttonsLeftSpacer={8}
      buttons={[
        {
          key: "close",
          label: "Close",
          color: "primary",
          variant: "outlined",
          onClick: closeModal,
        },
      ]}
    >
      <br />
      <BankingInformationForm
        bankingInformation={bankingInformation}
        isModal
        formFields={formFields}
      />
    </AmoModal>
  );
};

BankingInformationDetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  formFields: PropTypes.shape().isRequired,
  bankingInformation: PropTypes.shape().isRequired,
};

export default BankingInformationDetailsModal;
