import { Switch, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  switchLabel: {
    marginLeft: "0rem",
  },
  disabledCursor: {
    cursor: "default",
    pointerEvents: "none",
  },
}));

/**
 * This component is a customized MUI Switch, built to work with a react-hook-form controlled form
 *
 * @param props
 * @param {string} props.color              Theme color option
 * @param {object} props.control            The react-hook-form control, generated by useForm()
 * @param {boolean} props.disabled          Disables the component when true
 * @param {string} props.label              Label text for the component
 * @param {string} props.labelPlacement     Label text for the component; see MUI API for form control label
 * @param {string} props.id                 Field ID
 * @param {string} props.name               The name of the input; react-hook-form uses this to register the input
 * @param {string} props.testId             Maps to data-test-id; renamed on AMO component to avoid issues with targeting tests (maybe unnecessary)
 * @param {string} props.className          Passes in a css class
 * @param {object} props.formControlClasses Passes in the MUI classes prop for the FormControlLabel component
 * @param {boolean} props.required          Sets the required flag for this input (prefer handling this via a yup schema in most cases)
 * @param {string} props.variant            Sets the MUI variant for this input
 * @param {string} props.helperText         green helper text of the input shown when the input is touched
 * @param {string} props.size               Sets the MUI size for this input
 * @param {Function} props.onClick          Sets on trigger function
 * @param {boolean} props.isChangesAllowed  Sets an opportunity to change value
 *
 * @returns {Function} A MUI Switch component wrapped with the react-hook-form controller
 */
const AmoSwitch = ({
  color,
  control,
  disabled,
  label,
  labelPlacement,
  id,
  name,
  testId,
  className,
  formControlClasses,
  required,
  variant,
  helperText,
  size,
  onClick,
  isChangesAllowed,
}) => {
  const classes = useStyles();
  const isCursorDisabledStyle = !isChangesAllowed ? classes.disabledCursor : "";

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { value, ref } }) => (
        <FormControlLabel
          className={`${classes.switchLabel} ${isCursorDisabledStyle}`}
          classes={formControlClasses}
          label={label}
          labelPlacement={labelPlacement}
          control={
            <Switch
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              checked={value}
              disabled={disabled}
              inputRef={ref}
              color={color}
              id={id}
              data-testid={testId ?? `${id}-test`}
              className={`${className} ${isCursorDisabledStyle}`}
              variant={variant}
              required={required}
              size={size}
              onChange={(event) => {
                if (!isChangesAllowed) {
                  event.preventDefault();
                } else {
                  field.onChange(event);
                }
              }}
              onClick={() => {
                if (isChangesAllowed) {
                  onClick(value);
                }
              }}
            />
          }
        />
      )}
    />
  );
};

AmoSwitch.propTypes = {
  className: PropTypes.string,
  formControlClasses: PropTypes.shape(),
  color: PropTypes.string,
  control: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["bottom", "end", "start", "top"]),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  testId: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  isChangesAllowed: PropTypes.bool,
};

AmoSwitch.defaultProps = {
  className: undefined,
  formControlClasses: undefined,
  color: "",
  disabled: false,
  label: undefined,
  labelPlacement: "start",
  required: false,
  testId: undefined,
  variant: "outlined",
  helperText: null,
  size: "medium",
  onClick: () => {},
  isChangesAllowed: true,
};

export default AmoSwitch;
