import React from "react";
import { Box, Typography, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { useProjectStatus } from "./communicationDashboardHooks";
import {
  BarChart,
  Cell,
  Bar,
  XAxis,
  Text,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";
import { reporting } from "constants/reporting";
import { Link } from "react-router-dom";
import { routes } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "18rem",
    borderRadius: "10px",
    border: "1px solid #939393",
    padding: "1rem",
    paddingBottom: "0rem",
  },
  button: {
    width: "15rem",
    height: "3.125rem",
  },
  barChartRelativeContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  barChartContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    marginBottom: "2rem",
  },
}));

/**
 * Component that holds project data for communication users
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - component id
 * @param {string} props.testId - testing id
 *
 * @returns - Widget component
 */
const CommunicationsProjectWidget = (props) => {
  const { data, isLoading, isError, error } = useProjectStatus(
    reporting.currentReportingYear
  );
  const classes = useStyles();
  const { id, testId } = props;

  const FILL_OBJECT = {
    Considering: "#731794",
    Planning: "#5F8CFE",
    "On Hold": "#FFB600",
    Ready: "#23AE49",
  };

  const CustomizedAxisTick = (classProps) => {
    // X and Y are values given by the chart, an alias could be used, but it would probably just add a bit of confusion
    // eslint-disable-next-line id-length
    const { x, y, payload } = classProps;
    return (
      <Text
        x={x}
        y={y}
        width={75}
        fontSize={13}
        textAnchor="middle"
        verticalAnchor="start"
      >
        {payload.value}
      </Text>
    );
  };

  return (
    <>
      {isLoading && (
        <Skeleton
          style={{
            width: "57rem",
            height: "18rem",
          }}
          variant="rect"
        />
      )}
      {!isLoading && (
        <Grid
          container
          id={id}
          data-testid={testId}
          direction="column"
          className={classes.container}
        >
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h1">Projects</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  size="medium"
                  disableElevation
                  className={classes.button}
                  component={Link}
                  to={routes.communications.projects.list}
                >
                  <Typography align="center" variant="body2">
                    View Projects
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {isError && (
              <Box className={classes.errorContainer}>
                <Typography variant="h5">
                  {error?.response?.status === 404
                    ? "Data not found"
                    : "Unable to get data"}
                </Typography>
              </Box>
            )}
            {!isError && (
              // Implemented this way because it wasn't working otherwise (see links below)
              // https://github.com/recharts/recharts/issues/172#issuecomment-523362671
              // https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
              <Box className={classes.barChartRelativeContainer}>
                <Box className={classes.barChartContainer}>
                  <ResponsiveContainer width="100%" height={12 * 16}>
                    <BarChart
                      data={data}
                      barCategoryGap="5%"
                      margin={{
                        top: 20,
                        bottom: 0,
                      }}
                    >
                      <XAxis
                        dataKey="name"
                        axisLine={false}
                        tick={<CustomizedAxisTick />}
                        interval={0}
                        label={{
                          fill: "black",
                        }}
                      />
                      <Bar
                        minPointSize={15}
                        dataKey="value"
                        isAnimationActive={false}
                        label={{
                          fontSize: 15,
                          position: "top",
                          fontWeight: 200,
                          fill: "black",
                        }}
                      >
                        {data?.map((entry) => (
                          <Cell
                            key={entry.name}
                            fill={FILL_OBJECT[entry.name]}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

CommunicationsProjectWidget.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
};

export default CommunicationsProjectWidget;
