import { Api } from "../api";
import { AxiosResponse } from "axios";

const api = new Api("api/communications");

/**
 * @typedef {{
 *  id: number,
 *  projectId: number,
 *  reviewStatusId: number,
 *  communicationStatusId: number,
 *  municipalityId: number,
 *  categoryId: number,
 *  description: string,
 *  locations: Locations,
 *  totalCost: number,
 *  ccbfBudgeted: number,
 *  ccbfSpentToDate: number,
 *  startDate: string,
 *  constructionEnd: string,
 *  files: Array,
 *  links: Links
 *  }} ProjectDetails
 */

/**
 * @typedef {{
 *  longitude: number,
 *  latitude: number,
 *  isPrimary: boolean
 *  }} Locations
 */

/**
 * @typedef {{
 *  url: string,
 *  description: string
 *  }} Links
 */

export const projectDetailsService = {
  /**
   * @param projectId
   * @returns {Promise<AxiosResponse<ProjectDetails[]>>}
   */
  getProjectDetails: (projectId) => api.axios.get(`/project/${projectId}`),
};
