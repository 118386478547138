import { reportingService } from "api/services/reportingService";
import { amoReportingStatusService } from "api/services/amoReportingStatusService";
import { useQuery } from "react-query";
import { landingPageQueryKeys } from "./landingPageQueryKeys";
import { amoFinancialOverviewService } from "api/services/amoFinancialOverviewService";

const { getProjectReports } = amoReportingStatusService;
const { getFinancialReports } = amoReportingStatusService;
const { getFinancialOverview } = amoFinancialOverviewService;

/**
 * Store server's state of project results
 *
 * @param {number} year Reporting year
 * @returns The query object returned by the useQuery hook
 */
export const useReportingStatusData = (year) =>
  useQuery(
    landingPageQueryKeys.reportingStatusData(year),
    () => reportingService.getReportingStatusData(year),
    {
      select: ({ data }) => data,
    }
  );

/**
 * Store server's state of projects reporting status
 *
 * @param {number} year The year of the projects being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectReports = (year) => {
  const selectProjectReportStatus = ({ data }) => [
    { name: "Incomplete", value: data?.incomplete ?? 0 },
    { name: "Review Required", value: data?.reviewRequired ?? 0 },
    { name: "Follow-up Required", value: data?.followupRequired ?? 0 },
    { name: "Contacted", value: data?.contacted ?? 0 },
    { name: "Approved", value: data?.approved ?? 0 },
  ];

  return useQuery(
    landingPageQueryKeys.projectReporting(year),
    () => getProjectReports(year),
    {
      select: selectProjectReportStatus,
    }
  );
};

/**
 * Store server's state of financials reporting status
 *
 * @param {number} year The year of the financial reports being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useFinancialReports = (year) => {
  const selectFinancialsReportStatus = ({ data }) => [
    { name: "Incomplete", value: data?.incomplete ?? 0 },
    { name: "Review Required", value: data?.reviewRequired ?? 0 },
    { name: "Follow-up Required", value: data?.followupRequired ?? 0 },
    { name: "Contacted", value: data?.contacted ?? 0 },
    { name: "Approved", value: data?.approved ?? 0 },
  ];

  return useQuery(
    landingPageQueryKeys.financialsReporting(year),
    () => getFinancialReports(year),
    {
      select: selectFinancialsReportStatus,
    }
  );
};

/**
 * Store server's state of project results
 *
 * @param {number} year The year of the financial overview being fetched
 *
 * @returns The query object returned by the useQuery hook
 */
export const useFinancialOverview = (year) =>
  useQuery(
    landingPageQueryKeys.financialsOverview(year),
    () => getFinancialOverview(year),
    {
      select: ({ data }) => data,
    }
  );
