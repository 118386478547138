import React from "react";
import { Box, List, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import AmoPopupMenuItem from "components/AmoPopupMenuItem";
import { roleGroups } from "constants/user";
import { routes } from "constants/routes";
import { useUserContext } from "contexts/UserContext";
import { useYearContext } from "contexts/YearContext";
import { projectInterfaceIcons } from "constants/projectInterfaceIcons";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: colors.grey.light,
  },
  menuItem: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
  },
}));

/**
 * A mobile menu component with items for municipal users
 *
 * @param {object} props
 * @returns {React.Component} The menu component
 */
const MobileMunicipalMenu = (props) => {
  const classes = useStyles();
  const { hasRoles } = useUserContext();
  const { contextYear } = useYearContext();

  const { onItemClick } = props;

  const hasTreasurerOrDelegatePermission = hasRoles(
    roleGroups.municipal.treasurerOrDelegate
  );

  return (
    <>
      <Box data-testid="headerMunicipalMenu">
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to="/landing"
            iconName={projectInterfaceIcons.HOME}
            text="Home"
            testId="headerHomeLink"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={`/reporting/${contextYear}/projects`}
            iconName={projectInterfaceIcons.REPORTING_STATUS}
            text={`${contextYear} Report`}
            testId="headerReportButton"
            onClick={onItemClick}
          />
        </List>
        <Divider className={classes.divider} />
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.summaries.financial}
            iconName={projectInterfaceIcons.FINANCIALS}
            text="Financials"
            testId="headerSummMenuExp"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.summaries.projects}
            iconName={projectInterfaceIcons.PROJECTS}
            text="Projects"
            testId="headerSummMenuProj"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.summaries.results}
            iconName={projectInterfaceIcons.RESULTS}
            text="Results"
            testId="headerSummMenuOut"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.summaries.questionnaires}
            iconName={projectInterfaceIcons.QUESTIONNAIRES}
            text="Questionnaires"
            testId="headerSummMenuQuest"
            onClick={onItemClick}
          />
        </List>
        {hasTreasurerOrDelegatePermission ? (
          <>
            <Divider className={classes.divider} />
            <List>
              <AmoPopupMenuItem
                className={classes.menuItem}
                to="/users"
                iconName={projectInterfaceIcons.USERS}
                text="Users"
                testId="headerAdmMenuUsers"
                onClick={onItemClick}
              />
              <AmoPopupMenuItem
                className={classes.menuItem}
                to={routes.administration.bankingInformation}
                iconName={projectInterfaceIcons.BANKING_INFORMATION}
                text="Banking Information"
                testId="headerAdmMenuBank"
                onClick={onItemClick}
              />
            </List>
          </>
        ) : null}
        <Divider className={classes.divider} />
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to="/help"
            iconName={projectInterfaceIcons.HELP}
            text="Help"
            testId="headerHelpLink"
            onClick={onItemClick}
          />
        </List>
      </Box>
    </>
  );
};

// set the prop-types for this component
MobileMunicipalMenu.propTypes = {
  onItemClick: PropTypes.func,
};

MobileMunicipalMenu.defaultProps = {
  onItemClick: () => {},
};

export default MobileMunicipalMenu;
