import React, { useState, useRef } from "react";
import {
  Button,
  makeStyles,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";

import ListPageWrapper from "components/ListPageWrapper";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";

import colors from "constants/colors";
import { useYearContext } from "contexts/YearContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { exportToExcel } from "utils/export";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { bankLimitMonitoringStatuses } from "constants/amoTableDropdownOptions";

import { useBankLimitMonitoringHooks } from "./bankLimitMonitoringHooks";

import BankLimitMonitoringModal from "./BankLimitMonitoringModal";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: "3rem",
    height: "3rem",
    margin: "0 0",
    padding: "0 0",
  },
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  buttonsBar: {
    paddingBottom: "1.438rem !important",
  },
  textLink: {
    color: colors.green.main,
    textDecoration: "none",
    width: "auto",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    "&:focus": {
      outline: "none",
      border: `0.125rem solid ${theme.palette.primary.dark}`,
      borderRadius: "0.25rem",
      padding: "0.125rem 0.125rem",
    },
  },
  icon: {
    width: "3rem",
    height: "3rem",
  },
}));

/**
 * The Banking Limit Monitoring Page.
 *
 * @returns {React.Component} Banking Limit Monitoring Page component.
 */
const BankLimitMonitoringPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { contextYear } = useYearContext();
  const globalClasses = useGlobalStyles();
  const { getMunicipalitiesNames } = useMunicipalContext();
  const [isExportingToExcel, setIsExportingToExcel] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const {
    data: bankLimitMonitoringData,
    isFetching: bankLimitFetching,
    refetch: bankLimitRefetch,
  } = useBankLimitMonitoringHooks(contextYear);

  const openModal = (year, municipalityId) => {
    setModalData({ year, municipalityId });
    setShowModal(true);
    setFocusedItem(null);
  };

  const closeModal = (shouldRefetch) => {
    setShowModal(false);
    setFocusedItem({ municipalityId: modalData.municipalityId });
    setModalData({});
    if (shouldRefetch) {
      bankLimitRefetch();
    }
  };

  const tableColumns = [
    {
      fieldKey: "municipalityName",
      label: "Municipality",
      widthPerc: 24,
      customRender: (item) => (
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              color="primary"
              disableElevation
              disableRipple
              onClick={() => openModal(contextYear, item?.municipalityId)}
              className={globalClasses.tableActionCell}
              ref={(element) => {
                rowRefs.current[item.municipalityId] = element;
              }}
              onFocus={() =>
                setFocusedItem({ municipalityId: item.municipalityId })
              }
            >
              {item?.municipalityName}
            </Button>
          </Grid>
        </Grid>
      ),
      isCustom: true,
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "additionalInvestmentRequired",
      label: "Additional Investment Required",
      widthPerc: 26,
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "status",
      label: "Status",
      widthPerc: 12,
      dropdownOptions: bankLimitMonitoringStatuses,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "exemptionGranted",
      label: "Exemption Granted",
      widthPerc: 16,
      displayType: displayTypes.boolean,
    },
    {
      fieldKey: "notes",
      label: "Notes",
      widthPerc: 22,
      noCellWrap: true,
    },
  ];

  const tableActions = {
    actions: [tableDefaultActions.search, tableDefaultActions.export],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick: () => {
          setIsExportingToExcel(true);
          try {
            exportToExcel(
              filteredData.current,
              tableColumns,
              `Bank Limit Monitoring - ${contextYear}`,
              {
                sheetName: "Bank Limit Monitoring",
                specialColumnSizes: {
                  municipalityName: 30,
                  additionalInvestmentRequired: 30,
                  notes: 40,
                },
              }
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          } finally {
            setIsExportingToExcel(false);
          }
        },
        disabled: isExportingToExcel,
      },
    },
  };

  return (
    <>
      <ListPageWrapper>
        <Grid container direction="column" spacing={2} wrap="nowrap">
          <Grid item>
            <Typography variant="h1">Banking Limit Monitoring</Typography>
          </Grid>
          <Grid item className={classes.dividerContainer}>
            <Divider />
          </Grid>
          <Grid item>
            <Grid item>
              <AmoTableWithFilters
                items={bankLimitMonitoringData}
                columns={tableColumns}
                defaultOrderBy="municipalityName"
                enableItemClick
                onItemClick={(val) =>
                  openModal(contextYear, val?.municipalityId)
                }
                onFilteredDataChanged={(value) => {
                  filteredData.current = value;
                }}
                onScroll={(item) => {
                  rowRefs.current?.[item.municipalityId]?.focus();
                }}
                focusedItem={focusedItem}
                actionsProps={tableActions}
                isLoading={bankLimitFetching || !bankLimitMonitoringData}
                uniqueKeyGenerationFields={["municipalityId"]}
              />
            </Grid>
          </Grid>
        </Grid>
      </ListPageWrapper>

      {showModal && (
        <BankLimitMonitoringModal
          open={showModal}
          year={modalData.year}
          municipalityId={modalData.municipalityId}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default BankLimitMonitoringPage;
