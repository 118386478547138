import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  incomplete: number,
 *  reviewRequired: number,
 *  followupRequired: number,
 *  contacted: number,
 *  approved: number }} ReviewLogStatusResponse
 */

const api = new Api("api/amo/reporting-status");
export const amoReportingStatusService = {
  /**
   * @param {number} year
   * @returns {Promise<AxiosResponse<ReviewLogStatusResponse>>}
   */
  getProjectReports: (year) => api.getById(`projects/${year}`),
  /**
   * @param {number} year
   * @returns {Promise<AxiosResponse<ReviewLogStatusResponse>>}
   */
  getFinancialReports: (year) => api.getById(`financials/${year}`),
};
