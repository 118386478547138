import { useQuery } from "react-query";

import { indicatorManagementQueryKeys } from "./indicatorManagementQueryKeys";
import { indicatorService } from "api/services/indicatorService";

import { DateTime } from "luxon";

/**
 * Store server's state of indicator list result
 *
 * @param {number} year
 * @returns The query object returned by the useQuery hook
 */
export const useIndicatorsSummary = (year) =>
  useQuery(
    indicatorManagementQueryKeys.indicators(year),
    () => indicatorService.getSummaries(year),
    {
      select: ({ data }) =>
        data?.map((item) => ({
          ...(item ?? {}),
          outputOutcome: item.isOutcome ? "Outcome" : "Output",
          sort: item.sortOrder,
          status: item.isActive ? "Active" : "Inactive",
        })) ?? [],
      enabled: !!year,
    }
  );

/**
 * Store server's state of indicator lookup data result
 *
 * @returns The query object returned by the useQuery hook
 */
export const useIndicatorLookups = () =>
  useQuery(
    indicatorManagementQueryKeys.indicatorManagementLookups,
    () => indicatorService.getDropdownData(),
    {
      select: ({ data }) => data,
    }
  );

/**
 * Store server's state of indicator result
 *
 * @param {number} id
 * @returns The query object returned by the useQuery hook
 */
export const useIndicator = (id) =>
  useQuery(
    indicatorManagementQueryKeys.indicator(id),
    () => indicatorService.getIndicatorById(id),
    {
      select: ({ data }) => ({
        ...(data ?? {}),
        category: data?.categoryId ?? "",
        subcategory: data?.subcategoryId ?? "",
        outputOutcome: data?.indicatorType ? 1 : 0,
        grouping: data?.indicatorGroupingId ?? "",
        indicatorTypeId: data?.valueTypeId ?? "",
        descriptionLabel: data?.description ?? "",
        tooltipId: data?.tooltipId ?? "",
        tooltipText: data?.tooltipText ?? "",
        cellLocator: data?.cellLocator ?? "",
        validationOnEntry: data?.validationOnEntry?.id ?? "",
        unitType: data.indicatorUnitTypeId ?? "",
        standardUnit: data.standardIndicatorUnitTypeId ?? "None",
        customUnitName: data.customUnitName ?? "",
        beforeAfterIndicator: data.isBeforeAfter ?? false,
        calculationType: data.indicatorValueCalculationId ?? "",
        listOfValues: data.listOfValues ?? "",
        effectiveFrom: data.effectiveFrom
          ? DateTime.fromISO(data.effectiveFrom).toLocaleString(
              DateTime.DATE_SHORT
            )
          : "",
        effectiveThrough: data.effectiveTo
          ? DateTime.fromISO(data.effectiveTo).toLocaleString(
              DateTime.DATE_SHORT
            )
          : "",
        sortSequence: data.sortOrder ?? "",
      }),
      enabled: !!id && id !== "new",
    }
  );
