/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Divider,
  Button,
  makeStyles,
  IconButton,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useFieldArray, useWatch, useFormContext } from "react-hook-form";
import { RemoveCircleOutline } from "@material-ui/icons";
import AmoTextField from "components/inputs/AmoTextField";
import { useGlobalStyles } from "hooks/globalStylesHook";
import PropTypes from "prop-types";
import { questionnaireAnswerTypes } from "constants/questionnaireAnswerTypes";
import { questionnaireConditionOperators } from "constants/questionnaireConditionOperators";
import colors from "constants/colors";

const useStyles = makeStyles((theme) => ({
  addConditionRow: {
    padding: "1.625rem 1.25rem 1.625rem 1.25rem !important",
  },
  conditionsDivider: {
    paddingBottom: "1.625rem",
  },
  alert: {
    marginTop: "1rem",
    color: colors.red.dark,
  },
}));

export const defaultQuestionCondition = () => ({
  questionConditionQuestionNumber: 0,
  questionConditionOperatorId: questionnaireConditionOperators.is.value,
  questionConditionAnswerIndex: "",
});

/**
 * Component to handle Questionnaire's questions' conditions list behavior
 *
 * @param {object} props
 * @param {number} props.nestIndex The nested index belonging to a question, needed for nested arrays with useFieldArray usage
 * @param {boolean} props.hasLimitedEdition If true, the questionnaire form has limited edition
 *
 * @returns {Function} The questionnaire's questions' conditions list
 */
const QuestionnaireQuestionConditions = (props) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { nestIndex, hasLimitedEdition } = props;
  const { clearErrors, control, setError, formState } = useFormContext();
  const { errors } = formState;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions[${nestIndex}].visibilityConditions`,
    keyName: "arrayFieldId",
  });

  const questions = useWatch({
    control,
    name: "questions",
    defaultValue: [],
  });

  const question = useWatch({
    control,
    name: `questions[${nestIndex}]`,
    defaultValue: false,
  });

  // Checks all questions for any conflicts in visibility questions
  // Currently only checks conditions relating to single option questions for conflicts
  // Checks if any question needs a value but ends up with no valid option
  const checkConditionConflicts = () => {
    clearErrors("conditionConflict");

    const getAllOptions = () =>
      questions.map((item) => [
        ...Array(
          // if a select question, the form adds an extra empty option in the form that shouldn't be considered
          item.answerTypeId ===
            questionnaireAnswerTypes.singleSelectMultipleChoice.value ||
            item.answerTypeId ===
              questionnaireAnswerTypes.multiSelectMultipleChoice.value
            ? item.options.length - 1
            : item.options.length
        ).keys(),
      ]);
    const allOptions = getAllOptions();

    // For each question X, maintains a list of valid options for other questions so that question X can remain visible
    const remainingOptions = questions.map(() => getAllOptions());

    // Like remainingOptions, maintains a list of required and removed options
    const multiselectOptions = questions.map(() =>
      questions.map(() => ({ required: {}, removed: {} }))
    );

    // Intersects the remaining options for a primary question and another (merging) question
    // Returns -1 if no conflict, otherwise returns the index of the question that has no remaining valid options
    // If there's a conflict, it means the conditions for the merging question conflict with the conditions for the primary question
    const mergeRemainingOptions = (primaryIndex, mergingIndex) => {
      const mergedOptions = [];
      for (let i = 0; i < questions.length; i += 1) {
        mergedOptions[i] = allOptions[i].filter(
          (option) =>
            remainingOptions[primaryIndex][i].includes(option) &&
            remainingOptions[mergingIndex][i].includes(option)
        );
        if (mergedOptions[i].length === 0) return i;
      }
      remainingOptions[primaryIndex] = mergedOptions;
      return -1;
    };

    const mustMatch = (condition) =>
      condition.questionConditionOperatorId ===
      questionnaireConditionOperators.is.value;

    const getOptionIndex = (condition) =>
      parseInt(condition.questionConditionAnswerIndex, 10);

    // Removes invalid options depending on the condition
    // Returns True if there was a conflict (the question this condition points to has no remaining valid options)
    const applyCondition = (primaryIndex, condition, condQuestionIndex) => {
      const newOptions = remainingOptions[primaryIndex][
        condQuestionIndex
      ].filter(
        (option) =>
          mustMatch(condition) === (option === getOptionIndex(condition))
      );
      remainingOptions[primaryIndex][condQuestionIndex] = newOptions;
      return newOptions.length === 0;
    };

    // Updates remainingOptions, adding option as either required or removed
    // Returns empty string if no conflict, otherwise returns a conflict message
    const applyMultiselectCondition = (
      primaryIndex,
      condition,
      condQuestionIndex
    ) => {
      const operatorKey = mustMatch(condition) ? "required" : "removed";
      const oppositeKey = mustMatch(condition) ? "removed" : "required";
      const optionIndex = getOptionIndex(condition);

      multiselectOptions[primaryIndex][condQuestionIndex][operatorKey][
        optionIndex
      ] = true;

      // Check if option is both required and removed
      if (
        multiselectOptions[primaryIndex][condQuestionIndex][oppositeKey][
          optionIndex
        ]
      ) {
        return "option must both be set and not set";
      }
      // Check if all options were removed
      if (
        allOptions[condQuestionIndex].length ===
        Object.keys(multiselectOptions[primaryIndex][condQuestionIndex].removed)
          .length
      ) {
        return "no remaining valid options";
      }
      return "";
    };

    for (
      let questionIndex = 0;
      questionIndex < questions.length;
      questionIndex += 1
    ) {
      clearErrors(`conditionConflict-${questionIndex}`);
      const currentQuestion = questions[questionIndex];
      for (
        let conditionIndex = 0;
        conditionIndex < currentQuestion.visibilityConditions.length;
        conditionIndex += 1
      ) {
        const condition = currentQuestion.visibilityConditions[conditionIndex];

        // Check for empty condition
        if (condition.questionConditionAnswerIndex === "") continue;

        const condQuestionIndex =
          parseInt(condition.questionConditionQuestionNumber, 10) - 1;

        const condQuestionType = questions[condQuestionIndex].answerTypeId;

        if (
          condQuestionType === questionnaireAnswerTypes.yesNo.value ||
          condQuestionType ===
            questionnaireAnswerTypes.singleSelectMultipleChoice.value
        ) {
          const conflictingIndex = mergeRemainingOptions(
            questionIndex,
            condQuestionIndex
          );
          if (conflictingIndex > -1) {
            const issue = `condition ${conditionIndex + 1} causes question ${
              conflictingIndex + 1
            } to have no valid option`;
            // eslint-disable-next-line no-console
            console.log(issue); // For debugging, as this doesn't get displayed (static message instead)
            setError("conditionConflict"); // single error to check to disable save
            setError(`conditionConflict-${questionIndex}`, {
              type: "manual",
              message: issue,
            });
          }
          const conditionConflicts = applyCondition(
            questionIndex,
            condition,
            condQuestionIndex
          );
          if (conditionConflicts) {
            const issue = `condition ${conditionIndex + 1} causes question ${
              condQuestionIndex + 1
            } to have no valid option`;
            // eslint-disable-next-line no-console
            console.log(issue); // For debugging, as this doesn't get displayed (static message instead)
            setError("conditionConflict"); // single error to check to disable save
            setError(`conditionConflict-${questionIndex}`, {
              type: "manual",
              message: issue,
            });
          }
        } else if (
          condQuestionType ===
          questionnaireAnswerTypes.multiSelectMultipleChoice.value
        ) {
          // TODO check if question and conditional question can both be visible
          // i.e. call a function like mergeRemainingOptions() for multiselect

          const conflictMessage = applyMultiselectCondition(
            questionIndex,
            condition,
            condQuestionIndex
          );
          if (conflictMessage) {
            const issue = `condition ${conditionIndex + 1} causes question ${
              condQuestionIndex + 1
            } to have issue: ${conflictMessage}`;
            // eslint-disable-next-line no-console
            console.log(issue); // For debugging, as this doesn't get displayed (static message instead)
            setError("conditionConflict"); // single error to check to disable save
            setError(`conditionConflict-${questionIndex}`, {
              type: "manual",
              message: issue,
            });
          }
        }
      }
    }
  };

  const disabledSelect = (message) => (
    <TextField label="Answer" value="none" select variant="outlined" disabled>
      <MenuItem value="none" disabled>
        {message}
      </MenuItem>
    </TextField>
  );

  const answerField = (conditionIndex) => {
    const selectedQuestionIndex =
      question.visibilityConditions[conditionIndex]
        ?.questionConditionQuestionNumber - 1;

    // If selected question index is less than 0, return no answers
    if (selectedQuestionIndex < 0) return null;

    const selectedQuestion = questions?.[selectedQuestionIndex];

    const commonProps = {
      control,
      id: `questionnaire-question-condition-answer-${conditionIndex}`,
      name: `questions[${nestIndex}].visibilityConditions[${conditionIndex}].questionConditionAnswerIndex`,
      testId: `questionnaireQuestionConditionAnswerIndex${conditionIndex}`,
      variant: "outlined",
      fieldWidth: true,
    };

    const cleanHtml = (htmlString) =>
      htmlString?.replace(/<[^>]*>/g, "").replaceAll("&nbsp;", "");

    const options = selectedQuestion?.options?.flatMap(({ value }, index) =>
      value ? [{ value: index, text: cleanHtml(value) }] : []
    );

    const multipleChoiceField = (possibleAnswers) =>
      possibleAnswers?.length ? (
        <AmoTextField
          {...commonProps}
          label="Answer"
          select
          disabled={!possibleAnswers?.length || hasLimitedEdition}
          onChangeTrigger={checkConditionConflicts}
        >
          <MenuItem value="" disabled>
            Answer
          </MenuItem>
          {possibleAnswers?.map(({ value, text }) => (
            <MenuItem
              key={`questionnaireQuestionConditionAnswerIndex-${value}`}
              value={value}
            >
              {text}
            </MenuItem>
          ))}
        </AmoTextField>
      ) : (
        disabledSelect("No options available yet")
      );

    return {
      [questionnaireAnswerTypes.yesNo.value]: multipleChoiceField([
        { value: "0", text: "Yes" },
        { value: "1", text: "No" },
      ]),
      [questionnaireAnswerTypes.singleSelectMultipleChoice
        .value]: multipleChoiceField(options),
      [questionnaireAnswerTypes.multiSelectMultipleChoice
        .value]: multipleChoiceField(options),
    }[selectedQuestion?.answerTypeId];
  };

  const questionNumbers = questions?.flatMap((item, questionIndex) =>
    questionIndex < nestIndex &&
    [
      questionnaireAnswerTypes.yesNo.value,
      questionnaireAnswerTypes.singleSelectMultipleChoice.value,
      questionnaireAnswerTypes.multiSelectMultipleChoice.value,
    ].includes(item.answerTypeId)
      ? [questionIndex + 1]
      : []
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      {errors[`conditionConflict-${nestIndex}`] && (
        <Alert variant="outlined" severity="error" className={classes.alert}>
          <Typography variant="body1">
            This question has contradictory visibility conditions. This issue
            must be addressed before saving.
          </Typography>
        </Alert>
      )}
      {question.visibilityConditionsSwitch && (
        <>
          <Grid item className={classes.conditionsDivider}>
            <Divider />
          </Grid>
          <Grid item container direction="row" alignItems="center" spacing={3}>
            {!fields?.length && (
              <Grid item>
                <Typography>No conditions found</Typography>
              </Grid>
            )}
            {fields?.map((item, conditionIndex) => (
              <Fragment key={item.arrayFieldId}>
                <Grid item>
                  <Typography variant="body2">
                    Condition {conditionIndex + 1}:
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    xs={11}
                  >
                    <Grid item lg={6} xl={4}>
                      <AmoTextField
                        fullWidth
                        control={control}
                        id={`questionnaire-question-condition-question-number-${conditionIndex}`}
                        name={`questions[${nestIndex}].visibilityConditions[${conditionIndex}].questionConditionQuestionNumber`}
                        testId={`questionnaireQuestionConditionQuestionNumber${conditionIndex}`}
                        label="Question #"
                        select
                        variant="outlined"
                        required
                        onChangeTrigger={() => {
                          checkConditionConflicts();
                        }}
                        placeholder="Question number"
                        selectPlaceholderValue={0}
                        disabled={hasLimitedEdition}
                      >
                        {questionNumbers?.map((questionNumber) => (
                          <MenuItem
                            key={`questionnaireQuestionConditionQuestionNumber-${questionNumber}`}
                            value={questionNumber}
                          >
                            {questionNumber}
                          </MenuItem>
                        ))}
                      </AmoTextField>
                    </Grid>
                    <Grid item lg={6} xl={4}>
                      <AmoTextField
                        fullWidth
                        control={control}
                        id={`questionnaire-question-condition-operator-${conditionIndex}`}
                        name={`questions[${nestIndex}].visibilityConditions[${conditionIndex}].questionConditionOperatorId`}
                        testId={`questionnaireQuestionConditionOperator${conditionIndex}`}
                        select
                        label="Operator"
                        variant="outlined"
                        required
                        placeholder="Operator"
                        selectPlaceholderValue={1}
                        onChangeTrigger={checkConditionConflicts}
                        disabled={hasLimitedEdition}
                      >
                        {Object.values(questionnaireConditionOperators).map(
                          ({ text, value }) => (
                            <MenuItem
                              key={`questionnaireQuestionConditionOperator-${value}-${item.arrayFieldId}`}
                              value={value}
                            >
                              {text}
                            </MenuItem>
                          )
                        )}
                      </AmoTextField>
                    </Grid>
                    <Grid item lg={6} xl={4}>
                      {answerField(conditionIndex) ??
                        disabledSelect("Fill Question # field first")}
                    </Grid>
                  </Grid>
                  {!hasLimitedEdition && (
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          remove(conditionIndex);
                          checkConditionConflicts();
                        }}
                        color="secondary"
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            ))}
            {!hasLimitedEdition && (
              <Grid
                item
                container
                direction="row-reverse"
                className={classes.addConditionRow}
              >
                <Grid item>
                  <Button
                    className={globalClasses.button}
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => append(defaultQuestionCondition())}
                  >
                    <Typography variant="body2">Add Condition</Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

QuestionnaireQuestionConditions.propTypes = {
  nestIndex: PropTypes.number.isRequired,
  hasLimitedEdition: PropTypes.bool.isRequired,
};

export default QuestionnaireQuestionConditions;
