import React, { useState, useRef } from "react";
import {
  Icon,
  makeStyles,
  Divider,
  Grid,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  Button,
} from "@material-ui/core";
import { useHistory, useLocation, Link } from "react-router-dom";

import ListPageWrapper from "components/ListPageWrapper";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import ReviewStatus from "components/amo/ReviewStatus";

import { useGlobalStyles } from "hooks/globalStylesHook";
import { useYearContext } from "contexts/YearContext";
import { useSnackbar } from "contexts/SnackbarContext";
import colors from "constants/colors";
import { snackbarTypes } from "constants/snackbar";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { exportToExcel } from "utils/export";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { reviewStatuses } from "constants/amoTableDropdownOptions";

import {
  useFinancialReviewsHooks,
  useAllocations,
} from "./financialReviewsHooks";
import { useReviewAssignees } from "../commonReviewHooks";
import AllocationsUploadModal from "./AllocationsUploadModal";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  allocationMenuItemIcon: {
    color: colors.black,
    minWidth: "initial",
    marginRight: "1.125rem",
  },
  allocationButton: {
    minWidth: "12rem",
    height: "3.125rem",
  },
}));

/**
 * The Financial Review List Page.
 *
 * @returns {React.Component} Financial Review List Page component.
 */
const FinancialReviewListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { contextYear } = useYearContext();
  const history = useHistory();
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const { getMunicipalitiesNames } = useMunicipalContext();

  const handleAllocationsClick = (event) =>
    setAllocationsAnchorEl(event.target);
  const handleAllocationsClose = () => setAllocationsAnchorEl(null);

  const [allocationsAnchorEl, setAllocationsAnchorEl] = useState(null);
  const [showAllocationsModal, setShowAllocationsModal] = useState(false);
  const [focusedItem, setFocusedItem] = useState({});

  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const {
    data: financials,
    isFetching: financialsFetching,
    refetch: refetchFinancials,
  } = useFinancialReviewsHooks(contextYear);

  const {
    data: allocations,
    isFetching: allocationsFetching,
    refetch: refetchAllocations,
  } = useAllocations();

  const { data: assignees } = useReviewAssignees();

  const tableColumns = [
    {
      fieldKey: "municipalityName",
      label: "Municipality",
      minWidth: "15.625rem",
      customRender: ({ municipalityId, municipalityName }) => (
        <Link
          to={`${`/reviews/financial/${municipalityId}/${contextYear}`}${
            location.search
          }`}
          ref={(element) => {
            rowRefs.current[municipalityId] = element;
          }}
          onFocus={() => setFocusedItem({ municipalityId })}
          className={globalClasses.tableActionCell}
        >
          {municipalityName}
        </Link>
      ),
      isCustom: true,
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "statusName",
      label: "Status",
      minWidth: "9.375rem",
      customRender: ({ statusId, statusName }) => (
        <ReviewStatus id={statusId} name={statusName} />
      ),
      isCustom: true,
      dropdownOptions: reviewStatuses,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "assignedTo",
      label: "Assigned To",
      minWidth: "10rem",
      dropdownOptions: {
        " ": " ",
        ...(assignees?.reduce(
          (obj, { name }) => ({ ...obj, [name]: name }),
          {}
        ) ?? {}),
      },
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "openingBalance",
      label: "Opening Balance",
      minWidth: "13rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "allocation",
      label: "Allocation",
      minWidth: "9.375rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "interestEarned",
      label: "Interest Earned",
      minWidth: "12rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "disposals",
      label: "Disposals",
      minWidth: "9.375rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "netTransfers",
      label: "Net Transfers",
      minWidth: "11rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "netExpenditures",
      label: "Net Expenditures",
      minWidth: "13rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
    {
      fieldKey: "closingBalance",
      label: "Closing Balance",
      minWidth: "12.5rem",
      displayType: displayTypes.currency,
      isRounded: false,
    },
  ];

  const allocationColumns = [
    {
      fieldKey: "municipalityId",
      label: "Municipal ID",
    },
    {
      fieldKey: "municipalityName",
      label: "Municipality Name",
    },
    {
      fieldKey: "announced",
      label: "Amount",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "payment",
      label: "Estimated Payment Date",
      displayType: displayTypes.date,
    },
  ];

  const handleAllocationDownload = async () => {
    try {
      exportToExcel(allocations, allocationColumns, "Allocations", {
        sheetName: "Allocations",
        addTimestamp: true,
      });
    } catch (error) {
      showSnackbar(errorMessages.creatingSpreadsheet, snackbarTypes.error);
    }
  };

  const openAllocationsUploadModal = () => {
    setShowAllocationsModal(true);
    setFocusedItem(null);
  };

  const closeAllocationsUploadModal = (shouldRefetch) => {
    setShowAllocationsModal(false);
    setFocusedItem({});
    if (shouldRefetch) {
      refetchFinancials();
      refetchAllocations();
    }
  };

  const handleItemClick = (item) => {
    history.push(
      `${`/reviews/financial/${item.municipalityId}/${contextYear}`}${
        location.search
      }`
    );
  };

  const allocationsButton = (
    <Button
      aria-label="financialsReviewAllocationsButton"
      onClick={handleAllocationsClick}
      color="primary"
      size="medium"
      variant="outlined"
      className={classes.allocationButton}
      disabled={financialsFetching}
    >
      Allocations
    </Button>
  );

  const tableColumnsToExport = [
    {
      fieldKey: "municipalityId",
      label: "Municipal ID",
    },
    ...tableColumns,
  ];

  const tableActions = {
    actions: [
      tableDefaultActions.search,
      tableDefaultActions.export,
      "allocations",
    ],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            exportToExcel(
              filteredData.current,
              tableColumnsToExport,
              "Financial Information Reviews",
              {
                sheetName: "Reviews",
                specialColumnSizes: {
                  municipalityName: 30,
                  municipalityId: 10,
                },
              }
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
      },
    },
    customActionsComponents: {
      allocations: allocationsButton,
    },
  };

  return (
    <>
      <ListPageWrapper>
        <Grid container direction="column" spacing={2} wrap="nowrap">
          <Grid item>
            <Typography variant="h1">Financial Information</Typography>
          </Grid>
          <Grid item className={classes.dividerContainer}>
            <Divider />
          </Grid>
          <Grid item>
            <AmoTableWithFilters
              items={financials}
              columns={tableColumns}
              defaultOrderBy="municipalityName"
              enableItemClick
              onItemClick={handleItemClick}
              onFilteredDataChanged={(value) => {
                filteredData.current = value;
              }}
              debouncingTime={450}
              onScroll={(item) => {
                rowRefs.current?.[item.municipalityId]?.focus();
              }}
              focusedItem={focusedItem}
              actionsProps={tableActions}
              isLoading={financialsFetching}
              uniqueKeyGenerationFields={["municipalityId"]}
            />
          </Grid>
        </Grid>
      </ListPageWrapper>
      <Menu
        id="financialReviewAllocationMenu"
        anchorEl={allocationsAnchorEl}
        open={!!allocationsAnchorEl}
        onClose={handleAllocationsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            handleAllocationsClose();
            openAllocationsUploadModal();
          }}
        >
          <ListItemIcon className={classes.allocationMenuItemIcon}>
            <Icon className="material-icons-outlined">file_upload</Icon>
          </ListItemIcon>
          <Typography variant="body1">Upload</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAllocationsClose();
            handleAllocationDownload();
          }}
          disabled={allocationsFetching}
        >
          <ListItemIcon className={classes.allocationMenuItemIcon}>
            <Icon className="material-icons-outlined">file_download</Icon>
          </ListItemIcon>
          <Typography variant="body1">Download</Typography>
        </MenuItem>
      </Menu>
      {showAllocationsModal && (
        <AllocationsUploadModal
          open={showAllocationsModal}
          onClose={closeAllocationsUploadModal}
        />
      )}
    </>
  );
};

export default FinancialReviewListPage;
