import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  latitude: number,
 *  longitude: number }} ProjectLocation
 */

/**
 * @typedef {{
 *  amoId: number,
 *  internalId: string,
 *  title: string,
 *  scopeOfWork: string,
 *  objectives: string,
 *  category: string,
 *  natureOfInvestment: string,
 *  location: ProjectLocation,
 *  startDate: Date,
 *  endOfConstructionDate: Date,
 *  endOfFinancingDate: Date,
 *  totalCost: number,
 *  ccbfBudget: number,
 *  ccbfAllocatedInYear: number,
 *  ccbfAllocatedPreviously: number,
 *  signagePosted: boolean,
 *  links: string,
 *  comments: string }} ProjectUploadPayload
 */

/**
 * @typedef {{
 *  amoId: number,
 *  internalId: string,
 *  title: string,
 *  status: string }} ProjectUploadResponse
 */

const templateFileApi = new Api();
const projectTemplateApi = new Api("api/projects");

export const templateService = {
  /**
   * @param {ProjectUploadPayload[]} payload Array of projects
   *
   * @returns {Promise<AxiosResponse<ProjectUploadResponse[]>>} Array with the status of each project upload
   */
  projectTemplateUpload: (payload) =>
    projectTemplateApi.axios.post("upload", payload),
  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  getTemplateFile: () =>
    templateFileApi.axios.get("/project_template.xlsx", {
      responseType: "arraybuffer",
    }),
  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  getResultsTemplateFile: () =>
    templateFileApi.axios.get("/project_results_template.xlsx", {
      responseType: "arraybuffer",
    }),
};
