import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  year: number,
 *  title: string,
 *  lastEdited: string,
 *  published: boolean }} QuestionnaireListItem
 */

/**
 * @typedef {{
 *  parentQuestionId: number,
 *  visibilityOperator: number,
 *  visibilityValueId: number }} VisibilityCondition
 */

/**
 * @typedef {{
 *  id: number,
 *  questionnaireId: number,
 *  text: string,
 *  descriptionText: string,
 *  answerValues: string[],
 *  required: boolean,
 *  visibilityLogic: VisibilityCondition[],
 *  isPublished: boolean }} Question
 */

/**
 * @typedef {{
 *  id: number,
 *  type: string,
 *  year: number,
 *  title: string,
 *  instructionText: string,
 *  isPublished: boolean,
 *  questions: Question[] }} Questionnaire
 */

const api = new Api("api/questionnaires");
export const questionnaireService = {
  /**
   * @returns {Promise<AxiosResponse<QuestionnaireListItem[]>>}
   */
  getAll: () => api.get(),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<Questionnaire>>}
   */
  getById: (id) => api.getById(id),
  /**
   * @param {number} year
   * @param {string} type
   * @param {number=} id
   * @returns {Promise<AxiosResponse<boolean>>}
   */
  existsByYearAndType: (year, type, id = undefined) =>
    api.getById(`exists/${year}/${type}`, { id }),
  create: (payload) => api.post(payload),
  update: (id, payload) => api.put(id, payload),
  remove: (id) => api.del(id),
};
