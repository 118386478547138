import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Grid, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import { useForm, useWatch } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { questionnaireSubmissionService } from "api/services/questionnaireSubmissionService";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";
import { questionnaireAnswerTypes } from "constants/questionnaireAnswerTypes";
import UnsavedChangesPopover from "components/UnsavedChangesPopover";
import { roleGroups } from "constants/user";
import AmoFormActions from "components/AmoFormActions";
import ProjectReportQuestionnaireQuestion from "../report/ProjectReportQuestionnaireQuestion";
import { useAnnualReportNavBar } from "components/annualReport/annualReportNavBarHooks";
import { questionnaireConditionOperators } from "constants/questionnaireConditionOperators";
import { useIsMounted } from "hooks/useIsMounted";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  textGrid: {
    position: "relative",
    width: "80%",
    marginLeft: "3.5rem",
    paddingBottom: "5rem",
  },
  button: {
    width: "15rem",
    height: "3.125rem",
    borderWidth: "0.125rem",
  },
  bottomGridContainer: {
    border: "0.0625rem solid grey",
    paddingLeft: theme.spacing(5),
    marginTop: "1rem",
  },
  formActions: {
    position: "fixed",
    bottom: 0,
    left: 0,
    background: "white",
  },
  listItemText: {
    "& > span > p": {
      "&:first-child": {
        marginTop: "0rem",
      },
      "&:last-child": {
        marginBottom: "0rem",
      },
      "& > a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontWeight: "bolder",
      },
    },
    "& > span > p > a:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * Creates a risk or asset management questionnaire page
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.riskManagement - indicates whether this is a risk or asset management questionnaire
 * @returns - the risk or asset management questionnaire page
 */
const MunicipalQuestionnairePage = (props) => {
  const {
    riskManagement,
    yearOverride,
    municipalityIdOverride,
    responseOverride,
    extraActions,
    isAmoStaff,
    dataSaved,
  } = props;

  const classes = useStyles();
  const { year: questionnaireYear = yearOverride } = useParams();
  const { showSnackbar } = useSnackbar();
  const { user: currentUser, hasRoles } = useUserContext();
  const municipalityId = municipalityIdOverride ?? currentUser?.municipalityId;
  const hasQuestionnairesPermission =
    isAmoStaff || hasRoles(roleGroups.municipal.questionnaires);
  const history = useHistory();

  const mounted = useIsMounted();

  const [description, setDescription] = useState("");
  const [questionnaireId, setQuestionnaireId] = useState(0);
  const [submissionId, setSubmissionId] = useState(0);
  const [initialQuestions, setInitialQuestions] = useState([]);
  const [openUnsavedPrompt, setOpenUnsavedPrompt] = useState(false);
  const [targetPath, setTargetPath] = useState("");
  const [savedCheckboxAnswers, setSavedCheckboxAnswers] = useState([]);
  const [fileUploadAction, setFileUploadAction] = useState("Idle");

  const defaultValues = { questions: [] };

  const {
    control,
    getValues,
    reset,
    setValue,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues.questions,
    mode: "onChange",
  });
  const { isSubmitting, isDirty } = formState;

  const questionsWatch = useWatch({
    control,
    name: "questions",
  });

  const { refetch } = useAnnualReportNavBar(municipalityId);

  useLayoutEffect(() => {
    // function that gets the questionnaire data
    const getData = async () => {
      try {
        const questions = [];
        let response = responseOverride;
        if (responseOverride == null) {
          if (riskManagement) {
            response = await questionnaireSubmissionService.getRiskQuestionnaire(
              municipalityId,
              questionnaireYear
            );
          } else {
            response = await questionnaireSubmissionService.getAssetQuestionnaire(
              municipalityId,
              questionnaireYear
            );
          }

          if (!mounted.current) {
            return;
          }
        }

        if (response && response.status === 200) {
          setQuestionnaireId(response.data?.id);
          setSubmissionId(response.data?.submissionId);
          setDescription(response.data.instructionText);
          // This will be used to avoid a bug in RHF when mounting non-primitive objects
          const savedCheckbox = {};
          response.data?.questions?.forEach((question, i) => {
            let mappedAnswers;
            let files;
            switch (question.answerType) {
              case questionnaireAnswerTypes.multiSelectMultipleChoice.text:
                mappedAnswers = question.answers?.map((item) => {
                  // using savedCheckbox is not ideal, but if we instead try to set the answer value directly in the question RHF object,
                  // an error will appear when navigating between questionnaires
                  savedCheckbox[item.id] =
                    question.selectedAnswer?.filter(
                      (ans) => ans.value === item.id.toString()
                    ).length > 0;
                  return {
                    id: item.id,
                    textValue: item.value,
                    value:
                      question.selectedAnswer?.filter(
                        (ans) => ans.value === item.value
                      ).length > 0,
                  };
                });
                break;
              case questionnaireAnswerTypes.assetManagementPlanUpload.text:
                mappedAnswers = question.selectedAnswer.map(
                  (item) => item.value
                );
                files = question.selectedAnswer
                  .filter((item) => item.file)
                  .map((item) => item.file);
                break;
              case questionnaireAnswerTypes.singleSelectMultipleChoice.text:
              case questionnaireAnswerTypes.yesNo.text:
                mappedAnswers = question.selectedAnswer[0]?.value
                  ? question.selectedAnswer[0]?.value
                  : "none";
                break;
              case questionnaireAnswerTypes.date.text:
                mappedAnswers = question.selectedAnswer[0]?.value
                  ? DateTime.fromISO(question.selectedAnswer[0]?.value)
                  : "";
                break;
              default:
                mappedAnswers = question.selectedAnswer[0]?.value
                  ? question.selectedAnswer[0]?.value
                  : "";
                break;
            }
            questions[i] = {
              id: question.id,
              text: question.text,
              description: question.description,
              answer: mappedAnswers,
              answers: question.answers,
              answerType: question.answerType,
              visibilityRequirements: question.visibilityRequirements,
              visible: true,
              selectedAnswer: question.selectedAnswer,
              // if this is undefined, the municipalQuestionnaire throws an error, so those cases are set to false since falsey doesn't work
              required: question.required ? question.required : false,
              characterLimit: question.characterLimit,
              files,
            };
          });
          setSavedCheckboxAnswers(savedCheckbox);
          setInitialQuestions(questions);
        }
      } catch {
        if (!mounted.current) {
          return;
        }

        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
    };
    getData();
  }, [riskManagement]);

  useEffect(() => {
    if (initialQuestions) {
      reset({
        questions: initialQuestions.map((question) => ({
          ...question,
          answer:
            question.answerType === questionnaireAnswerTypes.date.text &&
            question.answer === ""
              ? null
              : question.answer,
        })),
        checkboxAnswers: savedCheckboxAnswers,
      });
    }
  }, [initialQuestions]);

  useEffect(() => {
    history.block((prompt) => {
      setTargetPath(prompt.pathname);
      setOpenUnsavedPrompt(isDirty);
      return !isDirty;
    });
  }, [history, isDirty]);

  const renderFormActions = () => [
    {
      testId: "saveResponsesButton",
      label: "Save Responses",
      disabled: !hasQuestionnairesPermission || isSubmitting,
      onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
    },
    ...(extraActions ?? []),
  ];

  const handleSaveClick = async ({ questions, checkboxAnswers }) => {
    let response = {};
    let filteredQuestions = questions.filter((item) => item?.visible);
    // Filter asset management plan answers if the submission already exist,
    // because the upload on these cases are handled by the backend
    if (submissionId) {
      filteredQuestions = filteredQuestions.filter(
        (item) =>
          item.answerType !==
          questionnaireAnswerTypes.assetManagementPlanUpload.text
      );
    }
    const payload = {
      questionnaireId,
      id: submissionId,
      answers: filteredQuestions.map((question) => {
        let answers;
        if (!question.answer) {
          answers = [""];
        } else {
          switch (question.answerType) {
            case questionnaireAnswerTypes.multiSelectMultipleChoice.text:
              answers = question.answer
                ?.filter((item) => checkboxAnswers[item.id]) // setting the values like this will avoid a bug in RHF when mounting non-primitive objects
                .map((item) => item.id.toString());
              break;
            case questionnaireAnswerTypes.assetManagementPlanUpload.text:
              answers = question.answer;
              break;
            case questionnaireAnswerTypes.singleSelectMultipleChoice.text:
            case questionnaireAnswerTypes.yesNo.text:
              answers = question.answer !== "none" ? [question.answer] : [""];
              break;
            case questionnaireAnswerTypes.date.text:
              // Passing only the iso date (without time)
              answers = question.answer.isValid
                ? [question.answer.toISODate()]
                : [""];
              break;
            default:
              answers = [question.answer];
              break;
          }
        }
        return {
          questionId: question.id,
          answerValues: answers,
          answer: answers,
        };
      }),
    };
    if (submissionId && submissionId !== 0) {
      response = riskManagement
        ? await questionnaireSubmissionService.updateRiskQuestionnaire(
            municipalityId,
            questionnaireYear,
            submissionId,
            payload
          )
        : await questionnaireSubmissionService.updateAssetQuestionnaire(
            municipalityId,
            questionnaireYear,
            submissionId,
            payload
          );
    } else {
      response = riskManagement
        ? await questionnaireSubmissionService.postRiskQuestionnaire(
            municipalityId,
            questionnaireYear,
            payload
          )
        : await questionnaireSubmissionService.postAssetQuestionnaire(
            municipalityId,
            questionnaireYear,
            payload
          );
    }

    if (!mounted.current) {
      return;
    }

    if (response && response.status === 200) {
      showSnackbar("Responses saved", snackbarTypes.success);
      if (!submissionId || submissionId === 0) {
        setSubmissionId(response.data.id);
      }
      refetch();
      reset({ questions });
      dataSaved();
    } else {
      showSnackbar(
        "Something went wrong.  Please contact the system administrator.",
        snackbarTypes.error
      );
    }
  };

  const saveUploadedFilesIds = async (questionName, savedFileIds) => {
    setValue(questionName, [savedFileIds[0].id.toString()]);
    setValue(questionName.replace("answer", "files"), savedFileIds);
  };

  const unblockNavigation = () => {
    history.block(() => {});
    setOpenUnsavedPrompt(false);
    history.push(targetPath);
  };

  const saveUnsavedChanges = async () => {
    await handleSaveClick({
      questions: getValues("questions"),
      checkboxAnswers: getValues("checkboxAnswers"),
    });

    if (!mounted.current) {
      return;
    }

    unblockNavigation();
  };

  const deleteFileAnswers = async (questionName) => {
    setValue(questionName, "");
    setValue(questionName.replace("answer", "files"), []);
  };

  const continueWithoutSaving = () => {
    // Filter AMP questions that have files uploaded
    const ampQuestions =
      getValues("questions")?.filter(
        (question) =>
          question.answerType ===
            questionnaireAnswerTypes.assetManagementPlanUpload.text &&
          question.files?.length
      ) ?? [];
    const hasVisibleUploadedFiles = ampQuestions?.some(
      (question) => question.visible
    );
    if (ampQuestions?.length && hasVisibleUploadedFiles) {
      setFileUploadAction("Rollback");
    } else {
      unblockNavigation();
    }
  };

  const onFileRollback = () => {
    setFileUploadAction("Idle");
    unblockNavigation();
  };

  const handleUnsavedPromptClose = () => {
    setOpenUnsavedPrompt(false);
  };

  /**
   * Validates whether a question should be visible based on requirements
   *
   * @param {number} questionId id of question to check
   * @returns {boolean} True if should be visible, false otherwise
   */
  const validateVisibilityRequirements = (questionId) => {
    // ID-indexed object of all questions
    const questions = Object.fromEntries(
      getValues().questions.map((item) => [item.id, item])
    );
    const question = questions[questionId];

    // Check if there are any requirments to check
    if (
      !question.visibilityRequirements ||
      question.visibilityRequirements?.length === 0
    )
      return true;

    // Loop through requirements and check if they are all met
    for (let i = 0; i < question.visibilityRequirements.length; i += 1) {
      const conditionEqualsIs =
        question.visibilityRequirements[i].visibilityOperator ===
        questionnaireConditionOperators.is.value;

      const conditionEqualsIsNot =
        question.visibilityRequirements[i].visibilityOperator ===
        questionnaireConditionOperators.isNot.value;

      // The question that is the requirement for visibility
      const parentQuestion =
        questions[question.visibilityRequirements[i].parentQuestionId];

      // If the parent question is not visible or has no answer, requirement is not met
      if (!parentQuestion.answer || !parentQuestion.visible) {
        question.visible = false;
        return false;
      }

      const parentIsMultiSelect =
        parentQuestion?.answerType ===
        questionnaireAnswerTypes.multiSelectMultipleChoice.text;

      // The answer(s) that have been selected
      const selectedAnswersIds = parentIsMultiSelect
        ? parentQuestion?.answer
            ?.filter((item) => getValues(`checkboxAnswers.${item.id}`))
            .map((item) => item.id)
        : [parseInt(parentQuestion?.answer, 10)];

      // True if the answer in the requirement was selected
      const answerIsSelected = selectedAnswersIds.includes(
        question.visibilityRequirements[i].visibilityAnswerId
      );

      // If requirement is not met, return false
      if (
        (conditionEqualsIs && !answerIsSelected) ||
        (conditionEqualsIsNot && answerIsSelected)
      ) {
        question.visible = false;
        return false;
      }
    }
    question.visible = true;
    return true;
  };

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.textGrid}
        wrap="nowrap"
        spacing={3}
      >
        <Grid item>
          <ListItemText
            className={classes.listItemText}
            variant="body1"
            component="span"
          >
            {description && parse(description)}
          </ListItemText>
        </Grid>
        {questionsWatch &&
          questionsWatch?.map(
            (item, i) =>
              validateVisibilityRequirements(item.id) && (
                <ProjectReportQuestionnaireQuestion
                  key={item.id}
                  control={control}
                  name={`questions[${i}].answer`}
                  id={`question-[${i}]`}
                  testId={`question[${i}]`}
                  title={`${questionsWatch[i]?.text}${
                    questionsWatch[i].required ? "*" : ""
                  }`}
                  type={item.answerType}
                  label={`${questionsWatch[i]?.text}${
                    questionsWatch[i].required ? "*" : ""
                  }`}
                  description={questionsWatch[i]?.description}
                  answerValues={questionsWatch[i]?.answers}
                  fileAssociationObjectId={
                    submissionId && submissionId !== 0 ? item.id : -1
                  }
                  allowedFileFormats={["pdf"]}
                  municipalityId={municipalityId}
                  fileYear={questionnaireYear}
                  saveUploadedFilesIds={saveUploadedFilesIds}
                  deleteFileAnswers={deleteFileAnswers}
                  files={item.files}
                  readOnly={!hasQuestionnairesPermission}
                  characterLimit={parseInt(item.characterLimit, 10)}
                  uploadAction={fileUploadAction}
                  onRollback={onFileRollback}
                />
              )
          )}
        <AmoFormActions
          className={classes.formActions}
          actions={renderFormActions()}
          hideLateralBorders
        />
      </Grid>
      <UnsavedChangesPopover
        id="municipal-questionnaire-confirm-unsaved-prompt"
        testId="municipalQuestionnaireConfirmUnsavedPrompt"
        open={!!openUnsavedPrompt}
        onSave={() => saveUnsavedChanges()}
        onContinue={() => continueWithoutSaving()}
        onClose={handleUnsavedPromptClose}
      />
    </>
  );
};

MunicipalQuestionnairePage.propTypes = {
  riskManagement: PropTypes.bool,
  yearOverride: PropTypes.bool,
  municipalityIdOverride: PropTypes.number,
  extraActions: PropTypes.arrayOf(
    PropTypes.shape({
      testId: PropTypes.string.isRequired,
      label: PropTypes.string,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
      ]),
      color: PropTypes.oneOf(["primary", "secondary"]),
      variant: PropTypes.oneOf(["outlined", "contained"]),
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      className: PropTypes.string,
    })
  ),
  isAmoStaff: PropTypes.bool,
  responseOverride: PropTypes.shape(),
  dataSaved: PropTypes.func,
};

MunicipalQuestionnairePage.defaultProps = {
  riskManagement: false,
  yearOverride: undefined,
  municipalityIdOverride: undefined,
  extraActions: undefined,
  isAmoStaff: false,
  responseOverride: undefined,
  dataSaved: () => {},
};

export default MunicipalQuestionnairePage;
