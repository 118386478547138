export const reviewStatuses = {
  Incomplete: "Incomplete",
  Pending: "Pending",
  Updated: "Updated",
  Approved: "Approved",
  Contacted: "Contacted",
  "To be contacted": "To be contacted",
  "To be revised": "To be revised",
  "To be discussed": "To be discussed",
};

export const communicationStatuses = {
  None: "None",
  Considering: "Considering",
  Planning: "Planning",
  "On Hold": "On Hold",
  Ready: "Ready",
  Cancelled: "Cancelled",
  Published: "Published",
};

export const amoUserSpecialRoles = {
  "": "",
  Lead: "Lead",
  Manager: "Manager",
};

export const municipalUserPermissions = {
  Delegate: "Delegate",
  "Primary Contact": "Primary Contact",
  Financials: "Financials",
  Projects: "Projects",
  Communications: "Communications",
};

export const projectCategories = {
  "Broadband Connectivity": "Broadband Connectivity",
  "Brownfield Redevelopment": "Brownfield Redevelopment",
  "Capacity-Building": "Capacity-Building",
  "Community Energy Systems": "Community Energy Systems",
  Culture: "Culture",
  "Disaster Mitigation": "Disaster Mitigation",
  "Drinking Water": "Drinking Water",
  "Fire Stations": "Fire Stations",
  "Local Roads and Bridges - Active Transportation":
    "Local Roads and Bridges - Active Transportation",
  "Local Roads and Bridges - Bridges": "Local Roads and Bridges - Bridges",
  "Local Roads and Bridges - Culverts": "Local Roads and Bridges - Culverts",
  "Local Roads and Bridges - Local Roads":
    "Local Roads and Bridges - Local Roads",
  "Local Roads and Bridges - Other": "Local Roads and Bridges - Other",
  "Public Transit": "Public Transit",
  Recreation: "Recreation",
  "Regional and Local Airports": "Regional and Local Airports",
  "Short-line Rail": "Short-line Rail",
  "Short-sea Shipping": "Short-sea Shipping",
  "Solid Waste": "Solid Waste",
  Sports: "Sports",
  Tourism: "Tourism",
  Wastewater: "Wastewater",
};

export const projectCategoriesShort = {
  "Broadband Connectivity": "Broadband Connectivity",
  "Brownfield Redevelopment": "Brownfield Redevelopment",
  "Capacity-Building": "Capacity-Building",
  "Community Energy Systems": "Community Energy Systems",
  Culture: "Culture",
  "Disaster Mitigation": "Disaster Mitigation",
  "Drinking Water": "Drinking Water",
  "Fire Stations": "Fire Stations",
  "Active Transportation": "Local Roads and Bridges - Active Transportation",
  Bridges: "Local Roads and Bridges - Bridges",
  Culverts: "Local Roads and Bridges - Culverts",
  "Local Roads": "Local Roads and Bridges - Local Roads",
  Other: "Local Roads and Bridges - Other",
  "Public Transit": "Public Transit",
  Recreation: "Recreation",
  "Regional and Local Airports": "Regional and Local Airports",
  "Short-line Rail": "Short-line Rail",
  "Short-sea Shipping": "Short-sea Shipping",
  "Solid Waste": "Solid Waste",
  Sports: "Sports",
  Tourism: "Tourism",
  Wastewater: "Wastewater",
};

export const projectCategoriesWithColon = {
  "Broadband Connectivity": "Broadband Connectivity",
  "Brownfield Redevelopment": "Brownfield Redevelopment",
  "Capacity-Building": "Capacity-Building",
  "Community Energy Systems": "Community Energy Systems",
  Culture: "Culture",
  "Disaster Mitigation": "Disaster Mitigation",
  "Drinking Water": "Drinking Water",
  "Fire Stations": "Fire Stations",
  "Local Roads and Bridges: Active Transportation":
    "Local Roads and Bridges: Active Transportation",
  "Local Roads and Bridges: Bridges": "Local Roads and Bridges: Bridges",
  "Local Roads and Bridges: Culverts": "Local Roads and Bridges: Culverts",
  "Local Roads and Bridges: Local Roads":
    "Local Roads and Bridges: Local Roads",
  "Local Roads and Bridges: Other": "Local Roads and Bridges: Other",
  "Public Transit": "Public Transit",
  Recreation: "Recreation",
  "Regional and Local Airports": "Regional and Local Airports",
  "Short-line Rail": "Short-line Rail",
  "Short-sea Shipping": "Short-sea Shipping",
  "Solid Waste": "Solid Waste",
  Sports: "Sports",
  Tourism: "Tourism",
  Wastewater: "Wastewater",
};

export const projectCategoriesAbbreviations = {
  BB: "Broadband Connectivity (BB)",
  BR: "Brownfield Redevelopment (BR)",
  CB: "Capacity Building (CB)",
  CE: "Community Energy Systems (CE)",
  CU: "Culture (CU)",
  DM: "Disaster Mitigation (DM)",
  DW: "Drinking Water (DW)",
  FI: "Fire Stations (FI)",
  "LR-A": "Local Roads and Bridges - Active Transportation (LR-A)",
  "LR-B": "Local Roads and Bridges - Bridges (LR-B)",
  "LR-C": "Local Roads and Bridges - Culverts (LR-C)",
  "LR-R": "Local Roads and Bridges - Local Roads (LR-R)",
  "LR-O": "Local Roads and Bridges - Other (LR-O)",
  PT: "Public Transit (PT)",
  RE: "Recreation (RE)",
  RA: "Regional and Local Airports (RA)",
  SR: "Short-line Rail (SR)",
  SS: "Short-sea Shipping (SS)",
  SW: "Solid Waste (SW)",
  SP: "Sports (SP)",
  TO: "Tourism (TO)",
  WW: "Wastewater (WW)",
};

export const projectStatuses = {
  Planned: "Planned",
  "Construction in Progress": "Construction in Progress",
  "Construction Complete": "Construction Complete",
  Closed: "Closed",
};

export const projectReportStatuses = {
  "Reporting Complete": "Reporting Complete",
  "Flags: Please Review": "Flags: Please Review",
  "Reporting Incomplete": "Reporting Incomplete",
  "Report CCBF Funding": "Report CCBF Funding",
  "Report Results": "Report Results",
  "Deletion Requested": "Deletion Requested",
};

export const indicatorTypes = {
  Output: "Output",
  Outcome: "Outcome",
};

export const indicatorValueTypes = {
  Numeric: "Numeric",
  Checkbox: "Checkbox",
  List: "List",
};

export const insuranceReviewStatuses = {
  "": "None",
  "Select One": "Unselected",
  Approved: "Approved",
  "Not Approved": "Not Approved",
};

export const incrementalityMonitoringStatuses = {
  Compliant: "Compliant",
  "At Risk": "At Risk",
};

export const bankInformationStatuses = {
  "Unlock Requested": "Unlock Requested",
  Unverified: "Unverified",
  Verified: "Verified",
};

export const bankLimitMonitoringStatuses = {
  "In Compliance": "In Compliance",
  "Non-Compliant": "Non-Compliant",
  "At Risk": "At Risk",
};
