import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { BackArrowSvg } from "img/notFoundImages";
import colors from "constants/colors";
import { routes } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    width: "auto",
    padding: "2rem 5rem",
    overflow: "hidden",
    scrollbarWidth: "none",
    scrollbarColor: "transparent",
    msOverflowStyle: "none",
    maxHeight: "94vh",
  },
  linkContainer: {
    marginTop: "7.75rem",
  },
  h1: {
    fontSize: "2.5rem",
  },
  darkGreen: {
    color: colors.green.dark,
  },
  leftPadding: {
    paddingLeft: "1rem",
  },
  textLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * A page to display generic errors
 *
 * @param props
 * @param props.title - the title of the error
 * @param props.description - the description of the error
 *
 * @returns {React.Component} The page component
 */
const GenericErrorPage = ({ title, description }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Grid
          container
          className={classes.gridContainer}
          direction="column"
          wrap="nowrap"
        >
          <Grid item>
            <Grid
              container
              direction="column"
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant="h1"
                  className={clsx(classes.h1, classes.darkGreen)}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  className={classes.darkGreen}
                  align="center"
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              className={classes.linkContainer}
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item>
                <BackArrowSvg />
              </Grid>
              <Grid item className={classes.leftPadding}>
                <Link
                  data-testid="notFoundHomeLink"
                  to={routes.dashboard}
                  className={classes.textLink}
                >
                  <Typography color="primary" variant="h6">
                    Back to home
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

// set the prop-types for the component
GenericErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default GenericErrorPage;
