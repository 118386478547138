import { useQuery } from "react-query";
import { questionnaireService } from "api/services/questionnaireService";

// Query Keys
import { questionnaireManagementKeys } from "./questionnaireManagementQueryKeys";

/**
 * Store server's state of questionnaire
 *
 * @param {string} questionnaireId The id of the questionnaire being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useQuestionnaire = (questionnaireId) =>
  useQuery(
    questionnaireManagementKeys.get(questionnaireId),
    () => questionnaireService.getById(questionnaireId),
    {
      select: ({ data }) => data,
      enabled: !!questionnaireId && questionnaireId !== "new",
    }
  );
