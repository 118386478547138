export const downloadWorkbook = async (workbook, name) => {
  try {
    const data = await workbook.outputAsync();
    const xlsxName = name;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(data, xlsxName);
    } else {
      const anchor = document.createElement("a");
      const url = window.URL.createObjectURL(data);
      document.body.appendChild(anchor);
      anchor.href = url;
      anchor.download = xlsxName;
      anchor.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(anchor);
    }
  } catch (error) {
    console.error(error);
  }
};
