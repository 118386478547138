import { useQuery } from "react-query";

import { financialReviewsQueryKeys } from "./financialReviewsQueryKeys";
import { reviewService } from "api/services/reviewsService";
import { aerChartService } from "api/services/aerChartService";
import { allocationsService } from "api/services/allocationsService";

/**
 * Store server's state of financials results
 *
 * @param {number} year
 * @returns The query object returned by the useQuery hook
 */
export const useFinancialReviewsHooks = (year) =>
  useQuery(
    financialReviewsQueryKeys.financialReviews(year),
    () => reviewService.getByYear(year),
    {
      select: ({ data }) =>
        data.map((financialInfo) => ({
          ...financialInfo,
          netExpenditures: financialInfo.netExpenditures * -1,
        })),
      enabled: !!year,
    }
  );
/**
 * Store server's state of financial years results
 *
 * @returns The query object returned by the useQuery hook
 */
export const useFinancialReviewYearsHooks = () =>
  useQuery(
    financialReviewsQueryKeys.financialReviewYears,
    () => reviewService.getYears(),
    {
      select: ({ data }) => data.sort((item1, item2) => item2 - item1),
    }
  );

/**
 * Store server's state of single financial review data
 *
 * @param {number} municipalityId
 * @param {number} year
 *
 * @returns The query object returned by the useQuery hook
 */
export const useFinancialReviewData = (municipalityId, year) =>
  useQuery(
    financialReviewsQueryKeys.financialReviewData(municipalityId, year),
    () => reviewService.getFinancialsReview(municipalityId, year),
    {
      select: ({ data }) => data,
    }
  );

/**
 * Store server's state of AER Chart data
 *
 * @param {number} municipalityId
 * @param {number} yearStart
 * @param {number} yearEnd
 * @returns The query object returned by the useQuery hook
 */
export const useAerChartData = (municipalityId, yearStart, yearEnd) =>
  useQuery(
    financialReviewsQueryKeys.aerChart({ municipalityId, yearStart, yearEnd }),
    () =>
      aerChartService.getById(municipalityId, {
        municipalityId,
        yearStart,
        yearEnd,
      }),
    {
      select: ({ data }) => data.years,
    }
  );

/**
 * Store server's state of allocation results
 *
 * @returns The query object returned by the useQuery hook
 */
export const useAllocations = () =>
  useQuery(
    financialReviewsQueryKeys.financialReviewAllocations,
    () => allocationsService.getAll(),
    {
      select: ({ data }) => data,
    }
  );
