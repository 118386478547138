import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, Link } from "react-router-dom";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import { useAmoUserHooks } from "./hooks/userDataHooks";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useSnackbar } from "contexts/SnackbarContext";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { snackbarTypes } from "constants/snackbar";
import { exportToExcel } from "utils/export";
import { amoUserSpecialRoles } from "constants/amoTableDropdownOptions";
import { errorMessages } from "constants/errorMessages";

/**
 * A table component for displaying AMO users
 *
 * @param {object} props
 * @returns {React.Component} The table component
 */
const AmoUserTable = (props) => {
  const { id, tableTitleComponent } = props;

  const history = useHistory();
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const { showSnackbar } = useSnackbar();

  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const { data: userData, isFetching: dataFetching } = useAmoUserHooks();

  const tableColumns = [
    { fieldKey: "imisId", label: "IMIS ID", widthPerc: 10 },
    {
      fieldKey: "name",
      label: "Name",
      widthPerc: 35,
      customRender: (user) => (
        <Link
          to={`/users/amo/${user.id}${location.search}`}
          ref={(element) => {
            rowRefs.current[user.id] = element;
          }}
          onFocus={() => setFocusedItem({ id: user.id })}
          className={globalClasses.tableActionCell}
        >
          {user.name}
        </Link>
      ),
      isCustom: true,
    },
    {
      fieldKey: "specialRole",
      label: "Special Role",
      dropdownOptions: amoUserSpecialRoles,
      displayType: displayTypes.dropdown,
    },
  ];

  const handleItemClick = (user) => {
    history.push(`/users/amo/${user.id}${location.search}`);
  };

  const tableActions = {
    titleComponent: tableTitleComponent,
    actions: [tableDefaultActions.search, tableDefaultActions.export],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            exportToExcel(filteredData.current, tableColumns, "AMO Staff");
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
      },
    },
  };

  return (
    <AmoTableWithFilters
      id={id}
      testId="amoUserTable"
      items={dataFetching ? [] : userData}
      columns={tableColumns}
      defaultOrderBy="name"
      enableItemClick
      onItemClick={handleItemClick}
      onFilteredDataChanged={(value) => {
        filteredData.current = value;
      }}
      onScroll={(item) => {
        rowRefs.current?.[item.id]?.focus();
      }}
      focusedItem={focusedItem}
      actionsProps={tableActions}
      isLoading={dataFetching}
    />
  );
};

// set the prop-types for this component
AmoUserTable.propTypes = {
  id: PropTypes.string,
  tableTitleComponent: PropTypes.node,
};

AmoUserTable.defaultProps = {
  id: "amo-user-table",
  tableTitleComponent: null,
};

export default AmoUserTable;
