import React from "react";
import PropTypes from "prop-types";
import colors from "../../constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box, Icon } from "@material-ui/core";
import { projectNavOptions } from "../../constants/projectNavOptions";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigationHelper } from "../../hooks/navigationHook";

// this function handles instance specific settings
const getItemSettings = (currentMenuItem) => {
  const returnValue = {
    itemText: null,
    icon: null,
  };

  switch (currentMenuItem) {
    case projectNavOptions.Projects: {
      returnValue.itemText = "Projects";
      returnValue.icon = (
        <Icon className="material-icons-outlined" fontSize="small">
          construction
        </Icon>
      );
      break;
    }
    case projectNavOptions.Insurance: {
      returnValue.itemText = "Insurance";
      returnValue.icon = (
        <Icon className="material-icons-outlined" fontSize="small">
          verified_user
        </Icon>
      );
      break;
    }
    case projectNavOptions.Financials: {
      returnValue.itemText = "Financials";
      returnValue.icon = (
        <Icon className="material-icons-outlined" fontSize="small">
          monetization_on
        </Icon>
      );
      break;
    }
    case projectNavOptions.RiskManagement: {
      returnValue.itemText = "Risk Management Questionnaire";
      returnValue.icon = (
        <Icon className="material-icons-outlined" fontSize="small">
          quiz
        </Icon>
      );
      break;
    }
    case projectNavOptions.AssetManagement: {
      returnValue.itemText = "Asset Management Questionnaire";
      returnValue.icon = (
        <Icon className="material-icons-outlined" fontSize="small">
          quiz
        </Icon>
      );
      break;
    }
    case projectNavOptions.ReportingYear: {
      returnValue.itemText = "Submit Report";
      returnValue.icon = (
        <Icon className="material-icons-outlined" fontSize="small">
          summarize
        </Icon>
      );
      break;
    }
    default: {
      returnValue.itemText = "Unknown Item";
      returnValue.icon = (
        <Icon className="material-icons-outlined" fontSize="small">
          lock
        </Icon>
      );
      break;
    }
  }

  return returnValue;
};

// this method sets the styles for the component's controls
const useStyles = makeStyles((theme) => ({
  paper: {
    border: true,
    height: "100%",
    backgroundColor: ({ selected }) => (selected ? "#F1F1F1" : "#FFFFFF"),
    justifyContent: "space-around",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  item: {
    width: "100%",
    height: "3.5rem",
    backgroundColor: ({ selected }) => (selected ? "#F1F1F1" : "#FFFFFF"),
  },
  iconGridItem: {
    height: "100%",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  iconBox: {
    color: ({ selected }) => (selected ? colors.black : colors.green.main),
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "100%",
  },
  itemWidgetDisplay: {
    marginLeft: "auto",
    padding: "0 1rem 0 0",
  },
  textContainer: {
    width: "85%",
    display: "flex",
    alignItems: "center",
  },
  menuLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  menuActiveLink: {
    color: colors.black,
    "&:hover": {
      cursor: "default",
      textDecoration: "none",
      backgroundColor: "#F8F8F8",
    },
  },
  menuLinkText: {
    color: colors.green.main,
    fontWeight: "bold",
  },
  menuActiveLinkText: {
    color: colors.black,
    fontWeight: "bold",
  },
  statusText: {
    color: colors.black,
    fontSize: "0.875rem",
  },
}));

/**
 * This component will display information about the current reporting year
 * status of the different sections of the annual report
 *
 * @param {object} props - the properties of the component.
 * @param {string} props.variant - helps the component decide how to display itself on screen.
 * @param {boolean} props.open - if variant == "drawer", this prop tells the component how much of itself to display.
 * @param {string} props.navLink - the link that will be used when the user clicks the button
 * @param {string} props.menuItemContent - denotes the section to display a navigation item for
 * @param {string} props.statusText - the text to display as status for this section of the annual report
 * @returns - a display containing an icon, a nav link and status of a single section of the annual report.
 */
const AnnualReportNavBarItem = (props) => {
  // capture our props into variables
  const { variant, open, navLink, menuItemContent, statusText } = props;

  // this helper hook check if this item is the one currently selected
  const { handleSamePathSubnavigation } = useNavigationHelper();

  // check that navlink matches location, without checking the year
  const location = useLocation();
  const navLinkPrefixLength = "/reporting/0000/".length;
  const matchesNavLink = location.pathname
    .substring(navLinkPrefixLength)
    .startsWith(navLink.substring(navLinkPrefixLength));

  // here we set up the look and feel of the control
  const classes = useStyles({ selected: matchesNavLink });
  const menuItemProperties = getItemSettings(menuItemContent);

  // this is the menu item in "open" drawer mode or widget mode
  const openView = (
    <>
      <Grid
        item
        data-testid={`itemRow-${menuItemContent}`}
        classes={{
          root: classes.item,
        }}
      >
        <NavLink
          data-testid={`textLinkButton-${menuItemContent}`}
          className={classes.menuLink}
          activeClassName={classes.menuActiveLink}
          onClick={(event) => handleSamePathSubnavigation(event, navLink)}
          to={navLink}
          isActive={() => matchesNavLink}
        >
          <Grid
            container
            data-testid={`itemRowBackground=${menuItemContent}`}
            direction="row"
            classes={{
              root: classes.paper,
            }}
            spacing={0}
          >
            <Grid
              item
              data-testid={`iconGridItem-${menuItemContent}`}
              classes={{ root: classes.iconGridItem }}
            >
              <Box
                data-testid={`iconBox-${menuItemContent}`}
                classes={{ root: classes.iconBox }}
              >
                {menuItemProperties.icon}
              </Box>
            </Grid>
            <Grid
              item
              data-testid={`textContainer-${menuItemContent}`}
              classes={{
                root: classes.textContainer,
              }}
            >
              <Grid
                container
                direction={variant === "drawer" ? "column" : "row"}
              >
                <Grid item>
                  <Typography
                    className={
                      matchesNavLink
                        ? classes.menuActiveLinkText
                        : classes.menuLinkText
                    }
                  >
                    {menuItemProperties.itemText}
                  </Typography>
                </Grid>
                <Grid
                  item
                  classes={
                    variant === "widget"
                      ? { root: classes.itemWidgetDisplay }
                      : null
                  }
                >
                  <Typography
                    variant="caption"
                    data-testid={`statusText-${menuItemContent}`}
                    className={classes.statusText}
                  >
                    {statusText}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </NavLink>
      </Grid>
    </>
  );

  // this is the menu item in "closed" mode
  const closedView = (
    <>
      <Grid
        item
        data-testid={`itemRow-${menuItemContent}`}
        classes={{
          root: classes.item,
        }}
      >
        <Grid
          item
          data-testid={`itemIconGrid-${menuItemContent}`}
          classes={{ root: classes.iconGridItem }}
        >
          <Box
            data-testid={`iconBox-${menuItemContent}`}
            classes={{ root: classes.iconBox }}
          >
            {menuItemProperties.icon}
          </Box>
        </Grid>
      </Grid>
    </>
  );

  // decide which view to return to the user
  const view = open ? openView : closedView;

  return <>{view}</>;
};

AnnualReportNavBarItem.propTypes = {
  variant: PropTypes.oneOf(["drawer", "widget"]).isRequired,
  open: PropTypes.bool.isRequired,
  navLink: PropTypes.string.isRequired,
  menuItemContent: PropTypes.oneOf(Object.values(projectNavOptions)).isRequired,
  statusText: PropTypes.string.isRequired,
};

export default AnnualReportNavBarItem;
