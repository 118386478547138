import { GoogleMapsApi } from "api/googleMapsApi";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  lat: number,
 *  lng: number }} LatLng
 */

/**
 * @typedef {{
 *  results: Array.<{
 *    address_components: Array.<{
 *      long_name: string,
 *      short_name: string,
 *      types: string[]
 *    }>,
 *    formatted_address: string,
 *    geometry: {
 *      bounds: {
 *        northeast: LatLng,
 *        southwest: LatLng
 *      },
 *      location: LatLng,
 *      location_type: string,
 *      viewport: {
 *        northeast: LatLng,
 *        southwest: LatLng
 *      }
 *    },
 *    place_id: string,
 *    types: string[]
 *  }>,
 *  status: string }} ReverseGeocodeResponse
 */

const googleMapsApi = new GoogleMapsApi();
export const googleMapsService = {
  /**
   * @param {string} latlng
   * @returns {Promise<AxiosResponse<ReverseGeocodeResponse>>}
   */
  findByLatLng: (latlng) => googleMapsApi.get({ latlng }),
  /**
   * @param {string} address
   * @returns {Promise<AxiosResponse<ReverseGeocodeResponse>>}
   */
  findByAddress: (address) => googleMapsApi.get({ address }),
};
