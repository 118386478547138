/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography, Link, Box, Grid } from "@material-ui/core";
import LoginCard from "../components/LoginCard";
import AnnouncementsCard from "../components/AnnouncementsCard";
import { Redirect } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";
import { mobileBreakpoint } from "constants/viewport";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme) => ({
  root: {
    // 0px 75px 16px 75px
    padding: "0rem 4.688rem 1rem 4.688rem",
    flexGrow: 1,
    /* ### Don't change this property ###
     * A height is needed for the page scroll to work properly.
     * Setting the height of the root box to 0 doesn't interfere
     * with it's content size/padding and makes the scroll work properly
     * (not sure why though).
     */
    maxHeight: 0,
    [`@media (max-width:${mobileBreakpoint.width - 1}px)`]: {
      padding: "0rem",
    },
  },
  loginMargin: {
    // 56px
    marginTop: "3.5rem",
    // 16px
    marginBottom: "1rem",
  },
  loginText: {
    letterSpacing: "0.15px",
    lineHeight: "24px",
  },
}));

/**
 * The login page, contains announcements and login buttons.
 *
 * @returns {React.Component} The login page component.
 */
const Login = () => {
  const classes = useStyles();
  const [loginIsDisabled, setLoginIsDisabled] = useState(true);
  const queryClient = useQueryClient();

  useEffect(async () => {
    queryClient.removeQueries();
    // removeQueries does not alert when it's done, so wait 0.2 seconds to finish
    setTimeout(() => setLoginIsDisabled(false), "200");
  }, []);

  // if the user has persistent data in storage, redirect to their landing page
  const { user } = useUserContext();
  if (user.isAuthenticated) {
    return <Redirect to="/landing" />;
  }

  return (
    <>
      <Box className={classes.root}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h2">
              Welcome to the CCBF reporting site
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={12} lg={7} xl={7}>
              <AnnouncementsCard />
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={0}
              >
                <Grid item>
                  <LoginCard disabled={loginIsDisabled} />
                </Grid>
                <Grid item container direction="column" spacing={0}>
                  <Grid item>
                    <Typography
                      className={clsx(classes.loginMargin, classes.loginText)}
                      align="center"
                      variant="body2"
                    >
                      The Canada Community-Building Fund provides <br />
                      permanent and stable funding for local infrastructure
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.loginText}
                      align="center"
                      variant="body2"
                      color="primary"
                    >
                      Find out more at{" "}
                      <Link
                        component="a"
                        variant="body2"
                        href={`${process.env.REACT_APP_BC_DOT_CA_LINK}`}
                        target="_blank"
                      >
                        BuildingCommunities.ca
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
