import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import colors from "../constants/colors";
import AmoTooltip from "./AmoTooltip";

const useStyles = makeStyles((theme) => ({
  actionGrid: {
    height: "5rem",
    borderTop: `1px solid ${colors.grey.light}`,
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  lateralBorders: {
    borderLeft: `1px solid ${colors.grey.light}`,
    borderRight: `1px solid ${colors.grey.light}`,
  },
  button: {
    width: "15rem",
    height: "3.125rem",
  },
  buttonMargin: {
    marginRight: ({ spacing }) => spacing,
  },
}));

/**
 * A form actions component
 *
 * @param {object} props - object containing props for this component
 * @param {object[]} props.actions - array of object containing props for the actions buttons configuration [required]
 * @param {string} props.actions.testId - sets the data-testId property of the button component
 * @param {string} props.actions.label - sets the text of the button component
 * @param {Node|Node[]|string} props.actions.children - sets the children of the button component (takes precedence over label property)
 * @param {string} props.actions.color - sets the color property of the button component, "primary" as default (valid values: "primary", "secondary")
 * @param {string} props.actions.variant - sets the variant of the button component, "contained" as default (valid values: "outlined", "contained")
 * @param {boolean} props.actions.disabled - controls whether the button component should be disabled
 * @param {Function} props.actions.onClick - function called when user clicks on the button (params: { event })
 * @param {string} props.actions.className - sets the className of the button component
 * @param {string} props.actions.tooltipText - tooltip text
 * @param {string} props.className - sets this Grid container className
 * @param {boolean} props.hideLateralBorders - controls whether the grid container lateral borders should be hidden
 * @param {string|number} props.spacing - text or number to configure the spacing margin of the actions
 * @returns - The form actions component
 */
const AmoFormActions = (props) => {
  const { actions, className, hideLateralBorders, spacing } = props;

  const classes = useStyles({ spacing });

  const actionButton = (action, index) => (
    <Button
      data-testid={action.testId}
      className={clsx(
        classes.button,
        index < actions.length - 1 ? classes.buttonMargin : null,
        action.className
      )}
      color={action.color || "primary"}
      variant={action.variant || "contained"}
      size="medium"
      disableElevation
      disabled={action.disabled || false}
      onClick={action.onClick}
    >
      <Typography align="center" variant="body2">
        {action.children ?? action.label}
      </Typography>
    </Button>
  );

  return (
    <>
      <Grid
        container
        spacing={0}
        className={clsx(
          classes.actionGrid,
          hideLateralBorders ? null : classes.lateralBorders,
          className
        )}
        justifyContent="center"
        alignItems="center"
      >
        {actions.map((action, index) => (
          <Grid item key={action.testId}>
            {action.tooltipText ? (
              <AmoTooltip
                tooltipText={action.tooltipText}
                customComponent={actionButton(action, index)}
              />
            ) : (
              actionButton(action, index)
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

// set the prop-types for this component
AmoFormActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      testId: PropTypes.string.isRequired,
      label: PropTypes.string,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
      ]),
      color: PropTypes.oneOf(["primary", "secondary"]),
      variant: PropTypes.oneOf(["outlined", "contained"]),
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      className: PropTypes.string,
      tooltipText: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
  hideLateralBorders: PropTypes.bool,
  spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AmoFormActions.defaultProps = {
  className: undefined,
  hideLateralBorders: false,
  spacing: "2.25rem",
};

export default AmoFormActions;
