import React from "react";
import PropTypes from "prop-types";
import colors from "constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { projectNavOptions } from "constants/projectNavOptions";
import { NavLink } from "react-router-dom";
import { useNavigationHelper } from "hooks/navigationHook";

// this method sets the styles for the component's controls
const useStyles = makeStyles((theme) => ({
  paper: {
    border: true,
    height: "100%",
    backgroundColor: ({ selected }) => (selected ? "#F1F1F1" : "#FFFFFF"),
    justifyContent: "space-around",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  item: {
    width: "100%",
    height: "3.5rem",
    backgroundColor: ({ selected }) => (selected ? "#F1F1F1" : "#FFFFFF"),
  },
  iconGridItem: {
    height: "100%",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  iconBox: {
    color: ({ selected }) => (selected ? colors.black : colors.green.main),
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "100%",
  },
  itemWidgetDisplay: {
    marginLeft: "auto",
    padding: "0 1rem 0 0",
  },
  textContainer: {
    width: "85%",
    display: "flex",
    alignItems: "center",
  },
  menuLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  menuActiveLink: {
    color: colors.black,
    "&:hover": {
      cursor: "default",
      textDecoration: "none",
      backgroundColor: "#F8F8F8",
    },
  },
}));

/**
 * This component will display information about the current reporting year
 * status of the different sections of the annual report
 *
 * @param {object} props - the properties of the component.
 * @param {string} props.navLink - the link that will be used when the user clicks the button
 * @param {string} props.menuItemContent - denotes the section to display a navigation item for
 * @returns - a display containing an icon, a nav link and status of a single section of the annual report.
 */
const HelpNavBarItem = (props) => {
  // capture our props into variables
  const { navLink, linkText, menuItemContent } = props;

  // this helper hook check if this item is the one currently selected
  const {
    handleSamePathNavigation,
    currentPathStartsWith,
  } = useNavigationHelper();

  // here we set up the look and feel of the control
  const classes = useStyles({ selected: currentPathStartsWith(navLink) });

  // this is the menu item
  const MenuItem = () => (
    <>
      <Grid
        item
        data-testid={`itemRow-${menuItemContent}`}
        classes={{
          root: classes.item,
        }}
      >
        <Grid
          container
          data-testid={`itemRowBackground=${menuItemContent}`}
          direction="row"
          classes={{
            root: classes.paper,
          }}
          spacing={0}
        >
          <Grid
            item
            data-testid={`textContainer-${menuItemContent}`}
            classes={{
              root: classes.textContainer,
            }}
          >
            <Grid container>
              <Grid item>
                <NavLink
                  data-testid={`textLinkButton-${menuItemContent}`}
                  className={classes.menuLink}
                  activeClassName={classes.menuActiveLink}
                  onClick={(event) => handleSamePathNavigation(event, navLink)}
                  to={navLink}
                  isActive={() => currentPathStartsWith(navLink)}
                >
                  {linkText}
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return <MenuItem />;
};

HelpNavBarItem.propTypes = {
  navLink: PropTypes.string.isRequired,
  menuItemContent: PropTypes.oneOf(Object.values(projectNavOptions)).isRequired,
  linkText: PropTypes.string.isRequired,
};

export default HelpNavBarItem;
