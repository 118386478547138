import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid, Typography } from "@material-ui/core";
import BankingInformationForm from "../../../components/BankingInformationForm";
import AmoFormActions from "components/AmoFormActions";
import AmoPageHeader from "components/AmoPageHeader";
import { useForm, useWatch } from "react-hook-form";
import AmoModal from "components/AmoModal";
import AmoTextField from "components/inputs/AmoTextField";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Skeleton } from "@material-ui/lab";
import { useFormManagement } from "hooks/formManagementHook";
import {
  formIds,
  bankingInformationCodes,
} from "constants/formContentManagement";
import parse from "html-react-parser";
import { useBankingInformation } from "./BankingInformationPageHooks";
import { useUserContext } from "contexts/UserContext";
import { bankingInformationService } from "api/services/bankingInformationService";
import { useIsMounted } from "hooks/useIsMounted";
import { useWrapApi } from "hooks/wrapApiHook";
import { formatISODate } from "utils/date";

const useStyles = makeStyles((theme) => ({
  inputWidth: {
    width: "100%",
  },
  formButton: {
    width: "25rem",
    margin: "0 1rem 1rem 0",
  },
  instructions: {
    margin: "1rem 1rem 1rem 0rem",
    whiteSpace: "pre-line",
  },
  divider: {
    background: "#F1F1F1",
  },
}));

const defaultValues = {
  id: null,
  municipalityId: null,
  municipalityName: "",
  treasurerName: "",
  isVerified: false,
  isEditRequested: false,
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  institutionName: "",
  institutionNumber: "",
  transitNumber: "",
  accountNumber: "",
};

const BankingInformationPage = (props) => {
  const classes = useStyles();

  const mounted = useIsMounted();

  const [isEditMode, setIsEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [
    bankingInformationForDisplay,
    setBankingInformationForDisplay,
  ] = useState({});
  const { formFields } = useFormManagement(formIds.bankingInformation);
  const { user } = useUserContext();

  const { data: bankingInformation, refetch } = useBankingInformation(
    user?.municipalityId
  );

  const {
    call: postBankingInformationCall,
    isLoading: isPostLoading,
  } = useWrapApi(
    bankingInformationService.postBankingInformation,
    "Banking Information saved and verified successfully."
  );

  const {
    call: putVerifyBankingInformationCall,
    isLoading: isPutLoading,
  } = useWrapApi(
    bankingInformationService.putVerifyBankingInformation,
    "Banking Information saved and verified successfully."
  );

  const {
    call: postRequestToEditCall,
    isLoading: isPostRequestLoading,
  } = useWrapApi(bankingInformationService.postRequestToEdit, "Request sent.");

  const bankingInformationSchema = yup
    .object({
      contactName: yup
        .string()
        .trim()
        .required("Contact Name is a required field"),
      contactEmail: yup
        .string()
        .email("Contact Email must be a valid email")
        .required("Contact Email is a required field"),
      contactPhone: yup
        .string()
        .trim()
        .length(12, "Contact Phone must be a 10 digit number")
        .required("Contact Phone is a required field"),
      institutionName: yup
        .string()
        .trim()
        .required("Institution name is a required field"),
      institutionNumber: yup
        .string()
        .length(3, "Institution number must be a 3 digit number")
        .required("Financial number is a required field"),
      transitNumber: yup
        .string()
        .length(5, "Transit number must be a 5 digit number")
        .required("Transit number is a required field"),
      accountNumber: yup
        .string()
        .trim()
        .required("Account number is a required field"),
    })
    .required();
  const { control, handleSubmit, reset, formState, getValues } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(bankingInformationSchema),
  });

  const { isValid } = formState;

  const [phoneWatch, phoneExtensionWatch] = useWatch({
    control,
    name: ["contactPhone", "phoneExtension"],
  });

  const onSaveAndVerify = async (formValues) => {
    setIsEditMode(false);
    const payload = {
      ...formValues,
      municipalityId: user.municipalityId,
    };
    const postResponse = await postBankingInformationCall(
      user.municipalityId,
      payload
    );

    if (!mounted.current) {
      return;
    }

    if (!postResponse.error) {
      const verifyResponse = await putVerifyBankingInformationCall(
        user.municipalityId
      );

      if (!mounted.current) {
        return;
      }

      if (!verifyResponse.error) {
        refetch();
      }
    }
  };

  const onCancel = () => {
    setOpenModal(false);
  };

  const onCancelEdit = () => {
    reset(bankingInformation);
    setIsEditMode(false);
  };

  const onRequestToEdit = () => {
    setOpenModal(true);
  };

  const onVerifyBankingInformation = async () => {
    const verifyResponse = await putVerifyBankingInformationCall(
      user.municipalityId
    );

    if (!mounted.current) {
      return;
    }

    if (!verifyResponse.error) {
      refetch();
    }
  };

  const onSendRequest = async () => {
    setOpenModal(false);
    const notes = getValues("resquestToEditNote") ?? "";
    const requestToEditResponse = await postRequestToEditCall(
      user?.municipalityId,
      {
        bodyText: notes,
      }
    );

    if (!mounted.current) {
      return;
    }

    if (!requestToEditResponse.error) {
      refetch();
    }
  };

  const onEditBankingInformation = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    if (bankingInformation) {
      reset(bankingInformation);
      setBankingInformationForDisplay({
        ...bankingInformation,
        transitNumber: bankingInformation.isVerified
          ? "*****"
          : bankingInformation.transitNumber,
        accountNumber: bankingInformation.isVerified
          ? [...bankingInformation.accountNumber].map((digit, index) => {
              // TODO: This process should be handled by the backend
              if (index < bankingInformation.accountNumber.length - 3) {
                return "*";
              }
              return digit;
            })
          : bankingInformation.accountNumber,
      });
    }
  }, [bankingInformation]);

  const formActions = [
    {
      testId: "verifyBankingInformationButton",
      label: "Verify Banking Information",
      className: classes.formButton,
      onClick: onVerifyBankingInformation,
      disabled: !isValid || isPostLoading || isPutLoading,
    },
    {
      testId: "editBankingInformationButton",
      label: "Edit Banking Information",
      variant: "outlined",
      className: classes.formButton,
      onClick: onEditBankingInformation,
    },
  ];

  const requestToEditAction = [
    {
      testId: "requestToEditButton",
      label: "Request to edit",
      variant: "outlined",
      className: classes.formButton,
      onClick: onRequestToEdit,
      disabled:
        (bankingInformation?.isEditRequested ?? true) || isPostRequestLoading,
      tooltipText: bankingInformation?.isEditRequested
        ? "A request to edit is pending review"
        : null,
    },
  ];

  const headerActions = [
    {
      testId: "saveAndVerifyBankingInformation",
      disabled: !isValid || isPostLoading || isPutLoading,
      label: "Save and Verify",
      color: "primary",
      onClick: handleSubmit((formValues) => onSaveAndVerify(formValues)),
    },
    {
      testId: "cancelEditBankingInformation",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: onCancelEdit,
    },
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12} container direction="column" spacing={2}>
        <Grid item>
          <AmoPageHeader
            title="Banking Information"
            hideDivider
            actions={isEditMode ? headerActions : null}
          />
        </Grid>
        {Object.keys(formFields).length !== 0 ? (
          <>
            <Grid item>
              {!bankingInformation?.isLocked &&
              !bankingInformation?.isVerified ? (
                <Typography className={classes.instructions}>
                  {isEditMode
                    ? parse(
                        formFields[bankingInformationCodes.descriptionEditForm]
                          .text
                      )
                    : parse(
                        formFields[
                          bankingInformationCodes.descriptionVerificationForm
                        ].text
                      )}
                </Typography>
              ) : (
                <Typography className={classes.instructions}>
                  {`Banking information was last verified on ${formatISODate(
                    bankingInformation?.updatedDate
                  )}.`}
                  {parse(
                    formFields[bankingInformationCodes.descriptionLockedForm]
                      ?.text
                  )}
                </Typography>
              )}
            </Grid>

            <Divider className={classes.divider} />

            {bankingInformationForDisplay ? (
              <BankingInformationForm
                control={control}
                getValues={getValues}
                isEditMode={isEditMode}
                bankingInformation={bankingInformationForDisplay}
                formFields={formFields}
                // Added these 2 props so that the component is immediately rerenderered after changing the value
                phoneWatch={phoneWatch}
                phoneExtensionWatch={phoneExtensionWatch}
              />
            ) : (
              <Skeleton width="100%" height="40rem" />
            )}

            {!isEditMode && (
              <Grid container item direction="row" xs alignItems="flex-end">
                <Grid item xs>
                  <AmoFormActions
                    actions={
                      bankingInformation?.isLocked ||
                      bankingInformation?.isVerified
                        ? requestToEditAction
                        : formActions
                    }
                    hideLateralBorders
                  />
                </Grid>
              </Grid>
            )}
            <AmoModal
              data-testid="amoModal"
              open={openModal}
              variant="default"
              title="Request to Edit Banking Info"
              width="32rem"
              showButton
              closePopover={() => setOpenModal(false)}
              buttons={[
                {
                  key: "sendRequest",
                  label: "Send Request",
                  color: "primary",
                  variant: "contained",
                  disabled: isPostRequestLoading,
                  onClick: onSendRequest,
                },
                {
                  key: "close",
                  label: "Close",
                  color: "primary",
                  variant: "outlined",
                  onClick: onCancel,
                },
              ]}
            >
              <AmoTextField
                control={control}
                label="Add note"
                id="request-to-edit-note"
                name="resquestToEditNote"
                testId="requestToEditNote"
                className={classes.inputWidth}
                variant="outlined"
                multiline
                rows={8}
                rowsMax={15}
              />
            </AmoModal>
          </>
        ) : (
          <Skeleton width="100%" height="40rem" />
        )}
      </Grid>
    </Grid>
  );
};

export default BankingInformationPage;
