import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

const FilterContext = createContext();

const useFilterContext = () => useContext(FilterContext);

const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({});

  const setFilter = (filterName, value, isHidden = false) => {
    setFilters({
      ...filters,
      [filterName]: { value, isHidden },
    });
  };
  const setFilterValue = (filterName, value) => {
    setFilters({
      ...filters,
      [filterName]: { ...filters[filterName], value },
    });
  };
  const setFilterIsHidden = (filterName, isHidden) => {
    setFilters({
      ...filters,
      [filterName]: { ...filters[filterName], isHidden },
    });
  };

  return (
    <FilterContext.Provider
      value={{ filters, setFilter, setFilterValue, setFilterIsHidden }}
    >
      {children}
    </FilterContext.Provider>
  );
};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useFilterContext, FilterProvider };
