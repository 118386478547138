import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  title: string,
 *  body: string,
 *  publish: boolean,
 *  creationDate: string,
 *  updateDate: string,
 *  updatedBy: number }} Announcement
 */

const api = new Api("api/announcements");
export const announcementsService = {
  /**
   * @returns {Promise<AxiosResponse<Announcement[]>>}
   */
  getPublished: () => api.axios.get("published", { withCredentials: false }),
  /**
   * @returns {Promise<AxiosResponse<Announcement[]>>}
   */
  getAll: () => api.get(),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<Announcement>>}
   */
  getById: (id) => api.getById(id),
  create: (payload) => api.post(payload),
  update: (id, payload) => api.put(id, payload),
  remove: (id) => api.del(id),
};
