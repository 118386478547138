import financialRowElement from "./financialRowElement";

const titleRow = (
  displayName,
  classes,
  financialDataFieldName = undefined,
  financialData = undefined,
  underlined = true
) =>
  financialRowElement(
    displayName,
    financialDataFieldName,
    financialData,
    classes,
    false,
    true,
    underlined,
    false,
    false,
    true
  );

export default titleRow;
