import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { projectReviewStatusIds } from "constants/projectReviewStatusIds";
import ColoredSquare from "./ColoredSquare";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "0.875rem",
  },
}));

/**
 * Displays a colored square (Color depends on the status) alongside the status name.
 *
 * @param {object} props
 * @param {string} props.id The id of the review status
 * @param {string} props.name The name of the review status
 * @returns {React.Component} A review status component.
 */
const ReviewStatus = ({ id, name }) => {
  const classes = useStyles();
  const colorMappings = {
    [projectReviewStatusIds.incomplete]: "grey",
    [projectReviewStatusIds.pending]: "blue",
    [projectReviewStatusIds.updated]: "blue",
    [projectReviewStatusIds.approved]: "green",
    [projectReviewStatusIds.contacted]: "yellow",
    [projectReviewStatusIds.toBeContacted]: "red",
    [projectReviewStatusIds.toBeRevised]: "red",
    [projectReviewStatusIds.toBeDiscussed]: "red",
  };
  return (
    <Grid container direction="row">
      <Grid item>
        <ColoredSquare color={colorMappings[id] ?? "black"} />
      </Grid>
      <Grid item>
        <Typography className={classes.text}>{name}</Typography>
      </Grid>
    </Grid>
  );
};

ReviewStatus.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default ReviewStatus;
