import { DateTime } from "luxon";

const removeTime = (isoDate) => (isoDate ? isoDate.split("T")[0] : null);

const dateToPayload = (dateForCheck) => {
  if (dateForCheck?.constructor === Date) {
    return DateTime.fromJSDate(dateForCheck).toISODate();
  }
  return dateForCheck?.toISODate() ?? null;
};

const formatISODate = (date) =>
  date != null ? DateTime.fromISO(date)?.toFormat("yyyy/MM/dd") : null;

export { removeTime, dateToPayload, formatISODate };
