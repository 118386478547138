import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useUserContext } from "./UserContext";
import { useMunicipalContext } from "./MunicipalContext";
import { roles } from "../constants/user";

/**
 * The base year context.
 */
const YearContext = createContext(null);

/**
 * React's context hook.
 *
 * @returns {any} The current value for the user context.
 */
const useYearContext = () => useContext(YearContext);

/**
 * A year context wrapper, provides context to children.
 *
 * @param {Node} {children}
 * @returns {Element} Wrapped children, that will now have access to the year context.
 */
const YearProvider = ({ children }) => {
  const { user } = useUserContext();
  const {
    userMunicipality,
    refetch: refetchMunicipalityData,
  } = useMunicipalContext();

  const defaultYear =
    DateTime.now() <= DateTime.now().set({ month: 9, day: 30 })
      ? DateTime.now().minus({ year: 1 }).year
      : DateTime.now().year;

  const [selectedYear, setSelectedYear] = useState(defaultYear);

  const isMunicipalUser = user?.roles?.includes(roles.municipal) ?? false;
  const contextYear = isMunicipalUser
    ? userMunicipality?.reportingYear ?? defaultYear
    : selectedYear;

  /**
   * this method checks if the passed date's year is equal to the current reporting year.
   *
   * @param {DateTime} dateIn - the date to check against
   * @param {number} yearToCompare - the year to compare with the date
   *
   * @returns {boolean} The page component
   */
  const isInReportingYear = (dateIn, yearToCompare = null) => {
    if (!dateIn || !DateTime.fromISO(dateIn).isValid) {
      return false;
    }
    return DateTime.fromISO(dateIn).year === (yearToCompare ?? contextYear);
  };

  /**
   * this method checks if the passed date's year is equal to the previous reporting year.
   *
   * @param {DateTime} dateIn - the date to check against
   * @param {number} yearToCompare - the year to compare with the date
   *
   * @returns {boolean} The page component
   */
  const isInPreviousReportingYear = (dateIn, yearToCompare = null) => {
    if (!dateIn || !DateTime.fromISO(dateIn).isValid) {
      return true;
    }
    return DateTime.fromISO(dateIn).year === (yearToCompare ?? contextYear) - 1;
  };

  /**
   * this method checks if the passed date's year is before the current reporting year
   *
   * @param {DateTime} dateIn - the date to check against
   * @param {number} yearToCompare - the year to compare with the date
   *
   * @returns {boolean} The page component
   */
  const isReportingPastDate = (dateIn, yearToCompare = null) => {
    if (!dateIn || !DateTime.fromISO(dateIn).isValid) {
      return false;
    }
    return (yearToCompare ?? contextYear) > DateTime.fromISO(dateIn).year;
  };

  /**
   * this method checks if the passed date's year is after the current reporting year
   *
   * @param {DateTime} dateIn - the date to check against
   * @param {number} yearToCompare - the year to compare with the date
   *
   * @returns {boolean} The page component
   */
  const isInFutureReportingYear = (dateIn, yearToCompare = null) => {
    if (!dateIn || !DateTime.fromISO(dateIn).isValid) {
      return false;
    }
    return (yearToCompare ?? contextYear) < DateTime.fromISO(dateIn).year;
  };

  return (
    <YearContext.Provider
      value={{
        contextYear,
        setSelectedYear,
        isInReportingYear,
        isReportingPastDate,
        isInPreviousReportingYear,
        isInFutureReportingYear,
        refetch: refetchMunicipalityData,
      }}
    >
      {children}
    </YearContext.Provider>
  );
};

YearProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useYearContext, YearProvider, YearContext };
