/**
 * Base URL of the site
 *
 */
export const publishedURL =
  process.env.NODE_ENV === "development"
    ? window.location.origin
    : process.env.REACT_APP_AUTHORITY;
/**
 * Function to copy a page URL to clipboard
 *
 * @param {string} path Path after base url
 */
export const copyPageUrl = (path) => {
  navigator.clipboard.writeText(`${publishedURL}${path}`); // copies url of saved page to clipboard
};
