import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { TableCell, Typography } from "@material-ui/core";
import colors from "constants/colors";
import { formatToCurrency } from "utils/number";
import { booleanToTextOrUndefined } from "utils/string";

import { displayTypes, displaySubTypes } from "constants/amoTableConstants";
import {
  AmoTableColumnProps,
  AmoTableColumnDefaultProps,
} from "constants/amoTablePropTypes";

const useStyles = makeStyles((theme) => ({
  tableRowCell: {
    borderBottom: `1px solid ${colors.grey.light}`,
    maxWidth: 0,
    wordWrap: `break-word`,
  },
  rowFont: {
    fontSize: "0.875rem",
  },
}));

/**
 * A table cell component for displaying the value of a row cell
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.uniqueKey - unique key to identify this cell [required]
 * @param {object} props.item - the item object related to this cell row [required]
 * @param {string} props.tableId - id of the table component
 * @param {AmoTableColumnProps} props.column - the object with the table column configuration
 *
 * @returns {React.Component} - The table cell component
 */
const AmoTableCell = ({ uniqueKey, item, tableId, column }) => {
  const {
    fieldKey,
    displayType,
    displaySubType,
    className,
    cellClassName,
    variant,
    color,
    noCellWrap,
    verticalAlign,
    horizontalAlign,
    isRounded,
    isNullable,
    customRender,
    dropdownOptions,
    isCustom,
  } = column;

  const classes = useStyles();

  const renderTextCell = (value) => {
    if (isNullable && value === null) return null;
    if (displayType === displayTypes.currency) {
      return formatToCurrency(value, isRounded);
    }
    if (displayType === displayTypes.boolean) {
      return booleanToTextOrUndefined(value) ?? "";
    }
    if (displayType === displayTypes.dropdown) {
      return displaySubType === displaySubTypes.differentFilterAndItems
        ? value
        : dropdownOptions[value] ?? "";
    }

    return value;
  };

  const isTextValue =
    !displayType ||
    displayType === displayTypes.text ||
    displayType === displayTypes.date ||
    displayType === displayTypes.boolean ||
    displayType === displayTypes.number ||
    displayType === displayTypes.currency ||
    displayType === displayTypes.dropdown;

  const isRightAligned =
    displayType === displayTypes.number ||
    displayType === displayTypes.currency;

  const isCustomRender = isCustom && customRender;

  return (
    <TableCell
      id={`${tableId ?? "amo-table"}-row-cell-${uniqueKey}-${fieldKey}`}
      className={clsx(classes.tableRowCell, cellClassName)}
      key={`amoTableRowCell-${uniqueKey}-${fieldKey}`}
      style={{
        textAlign: isRightAligned ? "right" : horizontalAlign ?? "initial",
        verticalAlign: verticalAlign ?? "middle",
      }}
    >
      <Typography
        className={clsx(classes.rowFont, className)}
        component="div"
        variant={typeof variant === "function" ? variant(item) : variant}
        color={typeof color === "function" ? color(item) : color}
        noWrap={noCellWrap}
      >
        {!isCustomRender && isTextValue && renderTextCell(item?.[fieldKey])}
        {!isCustomRender &&
          displayType === displayTypes.component &&
          item?.[fieldKey]}
        {isCustomRender && customRender(item)}
      </Typography>
    </TableCell>
  );
};

// set the prop-types for this component
AmoTableCell.propTypes = {
  uniqueKey: PropTypes.string.isRequired,
  item: PropTypes.shape().isRequired,
  tableId: PropTypes.string,
  column: PropTypes.shape(AmoTableColumnProps),
};

AmoTableCell.defaultProps = {
  tableId: undefined,
  column: AmoTableColumnDefaultProps,
};

export default AmoTableCell;
