import React from "react";
import { Button, capitalize, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import colors from "constants/colors";
import ReportingStatusLegendItem from "./ReportingStatusLegendItem";
import PropTypes from "prop-types";
import ReportingStatusBar from "./ReportingStatusBar";
import { routes } from "constants/routes";
import { useYearContext } from "contexts/YearContext";

const useStyles = makeStyles({
  button: {
    width: "20rem",
    height: "3.125rem",
  },
  title: {
    color: colors.green.dark,
  },
  reportingStatusContainer: {
    width: "80rem",
    border: "solid 1px #939393",
    borderRadius: "0.625rem",
    padding: "0.5rem",
  },
});

/**
 * AMO Reporting Status Component.
 *
 * @param {object} props
 * @param {object[]} props.statuses The reporting statuses
 * @returns {React.Component} AMO Reporting Status component.
 */
const ReportingStatus = (props) => {
  const { statuses } = props;
  const classes = useStyles();
  const { contextYear } = useYearContext();

  const reportingStatusColors = {
    unsubmitted: "#E0E0E0",
    submitted: "#5F8CFE",
    approved: "#23AE49",
  };

  const statusesToDisplay = Object.entries(statuses)
    .sort(([acc], [val]) => val.localeCompare(acc))
    .map(([status, amount]) => ({
      status,
      amount,
      color: reportingStatusColors[`${status}`.toLowerCase()],
    }));

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      wrap="nowrap"
      spacing={2}
      className={classes.reportingStatusContainer}
    >
      <Grid item>
        <Typography variant="h1" className={classes.title} data-testid="title">
          Reporting Status: {contextYear}
        </Typography>
      </Grid>
      <Grid item />
      <Grid item>
        <ReportingStatusBar statuses={statusesToDisplay} />
      </Grid>
      <Grid item container direction="row" justifyContent="center" spacing={4}>
        {statuses &&
          statusesToDisplay.map(({ status, amount, color }) => (
            <ReportingStatusLegendItem
              key={status}
              text={`${capitalize(status)} (${amount})`}
              color={color}
            />
          ))}
      </Grid>
      <Grid item>
        <Button
          data-testid="viewAnnualReportStatus"
          className={classes.button}
          color="primary"
          variant="outlined"
          size="medium"
          component={Link}
          to={routes.reviews.reportingStatus.list}
        >
          <Typography variant="body2">View Annual Report Status</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

ReportingStatus.propTypes = {
  statuses: PropTypes.shape({
    approved: PropTypes.number,
    submitted: PropTypes.number,
    unsubmitted: PropTypes.number,
  }).isRequired,
};

export default ReportingStatus;
