import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ProjectOutcome from "./ProjectOutcome";
import PropTypes from "prop-types";
import ProjectOutput from "./ProjectOutput";
import parse from "html-react-parser";

/**
 * Component to display project specific data
 *
 * @param {object} props
 * @returns {Function} The Project component
 */
const Project = (props) => {
  const { project } = props;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row">
        <Grid item xs={6}>
          <Typography variant="body2">Internal ID</Typography>
          <Typography>{project.internalId}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">AMO ID</Typography>
          <Typography>{project.id}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body2">Project title</Typography>
        <Typography>{project.title ?? "Untitled Project"}</Typography>
      </Grid>
      <Grid item>
        <Typography>
          {project.outcomes?.map((outcome) => (
            <ProjectOutcome key={outcome.id} outcome={outcome} />
          ))}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {project.outputs?.map((output) => (
            <ProjectOutput key={output.id} output={output} />
          ))}
        </Typography>
      </Grid>
      {project.otherResults && (
        <Grid item>
          <Typography variant="body2">Other Results</Typography>
          <Typography>
            {parse(project.otherResults.toString().replace(/\n/g, "<br>"))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

Project.propTypes = {
  project: PropTypes.shape().isRequired,
};

export default Project;
