import React, { useEffect } from "react";
import { makeStyles, Divider, Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import AmoModal from "components/AmoModal";
import AmoTextField from "components/inputs/AmoTextField";
import AmoSwitch from "components/inputs/AmoSwitch";

import colors from "constants/colors";
import { formatToCurrency } from "utils/number";

import { useBankLimitMonitoringModalHooks } from "./bankLimitMonitoringHooks";
import { useIsMounted } from "hooks/useIsMounted";
import { useWrapApi } from "hooks/wrapApiHook";
import { bankLimitMonitoringService } from "api/services/bankLimitMonitoringService";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "1.5rem",
  },
  dividerContainer: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  rowContainer: {
    marginBottom: "0.75rem",
  },
  rowLabel: {
    color: colors.green.dark,
  },
}));

/**
 * The Banking Limit Monitoring Modal.
 *
 * @param {object} props - object containing props for this component
 * @param {object} props.year - sets the selected year used to load the modal data [required]
 * @param {object} props.municipalityId - sets the municipality used to load the modal data [required]
 * @param {boolean} props.open - controls visibility of the AmoModal component
 * @param {Function} props.onClose - function called when the modal should be closed
 *
 * @returns {React.Component} Banking Limit Monitoring Modal component.
 */
const BankLimitMonitoringModal = (props) => {
  const { year, municipalityId, open, onClose } = props;
  const classes = useStyles();

  const mounted = useIsMounted();

  // define schema for form validation
  const BankLimitMonitoringValidationSchema = yup
    .object({
      notes: yup.string(),
      exemptionGranted: yup.boolean(),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    notes: "",
    exemptionGranted: false,
  };

  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(BankLimitMonitoringValidationSchema),
  });

  const { isValid, isSubmitting, isDirty } = formState;

  const { data, isFetching } = useBankLimitMonitoringModalHooks(
    year,
    municipalityId
  );

  const putData = useWrapApi(
    bankLimitMonitoringService.putMunicipalityData,
    "Successfully saved bank limit monitoring information"
  );

  // Reset the form with fetched bank limit monitoring details (as default values)
  useEffect(() => {
    reset(data);
  }, [data]);

  const saveData = async ({ exemptionGranted, notes }) => {
    const payload = { id: data?.id, municipalityId, exemptionGranted, notes };
    const response = await putData.call(year, municipalityId, payload);
    if (mounted.current && !response.error) {
      onClose(true);
    }
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  const modalButtons = [
    {
      key: "bankLimitModalSave",
      label: "Save",
      color: "primary",
      variant: "contained",
      disabled: !isDirty || isSubmitting || !isValid || isFetching,
      onClick: handleSubmit(saveData),
    },
    {
      key: "bankLimitModalClose",
      label: "Close",
      color: "primary",
      variant: "outlined",
      onClick: closeModal,
    },
  ];

  const renderModalRow = (
    label,
    value,
    isMoney = true,
    isComponent = false
  ) => {
    const transformedValue = isMoney ? formatToCurrency(value, true) : value;

    const rowValueComponent = isComponent ? (
      value
    ) : (
      <Typography variant="body1">{transformedValue}</Typography>
    );

    return (
      <Grid
        container
        direction="row"
        spacing={0}
        wrap="nowrap"
        alignItems="center"
        className={classes.rowContainer}
      >
        <Grid item xs={5}>
          <Typography variant="body2" className={classes.rowLabel}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs>
          {isFetching ? (
            <Skeleton width="8rem" height="1rem" variant="rect" />
          ) : (
            rowValueComponent
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <AmoModal
      open={open}
      title="Municipal Banking Limit Info"
      showButton
      width="55rem"
      buttonsLeftSpacer={5}
      buttons={modalButtons}
      closePopover={closeModal}
    >
      <Grid
        container
        direction="column"
        spacing={0}
        wrap="nowrap"
        className={classes.container}
      >
        <Grid item>
          <Typography variant="body2">Municipality</Typography>
        </Grid>
        <Grid item>
          {isFetching ? (
            <Skeleton width="20rem" height="1rem" variant="rect" />
          ) : (
            <Typography variant="body1">{data?.municipalityName}</Typography>
          )}
        </Grid>

        <Grid item className={classes.dividerContainer}>
          <Divider />
        </Grid>

        <Grid item>
          {renderModalRow(
            "Closing balance five years ago",
            data?.closingBalance5YearAgo ?? 0
          )}
        </Grid>
        <Grid item>
          {renderModalRow(
            "Investment since then",
            data?.investmentSinceThen ?? 0
          )}
        </Grid>
        <Grid item>
          {renderModalRow(
            "Outgoing transfers since then",
            data?.outgoingTransfersSinceThen ?? 0
          )}
        </Grid>
        <Grid item>
          {renderModalRow(
            "Additional Investment Required",
            data?.additionalInvestmentRequired ?? 0
          )}
        </Grid>
        <Grid item>{renderModalRow("Status", data?.status, false)}</Grid>
        <Grid item>
          {renderModalRow(
            "Exemption Granted",
            data?.status?.toLowerCase() === "in compliance" ? (
              <Typography variant="body1">N/A</Typography>
            ) : (
              <AmoSwitch
                control={control}
                id="bank-limit-monitoring-exemption-granted"
                testId="bankLimitMonitoringExemptionGranted"
                name="exemptionGranted"
                color="primary"
              />
            ),
            false,
            true
          )}
        </Grid>

        <Grid item className={classes.dividerContainer}>
          <Divider />
        </Grid>

        <Grid item>
          {isFetching ? (
            <Skeleton width="100%" height="6rem" variant="rect" />
          ) : (
            <AmoTextField
              control={control}
              id="bank-limit-monitoring-notes"
              testId="bankLimitMonitoringNotes"
              name="notes"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              placeholder="Enter notes..."
            />
          )}
        </Grid>
      </Grid>
    </AmoModal>
  );
};

// set the prop-types for this component
BankLimitMonitoringModal.propTypes = {
  year: PropTypes.number.isRequired,
  municipalityId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

// set prop-types defaults for this component
BankLimitMonitoringModal.defaultProps = {
  open: false,
};

export default BankLimitMonitoringModal;
