export const questionnaireConditionOperators = {
  is: {
    value: 1,
    text: "Is",
  },
  isNot: {
    value: 2,
    text: "Is Not",
  },
};
