import { googleMapsService } from "api/services/googleMapsService";

const locationIsInOntario = async (location, isAddress = false) => {
  const promise = isAddress
    ? googleMapsService.findByAddress(location)
    : googleMapsService.findByLatLng(location);

  const { data } = await promise;

  return !!data?.results?.some(
    (result) =>
      // Province is Ontario
      result?.address_components.some(
        (address) =>
          address.types.includes("administrative_area_level_1") &&
          address.long_name === "Ontario"
      ) &&
      // Country is Canada
      result?.address_components.some(
        (address) =>
          address.types.includes("country") && address.long_name === "Canada"
      )
  );
};

export { locationIsInOntario };
