import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useUserPrompt } from "contexts/UserPromptContext";
import { IdSrvProvider } from "contexts/IdSrvContext";
import { ApiProvider } from "contexts/ApiContext";
import { UserProvider } from "contexts/UserContext";
import { MunicipalProvider } from "contexts/MunicipalContext";
import { YearProvider } from "contexts/YearContext";
import { SnackbarProvider } from "contexts/SnackbarContext";
import { ViewportProvider } from "contexts/ViewportContext";
import { QueryParamProvider } from "use-query-params";

/**
 * A wrapper to place all the global providers
 *
 * @param {object} props object containing props for this component
 * @param {object} props.children elements inside layer
 * @returns {React.Component} The Provider layer
 */
const AmoProviderLayer = ({ children }) => {
  const { getUserConfirmation } = useUserPrompt();
  return (
    <Router getUserConfirmation={getUserConfirmation}>
      <IdSrvProvider>
        <ApiProvider>
          <UserProvider>
            <MunicipalProvider>
              <YearProvider>
                <SnackbarProvider>
                  <ViewportProvider>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      {children}
                    </QueryParamProvider>
                  </ViewportProvider>
                </SnackbarProvider>
              </YearProvider>
            </MunicipalProvider>
          </UserProvider>
        </ApiProvider>
      </IdSrvProvider>
    </Router>
  );
};

AmoProviderLayer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AmoProviderLayer;
