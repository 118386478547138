import { DateTime } from "luxon";
import { range } from "utils/number";

const initialReportingYear = 2005;

export const reporting = {
  currentReportingYear: DateTime.now().minus({ year: 1 }).year,
  firstYearOfCalculatedAveragesPeriod: 2014,
  // Earliest year for which Certificate of Insurance exists
  firstYearOfCOI: 2014,
  // Earliest year for which Questionnaires exist
  firstYearOfQuestionnaire: 2014,
  calendarYear: DateTime.now().year,
  availableYears: range(initialReportingYear, DateTime.now().year),
};
