import { useQuery } from "react-query";

import { bankingInformationReviewsQueryKeys } from "./bankingInformationReviewsQueryKeys";
import { bankingInformationService } from "api/services/bankingInformationService";
import { reviewService } from "api/services/reviewsService";
import { removeTime } from "utils/date";

/**
 * Store server's state of banking information reviews
 *
 * @returns The query object returned by the useQuery hook
 */
export const useBankingInformationReviewsHooks = () =>
  useQuery(
    bankingInformationReviewsQueryKeys.bankingInformationReviews,
    () => reviewService.getBankingInformationReviews(),
    {
      select: ({ data }) =>
        data?.map((item) => ({
          ...item,
          updatedDate: removeTime(item.updatedDate),
          isExported: item.isExportedAfterUpdate ? "Yes" : "No",
        })) ?? [],
    }
  );

/**
 * Store server's state of the last banking information verification request
 *
 * @returns The query object returned by the useQuery hook
 */
export const useVerificationRequestHooks = () =>
  useQuery(
    bankingInformationReviewsQueryKeys.verificationRequest,
    () => bankingInformationService.getRequestVerification(),
    {
      select: ({ data }) => data,
    }
  );

/**
 * Store server's state of banking information reviews
 *
 * @returns The query object returned by the useQuery hook
 */
export const useBankingInformationPlaceholdersHooks = () =>
  useQuery(
    bankingInformationReviewsQueryKeys.bankingInformationPlaceholders,
    () => reviewService.getBankingInformationPlaceholders(),
    {
      select: ({ data }) => data,
    }
  );
