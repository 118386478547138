import React from "react";
import clsx from "clsx";
import { Box, Icon } from "@material-ui/core";

/**
 * A numeric slider thumb component for the numeric slider
 *
 * @param {object} props - object containing props for this component
 *
 * @returns {React.Component} - The numeric slider thumb component
 */
const AmoTableFilterNumericSliderThumb = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box {...props}>
    <Icon className={clsx("material-icons-outlined", "icon")}>
      drag_indicator
    </Icon>
  </Box>
);

export default AmoTableFilterNumericSliderThumb;
