import { useQuery } from "react-query";
import { projectService } from "api/services/projectService";

// Query Keys
import { projectKeys } from "./projectQueryKeys";

/**
 * Store server's state of project communications form
 *
 * @param {string} projectId The id of the project being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectCommunicationsData = (projectId) => {
  const selectProjectCommunicationsData = (data) => {
    const projectCommunicationsData = data?.data;
    const reducedFlags =
      projectCommunicationsData?.flags?.reduce(
        (map, obj) => ({
          ...map,
          [obj.fieldCode]: {
            id: obj.id,
            ownerName: obj.updatedByName,
            ownerEmail: obj.updatedByEmail,
            comment: obj.message,
          },
        }),
        {}
      ) || {};
    return {
      ...(data?.data ?? {}),
      flags: reducedFlags,
    };
  };

  return useQuery(
    projectKeys.projectCommunications(projectId),
    () => projectService.getCommunicationsData(projectId),
    {
      select: selectProjectCommunicationsData,
    }
  );
};
