import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../../constants/colors";
import { Grid, Button, IconButton } from "@material-ui/core";
import { useForm, useFieldArray } from "react-hook-form";
import AmoTextField from "components/inputs/AmoTextField";
import { DeleteOutline } from "@material-ui/icons";
import AmoModal from "../../../components/AmoModal";
import AmoConfirmPopover from "components/AmoConfirmPopover";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { indicatorService } from "api/services/indicatorService";
import { getSortingComparator, stableSort } from "../../../utils/data";
import { errorMessages } from "constants/errorMessages";
import { useIsMounted } from "hooks/useIsMounted";

// sets the Material-UI styles for this component
const useStyles = makeStyles((theme) => ({
  button: {
    height: "3.125rem",
    borderWidth: "0.125rem",
  },
  iconButton: {
    padding: theme.spacing(2),
    color: ({ variant }) => (variant === "error" ? colors.white : null),
  },
  inactiveIcon: {
    color: colors.grey.medium,
  },
  textInput: {
    height: "3.5rem",
    width: "100%",
  },
  groupingBox: {
    maxHeight: "26rem",
    maxWidth: "99%",
    overflow: "auto",
    marginTop: theme.spacing(0.6),
    marginBottom: theme.spacing(0.6),
  },
  groupingRow: {
    height: "4.5rem",
    border: "1px solid grey",
    padding: theme.spacing(1),
    margin: theme.spacing(0.6),
  },
}));

/**
 * @param {object} props - object containing props for this component
 * @param {boolean} props.showModal - controls visibility of the modal
 * @returns - the manage groupings modal, styled for the AMO project
 */
const ManageGroupingsModal = (props) => {
  const { showModal, closeModalFunction, groupings, setGroupingData } = props;

  const defaultValues = {
    newGrouping: "",
  };
  const classes = useStyles(props);

  const mounted = useIsMounted();

  const {
    control,
    handleSubmit,
    getValues,
    resetField,
    watch,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });
  const {
    fields: groupingArray,
    append: appendGrouping,
    remove: removeGrouping,
  } = useFieldArray({
    control,
    name: "groupings",
  });

  const watchNewGrouping = watch("newGrouping");

  const [itemToDelete, setItemToDelete] = useState(null);
  const [nameExists, setNameExists] = useState(false);

  const newIndex = useRef(1);

  const { showSnackbar } = useSnackbar();

  // this effect will make a call to the API to get the groupings list
  useEffect(() => {
    const sortedGrouping = stableSort(
      groupings,
      getSortingComparator("asc", "name")
    );

    const mappedSortedGrouping = sortedGrouping?.map((item) => ({
      Id: item.id,
      name: item.name,
      indicatorAttached: item.indicatorsAttached,
      toBeDeleted: item.toBeDeleted,
      isNew: false,
    }));

    appendGrouping(mappedSortedGrouping);
  }, [groupings]);

  useEffect(() => {
    groupingSearch(groupingArray, watchNewGrouping);
  }, [watchNewGrouping]);

  // function that checks if a new grouping name exists in the current saved grouping names
  const groupingSearch = (groupArr, searchKey) => {
    let keyExists = false;
    for (const groups of groupArr) {
      const groupName = groups.name;

      if (groupName === searchKey.trim()) {
        keyExists = true;
        break;
      }
    }

    if (keyExists) {
      setNameExists(keyExists);
      setError("newGrouping", {
        type: "manual",
        message: "This grouping name already exists",
      });
    } else if (nameExists && !keyExists) {
      setNameExists(keyExists);
    }
  };

  const renderItems = () =>
    groupingArray?.map(
      (item, index) =>
        item.toBeDeleted !== true && (
          <Grid
            item
            container
            key={`grouping${item.isNew ? item.componentId : item.Id}`}
            spacing={1}
            alignItems="center"
            className={classes.groupingRow}
          >
            <Grid item xs={11}>
              <AmoTextField
                control={control}
                label=""
                id={`grouping${item.Id}`}
                name={`groupings.${index}.name`}
                variant="outlined"
                value={item.name}
                className={classes.textInput}
                focused={false}
              />
            </Grid>
            <Grid item xs={1}>
              {renderDeleteLink(item, index)}
            </Grid>
          </Grid>
        )
    );

  const renderDeleteLink = (item, index) => (
    <IconButton
      data-testid={`groupingRemoveButton${item.Id}`}
      onClick={() => openDeleteDialog(index)}
      className={classes.iconButton}
      disabled={item.indicatorAttached}
    >
      <DeleteOutline
        color="secondary"
        className={`${classes.actionIcon} ${
          item.indicatorAttached ? classes.inactiveIcon : ""
        }`}
      />
    </IconButton>
  );

  const openDeleteDialog = (index) => {
    setItemToDelete(index);
  };

  const onRemove = async (index) => {
    try {
      const grouping = groupingArray[index];

      if (!grouping.isNew) {
        await indicatorService.deleteGrouping(grouping?.Id);
        if (!mounted.current) {
          return;
        }
      }

      removeGrouping(index);
      setItemToDelete(null);
      showSnackbar("Successfully deleted");
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const addGrouping = async (groupingName) => {
    appendGrouping({
      id: 0,
      componentId: `new${newIndex.current}`,
      name: groupingName,
      indicatorAttached: false,
      isNew: true,
    });
    resetField("newGrouping");
    newIndex.current += 1;
  };

  const saveAndClose = async ({ groupings: indicatorGroupings }) => {
    const payload = { indicatorGroupings };
    await indicatorService.updateGroupings(payload);

    if (!mounted.current) {
      return;
    }

    closeModal();
  };

  const closeModal = async () => {
    resetField("newGrouping");
    const { data } = await indicatorService.getGroupings();

    if (!mounted.current) {
      return;
    }

    setGroupingData(data);
    closeModalFunction();
  };

  return (
    <AmoModal
      data-testid="amoModal"
      open={showModal}
      variant="default"
      title="Manage Groupings"
      width="40rem"
      showButton
      closePopover={closeModal}
      buttonsLeftSpacer={5}
      buttons={[
        {
          key: "saveAndClose",
          label: "Save and Close",
          color: "primary",
          variant: "contained",
          disabled: isSubmitting,
          onClick: handleSubmit((formValues) => saveAndClose(formValues)),
        },
        {
          key: "cancel",
          label: "Cancel",
          color: "secondary",
          variant: "outlined",
          onClick: closeModal,
        },
      ]}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <AmoTextField
            control={control}
            label=""
            id="newGroupingText"
            name="newGrouping"
            variant="outlined"
            placeholder="Enter new grouping name"
            className={classes.textInput}
            focused={false}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            id="AddButton"
            data-testid="AddButton"
            color="primary"
            variant="outlined"
            size="large"
            disableElevation
            onClick={handleSubmit((formValues) =>
              addGrouping(formValues.newGrouping)
            )}
            className={classes.button}
            disabled={nameExists}
          >
            Add
          </Button>
        </Grid>
        <Grid item container spacing={1} className={classes.groupingBox}>
          {renderItems()}
        </Grid>
      </Grid>
      {itemToDelete !== null && (
        <AmoConfirmPopover
          data-testid="groupings-delete-popup"
          variant="default"
          open
          title="Delete?"
          text={() =>
            `Are you sure you want to delete '${getValues(
              `groupings.${itemToDelete}.name`
            )}'?`
          }
          yesButtonFunction={() => onRemove(itemToDelete)}
          yesButtonVariant="contained"
          noButtonFunction={() => setItemToDelete(null)}
          closePopover={() => setItemToDelete(null)}
        />
      )}
    </AmoModal>
  );
};

ManageGroupingsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModalFunction: PropTypes.func.isRequired,
  groupings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setGroupingData: PropTypes.func.isRequired,
};

export default ManageGroupingsModal;
