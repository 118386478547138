import financialRowElement from "./financialRowElement";

const totalRow = (
  displayName,
  financialDataFieldName,
  financialData,
  classes,
  isNegative = false
) =>
  financialRowElement(
    displayName,
    financialDataFieldName,
    financialData,
    classes,
    true,
    true,
    false,
    isNegative,
    true,
    true
  );

export default totalRow;
