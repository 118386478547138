import React, { useState, useRef } from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";

import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import ListPageWrapper from "components/ListPageWrapper";
import { exportToExcel } from "utils/export";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import { useReportingStatusReviewsHooks } from "./reportingStatusReviewsHooks";
import ColoredSquare from "components/amo/ColoredSquare";
import { booleanToTextOrUndefined } from "utils/string";
import CheckedIcon from "components/amo/CheckedIcon";
import ReportingStatusModal from "pages/amo/reporting-status-reviews/ReportingStatusModal";
import { useYearContext } from "contexts/YearContext";
import colors from "constants/colors";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  municipalButton: {
    color: colors.green.main,
    textDecoration: "none",
    fontWeight: "bold",
    textAlign: "left",
    borderRadius: "5px",
    fontSize: "0.875rem",
    padding: "0.5rem 0.1rem",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    "&:focus": {
      textDecoration: "underline",
      backgroundColor: `${colors.green.main}20`,
      cursor: "pointer",
      outline: "none",
    },
  },
}));

/**
 * The Reporting Status Review List Page.
 *
 * @returns {React.Component} Reporting Status Review List Page component.
 */
const ReportingStatusReviewListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { contextYear } = useYearContext();
  const { getMunicipalitiesNames } = useMunicipalContext();

  const [showModal, setShowModal] = useState(false);
  const [selectedReportStatus, setSelectedReportStatus] = useState({});
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const {
    data: reportingStatusReviews,
    isFetching: reportingStatusReviewsFetching,
    refetch: refetchReportingStatusReviews,
  } = useReportingStatusReviewsHooks(contextYear);

  const openModal = async (id) => {
    setSelectedReportStatus({
      ...reportingStatusReviews.find(
        ({ municipalityId }) => municipalityId === id
      ),
      selectedYear: contextYear,
    });
    setShowModal(true);
    setFocusedItem(null);
  };

  const municipalityColor = ({
    reportSubmitted,
    financialsApproved,
    projectsApproved,
    certificateApproved,
  }) => {
    if (
      reportSubmitted &&
      financialsApproved &&
      projectsApproved &&
      certificateApproved
    ) {
      return "#23AE49"; // Green
    }
    if (!reportSubmitted) {
      return "#E0E0E0"; // Grey
    }
    return "#5F8CFE"; // Blue
  };

  const tableColumns = [
    {
      fieldKey: "municipalityName",
      label: "Municipality",
      minWidth: "15.625rem",
      customRender: ({ municipalityId, municipalityName, ...row }) => (
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              onClick={() => openModal(municipalityId)}
              disableRipple
              className={classes.municipalButton}
              ref={(element) => {
                rowRefs.current[municipalityId] = element;
              }}
              onFocus={() => setFocusedItem({ municipalityId })}
            >
              <Grid item>
                <ColoredSquare color={municipalityColor(row)} />
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  {municipalityName}
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      ),
      isCustom: true,
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "reportSubmitted",
      label: "Report Submitted",
      minWidth: "13rem",
      customRender: ({ reportSubmitted }) => (
        <CheckedIcon checked={reportSubmitted} />
      ),
      customExport: ({ reportSubmitted }) =>
        booleanToTextOrUndefined(reportSubmitted) ?? "",
      displayType: displayTypes.boolean,
      isCustom: true,
    },
    {
      fieldKey: "financialsApproved",
      label: "Financials Approved",
      minWidth: "14rem",
      customRender: ({ financialsApproved }) => (
        <CheckedIcon checked={financialsApproved} />
      ),
      customExport: ({ financialsApproved }) =>
        booleanToTextOrUndefined(financialsApproved) ?? "",
      displayType: displayTypes.boolean,
      isCustom: true,
    },
    {
      fieldKey: "projectsApproved",
      label: "Projects Approved",
      minWidth: "13rem",
      customRender: ({ projectsApproved }) => (
        <CheckedIcon checked={projectsApproved} />
      ),
      customExport: ({ projectsApproved }) =>
        booleanToTextOrUndefined(projectsApproved) ?? "",
      displayType: displayTypes.boolean,
      isCustom: true,
    },
    {
      fieldKey: "certificateApproved",
      label: "COI Approved",
      minWidth: "11rem",
      customRender: ({ certificateApproved }) => (
        <CheckedIcon checked={certificateApproved} />
      ),
      customExport: ({ certificateApproved }) =>
        booleanToTextOrUndefined(certificateApproved) ?? "",
      displayType: displayTypes.boolean,
      isCustom: true,
    },
    {
      fieldKey: "selectedForAudit",
      label: "Selected For Audit",
      minWidth: "13rem",
      customExport: ({ selectedForAudit }) =>
        booleanToTextOrUndefined(selectedForAudit) ?? "",
      displayType: displayTypes.boolean,
    },
    {
      fieldKey: "notes",
      label: "Notes",
      minWidth: "9.375rem",
      customRender: ({ notes }) => {
        if (!notes) {
          return "";
        }
        if (notes.length >= 30) {
          return `${notes.slice(0, 30)}...`;
        }
        return notes;
      },
      isCustom: true,
    },
  ];

  const tableActions = {
    actions: [tableDefaultActions.search, tableDefaultActions.export],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            exportToExcel(
              filteredData.current,
              [
                {
                  fieldKey: "municipalityId",
                  label: "Municipality Id",
                },
                ...tableColumns,
              ],
              "Reporting Status Reviews",
              {
                sheetName: "Reviews",
                specialColumnSizes: { municipalityName: 30 },
              }
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
      },
    },
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="h1">Reporting Status</Typography>
        </Grid>
        <Grid item className={classes.dividerContainer}>
          <Divider />
        </Grid>
        <Grid item>
          <Grid item>
            <AmoTableWithFilters
              items={reportingStatusReviews}
              columns={tableColumns}
              defaultOrderBy="municipalityName"
              onFilteredDataChanged={(value) => {
                filteredData.current = value;
              }}
              onScroll={(item) => {
                rowRefs.current?.[item.municipalityId]?.focus();
              }}
              focusedItem={focusedItem}
              actionsProps={tableActions}
              isLoading={reportingStatusReviewsFetching}
              uniqueKeyGenerationFields={["municipalityId"]}
            />
          </Grid>
        </Grid>
      </Grid>
      {showModal && (
        <ReportingStatusModal
          showModal={showModal}
          closeModalFunction={(refresh) => {
            if (refresh) refetchReportingStatusReviews();
            setShowModal(false);
            setFocusedItem({
              municipalityId: selectedReportStatus.municipalityId,
            });
          }}
          selectedReport={selectedReportStatus}
        />
      )}
    </ListPageWrapper>
  );
};

export default ReportingStatusReviewListPage;
