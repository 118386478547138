export const communicationStatusIds = {
  0: "All",
  1: "None",
  2: "Considering",
  3: "Planning",
  4: "On Hold",
  5: "Ready",
  6: "Cancelled",
  7: "Published",
};
