import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { TableCell, Typography, TableSortLabel, Icon } from "@material-ui/core";
import colors from "constants/colors";

import { displayTypes } from "constants/amoTableConstants";
import {
  AmoTableColumnProps,
  AmoTableColumnDefaultProps,
} from "constants/amoTablePropTypes";

const useStyles = makeStyles((theme) => ({
  darkGreen: {
    color: colors.green.dark,
  },
  tableHeadCell: {
    borderBottom: `1px solid ${colors.grey.main}`,
  },
  tableHeadCellFocus: {
    "&:focus, &:focus-visible, &:focus-within": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  noBorder: {
    borderBottom: "none",
  },
  tableHeadSortIcon: {
    fontSize: "1.3rem",
  },
}));

/**
 * A table header component for displaying the column label and sort
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.tableId - id of the table component
 * @param {AmoTableColumnProps} props.column - the object with the table column configuration
 * @param {boolean} props.showFilters - controls if the table filters are visible or not
 * @param {string} props.sortDirection - the sort direction of the column
 * @param {boolean} props.isSorted - controls whether this column is sorted or not
 * @param {Function} props.onHeaderClick - function called when user clicks on a table header (params: { @see props.column.fieldKey })
 *
 * @returns {React.Component} - The table header component
 */
const AmoTableHeader = ({
  tableId,
  column,
  showFilters,
  sortDirection,
  isSorted,
  onHeaderClick,
}) => {
  const {
    fieldKey,
    displayType,
    verticalAlign,
    horizontalAlign,
    widthPerc,
    minWidth,
    maxWidth,
    hideLabel,
    noHeaderWrap,
    label,
  } = column;

  const classes = useStyles();

  const isRightAligned =
    displayType === displayTypes.number ||
    displayType === displayTypes.currency;

  return (
    <TableCell
      id={`${tableId ?? "amo-table"}-head-cell-${fieldKey}`}
      key={`amoTableHeadCell-${fieldKey}`}
      className={clsx(
        showFilters ? classes.noBorder : classes.tableHeadCell,
        classes.tableHeadCellFocus
      )}
      sortDirection={isSorted ? sortDirection : false}
      width={widthPerc ? `${widthPerc}%` : null}
      style={{
        minWidth: minWidth ?? "inherit",
        maxWidth: maxWidth ?? "inherit",
        textAlign: isRightAligned ? "right" : horizontalAlign ?? "initial",
        verticalAlign: verticalAlign ?? "middle",
      }}
    >
      {hideLabel ? null : (
        <TableSortLabel
          id={`${tableId ?? "amo-table"}-head-cell-sort-${fieldKey}`}
          key={`amoTableHeadCellSort-${fieldKey}`}
          active={isSorted}
          direction={isSorted ? sortDirection : "asc"}
          onClick={() => onHeaderClick(fieldKey)}
          IconComponent={() =>
            isSorted ? (
              <Icon
                className={clsx(
                  "material-icons-outlined",
                  classes.darkGreen,
                  classes.tableHeadSortIcon
                )}
              >
                {sortDirection === "desc" ? "expand_more" : "expand_less"}
              </Icon>
            ) : null
          }
        >
          <Typography
            className={classes.darkGreen}
            variant="body2"
            noWrap={noHeaderWrap}
            title={noHeaderWrap && label}
            style={{
              maxWidth: maxWidth ?? "inherit",
            }}
          >
            {label}
          </Typography>
        </TableSortLabel>
      )}
    </TableCell>
  );
};

// set the prop-types for this component
AmoTableHeader.propTypes = {
  tableId: PropTypes.string,
  column: PropTypes.shape(AmoTableColumnProps),
  showFilters: PropTypes.bool,
  sortDirection: PropTypes.string,
  isSorted: PropTypes.bool,
  onHeaderClick: PropTypes.func,
};

AmoTableHeader.defaultProps = {
  tableId: undefined,
  column: AmoTableColumnDefaultProps,
  showFilters: false,
  sortDirection: "asc",
  isSorted: false,
  onHeaderClick: () => {},
};

export default AmoTableHeader;
