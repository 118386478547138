/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import parse from "html-react-parser";
import colors from "../constants/colors";
import { DateTime } from "luxon";
import { announcementsService } from "api/services/announcementService";
import { useSnackbar } from "contexts/SnackbarContext";
import { errorMessages } from "constants/errorMessages";
import { snackbarTypes } from "constants/snackbar";
import { mobileBreakpoint } from "constants/viewport";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // 24px 5px 8px 24px
    padding: "1.5rem 0.313rem 0.5rem 1.5rem",
    border: `1px solid ${colors.grey.main}`,
    borderRadius: "10px",
  },
  cardContent: {
    "&:last-child": { padding: "0" },
  },
  listItemRoot: {
    flexDirection: "column",
    padding: "0rem",
    marginBottom: "1.75rem",
    "&:last-child": {
      marginBottom: "0rem",
    },
    "& > div > a": {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    marginTop: "1rem",
    width: "100%",
    "& > span > p": {
      "&:first-child": {
        marginTop: "0rem",
      },
      "&:last-child": {
        marginBottom: "0rem",
      },
      "& > a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontWeight: "bolder",
      },
    },
    "& > span > p > a:hover": {
      textDecoration: "underline",
    },
    "& > span > iframe": {
      width: "100%",
      height: "56vw",
      // 560px
      maxWidth: "35rem",
      // 315px
      maxHeight: "19.75rem",
    },
  },
  list: {
    // 650px
    maxHeight: "40.625rem",
    padding: "0rem",
    // 48px
    paddingRight: "2rem",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      marginRight: 1,
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.grey.main,
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: colors.grey.dark,
    },
    [`@media (max-width:${mobileBreakpoint.width - 1}px)`]: {
      paddingRight: "1rem",
    },
  },
  listItemTitle: {
    fontSize: "1.5rem",
    color: colors.black,
  },
  listItemSubtitle: {
    fontSize: "0.875rem",
    color: theme.palette.primary.dark,
  },
  primaryTextDark: {
    color: theme.palette.primary.dark,
  },
}));

/**
 * The announcements card for the login page.
 *
 * @returns {React.Component} The annoucements card component.
 */
const AnnouncementsCard = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const mounted = useIsMounted();

  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await announcementsService.getPublished();

        if (!mounted.current) {
          return;
        }

        setAnnouncements(
          data.sort((announcement1, announcement2) =>
            announcement2.creationDate.localeCompare(announcement1.creationDate)
          )
        );
      } catch {
        if (!mounted.current) {
          return;
        }

        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
    })();
  }, []);

  return (
    <>
      <Typography gutterBottom className={classes.primaryTextDark} variant="h1">
        Announcements
      </Typography>
      {!announcements.length && (
        <Typography>No published announcements</Typography>
      )}
      {!!announcements.length && (
        <Card className={classes.root} variant="outlined">
          <CardContent className={classes.cardContent}>
            <List className={classes.list}>
              {announcements.map((announcement) => (
                <ListItem
                  key={`announcement-${announcement.id}`}
                  className={classes.listItemRoot}
                  alignItems="flex-start"
                >
                  <ListItemText
                    primary={announcement.title}
                    primaryTypographyProps={{
                      variant: "h3",
                      className: classes.listItemTitle,
                    }}
                    secondary={DateTime.fromISO(
                      announcement.creationDate
                    ).toLocaleString(DateTime.DATE_FULL)}
                    secondaryTypographyProps={{
                      variant: "caption",
                      className: classes.listItemSubtitle,
                    }}
                  />
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "body1",
                    }}
                    className={classes.listItemText}
                  >
                    {parse(announcement.body)}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AnnouncementsCard;
