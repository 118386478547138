import { makeStyles, Grid, Typography, Divider } from "@material-ui/core";

import React from "react";
import colors from "constants/colors";
import clsx from "clsx";
import { routes } from "constants/routes";
import { questionnaireTypes } from "constants/questionnaireTypes";
import AmoPageHeader from "components/AmoPageHeader";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useMunicipalContext } from "contexts/MunicipalContext";
import MunicipalQuestionnairePage from "pages/municipal/municipalQuestionnaire/ManagementQuestionnairePage";
import { useHistory, useLocation, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: colors.black,
  },
  button: {
    minHeight: "3.125rem",
    fontSize: "1rem",
    borderWidth: "0.125rem",
    "&:hover": {
      borderWidth: "0.125rem",
    },
  },
  iconButton: {
    border: `0.125rem solid ${theme.palette.primary.main}`,
  },
  ellipsisIcon: {
    cursor: "pointer",
    fontSize: "3.2rem",
  },
  menuItemIcon: {
    color: "black",
    marginRight: "1.125rem",
  },
  minWidth: "initial",
  categoryIcon: {
    width: "100%",
    textAlign: "center",
  },
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  buttonsBar: {
    paddingBottom: "1.438rem !important",
  },
}));

/**
 * The Questionnaire Review Edit Page.
 *
 * @returns {React.Component} Questionnaire Review Edit Page component.
 */
const QuestionnaireReviewEditPage = () => {
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { municipalities } = useMunicipalContext();
  const { municipalityId, year, type } = useParams();

  return (
    <Grid container direction="column" spacing={0} wrap="nowrap">
      {/* Header */}
      <Grid item>
        <AmoPageHeader
          title="Questionnaire Review:"
          subtitle="Edit Responses"
          backLinkText="Back to list"
          backLinkTo={routes.reviews.questionnaires.list}
        />
      </Grid>

      {/* Body */}
      <Grid item xs className={globalClasses.editPageBodyContainer}>
        <Grid
          container
          direction="column"
          spacing={0}
          className={clsx(
            globalClasses.editPageBody22,
            globalClasses.editPageBodyText
          )}
          wrap="nowrap"
        >
          <Grid item container spacing={4}>
            <Grid item xs={6}>
              <Grid
                container
                alignItems="flex-end"
                spacing={0}
                className={classes.overviewItem}
              >
                <Grid item xs={12}>
                  <Typography
                    data-testid="municipality"
                    variant="body2"
                    className={classes.label}
                  >
                    Municipality
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {municipalities[municipalityId]?.fullName ?? "--"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid
                container
                alignItems="flex-end"
                spacing={0}
                className={classes.rightOverviewItem}
              >
                <Grid item xs={12}>
                  <Typography
                    data-testid="reportingYear"
                    variant="body2"
                    className={classes.label}
                  >
                    Reporting Year
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">{year}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid
                container
                alignItems="flex-end"
                className={classes.overviewItem}
              >
                <Grid item xs={12}>
                  <Typography
                    data-testid="questionnaire"
                    variant="body2"
                    className={classes.label}
                  >
                    Questionnaire
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.financialStatus}>
                  <Typography variant="body1">{type} Questionnaire</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid>
            <MunicipalQuestionnairePage
              riskManagement={type === questionnaireTypes.riskMgmt}
              yearOverride={year}
              municipalityIdOverride={municipalityId}
              isAmoStaff
              extraActions={[
                {
                  testId: "cancelResponsesButton",
                  label: "Cancel",
                  color: "secondary",
                  variant: "outlined",
                  onClick: () =>
                    history.push(
                      `${routes.reviews.questionnaires.list}${location.search}`
                    ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuestionnaireReviewEditPage;
