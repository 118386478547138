import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import colors from "constants/colors";
import { routes } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: colors.grey.light,
    marginBottom: "1rem",
    padding: "0.8rem",
    "& p": {
      display: "inline",
    },
  },
  secondLine: {
    marginTop: "0.5rem",
    "& p": {
      fontSize: "0.88rem",
    },
  },
  fontSize: {
    fontSize: "0.875rem",
  },
  dateText: {
    color: colors.grey.dark,
  },
  routerTextLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * A component to a single recent list communication item
 *
 * @param {object} props - object containing props for this component
 * @param {number} props.id - Project id
 * @param {string} props.name - Project name
 * @param {string} props.municipality - Municipality name related to the project
 * @param {string} props.date - When the project was announced
 * @param {string} props.twitter - Twitter url used on the communication
 * @param {string} props.instagram - Instagram url used on the communication
 * @param {string} props.linkedIn - LinkedIn url used on the communication
 * @param {string} props.buildingCommunities - CCBF website url used on the communication
 *
 * @returns - The recent list item component
 */
const RecentListCommItem = (props) => {
  const {
    id,
    name,
    municipality,
    date,
    twitter,
    instagram,
    linkedIn,
    buildingCommunities,
  } = props;
  const classes = useStyles();
  return (
    <Typography variant="body1" className={classes.fontSize} component="div">
      <Grid
        key={id}
        container
        direction="column"
        className={classes.mainContainer}
      >
        <Grid item>
          <Typography
            variant="body1"
            className={clsx(classes.fontSize, classes.dateText)}
          >
            {date}
          </Typography>
        </Grid>
        <Grid item>
          <RouterLink
            to={routes.communications.projects.details.replace(
              ":projectId",
              id
            )}
            className={classes.routerTextLink}
          >
            <Typography variant="body2" color="primary">
              {name}
            </Typography>
          </RouterLink>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.fontSize}>
            {municipality}
          </Typography>
        </Grid>
        <Grid item className={classes.secondLine}>
          {twitter || instagram || linkedIn || buildingCommunities ? (
            <>
              Published to{" "}
              {twitter && (
                <>
                  <Link href={twitter} target="_blank" color="primary">
                    Twitter
                  </Link>
                  {(instagram || linkedIn || buildingCommunities) && ", "}
                </>
              )}
              {instagram && (
                <>
                  <Link href={instagram} target="_blank" color="primary">
                    Instagram
                  </Link>
                  {(linkedIn || buildingCommunities) && ", "}
                </>
              )}
              {linkedIn && (
                <>
                  <Link href={linkedIn} target="_blank" color="primary">
                    LinkedIn
                  </Link>
                  {buildingCommunities && ", "}
                </>
              )}
              {buildingCommunities && (
                <Link
                  href={buildingCommunities}
                  target="_blank"
                  color="primary"
                >
                  CCBF Website
                </Link>
              )}
            </>
          ) : (
            <Typography variant="body1">
              Not published on social media
            </Typography>
          )}
        </Grid>
      </Grid>
    </Typography>
  );
};

// set the prop-types for this component
RecentListCommItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  municipality: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  linkedIn: PropTypes.string,
  buildingCommunities: PropTypes.string,
};

RecentListCommItem.defaultProps = {
  twitter: undefined,
  instagram: undefined,
  linkedIn: undefined,
  buildingCommunities: undefined,
};

export default RecentListCommItem;
