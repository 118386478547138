import React, { useState, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import { Icon } from "leaflet";

const useStyles = makeStyles((theme) => ({
  mapWrapper: {
    "& > .leaflet-container": {
      height: "100%",
    },
  },
  // Filter is being used because the default pin icon is an image, and cannot directly be styled to a different colour
  purple: {
    filter:
      "brightness(0) saturate(100%) invert(15%) sepia(28%) saturate(7011%) hue-rotate(273deg) brightness(103%) contrast(105%)", // #731794
  },
  blue: {
    filter:
      "brightness(0) saturate(100%) invert(47%) sepia(90%) saturate(1891%) hue-rotate(205deg) brightness(103%) contrast(99%)", // #5F8CFE
  },
  yellow: {
    filter:
      "brightness(0) saturate(100%) invert(75%) sepia(24%) saturate(4617%) hue-rotate(357deg) brightness(104%) contrast(104%)", // #FFB600
  },
  green: {
    filter:
      "brightness(0) saturate(100%) invert(50%) sepia(86%) saturate(444%) hue-rotate(84deg) brightness(91%) contrast(85%)", // #23AE49
  },
  amoGreen: {
    filter:
      "invert(44%) sepia(26%) saturate(1168%) hue-rotate(52deg) brightness(89%) contrast(80%)", // #4D7F2A
  },
}));

/**
 * A map component to display a list of locations
 *
 * @param {object} props - object containing props for this component
 * @param {object[]} props.locations - list of locations to display
 * @param {number} props.locations.lat - location latitude
 * @param {number} props.locations.lng - location longitude
 * @param {string} props.locations.label - location label to display as tooltip
 * @param {string} props.locations.color - color of pin to display on map
 * @param {string} props.width - width of component
 * @param {string} props.height - height of component
 * @param {boolean} props.enableScrollZoom - controls whether the scroll zoom is enabled
 * @param {boolean} props.enableHiddenCheck - controls whether to check if the map is hidden
 *
 * @returns {React.Component} The map component
 */
const AmoMap = (props) => {
  const {
    locations,
    width,
    height,
    enableScrollZoom,
    enableHiddenCheck,
  } = props;
  const classes = useStyles();

  const [map, setMap] = useState(null);

  const ontarioBoundaries = [
    [41.6, -95.15],
    [56.86, -74.31],
  ];

  const bounds =
    locations.length < 2
      ? ontarioBoundaries
      : locations.reduce(
          (boundsArr, location) => [
            [
              Math.min(location.lat, boundsArr[0][0]),
              Math.min(location.lng, boundsArr[0][1]),
            ],
            [
              Math.max(location.lat, boundsArr[1][0]),
              Math.max(location.lng, boundsArr[1][1]),
            ],
          ],
          [
            [locations[0].lat, locations[0].lng],
            [locations[0].lat, locations[0].lng],
          ]
        );

  // This effect is necessary because the map bugs when hidden (tabs, for example)
  useLayoutEffect(() => {
    if (enableHiddenCheck && map) {
      map.invalidateSize(false);
      map.fitBounds(bounds);
    }
  }, [enableHiddenCheck, map]);

  useLayoutEffect(() => {
    if (map) {
      const newBounds =
        locations.length < 2
          ? ontarioBoundaries
          : locations.reduce(
              (boundsArr, location) => [
                [
                  Math.min(location.lat, boundsArr[0][0]),
                  Math.min(location.lng, boundsArr[0][1]),
                ],
                [
                  Math.max(location.lat, boundsArr[1][0]),
                  Math.max(location.lng, boundsArr[1][1]),
                ],
              ],
              [
                [locations[0].lat, locations[0].lng],
                [locations[0].lat, locations[0].lng],
              ]
            );

      map.fitBounds(newBounds);
    }
  }, [locations, map]);

  return (
    <div
      className={classes.mapWrapper}
      style={{ width, height }}
      data-testid="amo-map"
    >
      <MapContainer
        bounds={bounds}
        scrollWheelZoom={enableScrollZoom}
        whenCreated={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map((location) => (
          <Marker
            key={`${location.lat}${location.lng}`}
            position={[location.lat, location.lng]}
            icon={new Icon.Default({ className: classes[location.color] })}
          >
            {location?.label && <Tooltip>{location.label}</Tooltip>}
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

// set the prop-types for this component
AmoMap.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      label: PropTypes.string,
      color: PropTypes.string,
    })
  ).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  enableScrollZoom: PropTypes.bool,
  enableHiddenCheck: PropTypes.bool,
};

AmoMap.defaultProps = {
  width: "10rem",
  height: "10rem",
  enableScrollZoom: false,
  enableHiddenCheck: false,
};

export default AmoMap;
