import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router-dom";
import { routes } from "constants/routes";

// WARNING: The API context is being deprecated in this project in favor of services.

/**
 * The base API context.
 */
const ApiContext = createContext(null);

/**
 * React's context hook.
 *
 * @returns {any} The current value for the API context.
 */
const useApiContext = () => useContext(ApiContext);

/**
 * A API context wrapper, provides context to children, which includes get, post, put, del CRUD ops.
 *
 * @param {Node} {children}
 * @returns {Element} Wrapped children, that will now have access to the API context.
 */
const ApiProvider = ({ children }) => {
  const { signOut } = useAuth();
  const history = useHistory();
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // check if the response is 401 Unauthorized
      if (error?.response?.status === 401 && signOut) {
        // Signout the user out and redirect to login
        signOut();
      }
      // check if the response is 403 Forbidden
      if (error?.response?.status === 403 && history) {
        // Redirect to access denied page
        history.replace(routes.accessDenied);
      }
      return Promise.reject(error);
    }
  );

  const get = async (url) => {
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  };

  const post = async (url, data) => {
    try {
      const response = await axios.post(url, data);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  };

  const put = async (url, data) => {
    try {
      const response = await axios.put(url, data);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  };

  const del = async (url) => {
    try {
      const response = await axios.delete(url);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  };

  return (
    <ApiContext.Provider value={{ get, post, put, del }}>
      {children}
    </ApiContext.Provider>
  );
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ApiContext, useApiContext, ApiProvider };
