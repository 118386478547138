// Set up a key factory per feature for a flexible query key reference
export const insuranceReportKeys = {
  insurance: ["insurance"],
  list: (municipalityId) => [...insuranceReportKeys.insurance, municipalityId],
  report: (municipalityId, year) => [
    ...insuranceReportKeys.insurance,
    municipalityId,
    year,
  ],
};
