import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import { Grid, IconButton, Button, Typography } from "@material-ui/core";
import AmoConfirmPopover from "components/AmoConfirmPopover";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { routes } from "constants/routes";
import { faqListService } from "api/services/faqListService";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import AmoTable from "components/table/AmoTable";
import { tableDefaultActions } from "constants/amoTableConstants";
import { getSortingComparator, stableSort } from "utils/data";
import { copyPageUrl } from "utils/url";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    fontSize: "1.5rem",
  },
  rowFont: {
    fontSize: "0.875rem",
  },
  copyButton: {
    width: "10rem",
    height: "3.25rem",
  },
}));

/**
 * The AMO FAQs, for managing FAQ Q&A content.
 *
 * @param {object} props
 * @returns {React.Component} AMO FAQs component.
 */
const FAQs = (props) => {
  const { tableTitleComponent } = props;

  const classes = useStyles();
  const mounted = useIsMounted();
  const history = useHistory();
  const { search } = useLocation();
  const { showSnackbar } = useSnackbar();
  const globalClasses = useGlobalStyles();

  const [deletePopover, setDeletePopover] = useState(false);
  const [FAQData, setFAQData] = useState([]);
  const [deletePermalink, setDeletePermalink] = useState(null);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});

  const tableColumns = [
    {
      fieldKey: "questionComponent",
      label: "Question",
      widthPerc: 28,
      customRender: ({ id, permalink, question }) => (
        <Link
          data-testid="FAQTableNameLink"
          to={`${routes.contentManagement.help.editFAQ.replace(
            ":permalink",
            permalink
          )}${search}`}
          ref={(element) => {
            rowRefs.current[id] = element;
          }}
          className={globalClasses.tableActionCell}
          onFocus={() => setFocusedItem({ id })}
        >
          <Typography
            className={classes.rowFont}
            color="primary"
            variant="body2"
          >
            {question}
          </Typography>
        </Link>
      ),
      isCustom: true,
    },
    {
      fieldKey: "lastEdited",
      label: "Last Edited",
      widthPerc: 30,
    },
    { fieldKey: "published", label: "Published?", widthPerc: 12 },
    {
      fieldKey: "sortOrder",
      label: "Sort",
      widthPerc: 10,
    },
    {
      fieldKey: "actions",
      hideSearch: true,
      hideLabel: true,
      widthPerc: 20,
      customRender: ({ id, permalink, question }) => (
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Button
              data-testid={`faqsTableCopyButton${permalink}`}
              className={classes.copyButton}
              color="primary"
              variant="outlined"
              size="medium"
              onClick={(event) => copyURL(event, permalink)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  copyURL(event, permalink);
                }
              }}
              disableElevation
            >
              <Typography align="center" variant="body2">
                Copy URL
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <IconButton
              data-testid={`faqsTableEditButton${permalink}`}
              component={Link}
              to={`${routes.contentManagement.help.editFAQ.replace(
                ":permalink",
                permalink
              )}${search}`}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  history.push(
                    `${routes.contentManagement.help.editFAQ.replace(
                      ":permalink",
                      permalink
                    )}${search}`
                  );
                }
              }}
              onFocus={() => setFocusedItem({ id })}
            >
              <EditOutlined color="primary" className={classes.actionIcon} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              data-testid={`announcementsTableRemoveButton${permalink}`}
              onClick={(event) => onRemove(event, permalink)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  onRemove(event, permalink);
                }
              }}
            >
              <DeleteOutline color="secondary" className={classes.actionIcon} />
            </IconButton>
          </Grid>
        </Grid>
      ),
      isCustom: true,
    },
  ];

  const copyURL = (event, permalink) => {
    event.stopPropagation(); // prevents the AmoTable click event from propagating - prevents the delete + copy function from sending the user to the edit page
    copyPageUrl(routes.help.faqsByPermalink.replace(":permalink", permalink));
    showSnackbar("URL Copied");
  };

  const onRemove = (event, permalink) => {
    event.stopPropagation();
    setDeletePermalink(permalink);
    setDeletePopover(true);
  };

  const removeEvent = async () => {
    try {
      await faqListService.remove(deletePermalink);

      if (!mounted.current) {
        return;
      }

      showSnackbar("Successfully deleted");
      getData();
      setDeletePopover(false);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  // function that gets the required FAQ data
  const getData = async () => {
    setIsLoading(true);

    try {
      const { data } = await faqListService.getAll();

      if (!mounted.current) {
        return;
      }

      const mappedResult = data.map((item) => ({
        ...item,
        published: item.isPublished ? "Yes" : "No",
      }));

      setFAQData(mappedResult);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) {
        return;
      }

      if (error.response.data.status === 404) {
        setFAQData([]);
      } else {
        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
    }

    setIsLoading(false);
    setIsLoaded(true);
  };

  // this effect will make a call to the API to get the FAQ list
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      // If the data is loaded, sort the data
      const sortedResult = stableSort(
        FAQData,
        getSortingComparator(
          order,
          orderBy === "questionComponent" ? "question" : orderBy
        )
      );

      setFAQData(sortedResult);
    }
  }, [isLoaded, order, orderBy]);

  const tableActions = {
    titleComponent: tableTitleComponent,
    actions: [tableDefaultActions.new],
    actionsCustomConfigs: {
      [tableDefaultActions.new]: {
        label: "New FAQ",
        onClick: () => {
          history.push(`${routes.contentManagement.help.newFAQ}${search}`);
        },
        style: { width: "15rem" },
      },
    },
  };

  const handleRequestSort = (fieldKey, newOrder) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault(); // hacky way to prevent the table from resetting after enter is pressed
      }}
    >
      <>
        <AmoConfirmPopover
          data-testid="deleteFAQPopup"
          open={deletePopover}
          title="Delete FAQ"
          text="Are you sure you want to delete this FAQ?"
          yesButtonFunction={removeEvent}
          yesButtonVariant="contained"
          noButtonFunction={() => setDeletePopover(false)}
          closePopover={() => setDeletePopover(false)}
        />
        <AmoTable
          id="faqs-table"
          items={FAQData}
          columns={tableColumns}
          defaultOrderBy="sortOrder"
          onRequestSort={handleRequestSort}
          shouldUseQueryString
          disableFilters
          actionsProps={tableActions}
          onScroll={(item) => {
            rowRefs.current?.[item.id]?.focus();
          }}
          focusedItem={focusedItem}
          isLoading={isLoading}
        />
      </>
    </form>
  );
};

FAQs.propTypes = {
  tableTitleComponent: PropTypes.node,
};

FAQs.defaultProps = {
  tableTitleComponent: null,
};

export default FAQs;
