import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, MenuItem, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import AmoTextField from "components/inputs/AmoTextField";
import AmoModal from "components/AmoModal";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import AmoDatePicker from "components/inputs/AmoDatePicker";
import FileUploadList from "components/FileUploadList";
import { DateTime } from "luxon";
import { useInsuranceReviewByMunicipality } from "./insuranceReviewHooks";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Skeleton from "@material-ui/lab/Skeleton";
import { reviewService } from "api/services/reviewsService";
import { useQueryClient } from "react-query";
import { insuranceReviewQueryKeys } from "./insuranceReviewQueryKeys";
import { fileTypes, fileAssociations } from "constants/fileTypes";
import colors from "constants/colors";
import { useIsMounted } from "hooks/useIsMounted";

// sets the Material-UI styles for this component
const useStyles = makeStyles((theme) => ({
  labelTest: {
    fontWeight: "bold",
  },
  inputWidth: {
    width: "100%",
  },
  fileErrorBox: {
    position: "relative",
    width: "100%",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0.5rem",
  },
  errorText: {
    fontSize: "0.75rem",
    color: colors.red.main,
  },
}));

/**
 * @param {object} props - object containing props for this component
 * @param {boolean} props.showModal - controls visibility of the modal
 * @param {boolean} props.closeModalFunction - method that triggers when the close button is clicked
 * @param {boolean} props.newCertificate - indicates if this is a new certificate
 * @param {number} props.year - year of the certificate
 * @param {Function} props.onSaveHook - hook that is called after the save function is called
 * @returns - the manage groupings modal, styled for the AMO project
 */
const EditInsuranceCertificateModal = (props) => {
  const {
    showModal,
    closeModalFunction,
    municipalityId,
    newCertificate,
    year,
    onSaveHook,
  } = props;

  const insuranceCertificateSchema = yup
    .object(
      {
        coverageStarts: yup
          .date()
          .typeError("Coverage Starts must be a valid date")
          .min(
            new Date("2004-12-31"),
            "Coverage Starts must be on or after January 1, 2005"
          )
          .required("Coverage Starts is required"),
        coverageEnds: yup
          .date()
          .typeError("Coverage Ends must be a valid date")
          .required("Coverage Ends is required")
          .min(
            yup.ref("coverageStarts"),
            "Coverage Ends must be later than Coverage Starts"
          ),
        notes: yup.string().max(500).nullable(),
        status: yup.string(),
      },
      [["coverageStarts", "coverageEnds"]]
    )
    .required();

  const defaultValues = {
    coverageStarts: null,
    coverageEnds: null,
    notes: "",
    dateReceived: "",
    status: "none",
    id: "",
  };
  const classes = useStyles(props);

  const mounted = useIsMounted();

  const { control, reset, formState, handleSubmit } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(insuranceCertificateSchema),
  });
  const queryClient = useQueryClient();

  const { isValid, isSubmitting } = formState;

  const { showSnackbar } = useSnackbar();

  const [file, setFile] = useState(null);
  const [initialFile, setInitialFile] = useState(null);
  const [fileUploadAction, setFileUploadAction] = useState("Idle");

  const fileIsDirty = useMemo(() => file?.id !== initialFile?.id, [
    file,
    initialFile,
  ]);

  const {
    data: insuranceCertificate,
    refetch,
    isFetching,
  } = useInsuranceReviewByMunicipality(
    municipalityId,
    year,
    showModal && !newCertificate
  );

  const initializeValues = () => {
    const insuranceValues = {
      ...insuranceCertificate,
      status: ["Not Approved", "Approved"].includes(
        insuranceCertificate?.status
      )
        ? insuranceCertificate.status
        : "none",
    };
    reset(insuranceValues);
    setFile(insuranceCertificate?.file);
    setInitialFile(insuranceCertificate?.file);
  };

  useEffect(() => {
    if (showModal && !newCertificate) {
      initializeValues();
    }
  }, [insuranceCertificate]);

  useEffect(async () => {
    if (showModal && !newCertificate) {
      await refetch();
    }
  }, [showModal]);

  const saveCertificate = async (formValues, fileId = null) => {
    let statusId;
    switch (formValues.status) {
      case "Approved":
        statusId = 1;
        break;
      case "Not Approved":
        statusId = 2;
        break;
      default:
        statusId = 0;
    }

    const payload = {
      year,
      municipalityId,
      coverageStart: formValues.coverageStarts,
      coverageEnd: formValues.coverageEnds,
      notes: formValues.notes,
      dateReceived: DateTime.now(),
      statusId,
    };
    try {
      if (newCertificate || file?.id !== initialFile?.id) {
        payload.fileId = fileId;
        await reviewService.postInsuranceCertificate(payload);
      } else {
        await reviewService.updateInsuranceCertificate(payload);
      }

      if (!mounted.current) {
        return;
      }

      queryClient.invalidateQueries(
        insuranceReviewQueryKeys.insuranceReviewByMunicipality(
          municipalityId,
          year
        )
      );
      queryClient.invalidateQueries(
        insuranceReviewQueryKeys.insuranceReviews(year)
      );
      showSnackbar("Certificate saved successfully", snackbarTypes.success);
      onSaveHook(true);
      onClose();
    } catch (error) {
      if (!mounted.current) {
        return;
      }

      showSnackbar(
        "An error occured while saving the certificate",
        snackbarTypes.error
      );
    }
  };

  const onCancel = () => {
    if (fileIsDirty && newCertificate) {
      setFileUploadAction("Rollback");
    } else {
      onClose();
    }
  };

  const onClose = () => {
    if (!newCertificate && fileIsDirty) {
      queryClient.invalidateQueries(
        insuranceReviewQueryKeys.insuranceReviewByMunicipality(
          municipalityId,
          year
        )
      );
      queryClient.invalidateQueries(
        insuranceReviewQueryKeys.insuranceReviews(year)
      );
    }
    closeModalFunction();
    reset(defaultValues);
    setFile(null);
    setInitialFile(null);
  };

  const onSave = (event) => {
    handleSubmit((formValues) => saveCertificate(formValues, file?.id))(event);
  };

  const onFileRollback = () => {
    setFileUploadAction("Idle");
    onClose();
  };

  return (
    <AmoModal
      data-testid="amoModal"
      open={showModal}
      variant="default"
      title={`${newCertificate ? "Add " : ""}Certificate of Insurance: ${year}`}
      width="32rem"
      showButton
      closePopover={onCancel}
      buttons={[
        {
          key: "save",
          label: "Save",
          color: "primary",
          variant: "contained",
          disabled: isSubmitting || !isValid || !file?.name,
          onClick: onSave,
        },
        {
          key: "cancel",
          label: "Cancel",
          color: "secondary",
          variant: "outlined",
          onClick: onCancel,
        },
      ]}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} />
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <AmoDatePicker
              control={control}
              label="Coverage Starts"
              id="coverageStarts"
              name="coverageStarts"
              required
              testId="coverageStarts"
              variant="inline"
              hideCalendar
              className={classes.inputWidth}
              format="yyyy-MM-dd"
              helperText="Use format YYYY-MM-DD"
            />
          </Grid>
          <Grid item xs={6}>
            <AmoDatePicker
              control={control}
              label="Coverage Ends"
              id="coverageEnds"
              name="coverageEnds"
              required
              testId="coverageEnds"
              variant="inline"
              hideCalendar
              className={classes.inputWidth}
              format="yyyy-MM-dd"
              helperText="Use format YYYY-MM-DD"
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <AmoTextField
              multiline
              rows={1}
              rowsMax={10}
              control={control}
              label="Notes"
              id="notes"
              name="notes"
              variant="outlined"
              placeholder="Notes"
              className={classes.inputWidth}
              focused={false}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            {!isFetching || newCertificate ? (
              <>
                <FileUploadList
                  key={file ? file.id : "empty-file"}
                  fileYear={year}
                  fileAssociationId={fileAssociations.Insurance}
                  fileAssociationObjectId={insuranceCertificate?.id}
                  municipalityId={municipalityId}
                  showAllowedTypesHint={newCertificate}
                  allowedTypes={[
                    fileTypes.pdf,
                    fileTypes.jpg,
                    fileTypes.jpeg,
                    fileTypes.png,
                  ]}
                  initialValue={file ? [file] : []}
                  onChange={(files) => {
                    setFile(files?.[0]);
                  }}
                  uploadAction={fileUploadAction}
                  onRollback={onFileRollback}
                />
                {!file?.name && (
                  <Box className={classes.fileErrorBox}>
                    <Typography className={classes.errorText}>
                      A relevant insurance file must be uploaded
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Skeleton />
            )}
          </Grid>
        </Grid>

        {!newCertificate && (
          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Typography className={classes.labelTest}>
                Date Received:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {insuranceCertificate?.dateReceived
                  ? DateTime.fromISO(
                      insuranceCertificate?.dateReceived
                    ).toFormat("yyyy-MM-dd")
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        )}

        {!newCertificate && (
          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Typography className={classes.labelTest}>Status:</Typography>
            </Grid>
            <Grid item xs={6}>
              <AmoTextField
                control={control}
                select
                id="certificateStatus"
                name="status"
              >
                <MenuItem key="statusNone" value="none" disabled>
                  Select One
                </MenuItem>
                <MenuItem key="statusApproved" value="Approved">
                  Approved
                </MenuItem>
                <MenuItem key="statusNotApproved" value="Not Approved">
                  Not Approved
                </MenuItem>
              </AmoTextField>
            </Grid>
          </Grid>
        )}
      </Grid>
    </AmoModal>
  );
};

EditInsuranceCertificateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModalFunction: PropTypes.func.isRequired,
  municipalityId: PropTypes.number.isRequired,
  newCertificate: PropTypes.bool.isRequired,
  year: PropTypes.number.isRequired,
  onSaveHook: PropTypes.func.isRequired,
};

export default EditInsuranceCertificateModal;
