import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  question: string,
 *  answer: string,
 *  isPublished: number,
 *  lastEdited: string,
 *  pemalink: string,
 *  sortOrder: number
 *  }} Faqs
 */

const api = new Api("api/faq");
export const faqListService = {
  /**
   * @returns {Promise<AxiosResponse<Faqs[]>>}
   */
  getAll: () => api.get(),
  remove: (permalink) => api.del(permalink),
  create: (payload) => api.post(payload),
  /**
   * @param {string} permalink
   * @returns {Promise<AxiosResponse<Faqs>>}
   */
  getByPermalink: (permalink) => api.getById(permalink),
  update: (permalink, payload) => api.put(permalink, payload),
};
