import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  municipalUserManagementCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  formField: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        paddingBottom: "1rem",
      },
    },
  },
  formFieldCheckbox: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
  },
  formFieldGrid: {
    "& > .MuiGrid-item > .MuiGrid-container > .MuiGrid-item": {
      // 24px
      paddingRight: "1.5rem",
      // 24px
      paddingLeft: "1.5rem",
      "&:first-child": {
        paddingLeft: "0rem",
      },
      "&:last-child": {
        paddingRight: "0rem",
      },
    },
  },
  inputWidth: {
    width: "100%",
  },
  permissionsTitle: {
    color: colors.black,
    fontFamily: "Roboto",
    marginTop: "0.5rem",
    marginBottom: "0.25rem",
  },
}));

/**
 * A page component for editing "Municipal User Management" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const MunicipalUserManagementEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.municipalUserManagement
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const fieldDelegate = formFields[formCodes.fieldDelegate];
  const fieldContact = formFields[formCodes.fieldContact];
  const fieldUserEnabled = formFields[formCodes.fieldUserEnabled];
  const fieldPermissionFinancial =
    formFields[formCodes.fieldPermissionFinancial];
  const fieldPermissionProject = formFields[formCodes.fieldPermissionProject];
  const fieldPermissionQuestionnaire =
    formFields[formCodes.fieldPermissionQuestionnaire];
  const fieldPermissionCommunications =
    formFields[formCodes.fieldPermissionCommunications];

  const fieldNamesWithoutHelperText = new Set([
    fieldDelegate?.text ?? null,
    fieldContact?.text ?? null,
    fieldUserEnabled?.text ?? null,
    fieldPermissionFinancial?.text ?? null,
    fieldPermissionProject?.text ?? null,
    fieldPermissionQuestionnaire?.text ?? null,
    fieldPermissionCommunications?.text ?? null,
  ]);

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);
    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Municipal User Management"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyMargin
            )}
          >
            {/* General Information */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(classes.formField, classes.formFieldGrid)}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldFirstName] && (
                        <FormFieldWrapper
                          id="mum-first-name-field"
                          testId="mumFirstNameField"
                          type={formFields[formCodes.fieldFirstName].type}
                          value={formFields[formCodes.fieldFirstName].text}
                          className={classes.inputWidth}
                          inputProps={{ required: true }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldFirstName]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldLastName] && (
                        <FormFieldWrapper
                          id="mum-last-name-field"
                          testId="mumLastNameField"
                          type={formFields[formCodes.fieldLastName].type}
                          value={formFields[formCodes.fieldLastName].text}
                          className={classes.inputWidth}
                          inputProps={{ required: true }}
                          onEditClick={() =>
                            handleEditClick(formFields[formCodes.fieldLastName])
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldUsername] && (
                        <FormFieldWrapper
                          id="mum-username-field"
                          testId="mumUsernameField"
                          type={formFields[formCodes.fieldUsername].type}
                          value={formFields[formCodes.fieldUsername].text}
                          className={classes.inputWidth}
                          inputProps={{ required: true }}
                          onEditClick={() =>
                            handleEditClick(formFields[formCodes.fieldUsername])
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldTitle] && (
                        <FormFieldWrapper
                          id="mum-title-field"
                          testId="mumTitleField"
                          type={formFields[formCodes.fieldTitle].type}
                          value={formFields[formCodes.fieldTitle].text}
                          className={classes.inputWidth}
                          onEditClick={() =>
                            handleEditClick(formFields[formCodes.fieldTitle])
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldPhone] && (
                        <FormFieldWrapper
                          id="mum-phone-field"
                          testId="mumPhoneField"
                          type={formFields[formCodes.fieldPhone].type}
                          value={formFields[formCodes.fieldPhone].text}
                          className={classes.inputWidth}
                          inputProps={{ required: true }}
                          onEditClick={() =>
                            handleEditClick(formFields[formCodes.fieldPhone])
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldEmail] && (
                        <FormFieldWrapper
                          id="mum-email-field"
                          testId="mumEmailField"
                          type={formFields[formCodes.fieldEmail].type}
                          value={formFields[formCodes.fieldEmail].text}
                          className={classes.inputWidth}
                          inputProps={{ required: true }}
                          onEditClick={() =>
                            handleEditClick(formFields[formCodes.fieldEmail])
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldPhoneExtension] && (
                        <FormFieldWrapper
                          id="mum-phone-field"
                          testId="mumPhoneField"
                          type={formFields[formCodes.fieldPhoneExtension].type}
                          value={formFields[formCodes.fieldPhoneExtension].text}
                          className={classes.inputWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldPhoneExtension]
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Delegate */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(
                  classes.formFieldCheckbox,
                  classes.formFieldGrid
                )}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {fieldDelegate && (
                        <FormFieldWrapper
                          id="mum-treasurer-delegate-field"
                          testId="mumTreasurerDelegateField"
                          type={fieldDelegate.type}
                          value={fieldDelegate.text}
                          isFullWidth={false}
                          inputProps={{ checkbox: true }}
                          onEditClick={() => handleEditClick(fieldDelegate)}
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {fieldUserEnabled && (
                        <FormFieldWrapper
                          id="mum-user-enabled-field"
                          testId="mumUserEnabledField"
                          type={fieldUserEnabled.type}
                          value={fieldUserEnabled.text}
                          isFullWidth={false}
                          inputProps={{ switch: true }}
                          onEditClick={() => handleEditClick(fieldUserEnabled)}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {fieldContact && (
                        <FormFieldWrapper
                          id="mum-primary-contact-field"
                          testId="mumPrimaryContactField"
                          type={fieldContact.type}
                          value={fieldContact.text}
                          isFullWidth={false}
                          inputProps={{ checkbox: true }}
                          onEditClick={() => handleEditClick(fieldContact)}
                        />
                      )}
                    </Grid>
                    <Grid item xs />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Permissions */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(classes.formFieldCheckbox)}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    className={classes.permissionsTitle}
                    variant="subtitle2"
                    noWrap
                  >
                    Editing Permissions
                  </Typography>
                </Grid>
                <Grid item>
                  {fieldPermissionFinancial && (
                    <FormFieldWrapper
                      id="mum-permission-financial-field"
                      testId="mumPermissionFinancialField"
                      type={fieldPermissionFinancial.type}
                      value={fieldPermissionFinancial.text}
                      isFullWidth={false}
                      inputProps={{ checkbox: true }}
                      onEditClick={() =>
                        handleEditClick(fieldPermissionFinancial)
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {fieldPermissionProject && (
                    <FormFieldWrapper
                      id="mum-permission-project-field"
                      testId="mumPermissionProjectField"
                      type={fieldPermissionProject.type}
                      value={fieldPermissionProject.text}
                      isFullWidth={false}
                      inputProps={{ checkbox: true }}
                      onEditClick={() =>
                        handleEditClick(fieldPermissionProject)
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {fieldPermissionQuestionnaire && (
                    <FormFieldWrapper
                      id="mum-permission-questionnaire-field"
                      testId="mumPermissionQuestionnaireField"
                      type={fieldPermissionQuestionnaire.type}
                      value={fieldPermissionQuestionnaire.text}
                      isFullWidth={false}
                      inputProps={{ checkbox: true }}
                      onEditClick={() =>
                        handleEditClick(fieldPermissionQuestionnaire)
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {fieldPermissionCommunications && (
                    <FormFieldWrapper
                      id="mum-permission-communications-field"
                      testId="mumPermissionCommunicationsField"
                      type={fieldPermissionCommunications.type}
                      value={fieldPermissionCommunications.text}
                      isFullWidth={false}
                      inputProps={{ checkbox: true }}
                      onEditClick={() =>
                        handleEditClick(fieldPermissionCommunications)
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="mum-edit-popover"
        testId="mumEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
        showHelpText={!fieldNamesWithoutHelperText.has(modalField.text)}
      />
    </>
  );
};

export default MunicipalUserManagementEditPage;
