import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  name: string,
 *  phone: string,
 *  phoneExtension: string,
 *  email: string }} UserDetail
 */

/**
 * @typedef {{
 *  name: string,
 *  shortName: UserDetail,
 *  fullName: UserDetail,
 *  formalName: UserDetail
 * }} Municipality
 */

/**
 * @typedef {{
 *  municipalityNameList: Map<number, Municipality>
 * }} MunicipalityMap
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  shortName: UserDetail,
 *  fullName: UserDetail,
 *  formalName: UserDetail,
 *  population: number,
 *  reportingYear: number
 * }} UserMunicipality
 */

/**
 * @typedef {{
 *  municipalityName: string,
 *  treasurer: UserDetail,
 *  delegate: UserDetail,
 *  primary: UserDetail
 * }} MunicipalityContacts
 */

const api = new Api("api/municipalities");
export const municipalityService = {
  /**
   * @returns {Promise<AxiosResponse<MunicipalityMap>>}
   */
  getAll: () => api.getById("years"),
  /**
   * @returns {Promise<AxiosResponse<UserMunicipality>>}
   */
  getUserMunicipality: () => api.getById(`current`),
  /**
   * @param {number} municipalityId
   * @returns {Promise<AxiosResponse<MunicipalityContacts>>}
   */
  getMunicipalityContacts: (municipalityId) =>
    api.getById(`${municipalityId}/contacts`),
};
