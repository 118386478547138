import React, { useState, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  MenuItem,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { useQueryClient } from "react-query";
import { projectKeys } from "./projectQueryKeys";
import FormInputWrapper from "components/municipal/FormInputWrapper";
import AmoFormActions from "components/AmoFormActions";
import AmoTextField from "components/inputs/AmoTextField";
import AmoDatePicker from "components/inputs/AmoDatePicker";
import AmoLocationPickerControl from "components/inputs/AmoLocationPickerControl";
import AmoMap from "components/AmoMap";
import colors from "constants/colors";
import {
  formIds,
  projectReportGeneralInfoCodes as formCodes,
} from "constants/formContentManagement";
import { projectService } from "api/services/projectService";
import { steps, formStatuses } from "constants/projectReport";
import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useYearContext } from "contexts/YearContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { roleGroups, roles } from "constants/user";
import { DateTime } from "luxon";
import { parseDateString } from "utils/yupUtils";
import { range } from "utils/number";
import { dateToPayload } from "utils/date";
import { locationIsInOntario } from "utils/location";
import {
  deleteButtonTooltipText,
  deleteType,
} from "functions/projectDeleteMunicipalUtils";
import DeletionType from "enums/deletionType";
import { useProjectFunds } from "./projectReportFinancialsHooks";
import { useNavigationHelper } from "hooks/navigationHook";
import DeletionModal from "components/DeletionModal";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  body: {
    flexGrow: 1,
    flexBasis: 0,
    // 0px 80px 0px 80px
    padding: "0rem 5rem 0rem 5rem",
  },
  scrollableContent: {
    overflow: "hidden auto",
  },
  contentPadding: {
    // 0px 40px 0px 40px
    padding: "0rem 2.5rem 0rem 2.5rem",
  },
  fundsAppliedPadding: {
    // 0px 64px 0px 24px
    padding: "0rem 4rem 0rem 1.5rem",
  },
  topMargin: {
    // 24px
    marginTop: "1.5rem",
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    // 8px
    paddingBottom: "0.5rem",
  },
  outlinedButton: {
    backgroundColor: "transparent",
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
  formActions: {
    // 16px
    marginTop: "1rem",
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 24px 0px
    margin: "1.5rem 0rem",
  },
  warning: {
    "& label.Mui-focused": {
      color: colors.yellow,
    },
    "& legend.Mui-focused": {
      color: colors.yellow,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root:not(.Mui-disabled)": {
      "& fieldset": {
        borderColor: colors.yellow,
      },
      "&:hover fieldset": {
        borderColor: colors.yellow,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.yellow,
      },
      "& .MuiInputAdornment-root button": {
        color: colors.yellow,
      },
    },
  },
  // Remove spinner from number input
  numberTextField: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  secondaryLocationLeft: {
    // 8px
    paddingRight: "0.5rem",
  },
  secondaryLocationRight: {
    // 8px
    paddingRight: "0.5rem",
    paddingLeft: "0rem",
    marginRight: "0rem",
    [theme.breakpoints.up("md")]: {
      paddingRight: "0rem",
      // 40px
      paddingLeft: "2.5rem",
      marginRight: "-2.5rem",
    },
  },
  addLocationButtonContainerRight: {
    // 80px
    paddingLeft: "5rem",
  },
  addLocationButtonContainerLeft: {
    // 40px
    paddingLeft: "2.5rem",
  },
  addLocationButton: {
    width: "auto",
  },
  removeLocationButton: {
    color: colors.red.dark,
    padding: "0.25rem",
  },
  removeLocationIcon: {
    fontSize: "2rem",
    marginBottom: "0.4rem",
  },
  inputIcon: {
    color: colors.grey.dark,
  },
}));

/**
 * A form component for editing Project Report Financials
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.projectId - sets the project id used to load the project data, pass 'new' for new project [required]
 * @param {number} props.activeStep - sets the active step of the project report [required]
 * @param {number} props.categoryFormStatus - sets the Category form status (values: @see formStatuses )
 * @param {object} props.financialsData - sets the financials data of the project report
 * @param {Function} props.onFormStatusChange - function called when the form status changes (params: { @see formStatuses })
 * @param {Function} props.onNextStepRequest - function called when move to the next step is requested (params: none)
 * @param {Function} props.onUnsavedChanges - function called when project unsaved data changes (params: boolean)
 * @param {Function} props.onProjectDataChange - function called when project data changes (params: informationData)
 * @param {boolean} props.isPastConstructionEndDate - boolean to handle disabling controls when past end of construction date.
 * @param {number} props.constructionEndDateYear - int to use for individual field display and validation
 * @param {boolean} props.projectDeletionRequested - boolean to handle disabling deletion button if the request was already sent.
 * @param {Function} props.refetchValidationInfo - refetch Validation Info to update projectDeletionRequested value.
 *
 * @returns - The form component
 */
const ProjectReportGeneralInfoForm = (props) => {
  const {
    projectId,
    projectResult,
    refetchProjectData,
    activeStep,
    categoryFormStatus,
    financialsData,
    onFormStatusChange,
    onNextStepRequest,
    onUnsavedChanges,
    onProjectDataChange,
    isPastConstructionEndDate,
    constructionEndDateYear,
    projectDeletionRequested,
    refetchValidationInfo,
  } = props;

  const classes = useStyles();
  const { hasRoles } = useUserContext();
  const { contextYear } = useYearContext();
  const hasProjectsPermission = hasRoles(roleGroups.municipal.projects);
  const { formFields } = useFormManagement(
    formIds.projectReportGeneralInfo,
    true
  );
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { user: currentUser } = useUserContext();
  const { getMunicipality } = useMunicipalContext();
  const isTreasurerOrDelegate = hasRoles(
    roleGroups.municipal.treasurerOrDelegate
  );
  const { navigateBack } = useNavigationHelper();

  const mounted = useIsMounted();

  const [flags, setFlags] = useState({});
  const [primaryMapLocation, setPrimaryMapLocation] = useState(null);
  const [secondaryMapLocations, setSecondaryMapLocations] = useState([]);
  const [natureOfInvestmentValues, setNatureOfInvestmentValues] = useState([]);
  const [formStatus, setFormStatus] = useState(formStatuses.untouched);
  const [projectWasSaved, setProjectWasSaved] = useState(false);
  const [formWasReseted, setFormWasReseted] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const hasMunicipalRole = currentUser.roles.includes(roles.municipal);

  const getFieldLabel = (code, defaultLabel = "This field") =>
    formFields[code]?.text || defaultLabel;

  // Validation helper for use within yup schema test
  const whenCcbfFundsFirstYearSetMaxStartDate = (
    ccbfFundsFirstYear,
    schema
  ) => {
    if (!ccbfFundsFirstYear || ccbfFundsFirstYear <= 0) {
      return schema;
    }

    // Set max date to {ccbfFundsFirstYear + 1}-01-01 00:00:00 not included
    const maxStartDate = DateTime.local(ccbfFundsFirstYear + 1).minus({
      second: 1,
    });
    return schema.max(
      maxStartDate,
      `${getFieldLabel(
        formCodes.fieldStartDate
      )} must be in or before the first year in which CCBF funds were applied`
    );
  };

  // this method sets the mimimum date for the Construction Start Date DatePicker
  // base on other dates
  const whenStartDateSetMinConstructionEndDate = (
    startDate,
    isMunicipalUser,
    schema
  ) => {
    // get the project start date
    const luxonStartDate = startDate ? DateTime.fromJSDate(startDate) : null;
    // get the first day of the reporting year
    const municipalMinDate = DateTime.local(contextYear);
    // if the constructionEndDate is before the current reporting year, return
    if (isPastConstructionEndDate && !flags[formCodes.fieldEndConstruction]) {
      return schema;
    }

    // if we don't have a valid date, and this isn't a municipalUser, return
    if (!luxonStartDate?.isValid && !isMunicipalUser) {
      return schema;
    }

    // what should be the minimum date
    const minConstructionEndDate = luxonStartDate?.set({
      hour: 0,
      minute: 0,
      second: 0,
    });

    // if there is no end date, the end date is not valid, or the end date is less than the allowed minimum date
    // return an error
    if (
      isMunicipalUser &&
      !isPastConstructionEndDate &&
      (!minConstructionEndDate ||
        !minConstructionEndDate?.isValid ||
        municipalMinDate > minConstructionEndDate)
    ) {
      return schema.min(
        municipalMinDate,
        `${getFieldLabel(
          formCodes.fieldEndConstruction
        )} must be in or after the current year`
      );
    }

    // if the end date doesn't exist, or is invalid, return
    if (!minConstructionEndDate || !minConstructionEndDate?.isValid) {
      return schema;
    }

    // if the date is less than the start date, return an error
    return schema.min(
      minConstructionEndDate,
      `${getFieldLabel(
        formCodes.fieldEndConstruction
      )} must be on or after the start date`
    );
  };

  const whenEndOfFinancingSetMaxConstructionEndDate = (
    endOfFinancingDate,
    schema
  ) => {
    if (!endOfFinancingDate?.isValid) {
      // Set max date to 2029-01-01 00:00:00 not included
      return schema.max(
        DateTime.local(2029).minus({ second: 1 }),
        `${getFieldLabel(
          formCodes.fieldEndConstruction
        )} must be in or before 2028`
      );
    }

    const maxConstructionEndDate = endOfFinancingDate.set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    return schema.max(
      maxConstructionEndDate,
      `${getFieldLabel(
        formCodes.fieldEndConstruction
      )} must be on or before the end of financing date`
    );
  };

  const locationIsEmpty = (location) =>
    !location || (!location.position && !location.address);

  const positionIsEmpty = (position) =>
    !position || !position.lat || !position.lng;

  const yupLocationObject = () =>
    yup
      .object({
        id: yup.number(),
        position: yup
          .object({ lat: yup.number(), lng: yup.number() })
          .nullable(),
        address: yup.string().nullable(),
        valid: yup.boolean().nullable(),
      })
      .test(
        "validLocation",
        "Location must be filled with valid value",
        (_, context) => {
          const { originalValue: value } = context;
          return (
            locationIsEmpty(value) ||
            (value?.position &&
              value.position.lat != null &&
              value.position.lng != null &&
              value.position.lat !== 0 &&
              value.position.lng !== 0)
          );
        }
      )
      .test(
        "locationInOntario",
        "Location must be in Ontario",
        async (_, context) => {
          const { originalValue: value } = context;

          if (locationIsEmpty(value)) return true;

          try {
            const locationStr = value.position
              ? `${value.position.lat},${value.position.lng}`
              : value.address;

            const isValid = await locationIsInOntario(
              locationStr,
              !value.position
            );
            return isValid;
          } catch (error) {
            if (!mounted.current) {
              return false;
            }

            console.error(error);
            showSnackbar("Couldn't validate the location", snackbarTypes.error);
            return context.createError({
              message: "Couldn't validate the location",
            });
          }
        }
      )
      .nullable();

  // define schema for form validation
  const ProjectInformationValidationSchema = yup
    .object({
      title: yup
        .string()
        .nullable()
        .test(
          "title-validation-len",
          "Must be 100 characters or less",
          (val) => val.length <= 100
        ),
      internalId: yup
        .string()
        .nullable()
        .test(
          "internal-id-validation-len",
          "Must be 50 characters or less",
          (val) => val.length <= 50
        ),
      objectives: yup
        .string()
        .nullable()
        .test(
          "objectives-validation-len",
          "Must be 500 characters or less",
          (val) => val.length <= 500
        ),
      scopeOfWork: yup
        .string()
        .nullable()
        .test(
          "scope-work-validation-len",
          "Must be 500 characters or less",
          (val) => val.length <= 500
        ),
      startDate: yup
        .date()
        .min("2005-01-01", "Must be at least January 1, 2005")
        .nullable()
        .typeError(
          `${getFieldLabel(
            formCodes.fieldStartDate
          )} is not a valid date (yyyy-mm-dd)`
        )
        .transform(parseDateString)
        .when("$ccbfFundsFirstYear", whenCcbfFundsFirstYearSetMaxStartDate)
        .test(
          "start-date-validation-max",
          `The ${getFieldLabel(
            formCodes.fieldStartDate
          )} must be on or before the end of construction date`,
          (val, context) => {
            const endOfConstructionDate = dateToPayload(
              context.parent.constructionEndDate
            );
            const luxonStartDate = dateToPayload(val);
            return (
              !endOfConstructionDate || luxonStartDate <= endOfConstructionDate
            );
          }
        ),
      constructionEndDate: yup
        .date()
        .nullable()
        .typeError(
          `${getFieldLabel(
            formCodes.fieldEndConstruction
          )} is not a valid date (yyyy-mm-dd)`
        )
        .transform(parseDateString)
        .when(
          ["startDate", "$isMunicipalUser"],
          whenStartDateSetMinConstructionEndDate
        )
        .when(
          "$endOfFinancingDate",
          whenEndOfFinancingSetMaxConstructionEndDate
        ),
      investmentTypeId: yup.string().nullable(),
      additionalComments: yup
        .string()
        .nullable()
        .test(
          "additional-comments-validation-len",
          "Must be 4500 characters or less",
          (val) => val.length <= 4500
        ),
      primaryLocation: yupLocationObject(),
      secondaryLocations: yup.array().of(yupLocationObject()).nullable(),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    title: "",
    internalId: "",
    objectives: "",
    scopeOfWork: "",
    startDate: null,
    constructionEndDate: null,
    investmentTypeId: "",
    additionalComments: "",
    primaryLocation: "",
    secondaryLocations: [],
  };

  // Query project funds for deleteion
  const { data: fundsResult } = useProjectFunds(projectId, false);
  const reportingYear = getMunicipality()?.reportingYear;
  const hasPriorExpenditures = fundsResult?.data?.funds.some(
    ({ year }) => year < reportingYear
  );

  const validCcbfFunds =
    financialsData?.ccbfFunds?.filter((fund) => fund?.rawAmount > 0) ?? [];

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState,
    setValue,
    trigger,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(ProjectInformationValidationSchema),
    context: {
      ccbfFundsFirstYear: validCcbfFunds?.[validCcbfFunds.length - 1]?.year,
      endOfFinancingDate: financialsData?.endOfFinancingDate,
      isMunicipalUser: hasMunicipalRole,
    },
  });

  const {
    fields: secondaryLocations,
    append: appendToSecondaryLocations,
    remove: removeFromSecondaryLocations,
  } = useFieldArray({
    control,
    name: "secondaryLocations",
  });

  const { isValid, isSubmitting, isDirty, dirtyFields } = formState;

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    if (projectResult?.data) {
      const projectData = {
        ...projectResult.data,
        startDate: projectResult.data.startDate
          ? DateTime.fromISO(projectResult.data.startDate)
          : null,
        constructionEndDate: projectResult.data.constructionEndDate
          ? DateTime.fromISO(projectResult.data.constructionEndDate)
          : null,
      };
      reset(projectData);
      setFormWasReseted(true);
      setFlags(projectData.flags || {});

      if (projectData?.primaryLocation?.position) {
        setPrimaryMapLocation({
          ...(projectData?.primaryLocation?.position ?? {}),
          color: "green",
          label: "Primary Location",
        });
      }
      setSecondaryMapLocations(
        projectData?.secondaryLocations?.map((obj) => ({
          ...obj.position,
          color: "blue",
          label: "Secondary Location",
        })) ?? []
      );

      const touched = projectWasTouched(projectData);
      setProjectWasSaved(touched);

      handleFormStatusUpdate(
        getFormStatus(projectData, projectData.flags, touched)
      );

      onProjectDataChange(projectData);
    }
  }, [projectResult?.data]);

  useEffect(() => {
    if (formWasReseted && financialsData) {
      setFormWasReseted(false);
      trigger();
    }
  }, [formWasReseted, financialsData]);

  // these effects will make a call to the API to load necessary data for this component
  useLayoutEffect(() => {
    // TODO: load data from API
    const result = [
      {
        id: 1,
        name: "New",
      },
      {
        id: 2,
        name: "Enhancement",
      },
      {
        id: 3,
        name: "Renewal",
      },
    ];

    setNatureOfInvestmentValues(result);
  }, []);

  useLayoutEffect(() => {
    handleFormStatusUpdate(getFormStatus(getValues(), flags, projectWasSaved));
  }, [categoryFormStatus]);

  const handlePromptConfirmation = async (save, event) => {
    if (save) {
      const isFormValid = await trigger();

      if (!mounted.current) {
        return false;
      }

      if (isFormValid) {
        // Need to call handleSubmit passing the event or null when calling outside a UI interaction
        handleSubmit(saveProjectData)(event);
      } else {
        showSnackbar("Unable to save due to invalid data", snackbarTypes.error);
        return false;
      }
    } else {
      reset();
    }

    return true;
  };

  useEffect(() => {
    if (activeStep === steps.generalInformation) {
      onUnsavedChanges(
        isDirty,
        !isValid,
        isDirty ? handlePromptConfirmation : null
      );
    }
  }, [isDirty, isValid]);

  const projectDataIsIncomplete = (projectRef) =>
    !projectRef.title ||
    ((!constructionEndDateYear || constructionEndDateYear > 2020) &&
      !projectRef.objectives) ||
    !projectRef.scopeOfWork ||
    !projectRef.primaryLocation ||
    !projectRef.startDate ||
    !projectRef.constructionEndDate ||
    ((!constructionEndDateYear || constructionEndDateYear > 2015) &&
      !projectRef.investmentTypeId);

  const getFormStatus = (projectRef, flagsRef, projectWasSavedRef) => {
    if (categoryFormStatus === formStatuses.untouched) {
      return formStatuses.disabled;
    }

    if (flagsRef && Object.entries(flagsRef).length > 0) {
      return formStatuses.flagged;
    }

    if (isPastConstructionEndDate) {
      return formStatuses.completed;
    }

    if (projectWasSavedRef) {
      if (projectDataIsIncomplete(projectRef)) {
        return formStatuses.incomplete;
      }
      return formStatuses.completed;
    }

    return formStatuses.untouched;
  };

  const projectWasTouched = (projectRef) =>
    projectRef?.title ||
    projectRef?.internalId ||
    projectRef?.objectives ||
    projectRef?.scopeOfWork ||
    projectRef?.startDate ||
    projectRef?.constructionEndDate ||
    projectRef?.investmentTypeId ||
    projectRef?.additionalComments ||
    projectRef?.primaryLocation ||
    (projectRef?.secondaryLocations &&
      projectRef.secondaryLocations.length > 0);

  const handleFormStatusUpdate = (newFormStatus) => {
    if (newFormStatus !== formStatus) {
      setFormStatus(newFormStatus);
      onFormStatusChange(newFormStatus);
    }
  };

  const saveProjectData = async ({
    title,
    internalId,
    objectives,
    scopeOfWork,
    startDate,
    constructionEndDate,
    investmentTypeId,
    additionalComments,
    primaryLocation,
    secondaryLocations: secondaryLocationsRef,
  }) => {
    try {
      const payload = { id: projectId };
      const commentsChanged = dirtyFields.additionalComments;
      const constructionDateChanged = dirtyFields.constructionEndDate;

      if (title) {
        payload.title = title;
      }
      if (internalId) {
        payload.internalId = internalId;
      }
      if (objectives) {
        payload.objectives = objectives;
      }
      if (scopeOfWork) {
        payload.scopeOfWork = scopeOfWork;
      }
      if (startDate) {
        // Passing only the iso date (without time)
        payload.startDate = dateToPayload(startDate);
      }
      if (constructionEndDate) {
        // Passing only the iso date (without time)
        payload.constructionEndDate = dateToPayload(constructionEndDate);
      }
      if (investmentTypeId) {
        payload.investmentTypeId = investmentTypeId;
      }
      if (additionalComments) {
        payload.additionalComments = additionalComments;
      }

      payload.locations = [];

      if (primaryLocation) {
        payload.locations.push({
          id: primaryLocation?.id,
          isPrimary: true,
          latitude: primaryLocation?.position?.lat,
          longitude: primaryLocation?.position?.lng,
        });
      }
      if (secondaryLocationsRef) {
        for (let i = 0; i < secondaryLocationsRef.length; i += 1) {
          const secondaryLocation = secondaryLocationsRef[i];
          payload.locations.push({
            id: secondaryLocation.id,
            isPrimary: false,
            latitude: secondaryLocation.position?.lat,
            longitude: secondaryLocation.position?.lng,
          });
        }
      }

      await projectService.updateInformation(payload.id, payload);

      if (!mounted.current) {
        return;
      }

      refetchProjectData();

      showSnackbar("Project updated successfully.");

      // If additional comments changed invalidate financials query
      if (commentsChanged) {
        queryClient.invalidateQueries(projectKeys.projectFinancials(projectId));
      }
      // if the construction end date has changed, invalidate the validation query
      if (constructionDateChanged) {
        queryClient.invalidateQueries(projectKeys.projectValidation(projectId));
      }
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const addSecondaryLocation = () => {
    appendToSecondaryLocations({ address: "" });
  };
  const removeSecondaryLocation = (index) => {
    removeFromSecondaryLocations(index);
    handleMapLocationChange(null, index, false);
  };

  const handleEndConstructionFieldBlur = () => {
    const endDateValue = getValues("constructionEndDate");
    const startDateValue = getValues("startDate");

    if (endDateValue && !startDateValue) {
      document.getElementById("proj-report-start-date-field").focus();
    }
  };

  const renderInputWrapper = (
    id,
    testId,
    fieldCode,
    children,
    fullWidth = false
  ) => (
    <FormInputWrapper
      id={`${id}-wrapper`}
      testId={`${testId}Wrapper`}
      tooltip={!!formFields[fieldCode]?.tooltipText}
      tooltipProps={
        formFields[fieldCode]?.tooltipText
          ? {
              title: formFields[fieldCode]?.text,
              text: formFields[fieldCode]?.tooltipText,
            }
          : {}
      }
      flag={!!flags[fieldCode]}
      flagProps={
        flags[fieldCode]
          ? {
              ...flags[fieldCode],
              title: formFields[fieldCode]?.text,
            }
          : {}
      }
      childrenFullWidth={fullWidth}
      justifyContent="center"
    >
      {children}
    </FormInputWrapper>
  );

  // returns projectDeletionRequested in Deletion Requested or empty string to be in line with the same value from ProjectListPage.jsx
  const projectDeletionRequestedInString = projectDeletionRequested
    ? "Deletion Requested"
    : "";

  const getDeleteType = () => {
    const isRequestDeletion =
      deleteType(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ) === DeletionType.REQUEST;

    return {
      text: isRequestDeletion ? "Request Deletion" : "Delete Project",
      action: isRequestDeletion ? refetchValidationInfo : navigateBack,
    };
  };

  const renderFormActions = () => [
    {
      testId: "projReportSaveButton",
      label: "Save",
      disabled:
        !isDirty ||
        isSubmitting ||
        !isValid ||
        !hasProjectsPermission ||
        (!Object.entries(flags).length && isPastConstructionEndDate),
      onClick: handleSubmit(saveProjectData),
    },
    {
      testId: "projReportNextButton",
      label: "Next",
      variant: "outlined",
      className: classes.outlinedButton,
      onClick: onNextStepRequest,
    },
    {
      testId: "projReportDeleteButton",
      label: getDeleteType().text,
      variant: "outlined",
      color: "secondary",
      disabled: !isTreasurerOrDelegate || projectDeletionRequested,
      onClick: () => setShowDeletionModal(true),
      tooltipText: deleteButtonTooltipText(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ),
    },
  ];

  const validateLocation = (location, fieldName) => {
    let newValue = { address: "" };
    if (!locationIsEmpty(location)) {
      newValue = { ...location };
    }
    setValue(fieldName, newValue, { shouldValidate: true });
  };

  const handleMapLocationChange = (
    position,
    index = null,
    isPrimary = true
  ) => {
    if (!positionIsEmpty(position)) {
      const newMapLocation = {
        ...position,
        color: isPrimary ? "green" : "blue",
        label: `${isPrimary ? "Primary" : "Secondary"} Location`,
      };

      if (isPrimary) {
        setPrimaryMapLocation(newMapLocation);
      } else {
        const tempLocations = [...secondaryMapLocations];
        tempLocations?.splice(index ?? 0, 1, newMapLocation);
        setSecondaryMapLocations(tempLocations);
      }
    } else if (isPrimary) {
      setPrimaryMapLocation(null);
    } else {
      const tempLocations = [...secondaryMapLocations];
      tempLocations?.splice(index ?? 0, 1);
      setSecondaryMapLocations(tempLocations);
    }
  };

  const deleteModalClose = () => {
    setShowDeletionModal(false);
  };

  return (
    <>
      <DeletionModal
        open={showDeletionModal}
        id={parseInt(projectId, 10)}
        closeFunction={deleteModalClose}
        updateFunction={getDeleteType().action}
        deletionType={deleteType(
          isTreasurerOrDelegate,
          hasPriorExpenditures,
          projectDeletionRequestedInString
        )}
      />
      {!projectResult &&
        projectId !== "new" &&
        range(0, 9).map((index) => (
          <Skeleton key={index} width="100%" height="8rem" />
        ))}
      {(projectResult || projectId === "new") && (
        <Grid
          container
          className={classes.root}
          direction="column"
          spacing={0}
          wrap="nowrap"
        >
          <Grid item xs className={classes.scrollableContent}>
            <Grid
              container
              className={classes.body}
              direction="column"
              spacing={0}
              alignItems="stretch"
              wrap="nowrap"
            >
              <Grid item className={classes.contentPadding} xs={12} md={12}>
                <Typography
                  className={classes.fullWidth}
                  variant="body1"
                  component="span"
                >
                  {parse(formFields[formCodes.descriptionForm]?.text || "")}
                </Typography>
              </Grid>
              <Grid item className={classes.contentPadding} xs={12} md={12}>
                <Typography variant="h4" noWrap className={classes.title}>
                  {formFields[formCodes.titleDetails]?.text}
                </Typography>
              </Grid>
              <Grid item className={classes.contentPadding} xs={12} md={12}>
                <Typography
                  className={classes.fullWidth}
                  variant="body1"
                  component="span"
                >
                  {parse(formFields[formCodes.descriptionDetails]?.text || "")}
                </Typography>
              </Grid>
              <Grid item className={classes.topMargin} xs={12} md={12}>
                {renderInputWrapper(
                  "proj-report-title-field",
                  "projReportTitleField",
                  formCodes.fieldTitle,
                  <AmoTextField
                    disabled={
                      !hasProjectsPermission ||
                      (!flags[formCodes.fieldTitle] &&
                        isPastConstructionEndDate)
                    }
                    control={control}
                    id="proj-report-title-field"
                    name="title"
                    testId="projReportTitleField"
                    className={clsx(
                      classes.fullWidth,
                      flags[formCodes.fieldTitle] ? classes.warning : null
                    )}
                    label={getFieldLabel(formCodes.fieldTitle, "")}
                    required
                    helperText={formFields[formCodes.fieldTitle]?.helperText}
                    characterLimit={100}
                  />,
                  true
                )}
              </Grid>
              <Grid item className={classes.topMargin} xs={12} md={12}>
                {renderInputWrapper(
                  "proj-report-internal-title-field",
                  "projReportInternalTitleField",
                  formCodes.fieldInternalTitle,
                  <AmoTextField
                    disabled={
                      !hasProjectsPermission ||
                      (!flags[formCodes.fieldInternalTitle] &&
                        isPastConstructionEndDate)
                    }
                    control={control}
                    id="proj-report-internal-title-field"
                    name="internalId"
                    testId="projReportInternalTitleField"
                    className={clsx(
                      classes.fullWidth,
                      flags[formCodes.fieldInternalTitle]
                        ? classes.warning
                        : null
                    )}
                    label={getFieldLabel(formCodes.fieldInternalTitle, "")}
                    helperText={
                      formFields[formCodes.fieldInternalTitle]?.helperText
                    }
                    characterLimit={50}
                  />,
                  true
                )}
              </Grid>
              {(!constructionEndDateYear || constructionEndDateYear > 2020) && (
                <Grid item className={classes.topMargin} xs={12} md={12}>
                  {renderInputWrapper(
                    "proj-report-objectives-field",
                    "projReportObjectivesField",
                    formCodes.fieldObjectives,
                    <AmoTextField
                      multiline
                      rows={1}
                      rowsMax={10}
                      disabled={
                        !hasProjectsPermission ||
                        (!flags[formCodes.fieldObjectives] &&
                          isPastConstructionEndDate)
                      }
                      control={control}
                      id="proj-report-objectives-field"
                      name="objectives"
                      testId="projReportObjectivesField"
                      className={clsx(
                        classes.fullWidth,
                        flags[formCodes.fieldObjectives]
                          ? classes.warning
                          : null
                      )}
                      label={getFieldLabel(formCodes.fieldObjectives, "")}
                      required
                      helperText={
                        formFields[formCodes.fieldObjectives]?.helperText
                      }
                      characterLimit={500}
                    />,
                    true
                  )}
                </Grid>
              )}
              <Grid item className={classes.topMargin} xs={12} md={12}>
                {renderInputWrapper(
                  "proj-report-scope-field",
                  "projReportScopeField",
                  formCodes.fieldScope,
                  <AmoTextField
                    multiline
                    rows={1}
                    rowsMax={10}
                    disabled={
                      !hasProjectsPermission ||
                      (!flags[formCodes.fieldScope] &&
                        isPastConstructionEndDate)
                    }
                    control={control}
                    id="proj-report-scope-field"
                    name="scopeOfWork"
                    testId="projReportScopeField"
                    className={clsx(
                      classes.fullWidth,
                      flags[formCodes.fieldScope] ? classes.warning : null
                    )}
                    label={getFieldLabel(formCodes.fieldScope, "")}
                    required
                    helperText={formFields[formCodes.fieldScope]?.helperText}
                    characterLimit={500}
                  />,
                  true
                )}
              </Grid>
              <Grid item>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={12} className={classes.contentPadding}>
                {activeStep === steps.generalInformation && (
                  <AmoMap
                    locations={
                      primaryMapLocation
                        ? [primaryMapLocation, ...secondaryMapLocations]
                        : secondaryMapLocations
                    }
                    height="20rem"
                    width="100%"
                    enableHiddenCheck
                  />
                )}
              </Grid>
              <Grid item className={classes.topMargin}>
                <Grid container spacing={0} alignItems="baseline">
                  <Grid item xs={12} md={6}>
                    {renderInputWrapper(
                      "proj-report-primary-location-field",
                      "projReportPrimaryLocationField",
                      formCodes.fieldPrimaryLocation,
                      <AmoLocationPickerControl
                        disabled={
                          !hasProjectsPermission ||
                          (!flags[formCodes.fieldPrimaryLocation] &&
                            isPastConstructionEndDate)
                        }
                        control={control}
                        id="proj-report-primary-location-field"
                        name="primaryLocation"
                        testId="projReportPrimaryLocationField"
                        label={getFieldLabel(
                          formCodes.fieldPrimaryLocation,
                          ""
                        )}
                        required
                        warning={flags[formCodes.fieldPrimaryLocation]}
                        helperText={
                          formFields[formCodes.fieldPrimaryLocation]?.helperText
                        }
                        onChangeTrigger={(location) => {
                          validateLocation(location, "primaryLocation");
                          handleMapLocationChange(location?.position);
                        }}
                      />,
                      true
                    )}
                  </Grid>

                  {secondaryLocations?.map((value, index) => (
                    <Grid
                      key={`proj-report-secondary-location-field-key-${
                        value?.id || index
                      }`}
                      item
                      xs={12}
                      md={6}
                      className={clsx(
                        index !== 0 && index % 2 === 1
                          ? classes.secondaryLocationLeft
                          : classes.secondaryLocationRight,
                        index > 0 ? classes.topMargin : null
                      )}
                    >
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid item xs>
                          {renderInputWrapper(
                            `proj-report-secondary-location-field-${index}`,
                            `projReportSecondaryLocationField${index}`,
                            formCodes.fieldSecondaryLocation,
                            <AmoLocationPickerControl
                              disabled={
                                !hasProjectsPermission ||
                                isPastConstructionEndDate
                              }
                              control={control}
                              id={`proj-report-secondary-location-field-${index}`}
                              name={`secondaryLocations.${index}`}
                              testId={`projReportSecondaryLocationField${index}`}
                              label={getFieldLabel(
                                formCodes.fieldSecondaryLocation,
                                ""
                              )}
                              helperText={
                                formFields[formCodes.fieldSecondaryLocation]
                                  ?.helperText
                              }
                              onChangeTrigger={(location) => {
                                validateLocation(
                                  location,
                                  `secondaryLocations.${index}`
                                );
                                handleMapLocationChange(
                                  location?.position,
                                  index,
                                  false
                                );
                              }}
                            />,
                            true
                          )}
                        </Grid>
                        <Grid item>
                          <IconButton
                            id={`proj-report-remove-location-button-${index}`}
                            data-testid={`projReportRemoveLocationButton${index}`}
                            onClick={() => removeSecondaryLocation(index)}
                            className={classes.removeLocationButton}
                            disabled={isPastConstructionEndDate}
                            aria-label={`projReportRemoveLocation${index}`}
                          >
                            <Icon
                              className={clsx(
                                classes.removeLocationIcon,
                                "material-icons-outlined"
                              )}
                            >
                              remove_circle_outline
                            </Icon>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={clsx(
                      secondaryLocations && secondaryLocations?.length % 2
                        ? classes.addLocationButtonContainerLeft
                        : classes.addLocationButtonContainerRight,
                      classes.topMargin
                    )}
                  >
                    {hasProjectsPermission && (
                      <Button
                        data-testid="proj-report-add-location-button"
                        className={classes.addLocationButton}
                        color="primary"
                        disableElevation
                        disabled={isPastConstructionEndDate}
                        onClick={() => addSecondaryLocation()}
                        startIcon={
                          <Icon className="material-icons-outlined">
                            add_circle_outline
                          </Icon>
                        }
                      >
                        <Typography variant="body1">
                          Add additional location (optional)
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    {renderInputWrapper(
                      "proj-report-start-date-field",
                      "projReportStartDateField",
                      formCodes.fieldStartDate,
                      <AmoDatePicker
                        disabled={
                          !hasProjectsPermission ||
                          (!flags[formCodes.fieldStartDate] &&
                            isPastConstructionEndDate)
                        }
                        control={control}
                        id="proj-report-start-date-field"
                        name="startDate"
                        testId="projReportStartDateField"
                        className={clsx(
                          classes.fullWidth,
                          classes.topMargin,
                          flags[formCodes.fieldStartDate]
                            ? classes.warning
                            : null
                        )}
                        label={getFieldLabel(formCodes.fieldStartDate, "")}
                        required
                        variant="inline"
                        // margin="normal"
                        pickerIcon={
                          <Icon className="material-icons-outlined">
                            calendar_today
                          </Icon>
                        }
                        helperText={
                          formFields[formCodes.fieldStartDate]?.helperText
                        }
                      />,
                      true
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {renderInputWrapper(
                      "proj-report-end-construction-field",
                      "projReportEndConstructionField",
                      formCodes.fieldEndConstruction,
                      <AmoDatePicker
                        disabled={
                          !hasProjectsPermission ||
                          (!flags[formCodes.fieldEndConstruction] &&
                            isPastConstructionEndDate)
                        }
                        control={control}
                        id="proj-report-end-construction-field"
                        name="constructionEndDate"
                        testId="projReportEndConstructionField"
                        onBlurCustom={handleEndConstructionFieldBlur}
                        className={clsx(
                          classes.fullWidth,
                          classes.topMargin,
                          flags[formCodes.fieldEndConstruction]
                            ? classes.warning
                            : null
                        )}
                        label={getFieldLabel(
                          formCodes.fieldEndConstruction,
                          ""
                        )}
                        required
                        variant="inline"
                        // margin="normal"
                        pickerIcon={
                          <Icon className="material-icons-outlined">
                            calendar_today
                          </Icon>
                        }
                        helperText={
                          formFields[formCodes.fieldEndConstruction]?.helperText
                        }
                      />,
                      true
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.topMargin}>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    {(!constructionEndDateYear ||
                      constructionEndDateYear > 2015) &&
                      renderInputWrapper(
                        "proj-report-nature-investment-field",
                        "projReportNatureInvestmentField",
                        formCodes.fieldNatureInvestment,
                        <AmoTextField
                          disabled={
                            !hasProjectsPermission ||
                            (!flags[formCodes.fieldNatureInvestment] &&
                              isPastConstructionEndDate)
                          }
                          control={control}
                          id="proj-report-nature-investment-field"
                          name="investmentTypeId"
                          testId="projReportNatureInvestmentField"
                          className={clsx(
                            classes.fullWidth,
                            flags[formCodes.fieldNatureInvestment]
                              ? classes.warning
                              : null
                          )}
                          select
                          label={getFieldLabel(
                            formCodes.fieldNatureInvestment,
                            ""
                          )}
                          variant="outlined"
                          required
                          helperText={
                            formFields[formCodes.fieldNatureInvestment]
                              ?.helperText
                          }
                        >
                          {natureOfInvestmentValues.map((value) => (
                            <MenuItem
                              key={`projReportNatureInvestmentField-${value.id}`}
                              value={value.id}
                            >
                              {value.name}
                            </MenuItem>
                          ))}
                        </AmoTextField>,
                        true
                      )}
                  </Grid>
                  <Grid item xs />
                </Grid>
              </Grid>
              <Grid item>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item>
                {renderInputWrapper(
                  "proj-report-comments-field",
                  "projReportCommentsField",
                  formCodes.fieldComments,
                  <AmoTextField
                    multiline
                    rows={1}
                    rowsMax={10}
                    disabled={
                      !hasProjectsPermission ||
                      (!flags[formCodes.fieldComments] &&
                        isPastConstructionEndDate)
                    }
                    control={control}
                    id="proj-report-comments-field"
                    name="additionalComments"
                    testId="projReportCommentsField"
                    className={clsx(
                      classes.fullWidth,
                      flags[formCodes.fieldComments] ? classes.warning : null
                    )}
                    label={getFieldLabel(formCodes.fieldComments, "")}
                    helperText={formFields[formCodes.fieldComments]?.helperText}
                    characterLimit={4500}
                  />,
                  true
                )}
              </Grid>
            </Grid>
          </Grid>
          {activeStep === steps.generalInformation && (
            <Grid item>
              <AmoFormActions
                className={classes.formActions}
                actions={renderFormActions()}
                spacing="1.25rem"
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

// set the prop-types for this component
ProjectReportGeneralInfoForm.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- Complex object type
  projectResult: PropTypes.object.isRequired,
  refetchProjectData: PropTypes.func.isRequired,
  activeStep: PropTypes.oneOf([
    steps.category,
    steps.generalInformation,
    steps.financials,
    steps.communications,
    steps.results,
  ]).isRequired,
  categoryFormStatus: PropTypes.oneOf([
    formStatuses.untouched,
    formStatuses.completed,
    formStatuses.incomplete,
    formStatuses.flagged,
    formStatuses.disabled,
    formStatuses.changed,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  financialsData: PropTypes.any,
  onFormStatusChange: PropTypes.func,
  onNextStepRequest: PropTypes.func,
  onUnsavedChanges: PropTypes.func,
  onProjectDataChange: PropTypes.func,
  isPastConstructionEndDate: PropTypes.bool,
  constructionEndDateYear: PropTypes.number,
  projectDeletionRequested: PropTypes.bool,
  refetchValidationInfo: PropTypes.func,
};

ProjectReportGeneralInfoForm.defaultProps = {
  financialsData: {},
  onFormStatusChange: () => {},
  onNextStepRequest: () => {},
  onUnsavedChanges: () => {},
  onProjectDataChange: () => {},
  isPastConstructionEndDate: false,
  constructionEndDateYear: 0,
  projectDeletionRequested: true,
  refetchValidationInfo: () => {},
};

export default ProjectReportGeneralInfoForm;
