import {
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import colors from "constants/colors";

const useStyles = makeStyles((theme) => ({
  label: {
    color: colors.black,
    fontFamily: "Roboto",
    marginBottom: "0.75rem",
  },
}));

/**
 * This component is a customized MUI RadioGroup, built to work with a react-hook-form controlled form
 *
 * @param props
 * @param {any} props.children
 * @param {string} props.id                 Field ID
 * @param {string} props.testId             Maps to data-test-id; renamed on AMO component to avoid issues with targeting tests (maybe unnecessary)
 * @param {string} props.name               The name of the input; react-hook-form uses this to register the input
 * @param {object} props.control            The react-hook-form control, generated by useForm()
 * @param {string} props.color              Theme color option
 * @param {boolean} props.disabled          Disables the component when true
 * @param {string} props.label              Label text for the component
 * @param {string} props.labelVariant       Variant for the FormLabel component; see MUI API for FormLabel
 * @param {string} props.className          Passes in a css class
 * @param {object} props.radioGroupClasses  Passes in the MUI classes prop for the RadioGroup component
 * @param {string} props.ariaLabel          aria-label property of the RadioGroup component
 *
 * @returns {Function} A MUI Switch RadioGroup wrapped with the react-hook-form controller
 */
const AmoRadioGroup = ({
  // children are a well-known prop type in React, which has built-in warnings for type mismatches here
  // eslint-disable-next-line react/prop-types
  children,
  id,
  testId,
  name,
  control,
  color,
  disabled,
  label,
  labelVariant,
  className,
  radioGroupClasses,
  ariaLabel,
}) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { value, ref } }) => (
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography variant={labelVariant} className={classes.label}>
              {label}
            </Typography>
          </FormLabel>
          <RadioGroup
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            id={id}
            data-testid={testId ?? `${id}-test`}
            className={className}
            classes={radioGroupClasses}
            aria-label={ariaLabel}
            disabled={disabled}
            color={color}
          >
            {children}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

AmoRadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.string,
  className: PropTypes.string,
  radioGroupClasses: PropTypes.shape(),
  ariaLabel: PropTypes.string,
};

AmoRadioGroup.defaultProps = {
  testId: undefined,
  color: "",
  disabled: false,
  label: undefined,
  labelVariant: "body2",
  className: undefined,
  radioGroupClasses: undefined,
  ariaLabel: undefined,
};

export default AmoRadioGroup;
