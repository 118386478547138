import React, { useState } from "react";
import {
  Icon,
  IconButton,
  DialogTitle,
  DialogActions,
  Box,
  Button,
  Typography,
  DialogContent,
  Popover,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import AmoLocationAutocomplete from "./AmoLocationAutocomplete";

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    // 672px
    width: "42rem",
    // 640px
    maxHeight: "40rem",
    borderRadius: "5px",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    // 512px
    maxHeight: "32rem",
    "& > .leaflet-container": {
      // 360px
      height: "22.5rem",
    },
    overflow: "hidden",
    borderTop: "none",
    borderBottomColor: colors.grey.main,
    // 24px
    padding: "0rem 1.5rem 1.5rem 1.5rem",
  },
  autocompleteBox: {
    padding: "1rem 0rem",
  },
  dialogActions: {
    // 16px
    padding: "1rem",
    borderTop: `1px solid ${colors.grey.main}`,
  },
  dialogActionsSpacing: {
    "& :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  title: {
    // 24px
    fontSize: "1.5rem",
    fontWeight: "500",
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(1),
    color: colors.grey.icon,
  },
  button: {
    // 50px
    height: "3.125rem",
    // 136px
    width: "8.5rem",
  },
}));

/**
 * A modal component to pick a position on a map or autocomplete
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the component [required]
 * @param {string} props.testId - data-testid of the component
 * @param {boolean} props.open - controls whether the popover is open or not
 * @param {string} props.initialAddress - initial address of the autocomplete input
 * @param {object} props.initialPosition - object containing initial position data
 * @param {number} props.initialPosition.lat - latitude of the initial position
 * @param {number} props.initialPosition.lng - longitude of the initial position
 * @param {Function} props.onClose - function called when user clicks on the Close icon (params: none)
 * @param {Function} props.onSelect - function called when user clicks on the 'Select' button (params: { address, position })
 * @param {Function} props.onCancel - function called when user clicks on the 'Cancel' button (params: none)
 * @returns {React.Component} The modal component
 */
const AmoLocationPickerModal = (props) => {
  const {
    id,
    testId,
    open,
    initialAddress,
    initialPosition,
    onSelect,
    onCancel,
    onClose,
  } = props;

  const classes = useStyles();

  // Default center: Ontario
  const defaultCenter = [50.0, -85.0];
  const center = initialPosition
    ? [initialPosition.lat, initialPosition.lng]
    : defaultCenter;

  const [map, setMap] = useState(initialAddress);
  const [address, setAddress] = useState(initialAddress);
  const [position, setPosition] = useState(initialPosition);

  // eslint-disable-next-line react/prop-types
  const MapClickEvent = ({ position: eventPosition }) => {
    useMapEvents({
      click: (event) => {
        const lat = event?.latlng?.lat?.toFixed(5) ?? 0;
        const lng = event?.latlng?.lng?.toFixed(5) ?? 0;
        setPosition({ lat, lng });
        setAddress(`${lat}, ${lng}`);
      },
    });

    return <>{eventPosition ? <Marker position={eventPosition} /> : null}</>;
  };

  const handleAutocompleteChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleAutocompleteSelect = async (newAddress, newPosition) => {
    setAddress(newAddress);
    setPosition(newPosition);
    map.flyTo(newPosition, map.getZoom());
  };

  const handleSelect = () => {
    onSelect(address, position);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Popover
        id={id}
        data-testid={testId ?? `${id}-test`}
        anchorEl={document.body}
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.popoverPaper,
        }}
        getContentAnchorEl={null}
        elevation={4}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
      >
        <DialogTitle
          disableTypography
          className={classes.dialogTitle}
          onClose={handleClose}
        >
          <Typography variant="h6" className={classes.title}>
            Pick Location
          </Typography>
          <IconButton
            data-testid={`${testId ?? `${id}-test`}CloseIcon`}
            aria-label="closeIcon"
            className={classes.closeIcon}
            onClick={handleClose}
          >
            <Icon className="material-icons">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.autocompleteBox}>
            <AmoLocationAutocomplete
              id={`${id}-modal-autocomplete`}
              testId={`${testId || id}ModalAutocomplete`}
              label="Location"
              inputValue={address}
              onInputChange={handleAutocompleteChange}
              onSelect={handleAutocompleteSelect}
              hideAdornment
            />
          </Box>
          <MapContainer
            center={center}
            zoom={position ? 10 : 5}
            scrollWheelZoom
            whenCreated={setMap}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapClickEvent position={position} />
          </MapContainer>
        </DialogContent>
        <DialogActions
          className={classes.dialogActions}
          classes={{ spacing: classes.dialogActionsSpacing }}
        >
          <Button
            data-testid={`${testId ?? id}SaveButton`}
            className={classes.button}
            onClick={handleSelect}
            color="primary"
            variant="contained"
            size="large"
          >
            Select
          </Button>
          <Button
            data-testid={`${testId ?? `${id}-test`}ContinueButton`}
            className={classes.button}
            onClick={handleCancel}
            color="secondary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};

// set the prop-types for this component
AmoLocationPickerModal.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  initialAddress: PropTypes.string,
  initialPosition: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};

AmoLocationPickerModal.defaultProps = {
  testId: undefined,
  initialAddress: "",
  initialPosition: undefined,
  onSelect: () => {},
  onCancel: () => {},
  onClose: () => {},
};

export default AmoLocationPickerModal;
