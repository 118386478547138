import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Grid,
  Box,
} from "@material-ui/core";
import colors from "constants/colors";
import { v4 as uuidV4 } from "uuid";
import { range } from "utils/number";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";
import { useKeyboardNavigation } from "hooks/keyboardNavigationHook";

import AmoTableHeader from "./AmoTableHeader";
import AmoTableFilter from "./AmoTableFilter";
import AmoTablePagination from "./AmoTablePagination";
import AmoTableActions from "./AmoTableActions";
import AmoTableCell from "./AmoTableCell";
import {
  AmoTableProps,
  AmoTableDefaultProps,
} from "constants/amoTablePropTypes";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  tableHeadCell: {
    borderBottom: `1px solid ${colors.grey.main}`,
  },
  tableHeadFilterCell: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
  },
  tableBody: {
    "& .MuiTableRow-root": {
      cursor: "pointer",
    },
  },
}));

/**
 * A table component for displaying any data
 *
 * @param {AmoTableProps} props - object containing props for this component
 *
 * @returns {React.Component} - The table component
 */
const AmoTable = (props) => {
  const {
    id,
    testId,
    columns,
    items,
    defaultOrder,
    defaultOrderBy,
    enableItemClick,
    showFilters,
    rawItems,
    disableFilters,
    onRequestSort,
    onItemClick,
    onFilterChange,
    uniqueKeyGenerationFields,
    shouldUseQueryString,
    onScroll,
    focusedItem,
    actionsProps,
    isLoading,
    hideTableContent,
  } = props;

  const classes = useStyles();
  const tableRef = useRef(null);

  const [page, setPage] = useState(1);

  const [
    sortFields,
    setSortFields,
  ] = useConditionalQueryParams(
    `sortFields_${id}`,
    "object",
    { orderBy: defaultOrderBy, order: defaultOrder },
    shouldUseQueryString,
    (val) => (val ? onRequestSort(val.orderBy, val.order) : null)
  );
  const itemsPerPage = 10;
  const startingIndex = Math.max(0, (page - 1) * itemsPerPage);
  const itemsInPage = items.slice(startingIndex, startingIndex + itemsPerPage);

  const selectedRow = useKeyboardNavigation(
    items,
    page,
    itemsPerPage,
    onScroll,
    focusedItem
  );

  const handleHeaderClick = (fieldKey) => {
    const newOrder =
      fieldKey === sortFields.orderBy && sortFields.order === "asc"
        ? "desc"
        : "asc";
    setSortFields({ orderBy: fieldKey, order: newOrder });
  };

  const {
    titleComponent,
    actions,
    actionsCustomConfigs,
    customActionsComponents,
    showDividers,
  } = actionsProps;

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap">
      <Grid item>
        <AmoTableActions
          tableId={id}
          titleComponent={titleComponent}
          actions={actions}
          actionsCustomConfigs={actionsCustomConfigs}
          customActionsComponents={customActionsComponents}
          disableDefaultActions={isLoading}
          showDividers={showDividers}
        />
      </Grid>
      <Grid item>
        {isLoading &&
          range(0, 9).map((index) => (
            <Skeleton
              key={index}
              width="100%"
              height={["3.6rem", "0.126rem"][index] ?? "3rem"}
            />
          ))}
        {!isLoading && !hideTableContent && (
          <div className={classes.table}>
            <TableContainer style={{ maxWidth: "calc(100vw - 3.438rem)" }}>
              <Table
                ref={tableRef}
                id={id}
                data-testid={testId}
                className={classes.table}
                aria-labelledby="amoTable"
                stickyHeader
                size="medium"
                aria-label="amo table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <AmoTableHeader
                        tableId={id}
                        column={column}
                        showFilters={showFilters && !disableFilters}
                        sortDirection={sortFields.order}
                        isSorted={sortFields.orderBy === column.fieldKey}
                        onHeaderClick={handleHeaderClick}
                        key={`amoTableHeader-${column.fieldKey}`}
                      />
                    ))}
                  </TableRow>
                  {!disableFilters && (
                    <TableRow>
                      {columns.map((cell) => (
                        <AmoTableFilter
                          fieldKey={cell.fieldKey}
                          tableId={id}
                          column={cell}
                          displayType={cell.displayType}
                          label={cell.label}
                          showSearch={!cell.hideSearch && showFilters}
                          className={clsx(
                            !showFilters ? null : classes.tableHeadCell,
                            classes.tableHeadFilterCell
                          )}
                          dropdownOptions={cell.dropdownOptions}
                          shouldUseQueryString={shouldUseQueryString}
                          onFilterChange={onFilterChange}
                          key={`amoTableFilter-${cell.fieldKey}`}
                          items={rawItems}
                        />
                      ))}
                    </TableRow>
                  )}
                </TableHead>
                <TableBody
                  className={enableItemClick ? classes.tableBody : null}
                >
                  {itemsInPage?.map((item, index) => {
                    const uniqueKeyValues = uniqueKeyGenerationFields
                      .map(
                        (field) =>
                          item[field]
                            ?.toString()
                            ?.trim()
                            ?.replace(/\s/g, "_") ?? ""
                      )
                      .filter((key) => !!key);
                    const uniqueKey = uniqueKeyValues.join("-") || uuidV4();
                    return (
                      <TableRow
                        hover={enableItemClick}
                        onClick={() =>
                          enableItemClick ? onItemClick(item) : {}
                        }
                        tabIndex={-1}
                        key={`amoTableRow-${uniqueKey}`}
                        selected={index === selectedRow}
                      >
                        {columns.map((column) => (
                          <AmoTableCell
                            uniqueKey={uniqueKey}
                            item={item}
                            tableId={id}
                            column={column}
                            key={`amoTableRowCell-${uniqueKey}-${column.fieldKey}`}
                          />
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <AmoTablePagination
              itemsLength={items?.length ?? 0}
              tableId={id}
              itemsPerPage={itemsPerPage}
              shouldUseQueryString={shouldUseQueryString}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
            />
            {!items?.length && (
              <Box style={{ textAlign: "center", margin: "1rem" }}>
                No results found
              </Box>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

// set the prop-types for this component
AmoTable.propTypes = AmoTableProps;
AmoTable.defaultProps = AmoTableDefaultProps;

export default AmoTable;
