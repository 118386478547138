import { formIds } from "./formContentManagement";

export const routes = {
  dashboard: "/landing",
  account: "/account",
  resetPassword: "/reset-password",
  logout: "/logout",
  accessDenied: "/access-denied",
  reviews: {
    projects: {
      list: "/reviews/projects/",
      results: "/reviews/projects/results",
      form: "/reviews/projects/:id",
    },
    questionnaires: {
      list: "/reviews/questionnaires",
      form: "/reviews/questionnaires/:municipalityId/:year/:type",
    },
    financial: {
      list: "/reviews/financial/",
      form: "/reviews/financial/:municipalityId/:year",
    },
    bankLimit: "/reviews/bank-limit",
    insurance: {
      list: "/reviews/insurance/",
    },
    reportingStatus: {
      list: "/reviews/reporting-status/",
      form: "/reviews/reporting-status/:id",
    },
    incrementalityMonitoring: {
      list: "/reviews/incrementality-monitoring/",
      form: "/reviews/incrementality-monitoring/:id",
    },
    bankingInformation: {
      list: "/reviews/banking-information/",
    },
    reportQuery: "/reviews/report-query/",
  },
  contentManagement: {
    announcements: {
      list: "/content-management/announcements",
      new: "/content-management/announcements/new",
      edit: "/content-management/announcements/:announcementId",
    },
    forms: {
      list: "/content-management/forms",
      edit: {
        base: "/content-management/forms/",
        projectReportCategory: `/content-management/forms/${formIds.projectReportCategory}`,
        projectReportGeneralInfo: `/content-management/forms/${formIds.projectReportGeneralInfo}`,
        projectReportResults: `/content-management/forms/${formIds.projectReportResults}`,
        projectReportFinancials: `/content-management/forms/${formIds.projectReportFinancials}`,
        projectReportCommunications: `/content-management/forms/${formIds.projectReportCommunications}`,
        insuranceReporting: `/content-management/forms/${formIds.insuranceReporting}`,
        financialReporting: `/content-management/forms/${formIds.financialReporting}`,
        submitReport: `/content-management/forms/${formIds.submitReport}`,
        municipalLandingPage: `/content-management/forms/${formIds.municipalLandingPage}`,
        municipalUserManagement: `/content-management/forms/${formIds.municipalUserManagement}`,
        bankingInformation: `/content-management/forms/${formIds.bankingInformation}`,
      },
    },
    notifications: {
      list: "/content-management/notifications",
      edit: "/content-management/notifications/:notificationId",
    },
    outputsOutcomes: {
      list: "/content-management/outputs-outcomes",
      new: "/content-management/outputs-outcomes/new",
      edit: "/content-management/outputs-outcomes/:outputOutcomeId",
    },
    help: {
      list: "/content-management/help",
      newFAQ: "/content-management/help/faq/new",
      editFAQ: "/content-management/help/faq/:permalink",
      newPage: "/content-management/help/page/new",
      editPage: "/content-management/help/page/:permalink",
    },
    questionnaires: {
      list: "/content-management/questionnaires",
      new: "/content-management/questionnaires/new",
      edit: "/content-management/questionnaires/:questionnaireId",
    },
  },
  help: {
    base: "/help",
    faqs: "/help/faqs",
    faqsByPermalink: "/help/faqs/:permalink",
    page: "/help/:permalink",
  },
  users: {
    base: "/users",
    amo: "/users/amo",
    treasurer: "/users/treasurer",
    municipal: "/users/municipal",
    communication: "/users/communication",
  },
  administration: {
    bankingInformation: "/administration/bankingInformation",
  },
  summaries: {
    questionnaires: "/summaries/questionnaires",
    projects: "/summaries/projects",
    financial: "/summaries/financial",
    results: "/summaries/results",
  },
  dual: {
    // routes that redirect depending on if user is amo or not
    projects: "/projects/:projectId",
    financials: "/financials/:municipalityId/:year",
  },
  communications: {
    projects: {
      list: "/communications/projects",
      details: "/communications/projects/:projectId",
    },
  },
};

const baseRoute = `${process.env.REACT_APP_AUTHORITY}`;

export const apiRoutes = {
  forgotPassword: `${baseRoute}/Account/ForgotPassword?userIdentifier=`,
  resetPassword: `${baseRoute}/Account/UserPasswordReset?userIdentifier=`,
  municipalityList: `${baseRoute}/api/Municipalities`,
  users: {
    list: {
      amo: `${baseRoute}/api/Users/amo`,
      treasurer: `${baseRoute}/api/Users/treasurer`,
      municipal: `${baseRoute}/api/Users/municipal`,
      communication: `${baseRoute}/api/Users/communications`,
    },
    add: `${baseRoute}/Account/Create`,
    edit: `${baseRoute}/Account/Update`,
    get: `${baseRoute}/Account`,
    remove: `${baseRoute}/Account/Remove`,
    checkUsername: `${baseRoute}/Account/UsernameExists`,
    checkEmail: `${baseRoute}/Account/EmailExists`,
    checkDelegate: `${baseRoute}/Account/DelegateExists`,
    checkPrimary: `${baseRoute}/Account/PrimaryContactExists`,
  },
  indicators: {
    getSummaries: `${baseRoute}/api/Indicators/summaries/:reportingYear`,
    getById: `${baseRoute}/api/Indicators/:id`,
    add: `${baseRoute}/api/Indicators/`,
    edit: `${baseRoute}/api/Indicators/:id`,
  },
  announcement: `${baseRoute}/api/Announcements/:id`,
  announcements: `${baseRoute}/api/Announcements`,
};

export const replaceParams = (route, dictionary) =>
  Object.entries(dictionary).reduce(
    (newRoute, [key, value]) => newRoute.replace(`:${key}`, value),
    route
  );
