import React from "react";

import parse from "html-react-parser";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ReportPageWrapper from "components/ReportPageWrapper";
import {
  formIds,
  insuranceReportingCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";

import InsuranceList from "./InsuranceList";

const useStyles = makeStyles(() => ({
  textGrid: {
    position: "relative",
    width: "80%",
    marginLeft: "3.5rem",
  },
}));

/**
 * The insurance reporting page.
 *
 * @returns {React.Component} The page component.
 */
const InsuranceReportingPage = () => {
  const classes = useStyles();
  const { formFields } = useFormManagement(formIds.insuranceReporting);
  return (
    <ReportPageWrapper title="Insurance Reporting">
      <Grid container direction="column">
        <Grid item className={classes.textGrid}>
          <Typography variant="body1" component="span">
            {parse(formFields[formCodes.descriptionForm]?.text || "")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <InsuranceList />
      </Grid>
    </ReportPageWrapper>
  );
};

export default InsuranceReportingPage;
