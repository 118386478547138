export const steps = {
  category: 0,
  generalInformation: 1,
  financials: 2,
  communications: 3,
  results: 4,
};

/**
 * Enum for formStatuses.
 *
 * @enum {number} Form status number
 */
export const formStatuses = {
  untouched: 0,
  completed: 1,
  incomplete: 2,
  flagged: 3,
  disabled: 4,
  changed: 5,
};

export const localRoadsCategoryId = 9;
export const localRoadsSubcategoryId = 22;
