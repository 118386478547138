import { useQuery } from "react-query";
import { userService } from "api/services/userService";

// Query Keys
import { userKeys } from "./userQueryKeys";

/**
 * As a hook, this is easier to mock for tests and to reuse
 * Exporting this component's hooks from a separate, colocated file, emphasises the separation of concerns between server state (react-query) and local state (useState)
 * We should always add query params to the query keys array; this enables cacheing, deduplication, and stale data refreshing
 * The 'enabled' option here tells this query to wait until userIdFromParams exists before fetching data
 * The 'staleTime' option tells react-query to never refresh this server state (we only need it for initial load)
 *
 * @param {string} userId The id of the user being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useMunicipalUserDetails = (userId) => {
  const selectMunicipalUser = (data) => {
    const userData = data?.data;
    return {
      ...data,
      data: {
        ...userData,
        firstName: userData.firstName ?? "",
        lastName: userData.lastName ?? "",
        username: userData.userName ?? "",
        title: userData.title ?? "",
        email: userData.email ?? "",
        // Remove any non-digit character from the phone
        phone: (userData.phoneNumber ?? "").replace(/[^0-9.]+/g, ""),
        phoneExtension: userData.phoneExtension ?? "",
      },
    };
  };

  return useQuery(userKeys.user(userId), () => userService.getUser(userId), {
    select: selectMunicipalUser,
    enabled: !!userId && userId !== "new",
    staleTime: 3000,
    retry: (_, data) => data?.response?.status !== 404,
  });
};

/**
 * Store server's state of delegate status for a user
 *
 * @param {string} userIdToCheckFor               The id of the executing user
 * @param {string} municipalityIdToCheckFor       The municipality id for which to check delgate status
 * @param {boolean} isTreasurerDelegate Delegate status of the selected user
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useGetPreviousDelegate = (
  userIdToCheckFor,
  municipalityIdToCheckFor,
  isTreasurerDelegate
) => {
  const delegateCheckData = {
    municipalityIdToCheckFor,
    userIdToCheckFor,
  };

  return useQuery(
    userKeys.delegateCheck(userIdToCheckFor, municipalityIdToCheckFor),
    () => userService.checkDelegate(delegateCheckData),
    { enabled: isTreasurerDelegate } // if treasurer delegate is turned off, no need to query
  );
};

/**
 * Store server's state of primary contact status for a user
 *
 * @param {string} userIdToCheckFor               The id of the executing user
 * @param {string} municipalityIdToCheckFor       The municipality id for which to check primary contact status
 * @param {boolean} isPrimaryContact Primary contact status of the selected user
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useGetPreviousContact = (
  userIdToCheckFor,
  municipalityIdToCheckFor,
  isPrimaryContact
) => {
  const primaryContactCheckData = {
    municipalityIdToCheckFor,
    userIdToCheckFor,
  };

  return useQuery(
    userKeys.primaryContactCheck(userIdToCheckFor, municipalityIdToCheckFor),
    () => userService.checkPrimaryContact(primaryContactCheckData),
    { enabled: isPrimaryContact }
  );
};
