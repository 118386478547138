import React from "react";
import { Icon, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  validToolTipStyle: {
    backgroundColor: colors.green.main,
    color: "white",
  },
  warningToolTipStyle: {
    backgroundColor: colors.yellow,
    color: "white",
  },
  errorToolTipStyle: {
    backgroundColor: colors.red.main,
    color: "white",
  },
  validIcon: {
    color: colors.green.main,
    cursor: "pointer",
    verticalAlign: "middle",
  },
  warningIcon: {
    color: colors.yellow,
    cursor: "pointer",
    verticalAlign: "middle",
  },
  errorIcon: {
    color: colors.red.main,
    cursor: "pointer",
    verticalAlign: "middle",
  },
}));

/**
 * A flag component for review logs
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.type - the type of flag that is meant to be displayed
 * @param {string} props.message - the message to be displayed in the tooltip associated with the flag
 * @returns {React.Component} The Flag component, styled to AMO requirements
 */
const FlagComponent = (props) => {
  const { type, message } = props;
  const classes = useStyles();

  const renderFlag = () => {
    if (type === "valid") {
      return (
        <Tooltip
          title={message}
          placement="right"
          classes={{
            tooltip: classes.validToolTipStyle,
          }}
        >
          <Icon className={clsx("material-icons-outlined", classes.validIcon)}>
            check_icon
          </Icon>
        </Tooltip>
      );
    }
    if (type === "warning") {
      return (
        <Tooltip
          title={message}
          placement="right"
          classes={{
            tooltip: classes.warningToolTipStyle,
          }}
        >
          <Icon
            className={clsx("material-icons-outlined", classes.warningIcon)}
          >
            priority_high
          </Icon>
        </Tooltip>
      );
    }
    if (type === "error") {
      return (
        <Tooltip
          title={message}
          placement="right"
          classes={{
            tooltip: classes.errorToolTipStyle,
          }}
        >
          <Icon className={clsx("material-icons-outlined", classes.errorIcon)}>
            close
          </Icon>
        </Tooltip>
      );
    }
    return null;
  };

  return renderFlag();
};

FlagComponent.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default FlagComponent;
