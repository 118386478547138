import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { ErrorBoundary, useErrorHandler } from "react-error-boundary";
import GenericErrorPage from "./GenericErrorPage";

const whitelistedErrors = [
  // This specific error can be safely ignored: https://stackoverflow.com/a/50387233
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications.",
];

/**
 * A component to handle global errors
 *
 * @param props
 * @param props.children
 *
 * @returns {React.Component} The page component
 */
// eslint-disable-next-line react/prop-types
const GlobalErrorBoundary = ({ children }) => {
  const handleError = useErrorHandler();

  const handleWindowError = (event) => {
    if (whitelistedErrors.includes(event.message)) return true;
    handleError(event.message);
    return false;
  };

  useEffect(() => {
    /**
     * Global event handler to catch unhandled JS errors
     * Lern more on: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
     */
    window.addEventListener("error", handleWindowError);
    return () => window.removeEventListener("error", handleWindowError);
  }, []);

  return <>{children}</>;
};

/**
 * A component to handle React Component render errors
 *
 * Error boundaries do not catch errors for:
 *   - Event handlers
 *   - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 *   - Server side rendering
 *   - Errors thrown in the error boundary itself (rather than its children)
 * Lern more on: https://reactjs.org/docs/error-boundaries.html
 *
 * @param props
 * @param props.children
 *
 * @returns {React.Component} The page component
 */
// eslint-disable-next-line react/prop-types
const GenericErrorBoundary = ({ children }) => {
  const location = useLocation();

  const [errorPath, setErrorPath] = useState(location.pathname);

  // eslint-disable-next-line react/prop-types
  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    // Effect to reset error boundary on the route changes.
    useLayoutEffect(() => {
      if (location.pathname !== errorPath) {
        resetErrorBoundary();
      }
    }, [location]);

    return (
      <GenericErrorPage
        title="Something’s wrong..."
        description={
          error?.toString() ||
          "The application has encountered an unknown error :("
        }
      />
    );
  };

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={() => setErrorPath(location.pathname)}
      >
        <GlobalErrorBoundary>{children}</GlobalErrorBoundary>
      </ErrorBoundary>
    </>
  );
};

export default GenericErrorBoundary;
