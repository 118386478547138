import { useState, useEffect, useRef } from "react";
import {
  useQueryParam,
  NumberParam,
  StringParam,
  ObjectParam,
} from "use-query-params";

const queryConfig = {
  number: NumberParam,
  string: StringParam,
  object: ObjectParam,
};

/**
 * Hook to conditionally set and save a useState variable in the query string
 *
 * @param {string} name name of variable, used qhen saving to query string
 * @param {string} type type of variable, must match a key in queryConfig
 * @param {*} defaultValue default value of variable
 * @param {boolean} shouldUseQueryString flag on whether value should be saved to query string
 * @param  {Function} callBack function to call when value changes in query string, passes in new value
 * @returns [stateVar, setVar], the variable and the method to set the variable
 */
export const useConditionalQueryParams = (
  name,
  type,
  defaultValue,
  shouldUseQueryString,
  callBack = () => {}
) => {
  if (!(type in queryConfig)) throw new Error("Unknown query config type");

  const [stateVar, setStateVar] = useState(defaultValue);
  const [queryVar, setQueryVar] = useQueryParam(name, queryConfig[type]);
  const shouldUpdateQuery = useRef(false);

  const adjustValue = (val) => {
    if (type === "object") {
      return Object.fromEntries(
        Object.entries(val).filter(
          (item) => item[1] !== null && item[1] !== undefined
        )
      );
    }
    return val;
  };

  const hasDefaultValue =
    type === "object" && defaultValue
      ? Object.keys(defaultValue).length
      : defaultValue;
  // Set the querystring to default value if the query string is not set
  if (shouldUseQueryString && !queryVar && hasDefaultValue)
    setQueryVar(adjustValue(defaultValue), "replaceIn");

  // Compares and returns true if 2 objects are the same, only checks one depth
  const shallowComparator = (obj1, obj2) =>
    !!obj1 &&
    !!obj2 &&
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every((key) => obj1[key] === obj2[key]);

  // Set the state var when the querystring changes
  useEffect(() => {
    const decodedQueryVar =
      type === "object"
        ? JSON.parse(JSON.stringify(queryVar ?? {})?.replace(/%2D/g, "-"))
        : queryVar;
    const varsAreEqual =
      type === "object"
        ? shallowComparator(decodedQueryVar, stateVar)
        : decodedQueryVar === stateVar;
    if (shouldUseQueryString && !varsAreEqual) {
      let newStateValue = decodedQueryVar ?? defaultValue;
      if (type === "object") {
        newStateValue = Object.keys(decodedQueryVar).length
          ? decodedQueryVar
          : defaultValue;
      }

      shouldUpdateQuery.current = false;
      setStateVar(newStateValue);
      callBack(newStateValue);
    }
  }, [queryVar]);

  useEffect(() => {
    if (shouldUseQueryString && shouldUpdateQuery.current) {
      let stateValue;
      if (type === "object") {
        if ("min" in stateVar || "max" in stateVar) {
          stateValue = JSON.parse(JSON.stringify(stateVar));
        } else {
          stateValue = JSON.parse(
            JSON.stringify(stateVar).replace(/-/g, "%2D")
          );
        }
      } else {
        stateValue = stateVar;
      }
      setQueryVar(stateValue, "replaceIn");
    }
  }, [stateVar]);

  // Removes keys from an object that whose values are falsey
  const clearEmptyFields = (obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(
        (item) => !!(item[1] !== null && item[1] !== undefined)
      )
    );

  const setVar = (newValue, callBackData = null) => {
    shouldUpdateQuery.current = true;
    if (type === "object") setStateVar(clearEmptyFields(newValue ?? {}));
    else setStateVar(newValue);
    callBack(newValue, callBackData);
  };

  return [stateVar, setVar];
};
