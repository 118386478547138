import { useMutation } from "react-query";
import axios from "axios";

import { apiRoutes } from "constants/routes";
import { successMessages } from "constants/successMessages";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";

const createPasswordForgotReset = async (email) =>
  (await axios.get(`${apiRoutes.forgotPassword}${encodeURIComponent(email)}`))
    .data;

/**
 * Create password request.
 *
 * @param {object} showSnackbar showSnackbar component
 *
 * @returns {object} The query object return by the useQuery hook
 */

export const useCreatePasswordForgotReset = (showSnackbar) =>
  useMutation(createPasswordForgotReset, {
    onSuccess: () =>
      showSnackbar(successMessages.emailSent, snackbarTypes.success),
    onError: () =>
      showSnackbar(errorMessages.genericAndTryLater, snackbarTypes.error),
  });
