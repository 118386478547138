import { Api } from "../api";
import { AxiosResponse, CancelTokenSource } from "axios";

/**
 * @typedef {{
 *  currentYear: number,
 *  cumulativeTotal: number }} AnnualFinancialReportField
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string }} File
 */

/**
 * @typedef {{
 *  brownfield: AnnualFinancialReportField,
 *  capacityBldg: AnnualFinancialReportField,
 *  commEnergy: AnnualFinancialReportField,
 *  culture: AnnualFinancialReportField,
 *  disaster: AnnualFinancialReportField,
 *  fireStation: AnnualFinancialReportField,
 *  roadsBridges: AnnualFinancialReportField,
 *  airport: AnnualFinancialReportField,
 *  recreation: AnnualFinancialReportField,
 *  shortline: AnnualFinancialReportField,
 *  shortsea: AnnualFinancialReportField,
 *  solidWaste: AnnualFinancialReportField,
 *  sport: AnnualFinancialReportField,
 *  transit: AnnualFinancialReportField,
 *  tourism: AnnualFinancialReportField,
 *  wastewater: AnnualFinancialReportField,
 *  water: AnnualFinancialReportField }} Expenditures
 */

/**
 * @typedef {{
 *  id: number,
 *  openingBalance: number,
 *  receivedFromAMO: AnnualFinancialReportField,
 *  proceedsFromAssetDisposal: AnnualFinancialReportField,
 *  interestEarned: AnnualFinancialReportField,
 *  transfersReceived: AnnualFinancialReportField,
 *  transfersAdministered: AnnualFinancialReportField,
 *  administrationCosts: AnnualFinancialReportField,
 *  expenditures: Expenditures,
 *  file: File,
 *  closingBalance: number }} AnnualFinancialReport
 */

/**
 * @typedef {{
 *  id: number,
 *  proceedsFromDisposal: number,
 *  interestEarned: number,
 *  transfersIn: number,
 *  transfersOut: number,
 *  fileId: number }} AnnualFinancialReportSubmission
 */

/**
 * @typedef {{
 *  municipalityId: number,
 *  municipalityName: string,
 *  year: number,
 *  openBalance: number,
 *  allocationsSum: number,
 *  interestEarned: number,
 *  administrationCosts: number,
 *  proceedsFromDisposal: number,
 *  transferredIn: number,
 *  transferredOut: number,
 *  broadband: number,
 *  brownfield: number,
 *  capacityBldg: number,
 *  commEnergy: number,
 *  culture: number,
 *  disaster: number,
 *  water: number,
 *  fireStation: number,
 *  recreation: number,
 *  airport: number,
 *  roadsBridges: number,
 *  shortline: number,
 *  shortsea: number,
 *  sport: number,
 *  tourism: number,
 *  transit: number,
 *  solidWaste: number,
 *  wastewater: number,
 *  expendituresTotal: number,
 *  closeBalance: number,
 *  reviewStatus: string,
 *  reviewAssignee: string }} AmoQueryFinancial
 */

const makeAPI = (municipalityId, year) =>
  new Api(`api/Municipalities/${municipalityId}/financials/${year}`);

const yearsAPI = new Api("api/Municipalities");

const financialsAPI = new Api("api/Financials");

export const financialsService = {
  /**
   * @param {number} municipalityId
   * @returns {Promise<AxiosResponse<AnnualFinancialReport>>}
   */
  getYears: (municipalityId) =>
    yearsAPI.getById(`${municipalityId}/financials/years`),
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @returns {Promise<AxiosResponse<AnnualFinancialReport>>}
   */
  getByYear: (municipalityId, year) => makeAPI(municipalityId, year).get(),
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @param {AnnualFinancialReportSubmission} payload
   * @returns {Promise<AxiosResponse<AnnualFinancialReport>>}
   */
  postByYear: (municipalityId, year, payload) =>
    makeAPI(municipalityId, year).post(payload),
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @param {AnnualFinancialReportSubmission} payload
   * @returns {Promise<AxiosResponse<AnnualFinancialReport>>}
   */
  putByYear: (municipalityId, year, payload) =>
    makeAPI(municipalityId, year).putWithoutId(payload),
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @param {number} flow
   * @returns {Promise<AxiosResponse<number[]>>}
   */
  getNegativeClosingBalanceYears: (municipalityId, year, flow) =>
    makeAPI(municipalityId, year).getById(`validate/${flow}`),
  /**
   * @param {CancelTokenSource} source
   * @returns {Promise<AxiosResponse<AmoQueryFinancial[]>>}
   */
  getAllFinancialsForExport: (source) =>
    financialsAPI.getById("export", undefined, { cancelToken: source.token }),
};
