import { useQuery } from "react-query";
import { projectListService } from "api/services/projectListService";

// Query Keys
import { projectListQueryKeys } from "./projectListQueryKeys";

/**
 * Store server's state of all projects
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectList = () =>
  useQuery(
    projectListQueryKeys.projectList,
    () => projectListService.getProjects(),
    {
      select: ({ data }) =>
        data.map((project) => ({
          ...project,
          description: [project.scopeOfWork, project.objectives]
            .filter(Boolean)
            .join(" "),
        })),
    }
  );
