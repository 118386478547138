import React, { useState, useLayoutEffect, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import ListPageWrapper from "components/ListPageWrapper";
import AmoPageHeader from "components/AmoPageHeader";
import AmoMap from "components/AmoMap";
import ContactInfoModal from "components/amo/ContactInfoModal";
import AnnouncementLinks from "components/communication/AnnouncementLinks";
import { useLocation, useParams } from "react-router-dom";
import ReviewDataInput from "components/amo/reviewLog/ReviewDataInput";
import HistoryLog from "components/amo/HistoryLog";

import { routes } from "constants/routes";

import MediaGallery from "./MediaGallery";
import ProjectDetailsOverview from "./ProjectDetailsOverview";
import ProjectDetailsFinancials from "./ProjectDetailsFinancials";
import { useProjectDetailsHook } from "./commProjectDetailsHooks";
import { useHistoryLogs } from "components/amo/historyLogHooks";
import { communicationsService } from "api/services/communicationService";
import { useWrapApi } from "hooks/wrapApiHook";
import { useUserContext } from "contexts/UserContext";
import reviewTypes from "constants/reviewTypes";

const useStyles = makeStyles(() => ({
  placeholderSpacingVertical: {
    marginTop: "1.5rem",
  },
  placeholderSpacingHorizontal: {
    marginLeft: "1rem",
  },
  spacer: {
    height: "1.25rem",
  },
  title: {
    fontSize: "1.5rem",
    marginTop: "2.25rem",
    marginBottom: "1rem",
  },
}));

const ProjectDetails = () => {
  const classes = useStyles();
  const { projectId } = useParams();
  const location = useLocation();
  const headerRef = useRef(null);

  const [showContactInformation, setShowContactInformation] = useState(false);

  const {
    data: projectDetails,
    isFetching: projectDetailsIsFetching,
    refetch: refetchProjectDetails,
  } = useProjectDetailsHook(projectId);

  const { refetch: refetchHistoryLogs } = useHistoryLogs(
    reviewTypes.communications,
    projectId
  );

  // refetches data on file uploads/deletes
  const refetchData = (values, checkFiles = false) => {
    if (checkFiles && values.length === projectDetails?.files?.length) {
      return;
    }
    refetchProjectDetails();
  };
  const { user } = useUserContext();

  const headerActions = [
    {
      testId: "commProjectDetailsContactInfo",
      label: "Municipal Contact Info",
      variant: "outlined",
      onClick: () => setShowContactInformation(true),
    },
  ];

  const saveHistory = useWrapApi(
    communicationsService.saveHistory,
    "Successfully updated"
  );

  useLayoutEffect(() => {
    // Added this condition because the unit tests doesn't seem to support the scrollIntoView function
    if (headerRef.current?.scrollIntoView) {
      headerRef.current.scrollIntoView({ block: "center" });
    }
  }, [headerRef?.current]);

  return (
    <ListPageWrapper>
      <Grid
        container
        item
        xs={12}
        direction="column"
        style={{
          paddingBottom: "5rem",
          position: "relative",
          width: "100%",
        }}
      >
        <Grid item xs={12} md={12} ref={headerRef}>
          <AmoPageHeader
            title="Project Details"
            backLinkText="Back to Project List"
            backLinkTo={routes.communications.projects.list}
            actions={headerActions}
            navLinkProp={location.state ?? "Concise"}
          />
        </Grid>
        <Grid
          container
          direction="row"
          item
          style={{ marginTop: "1.5rem" }}
          justifyContent="space-between"
        >
          <Grid item xs={6} md={6}>
            <Grid container direction="column">
              <ProjectDetailsOverview
                projectData={projectDetails}
                isFetching={projectDetailsIsFetching}
              />

              <Typography variant="h4" className={classes.title}>
                Location
              </Typography>
              {projectDetailsIsFetching && (
                <Skeleton width="100%" height="20rem" variant="rect" />
              )}
              {!projectDetailsIsFetching && (
                <AmoMap
                  locations={projectDetails?.locations ?? []}
                  height="20rem"
                  width="100%"
                />
              )}

              <ProjectDetailsFinancials
                projectData={projectDetails}
                isFetching={projectDetailsIsFetching}
              />

              <MediaGallery
                files={projectDetails?.files}
                projectId={projectDetails?.projectId || null}
                links={projectDetails?.links || null}
                isLoaded={!projectDetailsIsFetching}
                refetchData={refetchData}
                onSave={async () => {
                  await saveHistory.call({
                    projectId,
                    statusId: projectDetails.communicationStatusId,
                    comment: "",
                    userId: user.id,
                    editedField: "Media Gallery",
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            md={6}
            spacing={2}
            style={{ marginLeft: "0.5rem" }}
            wrap="wrap-reverse"
            alignContent="flex-end"
          >
            <Grid item xs={12} lg={6}>
              <AnnouncementLinks
                projectId={projectId}
                onSave={async () => {
                  refetchHistoryLogs();
                  refetchProjectDetails();
                }}
              />
            </Grid>
            <Grid item container direction="column" xs={12} lg={6}>
              <HistoryLog
                objectId={projectId}
                type={reviewTypes.communications}
                hideShowChanges
                isCommunicationUser
              />

              <Grid item className={classes.spacer} />

              {projectDetails && !projectDetailsIsFetching && (
                <ReviewDataInput
                  type={reviewTypes.communications}
                  objectId={projectId}
                  projectStatusId={projectDetails?.communicationStatusId}
                  isBusy={projectDetailsIsFetching}
                  onSave={async () => {
                    refetchHistoryLogs();
                    refetchProjectDetails();
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!projectDetailsIsFetching && (
        <ContactInfoModal
          variant="default"
          showModal={showContactInformation}
          closeModalFunction={() => setShowContactInformation(false)}
          title="Contact Information"
          showButton
          municipalityId={projectDetails?.municipalityId ?? -1}
        />
      )}
    </ListPageWrapper>
  );
};

export default ProjectDetails;
