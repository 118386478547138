export const questionnaireAnswerTypes = {
  shortAnswer: {
    value: 1,
    text: "Short Answer",
  },
  singleSelectMultipleChoice: {
    value: 2,
    text: "Single-select multiple choice",
  },
  multiSelectMultipleChoice: {
    value: 3,
    text: "Multi-select multiple choice",
  },
  yesNo: {
    value: 4,
    text: "Yes/No",
  },
  assetManagementPlanUpload: {
    value: 5,
    text: "Asset Management Plan Upload",
  },
  number: {
    value: 6,
    text: "Number",
  },
  date: {
    value: 7,
    text: "Date",
  },
};
