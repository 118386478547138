import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  month: number,
 *  day: number,
 *  thresholdDays: number }} KeyDate
 */

const api = new Api("api/keydates");
export const keyDatesService = {
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<KeyDate>>}
   */
  getById: (id) => api.getById(id),
  /**
   * @param {string} name
   * @returns {Promise<AxiosResponse<KeyDate>>}
   */
  getByName: (name) => api.get({ name }),
  update: (id, payload) => api.put(id, payload),
};
