import { useQuery } from "react-query";
import { projectsQueryKeys } from "./projectsQueryKeys";
import {
  summariesService,
  // Used for JSDocs
  // eslint-disable-next-line import/named, no-unused-vars
  SummaryPayload,
} from "api/services/summaryService";
import { DateTime } from "luxon";
import { dateFormats } from "constants/dateFormats";

/**
 * Store server's state of project results
 *
 * @param {SummaryPayload} payload The summary request payload of the project results being fetched
 *
 * @returns The query object returned by the useQuery hook
 */
export const useProjectsData = (payload) =>
  useQuery(
    projectsQueryKeys.projects(payload),
    () => summariesService.getProjects(payload),
    {
      select: ({ data }) => {
        if (!data || data.length === 0) {
          return [];
        }

        return data.flatMap(
          ({
            internalId,
            startDate,
            constructionEndDate,
            financingEndDate,
            funding,
            scopeOfWork,
            objectives,
            ...project
          }) => {
            let description = "";

            if (scopeOfWork && objectives) {
              description = `${scopeOfWork}\n\n${objectives}`;
            } else if (scopeOfWork) {
              description = scopeOfWork;
            } else if (objectives) {
              description = objectives;
            }

            return {
              ...project,
              funding,
              internalId: internalId ?? "None",
              startDate: startDate
                ? DateTime.fromISO(startDate).toFormat(dateFormats.isoDate)
                : null,
              constructionEndDate: constructionEndDate
                ? DateTime.fromISO(constructionEndDate).toFormat(
                    dateFormats.isoDate
                  )
                : null,
              financingEndDate: financingEndDate
                ? DateTime.fromISO(financingEndDate).toFormat(
                    dateFormats.isoDate
                  )
                : null,
              ...funding?.reduce(
                (fundings, { year, fundingApplied }) => ({
                  ...fundings,
                  [`funding${year}`]: fundingApplied,
                }),
                {}
              ),
              description,
              scopeOfWork,
              objectives,
            };
          }
        );
      },
      enabled: !!Object.keys(payload)?.length,
    }
  );
