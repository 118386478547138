import { useQuery } from "react-query";

import { questionnaireReviewsQueryKeys } from "./questionnaireReviewsQueryKeys";
import { reviewService } from "api/services/reviewsService";

/**
 * Store server's state of questionnaires results
 *
 * @param {number} year
 * @param {string} type
 * @param {boolean} disabled
 * @returns The query object returned by the useQuery hook
 */
export const useQuestionnaireReviews = (year, type, disabled) =>
  useQuery(
    questionnaireReviewsQueryKeys.questionnaireReviews(year, type),
    () => reviewService.getQuestionnaires(year, type),
    {
      select: ({ data: { questions, submissionsList } }) => ({
        questions,
        list:
          submissionsList?.map(
            ({ submittedAnswers, assetManagementPlan, ...submission }) => ({
              ...submission,
              ampFileName: assetManagementPlan?.name,
              ampFileId: assetManagementPlan?.id,
              ampUploadDate: assetManagementPlan?.uploadDateTime,
              ...submittedAnswers.reduce(
                (acc, { answerValue, questionnaireQuestionId }) => ({
                  ...acc,
                  [questionnaireQuestionId]: answerValue,
                }),
                {}
              ),
            })
          ) ?? [],
      }),
      enabled: !!year && !!type && !disabled,
    }
  );
/**
 * Store server's state of questionnaire years results
 *
 * @returns The query object returned by the useQuery hook
 */
export const useQuestionnaireReviewYears = () =>
  useQuery(
    questionnaireReviewsQueryKeys.questionnaireReviewYears,
    () => reviewService.getYears(),
    {
      select: ({ data }) => data.sort((year1, year2) => year2 - year1),
    }
  );
