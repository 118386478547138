import React from "react";
import { Box, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../../../constants/colors";
import AmoPopupMenuItem from "../../AmoPopupMenuItem";
import { projectInterfaceIcons } from "constants/projectInterfaceIcons";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: colors.grey.light,
  },
  menuItem: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
  },
}));

/**
 * A mobile menu component with items for non logged visitors
 *
 * @param {object} props
 * @returns {React.Component} The menu component
 */
const MobileLoggedOutMenu = (props) => {
  const classes = useStyles();
  const { onItemClick } = props;

  return (
    <>
      <Box data-testid="headerLoggedOutMenu">
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to="/login"
            iconName={projectInterfaceIcons.LOGOUT}
            text="Log In"
            testId="headerLoginLink"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to="/help"
            iconName={projectInterfaceIcons.HELP}
            text="Help"
            testId="headerHelpLink"
            onClick={onItemClick}
          />
        </List>
      </Box>
    </>
  );
};

// set the prop-types for this component
MobileLoggedOutMenu.propTypes = {
  onItemClick: PropTypes.func,
};

MobileLoggedOutMenu.defaultProps = {
  onItemClick: () => {},
};

export default MobileLoggedOutMenu;
