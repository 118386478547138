import { useQuery } from "react-query";

import { commonReviewQueryKeys } from "./commonReviewQueryKeys";
import { reviewService } from "api/services/reviewsService";

/**
 * Store server's state of review assignees
 *
 * @returns The query object returned by the useQuery hook
 */
export const useReviewAssignees = () =>
  useQuery(
    commonReviewQueryKeys.reviewAssignees,
    () => reviewService.getAssignees(),
    {
      select: ({ data }) => data,
    }
  );
