import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import colors from "constants/colors";
import AmoPopupMenu from "../AmoPopupMenu";
import AmoPopupMenuItem from "../AmoPopupMenuItem";
import { useNavigationHelper } from "hooks/navigationHook";
import { routes } from "constants/routes";
import { useUserContext } from "contexts/UserContext";
import { roleGroups } from "constants/user";
import { useYearContext } from "contexts/YearContext";
import { projectInterfaceIcons } from "constants/projectInterfaceIcons";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  menuContainer: {
    "& > .MuiGrid-item:last-child": { paddingRight: "0" },
  },
  menuItemSpacing: {
    padding: "0rem 1.75rem",
  },
  menuNavLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  menuLinkPopup: {
    padding: "0rem 1.75rem",
    height: "4.25rem",
  },
  menuLinkPopupActive: {
    color: colors.black,
    backgroundColor: colors.grey.light,
  },
  menuActiveLink: {
    color: colors.black,
    backgroundColor: colors.grey.light,
  },
  menuLinkButton: {
    textDecoration: "none",
  },
  button: {
    width: "14.375rem",
    height: "3.125rem",
  },
}));

/**
 * A menu component with items for municipal users
 *
 * @returns {React.Component} The menu component
 */
const MunicipalMenu = () => {
  const classes = useStyles();
  const {
    handleSamePathNavigation,
    currentPathStartsWith,
  } = useNavigationHelper();
  const { hasRoles } = useUserContext();
  const { contextYear } = useYearContext();
  const hasTreasurerOrDelegatePermission = hasRoles(
    roleGroups.municipal.treasurerOrDelegate
  );

  return (
    <>
      <Grid
        data-testid="headerMunicipalMenu"
        container
        className={clsx(classes.menuContainer, classes.fullHeight)}
        justifyContent="flex-end"
        alignItems="center"
        spacing={0}
      >
        <Grid item className={classes.menuItemSpacing}>
          <NavLink
            data-testid="headerHomeLink"
            exact
            to="/landing"
            onClick={(event) => handleSamePathNavigation(event, "/landing")}
            className={classes.menuNavLink}
            activeClassName={classes.menuActiveLink}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <Typography align="center" variant="subtitle1">
              Home
            </Typography>
          </NavLink>
        </Grid>
        <Grid item>
          <AmoPopupMenu
            id="menu-summ-appbar"
            testId="headerSummMenu"
            anchor="parent"
            title="Summaries"
            classes={{
              button: classes.menuLinkPopup,
              buttonActive: classes.menuLinkPopupActive,
            }}
          >
            <AmoPopupMenuItem
              to={routes.summaries.financial}
              iconName={projectInterfaceIcons.FINANCIALS}
              text="Financials"
              testId="headerSummMenuExp"
            />
            <AmoPopupMenuItem
              to={routes.summaries.projects}
              iconName={projectInterfaceIcons.PROJECTS}
              text="Projects"
              testId="headerSummMenuProj"
            />
            <AmoPopupMenuItem
              to={routes.summaries.results}
              iconName={projectInterfaceIcons.RESULTS}
              text="Results"
              testId="headerSummMenuOut"
            />
            <AmoPopupMenuItem
              to={routes.summaries.questionnaires}
              iconName={projectInterfaceIcons.QUESTIONNAIRES}
              text="Questionnaires"
              testId="headerSummMenuQuest"
            />
          </AmoPopupMenu>
        </Grid>
        <Grid item>
          {hasTreasurerOrDelegatePermission ? (
            <AmoPopupMenu
              id="menu-adm-appbar"
              testId="headerAdmMenu"
              anchor="parent"
              title="Administration"
              classes={{
                button: classes.menuLinkPopup,
                buttonActive: classes.menuLinkPopupActive,
              }}
            >
              <AmoPopupMenuItem
                to="/users"
                iconName={projectInterfaceIcons.USERS}
                text="Users"
                testId="headerAdmMenuUsers"
              />
              <AmoPopupMenuItem
                to={routes.administration.bankingInformation}
                iconName={projectInterfaceIcons.BANKING_INFORMATION}
                text="Banking Information"
                testId="headerAdmMenuBankingInformation"
              />
            </AmoPopupMenu>
          ) : null}
        </Grid>
        <Grid item className={classes.menuItemSpacing}>
          <NavLink
            data-testid="headerHelpLink"
            to="/help"
            onClick={(event) => handleSamePathNavigation(event, "/help")}
            className={classes.menuNavLink}
            activeClassName={classes.menuActiveLink}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <Typography align="center" variant="subtitle1">
              Help
            </Typography>
          </NavLink>
        </Grid>
        <Grid item className={classes.menuItemSpacing}>
          <Button
            data-testid="headerReportButton"
            color="primary"
            variant={
              currentPathStartsWith(`/reporting/${contextYear}`)
                ? "outlined"
                : "contained"
            }
            size="large"
            disableElevation
            className={classes.button}
            startIcon={<Icon className="material-icons-outlined">create</Icon>}
            component={NavLink}
            to={`/reporting/${contextYear}/projects`}
            onClick={(event) =>
              handleSamePathNavigation(
                event,
                `/reporting/${contextYear}/projects`
              )
            }
          >
            <Typography align="center" variant="subtitle1">
              {contextYear} Report
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MunicipalMenu;
