import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  text: string }} Tooltip
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  updateDate: Date,
 *  updatedBy: number }} Form
 */

/**
 * @typedef {{
 *  id: number,
 *  code: string,
 *  type: string,
 *  text: string,
 *  helperText: string,
 *  url: string,
 *  tooltip: Tooltip }} FormField
 */

const api = new Api("api/Forms");
const formFieldApi = new Api("api/Forms/fields");
export const formsService = {
  /**
   * @param {number} formId
   * @returns {Promise<AxiosResponse<FormField[]>>}
   */
  getFormFieldsByFormId: (formId) => api.axios.get(`${formId}/fields`),
  /**
   * @returns {Promise<AxiosResponse<Form[]>>}
   */
  getAll: () => api.get(),
  update: (id, payload) => formFieldApi.put(id, payload),
};
