import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  name: number }} FileResponse
 */

const api = new Api("api/files");
export const filesService = {
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<File>>}
   */
  getById: (id) => api.getFile(id),
  /**
   * @param {number} projectId
   * @returns {Promise<AxiosResponse<File>>}
   */
  getByProjectId: (projectId) => api.getFile(`project/${projectId}`),
  /**
   * @param {number} year
   * @param {number[]} filesIds
   * @returns {Promise<AxiosResponse<File>>}
   */
  getInsurancesByIdsAsync: (year, filesIds) =>
    api.postToReceiveInsuranceFiles(filesIds, `insurances/${year}`),
  /**
   * @param {File} file
   * @param {number} fileAssociationId
   * @param {number} fileAssociationObjectId
   * @param {number} municipalityId
   * @param {number} year
   * @param {Function} onUploadProgress
   * @returns {Promise<AxiosResponse<FileResponse>>}
   */
  uploadFile: (
    file,
    fileAssociationId,
    fileAssociationObjectId,
    municipalityId,
    year,
    onUploadProgress = null
  ) => {
    const formData = new FormData();
    formData.append("year", year);
    formData.append("fileAssociationId", fileAssociationId);
    formData.append("fileAssociationObjectId", fileAssociationObjectId);
    formData.append("municipalityId", municipalityId);
    formData.append("file", file);
    return api.upload(formData, "", onUploadProgress);
  },
  uploadMultipleFiles: (
    files,
    fileAssociationId,
    fileAssociationObjectId,
    municipalityId,
    year,
    onUploadProgress = null
  ) => {
    const formData = new FormData();
    formData.append("year", year);
    formData.append("fileAssociationId", fileAssociationId);
    formData.append("fileAssociationObjectId", fileAssociationObjectId);
    formData.append("municipalityId", municipalityId);
    files?.map((file) => formData.append("files", file));
    return api.upload(formData, "multiple", onUploadProgress);
  },
  remove: (id, fileAssociationObjectId = 0) =>
    api.del(`${id}/${fileAssociationObjectId}`),
  /**
   * @param {number} year
   * @returns {string}
   */
  getQuestionnairesAssetManagementPlansURL: (year) =>
    api.getFile(`questionnaires/year/${year}`),
};
