/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Menu, Link, Icon, IconButton, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { usePopupState } from "material-ui-popup-state/hooks";

const useStyles = makeStyles(() => ({
  menuPopover: {
    pointerEvents: "none",
  },
  menuPaper: {
    minWidth: 180,
    borderRadius: 0,
    pointerEvents: "auto",
  },
  menuIcon: {
    fontSize: "3rem",
  },
}));

/**
 * A popup menu component
 *
 * @param {object} props
 * @returns {React.Component} The popup menu component
 */
const AmoPopupMenu = (props) => {
  const {
    id,
    testId,
    title,
    children,
    classes,
    type,
    anchor,
    anchorRef,
    onOpen,
    onClose,
  } = props;

  const internalClasses = useStyles();

  const popupState = usePopupState({ variant: "popover", popupId: id });

  const handlePopoverOpenChanges = () => {
    if (popupState.isOpen) onOpen();
    else onClose();
  };

  useEffect(handlePopoverOpenChanges, [popupState.isOpen]);

  const handleMouseOver = (event) => {
    let element = event.target;

    if (anchorRef && anchorRef.current) element = anchorRef.current;
    else if (anchor === "parent") element = event.target.parentElement;

    popupState.open(event);
    popupState.setAnchorEl(element);
  };

  const handleKeyPress = (event) => {
    let element = event.target;

    if (event.key === "Enter") {
      if (anchorRef && anchorRef.current) element = anchorRef.current;
      else if (anchor === "parent") element = event.target.parentElement;
      popupState.open(event);
      popupState.isOpen = true;
      popupState.setAnchorEl(element);
    }
  };

  const linkButton = (
    <Link
      aria-controls={popupState.isOpen ? id : undefined}
      aria-haspopup="true"
      onTouchStart={handleMouseOver}
      onMouseOver={handleMouseOver}
      onKeyDown={handleKeyPress}
      onMouseLeave={popupState.onMouseLeave}
      data-testid={`${testId ?? `${id}-test`}Link`}
      className={clsx(
        popupState.isOpen ? classes.buttonActive : null,
        classes.button
      )}
      component="button"
      variant="subtitle1"
      underline="none"
    >
      {title}
    </Link>
  );

  const iconButton = (
    <IconButton
      aria-controls={popupState.isOpen ? id : undefined}
      aria-haspopup="true"
      aria-label="popMenuIcon"
      onTouchStart={handleMouseOver}
      onMouseOver={handleMouseOver}
      onKeyDown={handleKeyPress}
      onMouseLeave={popupState.onMouseLeave}
      data-testid={`${testId ?? `${id}-test`}Button`}
      className={clsx(
        popupState.isOpen ? classes.buttonActive : null,
        classes.button
      )}
    >
      <Icon
        onMouseOver={(event) => event.stopPropagation()}
        className={clsx("material-icons-outlined", internalClasses.menuIcon)}
      >
        {title}
      </Icon>
    </IconButton>
  );

  return (
    <>
      {type === "icon" ? iconButton : linkButton}
      <Menu
        id={popupState.popupId}
        anchorEl={popupState.anchorEl}
        open={popupState.isOpen}
        onClose={popupState.close}
        onClick={popupState.close}
        PaperProps={{ onMouseLeave: popupState.onMouseLeave }}
        data-testid={testId ?? `${id}-test`}
        className={clsx(internalClasses.menuPopover, classes.menu)}
        classes={{
          paper: internalClasses.menuPaper,
        }}
        getContentAnchorEl={null}
        elevation={4}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus
      >
        <MenuItem style={{ top: -999 }} />
        {children}
      </Menu>
    </>
  );
};

// set the prop-types for this component
AmoPopupMenu.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(["link", "icon"]),
  anchor: PropTypes.oneOf(["self", "parent"]),
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.shape() }),
  ]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  classes: PropTypes.shape({
    button: PropTypes.string,
    buttonActive: PropTypes.string,
    menu: PropTypes.string,
  }),
};

AmoPopupMenu.defaultProps = {
  type: "link",
  anchor: "self",
  anchorRef: null,
  testId: null,
  onOpen: () => {},
  onClose: () => {},
  classes: {
    link: null,
    linkActive: null,
    menu: null,
  },
};

export default AmoPopupMenu;
