import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider } from "@material-ui/core";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import AmoTextField from "components/inputs/AmoTextField";
import { useMunicipalContext } from "contexts/MunicipalContext";
import ReviewStatus from "components/amo/ReviewStatus";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FinancialsForm, {
  NumberFormatCustom,
} from "pages/municipal/report/FinancialsForm";
import { financialReportingCodes as fieldCodes } from "constants/formContentManagement";
import AmoFlagInputPopover from "components/amo/AmoFlagInputPopover";
import AmoEditFieldPopover from "components/amo/AmoEditFieldPopover";

import { useIsMounted } from "hooks/useIsMounted";
import { useWrapApi } from "hooks/wrapApiHook";
import { reviewService } from "api/services/reviewsService";

const useStyles = makeStyles((theme) => ({
  body: {
    // 0px 352px 0px 352px
    padding: "0rem 22rem 0rem 22rem",
  },
  title: {
    fontSize: "1.5rem",
    marginBottom: "1.5rem",
  },
  titleSpacing: {
    marginTop: "2.5rem",
  },
  inputWidth: {
    width: "100%",
  },
  overviewItem: {
    marginLeft: "3.75rem",
  },
  label: {
    fontSize: "0.875rem",
  },
  rightOverviewItem: {
    marginLeft: "4.5rem",
  },
  financialStatus: {
    marginLeft: "-1.25rem",
  },
}));

/**
 * This form component to be used on the Financial Review Page.
 *
 * @param {object} props - object containing props for this component
 * @param {object} props.financialData - financial information used by this component
 * @param {Function} props.onDataUpdate - function called when a field or flag is change (param: none)
 * @param {boolean} props.isFetching - whether the is fetching the project data
 *
 * @returns {Function} The form component
 */
const FinancialReviewForm = (props) => {
  const { financialData, onDataUpdate, yearsData, isFetching } = props;

  const classes = useStyles();

  const mounted = useIsMounted();

  const { municipalities } = useMunicipalContext();

  const transformEmptyNumber = (value, originalValue) => {
    if (isNaN(value) && originalValue === "") {
      return null;
    }

    return value;
  };

  const yupNumber = (errorMessage) =>
    yup
      .number()
      .nullable()
      .transform(transformEmptyNumber)
      .typeError(errorMessage);

  const FinancialsFormEditSchema = yup
    .object({
      proceedsFromAssetDisposal: yupNumber(`Value is not a valid number`),
      interestEarned: yupNumber(`Value is not a valid number`),
      transfersReceived: yupNumber(`Value is not a valid number`),
      transfersAdministered: yupNumber(`Value is not a valid number`),
    })
    .required();

  const defaultValues = {
    proceedsFromAssetDisposal: 0,
    interestEarned: 0,
    transfersReceived: 0,
    transfersAdministered: 0,
  };

  const { control, reset, resetField, getValues, formState } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possible onBlur) if very important for validation
    mode: "onChange",
    resolver: yupResolver(FinancialsFormEditSchema),
  });

  const postFlag = useWrapApi(
    reviewService.postFlagFinancials,
    "Successfully added flag"
  );
  const putFlag = useWrapApi(
    reviewService.putFlagFinancials,
    "Successfully updated flag"
  );
  const deleteFlag = useWrapApi(
    reviewService.deleteFlagFinancials,
    "Successfully cleared flag"
  );
  const putField = useWrapApi(
    reviewService.putFinancialsField,
    "Successfully updated field"
  );

  // this effect will make a call to the API to get the data
  useEffect(() => {
    setFormData();
  }, [financialData]);

  const setFormData = () => {
    if (financialData) {
      const formValues = {
        proceedsFromAssetDisposal:
          financialData.proceedsAssetDisposal?.currentYear,
        interestEarned: financialData.interestEarned?.currentYear,
        transfersReceived: financialData.amountsReceived?.currentYear,
        transfersAdministered: financialData.amountsTransfered?.currentYear,
      };
      reset(formValues);
    }
  };

  const handleFieldUpdate = async (fieldName, fieldCode) => {
    const municipalityId = financialData?.municipalityId;
    const year = financialData?.reportingYear;
    const newValue = getValues(fieldName);
    const isDirty = formState.dirtyFields[fieldName];

    if (isDirty) {
      const payload = {
        municipalityId,
        reportingYear: year,
        fieldCode,
        value: newValue,
      };
      const response = await putField.call(
        municipalityId,
        year,
        fieldCode,
        payload
      );

      if (!mounted.current) {
        return;
      }

      if (!response.error) {
        onDataUpdate(response.data);
      }
    }
  };

  const handleSaveFlag = async (flagId, fieldCode, flagText) => {
    const payload = {
      reviewObjectId: financialData?.id,
      flagId,
      fieldCode,
      flagText,
    };

    const response = flagId
      ? await putFlag.call(payload.reviewObjectId, flagId, payload)
      : await postFlag.call(payload.reviewObjectId, payload);

    if (!mounted.current) {
      return;
    }

    if (!response.error) {
      onDataUpdate();
    }
  };
  const handleRemoveFlag = async (flagId) => {
    const response = await deleteFlag.call(financialData?.id, flagId);
    if (!mounted.current) {
      return;
    }
    if (!response.error) {
      onDataUpdate();
    }
  };

  const renderActions = (
    fieldName,
    fieldCode,
    { label = undefined, hideEdit = false, hideFlag = false, flagProps = {} }
  ) => ({
    ...(hideEdit
      ? {}
      : {
          edit: (
            <AmoEditFieldPopover
              id={`${fieldName}-edit-popover`}
              testId={fieldName ? `${fieldName}EditPopover` : undefined}
              onSave={() => handleFieldUpdate(fieldName, fieldCode)}
              onClose={() => resetField(fieldName)}
            >
              <AmoTextField
                control={control}
                id={fieldName}
                name={fieldName}
                className={classes.inputField}
                label={label}
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    isNegative: false,
                  },
                }}
                // Fix for the label not floating properly when prefilling
                InputLabelProps={{ shrink: true }}
              />
            </AmoEditFieldPopover>
          ),
        }),
    ...(hideFlag
      ? {}
      : {
          flag: (
            <AmoFlagInputPopover
              id={`${fieldName}-flag-popover`}
              testId={fieldName ? `${fieldName}FlagPopover` : undefined}
              flagId={flagProps?.flagId}
              ownerName={flagProps?.ownerName}
              lastUpdate={flagProps?.lastUpdate}
              comment={flagProps?.comment}
              onSave={(value) =>
                handleSaveFlag(flagProps?.flagId, fieldCode, value)
              }
              onRemove={() => handleRemoveFlag(flagProps?.flagId)}
              fieldLabel={label}
            />
          ),
        }),
  });

  const fieldActions = {
    openingBalance: renderActions(
      "openingBalance",
      fieldCodes.fldOpeningBalance,
      {
        label: "Opening Balance",
        flagProps: financialData?.openingBalance?.flagProps,
        hideEdit: true,
      }
    ),
    proceedsFromAssetDisposal: renderActions(
      "proceedsFromAssetDisposal",
      fieldCodes.fldProceedDisposalAssets,
      {
        label: "Proceeds from the Disposal of Assets",
        flagProps: financialData?.proceedsAssetDisposal?.flagProps,
      }
    ),
    interestEarned: renderActions(
      "interestEarned",
      fieldCodes.fldInterestEarned,
      {
        label: "Interest Earned",
        flagProps: financialData?.interestEarned?.flagProps,
      }
    ),
    transfersReceived: renderActions(
      "transfersReceived",
      fieldCodes.fldPlusAmountsReceived,
      {
        label: "Amounts Received",
        flagProps: financialData?.amountsReceived?.flagProps,
      }
    ),
    transfersAdministered: renderActions(
      "transfersAdministered",
      fieldCodes.fldLessAmountsTransferred,
      {
        label: "Amounts Transferred",
        flagProps: financialData?.amountsTransfered?.flagProps,
      }
    ),
    closingBalance: renderActions(
      "closingBalance",
      fieldCodes.fldClosingBalance,
      {
        label: "Closing Balance",
        flagProps: financialData?.closingBalance?.flagProps,
        hideEdit: true,
      }
    ),
  };

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={0}
        // className={globalClasses.editPageBodyText}
        wrap="nowrap"
      >
        <Grid item>
          <Typography variant="h4" className={classes.title}>
            Overview
          </Typography>
        </Grid>

        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Grid
              container
              alignItems="flex-end"
              spacing={0}
              className={classes.overviewItem}
            >
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Municipality
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {municipalities[financialData.municipalityId]?.fullName ??
                    "--"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              alignItems="flex-end"
              spacing={0}
              className={classes.rightOverviewItem}
            >
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Assigned To
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {financialData.assigneeName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              alignItems="flex-end"
              className={classes.overviewItem}
            >
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.label}>
                  Status
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.financialStatus}>
                <ReviewStatus
                  id={financialData.statusId}
                  name={financialData.statusName}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "1.5rem", marginBottom: "1rem" }} />

        <Grid item>
          <Typography variant="h4" className={classes.title}>
            {financialData?.reportingYear} Annual Report
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FinancialsForm
            hasPermissions
            reportingYearOverride={financialData?.reportingYear}
            municipalityIdOverride={financialData?.municipalityId}
            fieldActions={fieldActions}
            hideTooltips
            displayOnly
            yearsData={yearsData}
            preloadedData={{
              ...financialData,
              openingBalance: financialData.openingBalance?.currentYear,
              closingBalance: financialData.closingBalance?.currentYear,
              proceedsFromAssetDisposal: financialData.proceedsAssetDisposal,
              receivedFromAMO: financialData.receivedFromAmo,
              transfersReceived: financialData.amountsReceived,
              transfersAdministered: financialData.amountsTransfered,
            }}
            isFinancialReview
            isLoadingPreLoadedData={isFetching}
            onBylawFileChange={onDataUpdate}
          />
        </Grid>
      </Grid>
    </>
  );
};

// set the prop-types for this component
FinancialReviewForm.propTypes = {
  financialData: PropTypes.shape().isRequired,
  onDataUpdate: PropTypes.func,
  yearsData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool,
};

FinancialReviewForm.defaultProps = {
  onDataUpdate: () => {},
  isFetching: false,
};

export default FinancialReviewForm;
