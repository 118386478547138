import { useQuery } from "react-query";
import { annualReportNavBarKeys } from "./annualReportNavBarKeys";
import { annualReportNavBarService } from "api/services/annualReportNavBar";

const { getById } = annualReportNavBarService;

// Get the report summary which determines the information and options displayed in the nav bar
export const useAnnualReportNavBar = (municipalityId) =>
  useQuery(
    annualReportNavBarKeys.summary(municipalityId),
    () => getById(municipalityId),
    { enabled: municipalityId !== 0 }
  );
