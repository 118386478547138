import { reporting } from "constants/reporting";

const placeholders = {
  year: "[Year]",
  reportingYear: "[ReportingYear]",
  municipality: "[Municipality]",
  categories: "[Categories]",
  linkToHelpPage: "[link to help page]",
};

const CheckForPlaceholders = (inputString) => {
  for (const key in placeholders) {
    if (inputString.indexOf(placeholders[key]) !== -1) {
      return true;
    }
  }

  return false;
};

const CheckType = (inputString) => {
  switch (typeof inputString) {
    case "object": {
      return "object";
    }
    case "string": {
      return "string";
    }
    default: {
      return "error";
    }
  }
};

const ReplacePlaceholdersInString = (inputString, municipality) => {
  if (CheckForPlaceholders(inputString)) {
    const year = reporting.calendarYear;

    let returnValue = "";
    // now we replace all the placeholders in the string, and capture the output
    returnValue = inputString.replace(/\[Year\]/g, year.toString());

    if (municipality) {
      returnValue = returnValue.replace(/\[Municipality\]/g, municipality);
    }

    return returnValue;
  }
  return inputString;
};

const escapeRegExp = (string) =>
  string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");

export const ReplaceDynamicPlaceholderInString = (
  inputString,
  dynamicPlaceholder,
  replacementValue
) => {
  const regexEscapedPlaceholder = escapeRegExp(dynamicPlaceholder);
  return inputString.replace(
    new RegExp(regexEscapedPlaceholder, "g"),
    replacementValue.toString()
  );
};
export const ReplaceAllPlaceholdersInString = (
  inputString,
  dynamicPlaceholders,
  dynamicReplacementValues
) => {
  let returnValue = inputString;

  // Replace fixed placeholders
  returnValue = ReplacePlaceholdersInString(inputString);

  if (dynamicPlaceholders.length !== dynamicReplacementValues.length) {
    throw new Error(
      "The placeholders and replacement values length should match"
    );
  }

  // Replace dynamic placeholders
  for (let i = 0; i < dynamicPlaceholders.length; i += 1) {
    returnValue = ReplaceDynamicPlaceholderInString(
      returnValue,
      dynamicPlaceholders[i],
      dynamicReplacementValues[i]
    );
  }

  return returnValue;
};

const ReplacePlaceholdersInObjectHtml = (inputString) => {
  // we have an object, we need to step through the properties for __html
  // eslint-disable-next-line no-underscore-dangle
  if (inputString.__html === undefined) {
    return inputString;
  }
  const returnValue = { ...inputString };
  // eslint-disable-next-line no-underscore-dangle
  returnValue.__html = ReplacePlaceholdersInString(inputString.__html);
  return returnValue;
};

export const ReplacePlaceholders = (inputString) => {
  if (inputString === undefined || inputString === "") {
    return inputString;
  }
  switch (CheckType(inputString)) {
    case "string": {
      return ReplacePlaceholdersInString(inputString);
    }
    case "object": {
      return ReplacePlaceholdersInObjectHtml(inputString);
    }
    default: {
      return inputString;
    }
  }
};

export default placeholders;
