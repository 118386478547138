import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import { routes } from "constants/routes";
import AmoTable from "components/table/AmoTable";
import AmoConfirmPopover from "components/AmoConfirmPopover";
import { getSortingComparator, stableSort } from "utils/data";
import { questionnaireService } from "api/services/questionnaireService";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import Icon from "@material-ui/core/Icon";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import ListPageWrapper from "components/ListPageWrapper";
import { reporting } from "constants/reporting";
import { DateTime } from "luxon";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.black,
  },
  actionIcon: {
    fontSize: "1.5rem",
  },
  inactiveIcon: {
    color: colors.grey.medium,
  },
  truncateContainer: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  truncateField: {
    display: "table-cell",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionRow: {
    width: "12rem",
  },
  yearField: {
    width: "10rem",
  },
  publishedField: {
    width: "8rem",
  },
  rowFont: {
    fontSize: "0.875rem",
  },
  regularTextField: {
    fontWeight: "normal",
    color: colors.black,
  },
  textLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * Questionnaires Management List Page.
 *
 * @returns {React.Component} Questionnaires Management List Page component.
 */
const QuestionnairesManagementListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const globalClasses = useGlobalStyles();
  const { search } = useLocation();
  const history = useHistory();

  const mounted = useIsMounted();

  const [questionnaires, setQuestionnaires] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("yearComponent");
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const tableColumns = [
    {
      fieldKey: "yearComponent",
      label: "Reporting Year",
      minWidth: "11.5rem",
      displayType: displayTypes.component,
    },
    {
      fieldKey: "titleComponent",
      label: "Title",
      minWidth: "16rem",
      customRender: ({ id, title }) => (
        <Link
          data-testid="formContentTableNameLink"
          to={`${routes.contentManagement.questionnaires.edit.replace(
            ":questionnaireId",
            id
          )}${search}`}
          className={globalClasses.tableActionCell}
        >
          <Typography
            className={classes.rowFont}
            color="primary"
            variant="body2"
          >
            {truncateField(title)}
          </Typography>
        </Link>
      ),
      isCustom: true,
    },
    {
      fieldKey: "lastEditedComponent",
      label: "Last Edited",
      minWidth: "16rem",
      displayType: displayTypes.component,
    },
    {
      fieldKey: "publishedComponent",
      label: "Published?",
      minWidth: "9.2rem",
      displayType: displayTypes.component,
    },
    {
      fieldKey: "actions",
      hideLabel: true,
      minWidth: "9.3rem",
      customRender: (item) => {
        const deleteable = item.year > reporting.currentReportingYear;
        return (
          <Grid
            container
            direction="row"
            spacing={2}
            className={classes.actionRow}
          >
            <Grid item>
              <IconButton
                data-testid={`questionnairesTableEditButton${item.id}`}
                component={Link}
                to={`${routes.contentManagement.questionnaires.edit.replace(
                  ":questionnaireId",
                  item.id
                )}${search}`}
              >
                <EditOutlined color="primary" className={classes.actionIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <Link
                data-testid={`questionnairesTableCloneLink${item.id}`}
                to={{
                  pathname: routes.contentManagement.questionnaires.new,
                  state: { cloneId: item.id },
                  search,
                }}
                className={classes.textLink}
              >
                <IconButton
                  data-testid={`questionnairesTableCloneButton${item.id}`}
                >
                  <Icon
                    className={`material-icons-outlined ${classes.actionIcon}`}
                    color="primary"
                  >
                    content_copy_outline
                  </Icon>
                </IconButton>
              </Link>
            </Grid>
            <Grid item>
              <IconButton
                data-testid={`questionnairesTableRemoveButton${item.id}`}
                onClick={() => setItemToDelete(item)}
                disabled={!deleteable}
              >
                <DeleteOutline
                  color="secondary"
                  className={`${classes.actionIcon} ${
                    !deleteable ? classes.inactiveIcon : ""
                  }`}
                />
              </IconButton>
            </Grid>
          </Grid>
        );
      },
      isCustom: true,
    },
  ];

  const getData = async () => {
    try {
      const { data } = await questionnaireService.getAll();

      if (!mounted.current) {
        return;
      }

      const mappedResult = data.map((item) => ({
        ...item,
        yearComponent: (
          <div className={classes.yearField}>{regularTextField(item.year)}</div>
        ),
        titleComponent: renderTitleComponent(item.id, item.title),
        lastEditedComponent: truncateField(regularTextField(item.lastEdited)),
        lastEditedDate: getLastEditedDate(item.lastEdited),
        publishedComponent: (
          <div className={classes.publishedField}>
            {regularTextField(item.isPublished ? "Yes" : "No")}
          </div>
        ),
      }));
      setQuestionnaires(mappedResult);
    } catch {
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }

    setIsLoaded(true);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      // If the data is loaded, sort the forms
      const componentNames = {
        yearComponent: "year",
        titleComponent: "title",
        lastEditedComponent: "lastEditedDate",
        publishedComponent: "isPublished",
      };

      const sorted = stableSort(
        questionnaires,
        getSortingComparator(
          order,
          componentNames[orderBy] ? componentNames[orderBy] : orderBy
        )
      );
      setQuestionnaires(sorted);
    }
  }, [isLoaded, order, orderBy]);

  const truncateField = (text) => (
    <span className={classes.truncateContainer}>
      <span className={classes.truncateField}>{text}</span>
    </span>
  );

  const regularTextField = (text) => (
    <Typography
      className={`${classes.regularTextField} ${classes.rowFont}`}
      color="primary"
      variant="body2"
    >
      {text}
    </Typography>
  );

  const getLastEditedDate = (lastEditedText) => {
    if (!lastEditedText) return null;

    const dateText = lastEditedText.split(" ")[0];
    return DateTime.fromFormat(dateText, "dd/MM/yyyy");
  };

  const onRemove = async (id) => {
    try {
      setItemToDelete(null);
      await questionnaireService.remove(id);

      if (!mounted.current) {
        return;
      }

      showSnackbar("Successfully deleted");
      getData();
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const renderTitleComponent = (id, title) => (
    <Link
      data-testid="formContentTableNameLink"
      to={routes.contentManagement.questionnaires.edit.replace(
        ":questionnaireId",
        id
      )}
      className={globalClasses.tableActionCell}
    >
      <Typography className={classes.rowFont} color="primary" variant="body2">
        {truncateField(title)}
      </Typography>
    </Link>
  );

  const handleRequestSort = (fieldKey, newOrder) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);
  };

  const tableActions = {
    actions: [tableDefaultActions.new],
    actionsCustomConfigs: {
      [tableDefaultActions.new]: {
        label: "New Questionnaire",
        onClick: () => {
          history.push(
            `${routes.contentManagement.questionnaires.new}${search}`
          );
        },
        minWidth: "15.813rem",
      },
    },
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography gutterBottom className={classes.title} variant="h1">
            Questionnaire Management
          </Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        {itemToDelete && (
          <AmoConfirmPopover
            data-testid="questionnaire-delete-popup"
            variant="default"
            open={itemToDelete}
            title="Delete?"
            text={() =>
              `Are you sure you want to delete "${itemToDelete?.title}" for the year ${itemToDelete?.year}?`
            }
            yesButtonFunction={() => onRemove(itemToDelete.id)}
            yesButtonVariant="contained"
            noButtonFunction={() => setItemToDelete(null)}
            closePopover={() => setItemToDelete(null)}
          />
        )}
        <Grid item>
          <AmoTable
            id="questionnaires-table"
            testId="questionnairesTable"
            items={questionnaires}
            columns={tableColumns}
            defaultOrderBy="yearComponent"
            defaultOrder="desc"
            onRequestSort={handleRequestSort}
            shouldUseQueryString
            disableFilters
            actionsProps={tableActions}
          />
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default QuestionnairesManagementListPage;
