import { useMutation } from "react-query";
import axios from "axios";

import { apiRoutes } from "constants/routes";
import { successMessages } from "constants/successMessages";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";

const createUserDetails = async (userData) =>
  (await axios.post(`${apiRoutes.users.add}`, userData)).data;

/**
 * Create a user.
 *
 * @param {object} showSnackbar showSnackbar component
 * @param {Function} navigateBack - navigate back function
 *
 * @returns {object} The query object return by the useQuery hook
 */

export const useCreateUserDetails = (showSnackbar, navigateBack) =>
  useMutation(createUserDetails, {
    onSuccess: () => {
      showSnackbar(successMessages.userInformationSaved, snackbarTypes.success);
      navigateBack();
    },
    onError: () => {
      showSnackbar(errorMessages.genericAndContactAdmin, snackbarTypes.error);
    },
  });

const updateUserDetails = async (userData) =>
  (await axios.put(apiRoutes.users.edit, userData)).data;

/**
 * Update a user.
 *
 * @param {object} showSnackbar showSnackbar component
 * @param {Function} navigateBack - navigate back function
 *
 * @returns {object} The query object return by the useQuery hook
 */

export const useUpdateUserDetails = (showSnackbar, navigateBack) =>
  useMutation(updateUserDetails, {
    onSuccess: () => {
      showSnackbar(
        successMessages.userInformationUpdated,
        snackbarTypes.success
      );
      navigateBack();
    },
    onError: () => {
      showSnackbar(errorMessages.genericAndContactAdmin, snackbarTypes.error);
    },
  });

const deleteUser = async (userId) =>
  (await axios.post(`${apiRoutes.users.remove}/${userId}`)).data;

/**
 * Delete a user.
 *
 * @param {object} showSnackbar showSnackbar component
 * @param {Function} navigateBack - navigate back function
 *
 * @returns {object} The query object return by the useQuery hook
 */

export const useRemoveUser = (showSnackbar, navigateBack) =>
  useMutation(deleteUser, {
    onSuccess: () => {
      showSnackbar(successMessages.userRemoved, snackbarTypes.success);
      navigateBack();
    },
    onError: () =>
      showSnackbar(errorMessages.genericAndTryLater, snackbarTypes.error),
  });

const createPasswordReset = async (emailRecipient) =>
  (
    await axios.get(
      `${apiRoutes.resetPassword}${encodeURIComponent(emailRecipient)}`
    )
  ).data;

/**
 * Create password request.
 *
 * @param {object} showSnackbar showSnackbar component
 *
 * @returns {object} The query object return by the useQuery hook
 */

export const useCreatePasswordReset = (showSnackbar) =>
  useMutation(createPasswordReset, {
    onSuccess: () =>
      showSnackbar(successMessages.emailSent, snackbarTypes.success),
    onError: () =>
      showSnackbar(errorMessages.genericAndTryLater, snackbarTypes.error),
  });
