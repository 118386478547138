import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, Link } from "react-router-dom";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import { useTreasurerUserHooks } from "./hooks/userDataHooks";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useSnackbar } from "contexts/SnackbarContext";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { snackbarTypes } from "constants/snackbar";
import { exportToExcel } from "utils/export";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { errorMessages } from "constants/errorMessages";

/**
 * A table component for displaying Treasurer users
 *
 * @param {object} props
 * @returns {React.Component} The table component
 */
const TreasurerUserTable = (props) => {
  const { id, tableTitleComponent } = props;

  const history = useHistory();
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const { showSnackbar } = useSnackbar();
  const { getMunicipalitiesNames } = useMunicipalContext();

  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const { data: userData, isFetching: dataFetching } = useTreasurerUserHooks();

  const tableColumns = [
    { fieldKey: "imisId", label: "IMIS ID", minWidth: "8rem" },
    {
      fieldKey: "name",
      label: "Name",
      minWidth: "11rem",
      customRender: (user) => (
        <Link
          to={`/users/amo/${user.id}${location.search}`}
          ref={(element) => {
            rowRefs.current[user.id] = element;
          }}
          onFocus={() => setFocusedItem({ id: user.id })}
          className={globalClasses.tableActionCell}
        >
          {user.name}
        </Link>
      ),
      isCustom: true,
    },
    {
      fieldKey: "municipality",
      label: "Municipality",
      minWidth: "11rem",
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    { fieldKey: "email", label: "Email", minWidth: "10rem" },
    { fieldKey: "phone", label: "Phone #", minWidth: "8rem" },
  ];

  const handleItemClick = (user) => {
    history.push(`${`/users/treasurer/${user.id}`}${location.search}`);
  };

  const tableActions = {
    titleComponent: tableTitleComponent,
    actions: [tableDefaultActions.search, tableDefaultActions.export],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            exportToExcel(
              filteredData.current,
              tableColumns,
              "Municipal Treasurers"
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
      },
    },
  };

  return (
    <AmoTableWithFilters
      id={id}
      testId="treasurerUserTable"
      items={dataFetching ? [] : userData}
      columns={tableColumns}
      defaultOrderBy="name"
      enableItemClick
      onItemClick={handleItemClick}
      onFilteredDataChanged={(value) => {
        filteredData.current = value;
      }}
      onScroll={(item) => {
        rowRefs.current?.[item.id]?.focus();
      }}
      focusedItem={focusedItem}
      actionsProps={tableActions}
      isLoading={dataFetching}
    />
  );
};

// set the prop-types for this component
TreasurerUserTable.propTypes = {
  id: PropTypes.string,
  tableTitleComponent: PropTypes.node,
};

TreasurerUserTable.defaultProps = {
  id: "treasurer-user-table",
  tableTitleComponent: null,
};

export default TreasurerUserTable;
