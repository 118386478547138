import { useQuery } from "react-query";
import { landingPageKeys } from "./landingPageQueryKeys";
import { commDashboardService } from "api/services/commDashboardService";

/**
 * Store server's state of project locations based on status
 *
 * @param {number} statusId status id
 * @param {string} earliestDate earliest date to fetch projects (only if status is published)
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectLocations = (statusId, earliestDate) =>
  useQuery(
    landingPageKeys.projectLocations(statusId, earliestDate),
    () => commDashboardService.getProjectLocations(statusId, earliestDate),
    {
      select: ({ data }) => data,
    }
  );
