import { useQuery } from "react-query";
import { userService } from "api/services/userService";

// Query Keys
import { userKeys } from "./userQueryKeys";

/**
 * As a hook, this is easier to mock for tests and to reuse
 * Exporting this component's hooks from a separate, colocated file, emphasises the separation of concerns between server state (react-query) and local state (useState)
 * We should always add query params to the query keys array; this enables cacheing, deduplication, and stale data refreshing
 * The 'enabled' option here tells this query to wait until userIdFromParams exists before fetching data
 * The 'staleTime' option tells react-query to never refresh this server state (we only need it for initial load)
 *
 * @param {string} userId The id of the user being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useCommunicationUserDetails = (userId) => {
  const selectUser = (data) => {
    const userData = data?.data;
    return {
      id: userData?.userId,
      firstName: userData?.firstName ?? "",
      lastName: userData?.lastName ?? "",
      username: userData?.userName ?? "",
      title: userData?.title ?? "",
      organization: userData?.organization ?? "",
      email: userData?.email ?? "",
      phone: userData?.phoneNumber ?? "",
      phoneExtension: userData?.phoneExtension ?? "",
      isEnabled: userData?.enabled,
    };
  };

  return useQuery(userKeys.user(userId), () => userService.getUser(userId), {
    select: selectUser,
    enabled: !!userId && userId !== "new",
    staleTime: 3000,
    retry: (_, data) => data?.response?.status !== 404,
  });
};
