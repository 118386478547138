import { useEffect } from "react";

/**
 * A wrapper for `React.useEffect` for safe async operations.
 *
 * @param {Function} setup
 * Provides a `status` object as an argument to the callback.
 *
 * When `status.aborted` is true, the cleanup function has been called.
 * No state updates should be made if this is true.
 * @param {any[]} [dependencies]
 * @returns {null}
 */
export const useAsyncEffect = (setup, dependencies) =>
  useEffect(() => {
    const status = { aborted: false };

    const result = setup(status);

    return () => {
      status.aborted = true;
      if (typeof result === "function") {
        return result();
      }
      return result;
    };
  }, dependencies);
