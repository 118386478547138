import { useQuery } from "react-query";
import { bankingInformationService } from "api/services/bankingInformationService";

// Query Keys
import { bankingInformationKeys } from "./bankingInformationQueryKeys";

/**
 * Store server's state of banking information
 *
 * @param {number} municipalityId
 * @returns The query object returned by the useQuery hook
 */
export const useBankingInformation = (municipalityId) =>
  useQuery(
    bankingInformationKeys.bankingInformationByMunicipality(municipalityId),
    () => bankingInformationService.getBankingInformation(municipalityId),
    {
      select: ({ data }) => data,
    }
  );
