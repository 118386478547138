import { useQuery } from "react-query";
import { range } from "utils/number";
import { yearsRangeQueryKeys } from "./yearsRangeQueryKeys";
import {
  summariesService,
  // Used for JSDocs
  // eslint-disable-next-line import/named, no-unused-vars
  SummaryType,
} from "api/services/summaryService";

/**
 * Store server's state of projects years range
 *
 * @param {SummaryType} summaryType
 * @returns The query object returned by the useQuery hook
 */
export const useYearsRange = (summaryType) =>
  useQuery(
    yearsRangeQueryKeys.yearsRange,
    () => summariesService.getYearsRange(summaryType),
    {
      select: ({ data }) => range(data.startYear, data.endYear),
      refetchOnWindowFocus: false,
    }
  );

/**
 * Store server's state of projects years range
 *
 * @param {SummaryType} summaryType
 * @returns The query object returned by the useQuery hook
 */
export const useYearsRangeWithProjectCount = (summaryType) =>
  useQuery(
    yearsRangeQueryKeys.yearsRange,
    () => summariesService.getYearsRange(summaryType),
    {
      select: ({ data }) => ({
        years: range(data.startYear, data.endYear),
        noDatesProjectCount: data.noDatesProjectCount,
      }),
      refetchOnWindowFocus: false,
    }
  );
