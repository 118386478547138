import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  bankingInformationCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  body: {
    // 28px
    paddingTop: "1.75rem",
    // 8px
    paddingRight: "0.5rem",
  },
  formField: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        paddingBottom: "0rem",
      },
    },
  },
  formFieldGrid: {
    "& > .MuiGrid-item > .MuiGrid-container > .MuiGrid-item": {
      // 232px
      paddingRight: "14.5rem",
      // 24px
      // paddingLeft: "14.5rem",
      "&:first-child": {
        paddingLeft: "0rem",
      },
      "&:last-child": {
        paddingRight: "0rem",
      },
    },
  },
  dividerTitle: {
    backgroundColor: colors.grey.light,
    // 20px
    marginBottom: "1rem",
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 20px 0px
    margin: "1.25rem 0rem",
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    // 24px
    fontSize: "1.5rem",
    fontWeight: "500",
  },
}));

/**
 * A page component for editing Banking Information form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const BankingInformationEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.bankingInformation
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Banking Information"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
            hideDivider
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(classes.body, globalClasses.editPageBodyPadding)}
          >
            {/* Form Locked Description */}
            <Grid item xs>
              <Grid container direction="column" spacing={0} wrap="nowrap">
                <Grid item>
                  <Typography variant="h4" noWrap className={classes.title}>
                    Main Screen - Locked
                  </Typography>
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionLockedForm] && (
                    <FormFieldWrapper
                      id="bi-form-locked-description"
                      testId="biFormLockedDescription"
                      type={formFields[formCodes.descriptionLockedForm].type}
                      value={formFields[formCodes.descriptionLockedForm].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.descriptionLockedForm]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.dividerTitle} />
            </Grid>
            {/* Form Verification Description */}
            <Grid item xs>
              <Grid container direction="column" spacing={0} wrap="nowrap">
                <Grid item>
                  <Typography variant="h4" noWrap className={classes.title}>
                    Main Screen - Verification Requested
                  </Typography>
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionVerificationForm] && (
                    <FormFieldWrapper
                      id="bi-form-verification-description"
                      testId="biFormVerificationDescription"
                      type={
                        formFields[formCodes.descriptionVerificationForm].type
                      }
                      value={
                        formFields[formCodes.descriptionVerificationForm].text
                      }
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.descriptionVerificationForm]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.dividerTitle} />
            </Grid>
            {/* Form Edit Description */}
            <Grid item xs>
              <Grid container direction="column" spacing={0} wrap="nowrap">
                <Grid item>
                  <Typography variant="h4" noWrap className={classes.title}>
                    Edit Information Screen
                  </Typography>
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionEditForm] && (
                    <FormFieldWrapper
                      id="bi-form-edit-description"
                      testId="biFormEditDescription"
                      type={formFields[formCodes.descriptionEditForm].type}
                      value={formFields[formCodes.descriptionEditForm].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.descriptionEditForm]
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.dividerTitle} />
            </Grid>
            {/* Payment Advice Contact Fields */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(classes.formField, classes.formFieldGrid)}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldContactName] && (
                        <FormFieldWrapper
                          id="bi-contact-name-field"
                          testId="biContactNameField"
                          type={formFields[formCodes.fieldContactName].type}
                          value={formFields[formCodes.fieldContactName].text}
                          className={classes.fullWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldContactName]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldContactEmail] && (
                        <FormFieldWrapper
                          id="bi-contact-email-field"
                          testId="biContactEmailField"
                          type={formFields[formCodes.fieldContactEmail].type}
                          value={formFields[formCodes.fieldContactEmail].text}
                          className={classes.fullWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldContactEmail]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldContactPhone] && (
                        <FormFieldWrapper
                          id="bi-contact-phone-field"
                          testId="biContactPhoneField"
                          type={formFields[formCodes.fieldContactPhone].type}
                          value={formFields[formCodes.fieldContactPhone].text}
                          className={classes.fullWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldContactPhone]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldContactPhoneExtension] && (
                        <FormFieldWrapper
                          id="bi-contact-phone-field"
                          testId="biContactPhoneField"
                          type={
                            formFields[formCodes.fieldContactPhoneExtension]
                              .type
                          }
                          value={
                            formFields[formCodes.fieldContactPhoneExtension]
                              .text
                          }
                          className={classes.fullWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldContactPhoneExtension]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Financial Information Fields */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(classes.formField, classes.formFieldGrid)}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldFinancialInstitution] && (
                        <FormFieldWrapper
                          id="bi-financial-institution-field"
                          testId="biFinancialInstitutionField"
                          type={
                            formFields[formCodes.fieldFinancialInstitution].type
                          }
                          value={
                            formFields[formCodes.fieldFinancialInstitution].text
                          }
                          className={classes.fullWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldFinancialInstitution]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldInstitutionNumber] && (
                        <FormFieldWrapper
                          id="bi-institution-number-field"
                          testId="biInstitutionNumberField"
                          type={
                            formFields[formCodes.fieldInstitutionNumber].type
                          }
                          value={
                            formFields[formCodes.fieldInstitutionNumber].text
                          }
                          className={classes.fullWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldInstitutionNumber]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldTransitNumber] && (
                        <FormFieldWrapper
                          id="bi-transit-number-field"
                          testId="biTransitNumberField"
                          type={formFields[formCodes.fieldTransitNumber].type}
                          value={formFields[formCodes.fieldTransitNumber].text}
                          className={classes.fullWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldTransitNumber]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldAccountNumber] && (
                        <FormFieldWrapper
                          id="bi-account-number-field"
                          testId="biAccountNumberField"
                          type={formFields[formCodes.fieldAccountNumber].type}
                          value={formFields[formCodes.fieldAccountNumber].text}
                          className={classes.fullWidth}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldAccountNumber]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="bi-edit-popover"
        testId="biEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default BankingInformationEditPage;
