import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider } from "@material-ui/core";
import clsx from "clsx";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  projectReportFinancialsCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
  },
  formTitle: {
    // 32px
    paddingLeft: "2rem",
    "& > .MuiGrid-item": {
      "&:last-child": {
        // 20px
        paddingBottom: "1.25rem",
      },
    },
  },
  formField: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        paddingBottom: "0rem",
      },
    },
  },
  formFieldGrid: {
    "& > .MuiGrid-item > .MuiGrid-container > .MuiGrid-item": {
      // 24px
      paddingRight: "1.5rem",
      // 24px
      paddingLeft: "1.5rem",
      "&:first-child": {
        paddingLeft: "0rem",
      },
      "&:last-child": {
        paddingRight: "0rem",
      },
    },
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 24px 0px
    margin: "1.5rem 0rem",
  },
  fullWidth: {
    width: "100%",
  },
  fundsAppliedContainer: {
    // 32px 120px 0px 32px
    margin: "2rem 7.5rem 0rem 2rem",
    border: `1px solid ${colors.grey.main}`,
    borderRadius: "10px",
    padding: "1rem",
  },
}));

/**
 * A page component for editing "Project Reporting: Financials" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const ReportFinancialsEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.projectReportFinancials
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Project Reporting: Financials"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyPadding
            )}
          >
            {/* Form Description */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.descriptionForm] && (
                    <FormFieldWrapper
                      id="prf-form-description"
                      testId="prfFormDescription"
                      type={formFields[formCodes.descriptionForm].type}
                      value={formFields[formCodes.descriptionForm].text}
                      isFullWidth
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionForm])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Financial Information Title */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitle}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.titleInfo] && (
                    <FormFieldWrapper
                      id="prf-information-title"
                      testId="prfInformationTitle"
                      type={formFields[formCodes.titleInfo].type}
                      value={formFields[formCodes.titleInfo].text}
                      textProps={{ variant: "h4" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.titleInfo])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionInfo] && (
                    <FormFieldWrapper
                      id="prf-information-description"
                      testId="prfInformationDescription"
                      type={formFields[formCodes.descriptionInfo].type}
                      value={formFields[formCodes.descriptionInfo].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionInfo])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Financial Information Fields */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(classes.formField, classes.formFieldGrid)}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldFunding] && (
                        <FormFieldWrapper
                          id="prf-ccbf-funding-field"
                          testId="prfCcbfFundingField"
                          type={formFields[formCodes.fieldFunding].type}
                          value={formFields[formCodes.fieldFunding].text}
                          className={classes.fullWidth}
                          inputProps={{
                            required: true,
                          }}
                          onEditClick={() =>
                            handleEditClick(formFields[formCodes.fieldFunding])
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.fundsAppliedContainer}>
              {formFields[formCodes.titleFundsApplied] && (
                <FormFieldWrapper
                  id="prf-ccbf-funds-applied-title"
                  testId="prfCcbfFundsAppliedTitle"
                  type={formFields[formCodes.titleFundsApplied].type}
                  value={formFields[formCodes.titleFundsApplied].text}
                  textProps={{ variant: "h4" }}
                  onEditClick={() =>
                    handleEditClick(formFields[formCodes.titleFundsApplied])
                  }
                />
              )}
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={clsx(classes.formField, classes.formFieldGrid)}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldTotalBudgeted] && (
                        <FormFieldWrapper
                          id="prf-total-ccbf-funds-field"
                          testId="prfTotalCcbfFundsField"
                          type={formFields[formCodes.fieldTotalBudgeted].type}
                          value={formFields[formCodes.fieldTotalBudgeted].text}
                          className={classes.fullWidth}
                          inputProps={{
                            required: true,
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldTotalBudgeted]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldTotalCost] && (
                        <FormFieldWrapper
                          id="prf-total-proj-cost-field"
                          testId="prfTotalProjCostField"
                          type={formFields[formCodes.fieldTotalCost].type}
                          value={formFields[formCodes.fieldTotalCost].text}
                          className={classes.fullWidth}
                          inputProps={{
                            required: true,
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldTotalCost]
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldEndOfFinancing] && (
                        <FormFieldWrapper
                          id="prf-end-financing-field"
                          testId="prfEndFinancingField"
                          type={formFields[formCodes.fieldEndOfFinancing].type}
                          value={formFields[formCodes.fieldEndOfFinancing].text}
                          className={classes.fullWidth}
                          inputProps={{
                            required: true,
                            endIconName: "calendar_today",
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldEndOfFinancing]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldProvincialFunds] && (
                        <FormFieldWrapper
                          id="prf-provincial-funds-field"
                          testId="prfProvincialFundsField"
                          type={formFields[formCodes.fieldProvincialFunds].type}
                          value={
                            formFields[formCodes.fieldProvincialFunds].text
                          }
                          className={classes.fullWidth}
                          inputProps={{
                            required: true,
                            endIconName: "arrow_drop_down",
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldProvincialFunds]
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      {formFields[formCodes.fieldOtherFunds] && (
                        <FormFieldWrapper
                          id="prf-other-funds-field"
                          testId="prfOtherFundsField"
                          type={formFields[formCodes.fieldOtherFunds].type}
                          value={formFields[formCodes.fieldOtherFunds].text}
                          className={classes.fullWidth}
                          inputProps={{
                            required: true,
                            endIconName: "arrow_drop_down",
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldOtherFunds]
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      {formFields[formCodes.fieldStackingLimits] && (
                        <FormFieldWrapper
                          id="prf-stacking-limits-field"
                          testId="prfStackingLimitsField"
                          type={formFields[formCodes.fieldStackingLimits].type}
                          value={formFields[formCodes.fieldStackingLimits].text}
                          className={classes.fullWidth}
                          inputProps={{
                            required: true,
                            endIconName: "arrow_drop_down",
                          }}
                          onEditClick={() =>
                            handleEditClick(
                              formFields[formCodes.fieldStackingLimits]
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Additional Fields */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.fieldComments] && (
                    <FormFieldWrapper
                      id="prf-comments-field"
                      testId="prfCommentsField"
                      type={formFields[formCodes.fieldComments].type}
                      value={formFields[formCodes.fieldComments].text}
                      className={classes.fullWidth}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.fieldComments])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="prgi-edit-popover"
        testId="prgiEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default ReportFinancialsEditPage;
