import React, { useEffect, useState, useRef } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";
import {
  Icon,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemIcon,
  TextField,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import XlsxPopulate from "xlsx-populate";

import { fileTypes } from "constants/fileTypes";
import { roleGroups } from "constants/user";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import {
  projectCategoriesShort,
  projectStatuses,
  projectReportStatuses,
} from "constants/amoTableDropdownOptions";
import {
  signagePostedTexts,
  signagePostedValues,
} from "constants/signagePostedValues";
import {
  signageTypeTexts,
  signageTypeValues,
} from "constants/signageTypeValues";
import {
  signageIntentionsTexts,
  signageIntentionsValues,
} from "constants/signageIntentionsValues";
import {
  signageRationaleTexts,
  signageRationaleValues,
} from "constants/signageRationaleValues";

import { filterData } from "utils/data";
import { range } from "utils/number";

import { useYearContext } from "contexts/YearContext";
import { useUserContext } from "contexts/UserContext";
import { useWrapApi } from "hooks/wrapApiHook";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";
import { useIsMounted } from "hooks/useIsMounted";

import { projectService } from "api/services/projectService";
import { templateService } from "api/services/templateService";

import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import AmoTooltip from "components/AmoTooltip";
import ProjectCategoryIcon from "components/ProjectCategoryIcon";
import NameLink from "components/municipal/NameLink";

import ReportPageWrapper from "components/ReportPageWrapper";

import ProjectUploadModal from "./ProjectUploadModal";
import DeletionModal from "components/DeletionModal";
import {
  deleteButtonTooltipText,
  deleteType,
} from "functions/projectDeleteMunicipalUtils";

const useStyles = makeStyles((theme) => ({
  ellipsisIcon: {
    cursor: "pointer",
    fontSize: "3.2rem",
  },
  menuItemIcon: {
    color: "#000000",
    minWidth: "initial",
    marginRight: "1.125rem",
  },
}));

/**
 * The Project List Page.
 *
 * @returns {React.Component} Project List Page component.
 */
const ProjectListPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { contextYear } = useYearContext();
  const { hasRoles, hasAllRoles } = useUserContext();

  const mounted = useIsMounted();

  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [fileUploadResult, setFileUploadResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUploadData, setIsLoadingUploadData] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [deletionProjectId, setDeletionProjectId] = useState(null);
  const [isPriorExpenditures, setIsPriorExpenditures] = useState(true);
  const [projectReportStatus, setProjectReportStatus] = useState("");

  const isTreasurerOrDelegate = hasRoles(
    roleGroups.municipal.treasurerOrDelegate
  );
  const hasProjectsPermission = hasRoles(roleGroups.municipal.projects);
  const hasAllMunicipalPermissions =
    isTreasurerOrDelegate || hasAllRoles(roleGroups.municipal.all);

  const uploadTemplateFile = useWrapApi(templateService.projectTemplateUpload);

  const projectStatusFilters = {
    all: "All",
    open: "Open",
    planned: "Planned",
    constructionInProgress: "Construction in Progress",
    constructionComplete: "Construction Complete",
    closed: "Closed",
  };

  const reportingStatus = {
    reportingComplete: "Reporting Complete",
    reportingIncomplete: "Reporting Incomplete",
    reportExpenditures: "Report Expenditures",
    reportResults: "Report Results",
    flagsPleaseReview: "Flags: Please review",
  };

  const params = new URLSearchParams(history.location.search);
  const statusParam = params.get("status");
  const initialProjectStatus = statusParam || projectStatusFilters.open;

  const [statusFilter, setStatusFilter] = useConditionalQueryParams(
    "status",
    "string",
    initialProjectStatus,
    true
  );
  const inputFile = useRef(null);

  const customFilterMatch = {
    projectStatus: (item, filter) =>
      !filter ||
      filter === projectStatusFilters.all.toLowerCase() ||
      (filter === projectStatusFilters.open.toLowerCase() &&
        item !== projectStatusFilters.closed.toLowerCase()) ||
      item === filter,
  };

  const filterProjectsByStatus = (projectStatus) => {
    setFilteredProjects([
      ...filterData(projects, { projectStatus }, customFilterMatch),
    ]);
  };

  const isComplete = (reportStatus) =>
    [reportingStatus.reportingComplete].includes(reportStatus);

  const tableColumns = [
    {
      fieldKey: "id",
      label: "AMO ID",
      minWidth: "7.8rem",
    },
    {
      fieldKey: "municipalityInternalId",
      label: "Internal ID",
      minWidth: "9rem",
    },
    {
      fieldKey: "categoryName",
      label: "Category",
      horizontalAlign: "center",
      minWidth: "9.6rem",
      customRender: ({ categoryName, category }) => (
        <ProjectCategoryIcon name={categoryName} code={category} />
      ),
      isCustom: true,
      dropdownOptions: projectCategoriesShort,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "projectNameComponent",
      label: "Project Name",
      minWidth: "11rem",
      displayType: displayTypes.component,
    },
    {
      fieldKey: "projectStatus",
      label: "Project Status",
      minWidth: "11rem",
      dropdownOptions: projectStatuses,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "reportStatus",
      label: "Report Status",
      color: ({ reportStatus }) =>
        isComplete(reportStatus) ? "initial" : "secondary",
      variant: ({ reportStatus }) =>
        isComplete(reportStatus) ? "body1" : "body2",
      minWidth: "10.5rem",
      dropdownOptions: projectReportStatuses,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "actions",
      hideHeader: true,
      hideSearch: true,
      minWidth: "8rem",
      displayType: displayTypes.component,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    filterProjectsByStatus(statusFilter);
  }, [projects, statusFilter]);

  const getData = async () => {
    const { data } = await projectService.getAll();

    if (!mounted.current) {
      return;
    }

    const mappedResult = data.map((item) => {
      const title = item.title ?? "Untitled Project";
      return {
        ...item,
        title,
        projectNameComponent: (
          <NameLink
            name={title}
            link={`/reporting/${contextYear}/projects/${item.id}`}
          />
        ),
        actions: renderActions(
          item.id,
          item.reportStatus,
          item.hasPriorExpenditures
        ),
      };
    });

    setProjects(mappedResult);
    setIsLoading(false);
  };

  const deleteModalClose = () => {
    setShowDeletionModal(false);
    setDeletionProjectId(null);
    setIsPriorExpenditures(true);
    setProjectReportStatus("");
  };

  const isDeletionRequested = (reportStatus) =>
    reportStatus === "Deletion Requested";

  const deleteButton = (id, reportStatus, hasPriorExpenditures) => (
    <IconButton
      onClick={() => {
        setShowDeletionModal(true);
        setDeletionProjectId(id);
        setIsPriorExpenditures(hasPriorExpenditures);
        setProjectReportStatus(reportStatus);
      }}
      disabled={!isTreasurerOrDelegate || isDeletionRequested(reportStatus)}
    >
      <Icon
        color={
          !isTreasurerOrDelegate || isDeletionRequested(reportStatus)
            ? "initial"
            : "secondary"
        }
        className={clsx("material-icons-outlined", classes.menuIcon)}
      >
        delete
      </Icon>
    </IconButton>
  );

  const renderActions = (id, reportStatus, hasPriorExpenditures) => (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onDownload([id]);
          }}
        >
          <Icon
            color="primary"
            className={clsx("material-icons-outlined", classes.menuIcon)}
          >
            file_download
          </Icon>
        </IconButton>
      </Grid>
      <Grid item>
        {!isTreasurerOrDelegate || isDeletionRequested(reportStatus) ? (
          <AmoTooltip
            tooltipText={deleteButtonTooltipText(
              isTreasurerOrDelegate,
              hasPriorExpenditures,
              reportStatus
            )}
            customComponent={deleteButton(
              id,
              reportStatus,
              hasPriorExpenditures
            )}
          />
        ) : (
          deleteButton(id, reportStatus, hasPriorExpenditures)
        )}
      </Grid>
    </Grid>
  );

  const downloadTemplate = useWrapApi(projectService.downloadTemplate);
  const getTemplateFile = useWrapApi(templateService.getTemplateFile);
  /**
   * Downloads projects template file based on given ids
   *
   * @param {number[]} projectIds
   */
  const onDownload = async (projectIds) => {
    const {
      data: { data: projectsData },
    } = await downloadTemplate.call(projectIds);

    if (!mounted.current) {
      return;
    }

    // first we get the file from the server
    const {
      data: { data: fileData },
    } = await getTemplateFile.call();

    if (!mounted.current) {
      return;
    }

    const yesNoObj = { [true]: "Yes", [false]: "No" };

    const workbook = await XlsxPopulate.fromDataAsync(fileData);

    if (!mounted.current) {
      return;
    }

    // get the sheet we'll be populating
    const worksheet = workbook.sheet("Projects");

    // use the data on hand to populate the sheet
    projectsData.forEach((project, i) => {
      // the starting row is 4

      const rowIndex = i + 2;
      const cellData = [
        { cell: "A", data: project.id },
        { cell: "B", data: project.municipalityInternalId },
        { cell: "C", data: project.title },
        { cell: "D", data: project.scopeOfWork },
        { cell: "E", data: project.objectives },
        {
          cell: "F",
          data: project.subcategory
            ? `${project.category} - ${project.subcategory}`
            : project.category,
        },
        { cell: "G", data: project.investmentType },
        { cell: "H", data: project.latitude },
        { cell: "I", data: project.longitude },
        { cell: "J", data: project.startDate, isDate: true },
        { cell: "K", data: project.constructionEndDate, isDate: true },
        { cell: "L", data: project.financingEndDate, isDate: true },
        { cell: "M", data: project.totalCost },
        { cell: "N", data: project.totalBudgeted },
        { cell: "O", data: project.ccbfAllocation },
        { cell: "P", data: project.ccbfPreviously },
        { cell: "R", data: yesNoObj[project.otherFederalFunds] ?? null },
        { cell: "S", data: yesNoObj[project.provincialFunds] ?? null },
        { cell: "T", data: yesNoObj[project.stackingLimits] ?? null },
        { cell: "U", data: signagePostedTexts[project.signagePosted] ?? null },
        { cell: "V", data: signageTypeTexts[project.signageType] ?? null },
        {
          cell: "W",
          data: signageIntentionsTexts[project.signageIntentions] ?? null,
        },
        {
          cell: "X",
          data: signageRationaleTexts[project.signageRationale] ?? null,
        },
        { cell: "Y", data: project.communicationsActivities },
        { cell: "Z", data: project.additionalComments },
      ];
      cellData.forEach(({ cell, data, isDate }) => {
        const cellRef = worksheet.cell(`${cell}${rowIndex}`);
        if (data != null) {
          if (isDate) {
            const dateTime = DateTime.fromISO(data).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            cellRef.value(dateTime.toJSDate());
          } else {
            cellRef.value(data);
          }
        }
      });
    });

    // now send the updated workbook to the user
    workbook.outputAsync().then((data) => {
      const xlsxName = `AMO - Project Report - ${contextYear}.xlsx`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, xlsxName);
      } else {
        const anchor = document.createElement("a");
        const url = window.URL.createObjectURL(data);
        document.body.appendChild(anchor);
        anchor.href = url;
        anchor.download = xlsxName;
        anchor.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
      }
    });
  };

  const [ellipsisAnchorEl, setEllipsisAnchorEl] = useState(null);
  const ellipsisOnClick = (event) => setEllipsisAnchorEl(event.currentTarget);
  const ellipsisOnClose = () => setEllipsisAnchorEl(null);

  const handleFileUpload = async (event) => {
    event.preventDefault();
    setIsLoadingUploadData(true);
    setShowFileUploadModal(true);
    const { files } = event.target;
    const file = files[0];
    const workbook = await XlsxPopulate.fromDataAsync(file);

    if (!mounted.current) {
      return;
    }

    const worksheet = workbook.sheet("Projects");
    const index = 2;

    const projectsPayload = [];

    const usedRange = worksheet.usedRange();
    const highestRowNum = usedRange.endCell().rowNumber();

    for (let i = index; i <= highestRowNum; i += 1) {
      // cycle the rows and output the content
      const currentRow = worksheet.row(i);

      // Break the loop on the first completely empty row
      if (
        range(1, 27).every((cellNumber) => !currentRow.cell(cellNumber).value())
      ) {
        break;
      }

      const categoryCell = currentRow.cell(6).value()?.toString();
      const categorySplit = categoryCell?.split(" - ") ?? [];

      const yesNoObj = { yes: true, no: false };
      const otherFederalFundsValue = currentRow
        .cell(18)
        .value()
        ?.toString()
        ?.toLowerCase();
      const provincialFundsValue = currentRow
        .cell(19)
        .value()
        ?.toString()
        ?.toLowerCase();
      const stackingLimitsValue = currentRow
        .cell(20)
        .value()
        ?.toString()
        ?.toLowerCase();

      const project = {
        amoId: currentRow.cell(1).value() ?? 0,
        internalId: currentRow.cell(2).value()?.toString(),
        title: currentRow.cell(3).value()?.toString(),
        scopeOfWork: currentRow.cell(4).value()?.toString(),
        objectives: currentRow.cell(5).value()?.toString(),
        category: categorySplit[0],
        subcategory: categorySplit[1],
        natureOfInvestment: currentRow.cell(7).value()?.toString(),
        location: {
          latitude: currentRow.cell(8).value(),
          longitude: currentRow.cell(9).value(),
        },
        startDate: currentRow.cell(10).value()
          ? XlsxPopulate.numberToDate(currentRow.cell(10).value())
          : null,
        endOfConstructionDate: currentRow.cell(11).value()
          ? XlsxPopulate.numberToDate(currentRow.cell(11).value())
          : null,
        endOfFinancingDate: currentRow.cell(12).value()
          ? XlsxPopulate.numberToDate(currentRow.cell(12).value())
          : null,
        totalCost: currentRow.cell(13).value(),
        ccbfBudget: currentRow.cell(14).value(),
        ccbfAllocatedInYear: currentRow.cell(15).value(),
        otherFederalFunds: yesNoObj[otherFederalFundsValue] ?? null,
        provincialFunds: yesNoObj[provincialFundsValue] ?? null,
        stackingLimits: yesNoObj[stackingLimitsValue] ?? null,
        signagePosted: signagePostedValues[currentRow.cell(21).value()] ?? null,
        signageType: signageTypeValues[currentRow.cell(22).value()] ?? null,
        signageIntentions:
          signageIntentionsValues[currentRow.cell(23).value()] ?? null,
        signageRationale:
          signageRationaleValues[currentRow.cell(24).value()] ?? null,
        links: currentRow.cell(25).value()?.toString(),
        comments: currentRow.cell(26).value()?.toString(),
      };

      projectsPayload.push(project);
    }

    if (projectsPayload.length > 0) {
      const response = await uploadTemplateFile.call(projectsPayload);

      if (!mounted.current) {
        return;
      }

      if (response && !response.error) {
        setFileUploadResult(response.data?.data ?? []);

        // Update the project list
        getData();
      }
    }
    setIsLoadingUploadData(false);

    // clear file input value
    inputFile.current.value = "";
  };

  const statusDropdown = (
    <TextField
      variant="outlined"
      value={statusFilter}
      onChange={(event) => {
        const newStatus = event.target.value;
        setStatusFilter(newStatus);
      }}
      select
      SelectProps={{
        renderValue: (value) => `${value} (${filteredProjects.length})`,
      }}
      disabled={isLoading}
    >
      {Object.values(projectStatusFilters).map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </TextField>
  );

  const customExportButton = (
    <IconButton style={{ padding: 0 }} onClick={ellipsisOnClick}>
      <Icon color="primary" className={classes.ellipsisIcon}>
        more_vert
      </Icon>
    </IconButton>
  );

  const tableActions = {
    titleComponent: statusDropdown,
    actions: [
      tableDefaultActions.new,
      tableDefaultActions.search,
      "customExport",
    ],
    actionsCustomConfigs: {
      [tableDefaultActions.new]: {
        label: "New Project",
        onClick: () => {
          history.push(`/reporting/${contextYear}/projects/new`);
        },
        disabled: !isTreasurerOrDelegate && !hasProjectsPermission,
        toolTipText:
          "Additional permissions are required to create a new project",
      },
    },
    customActionsComponents: {
      customExport: customExportButton,
    },
  };

  const uploadMenuComponent = (
    <MenuItem
      onClick={() => {
        if (hasAllMunicipalPermissions) {
          ellipsisOnClose();
          inputFile.current?.click();
        }
      }}
      // TODO: re-enable when Jordan has tested project upload
      disabled
      // disabled={!hasAllMunicipalPermissions}
    >
      <>
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon className="material-icons-outlined">upload_file</Icon>
        </ListItemIcon>
        <Typography variant="body1">Upload Project(s) from .xlsx</Typography>
      </>
    </MenuItem>
  );

  return (
    <ReportPageWrapper title="Project List">
      <input
        type="file"
        id="project-template-upload-input"
        ref={inputFile}
        style={{ display: "none" }}
        onInput={(event) => handleFileUpload(event)}
        accept={`.${fileTypes.xlsx}`}
      />
      <ProjectUploadModal
        open={showFileUploadModal}
        onClose={() => setShowFileUploadModal(false)}
        uploadResultData={fileUploadResult}
        isLoading={isLoadingUploadData}
      />
      <DeletionModal
        open={showDeletionModal}
        id={deletionProjectId}
        closeFunction={deleteModalClose}
        updateFunction={() => getData()}
        deletionType={deleteType(
          isTreasurerOrDelegate,
          isPriorExpenditures,
          projectReportStatus
        )}
      />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <AmoTableWithFilters
            id="projects-table"
            testId="projectsTable"
            items={filteredProjects}
            columns={tableColumns}
            defaultOrderBy="id"
            defaultOrder="desc"
            actionsProps={tableActions}
            isLoading={isLoading}
            mappedFieldKeys={{
              projectNameComponent: "title",
            }}
            mappedFilterMatchs={customFilterMatch}
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={ellipsisAnchorEl}
        open={!!ellipsisAnchorEl}
        onClose={ellipsisOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {hasAllMunicipalPermissions ? (
          // TODO: re-enable when Jordan has tested project upload
          <AmoTooltip
            tooltipText="Project upload has been temporarily disabled"
            customComponent={uploadMenuComponent}
          />
        ) : (
          <AmoTooltip
            tooltipText="Additional permissions are required to upload project data"
            customComponent={uploadMenuComponent}
          />
        )}
        <MenuItem
          onClick={() => {
            ellipsisOnClose();
            onDownload(
              projects
                .filter(
                  ({ projectStatus }) =>
                    projectStatus !== projectStatusFilters.closed
                )
                .map(({ id }) => id)
            );
          }}
          disabled={isLoading}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon className="material-icons-outlined">file_download</Icon>
          </ListItemIcon>
          <Typography variant="body1">Download Open Project Data</Typography>
        </MenuItem>
      </Menu>
    </ReportPageWrapper>
  );
};

export default ProjectListPage;
