import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FileUpload from "./FileUpload";
import {
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Icon,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { fileTypes } from "constants/fileTypes";
import { filesService } from "api/services/fileService";
import { errorMessages } from "constants/errorMessages";
import { snackbarTypes } from "constants/snackbar";
import { useSnackbar } from "contexts/SnackbarContext";
import clsx from "clsx";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";
import colors from "constants/colors";
import parse from "html-react-parser";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  fileRow: {
    backgroundColor: "#F1F1F1",
    height: "4rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    overflowX: "hidden",
    textOverflow: "ellipses",
  },
  fileRowTransparent: {
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    overflowX: "hidden",
  },
  fileIcon: {
    verticalAlign: "bottom",
    marginRight: "0.625rem",
  },
  button: {
    width: "100%",

    "& .MuiButton-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "left",
      display: "block",
    },
  },
  progressText: {
    fontWeight: "500",
    color: colors.grey.dark,
  },
  progressNameText: {
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  progressPercText: {
    fontSize: "0.875rem",
  },
}));

/**
 * Type definition for component props.
 *
 * @typedef {object} FileUploadProps
 * @property {boolean} multiple - Sets whether multiple files are allowed or not.
 * @property {Function} onChange - The change handler.
 * @property {Array} allowedTypes - Sets alowed file types for the FileUpload component.
 * @property {boolean} showAllowedTypesHint - Display state for types hint.
 * @property {number} fileAssociationId - ID of the associated file.
 * @property {number} fileAssociationObjectId - ID of the associated file object.
 * @property {number} municipalityId - ID of the municipality associated to the file object.
 * @property {number} fileYear - The year of the file.
 * @property {Array} initialValue - Initial allowed types.
 * @property {string} title - The title of the file list.
 * @property {string} instructions - User instructions.
 * @property {boolean} readonly - Will prevent the user from modifying the file list if true.
 * @property {boolean} transparent - Will make the background transparent remove the fixed height.
 * @property {Function} onUpload - Function called after files are uploaded
 * @property {Function} onDelete - Function called after files are deleted
 * @property {boolean} noWrap - prop that allows you to cut off overflowing or intersecting componets
 * @property {boolean} synchronized - Sets whether the file upload is synchronized or not.
 * @property {string} uploadAction - indicates the upload action to execute (Idle, Rollback)
 * @property {Function} onRollback - function triggered after upload is reverted
 * @property {boolean} showComponent - Sets whether the file upload component is displayed or not.
 */

/**
 * File upload list.
 *
 * @param {FileUploadProps} props
 *
 * @returns {React.Component} The FileUploadList component.
 */
const FileUploadList = (props) => {
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const mounted = useIsMounted();
  const {
    multiple,
    onChange,
    allowedTypes,
    showAllowedTypesHint,
    fileAssociationId,
    fileAssociationObjectId,
    municipalityId,
    fileYear,
    initialValue,
    title,
    instructions,
    readonly,
    transparent,
    onUpload,
    onDelete,
    noWrap,
    synchronized,
    uploadAction,
    onRollback,
    showComponent,
  } = props;
  const [files, setFiles] = useState(initialValue);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uncommittedFiles, setUncommittedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const defaultUploadStatus = {
    progress: 0,
    file: null,
    current: 0,
    total: 0,
  };
  const [uploadStatus, setUploadStatus] = useState(defaultUploadStatus);

  const uploadFiles = async (newFiles) => {
    if (!newFiles?.length) return [];

    const uploadStartTime = new Date().getTime();
    setIsUploading(true);
    let responses = [];

    const getCurrentUploadingFileInfo = (loadedProgress) => {
      let currentSizeSum = 0;

      for (let i = 0; i < newFiles.length; i += 1) {
        const file = newFiles[i];
        currentSizeSum += file.size;
        if (currentSizeSum >= loadedProgress) {
          const uploadedFileSize =
            loadedProgress - (currentSizeSum - file.size);
          return { position: i + 1, file, uploadedFileSize };
        }
      }

      const uploadingFile = newFiles[newFiles.length - 1];
      return {
        position: newFiles.length,
        file: uploadingFile,
        uploadedFileSize: uploadingFile.size,
      };
    };

    const calculateUploadProgress = (loaded, total) => {
      const progress = (loaded / total) * 100;

      const uploadTimeInSeconds =
        (new Date().getTime() - uploadStartTime) / 1000;
      const uploadSpeed = loaded / 1000 / uploadTimeInSeconds;
      const kilobytesRemaining = (total - loaded) / 1000;
      const secondsLeft = Math.ceil(kilobytesRemaining / uploadSpeed);

      return {
        progress,
        secondsLeft,
      };
    };

    const onFileUpload = (progressEvent) => {
      const { loaded, total } = progressEvent;

      const { progress, secondsLeft } = calculateUploadProgress(loaded, total);
      const { file, position, uploadedFileSize } = getCurrentUploadingFileInfo(
        loaded
      );

      setUploadStatus((prevUploadStatus) => ({
        ...prevUploadStatus,
        progress,
        file,
        current: position,
        total: newFiles.length,
        uploadedFileSize,
        secondsLeft,
      }));
    };

    const onSingleFileUpload = (progressEvent, file) => {
      const { loaded, total } = progressEvent;

      const { progress, secondsLeft } = calculateUploadProgress(loaded, total);

      setUploadStatus((prevUploadStatus) => ({
        ...prevUploadStatus,
        progress,
        file,
        uploadedFileSize: loaded,
        secondsLeft,
      }));
    };

    if (synchronized) {
      try {
        const result = await filesService.uploadMultipleFiles(
          newFiles,
          fileAssociationId,
          fileAssociationObjectId,
          municipalityId,
          fileYear ?? DateTime.now().year,
          onFileUpload
        );

        if (!mounted.current) {
          return [];
        }

        responses =
          result?.data?.map((response, i) => ({
            status: response.isSuccessful ? "fulfilled" : "rejected",
            value: { data: response?.payload },
          })) ?? [];
      } catch (error) {
        if (mounted.current) {
          showSnackbar("Some files couldn't be uploaded.", snackbarTypes.error);
        }
        return [];
      }
    } else {
      responses = await Promise.allSettled(
        newFiles.map((file) =>
          filesService.uploadFile(
            file,
            fileAssociationId,
            fileAssociationObjectId,
            municipalityId,
            fileYear ?? DateTime.now().year,
            (progressEvent) => onSingleFileUpload(progressEvent, file)
          )
        )
      );

      if (!mounted.current) {
        return [];
      }
    }

    setIsUploading(false);
    setUploadStatus(defaultUploadStatus);

    if (responses.some((response) => response.status === "rejected")) {
      showSnackbar("Some files couldn't be uploaded.", snackbarTypes.error);
      return [];
    }

    const uploadedFiles = responses.map((item, i) => ({
      ...item.value.data,
      oldName: newFiles[i]?.name,
    }));
    const newUncommittedFiles = [...uncommittedFiles, ...uploadedFiles];

    await onUpload(fileAssociationObjectId, newUncommittedFiles);

    if (!mounted.current) {
      return [];
    }

    showSnackbar(
      "Your files were uploaded successfully.",
      snackbarTypes.success
    );
    setSelectedFiles([]);
    setUncommittedFiles(newUncommittedFiles);

    return uploadedFiles;
  };

  // There is no reference to the current state values on FileUpload's handleDrop callback
  const onFilesSelected = (newFiles) => setSelectedFiles(newFiles);
  useEffect(async () => {
    if (selectedFiles?.length) {
      const newFiles = await uploadFiles(selectedFiles);
      if (!mounted.current) {
        return;
      }
      setFiles([...files, ...newFiles]);
    }
  }, [selectedFiles]);

  useEffect(async () => {
    if (uploadAction === "Commit") {
      setUncommittedFiles([]);
    }

    if (uploadAction === "Rollback") {
      const filesToRemove = uncommittedFiles ?? [];
      if (filesToRemove?.length) {
        const success = await onRemove(filesToRemove, false);

        if (!mounted.current) {
          return;
        }

        if (success) {
          showSnackbar(
            "Your uploaded files were removed successfully.",
            snackbarTypes.success
          );
        } else {
          showSnackbar(
            "Some of your uploaded files couldn't be removed.",
            snackbarTypes.error
          );
        }
      }
      onRollback();
    }
    if (uploadAction === "RemoveAll") {
      if (files?.length) {
        await onRemove(files, true);
      }
    }
  }, [uploadAction]);

  const onRemove = async (filesToRemove, displayFeedback = true) => {
    if (!filesToRemove?.length) return true;

    const responses = await Promise.allSettled(
      filesToRemove.map((file) =>
        filesService.remove(file.id, fileAssociationObjectId)
      )
    );

    if (!mounted.current) {
      return false;
    }

    if (responses.some((response) => response.status === "rejected")) {
      if (displayFeedback) {
        showSnackbar("Some files couldn't be removed.", snackbarTypes.error);
      }
      return false;
    }

    setFiles(
      files.filter(
        (file) =>
          !filesToRemove.some((removedFile) => file.id === removedFile.id)
      )
    );
    setUncommittedFiles(
      uncommittedFiles.filter(
        (file) =>
          !filesToRemove.some((removedFile) => file.id === removedFile.id)
      )
    );
    onDelete(fileAssociationObjectId);
    if (displayFeedback) {
      showSnackbar(
        "The files were successfully deleted.",
        snackbarTypes.success
      );
    }
    return true;
  };

  const onDownloadFile = async ({ id, name }) => {
    try {
      const { data } = await filesService.getById(id);

      if (!mounted.current) {
        return;
      }

      // Download file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  useEffect(() => {
    onChange(files);
  }, [files]);

  const isImage = (fileName) => {
    const fileExtension = fileName?.split(".").pop();
    const imageTypes = [fileTypes.jpeg, fileTypes.jpg, fileTypes.png];
    return imageTypes.some((type) => type === fileExtension);
  };

  return showComponent ? (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        data-testid="fileUploadList"
      >
        {title && (
          <Grid item data-testid="titleContainer">
            <Typography variant="h4">{title}</Typography>
          </Grid>
        )}
        {instructions && (
          <Grid item data-testid="instructionsContainer">
            <Typography>{instructions && parse(instructions)}</Typography>
          </Grid>
        )}
        {!files?.length && readonly && (
          <Grid item data-testid="noFilesYetContainer">
            <Typography>There are no files available yet.</Typography>
          </Grid>
        )}
        {files?.map((file) => (
          <Grid
            item
            key={file?.id || uuidV4()}
            style={{ width: noWrap && "100%" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={
                transparent ? classes.fileRowTransparent : classes.fileRow
              }
              wrap={noWrap && "nowrap"}
            >
              <Grid item style={{ overflowX: "hidden" }}>
                <Button
                  onClick={() => onDownloadFile(file)}
                  className={classes.button}
                >
                  <Typography
                    color="primary"
                    variant="body2"
                    noWrap={noWrap && true}
                    title={noWrap && file?.name}
                  >
                    <Icon
                      className={clsx(
                        "material-icons-outlined",
                        classes.fileIcon
                      )}
                    >
                      {isImage(file?.name) ? "image" : "file_download"}
                    </Icon>
                    {file?.name}
                    {file?.oldName && file?.oldName !== file?.name
                      ? ` (${file.oldName})`
                      : ""}
                  </Typography>
                </Button>
              </Grid>
              {!readonly && (
                <Grid item data-testid="deleteButtonContainer">
                  <IconButton
                    color="secondary"
                    component="span"
                    onClick={async () => {
                      await onRemove([file]);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
        {!readonly && (multiple || (!multiple && files?.length < 1)) && (
          <>
            <Grid item data-testid="fileUploadContainer">
              <FileUpload
                multiple={multiple}
                allowedTypes={allowedTypes}
                onChange={onFilesSelected}
              />
            </Grid>
            {!!allowedTypes?.length && !!showAllowedTypesHint && (
              <Grid item align="center" data-testid="allowedFileTypesHint">
                <Typography>
                  Valid file types:{" "}
                  {allowedTypes
                    .map((allowedType) => `.${allowedType.toUpperCase()}`)
                    .join(", ")}
                </Typography>
              </Grid>
            )}
            {isUploading && (
              <>
                <Grid
                  item
                  data-testid="fileUploadProgress"
                  style={{
                    backgroundColor: colors.grey.mediumLight,
                    padding: "1rem",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    spacing={2}
                    style={{ marginBottom: "1rem" }}
                  >
                    <Grid item>
                      <Icon
                        className="material-icons-outlined"
                        style={{
                          fontSize: "2.25rem",
                          color: colors.grey.main,
                        }}
                      >
                        table_chart
                      </Icon>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        className={clsx(
                          classes.progressText,
                          classes.progressNameText
                        )}
                      >
                        {uploadStatus.file?.name}
                      </Typography>
                      <Typography
                        className={classes.progressText}
                        variant="caption"
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          wrap="nowrap"
                          spacing={4}
                        >
                          <Grid item>
                            {Math.floor(
                              (uploadStatus.uploadedFileSize ?? 0) / 1000
                            )}
                            KB/
                            {Math.floor((uploadStatus.file?.size ?? 0) / 1000)}
                            KB
                          </Grid>
                          {uploadStatus.total > 1 && (
                            <Grid item>
                              {`${uploadStatus.current} of ${uploadStatus.total} file(s) being uploaded`}
                            </Grid>
                          )}
                          <Grid item>
                            {`${uploadStatus.secondsLeft ?? 0} seconds left`}
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    spacing={1}
                  >
                    <Grid item xs>
                      <LinearProgress
                        variant="determinate"
                        color="primary"
                        value={uploadStatus.progress}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        className={clsx(
                          classes.progressText,
                          classes.progressPercText
                        )}
                      >{`${Math.round(uploadStatus.progress)}%`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  ) : null;
};

FileUploadList.propTypes = {
  multiple: PropTypes.bool,
  // These two are needed for uploading files
  fileAssociationId: PropTypes.number.isRequired,
  fileAssociationObjectId: PropTypes.number,
  municipalityId: PropTypes.number,
  fileYear: PropTypes.number,
  initialValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  /* eslint-disable-next-line react/no-unused-prop-types, react/require-default-props */
  handleValue: ({ multiple, initialValue, allowedTypes, readonly }) => {
    if (multiple === false && initialValue.length > 1 && !readonly) {
      return new Error(
        "Multiple is set to false, initial value should contain only one file"
      );
    }
    if (
      initialValue.some((file1, i) =>
        initialValue.some((file2, j) => file1.id === file2.id && i !== j)
      )
    ) {
      return new Error("Initial value set has repeated IDs");
    }
    const unknownFileTypes = allowedTypes.filter(
      (type) => !Object.values(fileTypes).includes(type)
    );
    if (unknownFileTypes.length) {
      return new Error(`Unknown file type/s: ${unknownFileTypes.join(", ")}`);
    }
    return null;
  },
  showAllowedTypesHint: PropTypes.bool,
  readonly: PropTypes.bool,
  transparent: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  instructions: PropTypes.string,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  noWrap: PropTypes.bool,
  synchronized: PropTypes.bool,
  uploadAction: PropTypes.oneOf(["Idle", "Rollback"]),
  onRollback: PropTypes.func,
  showComponent: PropTypes.bool,
};

FileUploadList.defaultProps = {
  initialValue: [],
  fileAssociationObjectId: -1,
  municipalityId: -1,
  fileYear: undefined,
  allowedTypes: Object.values(fileTypes),
  showAllowedTypesHint: true,
  multiple: false,
  onChange: () => {},
  title: undefined,
  instructions: undefined,
  readonly: false,
  transparent: false,
  onUpload: () => {},
  onDelete: () => {},
  noWrap: true,
  synchronized: false,
  uploadAction: "Idle",
  onRollback: () => {},
  showComponent: true,
};

export default FileUploadList;
