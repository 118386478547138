import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ColoredSquare from "./ColoredSquare";
import PropTypes from "prop-types";

/**
 * AMO Reporting Status Legend Item Component.
 *
 * @param {object} props
 * @param {string} props.text The text of the legend item
 * @param {string} props.color The color of the legend item
 * @returns {React.Component} AMO Reporting Status Legend Item component.
 */
const ReportingStatusLegendItem = ({ text, color }) => (
  <Grid item>
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <ColoredSquare color={color} size="1.5rem" />
      </Grid>
      <Grid item>
        <Typography variant="body2">{text}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

ReportingStatusLegendItem.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default ReportingStatusLegendItem;
