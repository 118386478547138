import { useQuery } from "react-query";
import { projectQuestionnairesQueryKeys } from "./projectQuestionnairesQueryKeys";
import {
  summariesService,
  // Used for JSDocs
  // eslint-disable-next-line import/named, no-unused-vars
  SummaryPayload,
} from "api/services/summaryService";

/**
 * Store server's state of project results
 *
 * @param {SummaryPayload} payload The summary request payload of the project results being fetched
 *
 * @returns The query object returned by the useQuery hook
 */
export const useProjectQuestionnairesData = (payload) =>
  useQuery(
    projectQuestionnairesQueryKeys.projectQuestionnaires(payload),
    () => summariesService.getProjectQuestionnaires(payload),
    {
      select: ({ data }) => data,
      enabled: !!Object.keys(payload)?.length,
      refetchOnWindowFocus: false,
    }
  );
