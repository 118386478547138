import React, { useRef, useState } from "react";
import { makeStyles, Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";

import AmoTableWithFilters from "components/table/AmoTableWithFilters";

import { useYearContext } from "contexts/YearContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { exportToExcel } from "utils/export";
import { useMunicipalContext } from "contexts/MunicipalContext";
import {
  projectCategories,
  indicatorTypes,
} from "constants/amoTableDropdownOptions";

import { useResultsReportingLog } from "./resultsLogHooks";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  lineBreak: {
    height: "2rem",
  },
  cellText: {
    fontSize: "0.875rem",
  },
}));

/**
 * The Results Reporting Log Page.
 *
 * @returns {React.Component} Results Reporting Log Page component.
 */
const ResultsLogListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { contextYear } = useYearContext();
  const { getMunicipalitiesNames } = useMunicipalContext();
  const [isExportingToExcel, setIsExportingToExcel] = useState(false);

  const filteredData = useRef([]);

  const tableColumns = [
    {
      fieldKey: "projectId",
      label: "Project ID",
      minWidth: "8.75rem",
      horizontalAlign: "right",
    },
    {
      fieldKey: "municipalityId",
      label: "Municipal ID",
      minWidth: "12rem",
    },
    {
      fieldKey: "municipalityName",
      label: "Municipality",
      minWidth: "14rem",
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "category",
      label: "Category",
      minWidth: "14rem",
      dropdownOptions: projectCategories,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "title",
      label: "Title",
      minWidth: "14rem",
    },
    {
      fieldKey: "description",
      label: "Description",
      customRender: ({ objectives, scopeOfWork }) => (
        <Typography className={classes.cellText} component="div">
          <Grid container direction="column">
            {!!scopeOfWork && <Grid item>{scopeOfWork}</Grid>}
            {!!scopeOfWork && !!objectives && (
              <Grid item className={classes.lineBreak} />
            )}
            {!!objectives && <Grid item>{objectives}</Grid>}
          </Grid>
        </Typography>
      ),
      minWidth: "30rem",
      isCustom: true,
    },
    {
      fieldKey: "totalCost",
      label: "Cost",
      minWidth: "8.75rem",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "indicatorType",
      label: "Type of Indicator",
      minWidth: "14rem",
      dropdownOptions: indicatorTypes,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "indicatorDescription",
      label: "Indicator",
      minWidth: "14rem",
    },
    {
      fieldKey: "textValue",
      label: "Text Value",
      minWidth: "20rem",
    },
    {
      fieldKey: "numericValue",
      label: "Numeric Value",
      minWidth: "13rem",
      displayType: displayTypes.number,
    },
    {
      fieldKey: "beforeValue",
      label: "Before",
      minWidth: "8.75rem",
      displayType: displayTypes.number,
    },
    {
      fieldKey: "afterValue",
      label: "After",
      minWidth: "8.75rem",
      displayType: displayTypes.number,
    },
    {
      fieldKey: "absoluteChange",
      label: "Absolute Change",
      minWidth: "13rem",
      customRender: (item) =>
        item?.absoluteChange ? (
          <Typography
            className={clsx(classes.cellText, classes.alignValuesField)}
          >
            {Number(item.absoluteChange?.toPrecision(8))}
          </Typography>
        ) : null,
      isCustom: true,
      horizontalAlign: "right",
    },
    {
      fieldKey: "unitCost",
      label: "Unit Cost",
      minWidth: "8.75rem",
      isNullable: true,
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "unitName",
      label: "Units",
      minWidth: "8.75rem",
    },
  ];

  const { data: results, isFetching: resultsFetching } = useResultsReportingLog(
    contextYear
  );

  const tableActions = {
    actions: [tableDefaultActions.search, tableDefaultActions.export],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick: () => {
          setIsExportingToExcel(true);
          try {
            exportToExcel(
              filteredData.current,
              tableColumns,
              `Results Reporting Log - ${contextYear}`,
              {
                sheetName: "Results",
                specialColumnSizes: {
                  title: 40,
                  objectives: 50,
                  indicatorDescription: 40,
                },
              }
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          } finally {
            setIsExportingToExcel(false);
          }
        },
        disabled: isExportingToExcel,
      },
    },
  };

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap">
      <Grid item>
        <Typography variant="h1">Results</Typography>
      </Grid>
      <Grid item className={classes.dividerContainer}>
        <Divider />
      </Grid>
      <Grid item>
        <Grid item>
          <AmoTableWithFilters
            items={results}
            columns={tableColumns}
            defaultOrderBy="projectId"
            defaultOrder="desc"
            onFilteredDataChanged={(value) => {
              filteredData.current = value;
            }}
            actionsProps={tableActions}
            isLoading={resultsFetching}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResultsLogListPage;
