import React from "react";
import PropTypes from "prop-types";
import colors from "../constants/colors";
import { IconButton, InputAdornment } from "@material-ui/core";
import AmoTextField from "./inputs/AmoTextField";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

// sets the Material-UI styles for this component
const useStyles = makeStyles({
  searchBox: {
    margin: "1.375rem 0 1.375rem 0", // 22px
    width: "20.375rem", // 326px
    borderRadius: "0.21875rem", // 3.5px
    backgroundColor: colors.white,
  },
});

/**
 * A search bar component
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - the form id of this input
 * @param {string} props.prompt - the placeholder value
 * @param {Function} props.searchFunction - the function executed when the search button is clicked.  ** MUST ACCEPT A STRING PARAM **
 * @returns {React.Component} The search bar component
 */
const SearchBox = (props) => {
  const { id, prompt, control, name, testId } = props;

  const classes = useStyles();

  return (
    <AmoTextField
      className={classes.searchBox}
      control={control}
      id={id}
      name={name}
      testId={testId}
      placeholder={prompt}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="searchButton"
              size="small"
              onClick={() => {}}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

// set the prop-types for this component
SearchBox.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

SearchBox.defaultProps = {
  testId: undefined,
};

export default SearchBox;
