import { useQuery } from "react-query";
import { DateTime } from "luxon";
import { useMunicipalContext } from "contexts/MunicipalContext";

import { landingPageKeys } from "./landingPageQueryKeys";
import { commDashboardService } from "api/services/commDashboardService";

/**
 * Store server's state of recent comms
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useRecentComms = () => {
  const { municipalities } = useMunicipalContext();

  return useQuery(
    landingPageKeys.recentComms(),
    () => commDashboardService.getRecentComms(),
    {
      select: ({ data }) =>
        data?.map((item) => ({
          ...item,
          municipality: municipalities[item?.municipalityId]?.name,
          date: DateTime.fromISO(item.announcementDate).toFormat("yyyy-MM-dd"),
        })) ?? [],
      refetchOnMount: "always",
    }
  );
};

/**
 * Store server's state of recent updates
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useRecentUpdates = () => {
  const { municipalities } = useMunicipalContext();

  return useQuery(
    landingPageKeys.recentUpdates(),
    () => commDashboardService.getRecentUpdates(),
    {
      select: ({ data }) =>
        data?.map((item) => ({
          ...item,
          municipality: municipalities[item?.municipalityId]?.name,
          date: DateTime.fromISO(item.date).toFormat("yyyy-MM-dd"),
        })) ?? [],
      refetchOnMount: "always",
    }
  );
};
