import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  municipalityId: number,
 *  reportingYear: number,
 *  payment: Date,
 *  announced: number }} AllocationItem
 */

const api = new Api("api/amo/allocations");
export const allocationsService = {
  /**
   * @returns {Promise<AxiosResponse<AllocationItem[]>>}
   */
  getAll: () => api.get(),
  /**
   * @param {AllocationItem} payload
   * @returns {Promise<AxiosResponse>}
   */
  post: (payload) => api.post(payload),
  /**
   * @param {AllocationItem[]} payload
   * @returns {Promise<AxiosResponse>}
   */
  postMultiple: (payload) => api.post(payload, "/multiple"),
};
