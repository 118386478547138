import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useUserContext } from "contexts/UserContext";
import ReportPageWrapper from "components/ReportPageWrapper";

import MunicipalQuestionnairePage from "pages/municipal/municipalQuestionnaire/ManagementQuestionnairePage";
import { questionnaireSubmissionService } from "api/services/questionnaireSubmissionService";

/**
 * The "asset management" questionnaire page.
 *
 * @returns {React.Component} The page component.
 */
const AssetManagementQuestionnairePage = () => {
  const { year: questionnaireYear } = useParams();
  const { user: currentUser } = useUserContext();

  const municipalityId = currentUser?.municipalityId;

  const [questionnaireResponse, setQuestionnaireResponse] = useState(null);

  const getQuestionnaireData = async () => {
    setQuestionnaireResponse(null);
    const response = questionnaireSubmissionService.getAssetQuestionnaire(
      municipalityId,
      questionnaireYear
    );
    setQuestionnaireResponse(response);
  };

  useLayoutEffect(() => {
    getQuestionnaireData();
  }, []);

  return (
    <ReportPageWrapper title="Asset Management Questionnaire">
      <MunicipalQuestionnairePage
        responseOverride={questionnaireResponse}
        dataSaved={getQuestionnaireData}
      />
    </ReportPageWrapper>
  );
};

export default AssetManagementQuestionnairePage;
