import PropTypes from "prop-types";

/**
 * The options accepted by some of the @material-ui/Typography component props
 *
 * @returns {object} The typography accepted options.
 */
export const typographyOptions = {
  color: [
    "initial",
    "inherit",
    "primary",
    "secondary",
    "desc",
    "textPrimary",
    "textSecondary",
    "error",
  ],
  variant: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
    "srOnly",
    "inherit",
  ],
  align: ["inherit", "left", "center", "right", "justify"],
};

/**
 * The options accepted by some of the @material-ui/Grid component props
 *
 * @returns {object} The grid accepted options.
 */
export const gridOptions = {
  justifyContent: [
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-around",
    "space-evenly",
  ],
  alignItems: ["flex-start", "center", "flex-end", "stretch", "baseline"],
};

/**
 * The options accepted by indicator types for Project Reporting Outputs and Outcomes
 *
 * @returns {object} The indicator accepted options.
 */

export const outputOptions = PropTypes.shape({
  categoryId: PropTypes.number.isRequired,
  categoryNameText: PropTypes.string.isRequired,
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      groupName: PropTypes.string,
      id: PropTypes.number.isRequired,
      indicatorType: PropTypes.string.isRequired,
      indicatorUnitTypeId: PropTypes.number,
      indicatorValues: PropTypes.arrayOf(PropTypes.string.isRequired),
      isBeforeAfter: PropTypes.bool,
      tooltipText: PropTypes.string,
      validationTypeId: PropTypes.number,
      standardIndicatorUnitTypeId: PropTypes.number,
      savedData: PropTypes.shape({
        afterValue: PropTypes.string,
        beforeValue: PropTypes.string,
        id: PropTypes.number.isRequired,
        outcomeValue: PropTypes.string.isRequired,
        unitTypeId: PropTypes.number,
      }),
    })
  ),
});

export const outcomeOptions = PropTypes.shape({
  calculationTypeId: PropTypes.number,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  indicatorType: PropTypes.string.isRequired,
  indicatorUnitTypeId: PropTypes.number,
  indicatorValues: PropTypes.arrayOf(PropTypes.string.isRequired),
  isBeforeAfter: PropTypes.bool,
  tooltipText: PropTypes.string,
  validationTypeId: PropTypes.number,
  standardIndicatorUnitTypeId: PropTypes.number,
  savedData: PropTypes.shape({
    afterValue: PropTypes.string,
    beforeValue: PropTypes.string,
    id: PropTypes.number.isRequired,
    outcomeValue: PropTypes.string.isRequired,
    unitTypeId: PropTypes.number,
  }),
});

/**
 * The options accepted by returned unit data for Project Reporting Results
 *
 * @returns {object} The unit data accepted options.
 */

export const unitDataOptions = PropTypes.shape({
  id: PropTypes.number.isRequired,
  indicatorUnitCategoryTypeId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});
