import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Grid,
  IconButton,
  Typography,
  Button,
  Icon,
  Divider,
} from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "img/icons/download_circle_thin_icon.svg";
import colors from "constants/colors";
import {
  tableDefaultActions,
  tableDefaultActionsConfigs,
} from "constants/amoTableConstants";
import { ReactComponent as SearchIcon } from "img/icons/search_icon.svg";
import { AmoTableActionsProps } from "constants/amoTablePropTypes";
import AmoTooltip from "../AmoTooltip";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "7.75rem",
    height: "3.125rem",
    borderWidth: "0.125rem",
    "&:hover": {
      borderWidth: "0.125rem",
    },
  },
  iconButton: {
    width: "3rem",
    height: "3rem",
    margin: "0 0",
    padding: "0 0",
  },
  icon: {
    width: "3rem",
    height: "3rem",
  },

  iconButtonClear: {
    border: `0.125rem solid ${theme.palette.secondary.main}`,
    "&:hover": {
      borderWidth: "0.125rem",
    },
  },
  closeSearchIcon: {
    paddingTop: "0.2rem",
  },
  topDivider: {
    marginTop: "1rem",
    marginBottom: "1.25rem",
  },
  bottomDivider: {
    marginTop: "1.25rem",
    marginBottom: "1rem",
  },
}));

/**
 * A table actions component
 *
 * @param {AmoTableActionsProps} props - object containing props for this component
 *
 * @returns {React.Component} The table actions component
 */
const AmoTableActions = (props) => {
  const {
    tableId,
    titleComponent,
    actions,
    actionsCustomConfigs,
    customActionsComponents,
    disableDefaultActions,
    showDividers,
  } = props;

  const classes = useStyles();

  const componentId = `${tableId ?? "amo-table"}-actions`;

  const newButtonComponent = (actionConfig) => (
    <Button
      id={`${componentId}-new-button`}
      data-testid={`${componentId}-newButtonTest`}
      color="primary"
      variant="outlined"
      size="large"
      disableElevation
      onClick={actionConfig?.onClick}
      className={classes.button}
      disabled={actionConfig?.disabled || disableDefaultActions}
      style={actionConfig?.style}
    >
      <Typography align="center" variant="body2">
        {actionConfig?.label}
      </Typography>
    </Button>
  );

  const renderAction = (action) => {
    let actionComponent;
    const actionConfig = {
      ...(tableDefaultActionsConfigs[action] ?? {}),
      ...(actionsCustomConfigs[action] ?? {}),
    };
    switch (action) {
      case tableDefaultActions.new:
        actionComponent =
          actionConfig.toolTipText &&
          (actionConfig.disabled || disableDefaultActions) ? (
            <AmoTooltip
              tooltipText={actionConfig.toolTipText}
              customComponent={newButtonComponent(actionConfig)}
            />
          ) : (
            newButtonComponent(actionConfig)
          );
        break;
      case tableDefaultActions.clearSearch:
        actionComponent = (
          <Button
            id={`${componentId}-clear-filter`}
            data-testid={`${componentId}-clearFilterButtonTest`}
            color="secondary"
            variant="outlined"
            size="large"
            disabled={actionConfig?.disabled || disableDefaultActions}
            disableElevation
            onClick={actionConfig?.onClick}
            className={classes.iconButtonClear}
            startIcon={
              <Icon
                className={clsx(
                  "material-icons-outlined",
                  classes.closeSearchIcon
                )}
              >
                close
              </Icon>
            }
          >
            <Typography align="center" variant="subtitle1">
              Clear Filters
            </Typography>
          </Button>
        );
        break;
      case tableDefaultActions.search:
        actionComponent = (
          <IconButton
            id={`${componentId}-search-button`}
            data-testid={`${componentId}-searchButtonTest`}
            aria-label={`${componentId}-search`}
            onClick={actionConfig?.onClick}
            className={classes.iconButton}
            disabled={actionConfig?.disabled || disableDefaultActions}
            color="primary"
          >
            <SearchIcon
              className={classes.icon}
              style={{
                stroke:
                  !actionConfig?.disabled && !disableDefaultActions
                    ? colors.green.main
                    : colors.grey.main,
              }}
              fill={
                !actionConfig?.disabled && !disableDefaultActions
                  ? colors.green.main
                  : colors.grey.main
              }
            />
          </IconButton>
        );
        break;
      case tableDefaultActions.export:
        actionComponent = (
          <IconButton
            id={`${componentId}-export-button`}
            data-testid={`${componentId}-exportButtonTest`}
            aria-label={`${componentId}-export`}
            onClick={actionConfig?.onClick}
            className={classes.iconButton}
            disabled={actionConfig?.disabled || disableDefaultActions}
            color="primary"
          >
            <DownloadIcon
              className={classes.icon}
              style={{
                stroke:
                  !actionConfig?.disabled && !disableDefaultActions
                    ? colors.green.main
                    : colors.grey.main,
              }}
              fill={
                !actionConfig?.disabled && !disableDefaultActions
                  ? colors.green.main
                  : colors.grey.main
              }
            />
          </IconButton>
        );
        break;
      default:
        actionComponent = customActionsComponents[action] ?? null;
        break;
    }
    return (
      !!actionComponent && (
        <Grid item key={`${componentId}-${action}`}>
          {actionComponent}
        </Grid>
      )
    );
  };

  return (
    <>
      {showDividers && <Divider className={classes.topDivider} />}
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>{titleComponent}</Grid>
        <Grid item xs>
          <Grid
            id={componentId}
            container
            justifyContent="flex-end"
            spacing={2}
            wrap="nowrap"
          >
            {actions.map((action) => renderAction(action))}
          </Grid>
        </Grid>
      </Grid>
      {showDividers && <Divider className={classes.bottomDivider} />}
    </>
  );
};

// set the prop-types for this component
AmoTableActions.propTypes = AmoTableActionsProps;

AmoTableActions.defaultProps = {
  tableId: null,
  titleComponent: null,
  actions: [],
  actionsCustomConfigs: {},
  customActionsComponents: {},
  disableDefaultActions: false,
};

export default AmoTableActions;
