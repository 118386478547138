import { withStyles } from "@material-ui/core/styles";
import { Slider } from "@material-ui/core";
import colors from "constants/colors";

const AmoTableFilterNumericSlider = withStyles({
  root: {
    color: colors.slider.activeBar,
    height: 4,
    padding: "1rem 0",
  },
  thumb: {
    height: "2rem",
    width: "2rem",
    backgroundColor: colors.slider.activeBar,
    marginTop: "-0.875rem",
    marginLeft: "-1rem",
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .icon": {
      color: colors.slider.thumbDetail,
    },
  },
  active: {},
  track: {
    height: 4,
  },
  rail: {
    color: colors.slider.backgroundBar,
    height: 4,
  },
})(Slider);

export default AmoTableFilterNumericSlider;
