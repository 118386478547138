// Format number to currency
export const formatToCurrency = (number, rounded = false) => {
  if (isNaN(number)) {
    return null;
  }
  const formatted = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
    minimumFractionDigits: rounded ? 0 : 2,
    maximumFractionDigits: rounded ? 0 : 2,
  }).format(Math.abs(number));
  return number < 0 ? `(${formatted})` : formatted;
};

/**
 *  Generates an array with the range of numbers between start and stop
 *
 * @param {number} start
 * @param {number} stop
 * @returns {number[]} Array with the range of numbers
 */
export const range = (start, stop) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);

/**
 * Round number to desired decimal places
 * This solution is to fix some JS edge cases where Math.round() doesn't work as expected
 *
 * @param {number} value number to be rounded
 * @param {number} numberOfDecimals number of decimals to round to
 * @returns {number} number rounded to desired decimal places
 */
export const customRound = (value, numberOfDecimals) => {
  const floatValue = parseFloat(value);

  if (isNaN(floatValue) || isNaN(numberOfDecimals)) {
    return value;
  }

  const powerOfTen = 10 ** numberOfDecimals;
  return Math.round((floatValue + Number.EPSILON) * powerOfTen) / powerOfTen;
};

/**
 * Round number to a fixed decimal places
 * This solution uses toFixed js method
 *
 * @param {number} value number to be rounded
 * @param {number} numberOfDecimals number of decimals to round to
 * @returns {number} number rounded to desired decimal places
 */
export const fixedRound = (value, numberOfDecimals) => {
  const floatValue = parseFloat(value);

  if (isNaN(floatValue) || isNaN(numberOfDecimals)) {
    return value;
  }

  return floatValue.toFixed(numberOfDecimals);
};

// Format number to phone format (###-###-####)
export const formatToPhone = (number) =>
  (number?.toString() ?? "")
    // Clean any non-digit character
    .replace(/[^0-9.]+/g, "")
    // Replace the number string with the phone format
    .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

// Format number to phone format (###-###-#### x ####)
export const formatToPhoneWithExtension = (number, extension = null) => {
  const phoneNumber = formatToPhone(number);
  const phoneExtension = extension ? ` x ${extension.toString()}` : "";
  return `${phoneNumber}${phoneExtension}`;
};

/**
 * An amount representing dollar-based currency.
 * This class can help prevent rounding errors when adding, subtracting, and comparing dollar values.
 */
export class Dollars {
  /**
   * @param {number|string|Dollars} value
   * The dollar amount.
   */
  constructor(value) {
    // Uses cents internally - integers do not have rounding errors on addition/subtraction/comparison
    if (value instanceof Dollars) {
      this.cents = value.cents;
    } else {
      this.cents = Math.round(parseFloat(value) * 100);
    }
  }

  /**
   * @returns {number}
   */
  valueOf() {
    return this.cents / 100;
  }

  /**
   * @returns {string}
   */
  toString() {
    return Number(this).toFixed(2);
  }

  /**
   * @returns {boolean}
   */
  isNaN() {
    return isNaN(this.cents);
  }

  /**
   * Add the given dollar values to this dollar value.
   *
   * @param {number|string|Dollars} values
   * @returns {Dollars}
   * A new instance with the added value.
   */
  plus(...values) {
    let newCents = this.cents;

    values.forEach((value) => {
      newCents += new Dollars(value).cents;
    });

    return new Dollars(newCents / 100);
  }

  /**
   * Subtract the given dollar values from this dollar value.
   *
   * @param {number|string|Dollars} values
   * @returns {Dollars}
   * A new instance with the subtracted value.
   */
  minus(...values) {
    let newCents = this.cents;

    values.forEach((value) => {
      newCents -= new Dollars(value).cents;
    });

    return new Dollars(newCents / 100);
  }

  /**
   * @param {number|string|Dollars} value
   * The value to compare.
   * @returns {boolean}
   * Whether the given dollar value is less than this dollar value.
   */
  lt(value) {
    return this.cents < new Dollars(value).cents;
  }

  /**
   * @param {number|string|Dollars} value
   * The value to compare.
   * @returns {boolean}
   * Whether the given dollar value is less than or equal to this dollar value.
   */
  le(value) {
    return this.cents <= new Dollars(value).cents;
  }

  /**
   * @param {number|string|Dollars} value
   * The value to compare.
   * @returns {boolean}
   * Whether the given dollar value is greater than this dollar value.
   */
  gt(value) {
    return this.cents > new Dollars(value).cents;
  }

  /**
   * @param {number|string|Dollars} value
   * The value to compare.
   * @returns {boolean}
   * Whether the given dollar value is greater than or equal to this dollar value.
   */
  ge(value) {
    return this.cents >= new Dollars(value).cents;
  }

  /**
   * @param {number|string|Dollars} value
   * The value to compare.
   * @returns {boolean}
   * Whether the given dollar value is equal to this dollar value.
   */
  eq(value) {
    return this.cents === new Dollars(value).cents;
  }

  /**
   * @param {number|string|Dollars} value
   * The value to compare.
   * @returns {boolean}
   * Whether the given dollar value is not equal to this dollar value.
   */
  ne(value) {
    return this.cents !== new Dollars(value).cents;
  }
}
