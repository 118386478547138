import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *	id: number,
 *	projectId: number,
 *	announcementDate: string,
 *	twitterUrl: string,
 *	instagramUrl: string,
 *	linkedInUrl: string,
 *	buildingCommunitiesUrl: string }} CommunicationAnnouncement
 */

/**
 * @typedef {{
 *	id: number,
 *	name: string }} CommunicationStatus
 */
/**
 * @typedef {{
 *	communicationsId: number,
 *	statusId: number,
 *	comment: string }} CommunicationHistoryPayload
 */

const api = new Api("api/communications/");
export const communicationsService = {
  /**
   * @param {number} projectId
   * @returns {Promise<AxiosResponse<CommunicationAnnouncement>>}
   */
  getAnnouncements: (projectId) =>
    api.getById(`announcements/project/${projectId}`),
  /**
   * @param {CommunicationAnnouncement} payload
   * @returns {Promise<AxiosResponse<CommunicationAnnouncement>>}
   */
  createAnnouncement: (payload) => api.axios.post("announcements", payload),
  /**
   * @param {number} id
   * @param {CommunicationAnnouncement} payload
   * @returns {Promise<AxiosResponse<CommunicationAnnouncement>>}
   */
  updateAnnouncement: (id, payload) =>
    api.axios.put(`announcements/${id}`, payload),
  /**
   * @returns {Promise<AxiosResponse<CommunicationStatus[]>>}
   */
  getStatuses: () => api.getById("statuses"),
  /**
   * @param {CommunicationHistoryPayload} payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  saveHistory: (payload) => api.axios.post("history", payload),
};
