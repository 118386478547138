import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import AmoTextField from "components/inputs/AmoTextField";
import colors from "constants/colors";
import AmoTooltip from "./AmoTooltip";

const useStyles = makeStyles((theme) => ({
  inputWidth: {
    width: "50%",
  },
  overviewItem: {
    marginLeft: "0rem",
  },
  label: {
    fontSize: "0.875rem",
  },
  previousValue: {
    color: colors.red.main,
    textDecoration: "line-through",
  },
}));

/**
 * A field component to use on the banking information page and modal
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.name - name of the component
 * @param {string} props.label - label of the field
 * @param {string} props.value - current value of the field
 * @param {boolean} props.previousValue - previous value of the field
 * @param {boolean} props.editMode - sets whether a label or textfield should be displayed
 * @param {boolean} props.overrideClassName - sets whether a label or textfield should be displayed
 * @param {boolean} props.required - sets whether this is a required field
 * @param {any} props.inputProps    MUI props that get passed to the Input component
 * @param {any} props.inputLabelProps    MUI props that get passed to the Input Label component
 * @param {any} props.phoneFormat    Formats the field for phone numbers
 * @param {any} props.tooltipText    sets the text of the tooltip
 * @param {any} props.helperText    sets the helper text of the tooltip
 * @returns {React.Component} The field component
 */
const BankingInformationField = (props) => {
  const {
    name,
    label,
    value,
    previousValue,
    editMode,
    control,
    required,
    overrideClassName,
    inputProps,
    inputLabelProps,
    phoneFormat,
    tooltipText,
    helperText,
  } = props;
  const classes = useStyles();

  return (
    <Grid item xs>
      <Grid
        container
        alignItems="flex-end"
        className={overrideClassName || classes.overviewItem}
      >
        {editMode ? (
          <Grid item container alignItems="center">
            <AmoTextField
              control={control}
              label={label}
              id={name}
              name={name}
              testId={`test-${name}`}
              className={classes.inputWidth}
              variant="outlined"
              required={required}
              InputProps={inputProps}
              InputLabelProps={inputLabelProps}
              helperText={helperText}
            />
            {tooltipText && (
              <Grid item xs>
                <AmoTooltip tooltipTitle={label} tooltipText={tooltipText} />
              </Grid>
            )}
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.label}>
                {label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.previousValue}>
                {phoneFormat && previousValue
                  ? previousValue
                      .toString()
                      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                  : previousValue}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={previousValue ? "body2" : "body1"}>
                {phoneFormat && value
                  ? value
                      .toString()
                      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                  : value}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

// set the prop-types for this component
BankingInformationField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  previousValue: PropTypes.string,
  editMode: PropTypes.bool,
  control: PropTypes.shape().isRequired,
  overrideClassName: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape(),
  inputLabelProps: PropTypes.shape(),
  phoneFormat: PropTypes.bool,
  tooltipText: PropTypes.string,
  helperText: PropTypes.string,
};

BankingInformationField.defaultProps = {
  value: "",
  previousValue: null,
  editMode: false,
  overrideClassName: null,
  required: false,
  inputProps: undefined,
  inputLabelProps: undefined,
  phoneFormat: false,
  tooltipText: "",
  helperText: "",
};

export default BankingInformationField;
