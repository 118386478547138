import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Typography } from "@material-ui/core";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  financialReportingCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    // 32px
    paddingLeft: "2rem",
    // 24px
    paddingRight: "1.5rem",
  },
  formTitle: {
    // 32px
    paddingTop: "2rem",
    "& > .MuiGrid-item": {
      "&:last-child": {
        // 20px
        paddingBottom: "1.25rem",
      },
    },
  },
  formTitleBylaw: {
    // 32px
    paddingTop: "2rem",
  },
  divider: {
    backgroundColor: colors.black,
    // 20px 0px
    margin: "1.25rem 0rem",
  },
  paddingTop: {
    paddingTop: "1.5rem",
  },
  underlined: {
    textDecorationLine: "underline",
  },
  tableRow: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  tableRowCaption: {
    color: colors.green.dark,
  },
  tableTotals: {},
  tableFirstCol: {
    paddingLeft: "2rem",
  },
  tableCellField: {},
  tableCurrencyMiddle: {
    // 16px
    paddingLeft: "1rem",
    // 7px
    paddingBottom: "0.43rem",
  },
  tableCellInput: {
    // 16px
    paddingLeft: "1rem",
    // 7px
    paddingBottom: "0.43rem",
    fontFamily: "Roboto",
    minWidth: "6rem",
    minHeight: "1.5rem",
    borderBottom: `2px solid ${colors.green.main}`,
  },
  cellMarginTop: {
    marginTop: "0.5rem",
  },
  firstEditRow: {
    marginTop: "0.4rem",
  },
  descriptionForm: {
    "& > p": {
      marginBottom: 0,
    },
  },
}));

/**
 * A page component for editing "Financial Reporting" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const FinancialReportingEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.financialReporting
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  const tableBodyRow = (
    caption,
    total1,
    total2,
    rowClass,
    firstColClass,
    key
  ) =>
    tableRow(
      <Typography variant="body1" className={classes.tableRowCaption}>
        {caption}
      </Typography>,
      <Typography variant="body1" className={classes.tableCurrencyMiddle}>
        {total1}
      </Typography>,
      <Typography variant="body1">{total2}</Typography>,
      rowClass,
      firstColClass,
      key
    );

  const totalRow = (
    caption,
    total1,
    total2,
    rowClass = classes.tableTotals,
    firstColClass
  ) =>
    tableRow(
      <Typography variant="body2" className={classes.tableRowCaption}>
        {caption}
      </Typography>,
      <Typography variant="body2">{total1}</Typography>,
      <Typography variant="body2">{total2}</Typography>,
      rowClass,
      firstColClass
    );

  const tableRow = (
    caption,
    col1,
    col2,
    rowClass = classes.tableRow,
    firstColClass = classes.tableFirstCol,
    key
  ) => (
    <Grid
      container
      direction="row"
      className={rowClass}
      key={key}
      alignItems="baseline"
    >
      <Grid item xs={6} className={firstColClass}>
        {caption}
      </Grid>
      <Grid item xs={3}>
        {col1}
      </Grid>
      <Grid item xs={3} style={{ justifyContent: "center" }}>
        {col2}
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Financials"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyPadding
            )}
          >
            {/* Form Description */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.descriptionForm] && (
                    <FormFieldWrapper
                      id="fr-form-description"
                      testId="frFormDescription"
                      type={formFields[formCodes.descriptionForm].type}
                      value={formFields[formCodes.descriptionForm].text}
                      textProps={{ variant: "body1" }}
                      align="flex-start"
                      className={classes.descriptionForm}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionForm])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Divider */}
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Column headers */}
            {tableRow(
              "",
              <Typography variant="subtitle1" className={classes.underlined}>
                Current Year
              </Typography>,
              <Typography variant="subtitle1" className={classes.underlined}>
                Cumulative
              </Typography>
            )}
            {/* Opening Balance */}
            {tableRow(
              <Typography variant="subtitle1">Opening Balance</Typography>,
              <Typography variant="subtitle1">$XX,XXX.XX</Typography>,
              "",
              classes.tableTotals,
              ""
            )}
            {/* Revenues */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitle}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.ttlRevenues] && (
                    <FormFieldWrapper
                      id="fr-revenues"
                      testId="frRevenues"
                      type={formFields[formCodes.ttlRevenues].type}
                      value={formFields[formCodes.ttlRevenues].text}
                      textProps={{ variant: "h3" }}
                      className={classes.tableRowCaption}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.ttlRevenues])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {tableBodyRow(
              "Received from AMO (Including Receivables)",
              "$XX,XXX.XX",
              "$XX,XXX.XX",
              classes.firstEditRow
            )}
            {tableRow(
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  spacing={0}
                  className={classes.tableCellField}
                  wrap="nowrap"
                >
                  <Grid item>
                    {formFields[formCodes.fldProceedDisposalAssets] && (
                      <FormFieldWrapper
                        id="fr-proceed-disposal-assets"
                        testId="frProceedDisposalAssets"
                        type={
                          formFields[formCodes.fldProceedDisposalAssets].type
                        }
                        value={
                          formFields[formCodes.fldProceedDisposalAssets].text
                        }
                        inputProps={{ labelOnly: true }}
                        textProps={{ variant: "body1" }}
                        isFullWidth={false}
                        onEditClick={() =>
                          handleEditClick(
                            formFields[formCodes.fldProceedDisposalAssets]
                          )
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>,
              <Typography
                variant="body1"
                className={classes.tableCellInput}
                component="span"
              >
                $XX,XXX.XX
              </Typography>,
              <Typography variant="body1" className={classes.cellMarginTop}>
                $XX,XXX.XX
              </Typography>,
              ""
            )}
            {tableRow(
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  spacing={0}
                  className={classes.tableCellField}
                  wrap="nowrap"
                >
                  <Grid item>
                    {formFields[formCodes.fldInterestEarned] && (
                      <FormFieldWrapper
                        id="fr-interest-earned"
                        testId="frInterestEarned"
                        type={formFields[formCodes.fldInterestEarned].type}
                        value={formFields[formCodes.fldInterestEarned].text}
                        inputProps={{ labelOnly: true }}
                        textProps={{ variant: "body1" }}
                        isFullWidth={false}
                        onEditClick={() =>
                          handleEditClick(
                            formFields[formCodes.fldInterestEarned]
                          )
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>,
              <Typography
                variant="body1"
                className={classes.tableCellInput}
                component="span"
              >
                $XX,XXX.XX
              </Typography>,
              <Typography variant="body1" className={classes.cellMarginTop}>
                $XX,XXX.XX
              </Typography>,
              ""
            )}
            {tableRow(
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  spacing={0}
                  className={classes.tableCellField}
                  wrap="nowrap"
                >
                  <Grid item>
                    {formFields[formCodes.fldLessAdministrationCosts] && (
                      <FormFieldWrapper
                        id="fr-less-administration-costs"
                        testId="frLessAdministrationCosts"
                        type={
                          formFields[formCodes.fldLessAdministrationCosts].type
                        }
                        value={
                          formFields[formCodes.fldLessAdministrationCosts].text
                        }
                        inputProps={{ labelOnly: true }}
                        textProps={{ variant: "body1" }}
                        isFullWidth={false}
                        onEditClick={() =>
                          handleEditClick(
                            formFields[formCodes.fldLessAdministrationCosts]
                          )
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>,
              <Typography
                variant="body1"
                className={classes.tableCellInput}
                component="span"
              >
                $XX,XXX.XX
              </Typography>,
              <Typography variant="body1" className={classes.cellMarginTop}>
                $XX,XXX.XX
              </Typography>,
              ""
            )}
            {/* Divider */}
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Revenues totals */}
            {totalRow("Net", "$XX,XXX.XX", "$XX,XXX.XX")}
            {/* Transfers */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitle}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.ttlTransfers] && (
                    <FormFieldWrapper
                      id="fr-transfers"
                      testId="frTransfers"
                      type={formFields[formCodes.ttlTransfers].type}
                      value={formFields[formCodes.ttlTransfers].text}
                      textProps={{ variant: "h3" }}
                      className={classes.tableRowCaption}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.ttlTransfers])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {tableRow(
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  spacing={0}
                  className={classes.tableCellField}
                  wrap="nowrap"
                >
                  <Grid item>
                    {formFields[formCodes.fldPlusAmountsReceived] && (
                      <FormFieldWrapper
                        id="fr-plus-amounts-received"
                        testId="frPlusAmountsReceived"
                        type={formFields[formCodes.fldPlusAmountsReceived].type}
                        value={
                          formFields[formCodes.fldPlusAmountsReceived].text
                        }
                        inputProps={{ labelOnly: true }}
                        textProps={{ variant: "body1" }}
                        isFullWidth={false}
                        onEditClick={() =>
                          handleEditClick(
                            formFields[formCodes.fldPlusAmountsReceived]
                          )
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>,
              <Typography
                variant="body1"
                className={classes.tableCellInput}
                component="span"
              >
                $XX,XXX.XX
              </Typography>,
              <Typography variant="body1" className={classes.cellMarginTop}>
                $XX,XXX.XX
              </Typography>,
              classes.firstEditRow
            )}
            {tableRow(
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  spacing={0}
                  className={classes.tableCellField}
                  wrap="nowrap"
                >
                  <Grid item>
                    {formFields[formCodes.fldLessAmountsTransferred] && (
                      <FormFieldWrapper
                        id="fr-less-amounts-transferred"
                        testId="frLessAmountsTransferred"
                        type={
                          formFields[formCodes.fldLessAmountsTransferred].type
                        }
                        value={
                          formFields[formCodes.fldLessAmountsTransferred].text
                        }
                        inputProps={{ labelOnly: true }}
                        textProps={{ variant: "body1" }}
                        isFullWidth={false}
                        onEditClick={() =>
                          handleEditClick(
                            formFields[formCodes.fldLessAmountsTransferred]
                          )
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>,
              <Typography
                variant="body1"
                className={classes.tableCellInput}
                component="span"
              >
                $XX,XXX.XX
              </Typography>,
              <Typography variant="body1" className={classes.cellMarginTop}>
                $XX,XXX.XX
              </Typography>,
              ""
            )}
            {/* Divider */}
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Transfers totals */}
            {totalRow("Net", "$XX,XXX.XX", "$XX,XXX.XX")}
            {/* Expenditures */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitle}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.ttlExpenditures] && (
                    <FormFieldWrapper
                      id="fr-expenditures"
                      testId="frExpenditures"
                      type={formFields[formCodes.ttlExpenditures].type}
                      value={formFields[formCodes.ttlExpenditures].text}
                      textProps={{ variant: "h3" }}
                      className={classes.tableRowCaption}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.ttlExpenditures])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {[
              "Broadband Connectivity",
              "Brownfield Redevelopment",
              "Capacity Building",
              "Community Energy Systems",
              "Culture",
              "Disaster Mitigation",
              "Drinking Water",
              "Fire Stations",
              "Local Roads and Bridges",
              "Public Transit",
              "Recreation",
              "Regional and Local Airports",
              "Short-line Rail",
              "Short-sea Shipping",
              "Solid Waste",
              "Sports",
              "Tourism",
              "Wastewater",
            ].map((caption, i) =>
              tableBodyRow(
                caption,
                "$XX,XXX.XX",
                "$XX,XXX.XX",
                undefined,
                undefined,
                `expenditures-row-${i}`
              )
            )}
            {/* Divider */}
            <Grid item xs>
              <Divider className={classes.divider} />
            </Grid>
            {/* Expenditures totals */}
            {totalRow("Net", "$XX,XXX.XX", "$XX,XXX.XX")}
            {/* Closing Balance */}
            {totalRow(
              "Closing Balance",
              "$XX,XXX.XX",
              "",
              classes.paddingTop,
              ""
            )}
            {/* Upload Bylaw */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitleBylaw}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.ttlUploadBylaw] && (
                    <FormFieldWrapper
                      id="fr-upload-bylaw"
                      testId="frUploadBylaw"
                      type={formFields[formCodes.ttlUploadBylaw].type}
                      value={formFields[formCodes.ttlUploadBylaw].text}
                      textProps={{ variant: "h3" }}
                      className={classes.tableRowCaption}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.ttlUploadBylaw])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Upload Bylaw instructions */}
            <Grid item xs>
              <Grid container direction="column" spacing={0} wrap="nowrap">
                <Grid item>
                  {formFields[formCodes.descUploadBylaw] && (
                    <FormFieldWrapper
                      id="fr-upload-bylaw-instructions"
                      testId="frUploadBylawInstructions"
                      type={formFields[formCodes.descUploadBylaw].type}
                      value={formFields[formCodes.descUploadBylaw].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descUploadBylaw])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="fr-edit-popover"
        testId="frEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default FinancialReportingEditPage;
