import React, { useState, useEffect } from "react";

import parse from "html-react-parser";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import colors from "constants/colors";
import {
  formIds,
  financialReportingCodes as formCodes,
} from "constants/formContentManagement";
import { roleGroups } from "constants/user";

import { stableSort } from "utils/data";

import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { useUserContext } from "contexts/UserContext";
import { useYearContext } from "contexts/YearContext";
import { useFilterContext, FilterProvider } from "contexts/FilterContext";

import ReportPageWrapper from "components/ReportPageWrapper";

import { financialsService } from "api/services/financialsService";

import FinancialsForm from "./FinancialsForm";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  pageIntro: {
    margin: "1rem 2rem",
  },
  yearFilter: {
    marginBottom: "1rem",
    padding: "1rem 2rem",
    background: colors.grey.light,
    display: "flex",
    alignItems: "center",
  },
  yearLabel: {
    paddingRight: "1rem",
    fontSize: "0.9rem",
  },
  yearSelect: {
    background: "white",
  },
}));

const YearFilter = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const municipalityId = useMunicipalContext().selectedMunicipalityId;
  const { contextYear } = useYearContext();

  const mounted = useIsMounted();

  const { filters, setFilter, setFilterValue } = useFilterContext();

  useEffect(() => {
    if (!filters.year) {
      setFilter("year", params.year, false);
    }
  }, []);

  const selectedYear = filters?.year?.value || params.year;

  const [years, setYears] = useState([contextYear]);

  // fetch data to populate year select UI
  useEffect(() => {
    const populateYears = async () => {
      const response = await financialsService.getYears(municipalityId);

      if (!mounted.current) {
        return;
      }

      const fetchedYears = response.data.includes(contextYear)
        ? response.data
        : [contextYear, ...response.data];

      // Making sure the years are sorted
      const sortedYears = stableSort(
        fetchedYears,
        (year1, year2) => year1 - year2
      );

      setYears(sortedYears);

      if (!fetchedYears.includes(parseInt(selectedYear, 10))) {
        // If an out of range year is selected, redirect to reporting year
        setFilterValue("year", contextYear);
      }
    };
    if (municipalityId) populateYears();
  }, [municipalityId]);

  useEffect(() => {
    if (selectedYear !== params.year)
      history.push(`/reporting/${selectedYear}/financials`);
  }, [selectedYear]);

  return (
    <div className={classes.yearFilter}>
      <Typography variant="body2" className={classes.yearLabel}>
        Year:
      </Typography>
      <TextField
        variant="outlined"
        value={selectedYear}
        onChange={(event) => setFilterValue("year", event.target.value)}
        select
        className={classes.yearSelect}
      >
        {Object.values(years).map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

/**
 * The financials reporting page.
 *
 * @returns {React.Component} The page component.
 */
const FinancialsPage = () => {
  const classes = useStyles();
  const { hasRoles } = useUserContext();
  const hasFinancialsPermission = hasRoles(roleGroups.municipal.financials);
  const { formFields } = useFormManagement(formIds.financialReporting);

  return (
    <ReportPageWrapper title="Financial Reporting">
      <FilterProvider>
        <Grid
          container
          direction="column"
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <Grid item>
            <Divider />
          </Grid>
          <Grid item className={classes.pageIntro}>
            <Typography variant="body1">
              {formFields[formCodes.descriptionForm]
                ? parse(formFields[formCodes.descriptionForm].text)
                : ""}
            </Typography>
          </Grid>
          <Grid item>
            <YearFilter />
          </Grid>
          <Grid item>
            <FinancialsForm hasPermissions={hasFinancialsPermission} />
          </Grid>
        </Grid>
      </FilterProvider>
    </ReportPageWrapper>
  );
};

export default FinancialsPage;
