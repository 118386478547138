export const formIds = {
  projectReportCategory: 1,
  projectReportGeneralInfo: 2,
  projectReportFinancials: 3,
  projectReportResults: 4,
  insuranceReporting: 5,
  financialReporting: 6,
  submitReport: 7,
  municipalLandingPage: 8,
  municipalUserManagement: 9,
  bankingInformation: 10,
  projectReportCommunications: 11,
};

export const formFieldTypes = {
  description: "Description",
  title: "Title",
  titleWithTooltip: "TitleWithTooltip",
  field: "Field",
  link: "Link",
};

export const projectReportCategoryCodes = {
  descriptionForm: "PRC_DESC_FORM",
  category: "PRC_FLD_CATEGORY",
  subcategory: "PRC_FLD_SUBCATEGORY",
  isProjectEligible: "PRC_LNK_ELIGIBLE",
  descriptionFormSaved: "PRC_DESC_SAVED_FORM",
  changeRequestReason: "PRC_FLD_SAVED_REASON",
};

export const projectReportGeneralInfoCodes = {
  descriptionForm: "PRGI_DESC_FORM",
  titleDetails: "PRGI_TTL_DETAILS",
  descriptionDetails: "PRGI_DESC_DETAILS",
  fieldTitle: "PRGI_FLD_TITLE",
  fieldInternalTitle: "PRGI_FLD_INTERNAL_TITLE",
  fieldObjectives: "PRGI_FLD_OBJECTIVES",
  fieldScope: "PRGI_FLD_SCOPE",
  fieldPrimaryLocation: "PRGI_FLD_PRIMARY_LOCATION",
  fieldSecondaryLocation: "PRGI_FLD_SECONDARY_LOCATION",
  fieldStartDate: "PRGI_FLD_START_DATE",
  fieldEndConstruction: "PRGI_FLD_END_CONSTRUCTION",
  fieldNatureInvestment: "PRGI_FLD_NATURE_INVESTMENT",
  fieldComments: "COMMON_FLD_COMMENTS",
};

export const projectReportFinancialsCodes = {
  descriptionForm: "PRF_DESC_FORM",
  titleInfo: "PRF_TTL_INFO",
  descriptionInfo: "PRF_DESC_INFO",
  fieldFunding: "PRF_FLD_FUNDING",
  titleFundsApplied: "PRF_TTL_FUNDS_APPLIED",
  fieldTotalBudgeted: "PRF_FLD_TOTAL_BUDGETED",
  fieldTotalCost: "PRF_FLD_TOTAL_COST",
  fieldEndOfFinancing: "PRF_FLD_END_FINANCING",
  fieldComments: "COMMON_FLD_COMMENTS",
  fieldOtherFunds: "PRF_FLD_OTHER_FUNDS",
  fieldProvincialFunds: "PRF_FLD_PROVINCIAL_FUNDS",
  fieldStackingLimits: "PRF_FLD_STACKING_LIMITS",
};

export const projectReportCommunicationsCodes = {
  descriptionForm: "PRCOMM_DESC_FORM",
  fieldSignagePosted: "PRCOMM_FLD_SIGNAGE",
  fieldSignageType: "PRCOMM_FLD_SIGNAGE_TYPE",
  fieldSignageIntentions: "PRCOMM_FLD_SIGNAGE_INTENTIONS",
  fieldSignageRationale: "PRCOMM_FLD_SIGNAGE_RATIONALE",
  titleUpload: "PRCOMM_TTL_UPLOAD",
  descriptionUpload: "PRCOMM_DESC_UPLOAD",
  fieldLinks: "PRCOMM_FLD_LINKS",
};

export const projectReportResultsCodes = {
  descriptionForm: "PRR_DESC_FORM",
  descriptionCategory: "PRR_DESC_CATEGORY",
  titleOutputs: "PRR_TTL_OUTPUTS",
  descriptionOutputs: "PRR_DESC_OUTPUTS",
  titleOutcomes: "PRR_TTL_OUTCOMES",
  descriptionOutcomes: "PRR_DESC_OUTCOMES",
  fieldOtherResults: "PRR_FLD_OTHER",
};

export const insuranceReportingCodes = {
  descriptionForm: "IR_DESC_FORM",
};

export const submitReportCodes = {
  outstandingPrerequisiteInstructions: "SR_DESC_OUTSTANDING",
  completePrerequisiteInstructions: "SR_DESC_COMPLETE",
  submitted: "SR_TTL_MODAL_TITLE",
  thanks: "SR_DESC_MODAL_TEXT",
};

export const financialReportingCodes = {
  descriptionForm: "FR_DESC_FORM",
  fldOpeningBalance: "FR_FLD_OPENING_BALANCE",
  fldClosingBalance: "FR_FLD_CLOSING_BALANCE",
  ttlRevenues: "FR_TTLT_REVENUES",
  fldProceedDisposalAssets: "FR_FLD_DISPOSAL_ASSETS",
  fldInterestEarned: "FR_FLD_INTEREST_EARNED",
  fldLessAdministrationCosts: "FR_FLD_ADM_COSTS",
  ttlTransfers: "FR_TTLT_TRANSFERS",
  fldPlusAmountsReceived: "FR_FLD_RECEIVED",
  fldLessAmountsTransferred: "FR_FLD_TRANSFERRED",
  ttlExpenditures: "FR_TTLT_EXPENDITURES",
  ttlUploadBylaw: "FR_TTLT_BYLAW",
  descUploadBylaw: "FR_DESC_BYLAW",
};

export const municipalLandingCodes = {
  bankLimitDescriptionFormStart: "MLP_DESC_BLS_START",
  bankLimitTitleFunds: "MLP_TTL_BLS_FUNDS",
  bankLimitTitleStatus: "MLP_TTL_BLS_STATUS",
  bankLimitTitleExemption: "MLP_TTL_BLS_EXEMPTION",
  bankLimitDescriptionFormEnd: "MLP_DESC_BLS_END",
  complianceDescriptionFormStart: "MLP_DESC_IC_START",
  complianceTitleTargetAvgInvestment: "MLP_TTL_IC_TARGET_AVG_INVESTMENT",
  complianceTitleAvgInvestment: "MLP_TTL_IC_AVG_INVESTMENT",
  complianceTitleStatus: "MLP_TTL_IC_STATUS",
  complianceDescriptionFormEnd: "MLP_DESC_IC_END",
};

export const municipalUserManagementCodes = {
  fieldFirstName: "MUM_FLD_FIRST_NAME",
  fieldLastName: "MUM_FLD_LAST_NAME",
  fieldUsername: "MUM_FLD_USERNAME",
  fieldTitle: "MUM_FLD_TITLE",
  fieldEmail: "MUM_FLD_EMAIL",
  fieldPhone: "MUM_FLD_PHONE",
  fieldPhoneExtension: "MUM_FLD_PHONE_EXTENSION",
  fieldDelegate: "MUM_FLD_DELEGATE",
  fieldContact: "MUM_FLD_CONTACT",
  fieldUserEnabled: "MUM_FLD_ENABLED",
  fieldPermissionFinancial: "MUM_FLD_PERM_FINANCIAL",
  fieldPermissionProject: "MUM_FLD_PERM_PROJECT",
  fieldPermissionCommunications: "MUM_FLD_PERM_COMMUNICATIONS",
  fieldPermissionQuestionnaire: "MUM_FLD_PERM_QUESTIONNAIRE",
};

export const bankingInformationCodes = {
  descriptionLockedForm: "BI_DESC_LOCKED_FORM",
  descriptionVerificationForm: "BI_DESC_VERIFICATION_FORM",
  descriptionEditForm: "BI_DESC_EDIT_FORM",
  fieldContactName: "BI_FLD_CONTACT_NAME",
  fieldContactEmail: "BI_FLD_CONTACT_EMAIL",
  fieldContactPhone: "BI_FLD_CONTACT_PHONE",
  fieldContactPhoneExtension: "BI_FLD_CONTACT_PHONE_EXTENSION",
  fieldFinancialInstitution: "BI_FLD_FINANCIAL_INSTITUTION",
  fieldInstitutionNumber: "BI_FLD_INSTITUTION_NUMBER",
  fieldTransitNumber: "BI_FLD_TRANSIT_NUMBER",
  fieldAccountNumber: "BI_FLD_ACCOUNT_NUMBER",
};
