import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import { actionTypes } from "constants/historyLog";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: colors.grey.light,
    margin: "0.2rem 0",
    padding: "1rem",
    "& p": {
      display: "inline",
    },
  },
  secondLine: {
    marginTop: "0.2rem",
    "& p": {
      fontSize: "0.88rem",
    },
  },
}));

const getMessage = (type, value) => {
  switch (type) {
    case actionTypes.setAssignee:
      return value?.trim() ? (
        <>
          <Typography variant="body1">Assigned to </Typography>
          <Typography variant="body2">{value}</Typography>
        </>
      ) : (
        <>
          <Typography variant="body1">Removed assignee</Typography>
        </>
      );

    case actionTypes.statusSetTo:
    case actionTypes.setStatus:
      return (
        <>
          <Typography variant="body1">Status set to </Typography>
          <Typography variant="body2">{value}</Typography>
        </>
      );

    case actionTypes.publish:
      return (
        <>
          <Typography variant="body1">Marked as </Typography>
          <Typography variant="body2">
            {value === "true" ? "published" : "unpublished"}
          </Typography>
        </>
      );

    case actionTypes.setAnnouncement:
      return (
        <>
          <Typography variant="body1">Announcement date set to </Typography>
          <Typography variant="body2">{value}</Typography>
        </>
      );

    case actionTypes.updateAnnouncement:
      return (
        <>
          <Typography variant="body1">Announcement details updated </Typography>
        </>
      );

    default:
      return "";
  }
};

/**
 * A component to a single history log item
 *
 * @param {object} props - object containing props for this component
 * @param {number} props.id - id of log item
 * @param {string} props.type - type of log
 * @param {string} props.timestamp - log timestamp
 * @param {string} props.user - user that caused the log
 * @param {string} props.value - associated value of the action taken
 * @param {boolean} props.isAmoUser - true if the user that caused the log is an AMO user
 * @param {boolean} props.isCommunicationUser - true if the user is a communication user
 *
 * @returns - The log item component
 */
const HistoryLogChangeItem = (props) => {
  const {
    id,
    type,
    timestamp,
    user,
    value,
    isAmoUser,
    isCommunicationUser,
  } = props;
  const classes = useStyles();
  return (
    <Grid
      key={id}
      container
      direction="column"
      className={classes.mainContainer}
    >
      <Grid item>{getMessage(type, value)}</Grid>
      <Grid item className={classes.secondLine}>
        <Typography variant="body1">
          {DateTime.fromISO(timestamp).toFormat("yyyy-MM-dd hh:mm a")} by{" "}
        </Typography>
        <Typography variant="body2">
          {isAmoUser || isCommunicationUser ? user : "municipality"}
        </Typography>
      </Grid>
    </Grid>
  );
};

// set the prop-types for this component
HistoryLogChangeItem.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  isAmoUser: PropTypes.bool.isRequired,
  isCommunicationUser: PropTypes.bool,
};

HistoryLogChangeItem.defaultProps = {
  isCommunicationUser: false,
};

export default HistoryLogChangeItem;
