import { React, useState } from "react";

import clsx from "clsx";
import parse from "html-react-parser";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  ListItemText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
  formIds,
  submitReportCodes as formCodes,
} from "constants/formContentManagement";
import colors from "constants/colors";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { reporting } from "constants/reporting";
import { SubmitReportIssueTypes } from "constants/SubmitReportIssueTypes";
import { roles } from "constants/user";

import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useUserContext } from "contexts/UserContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { useYearContext } from "contexts/YearContext";

import AmoPopover from "components/AmoPopover";
import AmoFormActions from "components/AmoFormActions";
import NameLink from "components/municipal/NameLink";
import ReportPageWrapper from "components/ReportPageWrapper";

import SubmitReportAccordion from "./SubmitReportAccordion";
import { useAnnualReportStatus, submitAnnualReport } from "./submitReportHooks";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    borderTop: `1px solid ${colors.grey.light}`,
  },
  scrollableContent: {
    overflow: "hidden auto",
  },
  fullWidth: {
    width: "100%",
  },
  issueContent: {
    // 0px 128px 16px 100px
    padding: "0rem 8rem 1rem 6.25rem",
  },
  descriptionContent: {
    // 0px 88px 16px 88px
    padding: "0rem 5.5rem 1rem 5.5rem",
  },
  formActions: {
    // 16px
    backgroundColor: colors.white,
  },
  issueText: {
    // 14px
    fontSize: "0.875rem",
    fontWeight: "bold",
    fontStyle: "italic",
  },
  warningText: {
    // 16px
    fontSize: "1rem",
    color: colors.red.dark,
  },
  loadingProgress: {
    color: colors.grey.light,
  },
  listItemText: {
    "& > span > p": {
      "&:first-child": {
        marginTop: "0rem",
      },
      "&:last-child": {
        marginBottom: "0rem",
      },
      "& > a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontWeight: "bolder",
      },
    },
    "& > span > p > a:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * The "submit report" page.
 *
 * @returns {React.Component} The page component.
 */
const SubmitReportPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { formFields } = useFormManagement(formIds.submitReport, true);
  const { hasRoles, user: currentUser } = useUserContext();
  const { showSnackbar } = useSnackbar();
  const { contextYear, refetch: refetchYearData } = useYearContext();

  const mounted = useIsMounted();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const requirementsCells = [
    {
      key: "requirement-title",
      fieldKey: "title",
      label: "Requirement",
      widthPerc: 40,
      // eslint-disable-next-line react/no-unstable-nested-components -- Required currently for <AmoTable>.
      customRender: (item) => {
        let link;
        switch (item.type) {
          case SubmitReportIssueTypes.insuranceReporting:
            link = "insurance";
            break;
          case SubmitReportIssueTypes.financialReporting:
            link = "financials";
            break;
          case SubmitReportIssueTypes.assetQuestionnaire:
            link = "questionnaires/assets";
            break;
          case SubmitReportIssueTypes.riskQuestionnaire:
            link = "questionnaires/risk";
            break;
          default:
            link = "";
            break;
        }
        return (
          <NameLink
            name={item.title}
            link={`/reporting/${contextYear}/${link}`}
          />
        );
      },
      isCustom: true,
    },
    {
      key: "requirement-issue",
      fieldKey: "issue",
      label: "Issue",
      color: "secondary",
      className: classes.issueText,
      widthPerc: 40,
    },
  ];
  const projectsCells = [
    {
      key: "project-title",
      fieldKey: "id",
      label: "Project",
      widthPerc: 40,
      // eslint-disable-next-line react/no-unstable-nested-components -- Required currently for <AmoTable>.
      customRender: (item) => {
        let title = "";
        if (item.id) {
          title += `${item.id}`;
        }
        if (item.municipalityInternalId) {
          if (title) {
            title += " ";
          }
          title += `(${item.municipalityInternalId})`;
        }
        if (title) {
          title += ": ";
        }
        title += item.title ?? "Untitled Project";

        return (
          <NameLink
            name={title}
            link={`/reporting/${contextYear}/projects/${item.id}`}
          />
        );
      },
      isCustom: true,
    },
    {
      key: "project-issue",
      fieldKey: "issue",
      label: "Issue",
      color: "secondary",
      className: classes.issueText,
      widthPerc: 40,
    },
  ];

  // Query annual report status
  const {
    data: annualReportStatusResult,
    isLoading: isStatusLoading,
  } = useAnnualReportStatus(currentUser?.municipalityId, contextYear);

  const requirements = annualReportStatusResult?.data?.requirements;
  const projects = annualReportStatusResult?.data?.projects;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await submitAnnualReport(currentUser?.municipalityId, contextYear);

      if (!mounted.current) {
        return;
      }

      setModalIsOpen(true);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
    setIsSubmitting(false);
  };

  const handlePopupClose = () => {
    refetchYearData();
    history.push("/");
  };

  const renderSubmitPageContent = () => {
    // Loading
    if (isStatusLoading) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress disableShrink color="primary" />
        </Box>
      );
    }

    const yearWarning =
      contextYear >= reporting.calendarYear ? (
        <Typography
          className={clsx(classes.fullWidth, classes.warningText)}
          variant="body2"
        >
          This annual report can only be submitted on the next calendar year.
        </Typography>
      ) : null;

    // Incomplete
    if (requirements?.length > 0 || projects?.length > 0) {
      return (
        <Grid
          container
          className={classes.issueContent}
          direction="column"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <Typography
              className={classes.fullWidth}
              variant="body1"
              component="span"
            >
              {parse(
                formFields[formCodes.outstandingPrerequisiteInstructions]
                  ?.text || ""
              )}
            </Typography>
            {yearWarning}
          </Grid>
          {requirements?.length > 0 && (
            <Grid item>
              <SubmitReportAccordion
                title="Annual Reporting Requirements"
                data={requirements}
                columns={requirementsCells}
              />
            </Grid>
          )}
          {projects?.length > 0 && (
            <Grid item>
              <SubmitReportAccordion
                title="Project Reporting Requirements"
                data={projects}
                columns={projectsCells}
              />
            </Grid>
          )}
        </Grid>
      );
    }

    return (
      <Box className={classes.descriptionContent}>
        <ListItemText
          className={clsx(classes.fullWidth, classes.listItemText)}
          variant="body1"
          component="span"
        >
          {parse(
            formFields[formCodes.completePrerequisiteInstructions]?.text || ""
          )}
        </ListItemText>
        {yearWarning}
        <AmoPopover
          data-testid="submitReportSuccessPopover"
          variant="success"
          title={formFields[formCodes.submitted]?.text}
          text={formFields[formCodes.thanks]?.text}
          showButton
          buttonText="Continue"
          buttonFunction={handlePopupClose}
          buttonVariant="contained"
          open={modalIsOpen}
          closePopover={handlePopupClose}
          width="18.75rem"
        />
      </Box>
    );
  };

  const formActions = [
    {
      disabled:
        !hasRoles([
          roles.municipalTreasurerDelegate,
          roles.municipalTreasurer,
        ]) ||
        requirements?.length > 0 ||
        projects?.length > 0 ||
        contextYear >= reporting.calendarYear ||
        isSubmitting ||
        isStatusLoading,
      testId: "submitReportButton",
      children: isSubmitting ? (
        <CircularProgress className={classes.loadingProgress} size={28} />
      ) : (
        "Submit Report"
      ),
      onClick: handleSubmit,
    },
  ];

  return (
    <ReportPageWrapper title={`Close Year: ${contextYear}`}>
      <Grid
        container
        className={classes.root}
        direction="column"
        spacing={0}
        wrap="nowrap"
      >
        <Grid item xs className={classes.scrollableContent}>
          {renderSubmitPageContent()}
        </Grid>
        <Grid item>
          <AmoFormActions
            className={classes.formActions}
            actions={formActions}
          />
        </Grid>
      </Grid>
    </ReportPageWrapper>
  );
};

export default SubmitReportPage;
