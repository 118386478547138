import React from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { useGlobalStyles } from "hooks/globalStylesHook";
import PropTypes from "prop-types";

export const nameLinkCss = {
  rowFont: {
    fontSize: "0.875rem",
  },
};

const useStyles = makeStyles((theme) => ({
  rowFont: nameLinkCss.rowFont,
}));

/**
 * Project name link to navigate to the project.
 *
 * @param {object} props
 * @param {string | React.component} props.name The name of the project
 * @param {string} props.link The link to the project
 * @param {Function} props.onKeyDown Handle KeyPress action
 * @param {boolean} props.removeSearch Whether to remove the current location search from link
 * @returns {React.Component} A project name link component.
 */
const NameLink = ({ name, link, removeSearch, onKeyDown }) => {
  const classes = useStyles();
  const { search } = useLocation();
  const globalClasses = useGlobalStyles();

  return (
    <Link
      to={removeSearch ? link : `${link}${search}`}
      onKeyDown={onKeyDown}
      className={globalClasses.tableActionCell}
    >
      <Typography className={classes.rowFont} color="primary" variant="body2">
        {name}
      </Typography>
    </Link>
  );
};

NameLink.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  link: PropTypes.string.isRequired,
  removeSearch: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

NameLink.defaultProps = {
  removeSearch: false,
  onKeyDown: null,
};

export default NameLink;
