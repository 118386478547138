import { useQuery } from "react-query";
import { projectService } from "api/services/projectService";

// Query Keys
import { projectKeys } from "./projectQueryKeys";

/**
 * Store server's state of project general information form
 *
 * @param {string} projectId The id of the project being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectInformationData = (projectId) => {
  const selectProjectInformationData = (data) => {
    const projectInformationData = data?.data;

    const primaryLocation = projectInformationData?.locations?.find(
      (obj) => obj.isPrimary
    );
    const secondaryLocations = projectInformationData?.locations?.filter(
      (obj) => !obj.isPrimary
    );

    const reducedFlags =
      projectInformationData?.flags?.reduce(
        (map, obj) => ({
          ...map,
          [obj.fieldCode]: {
            id: obj.id,
            ownerName: obj.updatedByName,
            ownerEmail: obj.updatedByEmail,
            comment: obj.message,
          },
        }),
        {}
      ) || {};

    return {
      ...data,
      data: {
        ...projectInformationData,
        title: projectInformationData?.title ?? "",
        internalId: projectInformationData?.internalId ?? "",
        objectives: projectInformationData?.objectives ?? "",
        scopeOfWork: projectInformationData?.scopeOfWork ?? "",
        investmentTypeId: projectInformationData?.investmentTypeId ?? "",
        signagePosted: projectInformationData?.signagePosted ?? "",
        additionalComments: projectInformationData?.additionalComments ?? "",
        communicationsActivities:
          projectInformationData?.communicationsActivities ?? "",
        primaryLocation: primaryLocation
          ? {
              id: primaryLocation.id,
              position: {
                lat: primaryLocation.latitude,
                lng: primaryLocation.longitude,
              },
              address: `${primaryLocation.latitude?.toFixed(
                5
              )}, ${primaryLocation.longitude?.toFixed(5)}`,
            }
          : null,
        secondaryLocations:
          secondaryLocations?.map((item) => ({
            id: item.id,
            position: {
              lat: item.latitude,
              lng: item.longitude,
            },
            address: `${item.latitude?.toFixed(5)}, ${item.longitude?.toFixed(
              5
            )}`,
          })) || [],
        flags: reducedFlags,
      },
    };
  };

  return useQuery(
    projectKeys.projectInformation(projectId),
    () => projectService.getInformationData(projectId),
    {
      select: selectProjectInformationData,
      enabled: !!projectId && projectId !== "new",
    }
  );
};
