import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  name: string }} FileItem
 */

/**
 * @typedef {{
 *  id: number,
 *  year: number,
 *  received: string,
 *  coverageStart: string,
 *  coverageEnd: string,
 *  status: string,
 *  notes: string,
 *  file: FileItem }} InsuranceListItem
 */

const makeAPI = (municipalityId) =>
  new Api(`api/Municipalities/${municipalityId}/insurance`);
export const insuranceService = {
  /**
   * @param {number} municipalityId
   * @returns {Promise<AxiosResponse<InsuranceListItem[]>>}
   */
  getAll: (municipalityId) => makeAPI(municipalityId).get(),
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @returns {Promise<AxiosResponse<InsuranceListItem>>}
   */
  get: (municipalityId, year) => makeAPI(municipalityId).getById(year),
  /**
   * @param {number} municipalityId
   * @param {InsuranceListItem} payload
   * @returns {Promise<AxiosResponse<number>>}
   */
  post: (municipalityId, payload) => makeAPI(municipalityId).post(payload),
  /**
   * @param {number} municipalityId
   * @param {InsuranceListItem} payload
   * @returns {Promise<AxiosResponse<InsuranceListItem>>}
   */
  put: (municipalityId, payload) =>
    makeAPI(municipalityId).axios.put("", payload),
};
