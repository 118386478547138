import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 * yearStart: string,
 * yearEnd: string,
 * reportingDueDate: string,
 * thresholdDays: number,
 * paymentDates: string[],
 * totalProjects: number,
 * completeProjects: number }} ReportStatusWidgetListItem
 */

const api = new Api("api/Municipalities/");
export const reportStatusWidgetService = {
  /**
   * @param {number} municipalId
   *
   * @returns {Promise<AxiosResponse<ReportStatusWidgetListItem>>}
   */
  getById: (municipalId) => api.axios.get(`${municipalId}/status/widget`),
};
