import { makeStyles } from "@material-ui/core/styles";
import { mobileBreakpoint } from "constants/viewport";
import colors from "constants/colors";

/**
 * A style sharing hook to reuse CSS classes across different components.
 *
 * Usage: Import the hook into any component, call it, and access the defined class names.
 *
 * @example
 * import { useGlobalStyles } from "hooks/globalStylesHook";
 * const ExampleComponent = () => {
 *  const globalClasses = useGlobalStyles();
 *  return <div className={globalClasses.exampleClass}>Example content</div>;
 * }
 */
export const useGlobalStyles = makeStyles((theme) => ({
  pageBody: {
    // 16px 25px
    padding: "1rem 1.563rem 0rem 1.563rem",
    display: "flex",
    flexGrow: 1,
    overflow: "auto",
    maxHeight: "calc(100vh - 4.5rem)",
  },
  editPageBodyContainer: {
    overflow: "hidden auto",
  },
  editPageBody22: {
    [theme.breakpoints.down("md")]: {
      padding: "0rem 0rem 0rem 0rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0rem 11rem 0rem 11rem",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0rem 22rem 0rem 22rem",
    },
    [`@media (max-width:${mobileBreakpoint.width - 1}px)`]: {
      padding: "0rem 1rem 0rem 1rem",
    },
  },
  editPageBody16: {
    padding: "0rem 16rem 0rem 16rem",
    [`@media (max-width:${mobileBreakpoint.width - 1}px)`]: {
      padding: "0rem 1rem 0rem 1rem",
    },
  },
  editPageBody17: {
    padding: "0rem 17rem 0rem 17rem",
    [`@media (max-width:${mobileBreakpoint.width - 1}px)`]: {
      padding: "0rem 1rem 0rem 1rem",
    },
  },
  editPageBodyText: {
    marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 25px
      paddingBottom: "1.563rem",
      "&:last-child": {
        // 16px
        paddingBottom: "1rem",
      },
      "& > .MuiGrid-container > .MuiGrid-item:last-child": {
        // 48px
        paddingLeft: "3rem",
      },
    },
  },
  editPageBodyTextNoLeftPadding: {
    marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 25px
      paddingBottom: "1.563rem",
      "&:last-child": {
        // 16px
        paddingBottom: "1rem",
      },
    },
  },
  editPageBodyTextFirstChildPadding: {
    marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "& > .MuiGrid-container > .MuiGrid-item": {
        // 48px
        paddingLeft: "3rem",
        "&:first-child": {
          paddingLeft: "0rem",
        },
      },
    },
  },
  editPageBodyMargin: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
  editPageBodyPadding: {
    // 20px
    paddingTop: "1.25rem",
    // 32px
    paddingBottom: "2rem",
  },
  button: {
    minWidth: "15.813rem",
    minHeight: "3.125rem",
    fontSize: "1rem",
    borderWidth: "0.125rem",
    "&:hover": {
      borderWidth: "0.125rem",
    },
  },
  tableActionCell: {
    display: "block",
    color: colors.green.main,
    justifyContent: "flex-start",
    textDecoration: "none",
    fontWeight: "bold",
    textAlign: "left",
    borderRadius: "5px",
    fontSize: "0.875rem",
    padding: "0.5rem 0.1rem",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    "&:focus": {
      textDecoration: "underline",
      backgroundColor: `${colors.green.main}20`,
      cursor: "pointer",
      outline: "none",
    },
  },
}));
