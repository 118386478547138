import { communicationDashboardService } from "api/services/communicationDashboardService";
import { useQuery } from "react-query";
import { communicationDashboardQueryKeys } from "./communicationDashboardQueryKeys";

const { getProjectStatus } = communicationDashboardService;

/**
 * Store server's state of projects reporting status
 *
 * @param {number} year The year of the projects being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectStatus = (year) => {
  const selectProjectStatus = ({ data }) => [
    { name: "Considering", value: data?.considering ?? 0 },
    { name: "Planning", value: data?.planning ?? 0 },
    { name: "On Hold", value: data?.onHold ?? 0 },
    { name: "Ready", value: data?.ready ?? 0 },
  ];

  return useQuery(
    communicationDashboardQueryKeys.projects(),
    () => getProjectStatus(),
    {
      select: selectProjectStatus,
    }
  );
};
