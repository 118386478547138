import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spacer: {
    paddingTop: "0.313rem",
    paddingBottom: "0.313rem",
  },
}));

/**
 * Produces a list of notification placeholders.
 *
 * @param {object} props
 * @param {string} props.title The title of the notification palceholders.
 * @param {Array} props.placeholders A list of placeholders.
 *
 * @returns {React.Component} Notification Placeholders component.
 */
const NotificationPlaceholders = (props) => {
  const classes = useStyles();
  const { title, placeholders } = props;
  return (
    <Grid container direction="column" spacing={0} wrap="nowrap">
      <Grid item>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item className={classes.spacer} />
      {placeholders.map(({ id, text, description }) => (
        <Grid key={id} item>
          <Typography>
            <b>{text}</b> {description && ` - ${description}`}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

NotificationPlaceholders.propTypes = {
  title: PropTypes.string.isRequired,
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ),
};

NotificationPlaceholders.defaultProps = {
  placeholders: [],
};

export default NotificationPlaceholders;
