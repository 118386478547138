import { useQuery } from "react-query";

import { reportingStatusReviewsQueryKeys } from "./reportingStatusReviewsQueryKeys";
import { reviewService } from "api/services/reviewsService";
import { municipalityService } from "api/services/municipalityService";

/**
 * Store server's state of reporting status results
 *
 * @param {number} year
 * @returns The query object returned by the useQuery hook
 */
export const useReportingStatusReviewsHooks = (year) =>
  useQuery(
    reportingStatusReviewsQueryKeys.reportingStatusReviews(year),
    () => reviewService.getReportingStatusReviews(year),
    {
      select: ({ data }) => data,
      enabled: !!year,
    }
  );

/**
 * Store server's state of the municipality contacts
 *
 * @param municipalityId The id of the municipality to look for
 * @returns The query object returned by the useQuery hook
 */
export const useMunicipalityContacts = (municipalityId) =>
  useQuery(
    reportingStatusReviewsQueryKeys.reportingStatusContacts(municipalityId),
    () => municipalityService.getMunicipalityContacts(municipalityId),
    {
      select: ({ data }) => data,
    }
  );
