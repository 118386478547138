import { useQuery } from "react-query";
import { financialsService } from "api/services/financialsService";

// Query Keys
import { financialsKeys } from "./financialQueryKeys";

/**
 * Store server's state of financials reports
 *
 * @param {number} municipalityId The id of the municipality being fetched
 * @param {number} year The year of the financials being fetched
 * @param {boolean} shouldFetch Whether the the data should be fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useFinancialReport = (municipalityId, year, shouldFetch) =>
  useQuery(
    financialsKeys.financialReport(municipalityId, year),
    () => financialsService.getByYear(municipalityId, year),
    {
      select: ({ data }) => data,
      enabled: municipalityId && year && shouldFetch,
    }
  );
