import React from "react";
import PropTypes from "prop-types";
import { customRound } from "utils/number";
import { Box, Grid } from "@material-ui/core";

/**
 * AMO Reporting Status Bar Component.
 *
 * @param {object} props
 * @param {any[]} props.statuses The reporting statuses
 * @returns {React.Component} AMO Reporting Status Bar component.
 */
const ReportingStatusBar = ({ statuses }) => {
  const total = statuses.reduce((acc, { amount }) => amount + acc, 0);

  return (
    <>
      <Box
        borderRadius="1.3rem"
        height="2.625rem"
        width="68rem"
        display="inline-block"
        overflow="hidden"
      >
        <Grid container spacing={0} wrap="nowrap">
          {statuses.map(({ amount, color, status }) => (
            <Grid
              item
              style={{ flexGrow: customRound((100 * amount) / total, 2) }}
              key={status}
            >
              <Box
                height="2.625rem"
                width="100%"
                style={{ backgroundColor: color }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

ReportingStatusBar.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      amount: PropTypes.number,
      color: PropTypes.string,
    })
  ).isRequired,
};

export default ReportingStatusBar;
