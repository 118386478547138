import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  date: string,
 *  amount: number }} NextPayment
 */

/**
 * @typedef {{
 *  openingBalance: number,
 *  expenditures: number,
 *  transfers: number,
 *  closingBalance: number,
 *  nextPayment: NextPayment }} FileResponse
 */

const api = new Api("api/Municipalities");
export const financialStatusService = {
  /**
   * @param {number} municipalityId
   * @returns {Promise<AxiosResponse<FileResponse>>}
   */
  getById: (municipalityId) =>
    api.getById(`${municipalityId}/landing/financials`),
};
