import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, ListItemText, MenuItem } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import FormInputWrapper from "components/municipal/FormInputWrapper";
import AmoFormActions from "components/AmoFormActions";
import AmoTextField from "components/inputs/AmoTextField";
import FileUploadList from "components/FileUploadList";
import colors from "constants/colors";
import { useProjectCommunicationsData } from "./projectReportCommunicationsHooks";
import {
  formIds,
  projectReportCommunicationsCodes as formCodes,
} from "constants/formContentManagement";
import { projectService } from "api/services/projectService";
import { steps, formStatuses } from "constants/projectReport";
import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { roleGroups, roles } from "constants/user";
import { fileAssociations } from "constants/fileTypes";
import { range } from "utils/number";
import {
  signagePostedTexts,
  signagePostedValues,
} from "constants/signagePostedValues";
import {
  signageTypeTexts,
  signageTypeValues,
} from "constants/signageTypeValues";
import {
  signageIntentionsTexts,
  signageIntentionsValues,
} from "constants/signageIntentionsValues";
import {
  signageRationaleTexts,
  signageRationaleValues,
} from "constants/signageRationaleValues";
import { useProjectFunds } from "./projectReportFinancialsHooks";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { useNavigationHelper } from "hooks/navigationHook";
import {
  deleteButtonTooltipText,
  deleteType,
} from "functions/projectDeleteMunicipalUtils";
import DeletionType from "enums/deletionType";
import DeletionModal from "components/DeletionModal";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  body: {
    flexGrow: 1,
    flexBasis: 0,
    // 0px 80px 0px 80px
    padding: "0rem 5rem 0rem 5rem",
  },
  scrollableContent: {
    overflow: "hidden auto",
  },
  contentPadding: {
    // 0px 40px 0px 40px
    padding: "0rem 2.5rem 0rem 2.5rem",
  },
  topMargin: {
    // 24px
    marginTop: "1.5rem",
  },
  fileUploadTopMargin: {
    // 40px
    marginTop: "2.5rem",
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    // 8px
    paddingBottom: "0.5rem",
  },
  outlinedButton: {
    backgroundColor: "transparent",
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
  formActions: {
    // 16px
    marginTop: "1rem",
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 24px 0px
    margin: "1.5rem 0rem",
  },
  warning: {
    "& label.Mui-focused": {
      color: colors.yellow,
    },
    "& legend.Mui-focused": {
      color: colors.yellow,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root:not(.Mui-disabled)": {
      "& fieldset": {
        borderColor: colors.yellow,
      },
      "&:hover fieldset": {
        borderColor: colors.yellow,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.yellow,
      },
      "& .MuiInputAdornment-root button": {
        color: colors.yellow,
      },
    },
  },
  // Remove spinner from number input
  numberTextField: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  inputIcon: {
    color: colors.grey.dark,
  },
  listItemText: {
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontWeight: "bolder",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * A form component for editing Project Report Financials
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.projectId - sets the project id used to load the project data, pass 'new' for new project [required]
 * @param {number} props.activeStep - sets the active step of the project report [required]
 * @param {function(formStatuses)} props.onFormStatusChange - function called when the form status changes
 * @param {function()} props.onNextStepRequest - function called when move to the next step is requested
 * @param {function(boolean)} props.onUnsavedChanges - function called when project unsaved data changes
 * @param {Function} props.onDataChange - function called when data changes
 * @param {boolean} props.isPastConstructionEndDate - boolean to handle disabling controls when past end of construction date.
 * @param {Date} props.endOfConstructionDate - end of construction date to determine if show new signage fields
 * @param {boolean} props.projectDeletionRequested - boolean to handle disabling deletion button if the request was already sent.
 * @param {Function} props.refetchValidationInfo - refetch Validation Info to update projectDeletionRequested value.
 *
 * @returns - The form component
 */
const ProjectReportCommunicationsForm = (props) => {
  const {
    projectId,
    activeStep,
    onFormStatusChange,
    onNextStepRequest,
    onUnsavedChanges,
    onDataChange,
    isPastConstructionEndDate,
    endOfConstructionDate,
    projectDeletionRequested,
    refetchValidationInfo,
  } = props;

  // New signage fields were included to be validated on endOfConstructionDate 2023 and onwards
  const includeNewSignageFields =
    endOfConstructionDate === null ||
    (endOfConstructionDate &&
      DateTime.fromISO(endOfConstructionDate).year >= 2023);

  const classes = useStyles();
  const { hasRoles } = useUserContext();
  const hasCommunicationsPermission = hasRoles(
    roleGroups.municipal.communications
  );
  const isTreasurerOrDelegate = hasRoles(
    roleGroups.municipal.treasurerOrDelegate
  );
  const { navigateBack } = useNavigationHelper();
  const { formFields } = useFormManagement(
    formIds.projectReportCommunications,
    true
  );
  const { showSnackbar } = useSnackbar();
  const { user: currentUser } = useUserContext();
  const { getMunicipality } = useMunicipalContext();

  const mounted = useIsMounted();

  const [flags, setFlags] = useState({});
  const [files, setFiles] = useState([]);
  const [formWasReseted, setFormWasReseted] = useState(false);
  const [fileIsLoaded, setFileIsLoaded] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const hasMunicipalRole = currentUser.roles.includes(roles.municipal);

  // Query project general information data
  const {
    data: communicationsData,
    refetch: refetchCommunicationsData,
    isFetched,
  } = useProjectCommunicationsData(projectId);

  const getFieldLabel = (code, defaultLabel = "This field") =>
    formFields[code]?.text || defaultLabel;

  const generateErrorMessage = (textValues) =>
    `Please choose one of the following: ${Object.values(textValues).join(
      ", "
    )}`;

  // define schema for form validation
  const ProjectInformationValidationSchema = yup
    .object({
      signagePosted: yup
        .mixed()
        .oneOf(["", ...Object.values(signagePostedValues)]),
      signageType: yup
        .mixed()
        .when("signagePosted", (signagePosted, schema) =>
          signagePosted === signagePostedValues.Posted &&
          includeNewSignageFields
            ? schema
                .oneOf(Object.values(signageTypeValues), () =>
                  generateErrorMessage(signageTypeTexts)
                )
                .required()
            : schema.nullable()
        ),
      signageIntentions: yup
        .mixed()
        .when("signagePosted", (signagePosted, schema) =>
          signagePosted === signagePostedValues["Not posted"] &&
          includeNewSignageFields
            ? schema
                .oneOf(Object.values(signageIntentionsValues), () =>
                  generateErrorMessage(signageIntentionsTexts)
                )
                .required()
            : schema.nullable()
        ),
      signageRationale: yup
        .mixed()
        .when("signageIntentions", (signagePosted, schema) =>
          signageIntentions ===
            signageIntentionsValues["Signage will not be posted"] &&
          includeNewSignageFields
            ? schema
                .oneOf(Object.values(signageRationaleValues), () =>
                  generateErrorMessage(signageRationaleTexts)
                )
                .required()
            : schema.nullable()
        ),
      communicationsActivities: yup
        .string()
        .nullable()
        .test(
          "communications-activities-validation-len",
          "Must be 300 characters or less",
          (val) => val.length <= 300
        ),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    signagePosted: "",
    signageType: "",
    signageIntentions: "",
    signageRationale: "",
    communicationsActivities: "",
  };

  // Query project funds for deleteion
  const { data: fundsResult } = useProjectFunds(projectId, false);
  const reportingYear = getMunicipality()?.reportingYear;
  const hasPriorExpenditures = fundsResult?.data?.funds.some(
    ({ year }) => year < reportingYear
  );

  // returns projectDeletionRequested in Deletion Requested or empty string to be in line with the same value from ProjectListPage.jsx
  const projectDeletionRequestedInString = projectDeletionRequested
    ? "Deletion Requested"
    : "";

  const getDeleteType = () => {
    const isRequestDeletion =
      deleteType(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ) === DeletionType.REQUEST;

    return {
      text: isRequestDeletion ? "Request Deletion" : "Delete Project",
      action: isRequestDeletion ? refetchValidationInfo : navigateBack,
    };
  };

  const { control, handleSubmit, reset, formState, trigger, watch } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(ProjectInformationValidationSchema),
    context: {
      isMunicipalUser: hasMunicipalRole,
    },
  });

  const { isValid, isSubmitting, isDirty } = formState;

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    if (communicationsData) {
      reset({
        ...communicationsData,
        signagePosted: communicationsData.signagePosted ?? "",
        signageType: communicationsData.signageType ?? "",
        signageIntentions: communicationsData.signageIntentions ?? "",
        signageRationale: communicationsData.signageRationale ?? "",
        communicationsActivities:
          communicationsData.communicationsActivities ?? "",
      });
      setFlags(communicationsData.flags || {});
      setFiles(communicationsData.files || []);
      setFileIsLoaded(true);

      const touched = projectWasTouched(communicationsData);

      handleFormStatusUpdate(
        getFormStatus(communicationsData, communicationsData.flags, touched)
      );
      setFormWasReseted(true);

      onDataChange(communicationsData);
    }
  }, [communicationsData]);

  useEffect(() => {
    if (formWasReseted && communicationsData) {
      setFormWasReseted(false);
      trigger();
    }
  }, [formWasReseted, communicationsData]);

  const shouldRefetchFiles = useRef(false);

  const handlePromptConfirmation = async (save, event) => {
    if (save) {
      const isFormValid = await trigger();

      if (!mounted.current) {
        return false;
      }

      if (isFormValid) {
        // Need to call handleSubmit passing the event or null when calling outside a UI interaction
        handleSubmit(saveProjectData)(event);
      } else {
        showSnackbar("Unable to save due to invalid data", snackbarTypes.error);
        return false;
      }
    } else if (shouldRefetchFiles.current) {
      refetchCommunicationsData();
    } else {
      reset();
    }

    return true;
  };

  useEffect(() => {
    if (activeStep === steps.communications) {
      onUnsavedChanges(
        isDirty,
        !isValid,
        isDirty ? handlePromptConfirmation : null
      );
    }
  }, [isDirty, isValid]);

  const isTouched = (field) =>
    field !== "" && field !== null && field !== undefined;

  const getFormStatus = (projectRef, flagsRef, projectWasSavedRef) => {
    if (flagsRef && Object.entries(flagsRef).length > 0) {
      return formStatuses.flagged;
    }

    if (isPastConstructionEndDate) {
      return formStatuses.completed;
    }

    if (projectWasSavedRef) {
      if (
        !isTouched(projectRef.signagePosted) ||
        (includeNewSignageFields &&
          ((projectRef.signagePosted === signagePostedValues.Posted &&
            !isTouched(projectRef.signageType)) ||
            (projectRef.signagePosted === signagePostedValues["Not posted"] &&
              !isTouched(projectRef.signageIntentions)) ||
            (projectRef.signagePosted === signagePostedValues["Not posted"] &&
              projectRef.signageIntentions ===
                signageIntentionsValues["Signage will not be posted"] &&
              !isTouched(projectRef.signageRationale))))
      ) {
        return formStatuses.incomplete;
      }
      return formStatuses.completed;
    }

    return formStatuses.untouched;
  };

  const projectWasTouched = (projectRef) =>
    isTouched(projectRef?.signagePosted) ||
    isTouched(projectRef?.signageType) ||
    isTouched(projectRef?.signageIntentions) ||
    isTouched(projectRef?.signageRationale) ||
    projectRef?.communicationsActivities ||
    (projectRef?.files && projectRef.files.length > 0);

  /**
   * Form status change based on @see {formStatuses}
   *
   * @param {number} newFormStatus Form status number to be assigned
   */
  const handleFormStatusUpdate = (newFormStatus) => {
    onFormStatusChange(newFormStatus);
  };

  const handleFilesChange = (value) => {
    setFiles(value);
    if (!isDirty) {
      refetchCommunicationsData();
      shouldRefetchFiles.current = false;
    } else {
      shouldRefetchFiles.current = true;
    }
  };

  const saveProjectData = async ({
    signagePosted,
    signageType,
    signageIntentions,
    signageRationale,
    communicationsActivities,
  }) => {
    try {
      const payload = { id: projectId };

      if (isTouched(signagePosted)) {
        payload.signagePosted = signagePosted;
      }
      if (includeNewSignageFields) {
        if (
          signagePosted === signagePostedValues.Posted &&
          isTouched(signageType)
        ) {
          payload.signageType = signageType;
        }
        if (signagePosted === signagePostedValues["Not posted"]) {
          if (isTouched(signageIntentions)) {
            payload.signageIntentions = signageIntentions;
          }
          if (
            signageIntentions ===
              signageIntentionsValues["Signage will not be posted"] &&
            isTouched(signageRationale)
          ) {
            payload.signageRationale = signageRationale;
          }
        }
      }
      if (communicationsActivities) {
        payload.communicationsActivities = communicationsActivities;
      }

      await projectService.updateCommunications(payload.id, payload);
      shouldRefetchFiles.current = false;

      if (!mounted.current) {
        return;
      }

      showSnackbar("Communications updated successfully.");
      refetchCommunicationsData();
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const renderInputWrapper = (
    id,
    testId,
    fieldCode,
    children,
    fullWidth = false,
    hideIconsSpacing = false
  ) => (
    <FormInputWrapper
      id={`${id}-wrapper`}
      testId={`${testId}Wrapper`}
      tooltip={!!formFields[fieldCode]?.tooltipText}
      tooltipProps={
        formFields[fieldCode]?.tooltipText
          ? {
              title: formFields[fieldCode]?.text,
              text: formFields[fieldCode]?.tooltipText,
            }
          : {}
      }
      flag={!!flags[fieldCode]}
      flagProps={
        flags[fieldCode]
          ? {
              ...flags[fieldCode],
              title: formFields[fieldCode]?.text,
            }
          : {}
      }
      hideTooltipSpacing={hideIconsSpacing}
      hideFlagSpacing={hideIconsSpacing}
      childrenFullWidth={fullWidth}
      justifyContent="center"
    >
      {children}
    </FormInputWrapper>
  );

  const renderFormActions = () => [
    {
      testId: "projReportSaveButton",
      label: "Save",
      disabled:
        !isDirty || isSubmitting || !isValid || !hasCommunicationsPermission,
      onClick: handleSubmit(saveProjectData),
    },
    {
      testId: "projReportNextButton",
      label: "Next",
      variant: "outlined",
      className: classes.outlinedButton,
      onClick: onNextStepRequest,
    },
    {
      testId: "projReportDeleteButton",
      label: getDeleteType().text,
      variant: "outlined",
      color: "secondary",
      disabled: !isTreasurerOrDelegate || projectDeletionRequested,
      onClick: () => setShowDeletionModal(true),
      tooltipText: deleteButtonTooltipText(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ),
    },
  ];

  const signagePosted = watch("signagePosted");
  const signageIntentions = watch("signageIntentions");

  const deleteModalClose = () => {
    setShowDeletionModal(false);
  };

  return (
    <>
      <DeletionModal
        open={showDeletionModal}
        id={parseInt(projectId, 10)}
        closeFunction={deleteModalClose}
        updateFunction={getDeleteType().action}
        deletionType={deleteType(
          isTreasurerOrDelegate,
          hasPriorExpenditures,
          projectDeletionRequestedInString
        )}
      />
      {!isFetched &&
        projectId !== "new" &&
        range(0, 9).map((index) => (
          <Skeleton key={index} width="100%" height="8rem" />
        ))}
      {(isFetched || projectId === "new") && (
        <Grid
          container
          className={classes.root}
          direction="column"
          spacing={0}
          wrap="nowrap"
        >
          <Grid item xs className={classes.scrollableContent}>
            <Grid
              container
              className={classes.body}
              direction="column"
              spacing={0}
              alignItems="stretch"
              wrap="nowrap"
            >
              <Grid item className={classes.contentPadding}>
                <ListItemText className={classes.listItemText}>
                  {parse(formFields[formCodes.descriptionForm]?.text || "")}
                </ListItemText>
              </Grid>
              <Grid item className={classes.contentPadding}>
                <Typography variant="h4" noWrap className={classes.title}>
                  {formFields[formCodes.titleUpload]?.text}
                </Typography>
              </Grid>
              <Grid item className={classes.contentPadding}>
                <ListItemText className={classes.listItemText}>
                  {parse(formFields[formCodes.descriptionUpload]?.text || "")}
                </ListItemText>
              </Grid>
              <Grid item className={classes.topMargin}>
                <Grid container spacing={10}>
                  <Grid item xs>
                    {renderInputWrapper(
                      "proj-report-signage-posted-field",
                      "projReportSignagePostedField",
                      formCodes.fieldSignagePosted,
                      <AmoTextField
                        disabled={
                          !hasCommunicationsPermission ||
                          (!flags[formCodes.fieldSignagePosted] &&
                            isPastConstructionEndDate)
                        }
                        control={control}
                        id="proj-report-signage-posted-field"
                        name="signagePosted"
                        testId="projReportSignagePostedField"
                        className={clsx(
                          classes.fullWidth,
                          flags[formCodes.fieldSignagePosted]
                            ? classes.warning
                            : null
                        )}
                        select
                        label={getFieldLabel(formCodes.fieldSignagePosted, "")}
                        variant="outlined"
                        required
                        helperText={
                          formFields[formCodes.fieldSignagePosted]?.helperText
                        }
                      >
                        {Object.values(signagePostedValues).map((value) => (
                          <MenuItem
                            key={`projReportSignagePostedField-${value}`}
                            value={value}
                          >
                            {signagePostedTexts[value]}
                          </MenuItem>
                        ))}
                      </AmoTextField>,
                      true
                    )}
                  </Grid>
                  <Grid item xs />
                </Grid>
                {includeNewSignageFields &&
                  signagePosted === signagePostedValues.Posted && (
                    <Grid container spacing={10}>
                      <Grid item xs>
                        {renderInputWrapper(
                          "proj-report-signage-type-field",
                          "projReportSignageTypeField",
                          formCodes.fieldSignageType,
                          <AmoTextField
                            disabled={
                              !hasCommunicationsPermission ||
                              (!flags[formCodes.fieldSignageType] &&
                                isPastConstructionEndDate)
                            }
                            control={control}
                            id="proj-report-signage-type-field"
                            name="signageType"
                            testId="projReportSignageTypeField"
                            className={clsx(
                              classes.fullWidth,
                              flags[formCodes.fieldSignageType]
                                ? classes.warning
                                : null
                            )}
                            select
                            label={getFieldLabel(
                              formCodes.fieldSignageType,
                              ""
                            )}
                            variant="outlined"
                            required
                            helperText={
                              formFields[formCodes.fieldSignageType]?.helperText
                            }
                          >
                            {Object.values(signageTypeValues).map((value) => (
                              <MenuItem
                                key={`projReportSignageTypeField-${value}`}
                                value={value}
                              >
                                {signageTypeTexts[value]}
                              </MenuItem>
                            ))}
                          </AmoTextField>,
                          true
                        )}
                      </Grid>
                      <Grid item xs />
                    </Grid>
                  )}
                {includeNewSignageFields &&
                  signagePosted === signagePostedValues["Not posted"] && (
                    <>
                      <Grid container spacing={10}>
                        <Grid item xs>
                          {renderInputWrapper(
                            "proj-report-signage-intentions-field",
                            "projReportSignageIntentionsField",
                            formCodes.fieldSignageIntentions,
                            <AmoTextField
                              disabled={
                                !hasCommunicationsPermission ||
                                (!flags[formCodes.fieldSignageIntentions] &&
                                  isPastConstructionEndDate)
                              }
                              control={control}
                              id="proj-report-signage-intentions-field"
                              name="signageIntentions"
                              testId="projReportSignageIntentionsField"
                              className={clsx(
                                classes.fullWidth,
                                flags[formCodes.fieldSignageIntentions]
                                  ? classes.warning
                                  : null
                              )}
                              select
                              label={getFieldLabel(
                                formCodes.fieldSignageIntentions,
                                ""
                              )}
                              variant="outlined"
                              required
                              helperText={
                                formFields[formCodes.fieldSignageIntentions]
                                  ?.helperText
                              }
                            >
                              {Object.values(signageIntentionsValues).map(
                                (value) => (
                                  <MenuItem
                                    key={`projReportSignageIntentionsField-${value}`}
                                    value={value}
                                  >
                                    {signageIntentionsTexts[value]}
                                  </MenuItem>
                                )
                              )}
                            </AmoTextField>,
                            true
                          )}
                        </Grid>
                        <Grid item xs />
                      </Grid>
                      {signageIntentions ===
                        signageIntentionsValues[
                          "Signage will not be posted"
                        ] && (
                        <Grid container spacing={10}>
                          <Grid item xs>
                            {renderInputWrapper(
                              "proj-report-signage-rationale-field",
                              "projReportSignageRationaleField",
                              formCodes.fieldSignageRationale,
                              <AmoTextField
                                disabled={
                                  !hasCommunicationsPermission ||
                                  (!flags[formCodes.fieldSignageRationale] &&
                                    isPastConstructionEndDate)
                                }
                                control={control}
                                id="proj-report-signage-rationale-field"
                                name="signageRationale"
                                testId="projReportSignageRationaleField"
                                className={clsx(
                                  classes.fullWidth,
                                  flags[formCodes.fieldSignageRationale]
                                    ? classes.warning
                                    : null
                                )}
                                select
                                label={getFieldLabel(
                                  formCodes.fieldSignageRationale,
                                  ""
                                )}
                                variant="outlined"
                                required
                                helperText={
                                  formFields[formCodes.fieldSignageRationale]
                                    ?.helperText
                                }
                              >
                                {Object.values(signageRationaleValues).map(
                                  (value) => (
                                    <MenuItem
                                      key={`projReportSignageRationaleField-${value}`}
                                      value={value}
                                    >
                                      {signageRationaleTexts[value]}
                                    </MenuItem>
                                  )
                                )}
                              </AmoTextField>,
                              true
                            )}
                          </Grid>
                          <Grid item xs />
                        </Grid>
                      )}
                    </>
                  )}
              </Grid>
              <Grid
                item
                className={clsx(
                  classes.contentPadding,
                  classes.fileUploadTopMargin
                )}
              >
                {fileIsLoaded &&
                  renderInputWrapper(
                    "proj-report-file-upload-field",
                    "projReportFileUploadField",
                    formCodes.titleUpload,
                    <FileUploadList
                      fileAssociationId={fileAssociations.Projects}
                      fileAssociationObjectId={parseInt(projectId, 10)}
                      initialValue={files}
                      municipalityId={getMunicipality().id}
                      onChange={handleFilesChange}
                      multiple
                      readonly={!hasCommunicationsPermission}
                      synchronized
                    />,
                    true,
                    true
                  )}
              </Grid>
              <Grid item className={classes.topMargin}>
                {renderInputWrapper(
                  "proj-report-comm-activities-field",
                  "projReportCommActivitiesField",
                  formCodes.fieldLinks,
                  <AmoTextField
                    multiline
                    rows={1}
                    rowsMax={10}
                    disabled={!hasCommunicationsPermission}
                    control={control}
                    id="proj-report-comm-activities-field"
                    name="communicationsActivities"
                    testId="projReportCommActivitiesField"
                    className={clsx(
                      classes.fullWidth,
                      flags[formCodes.fieldLinks] ? classes.warning : null
                    )}
                    label={getFieldLabel(formCodes.fieldLinks, "")}
                    helperText={formFields[formCodes.fieldLinks]?.helperText}
                    characterLimit={300}
                  />,
                  true
                )}
              </Grid>
            </Grid>
          </Grid>
          {activeStep === steps.communications && (
            <Grid item>
              <AmoFormActions
                className={classes.formActions}
                actions={renderFormActions()}
                spacing="1.25rem"
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

// set the prop-types for this component
ProjectReportCommunicationsForm.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  activeStep: PropTypes.oneOf([
    steps.category,
    steps.generalInformation,
    steps.financials,
    steps.communications,
    steps.results,
  ]).isRequired,
  onFormStatusChange: PropTypes.func,
  onNextStepRequest: PropTypes.func,
  onUnsavedChanges: PropTypes.func,
  onDataChange: PropTypes.func,
  isPastConstructionEndDate: PropTypes.bool,
  endOfConstructionDate: PropTypes.instanceOf(DateTime),
  projectDeletionRequested: PropTypes.bool,
  refetchValidationInfo: PropTypes.func,
};

ProjectReportCommunicationsForm.defaultProps = {
  onFormStatusChange: () => {},
  onNextStepRequest: () => {},
  onUnsavedChanges: () => {},
  onDataChange: () => {},
  isPastConstructionEndDate: false,
  projectDeletionRequested: true,
  endOfConstructionDate: null,
  refetchValidationInfo: () => {},
};

export default ProjectReportCommunicationsForm;
