import {
  decreaseCalculationTypes,
  percentageCalculationTypes,
} from "constants/indicatorConstants";
import { customRound } from "utils/number";

export const changeCalculations = (
  calcType,
  formName,
  getFormValues,
  setFormValues
) => {
  const beforeName = formName.split("-after")[0];
  const afterName = `${beforeName}-after`;
  const changeName = `${beforeName}-change`;

  const beforeValue = getFormValues(beforeName);
  const afterValue = getFormValues(afterName);
  const isPercentage = percentageCalculationTypes.includes(calcType);

  if (!beforeValue && !afterValue) {
    setFormValues(changeName, "");
    return;
  }
  if (!beforeValue || !afterValue || (isPercentage && beforeValue + 0 === 0)) {
    setFormValues(changeName, "N/A");
    return;
  }

  const decreaseModifier = decreaseCalculationTypes.includes(calcType) ? -1 : 1;
  const percentageModifier = isPercentage ? 100 / beforeValue : 1;
  const changeValue = customRound(
    (afterValue - beforeValue) * decreaseModifier * percentageModifier,
    2
  );
  const suffix = isPercentage ? "%" : "";

  setFormValues(changeName, `${changeValue}${suffix}`);
};

export const clearCalculations = (
  formName,
  getFormValues,
  resetFieldValue,
  defaultValues
) => {
  const beforeName = formName.split("-after")[0];
  const afterName = `${beforeName}-after`;
  const changeName = `${beforeName}-change`;

  const beforeValue = getFormValues(beforeName);
  const afterValue = getFormValues(afterName);
  const changeValue = getFormValues(changeName);

  const beforeDefaultValue = defaultValues ? defaultValues[beforeName] : null;
  const afterDefaultValue = defaultValues ? defaultValues[afterName] : null;
  const changeDefaultValue = defaultValues ? defaultValues[changeName] : null;

  if (
    beforeValue === beforeDefaultValue &&
    afterValue === afterDefaultValue &&
    changeValue !== changeDefaultValue
  ) {
    resetFieldValue(changeName);
  }
};

export const calculateRawChange = (
  calcType,
  beforeValue,
  afterValue,
  showPercentageChar = false,
  unitAbbreviation = null
) => {
  const isPercentage = percentageCalculationTypes.includes(calcType);
  const formatter = new Intl.NumberFormat("en-US");

  if (!beforeValue && !afterValue) {
    return null;
  }
  if (!beforeValue || !afterValue || (isPercentage && beforeValue + 0 === 0)) {
    return null;
  }

  const decreaseModifier = decreaseCalculationTypes.includes(calcType) ? -1 : 1;
  const percentageModifier = isPercentage ? 100 / beforeValue : 1;
  const changeValue = customRound(
    (afterValue - beforeValue) * decreaseModifier * percentageModifier,
    2
  );

  return `${formatter.format(changeValue)}${
    isPercentage && showPercentageChar ? "%" : unitAbbreviation ?? ""
  }`;
};
