import { useEffect, useState } from "react";

/**
 * Hook to handle keyboard navigation on tables
 *
 * @param {any[]} items - array of items that represent each row
 * @param {number} page - current page number, starting at 1
 * @param {number} rowCount - max number of rows per page
 * @param {Function} onScroll - callback when a new row is selected, item is passed. Required to allow navigation
 * @param {object} focusedItem - has id of current focused item
 * @returns {number} index of the selected row
 */
export const useKeyboardNavigation = (
  items,
  page = 1,
  rowCount = 10,
  onScroll,
  focusedItem
) => {
  /* key name constants */
  const UP_KEY = "ArrowUp";
  const DOWN_KEY = "ArrowDown";
  const TAB_KEY = "Tab";

  const [keyDownEvent, setKeyDownEvent] = useState(null);
  // true if keyboard navigation should be allowed
  const [navigationIsEnabled, enableNavigation] = useState(true);
  // index of currently selected row in table
  const [rowIndex, setRowIndex] = useState(null);
  // index of currently select item
  const itemIndex = rowIndex === null ? null : rowIndex + rowCount * (page - 1);

  /**
   * Sets keyDownEvent if the key pressed is relevant
   *
   * Due to how the event listener is added, it's important that the handler
   * doesn't use any of the props passed, and instead triggers a useEffect with the logic
   *
   * @param {object} event - key down event
   */
  const keyDownHandler = (event) => {
    if (
      event.key === UP_KEY ||
      event.key === DOWN_KEY ||
      event.key === TAB_KEY
    ) {
      setKeyDownEvent(event);
    }
  };

  // Add/remove keydown listener on component mount/unmount
  // Only adds the listener if onScroll is defined
  useEffect(() => {
    if (onScroll) {
      window.addEventListener("keydown", keyDownHandler);
      return () => {
        window.removeEventListener("keydown", keyDownHandler);
      };
    }
    return () => {};
  }, []);

  // useEffect to handle the logic of key presses
  useEffect(() => {
    const key = keyDownEvent?.key;

    // Do nothing if navigation disabled or there are no items
    if (!navigationIsEnabled || !items || !items.length) return;
    // Disable navigation if user tabs
    if (key === TAB_KEY) {
      enableNavigation(false);
    }
    // If no row selected, select first row on navigation attempt
    else if (rowIndex === null) {
      if (key === UP_KEY || key === DOWN_KEY) {
        setRowIndex(0);
      }
    }
    // Select previous row if not at top
    else if (key === UP_KEY && rowIndex > 0) {
      setRowIndex(rowIndex - 1);
    }
    // Select next row if not last row
    else if (
      key === DOWN_KEY &&
      rowIndex < rowCount - 1 &&
      itemIndex < items.length - 1
    ) {
      setRowIndex(rowIndex + 1);
    }
  }, [keyDownEvent]);

  // Call onScroll when selected row changes
  useEffect(() => {
    if (rowIndex !== null && onScroll) {
      onScroll(items[itemIndex]);
    }
  }, [rowIndex]);

  // Change if navigation is enabled, triggered by the current focused item changing
  useEffect(() => {
    enableNavigation(!!focusedItem);
    if (items.length > 0 && focusedItem && Object.keys(focusedItem).length) {
      const [key, value] = Object.entries(focusedItem)[0];
      const firstRowIndex = rowCount * (page - 1);
      for (let i = 0; i < rowCount; i += 1) {
        if (items[firstRowIndex + i]?.[key] === value) {
          setRowIndex(i);
          break;
        }
      }
    }
  }, [focusedItem]);

  // Reset rowIndex when the filtered items or page changes
  useEffect(() => {
    setRowIndex(null);
  }, [items, page]);

  return rowIndex;
};
