import { useState } from "react";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";

import { useIsMounted } from "./useIsMounted";

/**
 * @typedef {{
 *  call: Function,
 *  isLoading: boolean,
 *  error: Error }} WrapApiHookObject
 */

/**
 * Hook to wrap API calls
 * Sets an isLoading boolean, and provides snackbar options
 *
 * @param apiCall API function to wrap
 * @param successMessage snackbar message on success
 * @param errorMessage snackbar message on error
 * @returns {WrapApiHookObject}
 */
export const useWrapApi = (
  apiCall,
  successMessage = null,
  errorMessage = errorMessages.generic
) => {
  const mounted = useIsMounted();

  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const wrappedCall = async (...args) => {
    let data;
    let caughtError;
    setIsLoading(true);

    try {
      data = await apiCall(...args);
      if (successMessage && mounted.current) {
        showSnackbar(successMessage, snackbarTypes.success);
      }
    } catch (err) {
      caughtError = err;

      if (mounted.current) {
        console.error(err);

        setError(err);
        if (errorMessage) {
          showSnackbar(errorMessage, snackbarTypes.error);
        }
      }
    }

    if (mounted.current) {
      setIsLoading(false);
    }

    return { data, error: caughtError };
  };

  return {
    call: wrappedCall, // wrapped function that can be called the same way you would call the API function
    isLoading, // true if API promise is still loading
    error, // Error thrown by api call. This is a useState, and may not immediately update. Best for UI rendering.
    // Use the error returned in .call() for immediate checking
  };
};
