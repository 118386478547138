import React, { useState, useEffect } from "react";

import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  TextField,
  Grid,
} from "@material-ui/core";

import AmoTable from "components/table/AmoTable";
import ProjectReviewFieldWrapper from "components/amo/ProjectReviewFieldWrapper";

import colors from "constants/colors";
import { projectReportFinancialsCodes } from "constants/formContentManagement";
import { patterns } from "constants/regex";

import { useMunicipalContext } from "contexts/MunicipalContext";

import { getSortingComparator, stableSort } from "utils/data";
import { formatToCurrency } from "utils/number";

import { useProjectFunds } from "./projectReportFinancialsHooks";

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: "transparent",
    border: `1px solid ${colors.grey.main}`,
    "&.MuiAccordion-rounded:last-child": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    "&.MuiAccordion-rounded:first-child": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "& > .MuiAccordionSummary-root > .MuiAccordionSummary-content": {
      // 16px 0px
      margin: "1rem 0",
      "&.Mui-expanded": {
        // 16px 0px 0px 0px
        margin: "1rem 0 0 0",
      },
    },
    "& > .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon": {
      "&.Mui-expanded": {
        transform: "rotate(90deg)",
      },
      "& .MuiIcon-root": {
        color: colors.black,
      },
    },
  },
  warning: {
    border: `1px solid ${colors.yellow}`,
  },
  previousValue: {
    color: colors.red.main,
    textDecoration: "line-through",
  },
}));

/**
 * A accordion component for displaying CCBF Funds Applied
 *
 * @param {object} props - object containing props for this component
 * @param {string|number} props.projectId - sets the project id used to load the ccbf funds data, pass 'new' for new project [required]
 * @param {string} props.title - sets the title of the CCBF Funds component [required]
 * @param {Function} props.onProjectFundsChange - function called when project funds changes (params: { ccbfFundsTotal, ccbfFunds })
 * @param {boolean} props.isProjectReview - sets the flag to indicate if this is being displayed in a review page
 * @param {object[]} props.preloadedFunds - Array of funds that were loaded in a different component
 * @param {number} props.municipalityId - sets the municipality id related to the projects
 * @param {Function} props.onSave - function called when user clicks on the field popup save button (params: none)
 * @param {boolean} props.warning - whether to display this widget with yellow border
 * @returns - The accordion component
 */
const ProjectReportFinancialsFundsWidget = (props) => {
  const {
    projectId,
    title,
    onProjectFundsChange,
    isProjectReview,
    preloadedFunds,
    municipalityId,
    onSave,
    warning,
  } = props;

  const classes = useStyles();
  const { getMunicipality } = useMunicipalContext();

  const [ccbfFundsTotal, setCcbfFundsTotal] = useState(0);
  const [ccbfFunds, setCcbfFunds] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("year");

  const tableColumns = [
    {
      fieldKey: "year",
      label: "Year",
      widthPerc: 20,
    },
    {
      fieldKey: "amount",
      label: "Amount",
      // eslint-disable-next-line react/no-unstable-nested-components -- Currently required for AmoTable.
      customRender: (item) => {
        if (isProjectReview) {
          const { id, year, amount, flagProps, previousValue } = item;
          return (
            <ProjectReviewFieldWrapper
              projectId={projectId}
              fieldCode={projectReportFinancialsCodes.fieldFunding}
              id={`amount${id}Wrapper`}
              objectId={id || null}
              label="Amount"
              year={year}
              value={Number(amount)}
              formatValue={(value) =>
                value ? formatToCurrency(Number(value)) : ""
              }
              hideLabel
              hideFlag={year !== getMunicipality(municipalityId)?.reportingYear}
              flagProps={flagProps}
              previousValue={previousValue}
              renderInput={(value, setValue) => (
                <TextField
                  label="Amount"
                  value={value}
                  variant="outlined"
                  onChange={(event) => {
                    setValue(
                      event.target.value?.replace(patterns.numberInput, "")
                    );
                  }}
                  required
                />
              )}
              onSave={onSave}
            />
          );
        }

        return (
          <Typography style={{ fontSize: "0.875rem" }}>
            {item?.amount}
          </Typography>
        );
      },
      isCustom: true,
    },
  ];

  // Query project funds
  const { data: fundsResult, isFetching } = useProjectFunds(
    projectId,
    isProjectReview
  );

  useEffect(() => {
    const fundsToDisplay = preloadedFunds ?? fundsResult?.data;

    if (fundsToDisplay) {
      let previousFundsTotal = null;
      if (fundsToDisplay?.funds?.some(({ previousValue }) => !!previousValue)) {
        previousFundsTotal = fundsToDisplay?.funds?.reduce(
          (currentValue, { previousValue, amount }) =>
            !previousValue
              ? currentValue + Number(amount)
              : currentValue + Number(previousValue),
          null
        );
      }
      setCcbfFundsTotal({
        previous: previousFundsTotal,
        current: fundsToDisplay?.total,
      });

      // Sorting result
      const sortedResult = stableSort(
        fundsToDisplay?.funds,
        getSortingComparator(order, orderBy)
      );

      setCcbfFunds(sortedResult ?? []);

      onProjectFundsChange({
        ccbfFundsTotal: fundsToDisplay?.total ?? 0,
        ccbfFunds: sortedResult,
      });
    }
  }, [fundsResult]);

  const handleRequestSort = (fieldKey, newOrder) => {
    setOrder(newOrder);
    setOrderBy(fieldKey);

    const sortedItems = stableSort(
      ccbfFunds,
      getSortingComparator(newOrder, fieldKey)
    );
    setCcbfFunds(sortedItems);
  };

  return (
    <Accordion
      className={clsx(classes.accordion, warning ? classes.warning : null)}
      elevation={0}
    >
      <AccordionSummary
        id="proj-report-ccbf-funds"
        aria-controls="proj-report-ccbf-funds-content"
        expandIcon={<Icon className="material-icons">chevron_right</Icon>}
      >
        <Grid item container>
          <Grid item>
            <Typography variant="h4">{title}:</Typography>
          </Grid>
          <Grid item style={{ marginLeft: "1rem" }}>
            <Grid item container direction="column">
              {ccbfFundsTotal?.previous && (
                <Grid item>
                  <Typography variant="h3" className={classes.previousValue}>
                    {ccbfFundsTotal?.previous
                      ? formatToCurrency(ccbfFundsTotal?.previous)
                      : ""}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography variant="h3">
                  {ccbfFundsTotal?.current
                    ? formatToCurrency(ccbfFundsTotal?.current)
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <AmoTable
          id="proj-report-ccbf-funds-table"
          testId="projReportCcbfFundsTable"
          items={ccbfFunds}
          columns={tableColumns}
          defaultOrderBy="year"
          defaultOrder="desc"
          onRequestSort={handleRequestSort}
          disableFilters
          uniqueKeyGenerationFields={["year"]}
          isLoading={isFetching}
        />
      </AccordionDetails>
    </Accordion>
  );
};

// set the prop-types for this component
ProjectReportFinancialsFundsWidget.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string.isRequired,
  onProjectFundsChange: PropTypes.func,
  isProjectReview: PropTypes.bool,
  preloadedFunds: PropTypes.arrayOf(PropTypes.shape()),
  municipalityId: PropTypes.number,
  onSave: PropTypes.func,
  warning: PropTypes.bool,
};

ProjectReportFinancialsFundsWidget.defaultProps = {
  onProjectFundsChange: () => {},
  isProjectReview: false,
  preloadedFunds: null,
  municipalityId: null,
  onSave: () => {},
  warning: false,
};

export default ProjectReportFinancialsFundsWidget;
