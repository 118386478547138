import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";

// Query hooks and management
import { useCommunicationUserDetails } from "./communicationUserHooks";
import {
  useCreatePasswordReset,
  useCreateUserDetails,
  useRemoveUser,
  useUpdateUserDetails,
} from "./usersHook";

import { userService } from "api/services/userService";
import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";

import AmoTextField from "components/inputs/AmoTextField";
import AmoSwitch from "components/inputs/AmoSwitch";
import AmoPageHeader from "components/AmoPageHeader";
import AmoFormActions from "components/AmoFormActions";
import AmoConfirmPopover from "components/AmoConfirmPopover";

import { roles } from "constants/user";
import { patterns } from "constants/regex";
import { useGlobalStyles } from "hooks/globalStylesHook";
import NotFoundPage from "pages/NotFoundPage";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    overflow: "hidden auto",
    // marginBottom: "5rem",
  },
  body: {
    // 0px 272px 0px 272px
    padding: "0rem 22rem 0rem 22rem",
    marginTop: 0,
  },
  bodyText: {
    marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        // 16px
        paddingBottom: "1rem",
      },
      "& > .MuiGrid-container > .MuiGrid-item:last-child": {
        // 48px
        paddingLeft: "3rem",
      },
    },
  },
  inputWidth: {
    width: "90%",
  },
  switchLabel: {
    marginLeft: "0rem",
  },
}));

/**
 * Communications User Edit Page.
 *
 * @returns {React.Component} Communications User Edit Page component.
 */
const CommUserEditPage = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { userId } = useParams();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();

  const userIsNew = userId === "new";
  const headerSubtitle = userIsNew ? "Add User" : "Edit User";

  const generatedUsername = useRef("");

  const { hasRoles } = useUserContext();

  // modal properties
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const checkUserNameAvailability = async (username) => {
    const usernameValidationParams = {
      usernameToCheckFor: username,
      userIdToCheckFor: userIsNew ? null : userId,
    };
    const { data } = await userService.checkUsername(usernameValidationParams);
    return !data;
  };

  const checkEmailAvailability = async (email) => {
    const emailValidationParams = {
      emailToCheckFor: email,
      userIdToCheckFor: userIsNew ? null : userId,
    };
    const { data } = await userService.checkEmail(emailValidationParams);
    return !data;
  };

  // define schema for form validation
  const CommUserEditValidationSchema = yup
    .object({
      firstName: yup
        .string()
        .trim()
        .required("First name is required")
        .max(50, "Too long, maximum 50 characters"),
      lastName: yup
        .string()
        .trim()
        .required("Last name is required")
        .max(50, "Too long, maximum 50 characters"),
      username: yup
        .string()
        .trim()
        .required("Username is required")
        .min(3, "Too short, minimum 3 characters")
        .max(50, "Too long, maximum 50 characters")
        .test(
          "username-taken-validation",
          "Username is already taken",
          checkUserNameAvailability
        ),
      email: yup
        .string()
        .required("Email is required")
        .email("Email must be a valid email")
        .test(
          "email-taken-validation",
          "Email is already taken",
          checkEmailAvailability
        ),
      phone: yup
        .string()
        .required("Phone number is required")
        .matches(patterns.phone, { message: "Invalid phone format" }),
      phoneExtension: yup
        .string()
        .matches(patterns.number, { message: "Invalid phone extension" }),
      organization: yup
        .string()
        .trim()
        .required("Organization is required")
        .max(100, "Too long, maximum 100 characters"),
      title: yup
        .string()
        .trim()
        .nullable()
        .max(100, "Too long, maximum 100 characters"),
      isEnabled: yup.boolean(),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phone: "",
    phoneExtension: "",
    organization: "",
    title: "",
    isEnabled: true,
  };

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    setValue,
    formState,
    getValues,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(CommUserEditValidationSchema),
  });

  const { isValid, isSubmitting, isDirty } = formState;

  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    error,
  } = useCommunicationUserDetails(userId);

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    if (userDetails) {
      reset(userDetails);
    }
  }, [userDetails]);

  /*
  /  WATCH LIST
  /  Watches named fields for updates, used to trigger effects when we need to
  /  Caution: useWatch is optimized for renders, not for effects, so it may trigger effects multiple times
  /  This is suboptimal; the [watch] and [useWatch] docs hint at a better way, performance-wise
  /  https://react-hook-form.com/api/usewatch
  /  TODO: create a custom hook that's optimized for effect hooks
  */
  const [firstNameWatch, lastNameWatch, phoneWatch] = useWatch({
    control,
    name: ["firstName", "lastName", "phone"],
  });

  // When no username is manually entered, combine first initial and last name to create username
  useEffect(() => {
    // If it's editing the user, doesn't use generated username
    if (userId && !userIsNew) {
      return;
    }

    const currentUsername = getValues("username");

    const noSpaceLastName = lastNameWatch.trim().replace(" ", "");
    const useGeneratedUsername =
      !currentUsername || currentUsername === generatedUsername.current;

    if (firstNameWatch && noSpaceLastName) {
      generatedUsername.current = `${firstNameWatch.charAt(
        0
      )}${noSpaceLastName}`.toLowerCase();
    } else {
      generatedUsername.current = "";
    }
    if (useGeneratedUsername && generatedUsername.current !== currentUsername) {
      setValue("username", generatedUsername.current);
      // Trigger a validation check for this field
      trigger("username");
    }
  }, [firstNameWatch, lastNameWatch]);

  useEffect(() => {
    let modifyPhoneWatch;
    if (phoneWatch) {
      modifyPhoneWatch = phoneWatch
        .replace("-", "")
        .replace("-", "")
        .slice(0, 10);
      if (modifyPhoneWatch.length > 3 && modifyPhoneWatch.length < 7) {
        modifyPhoneWatch = `${modifyPhoneWatch.slice(
          0,
          3
        )}-${modifyPhoneWatch.slice(3, modifyPhoneWatch.length + 1)}`;
      } else if (modifyPhoneWatch.length >= 7) {
        modifyPhoneWatch = `${modifyPhoneWatch.slice(
          0,
          3
        )}-${modifyPhoneWatch.slice(3, 6)}-${modifyPhoneWatch.slice(6, 10)}`;
      }
    }
    setValue("phone", modifyPhoneWatch);
  }, [phoneWatch]);

  const navigateBack = () => history.push(`/users${location.search}`);

  const {
    mutateAsync: updateUser,
    isLoading: isUpdatingUser,
  } = useUpdateUserDetails(showSnackbar, navigateBack);
  const {
    mutateAsync: createUser,
    isLoading: isCreatingUser,
  } = useCreateUserDetails(showSnackbar, navigateBack);
  const { mutateAsync: deleteUser } = useRemoveUser(showSnackbar, navigateBack);
  const {
    mutateAsync: createPasswordReset,
    isLoading: isCreatingPasswordReset,
  } = useCreatePasswordReset(showSnackbar);

  const handleSaveClick = async ({
    firstName,
    lastName,
    username: userName,
    title,
    organization,
    email,
    phone: phoneNumber,
    phoneExtension,
    isEnabled: enabled,
  }) => {
    const userData = {
      userType: "communications",
      firstName,
      lastName,
      userName,
      title,
      organization,
      email,
      phoneNumber,
      phoneExtension: phoneExtension ?? "",
      enabled,
    };

    if (userIsNew) {
      createUser(userData);
    } else {
      userData.userId = userId;
      updateUser(userData);
    }
  };

  const handleRemoveUserClick = () => {
    setIsModalOpen(true);
  };

  const sendRemovalRequest = async (id) => deleteUser(id);

  const handlePasswordResetClick = () => {
    const emailRecipient = getValues("email");
    createPasswordReset(emailRecipient);
  };

  const headerActions = [
    {
      testId: "municipalUserEditRemoveUserButton",
      label: "Remove user",
      color: "secondary",
      variant: "outlined",
      onClick: handleRemoveUserClick,
    },
    {
      disabled: isCreatingPasswordReset,
      testId: "municipalUserEditPasswordResetButton",
      label: "Password reset",
      color: "secondary",
      variant: "outlined",
      onClick: handlePasswordResetClick,
    },
  ];

  const formActions = [
    {
      disabled:
        !isDirty ||
        isSubmitting ||
        !isValid ||
        isUpdatingUser ||
        isCreatingUser ||
        isCreatingPasswordReset,
      testId: "municipalUserEditSaveButton",
      label: "Save",
      onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
    },
    {
      disabled: isUpdatingUser || isCreatingUser || isCreatingPasswordReset,
      testId: "municipalUserEditCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: navigateBack,
    },
  ];

  return isUserDetailsLoading ? (
    <>
      <Skeleton width="50%" />
      <Skeleton width="50%" />
    </>
  ) : (
    <>
      {error?.response?.status === 404 && (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <NotFoundPage />
        </Grid>
      )}
      {!(error?.response?.status === 404) && (
        <Grid container direction="column" spacing={0} wrap="nowrap">
          {/* Header */}
          <Grid item>
            <AmoPageHeader
              title="User Management:"
              subtitle={headerSubtitle}
              backLinkText="Back to user list"
              backLinkTo="/users"
              actions={userIsNew ? null : headerActions}
            />
          </Grid>

          {/* Body */}
          <Grid item xs className={globalClasses.editPageBodyContainer}>
            <Grid
              container
              direction="column"
              spacing={0}
              className={clsx(
                globalClasses.editPageBody22,
                globalClasses.editPageBodyText
              )}
              wrap="nowrap"
            >
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      id="comm-user-edit-firstName"
                      name="firstName"
                      testId="commUserEditFirstName"
                      className={classes.inputWidth}
                      label="First Name"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      id="comm-user-edit-lastName"
                      name="lastName"
                      testId="commUserEditLastName"
                      className={classes.inputWidth}
                      label="Last Name"
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      id="comm-user-edit-username"
                      name="username"
                      testId="commUserEditUsername"
                      className={classes.inputWidth}
                      label="Username"
                      required
                    />
                  </Grid>
                  <Grid item xs />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      id="comm-user-edit-title"
                      name="title"
                      testId="commUserEditTitle"
                      className={classes.inputWidth}
                      label="Title"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      id="comm-user-edit-organization"
                      name="organization"
                      testId="commUserEditOrganization"
                      className={classes.inputWidth}
                      label="Organization"
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <AmoTextField
                      control={control}
                      id="comm-user-edit-email"
                      name="email"
                      testId="commUserEditEmail"
                      className={classes.inputWidth}
                      label="Email"
                      required
                    />
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item xs={8}>
                      <AmoTextField
                        control={control}
                        id="comm-user-edit-phone"
                        name="phone"
                        testId="commUserEditPhone"
                        className={classes.inputWidth}
                        label="Phone"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <AmoTextField
                        control={control}
                        id="comm-user-edit-phone-extension"
                        name="phoneExtension"
                        testId="commUserEditPhoneExtension"
                        className={classes.inputWidth}
                        label="Ext."
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!userIsNew && hasRoles([roles.amo, roles.amoSuperAdmin]) && (
                <Grid item>
                  <AmoSwitch
                    color="primary"
                    control={control}
                    id="commUserEditEnabled"
                    name="isEnabled"
                    testId="commUserEditEnabled"
                    label="User Enabled:"
                    labelPlacement="start"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Actions */}
          <AmoFormActions actions={formActions} hideLateralBorders />
        </Grid>
      )}
      <AmoConfirmPopover
        variant="default"
        open={isModalOpen}
        closePopover={() => handleModalClose()}
        title="Remove User?"
        text="Are you sure you want to remove this user?  This action is not reversible."
        showButton
        yesButtonText="Yes, Remove this user"
        yesButtonFunction={() => sendRemovalRequest(userId)}
        yesButtonVariant="contained"
        noButtonText="No, Cancel"
        noButtonFunction={() => handleModalClose()}
        noButtonVariant="outlined"
      />
    </>
  );
};

export default CommUserEditPage;
