import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Typography,
  MenuItem,
  Button,
  Menu,
} from "@material-ui/core";

import colors from "constants/colors";
import clsx from "clsx";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import ListPageWrapper from "components/ListPageWrapper";
import { exportToExcel } from "utils/export";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import { useInsuranceReviews } from "./insuranceReviewHooks";
import AmoTextField from "components/inputs/AmoTextField";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { reviewService } from "api/services/reviewsService";
import { filesService } from "api/services/fileService";
import { errorMessages } from "constants/errorMessages";
import EditInsuranceCertificateModal from "./EditInsuranceCertificateModal";
import { useYearContext } from "contexts/YearContext";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import { reporting } from "constants/reporting";
import { ReactComponent as DownloadWithoutCircleIcon } from "img/icons/download_green_icon.svg";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { insuranceReviewStatuses } from "constants/amoTableDropdownOptions";

const useStyles = makeStyles((theme) => ({
  disabled: {
    "& .MuiButton-root.Mui-disabled": {
      color: "grey",
    },
  },
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  dropdown: {
    maxWidth: "13rem",
  },
  iconInsurance: {
    width: "1rem",
    height: "1rem",
  },
  cellText: {
    fontSize: "0.875rem",
  },
}));

/**
 * The Certificates of Insurance List Page.
 *
 * @returns {React.Component} Certificates of Insurance List Page component.
 */
const InsuranceReviewListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { contextYear } = useYearContext();
  const globalClasses = useGlobalStyles();
  const mounted = useIsMounted();

  const { getMunicipalitiesNames } = useMunicipalContext();

  const isPriorToFirstCOI = contextYear < reporting.firstYearOfCOI;

  const [showModal, setShowModal] = useState(false);
  const [newCertificate, setNewCertificate] = useState(false);
  const [openModalId, setOpenModalId] = useState(false);
  const [requestIsOngoing, setRequestIsOngoing] = useState(false);
  const [newSave, setNewSave] = useState(false);
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const downloadRef = useRef(true);
  const filteredData = useRef([]);

  const defaultValues = {
    statuses: [],
  };

  const { control, setValue, getValues } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    data: insuranceCertificates,
    isFetching,
    refetch: refetchInsuranceList,
  } = useInsuranceReviews(contextYear, isPriorToFirstCOI);

  const loadCertificates = () => {
    const statusStates = [];
    insuranceCertificates?.forEach((item) => {
      setValue(`statuses.${item?.id}`, item.status);
      statusStates[item.id] = item.status;
    });
  };

  useEffect(() => {
    if (insuranceCertificates || newSave) {
      loadCertificates();
      setNewSave(false);
    }
  }, [insuranceCertificates, newSave]);

  const openModal = (id, submitted) => {
    if (downloadRef.current) {
      setNewCertificate(!submitted);
      setOpenModalId(id);
      setShowModal(true);
      setFocusedItem(null);
    }
  };

  const onDownloadFile = async (fileId, name) => {
    try {
      const { data } = await filesService.getById(fileId);

      if (!mounted.current) {
        return;
      }

      // Download file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}-COI.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      downloadRef.current = true;
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const downloadAllFiles = async () => {
    try {
      setRequestIsOngoing(true);
      const filesIds = filteredData.current
        .filter(({ fileId }) => !!fileId)
        .map(({ fileId }) => fileId);
      if (filesIds.length === 0) {
        showSnackbar("No files found", snackbarTypes.warning);
        return;
      }
      const { data } = await filesService.getInsurancesByIdsAsync(
        contextYear,
        filesIds
      );

      if (!mounted.current) {
        return;
      }

      // Download file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Insurances ${contextYear} files.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    } finally {
      if (mounted.current) {
        setRequestIsOngoing(false);
      }
    }
  };

  const saveValue = async (coi) => {
    const payload = {
      year: contextYear,
      municipalityId: coi.id,
      coverageStart: DateTime.fromISO(coi.coverageStart).toFormat("yyyy-MM-dd"),
      coverageEnd: DateTime.fromISO(coi.coverageEnd).toFormat("yyyy-MM-dd"),
      notes: coi.notes,
      statusId: getValues(`statuses.${coi.id}`),
      fileId: coi.fileId,
    };
    try {
      await reviewService.updateInsuranceCertificate(payload);

      if (!mounted.current) {
        return;
      }

      refetchInsuranceList();
      showSnackbar("Certificate updated successfully", snackbarTypes.success);
    } catch (error) {
      if (!mounted.current) {
        return;
      }

      showSnackbar(
        "An error occured while saving the certificate",
        snackbarTypes.error
      );
    }
  };

  const tableColumns = [
    {
      fieldKey: "download",
      label: "",
      minWidth: "4rem",
      hideSearch: true,
      customRender: ({ id, municipality, fileId }) => (
        <Button
          data-testid={`download-${id}`}
          align="right"
          onClick={() => {
            downloadRef.current = false;
            onDownloadFile(fileId, municipality);
          }}
          disabled={!fileId}
          className={clsx("material-icons-outlined", classes.disabled)}
        >
          <DownloadWithoutCircleIcon
            className={classes.iconInsurance}
            style={{ stroke: fileId ? colors.green.main : colors.grey.main }}
            fill={fileId ? colors.green.main : colors.grey.main}
          />
        </Button>
      ),
      isCustom: true,
    },
    {
      fieldKey: "municipality",
      label: "Municipality",
      minWidth: "15rem",
      maxWidth: "25rem",
      customRender: ({ id, municipality, submitted }) => (
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              color="primary"
              key={`certificate-button-${id}`}
              id={`certificate-button-${id}`}
              className={globalClasses.tableActionCell}
              onClick={() => openModal(id, submitted)}
              ref={(element) => {
                rowRefs.current[id] = element;
              }}
              onFocus={() => setFocusedItem({ id })}
              disableRipple
            >
              {municipality}
            </Button>
          </Grid>
        </Grid>
      ),
      isCustom: true,
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "submitted",
      label: "Submitted",
      minWidth: "10rem",
      displayType: displayTypes.boolean,
    },
    {
      fieldKey: "coverageStart",
      label: "Coverage Start",
      minWidth: "12rem",
      customRender: ({ coverageStart }) => (
        <Typography className={classes.cellText}>
          {coverageStart
            ? DateTime.fromISO(coverageStart).toFormat("yyyy-MM-dd")
            : ""}
        </Typography>
      ),
      isCustom: true,
      className: classes.cellText,
    },
    {
      fieldKey: "coverageEnd",
      label: "Coverage End",
      minWidth: "12rem",
      customRender: ({ coverageEnd }) => (
        <Typography className={classes.cellText}>
          {coverageEnd
            ? DateTime.fromISO(coverageEnd).toFormat("yyyy-MM-dd")
            : ""}
        </Typography>
      ),
      isCustom: true,
      className: classes.cellText,
    },
    {
      fieldKey: "notes",
      label: "Notes",
      minWidth: "17rem",
      maxWidth: "15rem",
      customRender: ({ notes }) => (
        <Typography className={classes.cellText}>
          {notes?.length > 30 ? `${notes.substring(0, 29)}...` : notes}
        </Typography>
      ),
      isCustom: true,
      className: classes.cellText,
    },
    {
      fieldKey: "statusTextValue",
      label: "Status",
      minWidth: "15rem",
      customRender: (item) =>
        item.submitted ? (
          <AmoTextField
            control={control}
            select
            id={`statuses.${item.municipality}`}
            name={`statuses.${item.id}`}
            onChangeTrigger={() => {
              saveValue(item);
            }}
            className={clsx("material-icons-outlined", classes.dropdown)}
            align="right"
            disabled={!item.submitted}
            onFocus={() => {
              downloadRef.current = false;
            }}
            InputProps={{
              onBlur: () => {
                downloadRef.current = true;
              },
            }}
          >
            <MenuItem key="statusNone" value={0} disabled>
              Select One
            </MenuItem>
            <MenuItem key="statusApproved" value={1}>
              Approved
            </MenuItem>
            <MenuItem key="statusNotApproved" value={2}>
              Not Approved
            </MenuItem>
          </AmoTextField>
        ) : (
          " "
        ),
      isCustom: true,
      dropdownOptions: insuranceReviewStatuses,
      displayType: displayTypes.dropdown,
    },
  ];

  const tableColumnsForDownload = [
    {
      fieldKey: "id",
      label: "Municipal ID",
    },
    {
      fieldKey: "municipality",
      label: "Municipality",
    },
    {
      fieldKey: "submitted",
      label: "Submitted",
    },
    {
      fieldKey: "coverageStart",
      label: "Coverage Start",
      displayType: displayTypes.date,
    },
    {
      fieldKey: "coverageEnd",
      label: "Coverage End",
      displayType: displayTypes.date,
    },
    {
      fieldKey: "notes",
      label: "Notes",
    },
    {
      fieldKey: "status",
      label: "Status",
      customExport: ({ id }) => {
        switch (getValues(`statuses.${id}`)) {
          case 1:
            return "Approved";
          case 2:
            return "Not Approved";
          default:
            return "";
        }
      },
    },
  ];

  const warningText = (
    <Typography variant="body1" color="primary">
      Certificates of Insurance were not collected prior to 2014.
    </Typography>
  );

  const [ellipsisAnchorEl, setEllipsisAnchorEl] = useState(null);
  const ellipsisOnClick = (event) => setEllipsisAnchorEl(event.currentTarget);
  const ellipsisOnClose = () => setEllipsisAnchorEl(null);

  const tableActions = {
    titleComponent: isPriorToFirstCOI ? warningText : null,
    actions: [tableDefaultActions.search, tableDefaultActions.export],
    actionsCustomConfigs: {
      [tableDefaultActions.search]: {
        disabled: isPriorToFirstCOI,
      },
      [tableDefaultActions.export]: {
        onClick: ellipsisOnClick,
        disabled: isPriorToFirstCOI,
      },
    },
  };

  const downloadTable = () => {
    try {
      exportToExcel(
        filteredData.current,
        tableColumnsForDownload,
        "Certificates of Insurance",
        {
          sheetName: "Reviews",
          specialColumnSizes: { municipalityName: 30, id: 10 },
        }
      );
    } catch (error) {
      showSnackbar(errorMessages.creatingSpreadsheet, snackbarTypes.error);
    }
  };

  const downloadTableAndAllFiles = () => {
    downloadTable();
    downloadAllFiles();
  };

  const downloadOptions = [
    { text: "Table", action: downloadTable },
    {
      text: "Files",
      action: downloadAllFiles,
      disabled: requestIsOngoing,
    },
    {
      text: "Table and files",
      action: downloadTableAndAllFiles,
      disabled: requestIsOngoing,
    },
  ];

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="h1">Certificates of Insurance</Typography>
        </Grid>
        <Grid item className={classes.dividerContainer}>
          <Divider />
        </Grid>
        <Grid item>
          <Grid item>
            <Menu
              anchorEl={ellipsisAnchorEl}
              open={!!ellipsisAnchorEl}
              onClose={ellipsisOnClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {downloadOptions.map(({ text, action, disabled }) => (
                <MenuItem
                  key={text}
                  onClick={() => {
                    ellipsisOnClose();
                    action();
                  }}
                  disabled={disabled}
                >
                  <Typography variant="body1">{text}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <AmoTableWithFilters
              items={insuranceCertificates}
              columns={tableColumns}
              defaultOrderBy="municipality"
              enableItemClick
              onItemClick={(item) => {
                openModal(item.id, item.submitted);
              }}
              onFilteredDataChanged={(value) => {
                filteredData.current = value;
              }}
              onScroll={(item) => {
                rowRefs.current?.[item.id]?.focus();
              }}
              focusedItem={focusedItem}
              actionsProps={tableActions}
              isLoading={isFetching}
              hideTableContent={isPriorToFirstCOI}
            />
          </Grid>
        </Grid>
      </Grid>
      <EditInsuranceCertificateModal
        key={showModal ? openModalId : "closed-modal"}
        showModal={showModal}
        newCertificate={newCertificate}
        municipalityId={openModalId}
        closeModalFunction={() => {
          setShowModal(false);
          setFocusedItem({ id: openModalId });
        }}
        year={contextYear}
        onSaveHook={setNewSave}
      />
    </ListPageWrapper>
  );
};

export default InsuranceReviewListPage;
