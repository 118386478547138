import { useQuery } from "react-query";
import { projectService } from "api/services/projectService";
import { municipalityService } from "api/services/municipalityService";
import { projectReviewsQueryKeys } from "./projectReviewsQueryKeys";

/**
 * Store server's state of project results
 *
 * @param {number} year
 * @returns The query object returned by the useQuery hook
 */
export const useProjectReviewsData = (year) =>
  useQuery(
    projectReviewsQueryKeys.projectReviews(year),
    () => projectService.getReviewByYear(year),
    {
      select: ({ data }) =>
        data.map((item) => ({
          ...item,
          title: item.title ?? "Untitled Project",
          municipality: item.municipalityName ?? "",
        })),
      enabled: !!year,
    }
  );

const stringToMapLocation = (
  locationString,
  labelIndex = 0,
  isPrimary = false
) => {
  if (!locationString) return null;

  try {
    return {
      lat: Number(locationString.split(",")[0]),
      lng: Number(locationString.split(",")[1].trim()),
      color: isPrimary ? "green" : "blue",
      label: `${isPrimary ? "Primary" : "Additional"} Location ${
        isPrimary ? "" : labelIndex
      }`,
    };
  } catch {
    return null;
  }
};

/**
 * Store server's state of a project review
 *
 * @param projectId The id of the project to look for
 * @returns The query object returned by the useQuery hook
 */
export const useProjectReviewDataById = (projectId) =>
  useQuery(
    projectReviewsQueryKeys.projectReviewById(projectId),
    () => projectService.getProjectReviewById(projectId),
    {
      select: ({ data }) => {
        const primaryLocation = stringToMapLocation(
          data.location?.primaryLocation?.value,
          0,
          true
        );
        const secondaryLocations =
          data.location?.secondaryLocations?.map((location, index) =>
            stringToMapLocation(location?.details?.value, index + 1)
          ) ?? [];
        return {
          ...(data ?? {}),
          mapLocations: primaryLocation
            ? [primaryLocation, ...secondaryLocations]
            : secondaryLocations,
        };
      },
    }
  );

/**
 * Store server's state of the municipality contacts
 *
 * @param municipalityId The id of the municipality to look for
 * @returns The query object returned by the useQuery hook
 */
export const useMunicipalityContacts = (municipalityId) =>
  useQuery(
    projectReviewsQueryKeys.municipalityContacts(municipalityId),
    () => municipalityService.getMunicipalityContacts(municipalityId),
    {
      select: ({ data }) => data,
    }
  );
