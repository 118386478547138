import React, { useState } from "react";
import ResetPasswordInput from "components/ResetPasswordInput";
import ResetPasswordSuccess from "components/ResetPasswordSuccess";
import { useCreatePasswordForgotReset } from "./resetPasswordHook";
import { useSnackbar } from "contexts/SnackbarContext";

/**
 * Reset Password Input.
 *
 * @returns {React.Component} Reset Password component.
 */
const ResetPassword = () => {
  const [validInput, setValidInput] = useState(false);
  const { showSnackbar } = useSnackbar();

  const {
    mutateAsync: createPasswordForgotRequest,
  } = useCreatePasswordForgotReset(showSnackbar);

  const handleClick = async (email) => {
    // call to send reset password email, must allow unauthenticated user access
    createPasswordForgotRequest(email);
    setValidInput(true);
  };

  if (validInput) {
    return <ResetPasswordSuccess />;
  }
  return <ResetPasswordInput handleClick={handleClick} />;
};

export default ResetPassword;
