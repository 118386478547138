import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Button, Link } from "@material-ui/core";
import colors from "../constants/colors";
import { useAuth } from "oidc-react";
import { mobileBreakpoint } from "constants/viewport";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // 32px 112px
    padding: "2rem 7rem",
    border: `1px solid ${colors.grey.main}`,
    borderRadius: "10px",
    [`@media (max-width:${mobileBreakpoint.width - 1}px)`]: {
      // 32px
      padding: "2rem",
    },
  },
  cardContent: {
    width: "100%",
    "&:last-child": { padding: "0" },
  },
  form: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  button: {
    width: "100%",
    height: "50px",
    // 24px
    marginBottom: "1.5rem",
  },
  primaryTextDark: {
    color: theme.palette.primary.dark,
  },
  robotoText: {
    fontFamily: "Roboto",
  },
  textButton: {
    width: "auto",
    padding: "0rem",
    paddingLeft: "0.5rem",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
}));

/**
 * The login card component, containing login buttons for two types of user.
 *
 * @param props
 * @param props.disabled - true if the login buttons should be disabled
 * @returns {React.Component} The login component.
 */
const LoginCard = (props) => {
  const { disabled } = props;
  const classes = useStyles();
  const auth = useAuth();
  const { state } = useLocation();
  const userUrl = state?.from?.pathname ?? "/landing";

  const handleLocalSignInClick = () => auth.signIn({ state: { userUrl } });

  const handleExternalSignInClick = () =>
    auth.signIn({
      acr_values: "idp:saml",
      state: { userUrl },
    });

  return (
    <>
      <Typography gutterBottom className={classes.primaryTextDark} variant="h1">
        Log In
      </Typography>
      <Card className={classes.root} variant="outlined">
        <CardContent className={classes.cardContent}>
          <form noValidate autoComplete="off" className={classes.form}>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              className={classes.button}
              onClick={handleExternalSignInClick}
              disableElevation
              disabled={disabled}
            >
              <Typography variant="body2">I am a treasurer</Typography>
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              className={classes.button}
              onClick={handleLocalSignInClick}
              disableElevation
              disabled={disabled}
            >
              <Typography variant="body2">I am NOT a treasurer</Typography>
            </Button>
          </form>
          <Typography
            className={classes.robotoText}
            align="center"
            variant="body1"
          >
            Trouble signing in?{" "}
            <Link
              component="a"
              variant="body2"
              href={`${process.env.REACT_APP_SUPPORT_LINK}`}
              target="_blank"
            >
              <Typography
                className={classes.robotoText}
                component="span"
                align="center"
                variant="body1"
                color="primary"
              >
                Contact support.
              </Typography>
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

LoginCard.propTypes = {
  disabled: PropTypes.bool,
};

LoginCard.defaultProps = {
  disabled: false,
};

export default LoginCard;
