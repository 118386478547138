import {
  AccountSvg,
  AddSvg,
  AnnouncementsSvg,
  AnnualExpenditureSvg,
  AssetManagementQuestionnairesSvg,
  BankingInformationSvg,
  BankingLimitMonitoringSvg,
  DangerSvg,
  DashboardSvg,
  DeleteCircleSvg,
  DeleteSvg,
  DownloadBlackSvg,
  DownloadCircleSvg,
  DownloadGreenSvg,
  ExpendituresSvg,
  FinancialsSvg,
  FlagSvg,
  FloppyDiskSvg,
  FormsSvg,
  HelpSvg,
  HomeSvg,
  ImageSvg,
  IncrementalityMonitoringSvg,
  InsuranceSvg,
  LogOutSvg,
  MinusSvg,
  NotificationsSvg,
  OkSvg,
  OutputsOutcomesSvg,
  PenSvg,
  ProjectListSvg,
  ProjectsSvg,
  QuestionSvg,
  QuestionnaireManagementSvg,
  QuestionnaireReviewSvg,
  QuestionnairesSvg,
  ReportingStatusSvg,
  ResultsSvg,
  RiskManagementQuestionnairesSvg,
  SearchSvg,
  SubmitReportSvg,
  UploadSvg,
  UsersSvg,
  DownloadCircleThinSvg,
} from "img/projectInterfaceIcons";

export const projectInterfaceIcons = {
  ACCOUNT: AccountSvg,
  ADD: AddSvg,
  ANNOUNCEMENTS: AnnouncementsSvg,
  ANNUAL_EXPENDITURE: AnnualExpenditureSvg,
  ASSET_MANAGEMENT_QUESTIONNAIRES: AssetManagementQuestionnairesSvg,
  BANKING_INFORMATION: BankingInformationSvg,
  BANKING_LIMIT_MONITORING: BankingLimitMonitoringSvg,
  DANGER: DangerSvg,
  DASHBOARD: DashboardSvg,
  DELETE_CIRCLE: DeleteCircleSvg,
  DELETE: DeleteSvg,
  DOWNLOAD_BLACK: DownloadBlackSvg,
  DOWNLOAD_CIRCLE: DownloadCircleSvg,
  DOWNLOAD_CIRCLE_THIN: DownloadCircleThinSvg,
  DOWNLOAD_GREEN: DownloadGreenSvg,
  EXPENDITURES: ExpendituresSvg,
  FINANCIALS: FinancialsSvg,
  FLAG: FlagSvg,
  FLOPPY_DISK: FloppyDiskSvg,
  FORMS: FormsSvg,
  HELP: HelpSvg,
  HOME: HomeSvg,
  IMAGE: ImageSvg,
  INCREMENTALITY_MONITORING: IncrementalityMonitoringSvg,
  INSURANCE: InsuranceSvg,
  LOGOUT: LogOutSvg,
  MINUS: MinusSvg,
  NOTIFICATIONS: NotificationsSvg,
  OK: OkSvg,
  OUTPUTS_OUTCOMES: OutputsOutcomesSvg,
  PEN: PenSvg,
  PROJECT_LIST: ProjectListSvg,
  PROJECTS: ProjectsSvg,
  QUESTION: QuestionSvg,
  QUESTIONNAIRE_MANAGEMENT: QuestionnaireManagementSvg,
  QUESTIONNAIRE_REVIEW: QuestionnaireReviewSvg,
  QUESTIONNAIRES: QuestionnairesSvg,
  REPORTING_STATUS: ReportingStatusSvg,
  RESULTS: ResultsSvg,
  RISK_MANAGEMENT_QUESTIONNAIRES: RiskManagementQuestionnairesSvg,
  SEARCH: SearchSvg,
  SUBMIT_REPORT: SubmitReportSvg,
  UPLOAD: UploadSvg,
  USERS: UsersSvg,
};
