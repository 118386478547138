export const actionTypes = {
  setAssignee: "Set assignee",
  setStatus: "Set status",
  statusSetTo: "Status set to",
  comment: "Comment",
  publish: "Publish",
  flagField: "Flag field",
  editField: "Edit field",
  setAnnouncement: "Announcement set",
  updateAnnouncement: "Announcement update",
};
