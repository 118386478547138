import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { TableCell, TextField, MenuItem } from "@material-ui/core";
import { displayTypes, displayTypesArray } from "constants/amoTableConstants";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";
import AmoTableFilterNumeric from "./AmoTableFilterNumeric";
import AmoTableFilterOptions from "./AmoTableFilterOptions";
import {
  AmoTableColumnProps,
  AmoTableColumnDefaultProps,
} from "constants/amoTablePropTypes";

const useStyles = makeStyles((theme) => ({
  // Remove number selector arrows from type number inputs
  noNumberSelector: {
    "& .MuiOutlinedInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      "-moz-appearance": "textfield",
    },
  },
  select: {
    maxWidth: "10rem",
    "& .MuiSelect-select": {
      minWidth: "7.1rem",
    },
  },
  noBorder: {
    borderBottom: `none`,
  },
}));

/**
 * A table filter component for filtering the value of a column
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.fieldKey - key of the item property that contain this filter value [required]
 * @param {object[]} props.items - array containing the table items [required]
 * @param {string} props.tableId - id of the table component
 * @param {AmoTableColumnProps} props.column - the object with the table column configuration
 * @param {string} props.displayType - the column display type (valid values: @see displayTypes)
 * @param {string} props.label - text to display in the filter input label
 * @param {boolean} props.showSearch - controls if this filter should be hidden, default false
 * @param {string} props.className - sets this filter TableCell className
 * @param {object} props.dropdownOptions - object containing props for the dropdown options where the keys should be all available values
 * @param {boolean} props.shouldUseQueryString - if true, will set and use the query string param for filtering
 * @param {Function} props.onFilterChange - function called when user types on a filter input (params: { @see props.fieldKey }, { filter value })
 *
 * @returns {React.Component} - The table filter component
 */
const AmoTableFilter = (props) => {
  const {
    fieldKey,
    items,
    tableId,
    column,
    displayType,
    label,
    showSearch,
    className,
    dropdownOptions,
    shouldUseQueryString,
    onFilterChange,
  } = props;

  const classes = useStyles();

  const isTextInput =
    !displayType ||
    displayType === displayTypes.text ||
    displayType === displayTypes.date ||
    displayType === displayTypes.component;

  const isNumberInput =
    displayType === displayTypes.number ||
    displayType === displayTypes.currency;

  const isObjectInput = isNumberInput || displayType === displayTypes.dropdown;

  const [filterValue, setFilterValue] = useConditionalQueryParams(
    tableId ? `filter_${tableId}_${fieldKey}` : `filter_${fieldKey}`,
    isObjectInput ? "object" : "string",
    isObjectInput ? {} : "",
    shouldUseQueryString,
    (value) => onFilterChange(fieldKey, value)
  );

  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  return (
    <TableCell
      id={`${tableId ?? "amo-table"}-filter-${fieldKey}`}
      className={clsx(className, !showSearch && classes.noBorder)}
      key={`amoTableHeadCellFilter-${fieldKey}`}
      style={{ textAlign: isNumberInput ? "right" : "inherit" }}
    >
      {showSearch && isTextInput && (
        <TextField
          placeholder={label}
          value={filterValue || ""}
          type="text"
          onChange={(event) => handleFilterChange(event.currentTarget.value)}
          variant="outlined"
        />
      )}
      {showSearch && isNumberInput && (
        <AmoTableFilterNumeric
          fieldKey={fieldKey}
          tableId={tableId}
          label={label}
          value={filterValue}
          range={{
            min: Math.min(
              ...(items?.map((item) => Number(item?.[fieldKey])) ?? [])
            ),
            max: Math.max(
              ...(items?.map((item) => Number(item?.[fieldKey])) ?? [])
            ),
          }}
          onFilterChange={handleFilterChange}
          isCurrency={displayType === displayTypes.currency}
          isRounded={column.isRounded}
          buttonWidth={
            column.minWidth ? `calc(${column.minWidth} - 2rem)` : null
          }
        />
      )}
      {showSearch && displayType === displayTypes.boolean && (
        <TextField
          onChange={(event) =>
            handleFilterChange(
              event.target.value === "none" ? null : event.target.value
            )
          }
          className={classes.select}
          value={filterValue || "none"}
          placeholder={label}
          variant="outlined"
          select
        >
          <MenuItem value="none">All</MenuItem>
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </TextField>
      )}
      {showSearch && displayType === displayTypes.dropdown && (
        <AmoTableFilterOptions
          fieldKey={fieldKey}
          options={dropdownOptions}
          tableId={tableId}
          label={label}
          value={filterValue}
          onFilterChange={handleFilterChange}
          buttonWidth={
            column.minWidth ? `calc(${column.minWidth} - 2rem)` : null
          }
        />
      )}
    </TableCell>
  );
};

// set the prop-types for this component
AmoTableFilter.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableId: PropTypes.string,
  column: PropTypes.shape(AmoTableColumnProps),
  displayType: PropTypes.oneOf(displayTypesArray),
  label: PropTypes.string,
  showSearch: PropTypes.bool,
  className: PropTypes.string,
  dropdownOptions: PropTypes.shape(),
  shouldUseQueryString: PropTypes.bool,
  onFilterChange: PropTypes.func,
};

AmoTableFilter.defaultProps = {
  tableId: undefined,
  column: AmoTableColumnDefaultProps,
  displayType: displayTypes.text,
  label: undefined,
  showSearch: false,
  className: undefined,
  dropdownOptions: undefined,
  shouldUseQueryString: false,
  onFilterChange: () => {},
};

export default AmoTableFilter;
