import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scrollableContent: {
    maxHeight: "calc(100vh - 5.375rem)",
    width: "100%",
  },
}));

/**
 * A list page component wrapper
 *
 * @param {object} props - object containing props for this component
 * @param {Node|Node[]} props.children - Template elements inside wrapper
 * @returns {React.Component} The List Page component wrapper
 */
const ListPageWrapper = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.scrollableContent}>{children}</div>;
};

ListPageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ListPageWrapper;
