import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  municipalityId: number,
 *  municipalityName: string,
 *  baseAmount: number,
 *  annualTarget: number,
 *  averageAnnualInvestment: number,
 *  periodStart: number,
 *  periodEnd: number,
 *  status: string,
 *  ammendmentMade: boolean }} IncrementalityMonitoringModalData
 */

/**
 * @typedef {{
 *  municipalityId: number,
 *  baseAmount: number,
 *  amendmentMade: boolean }} IncrementalityUpdateRequest
 */

/**
 * @typedef {{
 *  municipalityId: number,
 *  amount: number,
 *  periodEnd: number }} IncrementalityMonitoringItem
 */

const api = new Api("api/amo/review/incrementality-monitoring");
export const incrementalityMonitoringService = {
  /**
   * @param {number} municipalityId
   * @returns {Promise<AxiosResponse<IncrementalityMonitoringModalData>>}
   */
  getByMunicipality: (municipalityId) => api.getById(`${municipalityId}`),
  /**
   * @param {number} municipalityId
   * @param {IncrementalityUpdateRequest} payload
   * @returns {Promise<AxiosResponse>}
   */
  putMunicipalityData: (municipalityId, payload) =>
    api.axios.put(`${municipalityId}`, payload),
  /**
   * @param {IncrementalityMonitoringItem[]} payload
   * @returns {Promise<AxiosResponse>}
   */
  updateIncrementalityMonitoringData: (payload) => api.axios.put("", payload),
};
