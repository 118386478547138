import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import { routes } from "constants/routes";
import ListPageWrapper from "components/ListPageWrapper";
import AmoFormActions from "components/AmoFormActions";
import AmoPageHeader from "components/AmoPageHeader";
import { useSnackbar } from "contexts/SnackbarContext";
import { errorMessages } from "constants/errorMessages"; // this will be needed once the API is hooked up
import { snackbarTypes } from "constants/snackbar"; // this will be needed once the API is hooked up
import clsx from "clsx";
import { faqListService } from "api/services/faqListService";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AmoTextField from "components/inputs/AmoTextField";
import AmoRichTextEditor from "components/inputs/AmoRichTextEditor";
import AmoSwitch from "components/inputs/AmoSwitch";
import { usePermalink } from "hooks/permalinkHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  backText: {
    color: colors.green.main,
    marginRight: "2rem",
    cursor: "pointer",
    textDecoration: "none",
  },
  headerGrid: {
    paddingBottom: "1rem",
    borderBottom: "0.125rem #F1F1F1 solid",
  },
  formContainer: {
    position: "relative",
    width: "50%",
    marginTop: "1.5rem",
  },
  secondaryFormContainer: {
    marginTop: "0.25rem",
    fontSize: "1rem",
    fontWeight: 400,
  },
}));

/**
 * The AMO FAQ Edit Page, for editing FAQ content.
 *
 * @returns {React.Component} AMO FAQ Edit Page component.
 */
const EditFAQ = () => {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { showSnackbar } = useSnackbar();
  const { permalink } = useParams();
  const mounted = useIsMounted();

  const isCreate = permalink === "new";

  const validationSchema = yup
    .object({
      question: yup.string().required(),
      answer: yup.string().required(),
      permalink: yup.string().required(),
      isPublished: yup.boolean().required(),
      sortOrder: yup
        .number()
        .typeError("Sort sequence must be a number")
        .required(),
    })
    .required();

  const defaultValues = {
    question: "",
    answer: "",
    permalink: "",
    isPublished: true,
    sortOrder: null,
  };

  const {
    clearErrors,
    control,
    handleSubmit,
    setError,
    reset,
    formState,
    getValues,
    setValue,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [disablePermalink, disableSave] = usePermalink(
    "question",
    "permalink",
    permalink,
    faqListService.getByPermalink,
    control,
    getValues,
    setValue,
    setError,
    clearErrors
  );

  const handleSave = async (formValues) => {
    try {
      isCreate
        ? await faqListService.create(formValues)
        : await faqListService.update(permalink, formValues);

      if (!mounted.current) {
        return;
      }

      showSnackbar(`FAQ ${isCreate ? "created" : "updated"} successfully.`);
      goBackToList();
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const goBackToList = () =>
    history.push(`${routes.contentManagement.help.list}${search}`);

  const { isValid, isSubmitting } = formState;

  const formActions = [
    {
      testId: "editFAQCreateButton",
      label: "Save",
      disabled: isSubmitting || !isValid || disableSave,
      onClick: handleSubmit((formValues) => handleSave(formValues)),
    },
    {
      testId: "editFAQCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: goBackToList,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const returnData = await faqListService.getByPermalink(permalink);

        if (!mounted.current) {
          return;
        }

        reset(returnData.data);
      } catch (err) {
        if (!mounted.current) {
          return;
        }

        showSnackbar(errorMessages.generic, snackbarTypes.error);
        console.error(err);
      }
    };
    if (!isCreate) {
      getData();
    }
  }, []);

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <AmoPageHeader
            title={`${isCreate ? "New" : "Edit"} FAQ`}
            backLinkText="Back to FAQ List"
            backLinkTo={routes.contentManagement.help.list}
          />
        </Grid>

        <Grid item>
          <Grid item>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Typography variant="h6" className={classes.formContainer}>
                Please enter a question for the FAQ Page
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <AmoTextField
              id="faqQuestion"
              control={control}
              label="Question"
              name="question"
              variant="outlined"
              className={classes.formContainer}
            />
          </Grid>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <AmoTextField
              id="faqPermalink"
              control={control}
              label="Permalink"
              name="permalink"
              variant="outlined"
              className={classes.formContainer}
              disabled={disablePermalink}
              InputProps={{
                endAdornment: disableSave && (
                  <InputAdornment position="end">
                    <CircularProgress />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.formContainer}>
              <AmoRichTextEditor
                control={control}
                name="answer"
                label="Write your answer here"
                setError={setError}
                clearErrors={clearErrors}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              className={clsx(
                classes.formContainer,
                classes.secondaryFormContainer
              )}
            >
              <AmoSwitch
                id="faqIsPublished"
                color="primary"
                control={control}
                name="isPublished"
                label="Publish"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <AmoTextField
              id="helpSortOrder"
              control={control}
              label="Sort Order"
              name="sortOrder"
              variant="outlined"
              className={classes.formContainer}
              numberFormatProps={{ decimalScale: 0 }}
            />
          </Grid>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.formContainer}>
              <AmoFormActions actions={formActions} hideLateralBorders />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default EditFAQ;
