import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import colors from "../../constants/colors";
import { useNavigationHelper } from "../../hooks/navigationHook";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  menuContainer: {
    "& > .MuiGrid-item:last-child": { paddingRight: "0" },
  },
  menuItemSpacing: {
    padding: "0rem 1.75rem",
  },
  menuNavLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  menuActiveLink: {
    color: colors.black,
    backgroundColor: colors.grey.light,
  },
}));

/**
 * A menu component with items for non logged visitors
 *
 * @returns {React.Component} The menu items component
 */
const LoggedOutMenu = () => {
  const classes = useStyles();
  const { handleSamePathNavigation } = useNavigationHelper();

  return (
    <>
      <Grid
        data-testid="headerLoggedOutMenu"
        container
        className={clsx(classes.menuContainer, classes.fullHeight)}
        justifyContent="flex-end"
        alignItems="center"
        spacing={0}
      >
        <Grid item className={classes.menuItemSpacing}>
          <NavLink
            data-testid="headerLoginLink"
            exact
            to="/login"
            onClick={(event) => handleSamePathNavigation(event, "/login")}
            className={classes.menuNavLink}
            activeClassName={classes.menuActiveLink}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <Typography align="center" variant="subtitle1">
              Log In
            </Typography>
          </NavLink>
        </Grid>
        <Grid item className={classes.menuItemSpacing}>
          <NavLink
            data-testid="headerHelpLink"
            exact
            to="/help"
            onClick={(event) => handleSamePathNavigation(event, "/help")}
            className={classes.menuNavLink}
            activeClassName={classes.menuActiveLink}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <Typography align="center" variant="subtitle1">
              Help
            </Typography>
          </NavLink>
        </Grid>
      </Grid>
    </>
  );
};

export default LoggedOutMenu;
