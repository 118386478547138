import { AccordionDetails, ListItemText } from "@material-ui/core";
import React from "react";
import { useFaqAnswer } from "./helpPageHooks";
import PropTypes from "prop-types";

import parse from "html-react-parser";

/**
 * Shows the answer content for an FAQ when the panel is expanded.
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.faqId - the faq id of this item
 * @param {object} props.classes - styling for the details text
 * @returns {React.Component} The FAQ details component that displays under a question
 */
export const FaqDetails = ({ classes, faqId }) => {
  const { data } = useFaqAnswer(faqId);
  return (
    <AccordionDetails>
      <ListItemText
        primaryTypographyProps={{
          variant: "body1",
        }}
        className={classes.primaryTextDark}
      >
        {parse(data?.data?.answer ?? "")}
      </ListItemText>
    </AccordionDetails>
  );
};

FaqDetails.propTypes = {
  classes: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number])
  ),
  faqId: PropTypes.string.isRequired,
};

FaqDetails.defaultProps = {
  classes: "",
};
