import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import colors from "constants/colors";
import AmoPopupMenu from "components/AmoPopupMenu";
import AmoPopupMenuItem from "components/AmoPopupMenuItem";
import { useNavigationHelper } from "hooks/navigationHook";
import { routes } from "constants/routes";
import AmoPopupYearMenu from "components/AmoPopupYearMenu";
import { projectInterfaceIcons } from "constants/projectInterfaceIcons";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  menuContainer: {
    "& > .MuiGrid-item:last-child": { paddingRight: "0" },
  },
  menuItemSpacing: {
    padding: "0rem 1.75rem",
  },
  menuNavLink: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  menuLinkPopup: {
    padding: "0rem 1.75rem",
    height: "4.25rem",
  },
  menuLinkPopupActive: {
    color: colors.black,
    backgroundColor: colors.grey.light,
  },
  menuActiveLink: {
    color: colors.black,
    backgroundColor: colors.grey.light,
  },
}));

/**
 * A menu component with items for amo users
 *
 * @returns {React.Component} The menu component
 */
const AmoMenu = () => {
  const classes = useStyles();
  const { handleSamePathNavigation } = useNavigationHelper();

  return (
    <>
      <Grid
        container
        className={clsx(classes.menuContainer, classes.fullHeight)}
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <AmoPopupYearMenu
          id="menu-context-year-appbar"
          testId="headerContextYearMenu"
          classes={{
            button: classes.menuLinkPopup,
            buttonActive: classes.menuLinkPopupActive,
          }}
        />
        <Grid item>
          <Grid
            data-testid="headerAmoMenu"
            container
            className={clsx(classes.menuContainer, classes.fullHeight)}
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
          >
            <Grid item className={classes.menuItemSpacing}>
              <NavLink
                data-testid="headerHomeLink"
                exact
                to="/landing"
                onClick={(event) => handleSamePathNavigation(event, "/landing")}
                className={classes.menuNavLink}
                activeClassName={classes.menuActiveLink}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                <Typography align="center" variant="subtitle1">
                  Home
                </Typography>
              </NavLink>
            </Grid>
            <Grid item>
              <AmoPopupMenu
                id="menu-rvw-appbar"
                testId="headerReviewMenu"
                anchor="parent"
                title="Review"
                classes={{
                  button: classes.menuLinkPopup,
                  buttonActive: classes.menuLinkPopupActive,
                }}
              >
                <AmoPopupMenuItem
                  to={routes.reviews.projects.list}
                  iconName={projectInterfaceIcons.PROJECTS}
                  text="Projects"
                  testId="headerReviewMenuProj"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.financial.list}
                  iconName={projectInterfaceIcons.FINANCIALS}
                  text="Financial Information"
                  testId="headerReviewMenuExpenditure"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.projects.results}
                  iconName={projectInterfaceIcons.RESULTS}
                  text="Results"
                  testId="headerReviewMenuOut"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.questionnaires.list}
                  iconName={projectInterfaceIcons.QUESTIONNAIRE_REVIEW}
                  text="Questionnaire Review"
                  testId="headerReviewMenuQuest"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.bankingInformation.list}
                  iconName={projectInterfaceIcons.BANKING_INFORMATION}
                  text="Banking Information"
                  testId="headerReviewMenuBankInfo"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.bankLimit}
                  iconName={projectInterfaceIcons.BANKING_LIMIT_MONITORING}
                  text="Banking Limit Monitoring"
                  testId="headerReviewMenuBankLimit"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.incrementalityMonitoring.list}
                  iconName={projectInterfaceIcons.INCREMENTALITY_MONITORING}
                  text="Incrementality Monitoring"
                  testId="headerReviewMenuIncr"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.insurance.list}
                  iconName={projectInterfaceIcons.INSURANCE}
                  text="Insurance"
                  testId="headerReviewMenuInsurance"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.reportingStatus.list}
                  iconName={projectInterfaceIcons.REPORTING_STATUS}
                  text="Reporting Status"
                  testId="headerReviewMenuReportingStatus"
                />
                <AmoPopupMenuItem
                  to={routes.reviews.reportQuery}
                  iconName={projectInterfaceIcons.PROJECT_LIST}
                  text="Report Query"
                  testId="headerReviewMenuReportQuery"
                />
              </AmoPopupMenu>
            </Grid>
            <Grid item>
              <AmoPopupMenu
                id="menu-mngmt-appbar"
                testId="headerManagementMenu"
                anchor="parent"
                title="Content Management"
                classes={{
                  button: classes.menuLinkPopup,
                  buttonActive: classes.menuLinkPopupActive,
                }}
              >
                <AmoPopupMenuItem
                  to={routes.contentManagement.forms.list}
                  iconName={projectInterfaceIcons.FORMS}
                  text="Forms"
                  testId="headerManagementMenuForms"
                />
                <AmoPopupMenuItem
                  to={routes.contentManagement.announcements.list}
                  iconName={projectInterfaceIcons.ANNOUNCEMENTS}
                  text="Announcements"
                  testId="headerManagementMenuAnnounc"
                />
                <AmoPopupMenuItem
                  to={routes.contentManagement.outputsOutcomes.list}
                  iconName={projectInterfaceIcons.OUTPUTS_OUTCOMES}
                  text="Outputs & Outcomes"
                  testId="headerManagementMenuOut"
                />
                <AmoPopupMenuItem
                  to={routes.contentManagement.questionnaires.list}
                  iconName={projectInterfaceIcons.QUESTIONNAIRES}
                  text="Questionnaire Management"
                  testId="headerManagementMenuQuest"
                />
                <AmoPopupMenuItem
                  to={routes.contentManagement.help.list}
                  iconName={projectInterfaceIcons.HELP}
                  text="Help"
                  testId="headerManagementMenuHelp"
                />
                <AmoPopupMenuItem
                  to={routes.contentManagement.notifications.list}
                  iconName={projectInterfaceIcons.NOTIFICATIONS}
                  text="Notification Management"
                  testId="headerManagementMenuNotifications"
                />
              </AmoPopupMenu>
            </Grid>
            <Grid item>
              <AmoPopupMenu
                id="menu-comm-appbar"
                testId="headerCommunicationsMenu"
                anchor="parent"
                title="Communications"
                classes={{
                  button: classes.menuLinkPopup,
                  buttonActive: classes.menuLinkPopupActive,
                }}
              >
                <AmoPopupMenuItem
                  to="/communications/dashboard"
                  iconName={projectInterfaceIcons.DASHBOARD}
                  text="Dashboard"
                  testId="headerCommunicationsMenuDashboard"
                />
                <AmoPopupMenuItem
                  to={routes.communications.projects.list}
                  iconName={projectInterfaceIcons.PROJECT_LIST}
                  text="Project List"
                  testId="headerCommunicationsMenuProj"
                />
              </AmoPopupMenu>
            </Grid>
            <Grid item className={classes.menuItemSpacing}>
              <NavLink
                data-testid="headerUsersLink"
                to="/users"
                onClick={(event) => handleSamePathNavigation(event, "/users")}
                className={classes.menuNavLink}
                activeClassName={classes.menuActiveLink}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                <Typography align="center" variant="subtitle1">
                  Users
                </Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AmoMenu;
