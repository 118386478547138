import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  municipalityId: number,
 *  yearStart: number,
 *  yearEnd: number }} AerChartParams
 */

/**
 * @typedef {{
 *  year: number,
 *  openBalance: number,
 *  closeBalance: number,
 *  revenues: number,
 *  netTransfers: number,
 *  expenditures: number }} AerChartItem
 */

/**
 * @typedef {{
 *  municipalityId: number,
 *  years: AerChartItem[] }} AerChartResponse
 */

const api = new Api("api/amo/review/financials");
export const aerChartService = {
  /**
   * @param {number} municipalityId
   * @param {AerChartParams} params
   * @returns {Promise<AxiosResponse<AerChartResponse>>}
   */
  getById: (municipalityId, params) =>
    api.getById(`${municipalityId}/chart`, params),
};
