import React, { useEffect, useRef, useState } from "react";
import { Button, TextField, Box, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, Modifier, SelectionState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import PropTypes from "prop-types";
import { Code } from "@material-ui/icons";
import clsx from "clsx";

const editorToolbar = {
  height: "2.25rem",
  backgroundColor: "initial",
  visibility: "visible",
  border: "initial",
  margin: "0",
  padding: "0",
  "& .rdw-inline-wrapper": {
    "& .rdw-option-active": {
      backgroundColor: "#dedede",
      boxShadow: "initial",
    },
  },
  "& .rdw-option-wrapper:hover": {
    boxShadow: "initial",
    backgroundColor: "#e8e8e8",
  },
  "& .rdw-option-wrapper": {
    background: "initial",
    border: "initial",
  },
};

const editor = {
  border: "solid 0.063rem silver",
  borderRadius: "0.25rem",
  padding: "0 0.75rem 0 0.75rem",
  fontWeight: "normal",
  fontFamily: "Roboto",
  "&:hover": {
    borderColor: "#333333",
    cursor: "text",
  },
  "& .public-DraftEditorPlaceholder-hasFocus": {
    color: "#9197a3",
  },
  minHeight: "15.875rem",
};

const useStyles = makeStyles((theme) => ({
  root: {
    // 15px 100px
    padding: "0.9375rem 6.25rem",
    flexGrow: 1,
  },
  title: {
    color: colors.black,
  },
  form: {
    maxWidth: "49rem",
    margin: "auto",
  },
  button: {
    minWidth: "15rem",
    minHeight: "3.125rem",
    fontSize: "1rem",
    borderWidth: "0.125rem",
    "&:hover": {
      borderWidth: "0.125rem",
    },
  },
  editorWrapper: {
    width: "100%",
    wordBreak: "break-word",
  },
  editorDisabledWrapper: {
    width: "100%",
  },
  editorToolbar,
  editorToolbarDisabled: {
    ...editorToolbar,
    "& div": {
      "& .rdw-option-wrapper": {
        opacity: "0.3",
        cursor: "default",
        pointerEvents: "none",
      },
    },
  },
  editorToolbarNoMargin: {
    height: "auto",
    "& .rdw-option-wrapper": {
      margin: 0,
    },
  },
  // Match Title's field MUI styles
  editor,
  editorError: {
    ...editor,
    borderColor: "#E05343",
    "&:hover": {
      borderColor: "#E05343",
    },
  },
  editorSingleLine: {
    minHeight: "3.5rem",
  },
  editorHidden: {
    display: "none",
  },
  textDisabled: {
    color: "rgba(0, 0, 0, 0.38) !important",
  },
  rawHtmlTextField: {
    "& textarea": {
      minHeight: "calc(15.875rem - 37px)",
    },
  },
  adaptableRawHtmlTextField: {
    "& textarea": {
      minHeight: "calc(3.5rem - 37px)",
    },
  },
  editorLabel: {
    color: colors.grey.dark,
    fontSize: "0.75rem",
    fontFamily: "Roboto",
    fontWeight: "normal",
    lineHeight: 1,
    display: "block",
    zIndex: 1,
    position: "absolute",
    top: "1.875rem",
    left: "0.5rem",
    backgroundColor: colors.background,
    padding: "0rem 0.375rem",
  },
  toolbarCustomButtonsWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.375rem",
    position: "relative",
    flexWrap: "wrap",
    marginLeft: "auto",
    "& button": {
      borderColor: "rgba(0, 0, 0, 0.87)",
      borderWidth: "0.125rem",
      height: "1.688rem",
    },
  },
  buttonWithIcon: {
    paddingLeft: "0.875rem",
    "& svg": {
      marginRight: "0.5rem",
    },
  },
  disabledToolbarButton: {
    opacity: "0.3",
    cursor: "default",
    pointerEvents: "none",
  },
  richTextEditorWrapper: {
    height: "100%",
  },
  errorHint: {
    color: "#E05343",
    marginLeft: "0.875rem",
    marginRight: "0",
    fontSize: "0.75rem",
    marginTop: "0.188rem",
    textAlign: "left",
    fontFamily: "Roboto",
    fontWeight: "lighter",
    lineHeight: "1.66",
    "&:after": {
      fontFamily: "Material Icons",
      content: "'warning'",
      color: colors.red.main,
      float: "right",
    },
  },
  editToggle: {
    color: "white",
    background: "rgba(0, 0, 0, 0.87) !important",
  },
}));

const RichTextEditor = (props) => {
  const {
    id,
    testId,
    value,
    placeholder,
    onChange,
    internalErrorMessage,
    error,
    helperText,
    onBlur,
    disabled,
    decreaseToolbarSpacing,
    isSingleLineInput,
    simpleToolbar,
    adaptableMultiline,
    hideBold,
    label,
    required,
  } = props;
  const classes = useStyles();

  // Options
  const INLINE = "inline";
  const LIST = "list";
  const LINK = "link";
  const IMAGE = "image";
  const HEADER_TWO = "header-two";
  // Inline options
  const BOLD = "bold";
  const ITALIC = "italic";
  const UNDERLINE = "underline";
  // Block types
  const ATOMIC = "atomic"; // For images
  const UNSTYLED = "unstyled";
  const ORDERED_LIST = "unordered-list-item";
  const UNORDERED_LIST = "ordered-list-item";

  const allowedOptionTypes = [LINK, IMAGE];
  const allowedBlockTypes = [
    ATOMIC,
    UNSTYLED,
    ORDERED_LIST,
    UNORDERED_LIST,
    HEADER_TWO,
  ];
  const allowedInlineOptionStyles = [BOLD, ITALIC, UNDERLINE];
  const defaultOptions = [INLINE, LIST, LINK, IMAGE];
  const singleLineOptions = [INLINE, LINK];

  const linkHandle = useRef(null);
  const toolbarLinkConfig = {
    [LINK]: {
      defaultTargetOption: "_blank",
      linkCallback: (linkValue) => {
        linkHandle.current = linkValue;
        return linkValue;
      },
    },
  };

  const toolbarConfig = {
    options:
      isSingleLineInput || simpleToolbar ? singleLineOptions : defaultOptions,
    inline: { options: [...(hideBold ? [] : [BOLD]), ITALIC, UNDERLINE] },
    ...toolbarLinkConfig,
  };
  const simpleToolbarConfig = {
    options: [INLINE, LINK],
    inline: { options: [...(hideBold ? [] : [BOLD]), ITALIC, UNDERLINE] },
    ...toolbarLinkConfig,
  };

  const [editingHtml, setEditingHtml] = useState(false);
  const [preventHtmlFocus, setPreventHtmlFocus] = useState(true);

  const [editorStatus, setEditorStatus] = useState({
    rawHtml: "",
    state: EditorState.createEmpty(),
    isValid: true,
  });

  const isEditorValid = (state) => {
    const editorRawData = convertToRaw(state.getCurrentContent());

    return (
      Object.values(editorRawData.entityMap).every((entity) =>
        allowedOptionTypes.includes(entity.type.toLowerCase())
      ) &&
      editorRawData.blocks.every(
        (block) =>
          allowedBlockTypes.includes(block.type.toLowerCase()) &&
          block.inlineStyleRanges.every((rangeObj) =>
            allowedInlineOptionStyles.includes(rangeObj.style.toLowerCase())
          )
      )
    );
  };

  /**
   * Cleans html result.
   * By default, stateToHTML gets the result wrapped with a paragraph tag
   * and if it is empty, a <br> is inserted, like so:
   * <p><br></p>
   *
   * @param {string} htmlString
   * @returns {string} Curated HTML string
   */
  const cleanConvertedHtml = (htmlString) =>
    htmlString === "<p><br></p>" || htmlString === "<br>" ? "" : htmlString;

  /**
   * Retrieves editor content and converts it to HTML
   *
   * @param {EditorState} state
   * @returns {string} EditorState's content as HTML
   */
  const getHtmlFromState = (state) =>
    cleanConvertedHtml(
      stateToHTML(state.getCurrentContent(), {
        // Override and replace "strong", "em", and "ins" tags
        inlineStyles: {
          BOLD: { element: "b" },
          ITALIC: { element: "i" },
          UNDERLINE: { element: "u" },
        },
        // A custom function is passed down to the library and works like this, this rule doesn't apply here
        // eslint-disable-next-line consistent-return
        entityStyleFn: (entity) => {
          const entityType = entity.get("type").toLowerCase();
          if (entityType === "link") {
            const data = entity.getData();
            const target =
              data.target === undefined ? data.targetOption : data.target;
            return {
              element: "a",
              attributes: {
                href: data.url,
                target,
              },
            };
          }
        },
        defaultBlockTag: isSingleLineInput ? null : "p",
      })
    );

  /**
   * Converts raw HTML to EditorState
   *
   * @param {string} html
   * @returns Parsed HTML
   */
  const getStateFromHtml = (html) =>
    EditorState.createWithContent(stateFromHTML(html));

  /**
   * Updates editor state and raw html from the HTML string
   *
   * @param {string} html
   */
  const updateValuesFromHtml = (html) => {
    let finalHtml = html;
    if (linkHandle?.current != null) {
      const newLinkTagStart = `<a href="${linkHandle.current.target}" target="${linkHandle.current.targetOption}">`;
      const newLinkText = `${linkHandle.current.title}`;
      const newLinkTagEnd = `</a>`;
      const completeLink = `${newLinkTagStart}${newLinkText}${newLinkTagEnd}`;

      // Looks for the start of the link added to the text, since this part is consistent and not affected by random added spaces
      let indexOfNewLink = html.indexOf(completeLink);

      // Search in the previous html value if the text is present already present at same indexes, or if a new text is being added instead
      // (There are two options for inserting a link: New inserted link entirely at cursor or selected text converted to link)
      const previousHtml = editorStatus.rawHtml;
      const indexOfNewLinkInPrevious = previousHtml.indexOf(completeLink);
      while (
        indexOfNewLink >= 0 &&
        indexOfNewLink === indexOfNewLinkInPrevious
      ) {
        indexOfNewLink = html.indexOf(completeLink, indexOfNewLink + 1);
      }

      const addingLinkToExistingText =
        previousHtml.slice(
          indexOfNewLink,
          indexOfNewLink + newLinkText.length
        ) === newLinkText;

      // Replace all HTML surrounding the new added link with previous HTML to avoid random added spaces in new html because of link addition
      const beforeLinkPart = previousHtml.slice(0, indexOfNewLink);
      const afterLinkPart = previousHtml.slice(
        indexOfNewLink + (addingLinkToExistingText ? newLinkText.length : 0)
      );

      // Adding differentiating for all links to distinguish newly added ones
      const finalLink = completeLink.replace(
        ` target="${linkHandle.current.targetOption}"`,
        linkHandle.current.targetOption === "_blank" ? ` target="?"` : ""
      );

      // NOTE: With current logic when editing the text through the editor's popup, a new link will be created instead of editing previous one
      finalHtml = `${beforeLinkPart}${finalLink}${afterLinkPart}`;

      linkHandle.current = null;
    }
    const state = getStateFromHtml(finalHtml);
    const isValid = isEditorValid(state);
    setEditorStatus({
      rawHtml: finalHtml,
      state,
      isValid,
    });
    onChange(finalHtml, isValid);
  };

  /**
   * Updates editor state and raw html from EditorState
   *
   * @param {EditorState} state
   */
  const updateValuesFromEditorState = (state) => {
    const html = getHtmlFromState(state);
    const valueInHtml = getHtmlFromState(getStateFromHtml(value));

    const isValid = isEditorValid(state);
    const htmlHasChanged = valueInHtml !== html;
    setEditorStatus({
      rawHtml: html,
      state,
      isValid,
    });
    if (htmlHasChanged) onChange(html, isValid);
    if (linkHandle.current) updateValuesFromHtml(html);
  };

  const pastedTextHandler = (text, html, editorstate) => {
    const openTagRegex = /(&lt;)/g;
    const closeTagRegex = /(&gt;)/g;

    // If the pasted text has html formatting then process it, if not go to process text
    if (html) {
      let newHtml = html.replace(openTagRegex, "<");
      newHtml = newHtml.replace(closeTagRegex, ">");

      const state = getStateFromHtml(newHtml);

      let newContent = state.getCurrentContent();
      const rawNewContent = convertToRaw(newContent);
      rawNewContent.blocks.forEach((block, i) => {
        // Remove unsupported styles
        const targetRange = new SelectionState({
          anchorKey: block.key,
          anchorOffset: 0,
          focusKey: block.key,
          focusOffset: block.text.length - 1,
        });

        if (!allowedBlockTypes.includes(block.type.toLowerCase())) {
          newContent = Modifier.setBlockType(
            newContent,
            targetRange,
            "unstyled"
          );
        }

        block.inlineStyleRanges.forEach((style) => {
          if (!allowedInlineOptionStyles.includes(style.style.toLowerCase())) {
            newContent = Modifier.removeInlineStyle(
              newContent,
              targetRange,
              style.style
            );
          }
        });
      });

      const modifiedContent = Modifier.replaceWithFragment(
        editorstate.getCurrentContent(),
        editorstate.getSelection(),
        newContent.getBlockMap()
      );
      const newState = EditorState.push(
        editorstate,
        modifiedContent
      ).getCurrentContent();

      updateValuesFromEditorState(EditorState.createWithContent(newState));

      // returning true overrides the default behaviour on pasted text
      return true;
    }

    // Create new state from text, considering potential html content
    const newText = text.replace(openTagRegex, "<").replace(closeTagRegex, ">");

    const textState = getStateFromHtml(newText);
    const textContent = textState.getCurrentContent();

    const modifiedContent = Modifier.replaceWithFragment(
      editorstate.getCurrentContent(),
      editorstate.getSelection(),
      textContent.getBlockMap()
    );
    const newState = EditorState.push(editorstate, modifiedContent);

    updateValuesFromEditorState(newState);
    // returning true overrides the default behaviour on pasted text
    return true;
  };

  const editHtmlButtonRef = useRef();
  const editHtmlButton = (editing = false) => (
    <>
      <div className={classes.toolbarCustomButtonsWrapper}>
        <Button
          className={`${classes.buttonWithIcon} ${
            editing ? classes.editToggle : ""
          }`}
          ref={editHtmlButtonRef}
          variant="outlined"
          onClick={() => setEditingHtml(!editingHtml)}
          disabled={disabled}
        >
          <Code />
          Edit HTML
        </Button>
      </div>
    </>
  );

  // sets the preventHtmlFocus state to false AFTER the first render - this prevents the editHtmlButton ref from focusing as soon as the page loads
  useEffect(() => {
    setPreventHtmlFocus(false);
  }, []);

  // Set the focus on the same button after rendering the alternative editor mode (WYSIWYG/HTML)
  useEffect(() => {
    if (!preventHtmlFocus && editHtmlButtonRef.current) {
      editHtmlButtonRef.current.focus();
    }
  }, [editingHtml]);

  useEffect(() => {
    if (value && value !== editorStatus.rawHtml) updateValuesFromHtml(value);
  }, [value]);

  return (
    <>
      <Box style={{ position: "relative" }}>
        {label && editorStatus.rawHtml && (
          <Box
            className={clsx(
              classes.editorLabel,
              disabled ? classes.textDisabled : null
            )}
          >
            {`${label}${required ? " *" : ""}`}
          </Box>
        )}
        {!editingHtml && (
          <>
            <Editor
              id={id}
              data-testid={testId ?? `${id}-test`}
              readOnly={disabled}
              placeholder={placeholder}
              editorState={editorStatus.state}
              toolbarClassName={clsx(
                classes.editorToolbar,
                decreaseToolbarSpacing ? classes.editorToolbarNoMargin : null
              )}
              wrapperClassName={classes.editorWrapper}
              localization={{
                locale: "en",
                translations: {
                  "components.controls.link.linkTitle": "Text",
                  "components.controls.link.linkTarget": "URL",
                },
              }}
              editorClassName={clsx(
                !editorStatus.isValid || error
                  ? classes.editorError
                  : classes.editor,
                isSingleLineInput ? classes.editorSingleLine : null,
                disabled ? classes.textDisabled : null
              )}
              editorStyle={adaptableMultiline ? { minHeight: "auto" } : {}}
              onEditorStateChange={updateValuesFromEditorState}
              onBlur={onBlur}
              toolbar={toolbarConfig}
              toolbarCustomButtons={[editHtmlButton()]}
              handlePastedText={pastedTextHandler}
              // If single line, override the return function to disable new line returning true here
              handleReturn={() => isSingleLineInput}
            />
          </>
        )}
        {editingHtml && (
          <>
            {/* Only display disabled toolbar */}
            <Editor
              id={id}
              data-testid={testId ?? `${id}-test`}
              readOnly
              toolbarClassName={classes.editorToolbarDisabled}
              wrapperClassName={classes.editorDisabledWrapper}
              editorClassName={classes.editorHidden}
              toolbar={isSingleLineInput ? simpleToolbarConfig : toolbarConfig}
              toolbarCustomButtons={[editHtmlButton(true)]}
              // If single line, override the return function to disable new line returning true here
              handleReturn={() => isSingleLineInput}
            />
            <TextField
              className={
                adaptableMultiline
                  ? classes.adaptableRawHtmlTextField
                  : classes.rawHtmlTextField
              }
              placeholder={placeholder}
              fullWidth
              value={editorStatus.rawHtml}
              onChange={(event) => updateValuesFromHtml(event.target.value)}
              multiline={!isSingleLineInput}
              variant="outlined"
              focused={false}
              onBlur={onBlur}
              error={error}
              disabled={disabled}
              minRows={1}
            />
          </>
        )}
        {(!editorStatus.isValid || error) && (
          <FormHelperText error style={{ paddingLeft: "0.5rem" }}>
            {/* Prefer internal error message  */}
            {!editorStatus.isValid ? internalErrorMessage : helperText}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

RichTextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  internalErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  decreaseToolbarSpacing: PropTypes.bool,
  isSingleLineInput: PropTypes.bool,
  simpleToolbar: PropTypes.bool,
  adaptableMultiline: PropTypes.bool,
  hideBold: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
};

RichTextEditor.defaultProps = {
  testId: null,
  value: undefined,
  placeholder: undefined,
  onChange: undefined,
  error: undefined,
  helperText: undefined,
  onBlur: undefined,
  internalErrorMessage:
    "Invalid content, please make sure you are using only allowed HTML: bold, italic, underline, images, anchors, lists and blocks.",
  disabled: false,
  decreaseToolbarSpacing: false,
  isSingleLineInput: false,
  simpleToolbar: false,
  adaptableMultiline: false,
  hideBold: false,
  label: undefined,
  required: false,
};

export default RichTextEditor;
