import React from "react";
import { Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ReactComponent as Logo } from "../amoLogo_simple.svg";
import colors from "../constants/colors";
import { roles } from "../constants/user";
import MunicipalMenu from "./menu/MunicipalMenu";
import AmoMenu from "./menu/AmoMenu";
import CommMenu from "./menu/CommMenu";
import LoggedOutMenu from "./menu/LoggedOutMenu";
import AmoPopupMenu from "./AmoPopupMenu";
import AmoPopupMenuItem from "./AmoPopupMenuItem";
import { useUserContext } from "../contexts/UserContext";
import { routes } from "../constants/routes";
import { projectInterfaceIcons } from "constants/projectInterfaceIcons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.white,
    borderBottom: `0.125rem solid ${colors.green.border}`,
    height: "4.375rem",
    flexGrow: 1,
    padding: "0rem 0.625rem 0rem 1.875rem",
  },
  fullHeight: {
    height: "100%",
  },
  appBarGrid: {
    "& > .MuiGrid-item": {
      height: "100%",
      marginRight: "0.75rem",
    },
    "& > .MuiGrid-item:last-child": { marginRight: 0 },
  },
  menuIconPopup: {
    display: "flex",
    alignItems: "center",
    padding: "0rem 1.25rem",
    height: "4.25rem",
    color: theme.palette.primary.main,
    borderRadius: 0,
  },
  menuIconPopupActive: {
    backgroundColor: colors.grey.light,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "7rem",
    height: "3.875rem",
    padding: "8% 0",
  },
}));

/**
 * A header component for desktop
 *
 * @returns {React.Component} The header component
 */
const DesktopHeader = () => {
  const classes = useStyles();
  const { user } = useUserContext();

  const renderMenu = () => {
    // Logged out menu
    if (!user.isAuthenticated) {
      return <LoggedOutMenu />;
    }

    // AMO Menu
    if (user.roles.includes(roles.amo)) {
      return <AmoMenu />;
    }
    // Communications Menu
    if (user.roles.includes(roles.communications)) {
      return <CommMenu />;
    }

    // Municipal menu
    return <MunicipalMenu />;
  };

  return (
    <>
      <Grid
        data-testid="desktopHeader"
        container
        className={clsx(classes.appBarGrid, classes.fullHeight)}
        justifyContent="space-between"
        alignContent="stretch"
      >
        <Grid item className={classes.logoContainer}>
          <Link href={routes.dashboard}>
            <Logo className={classes.logo} data-testid="logo" />
          </Link>
        </Grid>
        <Grid item xs>
          {renderMenu()}
        </Grid>
        <Grid item>
          {user.isAuthenticated && (
            <AmoPopupMenu
              id="menu-account-appbar"
              testId="headerAccountMenu"
              type="icon"
              anchor="parent"
              title="account_circle"
              classes={{
                button: classes.menuIconPopup,
                buttonActive: classes.menuIconPopupActive,
              }}
            >
              {!user.roles.includes(roles.amo) && (
                <AmoPopupMenuItem
                  to="/account"
                  iconName={projectInterfaceIcons.ACCOUNT}
                  text="Account"
                  testId="headerAccountMenuAcc"
                />
              )}
              <AmoPopupMenuItem
                to="/logout"
                iconName={projectInterfaceIcons.LOGOUT}
                text="Log Out"
                testId="headerAccountMenuLogout"
              />
            </AmoPopupMenu>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DesktopHeader;
