import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { routes } from "constants/routes";
import FileUploadList from "components/FileUploadList";
import ListPageWrapper from "components/ListPageWrapper";
import AmoFormActions from "components/AmoFormActions";
import AmoPageHeader from "components/AmoPageHeader";
import { useSnackbar } from "contexts/SnackbarContext";
import { errorMessages } from "constants/errorMessages";
import { snackbarTypes } from "constants/snackbar";
import clsx from "clsx";
import { helpListService } from "api/services/helpListService";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AmoTextField from "components/inputs/AmoTextField";
import AmoRichTextEditor from "components/inputs/AmoRichTextEditor";
import AmoSwitch from "components/inputs/AmoSwitch";
import { usePermalink } from "hooks/permalinkHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    position: "relative",
    width: "50%",
    marginTop: "1.5rem",
  },
  secondaryFormContainer: {
    marginTop: "0.25rem",
    fontSize: "1rem",
    fontWeight: 400,
  },
}));

/**
 * The AMO Help Edit Page, for editing help content.
 *
 * @returns {React.Component} AMO Help Edit Page component.
 */
const EditPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { showSnackbar } = useSnackbar();
  const { permalink } = useParams();
  const mounted = useIsMounted();

  const isCreate = permalink === "new";
  const unblockNavigationFunc = useRef();
  const unblockNavigationData = useRef(null);

  const [fileData, setFileData] = useState([]);
  const [pageIsLoaded, setPageIsLoaded] = useState(false);
  const [pageId, setPageId] = useState(-1);
  const [fileUploadAction, setFileUploadAction] = useState("Idle");
  const previousFileData = useRef([]);
  const postRollbackAction = useRef();

  const fileIsDirty = useMemo(
    () =>
      fileData?.length !== previousFileData.current?.length ||
      fileData.some(
        (file) =>
          !previousFileData.current.some(
            (previousFile) => file.id === previousFile.id
          )
      ),
    [fileData, previousFileData.current]
  );

  const validationSchema = yup
    .object({
      title: yup.string().required(),
      content: yup.string().required(),
      permalink: yup.string().required(),
      isPublished: yup.boolean().required(),
      sortOrder: yup
        .number()
        .typeError("Sort sequence must be a number")
        .required(),
    })
    .required();

  const defaultValues = {
    title: "",
    content: "",
    permalink: "",
    isPublished: true,
    sortOrder: null,
  };

  const {
    clearErrors,
    control,
    handleSubmit,
    setError,
    reset,
    formState,
    getValues,
    setValue,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [disablePermalink, disableSave] = usePermalink(
    "title",
    "permalink",
    permalink,
    helpListService.getByPermalink,
    control,
    getValues,
    setValue,
    setError,
    clearErrors
  );

  const handleCreate = async (formValues) => {
    try {
      if (isCreate) {
        await helpListService.create(formValues);

        if (!mounted.current) {
          return;
        }

        // Unblock navigation before trying to go back to the list page
        unblockNavigation();
      } else {
        await helpListService.update(permalink, formValues);

        if (!mounted.current) {
          return;
        }
      }

      showSnackbar(`Page ${isCreate ? "created" : "updated"} successfully.`);
      goBackToList();
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const goBackToList = () => {
    history.push(`${routes.contentManagement.help.list}${search}`);
  };

  const { isValid, isSubmitting } = formState;

  const onSave = (event) => {
    handleSubmit((formValues) =>
      handleCreate({
        ...formValues,
        fileIds: isCreate ? fileData?.map((item) => item.id) ?? [] : [],
      })
    )(event);
  };

  const onCancel = () => {
    if (isCreate && fileIsDirty) {
      postRollbackAction.current = () => {
        goBackToList();
      };
      setFileUploadAction("Rollback");
    } else {
      goBackToList();
    }
  };

  const formActions = [
    {
      testId: "editPageSaveButton",
      label: "Save",
      disabled: isSubmitting || !isValid || disableSave,
      onClick: onSave,
    },
    {
      testId: "editPageCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: onCancel,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const returnData = await helpListService.getByPermalink(permalink);

        if (!mounted.current) {
          return;
        }

        setPageId(returnData.data.id);
        reset(returnData.data);
        const returnedFiles = returnData.data.files;

        if (returnedFiles !== null) {
          setFileData(returnedFiles);
          previousFileData.current = returnedFiles;
        }
        setPageIsLoaded(true);
      } catch {
        if (!mounted.current) {
          return;
        }

        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
    };
    if (!isCreate) {
      getData();
    } else {
      setPageIsLoaded(true);
    }
  }, []);

  const unblockNavigation = () => {
    const transition = unblockNavigationData.current;
    if (unblockNavigationFunc.current) {
      unblockNavigationFunc.current();
      unblockNavigationFunc.current = null;
    }
    if (transition && transition?.nextLocation) {
      unblockNavigationData.current = null;
      const {
        pathname,
        search: blockedSearch,
        state,
      } = transition.nextLocation;
      const path = `${pathname}${blockedSearch}`;
      if (transition.action === "REPLACE") {
        history.replace(path, state);
      } else {
        history.push(path, state);
      }
    }
  };

  useEffect(() => {
    if (isCreate && fileIsDirty) {
      if (!unblockNavigationFunc.current) {
        unblockNavigationFunc.current = history.block(
          (nextLocation, action) => {
            unblockNavigationData.current = { nextLocation, action };

            if (isCreate && fileIsDirty) {
              setFileUploadAction("Rollback");
              return false;
            }

            unblockNavigation();
            return true;
          }
        );
      }
    } else {
      unblockNavigation();
    }
  }, [isCreate, fileIsDirty]);

  const onFileRollback = () => {
    setFileUploadAction("Idle");
    if (postRollbackAction.current) {
      postRollbackAction.current();
      postRollbackAction.current = null;
    }
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <AmoPageHeader
            title={`${isCreate ? "New" : "Edit"} Help Page`}
            backLinkText="Back to Page List"
            backLinkTo={routes.contentManagement.help.list}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <AmoTextField
              id="helpTitle"
              control={control}
              label="Page Title"
              name="title"
              variant="outlined"
              className={classes.formContainer}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <AmoTextField
            id="helpPermalink"
            control={control}
            label="Permalink"
            name="permalink"
            variant="outlined"
            className={classes.formContainer}
            disabled={disablePermalink}
            InputProps={{
              endAdornment: disableSave && (
                <InputAdornment position="end">
                  <CircularProgress />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.formContainer}>
              <AmoRichTextEditor
                control={control}
                name="content"
                label="Write your content here"
                setError={setError}
                clearErrors={clearErrors}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Typography variant="h4" className={classes.formContainer}>
              File Upload
            </Typography>
            <Typography
              className={clsx(
                classes.formContainer,
                classes.secondaryFormContainer
              )}
            >
              Upload relevant files here
            </Typography>
            {pageIsLoaded && (
              <Grid
                item
                className={clsx(
                  classes.formContainer,
                  classes.secondaryFormContainer
                )}
              >
                <FileUploadList
                  multiple
                  fileYear={DateTime.now().year}
                  fileAssociationId={5} // help files
                  fileAssociationObjectId={isCreate ? -1 : pageId}
                  onChange={setFileData}
                  initialValue={fileData}
                  uploadAction={fileUploadAction}
                  onRollback={onFileRollback}
                />
              </Grid>
            )}
            <Grid
              item
              className={clsx(
                classes.formContainer,
                classes.secondaryFormContainer
              )}
            >
              <AmoSwitch
                id="helpIsPublished"
                color="primary"
                control={control}
                name="isPublished"
                label="Publish"
                labelPlacement="end"
              />
            </Grid>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignItems="center"
            >
              <AmoTextField
                id="helpSortOrder"
                control={control}
                label="Sort Order"
                name="sortOrder"
                variant="outlined"
                className={classes.formContainer}
                numberFormatProps={{ decimalScale: 0 }}
              />
            </Grid>
            <Grid item>
              <AmoFormActions actions={formActions} hideLateralBorders />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default EditPage;
