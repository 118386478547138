import PropTypes from "prop-types";
import { displayTypes, displayTypesArray } from "./amoTableConstants";

export const cellColors = [
  "initial",
  "inherit",
  "primary",
  "secondary",
  "desc",
  "textPrimary",
  "textSecondary",
  "error",
];

export const cellVariants = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "subtitle1",
  "subtitle2",
  "body1",
  "body2",
  "caption",
  "button",
  "overline",
  "srOnly",
  "inherit",
];

/**
 * @typedef {object} AmoTableActionsProps
 * @property {string} tableId - id of the table component
 * @property {Node} titleComponent - component to be displayed on the title slot of the action
 * @property {string[]} actions - array of actions to be displayed on the order of the array
 * @property {object} actionsCustomConfigs - custom configuration of the default existing actions
 * @property {object} customActionsComponents - object where the keys should be present on 'actions' prop and the values should be custom components
 * @property {boolean} disableDefaultActions - if true, all default actions being displayed will be disabled
 * @property {boolean} showDividers - if true, it will display a light divider above and below the table actions
 */
export const AmoTableActionsProps = {
  tableId: PropTypes.string,
  titleComponent: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.string),
  actionsCustomConfigs: PropTypes.shape(),
  customActionsComponents: PropTypes.objectOf(PropTypes.node),
  disableDefaultActions: PropTypes.bool,
  showBottomDivider: PropTypes.bool,
};

/**
 * @typedef {object} AmoTableColumnProps
 * @property {string} fieldKey - key of the item property that contain this cell value [required]
 * @property {string} key - key of the column property serving as unique identifier, if not set it will use the fieldKey instead
 * @property {string} label - text to display in the column header and filter
 * @property {string|number} widthPerc - text or number to configure the size of this column width in percentage
 * @property {string|number} minWidth - text or number to configure the min size of this column width
 * @property {string|number} maxWidth - text or number to configure the max size of this column width
 * @property {string|Function} variant - sets this column item Typography variant (valid values: @see cellVariants or function that receives the item)
 * @property {string|Function} color - sets this cell item Typography color (valid values: @see cellColors or function that receives the item)
 * @property {boolean} hideLabel - controls if this column header label should be hidden
 * @property {boolean} hideSearch - controls if this column search input should be hidden
 * @property {boolean} isRounded - whether the cell value is rounded or not (only for currency display type)
 * @property {boolean} isNullable - whether the cell value should be null or not (only for currency display type)
 * @property {boolean} isCustom - whether the cell render is custom or not, if so it will use the customRender function if it's not null
 * @property {Function} customRender - custom function to display the cell value that receives the row item and expects the desired cell value as return
 * @property {Function} customExport - custom function to export the cell value that receives the row item and expects the desired export value as return
 * @property {string} verticalAlign - sets the vertical alignment for the cell ( valid values: "top", "middle", "bottom")
 * @property {string} horizontalAlign - sets the horizontal alignment for the cell ( valid values: "left", "center", "right", "initial")
 * @property {boolean} noHeaderWrap - if true, the column header will not wrap, but instead will truncate with a text overflow ellipsis.
 * @property {boolean} noCellWrap - if true, the cell value will not wrap, but instead will truncate with a text overflow ellipsis.
 * @property {object} dropdownOptions - object containing props for the dropdown options where the keys should be all available values
 * @property {string} displayType - the column value display type (valid values: @see displayTypes)
 * @property {string} className - sets the value Typography component className, if applicable
 * @property {string} cellClassName - sets the value TableCell component className
 */
export const AmoTableColumnProps = {
  fieldKey: PropTypes.string.isRequired,
  key: PropTypes.string,
  label: PropTypes.string,
  widthPerc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  variant: PropTypes.oneOfType([PropTypes.oneOf(cellVariants), PropTypes.func]),
  color: PropTypes.oneOfType([PropTypes.oneOf(cellColors), PropTypes.func]),
  hideLabel: PropTypes.bool,
  hideSearch: PropTypes.bool,
  isRounded: PropTypes.bool,
  isNullable: PropTypes.bool,
  isCustom: PropTypes.bool,
  customRender: PropTypes.func,
  customExport: PropTypes.func,
  verticalAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
  horizontalAlign: PropTypes.oneOf(["left", "center", "right", "initial"]),
  noHeaderWrap: PropTypes.bool,
  noCellWrap: PropTypes.bool,
  dropdownOptions: PropTypes.shape(),
  displayType: PropTypes.oneOf(displayTypesArray),
  className: PropTypes.string,
  cellClassName: PropTypes.string,
};

export const AmoTableColumnDefaultProps = {
  key: null,
  label: null,
  widthPerc: null,
  minWidth: null,
  maxWidth: null,
  variant: "inherit",
  color: "inherit",
  hideLabel: false,
  hideSearch: false,
  isRounded: false,
  isNullable: false,
  isCustom: false,
  customRender: () => {},
  verticalAlign: "middle",
  horizontalAlign: "initial",
  noHeaderWrap: false,
  noCellWrap: false,
  dropdownOptions: null,
  displayType: displayTypes.text,
  className: null,
  cellClassName: null,
};

const sharedTableProps = {
  id: PropTypes.string,
  testId: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(AmoTableColumnProps))
    .isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  defaultOrder: PropTypes.oneOf(["asc", "desc"]),
  defaultOrderBy: PropTypes.string,
  enableItemClick: PropTypes.bool,
  onItemClick: PropTypes.func,
  uniqueKeyGenerationFields: PropTypes.arrayOf(PropTypes.string),
  onScroll: PropTypes.func,
  focusedItem: PropTypes.shape({ id: PropTypes.number }),
  actionsProps: PropTypes.instanceOf(AmoTableActionsProps),
  isLoading: PropTypes.bool,
  hideTableContent: PropTypes.bool,
  showFilters: PropTypes.bool,
};

const sharedTableDefaultProps = {
  id: null,
  testId: null,
  defaultOrder: "asc",
  defaultOrderBy: null,
  enableItemClick: false,
  onItemClick: () => {},
  uniqueKeyGenerationFields: ["id"],
  onScroll: null,
  focusedItem: null,
  actionsProps: {},
  isLoading: false,
  hideTableContent: false,
  showFilters: false,
};

/**
 * @typedef {object} AmoTableProps
 * @property {string} id - id of the table component [required]
 * @property {string} testId - data-testid of the table component
 * @property {AmoTableColumnProps[]} columns - object containing props for the table column configuration [required]
 * @property {object[]} items - array containing the table items [required]
 * @property {string} defaultOrder - sets the direction the items should be ordered (valid values: "asc", "desc")
 * @property {string} defaultOrderBy - key of the item property @see columns.fieldKey that should be ordered by default
 * @property {boolean} enableItemClick - controls if the table items should be clickable or not
 * @property {Function} onItemClick - function called when user clicks on an item row (params: { item clicked })
 * @property {string[]} uniqueKeyGenerationFields - keys of the fields that will be used to generate an unique key value for the table cell components
 * @property {object} onScroll - callback on keyboard navigation scrolling
 * @property {boolean} focusedItem - has id of current focused item
 * @property {AmoTableActionsProps} actionsProps - the property for the actions of the table
 * @property {boolean} isLoading - controls whether the table data is loading or not
 * @property {boolean} hideTableContent - controls whether the table is hidden
 * @property {boolean} showFilters - controls if the table filters should be visible or not
 * @property {object[]} rawItems - array containing all table items, including the ones not being displayed on the table
 * @property {boolean} disableFilters - controls whether to disable the table filters or not (if disabled no filter configuration will be considered)
 * @property {Function} onRequestSort - function called when user clicks on a column header (params: { @see columns.fieldKey }, { order direction })
 * @property {Function} onFilterChange - function called when user types on a filter input (params: { @see columns.fieldKey }, { filter value })
 * @property {boolean} shouldUseQueryString - if true, AmoTable will set set and use the query string param for pagination and filtering
 */
export const AmoTableProps = {
  ...sharedTableProps,
  rawItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disableFilters: PropTypes.bool,
  onRequestSort: PropTypes.func,
  onFilterChange: PropTypes.func,
  shouldUseQueryString: PropTypes.bool,
};

/**
 * @typedef {object} AmoTableWithFiltersProps
 * @property {string} id - id of the table component [required]
 * @property {string} testId - data-testid of the table component
 * @property {AmoTableColumnProps[]} columns - object containing props for the table column configuration [required]
 * @property {object[]} items - array containing the table items [required]
 * @property {string} defaultOrder - sets the direction the items should be ordered (valid values: "asc", "desc")
 * @property {string} defaultOrderBy - key of the item property @see columns.fieldKey that should be ordered by default
 * @property {boolean} enableItemClick - controls if the table items should be clickable or not
 * @property {Function} onItemClick - function called when user clicks on an item row (params: { item clicked })
 * @property {string[]} uniqueKeyGenerationFields - keys of the fields that will be used to generate an unique key value for the table cell components
 * @property {object} onScroll - callback on keyboard navigation scrolling
 * @property {boolean} focusedItem - has id of current focused item
 * @property {AmoTableActionsProps} actionsProps - the property for the actions of the table
 * @property {boolean} isLoading - controls whether the table data is loading or not
 * @property {boolean} hideTableContent - controls whether the table is hidden
 * @property {boolean} showFilters - controls if the table filters should be visible or not
 * @property {object} mappedFieldKeys - Map field keys to different field for filtering
 * @property {object} mappedFilterMatchs - Map field keys to custom filter match for filtering
 * @property {Function} onFilteredDataChanged - function called when filtered data changes
 * @property {number} debouncingTime - debouncing time when filtering
 */
export const AmoTableWithFiltersProps = {
  ...sharedTableProps,
  mappedFieldKeys: PropTypes.objectOf(PropTypes.string),
  mappedFilterMatchs: PropTypes.objectOf(PropTypes.func),
  onFilteredDataChanged: PropTypes.func,
  debouncingTime: PropTypes.number,
};

export const AmoTableDefaultProps = {
  ...sharedTableDefaultProps,
  rawItems: [],
  disableFilters: false,
  shouldUseQueryString: false,
  onRequestSort: () => {},
  onFilterChange: () => {},
  columnFilters: {},
  route: "",
};

export const AmoTableWithFiltersDefaultProps = {
  ...sharedTableDefaultProps,
  mappedFieldKeys: {},
  mappedFilterMatchs: {},
  onFilteredDataChanged: () => {},
  debouncingTime: 0,
};
