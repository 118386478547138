import React from "react";
import PropTypes from "prop-types";
import { Redirect, useLocation } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";

/**
 * Function that returns the redirect path if it should be taken, or nothing otherwise
 *
 * @callback ConditionalRoute
 * @param {object} params dictionary of params returned from Route render props
 * @returns {string} route to redirect if condition matches, false value otehrwise
 */

/**
 * A conditional Redirect component
 *
 * @param {object} props
 * @param {object} props.renderProps props from render of <Route>
 * @param {ConditionalRoute[]} props.conditionalRoutes array of ConditionalRoute functions,
 * @returns {React.Component} The conditional Redirect
 */
const ConditionalRedirect = (props) => {
  const { renderProps, conditionalRoutes } = props;
  const params = renderProps?.match?.params;

  const { user } = useUserContext();
  const location = useLocation();

  if (!user.loaded) return "";
  if (!user.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  for (const conditionalRoute of conditionalRoutes) {
    const path = conditionalRoute(params);
    if (path) return <Redirect to={path} />;
  }
  return "";
};

ConditionalRedirect.propTypes = {
  renderProps: PropTypes.shape().isRequired,
  conditionalRoutes: PropTypes.arrayOf(PropTypes.func).isRequired,
};

ConditionalRedirect.defaultProps = {};

export default ConditionalRedirect;
