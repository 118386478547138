import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  submissionId: number,
 *  year: number,
 *  title: string,
 *  instructionText: string }} MunicipalQuestionnaire
 */

const api = new Api("api/municipalities");

export const questionnaireSubmissionService = {
  /**
   * @param municipalityId
   * @param year
   * @returns {Promise<AxiosResponse<MunicipalQuestionnaire>>}
   */
  getRiskQuestionnaire: (municipalityId, year) =>
    api.axios.get(`/${municipalityId}/questionnaires/${year}/risk`),
  postRiskQuestionnaire: (municipalityId, year, payload) =>
    api.axios.post(`/${municipalityId}/questionnaires/${year}/risk`, payload),
  updateRiskQuestionnaire: (municipalityId, year, id, payload) =>
    api.axios.put(
      `/${municipalityId}/questionnaires/${year}/risk/${id}`,
      payload
    ),
  getAssetQuestionnaire: (municipalityId, year) =>
    api.axios.get(`/${municipalityId}/questionnaires/${year}/asset`),
  postAssetQuestionnaire: (municipalityId, year, payload) =>
    api.axios.post(`/${municipalityId}/questionnaires/${year}/asset`, payload),
  updateAssetQuestionnaire: (municipalityId, year, id, payload) =>
    api.axios.put(
      `/${municipalityId}/questionnaires/${year}/asset/${id}`,
      payload
    ),
};
