import React from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";

import FinancialsPage from "pages/municipal/report/FinancialsPage";
import InsuranceReportingPage from "pages/municipal/insurance/InsuranceReportingPage";
import RiskManagementQuestionnairePage from "pages/municipal/municipalQuestionnaire/RiskManagementQuestionnairePage";
import AssetManagementQuestionnairePage from "pages/municipal/municipalQuestionnaire/AssetManagementQuestionnairePage";
import ProjectListPage from "pages/municipal/ProjectListPage";
import ProjectReportPage from "pages/municipal/report/ProjectReportPage";
import SubmitReportPage from "pages/municipal/report/SubmitReportPage";

/**
 * The municipal reporting root page.
 *
 * @returns {React.Component} The page component.
 */
const ReportingPage = () => (
  <Switch>
    <PrivateRoute
      path="/reporting/:year/projects/:projectId"
      redirectPath="/login"
    >
      <ProjectReportPage />
    </PrivateRoute>
    <PrivateRoute path="/reporting/:year/projects" redirectPath="/login">
      <ProjectListPage />
    </PrivateRoute>
    <PrivateRoute path="/reporting/:year/insurance" redirectPath="/login">
      <InsuranceReportingPage />
    </PrivateRoute>
    <PrivateRoute path="/reporting/:year/financials" redirectPath="/login">
      <FinancialsPage />
    </PrivateRoute>
    <PrivateRoute
      path="/reporting/:year/questionnaires/risk"
      redirectPath="/login"
    >
      <RiskManagementQuestionnairePage />
    </PrivateRoute>
    <PrivateRoute
      path="/reporting/:year/questionnaires/assets"
      redirectPath="/login"
    >
      <AssetManagementQuestionnairePage />
    </PrivateRoute>
    <PrivateRoute path="/reporting/:year/close" redirectPath="/login">
      <SubmitReportPage />
    </PrivateRoute>
  </Switch>
);

export default ReportingPage;
