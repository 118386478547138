import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  municipalityId: number,
 *  additionalInvestmentRequired: number,
 *  status: string,
 *  exemptionGranted: boolean,
 *  notes: string }} BankLimitMonitoringData
 */

/**
 * @typedef {{
 *  id: number,
 *  municipalityId: number,
 *  closingBalance5YearAgo: number,
 *  investmentSinceThen: number,
 *  outgoingTransfersSinceThen: number,
 *  additionalInvestmentRequired: number,
 *  status: string,
 *  exemptionGranted: boolean,
 *  notes: string }} BankLimitMonitoringModalData
 */

/**
 * @typedef {{
 *  id: number,
 *  municipalityId: number,
 *  exemptionGranted: boolean,
 *  notes: string }} BankLimitUpdateRequest
 */

const api = new Api("api/amo/banking-monitoring");
export const bankLimitMonitoringService = {
  /**
   * @param {number} year
   * @returns {Promise<AxiosResponse<BankLimitMonitoringData[]>>}
   */
  getByYear: (year) => api.getById(year),
  /**
   * @param {number} year
   * @param {number} municipalityId
   * @returns {Promise<AxiosResponse<BankLimitMonitoringModalData>>}
   */
  getByYearAndMunicipality: (year, municipalityId) =>
    api.getById(`${year}/${municipalityId}`),
  /**
   * @param {number} year
   * @param {number} municipalityId
   * @param {BankLimitUpdateRequest} payload
   * @returns {Promise<AxiosResponse<BankLimitMonitoringModalData>>}
   */
  putMunicipalityData: (year, municipalityId, payload) =>
    api.axios.put(`${year}/${municipalityId}`, payload),
};
