import { Api } from "../api";
import { AxiosResponse } from "axios";

const makeAPI = (municipalityId) =>
  new Api(`api/Municipalities/${municipalityId}/reports`);
export const pdfReportsService = {
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @returns {Promise<AxiosResponse<File>>}
   */
  getAnnualReportFinancials: (municipalityId, year) =>
    makeAPI(municipalityId).getFile(`financials/${year}`),
  /**
   * @param {number} municipalityId
   * @param {object} payload
   * @returns {Promise<AxiosResponse<File>>}
   */
  getSummaryQuestionnaires: (municipalityId, payload) =>
    makeAPI(municipalityId).getFile("summary/questionnaires", payload),
  /**
   * @param {number} municipalityId
   * @param {object} payload
   * @returns {Promise<AxiosResponse<File>>}
   */
  getSummaryFinancials: (municipalityId, payload) =>
    makeAPI(municipalityId).getFile("summary/financials", payload),
  /**
   * @param {number} municipalityId
   * @param {object} payload
   * @returns {Promise<AxiosResponse<File>>}
   */
  getSummaryProjectResults: (municipalityId, payload) =>
    makeAPI(municipalityId).getFile("summary/project/results", payload),
  /**
   * @param {number} municipalityId
   * @param {object} payload
   * @returns {Promise<AxiosResponse<File>>}
   */
  getSummaryProjects: (municipalityId, payload) =>
    makeAPI(municipalityId).getFile("summary/projects", payload),
};
