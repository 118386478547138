import { useQuery } from "react-query";

import { insuranceReviewQueryKeys } from "./insuranceReviewQueryKeys";
import { reviewService } from "api/services/reviewsService";

/**
 * Store server's state of insurance results
 *
 * @param {number} year
 * @param {number} disabled
 * @returns The query object returned by the useQuery hook
 */
export const useInsuranceReviews = (year, disabled) =>
  useQuery(
    insuranceReviewQueryKeys.insuranceReviews(year),
    () => reviewService.getInsuranceCertificates(year),
    {
      select: ({ data }) =>
        data.map((item, index) => {
          let statusTextValue = "";
          if (item.submitted && !item.statusId) statusTextValue = "Select One";
          else if (item.statusId === 1) statusTextValue = "Approved";
          else if (item.statusId === 2) statusTextValue = "Not Approved";

          return {
            id: item.id,
            download: item.id,
            municipality: item.name,
            submitted: item.submitted,
            coverageStart: item.coverageStart,
            coverageEnd: item.coverageEnd,
            notes: item.notes,
            status: item.statusId ? item.statusId : 0,
            statusTextValue,
            fileId: item.fileId,
          };
        }),
      enabled: !!year && !disabled,
    }
  );

/**
 * Store server's state of an insurance certificate result
 *
 * @param {number} municipalityId
 * @param {number} year
 * @param {number} submitted
 * @returns The query object returned by the useQuery hook
 */
export const useInsuranceReviewByMunicipality = (
  municipalityId,
  year,
  submitted
) =>
  useQuery(
    insuranceReviewQueryKeys.insuranceReviewByMunicipality(
      municipalityId,
      year
    ),
    () =>
      reviewService.getInsuranceCertificateByMunicipalityId(
        municipalityId,
        year
      ),
    {
      select: ({ data }) => ({
        id: data.id,
        coverageStarts: data.coverageStart,
        coverageEnds: data.coverageEnd,
        notes: data.notes,
        status: data.status,
        dateReceived: data.received,
        file: data.file,
        year: data.year,
      }),
      enabled: false,
    }
  );
