import React from "react";
import { useAuth } from "oidc-react";
import clsx from "clsx";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQueryClient } from "react-query";

import colors from "constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    width: "auto",
    padding: "2rem 5rem",
    overflow: "hidden",
    scrollbarWidth: "none",
    scrollbarColor: "transparent",
    msOverflowStyle: "none",
    maxHeight: "94vh",
  },
  h1: {
    fontSize: "2.5rem",
  },
  darkGreen: {
    color: colors.green.dark,
  },
}));

/**
 * The Logout Page.
 *
 * @returns {React.Component} Logout Page component.
 */
const Logout = () => {
  const auth = useAuth();
  const classes = useStyles();
  const queryClient = useQueryClient();
  queryClient.removeQueries();
  // removeQueries does not alert when it's done, so wait 0.2 seconds to finish
  setTimeout(() => auth.signOutRedirect(), "200");

  return (
    <Box className={classes.root}>
      <Grid
        container
        className={classes.gridContainer}
        direction="column"
        wrap="nowrap"
      >
        <Grid item>
          <Grid container direction="column" wrap="nowrap" alignItems="center">
            <Grid item>
              <Typography
                variant="h1"
                className={clsx(classes.h1, classes.darkGreen)}
              >
                Logging out
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                className={classes.darkGreen}
                align="center"
              >
                Please wait a moment...
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Logout;
