import { useQuery } from "react-query";
import { useMunicipalContext } from "contexts/MunicipalContext";

import { bankLimitMonitoringQueryKeys } from "./bankLimitMonitoringQueryKeys";
import { bankLimitMonitoringService } from "api/services/bankLimitMonitoringService";
import { oneDayInMs } from "constants/caching";

/**
 * Store server's state of bank limit monitoring results
 *
 * @param {number} year
 * @returns The query object returned by the useQuery hook
 */
export const useBankLimitMonitoringHooks = (year) => {
  const { municipalities } = useMunicipalContext();

  return useQuery(
    bankLimitMonitoringQueryKeys.bankLimitMonitoring(year),
    () => bankLimitMonitoringService.getByYear(year),
    {
      select: ({ data }) =>
        data?.map((item) => ({
          ...item,
          municipalityName: municipalities[item?.municipalityId]?.name,
        })),
      enabled: !!year,
      cacheTime: oneDayInMs,
    }
  );
};

/**
 * Store server's state of bank limit monitoring modal result
 *
 * @param {number} year
 * @param {number} municipalityId
 * @returns The query object returned by the useQuery hook
 */
export const useBankLimitMonitoringModalHooks = (year, municipalityId) => {
  const { municipalities } = useMunicipalContext();

  return useQuery(
    bankLimitMonitoringQueryKeys.bankLimitMonitoringModal(year, municipalityId),
    () =>
      bankLimitMonitoringService.getByYearAndMunicipality(year, municipalityId),
    {
      select: ({ data }) => ({
        ...(data ?? {}),
        municipalityName: municipalities[data?.municipalityId]?.name,
        notes: data?.notes ?? "",
      }),
      enabled: !!year || !!municipalityId,
    }
  );
};
