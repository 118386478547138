import React from "react";
import { Box, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../../../constants/colors";
import AmoPopupMenuItem from "../../AmoPopupMenuItem";
import { routes } from "constants/routes";
import { projectInterfaceIcons } from "constants/projectInterfaceIcons";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: colors.grey.light,
  },
  menuItem: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
  },
}));

/**
 * A mobile menu component with items for communications users
 *
 * @param {object} props
 * @returns {React.Component} The menu component
 */
const MobileCommMenu = (props) => {
  const classes = useStyles();
  const { onItemClick } = props;

  return (
    <>
      <Box data-testid="headerCommMenu">
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.dashboard}
            iconName={projectInterfaceIcons.DASHBOARD}
            text="Dashboard"
            testId="headerCommunicationsMenuDashboard"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.communications.projects.list}
            iconName={projectInterfaceIcons.PROJECT_LIST}
            text="Project List"
            testId="headerCommunicationsMenuProj"
            onClick={onItemClick}
          />
        </List>
      </Box>
    </>
  );
};

// set the prop-types for this component
MobileCommMenu.propTypes = {
  onItemClick: PropTypes.func,
};

MobileCommMenu.defaultProps = {
  onItemClick: () => {},
};

export default MobileCommMenu;
