import { useEffect, useRef } from "react";

/**
 * This hook detects component unmount.
 * State changes, such as after async requests, should not happen on unmount.
 *
 * @returns {object}
 * Contains a ref indicating whether the component is mounted.
 */
export const useIsMounted = () => {
  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
};
