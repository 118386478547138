import React, { useState, useRef } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ListPageWrapper from "components/ListPageWrapper";
import { snackbarTypes } from "constants/snackbar";
import { useSnackbar } from "contexts/SnackbarContext";
import FilterActionsBar from "../FilterActionsBar";
import { useSummaryStyles } from "../hooks/summaryStylesHook";
import { useYearsRangeWithProjectCount } from "../hooks/yearsRangeHooks";
// Used for JSDocs
// eslint-disable-next-line import/named, no-unused-vars
import { SummaryPayload } from "api/services/summaryService";
import { useUserContext } from "contexts/UserContext";
import { useProjectsData } from "./projectsHooks";
import { exportToExcel } from "utils/export";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import { useYearContext } from "contexts/YearContext";
import { range } from "lodash";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { parseBreakLines } from "utils/htmlUtils";
import { projectCategoriesWithColon } from "constants/amoTableDropdownOptions";
import { getSortingComparator, stableSort } from "utils/data";
import { booleanToTextOrUndefined } from "utils/string";
import { printFile } from "utils/pdf";
import { errorMessages } from "constants/errorMessages";
import { pdfReportsService } from "api/services/pdfReportService";

const useStyles = makeStyles(() => ({
  linkContainer: {
    padding: "0 0",
  },
}));

const ProjectsSummaryPage = () => {
  const classes = useStyles();
  const { contextYear } = useYearContext();
  const { user } = useUserContext();
  const summaryClasses = useSummaryStyles();
  const { showSnackbar } = useSnackbar();
  const globalClasses = useGlobalStyles();

  const mounted = useIsMounted();

  const [payload, setPayload] = useState(/** @type {SummaryPayload} */ ({}));
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const { data: projects, isFetching, isFetched } = useProjectsData(payload);
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});

  const { data: lookupData } = useYearsRangeWithProjectCount("project");

  const newColumns =
    payload.yearEnd < 2023 && !payload.includeProjectsWithNoDates
      ? []
      : [
          {
            fieldKey: "provincialFunds",
            label: "Provincial funds?",
            minWidth: "12rem",
            verticalAlign: "top",
            displayType: displayTypes.boolean,
            customExport: ({ provincialFunds }) =>
              booleanToTextOrUndefined(provincialFunds) ?? "",
          },
          {
            fieldKey: "otherFederalFunds",
            label: "Other federal funds?",
            minWidth: "13rem",
            verticalAlign: "top",
            displayType: displayTypes.boolean,
            customExport: ({ otherFederalFunds }) =>
              booleanToTextOrUndefined(otherFederalFunds) ?? "",
          },
          {
            fieldKey: "stackingLimits",
            label: "Stacking limits on federal funding exceeded?",
            minWidth: "26rem",
            verticalAlign: "top",
            displayType: displayTypes.boolean,
            customExport: ({ stackingLimits }) =>
              booleanToTextOrUndefined(stackingLimits) ?? "",
          },
        ];

  const getTableColumns = () => [
    {
      fieldKey: "amoId",
      label: "AMO ID",
      minWidth: "9.375rem",
      verticalAlign: "top",
    },
    {
      fieldKey: "internalId",
      label: "Internal ID",
      minWidth: "9.375rem",
      verticalAlign: "top",
    },
    {
      fieldKey: "title",
      label: "Title",
      minWidth: "15.625rem",
      verticalAlign: "top",
      customRender: ({ amoId, title }) => (
        <Link
          to={"/reporting/:year/projects/:projectId"
            .replace(":projectId", amoId)
            .replace(":year", contextYear)}
          ref={(element) => {
            rowRefs.current[amoId] = element;
          }}
          onFocus={() => setFocusedItem({ amoId })}
          className={clsx(globalClasses.tableActionCell, classes.linkContainer)}
        >
          {title ?? "Untitled project"}
        </Link>
      ),
      isCustom: true,
    },
    {
      fieldKey: "description",
      label: "Description",
      minWidth: "37.5rem",
      verticalAlign: "top",
      customRender: ({ description }) => parseBreakLines(description),
      isCustom: true,
    },
    {
      fieldKey: "categoryName",
      label: "Category",
      minWidth: "18.75rem",
      verticalAlign: "top",
      dropdownOptions: projectCategoriesWithColon,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "startDate",
      label: "Start",
      minWidth: "9.375rem",
      verticalAlign: "top",
    },
    {
      fieldKey: "constructionEndDate",
      label: "End of Construction",
      minWidth: "15.625rem",
      verticalAlign: "top",
    },
    {
      fieldKey: "financingEndDate",
      label: "End of Financing",
      minWidth: "15.625rem",
      verticalAlign: "top",
    },
    {
      fieldKey: "totalCost",
      label: "Project Cost",
      minWidth: "11rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "projectCumulativeFunding",
      label: "Cumulative CCBF Funding",
      minWidth: "18.75rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "totalBudgeted",
      label: "Budgeted CCBF Funding",
      minWidth: "16.825rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    },
    ...range(payload.yearStart, payload.yearEnd + 1).map((year) => ({
      fieldKey: `funding${year}`,
      label: `${year ?? ""} Funding`,
      minWidth: "15.625rem",
      verticalAlign: "top",
      displayType: displayTypes.currency,
    })),
    ...newColumns,
  ];

  const filterActionComponent = (
    <FilterActionsBar
      years={lookupData?.years ?? []}
      onFilter={({ from, to, includeProjectsWithNoDates }) =>
        setPayload({
          municipalityId: user.municipalityId,
          yearStart: from,
          yearEnd: to,
          includeProjectsWithNoDates,
        })
      }
      disableOptionalButtons={!isFetched || !projects?.length || isLoadingFile}
      showPrint={false}
      showDownload={false}
      noDatesProjectCount={lookupData?.noDatesProjectCount}
      hideDividers
    />
  );

  const getPdfFile = async () => {
    try {
      const { data } = await pdfReportsService.getSummaryProjects(
        user.municipalityId,
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
    return null;
  };

  const handlePrint = async () => {
    setIsLoadingFile(true);
    setIsLoadingPrint(true);

    const pdfFile = await getPdfFile();

    if (!mounted.current) {
      return;
    }

    if (pdfFile) {
      printFile(pdfFile);
    }

    setIsLoadingFile(false);
    setIsLoadingPrint(false);
  };

  const tableActions = {
    titleComponent: filterActionComponent,
    actions: [
      tableDefaultActions.search,
      tableDefaultActions.new,
      tableDefaultActions.export,
    ],
    actionsCustomConfigs: {
      [tableDefaultActions.search]: {
        disabled: !isFetched,
      },
      [tableDefaultActions.new]: {
        label: "Print",
        onClick: () => {
          handlePrint();
        },
        disabled: !isFetched || isLoadingPrint,
      },
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            let exportTitle = `Projects Summary - ${payload.yearStart}`;
            if (payload.yearStart !== payload.yearEnd && payload.yearEnd) {
              exportTitle = `${exportTitle} to ${payload.yearEnd}`;
            }
            // Sort data by AMO ID in descending order
            const sortedProjects = stableSort(
              projects,
              getSortingComparator("desc", "amoId")
            );
            exportToExcel(sortedProjects, getTableColumns(), exportTitle, {
              sheetName: "Projects",
              specialColumnSizes: {
                description: 40,
                internalId: 12,
                amoId: 15,
                title: 25,
              },
            });
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
        disabled: !isFetched,
      },
    },
    showDividers: true,
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography className={summaryClasses.title} variant="h2">
            Projects
          </Typography>
        </Grid>
        {isFetched && !projects?.length && (
          <Grid item>
            <Typography>
              No results found for the selected reporting period
            </Typography>
          </Grid>
        )}
        <Grid item>
          <AmoTableWithFilters
            items={projects}
            columns={getTableColumns()}
            defaultOrderBy="amoId"
            defaultOrder="desc"
            onScroll={(item) => {
              rowRefs.current?.[item.amoId]?.focus();
            }}
            focusedItem={focusedItem}
            actionsProps={tableActions}
            isLoading={isFetching}
            uniqueKeyGenerationFields={["amoId"]}
            hideTableContent={!projects?.length}
          />
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default ProjectsSummaryPage;
