import { DateTime } from "luxon";

// this file contains utility functions that are used in the yup definition
// they are specific to yup and are not used elsewhere

// this method returns a JS Date object based on the passed originalValue
// the value parameter is used by yup when transforming the value
const parseDateString = (value, originalValue) => {
  if (DateTime.isDateTime(originalValue) && originalValue.isValid)
    return originalValue.toJSDate();
  if (!originalValue) return null;
  return originalValue;
};

export { parseDateString };
