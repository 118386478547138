import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, Link } from "react-router-dom";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import { useMunicipalUserHooks } from "./hooks/userDataHooks";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useUserContext } from "contexts/UserContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { roles } from "constants/user";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { snackbarTypes } from "constants/snackbar";
import { exportToExcel } from "utils/export";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { errorMessages } from "constants/errorMessages";

/**
 * A table component for displaying Municipal users
 *
 * @param {object} props
 * @returns {React.Component} The table component
 */
const MunicipalUserTable = (props) => {
  const { id, tableTitleComponent } = props;

  const history = useHistory();
  const location = useLocation();
  const { user } = useUserContext();
  const globalClasses = useGlobalStyles();
  const { showSnackbar } = useSnackbar();
  const { getMunicipalitiesNames } = useMunicipalContext();

  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const hasAmoRole = user.roles.includes(roles.amo);

  const { data: userData, isFetching: dataFetching } = useMunicipalUserHooks();

  const tableColumns = [
    {
      fieldKey: "name",
      label: "Name",
      minWidth: "11rem",
      customRender: (item) => (
        <Link
          to={`/users/amo/${item.id}${location.search}`}
          ref={(element) => {
            rowRefs.current[item.id] = element;
          }}
          onFocus={() => setFocusedItem({ id: item.id })}
          className={globalClasses.tableActionCell}
        >
          {item.name}
        </Link>
      ),
      isCustom: true,
    },
    hasAmoRole
      ? {
          fieldKey: "municipality",
          label: "Municipality",
          minWidth: "11rem",
          dropdownOptions: getMunicipalitiesNames(),
          displayType: displayTypes.dropdown,
        }
      : null,
    { fieldKey: "email", label: "Email", minWidth: "10rem" },
    { fieldKey: "phone", label: "Phone #", minWidth: "8rem" },
    { fieldKey: "permissions", label: "Permissions", minWidth: "11rem" },
  ].filter(Boolean);

  const handleItemClick = (currentUser) => {
    history.push(`/users/municipal/${currentUser.id}${location.search}`);
  };

  const tableActions = {
    titleComponent: tableTitleComponent,
    actions: [
      tableDefaultActions.new,
      tableDefaultActions.search,
      tableDefaultActions.export,
    ],
    actionsCustomConfigs: {
      [tableDefaultActions.new]: {
        onClick: () => {
          history.push(`/users/municipal/new${location.search}`);
        },
      },
      [tableDefaultActions.export]: {
        onClick: () => {
          try {
            exportToExcel(
              filteredData.current,
              tableColumns,
              "Other Municipal Staff"
            );
          } catch (error) {
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
        },
      },
    },
  };

  return (
    <AmoTableWithFilters
      id={id}
      items={dataFetching ? [] : userData}
      columns={tableColumns}
      defaultOrderBy="name"
      enableItemClick
      onItemClick={handleItemClick}
      onFilteredDataChanged={(value) => {
        filteredData.current = value;
      }}
      onScroll={(item) => {
        rowRefs.current?.[item.id]?.focus();
      }}
      focusedItem={focusedItem}
      actionsProps={tableActions}
      isLoading={dataFetching}
    />
  );
};

// set the prop-types for this component
MunicipalUserTable.propTypes = {
  id: PropTypes.string,
  tableTitleComponent: PropTypes.node,
};

MunicipalUserTable.defaultProps = {
  id: "municipal-user-table",
  tableTitleComponent: null,
};

export default MunicipalUserTable;
