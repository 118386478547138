import { useQuery } from "react-query";
import { insuranceService } from "api/services/insuranceService";
import { useYearContext } from "contexts/YearContext";
import { removeTime } from "utils/date";

// Query Keys
import { insuranceReportKeys } from "./insuranceReportQueryKeys";

/**
 * Store server's state of all insurance reports
 *
 * @param {number} municipalityId The id of the project being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useInsuranceList = (municipalityId) => {
  const { contextYear } = useYearContext();

  const selectInsuranceReports = ({ data }) => {
    if (data.findIndex((item) => item.year === contextYear) < 0) {
      const newRecord = {
        id: -1,
        year: contextYear,
        notes: "",
        coverageStart: "",
        coverageEnd: "",
        received: "",
        status: "Not submitted",
      };
      data.push(newRecord);
    }
    return (
      data?.map((item) => ({
        ...item,
        coverageStart: removeTime(item.coverageStart),
        coverageEnd: removeTime(item.coverageEnd),
        received: removeTime(item.received),
      })) ?? []
    );
  };

  return useQuery(
    insuranceReportKeys.list(municipalityId),
    () => insuranceService.getAll(municipalityId),
    {
      select: selectInsuranceReports,
    }
  );
};
/**
 * Store server's state of all insurance reports
 *
 * @param {number} municipalityId The id of the project being fetched
 * @param {number} year The id of the project being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useInsuranceReport = (municipalityId, year) =>
  useQuery(
    insuranceReportKeys.report(municipalityId, year),
    () => insuranceService.get(municipalityId, year),
    {
      select: ({ data }) => data,
    }
  );
