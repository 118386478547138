import React from "react";
import { useParams } from "react-router-dom";

const Sample = () => {
  const { name } = useParams();

  return (
    <>
      <div>
        <h2>{name.toUpperCase()}</h2>
      </div>
    </>
  );
};

export default Sample;
