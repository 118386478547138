import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider, MenuItem, Button } from "@material-ui/core";

import clsx from "clsx";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import AmoTextField from "components/inputs/AmoTextField";
import AmoPageHeader from "components/AmoPageHeader";
import AmoFormActions from "components/AmoFormActions";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import AmoDatePicker from "components/inputs/AmoDatePicker";

import colors from "constants/colors";
import { routes } from "constants/routes";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { localRoadsCategoryId } from "constants/projectReport";
import { sortComparator } from "constants/indicatorConstants";

import ManageGroupingsModal from "./ManageGroupingsModal";
import { indicatorService } from "api/services/indicatorService";
import { useIndicatorLookups, useIndicator } from "./indicatorManagementHooks";
import { useWrapApi } from "hooks/wrapApiHook";
import { useIsMounted } from "hooks/useIsMounted";
import { stableSort } from "utils/data";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    overflow: "hidden auto",
  },
  body: {
    // 0px 352px 0px 352px
    padding: "0rem 22rem 0rem 22rem",
  },
  bodyText: {
    marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        // 16px
        paddingBottom: "1rem",
      },
      "& > .MuiGrid-container > .MuiGrid-item:last-child": {
        // 48px
        paddingLeft: "3rem",
      },
    },
  },
  bodyCheckbox: {
    // marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 8px
      paddingBottom: "0.5rem",
      "&:first-child > .MuiGrid-container > .MuiGrid-item:last-child": {
        // 48px
        paddingLeft: "3rem",
      },
    },
  },
  inputWidth: {
    width: "100%",
  },
  subtitle: {
    color: colors.black,
    fontFamily: "Roboto",
    marginTop: "0.5rem",
  },
  checkboxLabel: {
    // 8px
    marginRight: "0rem",
  },
  modal: {
    width: "18.75rem",
  },
  manageGroupingsLine: {
    paddingBottom: "0.25rem",
  },
  manageGroupings: {
    color: colors.green.main,
    textDecoration: "none",
    justifyContent: "flex-end",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
}));

/**
 * This page component functions as both the "new" and "edit" page for indicators.
 *
 * @returns {Function} The indicator edit page component
 */
const IndicatorEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { outputOutcomeId: indicatorIdFromParams } = useParams();
  const history = useHistory();
  const mounted = useIsMounted();

  // modal properties
  const [showModal, setShowModal] = useState(false);

  const indicatorIsNew = indicatorIdFromParams === "new";
  const customUnitId = 22;

  const [disableSubcategory, setDisableSubcategory] = useState(true);
  const [disableGrouping, setDisableGrouping] = useState(true);
  const [typeOfIndicator, setTypeOfIndicator] = useState("");
  const [disableStandardUnit, setDisableStandardUnit] = useState(true);
  const [disableCustomUnitName, setDisableCustomUnitName] = useState(true);
  const [groupingData, setGroupingData] = useState([]);
  const [
    disableBeforeAfterIndicator,
    setDisableBeforeAfterIndicator,
  ] = useState(true);
  const [disableCalculationType, setDisableCalculationType] = useState(true);
  const [standardUnits, setStandardUnits] = useState([]);
  const [formWasReseted, setFormWasReseted] = useState(false);
  const unitTypeIsLoaded = useRef(false);

  const postData = useWrapApi(
    indicatorService.addIndicator,
    "Successfully saved indicator information"
  );

  const putData = useWrapApi(
    indicatorService.updateIndicator,
    "Successfully updated indicator information"
  );

  // Validation helper for use within yup schema test
  const validateCellLocator = async (cellLocator) => {
    if (!cellLocator) return true;
    const { data } = await indicatorService.validateCellLocator(
      cellLocator,
      !indicatorIsNew ? indicatorIdFromParams : undefined
    );
    return data;
  };

  // define schema for form validation
  const indicatorSchema = yup
    .object(
      {
        category: yup.string().required("Category is required"),
        subcategory: yup
          .string()
          .nullable()
          .default("")
          .when("category", {
            is: (categoryValue) =>
              categoryValue === localRoadsCategoryId.toString(),
            then: yup.string().required("Subcategory is required"),
          }),
        outputOutcome: yup.string().required("Output/outcome is required"),
        grouping: yup.string().when("outputOutcome", {
          is: "0",
          then: yup.string().required("grouping is required"),
        }),
        indicatorTypeId: yup.number().required("Type of indicator is required"),
        descriptionLabel: yup.string().required("Description is required"),
        tooltipText: yup.string().nullable(),
        cellLocator: yup
          .string()
          .required("Cell Locator is required")
          .test(
            "cell-locator-exists-validation",
            "Cell Locator already exists.",
            validateCellLocator
          ),
        validationOnEntry: yup.string().nullable(),
        unitType: yup.string().when("indicatorTypeId", {
          is: 1,
          then: yup.string().required("Unit type is required"),
        }),
        standardUnit: yup.string().when("indicatorTypeId", {
          is: 1,
          then: yup.string().required("Standard unit is required"),
        }),
        customUnitName: yup.string().when(["indicatorTypeId", "standardUnit"], {
          is: (indicatorTypeId, standardUnit) =>
            indicatorTypeId === 1 && standardUnit === customUnitId.toString(),
          then: yup.string().required("Custom unit name is required"),
        }),
        beforeAfterIndicator: yup.boolean(),
        calculationType: yup.string().when("beforeAfterIndicator", {
          is: true,
          then: yup.string().required("Calculation type is required"),
        }),
        listOfValues: yup.string().when("indicatorTypeId", {
          is: 3,
          then: yup.string().required("List of values is required"),
        }),
        effectiveFrom: yup
          .date()
          .typeError("Effective From must be a valid date")
          .nullable()
          .required("Effective from is required"),
        effectiveThrough: yup.lazy((value) => {
          if (value !== "") {
            return yup
              .date()
              .typeError("Effective Through must be a valid date")
              .nullable()
              .notRequired()
              .min(
                yup.ref("effectiveFrom"),
                "Effective Through must be later than Effective From"
              );
          }
          return yup.mixed().notRequired();
        }),
        sortSequence: yup
          .number()
          .typeError("Sort sequence must be a number")
          .required("Sort sequence is required"),
      },
      [
        // Add Cyclic deps here because when require itself
        ["effectiveThrough", "effectiveThrough"],
      ]
    )
    .required();

  const defaultValues = {
    category: "",
    subcategory: "",
    outputOutcome: "",
    grouping: "",
    indicatorTypeId: "",
    descriptionLabel: "",
    tooltipText: "",
    cellLocator: "",
    validationOnEntry: "",
    unitType: "",
    standardUnit: "",
    customUnitName: "",
    beforeAfterIndicator: false,
    calculationType: "",
    listOfValues: "",
    effectiveFrom: null,
    effectiveThrough: null,
    sortSequence: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState,
    setError,
    trigger,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possible onBlur) if very important for validation
    mode: "onChange",
    resolver: yupResolver(indicatorSchema),
  });

  /* 
  /  WATCH LIST
  /  Watches named fields for updates, used to trigger effects when we need to
  /  Caution: useWatch is optimized for renders, not for effects, so it may trigger effects multiple times
  /  This is suboptimal; the [watch] and [useWatch] docs hint at a better way, performance-wise
  /  https://react-hook-form.com/api/usewatch
  /  TODO: create a custom hook that's optimized for effect hooks
  */
  const [
    categoryWatch,
    outputOutcomeWatch,
    indicatorTypeIdWatch,
    unitTypeWatch,
    standardUnitWatch,
    beforeAfterIndicatorWatch,
  ] = useWatch({
    control,
    name: [
      "category",
      "outputOutcome",
      "indicatorTypeId",
      "unitType",
      "standardUnit",
      "beforeAfterIndicator",
    ],
  });

  const {
    data: dropdownData,
    isLoading: dropdownDataIsLoading,
  } = useIndicatorLookups();

  const { data: indicatorData, isLoading: indicatorIsLoading } = useIndicator(
    indicatorIdFromParams
  );

  // the below will update the form with fetched data
  useEffect(() => {
    setGroupingData(dropdownData?.groupings ?? []);
  }, [dropdownData]);

  useEffect(() => {
    if (indicatorData && dropdownData) {
      // Reset the form using the fetched value
      reset(indicatorData);
      setFormWasReseted(true);
    }
  }, [indicatorData, dropdownData]);

  useEffect(() => {
    if (formWasReseted) {
      setFormWasReseted(false);
      trigger();
    }
  }, [formWasReseted]);

  // the effects below will control form data changes
  useEffect(() => {
    if (dropdownDataIsLoading || !categoryWatch) return;

    const selectedCategory = dropdownData?.categories?.find(
      (item) => item.id === categoryWatch
    );
    const showSubcategory = selectedCategory?.id === localRoadsCategoryId;
    if (!showSubcategory) {
      setValue("subcategory", "");
    }
    setDisableSubcategory(!showSubcategory);
  }, [categoryWatch, dropdownDataIsLoading]);

  useEffect(() => {
    setDisableGrouping(outputOutcomeWatch !== 0);
    setDisableBeforeAfterIndicator(outputOutcomeWatch !== 1);
  }, [outputOutcomeWatch]);

  useEffect(() => {
    if (dropdownDataIsLoading) return;

    const currentType = dropdownData?.indicatorValueTypes?.find(
      (item) => item.id === indicatorTypeIdWatch
    );
    setTypeOfIndicator(currentType?.name);
  }, [indicatorTypeIdWatch, dropdownDataIsLoading]);

  useEffect(() => {
    if (!unitTypeWatch || dropdownDataIsLoading) return;

    const selectedUnitType = dropdownData?.indicatorUnitTypes?.find(
      (item) => item.id === unitTypeWatch
    );
    if (unitTypeIsLoaded.current) {
      setValue("standardUnit", "");
    }
    if (unitTypeWatch) unitTypeIsLoaded.current = true;
    const filteredStandardUnits = dropdownData?.indicatorUnitTypes?.filter(
      (unitType) =>
        unitType.indicatorUnitCategoryTypeId === selectedUnitType?.id
    );
    setStandardUnits(stableSort(filteredStandardUnits, sortComparator));
    setDisableStandardUnit(selectedUnitType === undefined);
  }, [unitTypeWatch, dropdownDataIsLoading]);

  useEffect(() => {
    setDisableCustomUnitName(standardUnitWatch !== customUnitId);
    // If it's not custom standard unit, trigger validation to clear custom unit validations
    if (standardUnitWatch !== customUnitId) {
      trigger("customUnitName");
    }
  }, [standardUnitWatch]);

  useEffect(() => {
    setDisableCalculationType(!beforeAfterIndicatorWatch);
  }, [beforeAfterIndicatorWatch]);

  const handleSaveClick = async ({
    category,
    subcategory,
    outputOutcome,
    grouping,
    indicatorTypeId,
    descriptionLabel,
    tooltipId,
    tooltipText,
    cellLocator,
    validationOnEntry,
    unitType,
    standardUnit,
    customUnitName,
    beforeAfterIndicator,
    calculationType,
    listOfValues,
    effectiveFrom,
    effectiveThrough,
    sortSequence,
  }) => {
    let response = {};

    const payload = {
      categoryId: category,
      indicatorType: outputOutcome === "1",
      valueTypeId: indicatorTypeId,
      description: descriptionLabel,
      cellLocator,
      effectiveFrom,
      sortOrder: Number(sortSequence),
    };

    if (subcategory) {
      payload.subcategoryId = subcategory;
    }
    if (grouping) {
      payload.indicatorGroupingId = grouping;
    }
    if (validationOnEntry) {
      payload.indicatorValidationTypeId = validationOnEntry;
    }
    if (unitType) {
      payload.indicatorUnitTypeId = unitType;
    }
    if (standardUnit && standardUnit !== "Custom" && standardUnit !== "None") {
      payload.standardIndicatorUnitTypeId = standardUnit;
    }
    if (customUnitName) {
      payload.customUnitName = customUnitName;
    }
    if (beforeAfterIndicator) {
      payload.isBeforeAfter = beforeAfterIndicator;
    }
    if (calculationType) {
      payload.indicatorValueCalculationId = calculationType;
    }
    if (listOfValues) {
      payload.listOfValues = listOfValues;
    }
    if (effectiveThrough) {
      payload.effectiveTo = effectiveThrough;
    }
    if (tooltipId) {
      payload.tooltipId = tooltipId;
    }
    if (tooltipText) {
      payload.tooltipText = tooltipText;
    }

    if (indicatorIsNew) {
      response = await postData.call(payload);
    } else {
      response = await putData.call(indicatorIdFromParams, payload);
    }

    if (!mounted.current) {
      return;
    }

    if (!response.error) {
      history.push(routes.contentManagement.outputsOutcomes.list);
    }
  };

  const navigateBack = () => {
    history.goBack();
  };

  const { isValid, isSubmitting, isDirty } = formState;

  const formActions = [
    {
      disabled: !isDirty || isSubmitting || !isValid,
      testId: "indicatorEditSaveButton",
      label: "Save",
      onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
    },
    {
      testId: "indicatorEditCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: navigateBack,
    },
  ];

  if (indicatorIsLoading || dropdownDataIsLoading) {
    return null;
  }

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title={`${indicatorIsNew ? "Create New" : "Edit"} Indicator`}
            backLinkText="Back to list"
            backLinkTo={routes.contentManagement.outputsOutcomes.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <form>
            <Grid
              container
              direction="column"
              spacing={0}
              className={clsx(
                globalClasses.editPageBody22,
                globalClasses.editPageBodyText
              )}
              wrap="nowrap"
            >
              <Grid item container spacing={6}>
                <Grid item xs={6}>
                  <AmoTextField
                    control={control}
                    label="Category"
                    id="indicator-category"
                    name="category"
                    select
                    required
                    testId="indicatorCategory"
                    variant="outlined"
                    className={classes.inputWidth}
                  >
                    <MenuItem key="indicatorCategoryNone" value="" disabled>
                      Category
                    </MenuItem>
                    {dropdownData.categories &&
                      Object.entries(
                        dropdownData.categories.filter(
                          (category) => !category.parentCategoryId
                        )
                      )
                        .sort(([aKey, aVal], [bKey, bVal]) =>
                          aVal.name.localeCompare(bVal.name)
                        )
                        .map(([key, value]) => (
                          <MenuItem
                            key={`indicatorCategory-${key}`}
                            value={value.id}
                          >
                            {value.name}
                          </MenuItem>
                        ))}
                  </AmoTextField>
                </Grid>
                <Grid item xs={6}>
                  {!disableSubcategory && (
                    <AmoTextField
                      control={control}
                      label="Subcategory"
                      id="indicator-subcategory"
                      name="subcategory"
                      testId="indicatorSubcategory"
                      className={classes.inputWidth}
                      variant="outlined"
                      disabled={disableSubcategory}
                      select
                      required
                    >
                      <MenuItem
                        key="indicatorSubcategoryNone"
                        value=""
                        disabled
                      >
                        Subcategory
                      </MenuItem>
                      {dropdownData.categories &&
                        Object.entries(
                          dropdownData.categories.filter(
                            (category) => category.parentCategoryId !== null
                          )
                        )
                          .sort(([aKey, aVal], [bKey, bVal]) =>
                            aVal.name.localeCompare(bVal.name)
                          )
                          .map(([key, value]) => (
                            <MenuItem
                              key={`indicatorCategory-${key}`}
                              value={value.id}
                            >
                              {value.name}
                            </MenuItem>
                          ))}
                    </AmoTextField>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={6}
                className={clsx(classes.manageGroupingsLine)}
              >
                <Grid item xs={6}>
                  <AmoTextField
                    control={control}
                    label="Output or Outcome"
                    id="indicator-output-outcome"
                    name="outputOutcome"
                    select
                    required
                    testId="indicatorOutputOutcome"
                    variant="outlined"
                    className={classes.inputWidth}
                  >
                    <MenuItem
                      key="indicatorSubcategoryNone"
                      value=""
                      disabled
                    />
                    <MenuItem key="Output" value={0}>
                      Output
                    </MenuItem>
                    <MenuItem key="Outcome" value={1}>
                      Outcome
                    </MenuItem>
                  </AmoTextField>
                </Grid>
                <Grid item xs={6}>
                  <AmoTextField
                    control={control}
                    label="Grouping"
                    id="indicator-grouping"
                    name="grouping"
                    select
                    required={!disableGrouping}
                    disabled={disableGrouping}
                    testId="indicatorGrouping"
                    variant="outlined"
                    className={classes.inputWidth}
                  >
                    <MenuItem key="indicatorGroupingNone" value="" disabled>
                      Grouping
                    </MenuItem>
                    {groupingData &&
                      Object.entries(groupingData)
                        .sort(([aKey, aVal], [bKey, bVal]) =>
                          aVal.name.localeCompare(bVal.name)
                        )
                        .map(([key, value]) => (
                          <MenuItem
                            key={`indicatorOutputOutcome-${value.id}`}
                            value={value.id}
                          >
                            {value.name}
                          </MenuItem>
                        ))}
                  </AmoTextField>
                  <Button
                    className={classes.manageGroupings}
                    align="right"
                    onClick={() => setShowModal(true)}
                  >
                    <Typography align="right" variant="body1">
                      Manage Groupings
                    </Typography>
                  </Button>
                </Grid>
              </Grid>

              <Grid item container spacing={6}>
                <Grid item xs={6}>
                  <AmoTextField
                    control={control}
                    id="indicator-type"
                    name="indicatorTypeId"
                    testId="indicatorTypeId"
                    className={classes.inputWidth}
                    select
                    label="Type of indicator"
                    variant="outlined"
                    required
                  >
                    <MenuItem key="indicatorTypeIdNone" value="" disabled>
                      Type of indicator
                    </MenuItem>
                    {dropdownData.indicatorValueTypes &&
                      Object.entries(dropdownData.indicatorValueTypes)
                        .sort(([aKey, aVal], [bKey, bVal]) =>
                          aVal.name.localeCompare(bVal.name)
                        )
                        .map(([key, value]) => (
                          <MenuItem
                            key={`indicatorTypeId-${value.id}`}
                            value={value.id}
                          >
                            {value.name}
                          </MenuItem>
                        ))}
                  </AmoTextField>
                </Grid>
              </Grid>

              <Grid item container spacing={0}>
                <Grid item xs>
                  <AmoTextField
                    control={control}
                    id="indicator-description-label"
                    name="descriptionLabel"
                    testId="indicatorDescriptionLabel"
                    className={classes.inputWidth}
                    label="Description/Label"
                    required
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={0}>
                <Grid item xs>
                  <AmoTextField
                    multiline
                    rows={1}
                    rowsMax={10}
                    control={control}
                    id="indicator-tooltip-text"
                    name="tooltipText"
                    testId="indicatorTooltipText"
                    className={classes.inputWidth}
                    label="Tooltip Text"
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={0}>
                <Grid item xs>
                  <AmoTextField
                    control={control}
                    id="indicator-cell-locator"
                    name="cellLocator"
                    testId="indicatorCellLocator"
                    className={classes.inputWidth}
                    label="Cell Locator"
                    required
                    onChangeTrigger={() => {
                      setError("cellLocator", "Validating...");
                    }}
                  />
                </Grid>
              </Grid>

              {typeOfIndicator === "Numeric" && (
                <>
                  <Divider style={{ marginBottom: "2rem" }} />

                  <Grid item container spacing={6}>
                    <Grid item xs={6}>
                      <AmoTextField
                        control={control}
                        id="indicator-validation-on-entry"
                        name="validationOnEntry"
                        testId="indicatorValidationOnEntry"
                        className={classes.inputWidth}
                        select
                        label="Validation on Entry"
                        variant="outlined"
                      >
                        <MenuItem
                          key="indicatorValidationOnEntryNone"
                          value=""
                          disabled
                        >
                          Validation on Entry
                        </MenuItem>
                        {dropdownData.indicatorValidationTypes &&
                          Object.entries(dropdownData.indicatorValidationTypes)
                            .sort(([aKey, aVal], [bKey, bVal]) =>
                              aVal.name.localeCompare(bVal.name)
                            )
                            .map(([key, value]) => (
                              <MenuItem
                                key={`indicatorValidationOnEntry-${key}`}
                                value={value.id}
                              >
                                {value.name}
                              </MenuItem>
                            ))}
                      </AmoTextField>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={6}>
                    <Grid item xs={6}>
                      <AmoTextField
                        control={control}
                        label="Unit type"
                        id="indicator-unit-type"
                        name="unitType"
                        select
                        required
                        testId="indicatorUnitType"
                        variant="outlined"
                        className={classes.inputWidth}
                      >
                        <MenuItem
                          key="indicatorUnitTypeNone"
                          value={0}
                          disabled
                        >
                          Unit type
                        </MenuItem>
                        {dropdownData.indicatorUnitCategoryTypes &&
                          dropdownData.indicatorUnitCategoryTypes.map(
                            (value) => (
                              <MenuItem
                                key={`indicatorUnitType-${value.name}`}
                                value={value.id}
                              >
                                {value.name}
                              </MenuItem>
                            )
                          )}
                      </AmoTextField>
                    </Grid>

                    <Grid item xs={6}>
                      <AmoTextField
                        control={control}
                        label="Standard unit"
                        id="indicator-standard-unit"
                        name="standardUnit"
                        select
                        required
                        disabled={disableStandardUnit}
                        testId="indicatorStandardUnit"
                        variant="outlined"
                        className={classes.inputWidth}
                      >
                        <MenuItem
                          key="indicatorStandardUnitNone"
                          value=""
                          disabled
                        >
                          Standard Unit
                        </MenuItem>
                        {standardUnits?.length > 0 &&
                          standardUnits.map((value) => (
                            <MenuItem
                              key={`indicatorStandardUnit-${value.id}`}
                              value={value.id}
                            >
                              {value.name}
                            </MenuItem>
                          ))}
                        <MenuItem key="none" value="None">
                          None
                        </MenuItem>
                      </AmoTextField>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={6}>
                    <Grid item xs={6}>
                      <AmoTextField
                        control={control}
                        id="indicator-custom-unit-name"
                        name="customUnitName"
                        testId="indicatorCustomUnitName"
                        className={classes.inputWidth}
                        label="Custom unit name"
                        disabled={disableCustomUnitName}
                        required={!disableCustomUnitName}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={6}>
                    <Grid item xs={6}>
                      <AmoCheckbox
                        color="primary"
                        control={control}
                        disabled={disableBeforeAfterIndicator}
                        id="indicator-before-after-question"
                        name="beforeAfterIndicator"
                        testId="indicatorBeforeAfterIndicator"
                        label="Before/After Question"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AmoTextField
                        control={control}
                        label="Calculation Type"
                        id="indicator-calculation-type"
                        name="calculationType"
                        select
                        required={!disableCalculationType}
                        disabled={disableCalculationType}
                        testId="indicatorCalculationType"
                        variant="outlined"
                        className={classes.inputWidth}
                      >
                        <MenuItem
                          key="indicatorCalculationType"
                          value=""
                          disabled
                        >
                          Calculation Type
                        </MenuItem>
                        {dropdownData.indicatorValueCalculations &&
                          Object.entries(
                            dropdownData.indicatorValueCalculations
                          ).map(([key, value]) => (
                            <MenuItem
                              key={`indicatorValueCalculations-${key}`}
                              value={value.id}
                            >
                              {value.name}
                            </MenuItem>
                          ))}
                      </AmoTextField>
                    </Grid>
                  </Grid>
                </>
              )}

              {typeOfIndicator === "List" && (
                <>
                  <Divider style={{ marginBottom: "2rem" }} />

                  <Grid item container spacing={0}>
                    <Grid item xs>
                      <AmoTextField
                        control={control}
                        id="indicator-list-of-values"
                        name="listOfValues"
                        testId="indicatorListOfValues"
                        className={classes.inputWidth}
                        label="List of values (line-separated)"
                        multiline
                        rows={8}
                        rowsMax={12}
                        required
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Divider style={{ marginBottom: "2rem" }} />

              <Grid item container spacing={6}>
                <Grid item xs={6}>
                  <AmoDatePicker
                    control={control}
                    label="Effective from"
                    id="indicator-effective-from"
                    name="effectiveFrom"
                    required
                    testId="indicatorEffectiveFrom"
                    variant="inline"
                    className={classes.inputWidth}
                    format="yyyy-MM-dd"
                  />
                </Grid>
                <Grid item xs={6}>
                  <AmoDatePicker
                    control={control}
                    label="Effective through (optional)"
                    id="indicator-effective-through"
                    name="effectiveThrough"
                    testId="indicatorEffectiveThrough"
                    variant="inline"
                    className={classes.inputWidth}
                    format="yyyy-MM-dd"
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={6}>
                <Grid item xs={6}>
                  <AmoTextField
                    control={control}
                    id="indicator-sort-sequence"
                    name="sortSequence"
                    testId="indicatorSortSequence"
                    className={classes.inputWidth}
                    label="Sort Sequence"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>

        {/* Actions */}
        <AmoFormActions actions={formActions} hideLateralBorders />
      </Grid>
      {showModal && (
        <ManageGroupingsModal
          variant="default"
          showModal={showModal}
          closeModalFunction={() => setShowModal(false)}
          title="Groupings"
          text="Texty text"
          showButton
          buttonText="Save and Close"
          groupings={groupingData}
          setGroupingData={setGroupingData}
        />
      )}
    </>
  );
};

export default IndicatorEditPage;
