import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Divider, Tabs, Tab } from "@material-ui/core";
import AmoUserTable from "components/amo/AmoUserTable";
import TresurerUserTable from "components/amo/TreasurerUserTable";
import MunicipalUserTable from "components/amo/MunicipalUserTable";
import CommunicationUserTable from "components/amo/CommunicationUserTable";
import colors from "constants/colors";
import { roles } from "constants/user";
import { useUserContext } from "contexts/UserContext";
import ListPageWrapper from "components/ListPageWrapper";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.black,
  },
  tab: {
    marginLeft: "1rem",
    "& .MuiTab-root": {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textTransform: "none",
      padding: "0rem",
      marginRight: "3rem",
      maxWidth: "none",
      minWidth: "0rem",
      "&.Mui-selected": {
        color: colors.black,
      },
    },
  },
}));

/**
 * User List Page.
 *
 * @returns {React.Component} User List Page component.
 */
const UserListPage = () => {
  const classes = useStyles();
  const { user } = useUserContext();

  const AmoTabValue = 0;
  const TreasurerTabValue = 1;
  const MunicipalTabValue = 2;
  const CommunicationsTabValue = 3;

  const tablesIds = {
    [AmoTabValue]: "amo-user-table",
    [TreasurerTabValue]: "treasurer-user-table",
    [MunicipalTabValue]: "municipal-user-table",
    [CommunicationsTabValue]: "communication-user-table",
  };

  const hasAmoRole = user.roles.includes(roles.amo);

  const [selectedTab, setSelectedTab] = useConditionalQueryParams(
    "selectedTab",
    "number",
    hasAmoRole ? AmoTabValue : MunicipalTabValue,
    true
  );

  const handleTabChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabsComponent = (
    <Tabs
      className={classes.tab}
      value={selectedTab}
      onChange={handleTabChange}
      aria-label="simple tabs example"
      textColor="primary"
      indicatorColor="primary"
    >
      <Tab
        id="user-mngt-tab-amo"
        label="AMO"
        aria-controls="user-mngt-tabpanel-amo"
        tabIndex={0}
        value={AmoTabValue}
      />
      <Tab
        id="user-mngt-tab-treasurers"
        label="Treasurers"
        aria-controls="user-mngt-tabpanel-treasurers"
        tabIndex={0}
        value={TreasurerTabValue}
      />
      <Tab
        id="user-mngt-tab-municipal"
        label="Other Municipal Staff"
        aria-controls="user-mngt-tabpanel-municipal"
        tabIndex={0}
        value={MunicipalTabValue}
      />
      <Tab
        id="user-mngt-tab-communications"
        label="Communications Staff"
        aria-controls="user-mngt-tabpanel-communications"
        tabIndex={0}
        value={CommunicationsTabValue}
      />
    </Tabs>
  );

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography gutterBottom className={classes.title} variant="h1">
            User Management
          </Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          {((hasAmoRole && selectedTab === MunicipalTabValue) ||
            !hasAmoRole) && (
            <Box
              display={
                !hasAmoRole || selectedTab === MunicipalTabValue
                  ? "block"
                  : "none"
              }
            >
              <MunicipalUserTable
                id={tablesIds[MunicipalTabValue]}
                tableTitleComponent={hasAmoRole ? tabsComponent : null}
              />
            </Box>
          )}
          {hasAmoRole && selectedTab === AmoTabValue && (
            <Box display={selectedTab === AmoTabValue ? "block" : "none"}>
              <AmoUserTable
                id={tablesIds[AmoTabValue]}
                tableTitleComponent={tabsComponent}
              />
            </Box>
          )}
          {hasAmoRole && selectedTab === TreasurerTabValue && (
            <Box display={selectedTab === TreasurerTabValue ? "block" : "none"}>
              <TresurerUserTable
                id={tablesIds[TreasurerTabValue]}
                tableTitleComponent={tabsComponent}
              />
            </Box>
          )}
          {hasAmoRole && selectedTab === CommunicationsTabValue && (
            <Box
              display={
                selectedTab === CommunicationsTabValue ? "block" : "none"
              }
            >
              <CommunicationUserTable
                id={tablesIds[CommunicationsTabValue]}
                tableTitleComponent={tabsComponent}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default UserListPage;
