import React from "react";
import { Grid, Typography, Box, Button, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import AmoFlagInputPopover from "./AmoFlagInputPopover";
import AmoEditFieldPopover from "./AmoEditFieldPopover";
import colors from "constants/colors";
import reviewFieldWrapperTypes from "constants/reviewFieldWrapperTypes";
import FlagComponent from "./FlagComponent";
import { parseBreakLines } from "utils/htmlUtils";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    padding: theme.spacing(0.2),
  },
  label: {
    fontSize: "0.875rem",
  },
  previousValue: {
    color: colors.red.main,
    textDecoration: "line-through",
  },
  spacer: {
    marginLeft: "1.625rem",
  },
  downloadAll: {
    color: colors.green.main,
    textDecoration: "none",
    justifyContent: "flex-end",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  },
  downloadAllIcon: {
    marginRight: "0.5rem",
  },
}));

/**
 * A wrapper component to view municipal inputs and edit/flag them for AMO users
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the component [required]
 * @param {string} props.testId - data-testid of the component
 * @param {string} props.label - sets the displayed label of the field
 * @param {string} props.value - sets the displayed value of the field
 * @param {string} props.previousValue - sets the previously displayed value of the field
 * @param {boolean} props.iconsToRight - sets if save and flag icons should to right as opposed to left (default)
 * @param {object} props.editProps - object containing props of this edit popover configuration
 * @param {Node|Node[]} props.editProps.input - sets the children of the edit popover
 * @param {Function} props.editProps.onSave - function called when user clicks on the Save button on the edit popover (params: none)
 * @param {Function} props.editProps.onClose - function called when user clicks on the Close icon on the edit popover (params: none)
 * @param {boolean} props.editProps.isSaveEnable - disable or enable Save button of AmoEditFieldPopover
 * @param {object} props.flagProps - object containing props of this flag popover configuration
 * @param {number} props.flagProps.flagId - sets the flag id of the flag popover
 * @param {string} props.flagProps.ownerName - sets the owner name of the flag popover
 * @param {Date} props.flagProps.lastUpdate - sets the last update date of the flag popover
 * @param {string} props.flagProps.comment - sets the comment of the flag popover
 * @param {Function} props.flagProps.onSave - function called when user clicks on the Save button on the flag popover (params: { value })
 * @param {Function} props.flagProps.onRemove - function called when user clicks on the Remove button on the flag popover (params: none)
 * @param {boolean} props.hideEdit - sets if edit button should appear
 * @param {boolean} props.hideFlag - sets if flag button should appear
 * @param {boolean} props.hideLabel - sets if the label should appear
 * @param {object} props.renderComponent - component to render instead of just text
 * @param {string} props.wrapperType - sets the wrapper type
 * @param {Function} props.downloadAllFunction - function called when user clicks on the download all button
 * @param {object} props.warning - object containing props of this warning configuration
 * @param {object} props.warning.type - type of warning to display
 * @param {object} props.warning.tooltipText - text to show on hover
 *
 * @returns - The wrapper component
 */
const FormViewWrapper = (props) => {
  const {
    id,
    testId,
    label,
    value,
    previousValue,
    iconsToRight,
    hideEdit,
    hideFlag,
    hideLabel,
    editProps,
    flagProps,
    renderComponent,
    wrapperType,
    downloadAllFunction,
    warning,
  } = props;

  const classes = useStyles();

  const renderValue = () => {
    if (renderComponent) return renderComponent;

    return (
      <>
        {previousValue && (
          <Grid item>
            <Typography variant="body1" className={classes.previousValue}>
              {parseBreakLines(previousValue)}
            </Typography>
          </Grid>
        )}
        <Grid item>
          {wrapperType !== reviewFieldWrapperTypes.locationPicker || !value ? (
            <Typography variant={previousValue ? "body2" : "body1"}>
              {parseBreakLines(value) || "--"}
            </Typography>
          ) : (
            <Typography
              align="left"
              variant="body1"
              className={classes.downloadAll}
              onClick={() => openLocation(value)}
            >
              {parseBreakLines(value)}
            </Typography>
          )}
        </Grid>
      </>
    );
  };

  const openLocation = (coordinates) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${coordinates.replace(
        /\s/g,
        ""
      )}`
    );
    // Log the coordinates being used to open google maps
    // eslint-disable-next-line no-console
    console.log({ coordinates });
  };

  return (
    <>
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        direction={iconsToRight ? "row-reverse" : "row"}
      >
        <Grid item>
          <Grid container spacing={0}>
            {hideEdit ? (
              <Box className={classes.spacer} />
            ) : (
              <Grid item>
                <AmoEditFieldPopover
                  id={`${id}-edit-popover`}
                  testId={testId ? `${testId}EditPopover` : undefined}
                  onSave={editProps.onSave}
                  onClose={editProps.onClose}
                  isSaveEnable={editProps.isSaveEnable}
                >
                  {editProps.input}
                </AmoEditFieldPopover>
              </Grid>
            )}

            {hideFlag ? (
              <Box className={classes.spacer} />
            ) : (
              <Grid item>
                <AmoFlagInputPopover
                  id={`${id}-flag-popover`}
                  testId={testId ? `${testId}FlagPopover` : undefined}
                  flagId={flagProps.flagId}
                  ownerName={flagProps.ownerName}
                  lastUpdate={flagProps.lastUpdate}
                  comment={flagProps.comment}
                  onSave={flagProps.onSave}
                  onRemove={flagProps.onRemove}
                  fieldLabel={label}
                  fieldValue={value}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs>
          <Grid
            data-testid={testId}
            container
            direction="column"
            alignItems="flex-start"
            spacing={0}
          >
            <Grid item xs={12} container>
              {!hideLabel && (
                <Grid item xs>
                  <Typography variant="body2" className={classes.label}>
                    {label}
                    {warning?.type && (
                      <FlagComponent
                        type={warning.type}
                        message={warning.tooltipText}
                      />
                    )}
                  </Typography>
                </Grid>
              )}
              {wrapperType === reviewFieldWrapperTypes.fileUpload && (
                <Grid item>
                  <Button
                    data-testid={testId}
                    className={classes.downloadAll}
                    align="right"
                    onClick={() => downloadAllFunction()}
                  >
                    <Icon
                      className={clsx(
                        "material-icons-outlined",
                        classes.downloadAllIcon
                      )}
                      color="primary"
                    >
                      file_download
                    </Icon>
                    <Typography align="right" variant="body1">
                      Download all
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
            {renderValue()}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// set the prop-types for this component
FormViewWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  previousValue: PropTypes.string,
  iconsToRight: PropTypes.bool,
  editProps: PropTypes.shape({
    input: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    onSave: AmoEditFieldPopover.propTypes.onSave,
    onClose: AmoEditFieldPopover.propTypes.onClose,
    isSaveEnable: AmoEditFieldPopover.propTypes.isSaveEnable,
  }),
  flagProps: PropTypes.shape({
    flagId: AmoFlagInputPopover.propTypes.flagId,
    ownerName: AmoFlagInputPopover.propTypes.ownerName,
    lastUpdate: AmoFlagInputPopover.propTypes.lastUpdate,
    comment: AmoFlagInputPopover.propTypes.comment,
    onSave: AmoFlagInputPopover.propTypes.onSave,
    onRemove: AmoFlagInputPopover.propTypes.onRemove,
  }),
  hideEdit: PropTypes.bool,
  hideFlag: PropTypes.bool,
  hideLabel: PropTypes.bool,
  renderComponent: PropTypes.shape(),
  wrapperType: PropTypes.string,
  downloadAllFunction: PropTypes.func,
  warning: PropTypes.shape({
    type: PropTypes.string,
    tooltipText: PropTypes.string,
  }),
};

FormViewWrapper.defaultProps = {
  testId: null,
  label: undefined,
  value: undefined,
  previousValue: undefined,
  iconsToRight: false,
  editProps: {},
  flagProps: {},
  hideEdit: false,
  hideFlag: false,
  hideLabel: false,
  renderComponent: null,
  wrapperType: reviewFieldWrapperTypes.text,
  downloadAllFunction: () => {},
  warning: {},
};

export default FormViewWrapper;
