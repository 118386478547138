import { Api } from "../api";
import { AxiosResponse } from "axios";
// Used for JSDocs
// eslint-disable-next-line import/named, no-unused-vars
import { NotificationPlaceholder } from "./notificationService";

/**
 * @typedef {{
 *  id: number,
 *  municipalityId: number,
 *  municipalityName: string,
 *  statusId: number,
 *  statusName: string,
 *  assignedTo: string,
 *  openingBalance: number,
 *  closingBalance: number,
 *  allocation: number,
 *  interestEarned: number,
 *  disposals: number,
 *  netTransfers: number,
 *  netExpenditures: number,
 *  administrationCosts: number }} FinancialInformation
 */

/**
 * @typedef {{
 *  flagId: number,
 *  ownerName: string,
 *  lastUpdate: string,
 *  comment: string
 * }} FlagProps
 */

/**
 * @typedef {{
 *  previousValue: number,
 *  currentYearValue: number,
 *  cumulativeValue: number,
 *  flagProps: FlagProps }} FinancialReviewDetails
 */

/**
 * @typedef {{
 *  currentYear: number,
 *  flagProps: FlagProps }} FinancialReviewFlaggableDetails
 */

/**
 * @typedef {{
 *  currentYear: number,
 *  cumulativeTotal: number }} FinancialReviewTransaction
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string }} FinancialReviewFile
 */

/**
 * @typedef {{
 *  broadbandConnectivity: FinancialReviewTransaction,
 *  brownfield: FinancialReviewTransaction,
 *  capacityBldg: FinancialReviewTransaction,
 *  commEnergy: FinancialReviewTransaction,
 *  culture: FinancialReviewTransaction,
 *  disaster: FinancialReviewTransaction,
 *  fireStation: FinancialReviewTransaction,
 *  roadsBridges: FinancialReviewTransaction,
 *  airport: FinancialReviewTransaction,
 *  recreation: FinancialReviewTransaction,
 *  shortline: FinancialReviewTransaction,
 *  shortsea: FinancialReviewTransaction,
 *  solidWaste: FinancialReviewTransaction,
 *  sport: FinancialReviewTransaction,
 *  transit: FinancialReviewTransaction,
 *  tourism: FinancialReviewTransaction,
 *  wastewater: FinancialReviewTransaction,
 *  water: FinancialReviewTransaction }} FinancialReviewExpenditures
 */

/**
 * @typedef {{
 *  id: number,
 *  reviewId: number,
 *  reportingYear: number,
 *  municipalityId: number,
 *  municipalityName: string,
 *  statusId: number,
 *  statusName: string,
 *  assigneeId: number,
 *  assigneeName: string,
 *  openingBalance: FinancialReviewFlaggableDetails,
 *  closingBalance: FinancialReviewFlaggableDetails,
 *  receivedFromAmo: FinancialReviewTransaction,
 *  proceedsAssetDisposal: FinancialReviewDetails,
 *  interestEarned: FinancialReviewDetails,
 *  amountsReceived: FinancialReviewDetails,
 *  amountsTransfered: FinancialReviewDetails,
 *  administrationCosts: FinancialReviewTransaction,
 *  expenditures: FinancialReviewExpenditures,
 *  file: FinancialReviewFile }} FinancialReviewData
 */

/**
 * @typedef {{
 *  projectId: number,
 *  objectId: number,
 *  fieldCode: string,
 *  value: string }} Field
 */

/**
 * @typedef {{
 *  municipalityId: number,
 *  reportingYear: number,
 *  fieldCode: string,
 *  value: string }} FinancialsField
 */

/**
 * @typedef {{
 *  id: number,
 *  projectId: number,
 *  fieldCode: string,
 *  flagText: string }} Flag
 */

/**
 * @typedef {{
 *  reportingStatusId: number,
 *  municipalityId: number,
 *  municipalityName: string,
 *  reportSubmitted: boolean,
 *  financialsApproved: boolean,
 *  projectsApproved: boolean,
 *  certificateApproved: boolean,
 *  selectedForAudit: boolean,
 *  notes: string}} ReportingStatusReview
 */

/**
 * @typedef {{
 *  id: number,
 *  actionTypeId: number,
 *  actionDescription: string,
 *  actionDate: string,
 *  actionBy: string,
 *  isAmoUser: boolean,
 *  actionValue: string }} HistoryItem
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  selected: boolean }} Assignee
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  actionable: boolean,
 *  selected: boolean }} Status
 */

/**
 * @typedef {{
 *  assignees: Assignee[],
 *  statuses: Status[],
 *  published: boolean }} DataInputLookups
 */

/**
 * @typedef {{
 *  reviewId: number,
 *  assigneeId: number,
 *  statusId: number,
 *  comment: string,
 *  isPublished: boolean,
 *  trackChange: boolean }} DataInputItem
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  submitted: boolean,
 *  coverageStart: string,
 *  coverageEnd: string,
 *  notes: string,
 *  actionValue: string }} InsuranceCertificate
 */

/**
 * @typedef {{
 *  municipalityId: number,
 *  municipalityName: string,
 *  baseAmount: number,
 *  annualTarget: number,
 *  averageAnnualInvestment: number,
 *  statusId: number,
 *  status: string,
 *  ammendmentMade: boolean }} IncrementalityMonitoringReview
 */

/**
 * @typedef {{
 * 	municipalityId: number,
 * 	municipalityName: string,
 * 	status: string,
 * 	treasurer: any,
 * 	contactName: string,
 * 	contactEmail: string,
 * 	contactPhone: string,
 * 	institutionName: string,
 * 	institutionNameOld: string,
 * 	institutionNumber: string,
 * 	institutionNumberOld: any,
 * 	transitNumber: string,
 * 	transitNumberOld: any,
 * 	accountNumber: string,
 * 	accountNumberOld: any,
 *  updated: boolean,
 *  updatedDate: string,
 *  isExportedAfterUpdate: boolean}} BankingInformationReview
 */

const api = new Api("api/amo/review");
export const reviewService = {
  /**
   * @param {number} year
   * @returns {Promise<AxiosResponse<FinancialInformation[]>>}
   */
  getByYear: (year) => api.getById(`financials/${year}`),
  /**
   * @param {number} year
   * @param {string} type
   * @returns {Promise<AxiosResponse<FinancialInformation[]>>}
   */
  getQuestionnaires: (year, type) =>
    api.getById(`questionnaires/${year}/${type}`),
  /**
   * @returns {Promise<AxiosResponse<number[]>>}
   */
  getYears: () => api.getById(`financialYears`),
  /**
   * @param {number} municipalityId
   * @param {number} year
   * @returns {Promise<AxiosResponse<FinancialReviewData>>}
   */
  getFinancialsReview: (municipalityId, year) =>
    api.getById(`financials/${municipalityId}/${year}`),
  /**
   *
   * @param {number} projectId
   * @param {string} fieldCode
   * @param {Field} payload
   * @returns {Promise<AxiosResponse<number>>} Id of affected flag
   */
  putField: (projectId, fieldCode, payload) =>
    api.axios.put(`projects/${projectId}/fields/${fieldCode}`, {
      ...payload,
      projectId: +payload.projectId,
      value: `${payload.value}`,
    }),
  /**
   *
   * @param {number} projectId
   * @param {Flag} payload
   * @returns {Promise<AxiosResponse<number>>} flag id
   */
  postFlag: (projectId, payload) =>
    api.axios.post(`${projectId}/flags/projects`, payload),
  /**
   *
   * @param {number} projectId
   * @param {number} flagId
   * @param {Flag} payload
   * @returns {Promise<AxiosResponse>} Empty response
   */
  putFlag: (projectId, flagId, payload) =>
    api.axios.put(`${projectId}/flags/projects/${flagId}`, {
      id: flagId,
      ...payload,
    }),
  /**
   *
   * @param {number} projectId
   * @param {number} flagId
   * @returns {Promise<AxiosResponse>} Empty response
   */
  deleteFlag: (projectId, flagId) =>
    api.axios.delete(`${projectId}/flags/projects/${flagId}`),
  getReportingStatusModalData: (year, municipalityId) =>
    api.getById(`reporting-status/${year}/${municipalityId}`),
  saveReportingStatusModalData: (year, municipalityId, payload) =>
    api.axios.put(`reporting-status/${year}/${municipalityId}`, payload),
  getReportingStatusReviews: (year) => api.getById(`reporting-status/${year}`),
  /**
   *
   * @param {number} municipalityId
   * @param {number} year
   * @param {string} fieldCode
   * @param {FinancialsField} payload
   * @returns {Promise<AxiosResponse<number>>} Id of affected field
   */
  putFinancialsField: (municipalityId, year, fieldCode, payload) =>
    api.axios.put(`financials/${municipalityId}/${year}/${fieldCode}`, payload),
  /**
   *
   * @param {number} objectId
   * @param {Flag} payload
   * @returns {Promise<AxiosResponse<number>>} flag id
   */
  postFlagFinancials: (objectId, payload) =>
    api.axios.post(`${objectId}/flags/financials`, payload),
  /**
   *
   * @param {number} objectId
   * @param {number} flagId
   * @param {Flag} payload
   * @returns {Promise<AxiosResponse>} Empty response
   */
  putFlagFinancials: (objectId, flagId, payload) =>
    api.axios.put(`${objectId}/flags/financials/${flagId}`, {
      id: flagId,
      ...payload,
    }),
  /**
   *
   * @param {number} objectId
   * @param {number} flagId
   * @returns {Promise<AxiosResponse>} Empty response
   */
  deleteFlagFinancials: (objectId, flagId) =>
    api.axios.delete(`${objectId}/flags/financials/${flagId}`),

  /**
   *
   * @param {number} reviewId
   * @returns {Promise<AxiosResponse<HistoryItem[]>>}
   */
  getHistoryLogs: (reviewId) => api.getById(`${reviewId}/history`),
  /**
   *
   * @param {number} reviewId
   * @returns {Promise<AxiosResponse<DataInputLookups>>}
   */
  getDataInputLookups: (reviewId) => api.getById(`${reviewId}/lookups`),
  /**
   *
   * @param {number} reviewId
   * @param {DataInputItem} payload
   * @returns {Promise<AxiosResponse>} Empty response
   */
  putDataInput: (reviewId, payload) => api.putWithoutId(payload, `${reviewId}`),

  /**
   * @param {number} year
   * @returns {Promise<AxiosResponse<InsuranceCertificate[]>>}
   */
  getInsuranceCertificates: (year) => api.axios.get(`/insurance/${year}`),

  /**
   * @param {InsuranceCertificate} payload
   * @returns {Promise<AxiosResponse>} Empty response
   */
  postInsuranceCertificate: (payload) => api.axios.post("/insurance", payload),

  /**
   * @param {InsuranceCertificate} payload
   * @returns {Promise<AxiosResponse>} Empty response
   */
  updateInsuranceCertificate: (payload) => api.axios.put("/insurance", payload),

  /**
   *
   * @param {number} municipalityId
   * @param {number} year
   * @returns {Promise<AxiosResponse<InsuranceCertificate>>} Empty response
   */
  getInsuranceCertificateByMunicipalityId: (municipalityId, year) =>
    api.axios.get(`/municipalities/${municipalityId}/insurance/${year}`),

  /**
   * @returns {Promise<AxiosResponse<IncrementalityMonitoringReview[]>>}
   */
  getIncrementalityMonitoringReviews: () =>
    api.getById("incrementality-monitoring"),
  /**
   * @returns {Promise<AxiosResponse<BankingInformationReview[]>>}
   */
  getBankingInformationReviews: () => api.getById("banking-information"),

  /**
   * @returns {Promise<AxiosResponse<NotificationPlaceholder[]>>}
   */
  getBankingInformationPlaceholders: () =>
    api.getById("banking-information-verification-placeholders"),
  /**
   *
   * @param {number} id
   * @returns {Promise<AxiosResponse<BankingInformationReview[]>>}
   */
  unlock: (id) => api.axios.put(`banking-information/unlock/${id}`),
  /**
   *
   * @param {number} id
   * @returns {Promise<AxiosResponse<BankingInformationReview[]>>}
   */
  denyUnlock: (id) => api.axios.put(`banking-information/deny-unlock/${id}`),
  /**
   *
   * @returns {Promise<AxiosResponse<Assignee[]>>}
   */
  getAssignees: () => api.getById("assignees"),
};
