import { useQuery } from "react-query";
import { projectService } from "api/services/projectService";
import { formatToCurrency } from "utils/number";

// Query Keys
import { projectKeys } from "./projectQueryKeys";

/**
 * Store server's state of all project funds
 *
 * @param {string} projectId The id of the project being fetched
 * @param {boolean} isProjectReview Whether the funds are for project review
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectFunds = (projectId, isProjectReview) => {
  const selectProjectFunds = (data) => {
    const projectFunds = data?.data;
    const mappedFunds = projectFunds?.funds?.map((item) => ({
      year: item.year,
      amount: formatToCurrency(item.amount),
      rawAmount: item.amount,
    }));
    return {
      ...data,
      data: {
        total: projectFunds?.total ?? 0,
        funds: mappedFunds,
      },
    };
  };

  return useQuery(
    projectKeys.projectFunds(projectId),
    () => projectService.getFunds(projectId),
    {
      select: selectProjectFunds,
      enabled: !isProjectReview,
    }
  );
};

/**
 * Store server's state of project financials form
 *
 * @param {string} projectId The id of the project being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectFinancialsData = (projectId) => {
  const selectProjectFinancialsData = (data) => {
    const projectFinancialsData = data?.data;
    const reducedFlags =
      projectFinancialsData?.flags?.reduce(
        (map, obj) => ({
          ...map,
          [obj.fieldCode]: {
            id: obj.id,
            ownerName: obj.updatedByName,
            ownerEmail: obj.updatedByEmail,
            comment: obj.message,
          },
        }),
        {}
      ) || {};
    return {
      ...data,
      data: {
        ...projectFinancialsData,
        ccbfFunding: projectFinancialsData.ccbfFunding?.toString() ?? "",
        totalCcbfBudgeted:
          projectFinancialsData.totalCcbfBudgeted?.toString() ?? "",
        totalProjectCost:
          projectFinancialsData.totalProjectCost?.toString() ?? "",
        additionalComments: projectFinancialsData?.additionalComments ?? "",
        flags: reducedFlags,
      },
    };
  };

  return useQuery(
    projectKeys.projectFinancials(projectId),
    () => projectService.getFinancialsData(projectId),
    {
      select: selectProjectFinancialsData,
      enabled: !!projectId && projectId !== "new",
    }
  );
};
