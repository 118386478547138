import React, { useState, useRef } from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";

import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import ListPageWrapper from "components/ListPageWrapper";
import { exportToExcel } from "utils/export";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import {
  useBankingInformationPlaceholdersHooks,
  useBankingInformationReviewsHooks,
} from "./bankingInformationReviewsHooks";
import BankingRequestVerificationModal from "components/amo/BankingRequestVerificationModal";
import { useWrapApi } from "hooks/wrapApiHook";
import { reviewService } from "api/services/reviewsService";
import BankingInformationDetailsModal from "components/amo/BankingInformationDetailsModal";
import BankingUnlockOrDenyModal from "components/amo/BankingUnlockOrDenyModal";
import { formIds } from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { bankInformationStatuses } from "constants/amoTableDropdownOptions";
import { errorMessages } from "constants/errorMessages";
import { bankingInformationService } from "api/services/bankingInformationService";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "20.563rem",
    height: "3.125rem",
  },
  listButton: {
    width: "11.125rem",
    height: "3.125rem",
  },
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  lightGreen: {
    color: "#00B628",
  },
}));

/**
 * The Banking Information Review List Page.
 *
 * @returns {React.Component} Banking Information Review List Page component.
 */
const BankingInformationReviewsListPage = () => {
  const { formFields } = useFormManagement(formIds.bankingInformation);

  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const globalClasses = useGlobalStyles();
  const { getMunicipalitiesNames } = useMunicipalContext();

  const [showModal, setShowModal] = useState(false);
  const [showUnlockOrDenyModal, setShowUnlockOrDenyModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentBankingInformation, setCurrentBankingInformation] = useState(
    false
  );
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});

  const openModal = (municipalityId) => {
    setShowDetailsModal(true);
    setCurrentBankingInformation(
      bankingInformationReviews.filter(
        (item) => item.municipalityId === municipalityId
      )[0]
    );
    setFocusedItem(null);
  };

  const openShowUnlockOrDenyModal = (municipalityId) => {
    setShowUnlockOrDenyModal(true);
    setCurrentBankingInformation(
      bankingInformationReviews.filter(
        (item) => item.municipalityId === municipalityId
      )[0]
    );
    setFocusedItem(null);
  };

  const unlockBankingInfo = useWrapApi(
    reviewService.unlock,
    "Successfully unlocked"
  );

  const denyUnlockBankingInfo = useWrapApi(
    reviewService.denyUnlock,
    "Successfully deny unlock"
  );

  const unlock = async (id) => {
    if (id) {
      await unlockBankingInfo.call(id);
      bankingInformationReviewsRefetch();
    }
  };

  const denyUnlock = async (id) => {
    if (id) {
      await denyUnlockBankingInfo.call(id);
      bankingInformationReviewsRefetch();
    }
  };

  const tableColumns = [
    {
      fieldKey: "municipalityName",
      label: "Municipality",
      widthPerc: 45,
      customRender: ({ municipalityId, municipalityName }) => (
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              color="primary"
              onClick={() => openModal(municipalityId)}
              className={globalClasses.tableActionCell}
              ref={(element) => {
                rowRefs.current[municipalityId] = element;
              }}
              onFocus={() => setFocusedItem({ municipalityId })}
              disableRipple
            >
              {municipalityName}
            </Button>
          </Grid>
        </Grid>
      ),
      isCustom: true,
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "status",
      label: "Status",
      widthPerc: 35,
      customRender: ({ status }) => (
        <Typography className={status === "Verified" ? classes.lightGreen : ""}>
          {status}
        </Typography>
      ),
      isCustom: true,
      dropdownOptions: bankInformationStatuses,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "actions",
      hideLabel: true,
      hideSearch: true,
      customRender: ({ id, status, municipalityId }) =>
        status === "Unlock Requested" ? (
          <Button
            aria-label="tableActionSearch"
            onClick={() => openShowUnlockOrDenyModal(municipalityId)}
            color="primary"
            size="medium"
            variant="outlined"
            className={classes.listButton}
            disabled={showUnlockOrDenyModal}
          >
            Unlock or Deny
          </Button>
        ) : (
          <Button
            aria-label="tableActionSearch"
            onClick={() => unlock(id)}
            color="primary"
            size="medium"
            variant="outlined"
            className={classes.listButton}
            disabled={status === "Unverified" || !id}
          >
            Unlock
          </Button>
        ),
      widthPerc: 15,
      isCustom: true,
    },
  ];

  const {
    data: bankingInformationReviews,
    isFetching: bankingInformationReviewsFetching,
    refetch: bankingInformationReviewsRefetch,
  } = useBankingInformationReviewsHooks();

  const {
    data: bankingInformationPlaceholders,
  } = useBankingInformationPlaceholdersHooks();

  const { call: putExportedBankingInformation } = useWrapApi(
    bankingInformationService.putExportedBankingInformation
  );

  const [ellipsisAnchorEl, setEllipsisAnchorEl] = useState(null);
  const ellipsisOnClick = (event) => setEllipsisAnchorEl(event.currentTarget);
  const ellipsisOnClose = () => setEllipsisAnchorEl(null);

  const exportData = async (updatedOnly = false) => {
    try {
      const data = updatedOnly
        ? bankingInformationReviews.filter(({ updated }) => updated)
        : bankingInformationReviews;
      const columns = [
        ...tableColumns.filter(({ fieldKey }) => fieldKey !== "actions"),
        { fieldKey: "treasurerName", label: "Treasurer" },
        { fieldKey: "contactName", label: "Contact Name" },
        { fieldKey: "contactEmail", label: "Contact Email" },
        { fieldKey: "contactPhone", label: "Contact Phone" },
        { fieldKey: "phoneExtension", label: "Contact Phone Extension" },
        {
          fieldKey: "institutionName",
          label: "Institution Name",
        },
        {
          fieldKey: "institutionNumber",
          label: "Institution Number",
        },
        {
          fieldKey: "transitNumber",
          label: "Transit Number",
        },
        {
          fieldKey: "accountNumber",
          label: "Account Number",
        },
        {
          fieldKey: "updatedDate",
          label: "Updated Date",
        },
        {
          fieldKey: "isExported",
          label: "Exported after Update or Verification",
        },
      ];
      exportToExcel(data, columns, "Banking Information Reviews", {
        sheetName: "Reviews",
        addTimestamp: true,
        specialColumnSizes: { municipalityName: 30 },
      });
      const isExportedMarked = await putExportedBankingInformation();
      if (isExportedMarked.data.status === 200) {
        bankingInformationReviewsRefetch();
      }
    } catch (error) {
      showSnackbar(errorMessages.creatingSpreadsheet, snackbarTypes.error);
    }
  };

  const requestVerificationButton = (
    <Button
      aria-label="tableActionSearch"
      onClick={() => {
        setShowModal(true);
        setFocusedItem(null);
      }}
      color="primary"
      size="medium"
      variant="contained"
      className={classes.button}
      disabled={bankingInformationReviewsFetching}
    >
      Request Verification
    </Button>
  );

  const customExportButton = (
    <Button
      aria-label="tableActionSearch"
      color="primary"
      size="medium"
      variant="outlined"
      className={classes.button}
      onClick={ellipsisOnClick}
      disabled={bankingInformationReviewsFetching}
    >
      Export Banking Information
    </Button>
  );

  const tableActions = {
    actions: [
      "requestVerification",
      "customExport",
      tableDefaultActions.search,
    ],
    customActionsComponents: {
      requestVerification: requestVerificationButton,
      customExport: customExportButton,
    },
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="h1">Banking Information</Typography>
        </Grid>
        <Grid item className={classes.dividerContainer}>
          <Divider />
        </Grid>
        <Grid item>
          <Grid item>
            <AmoTableWithFilters
              items={bankingInformationReviews}
              columns={tableColumns}
              defaultOrderBy="municipalityName"
              onScroll={(item) => {
                rowRefs.current?.[item.municipalityId]?.focus();
              }}
              focusedItem={focusedItem}
              actionsProps={tableActions}
              isLoading={bankingInformationReviewsFetching}
              uniqueKeyGenerationFields={["municipalityId"]}
            />
          </Grid>
        </Grid>
      </Grid>
      <BankingRequestVerificationModal
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
          setFocusedItem({});
        }}
        bankingInformationReviews={bankingInformationReviews}
        bankingInformationReviewsRefetch={bankingInformationReviewsRefetch}
        bankingInformationPlaceholders={bankingInformationPlaceholders}
      />
      <BankingUnlockOrDenyModal
        showModal={showUnlockOrDenyModal}
        closeModal={() => {
          setShowUnlockOrDenyModal(false);
          setFocusedItem({});
        }}
        onUnlockClick={unlock}
        onDenyUnlockClick={denyUnlock}
        currentBankingInformation={currentBankingInformation}
      />
      <BankingInformationDetailsModal
        showModal={showDetailsModal}
        closeModal={() => {
          setShowDetailsModal(false);
          setFocusedItem({
            municipalityId: currentBankingInformation.municipalityId,
          });
        }}
        formFields={formFields}
        bankingInformation={currentBankingInformation}
      />
      <Menu
        anchorEl={ellipsisAnchorEl}
        open={!!ellipsisAnchorEl}
        onClose={ellipsisOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            ellipsisOnClose();
            exportData();
          }}
        >
          <Typography variant="body1">Export all</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            ellipsisOnClose();
            exportData(true);
          }}
          disabled={!bankingInformationReviews?.some(({ updated }) => updated)}
        >
          <Typography variant="body1">Export updated only</Typography>
        </MenuItem>
      </Menu>
    </ListPageWrapper>
  );
};

export default BankingInformationReviewsListPage;
