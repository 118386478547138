import { useQuery } from "react-query";
import { helpKeys } from "./helpQueryKeys";
import { municipalHelpService } from "api/services/municipalHelpService";
import { oneDayInMs } from "constants/caching";

const {
  getAllHelpTitles,
  getAllFaqTitles,
  getHelpById,
  getFaqById,
} = municipalHelpService;

const oneDayCacheSetting = {
  cacheTime: oneDayInMs,
  staleTime: oneDayInMs,
  refetchOnMount: true,
};

// Help Queries
export const useHelpTitles = () =>
  useQuery(helpKeys.helpContent, () => getAllHelpTitles(), oneDayCacheSetting);

export const useHelpContent = (helpId) =>
  useQuery(
    helpKeys.helpArticle(helpId),
    () => getHelpById(helpId),
    oneDayCacheSetting
  );

// FAQ Queries
// Integration TODO: add a searchstring param to the FAQ questions query; the search box generates the param
export const useFaqQuestions = (searchString) =>
  useQuery(
    helpKeys.faqsSearch(searchString),
    () => getAllFaqTitles(searchString),
    oneDayCacheSetting
  );

export const useFaqAnswer = (faqId) =>
  useQuery(
    helpKeys.faqAnswer(faqId),
    () => getFaqById(faqId),
    oneDayCacheSetting
  );
