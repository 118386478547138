import axios from "axios";

export class GoogleMapsApi {
  constructor() {
    this.axios = axios.create({
      baseURL: `https://maps.googleapis.com/maps/api/geocode/json`,
      params: {
        key: process.env.REACT_APP_PLACES_API_KEY,
        language: "en",
      },
    });
  }

  get(params = undefined) {
    return this.axios.get("", { params });
  }
}
