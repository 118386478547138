import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import AmoFormActions from "components/AmoFormActions";
import colors from "constants/colors";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { projectService } from "api/services/projectService";
import { useSnackbar } from "contexts/SnackbarContext";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    color: colors.black,
    backgroundColor: colors.white,
    width: "38.125rem",
    maxHeight: "34rem",
    padding: theme.spacing(0),
    borderRadius: "5px",
    boxShadow: "15px -10px 40px rgba(0, 0, 0, 0.25)",
  },
  modalHeader: {
    position: "relative",
    width: "100%",
    marginTop: "1.5rem",
  },
  modalContent: {
    position: "relative",
    width: "100%",
  },
  headerFont: {
    fontSize: "1.5rem",
    marginLeft: "2.5rem",
    marginBottom: "2rem",
  },
  closeIcon: {
    cursor: "pointer",
    fontSize: "1.5rem",
    marginRight: "1.25rem",
    marginBottom: "2rem",
  },
  contentFont: {
    fontSize: "1rem",
  },
  fullWidth: {
    position: "relative",
    width: "33.4rem",
  },
  contentPadding: {
    // 0px 24px 0px 24px
    padding: "0rem 1.5rem 1rem 2.5rem",
  },
  deletionButtons: {
    width: "12rem",
  },
  confirmDeletionButtons: {
    backgroundColor: colors.red.main,
  },
}));

/**
 * creates a modal dialog for a Project review page using Material-UI's Popover component
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.open - controls visibility of the popover
 * @param {string} props.name - full name of the user requesting the deletion
 * @param {string} props.reason - reason of a requesting the deletion
 * @param {number} props.id - id of the selected project
 * @param {DateTime} props.dateRequested - the full date on which deletion was requested.
 * @param {Function} props.closeFunction - function that closes the modal
 * @param {Function} props.updateFunction - function that deletes the project
 * @param {Function} props.denyDeleteUpdateFunction - function uf deny the project deletion request
 *
 *
 * @returns - The modal component
 */

const AmoDeletionModal = (props) => {
  const {
    open,
    name,
    reason,
    id,
    dateRequested,
    closeFunction,
    updateFunction,
    denyDeleteUpdateFunction,
  } = props;

  const classes = useStyles(props);

  const mounted = useIsMounted();

  const [requestIsOngoing, setRequestIsOngoing] = useState(false);

  const { handleSubmit } = useForm({});

  const { showSnackbar } = useSnackbar();

  const handleDeletionSubmit = async () => {
    try {
      setRequestIsOngoing(true);
      const response = await projectService.deleteProjectAMO(id);

      if (!mounted.current) {
        return;
      }

      if (response?.status === 200) {
        showSnackbar(
          "The project was successfully deleted",
          snackbarTypes.success
        );
      }
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.projectDeletion, snackbarTypes.error);
    } finally {
      setRequestIsOngoing(false);
      closeFunction();
      updateFunction();
    }
  };

  const handleDenyDeletionSubmit = async () => {
    try {
      setRequestIsOngoing(true);
      const response = await projectService.denyDeleteProjectAMO(id);

      if (!mounted.current) {
        return;
      }

      if (response?.status === 200) {
        showSnackbar(
          "The project was successfully denied for deletion",
          snackbarTypes.success
        );
      }
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.amoProjectDenyDeletion, snackbarTypes.error);
    } finally {
      if (mounted.current) {
        setRequestIsOngoing(false);
        closeFunction();
        denyDeleteUpdateFunction();
      }
    }
  };

  const formsActions = [
    {
      disabled: requestIsOngoing,
      testId: "deleteProjectButton",
      className: clsx(classes.deletionButtons, classes.confirmDeletionButtons),
      label: "Delete the project",
      onClick: handleSubmit(async () => {
        await handleDeletionSubmit();
      }),
    },
    {
      disabled: requestIsOngoing,
      testId: "DenyDeletionButton",
      className: classes.deletionButtons,
      label: "Deny deletion",
      color: "primary",
      variant: "outlined",
      onClick: handleSubmit(async () => {
        await handleDenyDeletionSubmit();
      }),
    },
  ];

  const dateFormatted = (date) =>
    `${DateTime.fromISO(date, { locale: "en-GB" }).toLocaleString({
      month: "long",
      day: "numeric",
    })}, ${DateTime.fromISO(date, { locale: "en-GB" }).toLocaleString({
      year: "numeric",
    })}`;

  const modalHeader = (title) => (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.modalHeader}
    >
      <Typography variant="body2" type="h3" className={classes.headerFont}>
        {title}
      </Typography>
      <CloseIcon
        className={classes.closeIcon}
        onClick={() => closeFunction()}
      />
    </Grid>
  );

  const textBlock = (text) => (
    <Grid item className={classes.contentPadding}>
      <Typography
        className={clsx(classes.fullWidth, classes.contentFont)}
        variant="body1"
        component="span"
      >
        {text}
      </Typography>
    </Grid>
  );

  const formActionsView = () => (
    <Grid
      container
      style={{
        marginTop: "0.8rem",
      }}
    >
      <AmoFormActions actions={formsActions} hideLateralBorders />
    </Grid>
  );

  const deletion = () => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.modalContent}
    >
      <Grid item>{modalHeader(`Deletion requested`)}</Grid>
      <Grid
        container
        className={classes.body}
        direction="column"
        spacing={0}
        alignItems="stretch"
        wrap="nowrap"
      >
        {textBlock(
          `${name} requested the deletion of this project on ${dateFormatted(
            dateRequested
          )}. The stated reason is:`
        )}
        {textBlock(`${reason}`)}
        {textBlock(
          "CCBF funding was allocated to this project in prior reporting years. Please review carefully before proceeding with deletion."
        )}
        {textBlock("What would you like to do?")}
      </Grid>
      {requestIsOngoing && textBlock("Please wait...")}
      {formActionsView()}
    </Grid>
  );

  const renderForm = () => deletion();

  return (
    <Modal
      open={open}
      className={classes.modal}
      onClose={(event, closeReason) => {
        if (closeReason !== "backdropClick") {
          closeFunction(event, closeReason);
        }
      }}
      disableEscapeKeyDown
    >
      <Box className={classes.box}>{renderForm()}</Box>
    </Modal>
  );
};

AmoDeletionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  dateRequested: PropTypes.instanceOf(Date),
  closeFunction: PropTypes.func.isRequired,
  updateFunction: PropTypes.func.isRequired,
  denyDeleteUpdateFunction: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
};

AmoDeletionModal.defaultProps = {
  dateRequested: DateTime.local(),
};

export default AmoDeletionModal;
