import React, { useState } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import XlsxPopulate from "xlsx-populate";
import { DateTime } from "luxon";

import AmoModal from "components/AmoModal";
import AllocationsFileUpload from "components/AllocationsFileUpload";
import AmoTable from "components/table/AmoTable";

import { reporting } from "constants/reporting";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { range } from "utils/number";
import { getSortingComparator, stableSort } from "utils/data";

import { useWrapApi } from "hooks/wrapApiHook";
import { useIsMounted } from "hooks/useIsMounted";
import { allocationsService } from "api/services/allocationsService";
import { useMunicipalContext } from "contexts/MunicipalContext";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "1rem",
  },
  paddingBottom: {
    paddingBottom: "0.75rem",
  },
}));

/**
 * The allocations file upload modal.
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.open - controls visibility of the AmoModal component
 * @param {Function} props.onClose - function called when the modal should be closed
 *
 * @returns {React.Component} Allocations Upload Modal component.
 */
const AllocationsUploadModal = (props) => {
  const { open, onClose } = props;
  const classes = useStyles();

  const mounted = useIsMounted();

  const { municipalities } = useMunicipalContext();
  const { showSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = useState(null);
  const [issuesData, setIssuesData] = useState(null);
  const [uploadPayload, setUploadPayload] = useState(null);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const uploadAllocations = useWrapApi(
    allocationsService.postMultiple,
    null,
    null
  );

  const rowIsEmpty = (row, cellStart, cellEnd) =>
    !range(cellStart, cellEnd).some((cellIndex) => row.cell(cellIndex).value());

  const validateAllocation = (allocation) => {
    const messages = [];
    // Checking municipality
    if (!allocation.municipalityId) {
      messages.push("Municipality ID is required");
    } else if (!municipalities[allocation.municipalityId]) {
      messages.push("There is no Municipality with this ID");
    }
    // Checking amount
    if (!allocation.announced) {
      messages.push("Amount is required");
    } else if (isNaN(allocation.announced)) {
      messages.push("Amount should be a valid number");
    } else if (allocation.announced <= 0) {
      messages.push("Amount should be a positive number");
    }
    // Checking estimated payment date
    if (!allocation.payment) {
      messages.push("Estimated Payment Date is required");
    } else if (!DateTime.fromISO(allocation.payment).isValid) {
      messages.push("Estimated Payment Date should be a valid date");
    }

    return messages;
  };

  const handleAllocationUpload = async () => {
    if (selectedFile && uploadPayload?.length) {
      setIsUploadingFile(true);
      const result = await uploadAllocations.call(uploadPayload);
      const response = result?.data;

      if (!mounted.current) {
        return;
      }

      if (response?.status !== 200) {
        showSnackbar(`Failed to upload all rows`, snackbarTypes.error);
      } else if (response?.data?.some((item) => !item.isSuccessful)) {
        const failedRows = response.data.reduce((array, item, index) => {
          if (!item.isSuccessful) {
            array.push(uploadPayload[index].row);
          }
          return array;
        }, []);

        showSnackbar(
          `Failed to upload these rows: ${failedRows.join("; ")}`,
          snackbarTypes.error
        );
      } else {
        showSnackbar(
          "All allocations were uploaded successfully",
          snackbarTypes.success
        );
      }

      setIsUploadingFile(false);
      onClose(true);
    }
  };

  const closeModal = () => {
    onClose(false);
  };

  const handleRequestSort = (fieldKey, newOrder) => {
    const sorted = stableSort(
      issuesData,
      getSortingComparator(newOrder, fieldKey)
    );
    setIssuesData(sorted);
  };

  const fillPayloadData = async (newSelectedFile) => {
    if (!newSelectedFile) {
      setIssuesData(null);
      setUploadPayload(null);
      return;
    }

    setIsProcessingFile(true);

    const workbook = await XlsxPopulate.fromDataAsync(newSelectedFile);

    if (!mounted.current) {
      return;
    }

    const worksheet = workbook.sheet(0);

    const usedRange = worksheet.usedRange();
    const highestRowNum = usedRange.endCell().rowNumber();

    const payload = range(2, highestRowNum).reduce((result, index) => {
      const row = worksheet.row(index);
      if (rowIsEmpty(row, 1, 4)) {
        return result;
      }

      const estimatedPayment = DateTime.fromJSDate(
        XlsxPopulate.numberToDate(row.cell(3).value())
      );

      const item = {
        row: index,
        municipalityId: row.cell(1).value(),
        reportingYear: reporting.currentReportingYear,
        announced: row.cell(2).value(),
        payment: row.cell(3).value()
          ? // Passing only the iso date (without time)
            estimatedPayment?.toISODate() ?? "INVALID"
          : null,
      };
      return [...result, item];
    }, []);

    let issues = null;

    if (!payload?.length) {
      issues = [
        {
          row: 0,
          municipalityId: 0,
          reason: `Failed to process: Empty sheet`,
        },
      ];
    } else {
      issues = payload?.reduce((array, item, index) => {
        const messages = validateAllocation(item);

        if (messages.length > 0) {
          const issue = {
            row: item.row,
            municipalityId: item.municipalityId,
            reason: `Failed to process: ${messages.join("; ")}`,
          };

          return [...array, issue];
        }

        return array;
      }, []);
    }

    // Filter payload without issues
    const filteredPayload = payload.filter(
      (item) => !issues.some((issue) => issue.row === item.row)
    );

    setIssuesData(issues?.length ? issues : null);
    setUploadPayload(filteredPayload);
    setIsProcessingFile(false);
  };

  const handleFileChange = async (newSelectedFile) => {
    setSelectedFile(newSelectedFile);
    await fillPayloadData(newSelectedFile);
  };

  const tableColumns = [
    {
      fieldKey: "row",
      label: "Row",
      widthPerc: 15,
    },
    {
      fieldKey: "municipalityId",
      label: "Municipality ID",
      widthPerc: 35,
    },
    {
      fieldKey: "reason",
      label: "Reason",
      color: "secondary",
      variant: "body2",
      widthPerc: 50,
    },
  ];

  const modalButtons = [
    {
      key: "allocationsFileModalUpload",
      label: "Upload",
      color: "primary",
      variant: "contained",
      disabled:
        !selectedFile ||
        !uploadPayload?.length ||
        isProcessingFile ||
        isUploadingFile,
      onClick: handleAllocationUpload,
      loading: isProcessingFile || isUploadingFile,
    },
    {
      key: "allocationsFileModalCancel",
      label: "Cancel",
      color: "primary",
      variant: "outlined",
      onClick: closeModal,
    },
  ];

  const renderMessageRow = () => {
    if (!selectedFile || isProcessingFile) return null;
    let message = "";

    const uploadRows = uploadPayload?.length ?? 0;
    const issuesRows = issuesData?.length ?? 0;

    if (uploadRows <= 0) {
      message = "No rows will be uploaded due to these issues:";
    } else if (issuesRows > 0) {
      const totalRows = uploadRows + issuesRows;
      message = `Uploading ${uploadRows} of ${totalRows} rows, the following rows will be ignored:`;
    }

    if (!message) return null;

    return (
      <Grid item>
        <Typography variant="body1" className={classes.paddingBottom}>
          {message}
        </Typography>
      </Grid>
    );
  };

  return (
    <AmoModal
      open={open}
      title="Upload New Allocations"
      showButton
      width="40rem"
      buttons={modalButtons}
      closePopover={closeModal}
    >
      <Grid
        container
        direction="column"
        spacing={0}
        wrap="nowrap"
        className={classes.container}
      >
        {selectedFile && (
          <Grid item>
            <Typography variant="body1" className={classes.paddingBottom}>
              Do you want to upload this file to allocations?
            </Typography>
          </Grid>
        )}
        {isUploadingFile && (
          <Grid item>
            <Typography variant="body1" className={classes.paddingBottom}>
              Allocations are uploading. Please wait.
            </Typography>
          </Grid>
        )}
        <Grid item className={classes.paddingBottom}>
          <AllocationsFileUpload onChange={handleFileChange} />
        </Grid>
        {renderMessageRow()}
        {issuesData?.length && (
          <Grid item>
            <AmoTable
              id="allocations-upload-table"
              testId="allocationsUploadTable"
              items={issuesData}
              columns={tableColumns}
              defaultOrderBy="row"
              onRequestSort={handleRequestSort}
              shouldUseQueryString
              disableFilters
              uniqueKeyGenerationFields={["row"]}
            />
          </Grid>
        )}
      </Grid>
    </AmoModal>
  );
};

// set the prop-types for this component
AllocationsUploadModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

// set prop-types defaults for this component
AllocationsUploadModal.defaultProps = {
  open: false,
};

export default AllocationsUploadModal;
