import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  enabled: boolean }} NotificationListItem
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  selected: boolean }} NotificationRecipient
 */

/**
 * @typedef {{
 *  id: number,
 *  text: string,
 *  description: boolean }} NotificationPlaceholder
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  purpose: string,
 *  to: NotificationRecipient[],
 *  cc: NotificationRecipient[],
 *  placeholders: NotificationPlaceholder[],
 *  subject: string,
 *  body: string,
 *  enabled: boolean }} Notification
 */

const api = new Api("api/notifications");
export const notificationsService = {
  /**
   * @returns {Promise<AxiosResponse<NotificationListItem[]>>}
   */
  getAll: () => api.get(),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<Notification>>}
   */
  getById: (id) => api.getById(id),
  update: (id, payload) => api.put(id, payload),
};
