/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  Grid,
  Button,
  Icon,
  ListItemText,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import parse from "html-react-parser";
import { useSnackbar } from "contexts/SnackbarContext";
import { filesService } from "api/services/fileService";
import { errorMessages } from "constants/errorMessages";
import { snackbarTypes } from "constants/snackbar";
import clsx from "clsx";
import { useIsMounted } from "hooks/useIsMounted";
import { useNavigationHelper } from "hooks/navigationHook";
import { useHelpContent } from "./helpPageHooks";

const HEADER_PADDING = "1rem";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingTop: "0.625rem",
  },
  fileHeader: {
    paddingTop: HEADER_PADDING,
    paddingBottom: HEADER_PADDING,
  },
  fileIcon: {
    verticalAlign: "bottom",
    marginRight: "0.625rem",
  },
  primaryTextDark: {
    marginTop: "1rem",
    width: "100%",
    "& > span > p": {
      "&:first-child": {
        marginTop: "0rem",
      },
      "&:last-child": {
        marginBottom: "0rem",
      },
      "& > a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontWeight: "bolder",
      },
    },
    "& > span > p > a:hover": {
      textDecoration: "underline",
    },
    "& > span > iframe": {
      width: "100%",
      height: "56vw",
      // 560px
      maxWidth: "35rem",
      // 315px
      maxHeight: "19.75rem",
    },
  },
}));

/**
 * Renders the help content and files for a single help article, if found.
 *
 * @returns {React.Component} The help content section.
 */
const HelpSection = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const mounted = useIsMounted();

  const { location } = useNavigationHelper();
  const pathParts = location.pathname.split("/");
  const helpId = pathParts[pathParts.length - 1];

  const { data: helpContent, isFetching } = useHelpContent(helpId);

  const onDownloadFile = async ({ id, name }) => {
    try {
      const { data } = await filesService.getById(id);

      if (!mounted.current) {
        return;
      }

      // Download file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch {
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  // Checks for the unpublished property on the article, which only returns when the article is unpublished
  const isContentUnpublished = (article) =>
    article && "isPublished" in article && article?.isPublished === false;

  // Conditionally render the article title based on whether it exists and is published
  const ArticleTitle = () => {
    if (helpContent?.data?.title) {
      return helpContent.data.title;
    }
    if (isContentUnpublished(helpContent)) {
      return "Content Unavailable";
    }
    return "Content Not Found.";
  };

  // Conditionally render the article content based on whether it exists and is published
  const ArticleContent = () => {
    if (helpContent?.data?.content) {
      return parse(helpContent.data.content);
    }
    if (isContentUnpublished(helpContent)) {
      return "The help content you are trying to access is not currently available.  Please browse the other help content for guidance.";
    }
    return "This content does not exist.";
  };

  return (
    <Grid container direction="column">
      {isFetching ? (
        <>
          <Skeleton width="100%" height="3.75rem" />
        </>
      ) : (
        <Grid item>
          <Typography
            gutterBottom
            className={classes.primaryTextDark}
            variant="h2"
          >
            <ArticleTitle />
          </Typography>
        </Grid>
      )}
      <Divider orientation="horizontal" variant="fullWidth" light />
      {isFetching ? (
        <>
          <Skeleton width="100%" height="3.125rem" />
          <Skeleton width="100%" height="3.125rem" />
          <Skeleton width="100%" height="3.125rem" />
        </>
      ) : (
        <>
          <Grid item className={classes.content}>
            <ListItemText
              primaryTypographyProps={{
                variant: "body1",
              }}
              className={classes.primaryTextDark}
            >
              <ArticleContent />
            </ListItemText>
          </Grid>
          {helpContent?.data?.files && (
            <Grid item container direction="column">
              <Grid item>
                <Divider orientation="horizontal" variant="fullWidth" light />
              </Grid>
              {!!helpContent?.data?.files?.length && (
                <Grid item className={classes.fileHeader}>
                  <Typography className={classes.primaryTextDark} variant="h4">
                    Files
                  </Typography>
                </Grid>
              )}
              {helpContent?.data?.files?.map((file) => (
                <Grid item key={file.id}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.fileRow}
                  >
                    <Grid item>
                      <Button onClick={() => onDownloadFile(file)}>
                        <Typography color="primary" variant="body2">
                          <Icon
                            className={clsx(
                              "material-icons-outlined",
                              classes.fileIcon
                            )}
                          >
                            file_download
                          </Icon>
                          {file?.name}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default HelpSection;
