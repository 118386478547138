import React, { useState, useRef } from "react";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import PropTypes from "prop-types";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { formatToCurrency } from "utils/number";
import { routes } from "constants/routes";
import { NavLink, useLocation } from "react-router-dom";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { AmoTableActionsProps } from "constants/amoTablePropTypes";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { displayTypes } from "constants/amoTableConstants";
import { projectCategories } from "constants/amoTableDropdownOptions";
import { formatISODate } from "utils/date";

const useStyles = makeStyles((theme) => ({
  cellText: {
    fontSize: "0.875rem",
  },
}));

/**
 * The Communications Project List Concise Table.
 *
 * @param {object} props - object containing props for this component
 * @param {Array} props.tableData - object containing the table data
 * @param {boolean} props.enableClick - enable/disable table item click
 * @param {Function} props.handleItemClick - function called when user clicks on an item row (params: { item clicked })
 * @param {object} props.tableActions - the table actions for the AmoTableWithFilters component @see AmoTableActionsProps
 * @param {boolean} props.isLoading - sets whether the table data is loading
 *
 * @returns {React.Component} Communications Project List Concise Table.
 */
const ConciseViewTable = (props) => {
  const {
    tableData,
    enableClick,
    handleItemClick,
    tableActions,
    isLoading,
  } = props;

  const classes = useStyles();
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const { getMunicipalitiesNames } = useMunicipalContext();

  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});

  const tableColumns = [
    {
      fieldKey: "municipalityName",
      label: "Municipality",
      minWidth: "9.8rem",
      verticalAlign: "top",
      customRender: (item) => (
        <Typography className={classes.cellText}>
          {item.municipalityName}
        </Typography>
      ),
      isCustom: true,
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "name",
      label: "Project",
      minWidth: "15.625rem",
      verticalAlign: "top",
      customRender: ({ id, name, objectives, scopeOfWork }) => (
        <Typography className={classes.cellText}>
          <Grid container direction="row" spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <NavLink
                to={{
                  pathname: routes.communications.projects.details.replace(
                    ":projectId",
                    id
                  ),
                  state: "Concise",
                  search: location.search,
                }}
                ref={(element) => {
                  rowRefs.current[id] = element;
                }}
                onFocus={() => setFocusedItem({ id })}
                className={globalClasses.tableActionCell}
              >
                {name}
              </NavLink>
            </Grid>
            {!!scopeOfWork && (
              <Grid item xs={12}>
                {scopeOfWork}
              </Grid>
            )}
            {!!scopeOfWork && !!objectives && <br />}
            {!!objectives && (
              <Grid item xs={12}>
                {objectives}
              </Grid>
            )}
          </Grid>
        </Typography>
      ),
      isCustom: true,
    },
    {
      fieldKey: "category",
      label: "Category",
      minWidth: "9.375rem",
      verticalAlign: "top",
      dropdownOptions: projectCategories,
      displayType: displayTypes.dropdown,
    },
    {
      fieldKey: "totalCost",
      label: "Amounts",
      minWidth: "11.5rem",
      verticalAlign: "top",
      customRender: (item) => (
        <Typography className={classes.cellText}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs>
              <Grid item xs={12}>
                Total Cost:
              </Grid>
              <Grid item xs={12}>
                {formatToCurrency(item.totalCost)}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                CCBF Budgeted:
              </Grid>
              <Grid item xs={12}>
                {formatToCurrency(item.ccbfBudgeted)}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                CCBF Spent to-date:
              </Grid>
              <Grid item xs={12}>
                {formatToCurrency(item.ccbfSpent)}
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      ),
      isCustom: true,
    },
    {
      fieldKey: "startDate",
      label: "Dates",
      minWidth: "13rem",
      verticalAlign: "top",
      customRender: (item) => (
        <Typography className={classes.cellText}>
          <Grid
            item
            xs={12}
            container
            direction="column"
            spacing={2}
            alignItems="flex-start"
          >
            <Grid
              item
              xs={12}
              container
              direction="row"
              spacing={2}
              alignItems="flex-start"
            >
              <Grid item xs>
                <Grid item xs={12}>
                  Start:
                </Grid>
                <Grid item xs={12}>
                  {formatISODate(item.startDate)}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={12}>
                  End of Construction:
                </Grid>
                <Grid item xs={12}>
                  {formatISODate(item.constructionEndDate)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      ),
      isCustom: true,
    },
    {
      fieldKey: "updatedDate",
      label: "Last Updated",
      minWidth: "10.5rem",
      verticalAlign: "top",
      customRender: (item) =>
        item.updatedDate !== "0001-01-01T00:00:00" ? (
          <Typography className={classes.cellText}>
            <Grid item xs>
              {formatISODate(item.updatedDate)}{" "}
              {item.updatedBy ? `by ${item.updatedBy}` : ""}
            </Grid>
          </Typography>
        ) : (
          <></>
        ),
      isCustom: true,
    },
  ];

  return (
    <AmoTableWithFilters
      items={tableData}
      columns={tableColumns}
      defaultOrderBy="id"
      defaultOrder="desc"
      enableItemClick={enableClick}
      onItemClick={handleItemClick}
      debouncingTime={450}
      onScroll={(item) => {
        rowRefs.current?.[item.id]?.focus();
      }}
      focusedItem={focusedItem}
      actionsProps={tableActions}
      isLoading={isLoading}
    />
  );
};

ConciseViewTable.propTypes = {
  tableData: PropTypes.shape().isRequired,
  enableClick: PropTypes.bool,
  handleItemClick: PropTypes.func,
  tableActions: PropTypes.instanceOf(AmoTableActionsProps),
  isLoading: PropTypes.bool,
};

ConciseViewTable.defaultProps = {
  enableClick: true,
  handleItemClick: () => {},
  tableActions: [],
  isLoading: false,
};

export default ConciseViewTable;
