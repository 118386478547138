import financialRowElement from "./financialRowElement";

const financialEntry = (
  displayName,
  financialDataFieldName,
  financialData,
  classes,
  isNegative = true,
  dontShowBeforeYear = undefined
) =>
  financialRowElement(
    displayName,
    financialDataFieldName,
    financialData,
    classes,
    false,
    false,
    false,
    isNegative,
    true,
    false,
    dontShowBeforeYear
  );

export default financialEntry;
