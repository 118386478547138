import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Icon,
  Drawer,
  List,
  Divider,
  Box,
  Link,
} from "@material-ui/core";
import { routes } from "../constants/routes";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ReactComponent as Logo } from "../amoLogo_simple.svg";
import colors from "constants/colors";
import { roles } from "constants/user";
import MobileMunicipalMenu from "./menu/mobile/MobileMunicipalMenu";
import MobileAmoMenu from "./menu/mobile/MobileAmoMenu";
import MobileCommMenu from "./menu/mobile/MobileCommMenu";
import MobileLoggedOutMenu from "./menu/mobile/MobileLoggedOutMenu";
import AmoPopupMenuItem from "./AmoPopupMenuItem";
import AmoPopupYearMenu from "components/AmoPopupYearMenu";
import { useUserContext } from "contexts/UserContext";
import { projectInterfaceIcons } from "constants/projectInterfaceIcons";

const useStyles = makeStyles((theme) => ({
  appBarGrid: {
    "& > .MuiGrid-item": {
      height: "100%",
      marginRight: "0.75rem",
    },
  },
  fullHeight: {
    height: "100%",
  },
  drawerPaper: {
    minWidth: "9.375rem",
    width: "90%",
    maxWidth: "37.5rem",
    "&::-webkit-scrollbar": {
      width: "0.3rem",
      marginRight: 1,
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.grey.main,
      borderRadius: "0.125rem",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: colors.grey.dark,
    },
  },
  drawerHeader: {
    backgroundColor: colors.white,
    borderBottom: `0.125rem solid ${colors.green.border}`,
    height: "4.375rem",
    padding: "0rem 0.625rem 0rem 0.625rem",
  },
  divider: {
    backgroundColor: colors.grey.light,
  },
  menuItem: {
    padding: `0.75rem 2rem`,
  },
  menuIconPopup: {
    color: theme.palette.primary.main,
  },
  closeIcon: {
    color: colors.black,
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "7rem",
    height: "3.875rem",
    padding: "8% 0",
  },
}));

/**
 * A header component for mobile
 *
 * @returns {React.Component} The header component
 */
const MobileHeader = () => {
  const classes = useStyles();
  const { user } = useUserContext();

  const [isOpen, setOpen] = useState(false);

  const handleItemClick = () => setOpen(false);

  const renderMenu = () => {
    // Logged out menu
    if (!user.isAuthenticated) {
      return <MobileLoggedOutMenu onItemClick={handleItemClick} />;
    }

    // AMO Menu
    if (user.roles.includes(roles.amo)) {
      return <MobileAmoMenu onItemClick={handleItemClick} />;
    }
    // Communications Menu
    if (user.roles.includes(roles.communications)) {
      return <MobileCommMenu onItemClick={handleItemClick} />;
    }

    // Municipal menu
    return <MobileMunicipalMenu onItemClick={handleItemClick} />;
  };

  const renderAccountMenu = () => {
    if (!user || !user.isAuthenticated) return null;

    return (
      <Box>
        <Divider className={classes.divider} />
        <List>
          {!user.roles.includes(roles.amo) && (
            <AmoPopupMenuItem
              className={classes.menuItem}
              to="/account"
              iconName={projectInterfaceIcons.ACCOUNT}
              text="Account"
              testId="headerAccountMenuAcc"
              onClick={handleItemClick}
            />
          )}
          <AmoPopupMenuItem
            className={classes.menuItem}
            to="/logout"
            iconName={projectInterfaceIcons.LOGOUT}
            text="Log Out"
            testId="headerAccountMenuLogout"
            onClick={handleItemClick}
          />
        </List>
      </Box>
    );
  };

  return (
    <>
      <Grid
        data-testid="mobileHeader"
        container
        className={clsx(classes.appBarGrid, classes.fullHeight)}
        justifyContent="flex-start"
        alignContent="stretch"
      >
        <Grid item className={classes.center}>
          <IconButton
            data-testid="mobileHeaderDrawerButton"
            aria-label="mobileHeaderDrawerIcon"
            onClick={() => setOpen(true)}
            className={classes.menuIconPopup}
          >
            <Icon className="material-icons-outlined">menu</Icon>
          </IconButton>
        </Grid>
        <Grid item className={classes.center}>
          <Link href={routes.dashboard}>
            <Logo className={classes.logo} data-testid="logo" />
          </Link>
        </Grid>
        {user?.roles?.includes(roles.amo) && (
          <AmoPopupYearMenu
            id="mobile-menu-context-year-appbar"
            testId="mobileHeaderContextYearMenu"
            classes={{
              button: classes.menuLinkPopup,
              buttonActive: classes.menuLinkPopupActive,
            }}
          />
        )}
      </Grid>
      <Drawer
        data-testid="mobileHeaderDrawer"
        anchor="left"
        open={isOpen}
        onClose={() => setOpen(false)}
        PaperProps={{
          className: classes.drawerPaper,
        }}
        BackdropProps={{
          "data-testid": "mobileHeaderDrawerBackdrop",
        }}
      >
        <Grid
          container
          className={classes.drawerHeader}
          justifyContent="space-between"
        >
          <Grid item className={classes.center}>
            <Logo className={classes.logo} alt="logoDrawer" />
          </Grid>
          <Grid item className={classes.center}>
            <IconButton
              data-testid="mobileHeaderDrawerCloseButton"
              aria-label="mobileHeaderDrawerCloseIcon"
              onClick={() => setOpen(false)}
              className={classes.closeIcon}
            >
              <Icon className="material-icons-outlined">close</Icon>
            </IconButton>
          </Grid>
        </Grid>
        {renderMenu()}
        {renderAccountMenu()}
      </Drawer>
    </>
  );
};

export default MobileHeader;
