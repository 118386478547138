import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import axios from "axios";
import ListPageWrapper from "components/ListPageWrapper";
import { snackbarTypes } from "constants/snackbar";
import { useSnackbar } from "contexts/SnackbarContext";
import { errorMessages } from "constants/errorMessages";
import { exportToExcelWithWorker } from "utils/export";
import { displayTypes } from "constants/amoTableConstants";

import { financialsService } from "api/services/financialsService";
import { projectService } from "api/services/projectService";

import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    // 208px
    maxWidth: "13rem",
    "& .MuiSelect-root": {
      // 160px
      minWidth: "10rem",
    },
  },
  lightDivider: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
  },
  button: {
    width: "15rem",
    height: "3.125rem",
  },
  buttonMargin: {
    marginRight: ({ spacing }) => spacing,
  },
  outlinedButton: {
    backgroundColor: "transparent",
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
}));

/**
 * Report query review page for AMO users.
 *
 * @returns {React.Component} Report Query Review component.
 */
const ReportQueryReviewPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const mounted = useIsMounted();

  const [reportType, setReportType] = useState("financials");
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const cancelReport = useRef(null);

  const dropdownOptions = [
    { value: "financials", label: "Financials" },
    { value: "projects", label: "Projects" },
    { value: "expenditures", label: "Project Expenditures" },
  ];

  const financialsColumns = [
    {
      fieldKey: "municipalityId",
      label: "Municipal ID",
    },
    {
      fieldKey: "municipalityName",
      label: "Municipality",
    },
    {
      fieldKey: "year",
      label: "Reporting year",
    },
    {
      fieldKey: "openBalance",
      label: "Opening balance",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "allocationsSum",
      label: "Allocation",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "interestEarned",
      label: "Interest earnings",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "administrationCosts",
      label: "Administration costs",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "proceedsFromDisposal",
      label: "Asset disposals",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "transferredIn",
      label: "Transfers in",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "transferredOut",
      label: "Transfers out",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "broadband",
      label: "Expenditures - BC",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "brownfield",
      label: "Expenditures - BR",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "capacityBldg",
      label: "Expenditures - CB",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "commEnergy",
      label: "Expenditures - CE",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "culture",
      label: "Expenditures - CU",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "disaster",
      label: "Expenditures - DM",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "water",
      label: "Expenditures - DW",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "fireStation",
      label: "Expenditures - FS",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "recreation",
      label: "Expenditures - RE",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "airport",
      label: "Expenditures - RA",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "roadsBridges",
      label: "Expenditures - LR",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "shortline",
      label: "Expenditures - SR",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "shortsea",
      label: "Expenditures - SS",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "sport",
      label: "Expenditures - SP",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "tourism",
      label: "Expenditures - TO",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "transit",
      label: "Expenditures - PT",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "solidWaste",
      label: "Expenditures - SW",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "wastewater",
      label: "Expenditures - WW",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "expendituresTotal",
      label: "Expenditures - Total",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "closeBalance",
      label: "Closing balance",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "reviewStatus",
      label: "Status",
    },
    {
      fieldKey: "reviewAssignee",
      label: "Assignee",
    },
  ];

  const projectsColumns = [
    {
      fieldKey: "id",
      label: "AMO ID",
    },
    {
      fieldKey: "municipalityInternalId",
      label: "Internal ID",
    },
    {
      fieldKey: "municipalityId",
      label: "Municipal ID",
    },
    {
      fieldKey: "municipalityName",
      label: "Municipality",
    },
    {
      fieldKey: "title",
      label: "Title",
    },
    {
      fieldKey: "scopeOfWork",
      label: "Scope of work",
    },
    {
      fieldKey: "objectives",
      label: "Objectives",
    },
    {
      fieldKey: "additionalComments",
      label: "Additional comments",
    },
    {
      fieldKey: "category",
      label: "Category",
    },
    {
      fieldKey: "subcategory",
      label: "Subcategory",
    },
    {
      fieldKey: "investmentType",
      label: "Nature of investment",
    },
    {
      fieldKey: "latitude",
      label: "Latitude",
    },
    {
      fieldKey: "longitude",
      label: "Longitude",
    },
    {
      fieldKey: "startDate",
      label: "Start",
      displayType: displayTypes.date,
    },
    {
      fieldKey: "constructionEndDate",
      label: "EOC",
      displayType: displayTypes.date,
    },
    {
      fieldKey: "financingEndDate",
      label: "EOF",
      displayType: displayTypes.date,
    },
    {
      fieldKey: "totalCost",
      label: "Total budget",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "ccbfFundsSum",
      label: "CCBF allocated",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "totalBudgeted",
      label: "CCBF budgeted",
      displayType: displayTypes.currency,
    },
    {
      fieldKey: "otherFederalFunds",
      label: "Other Federal Funds",
    },
    {
      fieldKey: "provincialFunds",
      label: "Provincial Funds",
    },
    {
      fieldKey: "stackingLimits",
      label: "Stacking Limits",
    },
    {
      fieldKey: "signagePosted",
      label: "Signage",
    },
    {
      fieldKey: "signageIntentions",
      label: "Signage Intentions",
    },
    {
      fieldKey: "signageRationale",
      label: "Signage Rationale",
    },
    {
      fieldKey: "signageType",
      label: "Signage Type",
    },
    {
      fieldKey: "fileUploaded",
      label: "File uploaded",
    },
    {
      fieldKey: "communicationsActivities",
      label: "Links",
    },
    {
      fieldKey: "reviewStatus",
      label: "Status",
    },
    {
      fieldKey: "reviewAssignee",
      label: "Assignee",
    },
  ];

  const expendituresColumns = [
    {
      fieldKey: "id",
      label: "AMO ID",
    },
    {
      fieldKey: "reportingYear",
      label: "Reporting year",
    },
    {
      fieldKey: "category",
      label: "Category",
    },
    {
      fieldKey: "fundingApplied",
      label: "CCBF allocated",
      displayType: displayTypes.currency,
    },
  ];

  const handleReportDataExport = async () => {
    setIsLoadingReport(true);
    try {
      let serviceFunc = financialsService.getAllFinancialsForExport;
      let reportName = "All Financials";
      let columns = financialsColumns;
      if (reportType === "projects") {
        serviceFunc = projectService.getAllProjectsForExport;
        reportName = "All Projects";
        columns = projectsColumns;
      } else if (reportType === "expenditures") {
        serviceFunc = projectService.getAllProjectsExpendituresForExport;
        reportName = "All Project Expenditures";
        columns = expendituresColumns;
      }

      const source = axios.CancelToken.source();
      cancelReport.current = () => {
        source.cancel("Operation canceled by the user");
      };
      const response = await serviceFunc(source);

      if (!mounted.current) {
        return;
      }

      const { terminate: terminateExportWorker } = exportToExcelWithWorker(
        response.data,
        columns,
        reportName,
        {
          sheetName: reportName,
          addTimestamp: true,
        },
        (exportResult) => {
          cancelReport.current = null;
          if (exportResult.error) {
            console.error(exportResult.error);
            showSnackbar(
              errorMessages.creatingSpreadsheet,
              snackbarTypes.error
            );
          }
          setIsLoadingReport(false);
        }
      );

      cancelReport.current = () => {
        terminateExportWorker();
        showSnackbar("Operation canceled successfully", snackbarTypes.warning);
      };
    } catch (error) {
      if (axios.isCancel(error)) {
        showSnackbar("Operation canceled successfully", snackbarTypes.warning);
      } else {
        console.error(error);
        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
    }
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="h1">Report Queries</Typography>
        </Grid>
        <Grid item>
          <Divider className={classes.lightDivider} />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <TextField
                id="report-query-review-report-type"
                data-testid="reportQueryReviewReportType"
                fullWidth
                select
                label="Report Type"
                variant="outlined"
                value={reportType}
                onChange={({ target: { value } }) => {
                  setReportType(value);
                }}
                className={classes.dropdown}
              >
                {dropdownOptions.map(({ label, value }) => (
                  <MenuItem key={`period-${value}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <Button
                data-testid="reportQueryReviewExportButton"
                className={clsx(classes.button, classes.buttonMargin)}
                color="primary"
                variant="contained"
                size="medium"
                disableElevation
                disabled={isLoadingReport}
                onClick={handleReportDataExport}
              >
                <Typography align="center" variant="body2">
                  Export
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                data-testid="reportQueryReviewCancelButton"
                className={clsx(classes.button, classes.outlinedButton)}
                color="secondary"
                variant="outlined"
                size="medium"
                disableElevation
                disabled={!isLoadingReport}
                onClick={() => {
                  if (cancelReport.current) {
                    cancelReport.current();
                    cancelReport.current = null;
                  }
                  setIsLoadingReport(false);
                }}
              >
                <Typography align="center" variant="body2">
                  Cancel
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider className={classes.lightDivider} />
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default ReportQueryReviewPage;
