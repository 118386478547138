import React from "react";
import { Box, List, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import AmoPopupMenuItem from "components/AmoPopupMenuItem";
import { routes } from "constants/routes";
import { projectInterfaceIcons } from "constants/projectInterfaceIcons";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: colors.grey.light,
  },
  menuItem: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
  },
}));

/**
 * A mobile menu component with items for amo users
 *
 * @param {object} props
 * @returns {React.Component} The menu component
 */
const MobileAmoMenu = (props) => {
  const classes = useStyles();
  const { onItemClick } = props;

  return (
    <>
      <Box data-testid="headerAmoMenu">
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to="/landing"
            iconName={projectInterfaceIcons.HOME}
            text="Home"
            testId="headerHomeLink"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to="/users"
            iconName={projectInterfaceIcons.USERS}
            text="Users"
            testId="headerAdmMenuUsers"
            onClick={onItemClick}
          />
        </List>
        <Divider className={classes.divider} />
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.projects.list}
            iconName={projectInterfaceIcons.PROJECTS}
            text="Projects"
            testId="headerReviewMenuProj"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.financial.list}
            iconName={projectInterfaceIcons.FINANCIALS}
            text="Financial Information"
            testId="headerReviewMenuExpenditure"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.projects.results}
            iconName={projectInterfaceIcons.PROJECTS}
            text="Results"
            testId="headerReviewMenuOut"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.questionnaires.list}
            iconName={projectInterfaceIcons.QUESTIONNAIRES}
            text="Questionnaire Review"
            testId="headerReviewMenuQuest"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.bankingInformation.list}
            iconName={projectInterfaceIcons.BANKING_INFORMATION}
            text="Banking Information"
            testId="headerReviewMenuBankInfo"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.bankLimit}
            iconName={projectInterfaceIcons.BANKING_LIMIT_MONITORING}
            text="Banking Limit Monitoring"
            testId="headerReviewMenuBankLimit"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.incrementalityMonitoring.list}
            iconName={projectInterfaceIcons.INCREMENTALITY_MONITORING}
            text="Incrementality Monitoring"
            testId="headerReviewMenuIncr"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.insurance.list}
            iconName={projectInterfaceIcons.INSURANCE}
            text="Insurance"
            testId="headerReviewMenuInsurance"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.reportingStatus.list}
            iconName={projectInterfaceIcons.REPORTING_STATUS}
            text="Reporting Status"
            testId="headerReviewMenuReportingStatus"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.reviews.reportQuery}
            iconName={projectInterfaceIcons.PROJECT_LIST}
            text="Report Query"
            testId="headerReviewMenuReportQuery"
            onClick={onItemClick}
          />
        </List>
        <Divider className={classes.divider} />
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.contentManagement.forms.list}
            iconName={projectInterfaceIcons.FORMS}
            text="Forms"
            testId="headerManagementMenuForms"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.contentManagement.announcements.list}
            iconName={projectInterfaceIcons.ANNOUNCEMENTS}
            text="Announcements"
            testId="headerManagementMenuAnnounc"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.contentManagement.outputsOutcomes.list}
            iconName={projectInterfaceIcons.OUTPUTS_OUTCOMES}
            text="Outputs & Outcomes"
            testId="headerManagementMenuOut"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.contentManagement.questionnaires.list}
            iconName={projectInterfaceIcons.QUESTIONNAIRE_MANAGEMENT}
            text="Questionnaire Management"
            testId="headerManagementMenuQuest"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.contentManagement.help.list}
            iconName={projectInterfaceIcons.HELP}
            text="Help"
            testId="headerManagementMenuHelp"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.contentManagement.notifications.list}
            iconName={projectInterfaceIcons.NOTIFICATIONS}
            text="Notification Management"
            testId="headerManagementMenuNotifications"
            onClick={onItemClick}
          />
        </List>
        <Divider className={classes.divider} />
        <List>
          <AmoPopupMenuItem
            className={classes.menuItem}
            to="/communications/dashboard"
            iconName={projectInterfaceIcons.DASHBOARD}
            text="Dashboard"
            testId="headerCommunicationsMenuDashboard"
            onClick={onItemClick}
          />
          <AmoPopupMenuItem
            className={classes.menuItem}
            to={routes.communications.projects.list}
            iconName={projectInterfaceIcons.PROJECT_LIST}
            text="Project List"
            testId="headerCommunicationsMenuProj"
            onClick={onItemClick}
          />
        </List>
      </Box>
    </>
  );
};

// set the prop-types for this component
MobileAmoMenu.propTypes = {
  onItemClick: PropTypes.func,
};

MobileAmoMenu.defaultProps = {
  onItemClick: () => {},
};

export default MobileAmoMenu;
