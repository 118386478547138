import React from "react";
import PropTypes from "prop-types";
import AmoPopover from "./AmoPopover";

/**
 * creates a confirm modal dialog using AmoPopover component
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.open - controls visibility of the popover
 * @param {string} props.variant - controls the color scheme of the popover valid values are {"success", "error", "default"}
 * @param {string} props.title - text to display in the title
 * @param {string} props.text - text to display as the message
 * @param {string} props.yesButtonText - the label displayed on the Yes button
 * @param {Function} props.yesButtonFunction - function that executes when user clicks Yes button  ** MUST BE PARAMETERLESS **
 * @param {string} props.yesButtonVariant - controls the color scheme of Yes button, valid values are {"outlined", "contained", "text"}
 * @param {string} props.noButtonText - the label displayed on the Yes button
 * @param {Function} props.noButtonFunction - function that executes when user clicks No button ** MUST BE PARAMETERLESS **
 * @param {string} props.noButtonVariant - controls the color scheme of No button, valid values are {"outlined", "contained", "text"}
 * @param {Function} props.closePopover - function that hides the popover
 * @param {string} props.width - sets the width of the popover
 * @returns - the confirm popover component, styled for the AMO project
 */
const AmoConfirmPopover = (props) => {
  const {
    open,
    variant,
    title,
    text,
    yesButtonText,
    yesButtonFunction,
    yesButtonVariant,
    noButtonText,
    noButtonFunction,
    noButtonVariant,
    closePopover,
    width,
  } = props;

  return (
    <AmoPopover
      data-testid="amoConfirmPopover"
      open={open}
      variant={variant}
      title={title}
      text={text}
      showButton
      buttonText={yesButtonText}
      buttonFunction={yesButtonFunction}
      buttonVariant={yesButtonVariant}
      buttonColor="primary"
      secondButtonText={noButtonText}
      secondButtonFunction={noButtonFunction}
      secondButtonVariant={noButtonVariant}
      secondButtonColor="secondary"
      closePopover={closePopover}
      width={width}
    />
  );
};

AmoConfirmPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(["success", "error", "default"]),
  title: PropTypes.string,
  text: PropTypes.string,
  yesButtonText: PropTypes.string,
  yesButtonFunction: PropTypes.func,
  yesButtonVariant: PropTypes.oneOf(["outlined", "contained"]),
  noButtonText: PropTypes.string,
  noButtonFunction: PropTypes.func,
  noButtonVariant: PropTypes.oneOf(["outlined", "contained"]),
  closePopover: PropTypes.func.isRequired,
  width: PropTypes.string,
};

AmoConfirmPopover.defaultProps = {
  variant: "default",
  title: "Message",
  text: "Message Text",
  yesButtonText: "Yes",
  yesButtonFunction: null,
  yesButtonVariant: "outlined",
  noButtonText: "No",
  noButtonFunction: null,
  noButtonVariant: "outlined",
  width: null,
};

export default AmoConfirmPopover;
