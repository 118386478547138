import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  category: string,
 *  indicatorType: string,
 *  description: string,
 *  sortOrder: number,
 *  type: string,
 *  status: string }} OutputOutcome
 */

const indicatorApi = {
  summaries: new Api("api/Indicators/summaries"),
  indicators: new Api("api/Indicators"),
  dropdownData: new Api("api/Indicators/dropdownData"),
  groupings: new Api("api/Indicators/groupings"),
};

export const indicatorService = {
  /**
   * @param reportingYear
   * @returns {Promise<AxiosResponse<OutputOutcome[]>>}
   */
  getSummaries: (reportingYear) =>
    indicatorApi.summaries.getById(reportingYear),
  getIndicatorById: (id) => indicatorApi.indicators.getById(id),
  getDropdownData: () => indicatorApi.dropdownData.get(),
  getDropdownDataByProjectId: (projectId) =>
    indicatorApi.dropdownData.getById(projectId),
  getGroupings: () => indicatorApi.groupings.get(),
  updateGroupings: (payload) => indicatorApi.groupings.putWithoutId(payload),
  deleteGrouping: (id) => indicatorApi.groupings.del(id),
  addIndicator: (payload) => indicatorApi.indicators.post(payload),
  updateIndicator: (id, payload) => indicatorApi.indicators.put(id, payload),
  /**
   * @param {string} cellLocator
   * @param {number=} indicatorId
   * @returns {Promise<AxiosResponse<boolean>>}
   */
  validateCellLocator: (cellLocator, indicatorId) =>
    indicatorApi.indicators.getById(
      `validateCellLocator/${cellLocator}`,
      indicatorId
        ? {
            indicatorId,
          }
        : undefined
    ),
};
