import { useState, useEffect, useRef } from "react";

import { formFieldTypes } from "constants/formContentManagement";
import { formsService } from "api/services/formService";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { useYearContext } from "contexts/YearContext";
import placeholders, {
  ReplaceAllPlaceholdersInString,
} from "functions/placeholders";
import { routes } from "constants/routes";
import { publishedURL } from "utils/url";
import { nameLinkCss } from "components/municipal/NameLink";
import { makeStyles } from "@material-ui/core";
import { useIsMounted } from "hooks/useIsMounted";

let placeholdersValues = {};
const setPlaceholdersValues = (newPlaceholders) => {
  placeholdersValues = newPlaceholders;
};

const useStyles = makeStyles((theme) => ({
  rowFont: nameLinkCss.rowFont,
  textLink: nameLinkCss.textLink,
}));

/**
 * A hook for form management.
 *
 * @param {string} formId The id of the form.
 * @param {boolean} [forDisplay=false] Indicates whether the field is interactive input or display-only
 *
 * @returns {object} Contains form state and a function to update form state.
 */
export const useFormManagement = (formId, forDisplay = false) => {
  const classes = useStyles();

  const mounted = useIsMounted();

  const { showSnackbar } = useSnackbar();
  const {
    getMunicipalityName,
    isFetching: isFetchingMunicipality,
  } = useMunicipalContext();
  const { contextYear } = useYearContext();

  const [rawFormFields, setRawFormFields] = useState([]);
  const [formFields, setFormFields] = useState({});
  const isLoaded = useRef(false);

  const reduceFormField = (
    array,
    transform = true,
    placeholderNames = [],
    values = []
  ) =>
    array.reduce((map, obj) => {
      const text = forDisplay
        ? ReplaceAllPlaceholdersInString(obj.text, placeholderNames, values)
        : obj.text;

      const newObjCode = transform
        ? {
            id: obj.id,
            code: obj.code,
            type: obj.type,
            text,
            url: obj.url,
            helperText: obj.helperText,
            tooltipText: obj.tooltip?.text,
          }
        : { ...obj, text };

      return { ...map, [obj.code]: newObjCode };
    }, {});

  // this effect will make a call to the API to get the data
  useEffect(() => {
    setPlaceholdersValues({});
    addPlaceholderValue(
      placeholders.linkToHelpPage,
      `<a
        className="${classes.textLink}"
        target="_blank"
        href="${publishedURL + routes.help.base}"
      >here</a>`
    );

    const getData = async (id) => {
      try {
        const { data } = await formsService.getFormFieldsByFormId(id);

        if (!mounted.current) {
          return;
        }

        const reducedResult = reduceFormField(
          data,
          true,
          Object.keys(placeholdersValues),
          Object.values(placeholdersValues)
        );

        setRawFormFields(data);
        setFormFields(reducedResult);
        isLoaded.current = true;
      } catch {
        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
    };

    getData(formId);
  }, []);

  const addPlaceholderValue = (placeholder, value) => {
    const newPlaceholdersValues = {
      ...placeholdersValues,
      [placeholder]: value,
    };
    setPlaceholdersValues(newPlaceholdersValues);
    if (isLoaded.current) {
      const reducedFormFields = reduceFormField(
        rawFormFields,
        true,
        Object.keys(newPlaceholdersValues),
        Object.values(newPlaceholdersValues)
      );
      setFormFields(reducedFormFields);
    }
  };

  useEffect(() => {
    if (!forDisplay || isFetchingMunicipality) {
      return;
    }

    const municipalityName = getMunicipalityName(null, true);

    if (Object.entries(formFields)?.length > 0 && municipalityName !== "") {
      addPlaceholderValue(placeholders.municipality, municipalityName);
    }
  }, [isFetchingMunicipality, Object.entries(formFields)?.length]);

  useEffect(() => {
    if (!forDisplay || !contextYear) {
      return;
    }

    if (Object.entries(formFields)?.length > 0) {
      addPlaceholderValue(placeholders.reportingYear, contextYear);
    }
  }, [contextYear, Object.entries(formFields)?.length]);

  const saveFormField = async (updatedField) => {
    const payload = {
      id: updatedField.id,
      type: updatedField.type,
      text: updatedField.text,
    };

    if (updatedField.type === formFieldTypes.titleWithTooltip) {
      payload.tooltipText = updatedField.tooltipText;
    }
    if (updatedField.type === formFieldTypes.field) {
      payload.helperText = updatedField.helperText;
      payload.tooltipText = updatedField.tooltipText;
    }
    if (updatedField.type === formFieldTypes.link) {
      payload.url = updatedField.url;
      payload.helperText = updatedField.helperText;
    }

    try {
      await formsService.update(updatedField.id, payload);

      if (!mounted.current) {
        return false;
      }

      setFormFields({ ...formFields, [updatedField.code]: updatedField });

      showSnackbar("Form field updated successfully.");
    } catch {
      if (mounted.current) {
        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
      return false;
    }

    return true;
  };

  return {
    formFields,
    saveFormField,
    addPlaceholderValue,
  };
};
