import { useQuery } from "react-query";
import { useUserContext } from "contexts/UserContext";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { roles } from "constants/user";
import { formatToPhoneWithExtension } from "utils/number";

import { userService } from "api/services/userService";
import { userQueryKeys } from "./userQueryKeys";

/**
 * Store server's state of municipal user lookups
 *
 * @returns The query object returned by the useQuery hook
 */
export const useMunicipalUserHooks = () => {
  const { user } = useUserContext();
  const shouldUseMunicipalityId =
    user &&
    (user.roles.includes(roles.municipalTreasurer) ||
      user.roles.includes(roles.municipalTreasurerDelegate));
  const municipalityId = shouldUseMunicipalityId ? user.municipalityId : null;

  const {
    getMunicipalityName,
    isFetching: isFetchingMunicipality,
  } = useMunicipalContext();

  return useQuery(
    userQueryKeys.municipalUsers(municipalityId),
    () => userService.getMunicipalUsers(municipalityId),
    {
      select: ({ data }) =>
        data.map((item) => ({
          id: item.id,
          name: item.name,
          municipality: getMunicipalityName(item.municipalityId),
          email: item.email,
          phone: formatToPhoneWithExtension(item.phone, item.phoneExtension),
          permissions: item.permissions.join(", "),
        })),
      enabled: !!user && !isFetchingMunicipality,
    }
  );
};

/**
 * Store server's state of treasurer user lookups
 *
 * @returns The query object returned by the useQuery hook
 */
export const useTreasurerUserHooks = () => {
  const {
    municipalities,
    isFetching: isFetchingMunicipality,
  } = useMunicipalContext();

  return useQuery(userQueryKeys.treasurerUsers, userService.getTreasurers, {
    select: ({ data }) =>
      data.map((item) => ({
        id: item.id,
        imisId: item.imisId,
        name: item.name,
        municipality: municipalities[item.municipalityId]?.name,
        email: item.email,
        phone: formatToPhoneWithExtension(item.phone, item.phoneExtension),
      })),
    enabled: !isFetchingMunicipality,
  });
};

/**
 * Store server's state of communications user lookups
 *
 * @returns The query object returned by the useQuery hook
 */
export const useCommunicationsUserHooks = () =>
  useQuery(
    userQueryKeys.communicationsUsers,
    userService.getCommunicationsUsers,
    {
      select: ({ data }) =>
        data.map((item) => ({
          ...item,
          phone: formatToPhoneWithExtension(item.phone, item.phoneExtension),
        })),
    }
  );

/**
 * Store server's state of amo user lookups
 *
 * @returns The query object returned by the useQuery hook
 */
export const useAmoUserHooks = () =>
  useQuery(userQueryKeys.amoUsers, userService.getAmoUsers, {
    select: ({ data }) => data,
  });
