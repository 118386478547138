import React from "react";
import {
  Grid,
  Icon,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  DialogContent,
  Popover,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import clsx from "clsx";
import { usePopupState } from "material-ui-popup-state/hooks";

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    width: "21.5rem",
    borderRadius: "5px",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    borderTop: "none",
    borderBottomColor: colors.grey.main,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  dialogActions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  dialogActionsSpacing: {
    "& :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  fullWidth: {
    width: "100%",
    "& > div": {
      width: "100%",
    },
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "500",
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(1),
    color: colors.grey.icon,
  },
  editIcon: {
    padding: theme.spacing(0.2),
  },
  button: {
    minHeight: 50,
    marginBottom: theme.spacing(1),
  },
  saveButton: {
    maxWidth: "40%",
  },
}));

/**
 * A popover component to edit a form view wrapper field
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.id - id of the component [required]
 * @param {string} props.testId - data-testid of the component
 * @param {Function} props.onSave - function called when user clicks on the Save button (params: none)
 * @param {Function} props.onClose - function called when user clicks on the Close icon (params: none)
 * @param {boolean} props.isSaveEnable - disable or enable Save button
 *
 * @returns - The popover component
 */
const AmoEditFieldPopover = (props) => {
  const {
    // children are a well-known prop type in React, which has built-in warnings for type mismatches here
    // eslint-disable-next-line react/prop-types
    children,
    id,
    testId,
    onSave,
    onClose,
    isSaveEnable,
  } = props;

  const classes = useStyles();

  const popupState = usePopupState({ variant: "popover", popupId: id });

  const handleSave = (event) => {
    popupState.close(event);
    onSave();
  };

  const handleClose = (event) => {
    popupState.close(event);
    onClose();
  };

  return (
    <>
      <IconButton
        data-testid={`${testId ?? `${id}-test`}EditButton`}
        aria-controls={popupState.isOpen ? id : undefined}
        aria-haspopup="true"
        aria-label="editIcon"
        color="primary"
        className={classes.editIcon}
        onClick={popupState.open}
        onTouchStart={popupState.open}
      >
        <Icon className="material-icons-outlined">edit</Icon>
      </IconButton>
      <Popover
        id={popupState.popupId}
        anchorEl={popupState.anchorEl}
        open={popupState.isOpen}
        onClose={popupState.close}
        data-testid={testId ?? `${id}-test`}
        classes={{
          paper: classes.popoverPaper,
        }}
        getContentAnchorEl={null}
        elevation={4}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        hideBackdrop
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DialogTitle
          disableTypography
          className={classes.dialogTitle}
          onClose={handleClose}
        >
          <Typography variant="h6" className={classes.title}>
            Edit Field
          </Typography>
          <IconButton
            data-testid={`${testId ?? `${id}-test`}CloseIcon`}
            aria-label="closeIcon"
            className={classes.closeIcon}
            onClick={handleClose}
          >
            <Icon className="material-icons">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item className={classes.fullWidth}>
              {children}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          className={classes.dialogActions}
          classes={{ spacing: classes.dialogActionsSpacing }}
        >
          <Button
            data-testid={`${testId ?? `${id}-test`}SaveButton`}
            className={clsx(classes.button, classes.saveButton)}
            onClick={handleSave}
            color="primary"
            variant="contained"
            size="large"
            disabled={!isSaveEnable}
          >
            Save
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};

// set the prop-types for this component
AmoEditFieldPopover.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  isSaveEnable: PropTypes.bool,
};

AmoEditFieldPopover.defaultProps = {
  testId: null,
  onSave: () => {},
  onClose: () => {},
  isSaveEnable: true,
};

export default AmoEditFieldPopover;
