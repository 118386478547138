import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import ListPageWrapper from "components/ListPageWrapper";
import CommunicationsProjectWidget from "./CommunicationsProjectsWidget";
import RecentList from "components/communications/RecentList";
import { recentListTypes } from "constants/recentList";
import MapWidget from "components/communications/MapWidget";
import {
  defaultMapStatus,
  defaultMapTimeRange,
} from "constants/communicationConstants";

import { useRecentComms, useRecentUpdates } from "./recentListHooks";
import { useProjectLocations } from "./projectLocationsHooks";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { exportRecentComms } from "utils/export";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: "100%",
    height: "100%",
    paddingBottom: "1.5rem",
  },
  fullHeight: {
    height: "100%",
  },
  paddingBottom: {
    paddingBottom: "1.5rem",
  },
  titleText: {
    fontSize: "2.25rem",
    fontWeight: 700,
    width: "100%",
    marginBottom: "1rem",
  },
}));

/**
 * The Communications Landing Page.
 *
 * @returns {React.Component} Communications Landing Page component.
 */
const LandingPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const {
    data: recentComms,
    isFetching: recentCommsIsFetching,
  } = useRecentComms();

  const {
    data: recentUpdates,
    isFetching: recentUpdatesIsFetching,
  } = useRecentUpdates();

  const [mapParams, setMapParams] = useState([
    defaultMapStatus.id,
    defaultMapTimeRange.getDate(),
  ]);
  const { data: projectLocations } = useProjectLocations(
    mapParams[0],
    mapParams[1]
  );

  const recentCommsExportCells = [
    {
      fieldKey: "date",
      label: "Announcement Date",
    },
    {
      fieldKey: "name",
      label: "Project Name",
    },
    {
      fieldKey: "municipality",
      label: "Municipality",
    },
    {
      fieldKey: "twitterUrl",
      label: "Twitter",
    },
    {
      fieldKey: "instagramUrl",
      label: "Instagram",
    },
    {
      fieldKey: "linkedInUrl",
      label: "LinkedIn",
    },
    {
      fieldKey: "buildingCommunitiesUrl",
      label: "CCBF Website",
    },
  ];

  return (
    <ListPageWrapper>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          <Typography className={classes.titleText}>
            Communications Dashboard
          </Typography>
        </Grid>

        <Grid container item xs={6} md={6} direction="column">
          <Grid item className={classes.paddingBottom}>
            <CommunicationsProjectWidget
              id="communicationsProjectWidget"
              testId="communicationsProjectWidget"
            />
          </Grid>
          <Grid item xs>
            <Grid container spacing={2} className={classes.fullHeight}>
              <Grid item xs={12} md={6}>
                {recentCommsIsFetching && (
                  <Skeleton
                    width="100%"
                    height="29rem"
                    variant="rect"
                    style={{ borderRadius: "0.5rem" }}
                  />
                )}
                {!recentCommsIsFetching && (
                  <RecentList
                    id="dashboard-recent-comms"
                    testId="dashboardRecentComms"
                    type={recentListTypes.comms}
                    title="Recent Comms"
                    items={recentComms ?? []}
                    showDownloadIcon
                    onDownloadClick={(items) => {
                      try {
                        exportRecentComms(items, recentCommsExportCells, {
                          municipality: 30,
                          name: 40,
                        });
                      } catch (error) {
                        showSnackbar(
                          errorMessages.creatingSpreadsheet,
                          snackbarTypes.error
                        );
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {recentUpdatesIsFetching && (
                  <Skeleton
                    width="100%"
                    height="29rem"
                    variant="rect"
                    style={{ borderRadius: "0.5rem" }}
                  />
                )}
                {!recentUpdatesIsFetching && (
                  <RecentList
                    id="dashboard-recent-updates"
                    testId="dashboardRecentUpdates"
                    type={recentListTypes.updates}
                    title="Recent Updates"
                    items={recentUpdates ?? []}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}>
          <Grid item xs={12} md={12}>
            <MapWidget
              locations={projectLocations || []}
              onMapChange={setMapParams}
            />
          </Grid>
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default LandingPage;
