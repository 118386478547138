import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import AmoModal from "components/AmoModal";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  textbox: {
    width: "100%",
    marginTop: "1rem",
  },
  contentPadding: {
    // 0px 24px 0px 24px
    padding: "1rem 0.5rem 0rem 0.5rem",
  },
}));

/**
 * Modal to request banking information verification
 *
 * @param props
 * @param props.showModal
 * @param props.closeModal
 * @param props.onUnlockClick
 * @param props.onDenyUnlockClick
 * @param props.currentBankingInformation
 * @returns {React.Component} Modal component.
 */
const BankingUnlockOrDenyModal = (props) => {
  const classes = useStyles();
  const {
    showModal,
    closeModal,
    onUnlockClick,
    onDenyUnlockClick,
    currentBankingInformation,
  } = props;

  const textBlock = (text) => (
    <Grid item className={classes.contentPadding}>
      <Typography
        className={clsx(classes.fullWidth, classes.contentFont)}
        variant="body1"
        component="span"
      >
        {text}
      </Typography>
    </Grid>
  );

  return (
    <>
      <AmoModal
        open={showModal}
        width="40rem"
        title="Request to unlock Banking information"
        showButton
        closePopover={closeModal}
        buttons={[
          {
            key: "sendRequest",
            label: "Unlock",
            color: "primary",
            variant: "contained",
            onClick: () => {
              onUnlockClick(currentBankingInformation?.id);
              closeModal();
            },
          },
          {
            key: "close",
            label: "Deny",
            color: "secondary",
            variant: "outlined",
            onClick: () => {
              onDenyUnlockClick(currentBankingInformation?.id);
              closeModal();
            },
          },
        ]}
      >
        <Grid
          container
          direction="column"
          spacing={0}
          alignItems="stretch"
          wrap="nowrap"
        >
          {textBlock(
            `Unlock or Deny Banking information for Municipality ${currentBankingInformation?.municipalityName}`
          )}
        </Grid>
      </AmoModal>
    </>
  );
};

BankingUnlockOrDenyModal.propTypes = {
  currentBankingInformation: PropTypes.shape().isRequired,
  onUnlockClick: PropTypes.func.isRequired,
  onDenyUnlockClick: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default BankingUnlockOrDenyModal;
