import { useQuery } from "react-query";

import { incrementalityMonitoringReviewsQueryKeys } from "./incrementalityMonitoringReviewsQueryKeys";
import { reviewService } from "api/services/reviewsService";
import { incrementalityMonitoringService } from "api/services/incrementalityMonitoringService";

/**
 * Store server's state of financials results
 *
 * @returns The query object returned by the useQuery hook
 */
export const useIncrementalityMonitoringReviewsHooks = () =>
  useQuery(
    incrementalityMonitoringReviewsQueryKeys.incrementalityMonitoringReviews,
    () => reviewService.getIncrementalityMonitoringReviews(),
    {
      select: ({ data }) => data,
    }
  );

/**
 * Store server's state of incrementality monitoring modal result
 *
 * @param {number} municipalityId
 * @returns The query object returned by the useQuery hook
 */
export const useIncrementalityMonitoringModal = (municipalityId) =>
  useQuery(
    incrementalityMonitoringReviewsQueryKeys.incrementalityMonitoringModal(
      municipalityId
    ),
    () => incrementalityMonitoringService.getByMunicipality(municipalityId),
    {
      select: ({ data }) => data,
      enabled: !!municipalityId,
    }
  );
