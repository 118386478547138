import { makeStyles } from "@material-ui/core";
import colors from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    color: colors.green.dark,
  },
  firstColumnCell: {
    paddingLeft: "1.25rem",
    minWidth: "25rem",
  },
  cell: {
    minWidth: "12.5rem",
  },
  lastColumnCell: {
    minWidth: "16rem",
  },
  middleColumnSpacing: {
    marginLeft: 24,
  },
  firstRow: {
    paddingTop: "0.5rem",
  },
  rowDivider: {
    marginTop: "0.8rem",
  },
  underlined: {
    textDecoration: "underline",
  },
  margins: {
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  },
  verticalMargins: {
    marginTop: 16,
  },
}));

export default useStyles;
