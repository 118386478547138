import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, Typography, Button, Link, Divider } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

// Query hooks and management
import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useAccountDetails } from "./accountHooks";
import { useCreatePasswordReset } from "./usersHook";

import { userService } from "api/services/userService";
import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";

import AmoTextField from "components/inputs/AmoTextField";
import AmoFormActions from "components/AmoFormActions";
import FormInputWrapper from "components/municipal/FormInputWrapper";

import {
  formIds,
  municipalUserManagementCodes as formCodes,
} from "constants/formContentManagement";
import { patterns } from "constants/regex";
import { snackbarTypes } from "constants/snackbar";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { roles, userTypes } from "constants/user";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    overflow: "hidden auto",
  },
  body: {
    // 0px 352px 0px 352px
    padding: "0rem 22rem 0rem 22rem",
  },
  bodyText: {
    marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        // 16px
        paddingBottom: "1rem",
      },
      "& > .MuiGrid-container > .MuiGrid-item:last-child": {
        // 48px
        paddingLeft: "3rem",
      },
    },
  },
  inputWidth: {
    width: "100%",
  },
  description: {
    marginBottom: "1rem",
  },
  divider: {
    margin: "1rem",
  },
  button: {
    maxWidth: "12rem",
  },
}));

export const NumberFormatCustom = React.forwardRef((props, ref) => {
  // TODO: Should we pass the inputRef from MUI through to the input component? If not, comment why.
  const { onChange, isNegative, format, inputRef, ...other } = props;
  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowNegative={false}
      format={format}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isNegative: PropTypes.bool.isRequired,
  format: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
};

NumberFormatCustom.defaultProps = {
  inputRef: null,
};

/**
 * Account page component to update the user's information.
 *
 * @returns {Function} The account page component
 */
const AccountPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { user } = useUserContext();

  const mounted = useIsMounted();

  const [disabledFields, setDisabledFields] = useState(false);

  const checkIfEmailExists = async (email) => {
    const emailValidationParams = {
      emailToCheckFor: email,
      userIdToCheckFor: user.id ? user.id : null,
    };

    if (!emailValidationParams.userIdToCheckFor) return false;

    if (accountDetails?.data?.email === email) return true;

    const { data } = await userService.checkEmail(emailValidationParams);
    return !data;
  };

  // define schema for form validation
  const AccountValidationSchema = yup
    .object({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      email: yup
        .string()
        .email("Email must be a valid email")
        .required("Email is required")
        .test(
          "email-taken-validation",
          "Email already taken.",
          checkIfEmailExists
        ),
      phoneNumber: yup
        .string()
        .matches(patterns.phone, { message: "Invalid phone format" })
        .required("Phone number is required"),
      phoneExtension: yup
        .string()
        .matches(patterns.number, { message: "Invalid phone extension" }),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phoneNumber: "",
    phoneExtension: "",
    title: "",
  };

  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: user.roles.includes(roles.municipalTreasurer)
      ? null
      : yupResolver(AccountValidationSchema),
  });

  const {
    data: accountDetails,
    isLoading: isUserDetailsLoading,
    refetch: refetchAccountDetails,
  } = useAccountDetails(user.id);

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    if (accountDetails?.data) {
      const formattedData = {
        ...accountDetails?.data,
        phoneNumber: `${accountDetails?.data.phoneNumber}`.replace(/\D/g, ""),
      };
      reset(formattedData);
    }
  }, [accountDetails]);

  useEffect(() => {
    setDisabledFields(user.roles.includes(roles.municipalTreasurer));
  }, []);

  const { formFields } = useFormManagement(
    formIds.municipalUserManagement,
    true
  );

  const handleSaveClick = async ({
    email,
    firstName,
    lastName,
    phoneNumber,
    phoneExtension,
    title,
    userName,
  }) => {
    const userData = {
      userName,
      firstName,
      lastName,
      title,
      email,
      phoneNumber,
      municipalityId: user.municipalityId,
      phoneExtension: phoneExtension !== undefined ? phoneExtension : "",
      userType: userTypes.communications,
      enabled: true,
    };

    userData.userId = user.id;
    const response = await userService.updateAccountDetails(user.id, userData);

    if (!mounted.current) {
      return;
    }

    if (response && response.status === 200) {
      showSnackbar("Account information updated.", snackbarTypes.success);
      refetchAccountDetails();
    } else {
      showSnackbar(
        "Something went wrong.  Please contact the system administrator.",
        snackbarTypes.error
      );
    }
  };

  const navigateBack = () => {
    history.goBack();
  };

  const {
    mutateAsync: sendPasswordResetEmail,
    isLoading: isCreatingPasswordResetEmail,
  } = useCreatePasswordReset(showSnackbar);

  const handlePasswordResetClick = () =>
    sendPasswordResetEmail(accountDetails.data.email);

  const { isValid, isSubmitting } = formState;
  const formActions = [
    {
      disabled:
        isSubmitting ||
        user.roles.includes(roles.municipalTreasurer) ||
        !isValid,
      testId: "accountEditSaveButton",
      label: "Save",
      onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
    },
    {
      testId: "accountEditCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: navigateBack,
    },
  ];

  return isUserDetailsLoading ? (
    <>
      <Skeleton width="50%" />
      <Skeleton width="50%" />
    </>
  ) : (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <Typography variant="h1">Account</Typography>
        </Grid>
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody22,
              globalClasses.editPageBodyText
            )}
            wrap="nowrap"
          >
            <Grid item>
              <Grid container spacing={0}>
                {user.roles.includes(roles.municipalTreasurer) && (
                  <Grid item xs={12}>
                    <Typography className={classes.description}>
                      Please contact{" "}
                      <Link href="mailto:ccbf@amo.on.ca">ccbf@amo.on.ca</Link>{" "}
                      to update the information below.
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FormInputWrapper
                    id="account-edit-firstName-wrapper"
                    testId="accountEditFirstNameWrapper"
                    tooltip={
                      !!formFields[formCodes.fieldFirstName]?.tooltipText
                    }
                    tooltipProps={{
                      title: formFields[formCodes.fieldFirstName]?.text,
                      text: formFields[formCodes.fieldFirstName]?.tooltipText,
                    }}
                    childrenFullWidth
                    hideFlagSpacing
                  >
                    <AmoTextField
                      control={control}
                      label={formFields[formCodes.fieldFirstName]?.text}
                      id="account-edit-firstName"
                      name="firstName"
                      required
                      testId="accountEditFirstName"
                      variant="outlined"
                      className={classes.inputWidth}
                      disabled={disabledFields}
                      helperText={
                        formFields[formCodes.fieldFirstName]?.helperText
                      }
                    />
                  </FormInputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <FormInputWrapper
                    id="account-edit-lastName-wrapper"
                    testId="accountEditLastNameWrapper"
                    tooltip={!!formFields[formCodes.fieldLastName]?.tooltipText}
                    tooltipProps={{
                      title: formFields[formCodes.fieldLastName]?.text,
                      text: formFields[formCodes.fieldLastName]?.tooltipText,
                    }}
                    childrenFullWidth
                    hideFlagSpacing
                  >
                    <AmoTextField
                      control={control}
                      label={formFields[formCodes.fieldLastName]?.text}
                      id="account-edit-lastName"
                      name="lastName"
                      testId="accountEditLastName"
                      className={classes.inputWidth}
                      variant="outlined"
                      required
                      disabled={disabledFields}
                      helperText={
                        formFields[formCodes.fieldLastName]?.helperText
                      }
                    />
                  </FormInputWrapper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {!user.roles.includes(roles.municipalTreasurer) && (
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={6}>
                    <FormInputWrapper
                      id="account-edit-username-wrapper"
                      testId="accountEditUsernameWrapper"
                      tooltip={
                        !!formFields[formCodes.fieldUsername]?.tooltipText
                      }
                      tooltipProps={{
                        title: formFields[formCodes.fieldUsername]?.text,
                        text: formFields[formCodes.fieldUsername]?.tooltipText,
                      }}
                      childrenFullWidth
                      hideFlagSpacing
                    >
                      <AmoTextField
                        control={control}
                        id="account-edit-username"
                        name="userName"
                        testId="accountEditUsername"
                        className={classes.inputWidth}
                        label={formFields[formCodes.fieldUsername]?.text}
                        disabled
                        variant="outlined"
                        helperText={
                          formFields[formCodes.fieldUsername]?.helperText
                        }
                      />
                    </FormInputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      data-testid="account-edit-change-password"
                      className={classes.button}
                      color="primary"
                      variant="contained"
                      size="medium"
                      disableElevation
                      disabled={disabledFields || isCreatingPasswordResetEmail}
                      onClick={handlePasswordResetClick}
                    >
                      <Typography align="center" variant="body2">
                        Change Password
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <FormInputWrapper
                    id="account-edit-title-wrapper"
                    testId="accountEditTitleWrapper"
                    tooltip={!!formFields[formCodes.fieldTitle]?.tooltipText}
                    tooltipProps={{
                      title: formFields[formCodes.fieldTitle]?.text,
                      text: formFields[formCodes.fieldTitle]?.tooltipText,
                    }}
                    childrenFullWidth
                    hideFlagSpacing
                  >
                    <AmoTextField
                      control={control}
                      id="account-edit-title"
                      name="title"
                      testId="accountEditTitle"
                      className={classes.inputWidth}
                      label={formFields[formCodes.fieldTitle]?.text}
                      variant="outlined"
                      disabled={disabledFields}
                      helperText={formFields[formCodes.fieldTitle]?.helperText}
                    />
                  </FormInputWrapper>
                </Grid>
                <Grid item />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <FormInputWrapper
                    id="account-edit-email-wrapper"
                    testId="accountEditEmailWrapper"
                    tooltip={!!formFields[formCodes.fieldEmail]?.tooltipText}
                    tooltipProps={{
                      title: formFields[formCodes.fieldEmail]?.text,
                      text: formFields[formCodes.fieldEmail]?.tooltipText,
                    }}
                    childrenFullWidth
                    hideFlagSpacing
                  >
                    <AmoTextField
                      control={control}
                      id="account-edit-email"
                      name="email"
                      testId="accountEditEmail"
                      className={classes.inputWidth}
                      label={formFields[formCodes.fieldEmail]?.text}
                      required
                      disabled={disabledFields}
                      helperText={formFields[formCodes.fieldEmail]?.helperText}
                    />
                  </FormInputWrapper>
                </Grid>
                <Grid item container xs={6}>
                  <Grid item xs={8}>
                    <FormInputWrapper
                      id="account-edit-phone-wrapper"
                      testId="accountEditPhoneWrapper"
                      tooltip={!!formFields[formCodes.fieldPhone]?.tooltipText}
                      tooltipProps={{
                        title: formFields[formCodes.fieldPhone]?.text,
                        text: formFields[formCodes.fieldPhone]?.tooltipText,
                      }}
                      childrenFullWidth
                      hideFlagSpacing
                    >
                      <AmoTextField
                        control={control}
                        id="account-edit-phone"
                        name="phoneNumber"
                        testId="accountEditPhone"
                        className={classes.inputWidth}
                        label={formFields[formCodes.fieldPhone]?.text}
                        required
                        disabled={disabledFields}
                        pattern={/[^0-9.]+/g}
                        helperText={
                          formFields[formCodes.fieldPhone]?.helperText
                        }
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            format: "###-###-####",
                            isNegative: false,
                          },
                          onBlur: () => {},
                        }}
                        // Fix for the label not floating properly when prefilling
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormInputWrapper>
                  </Grid>
                  <Grid item xs={4}>
                    <FormInputWrapper
                      id="account-edit-phone-extension-wrapper"
                      testId="accountEditPhoneExtensionWrapper"
                      tooltip={
                        !!formFields[formCodes.fieldPhoneExtension]?.tooltipText
                      }
                      tooltipProps={{
                        title: formFields[formCodes.fieldPhoneExtension]?.text,
                        text:
                          formFields[formCodes.fieldPhoneExtension]
                            ?.tooltipText,
                      }}
                      childrenFullWidth
                      hideFlagSpacing
                    >
                      <AmoTextField
                        control={control}
                        id="account-edit-phone-extension"
                        name="phoneExtension"
                        testId="accountEditPhoneExtension"
                        pattern={/[^0-9.]+/g}
                        className={classes.inputWidth}
                        disabled={disabledFields}
                        label={formFields[formCodes.fieldPhoneExtension]?.text}
                        helperText={
                          formFields[formCodes.fieldPhoneExtension]?.helperText
                        }
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            format: "##########",
                            isNegative: false,
                          },
                          onBlur: () => {},
                        }}
                        // Fix for the label not floating properly when prefilling
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormInputWrapper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Actions */}
        <AmoFormActions actions={formActions} hideLateralBorders />
      </Grid>
    </>
  );
};

export default AccountPage;
