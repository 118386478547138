import React, { useState, useRef, useEffect } from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Typography,
  TextField,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
import parse from "html-react-parser";

import ListPageWrapper from "components/ListPageWrapper";
import AmoTableWithFilters from "components/table/AmoTableWithFilters";
import FileUploadList from "components/FileUploadList";

import { useGlobalStyles } from "hooks/globalStylesHook";
import { useConditionalQueryParams } from "hooks/conditionalQueryParamsHook";
import { useIsMounted } from "hooks/useIsMounted";

import { useYearContext } from "contexts/YearContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { useMunicipalContext } from "contexts/MunicipalContext";
import colors from "constants/colors";
import { snackbarTypes } from "constants/snackbar";
import { dateFormats } from "constants/dateFormats";
import { routes } from "constants/routes";
import { questionnaireTypes } from "constants/questionnaireTypes";
import { displayTypes, tableDefaultActions } from "constants/amoTableConstants";

import { exportToExcel } from "utils/export";
import { downloadFile } from "utils/pdf";

import { filesService } from "api/services/fileService";
import { useQuestionnaireReviews } from "./questionnaireReviewsHooks";
import { reporting } from "constants/reporting";
import { errorMessages } from "constants/errorMessages";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: "3rem",
    height: "3rem",
    margin: "0 0",
    padding: "0 0",
  },
  dividerContainer: {
    paddingBottom: "0.688rem !important",
  },
  buttonsBar: {
    paddingBottom: "1.438rem !important",
  },
  outlinedIconButtonDisabled: {
    border: `0.063rem solid rgba(0, 0, 0, 0.12)`,
    color: "#939393",
    backgroundColor: "white !important",
  },
  loadingProgress: {
    color: colors.grey.light,
  },
  icon: {
    width: "3rem",
    height: "3rem",
  },
}));

/**
 * The Questionnaire Review List Page.
 *
 * @returns {React.Component} Questionnaire Review List Page component.
 */
const QuestionnaireReviewListPage = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { contextYear } = useYearContext();
  const history = useHistory();
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const { getMunicipalitiesNames } = useMunicipalContext();

  const mounted = useIsMounted();

  const isPriorToFirstQuestionnaire =
    contextYear < reporting.firstYearOfQuestionnaire;

  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [focusedItem, setFocusedItem] = useState({});
  const rowRefs = useRef({});
  const filteredData = useRef([]);

  const [selectedType, setSelectedType] = useConditionalQueryParams(
    "type",
    "string",
    questionnaireTypes.assetMgmt,
    true
  );

  const [ellipsisAnchorEl, setEllipsisAnchorEl] = useState(null);
  const ellipsisOnClick = (event) => setEllipsisAnchorEl(event.currentTarget);
  const ellipsisOnClose = () => setEllipsisAnchorEl(null);

  const {
    data: questionnaires,
    isFetching: questionnairesFetching,
  } = useQuestionnaireReviews(
    contextYear,
    selectedType,
    isPriorToFirstQuestionnaire
  );

  const getTableColumns = () => [
    {
      fieldKey: "name",
      label: "Municipality",
      minWidth: "15.625rem",
      customRender: ({ municipalityId, name }) => (
        <Link
          to={`${routes.reviews.questionnaires.form
            .replace(":municipalityId", municipalityId)
            .replace(":year", contextYear)
            .replace(":type", selectedType)}${location.search}`}
          ref={(element) => {
            rowRefs.current[municipalityId] = element;
          }}
          onFocus={() => setFocusedItem({ municipalityId })}
          className={globalClasses.tableActionCell}
        >
          {name}
        </Link>
      ),
      isCustom: true,
      dropdownOptions: getMunicipalitiesNames(),
      displayType: displayTypes.dropdown,
    },
    ...(questionnaires?.questions?.map(({ id, text }) => ({
      fieldKey: `${id}`,
      label: parse(text),
      minWidth: "12.5rem",
      maxWidth: "15rem",
      customRender: ({ [id]: value }) => (
        <Typography variant="body1" noWrap title={value}>
          {value}
        </Typography>
      ),
      customExport: ({ [id]: value }) => value,
      noHeaderWrap: true,
      isCustom: true,
    })) ?? []),

    ...(selectedType === questionnaireTypes.assetMgmt
      ? [
          {
            fieldKey: "ampFileName",
            label: "Asset Management Plan",
            minWidth: "17rem",
            customRender: ({ ampFileName, ampFileId }) =>
              ampFileName ? (
                <FileUploadList
                  readonly
                  noWrap
                  transparent
                  initialValue={[
                    {
                      id: ampFileId,
                      name: ampFileName,
                    },
                  ]}
                />
              ) : (
                ""
              ),
            isCustom: true,
          },
          {
            fieldKey: "ampUploadDate",
            label: "AMP Upload Date",
            minWidth: "15.625rem",
            customRender: ({ ampUploadDate }) =>
              ampUploadDate
                ? DateTime.fromISO(ampUploadDate).toFormat(dateFormats.isoDate)
                : "",
            isCustom: true,
          },
        ]
      : []),
  ];

  const setFiltersForChanges = () => {
    const queryParam = qs.parse(location.search);
    if (queryParam.filters !== undefined) {
      const newQuery = {
        type: questionnaireTypes.assetMgmt,
        order: "asc",
        orderBy: "id",
        page: 1,
        // Keep municipality filter
        filter_name: queryParam.filter_name,
      };

      history.push({
        pathname: location.pathname,
        search: qs.stringify(newQuery),
      });
    }
  };

  useEffect(() => {
    setFiltersForChanges();
  }, [contextYear]);

  const downloadExcel = () => {
    try {
      exportToExcel(
        filteredData.current,
        getTableColumns(),
        `Questionnaire Review - ${contextYear} - ${selectedType}`,
        {
          sheetName: "Reviews",
        }
      );
    } catch (error) {
      showSnackbar(errorMessages.creatingSpreadsheet, snackbarTypes.error);
    }
  };

  const downloadAssetManagementPlans = async () => {
    setIsLoadingPlans(true);

    try {
      const response = await filesService.getQuestionnairesAssetManagementPlansURL(
        contextYear
      );

      if (!mounted.current) {
        return;
      }

      const dateNow = DateTime.now().toFormat("yyyyMMddHHmmss");
      const fileName = `Questionnaire Review - ${contextYear} - ${selectedType} - AMPs_${dateNow}.zip`;
      downloadFile(response.data, fileName);
    } catch (error) {
      if (!mounted.current) {
        return;
      }

      if (error?.response?.status === 404) {
        showSnackbar("No AMP file found", snackbarTypes.info);
      } else {
        showSnackbar(
          "An error occured while downloading the AMPs",
          snackbarTypes.error
        );
      }
    } finally {
      if (mounted.current) {
        setIsLoadingPlans(false);
      }
    }
  };

  const handleItemClick = (item) => {
    if (selectedType === "Risk Management") {
      history.push(
        `${`/reviews/questionnaires/${item.municipalityId}/${contextYear}/Risk%20Management`}${
          location.search
        }`
      );
    } else {
      history.push(
        `${`/reviews/questionnaires/${item.municipalityId}/${contextYear}/Asset%20Management`}${
          location.search
        }`
      );
    }
  };

  const typeDropdown = (
    <TextField
      variant="outlined"
      value={selectedType}
      onChange={(event) => {
        setSelectedType(event.target.value);
        setFiltersForChanges();
      }}
      select
    >
      <MenuItem value={questionnaireTypes.assetMgmt}>
        {questionnaireTypes.assetMgmt}
      </MenuItem>
      <MenuItem value={questionnaireTypes.riskMgmt}>
        {questionnaireTypes.riskMgmt}
      </MenuItem>
    </TextField>
  );

  const warningText = (
    <Typography variant="body1" color="primary">
      Questionnaires were not collected prior to 2014.
    </Typography>
  );

  const tableActions = {
    titleComponent: isPriorToFirstQuestionnaire ? warningText : typeDropdown,
    actions: [tableDefaultActions.search, tableDefaultActions.export],
    actionsCustomConfigs: {
      [tableDefaultActions.export]: {
        onClick:
          selectedType === questionnaireTypes.assetMgmt
            ? ellipsisOnClick
            : downloadExcel,
        disabled: isLoadingPlans || isPriorToFirstQuestionnaire,
      },
      [tableDefaultActions.search]: {
        disabled: isLoadingPlans || isPriorToFirstQuestionnaire,
      },
    },
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="h1">Questionnaire Review</Typography>
        </Grid>
        <Grid item className={classes.dividerContainer}>
          <Divider />
        </Grid>
        <Grid item>
          <AmoTableWithFilters
            items={questionnaires?.list}
            columns={getTableColumns()}
            defaultOrderBy="name"
            defaultOrder="asc"
            enableItemClick
            onItemClick={handleItemClick}
            onFilteredDataChanged={(value) => {
              filteredData.current = value;
            }}
            debouncingTime={450}
            onScroll={(item) => {
              rowRefs.current?.[item.municipalityId]?.focus();
            }}
            focusedItem={focusedItem}
            actionsProps={tableActions}
            isLoading={questionnairesFetching}
            uniqueKeyGenerationFields={["municipalityId"]}
            hideTableContent={isPriorToFirstQuestionnaire}
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={ellipsisAnchorEl}
        open={!!ellipsisAnchorEl}
        onClose={ellipsisOnClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            ellipsisOnClose();
            downloadExcel();
          }}
        >
          <Typography variant="body1">Download Results</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            ellipsisOnClose();
            downloadAssetManagementPlans();
          }}
        >
          <Typography variant="body1">
            Download Asset Management Plans
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            ellipsisOnClose();
            downloadExcel();
            downloadAssetManagementPlans();
          }}
        >
          <Typography variant="body1">
            Download Results + Download Asset Management Plans
          </Typography>
        </MenuItem>
      </Menu>
    </ListPageWrapper>
  );
};

export default QuestionnaireReviewListPage;
