import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

import AmoModal from "components/AmoModal";
import AmoTable from "components/table/AmoTable";

import { getSortingComparator, stableSort } from "utils/data";

/**
 * A modal component to display the incrementality monitoring upload results
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.open - controls visibility of the modal
 * @param {object[]} props.uploadResultData - object containing information of the incrementality monitoring upload result
 * @param {number} props.uploadResultData.municipalityId - municipality id
 * @param {string} props.uploadResultData.status - incrementality monitoring upload result status
 * @param {boolean} props.isLoading - whether the modal upload data is loading or not
 * @param {Function} props.onClose - function called when user clicks on the 'Continue' button or close icon (params: none)
 *
 * @returns {React.Component} The modal component
 */
const IncrementalityMonitoringUploadModal = (props) => {
  const { open, onClose, uploadResultData, isLoading } = props;

  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("status");

  const tableColumns = [
    {
      fieldKey: "municipalityId",
      label: "Municipality ID",
      widthPerc: 15,
    },
    {
      fieldKey: "status",
      label: "Update Result",
      color: ({ status }) =>
        status?.startsWith("Successfully") ? "initial" : "secondary",
      variant: ({ status }) =>
        status?.startsWith("Successfully") ? "body1" : "body2",
      widthPerc: 40,
    },
  ];

  const getOrderBy = (newOrderBy) => newOrderBy;

  // this effect sort the data when changed
  useEffect(() => {
    const mappedData = uploadResultData ?? [];

    const sortedResult = stableSort(
      mappedData,
      getSortingComparator(order, getOrderBy(orderBy))
    );

    setTableData(sortedResult);
  }, [uploadResultData]);

  const handleRequestSort = (fieldKey, newOrder) => {
    const newOrderBy = getOrderBy(fieldKey);
    setOrder(newOrder);
    setOrderBy(newOrderBy);

    const sorted = stableSort(
      tableData,
      getSortingComparator(newOrder, newOrderBy)
    );
    setTableData(sorted);
  };

  const closeModal = async () => {
    onClose();
  };

  const rowsUpdated = tableData.filter(({ status }) =>
    status.startsWith("Successfully")
  ).length;
  const rowsWithErrors = tableData.filter(({ status }) =>
    status.startsWith("Failed")
  ).length;

  return (
    <AmoModal
      data-testid="projectUploadResultModal"
      open={open}
      variant="default"
      title="File Upload Results"
      width="55rem"
      showButton
      closePopover={closeModal}
      buttonsLeftSpacer={8}
      buttons={[
        {
          key: "continue",
          label: "Continue",
          color: "primary",
          variant: "contained",
          onClick: closeModal,
        },
      ]}
    >
      {!isLoading && (
        <>
          <Typography>{rowsUpdated} rows updated successfully</Typography>
          <Typography>{rowsWithErrors} rows with errors</Typography>
        </>
      )}
      <AmoTable
        id="incrementality-upload-table"
        testId="incrementalityUploadTable"
        items={tableData}
        columns={tableColumns}
        defaultOrderBy="status"
        onRequestSort={handleRequestSort}
        shouldUseQueryString
        disableFilters
        isLoading={isLoading}
      />
    </AmoModal>
  );
};

IncrementalityMonitoringUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadResultData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool,
};

IncrementalityMonitoringUploadModal.defaultProps = {
  isLoading: false,
};

export default IncrementalityMonitoringUploadModal;
