import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";

/**
 * Produces a list of notification recipients.
 *
 * @param {object} props
 * @param {string} props.title The title of the notification.
 * @param {Array} props.recipients A list of recipients.
 * @param {Function} props.onChange The parent component's change handler.
 *
 * @returns {React.Component} Notification Recipients component.
 */
const NotificationRecipients = (props) => {
  const { title, recipients, onChange } = props;
  return (
    <Grid container direction="column" spacing={0} wrap="nowrap">
      <Grid item>
        <Typography variant="body2">{title}</Typography>
      </Grid>
      {recipients.map((recipient) => (
        <Grid key={recipient.id} item>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label={recipient.name}
            checked={recipient.selected}
            onChange={(event) =>
              onChange({ ...recipient, selected: event.target.checked })
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

NotificationRecipients.propTypes = {
  title: PropTypes.string.isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func,
};

NotificationRecipients.defaultProps = {
  recipients: [],
  onChange: () => {},
};

export default NotificationRecipients;
