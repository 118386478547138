export const errorMessages = {
  generic: "Something went wrong",
  genericAndContactAdmin:
    "Something went wrong. Please contact the system administrator.",
  genericAndTryLater: "Something went wrong, please try again.",
  dataLoad: "There was an error while trying to load the data",
  creatingSpreadsheet: "An error occurred while creating the spreadsheet",
  municipalProjectRequestDeletion:
    "An error occurred while sending the request",
  projectDeletion: "An error occurred while deleting the project",
  amoProjectDenyDeletion:
    "An error occurred while denying the project deletion",
};
