import { useQuery } from "react-query";
import { projectService } from "api/services/projectService";
import { oneDayInMs } from "constants/caching";

// Query Keys
import { projectKeys } from "./projectQueryKeys";

/**
 * Store server's state of all project categories
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectCategories = () => {
  const selectProjectCategories = (data) => {
    const projectCategories = data?.data;
    const reducedData = projectCategories.reduce(
      (map, obj) => ({
        ...map,
        [obj.id]: obj,
      }),
      {}
    );
    return {
      ...data,
      data: reducedData,
    };
  };

  return useQuery(
    projectKeys.projectCategories(),
    () => projectService.getAllCategories(),
    {
      select: selectProjectCategories,
      staleTime: oneDayInMs,
      refetchOnMount: true,
    }
  );
};

/**
 * Store server's state of project category form
 *
 * @param {string} projectId The id of the project being fetched
 *
 * @returns {object} The query object return by the useQuery hook
 */
export const useProjectCategoryData = (projectId) => {
  const selectProjectCategoryData = (data) => {
    const projectCategoryData = data?.data;
    const reducedFlags =
      projectCategoryData?.flags?.reduce(
        (map, obj) => ({
          ...map,
          [obj.fieldCode]: {
            id: obj.id,
            ownerName: obj.updatedByName,
            ownerEmail: obj.updatedByEmail,
            comment: obj.message,
          },
        }),
        {}
      ) || {};
    return {
      ...data,
      data: {
        ...projectCategoryData,
        categoryId: projectCategoryData?.categoryId ?? "",
        subcategoryId: projectCategoryData?.subcategoryId ?? "",
        changeRequestReason: projectCategoryData?.changeRequestReason ?? "",
        flags: reducedFlags,
      },
    };
  };

  return useQuery(
    projectKeys.projectCategory(projectId),
    () => projectService.getCategoryData(projectId),
    {
      select: selectProjectCategoryData,
      enabled: !!projectId && projectId !== "new",
      refetchOnWindowFocus: false,
    }
  );
};
