import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import HelpSection from "./HelpSection";
import HelpPageNavBar from "./HelpPageNavBar";
import { Switch, Route, Redirect } from "react-router-dom";
import FaqsSection from "./FaqsSection";
import ListPageWrapper from "components/ListPageWrapper";
import { routes } from "constants/routes";

const useStyles = makeStyles(() => ({
  navBar: {
    position: ({ breaking }) => (breaking ? "absolute" : "relative"),
    top: 0,
    left: 0,
    height: "375px",
  },
  mainContent: {
    overflow: "hidden",
    position: "relative",
    // 96px : 24px
    marginLeft: ({ breaking }) => (breaking ? "6rem" : "1.5rem"),
    flexGrow: 1,
    flexBasis: 0,
  },
}));

/**
 * Renders the Municipal Help layout page that contains a menu and a section for both Help and FAQ content.
 *
 * @returns {React.Component} The Municipal Help Layout page.
 */
const HelpPage = () => {
  const classes = useStyles();
  return (
    <ListPageWrapper>
      <Grid container direction="row" wrap="nowrap" spacing={2}>
        <Grid item>
          <HelpPageNavBar />
        </Grid>
        <Grid item classes={{ root: classes.mainContent }}>
          <Switch>
            <Route exact path={[routes.help.faqs, routes.help.faqsByPermalink]}>
              <FaqsSection />
            </Route>
            <Route exact path={routes.help.page}>
              <HelpSection />
            </Route>
            <Route exact path={routes.help.base}>
              <Redirect to={routes.help.faqs} />
              {/* if we don't redirect, FAQs item doesn't show as selected  */}
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default HelpPage;
