import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  TextField,
  Button,
  Popper,
  Grid,
  Typography,
  Icon,
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import colors from "constants/colors";
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/hooks";
import { ReactComponent as SearchIcon } from "img/icons/search_icon.svg";

const useStyles = makeStyles((theme) => ({
  filterButton: {
    height: "3.5rem",
    maxWidth: "12.5rem",
    "& .MuiButton-label": {
      fontWeight: "normal",
      color: "rgba(0, 0, 0, 0.5)",
      justifyContent: "space-between",
    },
  },
  filterButtonText: {
    fontSize: "0.875rem",
  },
  filterButtonActive: {
    border: `1px solid ${colors.green.main}`,
  },
  rootContainer: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.green.main}`,
    borderRadius: "5px",
    marginTop: "0.75rem",
    width: "18.5rem",
  },
  contentContainer: {
    padding: "1rem",
  },
  optionsContainer: {
    maxHeight: "19rem",
    maxWidth: "16.5rem",
    overflowY: "auto",
    padding: 0,
  },
  actionContainer: {
    borderTop: `1px solid ${colors.grey.medium}`,
    padding: "0.5rem 0rem 0.75rem 0.75rem",
  },
  actionButton: {
    height: "3.125rem",
    width: "6.25rem",
    marginRight: "0.5rem",
  },
  menuItem: {
    padding: 0,
    fontFamily: "Roboto",
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    fontWeight: "normal",
  },
  menuItemText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  popper: {
    // Needs to be this value to appear in front of the header (1100)
    // and behind dialogs (1300)
    zIndex: 1150,
  },
}));

/**
 * A table filter component for filtering the value of a column
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.fieldKey - key of the item property that contain this filter value [required]
 * @param {object} props.options - object containing props for the dropdown options where the keys should be all available values [required]
 * @param {string} props.tableId - id of the table component
 * @param {string} props.label - text to display in the filter input label
 * @param {object} props.value - options filter array with the filtered values
 * @param {Function} props.onFilterChange - function called when user types on a filter input (params: { @see props.fieldKey }, { filter value })
 * @param {string} props.buttonWidth - text or number to configure the size of the numeric filter button width
 *
 * @returns {React.Component} - The table filter component
 */
const AmoTableFilterOptions = (props) => {
  const {
    fieldKey,
    options,
    tableId,
    label,
    value,
    onFilterChange,
    buttonWidth,
  } = props;

  const baseId = `${tableId ?? "amo-table"}-filter-options-${fieldKey}`;

  const classes = useStyles();
  const [internalValue, setInternalValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const popupState = usePopupState({
    variant: "popper",
    popupId: `${baseId}-popper`,
  });

  const handleFilterChange = (newValue) => {
    onFilterChange(newValue);
  };

  const getInternalValueFromValue = () => {
    const filterKeys = Object.keys(value ?? {});
    // If the filter value object is empty consider all selected
    if (!filterKeys.length) {
      return Object.keys(options).reduce(
        (obj, key) => ({
          ...obj,
          [key]: true,
        }),
        {}
      );
    }
    return value;
  };

  useEffect(() => {
    setInternalValue(getInternalValueFromValue());
  }, [value, options]);

  const selectAll = useMemo(() => {
    const selectedFilters = Object.keys(internalValue).filter(
      (innerKey) => !!internalValue[innerKey]
    );

    return Object.keys(options).length === selectedFilters.length;
  }, [options, internalValue]);

  const buttonLabel = useMemo(() => {
    if (selectAll) {
      return "All";
    }

    const selectedFilters = Object.keys(internalValue).filter(
      (innerKey) => !!internalValue[innerKey]
    );

    if (!selectedFilters.length) {
      return label;
    }

    if (selectedFilters.length === 1) {
      return options[selectedFilters[0]]?.trim() || "None";
    }

    return `${selectedFilters.length} selected`;
  }, [internalValue, selectAll, options]);

  const filteredOptions = useMemo(
    () =>
      Object.entries(options).filter(
        ([_, name]) =>
          !!name?.toLowerCase()?.includes(searchValue?.toLowerCase())
      ),
    [options, searchValue]
  );

  return (
    <>
      <Button
        variant="outlined"
        className={clsx(
          "MuiOutlinedInput-root",
          classes.filterButton,
          popupState.isOpen ? classes.filterButtonActive : ""
        )}
        endIcon={
          <Icon className="material-icons-outlined">arrow_drop_down</Icon>
        }
        style={{ width: buttonWidth ?? "10rem", borderRadius: "3px" }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bindToggle(popupState)}
      >
        <Typography variant="body1" noWrap className={classes.filterButtonText}>
          {buttonLabel}
        </Typography>
      </Button>
      <Popper
        placement="bottom-start"
        className={classes.popper}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bindPopper(popupState)}
      >
        <Grid
          container
          direction="column"
          spacing={0}
          className={classes.rootContainer}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              spacing={0}
              className={classes.contentContainer}
            >
              <Grid item>
                <TextField
                  id={`${baseId}-search-input`}
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  label="Search"
                  value={searchValue}
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon fill={colors.grey.dark} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <List className={classes.optionsContainer}>
                  <ListItem
                    key={`${baseId}-option-all`}
                    className={classes.menuItem}
                    role={undefined}
                    dense
                    button
                    onClick={() => {
                      let reduceFunction = (obj, key) => ({
                        ...obj,
                        [key]: true,
                      });

                      if (selectAll) {
                        reduceFunction = (obj, key) => ({
                          ...obj,
                          [key]: false,
                        });
                      }

                      const newInternalValue = Object.keys(options).reduce(
                        reduceFunction,
                        {}
                      );
                      setInternalValue(newInternalValue);
                    }}
                  >
                    <ListItemIcon style={{ minWidth: "auto" }}>
                      <Checkbox
                        id={`${baseId}-option-all-check`}
                        style={{ paddingLeft: 0 }}
                        color="primary"
                        checked={selectAll}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": `${baseId}-option-all-label`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={`${baseId}-option-all-label`}
                      primary="All"
                      disableTypography
                      className={classes.menuItemText}
                    />
                  </ListItem>
                  {filteredOptions.map(([key, name]) => (
                    <ListItem
                      key={`${baseId}-option-${key}`}
                      className={classes.menuItem}
                      role={undefined}
                      dense
                      button
                      onClick={() => {
                        setInternalValue((prevInternalValue) => {
                          const newCheckValue = !prevInternalValue?.[key];
                          const newInternalValue = {
                            ...prevInternalValue,
                            [key]: newCheckValue,
                          };
                          return newInternalValue;
                        });
                      }}
                    >
                      <ListItemIcon style={{ minWidth: "auto" }}>
                        <Checkbox
                          id={`${baseId}-option-${key}-check`}
                          style={{ paddingLeft: 0 }}
                          color="primary"
                          checked={internalValue?.[key] ?? false}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": `${baseId}-option-${key}-label`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`${baseId}-option-${key}-label`}
                        primary={name?.trim() || "None"}
                        disableTypography
                        className={classes.menuItemText}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={0}
              justifyContent="flex-end"
              className={classes.actionContainer}
            >
              <Grid item>
                <Button
                  color="primary"
                  size="medium"
                  variant="outlined"
                  className={classes.actionButton}
                  onClick={() => {
                    setInternalValue(getInternalValueFromValue());
                    setSearchValue("");
                    popupState.close();
                  }}
                >
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={classes.actionButton}
                  disabled={!Object.keys(internalValue).length}
                  onClick={() => {
                    const selectedValues = Object.keys(internalValue).reduce(
                      (obj, key) => {
                        if (internalValue[key]) {
                          return {
                            ...obj,
                            [key]: internalValue[key],
                          };
                        }
                        return obj;
                      },
                      {}
                    );
                    handleFilterChange(!selectAll ? selectedValues : null);
                    setSearchValue("");
                    popupState.close();
                  }}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popper>
    </>
  );
};

// set the prop-types for this component
AmoTableFilterOptions.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  options: PropTypes.shape().isRequired,
  tableId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.shape(),
  onFilterChange: PropTypes.func,
  buttonWidth: PropTypes.string,
};

AmoTableFilterOptions.defaultProps = {
  tableId: undefined,
  label: undefined,
  value: [],
  onFilterChange: () => {},
  buttonWidth: undefined,
};

export default AmoTableFilterOptions;
