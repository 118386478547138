import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { routes } from "constants/routes";
import RichTextEditor from "../../../components/RichTextEditor";
import { useNotificationForm } from "./NotificationForm";
import { useSnackbar } from "contexts/SnackbarContext";
import { errorMessages } from "constants/errorMessages";
import { snackbarTypes } from "constants/snackbar";
import AmoPageHeader from "components/AmoPageHeader";
import clsx from "clsx";
import AmoFormActions from "components/AmoFormActions";
import NotificationRecipients from "./NotificationRecipients";
import NotificationPlaceholders from "./NotificationPlaceholders";
import { notificationsService } from "api/services/notificationService";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";

/**
 * Notification Edit Page.
 *
 * @returns {React.Component} Notification Edit Page component.
 */
const NotificationEditPage = () => {
  const globalClasses = useGlobalStyles();
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { notificationId } = useParams();
  const mounted = useIsMounted();

  const [isSaving, setIsSaving] = useState(false);

  const [notificationData, setNotificationData] = useState({
    name: "",
    purpose: "",
    placeholders: [],
  });

  const {
    handleInputValue,
    markAsTouched,
    markAllAsTouched,
    formIsValid,
    prefillForm,
    form,
    errors,
    touched,
  } = useNotificationForm();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await notificationsService.getById(notificationId);

        if (!mounted.current) {
          return;
        }

        prefillForm(data);
        setNotificationData({
          name: data.name,
          purpose: data.purpose,
          placeholders: data.placeholders,
        });
      } catch {
        if (!mounted.current) {
          return;
        }

        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
    };
    getData();
  }, []);

  const submit = async (event) => {
    event.preventDefault();
    if (!formIsValid()) {
      markAllAsTouched();
    } else {
      setIsSaving(true);
      try {
        await notificationsService.update(notificationId, form);

        if (!mounted.current) {
          return;
        }

        showSnackbar("Notification updated successfully.");
        goBackToList();
      } catch {
        if (!mounted.current) {
          return;
        }

        showSnackbar(errorMessages.generic, snackbarTypes.error);
      } finally {
        if (mounted.current) {
          setIsSaving(false);
        }
      }
    }
  };

  const goBackToList = () =>
    history.push(
      `${routes.contentManagement.notifications.list}${location.search}`
    );

  const formActions = [
    {
      testId: "notificationSubmitButton",
      label: "Save",
      disabled: !formIsValid() || isSaving,
      onClick: submit,
    },
    {
      testId: "notificationCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: goBackToList,
    },
  ];

  return (
    <Grid container direction="column" spacing={0} wrap="nowrap">
      {/* Header */}
      <Grid item>
        <AmoPageHeader
          title="Edit Notification"
          backLinkText="Back to Notification List"
          backLinkTo={routes.contentManagement.notifications.list}
        />
      </Grid>

      {/* Body */}
      <Grid item xs className={globalClasses.editPageBodyContainer}>
        <Grid
          container
          direction="column"
          spacing={0}
          className={clsx(
            globalClasses.editPageBody22,
            globalClasses.editPageBodyTextNoLeftPadding
          )}
          wrap="nowrap"
        >
          <Grid item>
            <Typography>
              <b>Notification:</b> {notificationData.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>Purpose:</b> {notificationData.purpose}
            </Typography>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <NotificationRecipients
              title="To"
              recipients={form.to}
              onChange={(changedRecipient) =>
                handleInputValue(
                  "to",
                  form.to.map((recipient) =>
                    recipient.id === changedRecipient.id
                      ? changedRecipient
                      : recipient
                  )
                )
              }
            />
          </Grid>

          <Grid item>
            <NotificationRecipients
              title="Cc"
              recipients={form.cc}
              onChange={(changedRecipient) =>
                handleInputValue(
                  "cc",
                  form.cc.map((recipient) =>
                    recipient.id === changedRecipient.id
                      ? changedRecipient
                      : recipient
                  )
                )
              }
            />
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <NotificationPlaceholders
              title="The following keys can be used in the subject or body of this email:"
              placeholders={notificationData.placeholders}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label="Subject"
              focused={false}
              value={form.subject}
              onBlur={() => markAsTouched("subject")}
              onChange={(event) =>
                handleInputValue("subject", event.target.value)
              }
              error={touched.subject && !!errors.subject}
              helperText={touched.subject && errors.subject}
            />
          </Grid>

          <Grid item>
            <RichTextEditor
              placeholder="Write your notification here"
              value={form.body}
              onBlur={() => markAsTouched("body")}
              onChange={(value, isValid) =>
                handleInputValue("body", value, !isValid)
              }
              error={touched.body && !!errors.body}
              helperText={touched.body && errors.body ? errors.body : undefined}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              control={<Switch color="primary" checked={form.enabled} />}
              label="Enabled"
              onChange={() => handleInputValue("enabled", !form.enabled)}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Actions */}
      <AmoFormActions actions={formActions} hideLateralBorders />
    </Grid>
  );
};

export default NotificationEditPage;
