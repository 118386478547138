import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  MenuItem,
  Divider,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  useForm,
  useWatch,
  useFieldArray,
  FormProvider,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AmoModal from "components/AmoModal";
import clsx from "clsx";
import AmoTextField from "components/inputs/AmoTextField";
import AmoPageHeader from "components/AmoPageHeader";
import AmoFormActions from "components/AmoFormActions";
import { routes } from "constants/routes";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import AmoSwitch from "components/inputs/AmoSwitch";
import { questionnaireService } from "api/services/questionnaireService";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";
import AmoRichTextEditor from "components/inputs/AmoRichTextEditor";
import { errorMessages } from "constants/errorMessages";
import { DateTime } from "luxon";
import QuestionnaireQuestions, {
  defaultQuestion,
} from "./QuestionnaireQuestions";
import { questionnaireAnswerTypes } from "constants/questionnaireAnswerTypes";
import { questionnaireTypes } from "constants/questionnaireTypes";
import { defaultQuestionOptions } from "./QuestionnaireQuestionOptions";
import { defaultQuestionCondition } from "./QuestionnaireQuestionConditions";
import { reporting } from "constants/reporting";
import { range } from "utils/number";
import { useQuestionnaire } from "./questionnaireManagementHooks";

/**
 * This page component functions as both the "new" and "edit" page for questionnaires.
 *
 * @returns {Function} The questionnaires edit page component
 */
const QuestionnairesEditPage = () => {
  const history = useHistory();
  const globalClasses = useGlobalStyles();
  const { showSnackbar } = useSnackbar();
  const { questionnaireId: itemIdFromParams } = useParams();
  const { state: locationState, search } = useLocation();

  const mounted = useIsMounted();

  const itemIsNew = itemIdFromParams === "new";
  const { cloneId } = locationState ?? {};

  const currentYear = DateTime.now().year;

  const [yearRange, setYearRange] = useState(
    range(currentYear - 1, currentYear + 1).reverse()
  );
  const [hasLimitedEdition, setHasLimitedEdition] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [originalPublishedValue, setOriginalPublishedValue] = useState(null);
  const [isCheckingConflict, setIsCheckingConflict] = useState(false);

  const hasTypeYearConflict = useRef({});
  const conflictCheckControl = useRef({});

  // Used in Yup Schema to check if the options fields need to be required
  const checkIfOptionTextRequired = (answerTypeId) =>
    hasLimitedEdition &&
    (answerTypeId ===
      questionnaireAnswerTypes.singleSelectMultipleChoice.value ||
      answerTypeId ===
        questionnaireAnswerTypes.multiSelectMultipleChoice.value);

  const checkQuestionnaireConflicts = async (
    triggerFieldsValidation = false
  ) => {
    const id = getValues("id");
    const type = getValues("type");
    const year = getValues("year");

    const controlKey = `${type}${year}`;

    if (!type || !year || conflictCheckControl.current[controlKey]) return;

    setIsCheckingConflict(true);
    conflictCheckControl.current[controlKey] = true;

    try {
      const {
        data: hasConflicts,
      } = await questionnaireService.existsByYearAndType(year, type, id);

      if (!mounted.current) {
        return;
      }

      hasTypeYearConflict.current[controlKey] = hasConflicts;
      if (triggerFieldsValidation) triggerFormValidation(["year", "type"]);
    } catch (ex) {
      if (!mounted.current) {
        return;
      }

      showSnackbar("Couldn't validate type and year", snackbarTypes.error);
    } finally {
      if (mounted.current) {
        conflictCheckControl.current[controlKey] = false;
        setIsCheckingConflict(false);
      }
    }
  };

  const testQuestionnaireConflicts = (_, context) => {
    const { parent } = context;
    const { year, type } = parent;

    const controlKey = `${type}${year}`;
    if (!(controlKey in hasTypeYearConflict.current)) return false;

    const hasConflict = hasTypeYearConflict.current[controlKey];
    return !hasConflict;
  };

  const transformEmptyNumber = (value, originalValue) => {
    if (isNaN(value) && originalValue === "") {
      return null;
    }

    return value;
  };

  const QuestionnaireEditSchema = yup
    .object({
      id: yup.number().notRequired(),
      type: yup
        .string()
        .oneOf(Object.values(questionnaireTypes))
        .required("Questionnaire type is required")
        .test(
          "questionnaire-type-validation",
          "A questionnaire with selected type and year already exists",
          testQuestionnaireConflicts
        ),
      year: yup
        .number()
        .positive()
        .integer()
        .required("Reporting year is required")
        .test(
          "questionnaire-year-validation",
          "A questionnaire with selected type and year already exists",
          testQuestionnaireConflicts
        ),
      title: yup.string().required("Title is required"),
      instructionText: yup
        .string()
        .test(
          "len",
          "Instruction text must 2000 characters or less",
          (val) => val.length <= 2000
        )
        .required("Instruction text is required"),
      isPublished: yup.boolean().required(),
      questions: yup
        .array()
        .of(
          yup.object({
            id: yup.number().notRequired(),
            answerTypeId: yup.number().notRequired(),
            text: yup.string().required("Question text is required"),
            descriptionText: yup.string(),
            options: yup.array().when("answerTypeId", {
              is: checkIfOptionTextRequired,
              then: (schema) =>
                schema.of(
                  yup.object({
                    id: yup.number().notRequired(),
                    value: yup.string().required("Option text is required"),
                  })
                ),
              otherwise: (schema) =>
                schema.of(
                  yup.object({
                    id: yup.number().notRequired(),
                    value: yup.string(),
                  })
                ),
            }),
            required: yup.boolean(),
            characterLimit: yup
              .number()
              .nullable()
              .transform(transformEmptyNumber)
              .typeError("This must be a valid number")
              .positive("This must be a positive number"),
            visibilityConditionsSwitch: yup.boolean(),
            visibilityConditions: yup.array().of(
              yup.object({
                id: yup.number().notRequired(),
                questionConditionQuestionNumber: yup.number(),
                questionConditionOperatorId: yup.number(),
                questionConditionAnswerIndex: yup.string(),
              })
            ),
          })
        )
        .min(1, "At least one question is required")
        .required(),
    })
    .required();

  const defaultValues = {
    type: "",
    year: currentYear,
    title: "",
    instructionText: "",
    isPublished: false,
    questions: [defaultQuestion()],
  };

  const formMethods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(QuestionnaireEditSchema),
  });
  const {
    clearErrors,
    control,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState,
    trigger: triggerFormValidation,
    reset,
  } = formMethods;

  const { fields: questions, append, remove } = useFieldArray({
    control,
    name: "questions",
    keyName: "arrayFieldId",
  });

  const publishedState = useWatch({
    control,
    name: "isPublished",
  });

  // Query project financials data
  const { data: questionnaireData, isFetching } = useQuestionnaire(
    cloneId ?? itemIdFromParams
  );

  useEffect(async () => {
    if (questionnaireData) {
      const year = cloneId
        ? reporting.calendarYear + 1
        : questionnaireData.year;

      const result = {
        ...questionnaireData,
        id: cloneId ? null : questionnaireData.id,
        year,
        questions: questionnaireData.questions?.map(
          ({ answerValues, visibilityConditions, ...question }) => ({
            ...question,
            visibilityConditions: visibilityConditions?.length
              ? visibilityConditions
              : [defaultQuestionCondition()],
            visibilityConditionsSwitch: !!visibilityConditions?.length,
            options: answerValues?.length
              ? answerValues.map(({ id, value }) => ({ id, value }))
              : defaultQuestionOptions(),
            characterLimit:
              question.characterLimit && question.characterLimit !== "0"
                ? question.characterLimit
                : "",
          })
        ) ?? [defaultQuestion()],
      };

      // The user should only be able to select years above or equal the current year
      const startYear = reporting.calendarYear;
      const maxDefaultYear = reporting.calendarYear + 2;

      if (year > maxDefaultYear) {
        setYearRange(range(startYear, year).reverse());
      } else if (year < reporting.calendarYear) {
        setYearRange([year].concat(range(startYear, maxDefaultYear)).reverse());
      } else {
        setYearRange(range(startYear, maxDefaultYear).reverse());
      }

      setOriginalPublishedValue(result.isPublished);

      if (cloneId) {
        delete result.id;
      } else {
        setHasLimitedEdition(currentYear > year);
      }

      reset(result);

      if (cloneId) {
        await checkQuestionnaireConflicts();

        if (!mounted.current) {
          return;
        }

        triggerFormValidation();
      } else {
        // If not cloned, initiated conflict check with false
        const controlKey = `${questionnaireData.type}${year}`;
        hasTypeYearConflict.current[controlKey] = false;
      }
    }
  }, [questionnaireData]);

  useEffect(() => {
    if (!publishedState && originalPublishedValue) {
      setWarningModalOpen(true);
    }
  }, [publishedState]);

  const handleSaveClick = async ({ ...formValues }) => {
    const payload = {
      ...formValues,
      questions: formValues.questions.map(
        ({
          visibilityConditionsSwitch,
          visibilityConditions,
          answerTypeId,
          options,
          characterLimit,
          ...question
        }) => ({
          ...question,
          visibilityConditionsSwitch,
          // Include visibilityConditions only if the switch is true
          ...(visibilityConditionsSwitch ? { visibilityConditions } : {}),
          answerTypeId,
          // Include options only if it applies and map them to string array filtering empty ones
          ...([
            questionnaireAnswerTypes.singleSelectMultipleChoice.value,
            questionnaireAnswerTypes.multiSelectMultipleChoice.value,
          ].includes(answerTypeId)
            ? {
                answerValues: options.flatMap((option) =>
                  option.value ? option : []
                ),
              }
            : {}),
          // Include "Yes" and "No" as answer values if it is the corresponding type
          ...([questionnaireAnswerTypes.yesNo.value].includes(answerTypeId)
            ? {
                answerValues: options?.filter(({ id }) => !!id)?.length
                  ? options.flatMap((option) => option)
                  : ["Yes", "No"].map((value) => ({ value })),
              }
            : {}),
          characterLimit: characterLimit?.toString(),
        })
      ),
    };
    try {
      await (itemIsNew
        ? questionnaireService.create(payload)
        : questionnaireService.update(itemIdFromParams, payload));

      if (!mounted.current) {
        return;
      }

      showSnackbar(
        `Questionnaire ${itemIsNew ? "created" : "updated"} successfully.`
      );
      goBackToList();
    } catch (error) {
      if (!mounted.current) {
        return;
      }

      showSnackbar(
        error?.response?.data?.[0]?.errorDetail ?? errorMessages.generic,
        snackbarTypes.error
      );
    }
  };

  const goBackToList = () =>
    history.push(`${routes.contentManagement.questionnaires.list}${search}`);

  const closeWarningModal = () => {
    setWarningModalOpen(false);
    setValue("isPublished", originalPublishedValue);
  };

  const proceedToPublish = () => {
    setWarningModalOpen(false);
  };

  const { isValid, isSubmitting, errors, isDirty } = formState;

  const formActions = [
    {
      disabled:
        !isDirty || isSubmitting || !isValid || errors?.conditionConflict,
      testId: "questionnaireEditSaveButton",
      label: "Save",
      onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
    },
    {
      testId: "questionnaireEditCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: goBackToList,
    },
  ];

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title={
              itemIsNew ? "Create New Questionnaire" : "Edit Questionnaire"
            }
            backLinkText="Back to Questionnaire List"
            backLinkTo={routes.contentManagement.questionnaires.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...formMethods}>
            <form>
              <Grid
                container
                direction="column"
                spacing={3}
                className={clsx(
                  globalClasses.editPageBody22,
                  globalClasses.editPageBodyMargin
                )}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography>
                    Please enter the details of your questionnaire.
                  </Typography>
                </Grid>
                <Grid item>
                  {isFetching && (
                    <Skeleton width="100%" height="3.5rem" variant="rect" />
                  )}
                  {!isFetching && (
                    <AmoTextField
                      control={control}
                      label="Questionnaire Title"
                      id="questionnaire-edit-title"
                      name="title"
                      testId="questionnaireEditFirstName"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item container direction="row" spacing={3}>
                  <Grid item xs>
                    {isFetching && (
                      <Skeleton width="100%" height="3.5rem" variant="rect" />
                    )}
                    {!isFetching && (
                      <AmoTextField
                        control={control}
                        id="questionnaire-edit-type"
                        name="type"
                        testId="questionnaireEditType"
                        fullWidth
                        select
                        label="Questionnaire Type"
                        variant="outlined"
                        required
                        onChangeTrigger={() =>
                          checkQuestionnaireConflicts(true)
                        }
                        disabled={hasLimitedEdition || isCheckingConflict}
                        InputProps={{
                          startAdornment: isCheckingConflict && (
                            <InputAdornment position="start">
                              <CircularProgress size={25} color="inherit" />
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem
                          key="questionnaireEditTypeNone"
                          value=""
                          disabled
                        >
                          Questionnaire Type
                        </MenuItem>
                        {Object.values(questionnaireTypes).map((value) => (
                          <MenuItem
                            key={`questionnaireEditType-${value}`}
                            value={value}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      </AmoTextField>
                    )}
                  </Grid>
                  <Grid item xs>
                    {isFetching && (
                      <Skeleton width="100%" height="3.5rem" variant="rect" />
                    )}
                    {!isFetching && (
                      <AmoTextField
                        control={control}
                        id="questionnaire-edit-year"
                        name="year"
                        testId="questionnaireEditYear"
                        fullWidth
                        select
                        label="Reporting Year"
                        variant="outlined"
                        required
                        onChangeTrigger={() =>
                          checkQuestionnaireConflicts(true)
                        }
                        disabled={hasLimitedEdition || isCheckingConflict}
                        InputProps={{
                          startAdornment: isCheckingConflict && (
                            <InputAdornment position="start">
                              <CircularProgress size={25} color="inherit" />
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem
                          key="questionnaireEditYearNone"
                          value=""
                          disabled
                        >
                          Reporting Year
                        </MenuItem>
                        {yearRange.map((yearValue) => (
                          <MenuItem
                            key={`questionnaireEditYear-${yearValue}`}
                            value={yearValue}
                          >
                            {yearValue}
                          </MenuItem>
                        ))}
                      </AmoTextField>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  {isFetching && (
                    <Skeleton width="100%" height="18rem" variant="rect" />
                  )}
                  {!isFetching && (
                    <AmoRichTextEditor
                      control={control}
                      id="questionnaire-instruction-text"
                      name="instructionText"
                      label="Instruction text"
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  )}
                </Grid>
                <Grid item>
                  {isFetching && (
                    <Skeleton width="20%" height="2rem" variant="rect" />
                  )}
                  {!isFetching && (
                    <AmoSwitch
                      color="primary"
                      control={control}
                      id="questionnaire-is-published"
                      name="isPublished"
                      testId="questionnaireIsPublished"
                      label="Published"
                      labelPlacement="end"
                    />
                  )}
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>
                  <Typography variant="h4">Questions</Typography>
                </Grid>
                <QuestionnaireQuestions
                  hasLimitedEdition={hasLimitedEdition}
                  isLoading={isFetching}
                  questions={questions}
                  appendQuestion={append}
                  removeQuestion={remove}
                />
              </Grid>
            </form>
          </FormProvider>
        </Grid>
        {/* Actions */}
        <AmoFormActions actions={formActions} hideLateralBorders />
      </Grid>
      <AmoModal
        open={warningModalOpen}
        width="33rem"
        title="Warning"
        closePopover={closeWarningModal}
        showButton
        buttons={[
          {
            key: "yesButton",
            label: "Yes",
            color: "primary",
            variant: "contained",
            onClick: proceedToPublish,
          },
          {
            key: "noButton",
            label: "No",
            color: "secondary",
            variant: "outlined",
            onClick: closeWarningModal,
          },
        ]}
      >
        <Grid>
          <Typography>
            You are unpublishing an already published questionnaire. Do you wish
            to proceed?
          </Typography>
        </Grid>
      </AmoModal>
    </>
  );
};

export default QuestionnairesEditPage;
