import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  imisId: string,
 *  name: string,
 *  specialRole: string }} User
 */

const userApi = {
  amo: new Api("api/Users/amo"),
  treasurer: new Api("api/Users/treasurer"),
  municipal: new Api("api/Users/municipal"),
  communications: new Api("api/Users/communications"),
  account: new Api("account/"),
  add: new Api("Account/Create"),
  edit: new Api("Account/Update"),
  get: new Api("Account"),
  remove: new Api("Account/Remove"),
  checkUsername: new Api("Account/UsernameExists"),
  checkEmail: new Api("Account/EmailExists"),
  checkDelegate: new Api("Account/DelegateExists"),
  checkPrimaryContact: new Api("Account/PrimaryContactExists"),
  checkSession: new Api("api/Users/checksession", false),
};

export const userService = {
  // List Resources

  /**
   * Get a list of AMO staff users.
   *
   * @returns {Promise<AxiosResponse<User[]>>}
   */
  getAmoUsers: () => userApi.amo.axios.get(),

  /**
   * A list of treasurer users.
   *
   * @returns {Promise<AxiosResponse<User[]>>}
   */
  getTreasurers: () => userApi.treasurer.axios.get(),

  /**
   * A list of municipal users for a given municipality ID
   *
   * @param {number} municipalityId municipality ID to search by
   *
   * @returns {Promise<AxiosResponse<User[]>>}
   */
  getMunicipalUsers: (municipalityId) =>
    userApi.municipal.axios.get(municipalityId),

  /**
   * A list of communications users, or given an id.
   *
   * @returns {Promise<AxiosResponse<User[]>>}
   */
  getCommunicationsUsers: () => userApi.communications.axios.get(),

  // Individual Resources

  /**
   * Add a new user.
   *
   * @param {object} userData user data to be saved
   *
   * @returns {Promise<AxiosResponse<User>>}
   */
  createNewUser: (userData) => userApi.add.post(userData),

  /**
   * Edit and existing user.
   *
   * @param {object} userData user data to be saved
   *
   * @returns {Promise<AxiosResponse<User>>}
   */
  editUser: (userData) => userApi.edit.put(userData),

  /**
   * Get a user account.
   *
   * @param {string} userId
   *
   * @returns {Promise<AxiosResponse<User>>}
   */
  getUser: (userId) => userApi.get.axios.get(userId),

  /**
   * Remove a user Account.
   *
   * @param {string} userId
   *
   * @returns {Promise<AxiosResponse<User>>}
   */
  deleteUser: (userId) => () => userApi.remove.delete(userId),

  /**
   * Get the account details.
   *
   * @param {string} accountId id of the account to retrieve
   *
   * @returns {Promise<AxiosResponse<User>>}
   */
  getAccountDetails: (accountId) => userApi.account.getById(accountId),

  /**
   * Update the account details.
   *
   * @param {string} accountId id of the account to update
   * @param {object} userData account data to be saved
   *
   * @returns {Promise<AxiosResponse<User>>}
   */
  updateAccountDetails: (accountId, userData) =>
    userApi.account.put(accountId, userData),

  /**
   * Check that a username exists.
   *
   * @param {object} CheckParams
   * @param {string} CheckParams.usernameToCheckFor
   * @param {string} CheckParams.userIdToCheckFor
   *
   * @returns {Promise<AxiosResponse<boolean>>}
   */
  checkUsername: (CheckParams) => userApi.checkUsername.post(CheckParams),

  /**
   * Check that a email exists.
   *
   * @param {object} emailCheckParams
   * @param {string} emailCheckParams.emailToCheckFor
   * @param {string} emailCheckParams.userIdToCheckFor
   *
   * @returns {Promise<AxiosResponse<boolean>>}
   */
  checkEmail: (emailCheckParams) => userApi.checkEmail.post(emailCheckParams),

  /**
   * Check that a delegate exists for a municipality.
   *
   * @param {object} delegateCheckData
   * @param {string} delegateCheckData.municipalityIdToCheckFor
   * @param {string} delegateCheckData.userIdToCheckFor
   *
   * @returns {Promise<AxiosResponse<User>>}
   */
  checkDelegate: (delegateCheckData) =>
    userApi.checkDelegate.post(delegateCheckData),

  /**
   * Check that a primary contact exists for a municipality.
   *
   * @param {object} primaryContactCheckData
   * @param {string} primaryContactCheckData.municipalityIdToCheckFor
   * @param {string} primaryContactCheckData.userIdToCheckFor
   *
   * @returns {Promise<AxiosResponse<string>>}
   */
  checkPrimaryContact: (primaryContactCheckData) =>
    userApi.checkPrimaryContact.post(primaryContactCheckData),

  /**
   * Check that the user session is still active on the authority server.
   *
   * @returns {Promise<AxiosResponse<boolean>>}
   */
  checkSession: () =>
    userApi.checkSession.axios.get("", {
      // Set this to return 401 on this route instead of redirecting to login page
      headers: { "X-Requested-With": "XMLHttpRequest" },
    }),
};
