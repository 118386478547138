import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 * id: number,
 * municipalityId: number,
 * name: string,
 * announcementDate: string,
 * twitterUrl: string,
 * instagramUrl: string,
 * linkedInUrl: string,
 * buildingCommunitiesUrl: string }} RecentCommData
 */

/**
 * @typedef {{
 * id: number,
 * name: string,
 * date: string,
 * municipalityId: number,
 * updatedBy: string }} RecentUpdateData
 */

/**
 * @typedef {{
 * id: number,
 * latitude: string,
 * longitude: string,
 * statusId: number }} ProjectLocation
 */

const api = new Api("api/communications");
export const commDashboardService = {
  /**
   * @returns {Promise<AxiosResponse<RecentCommData[]>>}
   */
  getRecentComms: () => api.getById("updates"),
  /**
   * @returns {Promise<AxiosResponse<RecentUpdateData[]>>}
   */
  getRecentUpdates: () => api.getById("updated-projects"),
  /**
   * @param {number} statusId status id
   * @param {string} earliestDate earliest date to fetch projects (only if status is published)
   * @returns {Promise<AxiosResponse<ProjectLocation[]>>}
   */
  getProjectLocations: (statusId, earliestDate) =>
    api.getById(`map/${statusId}`, earliestDate ? { earliestDate } : null),
};
