import React from "react";
import { Box } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { useGlobalStyles } from "hooks/globalStylesHook";
import Header from "./Header";
import PrivateRoute from "./PrivateRoute";
import ConditionalRedirect from "components/ConditionalRedirect";
import Login from "../pages/Login";
import Landing from "../pages/Landing";
import GenericErrorPage from "../pages/GenericErrorPage";
import Help from "../pages/municipal/help/HelpPage";
import AmoHelp from "../pages/amo/help/AmoHelp";
import EditFAQ from "../pages/amo/help/EditFAQ";
import EditPage from "../pages/amo/help/EditPage";
import Sample from "../pages/Sample";
import Logout from "../pages/Logout";
import LoadingUserData from "../pages/LoadingUserData";
import ReportingPage from "../pages/municipal/ReportingPage";
import UserListPage from "../pages/amo/users/UserListPage";
import AmoUserEditPage from "../pages/amo/users/AmoUserEditPage";
import TreasurerUserEditPage from "../pages/amo/users/TreasurerUserEditPage";
import MunicipalUserEditPage from "../pages/amo/users/MunicipalUserEditPage";
import CommUserEditPage from "../pages/amo/users/CommUserEditPage";
import FormContentListPage from "../pages/amo/FormContentListPage";
import ReportCategoryEditPage from "../pages/amo/forms/ReportCategoryEditPage";
import ReportGeneralInfoEditPage from "../pages/amo/forms/ReportGeneralInfoEditPage";
import InsuranceReportingEditPage from "../pages/amo/forms/InsuranceReportingEditPage";
import ReportFinancialsEditPage from "../pages/amo/forms/ReportFinancialsEditPage";
import ReportCommunicationsEditPage from "../pages/amo/forms/ReportCommunicationsEditPage";
import ReportResultsEditPage from "../pages/amo/forms/ReportResultsEditPage";
import SubmitReportEditPage from "../pages/amo/forms/SubmitReportEditPage";
import FinancialReportingEditPage from "../pages/amo/forms/FinancialReportingEditPage";
import MunicipalLandingEditPage from "../pages/amo/forms/MunicipalLandingEditPage";
import MunicipalUserManagementEditPage from "../pages/amo/forms/MunicipalUserManagementEditPage";
import BankingInformationEditPage from "../pages/amo/forms/BankingInformationEditPage";
import IndicatorEditPage from "../pages/amo/outputs-outcomes/IndicatorEditPage";
import OutputsOutcomesListPage from "../pages/amo/outputs-outcomes/OutputsOutcomesListPage";
import { useUserContext } from "../contexts/UserContext";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { useYearContext } from "../contexts/YearContext";
import ResetPassword from "../pages/ResetPassword";
import AnnouncementEditPage from "pages/amo/announcements/AnnouncementEditPage";
import AnnouncementsListPage from "pages/amo/announcements/AnnouncementsListPage";
import { routes, replaceParams } from "constants/routes";
import NotificationEditPage from "pages/amo/notifications/NotificationEditPage";
import NotificationsListPage from "pages/amo/notifications/NotificationsListPage";
import QuestionnairesManagementListPage from "pages/amo/questionnaires/QuestionnairesManagementListPage";
import QuestionnairesEditPage from "../pages/amo/questionnaires/QuestionnairesEditPage";
import NotFoundPage from "../pages/NotFoundPage";
import GenericErrorBoundary from "../pages/GenericErrorBoundary";
import ProjectResultsPage from "../pages/municipal/summaries/project-results/ProjectResultsPage";
import QuestionnairesSummaryPage from "pages/municipal/summaries/questionnaires/QuestionnairesSummaryPage";
import ProjectsSummaryPage from "pages/municipal/summaries/projects/ProjectsSummaryPage";
import FinancialSummaryPage from "pages/municipal/summaries/financial/FinancialSummaryPage";
import ProjectReviewListPage from "pages/amo/project-reviews/ProjectReviewListPage";
import FinancialReviewListPage from "pages/amo/financial-reviews/FinancialReviewListPage";
import InsuranceReviewListPage from "pages/amo/insurance-reviews/InsuranceReviewListPage";
import ProjectReviewPage from "pages/amo/project-reviews/ProjectReviewPage";
import FinancialReviewPage from "pages/amo/financial-reviews/FinancialReviewPage";
import BankingInformationPage from "pages/municipal/banking/BankingInformationPage";
import BankLimitMonitoringPage from "pages/amo/bank-limit/BankLimitMonitoringPage";
import ResultsLogListPage from "pages/amo/results-log/ResultsLogListPage";
import { roles, roleGroups } from "constants/user";
import ReportingStatusReviewListPage from "pages/amo/reporting-status-reviews/ReportingStatusReviewListPage";
import AccountPage from "pages/amo/users/AccountPage";
import QuestionnaireReviewListPage from "pages/amo/questionnaire-reviews/QuestionnaireReviewListPage";
import QuestionnaireReviewEditPage from "pages/amo/questionnaire-reviews/QuestionnaireReviewEditPage";
import IncrementalityMonitoringListPage from "pages/amo/incrementality-monitoring-reviews/IncrementalityMonitoringListPage";
import ProjectDetails from "pages/communications/project-details/ProjectDetails";
import ProjectListPage from "pages/communications/project-list/ProjectListPage";
import CommunicationsDashboard from "pages/communications/LandingPage";
import BankingInformationReviewsListPage from "pages/amo/banking-information-reviews/BankingInformationReviewsListPage";
import ReportQueryReviewPage from "pages/amo/report-query-reviews/ReportQueryReviewPage";

/**
 * A router component
 *
 * @returns {React.Component} The amo router
 */
const AmoRouter = () => {
  const globalClasses = useGlobalStyles();
  const { user, hasRoles } = useUserContext();
  const { isFetching: isFetchingMunicipality } = useMunicipalContext();
  const { contextYear } = useYearContext();

  // Added this condition to wait for the contexts data to be loaded
  // before using it on the routes below
  if (!user?.loaded || isFetchingMunicipality) {
    return (
      <Box className={globalClasses.pageBody}>
        <Switch>
          <Route component={LoadingUserData} />
        </Switch>
      </Box>
    );
  }

  return (
    <>
      <Header />
      <GenericErrorBoundary>
        <Box className={globalClasses.pageBody}>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route path="/signin-oidc" />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/help" component={Help} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path={routes.dual.projects}
              render={(renderProps) => (
                <ConditionalRedirect
                  renderProps={renderProps}
                  conditionalRoutes={[
                    (params) =>
                      hasRoles(roles.amo) &&
                      replaceParams(routes.reviews.projects.form, {
                        id: params.projectId,
                      }),
                    (params) =>
                      hasRoles(roles.communications) &&
                      replaceParams(routes.communications.projects.details, {
                        projectId: params.projectId,
                      }),
                    (params) =>
                      replaceParams("/reporting/:year/projects/:projectId", {
                        projectId: params.projectId,
                        year: contextYear,
                      }),
                  ]}
                />
              )}
            />
            <Route
              exact
              path={routes.dual.financials}
              render={(renderProps) => (
                <ConditionalRedirect
                  renderProps={renderProps}
                  conditionalRoutes={[
                    (params) =>
                      hasRoles(roles.amo) &&
                      replaceParams(routes.reviews.financial.form, params),
                    (params) =>
                      replaceParams("/reporting/:year/financials", params),
                  ]}
                />
              )}
            />

            <PrivateRoute exact path={routes.account} redirectPath="/login">
              <AccountPage />
            </PrivateRoute>
            <PrivateRoute path="/landing" redirectPath="/login">
              <Landing />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.help.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <AmoHelp />
            </PrivateRoute>
            <PrivateRoute
              path={[
                routes.contentManagement.help.editPage,
                routes.contentManagement.help.newPage,
              ]}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <EditPage />
            </PrivateRoute>
            <PrivateRoute
              path={[
                routes.contentManagement.help.editFAQ,
                routes.contentManagement.help.newFAQ,
              ]}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <EditFAQ />
            </PrivateRoute>
            <PrivateRoute path="/sample/:name" redirectPath="/login">
              <Sample />
            </PrivateRoute>
            <PrivateRoute path="/reporting/:year" redirectPath="/login">
              <ReportingPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/users"
              redirectPath="/login"
              allowedRoles={[
                roles.amo,
                ...roleGroups.municipal.treasurerOrDelegate,
              ]}
            >
              <UserListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/users/amo/:userId"
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <AmoUserEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/users/treasurer/:userId"
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <TreasurerUserEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/users/municipal/:userId"
              redirectPath="/login"
              allowedRoles={[
                roles.amo,
                ...roleGroups.municipal.treasurerOrDelegate,
              ]}
            >
              <MunicipalUserEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/users/communication/:userId"
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <CommUserEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.projects.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ProjectReviewListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.questionnaires.list}
              redirectPath="/login"
            >
              <QuestionnaireReviewListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.financial.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <FinancialReviewListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.bankingInformation.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <BankingInformationReviewsListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.reportingStatus.list}
              redirectPath="/login"
            >
              <ReportingStatusReviewListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.incrementalityMonitoring.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <IncrementalityMonitoringListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.announcements.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <AnnouncementsListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.announcements.edit}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <AnnouncementEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <FormContentListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.projectReportCategory}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ReportCategoryEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={
                routes.contentManagement.forms.edit.projectReportGeneralInfo
              }
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ReportGeneralInfoEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.projectReportFinancials}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ReportFinancialsEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={
                routes.contentManagement.forms.edit.projectReportCommunications
              }
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ReportCommunicationsEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.projectReportResults}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ReportResultsEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.insuranceReporting}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <InsuranceReportingEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.submitReport}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <SubmitReportEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.financialReporting}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <FinancialReportingEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.municipalLandingPage}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <MunicipalLandingEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.municipalUserManagement}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <MunicipalUserManagementEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.forms.edit.bankingInformation}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <BankingInformationEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.notifications.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <NotificationsListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.notifications.edit}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <NotificationEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.outputsOutcomes.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <OutputsOutcomesListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.outputsOutcomes.edit}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <IndicatorEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.questionnaires.list}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <QuestionnairesManagementListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.contentManagement.questionnaires.edit}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <QuestionnairesEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.administration.bankingInformation}
              redirectPath="/login"
            >
              <BankingInformationPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.summaries.questionnaires}
              redirectPath="/login"
            >
              <QuestionnairesSummaryPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.summaries.projects}
              redirectPath="/login"
            >
              <ProjectsSummaryPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.summaries.financial}
              redirectPath="/login"
            >
              <FinancialSummaryPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.projects.results}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ResultsLogListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.projects.form}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ProjectReviewPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.questionnaires.form}
              redirectPath="/login"
            >
              <QuestionnaireReviewEditPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.financial.form}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <FinancialReviewPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.reviews.insurance.list}
              redirectPath="/login"
            >
              <InsuranceReviewListPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.summaries.results}
              redirectPath="/login"
            >
              <ProjectResultsPage />
            </PrivateRoute>
            <PrivateRoute
              path={routes.reviews.bankLimit}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <BankLimitMonitoringPage />
            </PrivateRoute>
            <PrivateRoute
              path={routes.reviews.reportQuery}
              redirectPath="/login"
              allowedRoles={[roles.amo]}
            >
              <ReportQueryReviewPage />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.communications.projects.details}
              redirectPath="/login"
              allowedRoles={[roles.communications, roles.amo]}
            >
              <ProjectDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/communications/dashboard"
              redirectPath="/login"
              allowedRoles={[roles.amo, roles.communications]}
            >
              <CommunicationsDashboard />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={routes.communications.projects.list}
              redirectPath="/login"
              allowedRoles={[roles.communications, roles.amo]}
            >
              <ProjectListPage />
            </PrivateRoute>
            <Route path="/access-denied">
              <GenericErrorPage
                title="Access Denied!"
                description="You do not have permission to access that resource."
              />
            </Route>
            <Route component={NotFoundPage} />
          </Switch>
        </Box>
      </GenericErrorBoundary>
    </>
  );
};

export default AmoRouter;
