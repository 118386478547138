import React, { useState, useRef, useEffect, useMemo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import WarningIcon from "@material-ui/icons/Warning";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";

// Query hooks and management
import {
  useGetPreviousDelegate,
  useGetPreviousContact,
  useMunicipalUserDetails,
} from "./municipalUserHooks";
import { useFormManagement } from "hooks/formManagementHook";
import {
  useCreatePasswordReset,
  useCreateUserDetails,
  useRemoveUser,
  useUpdateUserDetails,
} from "./usersHook";

import { useMunicipalContext } from "contexts/MunicipalContext";
import { userService } from "api/services/userService";
import { useSnackbar } from "contexts/SnackbarContext";
import { useUserContext } from "contexts/UserContext";

import AmoTextField from "components/inputs/AmoTextField";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import AmoSwitch from "components/inputs/AmoSwitch";
import AmoPageHeader from "components/AmoPageHeader";
import AmoFormActions from "components/AmoFormActions";
import FormInputWrapper from "components/municipal/FormInputWrapper";
import NotFoundPage from "pages/NotFoundPage";
import AmoConfirmPopover from "components/AmoConfirmPopover";

import {
  formIds,
  municipalUserManagementCodes as formCodes,
} from "constants/formContentManagement";
import colors from "constants/colors";
import { roles } from "constants/user";
import { patterns } from "constants/regex";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { routes } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    overflow: "hidden auto",
  },
  body: {
    // 0px 352px 0px 352px
    padding: "0rem 22rem 0rem 22rem",
  },
  bodyText: {
    marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        // 16px
        paddingBottom: "1rem",
      },
      "& > .MuiGrid-container > .MuiGrid-item:last-child": {
        // 48px
        paddingLeft: "3rem",
      },
    },
  },
  bodyCheckbox: {
    // marginTop: "1.25rem",
    "& > .MuiGrid-item": {
      // 8px
      paddingBottom: "0.5rem",
      "&:first-child > .MuiGrid-container > .MuiGrid-item:last-child": {
        // 48px
        paddingLeft: "3rem",
      },
    },
  },
  inputWidth: {
    width: "100%",
  },
  subtitle: {
    color: colors.black,
    fontFamily: "Roboto",
    marginTop: "0.5rem",
  },
  checkboxLabel: {
    // 8px
    marginRight: "0rem",
  },
  modal: {
    width: "18.75rem",
  },
  warningText: {
    fontSize: "0.8rem",
    color: colors.red.warning,
  },
}));

/**
 * This page component functions as both the "new" and "edit" page for municipal users.
 *
 * @returns {Function} The municipal user edit page component
 */
const MunicipalUserEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { userId: userIdFromParams } = useParams();
  const history = useHistory();
  const { user: currentUser } = useUserContext();
  const { municipalities } = useMunicipalContext();
  const { showSnackbar } = useSnackbar();
  const { hasRoles } = useUserContext();
  const location = useLocation();
  const goBackRoute = `/users${location.search}`;

  // modal properties
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [isEnableUserModalOpen, setIsEnableUserModalOpen] = useState(false);
  const handleModalClose = () => {
    setIsRemoveUserModalOpen(false);
  };
  const handleDisableUserModalClose = (enabled = true) => {
    setIsEnableUserModalOpen(false);
    setValue("enabled", enabled);
  };
  const hasAmoRole = currentUser.roles.includes(roles.amo);
  const hasTreasurerRole = hasRoles([
    roles.municipalTreasurer,
    roles.municipalTreasurerDelegate,
  ]);
  const userIsNew = userIdFromParams === "new";
  const headerSubtitle = userIsNew ? "Add User" : "Edit User";

  const generatedUsername = useRef("");
  const previousSelectedPermissions = useRef(null);

  // Validation helper for use within yup schema test
  const checkIfUsernameExists = async (username) => {
    const usernameValidationParams = {
      usernameToCheckFor: username,
      userIdToCheckFor: userIsNew ? null : userIdFromParams,
    };
    const { data } = await userService.checkUsername(usernameValidationParams);
    return !data;
  };

  const checkIfEmailExists = async (email) => {
    const emailValidationParams = {
      emailToCheckFor: email,
      userIdToCheckFor: userIsNew ? null : userIdFromParams,
    };
    const { data } = await userService.checkEmail(emailValidationParams);
    return !data;
  };

  // define schema for form validation
  const MunicipalUserEditValidationSchema = useMemo(
    () =>
      yup
        .object({
          firstName: yup
            .string()
            .trim()
            .required("First name is required")
            .max(50, "Too long, maximum 50 characters"),
          lastName: yup
            .string()
            .trim()
            .required("Last name is required")
            .max(50, "Too long, maximum 50 characters"),
          userName: yup
            .string()
            .trim()
            .required("Username is required")
            .min(3, "Too short, minimum 3 characters")
            .max(50, "Too long, maximum 50 characters")
            .test(
              "username-taken-validation",
              "Username already taken.",
              checkIfUsernameExists
            ),
          email: yup
            .string()
            .email("Email must be a valid email")
            .required("Email is required")
            .max(320, "Email length cannot exceed 320 characters")
            .test(
              "email-taken-validation",
              "Email already taken.",
              checkIfEmailExists
            ),
          phoneNumber: yup
            .string()
            .required("Phone number is required")
            .matches(patterns.phone, { message: "Invalid phone format" }),
          phoneExtension: yup
            .string()
            .nullable()
            .matches(patterns.number, { message: "Invalid phone extension" }),
          municipalityId: hasAmoRole
            ? yup.string().required("Municipality is required")
            : yup.string().notRequired(),
          title: yup
            .string()
            .trim()
            .nullable()
            .max(100, "Too long, maximum 100 characters"),
          communicationsEditPermission: yup.boolean(),
          delegate: yup.boolean(),
          financialEditPermission: yup.boolean(),
          projectEditPermission: yup.boolean(),
          questionnaireRespondPermission: yup.boolean(),
          enabled: yup.boolean(),
          primaryContact: yup.boolean(),
        })
        .required(),
    [hasAmoRole]
  );

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phoneNumber: "",
    phoneExtension: "",
    municipalityId: "",
    title: "",
    delegate: false,
    financialEditPermission: false,
    projectEditPermission: false,
    communicationsEditPermission: false,
    questionnaireRespondPermission: false,
    enabled: true,
    primaryContact: false,
  };

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    trigger,
    setValue,
    formState,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    resolver: yupResolver(MunicipalUserEditValidationSchema),
  });

  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    error,
  } = useMunicipalUserDetails(userIdFromParams);

  const checkIfUserChangesSelfOrNew = () =>
    currentUser?.id !== userIdFromParams;

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    if (userDetails?.data) {
      reset(userDetails?.data);
    }
  }, [userDetails]);

  const { formFields } = useFormManagement(
    formIds.municipalUserManagement,
    true
  );

  /*
  /  WATCH LIST
  /  Watches named fields for updates, used to trigger effects when we need to
  /  Caution: useWatch is optimized for renders, not for effects, so it may trigger effects multiple times
  /  This is suboptimal; the [watch] and [useWatch] docs hint at a better way, performance-wise
  /  https://react-hook-form.com/api/usewatch
  /  TODO: create a custom hook that's optimized for effect hooks
  */
  const [
    firstNameWatch,
    lastNameWatch,
    primaryContact,
    delegate,
    municipalityIdWatch,
  ] = useWatch({
    control,
    name: [
      "firstName",
      "lastName",
      "primaryContact",
      "delegate",
      "municipalityId",
    ],
  });

  // When no username is manually entered, combine first initial and last name to create username
  useEffect(() => {
    // If it's editing the user, doesn't use generated username
    if (userIdFromParams && !userIsNew) {
      return;
    }

    const currentUsername = getValues("userName");

    const noSpaceLastName = lastNameWatch.trim().replace(" ", "");
    const useGeneratedUsername =
      !currentUsername || currentUsername === generatedUsername.current;

    if (firstNameWatch && noSpaceLastName) {
      generatedUsername.current = `${firstNameWatch.charAt(
        0
      )}${noSpaceLastName}`.toLowerCase();
    } else {
      generatedUsername.current = "";
    }
    if (useGeneratedUsername && generatedUsername.current !== currentUsername) {
      setValue("userName", generatedUsername.current);
      // Trigger a validation check for this field
      trigger("userName");
    }
  }, [firstNameWatch, lastNameWatch]);

  // Store the previous and update permissions when the tresurer delegate role changes
  useEffect(() => {
    if (delegate) {
      const [
        financialEditPermission,
        projectEditPermission,
        communicationsEditPermission,
        questionnaireRespondPermission,
      ] = getValues([
        "financialEditPermission",
        "projectEditPermission",
        "communicationsEditPermission",
        "questionnaireRespondPermission",
      ]);

      previousSelectedPermissions.current = {
        financialEditPermission,
        projectEditPermission,
        communicationsEditPermission,
        questionnaireRespondPermission,
      };
      setValue("financialEditPermission", true);
      setValue("projectEditPermission", true);
      setValue("communicationsEditPermission", true);
      setValue("questionnaireRespondPermission", true);
    } else if (previousSelectedPermissions.current) {
      const {
        financialEditPermission,
        projectEditPermission,
        communicationsEditPermission,
        questionnaireRespondPermission,
      } = previousSelectedPermissions.current;

      setValue("financialEditPermission", !!financialEditPermission);
      setValue("projectEditPermission", !!projectEditPermission);
      setValue("communicationsEditPermission", !!communicationsEditPermission);
      setValue(
        "questionnaireRespondPermission",
        !!questionnaireRespondPermission
      );
    }
  }, [delegate]);

  // Conditionally gets a municipality ID, depending on if the user is AMO staff
  // If treasurer is executing this, assume their municipality; if AMO, use selected
  const municipalityIdToCheckFor = currentUser.roles.includes(roles.amo)
    ? municipalityIdWatch
    : currentUser.municipalityId;

  const userIdToCheckFor = userIsNew ? null : userIdFromParams;

  const { data: previousDelegateData } = useGetPreviousDelegate(
    userIdToCheckFor,
    municipalityIdToCheckFor,
    delegate
  );

  const { data: previousPrimaryContact } = useGetPreviousContact(
    userIdToCheckFor,
    municipalityIdToCheckFor,
    primaryContact
  );

  const warningText = (text) => (
    <div style={{ display: "flex" }}>
      <Typography
        className={clsx(classes.fullWidth, classes.warningText)}
        variant="body2"
      >
        {text}
      </Typography>
      <WarningIcon
        style={{ marginLeft: "5px" }}
        className={classes.warningText}
      />
    </div>
  );

  // the primary contact control is conditional in two different locations
  // this code is to allow for 1 bit of code to handle the same way in both locations
  const primaryControl = (
    <>
      <FormInputWrapper
        id="municipal-user-edit-primary-wrapper"
        testId="municipalUserEditPrimaryWrapper"
        tooltip={!!formFields[formCodes.fieldContact]?.tooltipText}
        tooltipProps={{
          title: formFields[formCodes.fieldContact]?.text,
          text: formFields[formCodes.fieldContact]?.tooltipText,
        }}
        childrenFullWidth={false}
        hideFlagSpacing
      >
        <AmoCheckbox
          control={control}
          id="municipal-user-edit-primary"
          name="primaryContact"
          testId="municipalUserEditPrimary"
          color="primary"
          label={formFields[formCodes.fieldContact]?.text}
          // TODO: helperText not supported - fix
          // helperText={formFields[formCodes.fieldContact]?.helperText}
        />
      </FormInputWrapper>
      {previousPrimaryContact?.data &&
        primaryContact &&
        warningText(
          `Primary contact status will be re-assigned from ${previousPrimaryContact?.data}`
        )}
    </>
  );

  const hasPreviousDelegate = !!previousDelegateData?.data;

  const isCurrentUserAmoStaff = currentUser.roles.includes(roles.amo);
  const isCurrentUserTreasurer = currentUser.roles.includes(
    roles.municipalTreasurer
  );
  const isCurrentUserDelegate = currentUser.roles.includes(
    roles.municipalTreasurerDelegate
  );
  const editingSelf = +currentUser.id === +userIdFromParams;

  const shouldLogout =
    isCurrentUserDelegate &&
    !isCurrentUserAmoStaff &&
    !isCurrentUserTreasurer &&
    (delegate ? hasPreviousDelegate : editingSelf);

  const navigateBack = () => history.push(goBackRoute);
  const navigateLogout = () => history.replace(routes.logout);

  const navigateBackOnSave = shouldLogout ? navigateLogout : navigateBack;

  const {
    mutateAsync: updateUser,
    isLoading: isUpdatingUser,
  } = useUpdateUserDetails(showSnackbar, navigateBackOnSave);
  const {
    mutateAsync: createUser,
    isLoading: isCreatingUser,
  } = useCreateUserDetails(showSnackbar, navigateBackOnSave);
  const { mutateAsync: deleteUser } = useRemoveUser(showSnackbar, navigateBack);
  const {
    mutateAsync: createPasswordReset,
    isLoading: isCreatingPasswordReset,
  } = useCreatePasswordReset(showSnackbar);

  const handleSaveClick = async ({
    municipalityId,
    phoneExtension,
    ...rest
  }) => {
    const userData = {
      ...rest,
      userType: "municipal",
      phoneExtension: phoneExtension !== undefined ? phoneExtension : "",
      // if the executing user is Municipal - use that user's municipality
      municipalityId:
        hasTreasurerRole && !hasAmoRole
          ? currentUser.municipalityId
          : municipalityId,
    };
    if (userIsNew) {
      createUser(userData);
    } else {
      userData.userId = userIdFromParams;
      updateUser(userData);
    }
  };

  const handleRemoveUserClick = () => setIsRemoveUserModalOpen(true);

  const handleEnableUserClick = async (value) => {
    if (delegate || primaryContact) {
      // it is async because getValues provides a wrong result. We need to render switch at first
      await new Promise((resolve) => setTimeout(resolve, 1));
      setIsEnableUserModalOpen(!getValues("enabled"));
    }
  };

  const sendRemovalRequest = async (id) => deleteUser(id);

  const handlePasswordResetClick = () => {
    const emailRecipient = getValues("email");
    createPasswordReset(emailRecipient);
  };

  const passwordResetObject = {
    disabled: isCreatingPasswordReset,
    testId: "municipalUserEditPasswordResetButton",
    label: "Password reset",
    color: "secondary",
    variant: "outlined",
    onClick: handlePasswordResetClick,
  };

  const headerActions = checkIfUserChangesSelfOrNew()
    ? [
        {
          testId: "municipalUserEditRemoveUserButton",
          label: "Remove user",
          color: "secondary",
          variant: "outlined",
          onClick: handleRemoveUserClick,
        },
        passwordResetObject,
      ]
    : [passwordResetObject];

  const { isValid, isSubmitting, isDirty } = formState;

  const formActions = [
    {
      disabled:
        !isDirty ||
        isSubmitting ||
        !isValid ||
        isUpdatingUser ||
        isCreatingUser,
      testId: "municipalUserEditSaveButton",
      label: "Save",
      onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
    },
    {
      disabled: isUpdatingUser || isCreatingUser,
      testId: "municipalUserEditCancelButton",
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: navigateBack,
    },
  ];

  let onRemoveWarningPrefix = "";
  let onDisableWarningPrefix = "";
  if (primaryContact) {
    onRemoveWarningPrefix = "Primary Contact will be deleted. ";
    onDisableWarningPrefix = "The user is a Primary Contact. ";
  }
  if (delegate) {
    onRemoveWarningPrefix = "Treasurer Delegate will be deleted. ";
    onDisableWarningPrefix = "The user is a Treasurer Delegate. ";
  }
  const onRemoveWarningText = `${onRemoveWarningPrefix}Are you sure you want to remove this user?  This action is not reversible.`;
  const onDisableWarningText = `${onDisableWarningPrefix}Are you sure you want to disable the special user?`;
  return isUserDetailsLoading ? (
    <>
      <Skeleton width="50%" />
      <Skeleton width="50%" />
    </>
  ) : (
    <>
      {error?.response?.status === 404 && (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fullHeight}
          wrap="nowrap"
        >
          <NotFoundPage />
        </Grid>
      )}
      {!(error?.response?.status === 404) && (
        <Grid container direction="column" spacing={0} wrap="nowrap">
          {/* Header */}
          <Grid item>
            <AmoPageHeader
              title="User Management:"
              subtitle={headerSubtitle}
              backLinkText="Back to user list"
              backLinkTo="/users"
              actions={userIsNew ? null : headerActions}
            />
          </Grid>

          {/* Body */}
          <Grid item xs className={globalClasses.editPageBodyContainer}>
            <Grid
              container
              direction="column"
              spacing={0}
              className={clsx(
                globalClasses.editPageBody22,
                globalClasses.editPageBodyText
              )}
              wrap="nowrap"
            >
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <FormInputWrapper
                      id="municipal-user-edit-firstName-wrapper"
                      testId="municipalUserEditFirstNameWrapper"
                      tooltip={
                        !!formFields[formCodes.fieldFirstName]?.tooltipText
                      }
                      tooltipProps={{
                        title: formFields[formCodes.fieldFirstName]?.text,
                        text: formFields[formCodes.fieldFirstName]?.tooltipText,
                      }}
                      childrenFullWidth
                      hideFlagSpacing
                    >
                      <AmoTextField
                        control={control}
                        label={formFields[formCodes.fieldFirstName]?.text}
                        id="municipal-user-edit-firstName"
                        name="firstName"
                        required
                        testId="municipalUserEditFirstName"
                        variant="outlined"
                        className={classes.inputWidth}
                        helperText={
                          formFields[formCodes.fieldFirstName]?.helperText
                        }
                      />
                    </FormInputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <FormInputWrapper
                      id="municipal-user-edit-lastName-wrapper"
                      testId="municipalUserEditLastNameWrapper"
                      tooltip={
                        !!formFields[formCodes.fieldLastName]?.tooltipText
                      }
                      tooltipProps={{
                        title: formFields[formCodes.fieldLastName]?.text,
                        text: formFields[formCodes.fieldLastName]?.tooltipText,
                      }}
                      childrenFullWidth
                      hideFlagSpacing
                    >
                      <AmoTextField
                        control={control}
                        label={formFields[formCodes.fieldLastName]?.text}
                        id="municipal-user-edit-lastName"
                        name="lastName"
                        testId="municipalUserEditLastName"
                        className={classes.inputWidth}
                        variant="outlined"
                        required
                        helperText={
                          formFields[formCodes.fieldLastName]?.helperText
                        }
                      />
                    </FormInputWrapper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <FormInputWrapper
                      id="municipal-user-edit-username-wrapper"
                      testId="municipalUserEditUsernameWrapper"
                      tooltip={
                        !!formFields[formCodes.fieldUsername]?.tooltipText
                      }
                      tooltipProps={{
                        title: formFields[formCodes.fieldUsername]?.text,
                        text: formFields[formCodes.fieldUsername]?.tooltipText,
                      }}
                      childrenFullWidth
                      hideFlagSpacing
                    >
                      <AmoTextField
                        control={control}
                        id="municipal-user-edit-username"
                        name="userName"
                        testId="municipalUserEditUsername"
                        className={classes.inputWidth}
                        label={formFields[formCodes.fieldUsername]?.text}
                        variant="outlined"
                        required
                        helperText={
                          formFields[formCodes.fieldUsername]?.helperText
                        }
                      />
                    </FormInputWrapper>
                  </Grid>
                  <Grid item xs />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <FormInputWrapper
                      id="municipal-user-edit-title-wrapper"
                      testId="municipalUserEditTitleWrapper"
                      tooltip={!!formFields[formCodes.fieldTitle]?.tooltipText}
                      tooltipProps={{
                        title: formFields[formCodes.fieldTitle]?.text,
                        text: formFields[formCodes.fieldTitle]?.tooltipText,
                      }}
                      childrenFullWidth
                      hideFlagSpacing
                    >
                      <AmoTextField
                        control={control}
                        id="municipal-user-edit-title"
                        name="title"
                        testId="municipalUserEditTitle"
                        className={classes.inputWidth}
                        label={formFields[formCodes.fieldTitle]?.text}
                        variant="outlined"
                        helperText={
                          formFields[formCodes.fieldTitle]?.helperText
                        }
                      />
                    </FormInputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    {hasAmoRole && (
                      <FormInputWrapper
                        id="municipal-user-edit-municipality-wrapper"
                        testId="municipalUserEditMunicipalityWrapper"
                        tooltip={false}
                        childrenFullWidth
                        hideFlagSpacing
                      >
                        <AmoTextField
                          control={control}
                          id="municipal-user-edit-municipality"
                          name="municipalityId"
                          testId="municipalUserEditMunicipality"
                          className={classes.inputWidth}
                          select
                          label="Municipality"
                          variant="outlined"
                          required
                        >
                          <MenuItem
                            key="municipalUserEditMunicipalityNone"
                            value=""
                            disabled
                          >
                            Municipality
                          </MenuItem>
                          {Object.entries(municipalities)
                            .sort(([aKey, aVal], [bKey, bVal]) =>
                              aVal.name.localeCompare(bVal.name)
                            )
                            .map(([key, value]) => (
                              <MenuItem
                                key={`municipalUserEditMunicipality-${key}`}
                                value={key}
                              >
                                {value.name}
                              </MenuItem>
                            ))}
                        </AmoTextField>
                      </FormInputWrapper>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <FormInputWrapper
                      id="municipal-user-edit-email-wrapper"
                      testId="municipalUserEditEmailWrapper"
                      tooltip={!!formFields[formCodes.fieldEmail]?.tooltipText}
                      tooltipProps={{
                        title: formFields[formCodes.fieldEmail]?.text,
                        text: formFields[formCodes.fieldEmail]?.tooltipText,
                      }}
                      childrenFullWidth
                      hideFlagSpacing
                    >
                      <AmoTextField
                        control={control}
                        id="municipal-user-edit-email"
                        name="email"
                        testId="municipalUserEditEmail"
                        className={classes.inputWidth}
                        label={formFields[formCodes.fieldEmail]?.text}
                        required
                        helperText={
                          formFields[formCodes.fieldEmail]?.helperText
                        }
                      />
                    </FormInputWrapper>
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item xs={8}>
                      <FormInputWrapper
                        id="municipal-user-edit-phone-wrapper"
                        testId="municipalUserEditPhoneWrapper"
                        tooltip={
                          !!formFields[formCodes.fieldPhone]?.tooltipText
                        }
                        tooltipProps={{
                          title: formFields[formCodes.fieldPhone]?.text,
                          text: formFields[formCodes.fieldPhone]?.tooltipText,
                        }}
                        childrenFullWidth
                        hideFlagSpacing
                      >
                        <AmoTextField
                          control={control}
                          id="municipal-user-edit-phone"
                          name="phoneNumber"
                          testId="municipalUserEditPhone"
                          className={classes.inputWidth}
                          label={formFields[formCodes.fieldPhone]?.text}
                          required
                          helperText={
                            formFields[formCodes.fieldPhone]?.helperText
                          }
                          pattern={/[^0-9.]+/g}
                          numberFormatProps={{
                            format: "###-###-####",
                          }}
                        />
                      </FormInputWrapper>
                    </Grid>
                    <Grid item xs={4}>
                      <FormInputWrapper
                        id="municipal-user-edit-phone-extension-wrapper"
                        testId="municipalUserEditPhoneExtensionWrapper"
                        tooltip={
                          !!formFields[formCodes.fieldPhoneExtension]
                            ?.tooltipText
                        }
                        tooltipProps={{
                          title:
                            formFields[formCodes.fieldPhoneExtension]?.text,
                          text:
                            formFields[formCodes.fieldPhoneExtension]
                              ?.tooltipText,
                        }}
                        childrenFullWidth
                        hideFlagSpacing
                      >
                        <AmoTextField
                          control={control}
                          id="municipal-user-edit-phone-extension"
                          name="phoneExtension"
                          testId="municipalUserEditPhoneExtension"
                          className={classes.inputWidth}
                          label={
                            formFields[formCodes.fieldPhoneExtension]?.text
                          }
                          helperText={
                            formFields[formCodes.fieldPhoneExtension]
                              ?.helperText
                          }
                        />
                      </FormInputWrapper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              spacing={0}
              className={clsx(
                globalClasses.editPageBody22,
                classes.bodyCheckbox
              )}
              wrap="nowrap"
            >
              <Grid item>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginLeft: checkIfUserChangesSelfOrNew() ? "0" : "-48px",
                    }}
                  >
                    {hasAmoRole || hasTreasurerRole ? (
                      <>
                        <FormInputWrapper
                          id="municipal-user-edit-delegate-wrapper"
                          testId="municipalUserEditDelegateWrapper"
                          tooltip={
                            !!formFields[formCodes.fieldDelegate]?.tooltipText
                          }
                          tooltipProps={{
                            title: formFields[formCodes.fieldDelegate]?.text,
                            text:
                              formFields[formCodes.fieldDelegate]?.tooltipText,
                          }}
                          childrenFullWidth={false}
                          hideFlagSpacing
                        >
                          <AmoCheckbox
                            control={control}
                            id="municipal-user-edit-delegate"
                            name="delegate"
                            testId="municipalUserEditDelegate"
                            color="primary"
                            label={formFields[formCodes.fieldDelegate]?.text}
                            // TODO: helperText not supported - fix
                            // helperText={
                            //   formFields[formCodes.fieldDelegate]?.helperText
                            // }
                          />
                        </FormInputWrapper>
                        {delegate && hasPreviousDelegate
                          ? warningText(
                              `Treasurer delegate status will be re-assigned from ${
                                isCurrentUserDelegate
                                  ? "you"
                                  : previousDelegateData?.data?.name
                              }.${
                                shouldLogout
                                  ? " You will be logged out on save."
                                  : ""
                              }`
                            )
                          : null}
                        {!delegate && editingSelf && isCurrentUserDelegate
                          ? warningText(
                              `You are waiving your delegate role.${
                                shouldLogout
                                  ? " You will be logged out on save."
                                  : ""
                              }`
                            )
                          : null}
                      </>
                    ) : (
                      primaryControl
                    )}
                  </Grid>
                  {hasRoles([
                    roles.amo,
                    roles.amoSuperAdmin,
                    roles.municipalTreasurer,
                    roles.municipalTreasurerDelegate,
                  ]) &&
                    checkIfUserChangesSelfOrNew() && (
                      <Grid item xs={6}>
                        <FormInputWrapper
                          id="municipal-user-edit-enabled-wrapper"
                          testId="municipalUserEditEnabledWrapper"
                          tooltip={
                            !!formFields[formCodes.fieldUserEnabled]
                              ?.tooltipText
                          }
                          tooltipProps={{
                            title: formFields[formCodes.fieldUserEnabled]?.text,
                            text:
                              formFields[formCodes.fieldUserEnabled]
                                ?.tooltipText,
                          }}
                          childrenFullWidth={false}
                          hideFlagSpacing
                        >
                          <AmoSwitch
                            color="primary"
                            control={control}
                            onClick={() => handleEnableUserClick()}
                            id="municipal-user-edit-enabled"
                            name="enabled"
                            testId="municipalUserEditEnabled"
                            label={formFields[formCodes.fieldUserEnabled]?.text}
                            labelPlacement="start"
                            helperText={
                              formFields[formCodes.fieldUserEnabled]?.helperText
                            }
                          />
                        </FormInputWrapper>
                      </Grid>
                    )}
                </Grid>
              </Grid>
              {(hasAmoRole || hasTreasurerRole) && (
                <Grid item>{primaryControl}</Grid>
              )}
              <Grid item>
                <Typography className={classes.subtitle} variant="subtitle2">
                  Editing Permissions
                </Typography>
              </Grid>
              <Grid item>
                <FormInputWrapper
                  id="municipal-user-edit-financials-wrapper"
                  testId="municipalUserEditFinancialsWrapper"
                  tooltip={
                    !!formFields[formCodes.fieldPermissionFinancial]
                      ?.tooltipText
                  }
                  tooltipProps={{
                    title: formFields[formCodes.fieldPermissionFinancial]?.text,
                    text:
                      formFields[formCodes.fieldPermissionFinancial]
                        ?.tooltipText,
                  }}
                  childrenFullWidth={false}
                  hideFlagSpacing
                >
                  <AmoCheckbox
                    color="primary"
                    control={control}
                    disabled={delegate}
                    id="municipal-user-edit-financials"
                    label={formFields[formCodes.fieldPermissionFinancial]?.text}
                    name="financialEditPermission"
                    testId="municipalUserEditFinancials"
                    // TODO: helperText not supported - fix
                    // helperText={
                    //   formFields[formCodes.fieldPermissionFinancial]?.helperText
                    // }
                  />
                </FormInputWrapper>
              </Grid>
              <Grid item>
                <FormInputWrapper
                  id="municipal-user-edit-projects-wrapper"
                  testId="municipalUserEditProjectsWrapper"
                  tooltip={
                    !!formFields[formCodes.fieldPermissionProject]?.tooltipText
                  }
                  tooltipProps={{
                    title: formFields[formCodes.fieldPermissionProject]?.text,
                    text:
                      formFields[formCodes.fieldPermissionProject]?.tooltipText,
                  }}
                  childrenFullWidth={false}
                  hideFlagSpacing
                >
                  <AmoCheckbox
                    color="primary"
                    control={control}
                    disabled={delegate}
                    id="municipal-user-edit-projects"
                    name="projectEditPermission"
                    testId="municipalUserEditProjects"
                    label={formFields[formCodes.fieldPermissionProject]?.text}
                    // TODO: helperText not supported - fix
                    // helperText={
                    //   formFields[formCodes.fieldPermissionProject]?.helperText
                    // }
                  />
                </FormInputWrapper>
              </Grid>
              <Grid item>
                <FormInputWrapper
                  id="municipal-user-respond-questionnaires-wrapper"
                  testId="municipalUserRespondQuestionnairesWrapper"
                  tooltip={
                    !!formFields[formCodes.fieldPermissionQuestionnaire]
                      ?.tooltipText
                  }
                  tooltipProps={{
                    title:
                      formFields[formCodes.fieldPermissionQuestionnaire]?.text,
                    text:
                      formFields[formCodes.fieldPermissionQuestionnaire]
                        ?.tooltipText,
                  }}
                  childrenFullWidth={false}
                  hideFlagSpacing
                >
                  <AmoCheckbox
                    color="primary"
                    control={control}
                    disabled={delegate}
                    id="municipal-user-respond-questionnaires"
                    name="questionnaireRespondPermission"
                    testId="municipalUserRespondQuestionnaires"
                    label={
                      formFields[formCodes.fieldPermissionQuestionnaire]?.text
                    }
                    // TODO: helperText not supported - fix
                    // helperText={
                    //   formFields[formCodes.fieldPermissionQuestionnaire]?.helperText
                    // }
                  />
                </FormInputWrapper>
              </Grid>
              <Grid item>
                <FormInputWrapper
                  id="municipal-user-edit-comm-wrapper"
                  testId="municipalUserEditCommunicationsWrapper"
                  tooltip={
                    !!formFields[formCodes.fieldPermissionCommunications]
                      ?.tooltipText
                  }
                  tooltipProps={{
                    title:
                      formFields[formCodes.fieldPermissionCommunications]?.text,
                    text:
                      formFields[formCodes.fieldPermissionCommunications]
                        ?.tooltipText,
                  }}
                  childrenFullWidth={false}
                  hideFlagSpacing
                >
                  <AmoCheckbox
                    color="primary"
                    control={control}
                    disabled={delegate}
                    id="municipal-user-edit-communications"
                    name="communicationsEditPermission"
                    testId="municipalUserEditCommunications"
                    label={
                      formFields[formCodes.fieldPermissionCommunications]?.text
                    }
                    // TODO: helperText not supported - fix
                    // helperText={
                    //   formFields[formCodes.fieldPermissionCommunications]
                    //     ?.helperText
                    // }
                  />
                </FormInputWrapper>
              </Grid>
            </Grid>
          </Grid>

          {/* Actions */}
          <AmoFormActions actions={formActions} hideLateralBorders />
        </Grid>
      )}
      <AmoConfirmPopover
        variant="default"
        open={isRemoveUserModalOpen}
        closePopover={() => handleModalClose()}
        title="Remove User?"
        text={onRemoveWarningText}
        showButton
        yesButtonText="Yes, Remove this user"
        yesButtonFunction={() => sendRemovalRequest(userIdFromParams)}
        yesButtonVariant="contained"
        noButtonText="No, Cancel"
        noButtonFunction={() => handleModalClose()}
        noButtonVariant="outlined"
      />
      <AmoConfirmPopover
        variant="default"
        open={isEnableUserModalOpen}
        closePopover={() => handleDisableUserModalClose()}
        title="Disable User?"
        text={onDisableWarningText}
        showButton
        yesButtonText="Yes, Disable this user"
        yesButtonVariant="contained"
        yesButtonFunction={() => handleDisableUserModalClose(false)}
        noButtonText="No, Cancel"
        noButtonFunction={() => handleDisableUserModalClose()}
        noButtonVariant="outlined"
      />
    </>
  );
};

export default MunicipalUserEditPage;
