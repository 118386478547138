import React from "react";
import {
  Grid,
  Icon,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  DialogContent,
  Popover,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "constants/colors";
import { usePopupState } from "material-ui-popup-state/hooks";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    width: "25%",
    borderRadius: "5px",
  },
  dialogContent: {
    borderTop: "none",
    borderBottomColor: colors.grey.main,
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  flag: {
    color: colors.yellow,
    padding: theme.spacing(0.5),
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "500",
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(1),
    color: colors.grey.icon,
  },
  closeButton: {
    maxWidth: "25%",
    minHeight: 50,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  caption: {
    fontSize: "0.875rem",
    fontWeight: "normal",
    fontStyle: "italic",
  },
}));

/**
 * A readonly flag popup component for municipal users
 *
 * @param {object} props
 * @returns {React.Component} The readonly flag popup component
 */
const AmoFlagViewPopover = (props) => {
  const { id, testId, title, ownerName, ownerEmail, comment } = props;

  const classes = useStyles();

  const popupState = usePopupState({ variant: "popover", popupId: id });

  const parseBreakLines = (text) =>
    text != null ? parse(`${text}`.split("\n").join("<br/>")) : null;

  return (
    <>
      <IconButton
        data-testid={`${testId ?? `${id}-test`}Button`}
        aria-controls={popupState.isOpen ? id : undefined}
        aria-haspopup="true"
        aria-label="flagIcon"
        className={classes.flag}
        onClick={popupState.open}
        onTouchStart={popupState.open}
      >
        <Icon className="material-icons">flag</Icon>
      </IconButton>
      <Popover
        id={popupState.popupId}
        anchorEl={popupState.anchorEl}
        open={popupState.isOpen}
        onClose={popupState.close}
        data-testid={testId ?? `${id}-test`}
        classes={{
          paper: classes.popoverPaper,
        }}
        getContentAnchorEl={null}
        elevation={4}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        keepMounted
        hideBackdrop
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DialogTitle
          disableTypography
          className={classes.dialogTitle}
          onClose={popupState.close}
        >
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            data-testid={`${testId ?? `${id}-test`}CloseIcon`}
            aria-label="closeIcon"
            className={classes.closeIcon}
            onClick={popupState.close}
          >
            <Icon className="material-icons">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <Grid container spacing={2}>
            <Grid item className={classes.fullWidth}>
              <Grid container direction="row" justifyContent="space-between">
                <Typography variant="body2">{ownerName}</Typography>
                <Typography variant="body1">
                  <a href={`mailto:${ownerEmail}`}>{ownerEmail}</a>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>{parseBreakLines(comment)}</Typography>
            </Grid>
            <Grid item className={classes.fullWidth}>
              <Typography
                variant="body1"
                className={classes.caption}
                align="center"
              >
                Please contact AMO if you have any concerns or questions.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid={`${testId ?? `${id}-test`}CloseButton`}
            className={classes.closeButton}
            onClick={popupState.close}
            color="primary"
            variant="contained"
            size="large"
          >
            Close
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};

// set the prop-types for this component
AmoFlagViewPopover.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  title: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  ownerEmail: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

AmoFlagViewPopover.defaultProps = {
  testId: null,
};

export default AmoFlagViewPopover;
