import { useQuery } from "react-query";
import { projectFinancialsQueryKeys } from "./projectFinancialsQueryKeys";
import {
  summariesService,
  // Used for JSDocs
  // eslint-disable-next-line import/named, no-unused-vars
  SummaryPayload,
} from "api/services/summaryService";

/**
 * Store server's state of project results
 *
 * @param {SummaryPayload} payload The summary request payload of the project results being fetched
 *
 * @returns The query object returned by the useQuery hook
 */
export const useProjectFinancialsData = (payload) => {
  const netRevenues = (financialData) =>
    financialData.allocation +
    financialData.interestEarned +
    financialData.proceedsFromDisposal;

  const netTransfers = (financialData) =>
    financialData.transferredIn - financialData.transferredOut;

  const netExpenditures = (financialData) =>
    financialData.airport +
    financialData.broadband +
    financialData.brownfield +
    financialData.capacityBldg +
    financialData.commEnergy +
    financialData.culture +
    financialData.disaster +
    financialData.fireStation +
    financialData.recreation +
    financialData.roadsBridges +
    financialData.shortline +
    financialData.shortsea +
    financialData.solidWaste +
    financialData.sport +
    financialData.transit +
    financialData.tourism +
    financialData.wastewater +
    financialData.water;

  return useQuery(
    projectFinancialsQueryKeys.projectFinancials(payload),
    () => summariesService.getProjectFinancials(payload),
    {
      select: ({ data: { years, cumulative } }) => ({
        years: years
          .sort((item1, item2) => item2.year - item1.year)
          .map((financialData) => ({
            ...financialData,
            netRevenues: netRevenues(financialData),
            netTransfers: netTransfers(financialData),
            netExpenditures: netExpenditures(financialData),
          })),
        cumulative: {
          ...cumulative,
          netRevenues: netRevenues(cumulative),
          netTransfers: netTransfers(cumulative),
          netExpenditures: netExpenditures(cumulative),
        },
      }),
      enabled: !!Object.keys(payload)?.length,
      refetchOnWindowFocus: false,
    }
  );
};
