import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  title: string,
 *  body: string,
 *  publish: boolean,
 *  creationDate: string,
 *  updateDate: string,
 *  updatedBy: number }} ProjectResult
 */

/**
 * @typedef {{
 *    year: number,
 *    openBalance: number,
 *    allocation: number,
 *    transferredIn: number,
 *    transferredOut: number,
 *    interestEarned: number,
 *    proceedsFromDisposal: number,
 *    closeBalance: number,
 *    airport: number,
 *    broadband: number,
 *    brownfield: number,
 *    capacityBldg: number,
 *    commEnergy: number,
 *    culture: number,
 *    disaster: number,
 *    fireStation: number,
 *    recreation: number,
 *    roadsBridges: number,
 *    shortline: number,
 *    shortsea: number,
 *    solidWaste: number,
 *    sport: number,
 *    transit: number,
 *    tourism: number,
 *    wastewater: number,
 *    water: number
 * }} ProjectFinancialDataObject
 */

/**
 * @typedef {{
 *	years:  Array.<ProjectFinancialDataObject>,
 *	cumulative: ProjectFinancialDataObject
 * }}  ProjectFinancialData
 */

/**
 * @typedef {{
 *   year: number,
 *   details:  Array.<{
 *     id: number,
 *     question: string,
 *     answer: string
 *   }>,
 *   attachments:  Array.<{
 *     id: number,
 *     fileName: string
 *   }>
 * }} ProjectQuestionnaire
 */

/**
 * @typedef {{
 *   internalId: string,
 *   amoId: number,
 *   municipalityId: number,
 *   title: string,
 *   description: string,
 *   categoryName: string,
 *   startDate: string,
 *   constructionEndDate: string,
 *   financingEndDate: string,
 *   totalCost: number,
 *   totalBudgeted: number,
 *   projectCumulativeFunding: number,
 *   funding: Array.<{
 *     year: number,
 *     fundingApplied: number
 *   }>
 * }} Project
 */

/**
 * @typedef {{
 *  yearStart: number,
 *  yearEnd: number,
 *  municipalityId: number,
 *  questionnaireType: string }} SummaryPayload
 */

/**
 * @typedef {{
 *  startYear: number,
 *  endYear: number }} YearsRange
 */

/**
 * @typedef {"project" | "questionnaire" | "financial"} SummaryType
 */

const api = new Api("api/summaries");
export const summariesService = {
  /**
   * @param {SummaryPayload} payload
   * @returns {Promise<AxiosResponse<ProjectResult[]>>}
   */
  getProjectResults: (payload) => api.getById("project/results", payload),
  /**
   * @param {SummaryPayload} payload
   * @returns {Promise<AxiosResponse<ProjectFinancialData>>}
   */
  getProjectFinancials: (payload) => api.getById("financial", payload),
  /**
   * @param {SummaryPayload} payload
   * @returns {Promise<AxiosResponse<ProjectQuestionnaire[]>>}
   */
  getProjectQuestionnaires: (payload) => api.getById("questionnaires", payload),
  /**
   * @param {SummaryPayload} payload
   * @returns {Promise<AxiosResponse<Project[]>>}
   */
  getProjects: (payload) => api.getById("projects", payload),
  /**
   * @param {SummaryType} summaryType
   * @returns {Promise<AxiosResponse<YearsRange>>}
   */
  getYearsRange: (summaryType) => api.getById(`${summaryType}/years-range`),
};
