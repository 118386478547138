import React from "react";
import { viewportContext } from "../contexts/ViewportContext";

/**
 * React's context hook.
 *
 * @returns {any} The current value for the viewport context.
 */
const useViewport = () => {
  const { width } = React.useContext(viewportContext);

  return { width };
};

export { useViewport };
