/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router-dom";
import { setupApiInterceptorConfig } from "api/api";
import { userService } from "api/services/userService";
import { useIsMounted } from "hooks/useIsMounted";

/**
 * The base user context.
 */
const UserContext = createContext(null);

/**
 * React's context hook.
 *
 * @returns {{
 *   user: {
 *     isAuthenticated: boolean,
 *     name: string,
 *     firstName: string,
 *     lastName: string,
 *     phoneNumber: string,
 *     email: string,
 *     id: string,
 *     imisId: any,
 *     municipalityId: any,
 *     roles: string[],
 *     loaded: boolean
 *   },
 *   hasRoles: function(string[]): boolean
 * }} The current value for the user context.
 */
const useUserContext = () => useContext(UserContext);

/**
 * This is identical to useContext(UserContext)--basically a non-hook option.
 *
 * @returns {any} The current value for the user context.
 */
const UserConsumer = () => UserContext.Consumer;

/**
 * A user context wrapper, provides context to children.
 *
 * @param {Node} {children}
 * @returns {Element} Wrapped children, that will now have access to the user context.
 */
const UserProvider = ({ children }) => {
  const mounted = useIsMounted();

  const {
    userData,
    isLoading,
    signOutRedirect,
    signOut,
    userManager,
  } = useAuth();
  const history = useHistory();
  setupApiInterceptorConfig(signOutRedirect, history, signOut);
  const [isSilentLoading, setIsSilentLoading] = useState(false);
  const [isInternalLoading, setIsInternalLoading] = useState(true);

  const user = useMemo(
    () => ({
      isAuthenticated: userData?.profile?.sub !== undefined,
      name: userData?.profile?.name,
      firstName: userData?.profile?.given_name,
      lastName: userData?.profile?.family_name,
      phoneNumber: userData?.profile?.phone_number,
      email: userData?.profile?.email,
      id: userData?.profile?.sub,
      imisId: userData?.profile?.imis_id,
      municipalityId: userData?.profile?.municipality_id,
      loaded: !isLoading && !isSilentLoading && !isInternalLoading,
      roles: Array.isArray(userData?.profile?.role)
        ? userData?.profile?.role
        : [userData?.profile?.role],
    }),
    [userData?.profile, isLoading, isSilentLoading, isInternalLoading]
  );

  const silentSignIn = async (args) => {
    setIsSilentLoading(true);
    await userManager.signinSilent(args);
    if (!mounted.current) {
      return;
    }
    setIsSilentLoading(false);
  };

  const hasRoles = (roles) => user.roles.some((role) => roles.includes(role));

  const hasAllRoles = (roles) =>
    roles?.every((role) => user.roles.includes(role)) ?? true;

  // Effect to check the stored user session on a private route
  useEffect(async () => {
    if (!isLoading && isInternalLoading) {
      if (user?.isAuthenticated) {
        await userService.checkSession();
      }

      if (mounted.current) {
        setIsInternalLoading(false);
      }
    }
  }, [user?.isAuthenticated, isLoading]);

  return (
    <UserContext.Provider
      value={{
        user,
        hasRoles,
        hasAllRoles,
        silentSignIn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserContext, useUserContext, UserProvider, UserConsumer };
