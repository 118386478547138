import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, MenuItem, Link } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import FormInputWrapper from "components/municipal/FormInputWrapper";
import AmoFormActions from "components/AmoFormActions";
import AmoTextField from "components/inputs/AmoTextField";
import colors from "constants/colors";
import {
  useProjectCategoryData,
  useProjectCategories,
} from "./projectReportCategoryHooks";
import {
  formIds,
  projectReportCategoryCodes as formCodes,
} from "constants/formContentManagement";
import { projectService } from "api/services/projectService";
import {
  steps,
  formStatuses,
  localRoadsCategoryId,
} from "constants/projectReport";
import { useNavigationHelper } from "hooks/navigationHook";
import { useFormManagement } from "hooks/formManagementHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { useUserContext } from "contexts/UserContext";
import { roleGroups } from "constants/user";
import { range } from "utils/number";
import { useProjectFunds } from "./projectReportFinancialsHooks";
import { useMunicipalContext } from "contexts/MunicipalContext";
import DeletionModal from "components/DeletionModal";
import DeletionType from "enums/deletionType";
import {
  deleteButtonTooltipText,
  deleteType,
} from "functions/projectDeleteMunicipalUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  body: {
    // overflow: "hidden auto",
    flexGrow: 1,
    flexBasis: 0,
    // 0px 84px 0px 84px
    padding: "0rem 5.25rem 0rem 5.25rem",
    "& > .MuiGrid-item": {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "1.5rem",
    },
  },
  scrollableContent: {
    overflow: "hidden auto",
  },
  selectWidth: {
    // 328px
    width: "20.5rem",
  },
  fullWidth: {
    width: "100%",
  },
  link: {
    color: colors.green.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    // 8px
    marginTop: "-0.5rem",
  },
  captionText: {
    // 14px
    fontSize: "0.875rem",
  },
  warningText: {
    // 14px
    fontSize: "0.875rem",
    color: colors.red.dark,
    marginTop: "-1.5rem",
  },
  outlinedButton: {
    backgroundColor: "transparent",
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
  submitChangeButton: {
    // 288px
    width: "18rem",
    "&.Mui-disabled": {
      color: colors.grey.medium,
      border: `0.2em solid ${colors.grey.medium}`,
    },
  },
  deleteButton: {
    // 288px
    width: "12rem",
    color: colors.red.main,
    border: `0.2em solid ${colors.red.main}`,
    "&.Mui-disabled": {
      color: colors.grey.medium,
      border: `0.2em solid ${colors.grey.medium}`,
    },
  },
  formActions: {
    // 16px
    marginTop: "1rem",
  },
  warning: {
    "& label.Mui-focused": {
      color: colors.yellow,
    },
    "& legend.Mui-focused": {
      color: colors.yellow,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root:not(.Mui-disabled)": {
      "& fieldset": {
        borderColor: colors.yellow,
      },
      "&:hover fieldset": {
        borderColor: colors.yellow,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.yellow,
      },
      "& .MuiInputAdornment-root button": {
        color: colors.yellow,
      },
    },
  },
}));

/**
 * A form component for editing Project Report Category
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.projectId - sets the project id used to load the project data, pass 'new' for new project [required]
 * @param {number} props.activeStep - sets the active step of the project report [required]
 * @param {Function} props.onProjectCreate - function called when the project is created (params: { projectId })
 * @param {Function} props.onFormStatusChange - function called when the form status changes (params: { @see formStatuses })
 * @param {Function} props.onNextStepRequest - function called when move to the next step is requested (params: none)
 * @param {Function} props.onProjectDataChange - function called when project data changes (params: categoryData)
 * @param {boolean} props.isPastConstructionEndDate - boolean to handle disabling controls when past end of construction date.
 * @param {boolean} props.projectDeletionRequested - boolean to handle disabling deletion button if the request was already sent.
 * @param {Function} props.refetchValidationInfo - refetch Validation Info to update projectDeletionRequested value.
 * @returns - The form component
 */
const ProjectReportCategoryForm = (props) => {
  const {
    projectId,
    activeStep,
    onProjectCreate,
    onFormStatusChange,
    onNextStepRequest,
    onProjectDataChange,
    isPastConstructionEndDate,
    projectDeletionRequested,
    refetchValidationInfo,
  } = props;

  const classes = useStyles();
  const { hasRoles } = useUserContext();
  const isTreasurerOrDelegate = hasRoles(
    roleGroups.municipal.treasurerOrDelegate
  );
  const hasProjectsPermission = hasRoles(roleGroups.municipal.projects);
  const { navigateBack } = useNavigationHelper();
  const { formFields } = useFormManagement(formIds.projectReportCategory);
  const { showSnackbar } = useSnackbar();
  const { getMunicipality } = useMunicipalContext();

  const mounted = useIsMounted();

  const [flags, setFlags] = useState({});
  const [categories, setCategories] = useState({});
  const [subcategories, setSubcategories] = useState([]);
  const [formStatus, setFormStatus] = useState(formStatuses.untouched);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  // define schema for form validation
  const ProjectCategoryValidationSchema = yup
    .object({
      categoryId: yup
        .string()
        .default("")
        .required("Project Category is required"),
      subcategoryId: yup
        .string()
        .nullable()
        .default("")
        .when("categoryId", {
          is: (categoryValue) =>
            categoryValue === localRoadsCategoryId.toString(),
          then: yup.string().required("Subcategory is required"),
        }),
      changeRequestReason: yup
        .string()
        .default("")
        .when("$validateFormStatus", (validateFormStatus, schema) => {
          if (
            validateFormStatus === formStatuses.completed ||
            validateFormStatus === formStatuses.changed ||
            validateFormStatus === formStatuses.flagged
          ) {
            return yup
              .string()
              .required("Reason for change request is required");
          }
          return yup.string();
        }),
    })
    .required();

  // Default form values for react-hook-form
  // TODO: figure out a way to get these from react-query instead (initial value or placeholder feature)
  const defaultValues = {
    categoryId: "",
    subcategoryId: "",
    changeRequestReason: "",
  };

  // Query project funds for deleteion
  const { data: fundsResult } = useProjectFunds(projectId, false);
  const reportingYear = getMunicipality()?.reportingYear;
  const hasPriorExpenditures = fundsResult?.data?.funds.some(
    ({ year }) => year < reportingYear
  );

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState,
    setValue,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possibly onBlur) is very important for validation
    mode: "onChange",
    context: { validateFormStatus: formStatus },
    resolver: yupResolver(ProjectCategoryValidationSchema),
  });

  // Query project categories
  const {
    data: categoriesResult,
    isFetched: categoriesAreFetched,
  } = useProjectCategories();

  // Query project category data
  const {
    data: projectResult,
    refetch: refetchProjectData,
    isFetched,
  } = useProjectCategoryData(projectId);

  const previousProject = useRef(projectResult?.data);

  // returns projectDeletionRequested in Deletion Requested or empty string to be in line with the same value from ProjectListPage.jsx
  const projectDeletionRequestedInString = projectDeletionRequested
    ? "Deletion Requested"
    : "";

  const getDeleteType = () => {
    const isRequestDeletion =
      deleteType(
        isTreasurerOrDelegate,
        hasPriorExpenditures,
        projectDeletionRequestedInString
      ) === DeletionType.REQUEST;

    return {
      text: isRequestDeletion ? "Request Deletion" : "Delete Project",
      action: isRequestDeletion ? refetchValidationInfo : navigateBack,
    };
  };

  // Reset the form with fetched user details (as default values)
  useEffect(() => {
    if (projectResult?.data) {
      reset(projectResult?.data);
      setFlags(projectResult.data.flags || {});
      handleFormStatusUpdate(
        getFormStatus(projectResult.data, projectResult.data?.flags)
      );

      previousProject.current = projectResult.data;
      onProjectDataChange(projectResult?.data);
    }
  }, [projectResult]);

  useEffect(() => {
    if (categoriesResult?.data) {
      setCategories(categoriesResult.data);
    }
  }, [categoriesResult]);

  const [categoryWatch] = useWatch({
    control,
    name: ["categoryId"],
  });

  useLayoutEffect(() => {
    if (Object.entries(categories)?.length > 0 && categoryWatch) {
      setSubcategories(categories[categoryWatch]?.subcategories ?? []);
      setValue("subcategoryId", undefined);
    }
  }, [categories, categoryWatch]);

  /**
   * Category Change Requested change detection
   */
  const [categoryChangeRequested] = getValues(["categoryChangeRequested"]);

  const projectHasChanged = (projectRef) => {
    const previousProjectRef = previousProject.current;

    // If there is no previous project it's a new project
    if (!previousProjectRef) {
      return true;
    }
    // Check if category changed
    if (projectRef.categoryId !== previousProjectRef.categoryId) {
      return true;
    }
    if (
      projectRef.subcategoryId !== previousProjectRef.subcategoryId &&
      projectRef.subcategoryId !== undefined
    ) {
      return true;
    }

    return false;
  };

  const deleteModalClose = () => {
    setShowDeletionModal(false);
  };

  const getFormStatus = (projectRef, flagsRef) => {
    // TODO: check backend result and ids on integration
    if (flagsRef && Object.entries(flagsRef).length > 0) {
      return formStatuses.flagged;
    }

    if (projectRef.categoryId) {
      if (projectRef.categoryChangeRequested) {
        return formStatuses.changed;
      }
      if (
        projectRef.categoryId === localRoadsCategoryId &&
        !projectRef.subcategoryId
      ) {
        return formStatuses.untouched;
      }
      return formStatuses.completed;
    }

    return formStatuses.untouched;
  };

  const handleFormStatusUpdate = (newFormStatus) => {
    if (newFormStatus !== formStatus) {
      setFormStatus(newFormStatus);
      onFormStatusChange(newFormStatus);
    }
  };

  const saveProjectData = async (
    { categoryId, subcategoryId, changeRequestReason },
    isCategoryChange = false
  ) => {
    try {
      const payload = { categoryId };

      if (subcategoryId) {
        payload.subcategoryId = subcategoryId;
      }

      if (isCategoryChange) {
        payload.id = projectId;
        payload.changeRequestReason = changeRequestReason;

        await projectService.changeCategory(payload.id, payload);

        if (!mounted.current) {
          return false;
        }

        refetchProjectData();
      } else {
        const response = await projectService.create(payload);

        if (!mounted.current) {
          return false;
        }

        onProjectCreate(response.data);
        onNextStepRequest();
      }

      showSnackbar("Project updated successfully.");
      return true;
    } catch {
      if (mounted.current) {
        showSnackbar(errorMessages.generic, snackbarTypes.error);
      }
      return false;
    }
  };

  const handleContinueClick = async (formValues) => {
    const isSuccessSave = await saveProjectData(formValues);

    if (!mounted.current) {
      return;
    }

    if (isSuccessSave) {
      onNextStepRequest();
    }
  };

  const renderInputWrapper = (
    id,
    testId,
    fieldCode,
    children,
    fullWidth = false
  ) => (
    <FormInputWrapper
      id={`${id}-wrapper`}
      testId={`${testId}Wrapper`}
      tooltip={!!formFields[fieldCode]?.tooltipText}
      tooltipProps={
        formFields[fieldCode]?.tooltipText
          ? {
              title: formFields[fieldCode]?.text,
              text: formFields[fieldCode]?.tooltipText,
            }
          : {}
      }
      flag={!!flags[fieldCode]}
      flagProps={
        flags[fieldCode]
          ? {
              ...flags[fieldCode],
              title: formFields[fieldCode]?.text,
            }
          : {}
      }
      childrenFullWidth={fullWidth}
      justifyContent="center"
    >
      {children}
    </FormInputWrapper>
  );

  const { isValid, isSubmitting, isDirty } = formState;

  const renderFormActions = (formStatusRef) => {
    if (
      formStatusRef === formStatuses.completed ||
      formStatusRef === formStatuses.changed ||
      formStatusRef === formStatuses.flagged
    ) {
      return [
        {
          testId: "projReportSubmitChangeButton",
          label: "Submit Change Request",
          variant: "outlined",
          className: clsx(classes.submitChangeButton, classes.outlinedButton),
          disabled:
            !isDirty ||
            isSubmitting ||
            !isValid ||
            categoryChangeRequested ||
            isPastConstructionEndDate ||
            !projectHasChanged(getValues()) ||
            !hasProjectsPermission,
          onClick: handleSubmit((formValues) =>
            saveProjectData(formValues, true)
          ),
        },
        {
          testId: "projReportNextButton",
          label: "Next",
          variant: "contained",
          onClick: onNextStepRequest,
        },
        {
          testId: "projReportDeleteButton",
          label: getDeleteType().text,
          variant: "outlined",
          color: "secondary",
          disabled: !isTreasurerOrDelegate || projectDeletionRequested,
          onClick: () => setShowDeletionModal(true),
          tooltipText: deleteButtonTooltipText(
            isTreasurerOrDelegate,
            hasPriorExpenditures,
            projectDeletionRequestedInString
          ),
        },
      ];
    }
    return [
      {
        testId: "projReportContinueButton",
        label: "Continue",
        disabled: !isDirty || isSubmitting || !isValid,
        onClick: handleSubmit((formValues) => handleContinueClick(formValues)),
      },
      {
        testId: "projReportCancelButton",
        label: "Cancel",
        color: "secondary",
        variant: "outlined",
        className: classes.outlinedButton,
        onClick: navigateBack,
      },
    ];
  };

  const addHttpProtocolIfNotFound = (url) => {
    if (!url) {
      return "#";
    }
    if (url.startsWith("http")) {
      return url;
    }
    return `http://${url}`;
  };

  return (
    <>
      <DeletionModal
        open={showDeletionModal}
        id={parseInt(projectId, 10)}
        closeFunction={deleteModalClose}
        updateFunction={getDeleteType().action}
        deletionType={deleteType(
          isTreasurerOrDelegate,
          hasPriorExpenditures,
          projectDeletionRequestedInString
        )}
      />
      {(!isFetched || !categoriesAreFetched) &&
        projectId !== "new" &&
        range(0, 2).map((index) => (
          <Skeleton key={index} width="100%" height="8rem" />
        ))}
      {categoriesAreFetched && (isFetched || projectId === "new") && (
        <Grid
          container
          className={classes.root}
          direction="column"
          spacing={0}
          wrap="nowrap"
        >
          <Grid item xs className={classes.scrollableContent}>
            <Grid
              container
              className={classes.body}
              direction="column"
              spacing={0}
              wrap="nowrap"
            >
              <Grid item>
                <Typography
                  className={classes.fullWidth}
                  variant="body1"
                  component="span"
                >
                  {parse(
                    formStatus === formStatuses.completed ||
                      formStatus === formStatuses.changed
                      ? formFields[formCodes.descriptionFormSaved]?.text || ""
                      : formFields[formCodes.descriptionForm]?.text || ""
                  )}
                </Typography>
              </Grid>
              {categoryChangeRequested ? (
                <Grid item>
                  <Typography
                    className={clsx(
                      classes.fullWidth,
                      classes.warningText,
                      classes.darkRed
                    )}
                    variant="body2"
                  >
                    Change request has already been submitted.
                  </Typography>
                </Grid>
              ) : null}

              <Grid item>
                {renderInputWrapper(
                  "proj-report-category-field",
                  "projReportCategoryField",
                  formCodes.category,
                  <AmoTextField
                    control={control}
                    id="proj-report-category-field"
                    name="categoryId"
                    testId="projReportCategoryField"
                    className={clsx(
                      classes.selectWidth,
                      flags[formCodes.category] ? classes.warning : null
                    )}
                    select
                    label={formFields[formCodes.category]?.text || ""}
                    variant="outlined"
                    required
                    disabled={
                      categoryChangeRequested ||
                      !hasProjectsPermission ||
                      isPastConstructionEndDate
                    }
                    helperText={formFields[formCodes.category]?.helperText}
                    placeholder={formFields[formCodes.category]?.text || ""}
                  >
                    {Object.entries(categories)?.map(([key, value]) => (
                      <MenuItem
                        key={`projReportCategoryField-${value.id}`}
                        value={value.id}
                      >
                        {value.name}
                      </MenuItem>
                    ))}
                  </AmoTextField>
                )}
              </Grid>

              <Grid item>
                <Link
                  data-testid="projReportCategoryEligibilityLink"
                  href={addHttpProtocolIfNotFound(
                    formFields[formCodes.isProjectEligible]?.url
                  )}
                  className={classes.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    className={classes.captionText}
                    color="primary"
                    aria-label={
                      formFields[formCodes.isProjectEligible]?.helperText
                    }
                  >
                    {formFields[formCodes.isProjectEligible]?.text}
                  </Typography>
                </Link>
              </Grid>

              {subcategories && subcategories.length > 0 ? (
                <Grid item>
                  {renderInputWrapper(
                    "proj-report-subcategory-field",
                    "projReportSubcategoryField",
                    formCodes.subcategory,
                    <AmoTextField
                      control={control}
                      id="proj-report-subcategory-field"
                      name="subcategoryId"
                      testId="projReportSubcategoryField"
                      className={clsx(
                        classes.selectWidth,
                        flags[formCodes.subcategory] ? classes.warning : null
                      )}
                      select
                      label={formFields[formCodes.subcategory]?.text || ""}
                      variant="outlined"
                      required
                      disabled={
                        categoryChangeRequested ||
                        !hasProjectsPermission ||
                        isPastConstructionEndDate
                      }
                      helperText={formFields[formCodes.subcategory]?.helperText}
                      placeholder={
                        formFields[formCodes.subcategory]?.text || ""
                      }
                    >
                      {subcategories.map((value) => (
                        <MenuItem
                          key={`projReportSubcategoryField-${value.id}`}
                          value={value.id}
                        >
                          {value.name}
                        </MenuItem>
                      ))}
                    </AmoTextField>
                  )}
                </Grid>
              ) : null}

              {formStatus === formStatuses.completed ||
              formStatus === formStatuses.changed ||
              formStatus === formStatuses.flagged ? (
                <Grid item>
                  {renderInputWrapper(
                    "proj-report-category-change-reason-field",
                    "projReportCategoryChangeReasonField",
                    formCodes.changeRequestReason,
                    <AmoTextField
                      multiline
                      rows={1}
                      rowsMax={10}
                      control={control}
                      id="proj-report-category-change-reason-field"
                      name="changeRequestReason"
                      testId="projReportCategoryChangeReasonField"
                      className={clsx(
                        classes.fullWidth,
                        flags[formCodes.changeRequestReason]
                          ? classes.warning
                          : null
                      )}
                      label={
                        formFields[formCodes.changeRequestReason]?.text || ""
                      }
                      required
                      disabled={
                        categoryChangeRequested ||
                        !hasProjectsPermission ||
                        isPastConstructionEndDate
                      }
                      helperText={
                        formFields[formCodes.changeRequestReason]?.helperText
                      }
                    />,
                    true
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {activeStep === steps.category && (
            <Grid item>
              <AmoFormActions
                className={classes.formActions}
                actions={renderFormActions(formStatus)}
                spacing="1.25rem"
                hideLateralBorders
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

// set the prop-types for this component
ProjectReportCategoryForm.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  activeStep: PropTypes.oneOf([
    steps.category,
    steps.generalInformation,
    steps.financials,
    steps.communications,
    steps.results,
  ]).isRequired,
  onProjectCreate: PropTypes.func,
  onFormStatusChange: PropTypes.func,
  onNextStepRequest: PropTypes.func,
  onProjectDataChange: PropTypes.func,
  isPastConstructionEndDate: PropTypes.bool,
  projectDeletionRequested: PropTypes.bool,
  refetchValidationInfo: PropTypes.func,
};

ProjectReportCategoryForm.defaultProps = {
  onProjectCreate: () => {},
  onFormStatusChange: () => {},
  onNextStepRequest: () => {},
  onProjectDataChange: () => {},
  isPastConstructionEndDate: false,
  projectDeletionRequested: true,
  refetchValidationInfo: () => {},
};

export default ProjectReportCategoryForm;
