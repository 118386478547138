import React, { Fragment, useState, useEffect } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import Project from "./Project";
import ListPageWrapper from "components/ListPageWrapper";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import FilterActionsBar from "../FilterActionsBar";
import { useProjectsResultsData } from "./projectResultsHooks";
import { useUserContext } from "contexts/UserContext";
// Used for JSDocs
// eslint-disable-next-line import/named, no-unused-vars
import { SummaryPayload } from "api/services/summaryService";
import { useYearsRange } from "../hooks/yearsRangeHooks";
import { useIsMounted } from "hooks/useIsMounted";
import { useSummaryStyles } from "../hooks/summaryStylesHook";

import { pdfReportsService } from "api/services/pdfReportService";
import { printFile, downloadFile } from "utils/pdf";
import colors from "constants/colors";

/**
 * Component to display project results page
 *
 * @returns {Function} The ProjectResultsPage component
 */
const ProjectResultsPage = () => {
  const { user } = useUserContext();
  const summaryClasses = useSummaryStyles();
  const { showSnackbar } = useSnackbar();

  const mounted = useIsMounted();

  const { data: years } = useYearsRange("project");

  const [payload, setPayload] = useState(/** @type {SummaryPayload} */ ({}));
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const {
    data: projectResults,
    isFetched,
    error: projectResultsError,
  } = useProjectsResultsData(payload);

  useEffect(() => {
    if (projectResultsError?.response?.status === 500) {
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  }, projectResultsError);

  const getPdfFile = async () => {
    try {
      const { data } = await pdfReportsService.getSummaryProjectResults(
        user.municipalityId,
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
    return null;
  };

  const handleDownload = async () => {
    setIsLoadingFile(true);
    setIsLoadingDownload(true);

    const { yearStart, yearEnd } = payload;
    const pdfFile = await getPdfFile();

    if (!mounted.current) {
      return;
    }

    if (pdfFile) {
      let fileName = `AMO - Summary - Project Results - ${yearStart}`;
      if (yearStart !== yearEnd) {
        fileName += ` to ${yearEnd}`;
      }
      fileName += ".pdf";
      downloadFile(pdfFile, fileName);
    }

    setIsLoadingFile(false);
    setIsLoadingDownload(false);
  };

  const handlePrint = async () => {
    setIsLoadingFile(true);
    setIsLoadingPrint(true);

    const pdfFile = await getPdfFile();

    if (!mounted.current) {
      return;
    }

    if (pdfFile) {
      printFile(pdfFile);
    }

    setIsLoadingFile(false);
    setIsLoadingPrint(false);
  };

  return (
    <ListPageWrapper>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Typography className={summaryClasses.title} variant="h2">
            Project Results
          </Typography>
        </Grid>
        <Grid item>
          <FilterActionsBar
            years={years}
            onFilter={({ from, to }) =>
              setPayload({
                municipalityId: user.municipalityId,
                yearStart: from,
                yearEnd: to,
              })
            }
            onDownload={handleDownload}
            onPrint={handlePrint}
            disableOptionalButtons={
              !isFetched || !projectResults?.length || isLoadingFile
            }
            showPrintLoading={isLoadingPrint}
            showDownloadLoading={isLoadingDownload}
          />
        </Grid>
        {isFetched && !projectResults?.length && (
          <Grid item>
            <Typography>
              {projectResultsError?.response?.status === 500
                ? `${errorMessages.dataLoad}`
                : "No results found for the selected reporting period"}
            </Typography>
          </Grid>
        )}
        <Grid item className={summaryClasses.projectsList}>
          <div className={summaryClasses.listWrapper}>
            {projectResults &&
              projectResults?.map(({ year, projects }) => (
                <Fragment key={year}>
                  <Grid item className={summaryClasses.yearTitle}>
                    <Typography
                      variant="h3"
                      style={{ color: colors.green.dark }}
                    >
                      {year}
                    </Typography>
                  </Grid>
                  <Divider className={summaryClasses.divider} />
                  {projects?.map((project) => (
                    <Fragment key={project.id}>
                      <Grid item className={summaryClasses.projectWrapper}>
                        <Project project={project} />
                      </Grid>
                      <Divider className={summaryClasses.divider} />
                    </Fragment>
                  ))}
                </Fragment>
              ))}
          </div>
        </Grid>
      </Grid>
    </ListPageWrapper>
  );
};

export default ProjectResultsPage;
