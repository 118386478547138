import React, { useState, useEffect, useMemo, useRef } from "react";

import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  Box,
  CircularProgress,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import * as yup from "yup";

import { financialsService } from "api/services/financialsService";
import { pdfReportsService } from "api/services/pdfReportService";

import AmoFormActions from "components/AmoFormActions";
import AmoTextField from "components/inputs/AmoTextField";
import AmoTooltip from "components/AmoTooltip";
import FileUploadList from "components/FileUploadList";
import FlagComponent from "components/amo/FlagComponent";
import FormInputWrapper from "components/municipal/FormInputWrapper";
import { useAnnualReportNavBar } from "components/annualReport/annualReportNavBarHooks";

import colors from "constants/colors";
import { errorMessages } from "constants/errorMessages";
import { fileTypes, fileAssociations } from "constants/fileTypes";
import {
  formIds,
  financialReportingCodes as formCodes,
} from "constants/formContentManagement";
import { snackbarTypes } from "constants/snackbar";

import { useFilterContext } from "contexts/FilterContext";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { useSnackbar } from "contexts/SnackbarContext";
import { useYearContext } from "contexts/YearContext";

import { useFormManagement } from "hooks/formManagementHook";
import { useNavigationBlocker } from "hooks/navigationHook";
import { useIsMounted } from "hooks/useIsMounted";
import { useWindowDimensions } from "hooks/windowDimensionsHook";

import { fonts, getTextWidth } from "utils/htmlUtils";
import { formatToCurrency, Dollars } from "utils/number";
import { printFile } from "utils/pdf";

import { useFinancialReport } from "./financialReportHooks";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  thinnerGrid: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  columnHeader: {
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  columnHeaderTop: {
    textDecoration: "underline",
  },
  columnHeaderBottom: {
    fontWeight: "normal",
  },
  balance: {
    fontSize: "0.9rem",
  },
  sectionTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  toolTip: {
    paddingTop: "0.4rem",
    paddingLeft: "0.8rem",
  },
  actions: {
    paddingLeft: "0.8rem",
  },
  inputField: {
    width: "100%",
    "& input": {
      textAlign: "right",
    },
  },
  sectionDivider: {
    backgroundColor: "black",
    marginBottom: "1rem",
    marginTop: "0.5rem", // space for helper text to fit
  },
  boldLabel: {
    fontWeight: "bold",
  },
  uploadButton: {
    width: "auto",
    padding: 0,
    justifyContent: "left",
    color: colors.green.main,
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
  rowHeight: {
    minHeight: "3rem",
    height: "100%",
  },
  negativeBalance: {
    color: colors.red.main,
  },
  negativeBalanceMessage: {
    color: colors.red.main,
    fontStyle: "italic",
  },
  smallRowHeight: {
    height: "1.5rem",
  },
  formContainer: {
    position: "relative",
    paddingBottom: "7rem",
  },
  formActions: {
    position: "fixed",
    bottom: 0,
    left: 0,
    background: "white",
  },
  rowLabel: {
    color: colors.green.dark,
  },
  loadingProgress: {
    color: colors.grey.light,
  },
  previousValue: {
    color: colors.red.main,
    textDecoration: "line-through",
  },
}));

const formatToCurrencyOrClear = (value) =>
  isNaN(value) ? "" : formatToCurrency(value);

const getPositiveValuesMarginStyles = (val) => ({
  marginRight: !formatToCurrencyOrClear(val)?.includes(")")
    ? `${getTextWidth(")", fonts.body1Bold)}px`
    : "0px",
});

const shimmerField = (shouldShimmer, component) =>
  shouldShimmer ? <Skeleton /> : component;

const transformEmptyNumber = (value, originalValue) => {
  if (isNaN(value) && originalValue === "") {
    return null;
  }

  return value;
};

const FinancialsFormEditSchema = yup
  .object({
    proceedsFromAssetDisposal: yup
      .string()
      .nullable()
      .transform(transformEmptyNumber),
    interestEarned: yup.string().nullable().transform(transformEmptyNumber),
    transfersReceived: yup.string().nullable().transform(transformEmptyNumber),
    transfersAdministered: yup
      .string()
      .nullable()
      .transform(transformEmptyNumber),
  })
  .required();

export const NumberFormatCustom = React.forwardRef((props, ref) => {
  // TODO: Should we pass the inputRef from MUI through to the input component? If not, comment why.
  const { onChange, isNegative, inputRef, ...other } = props;
  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      prefix={isNegative ? "($" : "$"}
      suffix={isNegative ? ")" : ""}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isNegative: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
};

NumberFormatCustom.defaultProps = {
  inputRef: null,
};

const FinancialSection = (props) => {
  const {
    control,
    title,
    toolTipTitle,
    toolTipText,
    lines,
    net,
    isLoadingYear,
    isReadOnly,
    updateFormWithValue,
    hideTooltips,
    fieldActions,
    displayOnly,
    netWarnings,
    showFlags,
    // eslint-disable-next-line no-unused-vars
    hasPermissions,
  } = props;
  const classes = useStyles();
  const { screenWidth } = useWindowDimensions();
  // When screen is too small, or the screen has enough space to display uncollapsed side-nav bar,
  // there is no space to show on the right so top is used instead
  const tooltipPlacement =
    screenWidth <= 1228 || (screenWidth >= 1440 && screenWidth <= 1525)
      ? "top"
      : "right";
  return (
    <>
      <Grid item>
        <Box pb={3} pt={2}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={classes.sectionTitle}>{title}</Typography>
            </Grid>
            {!hideTooltips && toolTipText ? (
              <Grid item className={classes.toolTip}>
                <AmoTooltip
                  tooltipTitle={toolTipTitle}
                  tooltipText={toolTipText}
                  isInteractive={!!toolTipText?.includes("<a")}
                  tooltipPlacement={tooltipPlacement}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid container direction="column">
        {lines.map((line) => {
          if (!line.isUpload) {
            const getLineCurrentValue = () =>
              line.isSubtracted && line.currentValue > 0
                ? -1 * line.currentValue
                : line.currentValue;

            const getLineCumulativeValue = () =>
              line.isSubtracted && line.cumulativeValue > 0
                ? -1 * line.cumulativeValue
                : line.cumulativeValue;

            return (line.isPrevious && line.currentValue == null) ||
              line.hideCurrentYearField ? (
              ""
            ) : (
              <Grid item key={line.field} className={classes.rowHeight}>
                <Grid
                  container
                  className={classes.thinnerGrid}
                  alignItems="center"
                >
                  <Grid item xs={5}>
                    <Typography
                      className={classes.rowLabel}
                      style={{ marginLeft: line.hasLeftMargin ? 64 : null }}
                    >
                      {line.label}
                      {line.warningType !== "None" && showFlags && (
                        <FlagComponent
                          type={line.warningType}
                          message={line.tooltipMessage}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {shimmerField(
                      isLoadingYear,
                      <FormInputWrapper
                        id={`${line.field}-wrapper`}
                        testId={`${line.field}-test-wrapper`}
                        flag={!!line.flag}
                        flagProps={
                          line.flag ? { ...line.flag, title: line.label } : {}
                        }
                        tooltip={false}
                        hideTooltipSpacing
                        hideFlagSpacing={!line.isEditable}
                      >
                        {line.isEditable ? (
                          <AmoTextField
                            id={`financials-input-${line.field}`}
                            control={control}
                            name={line.field}
                            className={classes.inputField}
                            variant="standard"
                            disabled={isReadOnly && !displayOnly}
                            focused={displayOnly}
                            helperText={line.helperText}
                            InputProps={{
                              readOnly: isReadOnly || displayOnly,
                              onBlur: () => updateFormWithValue(line.field),
                            }}
                            pattern={/[^0-9.]+/g}
                            numberFormatProps={{
                              isNegative: line.isSubtracted,
                            }}
                            isCurrencyField
                          />
                        ) : (
                          <Typography
                            align="right"
                            className={
                              line.isPrevious ? classes.previousValue : null
                            }
                            style={getPositiveValuesMarginStyles(
                              getLineCurrentValue()
                            )}
                          >
                            {formatToCurrencyOrClear(getLineCurrentValue())}
                          </Typography>
                        )}
                      </FormInputWrapper>
                    )}
                  </Grid>
                  <Grid item xs={2} className={classes.toolTip}>
                    {!hideTooltips &&
                    !line.hideCurrentYearField &&
                    line.toolTipText ? (
                      <AmoTooltip
                        tooltipTitle={line.toolTipTitle}
                        tooltipText={line.toolTipText}
                        isInteractive={!!line.toolTipText?.includes("<a")}
                        tooltipPlacement={tooltipPlacement}
                      />
                    ) : (
                      ""
                    )}
                    {fieldActions?.[line.field]?.edit}
                    {fieldActions?.[line.field]?.flag}
                  </Grid>
                  <Grid item xs={2}>
                    {shimmerField(
                      isLoadingYear,
                      <Typography
                        align="right"
                        className={
                          line.isPrevious ? classes.previousValue : null
                        }
                        style={getPositiveValuesMarginStyles(
                          getLineCumulativeValue()
                        )}
                      >
                        {formatToCurrencyOrClear(getLineCumulativeValue())}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          }
          if (line.displayUpload) {
            return (
              <Box pt={5} pb={5} key={line.field}>
                {line.uploadComponent}
              </Box>
            );
          }
          return "";
        })}
        <Divider className={classes.sectionDivider} />
        <Grid item>
          <Grid container className={classes.thinnerGrid} alignItems="center">
            <Grid item xs={5}>
              <Typography className={classes.columnHeader}>
                Net
                {netWarnings &&
                  showFlags &&
                  netWarnings.map((flag) => (
                    <FlagComponent
                      key={JSON.stringify(flag)}
                      type={flag?.type}
                      message={flag?.message}
                    />
                  ))}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {shimmerField(
                isLoadingYear,
                <Typography
                  align="right"
                  className={classes.boldLabel}
                  style={getPositiveValuesMarginStyles(net?.currentYear)}
                >
                  {formatToCurrencyOrClear(net?.currentYear)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={1} className={classes.toolTip} />
            <Grid item xs={1} />
            <Grid item xs={2}>
              {shimmerField(
                isLoadingYear,
                <Typography
                  align="right"
                  className={classes.boldLabel}
                  style={getPositiveValuesMarginStyles(net?.cumulativeTotal)}
                >
                  {formatToCurrencyOrClear(net?.cumulativeTotal)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

FinancialSection.propTypes = {
  control: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  toolTipTitle: PropTypes.string,
  toolTipText: PropTypes.string,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      label: PropTypes.string,
      labelIsIdented: PropTypes.bool,
      currentValue: PropTypes.number,
      cumulativeValue: PropTypes.number,
      toolTipTitle: PropTypes.string,
      toolTipText: PropTypes.string,
      helperText: PropTypes.string,
      isEditable: PropTypes.bool,
      isSubtracted: PropTypes.bool,
      isUpload: PropTypes.bool,
      isPrevious: PropTypes.bool,
      displayUpload: PropTypes.bool,
      uploadComponent: PropTypes.node,
      hideCurrentYearField: PropTypes.bool,
      warningType: PropTypes.string,
      tooltipText: PropTypes.string,
      hasLeftMargin: PropTypes.bool,
    })
  ).isRequired,
  net: PropTypes.shape({
    currentYear: PropTypes.number,
    cumulativeTotal: PropTypes.number,
  }).isRequired,
  isLoadingYear: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  updateFormWithValue: PropTypes.func.isRequired,
  hideTooltips: PropTypes.bool,
  fieldActions: PropTypes.shape({
    edit: PropTypes.element,
    flag: PropTypes.element,
  }),
  netWarnings: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  showFlags: PropTypes.bool,
  displayOnly: PropTypes.bool,
  hasPermissions: PropTypes.bool,
};

FinancialSection.defaultProps = {
  toolTipTitle: null,
  toolTipText: null,
  isLoadingYear: false,
  isReadOnly: true,
  hideTooltips: false,
  fieldActions: undefined,
  netWarnings: [],
  showFlags: false,
  displayOnly: false,
  hasPermissions: false,
};

const expenditureFields = [
  { field: "broadbandConnectivity", label: "Broadband Connectivity" },
  { field: "brownfield", label: "Brownfield Redevelopment" },
  { field: "capacityBldg", label: "Capacity Building" },
  { field: "commEnergy", label: "Community Energy Systems" },
  { field: "culture", label: "Culture" },
  { field: "disaster", label: "Disaster Mitigation" },
  { field: "water", label: "Drinking Water" },
  { field: "fireStation", label: "Fire Stations" },
  { field: "roadsBridges", label: "Local Roads and Bridges" },
  { field: "transit", label: "Public Transit" },
  { field: "recreation", label: "Recreation" },
  { field: "airport", label: "Regional and Local Airports" },
  { field: "shortline", label: "Short-line Rail" },
  { field: "shortsea", label: "Short-sea Shipping" },
  { field: "solidWaste", label: "Solid Waste" },
  { field: "sport", label: "Sports" },
  { field: "tourism", label: "Tourism" },
  { field: "wastewater", label: "Wastewater" },
];

const financialsFormFieldNames = [
  "proceedsFromAssetDisposal",
  "interestEarned",
  "transfersReceived",
  "transfersAdministered",
];

const FinancialsForm = ({
  municipalityIdOverride,
  reportingYearOverride,
  hasPermissions,
  fieldActions,
  hideTooltips,
  displayOnly,
  preloadedData,
  yearsData,
  isFinancialReview,
  isLoadingPreLoadedData,
  onBylawFileChange,
}) => {
  const classes = useStyles();
  const { setFilterValue } = useFilterContext() ?? {};
  const { selectedMunicipalityId } = useMunicipalContext();
  const municipalityId = municipalityIdOverride ?? selectedMunicipalityId;
  const { contextYear } = useYearContext();
  const params = useParams();
  const selectedYear = reportingYearOverride ?? params.year;

  const mounted = useIsMounted();

  const [fileUploadAction, setFileUploadAction] = useState("Idle");
  const { formFields } = useFormManagement(formIds.financialReporting);
  const [formValues, setFormValues] = useState({});
  const [flags, setFlags] = useState({});
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const canEdit = !isReadOnly && hasPermissions;
  const { showSnackbar } = useSnackbar();
  const [
    {
      proceedsFromAssetDisposal,
      interestEarned,
      transfersReceived,
      transfersAdministered,
    },
    setLastReadValues,
  ] = useState({
    proceedsFromAssetDisposal: null,
    interestEarned: null,
    transfersReceived: null,
    transfersAdministered: null,
  });
  const [netWarnings, setNetWarnings] = useState([]);

  // Function to execute addtional action when the file rollback is called
  const fileDeleteAdditionalAction = useRef();

  const onFileUpload = (_, savedFilesIds) => {
    setValue("uploadFile", savedFilesIds[0]);
    onBylawFileChange();
  };

  const onFileDelete = () => {
    setUploadFile(null);
    setValue("uploadFile", null);
    onBylawFileChange();
  };

  const onFileRollback = () => {
    setFileUploadAction("Idle");
    if (fileDeleteAdditionalAction.current) {
      fileDeleteAdditionalAction.current();
      fileDeleteAdditionalAction.current = null;
    }
  };

  const defaultValues = {
    proceedsFromAssetDisposal: null,
    interestEarned: null,
    transfersReceived: null,
    transfersAdministered: null,
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState,
    reset,
  } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possible onBlur) if very important for validation
    mode: "onChange",
    resolver: yupResolver(FinancialsFormEditSchema),
  });

  const [transfersAdministeredWatch, uploadFileWatch] = useWatch({
    control,
    name: ["transfersAdministered", "uploadFile"],
  });

  const {
    data: financialsData,
    refetch: refetchFinancialsData,
    isFetching,
    isError,
    error: fetchError,
  } = useFinancialReport(municipalityId, selectedYear, !displayOnly);
  const { refetch: refetchNavBar } = useAnnualReportNavBar(municipalityId);

  const handleSaveClick = async (data, shouldRefetchForm = true) => {
    try {
      const payload = {
        municipalityId,
        year: selectedYear,
        proceedsFromDisposal: data.proceedsFromAssetDisposal || null,
        interestEarned: data.interestEarned || null,
        transfersIn: data.transfersReceived || null,
        transfersOut: data.transfersAdministered || null,
      };
      if (!formValues.id) {
        if (data.fileId) payload.fileId = data.fileId;
        const response = await financialsService.postByYear(
          municipalityId,
          selectedYear,
          payload
        );

        if (!mounted.current) {
          return;
        }

        setFormValues({ ...formValues, id: response.data.id });
      } else {
        await financialsService.putByYear(municipalityId, selectedYear, {
          id: formValues.id,
          ...payload,
        });

        if (!mounted.current) {
          return;
        }
      }

      setFileUploadAction("Commit");
      if (shouldRefetchForm) {
        refetchFinancialsData();
      }
      // This is to update the side navbar
      refetchNavBar();

      showSnackbar(
        "The form was successfully submitted",
        snackbarTypes.success
      );
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
    }
  };

  const handlePrint = async () => {
    setIsLoadingPrint(true);
    try {
      const { data } = await pdfReportsService.getAnnualReportFinancials(
        municipalityId,
        selectedYear
      );

      if (!mounted.current) {
        return;
      }

      // Print pdf file
      await printFile(data);

      if (!mounted.current) {
        return;
      }

      setIsLoadingPrint(false);
    } catch (error) {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.generic, snackbarTypes.error);
      setIsLoadingPrint(false);
    }
  };

  const updateFormWithValue = (field) => {
    setLastReadValues({
      ...{
        proceedsFromAssetDisposal,
        interestEarned,
        transfersReceived,
        transfersAdministered,
      },
      [field]: parseFloat(getValues(field)) || 0,
    });
  };

  const isLoadingYear = useMemo(
    () => (displayOnly ? isLoadingPreLoadedData : isFetching),
    [isFetching, isLoadingPreLoadedData, displayOnly]
  );

  const revenuesNet = useMemo(
    () => ({
      currentYear: new Dollars(formValues?.receivedFromAMO?.currentYear)
        .plus(proceedsFromAssetDisposal || 0)
        .plus(interestEarned || 0)
        .minus(formValues?.administrationCosts?.currentYear)
        .valueOf(),
      cumulativeTotal: new Dollars(formValues?.receivedFromAMO?.cumulativeTotal)
        .plus(proceedsFromAssetDisposal || 0)
        .plus(formValues?.proceedsFromAssetDisposal?.preCumulativeTotal)
        .plus(interestEarned || 0)
        .plus(formValues?.interestEarned?.preCumulativeTotal)
        .minus(formValues?.administrationCosts?.cumulativeTotal)
        .valueOf(),
    }),
    [
      proceedsFromAssetDisposal,
      interestEarned,
      formValues?.proceedsFromAssetDisposal?.preCumulativeTotal,
      formValues?.interestEarned?.preCumulativeTotal,
    ]
  );

  const transfersNet = useMemo(
    () => ({
      currentYear: new Dollars(transfersReceived || 0)
        .minus(transfersAdministered || 0)
        .valueOf(),
      cumulativeTotal: new Dollars(transfersReceived || 0)
        .plus(formValues?.transfersReceived?.preCumulativeTotal)
        .minus(transfersAdministered || 0)
        .minus(formValues?.transfersAdministered?.preCumulativeTotal)
        .valueOf(),
    }),
    [
      transfersReceived,
      transfersAdministered,
      formValues?.transfersReceived?.preCumulativeTotal,
      formValues?.transfersAdministered?.preCumulativeTotal,
    ]
  );

  const closingBalance = useMemo(
    () =>
      new Dollars(formValues?.openingBalance)
        .plus(revenuesNet?.currentYear)
        .plus(transfersNet?.currentYear)
        .plus(formValues?.expendituresNet?.currentYear ?? 0)
        .valueOf(),
    [revenuesNet, transfersNet, formValues?.expendituresNet]
  );

  const checkIfFileIsValid = (transfersOut, file) => {
    const transferValue = parseFloat(transfersOut) || 0;
    return !!(transferValue && file) || !!(!transferValue && !file);
  };

  const fileIsValid = useMemo(
    () => checkIfFileIsValid(transfersAdministeredWatch, uploadFileWatch),
    [transfersAdministeredWatch, uploadFileWatch]
  );

  const { isValid, isSubmitting, isDirty } = formState;

  const formActions = [
    {
      disabled:
        !canEdit ||
        isSubmitting ||
        !isValid ||
        !isDirty ||
        closingBalance < 0 ||
        !fileIsValid,
      testId: "financialFormSaveButton",
      label: "Save",
      onClick: handleSubmit((formInputs) => {
        handleSaveClick(formInputs);
      }),
    },
    {
      disabled: isLoadingPrint,
      testId: "financialFormCancelButton",
      children: isLoadingPrint ? (
        <CircularProgress className={classes.loadingProgress} size={28} />
      ) : (
        "Print"
      ),
      variant: "outlined",
      onClick: handlePrint,
    },
  ];

  const blankData = {
    // TODO remove when flow is changed to better handle years with no record
    id: null,
    openingBalance: null,
    receivedFromAMO: { currentYear: 0, cumulativeTotal: 0 },
    proceedsFromAssetDisposal: { currentYear: null, cumulativeTotal: 0 },
    interestEarned: { currentYear: null, cumulativeTotal: 0 },
    transfersReceived: { currentYear: null, cumulativeTotal: 0 },
    transfersAdministered: { currentYear: null, cumulativeTotal: 0 },
    administrationCosts: { currentYear: 0, cumulativeTotal: 0 },
    expenditures: {
      broadbandConnectivity: { currentYear: 0, cumulativeTotal: 0 },
      brownfield: { currentYear: 0, cumulativeTotal: 0 },
      capacityBldg: { currentYear: 0, cumulativeTotal: 0 },
      commEnergy: { currentYear: 0, cumulativeTotal: 0 },
      culture: { currentYear: 0, cumulativeTotal: 0 },
      disaster: { currentYear: 0, cumulativeTotal: 0 },
      fireStation: { currentYear: 0, cumulativeTotal: 0 },
      roadsBridges: { currentYear: 0, cumulativeTotal: 0 },
      airport: { currentYear: 0, cumulativeTotal: 0 },
      recreation: { currentYear: 0, cumulativeTotal: 0 },
      shortline: { currentYear: 0, cumulativeTotal: 0 },
      shortsea: { currentYear: 0, cumulativeTotal: 0 },
      solidWaste: { currentYear: 0, cumulativeTotal: 0 },
      sport: { currentYear: 0, cumulativeTotal: 0 },
      transit: { currentYear: 0, cumulativeTotal: 0 },
      tourism: { currentYear: 0, cumulativeTotal: 0 },
      wastewater: { currentYear: 0, cumulativeTotal: 0 },
      water: { currentYear: 0, cumulativeTotal: 0 },
    },
    file: null,
    closingBalance: 0,
  };

  const checkReadOnlyStatus = () => {
    // TODO Remove and fix on integrations
    if (municipalityIdOverride != null) {
      setIsReadOnly(false);
      return;
    }

    setIsReadOnly(contextYear?.toString() !== selectedYear?.toString());
  };

  const setData = (originalData) => {
    const data = { ...originalData };
    const financialsForm = {
      uploadFile: data?.file,
    };

    financialsFormFieldNames.forEach((field) => {
      if (data[field]) {
        const value =
          data[field]?.currentYear || data[field]?.currentYear === 0
            ? parseFloat(data[field].currentYear)
            : "";

        financialsForm[field] = value;
        data[field].preCumulativeTotal = new Dollars(
          data[field].cumulativeTotal
        )
          .minus(data[field].currentYear)
          .valueOf();
      }
    });

    let expendituresNetCurrentYear = new Dollars(0);
    let expendituresNetCumulativeTotal = new Dollars(0);

    expenditureFields.forEach((exp) => {
      expendituresNetCurrentYear = expendituresNetCurrentYear.minus(
        data.expenditures?.[exp.field]?.currentYear
      );
      expendituresNetCumulativeTotal = expendituresNetCumulativeTotal.minus(
        data.expenditures?.[exp.field]?.cumulativeTotal
      );
    });

    data.expendituresNet = {
      currentYear: expendituresNetCurrentYear.valueOf(),
      cumulativeTotal: expendituresNetCumulativeTotal.valueOf(),
    };

    const newFlags = {};

    (data?.flags ?? []).forEach((flag) => {
      newFlags[flag.fieldCode] = {
        id: flag.id,
        ownerName: flag.updatedByName,
        ownerEmail: flag.updatedByEmail,
        comment: flag.message,
      };
    });

    reset(financialsForm);
    setUploadFile(data?.file);
    checkReadOnlyStatus();
    setFormValues(data);
    setLastReadValues({
      proceedsFromAssetDisposal: data.proceedsFromAssetDisposal?.currentYear,
      interestEarned: data.interestEarned?.currentYear,
      transfersReceived: data.transfersReceived?.currentYear,
      transfersAdministered: data.transfersAdministered?.currentYear,
    });
    setFlags(newFlags);
  };

  useEffect(() => {
    if (isLoadingYear) return;

    if (isError && fetchError?.response?.status === 404) {
      setData(blankData);
      setIsReadOnly(false);
    } else if (displayOnly) {
      setData(preloadedData);
      setIsReadOnly(displayOnly);
    } else {
      setData(financialsData);
    }
  }, [
    financialsData?.id,
    preloadedData,
    displayOnly,
    isError,
    fetchError,
    isLoadingYear,
  ]);

  useEffect(() => {
    if (yearsData) {
      if (
        !yearsData[0].netExpenditures ||
        new Dollars(yearsData[0].netExpenditures).eq(0)
      ) {
        let setWarning = true;

        yearsData.slice(1, 10).forEach((year) => {
          if (new Dollars(year.netExpenditures).eq(0)) {
            setWarning = false;
          }
        });

        if (setWarning) {
          setNetWarnings([
            {
              type: "warning",
              message:
                "Expenditures were not reported, but have been reported every year for the past decade",
            },
          ]);
        }
      }

      if (
        yearsData &&
        yearsData.length >= 6 &&
        (!yearsData[0].reportingStatusId ||
          [1, 2].includes(yearsData[0].reportingStatusId))
      ) {
        let amountToSpend = new Dollars(yearsData[5].closeBalance);

        yearsData.slice(0, 5).forEach((year) => {
          amountToSpend = amountToSpend
            .minus(year.netExpenditures)
            .minus(year.transferredOut);
        });

        if (amountToSpend.gt(0)) {
          setNetWarnings([
            ...netWarnings,
            {
              type: yearsData[0].reportingStatusId === 1 ? "warning" : "error",
              message: `The municipality must spend or transfer an additional ${formatToCurrency(
                amountToSpend.valueOf()
              )} to comply with the five-year banking limit`,
            },
          ]);
        }
      }
    }
  }, [yearsData]);

  const handlePromptConfirmation = (save, event) => {
    const [transfersOut, file] = getValues([
      "transfersAdministered",
      "uploadFile",
    ]);

    if (save) {
      if (isValid && checkIfFileIsValid(transfersOut, file)) {
        // Need to call handleSubmit passing the event or null when calling outside a UI interaction
        handleSubmit((formInputs) => {
          handleSaveClick(formInputs, false);
        })(event);
      } else {
        showSnackbar("Unable to save due to invalid data", snackbarTypes.error);
        return false;
      }

      unblockNavigationAndClosePrompt(true);
    } else {
      const closePromptAction = () => {
        reset();
        unblockNavigationAndClosePrompt(true);
      };
      if (transfersOut > 0 || file?.id > 0) {
        setFileUploadAction("Rollback");
        fileDeleteAdditionalAction.current = closePromptAction;
      } else {
        closePromptAction();
      }
    }

    return true;
  };

  const handlePromptClose = () => {
    closePromptModal(false);
    setFilterValue("year", params.year);
  };

  const isClosingNegative = new Dollars(closingBalance).lt(0);

  // Hook to block user navigation
  const {
    unblockNavigationAndClosePrompt,
    closePromptModal,
  } = useNavigationBlocker(isDirty && !isFinancialReview, {
    onPromptSaveCallback: handlePromptConfirmation,
    onPromptCloseCallback: handlePromptClose,
    isAsyncClosing: true,
    hasInvalidData: !isValid || !fileIsValid || isClosingNegative,
  });

  // Action can be either released or received
  const getReceivedOrReleasedFlagValues = (
    formValuesKey,
    yearsDataKey,
    formCode,
    action
  ) => ({
    flag: flags[formCode],
    ...(yearsData &&
      formValues?.[formValuesKey]?.currentYear &&
      new Dollars(formValues?.[formValuesKey]?.currentYear).ne(0) &&
      !yearsData[1]?.[yearsDataKey] && {
        warningType: "warning",
        tooltipMessage: `No transfers were ${action} last year`,
      }),
    ...(yearsData &&
      (!formValues?.[formValuesKey]?.currentYear ||
        new Dollars(formValues?.[formValuesKey]?.currentYear).eq(0)) &&
      yearsData[1]?.[yearsDataKey] && {
        warningType: "warning",
        tooltipMessage: `${formatToCurrency(
          yearsData[1]?.[yearsDataKey]
        )} was ${action} last year`,
      }),
  });

  const getPreviousValueLine = (field, fieldKey, formCode, flagValues = {}) => {
    const fieldValue = formValues ? formValues[fieldKey] : null;
    return {
      field,
      label: formFields[formCode]?.text ?? "",
      currentValue: fieldValue?.previousValue,
      cumulativeValue: new Dollars(fieldValue?.preCumulativeTotal)
        .plus(fieldValue?.previousValue || 0)
        .valueOf(),
      isEditable: false,
      isSubtracted: false,
      isUpload: false,
      isPrevious: true,
      ...flagValues,
    };
  };

  const getCurrentLabel = (fieldKey, formCode) => {
    const fieldValue = formValues ? formValues[fieldKey] : null;
    return fieldValue?.previousValue == null
      ? formFields[formCode]?.text ?? ""
      : "";
  };

  return (
    <Grid
      className={classes.formContainer}
      container
      direction="column"
      spacing={0}
      wrap="nowrap"
    >
      {/* Body */}
      <Grid item xs className={classes.bodyContainer}>
        <form>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(classes.body, classes.bodyText)}
            wrap="nowrap"
          >
            {/* Opening Balance Section */}
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Grid container>
                    <Grid item xs={5} />
                    <Grid item xs={3} align="right">
                      <div
                        className={classes.columnHeader}
                        style={{ paddingRight: "0.5rem" }}
                      >
                        <div className={classes.columnHeaderTop}>
                          Current Year
                        </div>
                        <div className={classes.columnHeaderBottom}>
                          ({selectedYear})
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid
                      item
                      xs={2}
                      align="right"
                      style={{ paddingRight: "2rem" }}
                    >
                      <div className={classes.columnHeader}>
                        <div className={classes.columnHeaderTop}>
                          Cumulative
                        </div>
                        <div className={classes.columnHeaderBottom}>
                          (2005-{selectedYear})
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item xs={5}>
                      <div className={classes.balance}>Opening Balance</div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      align="right"
                      style={{ paddingRight: "0.5rem" }}
                    >
                      {shimmerField(
                        isLoadingYear,
                        <FormInputWrapper
                          id="opening-balance-wrapper"
                          testId="opening-balance-test-wrapper"
                          flag={!!flags[formCodes.fldOpeningBalance]}
                          flagProps={
                            flags[formCodes.fldOpeningBalance]
                              ? {
                                  ...flags[formCodes.fldOpeningBalance],
                                  title: "Opening Balance",
                                }
                              : {}
                          }
                          tooltip={false}
                          hideTooltipSpacing
                        >
                          <div>
                            {formatToCurrencyOrClear(
                              formValues?.openingBalance
                            )}
                          </div>
                        </FormInputWrapper>
                      )}
                    </Grid>
                    <Grid item xs={4} className={classes.actions}>
                      {fieldActions?.openingBalance?.edit}
                      {fieldActions?.openingBalance?.flag}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Revenues Section */}
            <FinancialSection
              displayOnly={displayOnly}
              hasPermissions={hasPermissions}
              fieldActions={fieldActions}
              hideTooltips={hideTooltips}
              control={control}
              title={formFields[formCodes.ttlRevenues]?.text ?? ""}
              toolTipTitle={formFields[formCodes.ttlRevenues]?.text ?? ""}
              toolTipText={formFields[formCodes.ttlRevenues]?.tooltipText ?? ""}
              lines={[
                {
                  field: "receivedFromAMO",
                  label: "Received from AMO (Including Receivables)",
                  currentValue: formValues?.receivedFromAMO?.currentYear,
                  cumulativeValue: formValues?.receivedFromAMO?.cumulativeTotal,
                  isEditable: false,
                  isSubtracted: false,
                  isUpload: false,
                },
                getPreviousValueLine(
                  "proceedsFromAssetDisposalPrevious",
                  "proceedsFromAssetDisposal",
                  formCodes.fldProceedDisposalAssets,
                  { flag: flags[formCodes.fldProceedDisposalAssets] }
                ),
                {
                  field: "proceedsFromAssetDisposal",
                  label: getCurrentLabel(
                    "proceedsFromAssetDisposal",
                    formCodes.fldProceedDisposalAssets
                  ),
                  currentValue:
                    formValues?.proceedsFromAssetDisposal?.currentYear,
                  cumulativeValue: new Dollars(
                    formValues?.proceedsFromAssetDisposal?.preCumulativeTotal
                  )
                    .plus(proceedsFromAssetDisposal || 0)
                    .valueOf(),
                  toolTipTitle:
                    formFields[formCodes.fldProceedDisposalAssets]?.text ?? "",
                  toolTipText:
                    formFields[formCodes.fldProceedDisposalAssets]
                      ?.tooltipText ?? "",
                  helperText:
                    formFields[formCodes.fldProceedDisposalAssets]
                      ?.helperText ?? "",
                  isEditable: true,
                  isSubtracted: false,
                  isUpload: false,
                  ...(!getCurrentLabel(
                    "proceedsFromAssetDisposal",
                    formCodes.fldProceedDisposalAssets
                  )
                    ? {}
                    : { flag: flags[formCodes.fldProceedDisposalAssets] }),
                },
                getPreviousValueLine(
                  "interestEarnedPrevious",
                  "interestEarned",
                  formCodes.fldInterestEarned,
                  {
                    flag: flags[formCodes.fldInterestEarned],
                    ...(yearsData &&
                      new Dollars(formValues?.interestEarned?.currentYear).eq(
                        0
                      ) &&
                      (yearsData[0].reportingStatusId === 2
                        ? {
                            warningType: "error",
                            tooltipMessage:
                              "Interest earnings were not reported",
                          }
                        : {
                            warningType: "warning",
                            tooltipMessage:
                              "Interest earnings have not been reported yet",
                          })),
                  }
                ),
                {
                  field: "interestEarned",
                  label: getCurrentLabel(
                    "interestEarned",
                    formCodes.fldInterestEarned
                  ),
                  currentValue: formValues?.interestEarned?.currentYear,
                  cumulativeValue: new Dollars(
                    formValues?.interestEarned?.preCumulativeTotal
                  )
                    .plus(interestEarned || 0)
                    .valueOf(),
                  toolTipTitle:
                    formFields[formCodes.fldInterestEarned]?.text ?? "",
                  toolTipText:
                    formFields[formCodes.fldInterestEarned]?.tooltipText ?? "",
                  helperText:
                    formFields[formCodes.fldInterestEarned]?.helperText ?? "",
                  isEditable: true,
                  isSubtracted: false,
                  isUpload: false,
                  ...(!getCurrentLabel(
                    "interestEarned",
                    formCodes.fldInterestEarned
                  )
                    ? {}
                    : {
                        flag: flags[formCodes.fldInterestEarned],
                        ...(yearsData &&
                          new Dollars(
                            formValues?.interestEarned?.currentYear
                          ).eq(0) &&
                          (yearsData[0].reportingStatusId === 2
                            ? {
                                warningType: "error",
                                tooltipMessage:
                                  "Interest earnings were not reported",
                              }
                            : {
                                warningType: "warning",
                                tooltipMessage:
                                  "Interest earnings have not been reported yet",
                              })),
                      }),
                },
                {
                  field: "administrationCosts",
                  label:
                    formFields[formCodes.fldLessAdministrationCosts]?.text ??
                    "",
                  currentValue: formValues?.administrationCosts?.currentYear,
                  cumulativeValue:
                    formValues?.administrationCosts?.cumulativeTotal,
                  toolTipTitle:
                    formFields[formCodes.fldLessAdministrationCosts]?.text ??
                    "",
                  toolTipText:
                    formFields[formCodes.fldLessAdministrationCosts]
                      ?.tooltipText ?? "",
                  helperText:
                    formFields[formCodes.fldLessAdministrationCosts]
                      ?.helperText ?? "",
                  isEditable: false,
                  isSubtracted: true,
                  isIndented: true,
                  isUpload: false,
                  hideCurrentYearField:
                    selectedYear > 2013 &&
                    (!formValues?.administrationCosts?.currentYear ||
                      new Dollars(
                        formValues?.administrationCosts?.currentYear
                      ).eq(0)) &&
                    (!formValues?.administrationCosts?.cumulativeTotal ||
                      new Dollars(
                        formValues?.administrationCosts?.cumulativeTotal
                      ).eq(0)),
                  hasLeftMargin: true,
                },
              ]}
              net={revenuesNet}
              isLoadingYear={isLoadingYear}
              isReadOnly={!canEdit}
              updateFormWithValue={updateFormWithValue}
              showFlags={isFinancialReview}
            />
            {/* Transfers Section */}
            <FinancialSection
              displayOnly={displayOnly}
              hasPermissions={hasPermissions}
              fieldActions={fieldActions}
              hideTooltips={hideTooltips}
              control={control}
              title={formFields[formCodes.ttlTransfers]?.text ?? ""}
              toolTipTitle={formFields[formCodes.ttlTransfers]?.text ?? ""}
              toolTipText={
                formFields[formCodes.ttlTransfers]?.tooltipText ?? ""
              }
              lines={[
                getPreviousValueLine(
                  "transfersReceivedPrevious",
                  "transfersReceived",
                  formCodes.fldPlusAmountsReceived,
                  getReceivedOrReleasedFlagValues(
                    "amountsReceived",
                    "transferredIn",
                    formCodes.fldPlusAmountsReceived,
                    "received"
                  )
                ),
                {
                  field: "transfersReceived",
                  label: getCurrentLabel(
                    "transfersReceived",
                    formCodes.fldPlusAmountsReceived
                  ),
                  cumulativeValue: new Dollars(
                    formValues?.transfersReceived?.preCumulativeTotal
                  )
                    .plus(transfersReceived || 0)
                    .valueOf(),
                  toolTipTitle:
                    formFields[formCodes.fldPlusAmountsReceived]?.text ?? "",
                  toolTipText:
                    formFields[formCodes.fldPlusAmountsReceived]?.tooltipText ??
                    "",
                  helperText:
                    formFields[formCodes.fldPlusAmountsReceived]?.helperText ??
                    "",
                  isEditable: true,
                  isSubtracted: false,
                  isUpload: false,
                  ...(!getCurrentLabel(
                    "transfersReceived",
                    formCodes.fldPlusAmountsReceived
                  )
                    ? {}
                    : getReceivedOrReleasedFlagValues(
                        "amountsReceived",
                        "transferredIn",
                        formCodes.fldPlusAmountsReceived,
                        "received"
                      )),
                },
                getPreviousValueLine(
                  "transfersAdministeredPrevious",
                  "transfersAdministered",
                  formCodes.fldLessAmountsTransferred,
                  getReceivedOrReleasedFlagValues(
                    "amountsTransfered",
                    "transftransferredOuterredIn",
                    formCodes.fldLessAmountsTransferred,
                    "released"
                  )
                ),
                {
                  field: "transfersAdministered",
                  label: getCurrentLabel(
                    "transfersAdministered",
                    formCodes.fldLessAmountsTransferred
                  ),
                  currentValue: formValues?.transfersAdministered?.currentYear,
                  cumulativeValue: new Dollars(
                    formValues?.transfersAdministered?.preCumulativeTotal
                  )
                    .plus(transfersAdministered || 0)
                    .valueOf(),
                  toolTipTitle:
                    formFields[formCodes.fldLessAmountsTransferred]?.text ?? "",
                  toolTipText:
                    formFields[formCodes.fldLessAmountsTransferred]
                      ?.tooltipText ?? "",
                  helperText:
                    formFields[formCodes.fldLessAmountsTransferred]
                      ?.helperText ?? "",
                  isEditable: true,
                  isSubtracted: true,
                  isUpload: false,
                  ...(!getCurrentLabel(
                    "transfersAdministered",
                    formCodes.fldLessAmountsTransferred
                  )
                    ? {}
                    : getReceivedOrReleasedFlagValues(
                        "amountsTransfered",
                        "transftransferredOuterredIn",
                        formCodes.fldLessAmountsTransferred,
                        "released"
                      )),
                },
                {
                  field: "bylawFile",
                  label: formFields[formCodes.ttlUploadBylaw]?.text ?? "",
                  isUpload: true,
                  displayUpload:
                    uploadFile?.id > 0 || transfersAdministered > 0,
                  uploadComponent:
                    !isFetching &&
                    Object.keys(formValues).length > 0 &&
                    (transfersAdministered > 0 || uploadFile?.id > 0) ? (
                      <FileUploadList
                        onChange={(fileList) => setUploadFile(fileList?.[0])}
                        allowedTypes={[fileTypes.pdf]}
                        showAllowedTypesHint
                        fileAssociationId={fileAssociations.Financials}
                        fileAssociationObjectId={
                          formValues.id === null ? undefined : formValues.id
                        }
                        municipalityId={municipalityId}
                        fileYear={selectedYear}
                        initialValue={uploadFile?.id > 0 ? [uploadFile] : []}
                        instructions={
                          displayOnly
                            ? ""
                            : formFields[formCodes.descUploadBylaw]?.text ?? ""
                        }
                        onUpload={onFileUpload}
                        onDelete={onFileDelete}
                        uploadAction={fileUploadAction}
                        onRollback={onFileRollback}
                      />
                    ) : (
                      ""
                    ),
                },
              ]}
              net={transfersNet}
              isLoadingYear={isLoadingYear}
              isReadOnly={!canEdit}
              updateFormWithValue={updateFormWithValue}
              showFlags={isFinancialReview}
            />
            {/* Expenditures Section */}
            <FinancialSection
              displayOnly={displayOnly}
              hasPermissions={hasPermissions}
              fieldActions={fieldActions}
              hideTooltips={hideTooltips}
              control={control}
              title={formFields[formCodes.ttlExpenditures]?.text ?? ""}
              toolTipTitle={formFields[formCodes.ttlExpenditures]?.text ?? ""}
              toolTipText={
                formFields[formCodes.ttlExpenditures]?.tooltipText ?? ""
              }
              lines={expenditureFields.map((expenditure) => ({
                field: expenditure.field,
                label: expenditure.label,
                currentValue:
                  formValues?.expenditures?.[expenditure.field]?.currentYear,
                cumulativeValue:
                  formValues?.expenditures?.[expenditure.field]
                    ?.cumulativeTotal,
                isEditable: false,
                isSubtracted: true,
                isUpload: false,
              }))}
              net={formValues.expendituresNet}
              isLoadingYear={isLoadingYear}
              isReadOnly={!canEdit}
              updateFormWithValue={updateFormWithValue}
              netWarnings={netWarnings}
              showFlags={isFinancialReview}
            />
            {/* Closing Balance Section */}
            <Grid item>
              <Box pt={2} pr={1}>
                <Grid container direction="column">
                  <Grid item className={classes.smallRowHeight}>
                    <Grid container>
                      <Grid item xs={5}>
                        <div className={classes.balance}>Closing Balance</div>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        align="right"
                        style={{ paddingRight: "0.5rem" }}
                      >
                        {shimmerField(
                          isLoadingYear,
                          <FormInputWrapper
                            id="closing-balance-wrapper"
                            testId="closing-balance-test-wrapper"
                            flag={!!flags[formCodes.fldClosingBalance]}
                            flagProps={
                              flags[formCodes.fldClosingBalance]
                                ? {
                                    ...flags[formCodes.fldClosingBalance],
                                    title: "Opening Balance",
                                  }
                                : {}
                            }
                            tooltip={false}
                            hideTooltipSpacing
                          >
                            <div
                              className={
                                isClosingNegative ? classes.negativeBalance : ""
                              }
                              style={getPositiveValuesMarginStyles(
                                closingBalance
                              )}
                            >
                              {formatToCurrencyOrClear(closingBalance)}
                            </div>
                          </FormInputWrapper>
                        )}
                      </Grid>
                      <Grid item xs={4} className={classes.actions}>
                        {fieldActions?.closingBalance?.edit}
                        {fieldActions?.closingBalance?.flag}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={6} />
                      <Grid item>
                        {isClosingNegative ? (
                          <div className={classes.negativeBalanceMessage}>
                            Closing balance must not be negative.
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {!displayOnly && (
        <AmoFormActions
          className={classes.formActions}
          actions={formActions}
          hideLateralBorders
        />
      )}
    </Grid>
  );
};

FinancialsForm.propTypes = {
  municipalityIdOverride: PropTypes.number,
  reportingYearOverride: PropTypes.number,
  hasPermissions: PropTypes.bool.isRequired,
  fieldActions: PropTypes.shape(),
  hideTooltips: PropTypes.bool,
  displayOnly: PropTypes.bool,
  preloadedData: PropTypes.shape(),
  yearsData: PropTypes.arrayOf(PropTypes.shape()),
  isFinancialReview: PropTypes.bool,
  isLoadingPreLoadedData: PropTypes.bool,
  onBylawFileChange: PropTypes.func,
};

FinancialsForm.defaultProps = {
  municipalityIdOverride: undefined,
  reportingYearOverride: undefined,
  fieldActions: undefined,
  hideTooltips: false,
  displayOnly: false,
  preloadedData: undefined,
  yearsData: undefined,
  isFinancialReview: false,
  isLoadingPreLoadedData: false,
  onBylawFileChange: () => {},
};

export default FinancialsForm;
