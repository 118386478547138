import React from "react";
import AnnualReportNavBar from "components/annualReport/AnnualReportNavBar";
import ReportingStatusWidget from "components/annualReport/ReportingStatusWidget";
import FinancialStatusWidget from "components/annualReport/FinancialStatusWidget";
import ListPageWrapper from "components/ListPageWrapper";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { DateTime } from "luxon";
import { useUserContext } from "contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    // 0px 75px 16px 75px
    padding: "0rem 4.688rem 1rem 4.688rem",
  },
  reportStatusWidget: {
    // 245px
    height: "15rem",
  },
  reportingChecklist: {
    width: "50%",
  },
  financialStatus: {
    width: "50%",
  },
  statusWidgetRow: {
    justifyContent: "space-between",
  },
  municipalityReportingText: {
    fontSize: "1.125rem",
  },
  municipalityName: {
    fontSize: "1.125rem",
    fontWeight: 700,
  },
}));

/**
 * Landing page for municipal users.
 *
 * @returns {React.Component} Landing Page component.
 */
const MunicipalLandingPage = () => {
  const classes = useStyles();
  const { user } = useUserContext();
  const { userMunicipality } = useMunicipalContext();

  return (
    <>
      <ListPageWrapper>
        <Grid container direction="column" spacing={2} className={classes.root}>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h2" component="span">
                Welcome back,{" "}
              </Typography>
              <Typography variant="h1" component="span">
                {user.firstName}!
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.municipalityReportingText}
                component="span"
              >
                Reporting for:{" "}
              </Typography>
              <Typography className={classes.municipalityName} component="span">
                {userMunicipality?.formalName}
              </Typography>
            </Grid>
          </Grid>

          <Grid item classes={{ root: classes.reportStatusWidget }}>
            <ReportingStatusWidget displayDate={DateTime.now()} />
          </Grid>

          <Grid item>
            <Grid
              container
              column="row"
              classes={{ root: classes.statusWidgetRow }}
              spacing={2}
            >
              <Grid item classes={{ root: classes.reportingChecklist }}>
                <AnnualReportNavBar variant="widget" />
              </Grid>
              <Grid item classes={{ root: classes.financialStatus }}>
                <FinancialStatusWidget />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListPageWrapper>
    </>
  );
};

export default MunicipalLandingPage;
