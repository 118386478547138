import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { communicationsService } from "api/services/communicationService";
import AmoDatePicker from "components/inputs/AmoDatePicker";
import AmoTextField from "components/inputs/AmoTextField";
import colors from "constants/colors";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useIsMounted } from "hooks/useIsMounted";
import { useWrapApi } from "hooks/wrapApiHook";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    border: "1px solid black",
    borderRadius: "0.5rem",
    padding: "0.8rem 1.5rem",
    height: "44.875rem",
    marginTop: 0,
    width: "100%",
    maxWidth: "30rem",
  },
  headerWrapper: {
    marginBottom: "0.5rem",
  },
  switchWrapper: {
    marginRight: "0",
  },
  switchLabel: {
    fontSize: "0.9rem",
    paddingLeft: "0.5rem",
  },
  logsItem: {
    overflow: "scroll",
  },
  noHistoryItem: {
    background: colors.grey.light,
    margin: "0.2rem 0",
    padding: "1rem",
    textAlign: "Center",
  },
  button: {
    width: "9.563rem",
    height: "3.125rem",
  },
  saveButtonContainer: {
    textAlign: "right",
    marginTop: "auto",
  },
}));

/**
 * The Announcements links component.
 *
 * @param {object} props - object containing props for this component
 * @param {number} props.projectId - id of the project [required]
 * @param {Function} props.onSave - function called when user clicks on the save button (params: none)
 * @returns {React.Component} Announcements links component.
 */
const AnnouncementLinks = (props) => {
  const { projectId, onSave } = props;

  const classes = useStyles();

  const mounted = useIsMounted();

  const FormSchema = yup.object({
    id: yup.number().nullable(),
    twitterUrl: yup.string().nullable(),
    linkedInUrl: yup.string().nullable(),
    instagramUrl: yup.string().nullable(),
    buildingCommunitiesUrl: yup.string().nullable(),
    announcementDate: yup
      .date()
      .typeError("Please enter a valid date.")
      .required()
      .max(
        DateTime.now().endOf("day"),
        "The date can't be greater than today."
      ),
  });

  const getAnnouncements = useWrapApi(communicationsService.getAnnouncements);

  const createAnnouncement = useWrapApi(
    communicationsService.createAnnouncement,
    "Successfully updated"
  );

  const updateAnnouncement = useWrapApi(
    communicationsService.updateAnnouncement,
    "Successfully updated"
  );

  useAsyncEffect(async (status) => {
    const {
      data: { data },
    } = await getAnnouncements.call(projectId);

    if (status.aborted) {
      return;
    }

    if (data) {
      reset(data);
    }
  }, []);

  const defaultValues = {
    id: null,
    twitterUrl: "",
    linkedInUrl: "",
    instagramUrl: "",
    buildingCommunitiesUrl: "",
    announcementDate: null,
  };

  const { control, watch, formState, reset, setValue, handleSubmit } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(FormSchema),
  });

  const { isValid, isSubmitting } = formState;

  const watchedValues = watch();

  const anyLinkFilled =
    watchedValues?.twitterUrl ||
    watchedValues?.linkedInUrl ||
    watchedValues?.instagramUrl ||
    watchedValues?.buildingCommunitiesUrl;

  const handleSaveClick = async (formValues) => {
    const { id, ...valuesWithoutId } = formValues;
    if (id) {
      await updateAnnouncement.call(id, { projectId, ...formValues });

      if (!mounted.current) {
        return;
      }
    } else {
      const {
        data: { data: newId },
      } = await createAnnouncement.call({
        projectId,
        ...valuesWithoutId,
      });
      setValue("id", newId);
    }
    onSave();
  };

  return (
    <Grid
      container
      direction="column"
      className={classes.mainContainer}
      spacing={2}
      wrap="nowrap"
    >
      <Grid item className={classes.titleContainer}>
        <Typography variant="h5">Announcement Links</Typography>
      </Grid>
      <Grid item>
        <AmoTextField
          id="twitterUrl-id"
          multiline
          rows={1}
          rowsMax={10}
          control={control}
          label="Twitter"
          name="twitterUrl"
          testId="twitterUrl"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: !!watchedValues.twitterUrl }}
        />
      </Grid>
      <Grid item>
        <AmoTextField
          id="linkedInUrl-id"
          multiline
          rows={1}
          rowsMax={10}
          control={control}
          label="Linkedin"
          name="linkedInUrl"
          testId="linkedInUrl"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: !!watchedValues.linkedInUrl }}
        />
      </Grid>
      <Grid item>
        <AmoTextField
          id="instagramUrl-id"
          multiline
          rows={1}
          rowsMax={10}
          control={control}
          label="Instagram"
          name="instagramUrl"
          testId="instagramUrl"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: !!watchedValues.instagramUrl }}
        />
      </Grid>

      <Grid item>
        <AmoTextField
          id="buildingCommunitiesUrl-id"
          multiline
          rows={1}
          rowsMax={10}
          control={control}
          label="BuildingCommunities.ca"
          name="buildingCommunitiesUrl"
          testId="buildingCommunitiesUrl"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: !!watchedValues.buildingCommunitiesUrl }}
        />
      </Grid>
      <Grid item>
        <AmoDatePicker
          id="announcementDate-id"
          control={control}
          label="Announcement Date *"
          name="announcementDate"
          testId="announcementDate"
          variant="outlined"
          maxDate={DateTime.now()}
          fullWidth
        />
      </Grid>

      <Grid item className={classes.saveButtonContainer}>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          disabled={!isValid || !anyLinkFilled || isSubmitting}
          data-testid="saveAnnouncementLinksButton"
          onClick={handleSubmit((formValues) => handleSaveClick(formValues))}
        >
          <Typography align="center" variant="body2">
            Save
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

AnnouncementLinks.propTypes = {
  projectId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
};

AnnouncementLinks.defaultProps = {
  onSave: () => {},
};

export default AnnouncementLinks;
