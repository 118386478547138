import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import AmoPageHeader from "components/AmoPageHeader";
import FormFieldWrapper from "components/amo/FormFieldWrapper";
import FormFieldEditDialog from "components/amo/FormFieldEditDialog";
import colors from "constants/colors";
import { routes } from "constants/routes";
import {
  formIds,
  projectReportCommunicationsCodes as formCodes,
} from "constants/formContentManagement";
import { useFormManagement } from "hooks/formManagementHook";
import { useGlobalStyles } from "hooks/globalStylesHook";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  formDescription: {
    // 32px
    paddingLeft: "2rem",
    "& > .MuiGrid-item": {
      // 20px
      paddingBottom: "1.25rem",
    },
  },
  formTitle: {
    // 32px
    paddingLeft: "2rem",
    "& > .MuiGrid-item": {
      "&:last-child": {
        // 20px
        paddingBottom: "1.25rem",
      },
    },
  },
  formField: {
    // 32px
    paddingLeft: "2rem",
    // 72px
    paddingRight: "4.5rem",
    "& > .MuiGrid-item": {
      // 32px
      paddingBottom: "2rem",
      "&:last-child": {
        paddingBottom: "0rem",
      },
    },
  },
  divider: {
    backgroundColor: colors.grey.light,
    // 20px 0px
    margin: "1.25rem 0rem",
  },
  inputWidth: {
    width: "100%",
  },
}));

/**
 * A page component for editing "Project Reporting: General Information" form on Form Content Management
 *
 * @returns {React.Component} The page component
 */
const ReportCommunicationsEditPage = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const mounted = useIsMounted();

  const { formFields, saveFormField } = useFormManagement(
    formIds.projectReportCommunications
  );

  const [modalField, setModalField] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalIsOpen(true);
  };

  const handlePopoverSaveClick = async (updatedField) => {
    const isSuccessful = await saveFormField(updatedField);

    if (!mounted.current) {
      return;
    }

    if (isSuccessful) {
      setModalIsOpen(false);
      setModalField({});
    }
  };

  const handlePopoverCloseClick = () => {
    setModalIsOpen(false);
    setModalField({});
  };

  return (
    <>
      <Grid container direction="column" spacing={0} wrap="nowrap">
        {/* Header */}
        <Grid item>
          <AmoPageHeader
            title="Edit"
            subtitle="Project Reporting: Communications"
            backLinkText="Back to Forms list"
            backLinkTo={routes.contentManagement.forms.list}
          />
        </Grid>

        {/* Body */}
        <Grid item xs className={globalClasses.editPageBodyContainer}>
          <Grid
            container
            direction="column"
            spacing={0}
            className={clsx(
              globalClasses.editPageBody16,
              globalClasses.editPageBodyPadding
            )}
          >
            {/* Form Description */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formDescription}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.descriptionForm] && (
                    <FormFieldWrapper
                      id="prcomm-form-description"
                      testId="prcommFormDescription"
                      type={formFields[formCodes.descriptionForm].type}
                      value={formFields[formCodes.descriptionForm].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionForm])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* File Upload Title */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formTitle}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.titleUpload] && (
                    <FormFieldWrapper
                      id="prcomm-file-upload-title"
                      testId="prcommFileUploadTitle"
                      type={formFields[formCodes.titleUpload].type}
                      value={formFields[formCodes.titleUpload].text}
                      textProps={{ variant: "h4" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.titleUpload])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.descriptionUpload] && (
                    <FormFieldWrapper
                      id="prcomm-file-upload-description"
                      testId="prcommFileUploadDescription"
                      type={formFields[formCodes.descriptionUpload].type}
                      value={formFields[formCodes.descriptionUpload].text}
                      textProps={{ variant: "body1" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.descriptionUpload])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* File Upload Fields */}
            <Grid item xs>
              <Grid
                container
                direction="column"
                spacing={0}
                className={classes.formField}
                wrap="nowrap"
              >
                <Grid item>
                  {formFields[formCodes.fieldSignagePosted] && (
                    <FormFieldWrapper
                      id="prcomm-proj-construction-signage-field"
                      testId="prcommProjConstructionSignageField"
                      type={formFields[formCodes.fieldSignagePosted].type}
                      value={formFields[formCodes.fieldSignagePosted].text}
                      className={classes.inputWidth}
                      inputProps={{ endIconName: "arrow_drop_down" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.fieldSignagePosted]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.fieldSignageType] && (
                    <FormFieldWrapper
                      id="prcomm-proj-signage-type-field"
                      testId="prcommProjSignageTypeField"
                      type={formFields[formCodes.fieldSignageType].type}
                      value={formFields[formCodes.fieldSignageType].text}
                      className={classes.inputWidth}
                      inputProps={{ endIconName: "arrow_drop_down" }}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.fieldSignageType])
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.fieldSignageIntentions] && (
                    <FormFieldWrapper
                      id="prcomm-proj-signage-intentions-field"
                      testId="prcommProjSignageIntentionsField"
                      type={formFields[formCodes.fieldSignageIntentions].type}
                      value={formFields[formCodes.fieldSignageIntentions].text}
                      className={classes.inputWidth}
                      inputProps={{ endIconName: "arrow_drop_down" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.fieldSignageIntentions]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.fieldSignageRationale] && (
                    <FormFieldWrapper
                      id="prcomm-proj-signage-rationale-field"
                      testId="prcommProjSignageRationaleField"
                      type={formFields[formCodes.fieldSignageRationale].type}
                      value={formFields[formCodes.fieldSignageRationale].text}
                      className={classes.inputWidth}
                      inputProps={{ endIconName: "arrow_drop_down" }}
                      onEditClick={() =>
                        handleEditClick(
                          formFields[formCodes.fieldSignageRationale]
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {formFields[formCodes.fieldLinks] && (
                    <FormFieldWrapper
                      id="prcomm-proj-links-field"
                      testId="prcommProjLinksField"
                      type={formFields[formCodes.fieldLinks].type}
                      value={formFields[formCodes.fieldLinks].text}
                      className={classes.inputWidth}
                      onEditClick={() =>
                        handleEditClick(formFields[formCodes.fieldLinks])
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormFieldEditDialog
        id="prcomm-edit-popover"
        testId="prcommEditPopover"
        field={modalField}
        open={modalIsOpen}
        onSave={handlePopoverSaveClick}
        onClose={handlePopoverCloseClick}
      />
    </>
  );
};

export default ReportCommunicationsEditPage;
