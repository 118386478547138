import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import AmoTextField from "components/inputs/AmoTextField";
import AmoFormActions from "components/AmoFormActions";
import colors from "constants/colors";
import DeletionType from "enums/deletionType";
import { projectService } from "api/services/projectService";
import { useSnackbar } from "contexts/SnackbarContext";
import { snackbarTypes } from "constants/snackbar";
import { errorMessages } from "constants/errorMessages";
import { useIsMounted } from "hooks/useIsMounted";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    color: colors.black,
    backgroundColor: colors.white,
    width: "38.125rem",
    maxHeight: "80vh",
    padding: theme.spacing(0),
    borderRadius: "5px",
    boxShadow: "15px -10px 40px rgba(0, 0, 0, 0.25)",
    overflowY: "auto",
  },
  modalHeader: {
    position: "relative",
    width: "100%",
    marginTop: "1.5rem",
  },
  modalContent: {
    position: "relative",
    width: "100%",
    paddingBottom: theme.spacing(2),
  },
  headerFont: {
    fontSize: "1.5rem",
    marginLeft: "2.5rem",
    marginBottom: "2rem",
  },
  closeIcon: {
    cursor: "pointer",
    fontSize: "1.5rem",
    marginRight: "1.25rem",
    marginBottom: "2rem",
  },
  gridContent: {
    position: "relative",
    width: "100%",
    marginTop: "2rem",
  },
  contentFont: {
    fontSize: "1rem",
  },
  fullWidth: {
    position: "relative",
    width: "33.4rem",
  },
  contentPadding: {
    // 0px 24px 0px 24px
    padding: "0rem 1.5rem 1rem 2.5rem",
  },
  deletionButtons: {
    width: "12rem",
  },
  confirmDeletionButtons: {
    backgroundColor: colors.red.main,
  },
}));

/**
 * creates a modal dialog for the Project List page and a Project -> Category page using Material-UI's Popover component
 *
 * @param {object} props - object containing props for this component
 * @param {boolean} props.open - controls visibility of the popover
 * @param {string} props.deletionType - type of request, possible options is in DeletionType enum
 * @param {number} props.id - id of the selected project
 * @param {Function} props.closeFunction - function that closes the modal
 * @param {Function} props.updateFunction - function that deletes the project
 *
 * @returns - The modal component
 */

const DeletionModal = (props) => {
  const { open, deletionType, id, closeFunction, updateFunction } = props;

  const classes = useStyles(props);

  const mounted = useIsMounted();

  const [requestIsOngoing, setRequestIsOngoing] = useState(false);

  const isDeletionRequestType = () => deletionType === DeletionType.REQUEST;
  const isDeletionType = () => deletionType === DeletionType.DELETION;

  const defaultValues = {
    reason: "",
  };

  const deletionSchema = yup
    .object()
    .shape({
      reason: yup.string().when("$deletionType", {
        is: DeletionType.REQUEST,
        then: yup
          .string()
          .trim()
          .required("The field cannot be empty")
          .nullable()
          .max(250, "Must be 250 characters or less"),
        otherwise: yup.string().notRequired(),
      }),
    })
    .required();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty },
    getValues,
  } = useForm({
    defaultValues,
    validateOnChange: true,
    mode: "onChange",
    resolver: yupResolver(deletionSchema),
  });

  const { showSnackbar } = useSnackbar();

  const closeModal = () => {
    reset(defaultValues);
    closeFunction();
  };

  const handleRequestSubmit = async () => {
    try {
      setRequestIsOngoing(true);

      const response = await projectService.deleteRequestProjectMunicipal({
        projectId: id,
        reason: getValues("reason"),
      });

      if (!mounted.current) {
        return;
      }

      if (response?.status === 200) {
        showSnackbar("Deletion request sent", snackbarTypes.success);
      }
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(
        errorMessages.municipalProjectRequestDeletion,
        snackbarTypes.error
      );
    }

    setRequestIsOngoing(false);
    closeModal();
    updateFunction();
  };

  const handleDeletionSubmit = async () => {
    try {
      setRequestIsOngoing(true);
      const response = await projectService.deleteProjectMunicipal(id);

      if (!mounted.current) {
        return;
      }

      if (response?.status === 200) {
        showSnackbar(
          "The project was successfully deleted",
          snackbarTypes.success
        );
      }
    } catch {
      if (!mounted.current) {
        return;
      }

      showSnackbar(errorMessages.projectDeletion, snackbarTypes.error);
    }

    setRequestIsOngoing(false);
    closeModal();
    updateFunction();
  };

  const formsActions = [
    {
      disabled:
        (isDeletionRequestType(deletionType) &&
          (isSubmitting || !isValid || !isDirty)) ||
        requestIsOngoing,
      testId: "deletionYesButton",
      className: clsx(classes.deletionButtons, classes.confirmDeletionButtons),
      label: "Yes",
      onClick: handleSubmit(async () => {
        !requestIsOngoing && isDeletionRequestType()
          ? await handleRequestSubmit()
          : await handleDeletionSubmit();
      }),
    },
    {
      disabled: requestIsOngoing,
      testId: "deletionNoButton",
      className: classes.deletionButtons,
      label: "No",
      color: "primary",
      variant: "outlined",
      onClick: closeModal,
    },
  ];

  const modalHeader = (title) => (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.modalHeader}
    >
      <Typography variant="body2" type="h3" className={classes.headerFont}>
        {title}
      </Typography>
      <CloseIcon className={classes.closeIcon} onClick={() => closeModal()} />
    </Grid>
  );

  const textBlock = (text) => (
    <Grid item className={classes.contentPadding}>
      <Typography
        className={clsx(classes.fullWidth, classes.contentFont)}
        variant="body1"
        component="span"
      >
        {text}
      </Typography>
    </Grid>
  );

  const formActionsView = () => (
    <Grid
      container
      style={{
        marginTop: isDeletionRequestType(deletionType) ? "0.6rem" : "3rem",
      }}
    >
      <AmoFormActions actions={formsActions} hideLateralBorders />
    </Grid>
  );

  const requestDeletion = () => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.modalContent}
    >
      <Grid item>{modalHeader(`Request deletion of project ${id}?`)}</Grid>
      <Grid
        container
        className={classes.body}
        direction="column"
        spacing={0}
        alignItems="stretch"
        wrap="nowrap"
      >
        {textBlock(
          "CCBF funds were allocated to this project in a prior year. Deletion must be reviewed and approved by AMO staff."
        )}
        {textBlock(
          "Deletion is permanent. Deleted project data cannot be recovered."
        )}
        {textBlock(
          "Please let us know why you would like to delete this project below."
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        className={classes.gridContent}
        style={{ marginTop: "0.5rem", marginBottom: "1.5rem" }}
      >
        <AmoTextField
          multiline
          rows={1}
          rowsMax={3}
          control={control}
          label="Reason for the request *"
          helperText="Briefly let us know why you want to delete this project"
          id="deletion-reason"
          name="reason"
          testId="deleteionRequestReason"
          className={classes.fullWidth}
          variant="outlined"
          emptyAsNull
          characterLimit={250}
        />
      </Grid>
      <Grid
        container
        className={classes.body}
        direction="column"
        spacing={0}
        alignItems="stretch"
        wrap="nowrap"
      >
        {textBlock("Are you sure you wish to proceed?")}
      </Grid>
      {requestIsOngoing &&
        textBlock("The project deletion request is ongoing.")}
      {formActionsView()}
    </Grid>
  );

  const deletion = () => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.modalContent}
    >
      <Grid item>{modalHeader(`Delete project ${id}?`)}</Grid>
      <Grid
        container
        className={classes.body}
        direction="column"
        spacing={0}
        alignItems="stretch"
        wrap="nowrap"
      >
        {textBlock(
          "Deletion is permanent. Deleted project data cannot be recovered."
        )}
        {textBlock("Are you sure you wish to proceed?")}
        {requestIsOngoing && textBlock("The project deletion is ongoing.")}
        {formActionsView()}
      </Grid>
    </Grid>
  );

  const renderForm = () => {
    if (isDeletionRequestType(deletionType)) {
      return requestDeletion();
    }
    if (isDeletionType(deletionType)) {
      return deletion();
    }
    return null;
  };

  return (
    <Modal
      open={open}
      className={classes.modal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          closeFunction(event, reason);
        }
      }}
      disableEscapeKeyDown
    >
      <Box className={classes.box}>{renderForm()}</Box>
    </Modal>
  );
};

DeletionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  closeFunction: PropTypes.func.isRequired,
  updateFunction: PropTypes.func.isRequired,
  deletionType: PropTypes.string.isRequired,
};

export default DeletionModal;
