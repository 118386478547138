const roles = {
  amo: "AmoStaff",
  amoSuperAdmin: "AmoStaff.Admin",
  amoManager: "AmoStaff.Manager",
  amoLead: "AmoStaff.Lead",
  municipal: "MunicipalUser",
  municipalTreasurer: "MunicipalUser.Treasurer",
  municipalTreasurerDelegate: "MunicipalUser.TreasurerDelegate",
  municipalProjects: "MunicipalUser.Projects",
  municipalCommunications: "MunicipalUser.Communications",
  municipalFinancials: "MunicipalUser.Financials",
  municipalQuestionnaire: "MunicipalUser.Questionnaires",
  communications: "CommunicationsUser",
};

const roleGroups = {
  municipal: {
    treasurerOrDelegate: [
      roles.municipalTreasurer,
      roles.municipalTreasurerDelegate,
    ],
    projects: [
      roles.municipalTreasurer,
      roles.municipalTreasurerDelegate,
      roles.municipalProjects,
    ],
    communications: [
      roles.municipalTreasurer,
      roles.municipalTreasurerDelegate,
      roles.municipalCommunications,
    ],
    financials: [
      roles.municipalTreasurer,
      roles.municipalTreasurerDelegate,
      roles.municipalFinancials,
    ],
    questionnaires: [
      roles.municipalTreasurer,
      roles.municipalTreasurerDelegate,
      roles.municipalQuestionnaire,
    ],
    all: [
      roles.municipalProjects,
      roles.municipalFinancials,
      roles.municipalCommunications,
    ],
  },
};

const municipalPermissions = {
  financials: "Financials",
  projects: "Projects",
  communications: "Communications",
};

const initialState = {
  isAuthenticated: true,
  id: 42,
  userType: "municipalAdmin",
  name: "Jordan Scantlebury",
  firstName: "Jordan",
  lastName: "Scantlebury",
  phoneNumber: "(519)-888-8888",
  email: "fake@email.com",
  roles: [roles.municipal],
  // roles: [roles.municipal, roles.municipalTreasurer],
  // roles: [ roles.amo ],
  // roles: [ roles.communications ],
};

const userTypes = {
  municipal: 0,
  communications: 1,
  amo: 2,
  treasurer: 3,
  treasurerDelegate: 4,
};

export { roles, roleGroups, municipalPermissions, initialState, userTypes };
