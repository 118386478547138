import { Api } from "../api";
import { AxiosResponse } from "axios";

/**
 * @typedef {{
 *  selectedCategoryIds: number[] }} SelectedCategoryIds
 */

const api = new Api("api/Projects");
export const additionalCategoriesService = {
  /**
   * @param {number} projectId
   * @returns {Promise<AxiosResponse<SelectedCategoryIds>>}
   */
  getById: (projectId) => api.getById(`${projectId}/results/categories`),
  /**
   * @param {number} projectId
   * @param {number[]} selectedCategoryIds
   * @returns {Promise<AxiosResponse<SelectedCategoryIds>>}
   */
  postById: (projectId, selectedCategoryIds) =>
    api.axios.post(`${projectId}/results/categories`, selectedCategoryIds),
};
