import { Api } from "../api";
import { AxiosResponse, CancelTokenSource } from "axios";

/**
 * @typedef {{
 *  id: number,
 *  municipalityInternalId: string,
 *  category: string,
 *  title: boolean,
 *  projectStatus: string,
 *  reportStatus: string }} Project
 */

/**
 * @typedef {{
 *  id: number,
 *  fieldCode: string,
 *  updatedByName: string,
 *  updatedByEmail: string,
 *  message: string }} ReviewFlag
 */

/**
 * @typedef {{
 *  id: number,
 *  isPrimary: boolean,
 *  latitude: number,
 *  longitude: number }} ProjectLocation
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string }} File
 */

/**
 * @typedef {{
 *  year: number,
 *  amount: number }} ProjectFund
 */

/**
 * @typedef {{
 *  total: number,
 *  funds: ProjectFund[] }} ProjectFunds
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  subcategories: ProjectCategory[] }} ProjectCategory
 */

/**
 * @typedef {{
 *  id: number,
 *  municipalityId: number,
 *  municipalityName: string,
 *  title: string,
 *  projectCost: number,
 *  ccbfFunding: number,
 *  categoryId: number,
 *  categoryCode: string,
 *  categoryName: string,
 *  assigneeName: string,
 *  statusId: number,
 *  statusName: string,
 *  deletionRequestedName: string,
 *  requestDate: Date}} ProjectReview
 */

/**
 * @typedef {{
 *  id: number,
 *  categoryId: number,
 *  subcategoryId: number,
 *  categoryChangeRequested: boolean,
 *  flags: ReviewFlag[] }} ProjectCategoryData
 */

/**
 * @typedef {{
 *  id: number,
 *  ccbfFunding: number,
 *  totalCcbfBudgeted: number,
 *  totalProjectCost: number,
 *  endOfFinancingDate: number,
 *  additionalComments: string,
 *  flags: ReviewFlag[] }} ProjectFinancialsData
 */

/**
 * @typedef {{
 *  id: number,
 *  title: string,
 *  internalId: string,
 *  objectives: string,
 *  scopeOfWork: string,
 *  startDate: Date,
 *  constructionEndDate: Date,
 *  investmentTypeId: number,
 *  signagePosted: boolean,
 *  additionalComments: string,
 *  communicationsActivities: string,
 *  locations: ProjectLocation[],
 *  files: File[],
 *  flags: ReviewFlag[] }} ProjectInformationData
 */

/**
 * @typedef {{
 *  endOfConstructionDate: Date,
 *  endOfFinancingDate: Date,
 *  closingBalance: number,
 *  projectDeletionRequested: boolean
 * }} ProjectValidationInfoResponse
 */

/**
 * @typedef {{
 *  flagId: number,
 *  ownerName: string,
 *  lastUpdate: string,
 *  comment: string
 * }} FlagProps
 */

/**
 * @typedef {{
 *  value: string,
 *  previousValue: string,
 *  flagProps: FlagProps
 * }} ReviewFieldValue
 */

/**
 * @typedef {{
 *  projectId: number,
 *  statusId: number,
 *  communicatioStatusId: number,
 *  municipalityId: number,
 *  assigneeId: number,
 *  category: {
 *    selectedCategory: ReviewFieldValue,
 *    selectedSubcategory: ReviewFieldValue
 *  },
 *  projectDetails: {
 *    title: ReviewFieldValue,
 *    objectives: ReviewFieldValue,
 *    scopeOfWork: ReviewFieldValue,
 *    natureOfInvestment: ReviewFieldValue,
 *    additionalComments: ReviewFieldValue
 *  },
 *  location: {
 *    primaryLocation: ReviewFieldValue
 *  },
 *  dates: {
 *    startDate: ReviewFieldValue,
 *    constructionEndDate: ReviewFieldValue,
 *    financingEndDate: ReviewFieldValue
 *  },
 *  financials: {
 *    ccbfFundsApplied: ProjectFund[],
 *    totalFundsBudgeted: ReviewFieldValue,
 *    totalCost: ReviewFieldValue
 *  },
 *  communications: {
 *    signagePosted: ReviewFieldValue,
 *    fileUpload: File[]
 *  }
 * }} ProjectReviewData
 */

/**
 * @typedef {{
 *  municipalityId: number,
 *  municipalityName: string,
 *  projectId: number,
 *  title: string,
 *  objectives: string,
 *  totalCost: number,
 *  category: string,
 *  indicatorType: string,
 *  indicatorDescription: string,
 *  textValue: string,
 *  numericValue: number,
 *  beforeValue: number,
 *  afterValue: number,
 *  absoluteChange: number,
 *  unitCost: number,
 *  unitName: string }} ResultsReportingLog
 */

/**
 * @typedef {{
 *  id: number,
 *  actionTypeId: number,
 *  actionDescription: string,
 *  actionDate: string,
 *  actionBy: string }} HistoryLog
 */

/**
 * @typedef {{
 *  id: number,
 *  signagePosted: boolean,
 *  communicationActivities: string,
 *  files: File[],
 *  flags: ReviewFlag[]}} CommunicationsData
 */

/**
 * @typedef {{
 *  id: number,
 *  municipalityInternalId: string,
 *  municipalityId: number,
 *  municipalityName: string,
 *  title: string,
 *  scopeOfWork: string,
 *  objectives: string,
 *  additionalComments: string,
 *  category: string,
 *  subcategory: string,
 *  investmentType: string,
 *  latitude: number,
 *  longitude: number,
 *  startDate: Date,
 *  constructionEndDate: Date,
 *  financingEndDate: Date,
 *  totalCost: number,
 *  ccbfFundsSum: number,
 *  totalBudgeted: number,
 *  signagePosted: boolean,
 *  fileUploaded: boolean,
 *  communicationsActivities: string,
 *  reviewStatus: string,
 *  reviewAssignee: string }} AmoQueryProject
 */

/**
 * @typedef {{
 *  id: number,
 *  reportingYear: number,
 *  fundingApplied: number,
 *  category: string }} AmoQueryProjectExpenditure
 */

/**
 * @typedef {{
 *  projectId: number,
 *  reason: string }} MunicipalDeletionRequest
 */

const api = new Api("api/projects");
export const projectService = {
  /**
   * @returns {Promise<AxiosResponse<Project[]>>}
   */
  getAll: () => api.get(),
  /**
   * @returns {Promise<AxiosResponse<ProjectCategory[]>>}
   */
  getAllCategories: () => api.axios.get("categories"),
  /**
   * @param {number} year
   * @returns {Promise<AxiosResponse<ProjectReview[]>>}
   */
  getReviewByYear: (year) => api.getById(`review/${year}`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<ProjectReview>>}
   */
  getProjectReviewById: (id) => api.getById(`${id}/review`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<ProjectFunds>>}
   */
  getFunds: (id) => api.axios.get(`${id}/funds`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<ProjectCategoryData>>}
   */
  getCategoryData: (id) => api.axios.get(`${id}/category`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<ProjectInformationData>>}
   */
  getInformationData: (id) => api.axios.get(`${id}/information`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<ProjectFinancialsData>>}
   */
  getFinancialsData: (id) => api.axios.get(`${id}/financials`),
  create: (payload) => api.axios.post("category", payload),
  changeCategory: (id, payload) => api.axios.post(`${id}/category`, payload),
  updateInformation: (id, payload) =>
    api.axios.put(`${id}/information`, payload),
  updateFinancials: (id, payload) => api.axios.put(`${id}/financials`, payload),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse>}
   */
  deleteProject: (id) => api.axios.delete(`${id}`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse>}
   */
  deleteProjectMunicipal: (id) => api.axios.delete(`municipal/${id}`),
  /**
   * @param {MunicipalDeletionRequest} payload
   * @returns {Promise<AxiosResponse>}
   */
  deleteRequestProjectMunicipal: (payload) =>
    api.axios.post(`deletion/request`, payload),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse>}
   */

  deleteProjectAMO: (id) => api.axios.delete(`${id}/true`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse>}
   */
  denyDeleteProjectAMO: (id) => api.axios.delete(`${id}/false`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<ProjectValidationInfoResponse>>}
   */
  getValidationInfo: (id) => api.axios.get(`${id}/validation-info`),
  /**
   * @param {number[]} projectIds
   * @returns {Promise<AxiosResponse<Project[]>>}
   */
  downloadTemplate: (projectIds) => api.axios.post("template", projectIds),
  /**
   * @param {number} year
   * @returns {Promise<AxiosResponse<ResultsReportingLog[]>>}
   */
  getResultsReportingLog: (year) => api.getById(`review/results/${year}`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<HistoryLog>>}
   */
  getCommunicationsHistory: (id) => api.getById(`${id}/communications-history`),
  /**
   * @param {number} id
   * @returns {Promise<AxiosResponse<CommunicationsData>>}
   */
  getCommunicationsData: (id) => api.getById(`${id}/communication`),

  updateCommunications: (id, payload) =>
    api.axios.put(`${id}/communication`, payload),
  /**
   * @param {number} projectId
   * @returns {Promise<AxiosResponse<any>>}
   */
  getProjectChanges: (projectId) => api.getById(`${projectId}/changes`),
  /**
   * @param {CancelTokenSource} source
   * @returns {Promise<AxiosResponse<AmoQueryProject[]>>}
   */
  getAllProjectsForExport: (source) =>
    api.getById("export", undefined, { cancelToken: source.token }),
  /**
   * @param {CancelTokenSource} source
   * @returns {Promise<AxiosResponse<AmoQueryProjectExpenditure[]>>}
   */
  getAllProjectsExpendituresForExport: (source) =>
    api.getById("export/expenditures", undefined, {
      cancelToken: source.token,
    }),
};
