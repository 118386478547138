import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Tooltip, Typography, Icon } from "@material-ui/core";
import colors from "constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";

const tooltipBase = {
  backgroundColor: colors.white,
  color: colors.green.contrastText,
  minWidth: "20.1875rem",
  minHeight: "6.25rem",
  maxHeight: "300px",
  paddingLeft: "3%",
  borderRadius: "0.625rem",
  border: `1.5px solid ${colors.green.main}`,
  "& .MuiTooltip-arrow": {
    color: colors.green.main,
  },
  marginTop: "-0.725rem",
};

// sets the Material-UI styles for this component
const useStyles = makeStyles({
  tooltip: {
    ...tooltipBase,
  },
  tooltipTopPlacement: {
    ...tooltipBase,
    marginTop: "-11.5rem",
    paddingLeft: "0rem",

    marginLeft: "0rem",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "260px",
    overflowY: "auto",
    padding: "1rem",
  },
  tooltipTitle: {
    color: "black",
    paddingBottom: "0.5rem",
  },
  tooltipText: {
    color: colors.black,
    fontSize: "0.95rem",
  },
  tooltipIcon: {
    color: `${colors.green.main}99`, // transparency 60%
    fontSize: "2rem",
  },
});

/**
 * A popup tooltip component
 *
 * @param {object} props - object containing props for this component
 * @param {string} props.tooltipTitle - the title to display on the tooltip
 * @param {string} props.tooltipText - the messge text to display on the tooltip
 * @param {
 *  "bottom" |
 *  "left" |
 *  "right" |
 *  "top" |
 *  "bottom-end" |
 *  "bottom-start" |
 *  "left-end" |
 *  "left-start" |
 *  "right-end" |
 *  "right-start" |
 *  "top-end" |
 *  "top-start"
 * } props.tooltipPlacement - the placement where the tooltip will be displayed
 * @param {string} props.customComponent - custom component for ToolTip
 * @returns {React.Component} The Tooltip component, styled to AMO requirements
 */
const AmoTooltip = React.forwardRef((props, ref) => {
  const {
    tooltipTitle,
    tooltipText,
    tooltipPlacement,
    customComponent,
  } = props;

  const [tooltipContent, setTooltipContent] = useState(<></>);

  const [open, setOpen] = React.useState(false);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const classes = useStyles();

  useLayoutEffect(() => {
    const content = (
      <>
        <div className={classes.grid}>
          {tooltipTitle !== null && (
            <Typography
              data-testid="tooltipTitle"
              variant="h5"
              className={classes.tooltipTitle}
            >
              {tooltipTitle}
            </Typography>
          )}
          <Typography
            data-testid="tooltipMessage"
            className={classes.tooltipText}
          >
            {parse(tooltipText?.toString()?.replace(/\n/g, "<br>") ?? "")}
          </Typography>
        </div>
      </>
    );

    setTooltipContent(content);
  }, [tooltipTitle, tooltipText]);

  return (
    <Tooltip
      data-testid="tooltip"
      ref={ref}
      classes={{
        tooltip:
          tooltipPlacement === "top"
            ? classes.tooltipTopPlacement
            : classes.tooltip,
      }}
      PopperProps={{
        "data-testid": "tooltipPopper",
        disablePortal: false,
      }}
      open={open}
      title={tooltipContent}
      placement={tooltipPlacement}
      interactive
      tabIndex={0}
      arrow
      disableHoverListener
      disableFocusListener
    >
      <span
        role="button"
        tabIndex={0}
        style={{ cursor: "help" }}
        onClick={handleOpenClose}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleOpenClose();
          }
        }}
      >
        {customComponent !== undefined && customComponent !== null ? (
          customComponent
        ) : (
          <Icon
            data-testid="tooltipIcon"
            className={clsx("material-icons-outlined", classes.tooltipIcon)}
            fontSize="large"
          >
            help_outline
          </Icon>
        )}
      </span>
    </Tooltip>
  );
});

// set the prop-types for this component
AmoTooltip.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
  customComponent: PropTypes.node,
};

AmoTooltip.defaultProps = {
  tooltipPlacement: "right",
  customComponent: undefined,
};

export default AmoTooltip;
