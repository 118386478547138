import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import { mobileBreakpoint } from "constants/viewport";

/**
 * A style sharing hook to use CSS classes for Summary components.
 *
 * Usage: Import the hook into any Summary component, call it, and access the defined class names.
 *
 * @example
 * import { useSummaryStyles } from "hooks/summaryStylesHook";
 * const ExampleComponent = () => {
 *  const summaryClasses = useSummaryStyles();
 *  return <div className={summaryClasses.exampleClass}>Example content</div>;
 * }
 */
export const useSummaryStyles = makeStyles((theme) => ({
  divider: {
    background: colors.green.dark,
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
  spacer: {
    height: "1.875rem",
  },
  smallSpacer: {
    height: "0.938rem",
  },
  listItemWrapper: {
    marginRight: "1.25rem",
    marginLeft: "1.25rem",
  },
  list: {
    marginTop: "1.25rem",
    [`@media (min-width:${mobileBreakpoint.width}px)`]: {
      marginLeft: "16rem",
      marginRight: "16rem",
    },
  },
  yearTitle: {
    marginLeft: "0.90rem",
    marginTop: "2rem",
  },
  darkGreen: {
    color: colors.green.dark,
  },
  listWrapper: {
    paddingLeft: "25%",
    paddingRight: "25%",
    [`@media (max-width:${mobileBreakpoint.width}px)`]: {
      paddingLeft: "5rem",
      paddingRight: "5rem",
    },
  },
  projectWrapper: {
    paddingLeft: "1.3rem",
    paddingRight: "1.3rem",
  },
  listItemText: {
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontWeight: "bolder",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
}));
