import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Box,
  Button,
  Icon,
  IconButton,
  Divider,
} from "@material-ui/core";
import AmoModal from "components/AmoModal";
import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import AmoCheckbox from "components/inputs/AmoCheckbox";
import AmoTextField from "components/inputs/AmoTextField";
import { useMunicipalContext } from "contexts/MunicipalContext";
import { useWrapApi } from "hooks/wrapApiHook";
import { useIsMounted } from "hooks/useIsMounted";
import { reviewService } from "api/services/reviewsService";
import { reportingService } from "api/services/reportingService";
import { routes } from "constants/routes";
import { Link } from "react-router-dom";
import { useSnackbar } from "contexts/SnackbarContext";
import { reporting } from "constants/reporting";
import AmoConfirmPopover from "components/AmoConfirmPopover";
import {
  useAnnualReportStatus,
  submitAnnualReport,
} from "pages/municipal/report/submitReportHooks";
import ReportingStatusSubmissionModal from "./ReportingStatusSubmissionModal";
import { useMunicipalityContacts } from "./reportingStatusReviewsHooks";
import { isNullOrWhitespace } from "utils/string";
import { formatToPhoneWithExtension } from "utils/number";
import ContactType from "enums/contactType";

const useStyles = makeStyles((theme) => ({
  modalButtons: {
    width: "5rem",
  },
  valueBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  rowBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  titleText: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  financialText: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: colors.green.main,
    width: "10rem",
    textDecoration: "none",
  },
  approvedText: {
    fontSize: "0.875rem",
    color: colors.green.border,
    marginLeft: "2.5rem",
  },
  nonApprovedText: {
    fontSize: "0.875rem",
    color: colors.red.main,
    marginLeft: "2.5rem",
  },
  checkbox: {
    padding: 0,
  },
  textField: {
    height: "10rem",
  },
  contactText: {
    fontSize: "0.875rem",
    color: colors.green.main,
  },
  contactButton: {
    width: "auto",
    fontSize: "0.875rem",
    color: colors.green.main,
    textDecoration: "none",
    padding: 0,
    backgroundColor: "transparent",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    "& .MuiButton-label": {
      fontWeight: "normal",
    },
  },
  emailCopyIconButton: {
    marginLeft: "3px",
    padding: "5px",
  },
  emailCopyIcon: {
    fontSize: "1.15rem",
    color: colors.green.main,
  },
  divider: {
    backgroundColor: colors.grey.light,
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));

/**
 * @param {object} props - object containing props for this component
 * @param {boolean} props.showModal - controls visibility of the modal
 * @param {Function} props.closeModalFunction - function to handle the close modal functionality
 * @param {object} props.selectedReport - object containing the reporting status info to display
 *
 * @returns - the manage contact info modal, styled for the AMO project
 */

const ReportingStatusModal = (props) => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { showModal, closeModalFunction, selectedReport } = props;
  const { municipalities } = useMunicipalContext();
  const { fullName: municipalityName, formalName: municipalityFormalName } =
    municipalities?.[selectedReport.municipalityId] ?? {};

  const mounted = useIsMounted();

  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const [isExecutingReportAction, setIsExecutingReportAction] = useState(false);
  const [showTreasurerEmailCopy, setShowTreasurerEmailCopy] = useState(false);
  const [showPrimaryEmailCopy, setShowPrimaryEmailCopy] = useState(false);
  const [showDelegateEmailCopy, setShowDelegateEmailCopy] = useState(false);

  const isYearClosed = !!selectedReport.reportSubmitted;

  // Query annual report issues
  const { data: submissionIssues } = useAnnualReportStatus(
    selectedReport.municipalityId,
    selectedReport.selectedYear,
    !isYearClosed && selectedReport.selectedYear < reporting.calendarYear
  );

  // Query municipality contacts
  const { data: municipalityContacts } = useMunicipalityContacts(
    selectedReport.municipalityId
  );

  const { treasurer, primary, delegate } = municipalityContacts ?? {};

  const ModalSchema = yup.object({
    selectedForAudit: yup.boolean(),
    notes: yup.string().trim().nullable(),
  });

  const defaultValues = {
    selectedForAudit: false,
    notes: "",
  };

  const { control, setValue, handleSubmit } = useForm({
    defaultValues,
    // setting the mode to "onChange" (or possible onBlur) if very important for validation
    mode: "onChange",
    resolver: yupResolver(ModalSchema),
  });

  const renderApprovedText = (category, value) => {
    let textValue = null;
    if (category === "financialReview" || category === "COI") {
      textValue = value ? "Approved" : "Not Approved";
    } else {
      textValue = value ? "Complete" : "Incomplete";
    }

    return (
      <Typography
        className={value ? classes.approvedText : classes.nonApprovedText}
        style={{ marginLeft: category === "COI" && "7.5rem" }}
      >
        {textValue}
      </Typography>
    );
  };

  useEffect(() => {
    setValue("selectedForAudit", selectedReport.selectedForAudit);
    setValue(
      "notes",
      selectedReport.notes == null
        ? selectedReport.notes
        : selectedReport.notes.trim()
    );
  }, []);

  const closeModal = (refresh = false) => closeModalFunction(refresh);

  const saveModalData = useWrapApi(reviewService.saveReportingStatusModalData);
  const putReopenYear = useWrapApi(
    reportingService.reopenReportingYear,
    "Successfully reopened municipality reporting year"
  );
  const postReportSubmission = useWrapApi(
    submitAnnualReport,
    "Successfully submitted municipality reporting year"
  );

  const reopenReportingYear = async () => {
    setIsReopenModalOpen(false);

    setIsExecutingReportAction(true);
    const { error } = await putReopenYear.call(
      selectedReport.municipalityId,
      selectedReport.selectedYear
    );

    if (!mounted.current) {
      return;
    }

    setIsExecutingReportAction(false);

    if (!error) {
      closeModal(true);
    }
  };

  const handleConfirmModalClose = () => {
    setIsReopenModalOpen(false);
  };

  const forceReportSubmission = async () => {
    setIsSubmissionModalOpen(false);

    setIsExecutingReportAction(true);
    const { error } = await postReportSubmission.call(
      selectedReport.municipalityId,
      selectedReport.selectedYear
    );

    if (!mounted.current) {
      return;
    }

    setIsExecutingReportAction(false);

    if (!error) {
      closeModal(true);
    }
  };

  const handleSaveClick = async ({ ...formValues }) => {
    const payload = {
      ...formValues,
      municipalityId: selectedReport.municipalityId,
    };
    const { error } = await saveModalData.call(
      selectedReport.selectedYear,
      selectedReport.municipalityId,
      payload
    );

    if (!mounted.current) {
      return;
    }

    if (!error) {
      showSnackbar("Updated successfully");
      closeModal(true);
    }
  };

  const copyEmailToClipboard = (source, email) => {
    navigator.clipboard.writeText(email);
    showSnackbar(`${source} email copied`);
  };

  // See more about zoomphonecall on https://developers.zoom.us/docs/zoom-phone/outbound-call/
  const zoomString = (userType) => {
    const contacts = {
      [ContactType.PRIMARY]: municipalityContacts?.primary,
      [ContactType.DELEGATE]: municipalityContacts?.delegate,
      [ContactType.TREASURER]: municipalityContacts?.treasurer,
    };

    const { phone, phoneExtension } = contacts[userType] || {};

    const cleanPhone = phone ? phone.replace(/[^0-9.]+/g, "") : "";
    const cleanExtension = phoneExtension
      ? `?callerid={${phoneExtension}}`
      : "";

    return `zoomphonecall://+1${cleanPhone}${cleanExtension}`;
  };

  return (
    <>
      <AmoModal
        data-testid="amoModal"
        open={showModal}
        variant="default"
        title="Reporting Status"
        width="70rem"
        showButton
        closePopover={() => closeModal()}
        buttonsLeftSpacer={0}
        buttons={[
          {
            key: "reopen",
            label: isYearClosed ? "Reopen Report" : "Force Submission",
            onClick: isYearClosed
              ? () => setIsReopenModalOpen(true)
              : () => setIsSubmissionModalOpen(true),
            color: "secondary",
            variant: "outlined",
            width: "12rem",
            disabled:
              (isYearClosed
                ? selectedReport.selectedYear < reporting.currentReportingYear
                : selectedReport.selectedYear >= reporting.calendarYear) ||
              isExecutingReportAction,
            loading: isExecutingReportAction,
          },
          {
            key: "save",
            label: "Save",
            color: "primary",
            variant: "contained",
            width: "15rem",
            marginLeft: "4.5rem",
            onClick: handleSubmit((formValues) => handleSaveClick(formValues)),
          },
          {
            key: "close",
            label: "Close",
            color: "primary",
            variant: "outlined",
            width: "15rem",
            marginLeft: "2.5rem",
            onClick: () => closeModal(),
          },
        ]}
      >
        <Grid container direction="column" spacing={0} wrap="nowrap">
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              style={{ marginTop: "2rem", minWidth: "54rem" }}
            >
              <Grid item xs={4}>
                <Grid container direction="column" spacing={0} wrap="nowrap">
                  <Grid item>
                    <Typography className={classes.titleText}>
                      Municipality
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{municipalityName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignContent="flex-start"
                  spacing={5}
                >
                  {/* Treasurer information */}
                  <Grid item>
                    <Grid container direction="column" wrap="nowrap">
                      <Grid item>
                        <Typography className={classes.titleText}>
                          Treasurer
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {isNullOrWhitespace(treasurer?.name)
                            ? "--"
                            : treasurer?.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {isNullOrWhitespace(treasurer?.email) ? (
                          <Typography className={classes.contactText}>
                            --
                          </Typography>
                        ) : (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            onMouseLeave={() =>
                              setShowTreasurerEmailCopy(false)
                            }
                          >
                            <Grid
                              item
                              onMouseEnter={() =>
                                setShowTreasurerEmailCopy(true)
                              }
                            >
                              <Button
                                className={classes.contactButton}
                                href={`mailto:${treasurer.email}`}
                                disableRipple
                              >
                                {treasurer.email}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Box
                                visibility={
                                  showTreasurerEmailCopy ? "visible" : "hidden"
                                }
                              >
                                <IconButton
                                  className={classes.emailCopyIconButton}
                                  onClick={() => {
                                    copyEmailToClipboard(
                                      "Treasurer",
                                      treasurer.email
                                    );
                                  }}
                                >
                                  <Icon
                                    className={clsx(
                                      "material-symbols-outlined",
                                      classes.emailCopyIcon
                                    )}
                                    color="primary"
                                  >
                                    content_copy
                                  </Icon>
                                </IconButton>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item>
                        {isNullOrWhitespace(treasurer?.phone) ? (
                          <Typography className={classes.contactText}>
                            --
                          </Typography>
                        ) : (
                          <Button
                            className={classes.contactButton}
                            // See more about zoomphonecall on https://developers.zoom.us/docs/zoom-phone/outbound-call/
                            href={zoomString(ContactType.TREASURER)}
                            disableRipple
                          >
                            {formatToPhoneWithExtension(
                              treasurer?.phone,
                              treasurer?.phoneExtension
                            )}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Primary contact information */}
                  <Grid item>
                    <Grid container direction="column" wrap="nowrap">
                      <Grid item>
                        <Typography className={classes.titleText}>
                          Primary Contact
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {isNullOrWhitespace(primary?.name)
                            ? "--"
                            : primary?.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {isNullOrWhitespace(primary?.email) ? (
                          <Typography className={classes.contactText}>
                            --
                          </Typography>
                        ) : (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            onMouseLeave={() => setShowPrimaryEmailCopy(false)}
                          >
                            <Grid
                              item
                              onMouseEnter={() => setShowPrimaryEmailCopy(true)}
                            >
                              <Button
                                className={classes.contactButton}
                                href={`mailto:${primary.email}`}
                                disableRipple
                              >
                                {primary.email}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Box
                                visibility={
                                  showPrimaryEmailCopy ? "visible" : "hidden"
                                }
                              >
                                <IconButton
                                  className={classes.emailCopyIconButton}
                                  onClick={() => {
                                    copyEmailToClipboard(
                                      "Primary Contact",
                                      primary.email
                                    );
                                  }}
                                >
                                  <Icon
                                    className={clsx(
                                      "material-symbols-outlined",
                                      classes.emailCopyIcon
                                    )}
                                    color="primary"
                                  >
                                    content_copy
                                  </Icon>
                                </IconButton>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item>
                        {isNullOrWhitespace(primary?.phone) ? (
                          <Typography className={classes.contactText}>
                            --
                          </Typography>
                        ) : (
                          <Button
                            className={classes.contactButton}
                            // See more about zoomphonecall on https://developers.zoom.us/docs/zoom-phone/outbound-call/
                            href={zoomString(ContactType.PRIMARY)}
                            disableRipple
                          >
                            {formatToPhoneWithExtension(
                              primary?.phone,
                              primary?.phoneExtension
                            )}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Treasurer delegate information */}
                  <Grid item>
                    <Grid container direction="column" wrap="nowrap">
                      <Grid item>
                        <Typography className={classes.titleText}>
                          Delegate Contact
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {isNullOrWhitespace(delegate?.name)
                            ? "--"
                            : delegate?.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {isNullOrWhitespace(delegate?.email) ? (
                          <Typography className={classes.contactText}>
                            --
                          </Typography>
                        ) : (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            onMouseLeave={() => setShowDelegateEmailCopy(false)}
                          >
                            <Grid
                              item
                              onMouseEnter={() =>
                                setShowDelegateEmailCopy(true)
                              }
                            >
                              <Button
                                className={classes.contactButton}
                                href={`mailto:${delegate.email}`}
                                disableRipple
                              >
                                {delegate.email}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Box
                                visibility={
                                  showDelegateEmailCopy ? "visible" : "hidden"
                                }
                              >
                                <IconButton
                                  className={classes.emailCopyIconButton}
                                  onClick={() => {
                                    copyEmailToClipboard(
                                      "Delegate Contact",
                                      delegate.email
                                    );
                                  }}
                                >
                                  <Icon
                                    className={clsx(
                                      "material-symbols-outlined",
                                      classes.emailCopyIcon
                                    )}
                                    color="primary"
                                  >
                                    content_copy
                                  </Icon>
                                </IconButton>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item>
                        {isNullOrWhitespace(delegate?.phone) ? (
                          <Typography className={classes.contactText}>
                            --
                          </Typography>
                        ) : (
                          <Button
                            className={classes.contactButton}
                            // See more about zoomphonecall on https://developers.zoom.us/docs/zoom-phone/outbound-call/
                            href={zoomString(ContactType.DELEGATE)}
                            disableRipple
                          >
                            {formatToPhoneWithExtension(
                              delegate?.phone,
                              delegate?.phoneExtension
                            )}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Divider className={classes.divider} />
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box className={classes.valueBox}>
              <Box className={classes.rowBox}>
                <Typography
                  className={classes.financialText}
                  component={Link}
                  to={routes.reviews.financial.form
                    .replace(":municipalityId", selectedReport.municipalityId)
                    .replace(":year", selectedReport.selectedYear)}
                >
                  Financial Review
                </Typography>
                {renderApprovedText(
                  "financialReview",
                  selectedReport.financialsApproved
                )}
              </Box>
              <Box className={classes.rowBox} style={{ marginTop: "1rem" }}>
                <Typography
                  className={classes.financialText}
                  component={Link}
                  to={`${
                    routes.reviews.projects.list
                  }?filter_municipality=${encodeURIComponent(
                    municipalities?.[selectedReport.municipalityId]?.name
                  ).replace(/-/g, "%252D")}-true`}
                >
                  Project Review
                </Typography>
                {renderApprovedText(
                  "projectReview",
                  selectedReport.projectsApproved
                )}
              </Box>
            </Box>
            <Box className={classes.valueBox} style={{ marginLeft: "15rem" }}>
              <Box className={classes.rowBox}>
                <Typography
                  className={classes.financialText}
                  style={{ width: "5rem" }}
                  component={Link}
                  to={`${
                    routes.reviews.insurance.list
                  }?filter_municipality=${encodeURIComponent(
                    municipalities?.[selectedReport.municipalityId]?.name
                  ).replace(/-/g, "%252D")}-true`}
                >
                  COI
                </Typography>
                {renderApprovedText("COI", selectedReport.certificateApproved)}
              </Box>
              <Box className={classes.rowBox} style={{ marginTop: "1rem" }}>
                <Typography className={classes.titleText}>
                  Selected for Audit
                </Typography>
                <Box style={{ marginLeft: "3.6rem" }}>
                  <AmoCheckbox
                    control={control}
                    className={classes.checkbox}
                    id="selectedForAuditCheckbox"
                    name="selectedForAudit"
                    testId="selectedForAuditCheckboxTest"
                    color="primary"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item>
            <AmoTextField
              control={control}
              multiline
              rows={4}
              id="notesTextfield"
              name="notes"
              testId="notesTextfieldTest"
              fullWidth
              variant="outlined"
              placeholder="Enter notes..."
            />
          </Grid>
        </Grid>
      </AmoModal>
      <AmoConfirmPopover
        variant="default"
        open={isReopenModalOpen}
        closePopover={() => handleConfirmModalClose()}
        title="Reopen reporting year?"
        text={`Are you sure you want to reopen ${selectedReport.selectedYear}'s report for ${municipalityFormalName}?`}
        showButton
        yesButtonText="Yes, Reopen"
        yesButtonFunction={reopenReportingYear}
        yesButtonVariant="contained"
        noButtonText="No, Cancel"
        noButtonFunction={() => handleConfirmModalClose()}
        noButtonVariant="outlined"
      />
      <ReportingStatusSubmissionModal
        open={isSubmissionModalOpen}
        onClose={() => setIsSubmissionModalOpen(false)}
        onSubmit={forceReportSubmission}
        municipalityId={selectedReport.municipalityId}
        year={selectedReport.selectedYear}
        requirements={submissionIssues?.data?.requirements ?? []}
        projects={submissionIssues?.data?.projects ?? []}
      />
    </>
  );
};

ReportingStatusModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModalFunction: PropTypes.func.isRequired,
  selectedReport: PropTypes.shape({
    municipalityId: PropTypes.number,
    selectedYear: PropTypes.number,
    municipalityName: PropTypes.string,
    treasurerName: PropTypes.string,
    reportSubmitted: PropTypes.bool,
    financialsApproved: PropTypes.bool,
    projectsApproved: PropTypes.bool,
    certificateApproved: PropTypes.bool,
    selectedForAudit: PropTypes.bool,
    notes: PropTypes.string,
  }).isRequired,
};

export default ReportingStatusModal;
