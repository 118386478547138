import {
  AirportSvg,
  BroadbandSvg,
  BrownfieldSvg,
  CapacityBuildingSvg,
  CultureSvg,
  DisasterSvg,
  EnergySvg,
  FireStationSvg,
  LocalRoadActiveSvg,
  LocalRoadBridgeSvg,
  LocalRoadCulvertSvg,
  LocalRoadRoadSvg,
  LocalRoadSvg,
  PublicTransitSvg,
  RecreationSvg,
  ShortLineRailSvg,
  ShortSeaShippingSvg,
  SolidWasteSvg,
  SportSvg,
  TourismSvg,
  WastewaterSvg,
  WaterSvg,
} from "img/projectCategoryIcons";

export const projectCategoryIcons = {
  AIRPORT: AirportSvg,
  BROADBAND: BroadbandSvg,
  BROWNFIELD: BrownfieldSvg,
  CAPBLDG: CapacityBuildingSvg,
  COMMENERGY: EnergySvg,
  CULTURE: CultureSvg,
  DISASTER: DisasterSvg,
  FIRE: FireStationSvg,
  RECREATION: RecreationSvg,
  ROADS_BRIDGE: LocalRoadBridgeSvg,
  ROADS_CULVERT: LocalRoadCulvertSvg,
  ROADS_LOCAL: LocalRoadRoadSvg,
  ROADS_OTHER: LocalRoadSvg,
  ROADS_TRANSPORT: LocalRoadActiveSvg,
  SHORTRAIL: ShortLineRailSvg,
  SHORTSEA: ShortSeaShippingSvg,
  SOLIDWASTE: SolidWasteSvg,
  SPORT: SportSvg,
  TOURISM: TourismSvg,
  TRANSIT: PublicTransitSvg,
  WASTEWATER: WastewaterSvg,
  WATER: WaterSvg,
};
